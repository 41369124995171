import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Directive validates if the input value is unique within an array
 * 
 * Usage:
 * Template:
 * <input type="text" [(ngModel)]="newPriceList.priceListName" id="newPricelistName" name="newPricelistName" #newPriceListNameFormItem="ngModel" maxlength="20" 
        [validateUnique]="customer.projectPriceLists" validateUniqueAttr="priceListName" required>
 * }
 */
@Directive({
  selector: '[validateUnique],[validateUniqueAttr]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateUniqueDirective, multi: true}]
})
export class ValidateUniqueDirective implements Validator {
  @Input('validateUnique') sourceArray: any[];    // array of objects
  @Input('validateUniqueAttr') attrName: string;  // object attribute to be unique

  validate(control: AbstractControl): {[key: string]: any} {
    return uniqueValidator(this.sourceArray, this.attrName)(control);
  }
}

/**
 * Validator function
 */
export function uniqueValidator(sourceArray: any[], attrName: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return sourceArray.filter(item => (attrName ? item[attrName] : item) === control.value).length > 0 ? { notUnique: true } : null;
  };
}
