import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { DeliveryOrder } from '../../model/delivery.order.model';
import { LanguageService } from '../../services/language.service';
import { LocalNumberPipe } from '../../locale.pipes.module';

@Injectable()
export class DeliveryOrdersItemsService {
    
    constructor (private http: EcmHttpService, 
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /** 
     * Loads items list of all delivery orders
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getDeliveryOrdersItems(query: any) {
        var this_ = this;
        let url = `/delivery-orders-items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/delivery-orders-items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

