<textarea class="form-control" style="height: 150px;"
    [(ngModel)]="post" (ngModelChange)="_onPostChange()"
    placeholder="{{ 'DISCUSSION_NEW_PLACEHOLDER' | translate }}" 
    [disabled]="isDisabled"
></textarea>

<div fxLayout="row" style="margin-top: 5px;" *ngIf="showPrivateControls">
    <div fxFlex="50 0" fxLayout="row" fxLayoutAlign="end center" class="row-container">
        <span>{{privateConfig?.dropdownText.title | translate}}:</span>
    </div>
    <div fxFlex="50 0" fxLayout="row" fxLayoutAlign="end" class="row-container">
        <select class="form-control" style="width: auto;" [(ngModel)]="isPrivate"
            (ngModelChange)="onIsPrivateChange()" [disabled]="isDisabled">

            <option [ngValue]="true">{{privateConfig?.dropdownText.privateText|translate}}</option>
            <option [ngValue]="false">{{privateConfig?.dropdownText.publicText|translate}}</option>
        </select>
    </div>
</div>

<div fxLayout="row" style="margin-top: 5px;" *ngIf="showPrivateControls && isPrivate === false">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="end" class="row-container">
        <span class="alert-danger" id="privateConfigWarning">{{privateConfig.buttonWarning | translate}}</span>
    </div>
</div>