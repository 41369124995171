export interface UserNotificationsRecurrencyResponseDto {
    id: number; // 1
    roleName: string; // "deliveryDateChangedAs"
    day0: number; // 1
    day1: number; // 0
    day2: number; // 0
    day3: number; // 0
    day4: number; // 0
    day5: number; // 0
    day6: number; // 0
}

export class UserNotificationsRecurrency {
    public id: number; // 1
    public roleName: string; // "deliveryDateChangedAs"
    public day0: boolean; // true
    public day1: boolean; // false
    public day2: boolean; // false
    public day3: boolean; // false
    public day4: boolean; // false
    public day5: boolean; // false
    public day6: boolean; // false

    constructor(dto: UserNotificationsRecurrencyResponseDto) {
        this.id = dto.id;
        this.roleName = dto.roleName;
        this.day0 = dto.day0 === 1;
        this.day1 = dto.day1 === 1;
        this.day2 = dto.day2 === 1;
        this.day3 = dto.day3 === 1;
        this.day4 = dto.day4 === 1;
        this.day5 = dto.day5 === 1;
        this.day6 = dto.day6 === 1;
    }
}

export class UserNotificationsRecurrencyRequestDto {
    public id: number; // 1
    public roleName: string; // "deliveryDateChangedAs"
    public day0: number;
    public day1: number;
    public day2: number;
    public day3: number;
    public day4: number;
    public day5: number;
    public day6: number;

    constructor(dto: UserNotificationsRecurrency) {
        this.id = dto.id;
        this.roleName = dto.roleName;
        this.day0 = dto.day0 ? 1 : 0;
        this.day1 = dto.day1 ? 1 : 0;
        this.day2 = dto.day2 ? 1 : 0;
        this.day3 = dto.day3 ? 1 : 0;
        this.day4 = dto.day4 ? 1 : 0;
        this.day5 = dto.day5 ? 1 : 0;
        this.day6 = dto.day6 ? 1 : 0;
    }
}
