import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TableColumn } from '@app/model/table.model';

@Component({
    selector: 'table-sort',
    templateUrl: 'table.sort.component.html',
    host: {
        '(document:click)': 'onClick($event)',
    }
})
export class TableSortComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('popover') private popover: ElementRef;
    @ViewChild('dropdownToggle', { static: true }) private dropdownToggle: ElementRef;
    // @Input() items: {id: string, name: string, checked: boolean, orderBy: boolean, orderDirection: string}[];
    @Input() items: TableColumn[];
    @Input() busy: boolean;
    @Input() suppressToUrl: boolean;
    @Input() refresh?: Subject<any>;
    @Output() sort: EventEmitter<any> = new EventEmitter<any>();

    isOpen: boolean;
    autoClose = false; // to not hide

    currentQueryParams: any = {};

    orderBy: string;

    refreshSubscribtion: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        if (this.refresh) {
            this.refreshSubscribtion = this.refresh.subscribe(() => {
                this.ngAfterViewInit();
            });
        }
    }

    ngAfterViewInit() {
        // subscribe to router event
        if (!this.suppressToUrl) {
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.currentQueryParams = {};
                for (var key in params) {
                    this.currentQueryParams[key] = params[key];
                }
                if (params.hasOwnProperty('orderBy')) {
                    setTimeout(() => {
                        const splitted = params['orderBy'].split(' ');
                        const orderByColumnName = splitted[0];
                        const orderByDiretion = splitted[1].toUpperCase();
                        for (let i = 0; i < this.items.length; ++i) {
                            if (this.items[i].id === orderByColumnName) {
                                this.items[i].orderDirection = orderByDiretion;
                                this.items[i].orderBy = true;
                                this.orderBy = this.items[i].id;
                                return;
                            }
                        }
                    }, 0);
                }
            });
        } else {
            this.items.map(item => {
                if (item.orderBy) { this.orderBy = item.id; }
            });
        }
    }

    onSelect(item) {
        if (this.busy) { return; }
        if (this.orderBy === item.id) {
            item.orderDirection = item.orderDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        this.setSort(item);
        if (!this.suppressToUrl) {
            this.currentQueryParams['orderBy'] = this.orderBy + ' ' + item.orderDirection;
            delete this.currentQueryParams['page'];
            const navigationExtras: NavigationExtras = {
                        queryParams: this.currentQueryParams
                };

                this.router.navigate([], navigationExtras);
        }
    }

    setSort(item) {
        this.orderBy = item.id;
            this.sort.emit({sortColumnName: this.orderBy, direction: item.orderDirection.toLowerCase()});
    }

    hide() {
        this.isOpen = false;
    }
    show() {
        this.isOpen = true;
    }

    onClick(event) {
        // call hide only if click outside of popover
        if (this.popover && !this.popover.nativeElement.contains(event.target) && !this.dropdownToggle.nativeElement.contains(event.target)  && this.popover.nativeElement.clientHeight > 0) {
            this.hide();
        }
    }

    ngOnDestroy(): void {
        if (this.refreshSubscribtion) {
            this.refreshSubscribtion.unsubscribe();
        }
    }
}
