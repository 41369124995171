import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../language.service'
import { OrdersItemsService } from '../order/orders.items.service'
import { Shipment, ShipmentItem } from '../../model/shipment.model';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridOrderItemDeliveryDateHistoryRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridOrderItemDeliveryDateHistoryRendererComponent/grid.order.item.delivery.date.history.renderer.component';
import { LocalNumberPipe, LocalCurrencyPipe } from '@app/locale.pipes.module';

@Injectable()
export class ShipmentGridService {

    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<{
        data: ShipmentItem,
        selected: boolean
    }>;

	constructor (
        private translateService: TranslateService,
        private languageService: LanguageService,
        private sharedService: SharedService,
        private tableService: TableService,
        private ordersItemsService: OrdersItemsService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    public getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean) {
        let original = [
            {id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30},
            {id: 'orderNumberEComm', name: "ORDER_ID", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            // {id: 'orderNumberCustomer', name: "ORDER_INTERNAL_NUMBER", checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'itemNote', name: 'SPECIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'orderCreated', name: "DATE_OF_ISSUE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'eCommProductId', name: 'PRODUCT_ID', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'amountOrdered', name: 'ORDERED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountReserved', name: 'RESERVED', checked: false, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountDelivered', name: 'DELIVERED', checked: false, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'amountInvoiced', name: 'INVOICED', checked: false, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountRemaining', name: 'AMOUNT_REMAINING', checked: false, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountBeingPacked', name: 'AMOUNT_PACKING', checked: false, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'amountShipmentInShipping', name: 'AMOUNT_IN_SHIPPING', checked: true, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountShipmentInProcess', name: 'AMOUNT_IN_PROCESS', checked: true, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'availableToShip', name: 'AVAILABLE_TO_SHIP', checked: true, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountShipment', name: 'AMOUNT_SHIPMENT', checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'amountInPacking', name: "AMOUNT_MOQ", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'packingsToShip', name: 'PACKINGS_TO_SHIP', checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'packing', name: 'PACKING_FORM', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: false, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'rowPrice', name: 'TOTAL_VALUE', checked: false, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'itemState', name: "STATUS", checked: false, orderBy: false, orderDirection: 'ASC', width: 50}
        ];

        original = this.tableService.filterSuppressColumns(original, 'shipmentItems');
        let restored = this.sharedService.user.preferences['shipmentTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    getGridOptions(startPage:number, shipment: Shipment) {
        var this_ = this;

        function headerClassFunc(params) {
            return 'bkg-gray-darker';
        }
        function yellowHeaderClassFunc(params) {
            return 'bkg-secondary fnt-primary';
        }

        var gridOptions = this.tableService.getDefaultGridOptions(startPage, 'shipmentTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs(shipment);
        return gridOptions;

    }

    public getColumnDefs(shipment: Shipment) {
        var editable = this.sharedService.hasPermission('shipments/*', 'PUT');
        var this_ = this;
        var colDefs = [
            {
                headerName: '', 
                field: "selected",
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences.shipmentSelection,
                    observable: this_.getSelectedSubject(),
                    observableAll: this_.getItemsSelectedSubject(),
                },
                headerComponent : GridHeaderSelectAllRendererComponent,
                headerComponentParams : {
                    selection: this_.sharedService.user.preferences.shipmentSelection,
                    observable: this_.getItemsSelectedSubject(),
                },
                hide: !(shipment.shipmentState != 'S' && shipment.shipmentState != 'C'),
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNumberEComm').name), 
                field: "orderNumberEComm",
                width: this_.getColumn('orderNumberEComm').width,
                minWidth: this_.getColumn('orderNumberCustomer').width,
                cellStyle: {'text-align': 'left'}
            },
            // {
            //     headerName: this_.translateService.instant(this_.getColumn('orderNumberCustomer').name), 
            //     field: "orderNumberCustomer",
            //     width: this_.getColumn('orderNumberCustomer').width,
            //     minWidth: this_.getColumn('orderNumberCustomer').width,
            //     cellStyle: {'text-align': 'left'}
            // },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemNote').name),
                field: 'itemNote',
                width: this_.getColumn('itemNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this_.getColumn('itemNote').checked
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderCreated').name), 
                field: "orderCreated",
                width: this_.getColumn('orderCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('eCommProductId').name), 
                field: "eCommProductId",
                width: this_.getColumn('eCommProductId').width,
                minWidth: 110,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountOrdered').name), 
                field: "amountOrdered",
                width: this_.getColumn('amountOrdered').width,
                minWidth: this_.getColumn('amountOrdered').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountReserved').name), 
                field: "amountReserved",
                width: this_.getColumn('amountReserved').width,
                minWidth: this_.getColumn('amountReserved').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountReserved').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountDelivered').name), 
                field: "amountDelivered",
                width: this_.getColumn('amountDelivered').width,
                minWidth: this_.getColumn('amountDelivered').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountDelivered').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountInvoiced').name), 
                field: "amountInvoiced",
                width: this_.getColumn('amountInvoiced').width,
                minWidth: this_.getColumn('amountInvoiced').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountInvoiced').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountRemaining').name), 
                field: "amountRemaining",
                width: this_.getColumn('amountRemaining').width,
                minWidth: this_.getColumn('amountRemaining').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountRemaining').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountBeingPacked').name), 
                field: "amountBeingPacked",
                width: this_.getColumn('amountBeingPacked').width,
                minWidth: this_.getColumn('amountBeingPacked').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountBeingPacked').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipmentInShipping').name), 
                field: "amountShipmentInShipping",
                width: this_.getColumn('amountShipmentInShipping').width,
                minWidth: this_.getColumn('amountShipmentInShipping').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipmentInProcess').name), 
                field: "amountShipmentInProcess",
                width: this_.getColumn('amountShipmentInProcess').width,
                minWidth: this_.getColumn('amountShipmentInProcess').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('availableToShip').name), 
                field: "availableToShip",
                width: this_.getColumn('availableToShip').width,
                minWidth: this_.getColumn('availableToShip').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            // {
            //     headerName: this_.translateService.instant(this_.getColumn('amountShipment').name), 
            //     field: "amountShipment",
            //     width: this_.getColumn('amountShipment').width,
            //     minWidth: this_.getColumn('amountShipment').width,
            //     suppressSizeToFit: true, 
            //     cellRendererFramework: GridNumberRendererComponent,
            //     cellStyle: {'text-align': 'right'}
            // },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipment').name), 
                field: 'amountShipment',
                width: this_.getColumn('amountShipment').width,
                minWidth: this_.getColumn('amountShipment').width,
                cellStyle: {'text-align': 'right'},
                valueGetter: (params: {data: ShipmentItem}) => this.getShipmentAmount(params.data)
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountInPacking').name), 
                field: 'amountInPacking',
                width: this_.getColumn('amountInPacking').width,
                minWidth: this_.getColumn('amountInPacking').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packingsToShip').name), 
                field: 'packingsToShip',
                width: this_.getColumn('packingsToShip').width,
                minWidth: this_.getColumn('packingsToShip').width,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: <GridInputRendererComponentParams> {
                    editable: editable,
                    isColValid: this.isColValid,
                    getPopInfo: (params: {data: ShipmentItem}) => {
                        return (this.isColValid('packingsToShip', params.data.packingsToShip, params.data) 
                            ? null
                            : [this.translateService.instant('AMOUNT_SHIPMENT_MORE_THAN_AVAILABLE_TO_SHIP_ERROR')]);
                    },
                    selection: this_.sharedService.user.preferences.shipmentSelection,
                    number: true,
                    decimal: false,
                },
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    selection: this_.sharedService.user.preferences.shipmentSelection,
                    observable: this_.getSelectedSubject(),
                    resetOriginalValuesOnEditStop: true,
                    inputType: 'number',
                },
                editable: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packing').name), 
                field: "packing",
                width: this_.getColumn('packing').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('PACKING_' + params.data.packing);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowWeight').name), 
                field: "rowWeight",
                width: this_.getColumn('rowWeight').width,
                minWidth: 80, 
                valueGetter: (params: {data: ShipmentItem}) => {
                    const rowWeight = this.getShipmentAmount(params.data) * params.data.weight;
                    return new LocalNumberPipe(this.languageService).transform(rowWeight, 
                        this.sharedService.appSettings.language, true, 3);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('unitPrice').name), 
                field: "unitPrice",
                minWidth: 80, 
                width: this_.getColumn('unitPrice').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridPriceRendererComponent,
                hide: !this_.getColumn('unitPrice').checked,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowPrice').name), 
                field: "rowPrice",
                minWidth: 80, 
                width: this_.getColumn('rowPrice').width,
                hide: !this_.getColumn('rowPrice').checked,
                valueGetter: (params: {data: ShipmentItem}) => {
                    const rowPrice = this.getShipmentAmount(params.data) * params.data.unitPrice;
                    return new LocalCurrencyPipe(this.languageService).transform(rowPrice, 
                        this.sharedService.appSettings.language, params.data.currency);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryDate').name), 
                field: "deliveryDate",
                width: this_.getColumn('deliveryDate').width,
                minWidth: 110,
                maxWidth: 110,
                suppressSizeToFit: true, 
                cellRendererFramework: this_.sharedService.hasPermission('order-item-delivery-date-history', 'GET') ? GridOrderItemDeliveryDateHistoryRendererComponent : GridDateRendererComponent,
                cellRendererParams: {getOrderItemDeliveryDateHistory: this_.ordersItemsService.getOrderItemDeliveryDateHistory}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemState').name), 
                field: "itemState",
                width: this_.getColumn('itemState').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {tooltipPrefix: 'ORDER_ITEM_STATE_'},
                hide: !this_.getColumn('itemState').checked,
            }
        ];

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'shipmentItems');
        return colDefs;
    }

    private getShipmentAmount(row: ShipmentItem): number {
        return row.packingsToShip * row.amountInPacking;
    }

    private isColValid(columnName: string, columnValue: any, row: ShipmentItem): boolean {
        const validationMap = {
            'packingsToShip': () =>
                row.packingsToShip * row.amountInPacking <= row.availableToShip &&
                    row.packingsToShip > 0
        };
        const isValid = (columnName in validationMap) ? validationMap[columnName]() : true;
        return isValid;
    }

    getFilterItems() {
        let filterItems = [
            {id: 'orderNumberEComm', name: 'ORDER_ID', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            {id: 'eCommProductId', name: 'PRODUCT_ID', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
            {id: 'orderNumberCustomer', name: 'ORDER_INTERNAL_NUMBER', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'date', value: '', operator: {id: 'eq', name: 'EQUAL', short: '='}},
            {id: 'itemState', name: 'STATUS', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '', 
                values: [
                    {id: 'N', name: 'ORDER_ITEM_STATE_N', dotId: 'N'}, 
                    {id: 'C', name: 'ORDER_ITEM_STATE_C', dotId: 'C'}
                ]
            },
            {id: 'amountShipment', title: 'SHOW_ZERO_AMOUNTS', type: 'checkbox', value: '0', operator: 'ne', checked: false},
        ]
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'shipmentItems');
        return filterItems;
    }
}