
<div class="row-container wider">    
    <div class="area-title">
        <h3><i class="fa fa-briefcase"></i><span translate>CUSTOMERS_ADMINISTRATION</span></h3>
        <button class="btn-primary" routerLink="/{{sharedService.appSettings.language}}/customer/0">
            <i class="fa fa-plus"></i>
            <span translate>ADD</span>
        </button>
    </div>

    <div fxLayout="column">
    	<ecm-table [dataGetter]="getCustomers" 
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [selection]="gridService.getSelection()"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [busy]="busy"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
            [bulk]="bulk"
        ></ecm-table>
    </div>
</div>