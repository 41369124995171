import { Component, ChangeDetectorRef, ViewContainerRef, ViewChild, AfterViewInit, ViewRef, OnDestroy } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { LanguageService, LocaleDef } from '../../../../services/language.service';
import { CellChange, EditedIdsObject, BaseTSelection } from '@app/model/table.model';
import { Subject } from 'rxjs';
import { ICellEditorParams } from 'ag-grid-community';

export interface GridInputEditorComponentParams {
    inputType?: 'number' | 'string' | 'decimal';
    textAlign?: string; // = 'right';  // optional
    maxWidth?: string; // '100px'
    maxLength?: string; // '7' ; 7 means max '999 999'
    decimalLength?: number; // 2 ; max length of decimal digits
    prefixString?: string; // ''
    editedIdsObj?: EditedIdsObject;
    selection?: BaseTSelection<any>;
    resetOriginalValuesOnEditStop?: boolean;
    onChange?: (params: ICellEditorParams) => void;
    cellChanged$?: Subject<CellChange>;
    onEnter?: (row: any) => void;
    isColValid?: (coldId: string, colValue: any, row: object) => boolean;
    // Deprecated
    subjectReloadCellEditable?: Subject<any>;
    observable?: Subject<any>;
}

interface MyParams extends ICellEditorParams, GridInputEditorComponentParams {}

@Component({
    selector: 'app-editor-cell',
    template: `
        <div class="center-text" style="padding: 0 2px;">
            <span>{{prefixString}}</span>

            <number-input name="number-input"
                        *ngIf="params.inputType === 'number' || params.inputType === 'decimal'"
                        [ngModel]="params.value"
                        (ngModelChange)="params.value=$event; onChange()"
                        [inputType]="inputType"
                        [maxLength]="maxLength"
                        [decimalLength]="decimalLength"
                        [maxWidth]="maxWidth"
                        [focusOnInit]="true"
                        [isValid]="isValid"
                        [invalidPopoverText]="params.invalidPopoverText"
                        [textAlign]="params.textAlign"
                        (blur)="beforeStopEditing()"
                        (enterPressed)="enterPressed()"
                        (escPressed)="escPressed()"
                        ></number-input>

            <input *ngIf="params.inputType === 'string'" #input type="text" class="form-control"
                    [ngModel]="params.isCurrency ? (params.value | localnumber:sharedService.appSettings.language) :
                    (params.value)"
                    (ngModelChange)="params.value=$event; onChange()"
                    (keydown)="$event.stopPropagation(); params.onKeyDown($event);"
                    style="margin-top: -6px; margin-left: 2px; padding: 4px; height: 27px; font-size: 98%; display: inline-block;"
                    [attr.maxlength]="maxLength"
                    [ngStyle]="getStyle()"
                    (blur)="beforeStopEditing()">
        </div>
    `,
    styles: [`
    `]
})
export class GridInputEditorComponent implements AgEditorComponent, AfterViewInit, OnDestroy {
    public params: MyParams;          // can be with isCurrency - this shows input value with pipe | localnumber:sharedService.appSettings.language
    public inputType = 'number'; // optional: 'number' / 'string' / 'decimal'
    public textAlign = 'right';  // optional
    public maxWidth = '100px';   // optional
    public maxLength = '7';     // optional; 7 means max '999 999'
    public decimalLength = 2; // optional; max length of decimal digits
    public prefixString = '';    // optional prefix string

    @ViewChild('container', { read: ViewContainerRef }) container;
    @ViewChild('input', { read: ViewContainerRef }) input;

    private decimalDelimiter: string;
    private thousandsDelimiter: string;
    locale: {[key: string]: LocaleDef};

    constructor(
        private cdRef: ChangeDetectorRef,
        public sharedService: SharedService,
        private languageService: LanguageService
    ) {
        this.isValid = this.isValid.bind(this);

        this.locale = this.languageService.getLocale();
        this.decimalDelimiter = this.locale[this.sharedService.appSettings.language].decimalDelimiter; // ',';
        this.thousandsDelimiter = this.locale[this.sharedService.appSettings.language].thousandsDelimiter; // ' ';
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        setTimeout(() => {
            if (this.input) {
                this.input.element.nativeElement.focus();
            }
            // const data = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data
            const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
            for (let i = 0; i < data.originalValues.length; ++i) {
                if (this.params.column.getColId() == data.originalValues[i].colId && this.params.value != data.originalValues[i].value) {
                     // implemented also in typeahead, if changed, change there too
                    if (this.params.value !== null  &&  !data.originalValues[i].value) {
                        this.onChange(); // already changed (e.g. when user selects icharacters in input and then starts to write)
                    }
                    break;
                }
            }
            if ( this.cdRef !== null &&
                this.cdRef !== undefined &&
                ! (this.cdRef as ViewRef).destroyed ) {
                    this.cdRef.detectChanges();
            }
            // this.cdRef.detectChanges();
        }, 0);
    }

    agInit(params: MyParams): void {
        const this_ = this;
        this.params = params;
        this.inputType = this.params.inputType ? this.params.inputType : this.inputType;
        this.maxWidth = this.params.maxWidth ? this.params.maxWidth : this.maxWidth;
        this.maxLength = this.params.maxLength ? this.params.maxLength : this.maxLength;
        this.decimalLength = this.params.decimalLength ? this.params.decimalLength : this.decimalLength;
        this.prefixString = this.params.prefixString ? this.params.prefixString : this.prefixString;

        // if (this.params.inputType === 'number' && this.params.decimal === false) {
        //     const findsThousands = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
        //     this.params.value = this.params.value.toString().replace(' ', '');
        //     this.params.value = this.params.value.toString().replace(' ', '').replace(findsThousands, ' ');
        // }
        this.params.onKeyDown = function(event) {
            if (event.keyCode === 13) { // enter
                this_.enterPressed();
            }
            if (event.keyCode === 27) { // escape
                this_.escPressed();
            }
        }
    }

    public onChange(): void {
        setTimeout(() => {
            // const findsDot = new RegExp(/[\.\,]/g);
            // if (this.params.value && this.params.value.toString().match(findsDot)) {
            //     const x = this.params.value.toString().replace(' ', '').replace(',', '.').split('.');
            //     this.params.value = x[0] + '.' + x[1].substring(0, 2);
            // }

            if (this.params.value &&
                this.params.value.toString().indexOf(this.decimalDelimiter) >= 0
            ) {
                const x = this.params.value.toString().replace(this.thousandsDelimiter, '').replace(this.decimalDelimiter, '.').split('.');
                this.params.value = x[0] + '.' + x[1].substring(0, this.decimalLength);
            }

            const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
            data.edited = true;
            data[this.params.column.getColId()] = this.params.value;
            this.params.node.setDataValue(this.params.column.getColId(), this.params.value);
            if (this.params.onChange) {
                this.params.onChange(this.params);
            }
            if (this.params.editedIdsObj) {
                this.params.editedIdsObj[data.id] = true;
            }

            // TODO: we need input editor to highlight input changed row because of loosing focus
            // select if data updated and item was not in selected before or is not new selected
            if (this.params.selection) {
                // if (!this.params.selection.ids[this.params.data.id] && !this.params.selection.newSelectedIds[this.params.data.id]) {
                    // this.params.selection.ids[this.params.data.id] = this.params.data;
                    // if (this.params.observable) {
                    //     this.params.observable.next({data: this.params.data, selected: !!this.params.selection.ids[this.params.data.id]});
                    //     this.params.api.refreshRows([this.params.node]);
                    // }
                // }
                if (this.params.selection.ids[data.id] && !this.params.selection.newSelectedIds[data.id]) {
                    this.params.selection.updatedIds[data.id] = data;
                }
            }

            if (this.params.subjectReloadCellEditable) {
                this.params.subjectReloadCellEditable.next({ data: data, rowIndex: this.params.rowIndex }); // tell other cells listening to this to reload editable value
            }

            if (this.params.cellChanged$) {
                this.params.cellChanged$.next({
                    data: data, rowIndex: this.params.rowIndex, changedColumnField: this.params.column.getColId()
                } as CellChange);
            }

            this.sharedService.appComponent.cdRef.detectChanges();

        }, 0);
    }

    enterPressed() {
        this.beforeStopEditing(true);
    }

    escPressed() {
        this.params.stopEditing();
        setTimeout(() => {
            const data = this.params.api.getModel().getRow(this.params.rowIndex).data
            for (let i = 0; i < data.originalValues.length; ++i) {
                if (data.originalValues[i].colId == this.params.column.getColId()) {
                    this.params.value = data[this.params.column.getColId()] = data.originalValues[i].value;
                    break;
                }
            }
            delete data.edited;
            if (this.params.editedIdsObj) {
                delete this.params.editedIdsObj[data.id];
            }
        }, 0);
    }

    getValue(): any {
        return this.params.value;
    }

    isPopup(): boolean {
        return false;
    }

    beforeStopEditing(enterWasPressed?: boolean) {
        const data = this.params.api.getModel().getRow(this.params.rowIndex).data;

        if (this.params.selection) {
            const wasSelectedBefore = !!this.params.selection.ids[data.id];
            if (!this.params.selection.newSelectedIds) {
                this.params.selection.ids[data.id] = this.params.selection.all ? false : !this.params.selection.ids[data.id];
                this.params.selection.ids[data.id] = true;
                // if all is selected, remove all selected ids and add all of current page but not curently deselected one
                if (this.params.selection.all) {
                    this.params.selection.ids = {};
                    const model = this.params.api.getModel();
                    for (let i = 0; i < model.getRowCount(); ++i) {
                        if (data.id != model.getRow(i).data.id) {
                            this.params.selection.ids[model.getRow(i).data.id] = true;
                        }
                    }
                }
            } else {
                if (!this.params.selection.deselectedIds[data.id]) {
                    this.params.selection[this.params.selection.ids[data.id] ? 'updatedIds' : 'newSelectedIds'][data.id] = data;
                }
                this.params.selection.ids[data.id] = data;
                delete this.params.selection.deselectedIds[data.id];
            }

            const valueHasChanged = this.params.value.toString() !== data.originalValues.filter(item => item.colId === this.params.column.getColId())[0].value.toString();
            if (this.params.observable && (!wasSelectedBefore || valueHasChanged)) {
                this.params.observable.next({data: data, selected: !!this.params.selection.ids[data.id]});
            }

            if (this.params.resetOriginalValuesOnEditStop) { // set new value to original value
                for (let i = 0; i < data.originalValues.length; ++i) {
                    if (data.originalValues[i].colId === this.params.column.getColId()) {
                        data.originalValues[i].value = data[this.params.column.getColId()] = this.params.value;
                        break;
                    }
                }
            }

            localStorage.setItem('user', JSON.stringify(this.sharedService.user));

            setTimeout(() => { this.params.api.redrawRows() }, 0);
        }

        if (this.params.onEnter && enterWasPressed) {
            this.params.onEnter(data);
            this.params.stopEditing();
            this.sharedService.appComponent.cdRef.detectChanges();


        }
    }

    isValid() {
        const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
        const colId = this.params.column.getColId();
        return (this.params.isColValid
            ? this.params.isColValid(
                colId,
                ['number', 'decimal'].indexOf(this.inputType) >= 0
                // ? (data[colId] ? data[colId].toString().replace(' ', '') : null)
                ? (data[colId] ? data[colId].toString().replace(this.thousandsDelimiter, '') : null)
                : data[colId],
                data
            )
            : true);
    }

    getStyle() {
        return {
            // Mark red when not valid
            'background-color': !this.isValid() ? 'rgba(255, 84, 98, 0.5)' : '',
            'width.px': this.params.column.getActualWidth() - 6,
            'max-width': this.maxWidth,
            'text-align': (this.inputType === 'number') || (this.inputType === 'decimal') ? 'right' : 'left'
        }
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }
}
