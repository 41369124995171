import { Component, Input, Inject, ChangeDetectorRef, HostListener, ViewChild, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ColumnState, GridOptions } from 'ag-grid-community';
import { WindowService } from '../../services/window.service';
import { QuestionDialogService } from '../../components/questionDialogComponent/question.dialog.service';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { TableService } from '../../services/table.service';
import { SharedService } from '../../services/shared.service';
import { DOCUMENT } from '@angular/common';
import { FormService } from '../../services/form.service';
import { IMyOptions } from 'mydatepicker';
import { TableBulk, TableBulkClickFn, ExportObj, TableColumn, TableDataGetterParams, EditedIdsObject, TableBulkActionItem, TableBulkTypes } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';

@Component({
  selector: 'ecm-table',
  templateUrl: 'table.component.html',
  styleUrls:  ['./table.component.scss']
})
export class TableComponent implements DoCheck {
    @ViewChild('agGrid') gridElement; 

	@Input() public dataGetter: (params: TableDataGetterParams) => void;
    @Input() public optionsGetter: Function;
    // @Input() public filterItems: {id: string, name: string, type: string, maxLength?: number, value?: any, valueFrom?: any, valueTo?: any, operator?: {id: string, name: string, short: string}, values?: [{id: string, name: string}]}[];
    @Input() public filterItems: TableFilterItem[];
    @Input() public filterSelectedAttrId: string;
    @Input() public suppressStoreFilters: boolean;
    @Input() public suppressSelection: string;
    @Input() public customDotColors: any; // used in filter for state dot colors
    @Input() public columnsGetter: (forceOriginal?: boolean) => TableColumn[];
    @Input() public columnsDefsGetter: Function;
    @Input() public total: number;
    @Input() public selection: {ids: any, all: boolean, visible: boolean, newSelectedIds?: any, deselectedIds?: any};
    @Input() public editedIdsObj: EditedIdsObject;
    @Input() public observableRefresh: Observable<any>;       // call .next() from outside on this to refresh table (e.g. go to page or change of order etc...)
    @Input() public observableReloadCurrent: Observable<any>; // call .next() from outside on this to reload current - table recreates data source (when add to current order or update, remove...)
    @Input() public observableSetEditable: Observable<any>;   // to change some fields to editable
    @Input() public subjectAllSelectedChange: Subject<any>;
    @Input() public subjectDeleteSelected: Subject<any>; // component emits row 'ids' of selected rows when used click delete in bulk actions
    @Input() public bulk: TableBulk;
    @Input() public showRefreshButton: boolean; // show refresh button to refresh data
    @Input() public busy: boolean;
    @Input() public emptyMessage: string;
    @Input() public suppressEmptyMessage = false;  // do not show the empty message
    @Input() public suppressToUrl: boolean;
    @Input() public tableNamePrefix: string;
    @Input() public secondaryHeaderColor: boolean;
    @Input() public exportObj: ExportObj; // object for table export
        // if defined one export button is shown
        //    {
        //        fileName: < name for exported file >
        //        export: < function thar returns observable of data with url to download file >
        //        excludeCols: [optional] array of excluded column ids
        //        customColNames: [optional] object {<col id>: <name for col>, ..}
        //    }
    @Input() public exportArray: ExportObj[]; // array for table exports
        // if defined export dropdown is shown to choose from export options
    @Input() public showNewRowButton: boolean; // show '+' button to add a new row
    @Input() public newRowGetter: Function;    // function to return the data object wor the new row
    @Input() public suppressPagination: boolean; // true when not show pagination
    @Input() public showTotalRowsInsteadPagination: boolean;
    @Input() public dropup?: boolean; //  if true, it drops pagination up, if false (default) it drops pagination down
    @Input() public resetFilter$?: Subject<void>; // force filter reset from outside of the component

    @HostListener('document:keydown', ['$event'])
        handleKeyboardEvent(event: KeyboardEvent) { 
            if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) { // ctrl+c
                document.execCommand('copy');
            }
        }

    // columns: {id: string, name: string, alwaysVisible: boolean, checked: boolean, orderBy: boolean, orderDirection: string, width: number}[];
    public columns: TableColumn[];
    public tableBulkTypes = TableBulkTypes;

    headerHeight: number = 50;
    gridHeight: number;
	gridWidth: number;
	gridOptions: GridOptions;
	showTable: boolean;
	// subjectRefresh = new Subject<any>(); // it seems to only be necessary for pagination component

    lastContentWidth: number;
    scrolledByLeft: number = 0;  // pixells grid scrolled by horizontaly from left
    dragging: boolean; // set to true if some column is being dragged 
    reseting: boolean; // set to true if columns are being reset to not overwrite by onColumnResized

    myDatePickerOptions: IMyOptions;

    public pagination = {
        startRow: 0,
        pageSize: 10
    };

    doNotGoToFirstPageOnNextSort = false; // set to true when first time setting sort to not go to first page and take page from url if its there
    suppressNextSortModelChange = false; // to not load data on some occasion - resetting columns, and then setting the same sort

    refreshSubscribtion;
    reloadSubscribtion;

    refreshTableSort;

    /**
     * Adjusts column widths if needed, stops editing of cells
     */
	onresize(value:any) {
        let this_ = this;

        setTimeout(function() {
        	if (!this_.gridOptions || !this_.gridOptions.api) {return;}
            this_.adjustTableWidth();
            this_.gridOptions.api.stopEditing();
        }, 1000);
    }

	constructor(private cdRef: ChangeDetectorRef,
              private windowService: WindowService,
              private route: ActivatedRoute,
              private router: Router,
              private questionDialogService: QuestionDialogService,
              private languageService: LanguageService,
              public sharedService: SharedService,
              private tableService: TableService,
              private translateService: TranslateService,
              private userService: UserService,
              private formService: FormService,
              @Inject(DOCUMENT) private document: any) {
        this.getAllColumns = this.getAllColumns.bind(this);
        this.sort = this.sort.bind(this);
        this.getSavedColumnStateIndex = this.getSavedColumnStateIndex.bind(this);
        this.isSavedColumnState = this.isSavedColumnState.bind(this);
        this.getSavedColumnState = this.getSavedColumnState.bind(this);
        this.getSavedColumnsIndex = this.getSavedColumnsIndex.bind(this);

        this.myDatePickerOptions = this.formService.getDatePickerOptions(false, null);
        this.myDatePickerOptions.selectionTxtFontSize = '100%';

        this.tableService.clearEditableObservable();

        this.languageService.getLanguageObservable().subscribe(() => {
            if (this.showTable && this.gridOptions.api) {
                this.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
                    const columnDefs = this.columnsDefsGetter();
                    columnDefs.forEach(colDef => {
                        this.gridOptions.api.getColumnDef(colDef.field).headerName = colDef.headerName;
                        this.gridOptions.api.refreshHeader();
                    });
                    this.gridOptions.api.sizeColumnsToFit(); // to fit current width of table
                    this.reloadCurrent();
                });
            }
        });

        if (typeof this.dropup === 'undefined') {
            this.dropup = false;
        }
    }

    ngDoCheck() {
        // if content width is changed call onresize - this happens in chrome, window is not resized when scrollbar appears/disappears, 
        // but content is, which causes empty space in grid
        if (this.lastContentWidth != document.getElementById('content').offsetWidth) {
            this.lastContentWidth = document.getElementById('content').offsetWidth;
            this.onresize(this.lastContentWidth);
        }
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
        if (this.reloadSubscribtion) {
            this.reloadSubscribtion.unsubscribe();
        }
        if (this.refreshSubscribtion) {
            this.refreshSubscribtion.unsubscribe();
        }
    }

    /**
     * Initializes grid
     */
	ngAfterViewInit() {
        // restore filters and navigate with restored filters if no filters are currently in route
        if (!this.suppressStoreFilters) {
            let restoredFilters = this.tableService.getRestoredFilters(this.tableNamePrefix);
            let currentFilters = Object.assign({}, this.route.snapshot.queryParams);
            delete currentFilters.page;
            delete currentFilters.group1;
            delete currentFilters.group2;
            if (restoredFilters && Object.keys(currentFilters).length === 0) {
                let navigationExtras: NavigationExtras = {
                      queryParams: restoredFilters
                };
                this.router.navigate([], navigationExtras);
            }
        }

        if (this.suppressToUrl) {
            this.refreshTableSort = new Subject();
        }

        // if (this.route.snapshot.queryParams.hasOwnProperty('page') && !this.suppressToUrl) {
        //     this.pagination.startRow = this.pagination.pageSize * (parseInt(this.route.snapshot.queryParams.page) - 1);
        //     console.log('TableComponent setting pagination.startRow 1', this.pagination.startRow);
        // }
        
        var this_ = this;

        this.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            
            this.windowService.height$.subscribe((value:any) => {
                //Do whatever you want with the value.
                //You can also subscribe to other observables of the service
                this.onresize({height$: value});
            });
            this.windowService.width$.subscribe((value:any) => {
                //Do whatever you want with the value.
                //You can also subscribe to other observables of the service
                this.onresize({width: value});
            });

            this.columns = this.columnsGetter().map(x => Object.assign({}, x)); // make copy of array

            this.gridOptions = this.optionsGetter();
            if (this.gridOptions.paginationPageSize > 0) {
                this.pagination.pageSize = this.gridOptions.paginationPageSize;
            }

            if (this.route.snapshot.queryParams.hasOwnProperty('page') && !this.suppressToUrl) {
                this.pagination.startRow = this.pagination.pageSize * (parseInt(this.route.snapshot.queryParams.page) - 1);
            }

            this.gridOptions.getRowStyle = this.getRowStyleFn();

            this.gridOptions.onModelUpdated = function(argument) {
                if (this_.gridOptions.api) {
                    const rowModel = this_.gridOptions.api.getModel();
                    const renderedNodes = this_.gridOptions.api.getRenderedNodes();

                    let height = this_.headerHeight + (rowModel.getRowCount() == 0 ? 15 : 0);
                    renderedNodes.forEach(node => {
                        height += node.rowHeight;
                    });
                    this_.gridHeight = height;
                }
            }    

            this.gridOptions.onColumnResized = function(event) {
                // set columns to fit if there is freee space after resizing
                if (event.finished && !this_.reseting) {
                    this_.userService.setUserPreference(this_.getSavedColumnStateIndex(), this_.gridOptions.columnApi.getColumnState(), true);
                    this_.adjustTableWidth();
                }
            }

            /**
             * @param {ColumnVisibleEvent} event
             */
            this.gridOptions.onColumnVisible = function(event) {
                var state = this_.gridOptions.columnApi.getColumnState();
                for (var i = 0; i < this_.columns.length; ++i) {
                    if (this_.columns[i].id == (event.column ? event.column.getColId() : null)) {
                        // change only if changed state (event is triggered on visible and also on hide even if column is hidden of visible)
                        for (var j = 0; j < state.length; ++j) {
                            if (state[j].colId == (event.column ? event.column.getColId() : null) && this_.columns[i].checked != event.visible) {
                                this_.columns[i].checked = event.visible;
                                this_.userService.setUserPreferences([
                                    {key: this_.getSavedColumnStateIndex(), value: state},
                                    {key: this_.getSavedColumnsIndex(), value: this_.columns.map(x => Object.assign({}, x))}
                                ], true);
                                this_.adjustTableWidth();
                            }
                        }
                        break;
                    }
                }
            }

            this.gridOptions.onColumnMoved = function(event) {
            }

            this.gridOptions.onDragStarted = function(event) {
                this_.dragging = true;
                this_.cdRef.detectChanges();
            }
            this.gridOptions.onDragStopped = function(event) {
                this_.userService.setUserPreference(this_.getSavedColumnStateIndex(), this_.gridOptions.columnApi.getColumnState(), true);

                this_.dragging = false;
                this_.cdRef.detectChanges();
            }

            this.gridOptions.onSortChanged = function() {
                if (this_.suppressNextSortModelChange) {
                    this_.suppressNextSortModelChange = false;
                    return;
                }
                if (this_.doNotGoToFirstPageOnNextSort) {
                    this_.doNotGoToFirstPageOnNextSort = false;
                } else {
                    this_.pagination.startRow = 0;
                }
                const columnState = this_.gridOptions.columnApi.getColumnState();
                const sortColumns = columnState.filter((col) => col.sort);

                this_.columns = this_.columns.map(x => {
                    const col = Object.assign({}, x);
                    if (sortColumns.length > 0) {
                        col.orderBy = col.id === sortColumns[0].colId;
                        if (col.orderBy) {
                            col.orderDirection = sortColumns[0].sort.toLocaleUpperCase();
                        }
                    }
                    return col;
                })
                // save to preferences
                setTimeout(function() {
                    this_.userService.setUserPreference(this_.sharedService.area + (this_.tableNamePrefix ? this_.tableNamePrefix : '') + 'TablePage', 0);
                    this_.userService.setUserPreference(this_.getSavedColumnsIndex(), this_.columns.map(item => item), true);
                    this_.loadData();

                    // to update components that should update
                    // this_.subjectRefresh.next();
                    if (this_.refreshTableSort) {
                        this_.refreshTableSort.next();
                    }
                }, 0);
            }

            if (this.observableRefresh) {
                this.refreshSubscribtion = this.observableRefresh.subscribe(res => {
                    this.refresh(res);
                });
            }
            if (this.observableReloadCurrent) {
                this.reloadSubscribtion = this.observableReloadCurrent.subscribe(res => {
                    this.reloadCurrent();
                });
            }
            if (this.observableSetEditable) {
                this.observableSetEditable.subscribe(res => {
                    this.tableService.editable = true;
                    this.tableService.getEditableObservable().next({editableColIds: res, editable: this.tableService.editable});
                });
            }
            if (this.subjectAllSelectedChange) {
                this.subjectAllSelectedChange.subscribe(res => {
                    if (!res || !this.gridOptions.api) {return;}

                    // change back because it is changed in toggleSelectedVisible
                    this.selection.visible = !this.selection.visible;
                    this.toggleSelectedVisible();
                });
            }

            // find if table has two line header or one line header
            for (let i = 0; i < this.gridOptions.columnDefs.length; ++i) {
                if (this.gridOptions.columnDefs[i].hasOwnProperty('children')) {
                    this.headerHeight = 75;
                    break;
                }
            }

            this.cdRef.detectChanges();

            this.gridOptions.onGridReady = () => {
                this_.gridHeight = 300;

                // restore column states (width, place, visibility)
                if (this.isSavedColumnState()) {
                    let implementationChanged = false; //  changes to true if something changed in implementation so we can not restore column state saved on server
                    const restored = this.getSavedColumnState();
                    const original = this_.columnsGetter(true);
                    if (restored) {
                        if (restored.length !== original.length) {
                            implementationChanged = true;
                        } else {
                            for (let i = 0; i < original.length; ++i) {
                                if (restored[i]) {
                                    if (!original[i].hidden && restored.filter(e => e.colId == original[i].id).length == 0) {
                                        implementationChanged = true;
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        implementationChanged = true; // to default
                    }

                    if (!implementationChanged) {
                        this_.gridOptions.columnApi.applyColumnState({ state: restored });
                    } else {
                        this_.gridHeight = 10000;
                        this_.userService.setUserPreference(this.getSavedColumnStateIndex(), '', true);
                        this_.adjustTableWidth();
                    }
                } else {
                    this_.gridWidth = document.getElementById('catalogue-table').offsetWidth; // to cause 100% width
                }


                // set initial orderBy
                if (!this_.suppressToUrl && this_.route.snapshot.queryParams.hasOwnProperty('orderBy')) {
                    const splitted = this_.route.snapshot.queryParams['orderBy'].split(' ');
                    this_.doNotGoToFirstPageOnNextSort = true;
                    this_.sort(splitted[0], splitted[1]);
                } else {
                    this.loadData();
                }
            }

            setTimeout(function() {
                this_.showTable = true;
                this_.sharedService.appComponent.cdRef.detectChanges();
            }, 0);

        });
    }

    /**
     * Makes a new getRowStyle fn by using user supplied custom fn (if any)
     * and overriding background color in a case of selection
     */
    private getRowStyleFn(): Function {
        const getRowStyle = this.gridOptions.getRowStyle;

        return (params) => {
            let rowStyle = {};

            if (getRowStyle) {
                const customStyle = getRowStyle(params);
                rowStyle = customStyle ? customStyle : rowStyle;
            }

            if (this.selection && (this.selection.ids[params.data.id] || this.selection.all)) {
                rowStyle = Object.assign({}, rowStyle,
                    { 'background-color': 'rgba(68, 255, 84, 0.15)' });
            }

            return Object.keys(rowStyle).length === 0 ? null : rowStyle;
        }
    }

    /**
     * Returns true if table element is wider than all visible columns together (used to know when to adjust column widths to avoid empty space)
     */
    adjustTableWidth() {
        var this_ = this;
        //if not saved settings, adjust to fullscreen
        if (this.showTable && 
            (!this.isSavedColumnState() || this.getSavedColumnState().length == 0)
        ) {
            this.gridWidth = 10000; // big number to be wider than anything to cause 100%
            this.sharedService.appComponent.cdRef.detectChanges();
            this.gridOptions.api.sizeColumnsToFit();
            setTimeout(function() {
                if (this_.gridOptions.api) {
                    this_.gridOptions.api.sizeColumnsToFit();
                    this_.setScrollByLeft();
                }
            }, 600);
            return;
        }
        var visibleColumnsWidth = 0;
        this.gridOptions.columnApi.getAllColumns().forEach(function(column){
            if (column.isVisible()) {visibleColumnsWidth += column.getActualWidth();}
        });
        this.gridWidth = visibleColumnsWidth + 2;
        this.cdRef.detectChanges();
    }

    /**
     * Loads data for grid
     */
    private loadData(): void {
        setTimeout(() => {
            const params: TableDataGetterParams = {
                startRow: this.pagination.startRow,
                endRow: this.pagination.startRow + this.pagination.pageSize,
                successCallback: (rowData, total) => {
                    if (this.gridOptions.api) {
                        this.gridOptions.api.setRowData(rowData);
                    }
                }
            };
            if (this.suppressToUrl) { // get filters from preferences when suppress to url
                params.filter = this.sharedService.user.preferences[this.sharedService.getUserPreferenceKey('Filter', this.tableNamePrefix)];
                
                // Find the sort column
                const columnState = this.gridOptions.columnApi.getColumnState();
                const sortColumns = columnState.filter((col) => col.sort);
                if (sortColumns.length > 1) {
                    console.warn('table.component: multiple sort columns detected', sortColumns)
                }
                if (sortColumns.length === 0) {
                    const orderByColumn = this.columns.filter(col => col.orderBy)[0];
                    if (orderByColumn) {
                        params.orderBy = { colId: orderByColumn.id, sort: orderByColumn.orderDirection };
                        this.sort(params.orderBy.colId, params.orderBy.sort);
                        return;
                    }
                } else {
                    params.orderBy = { colId: sortColumns[0].colId, sort: sortColumns[0].sort };
                }
            }
            this.dataGetter(params);
        }, 0);
    }

    /**
     * Refreshes grid - goes to given page or to first if not given
     *
     * @param page? number of page to go to
     */
    private refresh(page: number): void {
        if (this.gridOptions.api) {
            if (page != null) { // the condition is not clear
                this.pagination.startRow = page * this.pagination.pageSize;
                this.loadData();
            } else {
                this.pagination.startRow = 0;
                this.loadData();
            }
            this.gridOptions.api.deselectAll();
            // this.subjectRefresh.next();
        }
    }

    /**
     * Reloads currently displayed data
     */
    reloadCurrent() {
        this.loadData();
    }

    /**
     * Exports data from all pages
     */
    public export(format: string, exportObject?: ExportObj): void {
        const exportObj = exportObject ? exportObject : this.exportObj
        // get excluded columns
        let excludeCols = ['selected', 'actions']; // default exclude
        if (exportObj.excludeCols) {
            excludeCols = excludeCols.concat(exportObj.excludeCols);
        }

        const query: any = {
            exportToFormat: format,
            exportColumns: [],
            exportColumnsTranslated: []
        };
        // prepare filters
        if (!this.suppressToUrl) {
            for (const key in this.route.snapshot.queryParams) {
                if (this.route.snapshot.queryParams[key]) {
                    query[key] = this.route.snapshot.queryParams[key];
                }
            }
        } else {
            const filter = this.sharedService.user.preferences[this.sharedService.getUserPreferenceKey('Filter', this.tableNamePrefix)];
            console.log(filter);
            const columnState = this.gridOptions.columnApi.getColumnState();
            const sortColumn = columnState.find((col) => col.sort);

            if (!sortColumn) {
                const orderByColumn = this.columns.filter(col => col.orderBy)[0];
                if (orderByColumn) {
                    query.orderBy = orderByColumn.id + ' ' + orderByColumn.orderDirection;
                }
            } else {
                query.orderBy = sortColumn.colId + ' ' + sortColumn.sort;
            }
        }
        delete query.skip;
        delete query.top;
        delete query.page;

        // sort columns as displayed from left to right
        const columns = this.gridOptions.columnApi.getAllColumns().sort(function(a, b) {
            return a.getLeft() - b.getLeft();
        });
        columns.forEach(col => {
            if (col.isVisible()) {
                const colDef = col.getColDef();
                if (excludeCols.indexOf(colDef.field) > -1) { return; }
                query.exportColumns.push(colDef.field);
                const colName = exportObj.customColNames && exportObj.customColNames[colDef.field] ?
                              exportObj.customColNames[colDef.field] : colDef.headerName;
                // query.exportColumnsTranslated.push(encodeURIComponent(colName));
                query.exportColumnsTranslated.push(colName);
            }
        });

        // get exportUrl
        exportObj.export(query).subscribe(data => {
            this.tableService.getExport(data.exportUrl, format, exportObj.fileName);
        }, err => {
            console.log(err);
        });
    }

    getAllColumns() {
        return this.gridOptions.columnApi.getAllColumns();
    }

    /**
     * Sets visibility of column in grid
     *
     * @param event - object with id and checked value(true if column should be set to visible)
     */
    setColumnVisibility(event) {
        this.gridOptions.columnApi.setColumnVisible(event.id, !event.checked);
    }

    /**
     * Resets columns of grid to default settings
     */
    resetColumns() {
        const columnState = this.gridOptions.columnApi.getColumnState();
        this.reseting = true;
        this.userService.setUserPreferences([
            { key: this.getSavedColumnStateIndex(), value: '' },
            { key: this.getSavedColumnsIndex(), value: '' }
        ], true);
        this.columns = this.columnsGetter().map(x => Object.assign({}, x));
        this.gridWidth = 10000; // big number to be wider than anything to cause 100%
        this.cdRef.detectChanges();

        const columnDefs = this.columnsDefsGetter();
        this.gridOptions.api.setColumnDefs([]);
        this.gridOptions.api.setColumnDefs(columnDefs);
        this.suppressNextSortModelChange = true;

        // Find the sort column
        const sortColumns = columnState.filter((col) => col.sort);
        if (sortColumns.length > 1) {
            console.warn('table.component: multiple sort columns detected', sortColumns)
        }
        if (sortColumns.length > 0) {
            this.sort(sortColumns[0].colId, sortColumns[0].sort);
        }

        // reset the preference to nothing
        this.adjustTableWidth();
        this.onresize({});
        setTimeout(() => {
            this.reseting = false;
        }, 0);
    }

    /**
     * Sort grid data according to given object
     */
    sort(colId: string, sort: string) {
        this.gridOptions.columnApi.applyColumnState({
            // Sort on this column
            state: [{ colId, sort: sort.toLowerCase() }],
            // Clear sort on all other columns
            defaultState: { sort: null },
        });
    }

    /**
     * Goes to given page
     *
     * @param newValue - number of page to go to
     */
    public onPageChanged(newValue: number): void {
        this.userService.setUserPreference(this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'TablePage', newValue);
        this.pagination.startRow = this.pagination.pageSize * newValue;
        this.loadData();
    }

    /**
     * Sets page size of grid and recreates data source of grid
     *
     * @param newValue - number of items to be displayed on one page
     */
    onPageSizeChanged(newValue) {
        this.userService.setUserPreference(this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'TablePage', 0);
        this.userService.setUserPreference(this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'TablePageSize', newValue, true);
        this.pagination.startRow = 0;
        this.pagination.pageSize = newValue;
        this.loadData();
    }

    /**
     * On filter changed
     */
    onFilterChanged() {
        setTimeout(() => {
            this.refresh(0);
        }, 0);
    }


    getSelectedCount() {
        return this.selection ? Object.keys(this.selection.ids).length : 0;
    }
    getEditedCount() {
        return this.editedIdsObj ? Object.keys(this.editedIdsObj).length : 0;
    }

    cancelSelected() {
        if (this.selection.newSelectedIds) {
           for (const key in this.selection.ids) {
               if (!this.selection.newSelectedIds[key]) {
                  this.selection.deselectedIds[key] = this.selection.ids[key];
               }
           }
           this.selection.newSelectedIds = {};
        }

        this.selection.ids = {};
        this.selection.visible = false;
        this.selection.all = false;
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        if (this.subjectAllSelectedChange) {
            this.subjectAllSelectedChange.next('');
        }
        this.cdRef.detectChanges();
        this.gridOptions.api.redrawRows();
        this.gridOptions.api.deselectAll();
            // this.subjectRefresh.next();
        this.reloadCurrent;
    }

    toggleSelectedVisible() {
        this.selection.visible = !this.selection.visible;
        this.selection.all = false;
        let model = this.gridOptions.api.getModel();
        let toBeSetAsSelectedIds = {};
        for (var i = 0; i < model.getRowCount(); ++i) {
            if (this.selection.visible) {
                if (this.selection.newSelectedIds) {
                    if (!this.selection.deselectedIds[model.getRow(i).data.id] && !this.selection.ids[model.getRow(i).data.id]) {
                        this.selection.newSelectedIds[model.getRow(i).data.id] = model.getRow(i).data;
                    }
                    delete this.selection.deselectedIds[model.getRow(i).data.id];
                }
                toBeSetAsSelectedIds[model.getRow(i).data.id] = model.getRow(i).data;
            } else {
                delete this.selection.ids[model.getRow(i).data.id]; 
                if (this.selection.newSelectedIds) {
                    if (!this.selection.newSelectedIds[model.getRow(i).data.id]) {
                        this.selection.deselectedIds[model.getRow(i).data.id] = model.getRow(i).data;
                    }
                    delete this.selection.newSelectedIds[model.getRow(i).data.id];
                }
            }
        }

        for (const key of Object.keys(this.selection.ids)) {
            if (this.selection.deselectedIds && !toBeSetAsSelectedIds[key]) {
                this.selection.deselectedIds[key] = this.selection.ids[key];
            }
        }       

        this.selection.ids = toBeSetAsSelectedIds;
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        if (this.subjectAllSelectedChange) {
            this.subjectAllSelectedChange.next('');
        }
        this.cdRef.detectChanges();
        this.gridOptions.api.redrawRows();
    }

    toggleSelectedAll() {
        this.selection.visible = false;
        this.selection.all = !this.selection.all;
        const model = this.gridOptions.api.getModel();
        for (let i = 0; i < model.getRowCount(); ++i) {
            const row = model.getRow(i);
            if (this.selection.all) {
                if (this.selection.newSelectedIds && !this.selection.ids[row.data.id] && !this.selection.deselectedIds[row.data.id]) {
                    this.selection.newSelectedIds[row.data.id] = row.data;
                }
                this.selection.ids[row.data.id] = row.data;
            } else {
                delete this.selection.ids[row.data.id];
            }
        }
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        if (this.subjectAllSelectedChange) {
            this.subjectAllSelectedChange.next('');
        }
        this.cdRef.detectChanges();
        this.gridOptions.api.redrawRows();
    }

    /**
     * returns array of selected ids
     */
    private getSelectedIds(): string[] {
        let ids:string[] = [];
        if (!this.selection.all) {
            for (var key in this.selection.ids) {
                ids.push(key);
            }
        }
        return ids;
    }

    /**
     * Calls button function
     * @param {Function} fn  function to call after button click with selected IDs array
     */
    public callBulkButtonFn(fn: TableBulkClickFn): void {
        fn(this.getSelectedIds());
    }

    deleteSelected() {
        this.questionDialogService.confirm(
                {    
                    message: 'DELETE_SELECTED',
                    question: 'DELETE_SELECTED_QUESTION',
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                res => {
                    if (res == 'confirm') {
                        let ids = this.getSelectedIds();
                        this.cancelSelected();
                        this.subjectDeleteSelected.next(ids);
                    }
                }  
            );
    }

    /**
     * Onchange of bulk update item
     * @param bulkItem
     * @param event in case of select: {
     *      attributeName: string, // 'packing'
     *      selected: {... selected select item} // {id: 'F', name: 'PACKING_F}
     * }
     */
    // public onBulkChange(bulkItem: {id: string, name: string, type: string, value: any, values?: {id: string, name:string}[]}, event: any): void {
    public onBulkChange(bulkItem: TableBulkActionItem, event: any): void {
        let data: any = {};
        let saveDate = bulkItem.value || event.jsdate;

        if (bulkItem.type === TableBulkTypes.date) {
            // if (!event.jsdate) {return;}
            // data[item.id] = this.formService.getDateStringFromIsoDate(event.jsdate.toISOString());
            data[bulkItem.id] = event.jsdate === null ? null : this.formService.getDateStringFromIsoDate(event.jsdate.toISOString());
        }
        if (bulkItem.type === TableBulkTypes.select) {
            if (!event.selected.id) {return;}
            data[bulkItem.id] = event.selected.id;
        }
        if (bulkItem.type === TableBulkTypes.input) {
            data[bulkItem.id] = bulkItem.value;
        }

        // If update fn specified for the item, use it, otherwise use the general update fn
        const updateFn = bulkItem.itemUpdateFn ? bulkItem.itemUpdateFn : this.bulk.update;

        // this.bulk.update(this.getSelectedIds(), data, bulkItem.id).subscribe(data => {
        updateFn(this.getSelectedIds(), data).subscribe(() => {
            // reset value
            bulkItem.value = bulkItem.type === TableBulkTypes.date ? saveDate : '';

            if (bulkItem.values) {
                bulkItem.value = bulkItem.values[0];
            }
            // reload table data
            this.reloadCurrent()
        }, err => {
            console.log(err);
        });
    }

    /**
     * Event called when grid is scrolled inside its container
     */
    bodyScroll = function(event){
        this.setScrollByLeft();
    }

    /**
     * Sets scrol by left value according to scroll position inside grid scroll element
     */
    setScrollByLeft = function() {
        let this_ = this;
        setTimeout(function() {
            let scrollElement = this_.document.getElementsByClassName("ag-header-container")[0];
            if (scrollElement) {
                let leftValue = parseInt(scrollElement.style.left.replace('px',''));
                if (Number.isInteger(leftValue)) {
                    this_.scrolledByLeft = leftValue
                }
            }
        }, 0);
    }

    /**
     * Adds a new row to the grid
     */
    addNewRow() {
        const data = this.newRowGetter();
        data.edited = true;
        this.gridOptions.api.applyTransaction({add:[data]});
        this.editedIdsObj[data.id] = true;

        // get first col from last row and set focus
        const firstCol = this.gridOptions.columnApi.getAllDisplayedColumns()[2];
        this.gridOptions.api.ensureColumnVisible(firstCol);
        const model: any = this.gridOptions.api.getModel();
        this.gridOptions.api.setFocusedCell(model.rowsToDisplay.length - 1, firstCol);
        this.gridOptions.api.startEditingCell({
            rowIndex: model.rowsToDisplay.length - 1,
            colKey  : 'rfqProduct'
        });

    }

    private getSavedColumnStateIndex(): string {
        return this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'TableColumnsState';
    }

    private isSavedColumnState(): boolean {
        return this.sharedService.user.preferences.hasOwnProperty(this.getSavedColumnStateIndex());
    }

    private getSavedColumnState(): ColumnState[] {
        return this.sharedService.user.preferences[this.getSavedColumnStateIndex()];
    }

    private getSavedColumnsIndex(): string {
        return this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'TableColumns';
    }
}
