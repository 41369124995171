import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';

export type AreaPerm = 'newsRead' | 'newsCreate' |
    'customerStockItemsRead' | 'customerStockItemsReadAs' |
    'erpDataConsistencyCheck' | 'sentEmails' |
    'cataloqueRead' | 'purchaseCatalogueRead' | 'ProductsExport' | 'rfqRead' |
    'purchaseRfqRead' | 'ordersRead' | 'deliveryOrdersRead' |
    'invoicesRead' | 'journalRead' | 'monitoringRead' |
    'authorizedSellerCustomersRead' | 'usersAdmin' | 'repreOverrideUserAdmin' | 'ordersCreate' |
    'shipmentsRead' | 'asStockManage' | 'PriceListUpdate' | 'suppliersRead' | 'controllingRead';

/**
 * @class PermissionService.
 * Global permissions service
 * ---
 *
 * Provide string or array of strings to method get, post, delete and put and permission service return boolean representation
 * of resource availability for current user. Use to check availability for current permission/s or url
 *
 * @param {string|array} perms - set of permissions or one permission provided to comfront with user persmissions set in localStorage.
 * @return {boolean} Return boolean representation of permission: (true) if user has permission, otherwise return (false).
 *
 */
@Injectable()
export class PermissionsService {
    private areaPermDef: {
        areaPerm: AreaPerm,
        permissions: {
            perm: string,
            action: string
        }[],
        validator?: () => boolean;
    }[] = [
            {
                // News
                areaPerm: 'newsRead',
                permissions: [
                    { perm: 'news', action: 'GET' },
                ]
            },
            {
                // News
                areaPerm: 'newsCreate',
                permissions: [
                    { perm: 'news', action: 'POST' },
                ]
            },
            {
                // DISTRIBUTOR STORES / CUSTOMER STORES / CUSTOMER STOCK  customer-stock/items
                areaPerm: 'customerStockItemsRead',
                permissions: [
                    { perm: 'customer-stock/items', action: 'GET' },
                ],
                validator: () => this.sharedService.user.representative === 'customer' &&
                        !!this.sharedService.user.representsCustomer.customerStockActive,
            },
            {
                areaPerm: 'customerStockItemsReadAs', // customer stock for AS
                permissions: [
                    { perm: 'customer-stock/items', action: 'GET' },
                ],
                validator: () => this.sharedService.user.representative === 'AS',
            },
            {
                areaPerm: 'erpDataConsistencyCheck',
                permissions: [
                    { perm: 'c-check', action: 'GET'}
                ]
            },
            {
                areaPerm: 'sentEmails',
                permissions: [
                    { perm: 'sent-emails', action: 'GET'}
                ]
            },
            {
                // CATALOGUE
                areaPerm: 'cataloqueRead',
                permissions: [
                    { perm: 'products', action: 'GET' },
                    { perm: 'products-reduced/1', action: 'GET' },
                    { perm: 'products-reduced/2', action: 'GET' },
                    { perm: 'products-reduced/3', action: 'GET' }
                ]
            },
            {
                // CATALOGUE
                areaPerm: 'purchaseCatalogueRead',
                permissions: [
                    { perm: 'products-reduced/4', action: 'GET' }
                ]
            },
            {
                // RFQ
                areaPerm: 'rfqRead',
                permissions: [
                    { perm: 'rfq', action: 'GET' },
                ]
            },
            {
                // Purchase RFQ
                areaPerm: 'purchaseRfqRead',
                permissions: [
                    { perm: 'prfq', action: 'GET' }
                ]
            },
            {
                // ORDERS
                areaPerm: 'ordersRead',
                permissions: [
                    { perm: 'orders', action: 'GET' },
                    { perm: 'orders-reduced', action: 'GET' },
                ]
            },
            {
                // DELIVERY ORDERS
                areaPerm: 'deliveryOrdersRead',
                permissions: [
                    { perm: 'delivery-orders', action: 'GET' },
                ]
            },
            {
                // INVOICES
                areaPerm: 'invoicesRead',
                permissions: [
                    { perm: 'invoices', action: 'GET' },
                ]
            },
            {
                // JOURNAL
                areaPerm: 'journalRead',
                permissions: [
                    { perm: 'journal', action: 'GET' },
                ]
            },
            {
                // MONITORING
                areaPerm: 'monitoringRead',
                permissions: [
                    { perm: 'monitoring', action: 'GET' },
                ]
            },
            {
                // AS CUSTOMERS
                areaPerm: 'authorizedSellerCustomersRead',
                permissions: [
                    { perm: 'authorized-seller-customers', action: 'GET' },
                ]
            },
            {
                // AS/customer/ USER ADMINISTRATION
                areaPerm: 'usersAdmin',
                permissions: [
                    { perm: 'users', action: 'POST' }
                ]
            },
            {
                // representative override for user administration
                // Allows AP user to administer customer users
                areaPerm: 'repreOverrideUserAdmin',
                permissions: [
                    { perm: 'customers/for-repre-override-area/userAdmin', action: 'GET' }
                ]
            },
            {
                // BASKET - current order
                areaPerm: 'ordersCreate',
                permissions: [
                    { perm: 'orders', action: 'POST' },
                    { perm: 'preorders', action: 'POST' }
                ]
            },
            {
                // SHIPMENTS
                areaPerm: 'shipmentsRead',
                permissions: [
                    { perm: 'shipments', action: 'GET' },
                ]
            },
            {
                // AS stock
                areaPerm: 'asStockManage',
                permissions: [
                    { perm: 'manual-stock/items', action: 'GET' },
                    { perm: 'manual-stock/items', action: 'POST' },
                    { perm: 'manual-stock/items', action: 'PUT' }
                ]
            },
            {
                areaPerm: 'PriceListUpdate',
                permissions: [
                    { perm: 'price-lists', action: 'GET' }
                ]
            },
            {
                areaPerm: 'suppliersRead',
                permissions: [
                    { perm: 'suppliers', action: 'GET' }
                ]
            },
            {
                areaPerm: 'controllingRead',
                permissions: [
                    { perm: 'controlling', action: 'GET' }
                ]
            },
            {
                areaPerm: 'ProductsExport',
                permissions: [{ perm: 'ProductsExport', action: 'GET' }],
            },
        ];

    constructor(
        private sharedService: SharedService
    ) {
    }

    public hasPermission(areaPerm: AreaPerm): boolean {
        let result = false;
        const areaPermDef = this.areaPermDef.filter(item => item.areaPerm === areaPerm)[0];
        if (areaPermDef) {
            areaPermDef.permissions.forEach(permissions => {
                if (this.sharedService.hasPermission(permissions.perm, permissions.action)) {
                    result = true;
                }
            });
            if (areaPermDef.validator) {
                result = result && areaPermDef.validator();
            }
        }
        return result;
    }

}
