/**
 * Dialog for basket item splitting
 */
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { LocalDatePipe } from '../../../locale.pipes.module';
import { GridAvailabilityRendererComponent } from '../../tableComponent/gridRendererComponents/gridAvailabilityRendererComponent/grid.availability.renderer.component'
import { BasketService } from '../../../services/basket/basket.service'
import { FormService } from '../../../services/form.service';
import { TableService } from '../../../services/table.service';

// Set date D+180 for items which are not available
// let DELIVERY_DATE_FOR_NOT_AVAILABLE_AMOUNT = new Date();
// DELIVERY_DATE_FOR_NOT_AVAILABLE_AMOUNT.setDate(DELIVERY_DATE_FOR_NOT_AVAILABLE_AMOUNT.getDate() + 180);

@Component({
    selector: 'app-split-basket-item-dialog',
    templateUrl: 'split.basket.item.dialog.component.html',
    styleUrls:  ['./split.basket.item.dialog.component.scss']
})
export class SplitBasketItemDialogComponent implements OnInit {
    doneSubject: Subject<any>;
    params: any;
    basketItem: any;
    hideAvailabilityState = false;
    busy = false;

    constructor(public bsModalRef: BsModalRef,
                public translate: TranslateService,
                public sharedService: SharedService,
                private toastService: ToastService,
                private basketService: BasketService,
                private formService: FormService,
                private tableService: TableService
    ) {}

    ngOnInit() {
        setTimeout(() => {
            // Make a copy of the basket item
            this.basketItem = Object.assign({}, this.params);
            this.params.availabilityFulfillings.forEach(item => {
                item.amountPackages = Math.floor(item.amount / this.basketItem.amountInPacking);
            });
            this.basketItem.availabilityFulfillings =
                this.params.availabilityFulfillings.map(item => {
                    return Object.assign({}, item, {
                        deliveryDate     : this.formService.getClosestDeliveryDate(true, 1, item.deliveryDate).toDate(), // move the date to the closest work day if necessary
                        availabilityState: 'COMPLETELY_FULFILLED'
                    });
                });
            if (this.basketItem.availabilityState === 'PARTIALLY_FULFILLED') {
                this.basketItem.availabilityFulfillings.push({
                    deliveryDate     : null,
                    availabilityState: 'PARTIALLY_FULFILLED',
                    amountPackages   : (this.basketItem.amountMissing) / this.basketItem.amountInPacking,
                    amount           : this.basketItem.amountMissing
                });
            }

            // Move the date to the closest work day if necessary
            // DELIVERY_DATE_FOR_NOT_AVAILABLE_AMOUNT = this.formService.getClosestDeliveryDate(true, 1, DELIVERY_DATE_FOR_NOT_AVAILABLE_AMOUNT).toDate();

            this.hideAvailabilityState = this.tableService.isColumnSuppressed('basketItems', 'availabilityState');
        }, 0);
    }

    split() {
        this.busy = true;
        this.basketService.splitOrderItem(this.basketItem.id, this.basketItem.availabilityFulfillings).subscribe(
            data => {
                this.doneSubject.next();
                this.bsModalRef.hide();
                this.busy = false;
            },
            err => {
                console.log(err);
                this.busy = false;
            }
        );
    }
}
