import { Component }       from '@angular/core';
import { BusinessConditionsService } from 'app/services/businessConditions/business.conditions.service';

@Component({
    selector: 'business-conditions',
    templateUrl: 'business.conditions.component.html',
})
export class BusinessConditionsComponent { 
    constructor(
        private businessConditionsService: BusinessConditionsService
    ) {
    }

    download() {
        this.businessConditionsService.downloadBusinessConditions();
    }
}