import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';

@Injectable()
export class DeliveryOrderGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'eCommProductId', name: "PRODUCT_ID", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'postsCount', name: "DISCUSSION", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'orderNumberEComm', name: "ORDER_ID", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'deliveryAmount', name: "AMOUNT_SHIPMENT", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'packing', name: "PACKING", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'rowWeight', name: "WEIGHT_KG", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'rowPrice', name: "TOTAL_VALUE", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'itemState', name: "STATUS", checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'itemNote', name: "SPECIFICATION", checked: true, orderBy: false, orderDirection: 'ASC', width: 100}
        ];

        original = this.tableService.filterSuppressColumns(original, 'deliveryOrderItems');
        let restored = this.sharedService.user.preferences['delivery-orderTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-gray-darker';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'delivery-orderTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let this_ = this;
        let colDefs:any = [
            {
                headerName: this_.translateService.instant(this_.getColumn('eCommProductId').name), 
                field: "eCommProductId",
                width: this_.getColumn('eCommProductId').width,
                suppressSizeToFit: true, 
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: '', 
                field: "postsCount",
                width: this_.getColumn('postsCount').width,
                maxWidth: 50,
                minWidth: 50,
                cellRendererFramework: GridCommentRendererComponent,
                cellRendererParams: {itemArea: 'delivery-orders', idAttrName: 'deliveryOrderId'},
            },            
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNumberEComm').name), 
                field: "orderNumberEComm",
                width: this_.getColumn('orderNumberEComm').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryAmount').name), 
                field: "deliveryAmount",
                width: this_.getColumn('deliveryAmount').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packing').name), 
                field: "packing",
                width: this_.getColumn('packing').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('PACKING_' + params.data.packing);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowWeight').name), 
                field: "rowWeight",
                width: this_.getColumn('rowWeight').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('unitPrice').name), 
                field: "unitPrice",
                minWidth: 80, 
                width: this_.getColumn('unitPrice').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridPriceRendererComponent
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowPrice').name), 
                field: "rowPrice",
                width: this_.getColumn('rowPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemState').name), 
                field: "itemState",
                width: this_.getColumn('itemState').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_ITEM_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        green: 'P'
                    }
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemNote').name), 
                field: "itemNote",
                width: this_.getColumn('itemNote').width,
                cellStyle: {'text-align': 'left'}
            }
        ];
        colDefs = this.tableService.filterSuppressColumns(colDefs, 'deliveryOrderItems');
        return colDefs;
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        return [];
    }

}

