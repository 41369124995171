import { Component } from '@angular/core';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'app-footer-links',
    templateUrl: 'footer.links.component.html',
    styleUrls: ['./footer.links.component.scss']
})
export class FooterLinksComponent {

    constructor(public sharedService: SharedService) {
    }
}
