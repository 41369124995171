import { Injectable, ElementRef } from '@angular/core';
import { SharedService } from '../shared.service';

@Injectable()
export class ChatService {

    chatIframe: ElementRef;

    constructor(private sharedService: SharedService) {}

    /**
     * Sets chat iframe element
     */
    setChatIframeElement(element: ElementRef) {
        this.chatIframe = element;
    }

    /**
     * Sends the specified data to the chat iframe
     */
    sendToChat(data) {
        if (this.sharedService.apParams.showLivechat) {
            if (this.chatIframe) {
                const iframeWin = this.chatIframe.nativeElement.contentWindow;
                iframeWin.postMessage(data, 'https://magenda.sk');
            }
        }
    }
}
