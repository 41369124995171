import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { CatalogueService } from './catalogue/catalogue.service';
import { NewsService } from './news/news.service';

@Injectable()
export class CleanUpService {
    constructor (private sharedService: SharedService,
                 private catalogueService: CatalogueService,
                 private newsService: NewsService) {

    }

    /**
     * Cleans up cached data of services
     * e.g. cached product categories (because translations are from server)
     * ...
     */
    cleanUp() {
        this.catalogueService.clean();
        this.newsService.clean();
    }
}
