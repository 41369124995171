import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    selector: 'app-plus-click-cell',
    template: `	<div class="clickable" (click)="onClick()" style="font-size: 20px; margin-top: -2px;">
    				<i class="fa fa-plus-square fnt-primary"></i>
    			</div>`
})
export class GridPlusClickRendererComponent implements AgRendererComponent {
    params: any;

    constructor() {}

    agInit(params: any): void {
        this.params = params;
    }

    onClick() {
        this.params.onClick(this.params)
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
