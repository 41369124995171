import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ProjectPriceList, Customer } from '../../../model/customer.model';
import { SharedService } from '../../../services/shared.service';
import { LanguageService } from '../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from '../../../services/customer/customers.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'customer',
  templateUrl: 'customer.component.html'
})
export class CustomerComponent implements OnInit  {
    @ViewChild('customerForm') customerForm: any;
    public customer: Customer;
    private customerOld: Customer;
    public busy: boolean;

    public allAvailableGroups: {id: string, name: string, nonERPCustomer: boolean}[] = []; // all groups available
    private allAvailableGroupsOld: {id: string, name: string, nonERPCustomer: boolean}[] = []; // all groups available
    private allAvailableGroupsByERP: {id: string, name: string, nonERPCustomer: boolean}[] = [];
    public addGroupSelected = {id: '', name: 'ADD_TO_SECURITY_GROUP'};

    public newPriceList: ProjectPriceList;
    public newPriceListVisible: boolean;
    private pricelistsToDelete: ProjectPriceList[] = [];  // priceLists to be deleted
    public allPriceListCodes: string[];                 // all pricelist codes for the unique check
    public isERPCustomer: boolean;
    public isNewCustomer: boolean;
    public createAdminUser = true;
    public isAttrHidden: Record<string, boolean> = {};

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    public isEditing(): boolean {
        return this.customerForm && (this.customerForm.form.dirty || !!this.newPriceListVisible);
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private languageService: LanguageService,
        private translate: TranslateService,
        private customersService: CustomersService,
    ) {
        this.newPriceList = { id: null, priceListName: '', priceListCode: '', priceListCurrency: this.sharedService.priceListCurrencies[0], valid: 1 };
        
        const optionallyHiddenAttrs = this.customersService.getOptionallyHiddenAttrs();
        this.isAttrHidden = optionallyHiddenAttrs.reduce((acc, attr) => ({
            ...acc,
            [attr]: this.customersService.isColumnHidden(attr),
        }), {});
    }

    ngOnInit() {
        if (this.route.snapshot.params['id'] > 0) { // existing customer
          this.isNewCustomer = false;
            this.customersService.getCustomer(this.route.snapshot.params['id']).subscribe(data => {
                this.customer = data;
                this.customerOld = Object.assign({}, data);
                this.customerOld.notActivatedAdminUser = data.notActivatedAdminUser ? Object.assign({}, data.notActivatedAdminUser) : null;
                this.customerOld.projectPriceLists     = [...data.projectPriceLists];  // array copy
                this.isERPCustomer = this.customer.isERPCustomer || this.customer.isERPCustomer === 1 ? true : false;
                this.customer.customerStockActive = data.customerStockActive ? data.customerStockActive : 0;
                this.setAllPriceListCodes();
                this.getAvailableCustomerOptions(this.isERPCustomer);
            }, err => {
                console.log(err);
                this.busy = false;

            });
        } else { // id == 0 it is new customer
            this.isNewCustomer = true;
            this.getAvailableCustomerOptions(true);
            this.customer = new Customer(
                0, this.sharedService.user.representsAuthorizedSeller.id, '', '', '', '', '', '', '', '', 'EUR', 0,
                '', '', '', '', '', 1, null, '', [], true, 0, 0, 0, 0
            );
            this.isERPCustomer = true;
            this.customer.permissionGroups  = [];
            this.customer.priceListCurrency = this.sharedService.priceListCurrencies[0];
            this.customer.customerAdminLanguage = this.translate.currentLang.toUpperCase();
            this.customerOld = Object.assign({}, this.customer);
            this.setAllPriceListCodes();
        }

        this.languageService.getLanguageObservable().subscribe((res: string) => {
            if (this.route.snapshot.params['id'] <= 0) { // new customer
                this.customer.customerAdminLanguage = res.toUpperCase();
            }
        });
    }

    getAvailableCustomerOptions(isERPCustomer: boolean) {
        this.customersService.getAvailableCustomerOptions().subscribe(data => {
            this.allAvailableGroups = data.groups;
            this.allAvailableGroupsOld = this.allAvailableGroups.map(item => item);
            if (this.route.snapshot.params['id'] > 0) { // existing customer
                this.getCustomerPermissionGroups();
            }
            // this.allAvailableGroups = this.allAvailableGroups.filter(group => {return data.indexOf(group.id) < 0; });
            this.allAvailableGroups = this.allAvailableGroupsOld.filter(row => isERPCustomer ? true : (row.nonERPCustomer));
        }, err => {
            console.log(err)
        });
    }

    getCustomerPermissionGroups() {
        this.customersService.getCustomerPermissionGroups(this.route.snapshot.params['id']).subscribe(data => {
            this.customer.permissionGroups = data;
            this.customerOld.permissionGroups = [...this.customer.permissionGroups];

            this.allAvailableGroups = this.allAvailableGroups.filter(row =>
                    this.customer.permissionGroups.filter(perm => perm === row.id).length === 0
            );
        }, err => {
            console.log(err)
        });
    }

    onSelectCurrency(event, customerForm: FormGroup) {
        this.customer.priceListCurrency = event.selected;
        customerForm.markAsDirty();
    }

    onSelectLanguageAdmin(event) {
        this.customer.customerAdminLanguage = event.selected;
    }

    onSelectCurrencyNewPriceList(event) {
        this.newPriceList.priceListCurrency = event.selected;
    }

    /**
     * Project priceLists
     */
    getEmptyPriceListRow() {
        this.setAllPriceListCodes();
        return { id: null, priceListName: '', priceListCode: '', priceListCurrency: this.sharedService.priceListCurrencies[0], valid: 1 };
    }

    addNewPriceList() {
        this.customer.projectPriceLists.push(this.newPriceList);
        this.newPriceList = this.getEmptyPriceListRow();
        this.newPriceListVisible = false;
    }

    cancelNewPriceList() {
        this.newPriceListVisible = false;
        this.newPriceList = this.getEmptyPriceListRow();
    }

    deletePriceList(index: number, adminEmailForm: FormGroup) {
        if (this.customer.projectPriceLists[index].id !== null) {  // delete of existing priceList - we need to send it to the BE
            this.customer.projectPriceLists[index].valid = 0;
            this.pricelistsToDelete.push(this.customer.projectPriceLists[index]);
            adminEmailForm.markAsDirty();
        }
        this.customer.projectPriceLists.splice(index, 1);
        this.setAllPriceListCodes();
    }

    deleteAllPriceLists() {
      this.newPriceListVisible = false;
      this.newPriceList = this.getEmptyPriceListRow();
      this.customer.projectPriceLists = [];
      this.pricelistsToDelete = [];
      this.setAllPriceListCodes();
    }

    setAllPriceListCodes() {
        this.allPriceListCodes = [this.customer.priceListCode, ...this.customer.projectPriceLists.map(item => item.priceListCode)];
    }

    /**
     * Adds security group
     */
    onSelectAddGroup(event) {
        this.customer.permissionGroups.push(event.selected.id);
        this.allAvailableGroups = this.allAvailableGroups.filter(group => {return group.id !== event.selected.id; });
        this.customerForm.form.markAsDirty();
    }

    /**
     * Removes security group
     */
    removeFromGroup(groupId: string) {
        this.customer.permissionGroups = this.customer.permissionGroups.filter(id => {return groupId !== id; });
        this.customerForm.form.markAsDirty();
        const nonERPCustomerByID =  this.allAvailableGroupsOld.find(item => {return item.id === groupId; });
        this.allAvailableGroups.push({
            id: groupId,
            name: 'SECURITY_GROUP_' + groupId,
            nonERPCustomer: nonERPCustomerByID.nonERPCustomer
        });
    }

    /**
     * Removes all from security group
     */
    removeAllFromGroup() {
        this.customer.permissionGroups  = [...this.customerOld.permissionGroups];

        this.allAvailableGroups = this.allAvailableGroupsOld.filter(item => {
            return item.nonERPCustomer !== this.isERPCustomer && this.customer.permissionGroups.indexOf(item.id) < 0;
        } );
      // this.customer.permissionGroups  = [];
    }

    /**
     * Cancel changes in form
     */
    cancel(customerForm: FormGroup) {
        this.customer = Object.assign({}, this.customerOld);
        this.customer.projectPriceLists = [...this.customerOld.projectPriceLists];  // array copy
        // this.allAvailableGroups = [...this.allAvailableGroupsOld];
        this.pricelistsToDelete = [];
        this.isERPCustomer =  this.isNewCustomer || this.customer.isERPCustomer === true ||  this.customer.isERPCustomer === 1 ? true : false;
        if (!this.customer.customerStockActive || (this.isNewCustomer && this.customer.isERPCustomer)) {
            this.customer.customerStockActive = 0;
        }
        this.customer.permissionGroups  = [...this.customerOld.permissionGroups];
        // this.allAvailableGroups = this.allAvailableGroupsOld.filter(row => row.nonERPCustomer !== this.isERPCustomer);

        this.allAvailableGroups = this.allAvailableGroupsOld.filter(row => (row.nonERPCustomer !== this.isERPCustomer) &&
            this.customer.permissionGroups.filter(perm => perm === row.id).length === 0
        );

        // this.removeAllFromGroup();
      customerForm.markAsPristine();
    }

    /**
     * Save changes
     */
    save(customerForm: FormGroup) {
        this.busy = true;
        if (this.customer.id > 0) { // update existing
            const cleanUpForm = () => {
                this.customerOld = Object.assign({}, this.customer);
                customerForm.markAsPristine();
                this.busy = false;
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/customers'], {queryParams: this.sharedService.lastCustomersQueryParams});
            };
            console.log(this.customer);
            // Make diff customer vs. customerOld
            const params: any = Object.keys(this.customer)
                        .filter(key => ['permissionGroups', 'notActivatedAdminUser', 'projectPriceLists'].indexOf(key) === -1)
                        .reduce((acc, key) => this.customer[key] !== this.customerOld[key] ? Object.assign(acc, {[key]: this.customer[key]}) : acc, {});

            const projectPriceLists = [...this.pricelistsToDelete, ...this.customer.projectPriceLists.filter(item => item.id === null)];
            if (projectPriceLists.length > 0) {
                params.projectPriceLists = projectPriceLists;
            }

            params.isERPCustomer = this.isERPCustomer ? 1 : 0;

            if (this.customer.permissionGroups.length > 0) {
                params.permissionGroups = [...this.customer.permissionGroups];
            }

            if (Object.keys(params).length > 0) { // there is something to save
                this.customersService.updateCustomer(this.route.snapshot.params['id'], params).subscribe(() => {
                    cleanUpForm();
                }, err => {
                    console.log(err)
                    this.busy = false;
                });
            } else {
                cleanUpForm();
            }
        } else { // create new
            const params: any = {};
            for (const key in this.customer) {
                if (key !== 'id' && key !== 'url' && key !== 'notActivatedAdminUser') {
                    params[key] =  this.customer[key];
                }
            }
            params.isERPCustomer = this.isERPCustomer ? 1 : 0;
            this.customersService.createCustomer(params).subscribe(data => {
                this.busy = false;
                this.customerForm.form.markAsPristine();
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/customers'], {queryParams: this.sharedService.lastCustomersQueryParams});
            }, err => {
                console.log(err)
                this.busy = false;
            });
        }
    }

    /**
     * Resends invitation email to admin user
     */
    resendInvite(adminEmailForm: FormGroup) {
        this.customersService.resendInvite({id: this.customer.notActivatedAdminUser.id,
                email: this.customer.notActivatedAdminUser.email !== this.customerOld.notActivatedAdminUser.email ?
                this.customer.notActivatedAdminUser.email : null}).subscribe(data => {
            this.customerOld = Object.assign({}, this.customer);
            this.customerOld.notActivatedAdminUser = Object.assign({}, this.customer.notActivatedAdminUser);
            adminEmailForm.markAsPristine();
        }, err => {
            console.log(err)
        });
    }

    ReplaceAllAvailableGroups(erpCustomer: boolean) {
        this.isERPCustomer = erpCustomer;
        this.removeAllFromGroup();
        this.deleteAllPriceLists();
        this.customer.customerCode = null;

        // reset Distributor Stores to false if is not erpCustomer yet
        if (!erpCustomer) {
            this.customer.customerStockActive = 0;
        }
    }

    public setCustomerStockActive(value: number): void {
        if (this.customer.isERPCustomer) {
            this.customer.customerStockActive = value;
            this.customerForm.form.markAsDirty();
        }
    }

    public setProductsNotTakenNotification(value: number): void {
        if (this.customer.isERPCustomer) {
            this.customer.productsNotTakenNotification = value;
            this.customerForm.form.markAsDirty();
        }
    }

    public createAdminUserRadioClicked(create: boolean): void {
        if (create) {
            this.customer.customerAdminLanguage = this.translate.currentLang.toUpperCase();
        } else {
            this.customer.customerAdminEmail = '';
            this.customer.customerAdminLanguage = '';
        }
    }
}
