import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared.service';
import { TableService } from '../../services/table.service';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent, GridInputEditorComponentParams } from '../../components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridDistributorStoresActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDistributorStoresActionsRendereComponent/grid.distributor.stores.actions.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { PermissionsService } from '@app/services/permissions.service';
import { ColDef } from 'ag-grid-community';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';
import { SuppressColumnsAreas } from '@app/model/suppressColumnsAreas.model';

export type StockGridServiceAreas = 'as-stock' | 'customer-stock';

type Col = 'selected' | 'customerName' | 'brandCode' | 'eCommProductId' | 'amount' | 'actions';

@Injectable()
export class StockGridService {
    itemsEditedIds: any = {};
    subjectSelected: Subject<any>;
    updateStockItemsFn: Function;
    deleteStockItemsFn: Function;
    private areaConverter = new Map<StockGridServiceAreas, SuppressColumnsAreas>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private permissionsService: PermissionsService
    ) {
        this.subjectSelected = new Subject<any>();
        this.updateStockItems = this.updateStockItems.bind(this);
        this.deleteStockItems = this.deleteStockItems.bind(this);
        this.areaConverter.set('as-stock', 'asStock');
        this.areaConverter.set('customer-stock', 'customerStock');
    }

    public getColumns(forceOriginal: boolean, area: StockGridServiceAreas): any[] {
        let original = [
            {id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30 },
            {id: 'customerName', name: 'CUSTOMER', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            {id: 'brandCode', name: 'BRAND', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 60 },
            {id: 'eCommProductId', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 300},
            {id: 'amount', name: 'AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 300},
            {id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 130}
        ];
        original = original.filter(row => this.isColAvailable(row.id as Col));
        original = this.tableService.filterSuppressColumns(original, this.areaConverter.get(area));
        const restored = this.sharedService.user.preferences[`${area}TableColumns`];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId, area: StockGridServiceAreas) {
        const columns = this.getColumns(false, area);
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }
    /**
     * @area 'as-stock' / 'customer-stock'
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage: number, area: StockGridServiceAreas, subjectItemsSelected: Subject<any>, subjectReloadCurrent: Subject<any>) {
        const this_ = this;

        // function headerClassFunc(params) {
        //     return 'bkg-primary fnt-white';
        // }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, `${area}TablePageSize`, this.headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs(area, subjectItemsSelected, subjectReloadCurrent);
        return gridOptions;
    }

    /**
     * @area 'as-stock' / 'customer-stock'
     */
    getColumnDefs(area: StockGridServiceAreas, subjectItemsSelected: Subject<any>, subjectReloadCurrent: Subject<any>) {
        const this_ = this;
        const colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences[`${area}ItemsSelection`],
                    // observable: this_.getSelectedSubject(),
                    observable: this_.subjectSelected,
                    editedIdsObj: this_.itemsEditedIds
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this_.sharedService.user.preferences[`${area}ItemsSelection`],
                    // observable: this_.getItemsSelectedSubject(),
                    observable: subjectItemsSelected,
                    editedIdsObj: this_.itemsEditedIds
                },
                headerClass: this.headerClassFunc,
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('customerName', area).name),
                field: 'customerName',
                width: this_.getColumn('customerName', area).width,
                minWidth: 70
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('brandCode', area).name),
                field: 'brandCode',
                width: this_.getColumn('brandCode', area).width,
                minWidth: 70
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('eCommProductId', area).name),
                field: 'eCommProductId',
                width: this_.getColumn('eCommProductId', area).width,
                // maxWidth: 300,
                cellStyle: {'text-align': 'left'}
            },
        ];
        
        // amount
        const amount: ColDef = {
            headerName: this_.translateService.instant(this_.getColumn('amount', area).name),
            field: 'amount',
            width: this_.getColumn('amount', area).width,
            // maxWidth: 300,
            cellStyle: {'text-align': 'right'},
            suppressSizeToFit: true,
            editable: () => !this.permissionsService.hasPermission('customerStockItemsReadAs'),
            cellRendererFramework: GridInputRendererComponent,
            cellEditorFramework: GridInputEditorComponent,
        };
        const amountCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this_.itemsEditedIds,
            allowMultipleEdit: true,
            getEditable: (params) => !this.permissionsService.hasPermission('customerStockItemsReadAs'),
            isColValid: this_.isColValid,
            number: true,
            decimal: false
        };
        amount.cellRendererParams = amountCellRendererParams;

        const amountCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this_.itemsEditedIds,
            isColValid: this_.isColValid,
            inputType: 'number',
        };
        amount.cellEditorParams = amountCellEditorParams;
        colDefs.push(amount);

        const actions: ColDef = {
            headerName: this.translateService.instant(this.getColumn('actions', area).name),
            headerComponentParams: {
                router: this.router,
                activatedRoute: this.activatedRoute
            },
            headerClass: this.headerClassFunc,
            field: 'actions',
            width: this.getColumn('actions', area).width,
            minWidth: this.getColumn('actions', area).width,
            maxWidth: this.getColumn('actions', area).width,
            cellStyle: { 'text-align': 'center' },
            cellRendererFramework: GridDistributorStoresActionsRendererComponent,
            cellRendererParams: {
                editedIdsObj: this_.itemsEditedIds,
                showDelete: true,
                subjectReloadCurrent: subjectReloadCurrent,
                updateStockItems: this_.updateStockItems,
                deleteStockItems: this_.deleteStockItems
            },
            sortable: false,
        };
        colDefs.push(actions);

        return colDefs.filter(row => this.isColAvailable(row.field as Col));
    }

    setUpdateStockItemsFn(fn: Function) {
        this.updateStockItemsFn = fn;
    }

    updateStockItems(items: string[], data: {}, query?: any) {
        return this.updateStockItemsFn(items, data, query);
    }

    setDeleteStockItemsFn(fn: Function) {
        this.deleteStockItemsFn = fn;
    }

    deleteStockItems(items: string[], query?: any) {
        return this.deleteStockItemsFn(items, query);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'customerName', name: 'CUSTOMER', type: TableFilterItemTypes.text, maxLength: 100, 
                value: '', operator: TableFilterItemOperators.likeBoth
            },
            { 
                id: 'brandCode', name: 'BRAND', type: TableFilterItemTypes.multiselect, value: '', 
                allLabel: 'CATALOGUE_ALL',
                values: this.sharedService.brands.map(brand => ({ id: brand.code, name: brand.code })) 
            },
            {
                id: 'eCommProductId', name: 'PRODUCT_ID', type: TableFilterItemTypes.text, maxLength: 100, 
                value: '', operator: TableFilterItemOperators.likeBoth
            },
        ]

        return filterItems.filter(item => this.isColAvailable(item.id as Col));
    }
     /**
     * Validates one column (item field)
     */
    isColValid(field: string, value: any, row: any): boolean {
        const validationMap = {
            amount: val => val ? parseInt(val.toString().replace(' ', ''), 10) > 0 : false,
        };

        return (field in validationMap) ? validationMap[field](value) : true;
    }

    private isColAvailable(col: Col): boolean {
        const map = new Map<Col, () => boolean>();

        map.set('selected', () => !this.permissionsService.hasPermission('customerStockItemsReadAs'));
        map.set('customerName', () => this.permissionsService.hasPermission('customerStockItemsReadAs'));
        map.set('brandCode', () => this.sharedService.brands.length > 1);
        map.set('actions', () => !this.permissionsService.hasPermission('customerStockItemsReadAs'));

        return map.has(col) ? map.get(col)() : true;
    }

}
