import { BaseTSelection, CellChange, OriginalValue } from './table.model';

export interface PackingOption {
    'priceCoefficient': number;
    'packing': string,
    'amount': number
}

export enum AvailabilityState {
    COMPLETELY_FULFILLED = 'COMPLETELY_FULFILLED',
    LATELY_FULFILLED = 'LATELY_FULFILLED',
    PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED'
}

export interface AvailabilityFulfillings {
    'deliveryDate': Date,
    'amount': number
}

export enum BasketActions {
    ShowDistributorStores = 'ShowDistributorStores',
    Split = 'Split',
    Duplicate = 'Duplicate',
    CorrectDeliveryDate = 'CorrectDeliveryDate',
    CorrectAmountOrdered = 'CorrectAmountOrdered',
    Delete = 'Delete'
}

export interface BasketItem {
    'id': number,
    'amountOrdered': number,
    'deliveryDate': Date,
    'packing': string,
    'eCommProductId': string,
    'eCommProductIdOld': string | null,
    'eCommProductIdNew': string | null,
    'brandCode': string,
    'defaultPacking': string,
    'amountInPacking': number,
    'amountPackages': any,  // can be formatted number
    'weight': number,
    'rowWeight': number,
    'unitPrice': number,
    'rowPrice': number,
    'currency': string,
    'availabilityState': AvailabilityState,
    'availabilityFulfillings': AvailabilityFulfillings [],
    'amountMissing': number,
    'minDeliveryDate': Date,
    'valid': number,
    'nonCancelable': number,
    'packingOptions': PackingOption[],
    // attributes added locally
    url?: string,
    amountPackagesNotRounded?: string, // formetted number
    originalValues?: OriginalValue[]
    actions?: {
        [key: string]: boolean // [BasketActions.Split]: true
    },
    _disabled: boolean, // for temporary disable while batch editing with some rows
                        // updated and some not
    productIconClass?: string // as product.iconClass - used in a case of a product name change
}

export interface BasketItemsResponse {
    rows: BasketItem[];
    totalAmountOrdered: number;
    totalCount: number;
    totalInvalidCount: number;
    totalPrice: number;
    totalWeight: number;
    // added locally
    totalWeightCurrent?: number;
    totalPriceCurrent?: number;
    totalAmountOrderedCurrent?: number;
}

export interface ProductsOfCurrentOrderSelection extends BaseTSelection<BasketItem> {
    totalWeight?: number;
    totalValue?: number;
    totalAmountOrdered?: number;
}