import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { GridNumberRendererComponent } from '@components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridLinkRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridGenericRowActionsRendererComponent, GridGenericRowActionsRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';
import { PriceListService } from '../price-list.service';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, Subject, Subscription } from 'rxjs';
import { QuestionDialogService, QuestionDialogResult } from '@components/questionDialogComponent/question.dialog.service';
import { AppAreas } from '@app/model/appArea.model';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent, GridInputEditorComponentParams } from '@app/components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { CellChange, EditedIdsObject } from '@app/model/table.model';
import { GridHeaderActionsRendererComponent, GridHeaderActionsRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';
import { map } from 'rxjs/operators';
import { PriceList } from '@app/model/priceList.model';

@Injectable()
export class PriceListsGridService extends BaseGridService implements OnDestroy {
    public reloadCurrentGridPage$ = new Subject<void>();
    public editedIdsObj: EditedIdsObject = {};
    private cellChanged$: Subject<CellChange> = new Subject<CellChange>();
    private cancelRows$: Subject<any> = new Subject<any>();
    private subscriptions: Subscription[] = [];

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private priceListService: PriceListService,
        private questionDialogService: QuestionDialogService
    ) {
        super(tableService, sharedService);

        this.subscriptions.push(this.cancelRows$.subscribe(() => this.reloadCurrentGridPage$.next()));
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['price-lists'], // 'price-lists',
            gridNameCamel: 'priceLists',
            columnList: [
                { id: 'priceListCode', name: 'CUSTOMER_PRICE_LIST_CODE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 120},
                { id: 'currency', name: 'CURRENCY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                { id: 'note', name: 'NOTE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'itemsCount', name: 'ITEMS_COUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('priceListCode').name),
                field: 'priceListCode',
                width: this.getColumn('priceListCode').width,
                minWidth: this.getColumn('priceListCode').width,
                cellRendererFramework: GridLinkRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('currency').name),
                field: 'currency',
                width: this.getColumn('currency').width,
                minWidth: this.getColumn('currency').width,
                cellStyle: {'text-align': 'left'}
            },
        ];
        
        // note
        const noteCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            editable: true,
            number: false,
            decimal: false,
            maxWidth: 'auto',
            allowMultipleEdit: true,
            cellChanged$: this.cellChanged$
        };
        const noteCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            inputType: 'string',
            maxLength: '200',
            maxWidth: 'auto',
            cellChanged$: this.cellChanged$
        };
        const note: ColDef = {
            headerName: this.translateService.instant(this.getColumn('note').name),
            field: 'note',
            width: this.getColumn('note').width,
            minWidth: this.getColumn('note').width,
            cellStyle: {'text-align': 'left'},
            cellRendererFramework: GridInputRendererComponent,
            cellRendererParams: noteCellRendererParams,
            cellEditorFramework: GridInputEditorComponent,
            cellEditorParams: noteCellEditorParams,
            editable: true
        };
        colDefs.push(note);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('itemsCount').name),
                field: 'itemsCount',
                width: this.getColumn('itemsCount').width,
                minWidth: this.getColumn('itemsCount').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                cellStyle: {'text-align': 'right'}
            },
        ]);

        // actions
        const actionsHeaderComponentParams: GridHeaderActionsRendererComponentParams = {
            isRowValid: _ => true,
            editedIdsObj: this.editedIdsObj,
            cancel$: this.cancelRows$,
            save: (items) => this.updateRows(items)
        };
        const actions: ColDef = {
            headerName: this.translateService.instant('ACTIONS'),
            field: 'actions',
            width: this.getColumn('actions').width,
            minWidth: this.getColumn('actions').width,
            maxWidth: this.getColumn('actions').width,
            headerClass: (params) => this.headerClassFunc(params) + ' active-on-edited-rows',
            headerComponent: null,
            headerComponentFramework: GridHeaderActionsRendererComponent,
            headerComponentParams: actionsHeaderComponentParams,
            cellRendererFramework: GridGenericRowActionsRendererComponent,
            cellRendererParams: this.getActionsCellRendererParams(),
            sortable: false,
        };
        colDefs.push(actions);

        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    private getActionsCellRendererParams(): GridGenericRowActionsRendererComponentParams {
        return {
            rowActionsDef: [
                {
                    id: 'exportCSV',
                    type: 'ICON_ONLY',
                    iconClass: 'fa fa-download',
                    popover: 'EXPORT_TO_CSV',
                    method: iCellRendererParams => this.priceListService.exportCSV(
                        iCellRendererParams.data.priceListCode, iCellRendererParams.data.currency)
                },
                {
                    id: 'delete',
                    type: 'ICON_ONLY',
                    iconClass: 'fa fa-trash',
                    popover: 'DELETE_SELECTED',
                    method: iCellRendererParams => this.deletePriceList(iCellRendererParams)
                }
            ],
            editedIdsObj: this.editedIdsObj,
            cellChanged$: this.cellChanged$,
            save: (item) => this.updateRows([item]),
            cancel$: this.cancelRows$,
        };
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'priceListCode', name: 'CUSTOMER_PRICE_LIST_CODE', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'currency', name: 'CURRENCY', type: TableFilterItemTypes.multiselect,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'CZK', name: 'CZK'},
                    {id: 'EUR', name: 'EUR'},
                    {id: 'PLN', name: 'PLN'},
                    {id: 'USD', name: 'USD'},
                    {id: 'UAH', name: 'UAH'},
                ],
            },
        ]
        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

    private deletePriceList(iCellRendererParams: ICellRendererParams): void {
        this.questionDialogService.confirm(
            {
                message: 'PRICE_LIST_DELETE_TITLE',
                question: 'PRICE_LIST_DELETE_QUESTION',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(answer => {
            if (answer === QuestionDialogResult.Confirm) {
                this.priceListService.deletePriceList(iCellRendererParams.data.priceListCode, iCellRendererParams.data.currency)
                .subscribe(() => {
                    this.reloadCurrentGridPage$.next();
                });
            }
        });
    }

    private updateRows(priceListItems: PriceList[]): Observable<unknown> {
        return this.priceListService.updatePriceLists(priceListItems.map(item =>
                ({ priceListCode: item.priceListCode, currency: item.currency, note: item.note })
            ))
            .pipe(map(response => {
                this.reloadCurrentGridPage$.next();
                return response;
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())        ;
    }
}
