import { Component, DoCheck } from '@angular/core';
import { IHeaderAngularComp } from "ag-grid-angular";
import { Subject, Observable } from 'rxjs';
import { ToastService } from './../../../../services/toastService/toast.service';
import { SharedService } from './../../../../services/shared.service';
import { IHeaderParams, RowNode } from 'ag-grid-community';
import { BatchItemsUpdateResponse, EditedIdsObject } from '@app/model/table.model';

// Just like GridHeaderActionsRendererComponent but allows batch commit even in a case
// when there is an invalid line among changed lines. Those lines are then skipped
// and not updated - they are leftasedited and not commited/rollbacked.
export interface GridHeaderActions2RendererComponentParams {
    editedIdsObj: EditedIdsObject;
    isRowValid: (paramsData: any) => boolean;
    save: (rowsData: any[]) => Observable<any>;
    cancel$?: Subject<any>;
    reload$: Subject<any>;
}

interface MyParams extends IHeaderParams, GridHeaderActions2RendererComponentParams {}

@Component({
    selector: 'actions-renderer',
    template: ` <div class="ag-header-group-cell-label">
                    <span *ngIf="!showSave && !showCancel">{{params.displayName}}</span>
                    <div style="font-size: 20px; margin-top: -2px;" *ngIf="showSave || showCancel"> 
                        <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="showSave"></i>
                        <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel()" *ngIf="showCancel"></i>
                    </div>
                </div>`
})
export class GridHeaderActions2RendererComponent implements IHeaderAngularComp, DoCheck {
    public params: MyParams;

    public showSave: boolean;
    public showCancel: boolean;

    constructor(
        private toastService: ToastService,
        private sharedService: SharedService
    ) {
    }

    agInit(params: MyParams): void {
        this.params = params;
    }

    public ngDoCheck(): void {
        this.showSave = this.getEditedCount() > 1;
        this.showCancel = this.showSave;
    }

    public save(): void {
        const rowsToSave: any[] = []; // edited and valid rows
        const nodesToSave: RowNode[] = []; // edited and valid nodes
        const rowsNotToSave: any[] = []; // edit and not valid

        this.params.api.forEachNode(node => {
            const row = node.data;

            if (this.isNewRow(row)) {
                rowsToSave.push(row);
                nodesToSave.push(node);
            } else if (this.params.editedIdsObj[row.id]) { // edited row
                if (this.params.isRowValid(row)) {
                    rowsToSave.push(row);
                    nodesToSave.push(node);
                } else {
                    rowsNotToSave.push(row);
                }
            }
        });

        if (rowsToSave.length === 0) {
            return;
        }

        this.params.save(rowsToSave).subscribe((res: BatchItemsUpdateResponse) => {
            if (res.errors && res.errors.length > 0) {
                res.errors.forEach(() => {
                    this.toastService.addError(res.errors.length + ' ' + this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS') + ' ' + (res.successCount + res.errors.length) + ' ' + this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS_CANNOT_BE_UPDATED'));
                });
            }
            this.showSave = false;
            this.showCancel = false;

            const editedCount = this.getEditedCount();

            rowsToSave.forEach(row => {
                // Replace originalValues in the saved rows
                row.originalValues.forEach(item => {
                    item.value = row[item.colId];
                });
                // Cancle edited attrs
                row.edited = false;
                delete this.params.editedIdsObj[row.id];
                // Set temporarily disabled
                if (rowsNotToSave.length > 0) {
                    row._disabled = true;
                }
            });

            if (rowsNotToSave.length === 0) { // all edited rows were valid and saved
                // if (editedCount > 0) {
                    this.params.api.redrawRows();
                // } else {
                //     // Does this ever happen???
                //     if (this.params.cancel$) {
                //         this.params.cancel$.next();
                //     }
                // }
                if (this.params.reload$) {
                    this.params.reload$.next();
                }
            } else { // there are some edited but invalid rows which were not saved
                // We need to refresh the saved rows to set them disabled class
                this.params.api.redrawRows({rowNodes: nodesToSave});
            }

        });
    }

    public cancel(): void {
        this.showSave = false;
        this.showCancel = false;

        // Erase editedIdsObj's properties and keep the object reference
        for (const id in this.params.editedIdsObj) {
            delete this.params.editedIdsObj[id];
        }

        // Cleare 'edited' attr
        this.params.api.forEachNode(node => {
            // Replace originalValues in the saved rows
            node.data.originalValues.forEach(item => {
                item.value = node.data[item.colId];
            });
            // Cancel edited attrs
            node.data.edited = false;
        });
        
        this.params.api.redrawRows();
        // Reload so that the changes are reverted
        this.params.reload$.next();
    }

    private getEditedCount(): number {
        return Object.keys(this.params.editedIdsObj).length;
    }

    private isNewRow(row): boolean {
        return row.id < 0;  // new items have negative id
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

}