import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core'
import { SanitizeHtmlPipe } from '../../../sanitize-html.pipe';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dynamic-comp',
    template: `<div #iframeNewsContent [innerHTML]="data.body | sanitizeHtml">`
  })
  export class NewsDetailIframeComponent implements OnInit {
    @Input() data: any;
    @ViewChild('iframeNewsContent', { static: true }) iframeNewsContent: ElementRef;     /** IFRAME PROTOTYPE */

    iframeHeight;
    tryesToSetHeightCount = 0;

    constructor() {}

    ngOnInit() {
        this.data = this.data ? this.data : { body: 'empty' };
        setTimeout(() => {
            this.resizeIframe('iframe');
        }, 0)
    }

    resizeIframe(iframeID) {
        const iframe = window.parent.document.getElementById(iframeID);
        const newIframeHeight = this.iframeNewsContent.nativeElement.offsetHeight + 35;
        if ((this.iframeHeight !== newIframeHeight) && (newIframeHeight > 35)) {
            iframe.style.height = newIframeHeight + 'px';
            this.iframeHeight = newIframeHeight;
        }
        if (this.tryesToSetHeightCount < 5) { // try few times to resize again because of content not sized properly on first try
            setTimeout(() => {
                this.tryesToSetHeightCount++;
                this.resizeIframe('iframe');
            }, 500);
        }
    }
  }
