<div fxFlex="100" *ngIf="product">

	<div class="row-container">
    	<button class="btn-secondary" (click)="back()">
			<i class="fa fa-arrow-circle-left"></i>
			<a translate>BACK</a>
		</button>
    </div>

    <div class="row-container">
		<div class="area-title">
			<h3><strong>{{product.eCommProductId}}</strong></h3>
			<div *ngIf="product.tooltip"><i class="fa fa-exclamation-circle fnt-primary"></i>{{product.tooltip}}</div>
			<div *ngIf="sharedService.params?.features?.catalogue?.asWebProductCatalogue">
				<div *ngIf="product.asWebProductName && product.asWebLinkType === asWebLinkTypes.DirectConnection"><i class="fa fa-info-circle fnt-primary"></i><a [href]="asProductUrl" target="_blank" translate>CLICK_FOR_INTERACTIVE_CATALOGUE</a></div>
				<div *ngIf="product.asWebProductName && product.asWebLinkType === asWebLinkTypes.AlternativeConnection"><i class="fa fa-info-circle fnt-primary"></i><a [href]="asProductUrl" target="_blank" translate>PRODUCT_INFO_HERE_BUT_NOT_EXACTLY_SAME_PRODUCT</a></div>
				<div *ngIf="(product.asWebProductName && product.asWebLinkType === asWebLinkTypes.ContactAs) || !product.asWebProductName"><i class="fa fa-info-circle fnt-primary"></i><a href="https://www.zvlslovakia.sk/kontakt" target="_blank" translate>CONTACT_ZVL_FOR_PRODUCT_INFO</a></div>
			</div>
	    </div>

	    <div fxLayout="row" style="margin-top: 20px;">
	    	<div fxFlex="50" >
	    		<div style="margin-right: 40px;">
	    			<div class="params-table" fxFlex="100" fxLayout="column" style="border: none;">
						<div class="item" fxLayout="row" *ngIf="this.sharedService.brands.length > 1">
							<div fxFlex="40" class="key">
								<span translate>BRAND</span>
							</div>
							<div fxFlex="60" class="value">
								{{product.brandCode}}
							</div>
						</div>
	    				<div class="item" fxLayout="row" permissionHide areaPerm="purchaseCatalogueRead">
	    					<div fxFlex="40" class="key">
	    						<span translate>SHORT_NAME</span>
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.shortName}}
	    					</div>
	    				</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('productionGroupCode2Name') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>PRODUCT_CATEGORY</span>
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.productionGroupCode2Name}}
	    					</div>
	    				</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('dimensionID') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>DIMENSION</span>: d
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.dimensionID | localnumber:sharedService.appSettings.language}}
	    					</div>
	    				</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('dimensionOD') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>DIMENSION</span>: D
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.dimensionOD | localnumber:sharedService.appSettings.language}}
	    					</div>
	    				</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('dimensionB') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>DIMENSION</span>: B
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.dimensionB | localnumber:sharedService.appSettings.language}}
	    					</div>
						</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('dimensionB') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>RD_MIN_FULL_DESCRIPTION</span>
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.rdMin | localnumber:sharedService.appSettings.language}}
	    					</div>
						</div>
	    				<div class="item" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('dimensionB') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>RD_MAX_FULL_DESCRIPTION</span>
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.rdMax | localnumber:sharedService.appSettings.language}}
	    					</div>
	    				</div>
	    				<div class="item last" fxLayout="row" *ngIf="sharedService.params.suppressColumns.catalogue.indexOf('weight') < 0">
	    					<div fxFlex="40" class="key">
	    						<span translate>WEIGHT</span>
	    					</div>
	    					<div fxFlex="60" class="value">
	    						{{product.weight | localnumber:sharedService.appSettings.language}} kg
	    					</div>
	    				</div>
	    			</div>
	    		</div>
	    	</div>
	    	
	    	<div fxFlex="50" fxLayout="column">
	    		<div class="params-table" fxLayout="column" style="border: none;">
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('unitPrice')">
						<div fxFlex="40" class="key">
							<span translate>UNIT_PRICE</span>
						</div>
						<div fxFlex="60" class="value">
							{{product.unitPrice | localcurrency:sharedService.appSettings.language:sharedService.getUserCustomerCurrency()}}
						</div>
					</div>
					<!-- <div> -->
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amount')">
						<div fxFlex="40" class="key">
							{{'STOCK' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.amount| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amountUponVerification')">
						<div fxFlex="40" class="key">
							{{'AMOUNT_UPON_VERIFICATION' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							<span *ngIf="product.amountUponVerification > 0" translate>UPON_VERIFICATION_INFO</span>
							<span *ngIf="product.amountUponVerification === 0">{{product.amountUponVerification| localnumber:sharedService.appSettings.language}}</span>
						</div>
					</div>
					<ng-template #popTemplate>
						<div>
							<div style="margin-bottom: 8px;">
								<span translate>NEXT_POSSIBLE_DATES_OF_DELIVERY</span>:
							</div>
							<div *ngIf="!busy">
								<span *ngIf="availabilityRowsObj.futureDelivery1_4.length > 0" translate>IN_PRODUCTION</span>
								<div *ngFor="let availability of availabilityRowsObj.futureDelivery1_4">
									{{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language}} <span translate>UNIT</span>
								</div>
								
								<span *ngIf="availabilityRowsObj.futureDelivery5_7.length > 0" translate>IN_PRODUCTION_PLAN</span>
								<div *ngFor="let availability of availabilityRowsObj.futureDelivery5_7">
									{{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language}} <span translate>UNIT</span>
								</div>
							</div>
							<busy-indicator *ngIf="busy"></busy-indicator>
						</div>
					</ng-template>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('futureDeliveryPeriod1_4')">
						<div fxFlex="40" class="key">
							{{'IN_PRODUCTION' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							<span *ngIf="!canOpenAvailabilityPopover">{{product.futureDeliveryPeriod1_4 | localnumber:sharedService.appSettings.language}}</span>
							<span class="fnt-primary" *ngIf="canOpenAvailabilityPopover" #pop="bs-popover" [popover]="popTemplate" container="body" triggers="mouseenter:mouseleave">{{product.futureDeliveryPeriod1_4 | localnumber:sharedService.appSettings.language}}</span>
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('futureDeliveryPeriod5_7')">
						<div fxFlex="40" class="key">
							{{'IN_PRODUCTION_PLAN' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							<span *ngIf="!canOpenAvailabilityPopover">{{product.futureDeliveryPeriod5_7 | localnumber:sharedService.appSettings.language}}</span>
							<span class="fnt-primary" *ngIf="canOpenAvailabilityPopover" #pop="bs-popover" [popover]="popTemplate" container="body" triggers="mouseenter:mouseleave">{{product.futureDeliveryPeriod5_7 | localnumber:sharedService.appSettings.language}}</span>
						</div>
					</div>
					<div class="item last" fxLayout="row" *ngIf="!isColumnHidden('customerStockAmount')">
						<div fxFlex="40" class="key">
							{{'CUSTOMER_STOCK' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							<span>{{product.customerStockAmount | localnumber:sharedService.appSettings.language}}</span>
						</div>
					</div>

					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amountPlAs2')">
						<div fxFlex="40" class="key">
							{{'STOCK_PL' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.amountPlAs2| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amountSrAs2')">
						<div fxFlex="40" class="key">
							{{'STOCK_SR' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.amountSrAs2| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('futureDeliveryAs2')">
						<div fxFlex="40" class="key">
							{{'IN_PRODUCTION' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.futureDeliveryAs2| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amountSkAs4')">
						<div fxFlex="40" class="key">
							{{'STOCK_SR' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.amountSkAs4| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('amountCzAs4')">
						<div fxFlex="40" class="key">
							{{'STOCK_CZ' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.amountCzAs4| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('futureDelivery0_30As5')">
						<div fxFlex="40" class="key">
							{{'IN_PRODUCTION' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.futureDelivery0_30As5| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
					<div class="item" fxLayout="row" *ngIf="!isColumnHidden('futureDelivery30_As5')">
						<div fxFlex="40" class="key">
							{{'IN_PRODUCTION_PLAN' | translate | capitalize}}
						</div>
						<div fxFlex="60" class="value">
							{{product.futureDelivery30_As5| localnumber:sharedService.appSettings.language}}
						</div>
					</div>
				</div>

				<!-- <div permissionHide [perm]="['orders']" action="POST" -->
				<div *ngIf="!isColumnHidden('add')"
					class="order" fxLayout="row" style="margin-top: 30px;" fxLayoutAlign="space-around end">
					<div>
						<div class="input-container" fxLayout="column">
		                    <label translate>AMOUNT</label>
		                  	<!-- <input placeholder="{{ 'AMOUNT' | translate }}" [(ngModel)]="amount" class="form-control" id="amount" name="amount" #amountFormItem="ngModel" pattern="^[0-9]{0,10}$" maxlength="10" required> -->
							<div fxLayout="row">
								<number-input name="amount" fxFlex="80px"
									[ngModel]="amount"
									(ngModelChange)="amount=$event"
									[maxLength]="7"
									[focusOnInit]="true"
									[inputType]="'number'">
									</number-input>
							</div>
						</div>
					</div>
					<div *ngIf="!this.sharedService.hasPermission('products-reduced/3', 'GET')">
						<div class="input-container" style="width: 180px;">
							<label><span translate>TOTAL_PRICE</span>:</label>
							<span>{{getTotalPrice() | localcurrency:sharedService.appSettings.language:sharedService.getUserCustomerCurrency()}}</span>
						</div>
					</div>
					<div>
						<button class="btn-primary" [disabled]="amount <= 0" [ngClass]="{'ec-disabled': amount <= 0}" (click)="addToOrder()">
					    		<i class="fa fa-check-square-o"></i>
					      		<span translate>ADD_TO_ORDER</span>
					    </button>
					</div>
				</div>
	    	</div>
	    </div>
    </div>

</div>