import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
// import { NewsService} from '../../../../services/news/news.service';
import { NewsDialogService } from '../../../../services/news/news.dialog.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'news-show-in-dialog-renderer',
    template: `<div class="center-text fnt-primary" style="padding: 0 2px;"><span class="clickable" (click)="showInDialog(params.data.id)">{{params.data.readCount}}</span></div>`

})
export class GridNewsShowInDialogRendererComponent implements AgRendererComponent {
    params: any;
    setEditableSubscription: Subscription;

    constructor(
        // private newsService: NewsService,
        private newsDialogService: NewsDialogService,
    ) {

    }

    agInit(params: any): void {
        this.params = params;
        // this.params.subjectReloadCurrent = this.newsDialogService.getReloadCurrentSubject();
        // this.buttonsVisibility = this.getButtonsVisibility(this.params.data.actions);
    }
    showInDialog(newsId: number) {
        this.newsDialogService.showReadCount({id: this.params.data.id});
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
