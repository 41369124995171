import { Observable ,  Subject } from 'rxjs';
import { ImportShipmentErrorDialog } from './import.shipment.error.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { BulkImportResponse } from '@app/model/shipment.model';

@Injectable()
export class ImportShipmentErrorDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: BulkImportResponse): Observable<any> {
        this.bsModalRef = this.modalService.show(ImportShipmentErrorDialog, {
            class: 'modal-lg',
            ignoreBackdropClick: true
        });
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}