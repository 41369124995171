import { TranslateService } from '@ngx-translate/core';
import { PrfqItem, PrfqItemActions } from '@model/prfq.model';
import { TableBulk, TableBulkActionItem, TableBulkTypes, TableBulkClickFn, CellChange } from '@app/model/table.model';
import { QuestionDialogResult, QuestionDialogService } from '@app/components/questionDialogComponent/question.dialog.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { PrfqService } from '../prfq.service';
import { SharedService } from '@app/services/shared.service';
import { PrfqPermissionService } from '../prfq.permission.service';
import { DiscountDialogService } from '@app/components/discountDialogComponent/discount.dialog.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { PrfqJournalGridService } from './prfq.journal.grid.service';

enum BulkActionCodes {
    DELETE_ITEM = 'DELETE_ITEM',
    DATE_OFFERED_DAYS = 'DATE_OFFERED_DAYS',
    APPLY_ITEMS_DISCOUNT = 'APPLY_ITEMS_DISCOUNT',
    ITEM_VALIDITY_DAYS = 'ITEM_VALIDITY_DAYS',
    CONFIRM_ITEM = 'CONFIRM_ITEM',
    START_ITEM_WAITING = 'START_ITEM_WAITING',
    FINISH_ITEM_WAITING = 'FINISH_ITEM_WAITING',
    ACCEPT_ITEM = 'ACCEPT_ITEM',
    NOT_ACCEPT_ITEM = 'NOT_ACCEPT_ITEM',
    CANCEL_ACCEPTED_ITEM = 'CANCEL_ACCEPTED_ITEM',
    REOPEN_ITEM = 'REOPEN_ITEM'
};

export class PrfqsDetailItemsBulk {

    constructor (
        private translateService: TranslateService,
        private prfqService: PrfqService,
        protected sharedService: SharedService,
        private prfqPermissionService: PrfqPermissionService,
        private questionDialogService: QuestionDialogService,
        private discountDialogService: DiscountDialogService,
        private toastService: ToastService,
        private resetSelection: () => void,
        private reloadCurrentGridPage$: Subject<void>,
        private attachmentChanged$: Subject<CellChange>,
        private adjustOriginalValuesOfSelectedItems: (attrName: string, value: any) => void
    ) {
    }

    public getBulk(selectedItems: {[prfqItemId: string]: PrfqItem}, prfqId: number): TableBulk {
        if (!this.prfqPermissionService.hasPermissionItemsAction()) {
            // does not have the permission for item actions so return object with empty items array
            return {
                items: []
            };
        }

        const allAvailableActions: {[actionCode: string]: TableBulkActionItem} = this.getAllAvailableBulkActions();

        Object.keys(selectedItems).forEach(prfqItemId => {
            // Action permissions coming from BE in 'actions' array on item
            selectedItems[prfqItemId].actions.forEach(action => {
                if (allAvailableActions[action]) {
                    allAvailableActions[action].itemsCanDo += 1;
                }
            });
            // Other action permissions
            if (this.prfqPermissionService.isCellEditable(selectedItems[prfqItemId], 'dateOfferedDays')) {
                allAvailableActions[BulkActionCodes.DATE_OFFERED_DAYS].itemsCanDo += 1;
            }
            if (this.prfqPermissionService.isCellEditable(selectedItems[prfqItemId], 'itemValidityDays')) {
                allAvailableActions['ITEM_VALIDITY_DAYS'].itemsCanDo += 1;
            }
            if (this.prfqPermissionService.isCellEditable(selectedItems[prfqItemId], 'unitPriceOffered')) {
                allAvailableActions[BulkActionCodes.APPLY_ITEMS_DISCOUNT].itemsCanDo += 1;
            }
        });

        const bulkActionItems: TableBulkActionItem[] = Object.keys(allAvailableActions)
            .filter(actionCode => allAvailableActions[actionCode].itemsCanDo > 0)
            .map(actionCode => {
                const action = allAvailableActions[actionCode];
                if (action.itemsCanDo < Object.keys(selectedItems).length) {
                    action.name = this.translateService.instant(action.name) + ' ' +
                        action.itemsCanDo + '/' + Object.keys(selectedItems).length;
                }
                action.items = Object.keys(selectedItems).length;
                action.click = this.getBulkActionFn(actionCode, action, selectedItems, prfqId);
                return action;
            });
        // add bulk actions that are shown according to editable columns
        // if (this.rfqPermissionService.isColumnEditable('dateRequired')) {
        //     bulkItems.unshift(actions.DATE_REQUIRED);
        // }

        return {
            update: (items, data: {[key: string]: any}) => this.bulkUpdateFn(selectedItems, items, data),
            items: bulkActionItems
        };
    }

    private getAllAvailableBulkActions(): {[actionCode: string]: TableBulkActionItem} {
        return {
            [BulkActionCodes.DELETE_ITEM]: { 
                id: 'DELETE_ITEM', 
                name: 'DELETE_SELECTED', 
                value: null, 
                type: TableBulkTypes.button, 
                iconClass: 'fa fa-trash',
                itemsCanDo: 0
            },
            [BulkActionCodes.APPLY_ITEMS_DISCOUNT]: {
                id: 'DISCOUNT_APPLY_ITEM',
                name: 'DISCOUNT_APPLY',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-tags',
                itemsCanDo: 0
            },
            [BulkActionCodes.DATE_OFFERED_DAYS]: {
                id: 'dateOfferedDays',
                name: 'OFFERED_DATE_DAYS',
                value: null,
                type: TableBulkTypes.input,
                prefixString: 'D + ',
                itemsCanDo: 0
            },
            [BulkActionCodes.ITEM_VALIDITY_DAYS]: { 
                id: 'itemValidityDays', 
                name: 'RFQ_ITEM_VALIDITY', 
                value: null, 
                type: TableBulkTypes.input,
                prefixString: 'D + ',
                itemsCanDo: 0
            },
            [BulkActionCodes.CONFIRM_ITEM]: {
                id: 'CONFIRM_ITEM',
                name: 'RFQ_CONFIRM_ITEM',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-check-circle',
                itemsCanDo: 0
            },
            [BulkActionCodes.START_ITEM_WAITING]: {
                id: 'START_ITEM_WAITING',
                name: 'PRFQ_START_ITEM_WAITING',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-file-o',
                itemsCanDo: 0
            },
            [BulkActionCodes.FINISH_ITEM_WAITING]: {
                id: 'FINISH_ITEM_WAITING',
                name: 'PRFQ_FINISH_ITEM_WAITING',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-file-text',
                itemsCanDo: 0
            },
            [BulkActionCodes.ACCEPT_ITEM]: {
                id: 'ACCEPT_ITEM',
                name: 'RFQ_ACCEPT_ITEM',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-check-circle',
                itemsCanDo: 0
            },
            [BulkActionCodes.NOT_ACCEPT_ITEM]: {
                id: 'NOT_ACCEPT_ITEM',
                name: 'PRFQ_NOT_ACCEPT_ITEM',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-times-circle',
                itemsCanDo: 0
            },
            [BulkActionCodes.CANCEL_ACCEPTED_ITEM]: {
                id: 'CANCEL_ACCEPTED_ITEM',
                name: 'RFQ_CANCEL_ACCEPTED_ITEM',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-times-circle',
                itemsCanDo: 0
            },
            [BulkActionCodes.REOPEN_ITEM]: {
                id: 'REOPEN_ITEM',
                name: 'RFQ_REOPEN_ITEM',
                value: null,
                type: TableBulkTypes.button,
                iconClass: 'fa fa-undo',
                itemsCanDo: 0
            }
        };
    }

    private getBulkActionFn(actionCode: string, action: TableBulkActionItem,
        selectedItems: {[prfqItemId: string]: PrfqItem}, prfqId: number
    ): TableBulkClickFn {

        // 'click' fn should only be specified for buttons, not for dropdown, date picker nor input
        const fnMap = {
            [BulkActionCodes.DELETE_ITEM]: (itemIds: string[]) => this.bulk_DELETE_ITEM(selectedItems, action, prfqId, itemIds),
            [BulkActionCodes.APPLY_ITEMS_DISCOUNT]: (itemIds: string[]) => this.bulk_APPLY_ITEMS_DISCOUNT(selectedItems, action, prfqId, itemIds)
        };
        const genericActionFn = (itemIds: string[]) => this.doGenericBulkAction(selectedItems, action, prfqId, itemIds);

        return actionCode in fnMap ? fnMap[actionCode] : genericActionFn;
    }

    private bulk_DELETE_ITEM(selectedItems: {[prfqItemId: string]: PrfqItem}, 
        action: TableBulkActionItem, prfqId: number, itemIds: string[]
    ): void {

        let question = this.translateService.instant('QUESTION_ACTION_BULK') + '? '
        const attachmentsAffected = Object.keys(selectedItems).filter(key => selectedItems[key].attachmentsCount > 0).length > 0;
        if (attachmentsAffected) {
            question = this.translateService.instant('DELETE_RFQ_ITEMS_WITH_ATTACHMENTS_QUESTION') + ' ' + question;
        }
        question += (action.itemsCanDo < Object.keys(selectedItems).length
            ? this.translateService.instant('ACTION_CAN_BE_DONE_ONLY_ON') + action.itemsCanDo + ' ' +
                this.translateService.instant('OF') + ' ' + Object.keys(selectedItems).length +
                this.translateService.instant('FROM_SELECTED_ITEMS') + '.'
            : '');
        
        this.questionDialogService.confirm({
            message: 'BULK_ACTION_' + action.id, 
            question: question,
            suppressQuestionMark: true,
            primary: action.primary ? action.primary : 'YES',
            secondary: action.secondary ? action.secondary : 'NO'
        }).subscribe(answer => {
            if (answer === QuestionDialogResult.Confirm) {
                this.prfqService.deletePrfqItems(prfqId, itemIds.map(id => ({id: +id})))
                .subscribe(() => {
                    this.resetSelection();
                    this.reloadCurrentGridPage$.next();
                    if (attachmentsAffected) {
                        this.attachmentChanged$.next();
                    }
                });
            }
        });
    }

    private bulk_APPLY_ITEMS_DISCOUNT(selectedItems: {[prfqItemId: string]: PrfqItem}, 
        action: TableBulkActionItem, prfqId: number, itemIds: string[]
    ): void {

        const prfqNumber: string = selectedItems[Object.keys(selectedItems)[0]].prfqNumber;

        let question = this.translateService.instant('QUESTION_ACTION_BULK') + '? '
        // show modal with discount input, after confirm do calculations over unitPriceOffered otherwise cancel
        // if confirmed recalculate price and show success notification,  if canceled only close modal without change
        question = this.translateService.instant('DISCOUNT_ON') + ' ' + action.itemsCanDo + '/' + action.items + ' ' +
        this.translateService.instant('DISCOUNT_ITEMS_OF_RFQ_NUMBER') + ' ' + prfqNumber;
        this.discountDialogService.confirm({
            message: 'BULK_ACTION_' + action.id, question: question,
            suppressQuestionMark: false,
            primary: action.primary ? action.primary : 'DISCOUNT_APPLY',
            secondary: action.secondary ? action.secondary : 'CLOSE',
            inputLabel: this.translateService.instant('DISCOUNT_AMOUNT')
        }).subscribe(result => {
            if (typeof result === 'object') {
                const itemsToModify: PrfqItem[] = [];
                const coefficient: number = !result.textValue
                    ? 0
                    : parseFloat(result.textValue.toString().replace(',', '.'));

            Object.keys(selectedItems).forEach((prfqItemId) => {
                    const item = selectedItems[prfqItemId];
                    if (item.unitPriceOffered && coefficient > 0 &&
                        this.prfqPermissionService.isCellEditable(selectedItems[prfqItemId], 'unitPriceOffered')) {
                        // Formula: unitPriceOffered * (100 - „Výška zľavy v %“) / 100, rounded by 2 decimals
                        const newPrice = (item.unitPriceOffered * (100 - coefficient)) / 100;

                        item.unitPriceOffered = this.sharedService.roundTo(newPrice, 2); // round to 2 decimals but if third decimal exists round properly
                        itemsToModify.push(item);
                    }
                });

                // this.resetSelection();
                // subjectRefresh.next();
                this.prfqService.updatePrfqItems(itemsToModify).subscribe(() => {
                    this.reloadCurrentGridPage$.next();
                    this.toastService.addSuccess(this.sharedService.translateService.instant('DISCOUNT_SUCCESS'));
                },
                error => {
                    console.log(error);
                    this.toastService.addError(this.sharedService.translateService.instant('DISCOUNT_ERROR'));
                });
            }
        });
    }

    private doGenericBulkAction(selectedItems: {[prfqItemId: string]: PrfqItem}, 
        action: TableBulkActionItem, prfqId: number, itemIds: string[]
    ): void {

        let question = this.translateService.instant('QUESTION_ACTION_BULK') + '? '
        question += (action.itemsCanDo < Object.keys(selectedItems).length
            ? this.translateService.instant('ACTION_CAN_BE_DONE_ONLY_ON') + action.itemsCanDo + ' ' +
                this.translateService.instant('OF') + ' ' + Object.keys(selectedItems).length +
                this.translateService.instant('FROM_SELECTED_ITEMS') + '.'
            : '');
        
        this.questionDialogService.confirm({
            // message: 'BULK_ACTION_' + action.id, 
            message: action.name, 
            question: question,
            suppressQuestionMark: true,
            primary: action.primary ? action.primary : 'YES',
            secondary: action.secondary ? action.secondary : 'NO'
        }).subscribe(answer => {
            if (answer === QuestionDialogResult.Confirm) {
                const applicableItemIds = itemIds
                    .map(prfqId => selectedItems[prfqId])
                    .filter(prfqItem => prfqItem.actions.indexOf(action.id as PrfqItemActions) >= 0)
                    .map(prfqItem => prfqItem.id);
                this.prfqService.prfqItemsAction(prfqId, applicableItemIds, action.id)
                .subscribe(() => {
                    // this.resetSelection();
                    this.reloadCurrentGridPage$.next();
                });
            }
        });
    }

    /**
     * @param items 
     * @param data {<attr to set>: <new value>}
     */
    private bulkUpdateFn(selectedItems: {[prfqItemId: string]: PrfqItem}, items: string[], 
        data: {[key: string]: any}
    ): Observable<any> {

        return this.questionDialogService.confirm({
            message: 'BULK_UPDATE', 
            question: 'QUESTION_UPDATE_BULK', 
            primary: 'YES', 
            secondary: 'NO'
        }).pipe(switchMap(answer => {
            if (answer === QuestionDialogResult.Confirm) {
                // set new values to items from bulk update action
                const attrName = Object.keys(data)[0]; // e.g. 'itemValidityDays'
                const updateData = Object.keys(selectedItems)
                    .filter(prfqItemId => {
                        if (attrName === 'dateOfferedDays' || attrName === 'unitPriceOffered' || attrName === 'itemValidityDays') {
                            return this.prfqPermissionService.isCellEditable(selectedItems[prfqItemId], attrName);
                        } else {
                            return true;
                        }
                    })
                    .map(prfqItemId => {
                        const item = Object.assign({}, selectedItems[prfqItemId]);
                        // set new values to items from bulk update action
                        item[attrName] = data[attrName]
                        return item;
                    });

                return this.prfqService.updatePrfqItems(updateData)
                    .pipe(map(result => {
                        // this.adjustOriginalValuesOfSelectedItems(attrName, data[attrName]);
                        this.adjustOriginalValuesOfSelectedItems(attrName, data[attrName]);
                        return result;
                    }));

            } else {
                return of({});
            }
        }));
    }

    /**
     * After a bulk update it is necessary to update value in originalValues attribute of selected itens
     * because it is not update by rows reload
     * @param attrName column ID e.g. 'itemValidity'
     * @param value value to set
     */
    // private adjustOriginalValuesOfSelectedItems(attrName: string, value: any): void {
    //     if (this.sharedService.user.preferences['prfqDetailSelection'].ids) {  // some rows are selected
    //         Object.keys(this.sharedService.user.preferences['prfqDetailSelection'].ids).forEach(id => {
    //             if (this.sharedService.user.preferences['prfqDetailSelection'].ids[id].originalValues) {  // row has originalValues
    //                 const foundArr = this.sharedService.user.preferences['prfqDetailSelection'].ids[id].originalValues
    //                     .filter(colObj => colObj.colId === attrName);

    //                 if (foundArr.length === 1) {
    //                     foundArr[0].value = value;
    //                 }
    //             }
    //         });
    //     }
    // }
}
