import { Component } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'support',
  templateUrl: 'support.component.html',
  // styleUrls:  ['./support.component.css']
})
export class SupportComponent  { 
	
	constructor(public sharedService: SharedService) {
	}
}