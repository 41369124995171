<div class="row-container wider bigger">
    <div fxLayout="row" class="row-container">
        <input type="checkbox" id="make-comment" name="make-comment"
            style="margin-top: 4px;"
            [(ngModel)]="makeComment"
            (ngModelChange)="onMakeCommentChange()">
            <label for="make-comment" translate>{{params?.checkboxText}}</label>
    </div>

    <div fxLayout="row" class="row-container">
        <app-new-post style="width: 100%;"
            [showPrivateControls]="params?.showPrivateControls"
            [privateConfig]="params?.discussionPrivateConfig"
            [disabled$]="disabled$"
            (onPostChange)="onPostChange($event)"
        ></app-new-post>
    </div>

    <div fxLayout="row" class="row-container top-padded">
        <div fxFlex="50">
            <button class="btn-secondary" (click)="cancel()" style="width: 100px;">
                  <span translate>CANCEL</span>
            </button>
        </div>
        <div fxFlex="50" fxLayoutAlign="end">
            <button class="btn-primary" (click)="submit()" style="min-width: 100px;" 
                [disabled]="disableSubmit" 
                [ngClass]="{'ec-disabled': disableSubmit}">

                  <span translate>{{params?.submitButtonText}}</span>
            </button>
        </div>
    </div>
</div>