import { Component, OnInit, ViewChild, ViewChildren, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { NewsDetailService } from '../../../services/news/news.detail.service';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
import {Animations} from '../../../animations';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'news-discussion',
  styleUrls:  ['./news.discussion.component.scss'],
  templateUrl: 'news.discussion.component.html',
  animations: [Animations.slideInOut]
})

export class NewsDiscussionComponent implements OnInit, AfterViewInit {
    // @ViewChild('scrollContainer') private myScrollContainer: ElementRef;
    // @ViewChildren('newCommentInput', {read: ViewContainerRef}) newCommentInput;

    // @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
    // @Output() added: EventEmitter<any> = new EventEmitter<any>();
    @Input() newsId: any = null;
    @Input() comments;
    @Input() total: any;
    @Input() reload: any;

    @Input() public isModal: boolean;
    @Input() makeCollapsible: boolean;   // Set Header and Body for collapsible component (after click on header then body will collapse
    @Input() isCollapsed;                 // set collapsed state for this element
    @Input() disableEdit: boolean;  // force to disable textarea and send button
    @Output() isCollapsedChange = new EventEmitter();       // emit collapsed state for this element
    @Output() commentsHasChange = new EventEmitter();       // emit collapsed state for this element
    // Emits boolean when edited or changed
    @Output() edited: EventEmitter<boolean> = new EventEmitter<boolean>();

    sendBusy = false;
    showCommentFor = null;
    showReplyInId = null;
    replyToComment = null;
    commentText = null;
    reloadSubscribtion;
    lastCurrent: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private newsDetailService: NewsDetailService,
        public sharedService: SharedService,
        private toastService: ToastService,
    ) {
    }

    ngOnInit() {
        // this.newsDetailService.getComments().subscribe(
        //     res => console.log(res),
        //     err => console.log(err)
        // );
        this.comments = this.comments ? this.comments : null;
    }
    showReplyTo(current) {
        this.lastCurrent = Object.assign({}, current);
        this.replyToComment = this.comments.filter(item => item.id === (current.parentId ? current.parentId : current.id) )[0];
        // get id of parent or of child
        this.showCommentFor = current.parentId ? current.parentId : current.id;

        // filter all childrens by clicked reply icon:  current can be parent or child (parent doesnt have parentId - is null )
        const childrens = this.comments.filter(item => item.parentId === (current.parentId ? current.parentId : current.id) );

        // if childrens doesnt exist, show in parent otherwise get current childs and get last child (with highest id)
        if (childrens.length <= 0) {
            this.showReplyInId = current.id;
        } else {
            const commentsIds = childrens.map(item => item.id).sort((a, b) => { return a - b; });
            this.showReplyInId = commentsIds[commentsIds.length - 1];
        }
    }

    commentOnchange() {
        this.edited.emit(this.commentText.length !== 0);
    }

    sendComment(parent?: any) {
        this.sendBusy = true;
        const comment = {
            discussionText: this.commentText,
            parentId: parent && parent.id ? parent.id : null
        };
        if (comment.parentId === null) {
            delete comment.parentId;
        }

        this.newsDetailService.postComments(this.newsId, comment).subscribe(
            comments => {
                this.commentsHasChange.emit(parent);
                this.showCommentFor = null;
                this.showReplyInId = null;
                this.replyToComment = null;
                this.commentText = null;
                this.sendBusy = false;
                this.edited.emit(false);
            },
            err => {
                console.log(err);
                this.sendBusy = false;
        });

    }
    toggleCollapse() {
        this.isCollapsed  = (this.makeCollapsible) ? !this.isCollapsed : null;
        this.isCollapsedChange.emit(this.isCollapsed);
      }
      ngAfterViewInit() {
        if (this.reload) {
            this.reloadSubscribtion = this.reload.subscribe((data) => {
                this.showCommentFor = null;
                this.showReplyInId = null;
                this.replyToComment = null;
                this.commentText = null;
                this.ngOnInit();
            })
        }
    }
}

