import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";

@Component({
    selector: 'state-renderer',
    template: `	<div *ngIf="params.data[params.column.colId]" style="font-size: 15px;" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <ng-template #popTemplate><span translate>{{(params.tooltipPrefix ? params.tooltipPrefix : 'STATETOOLTYP_') + params.data[params.column.colId]}}</span></ng-template>
    				<div *ngIf="!withPopover" style="width: 20px; height: 20px; border: 3px solid #fff; border-radius: 10px; margin: 0 auto;"
                         [ngClass]="{'bkg-gray-darker': params.data[params.column.colId] == colors.gray,
                                     'bkg-green': params.data[params.column.colId] == colors.green,
                                     'bkg-lime': params.data[params.column.colId] == colors.lime,
                                     'bkg-red': params.data[params.column.colId] == colors.red,
                                     'bkg-yellow': params.data[params.column.colId] == colors.yellow,
                                     'bkg-black': params.data[params.column.colId] == colors.black,
                                     'bkg-blue-lighter': params.data[params.column.colId] == colors.blue,
                                     'bkg-blue': params.data[params.column.colId] == colors.blueDarker,
                                     'bkg-orange': params.data[params.column.colId] == colors.orange
                                    }">
                    </div>
                    <div *ngIf="withPopover" style="width: 20px; height: 20px; border: 3px solid #fff; border-radius: 10px; margin: 0 auto;"
                         [ngClass]="{'bkg-gray-darker': params.data[params.column.colId] == colors.gray,
                                     'bkg-green': params.data[params.column.colId] == colors.green,
                                     'bkg-lime': params.data[params.column.colId] == colors.lime,
                                     'bkg-red': params.data[params.column.colId] == colors.red,
                                     'bkg-yellow': params.data[params.column.colId] == colors.yellow,
                                     'bkg-black': params.data[params.column.colId] == colors.black,
                                     'bkg-blue-lighter': params.data[params.column.colId] == colors.blue,
                                     'bkg-blue': params.data[params.column.colId] == colors.blueDarker,
                                     'bkg-orange': params.data[params.column.colId] == colors.orange
                                    }"
                         [popover]="popTemplate" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left">
                    </div>
    			</div>`
})
export class GridStateRendererComponent implements AgRendererComponent {
    params:any;

    withPopover: boolean; // popover with container body cannot be in DOM a lot of times, so change alement without popover
                                  // to element with popover when this is true - when this grid cell is mouseover

    colors = { // acn be overriden by setting custom colors in params of renderer on grid options
        gray: 'X',
        green: 'P',
        red: 'N',
        yellow: 'C',
        black: 'D',
        lime: 'L',
        blue: 'S',
        blueDarker: 'F',
        orange: 'O'
    }

    constructor(private renderer:Renderer2) {

    }

    agInit(params:any):void {
        this.params = params;
        if (this.params.renderColors) { // if to everride colors
            this.colors = this.params.renderColors;
        }
    }

    mouseover() {
        this.withPopover = true;
    }
    mouseleave() {
        this.withPopover = false;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}