<div class="accordion-menu bkg-gray-darker">
	<!-- <div class="clickable category row-container" [ngClass]="{'active': productionGroup1 == 'all'}" style="margin-bottom: 10px;" 
		[routerLink]="['/' + sharedService.appSettings.language + '/' + catalogueService.getRoute(catalogueMode) + '/']" [queryParams]="{group1: 'all'}"  (click)="onCategoryAllClick()">
		<a class="fnt-primary bold-text" translate>CATALOGUE_ALL</a>
	</div> -->
	<div class="clickable category row-container" [ngClass]="{'active': productionGroup1 == 'all'}" style="margin-bottom: 10px;" 
		(click)="onCategoryAllClick()">
		<a class="fnt-primary bold-text" translate>CATALOGUE_ALL</a>
	</div>
	<div *ngFor="let category of categories">
		<div class="category row-container clickable"
			[ngClass]="{'visible': !category.collapsed, 'active': (productionGroup1 == category.id) && productionGroup1 && !productionGroup2}"
			[routerLink]="['/' + sharedService.appSettings.language + '/' + category.url]"
			[queryParams]="category.query" 
			(click)="openCategoryAndLoad(category);"
		>
			<div class="link-container" (mouseover)="openCategoryDelayed(category)" (mouseleave)="cancelTimeoutToOpenCategory()">
	    		<a [routerLink]="['/' + sharedService.appSettings.language + '/' + category.url]" [queryParams]="category.query" (click)="onLinkClick(category.query)"><strong class="fnt-primary">{{category.name}}</strong></a>
	    	</div>
	    	<div class="toggle" (click)="toggleCategory(category); $event.stopPropagation()"></div>
	   	</div>
	    <div class="clickable" [routerLink]="['/' + sharedService.appSettings.language + '/' + category.url]" [queryParams]="subcategory.query" *ngFor="let subcategory of category.children" [@slideInOut]="category.collapsed" (click)="onLinkClick(subcategory.query)">
	        <div class="subcategory row-container" [ngClass]="{'active': productionGroup1 == category.id && productionGroup2 == subcategory.id}">
	        	<!-- <a class="fnt-primary" [routerLink]="['/' + sharedService.appSettings.language + '/' + category.url]" [queryParams]="subcategory.query" (click)="onLinkClick(subcategory.query)">{{subcategory.name}}</a> -->
	        	<a class="fnt-primary" [routerLink]="['/' + sharedService.appSettings.language + '/' + category.url]" [queryParams]="subcategory.query">{{subcategory.name}}</a>
	        </div>
	    </div>
	</div>
</div>