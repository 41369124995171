<div class="row-container" *ngIf="saveErrorData">
    <div *ngIf="saveErrorData.successCount > 0">
        <h4 class="fnt-green"><span translate>INSERT_PRICE_LIST_SUCCESS</span>: {{saveErrorData.successCount}}</h4>
    </div>

    <div style="margin-top: 10px" *ngIf="saveErrorData.errors.length > 0">
        <div fxLayout="row">
            <h4 class="fnt-orange"><span translate>INSERT_PRICE_LIST_ERROR</span>: {{saveErrorData.errors.length}}</h4>

            <button class="btn-secondary" style="min-width: 200px; margin-left: 10px;"
                (click)="copyErrorsToClipboard()">
                <i class="fnt-primary fa fa-clipboard"></i>
                <span translate>COPY_ERRORS_TO_CLIPBOARD</span>
            </button>
        </div>

        <div style="display: block; overflow-y: auto; min-height: 240px;"
            [ngStyle]="{'max-height.px': maxHeight - (this.saveErrorData.successCount > 0 ? 30 : 0)}">
            <div fxLayout="row">
                <div fxFlex="25" *ngIf="componentMode === componentModes.WithBrandCodeAndProductName"
                    style="display: inline-block; margin-right: 10px;  margin-left: 7px;" 
                    translate>BRAND</div>
                <div fxFlex="25" *ngIf="componentMode === componentModes.WithBrandCodeAndProductName"
                    style="display: inline-block; margin-right: 10px;  margin-left: 7px;"
                    translate>PRODUCT_ID</div>
                <div fxFlex="25" *ngIf="componentMode === componentModes.WithProductCode"
                    style="display: inline-block; margin-right: 10px;  margin-left: 1px;"
                    translate>PRODUCT_CODE</div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 0px;"
                    translate>UNIT_PRICE</div>
            </div>
            <div fxLayout="row" *ngFor="let item of saveErrorData.errors; let odd=odd; let even=even;"
                [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">

                <div fxFlex="25" *ngIf="componentMode === componentModes.WithBrandCodeAndProductName"
                    style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
                    [ngClass]="{'fnt-orange': item.error.brandCode || item.error.productNameBrandCode}">
                    <input type="text" style="margin: 4px 0;" class="form-control"
                        [ngClass]="{'fnt-orange': item.error.brandCode || item.error.productNameBrandCode}"
                        [(ngModel)]="item.row.brandCode"
                        (ngModelChange)="onChange(item, 'brandCode')">
                    <div style="margin-top: -5px;">
                        <span style="font-size: 75%;" translate>{{item.error.brandCode}}</span>
                        <span style="font-size: 75%;" translate>{{item.error.productNameBrandCode}}</span>
                    </div>
                </div>

                <div fxFlex="25" *ngIf="componentMode === componentModes.WithBrandCodeAndProductName"
                    style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
                    [ngClass]="{'fnt-orange': item.error.productName || item.error.productNameBrandCode}">
                    <input type="text" style="margin: 4px 0;" class="form-control"
                        [ngClass]="{'fnt-orange': item.error.productName || item.error.productNameBrandCode}"
                        [(ngModel)]="item.row.productName"
                        (ngModelChange)="onChange(item, 'productName')">
                    <div style="margin-top: -5px;">
                        <span style="font-size: 75%;" translate>{{item.error.productName}}</span>
                        <span style="font-size: 75%;" translate>{{item.error.productNameBrandCode}}</span>
                    </div>
                </div>

                <div fxFlex="25" *ngIf="componentMode === componentModes.WithProductCode"
                    style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
                    [ngClass]="{'fnt-orange': item.error.productCode}">
                    <input type="text" style="margin: 4px 0;" class="form-control"
                        [ngClass]="{'fnt-orange': item.error.productCode}"
                        [(ngModel)]="item.row.productCode"
                        (ngModelChange)="onChange(item, 'productCode')">
                    <div style="margin-top: -5px;">
                        <span style="font-size: 75%;" translate>{{item.error.productCode}}</span>
                    </div>
                </div>

                <div fxFlex="25" style="display: inline-block; margin-right: 10px;">
                    <input type="text" style="margin: 4px 0;" class="form-control"
                        [ngClass]="{'fnt-orange': item.error.unitPrice}"
                        [(ngModel)]="item.row.unitPrice"
                        (ngModelChange)="onChange(item, 'unitPrice')">
                    <div style="margin-bottom: 2px">
                        <span *ngIf="item.row.unitPrice.length == 0" class="fnt-orange" style="font-size: 75%;"
                            translate>INSERT_TO_BASKET_AMOUNT_MISSING</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div style="padding-top: 15px;">
        <button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
            <span translate>CLOSE</span>
        </button>
        <button class="btn-primary" style="float: right" 
            (click)="addAgain()" [disabled]="!somethingChanged"
            [ngClass]="{'ec-disabled': !somethingChanged}">
            <i class="fa fa-plus"></i>
            <span translate>ADD_ITEMS_AGAIN</span>
        </button>
    </div>
</div>