import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable ,  of } from 'rxjs';

import { QuestionDialogService } from '../components/questionDialogComponent/question.dialog.service';
import { SharedService } from '../services/shared.service';
import { switchMap } from 'rxjs/operators';
import { AutoLogoutService } from '@app/services/auto-logout/auto-logout.service';

// does not allow to deactivate route when detail is edited
@Injectable()
export class CanDeactivateDetail implements CanDeactivate<any> {
    constructor(
        private questionDialogService: QuestionDialogService,
        private sharedService: SharedService,
        private autoLogoutService: AutoLogoutService
    ) {}

    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean> {
        // autoLogoutService.isTimeout() will force routing to logout route even when a form is being edited
        if (!component.isEditing() || this.autoLogoutService.isTimeout()) {
            return of(true);
        } else {
            return this.questionDialogService.confirm({
                message: 'WARNING',
                question: 'WARNING_DATA_NOT_SAVED_CONTINUE_ANYWAYS',
                primary: 'YES',
                secondary: 'NO',
            }, { keyboard: false, ignoreBackdropClick: true })
            .pipe(switchMap(res => {
                if (res !== 'confirm') {
                    // Restore 'area' in the sharedService because the navigation to the new one is cancelled
                    this.sharedService.restorePreviousAreaValue();
                }
                return res === 'confirm' ? of(true) : of(false);
            }));
        }
    }
}


