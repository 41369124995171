import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'under-construction',
  templateUrl: 'under.construction.component.html'
})
export class UnderConstructionComponent  {

	constructor() {
		
	}
}