import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NewsDetailService } from '../../../services/news/news.detail.service';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { Subject } from 'rxjs';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'news-discussion-dialog',
  styleUrls:  ['./news.discussion.dialog.component.scss'],
  templateUrl: 'news.discussion.dialog.component.html',
})

export class NewsDiscussionDialogComponent implements OnInit, AfterViewInit {
    doneSubject: Subject<any>;
    params: any;
    reload;
    comments;
    reloadComments = new Subject<any>();
    edited = false;
    showNotSavedQuestion = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private newsDetailService: NewsDetailService,
        public sharedService: SharedService,
        private toastService: ToastService,
        public bsModalRef: BsModalRef
    ) {
    }

    ngOnInit() {
        setTimeout(() => {
            // console.log('in modal', this.params);
            this.getComments();
        }, 0);
    }

    getComments(parent?: boolean) {
        this.newsDetailService.getComments(this.params.data.id).subscribe(
            comments => {
                this.comments = comments;
                this.params.commentsChangedSubject.next(this.comments.length);
                setTimeout(() => {
                    if (parent) {
                        this.reloadComments.next(parent);
                    }
                }, 0);
            },
            err => {
                console.log(err);

        });
    }

    commentsHasChanged(parent?: any) {
        // console.log('comments has changed - news');
        this.getComments(parent);
    }

    ngAfterViewInit() {
        if (this.reload) {
            // this.reloadSubscribtion = this.reload.subscribe((data) => {
            //     this.showCommentFor = null;
            //     this.showReplyInId = null;
            //     this.replyToComment = null;
            //     this.commentText = null;
            //     this.ngOnInit();
            // })
        }
    }

    onCommentEdited(edited) {
        this.edited = edited;
    }

    doNotClose() {
        this.showNotSavedQuestion = false;
    }

    forceClose() {
        this.doneSubject.next('restart');
        this.bsModalRef.hide();
    }

    close() {
        if (this.edited) {
            this.showNotSavedQuestion = true;
        } else {
            this.forceClose();
        }
    }
}
