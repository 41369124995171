<form>
  <div class="row-container wider">
    <h3><i class="fa fa-envelope-o"></i> <span translate>SEND_BY_EMAIL</span></h3>
  
      <div style="display: block; overflow-y: auto; min-width: 200px;"
        [ngStyle]="{'max-height.px': maxHeight}">
          <div class="input-container" style="display: block;">
              <label translate>EMAIL</label>
              <input
                type="text"
                name="internal_number"
                [(ngModel)]="data.email"
                maxlength="100"
                class="form-control"
                [required]="true">
          </div>
      </div>

      <div style="padding-top: 15px; margin-bottom: 15px">
          <button
            class="btn-secondary"
            (click)="close()"
            style="width: 100px;">
              <span translate>CLOSE</span>
          </button>
          <button
            class="btn-primary"
            style="float: right"
            (click)="send()" 
            [disabled]="!data.email"
            [ngClass]="{'ec-disabled': !data.email}">
              <i class="fa fa-check-square-o"></i>
              <span translate>SEND</span>
          </button>
      </div>
  </div>
</form>