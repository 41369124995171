export enum OrderDialogMode {
    Details = 'details',
    Errors = 'errors'
}

export enum OrderDialogResultState {
    Close = 'close',
    Order = 'order',
    ShowInvalidItems = 'showInvalidItems'
}

export interface OrderDialogResult {
    state: OrderDialogResultState;
    data: any
}
