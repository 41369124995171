import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-logout',
  template: ``
})
export class LogoutComponent implements OnInit {
    constructor(
        private userService: UserService
    ) {}

    ngOnInit() {
        this.userService.logout(true).subscribe(() => {
            // this.chatService.sendToChat({ setName: '.' });
            // this.newsService.stopTimer();
            // this.router.navigate(['/' + this.sharedService.appSettings.language + '/home']);
        }, err => console.log(err));
    }

}
