import { Observable, Subject } from 'rxjs';
import { AttachmetDialog } from './attachment.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { AttachmentPrivateConfig } from '../attachment.component';

@Injectable()
export class AttachmentDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    // if load discussion is true, load of comments is needed in dialog e.g. in orders items
    public confirm(
            params: any,
            getAttachments: Function,
            getUploadUrl: Function,
            getDownloadUrl: Function,
            deleteAttachment: Function,
            allowEdit: Function,
            setAttachmentS3Keys: Function,
            // getItemLabel?: Function,
            hideRowLabel?: boolean,
            showPrivateControls?: boolean,
            privateConfig?: AttachmentPrivateConfig,
            idAttrName?: string
    ): Observable<any> {
        this.bsModalRef = this.modalService.show(AttachmetDialog);
        params.getAttachments = getAttachments;
        params.getUploadUrl = getUploadUrl;
        params.getDownloadUrl = getDownloadUrl;
        params.deleteAttachment = deleteAttachment;
        params.allowEdit = allowEdit();
        params.setAttachmentS3Keys = setAttachmentS3Keys;
        // params.getItemLabel = getItemLabel;
        params.isModal = true; // yes, its modal
        params.hideRowLabel = hideRowLabel;
        params.showPrivateControls = showPrivateControls;
        params.privateConfig = privateConfig;
        params.idAttrName = idAttrName;

        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}