<div class="table-settings">
	<div  class="btn-group" dropdown [isOpen]="isOpen" (onShown)="show()" (onHidden)="hide()" [autoClose]="autoClose" placement="bottom right" >
		<ng-template #settingsTmpl><span translate>COLUMN_VISIBILITY</span></ng-template>
		<button #dropdownToggle id="single-button" type="button" class="btn btn-secondary" dropdownToggle [ngClass]="{'ec-disabled': busy}"
			[popover]="settingsTmpl" triggers="mouseenter:mouseleave">
            <i class="fa fa-cogs"></i><i class="fa fa-caret-down caret"></i>
        </button>
        <div #popover *dropdownMenu role="menu" aria-labelledby="single-button" class="dropdown-menu dropdown-menu-right">
        	<div class="row-container">
	        	<div class="row-container">
	        		<strong translate>COLUMN_VISIBILITY</strong>
	        	</div>
	    	    <div class="row-container dropdown-item" [ngClass]="{'hidden': item.hidden || item.doNotShowInTableSettings, 'ec-disabled': item.alwaysVisible, 'clickable': !item.alwaysVisible}" *ngFor="let item of items" (click)="onSelect(item)">
	    	   		<span><i class="fa" [ngClass]="{'fa-square-o': !item.checked, 'fa-check-square-o': item.checked}"></i> <span> {{item.name | translate}}</span></span>
	    	    </div>
	    	    <div class="row-container">
	        		<a (click)="reset()" translate>RESET_TO_DEFAULT</a>
	        	</div>
	    	</div>
        </div>
    </div>
</div>