import { Component, OnDestroy, OnInit }       from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { LanguageService } from '../../../services/language.service';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as mime from 'mime';  // !!! node_modules/mime/index.js has to be edited and added .json to both 'require' !!!
import { EcmHttpService } from '@app/services/http/ecm.http.service';

type Language = 'SK' | 'CZ' | 'EN' | 'DE' | 'RU' | 'ES';

type PresentationGroup = {
    title: string;
    presentations: Presentation[];
};

type Presentation = {
    titleSK: string; // 'Profil spoločnosti'
    titleEN: string; // 'Company Profile'
    baseName: string; // a base for thumbnail .png and presentation .pdf, e.g. 'Profil spolocnosti'
    languages: Language[]; // what languages are the file available in
    title?: string; // actual title - EN or SK
    thumbnailUrl?: string; // actual URL of thumbnail
    fileUrl?: string;
};

@Component({
  selector: 'app-presentations-component',
  templateUrl: 'presentations.component.html',
  styleUrls: ['presentations.component.scss'],
})
export class PresentationsComponent implements OnInit, OnDestroy { 
    public presentationGroups: PresentationGroup[] = [];
    private subscriptions: Subscription[] = [];
    private bucket: string = '';
    
    constructor(
        public sharedService: SharedService,
        private languageService: LanguageService,
        private http: EcmHttpService,
    ) {
        this.bucket = this.sharedService.assetsS3BucketUrl + '/presentations';

        this.presentationGroups = [
            {
                title: 'BUSSINESS_PRESENTATIONS_GROUP',
                presentations: [
                    {
                        titleSK: 'Profil spoločnosti',
                        titleEN: 'Company Profile',
                        baseName: 'Profil spolocnosti',
                        languages: ['SK', 'EN', 'DE', 'RU'],
                    },
                    {
                        titleSK: 'Podpora predaja ložísk ZVL a prístup k zákaznikom',
                        titleEN: 'Support For The Sale Of ZVL Bearings And Access To Customers',
                        baseName: 'Podpora predaja lozisk ZVL a pristup k zakaznikom',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Prečo spolupracovať so ZVL SLOVAKIA',
                        titleEN: 'Why Cooperate With ZVL SLOVAKIA',
                        baseName: 'Preco spolupracovat so ZVL SLOVAKIA',
                        languages: ['SK', 'EN'],
                    },
                ],
            },
            {
                title: 'ASSORTMENT_PRESENTATIONS_GROUP',
                presentations: [
                    {
                        titleSK: 'Ložiská pre poľnohospodárstvo',
                        titleEN: 'Bearings For Agriculture',
                        baseName: 'Loziska pre polnohospodarstvo',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Ložiská pre železničný priemysel',
                        titleEN: 'Bearings For The Railway Industry',
                        baseName: 'Loziska pre zeleznicny priemysel',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Súdkové ložiská',
                        titleEN: 'Spherical Roller Bearings',
                        baseName: 'Sudkove loziska',
                        languages: ['SK', 'EN'],
                    },            
                    {
                        titleSK: 'Ložiská pre elektromotory',
                        titleEN: 'Bearings For Electric Motors',
                        baseName: 'Loziska pre elektromotory',
                        languages: ['SK', 'EN'],
                    },            
                    {
                        titleSK: 'Nové výrobky ZVL',
                        titleEN: 'Innovations In Product Portfolio',
                        baseName: 'Nove vyrobky ZVL',
                        languages: ['SK', 'EN'],
                    },            
                    {
                        titleSK: 'Ihlové ložiská',
                        titleEN: 'Needle Roller Bearings',
                        baseName: 'Ihlove loziska',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Stojaté ložikové telesá',
                        titleEN: 'Pillow Block Type Bearing Housing Units',
                        baseName: 'Stojate lozikove telesa',
                        languages: ['SK', 'EN'],
                    },        
                ],
            },
            {
                title: 'TECHNICAL_PRESENTATIONS_GROUP',
                presentations: [
                    {
                        titleSK: 'Kontrola a zariadenia na kontrolu kvality ložísk',
                        titleEN: 'Control And Equipment For Quality Assurance Of Bearings',
                        baseName: 'Kontrola a zariadenia na kontrolu kvality lozisk',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Montáž a demontáž ložísk',
                        titleEN: 'Mounting And Dismounting Of Bearings ',
                        baseName: 'Montaz a demontaz lozisk',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Poškodenia ložísk',
                        titleEN: 'Bearing Damage ',
                        baseName: 'Poskodenia lozisk',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Proces vyroby ložísk',
                        titleEN: 'Bearings Production Process ',
                        baseName: 'Proces vyroby lozisk',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Proces výroby ocele',
                        titleEN: 'Steel Production Process ',
                        baseName: 'Proces vyroby ocele',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Technická prezentácia ponúkaného sortimentu ložísk ZVL',
                        titleEN: 'Technical Presentation Of The Offered Range Of Bearings ZVL',
                        baseName: 'Technicka prezentacia ponukaneho sortimentu lozisk ZVL',
                        languages: ['SK', 'EN', 'ES'],
                    },
                    {
                        titleSK: 'Technické parametre ložísk a výber ložísk pre užívateľov',
                        titleEN: 'Bearing Technical Parameters And Bearings Selection For Users',
                        baseName: 'Technicke parametre lozisk a vyber lozisk pre uzivatelov',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Ustavovanie strojov a diagnostika',
                        titleEN: 'Setting Up And Measuring The Geometry Of The Machines',
                        baseName: 'Ustavovanie strojov a diagnostika',
                        languages: ['SK', 'EN'],
                    },
                    {
                        titleSK: 'Návrh ložiska, hriadeľa a kontrola životnosti',
                        titleEN: 'Setting Up And Measuring The Geometry Of The Machines',
                        baseName: 'Navrh loziska, hriadela a kontrola zivotnosti',
                        languages: ['SK', 'EN'],
                    },
                ],
            }
        ];

        this.setPresentations();
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.languageService.getLanguageObservable().subscribe(() => this.languageChanged())
        );
    }

    private setPresentations(): void {
        this.presentationGroups.forEach(group => {
            group.presentations = this.genaratePath(group.presentations);
        });
    }

    private genaratePath(presentations: Presentation[]): Presentation[] {
        const lang = this.getCurrentLanguage();
        const titleLang = this.getMainLanguage(lang);

        return presentations.map(presentation => ({
            ...presentation,
            title: presentation[`title${titleLang}`],
            thumbnailUrl: `${this.bucket}/thumbnails/${presentation.baseName}_${titleLang}.png`,
            fileUrl: this.getFileUrl(presentation.baseName, titleLang),
        }));
    }

    private getMainLanguage(language: Language): Language {
        return language === 'SK' || language === 'CZ' ? 'SK' : 'EN';
    }

    private getFileUrl(baseName: string, language: Language): string {
        return `${this.bucket}/presentations/${baseName}_${language}.pdf`
    }

    private getCurrentLanguage(): Language {
        return this.sharedService.appSettings.language.toUpperCase() as Language;
    }

    private languageChanged(): void {
        this.setPresentations();
    }

    public download(baseName: string, language?: Language): void {
        language = language || this.getMainLanguage(language);
        const url = this.getFileUrl(baseName, language);
        const fileName = baseName + '.pdf';

        this.http.getLocal(url, { responseType: 'blob' }, true)
        .subscribe(data => {
            const blob = new Blob([data], { type: mime.getType(fileName) });
            FileSaver.saveAs(blob, fileName);
        }, error => {
            console.log(error);
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
