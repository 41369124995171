import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { Monitoring } from '../../model/monitoring.model';
import { LanguageService } from '../../services/language.service';
import { LocalNumberPipe } from '../../locale.pipes.module';

@Injectable()
export class MonitoringService {

    constructor (private http: EcmHttpService,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /**
     * Loads list of monitoring
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getMonitoring (query) {
        const url = `/monitoring`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
    /**
     * Set monitoring events from catalog
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    setCatalogActivityToMonitoring (data) {
        const url = `/monitoring`;
        return this.http.post(url, JSON.stringify(data), null, true);
    }
    /**
     * Exports all
     */;
    exportAll(query) {
        const url = `/monitoring`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

