import { Observable, Subject } from 'rxjs';
import { OrderDialog } from './order.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { OrderDialogMode, OrderDialogResult } from './order.dialog.model';

@Injectable()
export class OrderDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: {mode: OrderDialogMode, data?: any, preorder?: boolean}): Observable<OrderDialogResult> {
        this.bsModalRef = this.modalService.show(OrderDialog, { keyboard: false, ignoreBackdropClick: true });
        this.bsModalRef.content.doneSubject = new Subject<OrderDialogResult>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
