import { Component, OnDestroy} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { FormService } from '../../../../services/form.service';
import { LanguageService } from '../../../../services/language.service';
// import { LocalDatePipe } from '../../../../locale.pipes.module';
import * as moment from 'moment-timezone';
import { Subject, Subscription } from 'rxjs';
import { CellChange } from '@app/model/table.model';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

export interface GridDateRendererComponentParams {
    validatorFn?: (dataRow: any) => boolean;
    validatorMessageFn?: (dataRow: any) => string;
    subjectReloadCellEditable?: Subject<any>;
    getEditable?: any;
    editable?: any;
    isColValid?: (p1: any, p2: any, p3: any) => boolean;
    useValidationBackground?: boolean;
    editedIdsObj?: any;
    onChange?: (p: any) => void;
    allowMultipleEdit?: boolean;
    cellChanged$?: Subject<CellChange>;
    time?: boolean;
}

interface MyParams extends ICellRendererParams, GridDateRendererComponentParams {}

@Component({
    selector: 'datepicker-cell',
    template: `<span *ngIf="!editable && params.data[params.column['colId']]" [ngClass]="{'fnt-red': !valid}">{{params.value | localdate:sharedService.appSettings.language:params.time}}</span>

                <ng-template #invalidTooltip><span translate>{{tooltipString}}</span></ng-template>
                <div *ngIf="editable" fxLayoutAlign="center" (mouseover)="mouseover()" [adaptivePosition]="true" placement="right"
                    [popover]="tooltipString ? invalidTooltip : null" container="body" triggers="mouseenter:mouseleave">
                    <input type="text" class="form-control" [ngStyle]="style"
                        style="width: 90px; margin-top: -6px; padding: 4px; height: 27px; font-size: 98%; text-align: center;"
    	              [value]="params.data[params.column['colId']] | localdate:sharedService.appSettings.language"
                      [disabled]="disabled"
                      (focus)="startEditing()"
    	              />
                </div>
              `
})
export class GridDateRendererComponent implements AgRendererComponent, OnDestroy {
    params: MyParams;
    editable: boolean;
    private dateFormat: string;
    public valid = true;
    style: object;
    disabled: boolean;

    tooltipString: string;
    subscriptions: Subscription[] = [];

    constructor(
        public sharedService: SharedService,
        private languageService: LanguageService,
        private formService: FormService
    ) {
        this.dateFormat = this.languageService.getLocale()[this.sharedService.appSettings.language].dateFormat;
    }

    agInit(params: MyParams): void {
        this.params = params;
        this.editable = params.hasOwnProperty('editable') ? params.editable : true; // editable always, but when editable in params, take that value
        this.editable = params.hasOwnProperty('getEditable') ? params.getEditable(params) : params.editable; // if function getEditable is defined use that to get editable value - used for editable on each row different

        // save original values for cancel action
        if (!this.params.data.hasOwnProperty('originalValues')) {
            this.params.data.originalValues = [];
        }
        if (this.params.data.originalValues.filter(e => e.colId == this.params.column['colId'])
            .length === 0
        ) {
            this.params.data.originalValues.push({
                colId: this.params.column['colId'],
                value: this.params.data[this.params.column['colId']]
            });
        } else {
            this.onChange(this.params.data[this.params.column['colId']]);
        }

        this.updateState();

        if (this.params.subjectReloadCellEditable) {
            this.params.subjectReloadCellEditable.subscribe(result => {
                if (this.params.rowIndex === result.rowIndex) {
                    this.editable = this.params.hasOwnProperty('getEditable') ? this.params.getEditable(this.params) : this.params.editable;  // reload editable value
                    this.isValid();
                }
            });
        }

        if (this.params.cellChanged$) {
            this.subscriptions.push(this.params.cellChanged$.subscribe(cellChange => {
                this.updateState(cellChange);
            }));
        }

    }

    private updateState(cellChange?: CellChange): void {
        this.style = this.getStyle();
        this.disabled = this.isDisabled();
        this.valid = this.isValid();
    }

    isValid(): boolean {
        // this validation is not needed - validates from isColValid for whole row
        if (this.params.colDef.field === 'dateOfferedValidity') {
            if (this.params.data.dateOfferedValidity !== null &&
                moment().isAfter(this.params.data.dateOfferedValidity, 'days')
            ) {
                return false;
            }
            return true;
        }
        if (this.params.validatorFn) {
            this.valid = this.params.validatorFn(this.params.data);
            return this.valid;
            // return this.basketService.isItemDateValid(params.data, params.minDateAttrName);
            // if (params.data.deliveryDate === null) {
            //     this.valid = params.data.availabilityState === 'PARTIALLY_FULFILLED';
            //     return this.valid;
            // } else if (params.data.availabilityState === 'PARTIALLY_FULFILLED') {
            //     this.valid = false;
            //     return this.valid;
            // } else {
            //     this.valid = (new Date(params.data.minDeliveryDate)) <= (new Date(this.formService.getDateStringFromIsoDate(params.data.deliveryDate)));
            //     return this.valid;
            // }
        }
        this.valid = this.params.isColValid 
            ? this.params.isColValid(this.params.column['colId'], this.params.data[this.params.column['colId']],
                this.params.data) 
            : true;
        return this.valid;
    }

    getStyle(): object {
        // if  useValidationBackground is false , dont change background color
        if (typeof this.params.useValidationBackground === 'boolean' && this.params.useValidationBackground === false) {
            return {
                'background-color': ''
            }
        }
        return {
            'background-color': !this.isValid() ? 'rgba(255, 84, 98, 0.5)' : '' // mark red when not valid
        }
    }

    onChange(newDateString: string) {
        let originalValue = '';
        const this_ = this;

        this.params.data.originalValues.forEach(function(item) {
            if (item.colId === this_.params.column['colId']) {
                originalValue = item.value;
            }
        });

        if (newDateString !== originalValue) { // less then original iso string date
            this.params.data[this.params.column['colId']] = newDateString;
            this.params.data.edited = true;
            if (this.params.editedIdsObj) {
                this.params.editedIdsObj[this.params.data.id] = true;
            }
            if (this.params.onChange) { // call onCahnge function on column params if defined
                this.params.onChange(this.params);
            }
        }
    }

    startEditing() {
        if (this.isDisabled()) { return; }
        this.params.api.startEditingCell({
            rowIndex: this.params.rowIndex,
            colKey: this.params.column['colId'],
            keyPress: null,
            charPress: ''
        });
    }

    isDisabled(): boolean {
        if (!this.params.editedIdsObj || this.params.allowMultipleEdit) {return false;}
        if (Object.keys(this.params.editedIdsObj).length === 0) {return false;}
        return !(!!this.params.editedIdsObj[this.params.data.id]);
    }

    mouseover() {
        if (this.params.validatorMessageFn) {
            this.tooltipString = this.params.validatorMessageFn(this.params.data);
        }
        // if (this.params.minDateAttrName) {
        //     if (this.params.data.availabilityState === 'PARTIALLY_FULFILLED' && this.params.data.deliveryDate !== null) {
        //         this.tooltipString = 'DELIVERY_DATE_MUST_BE_EMPTY';
        //     } else if (this.params.data.availabilityState !== 'PARTIALLY_FULFILLED' && this.params.data.deliveryDate === null) {
        //         this.tooltipString = 'DELIVERY_DATE_MUST_BE_SET';
        //     } else if (this.params.data.availabilityState !== 'PARTIALLY_FULFILLED' &&
        //             (new Date(this.params.data.minDeliveryDate)) > (new Date(this.formService.getDateStringFromIsoDate(this.params.data.deliveryDate)))) {
        //         this.tooltipString = 'DELIVERY_DATE_INVALID';
        //     } else {
        //         this.tooltipString = null;
        //     }
        // }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
