<div style="margin-top: -5px; padding-left: 5px">
    <!-- <ng-template #toolTemplateSplit>{{'SPLIT_SELECTED' | translate | lowercase}}</ng-template>
    <ng-template #toolTemplateSplit>{{'SPLIT_SELECTED' | translate | lowercase}}</ng-template> -->

    <div class="input-container">
        <span fxFlex="38px" fxLayoutAlign="start center">
            <span *ngIf="params.showAttachments && params.data.attachmentsCount > 0" class="clickable attachment-count center-text" [ngClass]="{'empty': params.data.attachmentsCount === 0}"
                (click)="attachments()">
                <i class="fa fa-paperclip"></i>
                <span>{{ params.data.attachmentsCount }}</span>
            </span>
        </span>
        <span fxFlex="38px" fxLayoutAlign="start center">
            <!-- IS ADMIN NEWS -->
            <span *ngIf="canEdit && isVisibleComments" class="clickable comment-count center-text" [ngClass]="{'empty': params?.data.postsCount === 0}"
                (click)="discussions()">
                <i class="fa fa-comments" [ngClass]="{'empty': params?.data.postsCount === 0}"></i>
                <span *ngIf="params?.data.postsCount > 0">{{ params?.data.postsCount }}</span>
            </span>
            <!-- IS NOT ADMIN NEWS-->
            <span *ngIf="!canEdit && isVisibleComments" class="clickable comment-count center-text" (click)="discussions()">
                <i class="fa fa-comments"></i>
                <span></span>
            </span>
        </span>
        <span fxFlex="26px" fxLayoutAlign="center center" style="padding-top: 3px;" *ngIf="canEdit">
            <span *ngIf="params.showUnpublish && newsValid" class="unpublish-button center-text" [ngClass]="{'empty': params.data.unpublish}"
                (click)="unpublish()">
                <ng-template #popUnpublishInfo><div translate>NEWS_UNPUBLISH</div></ng-template>
                <i class="fa fa-check-circle-o clickable" aria-hidden="true" [popover]="popUnpublishInfo" container="body" [adaptivePosition]="true" placement="left" triggers="mouseenter:mouseleave" style="font-size: 150%"></i>
            </span>
        </span>
        <span fxFlex="26px" fxLayoutAlign="center center" style="padding-top: 3px;">
            <span *ngIf="params.showMarkAsRead && !params.data.read" class="show-mark-as-read-button center-text" [ngClass]="{'empty': params.data.showMarkAsRead}"
                (click)="markAsRead(params.data.id)">
                <ng-template #popMarkAsReadButtonInfo><div translate>NEWS_MARK_AS_READ</div></ng-template>
                <i class="clickable fa fa-check-circle fnt-lime" aria-hidden="true" [popover]="popMarkAsReadButtonInfo" container="body" [adaptivePosition]="true" placement="left" triggers="mouseenter:mouseleave" style="font-size: 150%"></i>
            </span>


            <span *ngIf="params.showMarkAsUnread && params.data.read" class="show-mark-as-unread-button center-text" [ngClass]="{'empty': params.data.showMarkAsUnread}"
                (click)="markAsUnread(params.data.id)">
                <ng-template #popMarkAsUnreadButtonInfo><div translate>NEWS_MARK_AS_UNREAD</div></ng-template>
                <i class="clickable fa fa-check-circle fnt-blue-lighter" aria-hidden="true" [popover]="popMarkAsUnreadButtonInfo" container="body" [adaptivePosition]="true" placement="left" triggers="mouseenter:mouseleave" style="font-size: 150%"></i>
            </span>
        </span>
    </div>




</div>