import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Subject, Subscription } from 'rxjs';
import { BasketService  } from '../../../../services/basket/basket.service'
import { CustomerStoresAvailabilityDialogService  } from '../../../../components/basket/customerStoresAvailabilityComponent/customer.stores.availability.dialog.service'
import { SplitBasketItemDialogService } from '../../../../components/basket/splitBasketItemsDialogComponent/split.basket.item.dialog.service'
import { FormService } from '../../../../services/form.service';
import { OriginalValue, RowActionsDef } from '@model/table.model';
import { BasketActions } from '@model/basket.model';
import { SharedService } from '@services/shared.service';
import { ToastService } from '@services/toastService/toast.service';

let focusDuplicatedRowListener;

@Component({
    selector: 'app-actions-renderer',
    // template: ` <div style="font-size: 20px; margin-top: -2px; padding: 0 5px;">
    //                 <ng-template #toolTemplateCustomerStores>{{'CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY' | translate | lowercase}}</ng-template>
    //                 <ng-template #toolTemplateSplit>{{'SPLIT' | translate | lowercase}}</ng-template>
    //                 <ng-template #toolTemplateDuplicate>{{'DUPLICATE' | translate | lowercase}}</ng-template>
    //                 <ng-template #toolTemplateCorrect>{{'CORRECT_DELIVERY_DATE' | translate | lowercase}}</ng-template>
    //                 <ng-template #toolTemplateDelete>{{'DELETE_SELECTED' | translate | lowercase}}</ng-template>
    //                 <span fxFlex="25px">
    //                     <i class="clickable fa fa-database" style="margin-right: 5px; opacity: 0.9" *ngIf="!params.data.edited && getEditedCount() == 0 && showDistributorStoresAction()"
    //                         [popover]="toolTemplateCustomerStores" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"
    //                         (click)="openCustomerStoresAvailability()">
    //                     </i>
    //                 </span>
    //                 <span fxFlex="25px">
    //                     <i class="clickable fa fa-share-alt" style="margin-right: 5px; opacity: 0.9" (click)="splitRow()" *ngIf="!params.data.edited && getEditedCount() == 0 && showSplitAction()" [popover]="toolTemplateSplit" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
    //                 </span>
    //                 <span fxFlex="25px">
    //                     <i class="clickable fa fa-clone" style="margin-right: 5px; opacity: 0.9" (click)="duplicateRow()" *ngIf="!params.data.edited && getEditedCount() == 0 && showCloneAction()" [popover]="toolTemplateDuplicate" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
    //                 </span>
    //                 <span fxFlex="25px">
    //                     <i class="clickable fa fa-calendar fnt-red" style="margin-right: 5px; opacity: 0.9" (click)="correctRow()" *ngIf="!params.data.edited && getEditedCount() == 0 && showCorrectAction()" [popover]="toolTemplateCorrect" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
    //                 </span>
    //                 <span fxFlex="25px">
    //                     <i class="clickable fa fa-trash" style="margin-right: 5px; opacity: 0.9" (click)="deleteRow()" *ngIf="!params.data.edited && getEditedCount() == 0" [popover]="toolTemplateDelete" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
    //                 </span>
    //             </div>
    //             <div style="font-size: 20px; margin-top: -2px;">
    //                 <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="params.data.edited && this.params.isRowValid(params.data)"></i>
    //                 <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel(true)" *ngIf="params.data.edited"></i>
    //             </div>
    //             `
    template: ` <div style="font-size: 20px; margin-top: -2px; padding: 0 5px;">
                    <span *ngIf="!params.data.edited && getEditedCount() == 0">
                        <span fxFlex="25px" *ngFor="let action of actions">
                            <span *ngIf="this.params.data.actions[action.id]">
                                <i class="clickable" [ngClass]="action.iconClass"
                                    style="margin-right: 5px; opacity: 0.9"
                                    [popover]="action?.popover" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"
                                    (click)="action.method()">
                                </i>
                            </span>
                        </span>
                    </span>
                </div>
                <div style="font-size: 20px; margin-top: -2px;">
                    <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="params.data.edited && this.params.isRowValid(params.data)"></i>
                    <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel(true)" *ngIf="params.data.edited"></i>
                </div>
                `
})
export class GridEditableRowActionsRendererComponent implements AgRendererComponent {
    params: any;
    subjectReloadCurrent: Subject<any>;
    newBasketItemId: number;  // newly duplicated row ID
    private subscriptions: Subscription[] = [];
    actions: RowActionsDef[];
    constructor(
        private sharedService: SharedService,
        private basketService: BasketService,
        private formService: FormService,
        private splitBasketItemDialogService: SplitBasketItemDialogService,
        private customerStoresAvailabilityDialogService: CustomerStoresAvailabilityDialogService,
        private toastService: ToastService
    ) {
        this.subjectReloadCurrent = basketService.getReloadCurrentSubject();

        this.actions = [
            {
                id: BasketActions.ShowDistributorStores,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-database',
                method: this.openCustomerStoresAvailability.bind(this),
                popover: this.sharedService.translateService.instant('CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY').toLowerCase()
            },
            {
                id: BasketActions.Split,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-share-alt',
                method: this.splitRow.bind(this),
                popover: this.sharedService.translateService.instant('SPLIT').toLowerCase()
            },
            {
                id: BasketActions.Duplicate,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-clone',
                method: this.duplicateRow.bind(this),
                popover: this.sharedService.translateService.instant('DUPLICATE').toLowerCase()
            },
            {
                id: BasketActions.CorrectAmountOrdered,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-archive  fnt-red',
                method: this.correctAmountOrdered.bind(this),
                popover: this.sharedService.translateService.instant('CORRECT_AMOUNT_ORDERED').toLowerCase()
            },
            {
                id: BasketActions.CorrectDeliveryDate,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-calendar  fnt-red',
                method: this.correctRow.bind(this),
                popover: this.sharedService.translateService.instant('CORRECT_DELIVERY_DATE').toLowerCase()
            },
            {
                id: BasketActions.Delete,
                type: 'ICON_ONLY' as const,
                iconClass: 'fa fa-trash',
                method: this.deleteRow.bind(this),
                popover: this.sharedService.translateService.instant('DELETE_SELECTED').toLowerCase()
            }
        ].filter(basketAction => {
            if (basketAction.id === BasketActions.CorrectAmountOrdered &&
                !this.sharedService.params.features.basket.correctAmountOrderedAvailable
            ) {
                return false;
            }
            return true;
        });
    }

    agInit(params: any): void {
        this.params = params;
        this.subscriptions.push(this.params.cancelObservable.subscribe(() => {
            this.cancel(false);
        }));
    }

    deleteRow() {
        this.basketService.deleteOrderedItem(this.params.data.id)
        .subscribe(data => {
            this.params.observable.next({id: this.params.data.id, amountOrderedDif: (this.params.data.amountOrdered * (-1)),
                rowWeightDif: (this.params.data.amountOrdered * (-1)) * this.params.data.weight,
                rowPriceDif: (this.params.data.amountOrdered * (-1)) * this.params.data.unitPrice
            });
            delete this.params.selection.ids[this.params.data.id];
            this.subjectReloadCurrent.next();
        }, err => console.log(err));
    }

    /**
     * Try to find a freshly inserted (duplicated) row and focus it
     * @param {event} event ag-grid modelUpdated event
     */
    focusDuplicatedRow(event) {
        // Try to find the ID - we will not find it if it is not on the current page
        let rowId = null;
        this.params.api.forEachNode(node => {
            if (node.data.id === this.newBasketItemId) {
                rowId = node.id;
            }
        });

        if (rowId !== null) {
            this.params.api.startEditingCell({
                rowIndex: rowId,
                colKey  : 'amountOrdered'
            });
        }

        this.params.api.removeEventListener('modelUpdated', focusDuplicatedRowListener);  // we only want to run it once so remove the listener
    }

    /**
     * Duplicates a row in the basket
     */
    duplicateRow() {
        this.basketService.duplicateOrderedItems([this.params.data.id])
        .subscribe(data => {
            this.newBasketItemId = data.insertId;  // ID of the inserted item

            // Find the inserted item and try to focus it
            focusDuplicatedRowListener = this.focusDuplicatedRow.bind(this);  // we need to work around the 'this' because of the class
            this.params.api.addEventListener('modelUpdated', focusDuplicatedRowListener);

            this.subjectReloadCurrent.next();
        }, err => console.log(err));
    }

    /**
     * Correct a row in the basket
     */
    correctRow() {
        this.basketService.correctDeliveryDatesOrderedItems([this.params.data.id])
        .subscribe(() => {
            this.subjectReloadCurrent.next();
            this.toastService.addSuccess('DELIVERY_DATE_CORRECTED');
        }, err => console.log(err));
    }

    public correctAmountOrdered(): void {
        this.basketService.correctAmountOrdered([this.params.data.id])
            .subscribe(() => {
                this.subjectReloadCurrent.next();
                this.toastService.addSuccess('AMOUNT_ORDERED_CORRECTED');
            }, err => console.log(err));
    }

    // showDistributorStoresAction() {
    //     return (this.params.data.availabilityState === 'PARTIALLY_FULFILLED' && this.params.showCustomerStock);
    // }

    /**
     * If to show the split action
     */
    // showSplitAction() {
    //     return (this.params.data.amountPackages.toString().replace(' ', '') % 1 === 0) &&
    //         (this.params.data.availabilityState !== 'COMPLETELY_FULFILLED') &&
    //         (this.params.data.availabilityFulfillings.length > 1 ||
    //             (this.params.data.availabilityFulfillings.length === 1 && this.params.data.availabilityState === 'PARTIALLY_FULFILLED'));
    // }
    /**
     * If to show the Clone action
     */
    // showCloneAction() {
    //     return (this.params.data.amountPackages.toString().replace(' ', '') % 1 === 0)
    // }

    /**
     * If to show the Correct delivery date action
     */
    // showCorrectAction(): boolean {
    //     return !this.basketService.isItemDateValid(this.params.data);
    // }

    /**
     * Splits a row in the basket
     */
    splitRow() {
        this.splitBasketItemDialogService.open(this.params.data)
        .subscribe(() => {
            this.subjectReloadCurrent.next();
        });
    }

    save() {
        this.basketService.updateOrderedItem(
            this.params.data.id,
            { amountOrdered: this.params.data.amountOrdered,
              deliveryDate: this.params.data.deliveryDate,
              packing: this.params.data.packing
            }
        )
        .subscribe(data => {
            this.params.data.amountOrder = null;
            this.params.data.priceTotal = 0.00;

            this.params.data.originalValues.forEach(item => {
                if (item.colId === 'amountOrdered') {
                    // update totals
                    const amountDif = this.params.data.amountOrdered - item.value
                    const rowWeightDif = amountDif * this.params.data.weight;
                    const rowPriceDif = amountDif * this.params.data.unitPrice;
                    this.params.observable.next({id: this.params.data.id, amountOrderedDif: amountDif, rowWeightDif: rowWeightDif, rowPriceDif: rowPriceDif});
                }
                item.value = this.params.data[item.colId];
            });

            this.cleanupAfterSaveOrCancel();
        }, err => console.log(err));
    }

    private cleanupAfterSaveOrCancel(): void {
        this.params.data.edited = false;
        delete this.params.editedIdsObj[this.params.data.id];
        
        if (Object.keys(this.params.editedIdsObj).length === 0) {
            this.subjectReloadCurrent.next();
        }
    }

    cancel(redraw) {
        const changedColumns = [];
        this.params.data.originalValues.forEach((item: OriginalValue) => {
            this.params.data[item.colId] = item.value;
            changedColumns.push(item.colId);
        });
        // We need to reload even after cancel because a batch partial commit might have been done
        // and some commited (saved) rows are disabled so we need to refresh them
        this.cleanupAfterSaveOrCancel();

        if (redraw) {
            this.params.api.refreshCells({ rowNodes: [this.params.node], columns: changedColumns });
            // this.params.api.redrawRows({rowNodes: [this.params.node]});
        }
    }

    getEditedCount(): number {
        return Object.keys(this.params.editedIdsObj).length;
    }

    openCustomerStoresAvailability() {
        this.customerStoresAvailabilityDialogService.confirm({ids: [this.params.data.id]});
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
