<div [ngClass]="{'component-outer-container': makeCollapsible}">
<form (ngSubmit)="submitForm(rfqQuestionnaire)" #rfqQuestionnaire="ngForm" name="rfqQuestionnaire">
  <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()"
       [attr.role]="makeCollapsible ? 'button': ''" data-target="collapseQuestionnaire">
          <div fxLayout="row">
            <h3 style="width: 100%;"><i class="fa fa-wpforms"></i>
              <span>{{'RFQ_QUESTIONNAIRE_TITLE'|translate}} - {{'RFQ_QUESTIONNAIRE_ADDITIONAL_DATA'|translate}}</span>
              <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }" class="fa" style="float: right"></i>
            </h3>
          </div>
        </div> <!-- end of component header container --->

        <div [@slideInOut]="isCollapsed" [ngClass]="{'component-body-container': makeCollapsible}" id="collapseQuestionnaire" >
          <div fxLayout="row">
            <!--<div class="rfq-detail-questionnaire-first"></div>-->
            <div class="rfq-detail-questionnaire">
              <label for="customerName" translate>RFQ_QUESTIONNAIRE_CUSTOMER_NAME</label>
              <input [(ngModel)]="questionnaire.customerName" #customerName="ngModel" type="text" id="customerName"
                     name="customerName" placeholder="" maxlength="50" (ngModelChange)="onElementChange($event)" class="form-control" 
                     [disabled]="readOnly">
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="customerLocality" translate>RFQ_QUESTIONNAIRE_CUSTOMER_LOCALITY</label>
              <input [(ngModel)]="questionnaire.customerLocality" #customerLocality="ngModel" type="text" id="customerLocality"
                     name="customerLocality" placeholder="" maxlength="50" (ngModelChange)="onElementChange($event)" class="form-control" 
                     [disabled]="readOnly">
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
                <label for="rfq-q-customer-size-select" translate>RFQ_QUESTIONNAIRE_CUSTOMER_SIZE</label>
                <select [(ngModel)]="questionnaire.customerSize" title="{{'RFQ_QUESTIONNAIRE_CUSTOMER_SIZE'|translate}}"
                        id="rfq-q-customer-size-select"
                        name="rfq-q-customer-size-select" (ngModelChange)="onElementChange($event)" class="form-control"
                        [disabled]="readOnly">
                  <option [value]="null" name="rfq-q-customer-size-select" [selected]="!questionnaire.customerSize">{{'PLEASE_SELECT_OPTION'|translate}}</option>
                  <option *ngFor="let size of customerSizeList" value="{{size}}" name="rfq-q-customer-size-select">{{size|translate}}</option>
                </select>
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="customerSegment"><span translate>RFQ_QUESTIONNAIRE_CUSTOMER_SEGMENT</span> *</label>
                <input [(ngModel)]="questionnaire.segment" #customerSegment="ngModel" type="text" id="customerSegment"
                       name="customerSegment" placeholder=""  maxlength="70"  (ngModelChange)="onElementChange($event)" class="form-control"
                       [disabled]="readOnly">
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="customerApplication"><span translate>RFQ_QUESTIONNAIRE_CUSTOMER_APPLICATION</span> *</label>
              <input [(ngModel)]="questionnaire.application" #customerApplication="ngModel" type="text"
                     id="customerApplication" name="customerApplication" placeholder="" maxlength="50"  (ngModelChange)="onElementChange($event)" class="form-control"
                     [disabled]="readOnly">
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="customerConcurentBrand" translate>RFQ_QUESTIONNAIRE_CONCURRENT_BRAND</label>
              <input [(ngModel)]="questionnaire.competition" #customerConcurentBrand="ngModel" type="text"
                     id="customerConcurentBrand" name="customerConcurentBrand" placeholder=""  maxlength="50" (ngModelChange)="onElementChange($event)" class="form-control"
                     [disabled]="readOnly">
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="rfq-q-repeat-probability-select"><span translate>RFQ_QUESTIONNAIRE_REPEAT_PROBABILITY</span> *</label>
                <select [(ngModel)]="questionnaire.repeatProbability" id="rfq-q-repeat-probability-select"
                        name="rfq-q-repeat-probability-select" (ngModelChange)="onElementChange($event)" class="form-control"
                        [disabled]="readOnly">
                  <option [value]="null"
                          name="rfq-q-repeat-probability-select">{{'PLEASE_SELECT_OPTION'|translate}}</option>
                  <option *ngFor="let probability of repeatProbabilityList" name="rfq-q-repeat-probability-select"
                          [value]="probability" title="{{probability|translate}}">{{probability|translate}}</option>
                </select>
            </div>
          </div>
          <div fxLayout="row">
            <div class="rfq-detail-questionnaire">
              <label for="repeatOtherImportantNotes" translate>RFQ_QUESTIONNAIRE_OTHER_IMPORTANT_NOTES</label>
              <textarea [(ngModel)]="questionnaire.notes" #repeatOtherImportantNotes="ngModel"
                        id="repeatOtherImportantNotes" name="repeatOtherImportantNotes" placeholder="" rows="8"
                        cols="70" maxlength="200" (ngModelChange)="onElementChange($event)" class="form-control"
                        [disabled]="readOnly"></textarea>
            </div>
          </div>
          <div fxLayout="row">
            <span translate>MANDATORY_FIELD</span>
          </div>
          <div style="min-width: 100%;text-align:right;">
            <button class="btn-primary text-right" style="margin-right: 10px;" [ngClass]="{'ec-disabled': !isChanged}" [disabled]="!isChanged" *ngIf="!readOnly">
              <i class="fa fa-envelope-open" [ngClass]="{'ec-disabled': !isChanged}"></i>
              <a [ngClass]="{'ec-disabled': !isChanged}" translate>RFQ_Q_SEND_QUESTIONNAIRE</a>
            </button>
            <button class="btn-secondary text-right" style="margin-right: 10px;" [ngClass]="{'ec-disabled': !isChanged}" [disabled]="!isChanged" *ngIf="!readOnly" (click)="resetForm()">
              <i class="fa fa-close" [ngClass]="{'ec-disabled': !isChanged}"></i>
              <a [ngClass]="{'ec-disabled': !isChanged}" translate>CANCEL_CHANGES</a>
            </button>
          </div>
        </div><!-- end of component body container --->

</form>
</div>
