import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { UserService } from '../../services/user/user.service';
import { SharedService } from '../../services/shared.service';
import { ToastService } from '../../services/toastService/toast.service';
import { PermissionsService } from '../../services/permissions.service';
import { HomepageService } from '@app/services/homepage.service';
import { UserNotificationsRecurrency } from '@app/model/user.notifications-recurrency.model';
import { Subscription } from 'rxjs';

type NotificationsRecord = {
    userNotificationRecurrency: UserNotificationsRecurrency,
    userNotificationRecurrencyOriginal: UserNotificationsRecurrency,
    hasChanged: boolean,
};

@Component({
  selector: 'user-profile',
  templateUrl: 'user.profile.component.html',
  styleUrls:  ['./user.profile.component.scss']
})
export class UserProfileComponent implements OnDestroy { 
    @ViewChild('passwordForm', { static: true }) public passwordForm: NgForm;
    email: 		string;
    givenName:  string;
    familyName: string;
    phoneNumber: string;
    detailsChanged: boolean;

    oldPassword: 	  string;
    newPassword:	  string;
    newPasswordAgain: string;
    busy: boolean;

    passHelpVisible: boolean = false;

    newPassValid = {
        lowercase: false,
        uppercase: false,
        number: false,
        length: false
    }

    homepageSettings = [];
    selectedHomepageSettings: any = {};
    selectedHomepageSettingsOld;
    homepageSettingsChanged = false;

    public notificationsRecords: NotificationsRecord[] = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public sharedService: SharedService,
        private toastService: ToastService,
        private userService: UserService,
        private permissionsService: PermissionsService,
        private homepageService: HomepageService,
    ) {
        this.initDetails();

        this.loadUserNotificationRecurrency();

        this.subscriptions.push(
            this.userService.onRepresentativeChange$.subscribe(() => this.loadUserNotificationRecurrency())
        );
    }

    /**
     * Checks if something in details changed
     */
    detailsOnChange() {
        this.detailsChanged = this.email != this.sharedService.user.email ||
                              this.givenName != this.sharedService.user.firstName ||
                              this.familyName != this.sharedService.user.lastName ||
                              this.phoneNumber != this.sharedService.user.phoneNumber;
    }

    /**
     * Save personal settings
     */

    savePersonalSettings () {
        const values = {homepage: this.selectedHomepageSettings.id};
        if (values.homepage === null || values.homepage === 'null' ) {
            delete values.homepage;
        }
        this.userService.setPersonalDetails(values).subscribe(data => {
            this.homepageSettingsChanged = false;
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }
    /**
     * Saves details
     */
    saveDetails() {
        const values = {givenName: this.givenName, familyName: this.familyName, phoneNumber: this.phoneNumber, email: this.email, language: this.sharedService.appComponent.selectedLanguage};

        // console.log('values', values);
        this.userService.setUserDetails(values).subscribe(data => {
            this.detailsOnChange();
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    /** 
     * Inits detail values to values stored in shared service user object
     */
    initDetails() {
        const this_ = this;

        this.homepageService.getHomepageSettingsDef().forEach((homepage) => {
            if (this.sharedService.user.homepage && this.sharedService.user.homepage === homepage.id) {
                this.selectedHomepageSettings = {id: homepage.id, name: homepage.name};
            }
            if (this.permissionsService.hasPermission(homepage.permission)) {
                this.homepageSettings.push({ id: homepage.id, name: homepage.name });
            }
        });

        if (!this.sharedService.user.homepage) {
            this.selectedHomepageSettings = {id: 'null', name: 'PLEASE_SELECT_OPTION'};
        }
        if (this.selectedHomepageSettings.length <= 0) {
            this.selectedHomepageSettings = {id: 'orders', name: 'ORDERS'};
        }
        // this.selectedHomepageSettings = this.sharedService.user.homepage ? this.sharedService.user.homepage : 'orders';
        this.selectedHomepageSettingsOld = this.selectedHomepageSettings;

        this.email 	    = this.sharedService.user.email;
        this.givenName  = this.sharedService.user.firstName;
        this.familyName = this.sharedService.user.lastName;
        this.phoneNumber = this.sharedService.user.phoneNumber;
        this.detailsOnChange();
    }

    onSelectHomepageSettings(ev) {
        // this.selectedHomepageSettings = {id: ev.selected.id, name: ev.selected.name};
        this.selectedHomepageSettings = this.homepageSettings.filter(item => item.id === ev.selected.id)[0];
        if ( this.selectedHomepageSettings ) {
            if (this.selectedHomepageSettings.id !== this.selectedHomepageSettingsOld.id) {
                this.homepageSettingsChanged = true;
            } else {
                this.homepageSettingsChanged = false;
            }
        }
    }

    public saveUserNotificationRecurrency(record: NotificationsRecord): void {
        this.userService.saveUserNotificationRecurrency(record.userNotificationRecurrency).subscribe(() => {
            record.userNotificationRecurrencyOriginal = { ...record.userNotificationRecurrency };
            record.hasChanged = false;
        },
        err => {
            console.error(err);
        });
    }

    public onNotificationsRecurrencyChange(record: NotificationsRecord): void {
        record.hasChanged = true;
    }

    public cancelUserNotificationRecurrency(record: NotificationsRecord): void {
        record.userNotificationRecurrency = { ...record.userNotificationRecurrencyOriginal };
        record.hasChanged = false;
    }

    /**
     * Saves new user password
     * Checks if new and new again passwords are the same before sending
     * Resets form after success
     */
    savePassword() {
        if (this.newPassword !== this.newPasswordAgain) {
            this.toastService.addError('PASSWORD_NEW_ARE_DIFFERENT_ERROR');
            return;
        }

        this.busy = true;
        this.userService.changePassword(this.oldPassword, this.newPassword).subscribe(data => {
            this.oldPassword = this.newPassword = this.newPasswordAgain = '';
            this.passwordForm.reset();
            this.busy = false;
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    /**
     * Sets validity object of new password
     */
    validatePassword(password: string) {
        password = password == null ? '' : password;
        this.newPassValid.lowercase = password.match(/[a-z]/) != null;
        this.newPassValid.uppercase = password.match(/[A-Z]/) != null;
        this.newPassValid.number = password.match(/\d/) != null;
        this.newPassValid.length = password.length >= 8;
    }

    /**
     * hides help with password
     */
    hidePassHelp() {
        if (this.newPassValid.lowercase &&
            this.newPassValid.uppercase &&
            this.newPassValid.number &&
            this.newPassValid.length) {
            this.passHelpVisible = false;
        }
    }
    /**
     * shows help with password
     */
    showPassHelp() {
        this.passHelpVisible = true;
    }

    public isChangeRepresentativeVisible(): boolean {
        let representativeAttrs = ['representsCustomer', 'representsAuthorizedSeller', 'representsSupplier', 'readAllRows'];
        var representativeOptionsCount = 0;
        for (var i = 0; i < representativeAttrs.length; ++i) {
            if (this.sharedService.user[representativeAttrs[i]] && this.sharedService.user[representativeAttrs[i]].id) { 
                representativeOptionsCount++;
            }
            if (representativeOptionsCount > 1) {
                return true;
            }
        }
        return false;
    }

    /**
     * Sets representative of user
     */
    public setRepresentative(representative: string): void {
        if (representative === this.sharedService.user.representative) {return;}
        this.userService.changeRepresentative(representative);
    }

    private loadUserNotificationRecurrency(): void {
        this.userService.getUserNotificationRecurrency().subscribe(data => {
            this.notificationsRecords = data.map(recurrencyRow => ({
                userNotificationRecurrency: recurrencyRow,
                userNotificationRecurrencyOriginal: { ...recurrencyRow },
                hasChanged: false,
            }));
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
