<div *ngIf="rfq" id="rfqDetail">
	<div class="row-container wider">
		<div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: -5px; margin-bottom: 10px;">
      <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/{{backUrl}}" [queryParams]="backUrl == 'rfqs' ? sharedService.lastRfqsQueryParams : backQueryParams">
				<i class="fa fa-arrow-circle-left"></i>
				<a translate>BACK</a>
      </button>
      
      <div *ngIf="repreOverrideCustomerName">
        <i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i>
        <span translate class="fnt-red" style="font-weight: bold" >YOU_ARE_WORKING_AS_CUSTOMER</span>:
        {{repreOverrideCustomerName}}
      </div>
		</div>

		<div *ngIf="addItemsVisible && isAddToRfqAvailable">
			<rfq-detail-add-items [rfqId]="rfq.id"></rfq-detail-add-items>
		</div>

      <div class="row-container">
        <h3 style="display: inline-block;"><i class="fa fa-file-text-o"></i> <span translate>RFQ</span>&ngsp;
          <span>{{rfq.rfqNumber}}</span></h3>
        <span class="row-container">
          <span translate>{{'RFQ_STATE_' + rfq.rfqState}}</span>
          <span class="fa fa-circle" style="border: 2px solid #fff; border-radius: 8px;" [ngClass]="{'fnt-gray': rfq.rfqState == 'P',
                                       'fnt-red': rfq.rfqState == 'N',
                                       'fnt-yellow': rfq.rfqState == 'O',
                           			 'fnt-green': rfq.rfqState == 'S',
                           			 'fnt-black': rfq.rfqState == 'F'}">
          </span>
        </span>

        <ng-template #rfqDetailDiscussionPopover>{{'DISCUSSION' | translate | lowercase}}</ng-template>
        <span class="row-container clickable" (click)="scrollToComments()" *ngIf="sharedService.hasPermission('rfq', 'GET') && rfq.rfqState !== 'P'" [popover]="rfqDetailDiscussionPopover" container="body" triggers="mouseenter:mouseleave">
          <i class="fa fa-comments" style="margin-right: 5px;"></i><span>{{sharedService.currentDiscussionComments.length}}</span>
        </span>

        <ng-template #rfqDetailAttachmentsPopover>{{'ATTACHMENTS' | translate | lowercase}}</ng-template>
        <span class="row-container clickable" (click)="scrollToAttachments()" *ngIf="sharedService.hasPermission('rfq', 'GET') && rfq.rfqState !== 'P'" [popover]="rfqDetailAttachmentsPopover" container="body" triggers="mouseenter:mouseleave">
          <i class="fa fa-paperclip" style="margin-right: 5px;"></i><span>{{attachmentsCount}}</span>
        </span>

        <ng-template #rfqDetailQuestionnairePopover>{{'RFQ_QUESTIONNAIRE_TITLE' | translate | lowercase}}</ng-template>
        <span class="questionnaire-notif-text clickable" (click)="scrollToQuestionnaire()" style="margin-top: 26px; margin-left: 8px;" [popover]="rfqDetailQuestionnairePopover" container="body" triggers="mouseenter:mouseleave">
          <i class="fa fa-wpforms" style="margin-right: 5px;"></i>
          <span *ngIf="!isQuestionnaireFulfilled" translate class="fnt-red" style="font-weight: bold" >QUESTIONNAIRE_IS_NOT_FILLED</span>
        </span>

        <ng-template #rfqDetailJournalPopover>{{'JOURNAL' | translate | lowercase}}</ng-template>
        <span class="journal-notif-text clickable" *ngIf="isElementAvailable.journal" (click)="scrollToJournal()" style="margin-top: 26px; margin-left: 8px;" [popover]="rfqDetailJournalPopover" container="body" triggers="mouseenter:mouseleave">
          <i class="fa fa-history" style="margin-right: 5px;"></i>
        </span>   

        <button class="btn-primary" style="margin: 10px 0 0 10px;" (click)="toggleAddItems()" 
                *ngIf="!addItemsVisible && buttonsVisibility.INSERT_ITEM && isAddToRfqAvailable">
          <i class="fa fa-plus"></i><span translate>ADD_TO_RFQ</span>
        </button>
        <button class="btn-secondary" style="margin: 10px 0 0 10px;" (click)="toggleAddItems()" 
                *ngIf="addItemsVisible && buttonsVisibility.INSERT_ITEM && isAddToRfqAvailable">
          <i class="fa fa-times"></i><span translate>ADD_TO_RFQ_CLOSE</span>
        </button>
     

        </div>
  
      </div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="16" class="row-container">
			<h4 translate>RFQ_NUMBER</h4>
			<span>{{rfq.rfqNumber}}</span>
		</div>
		<div fxFlex.gt-sm="16" class="row-container">
			<h4 translate>DATE_OF_CREATION</h4>
			<span>{{rfq.dateCreated | localdate:sharedService.appSettings.language:true}}</span>
		</div>
		<div fxFlex.gt-sm="16" class="row-container">
			<h4 translate>PERSON_CREATED</h4>
			<span>{{rfq.userCreatedFullName}}</span>
		</div>
		<div fxFlex.gt-sm="16" class="row-container">
      <app-rfq-detail-note [rfq]="rfq" [isNoteEditable]="isNoteEditable" (edited)="noteEdited($event)"></app-rfq-detail-note>
		</div>
		<div fxFlex.gt-sm="16" class="row-container" *ngIf="isElementAvailable.assignedSellerName">
			<h4 translate>ASSIGNED_SELLER_NAME</h4>
			<span>{{rfq.assignedSellerName}}</span>
		</div>
		<div fxFlex.gt-sm="16" class="row-container" *ngIf="isElementAvailable.totalPrice">
			<h4 translate>TOTAL_PRICE</h4>
      <span>{{rfq.totalPrice | localcurrency:sharedService.appSettings.language:rfq.totalPriceCurrency}}</span>
		</div>
	</div>

	<div class="row-container wider">
	    <div fxLayout="column">
        <div class="input-container" *ngIf="projectPriceLists?.length > 1">
          <label translate>PRICE_LISTS</label>
          <dropdown-component [items]="projectPriceLists" [selected]="currentPriceList" (onSelect)="onSelectProjectPriceList($event)"
            [autoClose]="true" [disabled]="!isPriceListEditable"></dropdown-component>
        </div>
        <rfq-detail-items *ngIf="rfq"
          [rfqId]="rfq.id"
          [rfq]="rfq"
          [customerId]="rfq.customerId"
          [showNewRowButton]="buttonsVisibility.INSERT_ITEM"
          [reloadObservable]="subjectReloadItems"
          [reloadGridOptions]="subjectReloadItemsGridOptions"
          (rowsDataLoaded)="getRowsDataChange($event)"
        ></rfq-detail-items>
	    </div>
	</div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
	<div fxFlex="33"></div>
	<div fxFlex="33"></div>

		<div fxFlex="33">
			<div fxLayout="row" fxLayoutAlign="end">
        <button class="btn-primary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.SUBMIT_RFQ" (click)="rfqAction('SUBMIT_RFQ')"
                [disabled]="!allowSendRfq || edited.note || edited.questionnaire || edited.discussionInForm"
                [ngClass]="{'ec-disabled': !allowSendRfq || edited.note || edited.questionnaire || edited.discussionInForm}"
        >
          <i class="fa fa-check-square-o"></i>
          <a translate>RFQ_SUBMIT_RFQ</a>
        </button>
        <button class="btn-secondary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.DELETE_RFQ" (click)="deleteRfq()"
                [disabled]="edited.note || edited.questionnaire || edited.discussionInForm"
                [ngClass]="{'ec-disabled': edited.note || edited.questionnaire || edited.discussionInForm}"
        >
          <i class="fa fa-times-circle"></i>
          <a translate>RFQ_DELETE_RFQ</a>
        </button>
        <button class="btn-primary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.OPEN_RFQ" (click)="rfqAction('OPEN_RFQ')"
        >
          <i class="fa fa-envelope-open"></i>
          <a translate>RFQ_OPEN_RFQ</a>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 10px">

        <button class="btn-primary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.ORDER_AND_CLOSE_RFQ"
                (click)="rfqOrderAndClose()"
                [disabled]="!canDoOrderAndClose"
                [ngClass]="{'ec-disabled': !canDoOrderAndClose}"
        >
          <i class="fa fa-check"></i>
          <span translate>RFQ_ORDER_AND_CLOSE</span>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="end">
        <button class="btn-primary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.CLOSE_RFQ"
                (click)="startRfqClose()"
        >
          <i class="fa fa-close"></i>
          <a translate>{{user.representative === representative.AS ? 'RFQ_SUBMIT' : (allItemsInStates_R_or_O ? 'RFQ_SUBMIT' : 'RFQ_SUBMIT_WITHOUT_ORDER')}}</a>
        </button>

        <button class="btn-primary" style="margin-right: 10px;"
                *ngIf="buttonsVisibility.REOPEN_RFQ"
                (click)="showModalStatusReopen()"
        >
          <i class="fa fa-undo"></i>
          <a translate>RFQ_REOPEN</a>
        </button>
      </div>

		</div>
	</div>
  <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <div fxFlex="33">
      <attachments #attachments id="attachments"
        [entityId]="rfq?.id"
        [attachmentsGetter]="getAttachments"
        [getUploadUrl]="getUploadUrl"
        [getDownloadUrl]="getDownloadUrl"
        [deleteAttachment]="deleteAttachment"
        [allowEdit]="buttonsVisibility.INSERT_ITEM"
        (attachmentS3Keys)="setAttachmentS3Keys($event)"
        (attachmentsChanged)="attachmentsChanged($event)"
        [reload]="subjectReloadAttachments"
        [makeCollapsible]="true"
        [(isCollapsed)]="isCollapsedChild.attachments"
      ></attachments>
    </div>

    <div fxFlex="33">
      <div *ngIf="sharedService.hasPermission('rfq', 'GET') && rfq.rfqState !== 'P'">
        <div>
          <discussion #discussion id="discussion"
                      itemArea="rfq"
                      [getItemLabel]="getCommentItemLabel"
                      [itemId]="route.snapshot.params['id']"
                      (added)="discussionChanged($event)"
                      [makeCollapsible]="true"
                      [(isCollapsed)]="isCollapsedChild.discussion"
                      (edited)="discussionInFormEdited($event)"
          ></discussion>
        </div>
      </div>
    </div>
    <div fxFlex="33">
      <app-rfq-detail-questionnaire #questionnaire id="questionnaire"
        [rfq]="rfq"
        [makeCollapsible]="true"
        [readOnly]="!rfqPermissionService.isQuestionnaireEditable()"
        (filledMandatory)="setQuestionnaireFulfill($event)"
        [(isCollapsed)]="isCollapsedChild.questionnaire"
        (edited)="questionnaireEdited($event)"
      ></app-rfq-detail-questionnaire>
    </div>
  </div>

  <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between"
    fxLayoutGap="25px" *ngIf="rfq && rfq.rfqCloseQuestionnaire">

    <div fxFlex="33">
      <app-rfq-close-questionnaire
        [rfqCloseQuestionnaire]="rfq.rfqCloseQuestionnaire"
        [makeCollapsible]="true"
        [readOnly]="true"
        [showTitle]="false"
        [(isCollapsed)]="isCollapsedChild.rfqCloseQuestionnaire"
      ></app-rfq-close-questionnaire>
    </div>

    <div fxFlex="33">
      <!-- just a placeholder because of the fxLayoutGap -->
    </div>
    <div fxFlex="33">
      <!-- just a placeholder because of the fxLayoutGap -->
    </div>

  </div>

  <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" *ngIf="isElementAvailable.journal">
    <div fxFlex="100">
      <journal-detail #rfqJournal id="rfqJournal" 
        *ngIf="rfq.rfqState !== 'P'"
        [entityId]="rfq?.id"
        [dataGetter]="getJournal"
        [optionsGetter]="getJournalGridOptions"
        [filterItemsGetter]="getJournalFilterItems"
        [columnsGetter]="getJournalColumns"
        [columnsDefsGetter]="getJournalColumnsDefs"
        [reload]="subjectReloadJournal"
        [(isCollapsed)]="isCollapsedChild.journal"
        [makeCollapsible]="true"
      ></journal-detail>
    </div>
  </div>

</div>
