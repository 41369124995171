import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TableService } from "../../../../services/table.service";
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { EditedIdsObject } from '@app/model/table.model';

export interface GridTypeaheadRendererComponentParams {
    editedIdsObj?: EditedIdsObject;
    maxLength?: string; // '10'
    maxWidth?: string; // '100px'
    textAlign?: string; //'center'
    // prefixString?: string; // ''
    allowMultipleEdit?: boolean;
    field?: string;
    editable?: boolean;
    parenthesisIfNotFound?: boolean;
    getEditable?: (params: ICellRendererParams) => boolean;
    isColValid?: (coldId: string, colValue: any, row: object) => boolean;
}

interface MyParams extends ICellRendererParams, GridTypeaheadRendererComponentParams {}

@Component({
    selector: 'typeahead-renderer',
    template: `
    <div *ngIf="editable" (click)="startEditing({value: this.typeaheadVal})" class="typeahead-container" style="padding: 0 3px;">
        <input #input class="form-control bs-popover" container="body" type="text" 
            [(ngModel)]="params.data[params.column.colId]"
            [attr.maxlength]="maxLength" 
            [disabled]="isDisabled()"
            (keypress)="onKeyPress($event);"
            [popover]="popError" 
            triggers=""
            [placeholder]="placeholder"
            [ngStyle]="getStyle()"
            style="margin-top: -6px; padding: 4px; height: 27px; font-size: 98%; display: inline-block; max-width: 100px;" 
            value="{{ params.value }}">
    </div>
    <span *ngIf="!editable" class="typeahead-container text" style="padding: 0 3px; text-align: right;">{{typeaheadVal}}</span>
    `
})
export class GridTypeaheadRendererComponent implements AgRendererComponent {
    public params: MyParams;
    public typeaheadVal : any;
    // isEditing = false;
    private field: string;
    // isAS: boolean;
    private setEditableSubscription: Subscription;
    public editable: boolean;
    public placeholder = '';             // if value is empty, use placeholder like "(not found)"
    public maxLength: string = '10';     // optional
    private maxWidth: string = '100px';   // optional
    private textAlign: string = 'center';  // optional
    // prefixString: string = '';    // optional prefix string
    public style: object;

    constructor(
        private tableService: TableService, 
        public sharedService: SharedService,
        public translateService: TranslateService) {
    }

    agInit(params: MyParams): void {
        let this_ = this;
        this.params = params;
        this.field = params.field;
        // this.isAS = this.sharedService.user.representative === 'AS';

        this.editable = params.hasOwnProperty('editable') ? params.editable : true; // editable always, but when editable in params, take that value
        this.editable = params.hasOwnProperty('getEditable') ? params.getEditable(params) : this.editable; // if function getEditable is defined use that to get editable value - used for editable on each row different
        this.maxLength = this.params.maxLength ? this.params.maxLength : this.maxLength;
        this.maxWidth = this.params.maxWidth ? this.params.maxWidth : this.maxWidth;
        this.style = {'text-align': this.params.textAlign ? this.params.textAlign : this.textAlign};
        // this.prefixString = ('prefixString' in this.params) ? this.params.prefixString : this.prefixString;

        // save original values for cancel action
        if (!this.params.data.hasOwnProperty('originalValues')) { this.params.data.originalValues = []; }
        if (this.params.data.originalValues.filter(e => {
            return e.colId == this.params.column['colId'];
        }).length == 0) {
            this.params.data.originalValues.push({ colId: this.params.column['colId'], value: this.params.value });
        }

        /** If rfqProduct is null, return not founded else return value */
        if (this.editable) {
            this.typeaheadVal = params.data[this.field];
            if ( params.data[this.field] === null && params.data.id > 0) {
                this.typeaheadVal = this.translateService.instant('NOT_FOUND');
            }
        } else {
            this.typeaheadVal = params.value;
            if ( params.value === null && params.data.id > 0) {
                this.typeaheadVal = this.translateService.instant('NOT_FOUND');
                // if (this.field === 'rfqProduct') {
                if (this.params.parenthesisIfNotFound) {
                    this.typeaheadVal = '('+this.translateService.instant('ag_grid_blanks')+')';
                }
                
            }
        }
        
        if (this.params.value && this.params.value !== null && this.params.value !== '') {
            this.placeholder = this.params.value;
          } else {
            this.placeholder = this.sharedService.translateService.instant('NOT_FOUND');
            // if (this.field === 'rfqProduct') {
            if (this.params.parenthesisIfNotFound) {
               this.placeholder = '('+this.translateService.instant('ag_grid_blanks')+')';
            }
          }
          if (this.sharedService.user.representative !== 'AS') { this.placeholder = '';}
        
            
        this.setEditableSubscription = this.tableService.getEditableObservable().subscribe(function(res:any) {
            if (res.editable) {
                if (res.editableColIds.indexOf(this_.params.column['colId']) > -1) {
                    this_.editable = true;
                }
        }
        });
        
        // if (this.params.subjectReloadCellEditable) {
        //     this.params.subjectReloadCellEditable.subscribe(result => {
        //         if (this.params.rowIndex === result.rowIndex) {
        //             this.editable = this.params.hasOwnProperty('getEditable') ? this.params.getEditable(this.params) : this.params.editable;  // reload editable value
        //         }
        //     });
        // }
    }

    isDisabled() {
        if (!this.params.editedIdsObj || this.params.allowMultipleEdit) {return false;}
        if (Object.keys(this.params.editedIdsObj).length == 0) {return false;}
        return !(!!this.params.editedIdsObj[this.params.data.id]);
    }

    isValid() {
        return (this.params.isColValid 
            ? this.params.isColValid(this.params.column['colId'], this.params.data[this.params.column['colId']], this.params.data)
            : true);
    }

    startEditing(data?: any) {
        this.params.api.startEditingCell({
            rowIndex: this.params.rowIndex,
            colKey: this.params.column['colId'],
            keyPress: null,
            charPress: '',
            // data: data,
            // isPopup: false
        });
    }

    getStyle() {
        return {
            // 'background-color': this.isMoreThan() || !this.isValid()
            //                     ? 'rgba(255, 84, 98, 0.5)' : '', // mark red when more than given value or 0
            'max-width': this.maxWidth
        }
    }

    // mouseover(element) {
    //     if (this.params.noMoreThan && 
    //         this.params.data[this.params.noMoreThan] < this.params.data[this.params.column['colId']] && 
    //         this.params.data[this.params.column['colId']] > 0) {
    //         element.show();
    //     }
    // }

    // used to start editing when user selects text in input and then starts to write
    public onKeyPress(event: KeyboardEvent): void { 
        // this code is probably irrelevant!
        // var this_ = this;
        // if (    !(event.keyCode >= 37 && event.keyCode <= 40) &&  // allow arrows
        //         event.keyCode != 46 &&                            // allow delete
        //         event.keyCode != 8) {                             // allow backspace
        //         event.preventDefault();
        //         return;
        //     }
        //     if (!(event.keyCode >= 37 && event.keyCode <= 40)) { // edited only if not arrows
        //         setTimeout(function() {
        //             this_.startEditing();
        //         }, 0);
        //     }

        // Timeout helps to start edit
        // Known issue: The firt keypress after selecting text in input doeas not trigger search in typeahed editor.
        // I tried sending the char to the editor via 'keyPress' or 'charPress' in api.startEditingCell() but it dod not help
        setTimeout(() => this.startEditing());
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

}