import { Injectable } from '@angular/core';
import { combineLatest, from, interval, Observable,  of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EcmHttpService, EcmHttpQueryParams } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import { BrandCategory } from '../../model/brand.category.model';
import { AsWebLinkTypes, Product, ProductAvailabilityObj } from '../../model/product.model';
import { CapitalizePipe } from '../../locale.pipes.module';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { PermissionsService } from '../permissions.service';
import { Representative } from '@app/model/user.model';

const DAYS_TO_BE_A_NEW_PRODUCT = 31;

export enum CatalogueModes {
    Catalogue = 'Catalogue',
    CatalogueSale = 'CatalogueSale',
    PurchaseCatalogue = 'PurchaseCatalogue'
}

@Injectable()
export class CatalogueService {
    private brandCategoriesCached: BrandCategory[];
    private newProductsExist: Map<CatalogueModes, boolean> = new Map();

    private routes: Map<CatalogueModes, string> = new Map();

    constructor (
        private translateService: TranslateService,
        private http: EcmHttpService,
        private sharedService: SharedService,
        private permissionsService: PermissionsService
    ) {
        this.getProductAvailability = this.getProductAvailability.bind(this);
        this.isColumnHidden = this.isColumnHidden.bind(this);

        this.routes.set(CatalogueModes.Catalogue, 'catalogue');
        this.routes.set(CatalogueModes.CatalogueSale, 'catalogue-sale');
        this.routes.set(CatalogueModes.PurchaseCatalogue, 'purchase-catalogue');
    }

    /**
     * Removes cached data
     */
    public clean(): void {
        this.brandCategoriesCached = null;
        this.newProductsExist.clear();
    }

    public getRoute(catalogueMode: CatalogueModes): string {
        if (this.routes.get(catalogueMode) === undefined) {
            console.error('Undefined catalogueMode', catalogueMode);
        }
        return this.routes.get(catalogueMode);
    }

    public getCatalogueModeFromPath(path: string): CatalogueModes {
        for (const item of this.routes.entries()) {
            if (item[1] === path) {
                return item[0];
            }
        }

        throw new Error(`No catalogue mode found for route: ${path}`);
    }

    /**
     * Loads categories for brand with given brandCode
     */
    public getBrandCategories(catalogueMode: CatalogueModes): Observable<BrandCategory[]> {
        // caching - if already available data return observable of those data
        if (this.brandCategoriesCached) {
            return of(this.brandCategoriesCached);
        } else { // data are not already available so call http request
            return this.http.get(`/product-groups/url/` + this.sharedService.apParams.hostUrl).pipe(map((data) => {
                let categories: BrandCategory[] = [];
                const route = '/' + this.getRoute(catalogueMode);
                data.forEach(function(category) {
                    const categoryObj = new BrandCategory(category.productionGroupCode1, category.productionGroupCode1Name, route,
                        {group1: category.productionGroupCode1});
                        
                    category.children.forEach(function(child) {
                        const childObj = new BrandCategory(child.productionGroupCode2, child.productionGroupCode2Name, route,
                            {group1: category.productionGroupCode1, group2: child.productionGroupCode2});
                        categoryObj.children.push(childObj);
                    });
                    categories.push(categoryObj);
                });
                this.brandCategoriesCached = categories;
                return categories;
            } ));
        }
    }

    private getApiEndpoint(catalogueMode: CatalogueModes): string {
        let url = `/products`;
        if (catalogueMode === CatalogueModes.Catalogue) {
            if (this.sharedService.hasPermission('products-reduced/1', 'GET')) { url = `/products-reduced/1`; }
            if (this.sharedService.hasPermission('products-reduced/2', 'GET')) { url = `/products-reduced/2`; }
            if (this.sharedService.hasPermission('products-reduced/3', 'GET')) { url = `/products-reduced/3`; }
        } else if (catalogueMode === CatalogueModes.CatalogueSale) {
            if (!this.sharedService.hasPermission('products-reduced/5', 'GET')) {
                throw new Error('Permission products-reduced/5 missing');
            } else {
                url = '/products-reduced/5'
            }
        } else if (catalogueMode === CatalogueModes.PurchaseCatalogue) {
            if (this.sharedService.hasPermission('products-reduced/4', 'GET')) { url = `/products-reduced/4`; }
        }

        return this.sharedService.user
            ? url
            : `public-products/url/` + this.sharedService.apParams.hostUrl;
    }

    /**
     * Loads products
     */
    public getProducts(query: EcmHttpQueryParams, catalogueMode: CatalogueModes, hidePreloader?: boolean): Observable<{total: number, rows: Product[]}> {
        const url = this.getApiEndpoint(catalogueMode);
        const _query = catalogueMode === CatalogueModes.CatalogueSale
            ? { ...query, ...{
                'unitPrice.operator': 'ne',
                'unitPrice.value': 0,
            } }
            : query;

        return this.http.get(url, this.http.prepareOptions(_query), !!hidePreloader).pipe(map(data => {
            const products: Product[] = [];
            data.rows.forEach(product => {
                products.push(this.getNewProduct(product, catalogueMode));
            })

            return {total: data.totalCount, rows: products};
        }));
    }

    private getNewProduct(apiProduct: any, catalogueMode: CatalogueModes, brandCode?: string): Product {
        const _brandCode = brandCode ? brandCode : apiProduct.brandCode;
        const urlObj = this.getProductUrlObj(catalogueMode, apiProduct.eCommProductId, _brandCode, 
            apiProduct.asWebProductName, apiProduct.asWebUrlPart, apiProduct.asWebLinkType);
        
        return new Product(
            apiProduct.amount,
            apiProduct.amountUponVerification,
            apiProduct.amountPlAs2,
            apiProduct.amountSrAs2,
            apiProduct.amountSkAs4,
            apiProduct.amountCzAs4,
            apiProduct.customerStockAmount,
            apiProduct.dimensionB,
            apiProduct.dimensionID,
            apiProduct.dimensionOD,
            apiProduct.rdMin,
            apiProduct.rdMax,
            apiProduct.eCommProductId,
            apiProduct.eCommProductIdOld,
            apiProduct.eCommProductIdNew,
            apiProduct.productName,
            apiProduct.shortName,
            apiProduct.brandCode,
            apiProduct.productionGroupCode1,
            apiProduct.productionGroupCode1Name,
            apiProduct.productionGroupCode2,
            apiProduct.productionGroupCode2Name,
            apiProduct.unitPrice,
            apiProduct.amountInPacking,
            apiProduct.weight,
            apiProduct.futureDeliveryPeriod1,
            apiProduct.futureDeliveryPeriod1_4,
            apiProduct.futureDeliveryPeriod2,
            apiProduct.futureDeliveryPeriod3,
            apiProduct.futureDeliveryPeriod4,
            apiProduct.futureDeliveryPeriod5,
            apiProduct.futureDeliveryPeriod5_7,
            apiProduct.futureDeliveryPeriod6,
            apiProduct.futureDeliveryPeriod7,
            apiProduct.futureDeliveryAs2,
            apiProduct.futureDelivery0_30As5,
            apiProduct.futureDelivery30_60As5,
            apiProduct.futureDelivery60_90As5,
            apiProduct.futureDelivery90_120As5,
            apiProduct.futureDelivery120_As5,
            apiProduct.futureDelivery30_As5,
            urlObj.url,
            urlObj.isUrlAbsolute,
            {group1: apiProduct.productionGroupCode1, group2: apiProduct.productionGroupCode2, detail: apiProduct.eCommProductId},
            apiProduct.asWebProductName,
            apiProduct.asWebUrlPart,
            apiProduct.asWebLinkType,
            this.getProductTooltip(apiProduct.eCommProductIdOld, apiProduct.eCommProductIdNew),
            this.getProductIconClass(apiProduct.eCommProductIdOld, apiProduct.eCommProductIdNew)
        );
    }

    private getProductUrlObj(catalogueMode: CatalogueModes, eCommProductId: string, brandCode: string, asWebProductName: string,
        asWebUrlPart: string, asWebLinkType: AsWebLinkTypes
    ): {url: string, isUrlAbsolute: boolean} {

        if (asWebProductName) {
            switch (asWebLinkType) {
                case AsWebLinkTypes.DirectConnection:
                    // FE#778 - temporarily turn off direct opening of the interactive catalogue
                    // return { url: this.getAsProductUrl(asWebProductName, asWebUrlPart), isUrlAbsolute: true };
                    return { 
                        url: this.getEcProductUrl(catalogueMode, eCommProductId, brandCode), 
                        isUrlAbsolute: false
                    };

                case AsWebLinkTypes.AlternativeConnection:
                    // FE#778 - temporarily turn off direct opening of the interactive catalogue
                    // return { url: this.getAsProductUrl(asWebProductName, asWebUrlPart), isUrlAbsolute: true };
                    return { 
                        url: this.getEcProductUrl(catalogueMode, eCommProductId, brandCode), 
                        isUrlAbsolute: false
                    };

                case AsWebLinkTypes.ContactAs:
                    return { 
                        url: this.getEcProductUrl(catalogueMode, eCommProductId, brandCode), 
                        isUrlAbsolute: false
                    };
                default:
                    console.error('Unknown asWebLinkType:' + asWebLinkType);
            }
        } else {
            return {
                url: '/' + this.getRoute(catalogueMode) + '/' + brandCode + '/' + encodeURIComponent(eCommProductId),
                isUrlAbsolute: false
            };
        }
    }

    private getEcProductUrl(catalogueMode: CatalogueModes, eCommProductId: string, brandCode: string): string {
        return '/' + this.getRoute(catalogueMode) + '/' + brandCode + '/' + encodeURIComponent(eCommProductId);
    }

    public getAsProductUrl(asWebProductName: string, asWebUrlPart: string): string {
        let language: string;

        if (this.sharedService.appSettings.language === 'sk' || this.sharedService.appSettings.language === 'cz') {
            language = '';
        } else {
            language = this.sharedService.appSettings.language + '/';
        }

        return  (
            'https://www.zvlbearing-catalog.sk/' +
            language + 
            'katalog/' +
            asWebUrlPart +
            '/?meno=' +
            asWebProductName +
            '&Odeslano=1'
        );
    }

    /**
     * Tooltip in a case that there is a new replacement of a product
     */
    public getProductTooltip (eCommProductIdOld: string, eCommProductIdNew: string): string {
        if (eCommProductIdOld) {
            return this.translateService.instant('PRODUCT_NAME_CHANGE') + ' ' + eCommProductIdOld;
        } else if (eCommProductIdNew) {
            return this.translateService.instant('PRODUCT_NAME_CHANGE') + ' ' + eCommProductIdNew;
        }
        return ''
    }

    /**
     * Icon diplayed next to the product name in a case that there is a new replacement of a product
     */
    public getProductIconClass (eCommProductIdOld: string, eCommProductIdNew: string): string {
        if (eCommProductIdOld || eCommProductIdNew) {
            return 'clickable fnt-red fa fa-exclamation-circle';
        }
        return ''
    }

    /**
     * Exports all
     */
    public exportAll(query: EcmHttpQueryParams, catalogueMode: CatalogueModes): Observable<{exportUrl: string}> {
        // Remove column 'amountUponVerification'
        const index = query.exportColumns.indexOf('amountUponVerification');
        if (index !== -1) {
            query.exportColumns = query.exportColumns.filter((col, i) => i !== index);
            query.exportColumnsTranslated = query.exportColumnsTranslated.filter((col, i) => i !== index);
        }

        return this.http.get(this.getApiEndpoint(catalogueMode), this.http.prepareOptions(this.getExportQuery(query)));
    }

    public exportAllExtended(query: EcmHttpQueryParams, catalogueMode: CatalogueModes): Observable<{exportUrl: string}> {
        const newQuery: any = {
            exportToFormat: query.exportToFormat,
            orderBy: 'productName ASC'
        }
        
        newQuery.exportColumns = this.getExtendedExportColumns(catalogueMode, [
            'futureDeliveryPeriod1', 'futureDeliveryPeriod2', 'futureDeliveryPeriod3', 
            'futureDeliveryPeriod4', 'futureDeliveryPeriod5', 'futureDeliveryPeriod6',
            'futureDeliveryPeriod7']
        );
        
        newQuery.exportColumnsTranslated = this.getExtendedExportColumnsTranslated(catalogueMode, [
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION')) + ' - ' + this.translateService.instant('TO') + ' 15 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION')) + ' - ' + this.translateService.instant('TO') + ' 30 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION')) + ' - ' + this.translateService.instant('TO') + ' 45 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION')) + ' - ' + this.translateService.instant('TO') + ' 60 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION_PLAN')) + ' - ' + this.translateService.instant('TO') + ' 90 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION_PLAN')) + ' - ' + this.translateService.instant('TO') + ' 120 ' + this.translateService.instant('TO_DAYS'),
            new CapitalizePipe().transform(this.translateService.instant('IN_PRODUCTION_PLAN')) + ' - ' + this.translateService.instant('OVER') + ' 120 ' + this.translateService.instant('TO_DAYS')
        ]);

        return this.http.get(this.getExportUrl(), this.http.prepareOptions(newQuery));
    }

    public exportAllExtendedByMonth(query: EcmHttpQueryParams, catalogueMode: CatalogueModes): Observable<{exportUrl: string}> {
        const newQuery: any = {
            exportToFormat: query.exportToFormat,
            orderBy: 'productName ASC'
        }

        const months = this.getMonths(6);

        newQuery.exportColumns = this.getExtendedExportColumns(catalogueMode, months.map(month => 
            `futureDeliveryMonth${month}`));

        newQuery.exportColumnsTranslated = this.getExtendedExportColumnsTranslated(catalogueMode,
            months.map(month => 
                new CapitalizePipe().transform(this.translateService.instant(`MONTH_${month}`))
            )
        );

        return this.http.get(this.getExportUrl(), this.http.prepareOptions(newQuery));
    }

    private getExtendedExportColumns(catalogueMode: CatalogueModes, columnsToInsert: string[]): string[] {
        return [
            'productName', 'amount',
            // ...(this.isColumnHidden('amountUponVerification', catalogueMode) 
            //     ? [] 
            //     : ['amountUponVerification']
            // ),
            ...columnsToInsert,
            'amountInPacking',
            'dimensionID', 'dimensionOD', 'dimensionB', 'rdMin', 'rdMax', 'weight', 
            'productionGroupCode1Name', 'productionGroupCode2Name'
        ];
    }

    private getExtendedExportColumnsTranslated(catalogueMode: CatalogueModes, columnsToInsert: string[]): string[] {
        return [
            this.translateService.instant('PRODUCT_ID'),
            new CapitalizePipe().transform(this.translateService.instant('STOCK')),
            // ...(this.isColumnHidden('amountUponVerification', catalogueMode) 
            //     ? [] 
            //     : [new CapitalizePipe().transform(this.translateService.instant('AMOUNT_UPON_VERIFICATION'))]
            // ),
            ...columnsToInsert,
            this.translateService.instant('AMOUNT_MOQ'),
            this.sharedService.translateService.instant('DIMENSIONS') + ' - d',
            this.sharedService.translateService.instant('DIMENSIONS') + ' - D',
            this.sharedService.translateService.instant('DIMENSIONS') + ' - B',
            this.translateService.instant('RD_MIN'),
            this.translateService.instant('RD_MAX'),
            this.translateService.instant('WEIGHT'),
            this.translateService.instant('PRODUCT_CATEGORY'),
            this.translateService.instant('PRODUCT_SUBCATEGORY'),
        ];
    }

    private getMonths(count: number): string[] {
        const currentMonth = (new Date()).getMonth() + 1;
        let months: number[] = [];

        // Get months from the current month till december
        const lastMonth = Math.min(currentMonth + count - 1, 12);
        for(let i = currentMonth; i <= lastMonth; i++) {
            months.push(i);
        }

        if (months.length < count) {
            // Get the missing months from january
            for (let i = 1; i <= count - months.length; i++) {
                months.push(i);
            }
        }
        return months.map(month => month.toString());
    }
    
    private getExportQuery(query): any {
        // transform query
        if (query.group1 && query.group1 !== 'all') {
            query['productionGroupCode1.value'] = query.group1;
            query['productionGroupCode1.operator'] = 'eq';
        }
        if (query.group2) {
            query['productionGroupCode2.value'] = query.group2;
            query['productionGroupCode2.operator'] = 'eq';
        }
        delete query.group1;
        delete query.group2;
        return query;
    }

    private getExportUrl(): string {
        let urlView = `/products`;
        if (this.sharedService.hasPermission('products-reduced/1', 'GET')) { urlView = `/products-reduced/1`; }
        if (this.sharedService.hasPermission('products-reduced/2', 'GET')) { urlView = `/products-reduced/2`; }
        if (this.sharedService.hasPermission('products-reduced/3', 'GET')) { urlView = `/products-reduced/3`; }

        return this.sharedService.user
            ? urlView
            : `public-products/url/` + this.sharedService.apParams.hostUrl;
    }

    /**
     * Loads product details
     */
    public getProductDetails(brandCode: string, eCommProductId: string, catalogueMode: CatalogueModes): Observable<Product> {
        const url = this.getApiEndpoint(catalogueMode) + `/` + brandCode + `/` + eCommProductId;

        return this.http.get(url).pipe(map((data) => {
            return this.getNewProduct(data, catalogueMode, brandCode);
        } ));
    }

    public getProductAvailability(brandCode: string, eCommProductId: string, clickedColumn?: string): Observable<ProductAvailabilityObj> {
        const url = `/products-availability/` + brandCode + `/` + eCommProductId;
        return clickedColumn ? this.http.get(url, this.http.prepareOptions({clickedColumn: clickedColumn})) : this.http.get(url);
    }

    public isColumnHidden(colName: string, catalogueMode: CatalogueModes): boolean {
        const user = this.sharedService.user;
        const params = this.sharedService.params;

        const validationMap = {
            'shortName': () => !(this.permissionsService.hasPermission('purchaseCatalogueRead') &&
                catalogueMode === CatalogueModes.PurchaseCatalogue),
            'customerStockAmount': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET') ||
                !(user.representative === 'customer' &&
                    user.subcustomer &&
                    user.representsCustomer.customerStockActive &&
                    user.representsCustomer.customerStockForSubcustomers &&
                    this.sharedService.hasPermission('products-reduced/3', 'GET')
                ),
            'amount': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'amountUponVerification': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                catalogueMode === CatalogueModes.CatalogueSale ||
                this.sharedService.hasPermission('products-reduced/2', 'GET') ||
                (params.cache.customerIdForAmountUponVerification &&
                user.representative === 'customer' &&
                user.representsCustomer.id === params.cache.customerIdForAmountUponVerification),
            'amountPlAs2': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'amountSrAs2': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'amountSkAs4': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'amountCzAs4': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'futureDeliveryPeriod1_4': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                catalogueMode === CatalogueModes.CatalogueSale ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'futureDeliveryPeriod5_7': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                catalogueMode === CatalogueModes.CatalogueSale ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'futureDeliveryAs2': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'futureDelivery0_30As5': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'futureDelivery30_As5': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                this.sharedService.hasPermission('products-reduced/2', 'GET'),
            'unitPrice': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                user.representative !== Representative.customer ||
                this.sharedService.hasPermission('products-reduced/1', 'GET') ||
                this.sharedService.hasPermission('products-reduced/2', 'GET') ||
                this.sharedService.hasPermission('products-reduced/3', 'GET'),
            'priceTotal': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                !(this.permissionsService.hasPermission('ordersCreate') &&
                    !(user.subcustomer &&
                        this.sharedService.hasPermission('products-reduced/3', 'GET'))
                ),
            'amountOrder': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                !this.permissionsService.hasPermission('ordersCreate'),
            'add': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                !this.permissionsService.hasPermission('ordersCreate'),
            'amountRfq': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                catalogueMode === CatalogueModes.CatalogueSale ||
                !this.sharedService.hasPermission('rfq', 'POST'),
            'addRfq': () => catalogueMode === CatalogueModes.PurchaseCatalogue ||
                catalogueMode === CatalogueModes.CatalogueSale ||
                !this.sharedService.hasPermission('rfq', 'POST')
        };
        return (colName in validationMap) ? validationMap[colName]() : false;
    }

    /**
     * Loads and caches info if some new products exist
     */
    public getNewProductsExist(catalogueMode: CatalogueModes): Observable<boolean> {
        if (this.newProductsExist.has(catalogueMode)) {
            return of(this.newProductsExist.get(catalogueMode));
        } else {
            const query = {
                'dateCreated.operator': 'ge',
                'dateCreated.value': this.getDateForNewProduct(),
                skip: 0,
                top: 1,
            };
            return interval(2000).pipe( // wait 2000 ms
                take(1), // only do it once
                switchMap(() => this.getProducts(query, catalogueMode, true)), // run the query
                map(productResponse => { // transform the result
                    const exist = productResponse.total > 0;
                    this.newProductsExist.set(catalogueMode, exist);
                    return exist;
                })
            );
        }
    }

    /**
     * We regards products younger than this to be new
     */
    public getDateForNewProduct(): string {
        let d = new Date();
        d.setDate(d.getDate() - DAYS_TO_BE_A_NEW_PRODUCT);
        return d.toISOString().substring(0, 10);
    }

}
