import { Component, Input } from '@angular/core';
import { SharedService } from '@services/shared.service'

@Component({
    selector: 'app-copyright',
    templateUrl: 'copyright.component.html',
    styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {

    @Input() smaller: boolean;
    constructor(public sharedService: SharedService) {

    }
}
