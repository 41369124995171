import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { DeliveryOrdersService } from '../deliveryOrder/delivery.orders.service';
import { OrdersItemsService } from '../order/orders.items.service';
import { GridTranslateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridRowPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowPriceRendererComponent/grid.row.price.renderer.component';
import { GridRowWeightRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowWeightRendererComponent/grid.row.weight.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridDateEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridDateEditorComponent/grid.date.editor.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridInputRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridOnDeliveryOrderRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridOnDeliveryOrderRendererComponent/grid.on.delivery.order.renderer.component';
import { GridOrderItemDeliveryDateHistoryRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridOrderItemDeliveryDateHistoryRendererComponent/grid.order.item.delivery.date.history.renderer.component';
import { LanguageService } from '../language.service'
import { LocalCurrencyPipe } from '../../locale.pipes.module';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';

@Injectable()
export class OrdersItemsGridService {

    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private sharedService: SharedService,
        private tableService: TableService,
        private deliveryOrdersService: DeliveryOrdersService,
        private ordersItemsService: OrdersItemsService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean) {
        let original = [
            { id: 'orderNumberEComm', name: 'ORDER_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
            ...(this.sharedService.user.availableAuthorizedSellers.length > 1 ? [{
                id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            ...(this.sharedService.user.availableCustomers.length > 1 ? [{
                id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            { id: 'orderCreated', name: 'DATE_OF_ISSUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'brandCode', name: 'BRAND', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'productName', name: 'PRODUCT_ID', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            ...(!this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [{
                id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70
                }] : []),
            { id: 'amountOrdered', name: 'ORDERED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 150 },
            { id: 'amountOpened', name: 'OPENED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 150 },
            { id: 'amountReserved', name: 'RESERVED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'amountDelivered', name: 'DELIVERED', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
            { id: 'amountInvoiced', name: 'INVOICED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'packing', name: 'PACKING', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 75 },
            ...(!this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [
                { id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'rowPrice', name: 'TOTAL_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'openedPrice', name: 'OPENED_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ] : []),
            { id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            // { id: 'orderNumberCustomer', name: 'ORDER_INTERNAL_NUMBER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'itemNote', name: 'SPECIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
        ];

        original = this.tableService.filterSuppressColumns(original, 'orderItems');
        const restored = this.sharedService.user.preferences['orders-itemsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    getGridOptions(startPage: number) {
        const this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'orders-itemsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        const this_ = this;
        const colDefs: any = [].concat(
            [
                {
                    headerName: this_.translateService.instant(this_.getColumn('orderNumberEComm').name),
                    field: 'orderNumberEComm',
                    width: this_.getColumn('orderNumberEComm').width,
                    minWidth: 70,
                    cellStyle: { 'text-align': 'left' }
                }
            ],
            (this.sharedService.user.availableAuthorizedSellers.length > 1) ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name),
                    field: 'authorizedSellerName',
                    width: this_.getColumn('authorizedSellerName').width,
                    cellStyle: { 'text-align': 'left' }
                }
            ] : [],
            (this.sharedService.user.availableCustomers.length > 1) ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('customerName').name),
                    field: 'customerName',
                    width: this_.getColumn('customerName').width,
                    minWidth: 70,
                    cellStyle: { 'text-align': 'left' }
                }
            ] : [],
            [
                {
                    headerName: this_.translateService.instant(this_.getColumn('orderCreated').name),
                    field: 'orderCreated',
                    width: this_.getColumn('orderCreated').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridDateRendererComponent,
                    cellRendererParams: { time: true },
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('brandCode').name),
                    field: 'brandCode',
                    minWidth: 70,
                    width: this_.getColumn('brandCode').width,
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('productName').name),
                    field: 'productName',
                    width: this_.getColumn('productName').width,
                    minWidth: 110,
                    cellStyle: { 'text-align': 'left' }
                }
            ],
            (!this.sharedService.hasPermission('orders-items-reduced', 'GET')) ? [
                {
                    headerName: '',
                    field: 'postsCount',
                    maxWidth: 50,
                    minWidth: 50,
                    cellRendererFramework: GridCommentRendererComponent,
                    cellRendererParams: { itemArea: 'orders', idAttrName: 'orderId', loadForEachItem: true },
                    width: this_.getColumn('postsCount').width,
                }
            ] : [],
            [
                {
                    headerName: this_.translateService.instant(this_.getColumn('amountOrdered').name),
                    field: 'amountOrdered',
                    width: this_.getColumn('amountOrdered').width,
                    minWidth: this_.getColumn('amountOrdered').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('amountOpened').name),
                    field: 'amountOpened',
                    width: this_.getColumn('amountOpened').width,
                    minWidth: this_.getColumn('amountOpened').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('amountReserved').name),
                    field: 'amountReserved',
                    width: this_.getColumn('amountReserved').width,
                    minWidth: this_.getColumn('amountReserved').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('amountDelivered').name),
                    field: 'amountDelivered',
                    width: this_.getColumn('amountDelivered').width,
                    minWidth: this_.getColumn('amountDelivered').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridOnDeliveryOrderRendererComponent,
                    cellRendererParams: { onDeliveryOrderGetter: this_.deliveryOrdersService.getProductOnDeliveryOrders }
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('amountInvoiced').name),
                    field: 'amountInvoiced',
                    width: this_.getColumn('amountInvoiced').width,
                    minWidth: this_.getColumn('amountInvoiced').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('packing').name),
                    field: 'packing',
                    width: this_.getColumn('packing').width,
                    minWidth: 110,
                    cellRendererFramework: GridTranslateRendererComponent,
                    cellRendererParams: { translatePrefix: 'PACKING_' },
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('rowWeight').name),
                    field: 'rowWeight',
                    width: this_.getColumn('rowWeight').width,
                    minWidth: 80,
                    cellRendererFramework: GridRowWeightRendererComponent,
                    cellRendererParams: { amountColId: 'amountOrdered' },
                }
            ],
            (!this.sharedService.hasPermission('orders-items-reduced', 'GET')) ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('unitPrice').name),
                    field: 'unitPrice',
                    minWidth: 80,
                    width: this_.getColumn('unitPrice').width,
                    cellStyle: { 'text-align': 'right' },
                    cellRendererFramework: GridPriceRendererComponent
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('rowPrice').name),
                    field: 'rowPrice',
                    minWidth: 80,
                    width: this_.getColumn('rowPrice').width,
                    cellRendererFramework: GridRowPriceRendererComponent,
                    cellRendererParams: { amountColId: 'amountOrdered' },
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('openedPrice').name),
                    field: 'openedPrice',
                    minWidth: 80,
                    width: this_.getColumn('openedPrice').width,
                    cellRendererFramework: GridPriceRendererComponent
                }
            ] : [],
            [
                {
                    headerName: this_.translateService.instant(this_.getColumn('deliveryDate').name),
                    field: 'deliveryDate',
                    width: this_.getColumn('deliveryDate').width,
                    minWidth: 110,
                    maxWidth: 110,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridOrderItemDeliveryDateHistoryRendererComponent,
                    cellRendererParams: { getOrderItemDeliveryDateHistory: this_.ordersItemsService.getOrderItemDeliveryDateHistory }
                },
                {
                    headerName: this_.translateService.instant(this_.getColumn('itemState').name),
                    field: 'itemState',
                    width: this_.getColumn('itemState').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridStateRendererComponent,
                    cellRendererParams: { tooltipPrefix: 'ORDER_ITEM_STATE_' },
                },
                // {
                //     headerName: this_.translateService.instant(this_.getColumn('orderNumberCustomer').name),
                //     field: 'orderNumberCustomer',
                //     width: this_.getColumn('orderNumberCustomer').width,
                //     minWidth: 70,
                //     cellStyle: { 'text-align': 'left' }
                // },
                {
                    headerName: this_.translateService.instant(this_.getColumn('itemNote').name),
                    field: 'itemNote',
                    width: this_.getColumn('itemNote').width,
                    minWidth: 70,
                    hide: !this_.getColumn('itemNote').checked,
                    cellStyle: { 'text-align': 'left' }
                },
            ]
        );

        return this.tableService.filterSuppressColumns(colDefs, 'orderItems');
    }

    public getFilterItems(): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            { id: 'orderNumberEComm', name: 'ORDER_ID', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'productName', name: 'PRODUCT_ID', type: TableFilterItemTypes.text, value: '', maxLength: 255, operator: TableFilterItemOperators.likeBoth },
            // { id: 'orderNumberCustomer', name: 'ORDER_INTERNAL_NUMBER', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'itemNote', name: 'SPECIFICATION', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },

            ...(this.sharedService.brands.length > 1
                ? [
                    { 
                        id: 'brandCode', 
                        name: 'BRAND', 
                        type: TableFilterItemTypes.multiselect,
                        value: '', 
                        allLabel: 'CATALOGUE_ALL',
                        values: this.sharedService.brands.map(brand => ({ id: brand.code, name: brand.code }))
                    }
                ]
                : []
            ),
            // { id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'date', value: '', operator: { id: 'eq', name: 'EQUAL', short: '=' } },
            { 
                id: 'deliveryDate', 
                name: 'DATE_OF_DELIVERY', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: '',
                showYearPicker: true
            },
            {
                id: 'itemState', name: 'STATUS', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: 'X', name: 'ORDER_ITEM_STATE_X', dotId: 'X' },
                    { id: 'N', name: 'ORDER_ITEM_STATE_N', dotId: 'N' },
                    { id: 'C', name: 'ORDER_ITEM_STATE_C', dotId: 'C' },
                    { id: 'P', name: 'ORDER_ITEM_STATE_P', dotId: 'P' }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['X', 'N', 'C']
                }
            },
            { id: 'amountOrdered', name: '', title: 'SHOW_ZERO_AMOUNTS', type: TableFilterItemTypes.checkbox, value: '0', operator: TableFilterItemOperators.ne, checked: false }
        ]
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({ id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: TableFilterItemTypes.multiselect, value: '', values: this.sharedService.user.availableAuthorizedSellers });
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({ 
                id: 'customer.id', 
                name: 'CUSTOMER', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: this.sharedService.user.availableCustomers.map(customer => 
                    ({...customer, id: customer.id.toString()}))
            });
        }

        filterItems = this.tableService.filterSuppressColumns(filterItems, 'orderItems');
        return filterItems;
    }
}
