import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { SharedService } from '@services/shared.service';
import { ToastService } from '@services/toastService/toast.service';
import { RfqsService } from '@services/rfq/rfqs.service';
import { RfqsGridService } from '@services/rfq/rfqs.grid.service';
import { RfqPermissionService } from '@services/rfq/rfq.permission.service';
import { ExportObj, TableBulk, TableBulkTypes } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { RepreOverrideAreas } from '@app/model/user.model';

@Component({
    selector: 'rfqs-summary',
    templateUrl: 'rfqs.summary.component.html'
})
export class RfqsSummaryComponent {

    totalRows: number;
    public filterItems: TableFilterItem[];
    private personsCreated: { id: string, name: string }[] = [];
    private assignedSellerNames: { id: string, name: string }[] = [];
    subjectRefresh = new Subject<any>();
    subjectAllRfqsSelected: Subject<any>;
    public bulk: TableBulk;
    public exportObj: ExportObj;

    busy: boolean;
    public initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table
    public readonly selectionAttrName = 'rfqsSelection';
    public resetFilter$ = new Subject<void>();
    private roleChangingIsRunning = false;
    public readonly repreOverrideAreaRfq = RepreOverrideAreas.rfq;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private toastService: ToastService,
        private rfqsService: RfqsService,
        private rfqsGridService: RfqsGridService,
        private rfqPermissionService: RfqPermissionService,
    ){
        this.subjectRefresh = new Subject<any>();
        this.subjectAllRfqsSelected = rfqsGridService.getItemsSelectedSubject();

        this.getRfqs = this.getRfqs.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('RFQS_SUMMARY'),
                export: this.rfqsService.exportAll.bind(this.rfqsService)
            };
        });

        let exportFunction = () => {
            let allowedStates = this.rfqsGridService.allowedStatesToExport;
            let selection = this.sharedService.user.preferences[this.selectionAttrName];
            let allowedItems = Object.keys(selection.ids).reduce((acc, item) => {
                if (allowedStates.indexOf(selection.ids[item].rfqState) >= 0) {
                    acc.push(selection.ids[item]);
                }
                return acc;
            }, []);
            if (!selection.all && allowedItems.length === 0) { // if nothing to export
                this.toastService.addError('RFQ_ALL_NOT_IN_ALLOWED_STATE_FOR_EXPORT');
                return;
            }
            if (selection.all || (allowedItems.length < Object.keys(selection.ids).length)) { // if some are in state that is not allowed for export
                this.toastService.addSuccess('RFQ_EXPORTED_WILL_BE_ONLY_ALLOWED_STATES');
            }
            this.rfqsService.export(
                selection.all ? null : allowedItems.map(item => item.id),
                this.sharedService.translateService.instant('RFQS_SUMMARY'), 'CSV', true);
        };

        this.bulk = {
            items: [
                { id: 'exportToCSV', name: 'EXPORT_TO_CSV', type: TableBulkTypes.button, iconClass: 'fa-download', click: exportFunction.bind(this) },
            ]
        }
        if (!this.sharedService.user.preferences.hasOwnProperty(this.selectionAttrName)) {
            this.sharedService.user.preferences[this.selectionAttrName] = { ids: {}, all: false, visible: false };
        }

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });

        this.initialized = true;
    }

    ngOnInit() {
        // set default filter if not filter already in query
        if (!this.route.snapshot.queryParams['rfqState.value'] && !this.sharedService.user.preferences[this.sharedService.getUserPreferenceKey('Filter', '')]) {
            this.sharedService.lastRfqsQueryParams['rfqState.operator'] = 'in';
            this.sharedService.lastRfqsQueryParams['rfqState.value'] = 'P,N,O';
            this.router.navigate([], { queryParams: this.sharedService.lastRfqsQueryParams });
        }
        let this_ = this;
        setTimeout(function () {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastRfqsQueryParams);
        }, 0);
    }

    public getRfqs(params) {
        if (this.roleChangingIsRunning) {
            this.finishRoleChange();
            return;
        }
        this.busy = true;
        let query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastRfqsQueryParams = {};
        for (let key in this.route.snapshot.queryParams) {
            this.sharedService.lastRfqsQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key != 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.rfqsService.getRfqs(query)
            .subscribe(data => {
                this.totalRows = data.total;
                this.personsCreated = data.personsCreated;
                this.assignedSellerNames = data.assignedSellerNames;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                params.successCallback(data.rows, data.total);
                this.busy = false;
            }, err => {
                console.log(err);
                // also in error to be able to cancel filter
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                this.busy = false;
            });
    }

    public createNew(): void {
        this.rfqsService.createRfq()
        .subscribe(rfq => {
            this.router.navigate([`/${this.sharedService.appSettings.language}/rfq/${rfq.id}`]);
        }, err => {
            console.log(err);
        })
    }

    public onRoleChange(): void {
        // Role change has two steps
        // 1. Reset filter - causing grid reload
        // 2. Reset the grid by hiding and re-showing it
        this.roleChangingIsRunning = true;
        this.resetFilter$.next();
    }

    private finishRoleChange(): void {
        this.roleChangingIsRunning = false;
        // Clear selection
        this.sharedService.resetSelection(this.selectionAttrName);

        // Reload grid after role change
        this.initialized = false;
        this.filterItems = null;

        setTimeout(() => {
            this.initialized = true;
        }, 0);
    }

    public isCreateRfqAvailable(): boolean {
        return this.rfqPermissionService.isElementAvailable('createRfq');
    }

    public isUserRoleSelectAvailable(): boolean {
        return this.rfqPermissionService.isElementAvailable('userRoleSelect');
    }

    getGridOptions() {
        return this.rfqsGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
    }
    private getFilterItems(): TableFilterItem[] {
        return this.rfqsGridService.getFilterItems(this.personsCreated, this.assignedSellerNames);
    }
    getColumns() {
        return this.rfqsGridService.getColumns();
    }
    getColumnsDefs() {
        return this.rfqsGridService.getColumnDefs();
    }
}