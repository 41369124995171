/**
 * Set focus to an input
 * Example:
 * <input name="lastName" [myFocus]="isLastNameFocused">
 */
import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({ selector: '[myFocus]' })
export class FocusDirective implements OnInit {
 
  @Input('myFocus') isFocused: boolean;
 
  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}
 
  ngOnInit() {
    if (this.isFocused) {
      // this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
      this.renderer.selectRootElement(this.hostElement.nativeElement, true).focus();
    }
  }
}
