<div fxFlex="100" class="row-container wider">
	<div [@slideInOut]="!addProductsVisible" *ngIf="!suppressAdding">
	    <div fxLayout="column" fxLayout.gt-sm="row"  class="row-container">
	    	<div fxFlex="50" class="row-container">
		        <div class="input-container" style="width: 100%;">
		        	<div fxLayout="row">
		            	<label translate>SEARCH_FILE_FOR_IMPORT</label>
		            	<ng-template #popFileImportTemplate><div translate>IMPORT_CSV_HELP</div><div translate>{{translationPrefix+'_IMPORT_HELP_1'}}</div><div translate>IMPORT_HELP_2</div></ng-template>
		            	<i class="fa fa-info-circle clickable" [popover]="popFileImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
		            </div>
		            <input #fileInput type="file" (change)="fileChangeEvent($event)" placeholder="{{ 'INSERT_CSV' | translate }" style="max-width: 100%;"/>

		            <!-- <div class="input-container" *ngIf="brands.length > 1">
		                <label translate>BRAND</label>
		                <dropdown-component attributeName="selectedBrandFromFile" [items]="brands" [selected]="selectedBrandFromFile" (onSelect)="onSelectBrandFromFile($event)"></dropdown-component>
		            </div>   -->

		            <div>
				    	<button class="btn-primary" (click)="fileCsvAddToOrder()" style="margin-top: 5px;" [disabled]="addFromFileBusy || fromFileObjects.length == 0" [ngClass]="{'ec-disabled': addFromFileBusy || fromFileObjects.length == 0}">
							<i class="fa fa-plus"></i>
							<a translate>DISTRIBUTOR_STORES_ADD_TO_STORE</a>
						</button>
					</div>
		        </div>  
		    </div>

	    	<div fxFlex="50" class="row-container">
		        <div class="input-container">
		        	<div fxLayout="row">
		            	<label translate>DISTRIBUTOR_STORES_IMPORT_BULK</label>
		            	<ng-template #popClipboardImportTemplate><div translate>IMPORT_CLIPBOARD_HELP</div><div translate>{{translationPrefix+'_IMPORT_HELP_1'}}</div><div translate>IMPORT_HELP_2</div></ng-template>
		            	<i class="fa fa-info-circle clickable" [popover]="popClipboardImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
		            </div>
		            <textarea #pasteTextarea class="form-control" [(ngModel)]="pastedExcel" (ngModelChange)="pasteExcelChanged(pastedExcel)" placeholder="{{ 'INSERT_CTRL_V' | translate }}" (keydown)="pasteExcelKeyDown($event, pasteTextarea)"></textarea>

		            <!-- <div class="input-container" *ngIf="brands.length > 1">
		                <label translate>BRAND</label>
		                <dropdown-component attributeName="selectedBrandPasted" [items]="brands" [selected]="selectedBrandPasted" (onSelect)="onSelectBrandPasted($event)"></dropdown-component>
		            </div>   -->

		            <div>
				    	<button class="btn-primary" (click)="ctrlVAddToOrder()" style="margin-top: 5px;" [disabled]="addFromPasteBusy || pastedExcel.length == 0" [ngClass]="{'ec-disabled': addFromPasteBusy || pastedExcel.length == 0}">
							<i class="fa fa-plus"></i>
							<a translate>DISTRIBUTOR_STORES_ADD_TO_STORE</a>
						</button>
					</div>
		        </div>  
		    </div>
	    </div>
	</div>

    <div fxLayoutAlign="start center">
		<h3 style="display: inline-block;"><i class="fa fa-database"></i> <span translate>{{translationPrefix}}</span></h3>
		<div *ngIf="!suppressAdding">
			<button class="btn-primary" style="margin: 0px 0 0 10px;" (click)="toggleAddPanel()" *ngIf="!addProductsVisible">
				<i class="fa fa-plus"></i>
				<span translate>DISTRIBUTOR_STORES_ADD_TO_STORE</span>
			</button>
			<button class="btn-secondary" style="margin: 0px 0 0 10px;" (click)="toggleAddPanel()" *ngIf="addProductsVisible">
				<i class="fa fa-times"></i>
				<span translate>DISTRIBUTOR_STORES_IMPORT_BULK_CLOSE</span>
			</button>
		</div>
	</div>

	<div *ngIf="showCustomerStockOptions">
		<app-customer-stock-options-component></app-customer-stock-options-component>
	</div>
	
	<div class="row-container wider">    
		<div fxLayout="column">
			<ecm-table  [dataGetter]="getStockItems" 
						[optionsGetter]="getGridOptions"
						[filterItems]="filterItems"
						[columnsGetter]="getColumns"
						[columnsDefsGetter]="getColumnsDefs"
						[total]="total"
						[selection]="sharedService.user.preferences[area + 'ItemsSelection']"
                   		[editedIdsObj]="stockGridService.itemsEditedIds"
						[observableRefresh]="subjectRefresh.asObservable()"
						[observableReloadCurrent]="subjectReloadCurrent.asObservable()"
						[subjectAllSelectedChange]="subjectAllSelected"
                   		[subjectDeleteSelected]="subjectDeleteSelected"
						[showRefreshButton]="true"
						[busy]="busy"
						[bulk]="bulk"
						emptyMessage=""
						[exportObj]="exportObj"></ecm-table>
		</div>
	</div>
</div>