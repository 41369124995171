<div class="component-outer-container">
  <div class="attachment">
    <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()"
    [attr.role]="makeCollapsible ? 'button': ''" data-target="collapseAttachments">
      <h3><i class="fa fa-paperclip"></i> <span translate>ATTACHMENTS</span>
        <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }" class="fa" style="float: right"></i>
      </h3>

    </div> <!-- end of component header container --->

    <div [@slideInOut]="isCollapsed" [ngClass]="{'component-body-container': makeCollapsible}" id="collapseAttachments" >
        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
            <ng-template #privateInfo><span>{{privateConfig.iconTitle | translate}}</span></ng-template>
            <table>
                <tr *ngFor="let attachment of attachments; let odd=odd; let even=even;" [ngStyle]="{'background-color': even ? '#fff' : '#f6f6f6'}">
                    <td>{{attachment.fullName}}</td>
                    <td>{{attachment.dateCreated | localdate:sharedService.appSettings.language:true}}</td>
                    <td *ngIf="attachment.childId"><span translate>ROW</span> {{attachment.childId}}<span *ngIf="attachment.note">,
                            {{attachment.note}}</span></td>
                    <td *ngIf="!attachment.childId && !attachmentsGetterWithoutEntity && hideRowLabel !== true" translate>ATTACHMENT_OF_REQUEST</td>
                    <td *ngIf="!attachmentsGetterWithoutEntity" class="fnt-primary clickable" (click)="download(attachment)">{{attachment.fileName}}</td>
                    <td *ngIf="attachmentsGetterWithoutEntity" >{{attachment.fileName}}</td>
                    <td>
                        <i class="fa fa-info-circle alert-danger" *ngIf="showPrivateControls && !attachment.privateItem"
                            [popover]="privateInfo" container="body" triggers="mouseenter:mouseleave" 
                            [adaptivePosition]="true" placement="left"></i>
                    </td>
                    <td><i class="clickable fa fa-trash" style="margin-right: 5px; opacity: 0.9" (click)="deleteExisting(attachment)"
                            *ngIf="allowEdit"></i></td>
                </tr>
            </table>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxFlexFill *ngIf="attachments.length === 0 || busy">
            <div fxFlex="100"  fxLayoutAlign="center center">
                <div *ngIf="attachments.length === 0" fxFlex="100" style="padding-bottom: 15px" fxLayoutAlign="center center"><span translate>ATTACHMENTS_NOT_FOUND</span></div>
                <busy-indicator *ngIf="busy"></busy-indicator>
            </div>
        </div>
        
      <div *ngIf="isAddDialogOpen" [ngClass]="{'attachments-add-next': isAddDialogOpen}">

          <div fxLayoutAlign="space-between center">
              <h3 translate>ADD_ATTACHMENT</h3>
              <button class="btn-secondary" style="margin: 10px 0 0 10px;" (click)="closeAddDialog()">
                  <i class="fa fa-times"></i>
                  <span translate>ADD_TO_ATTACHMENTS_CLOSE</span>
              </button>
          </div>

          <table class="table" *ngIf="uploader?.queue?.length">
              <thead>
                  <tr>
                      <th width="50%" translate>NAME_ITEM</th>
                      <th translate>STATUS</th>
                      <th translate>ACTIONS</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of uploader.queue">
                      <td>{{ item?.file?.name }}</td>
                      <td class="text-center">
                          <span *ngIf="item.isSuccess">
                              <i class="fa fa-check-circle fnt-green" style="margin-right: 5px; opacity: 0.9"></i>
                          </span>
                          <span *ngIf="item.isError">
                              <i class="glyphicon glyphicon-remove"></i>
                              <i class="fa fa-times-circle fnt-orange" style="margin-right: 5px; opacity: 0.9"></i>
                          </span>
                      </td>
                      <td>
                          <i class="clickable fa fa-trash" style="margin-right: 5px; opacity: 0.9" (click)="deleteNew(item)"></i>
                      </td>
                  </tr>
              </tbody>
          </table>

          <div class="progress" *ngIf="uploader?.queue?.length">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
          </div>

          <div *ngIf="showPrivateControls" style="margin-bottom: 20px;">
            <div>{{privateConfig?.dropdownText.title | translate}}:</div>
            <select class="form-control" style="width: auto;"
              [(ngModel)]="isNewAttachmentPrivate">

              <option [ngValue]="true">{{privateConfig?.dropdownText.privateText|translate}}</option>
              <option [ngValue]="false">{{privateConfig?.dropdownText.publicText|translate}}</option>
            </select>
          </div>

          <div style="margin-bottom: 7px;" *ngIf="showPrivateControls && isNewAttachmentPrivate === false">
            <span class="alert-danger" id="privateConfigWarning">{{privateConfig.buttonWarning | translate}}</span>
          </div>  

          <input #fileInput [disabled]="showPrivateControls && isNewAttachmentPrivate === null" 
            type="file" ng2FileSelect [uploader]="uploader" />

      </div>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="50" style="text-align: left">
            <button *ngIf="isModal" class="btn-secondary text-right" (click)="closeModal()"><a translate>CLOSE</a></button>
        </div>
        <div *ngIf="allowEdit && !isAddDialogOpen" fxFlex="50" style="text-align: right">
            <button class="btn-primary" (click)="openAddDialog()" ><i class="fa fa-plus"></i><span translate>ADD_ATTACHMENT</span></button>
        </div>
      </div>     

    </div><!-- end of component body container --->
  </div>
</div>
