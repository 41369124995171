import { Component, OnDestroy} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Subject, Subscription, Observable } from 'rxjs';
import { OriginalValue, RowActionsDef } from '@model/table.model';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { EditedIdsObject, CellChange } from '@app/model/table.model';
import { TranslateService } from '@ngx-translate/core';

export interface GridGenericRowActionsRendererComponentParams {
    editedIdsObj?: EditedIdsObject;
    rowActionsDef?: RowActionsDef[];

    // If false it will keep specific icons always on the same position
    //  x y z
    //  x   z
    //    y
    // If true it will always center icons
    //  x y z
    //   x z
    //    y
    suppressIconsPositionKeeping?: boolean;
    
    cellChanged$?: Subject<CellChange>;
    cancel$?: Subject<any>;
    isRowValid?: (paramsData: any) => boolean;
    save?: (paramsData: any) => Observable<any>;
    isNewRow?: (paramsData: any) => boolean;
}

interface MyParams extends ICellRendererParams, GridGenericRowActionsRendererComponentParams {}

interface RowActionsDefExtended extends RowActionsDef {
    _isVisible: boolean;
}

@Component({
    selector: 'app-grid-generic-row-actions-renderer-component',
    template: ` <div style="font-size: 20px; margin-top: -2px; padding: 0 5px;">
                    <span *ngIf="!showSave && !showCancel">
                        <ng-container *ngIf="params.suppressIconsPositionKeeping">
                            <span *ngFor="let action of rowActionsDef">
                                <ng-container *ngTemplateOutlet="innerTemplate; context: {$implicit: action}"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!params.suppressIconsPositionKeeping">
                            <span fxFlex="25px" *ngFor="let action of rowActionsDef">
                                <ng-container *ngTemplateOutlet="innerTemplate; context: {$implicit: action}"></ng-container>
                            </span>
                        </ng-container>
                    </span>
                </div>
                <div style="font-size: 20px; margin-top: -2px;">
                    <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="showSave"></i>
                    <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel(true)" *ngIf="showCancel"></i>
                </div>

                <ng-template #innerTemplate let-action>
                    <i *ngIf="action.type === 'ICON_ONLY' && action._isVisible" class="clickable" [ngClass]="action.iconClass"
                        style="margin-right: 5px; opacity: 0.9"
                        [popover]="action?.popover" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"
                        (click)="action.method(params)">
                    </i>
                    <span *ngIf="action.type === 'COUNT' && action._isVisible" (click)="action.method(params)" class="action clickable comment-count center-text" 
                        [ngClass]="{'empty': params.data[action.attrName] == 0}" style="font-size: 12px;">
                        <i [ngClass]="action.iconClass"></i> {{params.data[action.attrName] > 0 ? params.data[action.attrName] : ''}}
                    </span>
                </ng-template>
    `
})
export class GridGenericRowActionsRendererComponent implements AgRendererComponent, OnDestroy {
    public params: MyParams;
    public rowActionsDef: RowActionsDefExtended[];
    public showSave = false;
    public showCancel = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private translateService: TranslateService
    ) {
    }

    agInit(params: MyParams): void {
        this.params = params;

        this.rowActionsDef = this.params.rowActionsDef
            ? this.params.rowActionsDef.map(def => ({_isVisible: true, ...def}))
            : [];
        this.rowActionsDef.forEach(def => {
            if (def.popover) {
                def.popover = this.translateService.instant(def.popover).toLowerCase();
            }
        });

        this.updateState();

        if (this.params.cellChanged$) {
            this.subscriptions.push(this.params.cellChanged$.subscribe(cellChange => {
                this.updateState(cellChange);
            }));
        }
        if (this.params.cancel$) {
            this.subscriptions.push(this.params.cancel$.subscribe(() => {
                this.cancel();
            }));
        }
    }

    private updateState(cellChange?: CellChange): void {
        // console.log('updateState cellChange', cellChange, 'this.params.data', this.params.data);
        // this.showCancel = this.params.data.edited && this.getEditedCount() > 0;
        this.showCancel = this.params.data.edited; // || this.getEditedCount() > 0;
        this.showSave = this.params.data.edited && (this.params.isRowValid
            ? this.params.isRowValid(this.params.data) : this.showCancel);

        this.rowActionsDef.forEach(def => {
            if (def.isVisible) {
                def._isVisible = def.isVisible(this.params, def.id)
            }
        });
    }

    public cancel(redraw?: boolean): void {
        delete this.params.editedIdsObj[this.params.data.id];

        if (this.params.isNewRow && this.params.isNewRow(this.params.data)) {
            this.params.api.applyTransaction({ remove: [this.params.data] });
        } else {
            const changedColumns = [];

            this.params.data.originalValues.forEach((item: OriginalValue) => {
                this.params.data[item.colId] = item.value;
                changedColumns.push(item.colId);
            });

            this.params.data.edited = false;

            if (redraw) {
                this.params.api.refreshCells({ rowNodes: [this.params.node], columns: changedColumns });
                // this.params.api.redrawRows({rowNodes: [this.params.node]});
            }
        }
        this.updateState();
    }

    public save(): void {
        this.params.save(this.params.data).subscribe(() => {

            this.params.data.originalValues.forEach(item => {
                item.value = this.params.data[item.colId];
            });

            this.params.data.edited = false;

            delete this.params.editedIdsObj[this.params.data.id];

            this.updateState();
        }, err => console.log(err));
    }

    // deleteRow() {
    //     this.basketService.deleteOrderedItem(this.params.data.id)
    //     .subscribe(data => {
    //         this.params.observable.next({id: this.params.data.id, amountOrderedDif: (this.params.data.amountOrdered * (-1)),
    //             rowWeightDif: (this.params.data.amountOrdered * (-1)) * this.params.data.weight,
    //             rowPriceDif: (this.params.data.amountOrdered * (-1)) * this.params.data.unitPrice
    //         });
    //         delete this.params.selection.ids[this.params.data.id];
    //         this.subjectReloadCurrent.next();
    //     }, err => console.log(err));
    // }

    private getEditedCount(): number {
        return this.params.editedIdsObj ? Object.keys(this.params.editedIdsObj).length : 0;
    }

    public refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
