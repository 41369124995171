import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { LanguageService } from '../../services/language.service';
import { LocalDatePipe, LocalNumberPipe } from '../../locale.pipes.module';

@Injectable()
export class NewsDetailService {

    constructor (private http: EcmHttpService,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /**
     * Sets initial count of News without read news
     */
    // setNewsCount(newsPostponed?: number) {
    //     console.log('news.detail.service setNewsCount()');
    //     this.getNews().subscribe(data => {
    //         if (data.totalCount) {
    //             // const total = data.rows.filter(item => item.read === 0 && JSON.parse(localStorage.get('newsPostponed')).indexOf(item.id) < 0 ).length;
    //             const total = data.rows.filter(item => item.read === 0 ).length - newsPostponed;
    //             this.sharedService.setNewsCountStorage(total);
    //             // this.sharedService.setNewsCount(data.totalCount);
    //             this.sharedService.refreshNewsCount();
    //             // this.sharedService.setrefreshNewsCount(data.totalCount);
    //         }
    //     }, err => {
    //         console.log(err);
    //     });
    // }

    /**
     * Loads list of news
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    // getNews (query?) {
    //     console.log('news.detail.service getNews()');
    //     const url = `/news`;
    //     return this.http.get(url, this.http.prepareOptions(query));
    // }

    /**
     * Loads list of newsDetail
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getNewsDetail (id: number) {
        const url = `/news/${id}/header`;
        return this.http.get(url);
    }

    /**
     * Post news
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    postNewsDetail (data) {
        const url = `/news`;
        return this.http.post(url, JSON.stringify(data));
    }
    /**
    * Delete news
    *
    * @param query - object of query params ({skip: number, top: number, ...})
    */
    deleteNews(id: number) {
        const url = `/news/${id}/header`;
        return this.http.delete(url);
    }
    /**
     * Put news
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    putNewsDetail (id: number, data: any) {
        const url = `/news/${id}/header`;
        // console.log('stringify', JSON.stringify(data));
        return this.http.put(url, JSON.stringify(data));
    }
    /**
     * MarkAsRead
     */
    markAsReadNews (id) {
        const url = `/news/${id}/mark-as-read`;
        return this.http.put(url, '');
    }

    getDownloadUrl(entityId: number, attachmentId: number) {
        const url = '/news/attachment-download-url';
        return this.http.put(url, JSON.stringify({entityId: entityId, attachmentId: attachmentId}));
    }
    getUploadUrl(filename: string, entityId: number) {
        const url = '/news/attachment-upload-url';
        return this.http.put(url, JSON.stringify({fileName: filename, entityId: entityId}));
    }
    getComments(id: number) {
        const url = `/news/${id}/discussion`;
        return this.http.get(url);
    }
    /**
     * Post comments
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    postComments (id: number, data: any) {
        const url = `/news/${id}/discussion`;
        return this.http.post(url, JSON.stringify(data));
    }
    deleteAttachment(entityId: number, attachmentId: number) {
        return this.http.delete(`/news/${entityId}/attachment/${attachmentId}`);
    }
    getAttachments(id: number) {
        if (id === 0) {
            return of([]);
        } else {
            return this.http.get(`/news/${id}/attachments`);
        }
    }
    /**
     * Exports all
     */
    exportAll(query) {
        const url = `/newsDetail`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

