import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, HostListener, Output, EventEmitter, OnInit, ViewEncapsulation  } from '@angular/core';
import { Subject , empty} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../../../services/window.service';
import { SharedService } from '../../../services/shared.service';
import { NewsDetailService } from '../../../services/news/news.detail.service';
import { ToastService } from '../../../services/toastService/toast.service'

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'news-dialog',
    styles: ['.news-body-container img {max-width: 100%}'],
    templateUrl: 'news.dialog.component.html',

    encapsulation: ViewEncapsulation.None,
})
export class NewsDialogComponent implements OnInit {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;
    comment: any;
    ids = [];
    count;
    subjectReloadNews = new Subject<any>();
    subjectReloadAttachments = new Subject<any>();

    // @HostListener('document:keyup', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     if (event.keyCode === 27) { // escape
    //         this.doneSubject.next();
    //         this.bsModalRef.hide();
    //     }
    // }

    constructor(public bsModalRef: BsModalRef,
                public translate: TranslateService,
                private windowService: WindowService,
                public sharedService: SharedService,
                public newsDetailService: NewsDetailService,
                public toastService: ToastService,
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 100;
        });
    }
    ngOnInit() {
        setTimeout(() => {
            this.count = this.params.data.length;
        }, 0);
    }

    attachmentsGetter(entityId?: any) {
        return empty();
    }

    continue(sendToServer?: boolean, newsId?: number) {
        // SEND BLA BLA
        if (sendToServer === true) {
            // SEND TO SERVER ID OF READ NEW/S
            this.newsDetailService.markAsReadNews(newsId).subscribe(
                result => {
                    this.toastService.addSuccess('NEWS_SUCCESSFULLY_READ');
                    this.sharedService.setNewsCountStorage(+this.sharedService.getNewsCount() - 1);
                    this.sharedService.refreshNewsCount();
                },
                err => { console.log(err); }
            );
        }

        // remove from unread news list
        if (this.params.data.length > 1) {
            this.params.data.shift();
            setTimeout(() => {
                this.subjectReloadNews.next();
            }, 0);

        } else {
            // close modal
            this.doneSubject.next('restart');
            this.bsModalRef.hide();
        }
    }
    postpone() {
        const newsPostponed: any[] = JSON.parse(localStorage.getItem('newsPostponed')) === null ? [] : JSON.parse(localStorage.getItem('newsPostponed'));
        if (newsPostponed.indexOf(this.params.data[0].id) < 0) {
            newsPostponed.push(this.params.data[0].id);
            localStorage.setItem('newsPostponed', JSON.stringify(newsPostponed));
        }
        this.continue(false);
    }
}
