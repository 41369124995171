<div>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/support" translate>SUPPORT</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/faq" translate>FAQ</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/business-conditions"
        *ngIf="sharedService.permissions && sharedService.params && sharedService.params.showBusinessConditions"
        translate>BUSINESS_CONDITIONS</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/security" translate>SECURITY</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/contact" translate>CONTACT</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/manuals"
        *ngIf="sharedService.permissions && sharedService.params.features?.manuals" translate>MANUALS</a>
    <a class="fnt-white" routerLink="/{{sharedService.appSettings.language}}/presentations"
        *ngIf="sharedService.permissions" translate>PRESENTATIONS</a>
</div>
