<div style="margin-top: -5px;" >
    <ng-template #toolTemplateSplit>{{'SPLIT_SELECTED' | translate | lowercase}}</ng-template>

    <div class="input-container"  fxLayoutAlign="center center">
        <span style="padding-top: 3px;">
            <span class="delete-item-button center-text" *ngIf="params.showDelete"
                (click)="deleteItem()">
                <ng-template #popDeleteSelected><div translate>DELETE_SELECTED</div></ng-template>
                <i class="fa fa-trash clickable" aria-hidden="true" [popover]="popDeleteSelected" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left" style="font-size: 150%" *ngIf="!params.data.edited"></i>
            </span>
            <span class="save-item-button center-text" style="font-size: 20px;">
                <i class="clickable fa fa-check fnt-green" style="margin-left: 5px; margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="params.data.edited"></i>
            </span>
            <span class="cancel-item-button center-text" style="font-size: 20px;">                
                <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel()" *ngIf="params.data.edited"></i>
            </span>
        </span>
    </div>




</div>