<div class="backdrop" *ngIf="getEditedCount() > 0"></div>

<ecm-table 
    [dataGetter]="getPrfqItems" 
    [optionsGetter]="getGridOptions" 
    [columnsGetter]="getColumns" 
    [columnsDefsGetter]="getColumnsDefs"
    [filterItems]="filterItems"
    [total]="totalRows" 
    [selection]="gridService.getSelection()"
    [subjectAllSelectedChange]="gridService.allSelected$"
    [editedIdsObj]="gridService.editedIdsObj"
    [observableRefresh]="refreshGrid$.asObservable()" 
    [showRefreshButton]="true" 
    [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
    [bulk]="bulk"
    [busy]="busy"
    [secondaryHeaderColor]="true" 
    [exportObj]="exportObj"
    [showNewRowButton]="showNewRowButton"
    [newRowGetter]="getNewRow"
    [suppressEmptyMessage]="true"
    [suppressPagination]="true"
    [showTotalRowsInsteadPagination]="true"
    [tableNamePrefix]="tableNamePrefix"
></ecm-table>
