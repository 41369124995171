import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
import { BasketService } from '../basket/basket.service';
import { ShipmentsService } from '../shipment/shipments.service';
import { NewsService } from '../news/news.service';
import { CleanUpService } from '../clean.up.service';
import { Brand } from '../../model/brand.model';
import { map, switchMap, tap, toArray } from 'rxjs/operators';
import { PermissionsService } from '@services/permissions.service';
import { RepreOverrideAreas, RepreOverrideRequest, Representative, User } from '@app/model/user.model';
import { concat, Observable, Subject } from 'rxjs';
import { ChatService } from '../chat/chat.service';
import { Router } from '@angular/router';
import { UserNotificationsRecurrency, UserNotificationsRecurrencyRequestDto, UserNotificationsRecurrencyResponseDto } from '../../model/user.notifications-recurrency.model';

@Injectable()
export class UserService {
    public onRepresentativeChange$: Subject<void> = new Subject();

    constructor (
        private http: EcmHttpService,
        private sharedService: SharedService,
        private toastService: ToastService,
        private cleanUpService: CleanUpService,
        private basketService: BasketService,
        private shipmentsService: ShipmentsService,
        private newsService: NewsService,
        private permissionsService: PermissionsService,
        private chatService: ChatService,
        private router: Router
    ) {
        this.onRepresentativeChange$.subscribe(() => {
            // Remove user representative override (if there is some) after user representative change
            // It is to avoid the situation that an AS user in RFQ sets override to a customer
            // and then changes representative to a customer and can see user override select in RFQ
            if (this.isRepreOverrideForArea(RepreOverrideAreas.rfq)) {
                this.removeRepresentativeOverride(RepreOverrideAreas.rfq).subscribe(() => {
                    console.log('Repre override removed');
                });
            }
        });
    }

    /**
     * Loads details of user
     */
    getUserDetails() {
        return this.http.get(`/my-profile/details`).pipe(map((data) => {
            this.sharedService.permissions = null;
            this.sharedService.appComponent.cdRef.detectChanges();

            // save permissions
            var permissions: { [key:string]:[string]; } = {};
            for (var endPoint in data.permissions) {
                if (data.permissions.hasOwnProperty(endPoint)) {
                    var partsOfEndPoint = endPoint.split('/');
                    if (partsOfEndPoint.length > 1) {
                        partsOfEndPoint.shift();
                    }
                    let restOfEndpointString = partsOfEndPoint.join('/');
                    if (!permissions.hasOwnProperty(restOfEndpointString)) {
                        permissions[restOfEndpointString] = data.permissions[endPoint];
                    } else {
                        permissions[restOfEndpointString].concat(data.permissions[endPoint]);
                    }
                }
            }
            this.sharedService.setPermissions(permissions);

            // save brands
            var brands: Brand[] = [];
            data.brands.forEach(function(brand) {
                if (brand.brandCode === 'ZVL') { // to be at first place
                    brands.unshift(new Brand(brand.brandCode, brand.brandName));
                } else {
                    brands.push(new Brand(brand.brandCode, brand.brandName));
                }
            });
            this.sharedService.setBrands(brands);

            // save newsCount and newsPostponed array
            this.sharedService.setNewsCountStorage(0);
            this.sharedService.setNewsPostponed([]);

            // save representative
            this.sharedService.user.readAllRows = data.readAllRows;
            this.sharedService.user.representsCustomer = Object.assign({}, data.representsCustomer, {
                customerStockActive: data.representsCustomer && data.representsCustomer.customerStockActive === 1,
                customerStockForOtherCustomers: data.representsCustomer && data.representsCustomer.customerStockForOtherCustomers === 1,
                customerStockForSubcustomers: data.representsCustomer && data.representsCustomer.customerStockForSubcustomers === 1,
                });
            this.sharedService.user.representsSupplier = data.representsSupplier;
            this.sharedService.user.representsAuthorizedSeller = data.representsAuthorizedSeller;
            this.sharedService.user.representative = data.representative;

            // Other attributes
            this.sharedService.user.language = data.language;
            this.sharedService.user.phoneNumber = data.phoneNumber;
            this.sharedService.user.businessCondConsent = data.businessCondConsent;
            this.sharedService.user.homepage = data.homepage;
            this.sharedService.user.subcustomer = data.subcustomer;
            this.sharedService.user.repreOverride = data.repreOverride;

            // save available customers and sellers e.g. for filters
            data.availableCustomers.forEach(function(customer) {
                customer.checked = true;
            });
            this.sharedService.user.availableCustomers = data.availableCustomers;
            this.sharedService.user.availableSuppliers = data.availableSuppliers;
            data.availableAuthorizedSellers.forEach(function(authorizedSeller) {
                authorizedSeller.checked = true;
            });
            this.sharedService.user.availableAuthorizedSellers = data.availableAuthorizedSellers;

            localStorage.setItem('user', JSON.stringify(this.sharedService.user));

            this.sharedService.appComponent.cdRef.detectChanges();

            // this.startNewsDialog();
            return data;
        } ));
    }

    /**
     * Sets new user detail values
     * @param values - {givenName: < name >, familyName: < family name >, email: < email address >, homepage: < home page area name >}
     */
    setUserDetails(values: {givenName: string, familyName: string, phoneNumber: string, email: string, language: string, homepage?: string}, doNotShowSuccess?: boolean ) {
        return this.http.put(`/my-profile/details`, 
            JSON.stringify(values)
        )
        .pipe(map((data) => {
            this.sharedService.user.firstName = values.givenName;
            this.sharedService.user.lastName = values.familyName;
            this.sharedService.user.phoneNumber = values.phoneNumber;
            this.sharedService.user.email = values.email;
            this.sharedService.user.language = values.language;
            this.sharedService.user.homepage = values.homepage;
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            
            if (!doNotShowSuccess) {
                this.toastService.addSuccess('USER_DETAILS_CHANGED_SUCCESS');
            }
            return data;
        }));
    }

    /**
     * Sets new user detail values
     * @param values - {homepage: < home page area name >}
     */
    setPersonalDetails(values: {homepage?: string}, doNotShowSuccess?: boolean ) {
        return this.http.put(`/my-profile/details`, JSON.stringify(values)).pipe(map((data) => {
            this.sharedService.user.homepage = values.homepage;
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            
            if (!doNotShowSuccess) {
                this.toastService.addSuccess('USER_DETAILS_CHANGED_SUCCESS');
            }
            return data;
        }));
    }

    public setUserLanguage(data: {language: string}, doNotShowSuccess?: boolean): Observable<void> {
        return this.http.put(`/my-profile/details`, 
            JSON.stringify(data)
        )
        .pipe(map(() => {
            this.sharedService.user.language = data.language;

            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            
            if (!doNotShowSuccess) {
                this.toastService.addSuccess('USER_DETAILS_CHANGED_SUCCESS');
            }
        }));
    }

    /**
     * Sets news count for user, shows dialog to show news and starts timer
     */
    startNewsDialog() {
        // console.log('has permissions get news', this.sharedService.hasPermission('news', 'GET'));
        if (this.sharedService.hasPermission('news', 'GET')) {
            // console.log('starting getNewsCount()');
            this.newsService.setNewsCount();
        }
    }

    /**
     * Sets that user accepted business conditions
     */
    sendBusinessConditionsConsent() {
        return this.http.put(`/my-profile/details`,
            JSON.stringify({ businessCondConsent: 1 })
        );
    }

    /**
     * Changes representative of user: he can represent customer, supplier, authorizedSeller
     */
    public changeRepresentative(representative: string): void {
        this.http.put(`/my-profile/representative/` + representative, 
            JSON.stringify({})
        ).subscribe(data => {
            this.http.refreshToken(this.http)
                .pipe(switchMap(() => this.getUserDetails())).subscribe(data2 => {
                    this.toastService.addSuccess('REPRESENTATIVE_CHANGE_SUCCESS');
                    this.sharedService.setLastQueryParams();
                    this.loadDataAfterUserChange();
                    this.onRepresentativeChange$.next();
                }, err2 => {
                    console.log(err2)
                });
        }, err => {
            console.log(err)
        });
    }

    /**
     * Loads data displayed according to user
     */
    loadDataAfterUserChange() {
        localStorage.removeItem('newsCount');
        localStorage.removeItem('basketItemsCount');
        localStorage.removeItem('shipmentsCount');
        if (this.sharedService.hasPermission('news', 'GET')) {
            // setTimeout(() => {
            //     this.newsService.setNewsCount();
            // }, 5000); // primitive solution to avoid concurrent /news requests after login
            //           // should rather be refactored by caching http requests for 2-3 sec.
            this.newsService.setNewsCount();
        }
        if (this.permissionsService.hasPermission('ordersCreate')) {
            this.basketService.setBasketItemsCount();
        }
        if (this.sharedService.hasPermission('shipments', 'GET')) {
            this.shipmentsService.getShipmentsCount(true);
        }
    }

    /**
     * Changes password of user
     * @param oldValue - old pass
     * @param newValue - new pass
    */
    changePassword(oldValue: string, newValue: string) {
        return this.http
        .post(`/my-profile/change-password`, 
            JSON.stringify({
                previousPassword: oldValue, 
                proposedPassword: newValue, 
                accessToken: localStorage.getItem('access_token')
            })
        )
        .pipe(map((data) => {
            this.toastService.addSuccess('PASSWORD_CHANGE_SUCCESS');
            return data;
        }));
    }

    /**
     * Loads user preferences - settings of tables etc...
     */ 
    getUserPreferences() {
        var this_ = this;
        return this.http.get(`/my-profile/settings`).pipe(map(data => {
            this_.setUserPreferences(data
                .filter(item => item.itemValue.length > 0)
                .map(item => ({key: item.itemKey, value: JSON.parse(item.itemValue)}))
            );
            this_.sharedService.setLastQueryParams();  // apply remembered user preferences
            return data;
        } ));
    }

    /**
     * Sets user preference
     */    
    public setUserPreference(key: string, value: unknown, saveToServer?: boolean): void {
        // do not save if its the same as already saved
        if(JSON.stringify(value) == JSON.stringify(this.sharedService.user.preferences[key])) {return;}

        this.sharedService.user.preferences[key] = value;
        var valueString = JSON.stringify(this.sharedService.user);
        localStorage.setItem('user', valueString);

        if (saveToServer) {
            this.savePreferences([{
                itemKey: key,
                itemValue: JSON.stringify(value)}]).subscribe(function(res){});
        }
    }

    /**
     * Sets multiple user preferences
     */    
    setUserPreferences(prefs: {key: string, value: any}[], saveToServer?: boolean) {
        var this_ = this;
        var preferencesToBeSaved: {itemKey: string, itemValue: string}[] = [];
        prefs.forEach(function(pref){
            // save only if value is not same as already saved
            if (JSON.stringify(pref.value) != JSON.stringify(this_.sharedService.user.preferences[pref.key])) {
                this_.sharedService.user.preferences[pref.key] = pref.value;
                preferencesToBeSaved.push({itemKey: pref.key, itemValue: JSON.stringify(pref.value)});
            }
        });
        var userString = JSON.stringify(this_.sharedService.user);
        localStorage.setItem('user', userString);

        if (preferencesToBeSaved.length > 0 && saveToServer) {
            this.savePreferences(preferencesToBeSaved).subscribe(function(res){});
        }
    }

    /**
     * Saves user preference to server
     */
    savePreferences(prefs: {itemKey: string, itemValue: string}[]) {
        return this.http.put(`/my-profile/settings`, JSON.stringify(prefs));
    }

    /**
    * Logs user out, removes data from local storage
    */
    public logout(redir?: boolean): Observable<void> {
        return this.http
        .post(`/logout`, 
            JSON.stringify({
                accessToken: localStorage.getItem('access_token')
            })
        )
        .pipe(map(() => {
            this.sharedService.clear();
            this.cleanUpService.cleanUp();
            if (redir) {
                this.chatService.sendToChat({ setName: '.' });
                this.newsService.stopTimer();
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/home']);
            }
        }));
    }

    public setRepresentativeOverride(customerId: number, area: RepreOverrideAreas): Observable<void> {
        const data: RepreOverrideRequest = {
            customerId: customerId,
        };
        return concat(
                this.http.put(`/user-repre-override/${area}`, JSON.stringify(data)),
                this.http.refreshToken(this.http)
            ).pipe(
                toArray(), // only run subscribe once
                tap(result => {
                    const customer: { customerId: number, customerName: string } = result[0];

                    this.sharedService.user.repreOverride = {
                        customerId: customerId,
                        customerAsId: +this.sharedService.user.representsAuthorizedSeller.id,
                        area: area,
                        customerName: customer.customerName
                    };
                    localStorage.setItem('user', JSON.stringify(this.sharedService.user));
                }),
                map(() => {}) // return void
            );
    }

    public removeRepresentativeOverride(area: RepreOverrideAreas): Observable<void> {
        return concat(
                this.http.put(`/user-repre-override/${area}`, undefined),
                this.http.refreshToken(this.http)
            ).pipe(
                toArray(), // only run subscribe once
                tap(() => {
                    this.sharedService.user.repreOverride = null;
                    localStorage.setItem('user', JSON.stringify(this.sharedService.user));
                }),
                map(() => {}) // return void
            );
    }

    public getUserForRepreOverrideArea(area: RepreOverrideAreas): User {
        const user = this.sharedService.user;

        if (user.repreOverride && user.repreOverride.area === area) {
            const newUser = Object.assign({}, user);
            newUser.representative = Representative.customer;
            newUser.representsCustomer.id = user.repreOverride.customerId.toString();
            return newUser;
        } else {
            return user;
        }
    }

    public isRepreOverrideForArea(area: RepreOverrideAreas): boolean {
        const user = this.sharedService.user;

        return user.repreOverride && user.repreOverride.customerId && user.repreOverride.area === area;
    }

    public getUserNotificationRecurrency(): Observable<UserNotificationsRecurrency[]> {
        return this.http.get(`/my-profile/notifications-reccurency`).pipe(map((data: UserNotificationsRecurrencyResponseDto[]) => {
            return data.map(row => new UserNotificationsRecurrency(row));
        }));
    }

    public saveUserNotificationRecurrency(data: UserNotificationsRecurrency): Observable<void> {
        const dto = new UserNotificationsRecurrencyRequestDto(data);
        return this.http.put(`/my-profile/notifications-reccurency`, JSON.stringify(dto));
    }
}
