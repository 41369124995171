import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { LanguageService } from '../../../services/language.service';
import { WindowService } from '../../../services/window.service';
import { LocalDatePipe } from '../../../locale.pipes.module';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { FormService } from '../../../services/form.service';
import * as moment from 'moment';

@Component({
    selector: 'shipment-dialog',
    templateUrl: 'shipment.dialog.component.html'
})
export class ShipmentDialog {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;

    momentMinDate: any;
    shipmentDateInvalid = false;

    disableSubmit = false;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next();
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService, 
        public sharedService: SharedService, 
        private toastService: ToastService,
        private languageService: LanguageService, 
        private windowService: WindowService,
        private formService: FormService
    ) {
        this.windowService.height$.subscribe((value:any) => {
            //Do whatever you want with the value.
            //You can also subscribe to other observables of the service
            this.maxHeight = value - 120;
        });

        this.momentMinDate = this.formService.getClosestDeliveryDateIncluding(true);
    }

    ngOnInit() {
        setTimeout(() => {
            let momentShipmentDate = moment(
                this.params.shipmentDate.date.year + '-' +
                (this.params.shipmentDate.date.month < 10 ? '0' : '') +
                this.params.shipmentDate.date.month + '-' +
                (this.params.shipmentDate.date.day < 10 ? '0' : '') +
                this.params.shipmentDate.date.day);
            this.shipmentDateInvalid = !this.momentMinDate.isSameOrBefore(momentShipmentDate) || !this.formService.isWorkDay(momentShipmentDate);
        }, 0);
    }

    onDateChanged(event: IMyDateModel) {
        this.params.shipmentDate = event.jsdate;
        this.shipmentDateInvalid = !this.momentMinDate.isSameOrBefore(this.params.shipmentDate) 
            || !this.formService.isWorkDay(moment(this.params.shipmentDate));
    }

    close(data?: any) {
        this.disableSubmit = true;
        if (data) {
            this.doneSubject.next(data);
        } else {
            this.doneSubject.next();
        }
        this.bsModalRef.hide();
    }
}