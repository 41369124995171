<div *ngIf="priceListCode">
    <div class="row-container wider">
        <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/price-lists"
            [queryParams]="sharedService.lastPriceListsQueryParams">
            <i class="fa fa-arrow-circle-left"></i>
            <a translate>BACK</a>
        </button>
    </div>

    <div class="row-container wider">
        <div class="area-title">
            <h3><span translate>PRICE_LIST</span> {{priceListCode}}</h3>
        </div>
    </div>

    <div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
        <div fxFlex.gt-sm="25" class="row-container">
            <h4 translate>CUSTOMER_PRICE_LIST_CODE</h4>
            <span>{{priceListCode}}</span>
        </div>
        <div fxFlex.gt-sm="25" class="row-container">
            <h4 translate>CURRENCY</h4>
            <span>{{currency}}</span>
        </div>
    </div>

    <div class="row-container wider" style="margin-bottom: 20px;">
        <ecm-table
            [dataGetter]="getPriceListDetail"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [selection]="gridService.getSelection()"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [subjectDeleteSelected]="deleteSelected$"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
            [bulk]="bulk"
        ></ecm-table>
    </div>

</div>