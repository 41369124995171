<div class="home row-container wider" *ngIf="!sharedService.apParams.homeComponentName">
	<!-- ZVL -->
	<div class="zvl">
		<div fxLayout="column" fxLayout.gt-sm="row" style="max-width: 1100px; margin: 0 auto">
			<div fxFlex.gt-sm="250px" class="row-container">
				<div class="row-container bigger wider bkg-gray-darker center-text clickable" (click)="openLogin()">
					<img src="../../assets/images/ecommerce.png" style="width: 100%; max-width: 200px" />
				</div>
			</div>
			<div fxFlex.gt-sm="100" class="row-container">
				<div class="bkg-primary" style="padding-left: 5px;">
					<div class="row-container wider bigger bkg-gray-darker">
						<h2 class="fnt-primary" style="margin-top: 0" translate>HOME_PROFESSIONAL_MARKET</h2>
						<strong class="fnt-primary" translate>HOME_QUOTE</strong>
					</div>
				</div>
			</div>
			<div fxFlex.gt-sm="250px" class="row-container">
				<div class="row-container bigger wider bkg-gray-darker center-text" >
					<img src="../../assets/images/zvl-small.png"/>
				</div>
			</div>
		</div>

		<div fxLayout="column" fxLayout.gt-sm="row" style="max-width: 1100px; margin: 0 auto">
			<div fxFlex.gt-sm="250px" class="row-container">
				<div class="row-container  bkg-gray-darker">
					<div class="row-container item last">
						<strong class="fnt-primary" translate>HOME_WELCOME_TO_ECOMMERCE</strong>
					</div>
				</div>
			</div>
			<div fxFlex.gt-sm="100" class="row-container">
				<div *ngIf="showVideos && sharedService.apParams.video?.home">
					<video width="100%" height="350" controls>
						<source [src]="sharedService.assetsS3BucketUrl + '/' + sharedService.appSettings.language + '/' + sharedService.apParams.video.home" type="video/mp4">
					</video>
				</div>
			</div>
			<div fxFlex.gt-sm="250px" class="row-container">
				<div class="row-container bigger wider bkg-gray-darker center-text" >
					<p><strong class="fnt-primary" translate>HOME_ACTUAL_OFFER</strong></p>
					<p><strong class="fnt-primary">5570 <span translate>HOME_PRODUCTS_2p</span></strong></p>
				</div>

				<div style="margin-top: 15px;">
					<carousel>
						<slide>
							<img src="assets/images/slide_1.jpg" alt="First slide">
						</slide>
						<slide>
							<img src="assets/images/slide_2.jpg" alt="Second slide">
						</slide>
						<slide>
							<img src="assets/images/slide_3.jpg" alt="Third slide">
						</slide>
						<slide>
							<img src="assets/images/slide_4.jpg" alt="Fourth slide">
						</slide>
						<slide>
							<img src="assets/images/slide_5.jpg" alt="Fifth slide">
						</slide>
						<slide>
							<img src="assets/images/slide_6.jpg" alt="Sixth slide">
						</slide>
					</carousel>
				</div>
			</div>
		</div>
	</div>
	<!-- / ZVL -->

	<!-- IMETCZ-->
	<div class="imetcz" fxLayout="column" fxLayoutGap="80px" style="margin-top: 60px;">
		<div fxLayout="row" fxLayoutAlign="center center">
			<div fxFlex="280px" class="row-container bigger wider clickable" (click)="openLogin()">
				<img src="../../assets/images/ecommerce_imetcz.png"/>
			</div>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center">
			<div class="row-container bigger wider center-text">
				<h2 style="margin-top: 0px;">
					<div translate>IMET_HOME_MOTTO_1</div>
					<div translate>IMET_HOME_MOTTO_2</div>
				</h2>
			</div>
		</div>
	</div>
	<!-- / IMETCZ-->

	<!-- AS5-->
	<div class="as5" fxLayout="column" fxLayoutGap="80px" style="margin-top: 60px;">
		<div fxLayout="row" fxLayoutAlign="center center">
			<div fxFlex="280px" class="row-container bigger wider clickable" (click)="openLogin()">
				<img src="../../assets/images/ecommerce.png"/>
			</div>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center">
			<div class="row-container bigger wider center-text">
				<h2 style="margin-top: 0px;">
					<div translate>IMET_HOME_MOTTO_1</div>
					<div translate>IMET_HOME_MOTTO_2</div>
				</h2>
			</div>
		</div>
	</div>
	<!-- / AS5-->

	<div fxLayout="column" fxLayout.gt-sm="row" class="icons" style="max-width: 1100px; margin: 150px auto 10px;">
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/availability_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>AVAILABILITY</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/speed_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>SPEED</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/simplicity_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>SIMPLICITY</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/saving_' + sharedService.apParams.iconSet + '.png'" />
			<div translate>SAVING</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/support_' + sharedService.apParams.iconSet + '.png'" />
			<div translate>SUPPORT</div>
		</div>
	</div>
</div>

<div *ngIf="sharedService.apParams.homeComponentName">
	<app-home-as1 *ngIf="sharedService.apParams.homeComponentName === 'as1'"></app-home-as1>
</div>