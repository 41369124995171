import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { RoleCustomer } from '@app/model/customer.model';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SharedService } from '@services/shared.service';
import { CustomersService } from '@services/customer/customers.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { UserService } from '@app/services/user/user.service';
import { RepreOverrideAreas } from '@app/model/user.model';

enum UserRoles {
    as = 'as',
    customer = 'customer'
}
@Component({
    selector: 'app-user-role-select',
    templateUrl: 'user-role-select.component.html',
    styleUrls: ['./user-role-select.component.scss']
})
export class UserRoleSelectComponent implements OnInit {
    @Input() repreOverrideArea: RepreOverrideAreas;
    @Input() createCustomerIfNotFound: boolean;
    @Output() onRoleChange: EventEmitter<void> = new EventEmitter<void>();

    public role: UserRoles;
    public userRoles = UserRoles;
    public customer: RoleCustomer;
    public suggestions$: Observable<RoleCustomer[]>;
    public search: string;
    public customerNotFound: boolean;
    public locality: string;
    public priceListCurrency: string;

    constructor(
        public sharedService: SharedService,
        private customersService: CustomersService,
        private toastService: ToastService,
        private userService: UserService,
        private rendeder: Renderer2
    ) {}

    public ngOnInit(): void {
        const user = this.sharedService.user;

        // Set the role and customer (if had been set before)
        if (this.userService.isRepreOverrideForArea(this.repreOverrideArea)) {
            this.role = UserRoles.customer;
            this.customer = {
                id: user.repreOverride.customerId,
                customerName: user.repreOverride.customerName
            };
            this.search = this.customer.customerName
        } else {
            this.role = UserRoles.as;
        }
        this.init();

        this.suggestions$ = new Observable((observer: Observer<string>) => {
                observer.next(this.search);
            }).pipe(
                switchMap((query: string) => {
                    if (query) {
                        return this.customersService.getCustomersForRepreOverrideByName(query, this.repreOverrideArea).pipe(
                            map((data: RoleCustomer[]) => data || []),
                            tap(() => noop, err => {
                                console.error(err);
                            })
                        );
                    }
       
                    return of([]);
                })
            );
    }

    public _onRoleChange(): void {
        if (this.role === UserRoles.as) {
            this.userService.removeRepresentativeOverride(this.repreOverrideArea).subscribe(
                () => {
                    this.toastService.addSuccess('SWITCHED_TO_AS');
                    this.onRoleChange.next();
                },
                err => {
                    console.error(err);
                }
            );
        } else {
            // Focus the input element
            const searchInputElement = this.rendeder.selectRootElement('#searchInput');
            setTimeout(() => searchInputElement.focus(), 0);

            if (this.customer) {
                searchInputElement.select(); // select text in the search input
                this.selectCustomer(this.customer);
            }
        }
    }

    public onSelect(selected: {item: RoleCustomer}): void {
        this.selectCustomer(selected.item);
        // Remove focus from the input element
        const searchInputElement = this.rendeder.selectRootElement('#searchInput');
        setTimeout(() => searchInputElement.blur(), 0);
    }

    private selectCustomer(customer: RoleCustomer): void {
        this.userService.setRepresentativeOverride(customer.id, this.repreOverrideArea)
        .subscribe(
            () => {
                this.customer = customer;
                this.toastService.addSuccess('SWITCHED_TO_CUSTOMER');
                this.onRoleChange.next();
            },
            err => {
                console.error(err);
            });
    }

    public typeaheadNoResults(noResults: boolean): void {
        this.customerNotFound = noResults;

        if (this.customerNotFound) {
            this.customer = null;
        }
    }

    public create(): void {
        this.customersService.createCustomerForRepreOverride(this.search, this.priceListCurrency, this.locality, this.repreOverrideArea)
        .subscribe(newCustomer => {
            console.log('newCustomer', newCustomer);
            this.customerNotFound = false;
            this.toastService.addSuccess('CUSTOMER_CREATED_SUCCESS');
            return this.selectCustomer(newCustomer);
        },
        err => {
            // This does not happen :(
            if ('message' in err && err.message === 'CUSTOMER_WITH_GIVEN_NAME_ALREADY_EXISTS') {
                this.toastService.addError('CUSTOMER_WITH_GIVEN_NAME_ALREADY_EXISTS');
            } else {
                console.error(err);
            }
        })
    }

    private init(): void {
        this.locality = 'SK';
        this.priceListCurrency = 'EUR';
    }

}
