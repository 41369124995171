/**
 * Grid Dropdown Renderer/Editor
 *
 * This component creates dropdown element (select box) in one file without Editor Component. Permits to create default preddefined
 * value ("Please select option"). After selecting one option, run onChange() and notify ChangeDetectorRef
 * (which allows to save/refresh whole row)
 */

import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
import { TableService } from '../../../../services/table.service';
/* import { FormService } from '../../../../services/form.service'; */
import { SharedService } from '../../../../services/shared.service';
/* import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../services/language.service'; */

@Component({
  selector: 'grid-dropdown',
  template: `
    <div class="center-text">
      <ng-template #popError><span translate>{{params.noMoreThanError}}</span></ng-template>
      <select class="form-control" [ngStyle]="getStyle()" style="margin-top: -3px; padding: 4px; height: 27px; font-size: 98%;"
              *ngIf="editable"
              container="body" triggers=""
              [(ngModel)]="params.value" (ngModelChange)="onchange()">
          <option [selected]="isSelected(params.pleaseSelect)" value="" translate>
              {{ params.pleaseSelect }}
          </option>
          <option  *ngFor="let item of params.items" value="{{ item }}" [selected]="isSelected(item)">
              <span translate>{{ params.translatePrefix + item }}</span>
          </option>
      </select>
      </div>
      <div [ngStyle]="style" *ngIf="!editable">
        <span *ngIf="params.value" translate>
          {{ params.translatePrefix + params.value }}
        </span>
      </div>
               `
})
export class GridDropdownRendererComponent implements AgRendererComponent {
  // noMoreThan: 'availableToShip'
  // noMoreThanError: 'AMOUNT_SHIPMENT_MORE_THAN_AVAILABLE_TO_SHIP_ERROR'
  // isColValid
  params: any;
  editable: boolean;
  maxLength = "10"; // optional
  maxWidth = "100px"; // optional
  textAlign = "right"; // optional
  number = true; // optional formatting
  setEditableSubscription: Subscription;
  style: object;

  constructor(
    private tableService: TableService,
    public sharedService: SharedService
  ) {}

  agInit(params: any): void {
    this.params = params;
    // editable always, but when editable in params, take that value
    this.editable = params.hasOwnProperty("editable") ? params.editable : true;

    // if function getEditable is defined use that to get editable value - used for editable on each row different
    this.editable = params.hasOwnProperty("getEditable")
      ? params.getEditable(params)
      : params.editable;
    this.maxLength = this.params.maxLength
      ? this.params.maxLength
      : this.maxLength;
    this.maxWidth = this.params.maxWidth ? this.params.maxWidth : this.maxWidth;
    this.style = {
      "text-align": this.params.textAlign
        ? this.params.textAlign
        : this.textAlign
    };
    this.number = "number" in this.params ? this.params.number : this.number;
    const this_ = this;
    // save original values for cancel action
    if (!this.params.data.hasOwnProperty("originalValues")) {
      this.params.data.originalValues = [];
    }
    if (
      this.params.data.originalValues.filter(function(e) {
        return e.colId === this_.params.column.colId;
      }).length === 0
    ) {
      this_.params.data.originalValues.push({
        colId: this_.params.column.colId,
        value: this_.params.value
      });
    }

    this.setEditableSubscription = this.tableService
      .getEditableObservable()
      .subscribe(function(res: any) {
        if (res.editable) {
          if (res.editableColIds.indexOf(this_.params.column.colId) > -1) {
            this_.editable = true;
          }
        }
      });

    this.setEditableSubscription = this.tableService
      .getEditableObservable()
      .subscribe(function(res: any) {
        if (res.editable) {
          if (res.editableColIds.indexOf(this_.params.column.colId) > -1) {
            this_.editable = true;
          }
        }
      });
  }

  getDefaultValue() {
    if (!this.params.value || this.params.value === "") {
      return this.params.pleaseSelect;
    } else {
      return this.params.value;
    }
  }
  onchange() {
    const this_ = this;
    setTimeout(function() {
      const data = this_.params.api.getModel().rowsToDisplay[
        this_.params.rowIndex
      ].data;
      data.edited = true;
      data[this_.params.column.colId] = this_.params.value;
      if (this_.params.editedIdsObj) {
        this_.params.editedIdsObj[data.id] = true;
      }
      if (this_.params.selection) {
        if (
          this_.params.selection.ids[data.id] &&
          !this_.params.selection.newSelectedIds[data.id]
        ) {
          this_.params.selection.updatedIds[data.id] = data;
        }
      }
      this_.sharedService.appComponent.cdRef.detectChanges();
    }, 0);
  }
  isSelected(item) {
    let result = "";
    switch (true) {
      case (!this.params.value || this.params.value === "") &&
        item === this.params.pleaseSelect:
        result = "selected";
        break;
      case this.params.value === item:
        result = "selected";
        break;
    }
    return result;
  }
  isValid() {
    return this.params.isColValid
      ? this.params.isColValid(
          this.params.column.colId,
          this.params.data[this.params.column.colId],
          this.params.data
        )
      : true;
  }

  isDisabled() {
    if (!this.params.editedIdsObj) {
      return false;
    }
    if (Object.keys(this.params.editedIdsObj).length === 0) {
      return false;
    }
    return !!!this.params.editedIdsObj[this.params.data.id];
  }

  getStyle() {
    return {
      "background-color": !this.isValid() ? "rgba(255, 84, 98, 0.5)" : "", // mark red when more than given value or 0
      "max-width": this.maxWidth
    };
  }

  refresh(): boolean {
    // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
    return false;
  }

  ngOnDestroy() {
    this.setEditableSubscription.unsubscribe();
  }
}
