import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DeliveryOrdersService } from '../../../services/deliveryOrder/delivery.orders.service';
import { DeliveryOrdersGridService } from '../../../services/deliveryOrder/delivery.orders.grid.service';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { Subject } from 'rxjs';
import { ExportObj, TableBulk, TableBulkTypes } from '@app/model/table.model';
import { PersonCreated } from '@app/model/personCreated.model';

@Component({
  selector: 'delivery-orders',
  templateUrl: 'delivery.orders.component.html'
})
export class DeliveryOrdersComponent  { 

	totalRows:          number;
	filterItems: 		any[];
	personsIssued: PersonCreated[] = [];
	subjectRefresh = new Subject<any>();
	subjectAllDeliveryOrdersSelected: Subject<any>;
	public bulk: TableBulk;
	public exportObj: ExportObj

	busy:				boolean;
	initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table

	constructor(private router: Router, 
				private route: ActivatedRoute,
				private deliveryOrdersService: DeliveryOrdersService,
				private deliveryOrdersGridService: DeliveryOrdersGridService,
				public sharedService: SharedService,
				private toastService: ToastService) {
		this.subjectRefresh = new Subject<any>();
		this.subjectAllDeliveryOrdersSelected = deliveryOrdersGridService.getItemsSelectedSubject();

		this.getDeliveryOrders = this.getDeliveryOrders.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);

		this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
			this.exportObj = {
				fileName: this.sharedService.translateService.instant('DELIVERY_ORDERS_SUMMARY'), 
				export: this.deliveryOrdersService.exportAll.bind(this.deliveryOrdersService)
			};
		});

		let exportFunction = () => {
			let allowedStates = this.deliveryOrdersGridService.allowedStatesToExport;
			let selection = this.sharedService.user.preferences.deliveryOrdersSelection;
			let allowedItems = Object.keys(selection.ids).reduce((acc, item) => {
				if (allowedStates.indexOf(selection.ids[item].state) >= 0) {
					acc.push(selection.ids[item]);
				}
				return acc;
			}, []);
			if (!selection.all && allowedItems.length === 0) { // if nothing to export
				this.toastService.addError('DELIVERY_ORDER_ALL_NOT_IN_ALLOWED_STATE_FOR_EXPORT');
				return;
			}
			if (selection.all || (allowedItems.length < Object.keys(selection.ids).length)) { // if some are in state that is not allowed for export
				this.toastService.addSuccess('DELIVERY_ORDER_EXPORTED_WILL_BE_ONLY_ALLOWED_STATES');
			}
			this.deliveryOrdersService.export(
				selection.all ? null : allowedItems.map(item => item.id), 
				this.sharedService.translateService.instant('DELIVERY_ORDERS_SUMMARY'), 'CSV', true);
		};
		this.bulk = {
			items: [
				{id: 'exportToCSV', name: 'EXPORT_TO_CSV', type: TableBulkTypes.button, iconClass: 'fa-download', click: exportFunction.bind(this)}, 
			]
		}
		if (!this.sharedService.user.preferences.hasOwnProperty('deliveryOrdersSelection')) {
            this.sharedService.user.preferences.deliveryOrdersSelection = {ids: {}, all: false, visible: false};
        }

		// to reload when menu item is clicked but it is already routed to this area
		this.sharedService.subjectSameAreaClicked.subscribe(res => {
			this.subjectRefresh.next();
		});
		
        this.initialized = true;
	}

	ngOnInit() {
		// set default filter if not filter already in query
		if (!this.route.snapshot.queryParams['state.value'] && !this.sharedService.user.preferences[this.sharedService.getUserPreferenceKey('Filter', '')]) {
			this.sharedService.lastDeliveryOrdersQueryParams['state.operator'] = 'in';
			this.sharedService.lastDeliveryOrdersQueryParams['state.value'] = 'X,N,S,C';
			this.router.navigate([], {queryParams: this.sharedService.lastDeliveryOrdersQueryParams});
		}
		var this_ = this;
		setTimeout(function() {			
			// navigate with orderby parameter
			this_.sharedService.navigateWithOrderby(this_.getColumns(), 
				this_.route.snapshot.queryParams, this_.sharedService.lastDeliveryOrdersQueryParams);
		}, 0);
	}

	getDeliveryOrders(params) {
		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};

		this.sharedService.lastDeliveryOrdersQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastDeliveryOrdersQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

		this.deliveryOrdersService.getDeliveryOrders(query)
		.subscribe(data => {
			this.totalRows = data.total;
			this.personsIssued = data.personCreatedSYS;
			if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
			params.successCallback(data.rows, data.total);
			this.busy = false;
        }, err => {
        	console.log(err);
        	// also in error to be able to cancel filter
        	if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
        	this.busy = false;
        });
	}
	getGridOptions() {
		return this.deliveryOrdersGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.deliveryOrdersGridService.getFilterItems(this.personsIssued);
	}
	getColumns() {
		return this.deliveryOrdersGridService.getColumns();
	}
	getColumnsDefs() {
		return this.deliveryOrdersGridService.getColumnDefs();
	}
}