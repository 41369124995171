<div class="backdrop" *ngIf="getEditedCount() > 0"></div>

<ecm-table 
    [dataGetter]="getRfqItems" 
    [optionsGetter]="getGridOptions" 
    [columnsGetter]="getColumns" 
    [columnsDefsGetter]="getColumnsDefs"
    [filterItems]="filterItems"
    [total]="totalRows" 
    [selection]="sharedService.user.preferences['rfqItemsSelection']"
    [subjectAllSelectedChange]="subjectAllSelected"
    [editedIdsObj]="rfqGridService.rfqItemsEditedIds"
    [observableRefresh]="subjectRefresh.asObservable()" 
    [showRefreshButton]="true" 
    [observableReloadCurrent]="subjectReloadCurrent.asObservable()"
    [bulk]="bulk"
    [busy]="busy"
    [secondaryHeaderColor]="true" 
    [exportObj]="exportObj"
    [showNewRowButton]="showNewRowButton"
    [newRowGetter]="getNewRow"
    [suppressEmptyMessage]="true"
    [suppressPagination]="true"
    [showTotalRowsInsteadPagination]="true"
    [tableNamePrefix]="tableNamePrefix"
></ecm-table>
