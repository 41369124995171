import { Component } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { ToastService } from '../../../../services/toastService/toast.service';
import { SharedService } from '../../../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'distributor-stores-actions-renderer',
    templateUrl: './grid.distributor.stores.actions.renderer.component.html'
})
export class GridDistributorStoresActionsRendererComponent implements AgRendererComponent {
    // isRowValid
    params: any;
    setEditableSubscription: Subscription;
    buttonsVisibility: any = {};
    subjectReloadCurrent: Subject<any>;

    constructor(
        private toastService: ToastService,
        private sharedService: SharedService,
        private translateService: TranslateService,
        private router: Router,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
    deleteItem() {
        this.params.deleteStockItems([this.params.data.id]).subscribe(
            success => {
                this.toastService.addSuccess('DISTRIBUTOR_STORES_ITEM_SUCCESSFULLY_DELETED');
                this.params.subjectReloadCurrent.next();
            },
            err => console.log(err)
        );
    }

    save() {
        this.params.updateStockItems([this.params.data.id], { amount: this.params.data.amount }).subscribe(
            () => {
                this.params.data.edited = false;
                delete this.params.editedIdsObj[this.params.data.id];
                this.params.data.originalValues[0].value = this.params.data.amount;
                // console.log(this.params);
                // this.subjectReloadCurrent.next();
                this.params.api.redrawRows();
            },
            err => console.log(err)
        );

    }

    cancel() {
        this.params.data.originalValues.forEach((item) => {
            this.params.data[item.colId] = item.value;
        });
        this.params.data.edited = false;
        delete this.params.editedIdsObj[this.params.data.id];
        this.params.api.redrawRows();
    }
}
