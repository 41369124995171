import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service'

@Injectable()
export class ToastService {
    query: string[] = []  // currently displayed messages - used to not display the same again

    constructor(private toastrService: ToastrService,
                private translateService: TranslateService,
                private sharedService: SharedService) {
    }

    /**
     * Displays success toast message
     *
     * @param {string} message - text of message to be displayed
     * @param {number} time    - optional - timeout in miliseconds
     */
    addSuccess(message: string, time?: number) {
        const this_ = this;
        this.translateService.get(message, {value: ''}).subscribe((res: string) => {
            const toastOptions = {
                closeButton: true,
                timeOut: time ? time : 5000,
                positionClass: 'toast-top-center'
            };
            this.toastrService.success('', res, toastOptions);
            // because toast is not showing and hiding sometimes
            this.sharedService.appComponent.cdRef.detectChanges();
            setTimeout(function() {
                this_.sharedService.appComponent.cdRef.detectChanges();
            }, time ? time + 1 : 5001);
        });
    }

    /**
     * Displays error toast message
     *
     * @param {string} message - text of message to be displayed
     * @param {number} time    - optional - timeout in miliseconds
     */
    addError(message: string, time?: number) {
        const this_ = this;
        this.translateService.get(message, {value: ''}).subscribe((res: string) => {
            if (this_.query.indexOf(res) > -1) {return; } // do not add already added

            const toastOptions = {
                closeButton: true,
                timeOut: time ? time : 5000,
                positionClass: 'toast-top-center'
            };
            this.toastrService.warning('', res, toastOptions);
            // because toast is not showing and hiding sometimes
            this.sharedService.appComponent.cdRef.detectChanges();
            setTimeout(function() {
                this_.sharedService.appComponent.cdRef.detectChanges();
            }, time ? time + 1 : 5001);
        });
    }
}
