<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container clickable" routerLink="/{{sharedService.appSettings.language}}/price-lists">
            <span translate>PRICE_LIST_SUMMARY</span>
        </span>
        <span class="row-container tab-active" style="margin-right: 5px;">
            <span translate>PRICE_LIST_ITEMS</span>
        </span>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getPriceListItems"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [observableRefresh]="refresh$.asObservable()"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>