<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container tab-active" style="margin-right: 5px;">
                <span translate>PRICE_LIST_SUMMARY</span>
            </span>
            <span class="row-container clickable"
                routerLink="/{{sharedService.appSettings.language}}/price-lists-items">
                <span translate>PRICE_LIST_ITEMS</span>
            </span>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
            <button class="btn-primary" (click)="addPriceList()" translate>ADD_PRICE_LIST</button>
        </div>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getPriceLists"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>