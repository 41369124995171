import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { SupplierListResponse, Supplier } from '@app/model/supplier.model';
import { EcmHttpService, EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { ToastService } from '@services/toastService/toast.service';

@Injectable()
export class SupplierService {

    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private toastService: ToastService
    ) {
    }

    public getSuppliers(query: EcmHttpQueryParams): Observable<SupplierListResponse> {
        return this.http.get('/suppliers', this.http.prepareOptions(query))
            .pipe(map((response: SupplierListResponse) => {
                const rows = response.rows.map(row => Object.assign({}, row, {
                    url: '/supplier/' + row.id
                }));

                return {
                    rows: rows,
                    totalCount: response.totalCount
                }
            }));
    }

    public exportSuppliers(query: EcmHttpQueryParams): Observable<SupplierListResponse> {
        return this.http.get('/suppliers', this.http.prepareOptions(query));
    }

    public createSupplier(supplier: Supplier): Observable<void> {
        const _supplier = Object.assign({}, supplier);
        const ATTRS_TO_DELETE = ['id', 'url', 'notActivatedAdminUser'];
        ATTRS_TO_DELETE.forEach(attr => {
            delete _supplier[attr];
        });

        return this.http.post(`/suppliers`, JSON.stringify(_supplier))
            .pipe(
                map(() => {
                    this.toastService.addSuccess('SUPPLIER_CREATED_SUCCESS');
                }),
                catchError(err => {
                    if (err.error.message === 'SUPPLIER_WITH_GIVEN_CODE_EXISTS') {
                        this.toastService.addError('SUPPLIER_WITH_GIVEN_CODE_EXISTS');
                    }
                    return throwError(err);
                })
            );
    }

    public resendInvite(user: { id: number, email?: string }): Observable<void> {
        return this.http.put('/users/' + user.id + '/resend-invite',
            user.email ? JSON.stringify({ email: user.email }) : '')
            .pipe(map(() => {
                this.toastService.addSuccess('INVITE_SEND_AGAIN_SUCCESS');
            }));
    }

    public getSupplier(supplierId: number): Observable<Supplier> {
        return this.http.get('/suppliers/' + supplierId);
    }

    public getAvailablePermissionGroups(): Observable<string[]> {
        return this.http.get('/suppliers/available-options').pipe(map(result => result.groups));
    }

    public updateSupplier(supplierId: number, partialSupplier: Partial<Supplier>): Observable<void> {
        return this.http.put(`/suppliers/${supplierId}`, JSON.stringify(partialSupplier))
            .pipe(
                map(() => {
                    this.toastService.addSuccess('CHANGES_APPLIED_SUCCESSFULLY');
                }),
                catchError(err => {
                    if (err.error.message === 'SUPPLIER_WITH_GIVEN_CODE_EXISTS') {
                        this.toastService.addError('SUPPLIER_WITH_GIVEN_CODE_EXISTS');
                    }
                    return throwError(err);
                })
            );
    }

}
