import { Component, Inject, Input, ViewContainerRef, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef,
    Pipe, PipeTransform, ViewChildren, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../services/shared.service';
import { DiscussionService } from '../../../services/discussion.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import {Animations} from '../../../animations';
import { CommentItem, CommentProductItem, DiscussionPrivateConfig } from '@app/model/discussion.model';

@Component({
  selector: 'discussion',
  templateUrl: 'discussion.component.html',
  styleUrls:  ['./discussion.component.scss'],
  animations: [Animations.slideInOut]
})
export class DiscussionComponent implements OnInit, OnDestroy {
    @ViewChild('scrollContainer', { static: true }) private myScrollContainer: ElementRef;
    @ViewChildren('newCommentInput', {read: ViewContainerRef}) newCommentInput;

    @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() added: EventEmitter<number> = new EventEmitter<number>();
    // Emits boolean when edited or changed
    @Output() edited: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public itemArea: string;
    @Input() public itemId: string;
    @Input() public comentToFilterItemId: string; // defined if its in dialog, so show only comments to this item
    @Input() public maxHeight: number;
    @Input() public item: CommentProductItem; // defined if its in dialog, so show only comments to this item
    @Input() public getItemLabel: Function;
    @Input() public forceLoadOnInit: boolean;
    @Input() public showOnlyEmptyChildTableKeyItems: boolean;
    @Input() public isModal: boolean;
    @Input() makeCollapsible: boolean;   // Set Header and Body for collapsible component (after click on header then body will collapse
    @Input() isCollapsed;                 // set collapsed state for this element
    @Input() public showPrivateControls?: boolean; // if to show buttons and icons related to private posts
    @Input() public privateConfig?: DiscussionPrivateConfig; // more info in a case showPrivateControls === true
    @Input() private publishPostsCount?: boolean; // if true it will update DiscussionService.postsCount$
    @Output() isCollapsedChange = new EventEmitter();       // emit collapsed state for this element
    itemLabel: string;
    public comments: CommentItem[];
    loadBusy: boolean;
    subscribtionComment: Subscription;
    showMergedLabel = true;
    showDeliveryDate = true;

    newComment = '';
    sendBusy: boolean;
    showNotSavedQuestion = false;
    public isNewCommentPrivate = null;

    constructor(
        public sharedService: SharedService,
        private cdRef: ChangeDetectorRef,
        private discussionService: DiscussionService,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    ngOnInit() {
        this.makeCollapsible = this.makeCollapsible ? this.makeCollapsible : false;
        this.isCollapsed = this.isCollapsed ? this.isCollapsed : false;
        const this_ = this;
        this.subscribtionComment = this.discussionService.getCommentObservable().subscribe(function(res) {
            this_.loadDiscussion();
        });

        this.loadDiscussion(this.forceLoadOnInit); // called with true when load of comment is neccessary in each init e.g. items of orders screen
        if (this.comentToFilterItemId) {
            this.focusNewComment();
        }

        if (this.getItemLabel && this.item) {
            this.itemLabel = this.getItemLabel(this.item);
        }
        this.showMergedLabel = (this.itemArea === 'rfq') ? false : true;
        this.showDeliveryDate = this.itemArea !== 'delivery-orders';
    }

    toggleCollapse() {
        this.isCollapsed  = (this.makeCollapsible) ? !this.isCollapsed : null;
        this.isCollapsedChange.emit(this.isCollapsed);
        this.isNewCommentPrivate = null;
    }

    focusNewComment() {
        const this_ = this;
        setTimeout(function() {
            this_.newCommentInput.first.element.nativeElement.focus(); // focus input when in dialog
        }, 0);
    }

    setDisplayItem(item) {
        this.item = item;
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#newComment' });
    }

    loadDiscussion(forceReload?: boolean) {
        if (!this.comentToFilterItemId || forceReload) {
            this.loadBusy = true;
            this.discussionService.getDiscussion(this.itemArea, this.itemId)
            .subscribe(data => {
                this.filterComments(data.rows);
                this.discussionService.getCommentAddedToItemObservable().next(''); // to refresh count in table when added from under table not dialogue
                if (this.publishPostsCount) {
                    this.discussionService.publishPostsCount(data.rows.length);
                }
                this.sharedService.appComponent.cdRef.detectChanges();
                if (this.loaded) {
                    this.loaded.emit('');
                }
                this.loadBusy = false;
            }, err => {
                console.log(err)
                this.loadBusy = false;
            });
        } else {
            this.comments = [];
            this.filterComments(this.sharedService.currentDiscussionComments);
        }
    }

    filterComments(commentsRows: any) {
        const this_ = this;
        this_.comments = [];
        commentsRows.forEach(function(comment) {
            if (this_.showOnlyEmptyChildTableKeyItems) {
                if (comment.childTableKey.length === 0) {
                    this_.comments.push(comment);
                }
            } else {
                if (!this_.comentToFilterItemId) {
                    if (this_.getItemLabel) {
                        comment.commentItemLabel = this_.getItemLabel(comment);
                    }
                    this_.comments.push(comment);
                } else if (comment.childTableKey == this_.comentToFilterItemId) {
                    this_.comments.push(comment);
                }
            }
        });

        if (this_.comentToFilterItemId) {
            setTimeout(function() {
                try {
                    this_.myScrollContainer.nativeElement.scrollTop = this_.myScrollContainer.nativeElement.scrollHeight;
                } catch (err) { }
            }, 0);
        }
    }

    reply(comment) {
        comment.displayItem.id = comment.childTableKey;
        this.setDisplayItem(comment.displayItem);
        this.newCommentInput.first.element.nativeElement.focus();
    }

    commentOnchange() {
        this.cdRef.detectChanges();
        this.edited.emit(this.newComment.length !== 0);
    }


    public sendNewComment(): void {
        this.sendBusy = true;
        this.cdRef.detectChanges();
        this.discussionService.sendComment(
            this.itemArea, this.itemId, this.newComment,
            this.item && !this.showOnlyEmptyChildTableKeyItems ? (this.item.lineNumber ? this.item.lineNumber : this.item.id) : null,
            this.showPrivateControls && this.isNewCommentPrivate
        ).subscribe(() => {
            this.newComment = '';
            this.edited.emit(false);
            if (!this.comentToFilterItemId || this.forceLoadOnInit) {
                this.item = null;
                this.cdRef.detectChanges();
                this.loadDiscussion(true);
            }
            if (this.added) {
                this.added.emit(1);
            }
            this.sendBusy = false;
        }, err => {
            this.sendBusy = false;
            console.log(err)
        });
    }

    public onIsNewCommentPrivateChange(isNewCommentPrivate: boolean): void {
        if (this.isModal && !isNewCommentPrivate) {
            // Timeout gives Angular time to insert the element to DOM, so that scroll can find it
            setTimeout(() => {
                this.pageScrollService.scroll({
                    document: this.document,
                    scrollTarget: '#privateConfigWarning',
                    scrollViews: [this.myScrollContainer.nativeElement]
                });
            }, 0);
        }
    }

    /**
     * Used in dialog mode only (isModal === true) to close the modal
     */
    close() {
        if (this.newComment.length !== 0) {
            this.showNotSavedQuestion = true;
        } else {
            this.added.emit(0);
        }
    }

    public forceClose(): void {
        this.showNotSavedQuestion = false;
        this.added.emit(0);
    }

    doNotClose() {
        this.showNotSavedQuestion = false;
    }

    ngOnDestroy() {
        this.cdRef.detach();
        this.subscribtionComment.unsubscribe();
    }
}
