import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json' + '?cb=' + (new Date()).getTime());
}

@Injectable()
export class Translate {
    languages = [
        'cz',
        'en',
        'pl',
        // 'de',
        // 'es',
        // 'fr',
        'ru',
        'sk',
    ]
}
