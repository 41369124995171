import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User, UserGroup, NotificationRole, NotificationRoleWithRules, Representative, RepreOverrideAreas } from '@model/user.model'
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared.service';
import { LanguageService } from '../../services/language.service';
import { ToastService } from '../../services/toastService/toast.service';
import { UsersAdminService } from '../../services/user/users.admin.service';
import { Subscription } from 'rxjs';
import { QuestionDialogService,
    QuestionDialogResult } from '@components/questionDialogComponent/question.dialog.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { AddAsUserDialogService } from './addAsUserDialogComponent/add.as.user.dialog.service';

const ALL_SUBCUSTOMER_USER_GROUPS: UserGroup[] = [
    { id: 'ProductsReadReduced3' },
    { id: 'PreordersCreate' }
];

interface UserGroupForDropdown {
    id: string; // 'ProductsRead_AS'
    name: string; // 'SECURITY_GROUP_' + 'ProductsRead_AS'
    checked: boolean;
}

interface NotificationRoleForDropdown {
    id: number; // 91
    name: string; // 'NOTIFICATION_RULE_' + 'orderCreation'
    checked: boolean;
}

interface UserExtended extends User {
    availableGroupsForDropdown: UserGroupForDropdown[];
    availableNotificationRolesForDropdown: NotificationRoleForDropdown[];
}

@Component({
  selector: 'app-users-admin',
  templateUrl: 'users.admin.component.html',
  styleUrls:  ['./users.admin.component.scss']
})
export class UsersAdminComponent implements OnDestroy {
    public newUser: UserExtended;
    public newUserVisible = false;

    public viewDisabled = false;
    public users: UserExtended[] = [];
    private allAvailableGroups: UserGroup[] = []; // all groups available

    private allAvailableNotificationRoles: NotificationRoleWithRules[] = []; // all groups available
    public roleRuleMap: {
        [key: string]: { [key: string]: string[] }
    } = {}; // {<roleId>: {rules: [<ruleName>]}}
    public busy: boolean;
    private theSameClickedSubscription: Subscription;

    private subcustomerUserGroups: UserGroup[];
    private readonly subcustomerNotificationRoleNames: string[] = [ 'preorderCreation' ];
    public subcustomersAvailable: boolean;
    private isCustomerMode: boolean;  // the app is currently used by a customer user, not AS user
    public readonly repreOverrideAreaUserAdmin = RepreOverrideAreas.userAdmin;

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    isEditing(): boolean {
        return this.newUserVisible;
    }

    constructor(
        public sharedService: SharedService,
        private toastService: ToastService,
        private translate: TranslateService,
        private languageService: LanguageService,
        private usersAdminService: UsersAdminService,
        private questionDialogService: QuestionDialogService,
        private addAsUserDialogService: AddAsUserDialogService
    ) {
        this.subcustomersAvailable = this.sharedService.params.features.subcustomers &&
            this.sharedService.user.representative === Representative.customer;

        this.isCustomerMode = this.sharedService.user.representative === Representative.customer;

        this.loadAvailableUserOptions();

        this.languageService.getLanguageObservable().subscribe((res: string) => {
            if (this.newUser) {
                this.newUser.language = res.toUpperCase();
            }
        });

        // to reload when menu item is clicked but it is already routed to this area
        this.theSameClickedSubscription = this.sharedService.subjectSameAreaClicked.subscribe(res => {
            if (this.sharedService.area === 'users-admin') {
                this.loadAvailableUserOptions();
            }
        });
    }

    private loadUsers(): void {
        this.usersAdminService.getUsers(this.sharedService.user.representative, this.viewDisabled).subscribe(users => {
            this.users = users.map(user => Object.assign({}, user, {
                availableGroupsForDropdown: this.getGroupsForUser(user),
                availableNotificationRolesForDropdown: this.getNotificationRolesForUser(user)
            }));
        }, err => {
            console.log(err)
        });
    }

    private loadAvailableUserOptions(): void {
        this.usersAdminService.getAvailableUserOptions().subscribe(availableUserOptions => {
            this.allAvailableGroups = availableUserOptions.groups;
            this.allAvailableNotificationRoles = availableUserOptions.notificationRoles;
            this.allAvailableNotificationRoles.forEach(role => {
                this.roleRuleMap[role.id] = {rules: role.rules};
            });

            // Use only the subcustomer groups which are available to the current customer
            this.subcustomerUserGroups = ALL_SUBCUSTOMER_USER_GROUPS.filter(subcustomerGroup =>
                this.allAvailableGroups.find(group => group.id === subcustomerGroup.id));

            const newUser = new User(null, '', '', '', '', [], [], this.translate.currentLang.toUpperCase(),
                false, null, '', false);
            this.newUser = Object.assign({}, newUser, {
                availableGroupsForDropdown: this.getGroupsForUser(newUser),
                availableNotificationRolesForDropdown: this.getNotificationRolesForUser(newUser)
            });

            this.loadUsers();
        }, err => {
            console.log(err)
        });
    }

    /**
     * On select of language for new user
     */
    public onSelectLanguageNewUser(event): void {
        this.newUser.language = event.selected;
    }

    public addNewUser(userForm: FormGroup): void {
        this.usersAdminService.addNewUser(this.newUser).subscribe(_ => {
            this.cancelNewUser(userForm);
            this.loadUsers();
        }, err => {
            console.log(err)
        });
    }

    public cancelNewUser(userForm: FormGroup): void {
        this.newUserVisible = false;
        this.newUser.email = '';
        this.newUser.groups = [];
        this.newUser.notificationRoles = [];
        this.newUser.subcustomer = '';
        this.newUser.availableGroupsForDropdown = this.getGroupsForUser(this.newUser);
        this.newUser.availableNotificationRolesForDropdown = this.getNotificationRolesForUser(this.newUser);
        userForm.markAsPristine();
    }

    // Groups

    public removeFromGroup(user: UserExtended, groupId: string): void {
        const remove = () => {
            user.groups.splice(user.groups.indexOf(groupId), 1);

            user.availableGroupsForDropdown.forEach(group => {
                if (group.id === groupId) {
                    group.checked = false;
                }
            });
        };
        if (user.id) { // if existing
            this.usersAdminService.removeUserFromGroup(user.id, groupId).subscribe(() => {
                this.toastService.addSuccess('USER_PERMISSIONS_REMOVED');
                remove();
            }, err => {
                console.log(err)
            });
        } else { // if new user
            remove();
        }
    }

    private getGroupsForUser(user: User): UserGroupForDropdown[] {
        let groups: UserGroup[];

        if (user.isSubcustomer && this.isCustomerMode) {
            groups = [...this.subcustomerUserGroups];
        } else {
            groups = this.removeSubcustomerUserGroups(this.allAvailableGroups);
        }

        // console.log('groups', groups);
        // Admin can not remove admin group from himself
        return groups
            .filter(group => {
                return user.id !== this.sharedService.user.id ||
                    (user.id === this.sharedService.user.id && group.id !== 'AuthorizedSellerUserAdmin' &&
                    group.id !== 'CustomerUserAdmin' && group.id !== 'SupplierUserAdmin')
            })
            .map(group => ({
                id: group.id,
                name: 'SECURITY_GROUP_' + group.id,
                checked: user.groups.indexOf(group.id) >= 0
            }));
    }

    private removeSubcustomerUserGroups(userGroups: UserGroup[]): UserGroup[] {
        return userGroups.filter(group => {
            const foundGroup = this.subcustomerUserGroups.find(subcustomerGroup =>
                group.id === subcustomerGroup.id);
            return foundGroup === undefined;
        });
    }

    // User group selected
    public onGroupsChanged(selectedGroups: UserGroupForDropdown[], user: UserExtended): void {
        const selectedIds: string[] = selectedGroups.map(item => item.id);
        if (user.id) { // if existing
            // Do not allow user to delete admin group for him/herself
            if (user.id === this.sharedService.user.id && !this.getAdminGroup(selectedIds)) {
                const adminGroup = this.getAdminGroup(user.groups);
                selectedIds.push(adminGroup);

                user.availableGroupsForDropdown.forEach(group => {
                    if (group.id === adminGroup) {
                        group.checked = true;
                    }
                });
            }
            // Do not allow user to delete AuthorizedSellerErp group
            if (user.groups.some(group => group === 'AuthorizedSellerErp') &&
                !selectedGroups.some(group => group.id === 'AuthorizedSellerErp')
            ) {
                selectedIds.push('AuthorizedSellerErp');

                user.availableGroupsForDropdown.forEach(group => {
                    if (group.id === 'AuthorizedSellerErp') {
                        group.checked = true;
                    }
                });
            }

            this.setUserGroups(user, selectedIds);

            // Add 'preorderCreation' notification for 'PreordersCreate' group
            if (selectedGroups.some(item => item.id === 'PreordersCreate')) {
                if (!user.notificationRoles.some(role => role.name === 'preorderCreation')) {
                    const preorderCreationRole = this.allAvailableNotificationRoles.find(role => role.name === 'preorderCreation');

                    if (preorderCreationRole) {
                        this.addToNotificationRole(user, preorderCreationRole);
                    }
                }
            }
        } else { // if new user
            user.groups = selectedIds;

            // Add 'preorderCreation' notification for 'PreordersCreate' group
            if (selectedGroups.some(item => item.id === 'PreordersCreate')) {
                if (!user.notificationRoles.some(role => role.name === 'preorderCreation')) {
                    const preorderCreationRole = this.allAvailableNotificationRoles.find(role => role.name === 'preorderCreation');
                    if (preorderCreationRole) {
                        this.addToNotificationRole(user, preorderCreationRole);
                    }
                }
            }
            this.setAvailableGroupsForDropdown(user);
        }
    }

    private getAdminGroup(groupIds: string[]): string {
        return groupIds.find(groupId => 
            groupId === 'AuthorizedSellerUserAdmin'
            || groupId === 'CustomerUserAdmin'
            || groupId === 'SupplierUserAdmin');
    }

    private getUserNameOrEmail(user: User): string {
        return user.firstName && user.lastName ? user.lastName + ' ' + user.firstName : '(' + user.email + ')';
    }

    private setUserGroups(user: UserExtended, groupIds: string[]): void {
        const set = () => {
            user.groups = [...groupIds];
            this.setAvailableGroupsForDropdown(user);
        }
        if (user.id) {
            const allAvailableGroups = this.getGroupsForUser(user);
            const add: string[] = [];
            const remove: string[] = [];

            allAvailableGroups.forEach(group => {
                const isInPreviousGroups = user.groups.indexOf(group.id) >= 0;
                const isInNewGroups = groupIds.indexOf(group.id) >= 0;

                if (isInPreviousGroups && !isInNewGroups) {
                    remove.push(group.id);
                } else if (!isInPreviousGroups && isInNewGroups) {
                    add.push(group.id);
                }
            });

            this.usersAdminService.changeUserGroups(user.id, add, remove).subscribe(() => {
                this.toastService.addSuccess('USER_PERMISSIONS_CHANGED');
                set();
            },
            err => console.error(err));
        } else {
            set();
        }
    }

    private setAvailableGroupsForDropdown(user: UserExtended): void {
        user.availableGroupsForDropdown.forEach(groupForDropdown => {
            groupForDropdown.checked = user.groups.indexOf(groupForDropdown.id) >= 0;
        });
    }

    public removeAllGroups(user: UserExtended): void {
        const setGroups = (groups) => {
            user.groups = groups;
            this.setAvailableGroupsForDropdown(user);
        }
        this.questionDialogService.confirm(
            {
                message: 'PER_NOTIF_REMOVE_ALL',
                question: this.translate.instant('PER_NOTIF_REMOVE_ALL_GROUPS_QUESTION') + ' ' + this.getUserNameOrEmail(user),
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            result => {
                if (result === QuestionDialogResult.Confirm) {
                    if (user.id) { // if existing
                        let groupsToRemove = [...user.groups];
                        let groupsToKeep = [];
                        // Do not allow user to delete admin group for him/herself
                        if (user.id === this.sharedService.user.id) {
                            const adminGroup = this.getAdminGroup(groupsToRemove);
                            if (adminGroup) {
                                groupsToRemove = groupsToRemove.filter(group => group !== adminGroup);
                                groupsToKeep = [adminGroup];
                            }
                        }
                        // Do not allow user to delete AuthorizedSellerErp group
                        if (groupsToRemove.some(group => group === 'AuthorizedSellerErp')) {
                            groupsToRemove = groupsToRemove.filter(group => group !== 'AuthorizedSellerErp');
                            groupsToKeep.push('AuthorizedSellerErp');
                        }

                        this.usersAdminService.changeUserGroups(user.id, [], groupsToRemove)
                        .subscribe(() => {
                            this.toastService.addSuccess('USER_PERMISSIONS_REMOVED');
                            setGroups(groupsToKeep);
                        }, err => {
                            console.log(err)
                        });
                    } else { // if new user
                        setGroups([]);
                    }
                }
            }
        );
    }

    public addAsUserGroups(user: UserExtended): void {
        this.addAsUserDialogService.confirm({users: this.getUsersToAddAsDialog(user)})
        .subscribe((result: {user: User}) => {
            if (result.user) {
                const newGroups = [...result.user.groups];
                // Do not allow user to delete admin group for him/herself
                if (user.id === this.sharedService.user.id && !this.getAdminGroup(newGroups)) {
                    const adminGroup = this.getAdminGroup(user.groups);
                    newGroups.push(adminGroup);
                }
                // Do not allow user to delete AuthorizedSellerErp group
                if (user.groups.some(group => group === 'AuthorizedSellerErp') &&
                    !newGroups.some(group => group === 'AuthorizedSellerErp')
                ) {
                    newGroups.push('AuthorizedSellerErp');
                }

                this.setUserGroups(user, newGroups);
            }
        });
    }

    private getUsersToAddAsDialog(user: User): User[] {
        return this.users.filter(otherUser =>
            otherUser.enabled &&
            otherUser.id !== user.id &&
            (user.isSubcustomer && this.isCustomerMode
                ? otherUser.isSubcustomer && user.subcustomer === otherUser.subcustomer
                : !otherUser.isSubcustomer
            )
        );
    }

    public onGroupsDropdownClosed(user: UserExtended): void {
        user.availableGroupsForDropdown.forEach(dropdownGroup => {
            dropdownGroup.checked = user.groups.indexOf(dropdownGroup.id) >= 0;
        });
    }

    // Notification roles

    public removeFromNotifRole(user: UserExtended, notifRole: NotificationRole): void {
        // Do not remove 'preorderCreation' notification if user in 'PreordersCreate' group
        if (notifRole.name === 'preorderCreation')  {
            const preordersCreateGroupIndex = user.groups.indexOf('PreordersCreate');
            if (preordersCreateGroupIndex !== -1) {
                this.toastService.addError('SUBCUSTOMER_REMOVE_PREORDER_CREATION_NOTIF_ERROR');
                return; // do not delete
            }

            if (this.isCustomerMode) {
                // Do not remove 'preorderCreation' notification in the specified case
                const { atLeastOneSubcustomerExists, customerUsersHavePreorderNotif } =
                    this.getSubcustomerValidation(user.id);
    
                if (!customerUsersHavePreorderNotif && atLeastOneSubcustomerExists) {
                    this.toastService.addError('SUBCUSTOMER_REMOVE_NOTIF_ERROR', 10000);
                    return;
                }
            }
        }

        const removeFromUser = function () {
            for (let i = 0; i < user.notificationRoles.length; ++i) {
                if (user.notificationRoles[i].id === notifRole.id) {
                    user.notificationRoles.splice(i, 1);
                    break;
                }
            }

            user.availableNotificationRolesForDropdown.forEach(role => {
                if (role.id === notifRole.id) {
                    role.checked = false;
                }
            });
        }
        if (user.id) { // if existing
            this.usersAdminService.removeUserFromNotificationRole(user.id, notifRole.id)
            .subscribe(() => {
                this.toastService.addSuccess('USER_NOTIFICATIONS_REMOVED');
                removeFromUser();
            }, err => {
                console.log(err)
            });
        } else { // if new user
            removeFromUser();
        }
    }

    private addToNotificationRole(user: UserExtended, notifRole: NotificationRole): void {
        const add = () => {
            user.notificationRoles.push(notifRole);

            user.availableNotificationRolesForDropdown.forEach(role => {
                if (role.id === notifRole.id) {
                    role.checked = true;
                }
            });
        };
        if (user.id) { // if existing
            this.usersAdminService.addUserToNotificationRole(user.id, notifRole.id)
            .subscribe(() => {
                this.toastService.addSuccess('USER_NOTIFICATIONS_CHANGED');
                add();
            }, err => {
                console.log(err)
            });
        } else { // if new user
            add();
        }
    }

    private getNotificationRolesForUser(user: User): NotificationRoleForDropdown[] {
        let roles: NotificationRole[];

        if (user.isSubcustomer && this.isCustomerMode) {
            roles = this.allAvailableNotificationRoles.filter(role =>
                this.subcustomerNotificationRoleNames.indexOf(role.name) !== -1);
        } else {
            roles = [...this.allAvailableNotificationRoles];
        }

        // Admin can not remove some roles from himself
        return roles
            .filter(role => {
                return user.id !== this.sharedService.user.id || 
                    (user.id === this.sharedService.user.id && role.name !== 'productsNotTakenCustomer');
            })
            .map(role => ({
                 ...role,
                checked: user.notificationRoles.some(item => item.id === role.id)
            }));
    }

    public onNotificationRolesChanged(selected: NotificationRoleForDropdown[], user: UserExtended): void {
        const selectedRoles: NotificationRole[] = selected.map(role => ({id: role.id, name: role.name}));
        // Do some validation
        if (!this.doNotRemovePreorderCreationNotif(selectedRoles, user)) {
            console.log(user);
            return;
        }

        // Do not remove admin notification roles for him/herself
        if (user.id === this.sharedService.user.id && !this.getAdminNotificationRole(selectedRoles)) {
            const adminRole = this.getAdminNotificationRole(user.notificationRoles);
            
            if (adminRole) {
                selectedRoles.push(adminRole);

                user.availableNotificationRolesForDropdown.forEach(role => {
                    if (role.id === adminRole.id) {
                        role.checked = true;
                    }
                });
            }
        }

        this.setUserNotificationRoles(user, selectedRoles);
    }

    private setAvailableNotificationRolesForDropdown(user: UserExtended): void {
        user.availableNotificationRolesForDropdown.forEach(dropdownRole => {
            dropdownRole.checked = user.notificationRoles.some(role => role.id === dropdownRole.id);
        });
    }

    private doNotRemovePreorderCreationNotif(rolesToSet: NotificationRole[], user: UserExtended): boolean {
        const aboutToRemovePreorderCreation = !rolesToSet.some(role => role.name === 'preorderCreation') &&
            this.isCustomerMode;

        if (aboutToRemovePreorderCreation) {
            // Do not remove 'preorderCreation' notification if user in 'PreordersCreate' group
            const hasPreorderCreateGroup = user.groups.indexOf('PreordersCreate') >= 0;
            if (hasPreorderCreateGroup) {
                this.toastService.addError('SUBCUSTOMER_REMOVE_PREORDER_CREATION_NOTIF_ERROR');
                rolesToSet.push(user.notificationRoles.find(role => role.name === 'preorderCreation'));
                user.availableNotificationRolesForDropdown.forEach(role => {
                    if (role.name === 'preorderCreation') {
                        role.checked = true;
                    }
                });
            }
            // Do not remove 'preorderCreation' notification in the specified case
            const { atLeastOneSubcustomerExists, customerUsersHavePreorderNotif } =
                this.getSubcustomerValidation(user.id);

            if (!customerUsersHavePreorderNotif && atLeastOneSubcustomerExists) {
                this.toastService.addError('SUBCUSTOMER_REMOVE_NOTIF_ERROR', 10000);
                return false;
            }
        }
        return true;
    }

    public removeAllNotificationRoles(user: UserExtended): void {
        const setRoles = (roles: NotificationRole[]) => {
            user.notificationRoles = roles;
            this.setAvailableNotificationRolesForDropdown(user);
        }
        this.questionDialogService.confirm(
            {
                message: 'PER_NOTIF_REMOVE_ALL',
                question: this.translate.instant('PER_NOTIF_REMOVE_ALL_NOTIFICATION_RULES_QUESTION') + ' ' + this.getUserNameOrEmail(user),
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            result => {
                if (result === QuestionDialogResult.Confirm) {
                    const userRoles: NotificationRole[] = [...user.notificationRoles];
                    let rolesToRemove: NotificationRole[] = [...userRoles];
                    let rolesToKeep: NotificationRole[] = [];

                    const aboutToRemovePreorderCreation = userRoles.some(role => role.name === 'preorderCreation');
                    if (aboutToRemovePreorderCreation) {
                        // Do not remove 'preorderCreation' notification if user in 'PreordersCreate' group
                        const hasPreorderCreateGroup = user.groups.indexOf('PreordersCreate') >= 0;
                        if (hasPreorderCreateGroup) {
                            this.toastService.addError('SUBCUSTOMER_REMOVE_PREORDER_CREATION_NOTIF_ERROR');
                            rolesToRemove = userRoles.filter(role => role.name !== 'preorderCreation');
                            rolesToKeep = userRoles.filter(role => role.name === 'preorderCreation');
                        }

                        // Do not remove 'preorderCreation' notification in the specified case
                        const { atLeastOneSubcustomerExists, customerUsersHavePreorderNotif } =
                            this.getSubcustomerValidation(user.id);

                        if (!customerUsersHavePreorderNotif && atLeastOneSubcustomerExists) {
                            this.toastService.addError('SUBCUSTOMER_REMOVE_NOTIF_ERROR', 10000);
                            return;
                        }
                    }
                    // Do not remove admin notification roles for him/herself
                    if (user.id === this.sharedService.user.id) {
                        const adminRole = this.getAdminNotificationRole(rolesToRemove);
                        if (adminRole) {
                            rolesToRemove = rolesToRemove.filter(role => role !== adminRole);
                            rolesToKeep = [...rolesToKeep, adminRole];
                        }
                    }

                    if (user.id) { // if existing
                        this.usersAdminService.changeUserNotificationRoles(user.id, [],
                            rolesToRemove.map(role => role.id))
                        .subscribe(() => {
                            this.toastService.addSuccess('USER_NOTIFICATIONS_REMOVED');
                            setRoles(rolesToKeep);
                        }, err => {
                            console.log(err)
                        });
                    } else { // if new user
                        setRoles(rolesToKeep);
                    }
                }
            }
        );
    }

    private setUserNotificationRoles(user: UserExtended, roles: NotificationRole[]): void {
        const set = () => {
            user.notificationRoles = [...roles];
            this.setAvailableNotificationRolesForDropdown(user);
        }
        if (user.id) {
            const allAvailableNotificationRoles = this.getNotificationRolesForUser(user);
            const add: number[] = [];
            const remove: number[] = [];

            allAvailableNotificationRoles.forEach(role => {
                const isInPreviousRoles = user.notificationRoles.find(userRole => userRole.id === role.id) !== undefined;
                const isInNewRoles = roles.find(newRole => newRole.id === role.id) !== undefined;

                if (isInPreviousRoles && !isInNewRoles) {
                    remove.push(role.id);
                } else if (!isInPreviousRoles && isInNewRoles) {
                    add.push(role.id);
                }
            });

            this.usersAdminService.changeUserNotificationRoles(user.id, add, remove)
            .subscribe(() => {
                this.toastService.addSuccess('USER_NOTIFICATIONS_CHANGED');
                set();
            });
        } else {
            set();
        }
    }

    public addAsUserNotificationRoles(user: UserExtended): void {
        this.addAsUserDialogService.confirm({users: this.getUsersToAddAsDialog(user)})
        .subscribe(result => {
            if (result.user) {
                const notificationRoles = [...result.user.notificationRoles];
                // Do some validation
                if (!this.doNotRemovePreorderCreationNotif(notificationRoles, user)) {
                    return;
                }
                // Do not remove admin notification roles for him/herself
                if (user.id === this.sharedService.user.id && !this.getAdminNotificationRole(notificationRoles)) {
                    const adminRole = this.getAdminNotificationRole(user.notificationRoles);
                    if (adminRole) {
                        notificationRoles.push(adminRole);
                    }
                }
                this.setUserNotificationRoles(user, notificationRoles);
            }
        });
    }

    private getAdminNotificationRole(roles: NotificationRole[]): NotificationRole {
        return roles.find(role => 
            role.name === 'productsNotTakenCustomer'
        );
    }

    // public onNotificationRolesDropdownClosed(user: UserExtended): void {
    //     user.availableNotificationRolesForDropdown.forEach(dropdownRole => {
    //         dropdownRole.checked = user.notificationRoles.some(role => role.id === dropdownRole.id);
    //     });
    // }

    // Enable/Disable

    public enableUser(user: User): void {
        const { atLeastOneSubcustomerExists, customerUsersHavePreorderNotif } =
            this.getSubcustomerValidation(user.id);

        if (user.isSubcustomer && !customerUsersHavePreorderNotif) {
            this.toastService.addError('SUBCUSTOMER_ENABLE_ERROR', 10000);
            return;
        }

        this.busy = true;
        this.usersAdminService.enableUser(user.id).subscribe(data => {
            user.enabled = true;
            this.busy = false;
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    public disableUser(user: User): void {
        const { atLeastOneSubcustomerExists, customerUsersHavePreorderNotif } =
            this.getSubcustomerValidation(user.id);

        if (user.notificationRoles.some(role => role.name === 'preorderCreation') &&
            !customerUsersHavePreorderNotif && atLeastOneSubcustomerExists
        ) {
            this.toastService.addError('SUBCUSTOMER_DISABLE_ERROR', 10000);
            return;
        }

        this.busy = true;
        this.usersAdminService.disableUser(user.id).subscribe(data => {
            user.enabled = false;
            this.busy = false;
            if (!this.viewDisabled) {
                this.users = this.users.filter(item => item.id !== user.id);
            }
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    // Resend invitation

    public resendInvite(user: User): void {
        this.usersAdminService.resendInvite(user.id).subscribe(() => {
        }, err => {
            console.log(err)
        });
    }

    /**
     * Toggles show disabled filter
     */
    public onDisabledToggle(): void {
        this.viewDisabled = !this.viewDisabled;
        this.loadUsers();
    }

    public ngOnDestroy(): void {
        this.theSameClickedSubscription.unsubscribe();
    }

    // Subcustomer on new user
    public onNewUserSubcustomerStateAboutToChange(isSubcustomer: boolean): void {
        // console.log('onNewUserSubcustomerStateAboutToChange', isSubcustomer);
        if (isSubcustomer && !this.getSubcustomerValidation(this.newUser.id).customerUsersHavePreorderNotif) {
            this.toastService.addError('SUBCUSTOMER_CHANGE_ERROR', 10000);
            return;
        }

        this.newUser.isSubcustomer = isSubcustomer;
        if (!this.newUser.isSubcustomer) {
            this.newUser.subcustomer = '';
        }
        this.newUser.groups = [];
        this.newUser.notificationRoles = [];
        this.newUser.availableGroupsForDropdown = this.getGroupsForUser(this.newUser);
        this.newUser.availableNotificationRolesForDropdown = this.getNotificationRolesForUser(this.newUser);
    }

    public onNewUserSubcustomerChanged(subcustomer: string): void {
        this.newUser.subcustomer = subcustomer;
    }

    // Subcustomer on existing users
    public onUserSubcustomerStateAboutToChange(user: UserExtended, isSubcustomer: boolean): void {
        // console.log('onUserSubcustomerStateChanged', user, isSubcustomer);

        if (isSubcustomer && !this.getSubcustomerValidation(user.id).customerUsersHavePreorderNotif) {
            this.toastService.addError('SUBCUSTOMER_CHANGE_ERROR', 10000);
            return;
        }

        this.askIfDeletePermissions(user).subscribe(answer => {
            if (answer === QuestionDialogResult.Confirm) {
                user.isSubcustomer = isSubcustomer;
                if (!user.isSubcustomer) {
                    user.subcustomer = '';
                }
                user.isEditing = user.isSubcustomer;
                user.groups = [];
                user.notificationRoles = [];
                user.availableGroupsForDropdown = this.getGroupsForUser(user);
                user.availableNotificationRolesForDropdown = this.getNotificationRolesForUser(user);

                this.usersAdminService.updateUser(user.id, user.subcustomer, [], [])
                .subscribe(() => {
                    // console.log('updated');
                },
                err => {
                    console.log(err);
                });
            }
            // console.log('onUserSubcustomerStateAboutToChange setting to', user.isSubcustomer);
        });
    }

    private askIfDeletePermissions(user: User): Observable<QuestionDialogResult> {
        if (user.groups.length === 0 && user.notificationRoles.length === 0) {
            return of(QuestionDialogResult.Confirm);
        } else {
            return this.questionDialogService.confirm({
                message: 'DELETE_PERMISSIONS_AND_NOTIFICATIONS_TITLE',
                question: 'DELETE_PERMISSIONS_AND_NOTIFICATIONS_QUESTION',
                primary: 'YES',
                secondary: 'NO'
            },
                { keyboard: false, ignoreBackdropClick: true }
            );
        }
    }

    public onUserSubcustomerSaveButtonClicked(user: User, subcustomer: string): void {
        // console.log('onUserSubcustomerSaveButtonClicked', user, subcustomer);

        this.usersAdminService.updateUser(user.id, subcustomer).subscribe(() => {
            user.subcustomer = subcustomer;
        },
        err => {
            console.log(err);
        });
    }

    public onUserSubcustomerCancelButtonClicked(user: User): void {
        // console.log('onUserSubcustomerCancelButtonClicked', user);
        // this.onUserSubcustomerStateAboutToChange(user, false);
    }

    private getSubcustomerValidation(ignoreUserId: number): {atLeastOneSubcustomerExists: boolean, customerUsersHavePreorderNotif: boolean} {
        return this.users.reduce((acc, user) => {
                if (user.id === ignoreUserId || !user.enabled) {
                    return acc;
                }
                const userIsSubcustomer = user.subcustomer !== '' && user.subcustomer !== undefined;
                const userHasPreorderNotif = user.notificationRoles.some(role =>
                    !userIsSubcustomer && role.name === 'preorderCreation');
                return {
                    atLeastOneSubcustomerExists: acc.atLeastOneSubcustomerExists || userIsSubcustomer,
                    customerUsersHavePreorderNotif: acc.customerUsersHavePreorderNotif || userHasPreorderNotif
                };
            },
            { atLeastOneSubcustomerExists: false, customerUsersHavePreorderNotif: false }
        );
    }

    public onUserSubcustomerIsEditingChanged(user: User, isEditing: boolean): void {
        user.isEditing = isEditing;
    }

    // Changing user role AS -> customer X or the other way round
    public onRoleChange(): void {
        this.loadAvailableUserOptions();
    }
}
