import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service'
import { LocalNumberPipe } from '../../../../locale.pipes.module';

@Component({
    selector: 'row-weight-cell',
    template: `<div style="padding: 0 3px; text-align: right;">{{getTotalWeight() | localnumber:sharedService.appSettings.language:true:3}}</div>`
})
export class GridRowWeightRendererComponent implements AgRendererComponent {
    params:any;

    constructor(public sharedService: SharedService) {

    }

    agInit(params:any):void {
        this.params = params;
    }

    /**
     * Returns price counted from amount to be ordered and price for unit in row 
     */
    getTotalWeight() {
		return (this.params.data[this.params.amountColId] != null && this.params.data[this.params.amountColId].toString().length != 0) && (this.params.data.weight != null) ? 
			(Math.round((this.params.data[this.params.amountColId] * this.params.data.weight) * 10000) / 10000) : '0.0';
	}

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}