import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Subject } from 'rxjs';
import { Animations} from '../../animations';
import { TableColumn } from '@app/model/table.model';

@Component({
    selector: 'journal-detail',
    templateUrl: 'journal.detail.component.html',
    styleUrls:  ['./journal.detail.component.scss'],
    animations: [Animations.slideInOut]
})
export class JournalDetailComponent  {
    @Input() entityId?: number;          // optional entity ID (if entity already exists)
    @Input() dataGetter: Function;       // function to provide JournalDetail[]
    @Input() optionsGetter: Function;
    @Input() filterItemsGetter: Function;
    @Input() columnsGetter: (forceOriginal?: boolean) => TableColumn[];
    @Input() columnsDefsGetter: Function;
    @Input() reload: Subject<any>;       // optional reload subject - reloads data when next() is called on this from outside
    @Input() makeCollapsible: boolean;    // Set Header and Body for collapsible component (after click on header then body will collapse
    @Input() isCollapsed;       // set collapsed state for this element
    @Output() isCollapsedChange = new EventEmitter();       // set collapsed state for this element

    reloadSubscribtion;
    busy = false;
    totalRows: number;
    personsCreated: any[];
    filterItems: any[];
    subjectRefresh = new Subject();

    // simple lazy hidding
    // if closed after click on header load data and wait 400ms after that open accordion.
    // if open and after click on header wait for animation 300ms and after that hide element
    isCollapsedLazy = false;


    constructor(
        public sharedService: SharedService,
    ) {
        this.loadJournalItems = this.loadJournalItems.bind(this);
    }
    ngOnInit() {
        this.makeCollapsible = this.makeCollapsible ? this.makeCollapsible : false;
        this.isCollapsed = this.isCollapsed ? this.isCollapsed : false;
    }
    ngAfterViewInit() {
        if (this.reload && !this.isCollapsed) {
            this.reloadSubscribtion = this.reload.subscribe(() => {
                this.isCollapsedLazy = !this.isCollapsed;
                this.subjectRefresh.next();
            })
            // setTimeout(() => {
            //     this.isCollapsedLazy = !this.isCollapsed;
            // }, 500);
        }
    }
    toggleCollapse() {
        const oldIsColapsedVal = this.isCollapsed;
        this.isCollapsed  = (this.makeCollapsible) ? !this.isCollapsed : null;
        this.isCollapsedChange.emit(this.isCollapsed);

        if (this.isCollapsed !== oldIsColapsedVal) {
            setTimeout(() => {
                this.isCollapsedLazy = !this.isCollapsed;
            }, 1000);
        }
      }
    /** load journal items */
    loadJournalItems(params) {
        this.busy = true;
        let query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            // orderBy: this.route.snapshot.queryParams['orderBy']
        };

        var columns = this.columnsGetter();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].orderBy) {
                query.orderBy = columns[i].id + ' ' + columns[i].orderDirection;
            }
        }
        if (params.filter) { // because of suppressToUrl filters are here
            query = Object.assign(query, params.filter);
        }

        this.dataGetter(this.entityId, query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                this.personsCreated = data.personsCreated;
                // this.assignedSellerNames = data.assignedSellerNames;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.filterItemsGetter(this.personsCreated);
                }
                params.successCallback(data.rows, data.total);
                this.busy = false;
            }, err => {
                console.log(err);
                // also in error to be able to cancel filter
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.filterItemsGetter();
                }
                this.busy = false;
            });
    }

    ngOnDestroy() {
        if (this.reloadSubscribtion) {
            this.reloadSubscribtion.unsubscribe();
        }
    }
}
