import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { InvoicesItemsService } from '../../../services/invoice/invoices.items.service';
import { InvoicesItemsGridService } from '../../../services/invoice/invoices.items.grid.service';
import { SharedService } from '../../../services/shared.service';
import { Subject } from 'rxjs';
import { ExportObj } from '@app/model/table.model';

@Component({
  selector: 'invoices-items',
  templateUrl: 'invoices.items.component.html'
})
export class InvoicesItemsComponent  { 

	totalRows:          number;
	filterItems: 		any[];
	subjectRefresh = new Subject<any>();
	public exportObj: ExportObj;

	busy:				boolean;

	constructor(private router: Router, 
				private route: ActivatedRoute,
				private invoicesItemsService: InvoicesItemsService,
				private invoicesItemsGridService: InvoicesItemsGridService,
				public sharedService: SharedService) {
		this.subjectRefresh = new Subject<any>();

		this.getInvoicesItems = this.getInvoicesItems.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);

		this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
			this.exportObj = {
				fileName: this.sharedService.translateService.instant('INVOICES_ITEMS'), 
				export: this.invoicesItemsService.exportAll.bind(this.invoicesItemsService)
			};
		});
	}

	ngOnInit() {
		var this_ = this;
		setTimeout(function() {
			// navigate with orderby parameter
			this_.sharedService.navigateWithOrderby(this_.getColumns(), 
				this_.route.snapshot.queryParams, this_.sharedService.lastInvoiceItemsQueryParams);
		}, 0);
	}

	getInvoicesItems(params) {
		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};
		
		this.sharedService.lastInvoiceItemsQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastInvoiceItemsQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

        if (query.skip >= 0 && query.top > 0) {
			this.invoicesItemsService.getInvoicesItems(query)
			.subscribe(data => {
				this.totalRows = data.totalCount;
				if (!this.filterItems) { // only if not already to not loose fererence of selected
					this.filterItems = this.getFilterItems();
				}
				params.successCallback(data.rows, data.totalCount);
				this.busy = false;
	        }, err => {
	        	console.log(err);
	        	// also in error to be able to cancel filter
	        	if (!this.filterItems) { // only if not already to not loose fererence of selected
					this.filterItems = this.getFilterItems();
				}
	        	this.busy = false;
	        });
		}
	}
	getGridOptions() {
		return this.invoicesItemsGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.invoicesItemsGridService.getFilterItems();
	}
	getColumns() {
		return this.invoicesItemsGridService.getColumns();
	}
	getColumnsDefs() {
		return this.invoicesItemsGridService.getColumnDefs();
	}
}