<form>
    <div class="row-container wider" *ngIf="params">    
        <div *ngIf="params.mode == 'details'" style="display: block; overflow-y: auto; min-width: 400px;"  [ngStyle]="{'max-height.px': maxHeight}">
            <div *ngIf="data.showOrderComment" class="input-container" style="display: block;">
                <label translate>ORDER_COMMENT</label>
                <input type="text" name="orderComment" [myFocus]="true" [(ngModel)]="data.orderComment" maxlength="100" class="form-control">
            </div>
            <div class="input-container" style="display: block;">
                <label translate>ORDER_INTERNAL_NUMBER</label>
                <input type="text" name="internal_number" [(ngModel)]="data.orderNumberCustomer" maxlength="20" class="form-control" style="max-width: 150px;"
                    [required]="orderNumberCustomerRequired && !params.preorder">
                <div *ngIf="orderNumberCustomerRequired && !data.orderNumberCustomer && !params.preorder" class="alert alert-danger">
                    <span translate>REQUIRED_FIELD</span>
                </div>
            </div>
            <div class="input-container" style="display: block;">
                <label translate>{{ (params.preorder ? 'PRE' : '') + 'ORDER_NOTE' }}</label>
                <input type="text" name="orderNote" [(ngModel)]="data.orderNote" maxlength="100" class="form-control">
            </div>
            <div *ngIf="params?.data.hasIdustrials" style="margin-top: 15px">
                <div><strong class="fnt-orange" translate>WARNING</strong></div>
                <div class="fnt-orange" style="margin-top: 15px" translate>WARNING_INDUSTRIAL_MAY_NOT_BE_AVAILABLE</div>
            </div>
            <!-- BEGIN OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS -->
            <div *ngIf="showTemporaryMessageForSelectedCustomers" class="input-container" style="display: block;">
                <div class="fnt-orange" style="margin-top: 15px">Уважаемый партнер,</div>
                <div class="fnt-orange" style="margin-top: 15px">Связи с нестабильной военно-политической ситуацией на территории Украины и связанных с этим рисками исполнения условий  торговых отношений, мы вынуждены временно приостановить все плановые отгрузки клиентам.</div>
                <div class="fnt-orange" style="margin-top: 15px">О возобновлении отгрузок будет сообщено дополнительно.</div>
            </div>
            <!-- END OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS -->
        </div>

        <div *ngIf="params.mode == 'errors'">
            <h4 class="fnt-orange"><span translate>{{ (params.preorder ? 'PREORDER_' : '') + 'BASKET_INVALID_DATE_ITEMS_ERROR_1' }}</span> ({{params.data.errorCount}}). <span translate>BASKET_INVALID_DATE_ITEMS_ERROR_2</span></h4>
        </div>

        <div style="padding-top: 15px; margin-bottom: 15px">
            <button class="btn-secondary" (click)="close()" style="width: 100px;">
                <span translate>CLOSE</span>
            </button>
            <button *ngIf="params.mode !== 'errors'" class="btn-primary" style="float: right" (click)="order(data)" 
                [disabled]="disableSubmit || params.mode == 'wrong_dates' || (orderNumberCustomerRequired && !data.orderNumberCustomer && !params.preorder)"
                [ngClass]="{'ec-disabled': disableSubmit || params.mode == 'wrong_dates' || (orderNumberCustomerRequired && !data.orderNumberCustomer && !params.preorder)}"
            >
                <i class="fa fa-check-square-o"></i>
                <span translate>{{ params.preorder ? 'MAKE_PREORDER' : 'MAKE_ORDER' }}</span>
            </button>
            <button *ngIf="params.mode === 'errors'" class="btn-primary" style="float: right" (click)="showInvalidItems()">
                <span translate>SHOW_INVALID_ITEMS</span>
            </button>
        </div>
    </div>
</form>