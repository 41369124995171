import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, HostListener, ViewChild, ElementRef  } from '@angular/core';
import { Subject } from 'rxjs';
import { FormService } from '../../services/form.service';

@Component({
    selector: 'discount-dialog',
    template: `
        <div class="row-container wider" *ngIf="params">
            <h4><span translate>{{params.message}}</span></h4>
            <div style="margin-bottom:10px"><span translate>{{params.question}}</span>{{params.suppressQuestionMark ? '' : '?'}}</div>
            <div><span style="display:inline-block">{{params.inputLabel}}: </span>

            <number-input name="number-input" #box
                [ngModel]="textValue"
                (ngModelChange)="textValue=$event"
                [maxWidth]="80"
                [maxLength]="5"
                [isValid]="isValid"
                [focusOnInit]="true"
                [inputType]="'decimal'"
                style="display:inline-block; max-width: 100px; margin-left: 10px; text-align: center;">
                </number-input>

            </div>
            <div fxLayout="row" class="row-container bigger">
                <div fxFlex="50">
                    <button class="btn-secondary" (click)="dismiss()" style="width: 100px;" *ngIf="params.secondary">
                          <span class translate>{{params.secondary}}</span>
                    </button>
                </div>
                <div fxFlex="50" fxLayoutAlign="end">
                    <button class="btn-primary" (click)="confirm()" style="min-width: 100px;" [disabled]="!boxValid" [ngClass]="{'ec-disabled': !boxValid}">
                          <span class translate>{{params.primary}}</span>
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class DiscountDialog {
    @ViewChild('box') box: ElementRef;
    doneSubject: Subject<any>;
    params: any;
    disableSubmit = true;
    textValue: any;
    boxValid = false;


    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            // this.doneSubject.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public formService: FormService
        ) {
            this.isValid = this.isValid.bind(this);
    }

    confirm() {
        const this_ = this;
        this.disableSubmit = true;
        this.doneSubject.next({res: 'confirm', textValue: this_.textValue });
        this.bsModalRef.hide();
      }

    dismiss() {
        this.doneSubject.next('dismiss');
        this.bsModalRef.hide();
    }

    isValid(val) {
        this.boxValid = parseFloat(val) >= 0 && parseFloat(val) < 100;
        return this.boxValid;

    }
}
