<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container clickable" style="margin-right: 5px;"
                routerLink="/{{sharedService.appSettings.language}}/prfqs">
                <span translate>RFQS_SUMMARY</span>
            </span>
            <span class="row-container tab-active">
                <span translate>RFQS_ITEMS</span>
            </span>
        </div>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getPurchaseRfqsItems"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>