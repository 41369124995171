import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service'
import { LocalCurrencyPipe } from '../../../../locale.pipes.module';

@Component({
    selector: 'row-price-cell',
    template: `<div style="padding: 0 3px; text-align: right;">{{getTotalPrice() | localcurrency:sharedService.appSettings.language:currency}}</div>`
})
export class GridRowPriceRendererComponent implements AgRendererComponent {
    params  : any;
    currency: string;

    constructor(public sharedService: SharedService) {

    }

    agInit(params:any):void {
        this.params   = params;
        this.currency = this.params.currency || params.data.currency;  // forced currency vs the one in the data
    }

    /**
     * Returns price counted from amount to be ordered and price for unit in row 
     */
    getTotalPrice() {
		return (this.params.data[this.params.amountColId] != null && this.params.data[this.params.amountColId].toString().length != 0) && (this.params.data.unitPrice != null) ? 
			(Math.round((this.params.data[this.params.amountColId] * this.params.data.unitPrice) * 100) / 100) : '0.0';
	}

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}