import { Injectable } from '@angular/core';
import { ReplaySubject ,  Subject, Observable, BehaviorSubject } from 'rxjs';
import { EcmHttpService } from './http/ecm.http.service';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { CommentItem } from '@app/model/discussion.model';

@Injectable()
export class DiscussionService {
    private discussionRefreshObservable = new ReplaySubject(1);
    private discussionCommentAddedToItemObservable = new ReplaySubject(1);
    private _postsCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    public readonly postsCount$: Observable<number> = this._postsCount$.asObservable();

    constructor (
        private http: EcmHttpService, 
        private sharedService: SharedService
    ) {}

    public publishPostsCount(count: number): void {
        this._postsCount$.next(count);
    }

    getCommentObservable() {
        return this.discussionRefreshObservable;
    }

    getCommentAddedToItemObservable() {
        return this.discussionCommentAddedToItemObservable;
    }

    // clearCommentAddedToItemObservable() {
    //     this.discussionCommentAddedToItemObservable = new ReplaySubject(1);
    // }

    /**
     * @param {string} itemArea area of item e.g. orders
     * @param {string} itemId   id of item
     */
    getDiscussion(itemArea: string, itemId: string): Observable<{rows: CommentItem[]}> {
        this.sharedService.currentDiscussionComments = [];
        return this.http.get(`/` + itemArea + `/` + itemId + `/discussion`)
        .pipe(map((data) => {
            this.sharedService.currentDiscussionComments = data.rows;
            return data;
        }));
    }

    /**
     * @param {string} itemArea area of item e.g. order
     * @param {string} itemId   id of item
     * @param {string} text     text of comment
     */
    public sendComment(itemArea: string, itemId: string, text: string, childTableKey?: string, privateItem?: boolean): Observable<void> {
        return this.http.post(`/` + itemArea + `/` + itemId + `/discussion`, JSON.stringify({
            discussionText: text, 
            childTableKey: childTableKey ? childTableKey.toString() : '',
            privateItem: privateItem || false
        }));
    }
}
