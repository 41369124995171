import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from '../table.service';
import { SharedService } from '../shared.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridMailtoRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridMailtoRendererComponent/grid.mailto.renderer.component';

@Injectable()
export class CustomerStoresAvailabilityGridService {

    constructor (
                 private translateService: TranslateService,
                 private tableService: TableService,
                 private sharedService: SharedService) {
    }

    getColumns(forceOriginal?: boolean): any[] {
        const original = [
            {id: 'eCommProductId', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 120},
            {id: 'customerName', name: 'CUSTOMER', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'contactName', name: 'CUSTOMER_CONTACT_NAME', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'contactPhone', name: 'CUSTOMER_CONTACT_PHONE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'contactEmail', name: 'CUSTOMER_CONTACT_EMAIL', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amount', name: 'PIECES', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
        ];
        const restored = this.sharedService.user.preferences['current-orderCustomerStoresAvailabilityTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns(false);
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions() {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(null, '', headerClassFunc);
        gridOptions.defaultColDef.headerComponentParams.suppressToUrl = true;
        gridOptions.defaultColDef.headerComponentParams.columnsObjects = this.getColumns();
        gridOptions.defaultColDef.headerComponentParams.sharedService = this.sharedService;
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        const colDefs: any = [
            {
                headerName: this.translateService.instant(this.getColumn('eCommProductId').name),
                field: 'eCommProductId',
                // width: this.getColumn('eCommProductId').width,
                cellStyle: {'text-align': 'left'},
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerName').name),
                field: 'customerName',
                // width: this.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => params.data.customer.customerName
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactName').name),
                field: 'contactName',
                // width: this.getColumn('contactName').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => params.data.customer.contactName
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactPhone').name),
                field: 'contactPhone',
                // width: this.getColumn('contactPhone').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => params.data.customer.contactPhone
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactEmail').name),
                field: 'contactEmail',
                // width: this.getColumn('contactEmail').width,
                cellRendererFramework: GridMailtoRendererComponent,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => params.data.customer.contactEmail
            },
            {
                headerName: this.translateService.instant(this.getColumn('amount').name),
                field: 'amount',
                // width: this.getColumn('amount').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: false},
                cellStyle: {'text-align': 'center'},
            }
        ];
        return colDefs;
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        return []
    }

}
