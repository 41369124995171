import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrfqService } from '../prfq.service';
import { PrfqsGridService } from './prfqs.grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataGetterParams, TableColumn, ExportObj } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { SharedService } from '@services/shared.service';
import { Subject } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { BaseComponent } from '@app/components/base/base.component';
import { PrfqPermissionService } from '../prfq.permission.service';

@Component({
    selector: 'app-prfqs',
    templateUrl: './prfqs.component.html',
    styleUrls: ['./prfqs.component.scss']
})
export class PrfqsComponent extends BaseComponent implements OnInit, OnDestroy {

    public totalRows: number;
    public filterItems: TableFilterItem[];
    public exportObj: ExportObj;
    public initialized = false;
    public refreshGrid$: Subject<void>;
    public canCreatePrfq = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService,
        private prfqService: PrfqService,
        public gridService: PrfqsGridService,
        private prfqPermissionService: PrfqPermissionService
    ) {
        super(router, route, sharedService);

        this.getPurchaseRfqs = this.getPurchaseRfqs.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        
        this.gridService.initSelection();

        this.canCreatePrfq = this.prfqPermissionService.isElementAvailable('createPrfq');

        // To reload when menu item is clicked but it is already routed to this area
        this.refreshGrid$ = new Subject<void>();
        this.subscriptions.push(this.sharedService.subjectSameAreaClicked.subscribe(() => {
            this.refreshGrid$.next();
        }));

        this.sharedService.translateService.get('RFQS_SUMMARY').subscribe(translation => {
            this.exportObj = {
                fileName: translation,
                export: this.prfqService.exportPrfqs.bind(this.prfqService)
            };
        })

        this.initialized = true;
    }

    public ngOnInit() {
        setTimeout(() => {
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastPrfqsQueryParams);
        });
    }

    public createPrfq(): void {
        this.prfqService.createPrfq().subscribe(prfq => {
            this.router.navigate([`/${this.sharedService.appSettings.language}/prfq/${prfq.id}`]);
        }, err => {
            console.log(err);
        })
    }

    public getPurchaseRfqs(params: TableDataGetterParams): void {
        const query: EcmHttpQueryParams = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastPrfqsQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastPrfqsQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.prfqService.getPrfqs(query).subscribe(response => {
            this.totalRows = response.totalCount;
            if (!this.filterItems) { // only if not already have it; not to loose reference of selected
                this.filterItems = this.gridService.getFilterItems(response.personsCreated);
            }
            params.successCallback(response.rows, response.totalCount);
        }, err => {
            console.log(err);
            // Also in error to be able to cancel filter
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
        });
    }

    public getGridOptions(): GridOptions {
        return this.gridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.gridService.getColumns();
    }

    public getColumnsDefs(): ColDef[] {
        return this.gridService.getColumnDefs();
    }

}
