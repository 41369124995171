import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { PrfqItem, PrfqActions, Prfq, PrfqsResponse, PrfqItemForApi, CreatePrfqItemResponse, PrfqJournalResponse, PrfqItemsResponse, PrfqItemStates, PrfqStates, UpdatePrfqItemsResponse } from '@app/model/prfq.model';
import { EcmHttpService, EcmHttpQueryParams } from '@services/http/ecm.http.service';
import { Observable, Subject, of, throwError } from 'rxjs';
import { Attachment, AttachmentUploadObj, AttachmentDownloadObj } from '@app/model/attachment.model';
import { map, switchMap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { FormService } from '@app/services/form.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { AttachmentPrivateConfig } from '../attachmentComponent/attachment.component';
import { StateColorsObject } from '@app/model/table.model';
import { DiscussionPrivateConfig } from '@app/model/discussion.model';

@Injectable()
export class PrfqService {
    private reloadCurrent$: Subject<void> = new Subject<void>();

    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private formService: FormService,
        private toastService: ToastService
    ) {
        this.isColValid = this.isColValid.bind(this);
    }

    public getReloadCurrentSubject(): Subject<void> {
        return this.reloadCurrent$;
    }

    /**
     * Validates the whole record (item row)
     */
    // public isRowValid(row: PrfqItem): boolean {
    //     if (row.id < 0 || row.itemState === PrfqItemStates.IN_PROGRESS) {
    //         return this.isColValid('amountRequired', row.amountRequired, row)
    //     }
    //     const isValid = Object.keys(row)
    //         .reduce((valid, field) => valid && this.isColValid(field, row[field], row), true);
    //     return isValid;
    // }

    /**
     * Validates one column (item field)
     */
    public isColValid(field: string, value: any, row?: PrfqItem): boolean {
        const validationMap = {
            amountRequired: val => val && val > 0 && val < 1000000,
            // amountRequired: val => val ? parseInt(val.toString().replace(' ', ''), 10) > 0 : false,
            unitPriceRequired: val => {
                const numValue = this.sharedService.getNumberFromString(val);
                return numValue ? (numValue >= 0 && numValue <= 999999) : true;
            },
            unitPriceOffered: val => {
                const numValue = this.sharedService.getNumberFromString(val);
                return numValue ? (numValue >= 0 && numValue <= 999999) : true;
            },
            dateOfferedDays: val => val === null || val === '' || (val >= 3 && val <= 365),
            // itemValidity: val => {
            //     if (val === null || val === '') {
            //         return true;
            //     }
            //     val = moment(val).utc().format('x');
            //     const now = moment().utc().format('x');
            //     const noon = moment().set({'hour': 12, 'minute': 0, 'second': 0}).utc().format('x');
            //     const now1 = moment().utc().add(1, 'days').format('x');
            //     const now2 = moment().utc().add(2, 'days').format('x');
            //     const now366 = moment().utc().add(366, 'days').format('x');

            //     // if its before noon and valid
            //     if (now <= noon && (now1 < val && now366 > val)) {
            //         return true;
            //     }

            //     // if its after noon and valid
            //     if (now > noon && (now2 < val && now366 > val)) {
            //         return true;
            //     }
            //     return false;
            // },
            itemValidityDays: (val) => {
                if (!val) {
                    return true;
                }
                return this.isItemValidityDaysValid(val)
            },
            // rfqProductAS: val => { return val && (val !== '') ? row.rfqProductASInDB || typeof row.rfqProductASInDB === 'undefined' : true; },
            // amountPackages: val => val && val > 0 && val < 1000000 && (val % 1 === 0) && val.toString().replace(',', '.').indexOf('.') < 0,
            // packing: val => val !== undefined && val !== null
        };

        const isValid = (field in validationMap) ? validationMap[field](value, row) : true;
        return isValid;
    }

    // public isItemValidityValid(prfqItem: PrfqItem): boolean {
    //     if (!prfqItem.itemValidity) {
    //         return false;
    //     }
    //     const _dateValue = typeof prfqItem.itemValidity === 'string' 
    //         ? new Date(prfqItem.itemValidity) 
    //         : prfqItem.itemValidity;
    //     return _dateValue > new Date();
    // }
    public isItemValidityDaysValid(itemValidityDays: number): boolean {
        if (!itemValidityDays) {
            return false;
        }

        return itemValidityDays > 0;
    }

    public getPrfq(prfqId: number): Observable<Prfq> {
        return this.http.get(`/prfq/${prfqId}/header`)
            .pipe(map(item => {
                return Object.assign({}, item, {
                    url: `rfq/${item.id}`
                });
            }));
    }

    public getAttachments(prfqId: number): Observable<Attachment[]> {
        return this.http.get(`/prfq/${prfqId}/attachments`);
    }

    public getAttachmentUploadUrl(fileName: string, prfqId?: number, childId?: number, privateItem?: boolean
    ): Observable<AttachmentUploadObj> {

        return this.http.put(`/prfq/attachment-upload-url`, JSON.stringify(
            Object.assign(
                {fileName: fileName, entityId: prfqId, childId: childId },
                privateItem ? { privateItem: privateItem} : {}
            )
        ));
    }

    public getAttachmentDownloadUrl(prfqId: number, attachmentId: number, lineNumber?: number
    ): Observable<AttachmentDownloadObj> {
        
        const data = { entityId: prfqId, attachmentId: attachmentId };
        if (lineNumber) {
            data['lineNumber'] = lineNumber;
        }
        return this.http.put(`/prfq/attachment-download-url`, JSON.stringify(data));
    }

    public deleteAttachment(entityId: number, attachmentId: number): Observable<void> {
        return this.http.delete(`/prfq/${entityId}/attachment/${attachmentId}`);
    }

    public prfqAction(prfqId: number, action: PrfqActions, extraData?: any): Observable<void> {
        return this.http.put(`/prfq/${prfqId}/action`, 
            JSON.stringify({action, extraData}), null, true);
    }

    public doPrfqAction(prfqId: number, action: PrfqActions, extraData?: any): void {
        this.prfqAction(prfqId, action, extraData)
            .subscribe(() => {
                // this.toastService.addSuccess(`RFQ_${action}_DONE`);
                // this.router.navigate([`/${this.sharedService.appSettings.language}/rfqs`]);
            },
                err => { console.log(err) });
    }

    public deletePrfq(prfqId: number): Observable<void> {
        return this.http.delete(`/prfq/${prfqId}/header`);
    }

    public getJournal(prfqId: number, query: EcmHttpQueryParams): Observable<PrfqJournalResponse> {
        return this.http.get(`/prfq/${prfqId}/journal`, this.http.prepareOptions(query));
    }

    /**
     * Exports prfq with given id to given format file with name containing given prfqNumber
     * @param prfqIds - array of ids to be exported, if null export all
     * @param prfqNumber - number of prfq to be exported
     * @param format - format to be exported
     * @param suppressFirstPartOfFileName - true if not to include first part of file name - to use only deliveryNumber value for file name
     */
    public export(prfqIds: number[], prfqNumber: string, format: 'CSV', suppressFirstPartOfFileName?: boolean): void {
        const columns = ['prfqNumber', 'dateCreated', 'lineNumber',
            'manufacturer', 'productSupplier', 'productShortName',
            'amountRequired', 'amountOffered', 'unitPriceRequired',
            'currencyPriceRequired', 'unitPriceOffered', 'currencyPriceOffered',
            'dateRequired', 'dateOfferedDays','itemNote', 'dateChanged', 'itemValidityDays'];
        let query: any = {
            exportToFormat: format,
            exportColumns: columns,
            orderBy: 'prfqNumber ASC'
        }
        if (prfqIds) {
            query['prfqId.operator'] = 'in';
            query['prfqId.value'] = prfqIds.join(',');
        } else {
            query = Object.assign({}, this.sharedService.lastPrfqsQueryParams, query);
            delete query.page;
        }
        this.http.get('/prfq-items', this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, { responseType: 'text' }))
        ).subscribe(data => {
            const mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            const blob = new Blob(['\ufeff' + data], { type: mediaType });
            const filename = (suppressFirstPartOfFileName ? '' : (this.translateService.instant('PRFQ') + '_')) + prfqNumber + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }

    public getPrfqs(query: EcmHttpQueryParams): Observable<PrfqsResponse> {
        return this.http.get('/prfq', this.http.prepareOptions(query)).pipe(
            map((response: PrfqsResponse) => {
                const rows = response.rows.map(row => ({...row,
                    url: `prfq/${row.id}`
                }));
                return {...response, rows};
            })
        );
    }

    public exportPrfqs(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get('/prfq', this.http.prepareOptions(query));
    }

    public updatePrfqNote(prfqId: number, note: string): Observable<void> {
        return this.http.put(`/prfq/${prfqId}/header`, JSON.stringify({ prfqNote: note }));
    }

    public updatePrfq(prfqId: number, data: any): Observable<void> {
      return this.http.put(`/prfq/${prfqId}/header`, JSON.stringify(data));
    }
    
    public createPrfq(): Observable<Prfq> {
        return this.http.post('/prfq', {});
    }

    public getProductShortNamesByString(searchValue?: string): Observable<string[]> {
        let query = {
          'columns': 'shortName,brandCode'
        };
        if (searchValue) {
            query['skip'] = 0;
            query['top'] = 40;
            query['shortName.operator'] = 'likeBoth';
            query['shortName.value'] = searchValue;
  
        }
        return this.http.get(`/products-lookup`, this.http.prepareOptions(query)).pipe(
            map(response => response.map(item => item.shortName))
        );
    }

    public getAllPrfqsItems(query: EcmHttpQueryParams): Observable<PrfqItemsResponse> {
        return this.http.get('/prfq-items', this.http.prepareOptions(query)).pipe(
            map((response: PrfqItemsResponse) => {
                const rows = response.rows.map(row => ({...row,
                    url: `prfq/${row.prfqId}`
                }));
                return {...response, rows};
            })
        );
    }
    
    public exportAllPrfqsItems(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get('/prfq-items', this.http.prepareOptions(query));
    }

    public exportPrfqItems(prfqId: number, query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get(`/prfq/${prfqId}/items`, this.http.prepareOptions(query));
    }

    public getPrfqItems(prfqId: number, query: EcmHttpQueryParams): Observable<PrfqItemsResponse> {
        return this.http.get(`/prfq/${prfqId}/items`, this.http.prepareOptions(query));
    }

    public createPrfqItems(prfqId: number, items: Partial<PrfqItem>[]): Observable<CreatePrfqItemResponse> {
        return this.http.put(`/prfq/${prfqId}/items`,
            JSON.stringify({
                toInsert: items.map(item => this.getPrfqItemForApi(item)),
                toUpdate: [],
                toDelete: []
            }));
    }

    private updatePrfqItem(prfqId: number, item: Partial<PrfqItem>): Observable<CreatePrfqItemResponse> {
        return this.http.put(`/prfq/${prfqId}/items`,
            JSON.stringify({
                toInsert: [],
                toUpdate: [this.getPrfqItemForApi(item)],
                toDelete: []
            }));
    }

    public updatePrfqItems(items: Partial<PrfqItem>[]): Observable<CreatePrfqItemResponse> {
        return this.http.put(`/prfq/${items[0].prfqId}/items`,
            JSON.stringify({
                toInsert: items
                    .filter(item => this.isNewRow(item))
                    .map(item => this.getPrfqItemForApi(item)),
                toUpdate: items
                    .filter(item => !this.isNewRow(item))
                    .map(item => this.getPrfqItemForApi(item)),
                toDelete: []
            }));
    }

    public updatePrfqItemsByLineNumber(prfqId: number, items: Partial<PrfqItem>[]): Observable<UpdatePrfqItemsResponse> {
        const _items = items
            .map(item => Object.keys(item).reduce((acc, attrName) => 
                item[attrName] ? Object.assign({}, acc, {[attrName]: item[attrName]}) : acc, {})
            );
            // .map(item => Object.assign({}, item,
            //     item['itemValidity']
            //     ? { itemValidity: this.formService.getDateStringFromIsoDate(item['itemValidity']) }
            //     : {}
            // ));

        return this.http.put(`/prfq/${prfqId}/items-by-line-number`,
            JSON.stringify({items: _items})
        );
    }

    public savePrfqItem(prfqId: number, prfqItem: PrfqItem): Observable<void> {
        return (this.isNewRow(prfqItem)
                ? this.createPrfqItems(prfqItem.prfqId, [prfqItem])
                : this.updatePrfqItem(prfqItem.prfqId, {id: prfqItem.id, ...this.getChangedAttrs(prfqItem)})
            ).pipe(
                map(response => {
                    if (response.toInsert.errors && response.toInsert.errors.length > 0) {
                        response.toInsert.errors.forEach(errorItem => {
                            const errorAttr = Object.keys(errorItem.error)[0];
                            this.toastService.addError(this.translateService.instant('PRFQ_INVALID_VALUE_' + errorAttr.toUpperCase()));
                        });
                        // API returns error as a success response so we need to convert it into error observable
                        throw Error('This is a handled error in PrfqService');
                    }
                })
            );
    }

    /**
     * Returns only attributes, which are different from originalValues
     * originalValues: [
            {colId: "rfqProduct", value: <original value>},
            {colId: "amountRequired", value: <original value>}
            ...
        ]
     */
    private getChangedAttrs(prfqItem: PrfqItem): Partial<PrfqItem> {
        return prfqItem.originalValues.reduce((acc, row) => prfqItem[row.colId] === row.value
            ? acc : Object.assign({}, acc, { [row.colId]: prfqItem[row.colId] }),
            {});
    }

    private getPrfqItemForApi(prfqItem: Partial<PrfqItem>): PrfqItemForApi {
        let apiItem: PrfqItemForApi = new PrfqItemForApi();
        console.log('prfqItem', prfqItem);

        const NUMERIC_ATTRS: string[] = ['id', 'amountOffered', 'unitPriceRequired', 'unitPriceOffered',
            'dateOfferedDays', 'itemValidityDays'];
        const DATE_ATTRS: string[] = ['dateRequired'];

        apiItem = Object.keys(prfqItem)
        .filter(attr => {
            const val = prfqItem[attr];
            // null in numeric attr means no value and no change -> should not be send to BE
            // '' in numeric attr means an existing value was cleared -> should be send to BE
            if (NUMERIC_ATTRS.indexOf(attr) >= 0) {
                return val !== null;
            }
            // null in date attr means an existing value was cleared -> should be send to BE
            // if no date attr was changed then no attr is present here
            else if (DATE_ATTRS.indexOf(attr) >= 0) {
                return true; // always process date attrs
            } 
            // text attrs are here only when changed so alwas send the value to BE
            else {
                // prfqItem[attr] !== null && (attr in apiItem)
                return attr in apiItem;
            }
        })
        .reduce((acc, attr) => {
            let val = prfqItem[attr];
            // Format dates
            if (val && DATE_ATTRS.indexOf(attr) >= 0) {
                val = this.formService.getDateStringFromIsoDate(val);
            }
            // Null empty numeric values
            if (val === '' && NUMERIC_ATTRS.indexOf(attr) >= 0) {
                val = null;
            }
            
            return Object.assign({}, acc, {[attr]: val});
        }, {});

        const isNewRow = this.isNewRow(prfqItem);
        if (isNewRow) {
            delete apiItem.id;
            // Only send non-null attrs while creating a row
            Object.keys(apiItem).forEach(attrName => {
                if (apiItem[attrName] === null) {
                    delete apiItem[attrName];
                }
            });
        }
        if ((apiItem.productShortName || isNewRow) && !apiItem.brandCode) {
            apiItem.brandCode = 'ZVL'; // TODO: replace this when brandCode will be in UI
        }
        // console.log('getPrfqItemForApi', apiItem);
        return apiItem;
    }

    public deletePrfqItems(prfqId: number, items: {id: number}[]): Observable<void> {
        return this.http.put(`/prfq/${prfqId}/items`,
            JSON.stringify({ toInsert: [], toUpdate: [], toDelete: items }));
    }

    public isNewRow(prfqItem: Partial<PrfqItem>): boolean {
        return !prfqItem.hasOwnProperty('id') || prfqItem.id < 0; // new items have negative id
    }

    public getDiscussionPrivateConfig(): DiscussionPrivateConfig {
        return {
            dropdownText: {
                title: 'PRFQ_DISCUSSION_DROPDOWN_TITLE',
                privateText: 'PRFQ_DISCUSSION_DROPDOWN_PRIVATE_TEXT',
                publicText: 'PRFQ_DISCUSSION_DROPDOWN_PUBLIC_TEXT'
            },
            iconTitle: 'PRFQ_DISCUSSION_ICON_TITLE',
            buttonWarning: 'PRFQ_DISCUSSION_SUBMIT_WARNING'
        };
    }

    public getAttachmentPrivateConfig(): AttachmentPrivateConfig {
        return {
            dropdownText: {
                title: 'PRFQ_ATTACHMENT_DROPDOWN_TITLE',
                privateText: 'PRFQ_DISCUSSION_DROPDOWN_PRIVATE_TEXT',
                publicText: 'PRFQ_DISCUSSION_DROPDOWN_PUBLIC_TEXT'
            },
            iconTitle: 'PRFQ_DISCUSSION_ICON_TITLE',
            buttonWarning: 'PRFQ_ATTACHMENT_SUBMIT_WARNING'
        };
    }

    public prfqItemsAction(prfqId: number, prfqItemIds: number[], action: string, extraData?: any): Observable<void> {
        return this.http.put(`/prfq/${prfqId}/items-action`, JSON.stringify({ 
            prfqItemIds, action, extraData }), null, true);
    }

    public getPrfqStateColors(): StateColorsObject {
        return {
            gray: PrfqStates.IN_PROGRESS, 
            red: PrfqStates.NEW, 
            yellow: PrfqStates.OPENED, 
            green: PrfqStates.CLOSED_ACCEPTED, 
            black: PrfqStates.CLOSED_NOT_ACCEPTED
        };
    }

    public getPrfqItemStateColors(): StateColorsObject {
        return {
            gray: PrfqItemStates.IN_PROGRESS,
            red: PrfqItemStates.NEW,
            yellow: PrfqItemStates.OPENED,
            blueDarker: PrfqItemStates.NOT_CONFIRMED,
            blue: PrfqItemStates.CONFIRMED,
            orange: PrfqItemStates.WAITING,
            green: PrfqItemStates.ACCEPTED,
            black: PrfqItemStates.NOT_ACCEPTED
        };
    }
}
