import { Injectable } from "@angular/core";
import { EcmHttpService, EcmHttpQueryParams } from '@services/http/ecm.http.service';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SentEmail, SentEmailsResponse } from "@app/model/sentEmail.model";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SentEmailsService {
    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService
    ) {}

    public getList(query: EcmHttpQueryParams): Observable<SentEmailsResponse> {
        return this.http.get('/sent-emails', this.http.prepareOptions(query)).pipe(
            map((response: SentEmailsResponse) => {
                const rows: SentEmail[] = response.rows.map(row => {
                    const sentEmail = new SentEmail(row);
                    
                    sentEmail.eventCodeTranslated = 
                        this.translateService.instant(`NOTIF_${row.eventCode}_HEADER`);
                    
                    return sentEmail;
                });
                return {...response, rows};
            })
        );
    }

    public exportList(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get('/sent-emails', this.http.prepareOptions(query));
    }

    public resendEmail(id: number): Observable<void> {
        return this.http.put(`/sent-emails/${id}`, '');
    }

}
