import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../language.service'
import { DeliveryOrdersService } from './delivery.orders.service'
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridActionsRendererComponent/grid.actions.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes, TableFilterItemValue } from '@app/model/table.filter.model';
import { PersonCreated } from '@app/model/personCreated.model';

@Injectable()
export class DeliveryOrdersGridService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

    allowedStatesToExport =['S','C','P'];

	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService,
                 private tableService: TableService,
                 private deliveryOrdersService: DeliveryOrdersService) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30},
            {id: 'number', name: "DELIVERY_NUMBER", alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'deliveryCreated', name: "DATE_OF_ISSUE", checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'deliveryDate', name: "DELIVERY_DATE", checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'sortName', name: "PERSON_ISSUED", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'totalWeight', name: "WEIGHT_KG", checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'totalPrice', name: "TOTAL_VALUE", checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'deliveryNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'state', name: "STATUS", checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'actions', hidden: true, name: "ACTIONS", checked: true, orderBy: false, orderDirection: 'ASC', width: 100}
        ];
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            original.splice(2, 0, {id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            original.splice(2, 0, {id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }

        original = this.tableService.filterSuppressColumns(original, 'deliveryOrders');
        let restored = this.sharedService.user.preferences['delivery-ordersTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        var this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        var gridOptions = this.tableService.getDefaultGridOptions(startPage, 'delivery-ordersTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        var this_ = this;
        let colDefs: any[] = [
            {
                headerName: '', 
                field: "selected",
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences.deliveryOrdersSelection, observable: this_.getSelectedSubject(),
                },
                headerComponent : GridHeaderSelectAllRendererComponent,
                headerComponentParams : {
                    selection: this_.sharedService.user.preferences.deliveryOrdersSelection,
                    observable: this_.getItemsSelectedSubject()
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('number').name), 
                field: "number",
                width: this_.getColumn('number').width,
                cellRendererFramework: GridLinkRendererComponent
            },         
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryCreated').name), 
                field: "deliveryCreated",
                width: this_.getColumn('deliveryCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },     
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryDate').name), 
                field: "deliveryDate",
                width: this_.getColumn('deliveryDate').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('sortName').name), 
                field: "sortName",
                width: this_.getColumn('sortName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('totalWeight').name), 
                field: "totalWeight",
                width: this_.getColumn('totalWeight').width,
                minWidth: 80, 
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('totalPrice').name), 
                field: "totalPrice",
                width: this_.getColumn('totalPrice').width,
                minWidth: 80, 
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryNote').name), 
                field: "deliveryNote",
                width: this_.getColumn('deliveryNote').width,
                cellStyle: {'text-align': 'left'},
                hide: !this_.getColumn('deliveryNote').checked,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('state').name), 
                field: "state",
                width: this_.getColumn('state').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        blue:  'S',
                        yellow:'C',
                        green: 'P',
                        black: 'D'
                    }
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('actions').name), 
                field: "actions",
                width: this_.getColumn('actions').width,
                minWidth: this_.getColumn('actions').width,
                cellRendererFramework: GridActionsRendererComponent,
                cellRendererParams: {service: this_.deliveryOrdersService, 
                    namePartGetter: function(data) {
                        return data.number ? data.number : data.deliveryNumber
                    },
                    allowOnStates: this_.allowedStatesToExport 
                },
                sortable: false,
            }
        ];
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            colDefs.splice(2, 0, {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name), 
                field: "authorizedSellerName",
                width: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            colDefs.splice(2, 0, {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name), 
                field: "customerName",
                width: this_.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'deliveryOrders');
        return colDefs;
    }

    public getFilterItems(personsCreated: PersonCreated[]): TableFilterItem[] {
        if (personsCreated.length == 0 || (personsCreated[0] && personsCreated[0].id === '')) {
            personsCreated[0] = 
                { id: '0', name: 'ITEMS_WITH_PERSON_NAME_NOT_FILLED', customerId: 0 };
        }

        let filterItems: TableFilterItem[] = [
            {id: 'number', name: 'DELIVERY_NUMBER', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth},
            {id: 'deliveryNote', name: 'NOTE', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth},
            // {id: 'deliveryCreated', name: 'DATE_OF_ISSUE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {
                id: 'deliveryCreated', 
                name: 'DATE_OF_ISSUE', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: '',
                showYearPicker: true
            },
            // {id: 'deliveryDate', name: 'DELIVERY_DATE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'deliveryDate', name: 'DELIVERY_DATE', type: TableFilterItemTypes.dateRange, valueFrom: '', valueTo: '' },
            {
                id: 'personCreatedSYS', 
                name: 'PERSON_ISSUED', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: personsCreated,
                updateValuesOnFilterMicroChange: (changedItem) => this.tableService.updatePersonCreatedFilter(changedItem, personsCreated),
            },
            {id: 'state', name: 'STATUS', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '', 
                customDotColors: { gray: 'X', red: 'N', blue: 'S', yellow:'C', green: 'P', black: 'D'}, 
                values: [
                    {id: 'X', name: 'DELIVERY_ORDER_STATE_X', dotId: 'X', default: true}, 
                    {id: 'N', name: 'DELIVERY_ORDER_STATE_N', dotId: 'N', default: true}, 
                    {id: 'S', name: 'DELIVERY_ORDER_STATE_S', dotId: 'S', default: true}, 
                    {id: 'C', name: 'DELIVERY_ORDER_STATE_C', dotId: 'C', default: true}, 
                    {id: 'P', name: 'DELIVERY_ORDER_STATE_P', dotId: 'P', default: false}, 
                    {id: 'D', name: 'DELIVERY_ORDER_STATE_D', dotId: 'D', default: false}
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['X', 'N', 'S', 'C']
                }
            },
        ]
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: TableFilterItemTypes.multiselect, value: '', values: this.sharedService.user.availableAuthorizedSellers});
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({
                id: 'customer.id', 
                name: 'CUSTOMER', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: this.sharedService.user.availableCustomers.map(
                    customer => ({...customer, id: customer.id.toString()}))
            });
        }
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'deliveryOrders');
        return filterItems;
    }

}

