import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { Observable, Subject, of } from 'rxjs';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
import { FormService } from '../form.service';
import { LanguageService } from '../language.service';
import { OrdersService } from '../order/orders.service';
import { LocalNumberPipe } from '../../locale.pipes.module';
import { BasketItem, BasketItemsResponse, AvailabilityState, BasketActions } from '@model/basket.model'
import { map, tap } from 'rxjs/operators';
import { BatchItemsUpdateResponse, BulkItemsUpdateResponse } from '@app/model/table.model';
import { CatalogueService } from '../catalogue/catalogue.service';

export interface CreateOrderParams {
    items: string[],
    orderNumberCustomer: string,
    orderNote: string,
    projectPriceListCode?: string
}

export interface CreateOrderResult {
    purchaseOrder: {
        id: number;
    }
}

@Injectable()
export class BasketService {
    subjectReloadCurrent: Subject<any>;

    constructor (
        private http: EcmHttpService,
        private sharedService: SharedService,
        private toastService: ToastService,
        private formService: FormService,
        private languageService: LanguageService,
        private ordersService: OrdersService,
        private catalogueService: CatalogueService
    ) {
        this.subjectReloadCurrent = new Subject<any>();
        this.isItemDateValid = this.isItemDateValid.bind(this);
        this.getInvalidItemDateMessage = this.getInvalidItemDateMessage.bind(this);
    }

    // !!! Current order = shoping basket !!!

    /**
     * Adds products to the basket
     *
     * @param products - array of products to be added
     */
    addToOrder(products: {amountOrdered: string, eCommProductId: string, brandCode: string, deliveryDate: string}[]) {
        products.forEach(product => {
            product.deliveryDate = this.formService.getDateStringFromIsoDate(product.deliveryDate);
        });
        return this.http.post(`/basket-items`, JSON.stringify(products)).pipe(map((data) => {
            this.setBasketItemsCount();
            if (data.errors.length === 0) {
                this.toastService.addSuccess('ADD_TO_ORDER_SUCCESS');
            } else {
                data.errors.forEach(function(item) {
                    for (const key in item.error) {
                        if (item.error[key]) {
                            item.error[key] = item.error[key].toUpperCase().replace(/\ /g, '_') + '_ERROR';
                            if (item.error[key] === 'INVALID_DATA_TYPE:_DELIVERYDATE_ERROR') {
                                item.row.deliveryDate = '';
                            }
                        }
                    }
                });
            }
            if (data.invalidPackingAmount) {
                this.toastService.addError('SOME_BASKET_ITEMS_NEED_ADJUST_AMOUNT_PACKAGES', 10000);
            }
            return data;
        } ));
    }

    /**
     * Returns observable of reload - used to reload table data
     */
    getReloadCurrentSubject(): Subject<any> {
        return this.subjectReloadCurrent;
    }

    /**
     * Updates item in current order
     *
     * @param itemId - id of item in order
     * @param attrs  - object of editable attributes of item in order with its values
     */
    updateOrderedItem(itemId: number, attrs: {amountOrdered: string, deliveryDate: string, packing: string}) {
        attrs.deliveryDate = this.formService.getDateStringFromIsoDate(attrs.deliveryDate);
        return this.http.put(`/basket-items/` + itemId, JSON.stringify(attrs));
    }

    /**
     * Bulk update of items in basket
     */
    public updateOrderedItems(itemIds: string[], data: {deliveryDate?: string, packing?: string}, 
        query: any): Observable<BulkItemsUpdateResponse<BasketItem>> 
    {
        delete query.page;
        delete query.top;
        return this.http.put(`/basket-items`, 
                JSON.stringify({items: itemIds, data: data, action: 'UPDATE'}), 
                this.http.prepareOptions(query)
            ).pipe(tap((result: BulkItemsUpdateResponse<BasketItem>) => {
                if (!result.errors || result.errors.length === 0) {
                    this.toastService.addSuccess('REPRESENTATIVE_CHANGE_SUCCESS');
                }
            })
        );
    }

    /**
     * Bulk update of items in bulk
     *
     * @param itemId - id of item in order
     * @param attrs  - object of editable attributes of item in order with its values
     */
    public updateAttributesOfOrderedItems(data: { id: number, amountOrdered?: number, 
        deliveryDate?: any, packing?: string}[]): Observable<BatchItemsUpdateResponse> 
    {
        data.forEach(element => {
            if (element.deliveryDate) {
                element.deliveryDate = this.formService.getDateStringFromIsoDate(element.deliveryDate)
            } });
        return this.http.put(`/basket-items`, JSON.stringify({rows: data}));
    }

    /**
     * Deletes item from current order
     *
     * @param itemId - id of item in order
     */
    deleteOrderedItem(itemId: number) {
        const items = [itemId];
        return this.http.put(`/basket-items`, JSON.stringify({items: items, action: 'DELETE'}))
            .pipe(map(data => {
                this.setBasketItemsCount();
                return data;
            }));
    }

    /**
     * Deletes given items of current order
     */
    deleteOrderItems(items: string[], query: any) {
        return this.http.put(`/basket-items`, JSON.stringify({items: items, action: 'DELETE'}), this.http.prepareOptions(query))
            .pipe(map(data => {
                this.setBasketItemsCount();
                return data;
            }));
    }

    /**
     * Duplicates the specified items in the current order
     *
     * @param items - ids of items to duplicate
     */
    duplicateOrderedItems(items: string[]) {
        return this.http.put(`/basket-items`, JSON.stringify({items: items, action: 'DUPLICATE'}))
            .pipe(map(data => {
                this.setBasketItemsCount();
                this.toastService.addSuccess(items.length === 1 ? 'ITEM_DUPLICATED' : 'ITEMS_DUPLICATED');
                return data;
            }));
    }

    /**
     * Corrects dates of delivery the specified items in the current order
     *
     * @param items - ids of items to correct
     */
    public correctDeliveryDatesOrderedItems(items: string[]) {
        return this.http.put(`/basket-items`, JSON.stringify({items: items, action: 'CORRECT_DELIVERY_DATE'}));
    }

    public correctAmountOrdered(itemIds: string[]) {
        return this.http.put(`/basket-items`, JSON.stringify({ items: itemIds, action: 'CORRECT_AMOUNT_ORDERED' }));
    }

    public bulkSetPacking(itemIds: string[]): Observable<any> {
        return of();
    }

    /**
     * Splits a basket item
     */
    splitOrderItem(itemId: number, splitParams: {deliveryDate: Date, amount: number}[]) {
        return this.http.put(`/basket-items/${itemId}/split`,
                JSON.stringify(splitParams.map(item => ({
                    deliveryDate: this.formService.getDateStringFromIsoDate(item.deliveryDate),
                    amount      : item.amount
                })))
            )
            .pipe(map(data => {
                this.setBasketItemsCount();
                return data;
            }));
    }

    /**
     * Loads Detail of current order
     */
    getCurrentOrderDetail() {
        return this.http.get(`/basket`);
    }

    /**
     * Loads list of basket items
     *
     * @param query - object of query params ({projectPriceListCode: string, skip: number, top: number, ...})
     */
    public getBasketItems(query, hidePreloader?: boolean): Observable<BasketItemsResponse> {
        if ('valid.value' in query) {
            query['valid.value'] = 0;
        }

        const url = `/basket-items`;
       return this.http.get(url, this.http.prepareOptions(query), hidePreloader).pipe(map((data: BasketItemsResponse) => {
            data.totalWeightCurrent = 0;
            data.totalPriceCurrent = 0;
            data.totalAmountOrderedCurrent = 0;
            data.rows.forEach((basketItem: BasketItem) => {
                basketItem.url = '/catalogue/' + basketItem.brandCode + '/' + encodeURIComponent(basketItem.eCommProductId);
                data.totalWeightCurrent += basketItem.rowWeight;
                data.totalPriceCurrent += basketItem.rowPrice;
                data.totalAmountOrderedCurrent += basketItem.amountOrdered;
                basketItem.packingOptions = basketItem.packingOptions ? basketItem.packingOptions : [{packing: 'A', amount: 1, priceCoefficient: 1}];
                if (basketItem.packingOptions.length > 1) {
                    // set default option to first position
                    const defaultOption = basketItem.packingOptions.filter(item => item.packing === basketItem.defaultPacking)[0];
                    basketItem.packingOptions = basketItem.packingOptions.filter(item => item.packing !== basketItem.defaultPacking);
                    basketItem.packingOptions.unshift(defaultOption);
                }
                basketItem.amountPackagesNotRounded = basketItem.amountPackages;
                basketItem.amountPackages = new LocalNumberPipe(this.languageService).transform(
                    basketItem.amountPackages,
                    this.sharedService.appSettings.language, false, 2);

                basketItem.originalValues = [{colId: 'amountOrdered', value: basketItem.amountOrdered}]
                basketItem.actions = this.getItemActions(basketItem);
                basketItem.productIconClass = this.catalogueService.getProductIconClass(
                    basketItem.eCommProductIdOld, basketItem.eCommProductIdNew);
            })
            return data;
        }));
    }

    private getItemActions(basketItem: BasketItem): { [key: string]: boolean } {
        const actionsDefinition = [
            {
                action: BasketActions.ShowDistributorStores,
                isAvailable: () => basketItem.availabilityState === AvailabilityState.PARTIALLY_FULFILLED &&
                    this.sharedService.params.features.customer.customerStock &&
                    !this.sharedService.hasPermission('preorders', 'POST')
            },
            {
                action: BasketActions.Split,
                isAvailable: () => (basketItem.amountPackages.toString().replace(' ', '') % 1 === 0) &&
                    (basketItem.availabilityState !== AvailabilityState.COMPLETELY_FULFILLED ) &&
                    (basketItem.availabilityFulfillings.length > 1 ||
                        (basketItem.availabilityFulfillings.length === 1 &&
                            basketItem.availabilityState === AvailabilityState.PARTIALLY_FULFILLED))

            },
            {
                action: BasketActions.Duplicate,
                isAvailable: () => (basketItem.amountPackages.toString().replace(' ', '') % 1 === 0)
            },
            {
                action: BasketActions.CorrectDeliveryDate,
                isAvailable: () => !this.isItemDateValid(basketItem)
            },
            {
                action: BasketActions.CorrectAmountOrdered,
                isAvailable: () => !this.isItemAmountOrderedValid(basketItem)
            },
            {
                action: BasketActions.Delete,
                isAvailable: () => true
            }
        ];
        return actionsDefinition.reduce((acc, def) =>
            Object.assign({}, acc, { [def.action]: def.isAvailable() }), {});
    }

    /**
     * Loads and saves basket items count
     */
    setBasketItemsCount() {
        this.http.get('/basket-items-count', this.http.prepareOptions({}), true)
        .subscribe(data => {
            this.sharedService.basketItemsCount = data.totalCount;
            localStorage.setItem('basketItemsCount', data.totalCount);
        }, err => {
            console.log(err);
        });
    }

    /**
     * Exports all
     */
    exportAll(query) {
        const url = `/basket-items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Creates an order
     */
    order(dataObj: CreateOrderParams): Observable<CreateOrderResult> {
        return this.orderPreorder('/orders', dataObj);
    }

    /**
     * Creates a preorder
     */
    preorder(dataObj: CreateOrderParams): Observable<CreateOrderResult> {
        return this.orderPreorder('/preorders', dataObj);
    }

    private orderPreorder(url: string, dataObj: CreateOrderParams): Observable<CreateOrderResult> {
        return this.http.post(url, JSON.stringify(dataObj), null, false, true)
            .pipe(map(data => {
                this.setBasketItemsCount();
                return data;
            }));
    }

    /**
     * Returns availability for basket item in stores of other customers
     */
    getCustomerStoresAvailability(basketItemIds: number[], query) {
        if (basketItemIds.length > 0) { query.itemIds = basketItemIds; }
        return this.http.get(`/customer-stock/basket`, this.http.prepareOptions(query))
        .pipe(map((data: any) => {
            data.rows.forEach(row => {
                row.customer = data.customers.filter(customer => customer.id === row.customerId)[0];
            });
            return data;
        }));
    }

    customerStoresAvailabilityExportAll(basketItemIds: number[], query) {
        if (basketItemIds.length > 0) { query.itemIds = basketItemIds; }
        const url = `/customer-stock/basket`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    public isItemDateValid(item: BasketItem, minDateAttrName = 'minDeliveryDate'): boolean {
        if (item[minDateAttrName]) {
            // Special rule for subcustomer - preorder permission
            if (this.sharedService.hasPermission('preorders', 'POST')) {
                if (item.deliveryDate === null) {
                    return true;
                } else {
                    return !((new Date(item[minDateAttrName])) >
                        (new Date(this.formService.getDateStringFromIsoDate(item.deliveryDate))));
                }
            } else {
                // General rule
                if (item.deliveryDate === null) {
                    return item.availabilityState === AvailabilityState.PARTIALLY_FULFILLED;
                } else if (item.availabilityState === AvailabilityState.PARTIALLY_FULFILLED) {
                    return false;
                } else {
                    return !((new Date(item[minDateAttrName])) > 
                        (new Date(this.formService.getDateStringFromIsoDate(item.deliveryDate))));
                }
            }
        }
        return true;
    }

    getInvalidItemDateMessage(item: BasketItem): string {
        if (this.isItemDateValid(item)) { // filter-out subcustomer validity
            return null;
        }
        if (item.availabilityState === AvailabilityState.PARTIALLY_FULFILLED
            && item.deliveryDate !== null
        ) {
            return 'DELIVERY_DATE_MUST_BE_EMPTY';
        } else if (item.availabilityState !== AvailabilityState.PARTIALLY_FULFILLED &&
            item.deliveryDate === null
        ) {
            return 'DELIVERY_DATE_MUST_BE_SET';
        } else if (item.availabilityState !== 'PARTIALLY_FULFILLED' &&
                (new Date(item.minDeliveryDate)) >
                    (new Date(this.formService.getDateStringFromIsoDate(item.deliveryDate)))
        ) {
            return 'DELIVERY_DATE_INVALID';
        } else {
            return null;
        }
    }

    public isItemAmountOrderedValid(item: BasketItem): boolean {
        const amountPackages = item.amountPackages.toString().replace(this.languageService.currentLocale.thousandsDelimiter, '');
        return amountPackages && amountPackages > 0 && amountPackages < 1000000 && (amountPackages % 1 === 0);
    }
}
