<div *ngIf="filterItems">
	<app-table-filter
		[items]="filterItems"
		[filterSelectedAttrId]="filterSelectedAttrId"
		[customDotColors]="customDotColors"
		[selection]="selection"
		[tableNamePrefix]="tableNamePrefix"
		[suppressStoreFilters]="suppressStoreFilters"
		[suppressToUrl]="suppressToUrl"
		[resetFilter$]="resetFilter$"
		(onFilterChanged)="onFilterChanged()"
	></app-table-filter>
</div>
<div id="catalogue-table" class="table" style="position: relative;" [ngStyle]="{'height.px': gridHeight + 115 + (((bulk || subjectDeleteSelected) && getSelectedCount() > 0) ? 40 : 0)}">
	<div *ngIf="showTable" style="margin: 0 5px; position: absolute; top: 0; bottom: 0; left: 0; right: 0" [ngClass]="{'over-backdrop': getEditedCount() > 0, 'selectable-text-in-cells': !dragging}">

		<div class="row-container" fxLayout="row" style="position: relative; max-width: 100%; padding-right: 0" [ngStyle]="{'width.px': gridWidth}"
			*ngIf="!suppressEmptyMessage && (total === 0)">
			<div class="empty-table-message" [ngStyle]="{'margin-top.px': headerHeight + 35}">
				<span translate>{{emptyMessage ? emptyMessage : 'EMPTY_TABLE'}}</span>
			</div>
		</div>		

		<div class="row-container" fxLayout="row" [ngStyle]="{'width.px': gridWidth}" style="max-width: 100%;">
			<div fxFlex="100"> 
				<div class="selected" fxLayout="row">
					<div fxFlex="100" style="margin-left: -5px;" *ngIf="total > 0 && gridOptions.api && (selection && selection.ids && getSelectedCount() > 0) && getEditedCount() === 0">
						<div class="row-container" style="margin-right: 3px;">
							<span translate>SELECTED_COUNT</span>: {{selection.all ? total : getSelectedCount()}} / {{total}}
	                    	<ng-template #toolTemplate><span translate>SELECTED_CANCEL</span></ng-template>
							<i class="fa fa-times clickable" (click)="cancelSelected()" [popover]="toolTemplate" container="body" triggers="mouseenter:mouseleave" *ngIf="!suppressSelection"></i>
						</div>
						<div class="row-container bkg-white btn clickable" style="margin-right: 3px;" (click)="toggleSelectedVisible()" *ngIf="gridOptions.api.getModel().getRowCount() != total && !suppressSelection">
							<i class="fa fa-square-o" *ngIf="!selection.visible"></i>
							<i class="fa fa-check-square-o" *ngIf="selection.visible"></i>
							<span translate>SELECT_VISIBLE</span> ({{gridOptions.api.getModel().getRowCount()}})
						</div>
						<div class="row-container bkg-white btn clickable" style="margin-right: 3px;" (click)="toggleSelectedAll()" *ngIf="!suppressSelection">
							<i class="fa fa-square-o" *ngIf="!selection.all && !(selection.visible && gridOptions.api.getModel().getRowCount() === total)"></i>
							<i class="fa fa-check-square-o" *ngIf="selection.all || (selection.visible && gridOptions.api.getModel().getRowCount() === total)"></i>
							<span translate>SELECT_ALL</span> ({{total}})
						</div>
					</div>
				</div>
				<div class="bulk" fxLayout="row" *ngIf="(bulk || subjectDeleteSelected) && (gridOptions.api && total > 0 && (selection && selection.ids && getSelectedCount() > 0))">
					<div class="row-container" style="margin: 0 3px 0 -5px;"><span translate>BULK_ACTIONS</span> ({{selection.all ? total : getSelectedCount()}}):</div>
					<div fxLayout="row wrap">
						<div style="margin-right: 8px;">
							<button class="btn-secondary" [disabled]="amount <= 0" [ngClass]="{'ec-disabled': amount <= 0}" *ngIf="subjectDeleteSelected" (click)="deleteSelected()">
									<i class="fa fa-trash"></i>
									<span translate>DELETE_SELECTED</span>
							</button>
						</div>
						<div *ngFor="let item of bulk.items">
							<div *ngIf="bulk" style="margin-right: 8px;">
								<div *ngIf="item.type === tableBulkTypes.select">
									<dropdown-component [attributeName]="item.id" 
										[items]="item.values" 
										[selected]="item.value"
										[justButtonLabel]="item.buttonLabel"
										(onSelect)="onBulkChange(item, $event)"
									></dropdown-component>
								</div>
								<div *ngIf="item.type === tableBulkTypes.date">
									<span style="vertical-align:14px; margin-right: 1px;"> <span translate>{{item.name}}</span>:</span>
									<my-date-picker 
										[(ngModel)]="item.value"
										[options]="myDatePickerOptions"
										[locale]="this.sharedService.appSettings.language"
										[selector]="selector"
										[placeholder]="item.name | translate"
										(dateChanged)="onBulkChange(item, $event)"></my-date-picker>
								</div>
								<div *ngIf="item.type === tableBulkTypes.button">
									<!-- <button class="btn-secondary" (click)="item.click()"> -->
									<button class="btn-secondary" (click)="callBulkButtonFn(item.click)">
										<i class="fa" *ngIf="item.iconClass" [ngClass]="[item.iconClass]"></i>
										<span translate>{{item.name}}</span>
									</button>
								</div>
								<div *ngIf="item.type === tableBulkTypes.input">
									<span style="margin-right: 1px;"> <span translate>{{item.name}}</span>:</span>
									<span *ngIf="item.prefixString">{{item.prefixString}} </span><input [(ngModel)]="item.value" style="padding: 5px;" [ngStyle]="{'width': item.width || '38px'}">
									<i *ngIf="item.value" class="clickable fa fa-check fnt-green" style="margin: 0 4px;" (click)="onBulkChange(item, $event)"></i>
									<i *ngIf="item.value" class="clickable fa fa-times" style="margin: 0 4px;" (click)="item.value = ''"></i>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>

			<div fxFlex="160px" fxLayout="row" fxLayoutAlign="end end" style="margin-right: -5px;">
				<ng-template #refreshTmpl><span translate>REFRESH</span></ng-template>
				<button class="btn-secondary" *ngIf="showRefreshButton" (click)="reloadCurrent()" [ngClass]="{'ec-disabled': getEditedCount() > 0}"
					[popover]="refreshTmpl" triggers="mouseenter:mouseleave">
			    	<i class="fa fa-refresh"></i>
			    </button>
			    <ng-template #exportTmpl><span style="white-space: nowrap;" translate>EXPORT_TO_XLSX</span></ng-template>
				<button id="default-table-xlsx-export" class="btn-secondary" [hidden]="!exportObj || total === 0" (click)="export('XLSX')" [ngClass]="{'ec-disabled': getEditedCount() > 0}"
					[popover]="exportTmpl" triggers="mouseenter:mouseleave">
			    	<i class="fa fa-file-excel-o"></i>
				</button>
				<div class="btn-group table-xlsx-export-menu" 
					dropdown [isOpen]="isExportDropdownOpen"
					[autoClose]="true" placement="bottom right"
					*ngIf="exportArray && total > 0">
					<button #dropdownToggle id="single-button" type="button" class="btn btn-secondary" dropdownToggle [ngClass]="{'ec-disabled': busy}"
						[popover]="exportTmpl" triggers="mouseenter:mouseleave">
						<i class="fa fa-file-excel-o"></i><i class="fa fa-caret-down caret"></i>
					</button>
					<div #popover *dropdownMenu role="menu" aria-labelledby="single-button" class="dropdown-menu dropdown-menu-right">
						<div class="row-container">
							<div class="row-container dropdown-item"
								[ngClass]="{'hidden': item.hidden || item.doNotShowInTableSettings, 'ec-disabled': item.alwaysVisible, 'clickable': !item.alwaysVisible}"
								*ngFor="let item of exportArray" (click)="export('XLSX', item)">
								   <span>{{item.name | translate}}</span>
							</div>
						</div>
					</div>
				</div>
				<table-sort 
					(sort)="sort($event.sortColumnName, $event.direction)" 
					[items]="columns"
					[busy]="busy || getEditedCount() > 0"
					[suppressToUrl]="suppressToUrl"
					[refresh]="refreshTableSort?.asObservable()"></table-sort>
				<table-settings 
					(setColumnVisibility)="setColumnVisibility($event)" 
					(resetColumns)="resetColumns($event)"
					[busy]="busy || getEditedCount() > 0"
					[items]="columns"></table-settings>
			</div>
		</div>

		<div *ngIf="total > 0 && gridOptions.columnApi">
			<sticky-header [gridHeight]="gridHeight" [gridWidth]="gridWidth" [getAllColumns]="getAllColumns" [scrolledByLeft]="scrolledByLeft" [secondaryHeaderColor]="secondaryHeaderColor"></sticky-header>
		</div>

		<div #gridContainer>
			<ag-grid-angular #agGrid style="max-width: 100%;  z-index: 1001;" [ngStyle]="{'width.px': gridWidth}" class="ag-theme-fresh"
	                [gridOptions]="gridOptions"
	                (bodyScroll)="bodyScroll($event)" >
	   		</ag-grid-angular>
		</div>
		   
		<div style="max-width: 100%; z-index: 1 !important;" [ngStyle]="{'width.px': gridWidth}">
			<div fxLayout="row" fxLayoutAlign="space-between top">

				<div>
					<button class="btn-primary" style="margin-top: 5px" (click)="addNewRow()"
						*ngIf="showNewRowButton">
						
						<i class="fa fa-plus" style="margin-right: 0"></i>
					</button>
				</div>

				<!-- <div style="max-width: 100%; z-index: 1 !important;" [ngStyle]="{'width.px': gridWidth}" *ngIf="showTable"> -->
				<div *ngIf="showTable && suppressPagination && showTotalRowsInsteadPagination">
					<span translate>ITEMS_PER_PAGE</span>: {{total}}
				</div>
				<div *ngIf="showTable && !suppressPagination && !showTotalRowsInsteadPagination" fxFlex="100">
					<table-pagination
						[gridOptions]="gridOptions"
						[total]="total"
						[pagination]="pagination"
						(onPageChanged)="onPageChanged($event)"
						(onPageSizeChanged)="onPageSizeChanged($event)"
						(goToPage)="goToPage($event)"
						[busy]="busy || getEditedCount() > 0"
						[suppressToUrl]="suppressToUrl"
						[dropup]="dropup"
					></table-pagination>
				</div>

			</div>
		</div>
	</div>
</div>