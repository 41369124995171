import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { EcmHttpService } from './http/ecm.http.service';
import * as FileSaver from 'file-saver';
import { SharedService } from './shared.service';
import { GridHeaderRendererComponent, GridHeaderRendererComponentParams } from '../components/tableComponent/gridRendererComponents/gridHeaderRendererComponent/grid.header.renderer.component';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TableFilterItem, TableFilterItemValue } from '@app/model/table.filter.model';
import { AvailableUserCustomer } from '@app/model/user.model';
import { SuppressColumnsAreas } from '../model/suppressColumnsAreas.model';

@Injectable()
export class TableService {
    editable: boolean = false;
    public observableEditable = new ReplaySubject(1);

    constructor (
        private http: EcmHttpService, 
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService
    ) {}

    public getDefaultGridOptions(startPage: number, tablePageSizeName: string, 
        headerClassFunc: (params: any) => string | string[]): GridOptions 
    {
        const headerComponentParams: GridHeaderRendererComponentParams = {
            router: this.router,
            activatedRoute: this.activatedRoute,
            cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
        };

        const defaultColDef: ColDef = {
            headerComponent : GridHeaderRendererComponent,
            headerComponentParams: headerComponentParams,
            headerClass: headerClassFunc,
            resizable: true,
            sortable: true,
        };

        return {
            domLayout: 'autoHeight',
            rowHeight: 35,
            headerHeight: 50, // two linws
            paginationPageSize: this.sharedService.user.preferences[tablePageSizeName] ? 
            this.sharedService.user.preferences[tablePageSizeName] : 10,
            // To fix 'Grid property paginationStartPage is gone, use api.paginationGoToPage(x) instead'
            // paginationStartPage: startPage != null ? startPage - 1 : 0,
            // rowModelType: 'pagination',
            suppressDragLeaveHidesColumns: true,
            suppressPaginationPanel: true,
            suppressPreventDefaultOnMouseWheel: true,
            suppressLoadingOverlay: true,
            suppressNoRowsOverlay: true,
            suppressContextMenu: true,
            singleClickEdit: true,
            defaultColDef : defaultColDef,
            suppressCellSelection: true,
            columnDefs: []
        }
    }

    getEditableObservable() {
        return this.observableEditable;
    }

    clearEditableObservable() {
        this.observableEditable = new ReplaySubject(1);
    }

    isSelectionEmpty(selection: any) {
    	var remainsSelected = Object.keys(selection.ids).length;
    	for (var key in selection.deselectedIds) {
		  	if (selection.ids.hasOwnProperty(key)) {
		    	remainsSelected--;
		  	}
		}
    	return !selection.all &&
	  		   remainsSelected == 0 &&
			   Object.keys(selection.newSelectedIds).length == 0;
    }

    updateSelectionWithData(selection, rows) {
        rows.map(row => {
            if (selection.ids.hasOwnProperty(row.id)) {
                selection.ids[row.id] = {...row};
            }
        });
    }

    isSomethingChanged(selection: any) {
    	if (!selection || !selection.newSelectedIds) {
    		return false;
    	}
    	return selection.all ||
	  		   Object.keys(selection.newSelectedIds).length > 0 ||
	  		   Object.keys(selection.deselectedIds).length > 0 ||
			   Object.keys(selection.updatedIds).length > 0;
    }

    /**
     * After a bulk update it is necessary to update value in originalValues attribute of selected itens
     * because it is not update by rows reload
     * @param attrName column ID e.g. 'itemValidity'
     * @param value value to set
     */
    public adjustOriginalValuesOfSelectedItems(selectionAttrName: string, attrName: string, value: any): void {
        if (this.sharedService.user.preferences[selectionAttrName].ids) {  // some rows are selected
            Object.keys(this.sharedService.user.preferences[selectionAttrName].ids).forEach(id => {
                if (this.sharedService.user.preferences[selectionAttrName].ids[id].originalValues) {  // row has originalValues
                    const foundArr = this.sharedService.user.preferences[selectionAttrName].ids[id].originalValues
                        .filter(colObj => colObj.colId === attrName);

                    if (foundArr.length === 1) {
                        foundArr[0].value = value;
                    }
                }
            });
        }
    }

    getExport(exportUrl:string, format:string, fileName:string) {
        this.http.getLocal(exportUrl, {responseType: 'blob'}).subscribe(data => { 
            const blob = new Blob([data],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName + '.' + format.toLowerCase());
        }, error => {
            console.log(error);
        });
    }



    getRestoredFilters(tableNamePrefix) {
        const key = this.sharedService.getUserPreferenceKey('Filter', tableNamePrefix);
        // if (this.sharedService.user.preferences.hasOwnProperty(this.sharedService.area + (tableNamePrefix ? tableNamePrefix : '') + 'Filter')) {
        //     return this.sharedService.user.preferences[this.sharedService.area + (tableNamePrefix ? tableNamePrefix : '') + 'Filter'];
        // }

        return this.sharedService.user.preferences.hasOwnProperty(key) ? this.sharedService.user.preferences[key] : null;
    }

    /** 
     * Returns col defs reduced by supressed columns for area id
     * @param colDefs: column definitions
     * @param areaId: area id string e.g. 'catalogue'/'basketItems'
     */ 
    filterSuppressColumns(colDefs: any, areaId: SuppressColumnsAreas): any[] {
        colDefs = colDefs.map(item => {
            if (item.children && this.sharedService.params.suppressColumns[areaId])
                item.children = item.children.filter(child => this.sharedService.params.suppressColumns[areaId].indexOf(child.field) < 0);
            return item;
        }).filter(item => 
            this.sharedService.params.suppressColumns[areaId] ? this.sharedService.params.suppressColumns[areaId].indexOf(item.field ? item.field : item.id) < 0 : true);
        return colDefs;
    }

    /**
     * Finds out if specified column is suppressed
     * @param  {string}  areaId
     * @param  {string}  columnName
     * @return {boolean}
     */
    isColumnSuppressed(areaId: SuppressColumnsAreas, columnName: string): boolean {
        return this.sharedService.params.suppressColumns[areaId] && this.sharedService.params.suppressColumns[areaId].indexOf(columnName) !== -1;
    }

    /**
     * Returns items of specified filter dropdown where supress states are filtered out
     * @param {any[]}  items   array of filter dropdown items where 'id' is a state attribute
     * @param {string} entity  entity state name, e.g. orderState
     * @return {array} copy of items array
     */
    filterSuppressStates(items: any[], entity: string) {
        return items.filter(item => this.sharedService.params.suppressStates[entity] 
                                    ? this.sharedService.params.suppressStates[entity].indexOf(item.id) < 0
                                    : true);
    }

    /**
     * Returns column array reduced by the suppressed export columns for specified area and format
     * @param  {string[]} columns
     * @param  {string}   areaId  e.g. 'orders'
     * @param  {string}   format  e.g. 'CSV'
     * @return {string[]}
     */
    filterSuppressExportColumns(columns: string[], areaId: SuppressColumnsAreas, format: string): string[] {
        return columns.filter(column => this.sharedService.params[`suppressExportColumns${format}`][areaId].indexOf(column) === -1);
    }

    /**
     * Changes Header name for given colId to given value
     * @param gridOptions 
     * @param colId 
     * @param newValue 
     */
    changeHeaderName(gridOptions, colId: string, newValue: string) {
        if (!gridOptions.columnApi) { // if grid is not ready in time of calling, wait for timeout and call again
            setTimeout(() => {
                this.changeHeaderName(gridOptions, colId, newValue);
            }, 500);
            return;
        }
        gridOptions.columnApi.getColumn(colId).colDef.headerName = newValue;
        gridOptions.api.refreshHeader();
    }

    /**
     * Used in a grid service when filter column 'personCreated' depends on the 'customer.id' filter column.
     * This is a method called from 'updateValuesOnFilterMicroChange' of the 'personCreated' filter column.
     */
    public updatePersonCreatedFilter(changedItem: TableFilterItem | null, personsCreated: TableFilterItemValue[]): TableFilterItemValue[] {
        // Reset filter - return the original values
        if (changedItem === null) {
            return personsCreated;
        }
        // Check if the change comes from the customers dropdown
        else if (changedItem.id === 'customer.id') {
            const selectedCustomers = changedItem.values.filter(value => value.checked) as unknown as AvailableUserCustomer[];
            return personsCreated.filter(person => selectedCustomers.find(customer => +customer.id === +person.customerId));
        }
        return null;
    }
}
