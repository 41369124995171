import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { InvoicesService } from '../../../services/invoice/invoices.service';
import { InvoicesGridService } from '../../../services/invoice/invoices.grid.service';
import { SharedService } from '../../../services/shared.service';
import { Subject } from 'rxjs';
import { ExportObj, TableBulk, TableBulkTypes } from '@app/model/table.model';

@Component({
  selector: 'invoices',
  templateUrl: 'invoices.component.html'
})
export class InvoicesComponent  { 

	totalRows:          number;
	filterItems: 		any[];
	subjectRefresh = new Subject<any>();
	subjectAllInvoicesSelected: Subject<any>;
	public bulk: TableBulk;
	public exportObj: ExportObj;

	busy:				boolean;
	initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table

	constructor(private router: Router, 
				private route: ActivatedRoute,
				private invoicesService: InvoicesService,
				private invoicesGridService: InvoicesGridService,
				public sharedService: SharedService) {
		this.subjectRefresh = new Subject<any>();
		this.subjectAllInvoicesSelected = invoicesGridService.getItemsSelectedSubject();

		this.getInvoices = this.getInvoices.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);

		this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
			this.exportObj = {
				fileName: this.sharedService.translateService.instant('INVOICES_SUMMARY'), 
				export: this.invoicesService.exportAll.bind(this.invoicesService)
			};
		});

		let exportFunction = () => {
			let selection = this.sharedService.user.preferences.invoicesSelection;
			this.invoicesService.export(
				selection.all ? null : Object.keys(selection.ids), 
				this.sharedService.translateService.instant('INVOICES_SUMMARY'), 'CSV', true);
		};
		this.bulk = {
			items: [
				{id: 'exportToCSV', name: 'EXPORT_TO_CSV', type: TableBulkTypes.button, iconClass: 'fa-download', click: exportFunction.bind(this)}, 
			]
		}
		if (!this.sharedService.user.preferences.hasOwnProperty('invoicesSelection')) {
            this.sharedService.user.preferences.invoicesSelection = {ids: {}, all: false, visible: false};
        }

		// to reload when menu item is clicked but it is already routed to this area
		this.sharedService.subjectSameAreaClicked.subscribe(res => {
			this.subjectRefresh.next();
		});
		
        this.initialized = true;
	}

	ngOnInit() {
		var this_ = this;
		setTimeout(function() {
			// navigate with orderby parameter
			this_.sharedService.navigateWithOrderby(this_.getColumns(), 
				this_.route.snapshot.queryParams, this_.sharedService.lastInvoicesQueryParams);
		}, 0);
	}

	getInvoices(params) {
		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};
		
		this.sharedService.lastInvoicesQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastInvoicesQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

        if (query.skip >= 0 && query.top > 0) {
			this.invoicesService.getInvoices(query)
			.subscribe(data => {
				this.totalRows = data.total;
				if (!this.filterItems) { // only if not already to not loose fererence of selected
					this.filterItems = this.getFilterItems();
				}
				params.successCallback(data.rows, data.total);
				this.busy = false;
	        }, err => {
	        	console.log(err);
	        	// also in error to be able to cancel filter
	        	if (!this.filterItems) { // only if not already to not loose fererence of selected
					this.filterItems = this.getFilterItems();
				}
	        	this.busy = false;
	        });
		}
	}
	getGridOptions() {
		return this.invoicesGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.invoicesGridService.getFilterItems();
	}
	getColumns() {
		return this.invoicesGridService.getColumns();
	}
	getColumnsDefs() {
		return this.invoicesGridService.getColumnDefs();
	}
}