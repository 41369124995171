<div dropdown [autoClose]="true" [insideClick]="true" #dropdown="bs-dropdown" class="dropdown">
    <button class="clickable pick-year-link fnt-primary dropdown-toggle" 
        type="button" dropdownToggle>{{'FILTER_PICK_YEARS' | translate}}</button>

    <div *dropdownMenu class="dropdown-menu" role="menu">
        <div class="input-container" style="margin: 0 10px 0 0;">
            <label translate>FROM</label>

            <select [(ngModel)]="fromYear" class="form-control">
                <!-- <option [value]="0">{{'PLEASE_SELECT_OPTION'|translate}}</option> -->
                <option *ngFor="let optionFrom of yearsFrom" 
                    value="{{optionFrom}}">{{optionFrom}}</option>
            </select>
        </div>

        <div class="input-container">
            <label translate>TO</label>

            <select [(ngModel)]="toYear" class="form-control">
                <!-- <option [value]="0">{{'PLEASE_SELECT_OPTION'|translate}}</option> -->
                <option *ngFor="let optionTo of yearsTo" 
                    value="{{optionTo}}">{{optionTo}}</option>
            </select>
        </div>

        <div class="footer">
            <button type="button" (click)="dropdown.hide(); save()" class="btn btn-primary" 
                [disabled]="fromYear > toYear"
                translate>SAVE</button>
        </div>
    </div>
</div>
