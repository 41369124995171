import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridUrlLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridUrlLinkRendererComponent/grid.url.link.renderer.component';

@Injectable()
export class NewsDetailGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'dateCreated', name: 'DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'title', name: 'TITLE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'body', name: 'BODY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'dateFrom', name: 'DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'dateTo', name: 'DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'important', name: 'IS_IMPORTANT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'readCount', name: 'READ_COUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'attachmentsCount', name: 'ATTACHMENTS_COUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
        ];
        original = this.tableService.filterSuppressColumns(original, 'newsDetail');
        let restored = this.sharedService.user.preferences['newsDetailTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'newsDetailTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        const this_ = this;
        let colDefs: any = [
            {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'authorizedSellerName',
                width: this_.getColumn('authorizedSellerName').width,
                minWidth: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('title').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'title',
                width: this_.getColumn('title').width,
                minWidth: this_.getColumn('title').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'dateCreated',
                width: this_.getColumn('dateCreated').width,
                minWidth: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('body').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'body',
                width: this_.getColumn('body').width,
                minWidth: this_.getColumn('body').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dateFrom').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'dateFrom',
                width: this_.getColumn('dateFrom').width,
                minWidth: this_.getColumn('dateFrom').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dateTo').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'dateTo',
                width: this_.getColumn('dateTo').width,
                minWidth: this_.getColumn('dateTo').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('important').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'important',
                width: this_.getColumn('important').width,
                minWidth: this_.getColumn('important').width,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('readCount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'readCount',
                width: this_.getColumn('readCount').width,
                minWidth: this_.getColumn('readCount').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('attachmentsCount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'attachmentsCount',
                width: this_.getColumn('attachmentsCount').width,
                minWidth: this_.getColumn('attachmentsCount').width,
                cellStyle: {'text-align': 'left'}
            },
        ];

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'newsDetail');
        return colDefs;
    }
    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], customers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        customers.unshift({id: 'NULL', name: 'AUTHORIZED_SELLER_USER'});
        let filterItems = [
            {id: 'dateCreated', name: 'DATE_CREATED', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'dateFrom', name: 'DATE_FROM', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'dateTo', name: 'DATE_TO', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: authorizedSellers},
            // {id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: customers},
            // {id: 'userId', name: 'USER', type: 'multiselect', search: true, value: '', values: personsIssued},
            {id: 'important', name: 'IS_IMPORTANT', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'important', name: 'NEWS_IS_IMPORTANT'},
                    {id: 'unimportant', name: 'NEWS_IS_NOT_IMPORTANT'},
                ]},
        ]
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'newsDetail');
        return filterItems;
    }

}

