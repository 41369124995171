<h4 translate>NOTE</h4>

<div *ngIf="!isEditable">
    <span>{{ note }}</span>
</div>

<div *ngIf="isEditable">
    <div>
        <textarea class="form-control" [(ngModel)]="_note" rows="4" cols="40" [value]="_note" title="{{'NOTE'|translate}}" style="width:100%"
            (ngModelChange)="onNoteChange()"
        ></textarea>
    </div>
    <div *ngIf="isEditing" style="text-align:right; padding-top: 10px;">
        <button class="btn-primary text-right" style="margin-right: 10px;" (click)="save()">
            <i class="fa fa-check-square-o"></i><a translate>SAVE</a>
        </button>
        <button class="btn-secondary text-right" (click)="cancelEdit()">
            <i class="fa fa-close"></i><a translate>CANCEL_CHANGES</a>
        </button>
    </div>
</div>
