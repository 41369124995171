import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'app-paste',
    templateUrl: 'paste.component.html'
})
export class PasteComponent {
    @Input() rowAttrs: string[]; // ['eCommProductId', 'amountOrdered]
    @Input() addButtonText: string; // 'ADD_TO_ORDER'
    @Input() importHelp1Text: string; // 'PRICE_LIST_IMPORT_HELP_1_3'
    @Input() disabled: boolean;
    @Input() clearAfterSubmit: boolean; // if to clear textarea after submit button has been clicked
    @Output() onResultReady: EventEmitter<any[]> = new EventEmitter<any[]>();

    public parsedRows: any[];
    public pastedExcel: string;

    constructor(
        private sharedService: SharedService
    ) {
        this.clearValues();
    }

    pasteExcelChanged(newValue: string) {
        this.parsedRows = this.sharedService.getObjectsFromSeparatedString(
            newValue, this.rowAttrs);
    }

    /**
     * Change tab key to \t and not go to next focusable element when tab is pressed
     * @param event - keydown event
     * @param element - textarea html element
     */
    pasteExcelKeyDown(event, element) {
        if (event.keyCode === 9) {
            const v = element.value,
                s = element.selectionStart,
                e = element.selectionEnd; element.value = v.substring(0, s) + '\t' + v.substring(e); element.selectionStart = element.selectionEnd = s + 1;
            this.pasteExcelChanged(element.value);
            return false;
        }
    }

    public emitResult(): void {
        this.onResultReady.emit(this.parsedRows);
        if (this.clearAfterSubmit) {
            this.clearValues();
        }
    }

    private clearValues(): void {
        this.parsedRows = [];
        this.pastedExcel = '';
    }

}
