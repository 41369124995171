import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
// import { CleanUpService } from '../clean.up.service';
import { UserService } from './user.service';
import { User, AvailableUserOptions, Representative } from '@model/user.model';
import { Observable, of } from 'rxjs';
import { map, repeat } from 'rxjs/operators';


@Injectable()
export class UsersAdminService {
    constructor (private http: EcmHttpService,
                 private sharedService: SharedService,
                 private toastService: ToastService,
                //  private cleanUpService: CleanUpService,
                 private userService: UserService) {

    }

    /**
     * Loads users
     */
    getUsers(representative: Representative, viewDisabled: boolean): Observable<User[]> {
        const url = `/users${viewDisabled ? '?disabled=1' : ''}`;

        return this.http.get(url)
        .pipe(map(data => {
            let meUserIndex;
            const users: User[] = [];
            data.forEach((user, index) => {
                if (user.id === this.sharedService.user.id) {
                    meUserIndex = index;
                }
                const userObj = new User(
                    user.id,
                    user.givenName,
                    user.familyName,
                    user.phoneNumber,
                    user.email,
                    user.groups,
                    user.notificationRoles,
                    '',
                    user.businessCondConsent,
                    user.homepage,
                    user.subcustomer,
                    user.subcustomer ? true : false
                )
                userObj.representative = user.representative;
                userObj.enabled = user.enabled;
                users.push(userObj);
            })

            if (meUserIndex > 0) { // to put current user to beginning
                const meUser = users[meUserIndex];
                users.splice(meUserIndex, 1);
                users.unshift(meUser);
            }
            return users;
        }));
    }

    /**
     * Creates new user
     *
     * @param user {email: <email address>, groups: [<groupId>, ...], notificationRoles: [<roleId>, ...]}
     */
    addNewUser(user: User): Observable<void> {
        return this.http.post('/users', JSON.stringify({
            email:             user.email,
            groups:            user.groups,
            notificationRoles: user.notificationRoles.map(role => {return role.id}),
            language:          user.language.toLowerCase(),
            subcustomer:       user.subcustomer
        }));
    }

    /**
     * Removes user from security group
     *
     * @param userId  <id of user>
     * @param groupId <id of group>
     */
    removeUserFromGroup(userId: number, groupId: string) {
        return this.http.delete('/users/' + userId + '/group/' + groupId, '')
        .pipe(map((data) => {
            if (userId === this.sharedService.user.id) {
                this.userService.getUserDetails().subscribe(data => {
                    // do nothing
                }, err => {
                    console.log(err)
                });
            }
            return data;
        }));
    }

    /**
     * Changes user groups
     * @param userId
     * @param add array of groupd IDs to add to
     * @param remove array of groupd IDs to remove from
     */
    public changeUserGroups(userId: number, add: string[], remove: string[]): Observable<void> {
        return this.http.put('/users/' + userId + '/groups', JSON.stringify({
            add: add,
            remove: remove
        }));
    }

    /**
     * Adds user to security group
     *
     * @param userId  <id of user>
     * @param groupId <id of group>
     */
    addUserToGroup(userId: number, groupId: string) {
        return this.http.put('/users/' + userId + '/group/' + groupId, '')
        .pipe(map((data) => {
            if (userId === this.sharedService.user.id) {
                this.userService.getUserDetails().subscribe(() => {
                    // do nothing
                }, err => {
                    console.log(err)
                });
            }
            return data;
        }));
    }

    /**
     * Removes user from notification role
     *
     * @param userId      <id of user>
     * @param notifRoleId <id of notification role>
     */
    removeUserFromNotificationRole(userId: number, notifRoleId: number) {
        return this.http.delete('/users/' + userId + '/notification-role/' + notifRoleId, '');
    }

    /**
     * Removes user from all notification roles
     *
     * @param userId  <id of user>
     */
    // removeUserFromAllNotificationRoles(userId: number) {
    //     return this.setUserNotificationRoles(userId, []);
    // }

    /**
     * Sets user notification roles
     *
     * @param userId  <id of user>
     * @param notifRoleIds  [ids of roles]
     */
    // setUserNotificationRoles(userId: number, notifRoleIds: number[]) {
    //     return this.http.put('/users/' + userId + '/notification-roles', JSON.stringify(notifRoleIds));
    // }

    /**
     * Adds user to notification role
     *
     * @param userId      <id of user>
     * @param notifRoleId <id of notification role>
     */
    addUserToNotificationRole(userId: number, notifRoleId: number) {
        return this.http.put('/users/' + userId + '/notification-role/' + notifRoleId, '');
    }

    /**
     * Changes user notification roles
     * @param userId
     * @param add array of notification role IDs to add to
     * @param remove array of notification role IDs to remove from
     */
    public changeUserNotificationRoles(userId: number, add: number[], remove: number[]): Observable<void> {
        return this.http.put('/users/' + userId + '/notification-roles', JSON.stringify({
            add: add,
            remove: remove
        }));
    }

    /**
     * Enables user
     *
     * @param userId      <id of user>
     */
    enableUser(userId: number) {
        return this.http.put('/users/' + userId + '/enable', '');
    }

    /**
     * Disables user
     *
     * @param userId      <id of user>
     */
    disableUser(userId: number) {
        return this.http.delete('/users/' + userId);
    }

    /**
     * Resends invitation email to user
     *
     * @param userId      <id of user>
     */
    resendInvite(userId: number) {
        return this.http.put('/users/' + userId + '/resend-invite', '')
        .pipe(map((data) => {
            this.toastService.addSuccess('INVITE_SEND_AGAIN_SUCCESS');
            return data;
        }));
    }

    /**
     * Loads available options for security group and notification role dropdowns
     */
    getAvailableUserOptions(): Observable<AvailableUserOptions> {
        return this.http.get('/users/available-options');
    }

    updateUser(userId: number, subcustomer?: string, groups?: string[],
        notificationRoleIds?: number[]): Observable<void> {

        return this.http.put('/users/' + userId, JSON.stringify(Object.assign({},
            subcustomer !== undefined ? { subcustomer } : {},
            groups !== undefined ? { groups } : {},
            notificationRoleIds !== undefined ? { notificationRoles: notificationRoleIds } : {}
        )));
    }

}
