<div *ngIf="order">
	<div class="row-container wider">
		<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/{{backUrl}}"
			[queryParams]="backUrl == 'orders' ? sharedService.lastOrdersSummaryQueryParams :
				backUrl == 'current-order' ? sharedService.lastProductsOfCurrentOrderQueryParams : {}">
			<i class="fa fa-arrow-circle-left"></i>
			<a translate>BACK</a>
		</button>
	</div>	
	<div class="row-container wider">
		<div class="area-title">
	        <h3><i class="fa fa-list-alt"></i><span translate>ORDER</span> {{order.orderNumberEComm}}</h3>
	        <span class="row-container">
        		<span translate>{{'STATETOOLTYP_' + order.orderState}}</span>
	        	<span class="fa fa-circle" style="border: 2px solid #fff; border-radius: 8px;" 
                     [ngClass]="{'fnt-gray': order.orderState == 'X',
                     			 'fnt-green': order.orderState == 'P',
								 'fnt-red': order.orderState == 'W',
                                 'fnt-blue-lighter': order.orderState == 'N',
                                 'fnt-yellow': order.orderState == 'C'}">
	            </span>
	        </span>
	        <span class="row-container clickable" (click)="scrollToComments()" *ngIf="!sharedService.hasPermission('orders-reduced', 'GET')">
	        	<i class="fa fa-comments" style="margin-right: 5px;"></i><span>{{sharedService.currentDiscussionComments.length}}</span>
	        </span>
	    </div>
    </div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>ORDER_ID</h4>
			<span>{{order.orderNumberEComm}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>DATE_OF_ISSUE</h4>
			<span>{{order.orderCreated | localdate:sharedService.appSettings.language:true}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>PERSON_ISSUED</h4>
			<span>{{order.fullName}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>ORDER_STATUS</h4>
			<span translate>{{'STATETOOLTYP_' + order.orderState}}</span>
		</div>
	</div>

	<div class="row-container wider" style="margin-bottom: 20px;">
		<ecm-table [dataGetter]="getProductsOfOrder" 
                   [optionsGetter]="getProductsOfOrderGridOptions"
                   [filterItems]="filterItems"
                   [columnsGetter]="getProductsOfOrderColumns"
                   [columnsDefsGetter]="getProductsOfOrderColumnsDefs"
                   [total]="totalRows"
                   [observableRefresh]="subjectRefresh.asObservable()"
                   [showRefreshButton]="true"
                   [busy]="busy"
                   [tableNamePrefix]="tableNamePrefix"
                   [secondaryHeaderColor]="true"
                   [exportObj]="exportObj"></ecm-table>
	</div>

	<div fxLayout="column" fxLayout.gt-sm="row" class="row-container bigger wider">
		<div fxFlex="50">
			<div style="margin-right: 40px;">
				<div class="params-table" fxFlex="100" fxLayout="column">
<!-- 					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>SHIPPING_TYPE</span>
						</div>
						<div fxFlex="60" class="value">
							<span *ngIf="order.shippingType" translate>{{'SHIPPING_TYPE_' + order.shippingType}}</span>
						</div>
					</div>

					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>TERM_OF_EXPIRATION</span>
						</div>
						<div fxFlex="60" class="value">
							<span *ngIf="order.paymentCondition" translate>{{'TERM_OF_EXPIRATION_' + order.paymentCondition}}</span>
						</div>
					</div> -->

					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>ORDER_INTERNAL_NUMBER</span>
						</div>
						<div fxFlex="60" class="value left-align">
							{{order.orderNumberCustomer}}
						</div>
					</div>

					<div class="item last" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>ORDER_NOTE</span>
						</div>
						<div fxFlex="60" class="value left-align">
							{{order.orderNote}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div fxFlex="50">
			<div class="params-table" fxFlex="100" fxLayout="column">
				<div class="item" fxLayout="row">
					<div fxFlex="40" class="key">
						<span translate>TOTAL_WEIGHT</span>
					</div>
					<div fxFlex="60" class="value">
						{{totalWeight | localnumber:sharedService.appSettings.language:true:3}} kg
					</div>
				</div>
				<div *ngIf="!sharedService.hasPermission('orders-reduced', 'GET')"
					class="item last" fxLayout="row">
					<div fxFlex="40" class="key">
						<span translate>TOTAL_PRICE</span>
					</div>
					<div fxFlex="60" class="value">
						{{totalPrice | localcurrency:sharedService.appSettings.language:orderCurrency}}
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="!sharedService.hasPermission('orders-reduced', 'GET')" 
		class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="50">
			<discussion #discussion
	            itemArea="orders"
	            [itemId]="route.snapshot.params['id']"
				(edited)="discussionInFormEdited($event)"
	        ></discussion>
	    </div>
	</div>
</div>