export enum SentEmailEventCodes {
    'orderCreation' = 'orderCreation',
    'orderConfirmation' = 'orderConfirmation',
    'deliveryOrderCreation' = 'deliveryOrderCreation',
    'deliveryOrderConfirmation' = 'deliveryOrderConfirmation',
    'undefinedSentEmailEventCode' = 'undefinedSentEmailEventCode'
}

export class SentEmail {
    public id: number;
    public eventCode: SentEmailEventCodes;
    public entitySearchString: string;
    public email: string;
    public dateCreated: Date;
    public success: boolean;
    // Added locally
    public eventCodeTranslated: string;

    constructor(item: any) {
        this.id = item.id || 0;
        this.eventCode = item.eventCode || SentEmailEventCodes.undefinedSentEmailEventCode;
        this.entitySearchString = item.entitySearchString || '';
        this.email = item.email || '';
        this.dateCreated = new Date(item.dateCreated);
        this.success = !!item.success;
    
        // TODO: Make this working!!!
        //
        // NOTIF_orderConfirmation_HEADER
        // this.eventCodeTranslated =  translateService.instant(`NOTIF_${this.eventCode}_HEADER`);
    }

}

export interface SentEmailsResponse {
    rows: SentEmail[]
    totalCount: number
}
