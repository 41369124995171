import { Observable ,  Subject } from 'rxjs';
import { AddToRfqDialogComponent } from './add.to.rfq.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class AddToRfqService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    /**
     * Shows the confirm modal doalog
     * @param params
     * @param modalParams { keyboard: default true, ignoreBackdropClick: default false }
     */
    public confirm(params: any, modalParams?: any): Observable<any> {
        this.bsModalRef = this.modalService.show(AddToRfqDialogComponent, modalParams);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
