import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';

@Component({
    selector: 'order-item-delivery-date-history-renderer',
    template: ` <div style="padding: 5px 0; margin-top: -5px;" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <ng-template #popTemplate>
                        <div (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                            <div style="text-align: right;">
                                <i class="clickable fa fa-times-circle" (click)="close()"></i>
                            </div>
                            <div *ngIf="!busy && deliveryDateHist && deliveryDateHist.original && deliveryDateHist.current">
                                <div><span translate>ORIGINAL_DELIVERY_DATE</span>:</div>
                                <div style="margin-bottom: 5px;">{{deliveryDateHist.original | localdate:sharedService.appSettings.language}}</div>
                                <div><span translate>CURRENT_DELIVERY_DATE</span>:</div>
                                <div>{{deliveryDateHist.current | localdate:sharedService.appSettings.language}}</div>
                            </div>
                            <div *ngIf="!busy && dataNotAvailable">
                                    <span translate>DATA_NOT_AVAILABLE</span>
                                </div>
                            <busy-indicator *ngIf="busy"></busy-indicator>
                        </div>
                    </ng-template>
                    <div>
                        <div *ngIf="!withPopover" class="fnt-primary">
                            {{params.value | localdate:sharedService.appSettings.language}}
                        </div>
                        <div *ngIf="withPopover" class="fnt-primary clickable"
                             #pop="bs-popover" [popover]="popTemplate" container="body" triggers=""
                             [adaptivePosition]="true" placement="right"
                             (click)="show(pop)">
                            {{params.value | localdate:sharedService.appSettings.language}}
                        </div>
                    </div>
                </div>
                `
})
export class GridOrderItemDeliveryDateHistoryRendererComponent implements AgRendererComponent {
    params:any;

    withPopover: boolean; // popover with container body cannot be in DOM a lot of times, so change alement without popover
                                  // to element with popover when this is true - when this grid cell is mouseover

    busy: boolean;                                  
    deliveryDateHist: {original: string, current: string};
    dataNotAvailable = false; // set to true after load if value does not equal sum of deliveryAmount of all loaded deliveryOrders
    openedPopupTimeout:any;

    constructor(public sharedService: SharedService, 
                private renderer: Renderer2) {
    }

    agInit(params:any):void {
        this.params = params;
    }

    mouseover() {
        this.withPopover = true;
        clearTimeout(this.openedPopupTimeout);
    }
    mouseleave() {
        clearTimeout(this.openedPopupTimeout);
        var this_ = this;
        this.openedPopupTimeout = setTimeout(() => {  
            if (!this.busy) { // hide popover only if data is loaded already
                this_.withPopover = false;
            }
        }, 3000);
    }

    show(pop: any) {
        this.busy = true;
        pop.show();
        this.params.getOrderItemDeliveryDateHistory(this.params.data.id).subscribe(data => {
            this.deliveryDateHist = {original: data.originalDeliveryDate, current: data.currentDeliveryDate};
            // set flag that some data are not available when sum of amounts is not equal to value shown in column
            this.dataNotAvailable = !this.deliveryDateHist.original || !this.deliveryDateHist.current;

            this.busy = false;
            this.mouseleave();
        }, err => {
            this.busy = false;
            console.log(err)
        });
    }

    close() {
        this.withPopover = false;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}