import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject, CellChange, RowActionsDef, TableBulk } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { Subject, Observable, of } from 'rxjs';
import { GridDateRendererComponent, GridDateRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '@components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { PrfqStates, PrfqItemStates, PrfqItem, PrfqItemActions } from '@app/model/prfq.model';
import { GridNumberRendererComponent } from '@components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridCommentRendererComponent, GridCommentRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridAttachmentRendererComponent, GridAttachmentRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridAttachmentRendererComponent/grid.attachment.renderer.component';
import { PrfqService } from '../prfq.service';
import { map, tap } from 'rxjs/operators';
import { GridGenericRowActionsRendererComponent, GridGenericRowActionsRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';
import { GridSelectedRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridTypeaheadRendererComponent, GridTypeaheadRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridTypeaheadRendererComponent/grid.typeahead.renderer.component';
import { GridTypeaheadEditorComponent, GridTypeaheadEditorComponentParams } from '@app/components/tableComponent/gridEditorComponents/gridTypeaheadEditorComponent/grid.typeahead.editor.component';
import { PrfqPermissionService } from '../prfq.permission.service';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent, GridInputEditorComponentParams } from '@app/components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridDateEditorComponent, GridDateEditorComponentParams } from '@app/components/tableComponent/gridEditorComponents/gridDateEditorComponent/grid.date.editor.component';
import { QuestionDialogService, QuestionDialogResult } from '@app/components/questionDialogComponent/question.dialog.service';
import { ICellRendererParams } from 'ag-grid-community';
import { GridHeaderActionsRendererComponentParams, GridHeaderActionsRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';
import { DiscountDialogService } from '@components/discountDialogComponent/discount.dialog.service';
import { ToastService } from '@services/toastService/toast.service';
import { Representative } from '@app/model/user.model';
import { PrfqsDetailItemsBulk } from './prfq-detail-items-bulk';
import { AppAreas } from '@app/model/appArea.model';
import { LocalDatePipe } from '@app/locale.pipes.module';
import { LanguageService } from '@app/services/language.service';

@Injectable()
export class PrfqsDetailItemsGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    public selected$: Subject<any> = new Subject<any>();
    public allSelected$: Subject<any> = new Subject<any>();
    private cellChanged$: Subject<CellChange> = new Subject<CellChange>();
    public attachmentChanged$: Subject<CellChange> = new Subject<CellChange>();
    private cancelRowChanges$: Subject<any> = new Subject<any>();
    private prfqsDetailItemsBulk: PrfqsDetailItemsBulk;

    constructor (
        private translateService: TranslateService,
        private prfqService: PrfqService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private prfqPermissionService: PrfqPermissionService,
        private questionDialogService: QuestionDialogService,
        private discountDialogService: DiscountDialogService,
        private toastService: ToastService,
        private languageService: LanguageService
    ) {
        super(tableService, sharedService);

        this.isRowValid = this.isRowValid.bind(this);
        this.prfqsDetailItemsBulk = new PrfqsDetailItemsBulk(
            this.translateService,
            this.prfqService,
            this.sharedService,
            this.prfqPermissionService,
            this.questionDialogService,
            this.discountDialogService,
            this.toastService,
            this.resetSelection.bind(this),
            this.reloadCurrentGridPage$,
            this.attachmentChanged$,
            this.adjustOriginalValuesOfSelectedItems.bind(this)
        );
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            // gridNameDashed: 'prfq-detail',
            // gridNameCamel: 'prfqDetail',
            gridNameDashed: AppAreas.prfq, // 'prfq',
            gridNameCamel: 'prfq', // 'prfq',
            columnList: [
                { id: 'selected', name: 'SELECTED', hidden: false, checked: true, orderBy: false, width: 30, alwaysVisible: true },
                { id: 'lineNumber', name: 'LINE_NUMBER', checked: true, orderBy: false, orderDirection: 'ASC', width: 30 },
                { id: 'productShortName', name: 'SHORT_NAME', checked: true, orderBy: true, orderDirection: 'ASC', width: 150 },
                { id: 'manufacturer', name: 'MANUFACTURER', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'productSupplier', name: 'PRODUCT_ID_SUPPLIER', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'amountRequired', name: 'REQUIRED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'amountOffered', name: 'OFFERED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'unitPriceRequired', name: 'REQUIRED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'unitPriceOffered', name: 'OFFERED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'dateRequired', name: 'REQUIRED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 150 },
                { id: 'dateOfferedDays', name: 'OFFERED_DATE_DAYS', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'itemValidityDays', name: 'RFQ_ITEM_VALIDITY', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'itemNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'attachmentsCount', name: 'ATTACHMENTS', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
                { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
            ]
            .filter(column => this.prfqPermissionService.isPrfqItemColumnAvailable(column.id))
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.getSelection(),
                    observable: this.selected$,
                    editedIdsObj: this.editedIdsObj
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.getSelection(),
                    editedIdsObj: this.editedIdsObj,
                    observable: this.allSelected$
                },
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('lineNumber').name),
                field: 'lineNumber',
                minWidth: 30,
                width: this.getColumn('lineNumber').width,
                cellRendererFramework: GridNumberRendererComponent,
                editable: false
            }
        ];
        // productShortName
        const productShortName: ColDef = {
            headerName: this.translateService.instant(this.getColumn('productShortName').name),
            field: 'productShortName',
            width: this.getColumn('productShortName').width,
            minWidth: this.getColumn('productShortName').width,
            cellStyle: {'text-align': 'left'},
            suppressKeyboardEvent: (params) => params.editing === true  && ([13, 38, 40].indexOf(params.event.keyCode) > -1),
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'productShortName'),
            suppressSizeToFit: true,
        };
        const productShortNameGridTypeaheadRendererComponentParams: GridTypeaheadRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            isColValid: this.prfqService.isColValid,
            field: 'productShortName',
            parenthesisIfNotFound: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'productShortName')
        };
        const productShortNameGridTypeaheadEditorComponentParams: GridTypeaheadEditorComponentParams = {
            dataGetter: (searchValue) => this.prfqService.getProductShortNamesByString(searchValue),
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        productShortName.cellRendererFramework = GridTypeaheadRendererComponent;
        productShortName.cellRendererParams = productShortNameGridTypeaheadRendererComponentParams;
        productShortName.cellEditorFramework = GridTypeaheadEditorComponent;
        productShortName.cellEditorParams = productShortNameGridTypeaheadEditorComponentParams;

        colDefs.push(productShortName);
        
        // manufacturer
        const manufacturer: ColDef = {
            headerName: this.translateService.instant(this.getColumn('manufacturer').name),
            field: 'manufacturer',
            width: this.getColumn('manufacturer').width,
            minWidth: this.getColumn('manufacturer').width,
            cellStyle: {'text-align': 'left'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'manufacturer')
        };
        const manufacturerGridInputRendererComponentParams: GridInputRendererComponentParams = {
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'manufacturer'),
            maxLength: '10',
            number: false,
            decimal: false,
            textAlign: 'left',
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const manufacturerGridInputEditorComponentParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            inputType: 'string',
            maxLength: '10',
            cellChanged$: this.cellChanged$
        };
        manufacturer.cellRendererFramework = GridInputRendererComponent;
        manufacturer.cellRendererParams = manufacturerGridInputRendererComponentParams;
        manufacturer.cellEditorFramework = GridInputEditorComponent;
        manufacturer.cellEditorParams = manufacturerGridInputEditorComponentParams;

        colDefs.push(manufacturer);

        // productSupplier
        const productSupplier: ColDef = {
            headerName: this.translateService.instant(this.getColumn('productSupplier').name),
            field: 'productSupplier',
            width: this.getColumn('productSupplier').width,
            minWidth: this.getColumn('productSupplier').width,
            cellStyle: {'text-align': 'left'},
            suppressKeyboardEvent: (params) => params.editing === true  && ([13, 38, 40].indexOf(params.event.keyCode) > -1),
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'productSupplier'),
            suppressSizeToFit: true,
        };
        const productSupplierCellRendererParams: GridTypeaheadRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            isColValid: this.prfqService.isColValid,
            field: 'productSupplier',
            parenthesisIfNotFound: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'productSupplier')
        };
        const productSupplierCellEditorParams: GridTypeaheadEditorComponentParams = {
            dataGetter: (searchValue) => this.prfqService.getProductShortNamesByString(searchValue),
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        productSupplier.cellRendererFramework = GridTypeaheadRendererComponent;
        productSupplier.cellRendererParams = productSupplierCellRendererParams;
        productSupplier.cellEditorFramework = GridTypeaheadEditorComponent;
        productSupplier.cellEditorParams = productSupplierCellEditorParams;

        colDefs.push(productSupplier);

        // amountRequired
        const amountRequired: ColDef = {
            headerName: this.translateService.instant(this.getColumn('amountRequired').name),
            field: 'amountRequired',
            width: this.getColumn('amountRequired').width,
            minWidth: this.getColumn('amountRequired').width,
            cellStyle: {'text-align': 'right'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'amountRequired')
        };
        const amountRequiredCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'amountRequired'),
            number: true,
            decimal: false,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const amountRequiredCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'number',
            cellChanged$: this.cellChanged$
        };
        amountRequired.cellRendererFramework = GridInputRendererComponent;
        amountRequired.cellRendererParams = amountRequiredCellRendererParams;
        amountRequired.cellEditorFramework = GridInputEditorComponent;
        amountRequired.cellEditorParams = amountRequiredCellEditorParams;

        colDefs.push(amountRequired);

        // amountOffered
        const amountOffered: ColDef = {
            headerName: this.translateService.instant(this.getColumn('amountOffered').name),
            field: 'amountOffered',
            width: this.getColumn('amountOffered').width,
            minWidth: this.getColumn('amountOffered').width,
            cellStyle: {'text-align': 'right'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'amountOffered')
        };
        const amountOfferedCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'amountOffered'),
            number: true,
            decimal: false,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const amountOfferedCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'number',
            cellChanged$: this.cellChanged$
        };
        amountOffered.cellRendererFramework = GridInputRendererComponent;
        amountOffered.cellRendererParams = amountOfferedCellRendererParams;
        amountOffered.cellEditorFramework = GridInputEditorComponent;
        amountOffered.cellEditorParams = amountOfferedCellEditorParams;

        colDefs.push(amountOffered);

        // unitPriceRequired
        const unitPriceRequired: ColDef = {
            headerName: this.translateService.instant(this.getColumn('unitPriceRequired').name),
            field: 'unitPriceRequired',
            width: this.getColumn('unitPriceRequired').width,
            minWidth: this.getColumn('unitPriceRequired').width,
            cellStyle: {'text-align': 'right'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'unitPriceRequired')
        };
        const unitPriceRequiredCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'unitPriceRequired'),
            number: true,
            decimal: true,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const unitPriceRequiredCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'decimal',
            maxLength: '10',
            cellChanged$: this.cellChanged$
        };
        unitPriceRequired.cellRendererFramework = GridInputRendererComponent;
        unitPriceRequired.cellRendererParams = unitPriceRequiredCellRendererParams;
        unitPriceRequired.cellEditorFramework = GridInputEditorComponent;
        unitPriceRequired.cellEditorParams = unitPriceRequiredCellEditorParams;

        colDefs.push(unitPriceRequired);

        // unitPriceOffered
        const unitPriceOffered: ColDef = {
            headerName: this.translateService.instant(this.getColumn('unitPriceOffered').name),
            field: 'unitPriceOffered',
            width: this.getColumn('unitPriceOffered').width,
            minWidth: this.getColumn('unitPriceOffered').width,
            cellStyle: {'text-align': 'right'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'unitPriceOffered')
        };
        const unitPriceOfferedCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'unitPriceOffered'),
            number: true,
            decimal: true,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const unitPriceOfferedCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'decimal',
            maxLength: '10',
            cellChanged$: this.cellChanged$
        };
        unitPriceOffered.cellRendererFramework = GridInputRendererComponent;
        unitPriceOffered.cellRendererParams = unitPriceOfferedCellRendererParams;
        unitPriceOffered.cellEditorFramework = GridInputEditorComponent;
        unitPriceOffered.cellEditorParams = unitPriceOfferedCellEditorParams;

        colDefs.push(unitPriceOffered);

        //dateRequired
        const dateRequired: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateRequired').name),
            field: 'dateRequired',
            width: this.getColumn('dateRequired').width,
            minWidth: 90,
            suppressSizeToFit: true,
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'dateRequired'),
            cellClass: 'overflow-visible'
        };
        const dateRequiredCellRendererParams: GridDateRendererComponentParams = {
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'dateRequired'),
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            cellChanged$: this.cellChanged$
        };
        const dateRequiredCellEditorParams: GridDateEditorComponentParams = {
            allowNullDate: true,
            cellChanged$: this.cellChanged$
        };
        dateRequired.cellRendererFramework = GridDateRendererComponent;
        dateRequired.cellRendererParams = dateRequiredCellRendererParams;
        dateRequired.cellEditorFramework = GridDateEditorComponent;
        dateRequired.cellEditorParams = dateRequiredCellEditorParams;

        colDefs.push(dateRequired);

        // dateOfferedDays
        const dateOfferedDays: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateOfferedDays').name),
            field: 'dateOfferedDays',
            width: this.getColumn('dateOfferedDays').width,
            minWidth: this.getColumn('dateOfferedDays').width,
            cellStyle: {'text-align': 'center'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'dateOfferedDays')
        };
        const dateOfferedDaysCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'dateOfferedDays'),
            number: true,
            decimal: false,
            prefixString: 'D + ',
            maxWidth: '50%',
            textAlign: 'center',
            getPopInfo: (params: {data: PrfqItem}) => {
                let dPlusDate = new Date();
                dPlusDate.setDate(dPlusDate.getDate() + params.data.dateOfferedDays);
                return [this.translateService.instant('OFFERED_DATE_DAYS_TOOLTIP') + ': ' +
                    new LocalDatePipe(this.languageService).transform(dPlusDate.toISOString(),
                        this.sharedService.appSettings.language)];
            },
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const dateOfferedDaysCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'number',
            prefixString: 'D + ',
            maxWidth: '50%',
            textAlign: 'center',
            maxLength: '10',
            cellChanged$: this.cellChanged$
        };
        dateOfferedDays.cellRendererFramework = GridInputRendererComponent;
        dateOfferedDays.cellRendererParams = dateOfferedDaysCellRendererParams;
        dateOfferedDays.cellEditorFramework = GridInputEditorComponent;
        dateOfferedDays.cellEditorParams = dateOfferedDaysCellEditorParams;

        colDefs.push(dateOfferedDays);

        // itemValidityDays
        const itemValidityDays: ColDef = {
            headerName: this.translateService.instant(this.getColumn('itemValidityDays').name),
            field: 'itemValidityDays',
            width: this.getColumn('itemValidityDays').width,
            minWidth: this.getColumn('dateOfferedDays').width,
            cellStyle: {'text-align': 'center'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'itemValidityDays'),
        };
        const itemValidityCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.editedIdsObj,
            allowMultipleEdit: true,
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'itemValidityDays'),
            number: true,
            decimal: false,
            prefixString: 'D + ',
            maxWidth: '50%',
            textAlign: 'center',
            getPopInfo: (params: {data: PrfqItem}) => {
                let itemValidity: string = '';

                if (params.data.itemValidity) {
                    itemValidity = params.data.itemValidity;
                } else if (params.data.itemValidityDays) {
                    let dPlusDate = new Date();
                    dPlusDate.setDate(dPlusDate.getDate() + params.data.itemValidityDays);
                    itemValidity = dPlusDate.toISOString();
                }

                return [this.translateService.instant('ITEM_VALIDITY_DAYS_TOOLTIP') + ': ' +
                    new LocalDatePipe(this.languageService).transform(itemValidity,
                        this.sharedService.appSettings.language)];
            },
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const itemValidityDaysCellEditorParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            inputType: 'number',
            prefixString: 'D + ',
            maxWidth: '50%',
            textAlign: 'center',
            maxLength: '10',
            cellChanged$: this.cellChanged$
        };
        itemValidityDays.cellRendererFramework = GridInputRendererComponent;
        itemValidityDays.cellRendererParams = itemValidityCellRendererParams;
        itemValidityDays.cellEditorFramework = GridInputEditorComponent;
        itemValidityDays.cellEditorParams = itemValidityDaysCellEditorParams;

        colDefs.push(itemValidityDays);

        // itemNote
        const itemNote: ColDef = {
            headerName: this.translateService.instant(this.getColumn('itemNote').name),
            field: 'itemNote',
            width: this.getColumn('itemNote').width,
            minWidth: this.getColumn('itemNote').width,
            cellStyle: {'text-align': 'left'},
            editable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'itemNote'),
            hide: !this.getColumn('itemNote').checked,
        };
        const itemNoteGridInputRendererComponentParams: GridInputRendererComponentParams = {
            getEditable: (params) => this.prfqPermissionService.isCellEditable(params.data, 'itemNote'),
            maxLength: '255',
            number: false,
            decimal: false,
            textAlign: 'left',
            editedIdsObj: this.editedIdsObj,
            isColValid: this.prfqService.isColValid,
            cellChanged$: this.cellChanged$
        };
        const itemNoteGridInputEditorComponentParams: GridInputEditorComponentParams = {
            editedIdsObj: this.editedIdsObj,
            inputType: 'string',
            maxLength: '255',
            cellChanged$: this.cellChanged$
        };
        itemNote.cellRendererFramework = GridInputRendererComponent;
        itemNote.cellRendererParams = itemNoteGridInputRendererComponentParams;
        itemNote.cellEditorFramework = GridInputEditorComponent;
        itemNote.cellEditorParams = itemNoteGridInputEditorComponentParams;

        colDefs.push(itemNote);

        // postsCount
        const postsCount: ColDef = {
            headerName: this.translateService.instant(this.getColumn('postsCount').name),
            field: 'postsCount',
            width: this.getColumn('postsCount').width
        };
        postsCount.cellRendererFramework = GridCommentRendererComponent;
        const postsCountCellRendererParams: GridCommentRendererComponentParams = {
            itemArea: 'prfq',
            idAttrName: 'prfqId',
            loadForEachItem: true,
            getItemLabel: (item) => this.translateService.instant('ROW').toLowerCase() + ' ' +
                item.lineNumber,
            isHidden: params => params.data.itemState === PrfqItemStates.IN_PROGRESS,
            showPrivateControls: this.sharedService.user.representative === Representative.AS,
            privateConfig: this.prfqService.getDiscussionPrivateConfig()
        };
        postsCount.cellRendererParams = postsCountCellRendererParams;
        colDefs.push(postsCount);

        // attachmentsCount
        const attachmentsCount: ColDef = {
            headerName: this.translateService.instant(this.getColumn('attachmentsCount').name),
            field: 'attachmentsCount',
            width: this.getColumn('postsCount').width
        };
        const attachmentsCountCellRendererParams: GridAttachmentRendererComponentParams = {
            getAttachments: (params, id) => this.prfqService.getAttachments(params.data.prfqId)
                .pipe(map(attachments => {
                    attachments = attachments.filter(item => item.childId === params.data.lineNumber);
                    return attachments;
            })),
            getUploadUrl: (params, fileName: string, privateItem: boolean) =>
                this.prfqService.getAttachmentUploadUrl(fileName, params.data.prfqId, params.data.lineNumber, privateItem),
            deleteAttachment: (params, entityId: number, attachmentId: number) =>
                this.prfqService.deleteAttachment(entityId, attachmentId),
            getDownloadUrl: (params, attachmentId: number, lineNumber?: number) =>
                this.prfqService.getAttachmentDownloadUrl(params.data.prfqId, attachmentId, lineNumber),
            getAllowEdit: (params) => this.prfqPermissionService.isCellEditable(params.data, 'attachmentsCount'),
            attachmentChanged: () => this.attachmentChanged$.next(),
            showPrivateControls: this.sharedService.user.representative === Representative.AS,
            privateConfig: this.prfqService.getAttachmentPrivateConfig(),
            idAttrName: 'prfqId'
        };
        attachmentsCount.cellRendererFramework = GridAttachmentRendererComponent;
        attachmentsCount.cellRendererParams = attachmentsCountCellRendererParams;

        colDefs.push(attachmentsCount);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'PRFQ_ITEM_STATE_',
                    renderColors: this.prfqService.getPrfqItemStateColors()
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            }
        ]);

        // actions
        const actions: ColDef = {
            headerName: this.translateService.instant(this.getColumn('actions').name),
            field: 'actions',
            width: this.getColumn('actions').width,
            minWidth: this.getColumn('actions').width,
            sortable: false,
        };
        const actionsHeaderComponentParams: GridHeaderActionsRendererComponentParams = {
            editedIdsObj:this.editedIdsObj,
            isRowValid: this.isRowValid,
            save: (rowsData) => this.prfqService.updatePrfqItems(rowsData),
            cancel$: this.cancelRowChanges$,
            reload$: this.reloadCurrentGridPage$
        };
        actions.headerClass = (params) => this.headerClassFunc(params) + ' active-on-edited-rows';
        actions.headerComponent = null;
        actions.headerComponentFramework = GridHeaderActionsRendererComponent;
        actions.headerComponentParams = actionsHeaderComponentParams;

        const rowActionsDef: RowActionsDef[] = [
            {
                id: PrfqItemActions.DELETE_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-trash',
                popover: 'DELETE_SELECTED',
                method: (params) => this.deleteRow(params.data),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.CONFIRM_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-check-circle fnt-blue-lighter',
                popover: 'RFQ_CONFIRM_ITEM',
                method: (params) => this.confirmItem(params.data),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.ACCEPT_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-check-circle fnt-green',
                popover: 'RFQ_ACCEPT_ITEM',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.ACCEPT_ITEM),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.NOT_ACCEPT_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-times-circle fnt-orange',
                popover: 'PRFQ_NOT_ACCEPT_ITEM',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.NOT_ACCEPT_ITEM),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.START_ITEM_WAITING,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-file-o',
                popover: 'PRFQ_START_ITEM_WAITING',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.START_ITEM_WAITING),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.FINISH_ITEM_WAITING,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-file-text fnt-blue',
                popover: 'PRFQ_FINISH_ITEM_WAITING',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.FINISH_ITEM_WAITING),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.CANCEL_ACCEPTED_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-times-circle',
                popover: 'RFQ_CANCEL_ACCEPTED_ITEM',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.CANCEL_ACCEPTED_ITEM),
                isVisible: this.isRowActionVisible
            },
            {
                id: PrfqItemActions.REOPEN_ITEM,
                type: 'ICON_ONLY',
                iconClass: 'fa fa-undo',
                popover: 'RFQ_REOPEN_ITEM',
                method: (params: {data: PrfqItem}) => this.doItemAction(params.data,
                    PrfqItemActions.REOPEN_ITEM),
                isVisible: this.isRowActionVisible
            }
        ];
        const actionsCellRendererParams: GridGenericRowActionsRendererComponentParams = {
            rowActionsDef: rowActionsDef,
            editedIdsObj: this.editedIdsObj,
            suppressIconsPositionKeeping: true,
            cellChanged$: this.cellChanged$,
            isRowValid: this.isRowValid,
            isNewRow: this.prfqService.isNewRow,
            save: (prfqItem: PrfqItem) => this.savePrfqItem(prfqItem.prfqId, prfqItem),
            cancel$: this.cancelRowChanges$
        };
        actions.cellRendererFramework = GridGenericRowActionsRendererComponent;
        actions.cellRendererParams = actionsCellRendererParams;

        colDefs.push(actions);

        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel)
            .filter(colDef => this.prfqPermissionService.isPrfqItemColumnAvailable(colDef.field));
    }

    public getFilterItems(prfqState?: PrfqStates): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'productShortName', name: 'SHORT_NAME', type: TableFilterItemTypes.text, 
                maxLength: 30, value: '', operator: TableFilterItemOperators.likeBoth
            },
            ...(this.prfqPermissionService.isPrfqItemColumnAvailable('itemState')
                ? [{
                    id: 'itemState', name: 'RFQ_ITEM_STATE', type: TableFilterItemTypes.multiselect, 
                    allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                    customDotColors: this.prfqService.getPrfqItemStateColors(),
                    values: [
                        { id: PrfqItemStates.IN_PROGRESS, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.IN_PROGRESS, dotId: PrfqItemStates.IN_PROGRESS, default: true },
                        { id: PrfqItemStates.NEW, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NEW, dotId: PrfqItemStates.NEW, default: true },
                        { id: PrfqItemStates.OPENED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.OPENED, dotId: PrfqItemStates.OPENED, default: true },
                        { id: PrfqItemStates.NOT_CONFIRMED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NOT_CONFIRMED, dotId: PrfqItemStates.NOT_CONFIRMED, default: true },
                        { id: PrfqItemStates.CONFIRMED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.CONFIRMED, dotId: PrfqItemStates.CONFIRMED, default: true },
                        { id: PrfqItemStates.WAITING, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.WAITING, dotId: PrfqItemStates.WAITING, default: true },
                        { id: PrfqItemStates.ACCEPTED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.ACCEPTED, dotId: PrfqItemStates.ACCEPTED, default: true },
                        { id: PrfqItemStates.NOT_ACCEPTED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NOT_ACCEPTED, dotId: PrfqItemStates.NOT_ACCEPTED, default: true }
                    ],
                    bulkCheckbox: {
                        title: 'SHOW_OPENED_STATES_ONLY',
                        ids: [
                            PrfqItemStates.IN_PROGRESS, PrfqItemStates.NEW, PrfqItemStates.OPENED,
                            PrfqItemStates.NOT_CONFIRMED, PrfqItemStates.CONFIRMED,
                            PrfqItemStates.WAITING
                        ]
                    }
                }]
                : []
            )
        ];
        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

    public getNewRow(): PrfqItem {
        const newRow: PrfqItem = {
            id: null,
            prfqId: null,
            prfqNumber: null,
            supplierId: null,
            supplierName: null,
            manufacturer: null,
            lineNumber: null,
            // product: null,
            productSupplier: null,
            productShortName: null,
            eCommProductId: null,
            brandCode: null,
            amountRequired: null,
            amountOffered: null,
            amountInPacking: null,
            packing: null,
            defaultPacking: null,
            packingOptions: null,
            amountPackages: null,
            unitPriceRequired: null,
            unitPriceOffered: null,
            currencyPriceRequired: null,
            currencyPriceOffered: null,
            dateRequired: null,
            dateOfferedDays: null,
            itemValidity: null,
            itemValidityDays: null,
            itemNote: null,
            postsCount: null,
            attachmentsCount: null,
            itemState: null,
            dateCreated: null,
            dateChanged: null,
            dateChangedAS: null,
            actions: null,
        };

        newRow.id = -(new Date()).getTime();  // negative unique number to differentiate it from real IDs
        newRow.packingOptions = [];
        return newRow;
    }

    private isRowValid(row: PrfqItem): boolean {
        if (this.prfqService.isNewRow(row)) {
            return this.prfqService.isColValid('amountRequired', row.amountRequired, row)
        }
        const isValid = Object.keys(row)
            .reduce((valid, field) => valid && this.prfqService.isColValid(field, row[field], row), true);

        return isValid;
    }

    private isRowActionVisible(params: ICellRendererParams, rowActionsDefId: string): boolean {
        const prfqItem: PrfqItem = params.data;
        return prfqItem.actions && prfqItem.actions.some(action => action === rowActionsDefId);
    }

    private savePrfqItem(prfqId: number, prfqItem: PrfqItem): Observable<void> {
        return this.prfqService.savePrfqItem(prfqId, prfqItem).pipe(
            tap(() => {
                this.reloadCurrentGridPage$.next();
            })
        );
    }

    private deleteRow(prfqItem: PrfqItem): void {
        const doDelete = () => {
            this.prfqService.deletePrfqItems(prfqItem.prfqId, [{ id: prfqItem.id }])
                .subscribe(() => {
                    this.reloadCurrentGridPage$.next();
                    this.attachmentChanged$.next();
                }, err => console.log(err));
        };

        if (prfqItem.attachmentsCount > 0) { // question when item has some attachments
            this.questionDialogService.confirm(
                {
                    message: 'DELETE_ITEM_MESSAGE',
                    question: 'DELETE_RFQ_ITEM_WITH_ATTACHMENTS_QUESTION',
                    suppressQuestionMark: true,
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                answer => {
                    if (answer === QuestionDialogResult.Confirm) {
                        doDelete();
                    }
                }
            );
        } else {
            doDelete();
        }
    }

    private confirmItem(prfqItem: PrfqItem): void {
        if (this.prfqService.isItemValidityDaysValid(prfqItem.itemValidityDays)) {
            this.doItemAction(prfqItem, PrfqItemActions.CONFIRM_ITEM);
        } else {
            this.toastService.addError(
                this.translateService.instant('RFQ_ITEM_VALIDITY_VALIDATION_ERROR', 9000));
        }
    }

    private doItemAction(prfqItem: PrfqItem, action: PrfqItemActions): void {
        this.prfqService.prfqItemsAction(prfqItem.prfqId, [prfqItem.id], action).subscribe(() => {
            this.reloadCurrentGridPage$.next();
        },
        err => console.error(err));
    }

    public getBulk(selectedItems: {[prfqItemId: string]: PrfqItem}, prfqId: number): TableBulk {
        return this.prfqsDetailItemsBulk.getBulk(selectedItems, prfqId);
    }

    private adjustOriginalValuesOfSelectedItems(attrName: string, value: any): void {
        return this.tableService.adjustOriginalValuesOfSelectedItems(this.getSelectionAttrName(), 
            attrName, value);
    }

}
