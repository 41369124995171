<div fxLayout="column" fxLayout.gt-sm="row" class="row-container">

    <div fxFlex="50" class="row-container">
        <div class="input-container" style="width: 100%;">
            <div fxLayout="row">
                <label translate>ADD_FILE_TO_RFQ</label>
                <ng-template #popFileImportTemplate>
                    <div translate>IMPORT_CSV_HELP</div>
                    <div translate>RFQ_IMPORT_HELP_1</div>
                    <div translate>RFQ_IMPORT_HELP_2</div>
                </ng-template>
                <i class="fa fa-info-circle clickable" [popover]="popFileImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
            </div>
            <input #fileInput type="file" (change)="fileChangeEvent($event)" placeholder="{{ 'INSERT_CSV' | translate }" style="max-width: 100%;"
            />

            <div class="input-container" *ngIf="brands.length > 1">
                <label translate>BRAND</label>
                <dropdown-component attributeName="selectedBrandFromFile" [items]="brands" [selected]="selectedBrandFromFile" (onSelect)="onSelectBrandFromFile($event)"></dropdown-component>
            </div>

            <div>
                <button class="btn-primary" (click)="fileCsvAddToOrder()" style="margin-top: 5px;" [disabled]="addFromFileBusy || fromFileObjects.length == 0"
                    [ngClass]="{'ec-disabled': addFromFileBusy || fromFileObjects.length == 0}">
                    <i class="fa fa-plus"></i>
                    <a translate>ADD_TO_RFQ</a>
                </button>
            </div>
        </div>
    </div>

    <div fxFlex="50" class="row-container">
        <div class="input-container">
            <div fxLayout="row">
                <label translate>ADD_CLIPBOARD_TO_RFQ</label>
                <ng-template #popClipboardImportTemplate>
                    <div translate>IMPORT_CLIPBOARD_HELP</div>
                    <div translate>RFQ_IMPORT_HELP_1</div>
                    <div translate>RFQ_IMPORT_HELP_2</div>
                </ng-template>
                <i class="fa fa-info-circle clickable" [popover]="popClipboardImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
            </div>
            <textarea #pasteTextarea class="form-control" [(ngModel)]="pastedExcel" (ngModelChange)="pasteExcelChanged(pastedExcel)"
                placeholder="{{ 'INSERT_CTRL_V' | translate }}" (keydown)="pasteExcelKeyDown($event, pasteTextarea)"></textarea>

            <div class="input-container" *ngIf="brands.length > 1">
                <label translate>BRAND</label>
                <dropdown-component attributeName="selectedBrandPasted" [items]="brands" [selected]="selectedBrandPasted" (onSelect)="onSelectBrandPasted($event)"></dropdown-component>
            </div>

            <div>
                <button class="btn-primary" (click)="ctrlVAddToOrder()" style="margin-top: 5px;" [disabled]="addFromPasteBusy || pastedExcel.length == 0"
                    [ngClass]="{'ec-disabled': addFromPasteBusy || pastedExcel.length == 0}">
                    <i class="fa fa-plus"></i>
                    <a translate>ADD_TO_RFQ</a>
                </button>
            </div>
        </div>
    </div>
</div>