import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridLinkRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { Subject } from 'rxjs';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { AppAreas } from '@app/model/appArea.model';
import { GridNumberRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { CCheck, CCheckEntities } from '@app/model/cCheck.model';

@Injectable()
export class CChecksGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    private selected$: Subject<any> = new Subject<any>();
    public allSelected$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['c-checks'], // 'prfqs',
            gridNameCamel: 'cChecks',
            columnList: [
                { id: 'entityName', name: 'MODULE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'start', name: 'CHECK_DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 130 },
                { id: 'issuesFound', name: 'NO_DIFFERENCES', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('entityName').name),
                field: 'entityName',
                width: this.getColumn('entityName').width,
                // suppressSizeToFit: true,
                // minWidth: this.getColumn('entityName').width,
                valueGetter: (params: {data: CCheck}) => params.data.entityTranslated,
                cellRendererFramework: GridLinkRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('start').name),
                field: 'start',
                width: this.getColumn('start').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            },
            {
                headerName: this.translateService.instant(this.getColumn('issuesFound').name),
                field: 'issuesFound',
                width: this.getColumn('issuesFound').width,
                suppressSizeToFit: true,
                // minWidth: this.getColumn('issuesFound').width,
                cellRendererFramework: GridNumberRendererComponent
            },
        ];
        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            { 
                id: 'entityName', name: 'MODULE', type: TableFilterItemTypes.multiselect,
                search: false, value: '', values: [
                    { id: CCheckEntities.purchaseOrder, name: CCheckEntities.purchaseOrder },
                    { id: CCheckEntities.deliveryOrder, name: CCheckEntities.deliveryOrder }
                ],
                allLabel: 'CATALOGUE_ALL'
            },
            {
                id: 'start', name: 'CHECK_DATE', type: TableFilterItemTypes.dateRange,
                valueFrom: '', valueTo: '', showYearPicker: false 
            }
            // {
            //     id: 'issuesFound', name: 'NO_DIFFERENCES', type: TableFilterItemTypes.,
            //     valueFrom: '', valueTo: '', showYearPicker: false 
            // }
        ];

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
