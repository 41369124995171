/**
 * Copy of DropdownComponent
 * Changed the component interface so that the input values are not modified
 * All emmited values are new objects, not the input objects
 * So far fine-tuned for multiselect only, but all the original functions are here, just not tested yet.
 * Only used in user administration so far.
 */
import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

interface Item {
    id: number | string,
    name: string,
    checked?: boolean,
    dotId?: string
}

@Component({
  selector: 'multiselect-component',
  templateUrl: 'multiselect.component.html',
  styleUrls:  ['./multiselect.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class MultiselectComponent implements OnInit, OnDestroy {
    @ViewChild('popover') private popover: ElementRef;
    @ViewChild('dropdownToggle', { static: true }) private dropdownToggle: ElementRef;
    @ViewChild('searchMultiple') private searchMultiple: ElementRef;

    @Input() items: Item[]
    @Input() prefix?: string; // prefix to be added to name used to translate
    @Input() selected?: Item; // value to be displayed as selected
    @Input() disabled?: boolean;
    @Input() disableSelectedValueInButton?: boolean
    @Input() autoClose?: any;
    @Input() multiselect: boolean = false;
    @Input() disableOptionAll?: boolean; // if true, dont show "All" item on top of component, default is false/undefined otherwise true
    @Input() search?: boolean; // true if search is shown in list of items
    @Input() multiselectAllLabel?: string; // custom label to be shown when all are selected
    @Input() multiselectSelectedLabel?: string; // custom label to be shown when some items are selected
    @Input() multiselectFoundLabel?: string; // custom label to be shown when some items are filtered
    @Input() resetMultiselectSearch$?: Observable<void>;
    @Input() smaller?: boolean;
    @Input() customDotColors?: any;
    @Input() titleGetter?: () => string[]; // function that returns array of strings to show in title, each item is in new row
    @Input() dropup?: boolean; //  if true, it drops up, if false (default) it drops down
    @Input() justButtonLabel?: string; // to show only this string in button when multiple not all label and selected items labels
    @Input() disableSelectionOnSearch?: boolean; // to disable selection of item on search
    @Output() onSelect?: EventEmitter<Item> = new EventEmitter<Item>();
    @Output() onMultipleSelect?: EventEmitter<Item[]> = new EventEmitter<Item[]>();
    @Output() onHidden?: EventEmitter<any> = new EventEmitter<any>();
    @Output() onShown?: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEnterInSearch?: EventEmitter<any> = new EventEmitter<any>();

    public innerItems: Item[];

    public isOpen: boolean;
    public multiSelectedItems: Item[] = [];
    public searchMultipleItemsText = '';
    public colors = { // can be overriden by setting custom colors
        black: 'D',
        gray: 'X',
        green: 'P',
        lime: 'L',
        red: 'N',
        yellow: 'C',
        blue: 'S',
        blueDarker: 'F'
    };
    private allItems: Item[]; // to store all items for search in items
    private subscriptions: Subscription[] = [];

    constructor(
        private translate: TranslateService
    ) {
    }

    public ngOnInit(): void {
        this.init();
    }

    private init(): void {
        this.innerItems = this.arrCopy(this.items);

        if (this.autoClose !== false) {
            this.autoClose = true;
        }
        if (this.disableOptionAll !== true) {
            this.disableOptionAll = false;
        }
        if (!this.selected) {
            this.selected = this.innerItems[0];
        }
        if (this.multiselect) {
            this.allItems = [...this.innerItems]; // to store all items for search in items
            this.setMultiSelectedItems();

            if (this.resetMultiselectSearch$) {
                this.subscriptions.push(this.resetMultiselectSearch$.subscribe(() => {
                    this.resetSearch();
                }));
            }
        }
        if (this.customDotColors) {
            this.colors = this.customDotColors;
        }
        if (typeof this.dropup === 'undefined') {
            this.dropup = false;
        }
    }

    /**
     * Item was selected
     */
    public select(item: Item): void {
        if (!this.disableSelectedValueInButton && !this.multiselect) {
            // set selected to button
            this.selected = item;
        }
        if (this.multiselect) {
            // check item if multiselect mode
            item.checked = !item.checked;
            this.setMultiSelectedItems();
            if (this.multiSelectedItems.length === 1) {
                this.selected = this.multiSelectedItems[0];
            }
        }

        if (!this.disableSelectionOnSearch) {
            // setTimeout(() => {
                this.onSelect.emit(item);
            // }, 0);
        }
    }

    /**
     * Toggles all selected
     */
    public multiselectToggleAll(): void {
        if (this.multiSelectedItems.length === this.innerItems.length) {
            this.multiselectCancelAll();
        } else {
            this.multiselectSelectAll();
        }
        this.setMultiSelectedItems();
    }

    /**
     * Selects all items in multiselect dropdown
     */
    private multiselectSelectAll(): void {
        this.innerItems.forEach(item => {
            item.checked = true;
            if (!this.disableSelectionOnSearch) {
                this.onSelect.emit(item);
            }
        });
        this.setMultiSelectedItems();
    }
    /**
     * Deselects all items in multiselect dropdown
     */
    private multiselectCancelAll(): void {
        this.innerItems.forEach(item => {
            item.checked = false;
            if (!this.disableSelectionOnSearch) {
                this.onSelect.emit(item);
            }
        });
        this.setMultiSelectedItems();
    }

    public multiselectApplyWhenSelectionOnSearchDisabled(): void {
        this.onMultipleSelect.emit(this.searchMultipleItemsText.length > 0
            ? this.allItems.filter(item => item.checked) 
            : this.multiSelectedItems
        );
    }

    /**
     * Filter items in multiple select list
     */
    public filterMultiValues(searchString: string): void {
        // if (this.disableSelectionOnSearch && this.items.length > this.allItems.length) {
        //     this.allItems = this.items.map(item => item); // in case items were not already filled from outside when oninit
        // }
        this.innerItems = this.allItems.map(item => {
            const hidden = !this.isSearchStringInValue(searchString, item.name);
            if (!this.disableSelectionOnSearch) {
                item.checked = !hidden;
                this.onSelect.emit(item);
            }
            return item;
        }).filter(item =>
            this.disableSelectionOnSearch ? this.isSearchStringInValue(searchString, item.name) : item.checked
        );
        this.setMultiSelectedItems();
    }

    private isSearchStringInValue(searchString: string, value: string): boolean {
        return this.translate.instant((this.prefix ? this.prefix : '') + value).toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    }

    /**
     * Resets search in multiple dropdown
     */
    public resetSearch(): void {
        setTimeout(() => { // to prevent closing on resetSearch click
            this.searchMultipleItemsText = '';
            if (this.allItems) {
                const multiSelectedItems = this.allItems.filter(item => !!item.checked );

                this.innerItems = this.allItems.map(item => {
                    if (!this.disableSelectionOnSearch) {
                        item.checked = multiSelectedItems.filter(selecteditem => selecteditem.id === item.id && selecteditem.checked).length === 1;
                        // if (this.items.length === 0) { // set  to default if searched is empty
                        //     item.checked = item.hasOwnProperty('default') ? item.default : true;
                        // }
                    }
                    return item;
                });
                this.setMultiSelectedItems();
            }
        }, 0);
    }


    /**
      * On key up of search input in multiple select
     */
    public searchKeyUp(event): void {
        if (event.keyCode === 13 && this.innerItems.length > 0) { // enter and some items are to be filtered
            this.onEnterInSearch.next();
            this.isOpen = false;
        }
        if (event.keyCode === 27) { // escape
            this.isOpen = false;
            this.resetSearch();
        }
    }

    /**
     * Returns text that should be in button of multiselect
     */
    public getMultiSelectedBtnText(): string {
        if (this.justButtonLabel) {
            return this.translate.instant(this.justButtonLabel);
        }
        const selectedItems = this.innerItems.filter(item => item.checked);
        // this.multiSelectedItems = selectedItems;
        if (selectedItems.length === this.allItems.length) {
            return this.translate.instant(this.multiselectAllLabel ? this.multiselectAllLabel : 'MULTISELECT_FILTER_ALL').toLowerCase();
        }
        if (selectedItems.length < this.allItems.length && selectedItems.length > 1) {
            return this.translate.instant(this.multiselectSelectedLabel ? this.multiselectSelectedLabel : 'MULTISELECT_FILTER_SELECTED').toLowerCase() + ' (' + selectedItems.length + ')';
        }
        if (selectedItems.length < this.allItems.length && selectedItems.length === 1) {
            return selectedItems[0].name;
        }
    }

    /**
     * Hides popover, used when autoclose is false
     */
    public hide(): void {
        this.isOpen = false;
        this.resetSearch();

        if (this.onHidden) {
            this.onHidden.emit();
        }
    }

    /**
     * Shows popover, used when autoclose is false
     */
    public show(): void {
        if (this.onShown) {
            this.onShown.emit();
        }
        this.isOpen = true;
        this.init();

        if (this.search) {
            setTimeout(() => {
                this.searchMultiple.nativeElement.focus();
            }, 0);
        }
    }

    /**
     * If click outside of popover, calls hide - used when autoclose is false
     */
    public onClick(event): void {
        if (this.popover && !this.popover.nativeElement.contains(event.target) &&
            !this.dropdownToggle.nativeElement.contains(event.target)  &&
            this.popover.nativeElement.clientHeight > 0
        ) {
            this.hide();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private setMultiSelectedItems(): void {
        this.multiSelectedItems = this.innerItems.filter(item => !!item.checked);
    }

    private arrCopy(items: any[]): any[] {
        return items.map(item => Object.assign({}, item));
    }
}
