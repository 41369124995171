<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container tab-active" style="margin-right: 5px;">
            <span translate>DELIVERY_ORDERS_SUMMARY</span>
        </span>
        <span class="row-container clickable" routerLink="/{{sharedService.appSettings.language}}/delivery-orders-items">
            <span translate>DELIVERY_ORDERS_ITEMS</span>
        </span>
    </div>

    <div fxLayout="column" *ngIf="initialized">
    	<ecm-table [dataGetter]="getDeliveryOrders" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [selection]="sharedService.user.preferences.deliveryOrdersSelection"
                     [subjectAllSelectedChange]="subjectAllDeliveryOrdersSelected"
                     [bulk]="bulk"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>