import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../../../services/window.service';
import { SharedService } from '@services/shared.service';
import { SendByEmailData, SendByEmailDialogResultState, SendByEmailDialogResult } from './send-by-email.dialog.model';
import { UserService } from '@app/services/user/user.service';

@Component({
    selector: 'app-send-by-email-dialog-component',
    templateUrl: 'send-by-email.dialog.component.html'
})
export class SendByEmailDialogComponent implements OnInit {
    doneSubject: Subject<any>;
    
    public maxHeight: number;

    public data: SendByEmailData = {
        email: '',
    };

    private userPreferenceKey: string;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            const result: SendByEmailDialogResult = {
                state: SendByEmailDialogResultState.Close,
                data: { email: '' },
            };

            this.doneSubject.next(result);
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private windowService: WindowService,
        private sharedService: SharedService,
        private userService: UserService,
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 120;
        });

        this.userPreferenceKey = this.sharedService.area + 'SendByEmail.email';
    }
    
    ngOnInit() {
        const email = this.sharedService.user.preferences[this.userPreferenceKey];

        if (email) {
            this.data.email = email;
        }
    }

    public close() {
        this.hide({ 
            state: SendByEmailDialogResultState.Close,
            data: { email: '' },
        });
    }

    public send() {
        this.userService.setUserPreference(this.userPreferenceKey, this.data.email, true);
        this.hide({
            state: SendByEmailDialogResultState.Send,
            data: this.data,
        });
    }

    private hide(result: SendByEmailDialogResult) {
        this.doneSubject.next(result);
        this.bsModalRef.hide();
    }
}
