import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { LanguageService } from '../../services/language.service';

@Injectable()
export class ShipmentsItemsService {

    constructor (private http: EcmHttpService, 
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /** 
     * Loads items list of all delivery orders
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getShipmentsItems(query: any) {
        var this_ = this;
        let url = `/shipments-items-to-ship`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/shipments-items-to-ship`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

