import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NewPostResult } from '@app/components/discussion/newPostComponent/new-post.component';
import { DiscussionPrivateConfig } from '@app/model/discussion.model';
import { BehaviorSubject, Subject } from 'rxjs';

export interface ClosePrfqParams {
    checkboxText: string,
    submitButtonText: string,
    showPrivateControls: boolean,
    discussionPrivateConfig?: DiscussionPrivateConfig
}

@Component({
  selector: 'app-close-prfq',
  templateUrl: 'close-prfq.component.html',
  styleUrls:  ['./close-prfq.component.scss']
})
export class ClosePrfqComponent implements OnInit, OnDestroy {
    public params: ClosePrfqParams;
    public done$: Subject<NewPostResult> = new Subject();

    public makeComment = false;
    public disabled$?: BehaviorSubject<boolean> = new BehaviorSubject(!this.makeComment);
    public disableSubmit = false;
    private newPostResult: NewPostResult = { post: null, isPrivate: null };

    constructor(
    ) {
    }

    public ngOnInit(): void {
    }

    public onMakeCommentChange(): void {
        this.disabled$.next(!this.makeComment);
        this.setDisableSubmit();
    }

    public onPostChange(postResult: NewPostResult): void {
        this.newPostResult = postResult;
        this.setDisableSubmit();
    }

    private setDisableSubmit(): void {
        this.disableSubmit = this.makeComment && 
            (!this.newPostResult.post || 
                (this.params.showPrivateControls && this.newPostResult.isPrivate === null)
            );
    }

    public submit(): void {
        this.done$.next(this.newPostResult);
    }

    public cancel(): void {
        this.done$.next();
    }

    public ngOnDestroy(): void {
    }
}
