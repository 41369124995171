import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { LoginDialogService } from '../login/loginDialogComponent/login.dialog.service';
import { LanguageService } from '../../services/language.service';
import { HomepageService } from '@app/services/homepage.service';
import { UserService } from '@app/services/user/user.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls:  ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    private observableLogin: Observable<any>;
    public showVideos: Boolean = true;
    private subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private loginDialogService: LoginDialogService,
        public sharedService: SharedService,
        private languageService: LanguageService,
        private homepageService: HomepageService
    ) {
        this.subscriptions.push(
            this.languageService.getLanguageObservable().subscribe(() => this.languageChanged())
        );
    }

    public ngOnInit(): void {
        // If is a user logged-in then redir to users homepage
        if (this.sharedService.isUserLoggedIn()) {
            console.log('redir v HomeComponent');
            this.router.navigate(['/' + this.sharedService.appSettings.language + '/' +
                this.homepageService.getHomepageRoute(this.sharedService.user.homepage) ]);
        }
    }

    /**
     * Opens login
     */
    public openLogin(): void {
        this.observableLogin = this.loginDialogService.confirm();
        this.observableLogin.subscribe(
            res => {
                if (res) {
                    // this.router.navigate(['/' + this.sharedService.appSettings.language + '/catalogue']);
                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/' +
                        this.homepageService.getHomepageRoute(this.sharedService.user.homepage)]);
                }
            }
        );
    }

    private languageChanged(): void {
        // Force template to redraw because video elements ignore change of the src attribute
        this.showVideos = false;
        setTimeout(() => this.showVideos = true, 0);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
