import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { Subject } from 'rxjs';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { AppAreas } from '@app/model/appArea.model';
import { SentEmail, SentEmailEventCodes } from '@app/model/sentEmail.model';
import { GridGenericRowActionsRendererComponent, GridGenericRowActionsRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';
import { SentEmailsService } from '../sent-emails.service';
import { ToastService } from '@app/services/toastService/toast.service';

@Injectable()
export class SentEmailsListGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private sentEmailsService: SentEmailsService,
        private toastService: ToastService,
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['sent-emails'], // 'prfqs',
            gridNameCamel: 'sentEmails',
            columnList: [
                { id: 'dateCreated', name: 'SENT_DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 130 },
                { id: 'eventCode', name: 'TYPE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'entitySearchString', name: 'IDENTIFIER', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'email', name: 'EMAIL', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'success', name: 'SENT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 25 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            },
            {
                headerName: this.translateService.instant(this.getColumn('eventCode').name),
                field: 'eventCode',
                width: this.getColumn('eventCode').width,
                // suppressSizeToFit: true,
                // minWidth: this.getColumn('entityName').width,
                valueGetter: (params: {data: SentEmail}) => params.data.eventCodeTranslated,
            },
            {
                headerName: this.translateService.instant(this.getColumn('entitySearchString').name),
                field: 'entitySearchString',
                width: this.getColumn('entitySearchString').width,
                // suppressSizeToFit: true,
                // minWidth: this.getColumn('entityId').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('email').name),
                field: 'email',
                width: this.getColumn('email').width,
                // suppressSizeToFit: true,
                // minWidth: this.getColumn('email').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('success').name),
                field: 'success',
                width: this.getColumn('success').width,
                valueGetter: (params) => {
                    const sentEmail: SentEmail = params.data;
                    return sentEmail.success ? this.sharedService.translateService.instant('YES') : this.sharedService.translateService.instant('NO');
                }
                // suppressSizeToFit: true,
                // minWidth: this.getColumn('success').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('actions').name),
                field: 'actions',
                width: this.getColumn('actions').width,
                minWidth: this.getColumn('actions').width,
                cellRendererFramework: GridGenericRowActionsRendererComponent,
                cellRendererParams: this.getActionsCellRendererParams(),
                sortable: false,
            }
        ];
        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    private getActionsCellRendererParams(): GridGenericRowActionsRendererComponentParams {
        return {
            rowActionsDef: [
                // {
                //     id: 'showEmail',
                //     type: 'ICON_ONLY',
                //     iconClass: 'fa fa-search',
                //     method: (params) => this.showEmail(params.data),
                //     popover: 'SHOW'
                // },
                {
                    id: 'resend',
                    type: 'ICON_ONLY',
                    iconClass: 'fa fa-repeat',
                    method: (params) => this.resendEmail(params.data),
                    popover: 'SEND'
                },
            ]
        };
    }

    // private showEmail(sentEmail: SentEmail): void {
    //     console.log('Not implemented yet', sentEmail);
    // }

    private resendEmail(sentEmail: SentEmail): void {
        this.sentEmailsService.resendEmail(sentEmail.id).subscribe(result => {
            this.toastService.addSuccess('SENT_EMAIL_SUCCESS');
            this.reloadCurrentGridPage$.next();
        },
        error => {
            console.log(error);
            this.reloadCurrentGridPage$.next();
        });
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            { 
                id: 'eventCode', name: 'TYPE', type: TableFilterItemTypes.multiselect,
                search: false, value: '', values: [
                    { 
                        id: SentEmailEventCodes.orderCreation, 
                        name: `NOTIF_${SentEmailEventCodes.orderCreation}_HEADER`,
                    },
                    { 
                        id: SentEmailEventCodes.orderConfirmation,
                        name: `NOTIF_${SentEmailEventCodes.orderConfirmation}_HEADER`,
                    },
                    { 
                        id: SentEmailEventCodes.deliveryOrderCreation,
                        name: `NOTIF_${SentEmailEventCodes.deliveryOrderCreation}_HEADER`,
                    },
                    { 
                        id: SentEmailEventCodes.deliveryOrderConfirmation,
                        name: `NOTIF_${SentEmailEventCodes.deliveryOrderConfirmation}_HEADER`,
                    },
                ],
                allLabel: 'CATALOGUE_ALL'
            },
            {
                id: 'entitySearchString', name: 'IDENTIFIER', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            }
        ];

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
