<div class="subcustomer-component">
    <div class="input-container">
        <label translate>SUBCUSTOMER_USER</label>
    </div>
    <form #subcustomerForm="ngForm">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div style="margin-right: 5px">
                        <input type="radio" value="yes" name="state" [(ngModel)]="state"
                            (click)="_onStateChanged(states.Yes); $event.preventDefault()">
                    </div>
                    <div style="margin-right: 5px" 
                        (click)="_onStateChanged(states.Yes)" translate>YES</div>
                </div>
                <div>
                    <div class="input-container">
                        <input [(ngModel)]="_subcustomer" class="form-control"
                            name="subcustomerName"
                            (keyup)="_onSubcustomerChanged()"
                            #subcustomerFormItem="ngModel" [disabled]="state === states.No"
                            [ngClass]="{'ec-disabled': state === states.No}" required>
                    </div>
                </div>
                <div fxLayoutAlign="end center"
                    *ngIf="!subcustomerFormItem.valid && state === states.Yes"
                    class="alert alert-danger">
                    <span translate>SUBCUSTOMER_EMPTY</span>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="row">
                    <input type="radio" value="no" name="state" [(ngModel)]="state"
                        (click)="_onStateChanged(states.No); $event.preventDefault()" style="margin-right: 5px"><span
                        (click)="_onStateChanged(states.No)" translate>NO</span>
                </div>
            </div>
            <div *ngIf="showButtons && state === states.Yes && _subcustomer !== originalSubcustomer"
                fxLayoutAlign="center center"
            >
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button class="btn-primary" (click)="_onSaveButtonClicked()"
                        [disabled]="!_subcustomer"
                        [ngClass]="{'ec-disabled': !_subcustomer}">
                        <span translate>SAVE</span>
                    </button>
                    <button class="btn-secondary" (click)="_onCancelButtonClicked()">
                        <span translate>CANCEL</span>
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>