import { Component } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: 'faq',
    templateUrl: 'faq.component.html'
})
export class FaqComponent  { 
  
    FAQ_COUNT = 13;
    faqs = [];

    constructor(private sharedService: SharedService) {
        for(let i = 1; i <= this.FAQ_COUNT; i++)
            this.faqs.push({ 
                q: `FAQ_AS${this.sharedService.apParams.id}_${i}_Q`, 
                a: `FAQ_AS${this.sharedService.apParams.id}_${i}_A` 
            });
    }
}