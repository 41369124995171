import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@app/components/base/base.component';
import { FormGroup, Form } from '@angular/forms';
import { Supplier } from '@app/model/supplier.model';
import { SupplierService } from '../supplier.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '@app/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language.service';

interface GroupDropdownItem {
    id: string;
    name: string;
}

@Component({
    selector: 'app-supplier-detail',
    templateUrl: './supplier-detail.component.html',
    styleUrls: ['./supplier-detail.component.scss']
})
export class SupplierDetailComponent extends BaseComponent implements OnInit {

    public busy = false;
    public supplier: Supplier;
    public supplierOld: Supplier;
    public allAvailableGroups: GroupDropdownItem[] = [];
    public allAvailableGroupsOld: GroupDropdownItem[] = [];
    public addGroupSelected: GroupDropdownItem = { id: '', name: 'ADD_TO_SECURITY_GROUP' };
    @ViewChild('supplierForm') private supplierForm: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService,
        private supplierService: SupplierService,
        private translate: TranslateService,
        private languageService: LanguageService
    ) {
        super(router, route, sharedService);
    }

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    public isEditing(): boolean {
        return this.supplierForm && this.supplierForm.form.dirty;
    }

    public ngOnInit(): void {
        if (this.route.snapshot.params['id'] > 0) { // existing supplier
            this.supplierService.getSupplier(this.route.snapshot.params['id']).subscribe(supplier => {
                this.supplier = supplier;
                this.supplierOld = Object.assign({}, supplier);
                this.supplierOld.notActivatedAdminUser = supplier.notActivatedAdminUser 
                    ? Object.assign({}, supplier.notActivatedAdminUser) : null;
                this.supplierOld.permissionGroups = [...supplier.permissionGroups];
                this.loadAvailablePermissionGroups();
            }, err => {
                console.log(err);
                this.busy = false;
            });
        } else { // id == 0 it is a new supplier
            // this.getAvailableCustomerOptions(true);

            this.supplier = {
                id: 0,
                supplierName: '',
                supplierCode: '',
                contactName: '',
                contactPhone: '',
                contactEmail: '',
                locality: '',
                country: '',
                city: '',
                street: '',
                zip: '',
                permissionGroups: [],
                supplierAdminEmail: '',
                supplierAdminLanguage: this.translate.currentLang.toUpperCase(),
                notActivatedAdminUser: null,
                valid: 1
            };
            this.supplierOld = Object.assign({}, this.supplier), { permissionGroups: [] };
            this.loadAvailablePermissionGroups();
        }

        this.languageService.getLanguageObservable().subscribe((lang: string) => {
            if (this.route.snapshot.params['id'] <= 0) { // new supplier
                this.supplier.supplierAdminLanguage = lang.toUpperCase();
            }
        });
    }

    public onSelectLanguageAdmin(event: { attributeName: string, selected: string }): void {
        this.supplier.supplierAdminLanguage = event.selected;
    }

    public resendInvite(adminEmailForm: FormGroup): void {
        this.supplierService.resendInvite({
            id: this.supplier.notActivatedAdminUser.id,
            email: this.supplier.notActivatedAdminUser.email !== this.supplierOld.notActivatedAdminUser.email
                ? this.supplier.notActivatedAdminUser.email : null
        }).subscribe(() => {
            this.supplier = Object.assign({}, this.supplier);
            this.supplier.notActivatedAdminUser = Object.assign({}, this.supplier.notActivatedAdminUser);
            adminEmailForm.markAsPristine();
        }, err => {
            console.log(err)
        });
    }

    public onSelectAddGroup(event: { attributeName: string, selected: GroupDropdownItem },
        supplierForm: FormGroup
    ): void {
        this.supplier.permissionGroups.push(event.selected.id);
        this.allAvailableGroups = this.allAvailableGroups.filter(group => group.id !== event.selected.id);
        supplierForm.markAsDirty();
    }

    public removeFromGroup(groupId: string, supplierForm: FormGroup): void {
        this.supplier.permissionGroups = this.supplier.permissionGroups.filter(id => groupId !== id);
        this.allAvailableGroups.push({ id: groupId, name: 'SECURITY_GROUP_' + groupId });
        supplierForm.markAsDirty();
    }

    public save(supplierForm: FormGroup): void {
        this.busy = true;
        if (this.supplier.id > 0) { // update existing
            const cleanUpForm = () => {
                this.supplierOld = Object.assign({}, this.supplier);
                this.supplierOld.permissionGroups = [...this.supplier.permissionGroups];
                supplierForm.markAsPristine();
                this.busy = false;
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/suppliers'],
                    { queryParams: this.sharedService.lastSuppliersQueryParams });
            };

            console.log(this.supplier);
            // Make diff supplier vs. supplierOld
            const supplierUpdates: Partial<Supplier> = Object.keys(this.supplier)
                .filter(key => ['permissionGroups', 'notActivatedAdminUser'].indexOf(key) === -1)
                .reduce((acc, key) => this.supplier[key] !== this.supplierOld[key]
                    ? Object.assign(acc, { [key]: this.supplier[key] })
                    : acc,
                {});

            if (this.supplier.permissionGroups.length > 0) {
                supplierUpdates.permissionGroups = [...this.supplier.permissionGroups];
            }

            if (Object.keys(supplierUpdates).length > 0) { // there is something to save
                this.supplierService.updateSupplier(this.route.snapshot.params['id'], supplierUpdates)
                .subscribe(() => {
                    cleanUpForm();
                }, err => {
                    console.log(err)
                    this.busy = false;
                });
            } else {
                cleanUpForm();
            }
        } else { // create new
            this.supplierService.createSupplier(this.supplier).subscribe(() => {
                this.busy = false;
                supplierForm.markAsPristine();
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/suppliers'],
                    { queryParams: this.sharedService.lastSuppliersQueryParams });
            }, err => {
                console.log(err)
                this.busy = false;
            });
        }
    }

    public cancel(supplierForm: FormGroup): void {
        this.supplier = Object.assign({}, this.supplierOld);
        this.supplier.permissionGroups = [...this.supplierOld.permissionGroups];

        this.allAvailableGroups = this.allAvailableGroupsOld
            .filter(group => this.supplier.permissionGroups.indexOf(group.id) === -1);

        supplierForm.markAsPristine();
    }

    private loadAvailablePermissionGroups(): void {
        this.supplierService.getAvailablePermissionGroups().subscribe(groups => {
            this.allAvailableGroupsOld = groups
                .map(groupId => ({ id: groupId, name: 'SECURITY_GROUP_' + groupId}));

            this.allAvailableGroups = this.allAvailableGroupsOld
                .filter(group => this.supplier.permissionGroups.indexOf(group.id) === -1)
        });
    }
}
