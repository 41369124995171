<div fxLayout="column" class="row-container component-outer-container">
    <div fxLayout="row" class="row-container" style="border-bottom: 1px solid #eee;">
        <h4><i class="fa fa-plus"></i><span translate>ADD_TO_RFQ</span></h4>
    </div>

    <div fxLayout="row" class="row-container">
        <div fxFlex="1 1 40%">
            <app-file-loader
                [rowAttrs]="rowAttrs"
                [addButtonText]="'ADD_TO_RFQ'"
                [importHelp1Text]="'PRFQ_ADD_ITEMS_IMPORT_HELP_1'"
                (onResultReady)="onDataReady($event)"
            ></app-file-loader>
        </div>

        <div fxFlex="2 2 60%">
            <app-paste style="width: 100%;"
                [rowAttrs]="rowAttrs"
                [addButtonText]="'ADD_TO_RFQ'"
                [importHelp1Text]="'PRFQ_ADD_ITEMS_IMPORT_HELP_1'"
                [clearAfterSubmit]="true"
                (onResultReady)="onDataReady($event)"
            ></app-paste>
        </div>
    </div>

</div>
