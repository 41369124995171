<div class="row-container wider">
    <div fxLayout="row">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container bkg-white" style="margin-right: 5px;">
                <span translate>ERP_DATA_CONSISTENCY_CHECK</span>
            </span>
            <!-- <span class="row-container clickable"
                routerLink="/{{sharedService.appSettings.language}}/prfqs-items">
                <span translate>RFQS_ITEMS</span>
            </span> -->
        </div>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getCChecks"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>