import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { LocalNumberPipe } from '../../../../locale.pipes.module';

@Component({
    selector: 'app-price-renderer',
    template: `
        <ng-template #popInfo>
            <div *ngFor="let item of popInfoValue">{{item}}</div>
        </ng-template>
        <div style="padding: 0 3px; text-align: right;"
            [popover]="params.getPopInfo ? popInfo : null" container="body" triggers="mouseenter:mouseleave">
            {{params.value | localcurrency:sharedService.appSettings.language:currency}}
        </div>
    `
})
export class GridPriceRendererComponent implements AgRendererComponent {
    params: any;
    currency: string;
    popInfoValue: string[];

    constructor(public sharedService: SharedService) {

    }

    agInit(params: any): void {
        this.params = params;
        // forced currency vs the one in the data
        this.currency = this.params.currency
            ? this.params.currency
            : this.params.currencyColumnName
            ? params.data[this.params.currencyColumnName]
            : params.data.currency;
        if (this.params.getPopInfo) {
            this.popInfoValue = this.params.getPopInfo(this.params);
        }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
