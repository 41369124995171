import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../../../services/window.service';
import { OrderDialogMode, OrderDialogResult, OrderDialogResultState } from './order.dialog.model';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'app-order-dialog',
    templateUrl: 'order.dialog.component.html'
})
export class OrderDialog implements OnInit {
    doneSubject: Subject<any>;
    params: {
        mode: OrderDialogMode;
        data: any;
        preorder: boolean; // if is preorder
        orderNumberCustomerRequired: boolean;
    };
    maxHeight: number;

    data: any = {};

    dateModel: any;
    selector: number;

    disableSubmit = false;
    public orderNumberCustomerRequired: boolean;
    // BEGIN OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS
    public showTemporaryMessageForSelectedCustomers = false;
    // END OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            const result: OrderDialogResult = {
                state: OrderDialogResultState.Close,
                data: null
            };

            this.doneSubject.next(result);
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private windowService: WindowService,
        private sharedService: SharedService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 120;
        });

        // BEGIN OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS
        const SELECTED_CUSTOMER_IDS = [
            62, // Agrotrak
            16, // Company Kamaz LLC
            7, // LLC EVENLINE
            148, // OOO "Melon Treid"
            21, // Techsnab
        ];
        this.showTemporaryMessageForSelectedCustomers = SELECTED_CUSTOMER_IDS.indexOf(+this.sharedService.user?.representsCustomer?.id) >= 0;
        // END OF TEMPORARY MESSAGE FOR SELECTED CUSTOMERS
    }
    ngOnInit() {
        this.orderNumberCustomerRequired = this.sharedService.params.features.basket.orderNumberCustomerRequired;
        setTimeout(() => {
            if (this.params.mode === OrderDialogMode.Details) {
                this.data = {
                    showOrderComment: this.params.data.showOrderComment, // used when ordering from rfq
                    orderComment: this.params.data.orderComment, // used when ordering from rfq
                    orderNumberCustomer: this.params.data.orderNumberCustomer,
                    orderNote: this.params.data.orderNote,
                };
            }
        }, 0);
    }

    close() {
        const result: OrderDialogResult = { 
            state: OrderDialogResultState.Close,
            data: null
        };
        this.hide(result);
    }

    public order(data) {
        const result: OrderDialogResult = {
            state: OrderDialogResultState.Order,
            data: data
        };
        this.hide(result);
    }

    public showInvalidItems() {
        const result: OrderDialogResult = {
            state: OrderDialogResultState.ShowInvalidItems,
            data: null
        };
        this.hide(result);
    }

    private hide(result: OrderDialogResult) {
        this.disableSubmit = true;
        this.doneSubject.next(result);
        this.bsModalRef.hide();
    }
}
