export enum CCheckEntities {
    'purchaseOrder' = 'purchaseOrder',
    'deliveryOrder' = 'deliveryOrder'
}

export interface CCheck {
    id: number,
    start: string, // Date
    end: string, // Date
    entityName: CCheckEntities
    issuesFound: number,
    // added locally
    url?: string,
    entityTranslated?: string
}

export interface CChecksResponse {
    rows: CCheck[]
    totalCount: number
}

export enum CCheckRecordSource {
    'Erp' = 'E',
    'ECommerce' = 'C'
}

export interface CCheckPurchaseOrder {
    id: number,
    cCheckId: number,
    orderNumberEComm: string,
    customerName: string,
    customerCode: string,
    priceListCode: string,
    orderState: string, 
    lineNumber: number,
    itemState: string,
    productCode: string, 
    stockCode: string,
    packing: string,
    deliveryDate: string,
    amountOrdered: number,
    amountReserved: number, 
    amountDelivered: number,
    amountInvoiced: number,
    unitPrice: number,
    currency: string,
    recordSource: CCheckRecordSource
}

export interface CCheckDeliveryOrder {
    id: number,
    cCheckId: number,
    customerName: string,
    customerCode: string, 
    deliveryNumber: string,
    deliveryNumberERP: string, 
    deliveryDate: string,
    deliveryState: string,
    wfState: string,
    orderNumberEComm: string,
    lineNumber: number, 
    deliveryAmount: number,
    totalPrice: number,
    currency: string,
    itemState: string,
    recordSource: CCheckRecordSource
}
