<div [ngClass]="{'component-outer-container': makeCollapsible}">
    <form #rfqCloseQuestionnaireForm="ngForm" name="rfqCloseQuestionnaireName">
        <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()"
            [attr.role]="makeCollapsible ? 'button': ''" data-target="collapseCloseQuestionnaire">
            <div fxLayout="row">
                <h3 style="width: 100%;"><i class="fa fa-wpforms"></i>
                    <span>{{'RFQ_CLOSE_QUESTIONNAIRE'|translate}}</span>
                    <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }" class="fa" style="float: right"></i>
                </h3>
            </div>
        </div>

        <div [@slideInOut]="isCollapsed" [ngClass]="{'component-body-container': makeCollapsible}" id="collapseCloseQuestionnaire" >

            <h4 *ngIf="showTitle" class="mt-15"><span translate>RFQ_CLOSE_QUESTIONNAIRE_INTRO</span>:</h4>

            <div>
                <input type="checkbox" 
                    [(ngModel)]="rfqCloseQuestionnaire.priceNotSuitable"
                    id="priceNotSuitable" name="priceNotSuitable"
                    (ngModelChange)="onElementChange()" [disabled]="readOnly">
                <label for="priceNotSuitable" translate>RFQ_CLOSE_QUESTIONNAIRE_PRICE_NOT_SUITABLE</label>
            </div>

            <div>
                <input type="checkbox" 
                    [(ngModel)]="rfqCloseQuestionnaire.deliveryDateNotSuitable"
                    id="deliveryDateNotSuitable" name="deliveryDateNotSuitable" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly">
                <label for="deliveryDateNotSuitable" translate>RFQ_CLOSE_QUESTIONNAIRE_DELIVERY_DATE_SUITABLE</label>
            </div>

            <div>
                <input type="checkbox" 
                    [(ngModel)]="rfqCloseQuestionnaire.highMoq"
                    id="highMoq" name="highMoq" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly">
                <label for="highMoq" translate>RFQ_CLOSE_QUESTIONNAIRE_HIGH_MOQ</label>
            </div>

            <div>
                <input type="checkbox" 
                    [(ngModel)]="rfqCloseQuestionnaire.constructionNotSuitable"
                    id="constructionNotSuitable" name="constructionNotSuitable" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly">
                <label for="constructionNotSuitable" translate>RFQ_CLOSE_QUESTIONNAIRE_CONSTRUCTION_NOT_SUITABLE</label>
            </div>

            <div>
                <input type="checkbox" 
                    [(ngModel)]="rfqCloseQuestionnaire.other"
                    id="other" name="other" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly">
                <label for="other" translate>RFQ_CLOSE_QUESTIONNAIRE_OTHER</label>
            </div>

            <div>
                <label for="notes" translate>RFQ_CLOSE_QUESTIONNAIRE_NOTES</label>
                <textarea class="form-control" [(ngModel)]="rfqCloseQuestionnaire.notes" 
                    maxlength="500" id="notes" name="notes" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly"
                ></textarea>
                <!-- <input class="form-control" type="text" placeholder="" maxlength="500"
                    [(ngModel)]="rfqCloseQuestionnaire.notes"
                    id="notes" name="notes" 
                    (ngModelChange)="onElementChange()" [disabled]="readOnly"> -->
            </div>

            <div *ngIf="!isFormValid" translate>RFQ_CLOSE_QUESTIONNAIRE_NOT_VALID</div>

            <div *ngIf="!readOnly" fxLayout="row" class="row-container bigger">
                <div fxFlex="30">
                    <button class="btn-secondary" (click)="cancel()" style="width: 100px;">
                        <span class translate>CANCEL</span>
                    </button>
                </div>
                <div fxFlex="70" fxLayoutAlign="end">
                    <button class="btn-primary" (click)="submit()"
                        [disabled]="!isFormValid"
                        [ngClass]="{'ec-disabled': !isFormValid}">
                        <span class translate>{{submitButtonText}}</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
