import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';

@Injectable()
export class InvoicesItemsGridService {


    constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            {id: 'invoiceNumber', name: 'INVOICE_NUMBER', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'sellerName', name: 'INVOICE_CUSTOMER', checked: true, orderBy: false, orderDirection: 'DESC', width: 120},
            {id: 'issueDate', name: 'DATE_OF_ISSUE', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'taxDate', name: 'INVOICE_TAX_DATE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'productCode', name: 'PRODUCT_CODE', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'productName', name: 'PRODUCT_NAME', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amount', name: 'INVOICE_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'unit', name: 'INVOICE_UNIT', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'unitPrice', name: 'INVOICE_UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'totalPrice', name: 'INVOICE_PRICE_WITHOUT_TAX', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'totalWeight', name: 'INVOICE_WEIGHT_BRUTTO', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'itemNote', name: 'SPECIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'packing', name: 'PACKING', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
        ];

        original = this.tableService.filterSuppressColumns(original, 'invoicesItems');
        const restored = this.sharedService.user.preferences['invoices-itemsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'invoices-itemsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs: any[] =  [
            {
                headerName: this.translateService.instant(this.getColumn('invoiceNumber').name),
                field: 'invoiceNumber',
                width: this.getColumn('invoiceNumber').width,
                suppressSizeToFit: true,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('issueDate').name),
                field: 'issueDate',
                width: this.getColumn('issueDate').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('taxDate').name),
                field: 'taxDate',
                width: this.getColumn('taxDate').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('productCode').name),
                field: 'productCode',
                width: this.getColumn('productCode').width,
                suppressSizeToFit: true,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productName').name),
                field: 'productName',
                width: this.getColumn('productName').width,
                suppressSizeToFit: true,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('amount').name),
                field: 'amount',
                width: this.getColumn('amount').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('unit').name),
                field: 'unit',
                width: this.getColumn('unit').width,
                suppressSizeToFit: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPrice').name),
                field: 'unitPrice',
                width: this.getColumn('unitPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('totalPrice').name),
                field: 'totalPrice',
                width: this.getColumn('totalPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('totalWeight').name),
                field: 'totalWeight',
                width: this.getColumn('totalWeight').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3}
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemNote').name),
                field: 'itemNote',
                width: this.getColumn('itemNote').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('packing').name),
                field: 'packing',
                width: this.getColumn('packing').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) =>
                    params.data.packing ? this.translateService.instant('PACKING_' + params.data.packing) : ''
            },
        ];

        if (this.sharedService.user.representative === 'AS') {
            colDefs.splice(1, 0, {
                headerName: this.translateService.instant(this.getColumn('sellerName').name),
                field: 'sellerName',
                width: this.getColumn('sellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'invoicesItems');
        return colDefs;
    }

    getFilterItems() {
        let filterItems: any[] = [
            {id: 'invoiceNumber', name: 'INVOICE_NUMBER', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            // {id: 'issueDate', name: 'DATE_OF_ISSUE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'productName', name: 'PRODUCT_NAME', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
            {id: 'issueDate', name: 'DATE_OF_ISSUE', type: 'dateRange', valueFrom: '', valueTo: ''},
            {id: 'itemNote', name: 'SPECIFICATION', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
        ];
        if (this.sharedService.user.representative === 'AS') {
            filterItems.unshift({id: 'sellerName', name: 'INVOICE_CUSTOMER', type: 'text', maxLength: 50, value: '', operator: 'likeBoth'});
        }
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: this.sharedService.user.availableAuthorizedSellers});
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: this.sharedService.user.availableCustomers});
        }

        filterItems = this.tableService.filterSuppressColumns(filterItems, 'invoicesItems');
        return filterItems
    }

}

