import { Component, DoCheck, OnDestroy} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { OriginalValue, CellChange } from '@model/table.model';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { Subject, Subscription } from 'rxjs';

interface DropdownItem {
    id: string;
    name: string
}

export interface GridSelectRendererComponentParams {
    itemsGetter?: (params: any) => DropdownItem[], // dropdown items getter
    items?: DropdownItem[], // dropdown items, e.g. [{id: 'A', name: 'jednokusove'}, ...]
    // data: { // data row object
    //     id: number;
    //     originalValues: OriginalValue[];
    //     edited: boolean;
    //     // other custom data attrs of the grid row
    // },
    allowMultipleEdit?: boolean,
    onChange?: (params: any) => void,
    editedIdsObj: any, // {27369: true, 27370: true}
    hideIfNoItems: boolean;
    cellChanged$?: Subject<CellChange>;
}

interface MyParams extends ICellRendererParams, GridSelectRendererComponentParams {
    // aggrid attrs ...
    // api: any, // grid API
    // rowIndex: number, // 6
    // value: string // item.id, e.g. 'A'
    // column: {
    //     colId: string // e.g. 'packing'
    //     // other aggrid attrs ...
    // },
}

@Component({
    selector: 'app-select-renderer',
    template: `	<div style="margin: -3px auto 0 auto;">
                    <button id="single-button" type="button" class="btn btn-secondary"
                        *ngIf="!(params.hideIfNoItems && params.items?.length === 0)"
                        style="font-size: 98%; height: 27px;" [disabled]="disabled"
                        [ngClass]="{'ec-disabled': disabled}" (click)="startEditing()">
                        <span translate>{{selected?.name}}</span><i class="fa fa-caret-down caret"></i>
                    </button>
    			</div>`
})
export class GridSelectRendererComponent implements AgRendererComponent, OnDestroy {
    params: MyParams;
    selected: DropdownItem;
    disabled: boolean;
    subscriptions: Subscription[] = [];

    constructor() {
    }

    agInit(params: MyParams): void {
        this.params = params;
        if (this.params.itemsGetter) {
            this.params.items = this.params.itemsGetter(this.params);
        }
        params.items.forEach(item => {
            if (params.value === item.id) { this.selected = item; }
        });
        // save original values for cancel action
        if (!this.params.data.hasOwnProperty('originalValues')) { this.params.data.originalValues = []; }
        if (this.params.data.originalValues.filter((originalValue: OriginalValue) => {
                    return originalValue.colId === this.params.column['colId'];
                }).length === 0
        ) {
            this.params.data.originalValues.push({colId: this.params.column['colId'], value: params.value});
        } else {
            this.onChange(this.params.data[this.params.column['colId']]);
        }

        this.updateState();

        if (this.params.cellChanged$) {
            this.subscriptions.push(this.params.cellChanged$.subscribe(cellChange => {
                this.updateState(cellChange);
            }));
        }

    }

    private updateState(cellChange?: CellChange): void {
        if (this.params.itemsGetter) {
            this.params.items = this.params.itemsGetter(this.params);
        }
        this.doCheck();
    }

    // ngDoCheck() {
    //     console.log('grid.select.renderer.component ngDoCheck');
    //     if (this.selected && (this.selected.id !== this.params.data[this.params.column['colId']])) {
    //         this.params.items.forEach(item => {
    //             if (this.params.data[this.params.column['colId']] === item.id) { this.selected = item; }
    //         });
    //     }

    //     this.disabled = this.isDisabled();
    // }
    private doCheck() {
        if (this.selected && (this.selected.id !== this.params.data[this.params.column['colId']])) {
            this.params.items.forEach(item => {
                if (this.params.data[this.params.column['colId']] === item.id) { this.selected = item; }
            });
        }

        this.disabled = this.isDisabled();
    }

    onChange(newValue: any) {
        this.params.data[this.params.column['colId']] = newValue;
        for (let i = 0; i < this.params.data.originalValues.length; ++i) {
            if (this.params.data.originalValues[i].colId === this.params.column['colId']) {
                if (this.params.data.originalValues[i].value !== this.params.data[this.params.column['colId']]) {
                    this.params.data.edited = true;
                    if (this.params.editedIdsObj) {
                        this.params.editedIdsObj[this.params.data.id] = true;
                    }
                }
                if (this.params.onChange) {
                    this.params.onChange(this.params);
                }
                break;
            }
        }
    }

    startEditing() {
        this.params.api.startEditingCell({
            rowIndex: this.params.rowIndex,
            colKey: this.params.column['colId'],
            keyPress: null,
            charPress: ''
        });
    }

    isDisabled() {
        if (!this.params.editedIdsObj || this.params.items.length === 1 || this.params.allowMultipleEdit) { return false }
        if (Object.keys(this.params.editedIdsObj).length === 0) { return false; }
        return !(!!this.params.editedIdsObj[this.params.data.id]);
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
