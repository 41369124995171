<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container clickable" style="margin-right: 5px;" routerLink="/{{sharedService.appSettings.language}}/shipments">
                <span translate>SHIPMENTS_SUMMARY</span>
            </span>
            <span class="row-container tab-active clickable">
                <span translate>SHIPMENTS_ITEMS</span>
            </span>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
            <button permissionHide perm="shipments" action="POST" 
                class="btn-primary" (click)="createNew()">
                <img src="../../assets/images/shipment_white.png" style="width: 22px; margin-right: 5px;"/>
                <a translate>EXPEDITION_CREATE</a>
            </button>
        </div>
    </div>

    <div fxLayout="column">
    	<ecm-table [dataGetter]="getShipmentItems" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>