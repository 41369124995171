import { Injectable } from '@angular/core';
import { EcmHttpService } from './http/ecm.http.service';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {
    constructor (private http: EcmHttpService, private sharedService: SharedService) {}

    /**
     * Logs user in, saves tokens to local storage, sets details of logged user
     *
     * @param {string} email - email of user
     * @param {string} password - password of user
     */
    public login (email: string, password: string): Observable<void> {
        return this.http
        .post(`/login`, JSON.stringify({email: email, password: password}))
        .pipe(map((data) => {
            if (data) {
                localStorage.setItem('access_token', data.accessToken);
                localStorage.setItem('id_token', data.idToken);
                localStorage.setItem('refresh_token', data.refreshToken);

                // set user details from JWT ID token
                this.sharedService.setUserFromToken(data.idToken);
            }
            // return data;
        }));
    }

    resetPassword(email: string) {
        return this.http
        .post(`/reset-password`, JSON.stringify({email: email}));
    }

    confirmForgotPassword(email: string, password: string, confirmationCode: string) {
        return this.http
        .post(`/confirm-forgot-password`, JSON.stringify({email: email, password: password, confirmationCode: confirmationCode}));
    }

    setCredentials(email: string, password: string, tempPass: string, givenName: string, familyName: string, phoneNumber: string, businessCondConsent?: number) {
        return this.http.post(`/users/set-credentials`, JSON.stringify(
            { email: email, password: password, tempPass: tempPass, givenName: givenName, familyName: familyName,
                phoneNumber: phoneNumber, businessCondConsent: businessCondConsent}));
    }

}