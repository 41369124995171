import { Component, OnInit, OnDestroy } from '@angular/core';
import { PriceListService } from '../price-list.service';
import { PriceListsItemsGridService } from './price-lists-items.grid.service';
import { ActivatedRoute } from '@angular/router';
import { TableDataGetterParams, TableColumn, ExportObj } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { SharedService } from '@services/shared.service';
import { Subject, Subscription } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';

@Component({
    selector: 'app-price-lists-items',
    templateUrl: './price-lists-items.component.html',
    styleUrls: ['./price-lists-items.component.css']
})
export class PriceListsItemsComponent implements OnInit, OnDestroy {

    totalRows: number;
    filterItems: TableFilterItem[];
    exportObj: ExportObj;
    initialized = false;
    refresh$: Subject<void>;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private priceListService: PriceListService,
        private priceListsItemsGridService: PriceListsItemsGridService
    ) {
        this.getPriceListItems = this.getPriceListItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        // To reload when menu item is clicked but it is already routed to this area
        this.refresh$ = new Subject<void>();
        this.subscriptions.push(this.sharedService.subjectSameAreaClicked.subscribe(() => {
            this.refresh$.next();
        }));

        this.sharedService.translateService.get('PRICE_LIST_ITEMS').subscribe(translation => {
            this.exportObj = {
                fileName: translation,
                export: this.priceListService.exportPriceListsItems.bind(this.priceListService)
            };
        })

        this.initialized = true;
    }

    ngOnInit() {
        setTimeout(() => {
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastPriceListsItemsQueryParams);
        });
    }

    public getPriceListItems(params: TableDataGetterParams): void {
        const query: EcmHttpQueryParams = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastPriceListsItemsQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastPriceListsItemsQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.priceListService.getPriceListItems(query).subscribe(response => {
            this.totalRows = response.totalCount;
            if (!this.filterItems) { // only if not already have it; not to loose fererence of selected
                this.filterItems = this.priceListsItemsGridService.getFilterItems();
            }
            params.successCallback(response.rows, response.totalCount);
        }, err => {
            console.log(err);
            // Also in error to be able to cancel filter
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.priceListsItemsGridService.getFilterItems();
            }
        });
  }

    public getGridOptions(): GridOptions {
        return this.priceListsItemsGridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.priceListsItemsGridService.getColumns();
    }

    public getColumnsDefs(): ColDef[] {
        return this.priceListsItemsGridService.getColumnDefs();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
