<div class="row-container wider" style="width: 100%" *ngIf="params">
    <div>
        <!-- Add discussion -->
        <div fxLayout="row" class="rfq-detail-widgets" style="margin-bottom: 10px">
            <news-discussion [comments]="comments" [total]="comments?.length" [makeCollapsible]="false" [newsId]="params?.data.id"
                (commentsHasChange)="commentsHasChanged($event)" [reload]="reloadComments" [isCollapsed]="false"
                [isModal]="true" style="width: 100%" (edited)="onCommentEdited($event)" [disableEdit]="showNotSavedQuestion"
            ></news-discussion>
        </div>
    </div>
    
    <div fxLayout="row" style="margin-top: 15px;" *ngIf="showNotSavedQuestion" class="fnt-orange">
        <span translate>WARNING_DATA_NOT_SAVED_CONTINUE_ANYWAYS</span> ?
    </div>

    <div fxLayout="row" style="margin-top: 5px;" *ngIf="showNotSavedQuestion">
        <div fxFlex="50" fxLayout="row" class="row-container">
            <button class="btn-secondary" (click)="doNotClose()">
                <span translate>NO</span>
            </button>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" class="row-container">
            <button class="btn-primary" (click)="forceClose()">
                <span translate>YES</span>
            </button>
    
        </div>
    </div>

    <div style="padding-bottom: 15px;" *ngIf="!showNotSavedQuestion">
            <button class="btn-secondary" (click)="close()" style="width: 100px;">
                <span translate>CLOSE</span>
            </button>
    </div>

</div>

