import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { RfqsService } from './rfqs.service'
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridActionsRendererComponent/grid.actions.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridPlusClickRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPlusClickRendererComponent/grid.plus.click.renderer.component';
import { map } from 'rxjs/operators';
import { ColDef } from 'ag-grid-community';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';
import { RfqPermissionService } from './rfq.permission.service';
import { GridPriceRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';

@Injectable()
export class RfqsGridService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

    allowedStatesToExport = ['N', 'O', 'S', 'F'];

    subjectAddToRfq = new Subject();

    constructor(
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private rfqsService: RfqsService,
        private rfqPermissionService: RfqPermissionService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            { id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30 },
            { id: 'rfqNumber', name: 'RFQ_NUMBER', alwaysVisible: true, checked: true, orderDirection: 'ASC', width: 130 },
            { id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'totalPrice', name: 'TOTAL_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
            { id: 'rfqState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'userCreatedSortName', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'assignedSellerName', name: 'ASSIGNED_SELLER_NAME', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'dateClosed', name: 'DATE_OF_CLOSING', checked: true, orderBy: false, orderDirection: 'DESC', width: 120 },
            { id: 'rfqNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
            // { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            // { id: 'attachmentsCount', name: 'ATTACHMENTS', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
        ];

        original = this.tableService.filterSuppressColumns(original, 'rfqs')
            .filter(colDef => this.rfqPermissionService.isRfqColumnAvailable(colDef.id));
        const restored = this.sharedService.user.preferences['rfqsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    getGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'rfqsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.sharedService.user.preferences['rfqsSelection'], observable: this.getSelectedSubject(),
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.sharedService.user.preferences['rfqsSelection'],
                    observable: this.getItemsSelectedSubject()
                },
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('rfqNumber').name),
                field: 'rfqNumber',
                width: this.getColumn('rfqNumber').width,
                cellRendererFramework: GridLinkRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerName').name),
                field: 'customerName',
                width: this.getColumn('customerName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this.translateService.instant(this.getColumn('totalPrice').name),
                field: 'totalPrice',
                width: this.getColumn('totalPrice').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'totalPriceCurrency' },
            },
            {
                headerName: this.translateService.instant(this.getColumn('rfqState').name),
                field: 'rfqState',
                width: this.getColumn('rfqState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'RFQ_STATE_',
                    renderColors: {
                        gray: 'P',
                        red: 'N',
                        yellow: 'O',
                        green: 'S',
                        black: 'F'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('userCreatedSortName').name),
                field: 'userCreatedSortName',
                width: this.getColumn('userCreatedSortName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                    headerName: this.translateService.instant(this.getColumn('assignedSellerName').name),
                    field: 'assignedSellerName',
                    width: this.getColumn('assignedSellerName').width,
                    cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateClosed').name),
                field: 'dateClosed',
                width: this.getColumn('dateClosed').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('rfqNote').name),
                field: 'rfqNote',
                width: this.getColumn('rfqNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('rfqNote').checked,
            },
            /*{
                headerName: '',
                field: 'postsCount',
                maxWidth: 50,
                minWidth: 50,
                cellRendererFramework: GridCommentRendererComponent,
                cellRendererParams: {
                    itemArea: 'rfq', idAttrName: 'id', loadForEachItem: true, showOnlyEmptyChildTableKeyItems: true,
                    isHidden: params => params.data.rfqState === 'P'
                                POZNAMKA
                                showAllComments: true, commented out because not all comments are shown just comments to header of request not items of request
                                getItemLabel: (item) => item.rfqNumber ? item.rfqNumber : this.translateService.instant('ROW').toLowerCase() + ' ' +
                    item.displayItem.lineNumber
                },
                width: this.getColumn('postsCount').width,
            },
            {
                headerName: '',
                field: 'attachmentsCount',
                maxWidth: 50,
                minWidth: 50,
                cellRendererFramework: GridAttachmentRendererComponent,
                cellRendererParams: {
                    itemArea: 'rfq', idAttrName: 'id',
                    getAttachments: (params, id) => this.rfqsService.getAttachments(id),
                    getUploadUrl: (params, fileName: string) => this.rfqsService.getAttachmentUploadUrl(fileName, params.data.id),
                    deleteAttachment: (params, entityId: number, attachmentId: number, lineNumber?: number) => this.rfqsService.deleteAttachment(params.data.id, attachmentId),
                    getDownloadUrl: (params, attachmentId: number, lineNumber?: number) => this.rfqsService.getAttachmentDownloadUrl(params.data.id, attachmentId, lineNumber),
                    getAllowEdit: (params) => params.data.actions ? params.data.actions.indexOf('INSERT_ITEM') > -1 : false
                },
                width: this.getColumn('postsCount').width,
            },*/
            {
                headerName: this.translateService.instant(this.getColumn('actions').name),
                field: 'actions',
                width: this.getColumn('actions').width,
                minWidth: this.getColumn('actions').width,
                cellRendererFramework: GridActionsRendererComponent,
                cellRendererParams: {
                    service: this.rfqsService,
                    namePartGetter: function (data) {
                        return data.rfqNumber
                    },
                    itemArea: 'rfq', idAttrName: 'id',
                    // COMMENTS IN ACTIONS
                    showComments: 'true',
                    showCommentsRfq: 'true',
                    stateAttrName: 'rfqState',
                    allowOnStates: this.allowedStatesToExport,
                    loadForEachItem: true,
                    showOnlyEmptyChildTableKeyItems: true,
                    isHidden: params => params.data.rfqState === 'P',
                    // ATTACHMENTS IN ACTIONS
                    showAttachments: 'true', getAttachments: (params, id) => this.rfqsService.getAttachments(id).pipe(map(data => { data = data.filter(item => item.childId === null); return data; })),
                    getUploadUrl: (params, fileName: string) => this.rfqsService.getAttachmentUploadUrl(fileName, params.data.id),
                    deleteAttachment: (params, entityId: number, attachmentId: number, lineNumber?: number) => this.rfqsService.deleteAttachment(params.data.id, attachmentId),
                    getDownloadUrl: (params, attachmentId: number, lineNumber?: number) => this.rfqsService.getAttachmentDownloadUrl(params.data.id, attachmentId, lineNumber),
                    getAllowEdit: (params) => params.data.actions ? params.data.actions.indexOf('INSERT_ITEM') > -1 : false
                },
                sortable: false,
            }
        ];

        return this.tableService.filterSuppressColumns(colDefs, 'rfqs')
            .filter(colDef => this.rfqPermissionService.isRfqColumnAvailable(colDef.field));
    }

    public getFilterItems(personsCreated: { id: string, name: string }[] = [], assignedSellerNames: { id: string, name: string }[] = []): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            ...(this.rfqPermissionService.isRfqColumnAvailable('customerName')
                ? [{ 
                    id: 'customer.id', 
                    name: 'CUSTOMER', 
                    type: TableFilterItemTypes.multiselect, 
                    search: true, 
                    value: '', 
                    values: this.sharedService.user.availableCustomers.map(customer => 
                        ({...customer, id: customer.id.toString()}))
                }]
                : []
            ),
            ...(this.rfqPermissionService.isRfqColumnAvailable('assignedSellerName')
                ? [{ id: 'assignedSellerName', name: 'ASSIGNED_SELLER_NAME', type: TableFilterItemTypes.multiselect, search: true, value: '', values: assignedSellerNames }]
                : []
            ),
            { id: 'rfqNumber', name: 'RFQ_NUMBER', type: TableFilterItemTypes.text, maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'rfqNote', name: 'NOTE', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { 
                id: 'dateCreated', 
                name: 'DATE_OF_CREATION', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: '',
                showYearPicker: true
            },
            { id: 'personCreated', name: 'PERSON_CREATED', type: TableFilterItemTypes.multiselect, search: true, value: '', values: personsCreated },
        ];

        filterItems.push({
            id: 'rfqState', name: 'STATUS', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
            customDotColors: { gray: 'P', red: 'N', yellow: 'O', green: 'S', black: 'F' },
            values: [
                ...(this.rfqPermissionService.isElementAvailable('rfqItemStateFilterStateInProgress') 
                    ? [{ id: 'P', name: 'RFQ_STATE_P', dotId: 'P', default: true }] 
                    : []
                ),
                { id: 'N', name: 'RFQ_STATE_N', dotId: 'N', default: true },
                { id: 'O', name: 'RFQ_STATE_O', dotId: 'O', default: true },
                { id: 'S', name: 'RFQ_STATE_S', dotId: 'S', default: false },
                { id: 'F', name: 'RFQ_STATE_F', dotId: 'F', default: false }],
            bulkCheckbox: {
                title: 'SHOW_OPENED_STATES_ONLY',
                ids: [
                    ...(this.rfqPermissionService.isElementAvailable('rfqItemStateFilterStateInProgress') 
                        ? ['P'] 
                        : []
                    ), 'N', 'O']
            }
        });

        filterItems = this.tableService.filterSuppressColumns(filterItems, 'rfqs');
        return filterItems;
    }

    getInAddDialogColumns(forceOriginal?: boolean): any[] {
        const original = [
            { id: 'rfqNumber', name: 'RFQ_NUMBER', alwaysVisible: true, checked: true, orderDirection: 'ASC', width: 130 },
            { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'userCreatedSortName', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
        ];

        const restored = this.sharedService.user.preferences['catalogueAddToRfqDialogTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getInAddDialogGridOptions() {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(0, 'catalogueAddToRfqDialogTablePageSize', headerClassFunc);
        gridOptions.defaultColDef.headerComponentParams = {
            columnsObjects: this.getInAddDialogColumns(),
            columnsPreferenceKey: 'catalogueAddToRfqDialogTableColumns',
            sharedService: this.sharedService,
            suppressToUrl: true
        },
        gridOptions.columnDefs = this.getInAddDialogColumnDefs();
        return gridOptions;
    }

    getInAddDialogColumnDefs() {
        const colDefs: any[] = [
            {
                headerName: this.translateService.instant(this.getColumn('rfqNumber').name),
                field: 'rfqNumber',
                width: this.getColumn('rfqNumber').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this.translateService.instant(this.getColumn('userCreatedSortName').name),
                field: 'userCreatedSortName',
                width: this.getColumn('userCreatedSortName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: '',
                field: 'addRfq', width: 30, minWidth: 30, suppressSizeToFit: true,
                sortable: false,
                cellRendererFramework: GridPlusClickRendererComponent,
                cellRendererParams:  {
                    onClick: (params) => {
                        this.subjectAddToRfq.next(params.data);
                    }
                },
            },
        ];
        return colDefs;
    }
}

