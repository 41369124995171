<div class="renderer">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 fxFlex="noshrink"><i class="fa fa-share-alt"></i> <span translate>SPLIT</span></h3>
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="space-around">
            <basket-availability-renderer [availabilityState]="basketItem?.availabilityState" [usePopover]="false" *ngIf="!hideAvailabilityState"></basket-availability-renderer>
            <div>
                <span style="font-weight: bold;">{{basketItem?.eCommProductId}}</span>
                <div>
                    <span translate>{{'PACKING_' + basketItem?.packing}}</span>
                </div>
            </div>
            <div>
                <span>{{basketItem?.amountOrdered | localnumber:sharedService.appSettings.language}}</span>
                <div>
                    <span translate>AMOUNT_MOQ</span>: <span>{{basketItem?.amountInPacking | localnumber:sharedService.appSettings.language}}</span>
                </div>
            </div>
            <div>
                <span>{{basketItem?.deliveryDate  | localdate:sharedService.appSettings.language}}</span>
            </div>
        </div>
    </div>

    <table>
        <thead>
            <tr>
                <th class="availability" *ngIf="!hideAvailabilityState" translate>AVAILABILITY</th>
                <th class="amount" translate>AMOUNT_PACKAGES</th>
                <th class="amount" translate>PIECES</th>
                <th class="delivery-date" translate>DATE_OF_DELIVERY</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of basketItem?.availabilityFulfillings; let odd=odd; let even=even;" [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">
                <td class="availability" *ngIf="!hideAvailabilityState"><basket-availability-renderer [availabilityState]="row.availabilityState" [usePopover]="false"></basket-availability-renderer></td>
                <td class="amount-packages">{{row.amountPackages | localnumber:sharedService.appSettings.language:false:2}}</td>
                <td class="amount">{{row.amount | localnumber:sharedService.appSettings.language}}</td>
                <td class="delivery-date"><span>{{row.deliveryDate | localdate:sharedService.appSettings.language}}</span>
                </td>
                <!-- <td class="delivery-date"><span *ngIf="row.availabilityState !== 'PARTIALLY_FULFILLED'">{{row.deliveryDate  | localdate:sharedService.appSettings.language}}</span></td> -->
            </tr>
        </tbody>
    </table>

    <div fxLayout="row" style="margin-top: 10px;">
        <div fxFlex="50" fxLayout="row" class="row-container">
            <button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
                <span translate>CLOSE</span>
            </button>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" class="row-container">
            <busy-indicator *ngIf="busy"></busy-indicator>
            <button class="btn-primary" (click)="split()" [disabled]="busy" [ngClass]="{'ec-disabled': busy}">
                <i class="fa fa-share-alt"></i>
                <span translate>SPLIT</span>
            </button>
        </div>
    </div>
</div>