import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/toastService/toast.service';
import { WindowService } from '../../../services/window.service';
import { PriceListService } from '../price-list.service';
import { SavePriceListResponse } from '@app/model/priceList.model';

export interface PriceListImportErrorDialogResult {
    rows: {[key: string]: any}[]
}

enum ComponentModes { 
    WithProductCode, WithBrandCodeAndProductName
}

@Component({
    selector: 'app-price-list-import-error-dialog',
    templateUrl: 'price-list-import-error-dialog.component.html'
})
export class PriceListImportErrorDialogComponent implements OnInit {
    // Incomming data
    public saveErrorData: SavePriceListResponse;
    public modalClosed$: Subject<any>;

    // Own data
    public maxHeight: number;
    public somethingChanged = false;
    public componentMode: ComponentModes;
    public componentModes = ComponentModes;

    // Initialize selector is closed
    private selector: number;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.modalClosed$.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private toastService: ToastService,
        private windowService: WindowService,
        private priceListService: PriceListService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
        this.componentMode = this.priceListService.isColumnHidden('productCode')
            ? ComponentModes.WithBrandCodeAndProductName : ComponentModes.WithProductCode;

    }

    public ngOnInit(): void {
        // setTimeout(() => {
        //     this.saveErrorData.errors.forEach(error => {
        //         if (error.row.deliveryDate && error.row.deliveryDate.length !== 0) {
        //             const momentDate = moment(error.row.deliveryDate);
        //             error.row.deliveryDateModel = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }
        //         }
        //         error.row.datePickerOptions = this.getDatePickerOptions(error);
        //     });
        // }, 0);
    }

    public copyErrorsToClipboard(): void {
        // caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    private copy(): void {
        let tmpEl;
        tmpEl = document.createElement('textarea');

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = 0;
        tmpEl.style.position = 'fixed';
        tmpEl.style.pointerEvents = 'none';
        tmpEl.style.zIndex = -1;

        let value = '';
        // fill it with your HTML
        this.saveErrorData.errors.forEach(errorItem => {
            if (this.componentMode === ComponentModes.WithBrandCodeAndProductName) {
                value += errorItem.row.brandCode + '\t';
                value += errorItem.row.productName + '\t';
            }
            if (this.componentMode === ComponentModes.WithProductCode) {
                value += errorItem.row.productCode + '\t';
            }
            value += errorItem.row.unitPrice + '\t';
            for (const key of Object.keys(errorItem.error)) {
                value += this.translate.instant(errorItem.error[key]) + ' ';
            }
            value += '\n';
        });

        // // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(value))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand('copy');

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    public onChange(item, attrChangedName): void {
        this.somethingChanged = true;
        delete item.error[attrChangedName];

        if (attrChangedName === 'unitPrice') {
            if (isNaN(item.row.unitPrice)) {
                item.error[attrChangedName] = 'Invalid data type: unitPrice';
            }
        }

        if (attrChangedName === 'brandCode' || attrChangedName === 'productName') {
            delete item.error.productNameBrandCode;
        }
    }

    public addAgain(): void {
        const rows = [];
        this.saveErrorData.errors.forEach((error) => {
            delete error.row.productNameBrandCode;
            rows.push(error.row);
        });
        this.modalClosed$.next({ rows: rows });
        this.bsModalRef.hide();
    }
}
