import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Subject, forkJoin } from 'rxjs';
import { RfqsService } from '../../../../services/rfq/rfqs.service';
import { RfqService } from '../../../../services/rfq/rfq.service';
import { RfqPermissionService } from '../../../../services/rfq/rfq.permission.service';
import { ToastService } from '../../../../services/toastService/toast.service';
import { SharedService } from '../../../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { RfqDetailSplitDialogService } from '../../../rfqComponent/rfqDetailComponent/RfqDetailSplitDialogComponent/rfq.detail.split.dialog.service';
import { RfqDetailJournalDialogService } from '../../../rfqComponent/rfqDetailComponent/RfqDetailJournalDialogComponent/rfq.detail.journal.dialog.service';
import { QuestionDialogService } from '../../../../components/questionDialogComponent/question.dialog.service';
import { Rfq, RfqToInsert } from '../../../../model/rfq.model';
import * as moment from 'moment';

@Component({
    selector: 'actions-renderer',
    template: ` <div style="font-size: 20px; margin-top: -2px;">
                    <ng-template #toolTemplateSplit>{{'SPLIT_SELECTED' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateDelete>{{'DELETE_SELECTED' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateConfirm>{{'RFQ_CONFIRM_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateAccept>{{'RFQ_ACCEPT_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateCancelAccepted>{{'RFQ_CANCEL_ACCEPTED_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateOrder>{{'RFQ_ORDER_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateCancel>{{'RFQ_CANCEL_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateReopen>{{'RFQ_REOPEN_ITEM' | translate | lowercase}}</ng-template>
                    <ng-template #toolTemplateJournal>{{'JOURNAL' | translate | lowercase}}</ng-template>
                    <i class="clickable fa fa-share-alt" style="margin-right: 5px; opacity: 0.9" (click)="splitRow()"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && showSplit &&  buttonsVisibility.SPLIT_ITEM" [popover]="toolTemplateSplit" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-trash" style="margin-right: 5px; opacity: 0.9" (click)="deleteRow()"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.DELETE_ITEM" [popover]="toolTemplateDelete" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-check-circle fnt-blue-lighter" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('CONFIRM_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.CONFIRM_ITEM" [popover]="toolTemplateConfirm" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-check-circle fnt-lime" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('ACCEPT_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.ACCEPT_ITEM" [popover]="toolTemplateAccept" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-times-circle fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('CANCEL_ACCEPTED_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.CANCEL_ACCEPTED_ITEM" [popover]="toolTemplateCancelAccepted" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-cart-plus" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('ORDER_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.ORDER_ITEM" [popover]="toolTemplateOrder" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-ban" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('CANCEL_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.CANCEL_ITEM" [popover]="toolTemplateCancel" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-undo" style="margin-right: 5px; opacity: 0.9" (click)="makeActionOnRow('REOPEN_ITEM')"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && buttonsVisibility.REOPEN_ITEM" [popover]="toolTemplateReopen" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                    <i class="clickable fa fa-history" style="margin-right: 5px; opacity: 0.9" (click)="openJournal()"
                        *ngIf="!params.data.edited && getEditedCount() === 0 && isJournalActionAvailable()" [popover]="toolTemplateJournal" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>

                    <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()"
                        *ngIf="params.data.edited && this.params.isRowValid && this.params.isRowValid(params.data)"></i>
                    <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel(true)"
                        *ngIf="params.data.edited"></i>
                </div>`
})
export class GridRfqActionsRendererComponent implements AgRendererComponent {
    // isRowValid
    params: any;
    subjectReloadCurrent: Subject<any>;
    buttonsVisibility: any = {};
    showSplit: boolean;
    cancelSubscribtion;

    constructor(
        private rfqsService: RfqsService,
        private rfqPermissionService: RfqPermissionService,
        private toastService: ToastService,
        private sharedService: SharedService,
        private translateService: TranslateService,
        private rfqDetailSplitDialogService: RfqDetailSplitDialogService,
        private rfqDetailJournalDialogService: RfqDetailJournalDialogService,
        private rfqService: RfqService,
        private questionDialogService: QuestionDialogService
    ) {
        this.subjectReloadCurrent = rfqsService.getReloadCurrentSubject();
    }

    agInit(params: any): void {
        this.params = params;
        this.buttonsVisibility = this.getButtonsVisibility(this.params.data.actions);

        if (this.params.data.rfqState === 'O' && (this.params.data.itemState === 'O' || this.params.data.itemState === 'F') &&
            this.params.data.amountRequired > 1) {
            this.showSplit = true;
        }

        this.cancelSubscribtion = this.params.cancelObservable.subscribe(() => {
            this.cancel(false);
        });
    }

    deleteRow() {
        const doDelete = () => {
            this.rfqsService.deleteRfqItems(this.params.data.rfqId, [{ id: this.params.data.id }])
                .subscribe(() => {
                    // this.params.observable.next({ id: this.params.data.id, rowWeightDif: (this.params.data.amountOrdered * (-1)) * this.params.data.weight, rowPriceDif: (this.params.data.amountOrdered * (-1)) * this.params.data.unitPrice });
                    // delete this.params.selection.ids[this.params.data.id];
                    this.subjectReloadCurrent.next();
                    this.params.reloadAttachmentsObservable.next();
                }, err => console.log(err));
        };

        if (this.params.data.attachmentsCount > 0) { // question when item has some attachments
            this.questionDialogService.confirm(
                {
                    message: 'DELETE_ITEM_MESSAGE',
                    question: 'DELETE_RFQ_ITEM_WITH_ATTACHMENTS_QUESTION',
                    suppressQuestionMark: true,
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                res => {
                    if (res == 'confirm') {
                        doDelete();
                    }
                }
            );
        } else {
            doDelete();
        }
    }

    splitRow() {
        this.rfqDetailSplitDialogService.confirm({
            message: this.translateService.instant('SPLIT_SELECTED_ITEMS_TITLE'),
            question: this.translateService.instant('SPLIT_SELECTED_ITEMS_DESCRIPTION'),
            suppressQuestionMark: true,
            primary: 'SPLIT_SELECTED',
            secondary: 'CANCEL',
            currentData: this.params.data
        }).subscribe(res => {
            if (typeof res == 'object') {
                let oldRow = Object.assign({}, this.params.data);
                let newRow = Object.assign({}, this.params.data);

                oldRow.amountRequired = this.params.data.amountRequired; // - parseInt(res.textValueNew);
                newRow.amountRequired = parseInt(res.textValueNew, 10);
                newRow.unitPriceRequired = oldRow.unitPriceRequired;

                let resObs = forkJoin(
                    this.rfqsService.updateRfqItem(this.params.data.rfqId, oldRow, this.params.getRfq),
                    this.rfqsService.createRfqItem(this.params.data.rfqId, newRow, this.params.getRfq, Object.keys(new RfqToInsert))
                );
                let result = resObs.subscribe(
                    res => {
                        this.toastService.addSuccess(this.sharedService.translateService.instant('SPLIT_DONE_SUCCESS'));
                        if (this.getEditedCount() > 0) {
                            this.params.api.redrawRows();
                        } else {
                            this.subjectReloadCurrent.next();
                        }
                    }, error => {
                        this.toastService.addError(this.sharedService.translateService.instant('SPLIT_DONE_ERROR'));
                        console.log(error);
                    }
                );
            }
        });
    }

    public makeActionOnRow(action: string): void {
        if (action === 'CONFIRM_ITEM') {
            if (this.isItemValidityValid()) {
                this.rfqsService.rfqItemsAction(this.params.data.rfqId, [this.params.data.id], action)
                    .subscribe(() => {
                        this.subjectReloadCurrent.next();
                    }, err => console.log(err));
            } else {
                this.toastService.addError(this.sharedService.translateService.instant('RFQ_ITEM_VALIDITY_VALIDATION_ERROR', 9000));
            }
        } else if (action === 'ORDER_ITEM') {
            // this.rfqService.orderItemAndCloseRfq(this.params.data.rfqId, [this.params.data], false, this.subjectReloadCurrent);
            this.rfqService.orderItem(this.params.data.rfqId, this.params.data, this.subjectReloadCurrent);
        } else {
            this.rfqsService.rfqItemsAction(this.params.data.rfqId, [this.params.data.id], action)
                .subscribe(() => {
                    this.subjectReloadCurrent.next();
                }, err => console.log(err));
        }
    }

    isItemValidityValid(): boolean {
        if (this.params.data.itemValidity === '' || this.params.data.itemValidity === null || typeof this.params.data.itemValidity === 'undefined') {
            return false;
        }
        const val = moment(this.params.data.itemValidity).utc();
        const now = moment().utc();
        if (val < now) {
            return false;
        }
        return true;
    }
    save() {
        (this.isNewRow()
            ? this.rfqsService.createRfqItem(this.params.data.rfqId, this.getChangedData(this.params.data), this.params.getRfq)
            : this.rfqsService.updateRfqItem(this.params.data.rfqId, this.params.data, this.params.getRfq)
        ).subscribe(result => {
            // console.log(result.toInsert.errors);
            if (result.toInsert.errors && result.toInsert.errors.length > 0) {
                result.toInsert.errors.map(item => {
                    // console.log(Object.keys(item.error));
                    const errorMessage = Object.keys(item.error)[0];
                    switch (true) {
                        case (errorMessage === 'amountRequired'):
                            this.toastService.addError(this.sharedService.translateService.instant('INVALID_DATA_TYPE:_AMOUNTREQUIRED_ERROR'));
                            break;
                        case (errorMessage === 'unitPriceRequired'):
                            this.toastService.addError(this.sharedService.translateService.instant('INVALID_DATA_TYPE:_UNITPRICEREQUIRED_ERROR'));
                            break;
                        case (errorMessage === 'dateRequired'):
                            this.toastService.addError(this.sharedService.translateService.instant('INVALID_DATA_TYPE:_UNITPRICEREQUIRED_ERROR'));
                            break;
                        default:
                            this.toastService.addError(this.sharedService.translateService.instant('ERROR_OCCURED'));
                            break;
                    }
                }
                );
            } else {
                if (this.isNewRow()) {
                    this.toastService.addSuccess('PRODUCT_ADDED_TO_RFQ_GENERAL');
                }
            }
            // update som attributes from response
            if (this.params.data.id in result.toUpdate) {
                const resultObj = result.toUpdate[this.params.data.id];
                this.params.data.rfqProductAS = resultObj.rfqProductAS;
                this.params.data.unitPriceCatalogue = resultObj.unitPriceCatalogue;
            }

            this.params.data.originalValues.forEach(item => {
                item.value = this.params.data[item.colId];
            });
            this.params.data.edited = false;
            delete this.params.editedIdsObj[this.params.data.id];

            if (this.getEditedCount() > 0) {
                this.params.api.redrawRows();
            } else {
                this.subjectReloadCurrent.next();
            }
        }, err => console.log(err));
    }

    /**
     * Returns only attributes, which are different from originalValues
     * originalValues: [
            {colId: "rfqProduct", value: <original value>},
            {colId: "amountRequired", value: <original value>}
            ...
        ]
     */
    getChangedData(data) {
        // console.log(data);
        return data.originalValues.reduce((acc, row) => data[row.colId] === row.value
            ? acc : Object.assign({}, acc, { [row.colId]: data[row.colId] }),
            {});
    }

    cancel(redraw) {
        delete this.params.editedIdsObj[this.params.data.id];

        if (!this.isNewRow()) {  // Existing row
            this.params.data.originalValues.forEach(item => {
                this.params.data[item.colId] = item.value;
            });
            this.params.data.edited = false;
            if (redraw) {
                this.params.api.redrawRows();
            }
        } else {  // a new, not saved row
            this.params.api.applyTransaction({ remove: [this.params.data] });
        }
    }

    isNewRow() {
        return this.params.data.id < 0;  // new items have negative id
    }

    getEditedCount() {
        return Object.keys(this.params.editedIdsObj).length;
    }

    private getButtonsVisibility(actions: string[]) {
        return actions ? actions.reduce((acc, action) => Object.assign({}, acc, { [action]: this.rfqPermissionService.hasPermissionItemsAction() }), {}) : {};
    }

    openJournal() {
        this.rfqDetailJournalDialogService.confirm({
            rfqId: this.params.data.rfqId,
            lineNumber: this.params.data.lineNumber
        }).subscribe(() => { });
    }

    public isJournalActionAvailable(): boolean {
        return this.rfqPermissionService.isElementAvailable('journal');
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
