import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { OrdersService } from '../../../../services/order/orders.service';
import { SharedService } from '../../../../services/shared.service';
import { Subject } from 'rxjs';
import { PersonCreated } from '@app/model/personCreated.model';

@Component({
  selector: 'orders-summary',
  templateUrl: 'orders.summary.component.html'
})
export class OrdersSummaryComponent  { 

	totalRows:          number;
	filterItems: 		any[];
	personsIssued:      PersonCreated[] = [];
	subjectRefresh = new Subject<any>();
	subjectAllOrdersSelected: Subject<any>;
	bulk: 				{update?: Function, items: {id: string, name: string, type: string, iconClass?: string, value?: any, values?: {id: string, name:string}[], click?: Function}[]};
	exportObj: 			{fileName: string, export: Function};

	busy:				boolean;
	initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table

	constructor(private router: Router, 
				private route: ActivatedRoute,
				private ordersService: OrdersService,
				public sharedService: SharedService) {
		this.subjectRefresh = new Subject<any>();
		this.subjectAllOrdersSelected = ordersService.getItemsSelectedSubject();

		this.getOrders = this.getOrders.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);

		this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
			this.exportObj = {
				fileName: this.sharedService.translateService.instant('ORDERS_SUMMARY'), 
				export: this.ordersService.exportAll.bind(this.ordersService)
			};
		});

		let exportFunction = function() {
			let selection = this.sharedService.user.preferences['ordersSelection']
			this.ordersService.export(
				selection.all ? null : Object.keys(selection.ids), 
				this.sharedService.translateService.instant('ORDERS_SUMMARY'), 'CSV', true);
		};
		this.bulk = {
			items: [
				{id: 'exportToCSV', name: 'EXPORT_TO_CSV', type: 'button', iconClass: 'fa-download', click: exportFunction.bind(this)}, 
			]
		}
		if (!this.sharedService.user.preferences.hasOwnProperty('ordersSelection')) {
            this.sharedService.user.preferences['ordersSelection'] = {ids: {}, all: false, visible: false};
        }

		// to reload when menu item is clicked but it is already routed to this area
		this.sharedService.subjectSameAreaClicked.subscribe(res => {
			this.subjectRefresh.next();
		});
		
        this.initialized = true;
	}

	queryParamsCheck() {
		// set default filter if not filter already in query
		if (!this.route.snapshot.queryParams['orderState.value'] && !this.sharedService.user.preferences[this.sharedService.getUserPreferenceKey('Filter', '')]) {
			this.sharedService.lastOrdersSummaryQueryParams['orderState.operator'] = 'in';
			this.sharedService.lastOrdersSummaryQueryParams['orderState.value'] = 'X,W,N,C';
			this.router.navigate([], {queryParams: this.sharedService.lastOrdersSummaryQueryParams});
		}
	}

	ngOnInit() {
		this.queryParamsCheck();
		var this_ = this;
		setTimeout(function() {
			// navigate with orderby parameter
			this_.sharedService.navigateWithOrderby(this_.getColumns(), 
				this_.route.snapshot.queryParams, this_.sharedService.lastOrdersSummaryQueryParams);
			}, 0);
	}

	/**
	 * Loads list
	 * @param params - params of loading data from grid
	 */
	getOrders(params) {
		// this.queryParamsCheck();

		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};

		this.sharedService.lastOrdersSummaryQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastOrdersSummaryQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

		this.ordersService.getOrders(query)
		.subscribe(data => {
			this.totalRows = data.total;
			this.personsIssued = data.personCreatedSYS;
			if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
			params.successCallback(data.rows, data.total);
			this.busy = false;
        }, err => {
        	console.log(err);
        	// also in error to be able to cancel filter
        	if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
        	this.busy = false;
        });
	}
	getGridOptions() {
		return this.ordersService.getOrdersGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.ordersService.getFilterItems(this.personsIssued);
	}
	getColumns() {
		return this.ordersService.getColumns();
	}
	getColumnsDefs() {
		return this.ordersService.getColumnDefs();
	}

	printExportOrders() {
		console.log("printExportOrders");
	}
}