import { Component } from '@angular/core';
import { SharedService } from '../../../services/shared.service';


@Component({
  selector: 'contact',
  templateUrl: 'contact.component.html',
  // styleUrls:  ['./contact.component.css']
})
export class ContactComponent  { 
	
	constructor(public sharedService: SharedService) {
	}
}