import { Component, ViewChild, ElementRef, OnInit, EventEmitter, Output, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { Subject } from 'rxjs';

declare var Jodit: any;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'wysiwyg',
    templateUrl: 'wysiwyg.component.html',
    styleUrls:  ['./wysiwyg.component.scss']
})

export class WysiwygComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('wysiwyg', { static: true }) wysiwyg: ElementRef;
    @Input() valueGetter;
    @Input() config; // optional config
    @Input() reload: Subject<any>;       // optional reload subject - reloads newsDetail when next() is called on this from outside
    @Input() readOnly: boolean;
    @Output() wysiwygText: EventEmitter<any> = new EventEmitter<any>();
    wysiwygModel = null;
    reloadSubscription;
    editor = null;
    joditConfig;
    hasChanged = false; // override first data load. in MS EDGE causing change event trigger

    constructor(private router: Router,
        private route: ActivatedRoute,
        private sharedService: SharedService) {

        this.joditConfig = {
            'autofocus': false,
            'toolbarButtonSize': 'small',
            'defaultMode': '1',
            'saveHeightInStorage': true,
            'saveModeInStorage': true,
            'height': 400,
            'language': this.sharedService.appSettings.language,
            'buttons': 'source,bold,italic,|,ul,ol,|,|,fontsize,brush,paragraph,,image,video,table,link,|,align,undo,redo,hr,|,fullsize',
            'uploader': {
                'insertImageAsBase64URI': true,
                // url: 'https://woh2ndzx5d.execute-api.eu-west-1.amazonaws.com/development/news-add',
                // format: 'json',
            }
        };
    }

    ngOnInit() {
        if (this.valueGetter && this.readOnly) {
            this.wysiwyg.nativeElement.value = this.valueGetter;
        }
        if (this.valueGetter && !this.readOnly) {
            this.wysiwyg.nativeElement.value = this.valueGetter;
            // this.wysiwygText.emit( this.valueGetter);
        }

        this.sharedService.translateService.get('SUNDAY').subscribe(() => {
            this.setJoditTranslations();
            this.editor = new Jodit(this.wysiwyg.nativeElement, this.config ? this.config : this.joditConfig);
        });
    }
    onChange(event: any) {
        if (this.hasChanged)  {
            if (!this.readOnly) {
                this.wysiwygText.emit(event.target.value);
                // this.wysiwygModel = value;
            }
        } else {
            this.hasChanged = true;
        }

    }
    ngAfterViewInit() {
        if (this.reload) {
            this.reloadSubscription = this.reload.subscribe(() => {
                setTimeout(() => {
                    this.editor.destruct();
                    this.wysiwyg.nativeElement.value = this.readOnly ? '' : this.valueGetter;
                    this.hasChanged = false;
                    this.ngOnInit();

                    // if (this.readOnly) {
                    //     this.wysiwyg.nativeElement.value = '';
                    //     this.editor.destruct();
                    //     this.editor = null;
                    // }
                    // this.ngOnInit();
                }, 0);
            })
        }
    }

    setJoditTranslations() {
        Jodit.lang[this.sharedService.appSettings.language] = {
            'Chars: %d': this.sharedService.translateService.instant('CHARS') + ': %d',
            'Words: %d': this.sharedService.translateService.instant('WORDS') + ': %d',
            'Change mode': this.sharedService.translateService.instant('CHANGE_MODE'),
            'Insert Unordered List': this.sharedService.translateService.instant('INSERT_UNORDERED_LIST'),
            'Insert Ordered List': this.sharedService.translateService.instant('INSERT_ORDERED_LIST'),
            'Font size': this.sharedService.translateService.instant('FONT_SIZE'),
            'Fill color or set the text color': this.sharedService.translateService.instant('FILL_COLOR_OR_SET_TEXT_COLOR'),
            'Background': this.sharedService.translateService.instant('BACKGROUND'),
            'Insert format block': this.sharedService.translateService.instant('INSERT_FORMAT_BLOCK'),
            'Insert youtube/vimeo video': this.sharedService.translateService.instant('INSERT_YOUTUBE_VIMEO_VIDEO'),
            'Insert Image': this.sharedService.translateService.instant('INSERT_IMAGE'),
            'Insert table': this.sharedService.translateService.instant('INSERT_TABLE'),
            'Insert link': this.sharedService.translateService.instant('INSERT_LINK'),
            'Align': this.sharedService.translateService.instant('ALIGN'),
            'Align Center': this.sharedService.translateService.instant('ALIGN_CENTER'),
            'Align Left': this.sharedService.translateService.instant('ALIGN_LEFT'),
            'Align Right': this.sharedService.translateService.instant('ALIGN_RIGHT'),
            'Undo': this.sharedService.translateService.instant('UNDO'),
            'Redo': this.sharedService.translateService.instant('REDO'),
            'Insert Horizontal Line': this.sharedService.translateService.instant('INSERT_HORIZONTAL_LINE'),
            'Open editor in fullsize': this.sharedService.translateService.instant('OPEN_EDITOR_IN_FULLSIZE'),
            'Type something': this.sharedService.translateService.instant('TYPE_SOMETHING'),
            'Select all': this.sharedService.translateService.instant('SELECT_EVERYTHING'),
        };
    }

    ngOnDestroy() {
        this.editor.destruct();
    }
}
