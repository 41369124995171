import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { LanguageService } from '../../../services/language.service';
import { WindowService } from '../../../services/window.service';
import { LocalDatePipe } from '../../../locale.pipes.module';
import { FormService } from '../../../services/form.service';
import { IMyOptions } from 'mydatepicker';
import * as moment from 'moment';

@Component({
    selector: 'app-import-basket-error-dialog',
    templateUrl: 'import.basket.error.dialog.component.html'
})
export class ImportBasketErrorDialog implements OnInit {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;
    somethingChanged = false;

    // Initialize selector is closed
    public selector: number;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private sharedService: SharedService,
        private toastService: ToastService,
        private languageService: LanguageService,
        private formService: FormService,
        private windowService: WindowService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.params.errors.forEach(error => {
                if (error.row.deliveryDate && error.row.deliveryDate.length !== 0) {
                    const momentDate = moment(error.row.deliveryDate);
                    error.row.deliveryDateModel = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }
                }
                error.row.datePickerOptions = this.getDatePickerOptions(error);
            });
        }, 0);
    }

    getDatePickerOptions(item) {
        const datePickerOptions: IMyOptions = item.row.minDeliveryDate 
            ? this.formService.getDatePickerOptions(false, moment(item.row.minDeliveryDate))
            : this.formService.getDatePickerOptions(false);
        datePickerOptions.selectionTxtFontSize = '98%';
        return datePickerOptions;
    }

    copyErrorsToClipboard() {
        // caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    copy() {
        let tmpEl;
        tmpEl = document.createElement('textarea');

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = 0;
        tmpEl.style.position = 'fixed';
        tmpEl.style.pointerEvents = 'none';
        tmpEl.style.zIndex = -1;

        let value = '';
        // fill it with your HTML
        this.params.errors.forEach(errorItem => {
            value += errorItem.row.eCommProductId + '\t';
            value += errorItem.row.amountOrdered + '\t';
            value += new LocalDatePipe(this.languageService).transform(errorItem.row.deliveryDate, this.sharedService.appSettings.language) + '\t';
            for (const key of Object.keys(errorItem.error)) {
                value += this.translate.instant(errorItem.error[key]) + ' ';
            }
            value += '\n';
        });

        // // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(value))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand('copy');

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    onChange(item, attrChangedName) {
        this.somethingChanged = true;
        delete item.error[attrChangedName];
        if (attrChangedName === 'amountOrdered') {
            if (isNaN(item.row.amountOrdered)) {
                item.error[attrChangedName] = 'Invalid data type: amountOrdered';
            }
        }
    }

    onDateChanged(event, item) {
        this.somethingChanged = true;
        if (event.jsdate) {
            item.row.deliveryDate = event.jsdate.toISOString();
            delete item.error.deliveryDate;
        }
    }

    addToBasketAgain() {
        const rows = [];
        this.params.errors.forEach(function(error) {
            delete error.row.deliveryDateModel;
            delete error.row.minDeliveryDate;
            delete error.row.datePickerOptions;
            delete error.row.eCommProductIdBrandCode;
            rows.push(error.row);
        });
        this.doneSubject.next({rows: rows});
        this.bsModalRef.hide();
    }
}
