export class News {
    constructor (
        public attachmentsCount:    number,
        public dateCreated:         string,
        public id:                  number,
        public important:           number,
        public postsCount:          number,
        public readCount:           number,
        public read:                number,
        public title:               string,
        public titleAdmin:          string | undefined,
        public userCreatedFullName: string,
        public userCreatedId:       number,
        public userCreatedSortName: string,
        public validFrom:           string,
        public validTo:             string
    ) { }
}
export class NewsCustomer {
    constructor (
        public title:                string,
        public dateCreated:          string,
        public body:                 any,
        public dateFrom:             string,
        public dateTo:               string,
        public authorizedSellerId:   string,
        public authorizedSellerName: string,
        public important:          boolean|number,
        public attachmentsCount:     number,
        public totalCount:           number
    ) { }
}
export class NewsSeller {
    constructor (
        public authorizedSellerId:   string,
        public authorizedSellerName: string,
        public title:                string,
        public dateCreated:          string,
        public body:                 any,
        public dateFrom:             string,
        public dateTo:               string,
        public important:          boolean|number,
        public readCount:            number,
        public attachmentsCount:     number,
        public totalCount:           number
    ) { }
}
export class NewsDetail {
    constructor(
            public id: number = null,
            public dateCreated: string = null,
            public authorizedSellerId: number = null,
            public authorizedSellerName: string = null,
            public attachments: any[] = [],
            public attachmentS3Keys: any[] = [],
            public title: string = null,
            public titleAdmin: string = null,
            public body = '',
            public languages: string = null,
            public validFrom: string = null,
            public validTo: string = null,
            public forAllASUsers = 0,
            public forAllCustomerUsers = 0,
            public customers: string[] = [],
            public notification: boolean = null,
            public discussion: boolean = null,
            public important: boolean = null,
            public skipTitleAndLogoInEmail: boolean = false,
    ) {}
}

export class NewsMenu {
    constructor(
        public rows: NewsMenuRow[],
        public totalCount: number
    ) {}
}

export class NewsMenuRow {
    constructor(
        public id: number,
        public url: string,
        public title: string,
        public dateCreated: string,
        public important: boolean,
        public viewed: boolean,
    ) {}
}
export class NewsDialog {
    constructor(

    ) {}
}

export class NewsDiscussion {
    constructor (
       public id: number,
       public parentId: number,
       public dateCreated: string,
       public discussionText: string,
       public fullName: string
    ) {}
}

export interface NewsListResponse {
    rows: News[],
    totalCount: number,
    personsCreated: {id: number, name: string}[]
}