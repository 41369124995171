import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-year-range-picker',
    templateUrl: 'year-range-picker.component.html',
    styleUrls: ['./year-range-picker.component.scss']
})
export class YearRangePickerComponent implements OnChanges{

    @Input() fromDate?: Date;
    @Input() toDate?: Date;
    @Output() onRangeSelected: EventEmitter<{fromDate: Date, toDate: Date}> = new EventEmitter();

    public yearsFrom: number[] = [];
    public yearsTo: number[] = [];
    public fromYear: number = 0;
    public toYear: number = 0;
    private readonly minYear: number = 2017;
    private readonly maxYear: number = (new Date()).getFullYear();

    constructor() {
        for (let i = this.minYear; i <= this.maxYear; i++) {
            this.yearsFrom.push(i);
            this.yearsTo.push(i);
        }
    }

    public ngOnChanges(): void {
        const thisYear = (new Date()).getFullYear();
        this.fromYear = this.fromDate ? this.fromDate.getFullYear() : thisYear;
        this.toYear = this.toDate ? this.toDate.getFullYear() : thisYear;

        if (this.fromYear > this.toYear) {
            this.fromYear = this.toYear;
        }
    }

    public save(): void {
        this.onRangeSelected.emit({
            fromDate: new Date(this.fromYear, 0, 1),
            toDate: new Date(this.toYear, 11, 31)
        });
    }
}
