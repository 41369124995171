export class Attachment {
    constructor(
        public fullName: string,
        public dateCreated: string,
        public childId: number,
        public fileName: string,
        public note?: string,
        public id?: number,
        public s3?: string,
        public privateItem?: boolean
    ) {}
}

export interface AttachmentUploadObj {
    url: string, // signed url
    key: string // S3 key
}

export interface AttachmentDownloadObj {
    url: string // signed url
}
