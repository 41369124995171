<div [ngClass]="{'component-outer-container': makeCollapsible}">
    <div class="discussion">
        <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()" [attr.role]="makeCollapsible ? 'button': ''"
            data-target="collapseDiscusion">
            <h3><i class="fa fa-comments"></i>
                <span translate>DISCUSSION</span>
                <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }"
                    class="fa" style="float: right"></i>
            </h3>
        </div> <!-- end of component header container --->
        <div [@slideInOut]="isCollapsed" [ngClass]="{'component-body-container': makeCollapsible}" id="collapseDiscusion">
            <div #scrollContainer class="comments">

                <div *ngIf="comments === null || comments.length === 0">
                    <div fxLayout="row">
                        <textarea [(ngModel)]="commentText" (ngModelChange)="commentOnchange()" class="form-control" placeholder="{{ 'DISCUSSION_NEW_PLACEHOLDER' | translate }}"
                            rows="6" maxlength="4000" [myFocus]="isModal || (showReplyInId !== null)"></textarea>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" style="text-align: right; margin-top: 
                10px">
                        <button class="btn-primary" style="margin-right: 10px" (click)="sendComment()" [disabled]="!commentText || commentText.length === 0"
                            [ngClass]="{'ec-disabled': !commentText || commentText.length === 0}">
                            <i class="fa fa-comment-o"></i>
                            <span translate>DISCUSSION_NEW_SEND</span>
                        </button>
                    </div>
                </div>

                <div class="comment row-container" *ngFor="let comment of comments; let isLast = last; let i = index"
                    [ngClass]="{'last': isLast, 'child-discussion': comment.parentId}">
                    <div fxLayout="row">
                        <div fxFlex="100">
                            <div *ngIf="comment.parentId" fxFlex="5" style="color:#aaa">
                                <i class="fa fa-level-up fa-rotate-90" style="margin: 3px 5px;"></i>
                            </div>
                            <div class="display-item" [fxFlex]="comment.parentId ? 90 : 95">
                                <strong class="name">{{comment.fullName}}
                                    <span class="time">{{comment.dateCreated |
                                        localDateTimeFromNow:sharedService.appSettings.language}}</span>
                                </strong>
                            </div>
                            <div fxFlex="5" fxLayoutAlign="end start" style="color:#aaa">
                                <ng-template #textToolTipReply><span>{{'DISCUSSION_REPLY' | translate}}</span></ng-template>
                                <i *ngIf="commentText && commentText.length > 0" class="fa fa-mail-reply" style="margin: 3px 5px; cursor: not-allowed; opacity: 0.7;"></i>
                                <i *ngIf="!commentText || (commentText && commentText.length === 0)" class="fa fa-mail-reply clickable dark-on-hover"
                                    (click)="showReplyTo(comment)" [popover]="textToolTipReply" container="body"
                                    triggers="mouseenter:mouseleave" style="margin: 3px 5px;"></i>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div *ngIf="comment.parentId" fxFlex="5">
                            &nbsp;
                        </div>
                        <div class="text" [fxFlex]="comment.parentId ? 95 : 100">
                            {{comment.discussionText}}
                        </div>
                    </div>

                    <ng-template #newCommentTemplate [ngIf]="(isLast && showReplyInId === null) || showReplyInId === comment.id">
                        <div fxLayout="row" style="margin-top: 15px;border-top: 1px solid #EEE;width: 100%;padding-top: 15px;">

                            <small *ngIf="(showReplyInId !== null)"><span translate>REPLY_TO_COMMENT_BY</span> <strong>{{
                                    replyToComment?.fullName }}</strong> <span translate>FROM_DAY</span> <strong>{{replyToComment?.dateCreated
                                    | localDateTimeFromNow:sharedService.appSettings.language}}</strong></small>
                        </div>
                        <div fxLayout="row">
                            <textarea #newCommentInput [(ngModel)]="commentText" (ngModelChange)="commentOnchange()" class="form-control" placeholder="{{ 'DISCUSSION_NEW_PLACEHOLDER' | translate }}"
                                rows="6" maxlength="4000" [myFocus]="isModal || (showReplyInId !== null)"
                                [disabled]="disableEdit" [ngClass]="{'ec-disabled': disableEdit}" 
                            ></textarea>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center" style="text-align: right; margin-top: 10px">
                            <busy-indicator *ngIf="sendBusy"></busy-indicator>
                            <button class="btn-primary" style="margin-right: 10px" (click)="sendComment(replyToComment)"
                                [disabled]="!commentText || commentText.length === 0 || sendBusy || disableEdit" 
                                [ngClass]="{'ec-disabled': !commentText || commentText.length === 0 || sendBusy || disableEdit}">
                                <i class="fa fa-comment-o"></i>
                                <span translate>DISCUSSION_NEW_SEND</span>
                            </button>
                        </div>

                    </ng-template>
                </div>

            </div><!-- end of component body container --->
        </div>
    </div>
</div>
