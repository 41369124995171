import { Injectable } from '@angular/core';
import { TableColumn, BaseSelection } from '@app/model/table.model';
import { TableService } from '@services/table.service';
import { SharedService } from '@services/shared.service';
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { Params } from '@angular/router';
import { AppAreas } from '@app/model/appArea.model';
import { SuppressColumnsAreas } from '@app/model/suppressColumnsAreas.model';

export interface GridServiceOptions {
    gridNameDashed: AppAreas; // 'price-list' - must be the string used for routing!
    gridNameCamel: SuppressColumnsAreas; // 'priceList' - camel copy of gridNameDashed
    tableNamePrefix?: string;   // used when there are more grids in a component
                                // The same value must be used in grid template for attr '[tableNamePrefix]'
    columnList: TableColumn[];
}

@Injectable({
    providedIn: 'root'
})
export class BaseGridService {

    constructor(
        protected tableService: TableService,
        protected sharedService: SharedService
    ) {}

    protected getGridServiceOptions(): GridServiceOptions {
        console.log('This method must be overriden in a child grid service!!!');
        return {} as GridServiceOptions;
    }

    protected getColumnDefs(): ColDef[] {
        console.log('This method must be overriden in a child grid service!!!');
        return [];
    }

    protected headerClassFunc(params: any): string {
        return 'bkg-primary fnt-white';
    }

    public getColumns(forceOriginal?: boolean, tableNamePrefix?: string): TableColumn[] {
        const gsOptions = this.getGridServiceOptions();
        let original = gsOptions.columnList;
        original = this.tableService.filterSuppressColumns(original, gsOptions.gridNameCamel);

        const key = `${this.getDashedAttrName(tableNamePrefix)}TableColumns`;
        const restored = this.sharedService.user.preferences[key];
        // TODO: consider this
        // const restored = this.sharedService.user.preferences[
        //     this.sharedService.getUserPreferenceKey('TableColumns', tableNamePrefix)];

        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    public getColumn(colId: string): TableColumn {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {
            id: '',
            name: '_',
            checked: false,
            orderBy: false,
            orderDirection: '',
            width: 0
        };
    }

    public getGridOptions(startPage: number): GridOptions {
        const attrName = this.getDashedAttrName();
        const gridOptions = this.tableService.getDefaultGridOptions(startPage, `${attrName}TablePageSize`, this.headerClassFunc);
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    public initSelection(): void {
        const selectionAttrName = this.getSelectionAttrName();
        const selection: BaseSelection = {
            ids: {},
            all: false,
            visible: false
        };

        this.sharedService.user.preferences[selectionAttrName] = selection;
    }

    public getSelection(): BaseSelection {
        return this.sharedService.user.preferences[this.getSelectionAttrName(true)];
    }

    public resetSelection(): void {
        // const selectionAttrName = this.getSelectionAttrName();
        // this.sharedService.user.preferences[selectionAttrName].ids = {};
        // this.sharedService.user.preferences[selectionAttrName].all = false;
        // this.sharedService.user.preferences[selectionAttrName].visible = false;
        // localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        this.sharedService.resetSelection(this.getSelectionAttrName(true));
    }

    public getSelectionAttrName(testExistence?: boolean): string {
        const selectionAttrName = this.getGridServiceOptions().gridNameCamel + 'Selection';

        if (testExistence && !this.sharedService.user.preferences.hasOwnProperty(selectionAttrName)) {
            console.error('Selection not initialized, call <gridService>.initSelection() in the component constructor');
        }

        return selectionAttrName;
    }

    private getDashedAttrName(tableNamePrefix?: string): string {
        const gsOptions = this.getGridServiceOptions();
        return gsOptions.gridNameDashed + (gsOptions.tableNamePrefix || tableNamePrefix || '');
    }

    public getLastQueryParamsKey(): string {
        const gsOptions = this.getGridServiceOptions();
        const gridNameCamelUpper = gsOptions.gridNameCamel.charAt(0).toUpperCase() + gsOptions.gridNameCamel.slice(1);
        return `last${gridNameCamelUpper}QueryParams`;
    }
}
