import { Component } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { ToastService } from '../../../../services/toastService/toast.service';
import { SharedService } from '../../../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentDialogService } from '../../../../components/attachmentComponent/attachmentDialogComponent/attachment.dialog.service';
import { NewsDetailService} from '../../../../services/news/news.detail.service';
import { QuestionDialogService } from '../../../../components/questionDialogComponent/question.dialog.service';
import { NewsDiscussionDialogService } from '../../../../services/news/news.discussion.dialog.service';
import { NewsService} from '../../../../services/news/news.service';
import { PermissionsService } from '../../../../services/permissions.service';
// import moment = require('moment');
import * as moment from 'moment';
import { map } from 'rxjs/operators';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'news-actions-renderer',
    templateUrl: './grid.news.actions.renderer.component.html',
    styleUrls:  ['./grid.news.actions.renderer.component.scss']
})
export class GridNewsActionsRendererComponent implements AgRendererComponent {
    // isRowValid
    params: any;
    setEditableSubscription: Subscription;
    buttonsVisibility: any = {};
    newsValid: boolean;
    attachmentS3Keys: string[] = [];
    canEdit = false;
    canEditAttachments = false;
    commentsChangedSubject: Subject<any> = new Subject();

    isVisibleComments: boolean;

    constructor(
        private toastService: ToastService,
        private sharedService: SharedService,
        private translateService: TranslateService,
        private questionDialogService: QuestionDialogService,
        private attachmentDialogService: AttachmentDialogService,
        private newsDiscussionDialogService: NewsDiscussionDialogService,
        private newsService: NewsService,
        private newsDetailService: NewsDetailService,
        private router: Router,
        private permissionsService: PermissionsService
    ) {
        this.commentsChangedSubject.subscribe(result => {
            this.params.data.postsCount = result;
        });
    }

    agInit(params: any): void {
        this.params = params;
        this.params.subjectReloadCurrent = this.newsService.getReloadCurrentSubject();
        // this.buttonsVisibility = this.getButtonsVisibility(this.params.data.actions);

        // this.newsValid = moment(this.params.data.validTo) > moment();
        this.newsValid = this.params.data.valid;
        // this.canEditAttachments = this.permissionsService.hasPermission('newsAttachmentsCreate');
        this.canEdit = this.permissionsService.hasPermission('newsCreate'); // if true, its admin..  if false, its others (customers etc.)

        this.isVisibleComments = this.params.data.discussion === 1;
    }

    // getButtonsVisibility(actions: string[]) {
    //     return actions ? actions.reduce((acc, action) => Object.assign({}, acc, { [action]: this.rfqPermissionService.hasPermissionItemsAction() }), {}) : {};
    // }
    markAsRead(id: number) {
        this.newsService.markAsReadNews(id).subscribe(
            result => {
                this.toastService.addSuccess('NEWS_SUCCESSFULLY_READ');
                this.params.subjectReloadCurrent.next();
                this.newsService.setNewsCount();
            },
            err => { console.log(err); }
        );
    }
    markAsUnread(id: number) {
        this.newsService.markAsUnreadNews(id).subscribe(
            result => {
                this.toastService.addSuccess('NEWS_SUCCESSFULLY_UNREAD');
                this.params.subjectReloadCurrent.next();
                this.newsService.setNewsCount();
            },
            err => { console.log(err); }
        );
    }

    unpublish() {
        this.newsService.putNews(this.params.data.id, { validTo: new Date().toISOString() }).subscribe(
            result => {
                this.params.subjectReloadCurrent.next();
                this.toastService.addSuccess('NEWS_SUCCESSFULLY_INVALIDATED');
                this.newsService.setNewsCount();
            },
            err => { console.log(err); }
        );
        // this.questionDialogService.confirm(
        //     {
        //         message: 'NEWS_REALLY_TO_INVALIDATE',
        //         question: 'NEWS_REALLY_TO_INVALIDATE_WARNING',
        //         primary: 'YES',
        //         secondary: 'NO'
        //     }
        // ).subscribe(
        //     res => {
        //         if (res === 'confirm') {
        //             this.newsService.putNews(this.params.data.id, { validTo: new Date().toISOString() }).subscribe(
        //                 result => {
        //                     this.params.subjectReloadCurrent.next();
        //                     this.toastService.addSuccess('NEWS_SUCCESSFULLY_INVALIDATED');
        //                 },
        //                 err => { console.log(err); }
        //             );
        //         }
        //     }
        // );
    }

    discussions() {
        this.newsDiscussionDialogService.confirm(
            {data: this.params.data, isModal: true, commentsChangedSubject: this.commentsChangedSubject}
        ).subscribe(
            res => {}
        );
    }

    attachments() {
        this.attachmentDialogService.confirm(
            this.params.data,
            (entityId?: any) => this.newsDetailService.getAttachments(this.params.data.id),
            (fileName: string) => this.newsDetailService.getUploadUrl(fileName, this.params.data.id),
            (attachmentId: number) => this.newsDetailService.getDownloadUrl(this.params.data.id, attachmentId),
            (newsId: number, attachmentId: number, lineNumber?: number) =>
                this.newsDetailService.deleteAttachment(newsId, attachmentId).pipe(map(result => {
                    this.params.data.attachmentsCount = this.params.data.attachmentsCount - 1; // attachment was deleted
                    if (this.params.attachmentChanged) {
                        this.params.attachmentChanged();
                    }
                })),
            () => this.permissionsService.hasPermission('newsCreate'),
            (s3Keys: string[]) => {
                this.params.data.attachmentsCount = this.params.data.attachmentsCount + 1; // attachments were added
                if (this.params.attachmentChanged) {
                  this.params.attachmentChanged();
                }
              },
            true
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    console.log('ide');
                    // this.newsService.putNews(this.params.data.id, { validTo: new Date().toISOString() }).subscribe(
                    //     result => {
                    //         this.params.subjectReloadCurrent.next();
                    //         this.toastService.addSuccess('NEWS_SUCCESSFULLY_INVALIDATED');
                    //     },
                    //     err => { console.log(err); }
                    // );
                }
            }
        );
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
