
import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef,  ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, NavigationEnd } from '@angular/router';
import { NewsDetailService } from '../../../services/news/news.detail.service';
import { SharedService } from '../../../services/shared.service';
import { Subject ,  Observable} from 'rxjs';
import { FormService } from '../../../services/form.service';
import { FormGroup } from '@angular/forms';
import { IMyDateModel, IMyDate } from 'mydatepicker';
import * as moment from 'moment-timezone';
import { ToastService } from '../../../services/toastService/toast.service';

import { QuestionDialogService } from '../../../components/questionDialogComponent/question.dialog.service';

// MOCKUP
import { NewsDetail } from '../../../model/news.model';

import { NewsDetailIframeComponent } from '../newsDetailComponent/news.detail.iframe.component';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'news-detail',
    styleUrls: ['./news.detail.component.scss'],
    templateUrl: 'news.detail.component.html'
})

export class NewsDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() newsId: number;
    @Input() reload: Subject<any>;       // optional reload subject - reloads newsDetail when next() is called on this from outside
    @Input() isModal: false;       // optional reload subject - reloads newsDetail when next() is called on this from outside
    // @ViewChild('addNews') addNews: ElementRef;
    @ViewChild('iframe') iframe: ElementRef;     /** IFRAME PROTOTYPE */
    @ViewChild('addNews', { static: true }) addNewsForm: any;
    doc: any;
    compRef: ComponentRef<NewsDetailIframeComponent>;
    /** END OF IFRAME PROTOTYPE */

    subjectReload = new Subject<any>();
    reloadComments = new Subject<any>();
    reloadSubscribtion;
    subjectReloadWysiwyg = new Subject<any>();
    allow = {
        publish: false,
        reset: false
    }
    newsData;
    newsDataOld;

    // datepicker
    validFromDate;
    validFromTime;
    validToDate;
    validToTime;

    // COMMENT
    discussion = '';

    readOnly = false; // if true, Jodit in wysiwyg will be destructed and created as new. NgModel wil not be used (emitter is ommited)
    canEdit = false;
    isEdit = false;
    languages: { id: number, name: string, checked: boolean }[] = [];
    selectedLanguages: { id: number, name: string, checked: boolean }[] = [];
    attachmentS3Keys: string[] = [];
    attachmentsWithoutEntity: string[] = [];
    // attachmentsWithoutEntity = Observable.empty<Response>();
    subjectReloadAttachments = new Subject<any>();
    comments = null;
    customersItems: any[] = [];
    multiselectAllLabel = 'CATALOGUE_ALL';
    multiselectSelectedLabel = 'SELECTED_STATES';
    myDatePickerOptionsFrom;
    myDatePickerOptionsTo;
    showDateFromPicker = true;
    showDateToPicker = true;

    wysiwygTextEdited = false;
    readOnlyWysiwygConfig = {
        'iframe': true,
        'iframeStyle': 'body { font-size: 14px; font-family: Arial, Helvetica, Arial, Verdana, sans-serif !important; }',
        'toolbar': false,
        'readonly': true,
        'spellcheck': false,
        'showCharsCounter': false,
        'showWordsCounter': false,
        'showXPathInStatusbar': false
    };

    today = moment();

    // totalRows:          number;
    // filterItems: 		any[];
    // personCreated:      {id: string, name: string}[] = [];
    // authorizedSellers:  {id: string, name: string}[] = [];
    // customers: 			{id: string, name: string}[] = [];
    // subjectRefresh = new Subject<any>();
    // exportObj: 			{fileName: string, export: Function};

    busy: boolean;

    edited = {  // what part of form is edited (changed)
        discussionInForm: false
    }

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    isEditing(): boolean {
        return this.allow.publish || this.allow.reset || this.edited.discussionInForm;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private newsDetailService: NewsDetailService,
        private formService: FormService,
        public sharedService: SharedService,
        private toastService: ToastService,
        private questionDialogService: QuestionDialogService,
        // IFRAME PROTOTYPE
        private vcRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver
        // END OF IFRAME PROTOTYPE
    ) {
        this.getUploadUrl = this.getUploadUrl.bind(this);
        this.getDownloadUrl = this.getDownloadUrl.bind(this);
        this.attachmentsGetter = this.attachmentsGetter.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
    }

    createComponent() {
        if (this.newsId !== 0 && !this.isEdit && (this.newsData.body && this.newsData.body.length > 0)) {
            this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
            const compFactory = this.resolver.resolveComponentFactory(NewsDetailIframeComponent);
            this.compRef = this.vcRef.createComponent(compFactory);
            (<NewsDetailIframeComponent>this.compRef.instance).data = { body: this.newsData.body };
            if (this.doc.body) {
                while (this.doc.body.firstChild) {
                    this.doc.body.firstChild.remove();
                }
            }
            this.doc.body.appendChild(this.compRef.location.nativeElement);
        }
    }

    onLoad() {
        setTimeout(() => {
            this.createComponent();
        }, 0);

    }

    ngOnInit() {
        this.allow.publish = false;
        this.allow.reset = false;
        this.wysiwygTextEdited = false;

        this.today.hour(0)
        this.today.minute(0);
        this.today.second(0);
        this.isModal = !this.isModal ? false : this.isModal;
        this.canEdit = this.sharedService.permissions.news.indexOf('POST') >= 0  ? true : false;
        this.newsId = !this.newsId ? +this.route.snapshot.paramMap.get('id') : this.newsId;
        if (this.canEdit) {
            this.customersItems = this.sharedService.user.availableCustomers.map(item => item);
        }

        this.myDatePickerOptionsFrom = this.formService.getDatePickerOptions(false, moment());
        this.myDatePickerOptionsTo = this.formService.getDatePickerOptions(false, moment());
        this.enableHolidaysAndWeekendsFromTo();

        if (this.newsId === 0) {
            this.languages = this.sharedService.languages.map((item, index) => ({ id: index, name: item, checked: true }));
            this.validFromTime = new Date(new Date().setHours(0, 0, 0, 0));
            this.validToTime = new Date(new Date().setHours(23, 59, 0, 0));
            this.newsData = new NewsDetail();
            this.newsData.languages = this.languages.map(item => item.name);
            this.newsDataOld = Object.assign({}, this.newsData); // cloned data to provide pristine values
            this.isEdit = true;
        } else {
            this.busy = true;
            this.loadData();
        }
    }

    loadData() {
        this.newsDetailService.getNewsDetail(this.newsId).subscribe(
            data => this.prepareData(data),
            err => {
                console.log(err);
                this.busy = false;
        });
    }

    enableHolidaysAndWeekendsFromTo() {
    // #437 - allow holidays and weekends
    this.myDatePickerOptionsFrom.disableDays = [];
    this.myDatePickerOptionsFrom.disableWeekends = false;
    this.myDatePickerOptionsFrom.disableSince = [];
    this.myDatePickerOptionsTo.disableDays = [];
    this.myDatePickerOptionsTo.disableWeekends = false;
    this.myDatePickerOptionsTo.disableSince = [];
    // #437 - end of
    }
    prepareData(data: any) {
        this.allow.publish = false;
        this.allow.reset = false;
        this.wysiwygTextEdited = false;

        this.newsData = Object.assign({}, data);
        this.newsDataOld = Object.assign({}, data); // cloned data to provide pristine values

        if (data.validFrom !== null && data.validFrom !== '') {
            const validFrom_ = moment(data.validFrom);
            this.validFromDate = { date: { year: validFrom_.year(), month: validFrom_.month() + 1, day: validFrom_.date() } };
            this.validFromTime = new Date(new Date().setHours(validFrom_.hours(), validFrom_.minutes()));
        } else {
            this.showDateFromPicker = false;
            this.validFromDate = null;
            this.validFromTime = new Date();
            this.validFromTime.setHours(0, 0, 0, 0);
            setTimeout(() => {
                this.showDateFromPicker = true;
            }, 0)
        }
        if (data.validTo !== null && data.validTo !== '') {
            const validTo_ = moment(data.validTo);
            this.validToDate = { date: { year: validTo_.year(), month: validTo_.month() + 1, day: validTo_.date() } };
            this.validToTime = new Date(new Date().setHours(validTo_.hours(), validTo_.minutes()));
        } else {
            this.showDateToPicker = false;
            this.validToDate = null;
            this.validToTime = new Date();
            this.validToTime.setHours(23, 59, 0, 0);
            setTimeout(() => {
                this.showDateToPicker = true;
            }, 0)
        }
        this.resetEmptyTimeToZero(data); // if date is null, time will be 0:0:0. after that set datepicker options
        if (moment(data.validFrom) < moment() ) {
            this.myDatePickerOptionsTo = this.formService.getDatePickerOptions(false, moment());
            this.enableHolidaysAndWeekendsFromTo();
        } else {
            this.myDatePickerOptionsTo = this.formService.getDatePickerOptions(false, moment(data.validFrom));
            this.enableHolidaysAndWeekendsFromTo();
        }
        // this.myDatePickerOptionsTo.disableSince = moment(data.validFromDate) < moment() ? moment() : moment(data.validFromDate);

        /** Check only selected languages  */
        this.languages = this.sharedService.languages.map((item, index) => ({ id: index, name: item, checked: false }));
        if (this.newsData.languages) {
            this.newsData.languages.forEach(element => {
                this.languages.forEach(item => {
                    if (item.name.toLowerCase() === element.toLowerCase()) {
                        item.checked = true;
                    }
                });
            });
        }
        this.subjectReloadWysiwyg.next();

        if (this.newsData.discussion === 1 ) {
            this.getComments();
        }
        
        if (this.newsData.customers && this.newsData.customers.length > 0) {
            this.customersItems.map(item => {
                item.checked = this.newsData.customers.filter(filterItem => filterItem.id === item.id).length > 0;
            });
        }

        /** Get value of saved customers. can be all (1), nothing/no one (0) or selected users (2).
         *  But in DB are stored only ALL/NONE as forAllCustomerUsers. If there are selected users, they are stored as data.customers
         *  without any FE connection/relation between forAllCustomerUsers and Customers.
         *
         *  Switch is used to simplify IF statement
         */
        switch (true) {
            case (typeof data.forAllCustomerUsers === 'undefined' || typeof data.customers === 'undefined'):
            case (data.forAllCustomerUsers === null || data.forAllCustomerUsers === ''):
            case (data.forAllCustomerUsers === 0 && data.customers.length <= 0):
                this.newsData.forAllCustomerUsers = 0;
                this.newsDataOld.forAllCustomerUsers = 0;
                break;
            case (data.forAllCustomerUsers === 1 && data.customers.length <= 0):
                this.newsData.forAllCustomerUsers = 1;
                this.newsDataOld.forAllCustomerUsers = 1;
                break;
            case (data.customers && data.customers.length > 0):
                this.newsData.forAllCustomerUsers = 2;
                this.newsDataOld.forAllCustomerUsers = 2;
                break;
            default:
                this.newsData.forAllCustomerUsers = 0;
                this.newsDataOld.forAllCustomerUsers = 0;
                break;
        }
        setTimeout(() => {
            this.onLoad();
        }, 0);
        if (this.busy === true)  {
            this.busy = false;
        }
    }

    getComments(parent?: boolean) {
        this.newsDetailService.getComments(this.newsId).subscribe(
            comments => {
                this.comments = comments;
                setTimeout(() => {
                    if (parent) {
                        this.reloadComments.next(parent);
                    }
                }, 0);
            },
            err => {
                console.log(err);

        });
    }
    commentsHasChanged(parent?: any) {
        // console.log('comments has changed - news');
        this.getComments(parent);
    }


    ngAfterViewInit() {
        if (this.reload) {
            this.reloadSubscribtion = this.reload.subscribe(() => {
                this.getUploadUrl = this.getUploadUrl.bind(this);
                this.getDownloadUrl = this.getDownloadUrl.bind(this);
                this.attachmentsGetter = this.attachmentsGetter.bind(this);
                this.deleteAttachment = this.deleteAttachment.bind(this);
                this.ngOnInit();
                setTimeout(() => {
                    this.readOnly = true;
                    this.subjectReloadAttachments.next({initialize: true});
                    if ( this.newsId !== 0) {
                        this.onLoad();
                    }
                }, 0);
            })
        }
        //  this.onLoad();
    }

    onDateFromChanged(event: IMyDateModel) {
        const val: any = event.jsdate;
        if (val === '' || val === null || val === undefined) {
            this.validFromDate = null;
            this.validFromTime = new Date(new Date().setHours(0, 0, 0, 0));
        } else {
            this.validFromDate = val;
        }

        const from = moment(this.validFromDate);
        from.hour(moment(this.validFromTime).hour());
        from.minute(moment(this.validFromTime).minute());
        from.second(moment(this.validFromTime).second());
        from.millisecond(moment(this.validFromTime).millisecond());
        this.newsData.validFrom = from.toISOString();

        if (!this.validFromDate || moment(this.validFromDate) < moment() ) {
            this.myDatePickerOptionsTo = this.formService.getDatePickerOptions(false, moment());
            this.enableHolidaysAndWeekendsFromTo();
        } else {
            this.myDatePickerOptionsTo = this.formService.getDatePickerOptions(false, moment(this.validFromDate));
            this.enableHolidaysAndWeekendsFromTo();
        }
        this.validate();
    }
    onDateToChanged(event: IMyDateModel) {
        const val: any = event.jsdate;
        if (val === '' || val === null) {
            this.validToDate = null;
            this.validToTime = new Date(new Date().setHours(23, 59, 0, 0));
        } else {
            this.validToDate = val;
        }

        if (this.validToDate) {
            const to = moment(this.validToDate);
            to.hour(moment(this.validToTime).hour());
            to.minute(moment(this.validToTime).minute());
            to.second(moment(this.validToTime).second());
            to.millisecond(moment(this.validToTime).millisecond());
            this.newsData.validTo = to.toISOString();
        } else {
            this.newsData.validTo = null;
        }
        if (moment(this.newsData.validFromDate) < moment() ) {
            this.myDatePickerOptionsTo.disableSince = { date: { year: moment().year(), month: moment().month(), day: moment().date() } };
        } else {
            this.myDatePickerOptionsTo.disableSince = { date: { year: moment(this.newsData.validFromDate).year(), month: moment(this.newsData.validFromDate).month(), day: moment(this.newsData.validFromDate).date() } };
        }
        this.validate();
    }
    onTimeFromChanged(newDateTime: Date) {
        // this.validFromTime = event;
        if (this.newsData.validFrom) {
            const from = moment(this.newsData.validFrom);
            from.hour(moment(this.validFromTime).hour());
            from.minute(moment(this.validFromTime).minute());
            this.newsData.validFrom = from.toISOString();
        }
        this.validate();
    }
    onTimeToChanged(newDateTime: Date) {
        // this.validToTime = event;
        if (this.newsData.validTo) {
            const to = moment(this.newsData.validTo);
            to.hour(moment(this.validToTime).hour());
            to.minute(moment(this.validToTime).minute());
            this.newsData.validTo = to.toISOString();
        }
        this.validate();
    }
    onDropdownChanged(element: string) {
        if (element === 'languages') {
            this.newsData.languages = this.languages.filter(item => item.checked).map(item => item.name.toLocaleLowerCase());
        }
        this.validate();
    }
    onCheckboxChanged(element: string) {
        if (element === 'forAllCustomerUsers') {
            if (this.newsData.forAllCustomerUsers === 0 || this.newsData.forAllCustomerUsers === 1) {
                this.newsData.customers = [];
            } else {
                this.newsData.customers = this.customersItems.filter(item => item.checked).map(item => item.id);
            }
        } else {
            this.newsData[element] = this.newsData[element] === true ? 1 : 0;
        }
        this.validate();
    }
    resetEmptyTimeToZero(data) {
        if (this.validFromTime === null && this.validFromDate === null) {
            this.validFromTime = new Date();
            this.validFromTime.setHours(0, 0, 0, 0);
        }
        if (this.validToTime === null && this.validToDate === null) {
            this.validToTime = new Date();
            this.validToTime.setHours(23, 59, 0, 0);
        }
    }
    wysiwygTextChanged(text?: any) {
        this.newsData.body = text ? text : '';
        this.wysiwygTextEdited = true;
        this.validate();
    }
    attachmentsGetter(entityId?: any) {
        // if (this.newsId === 0) {
        //     return this.attachmentsWithoutEntity;
        // } else {
            return this.newsDetailService.getAttachments(this.newsId);
        // }
    }
    getUploadUrl(fileName: string) {
        return this.newsDetailService.getUploadUrl(fileName, this.newsData.id);
    }
    getDownloadUrl(attachmentId: number) {
        return this.newsDetailService.getDownloadUrl(this.newsData.id, attachmentId);
    }
    setAttachmentS3Keys(s3Keys: string[]) {
        this.attachmentS3Keys = s3Keys;
    }

    // linenumber is only for back compatibility with rfq
    deleteAttachment(newsId: number, attachmentId: number, lineNumber?: number) {
        return this.newsDetailService.deleteAttachment(newsId, attachmentId);
    }

    attachmentsChanged(event?) { // unused
        // this.subjectReloadAttachments.next();
    }
    attachmentsChangedWithoutEntity(event?) {
        // this.subjectReloadAttachments.next();
    }
    selectedCustomer(ev) {
        this.newsData.customers = this.customersItems.filter(item => item.checked).map(item => item.id);
        this.validate();
    }
    // ngDoCheck() {

    // }

    validate() {
        if (this.isEdit) {
            // check all mandatory fields
            this.allow.publish = true;
            this.allow.reset = false;

            if (!this.newsData) {
                this.allow.publish = false;
            } else {
                // VALIDATE RESTE BUTTON
                if (this.newsData.title !== this.newsDataOld.title ||
                    this.newsData.titleAdmin !== this.newsDataOld.titleAdmin ||
                    this.newsData.validFrom !== this.newsDataOld.validFrom ||
                    this.newsData.validTo !== this.newsDataOld.validTo ||
                    this.newsData.important !== this.newsDataOld.important ||
                    this.newsData.forAllASUsers !== this.newsDataOld.forAllASUsers ||
                    this.newsData.forAllCustomerUsers !== this.newsDataOld.forAllCustomerUsers ||
                    this.newsData.notification !== this.newsDataOld.notification ||
                    this.newsData.discussion !== this.newsDataOld.discussion ||
                    this.newsData.skipTitleAndLogoInEmail !== this.newsDataOld.skipTitleAndLogoInEmail ||
                    JSON.stringify(this.newsData.languages) !== JSON.stringify(this.newsDataOld.languages) ||
                    JSON.stringify(this.newsData.customers) !== JSON.stringify(this.newsDataOld.customers) ||
                    this.wysiwygTextEdited
                    ) {
                    this.allow.reset = true;
                }
                this.allow.publish = this.allow.reset; // something has changed

                // validate title - must be provided
                if ((!this.newsData.title || this.newsData.title === null || this.newsData.title.length === 0)) {
                    this.allow.publish = false;
                }
                // Validate if news are for AS or users. at least one must be selected
                if (this.newsData.forAllCustomerUsers === 0 && this.newsData.forAllASUsers === 0) {
                    this.allow.publish = false;
                }
                // Validate if news are for SELECTED customer (option 3, value as 2), they must be provided (cant be sent to empty)
                if (this.newsData.forAllCustomerUsers === 2 && this.customersItems.filter(item => item.checked).map(item => item.id).length === 0) {
                    this.allow.publish = false;
                }
                // Validate languages - at least one must be provided
                if (this.newsData.languages && this.newsData.languages.length === 0) {
                    this.allow.publish = false;
                }
            }

            // validate - Date whend news are valid (open, started) must be provided
            if (typeof this.validFromDate === 'undefined' || !this.validFromDate || this.validFromDate.length === 0 || this.validFromDate === null) {
                this.allow.publish = false;
            }

            // this.allow.publish can be true or false here
        }
    }

    backToNonEdit() {
        if (this.allow.publish || this.allow.reset) {
            this.questionDialogService.confirm({
                message: 'WARNING',
                question: 'WARNING_DATA_NOT_SAVED_CONTINUE_ANYWAYS',
                primary: 'YES',
                secondary: 'NO',
            }).subscribe(res => {
                if (res === 'confirm') {
                    this.isEdit = false;
                    this.resetForm(this.addNewsForm.form);
                }
            });
        } else {
            this.isEdit = false;
            this.resetForm(this.addNewsForm.form);
        }
    }

    resetForm(newsForm: FormGroup) {
        this.prepareData(this.newsDataOld);
    }

    updateNewsDetail() {
        const requestData: any = {};
        Object.keys(this.newsData).forEach(element => {
            if (element === 'validFrom' && this.validFromDate && this.validFromDate.jsdate) {
                const from = moment(this.validFromDate.jsdate);
                from.hour(moment(this.validFromTime).hour());
                from.minute(moment(this.validFromTime).minute());
                this.newsData.validFrom = from.toISOString();
            } else if (element === 'validFrom') {
                // this.newsData.validFrom = this.newsDataOld.validFrom;
            }

            if (element === 'validTo' && this.validToDate && this.validToDate.jsdate) {
                const to = moment(this.validToDate.jsdate);
                to.hour(moment(this.validToTime).hour());
                to.minute(moment(this.validToTime).minute());
                this.newsData.validTo = to.toISOString();
            } else if (element === 'validTo') {
                if ( this.validToDate === '' && this.validToDate !== this.newsDataOld.validTo) {
                    this.newsData.validTo = null;
                } else {
                    // this.newsData.validTo = this.newsDataOld.validTo;
                }
            }

            if (typeof this.newsData[element] !== 'undefined' && this.newsData[element] !== '' &&
                this.newsData[element] !== this.newsDataOld[element]) {
                // this.preSave(false, element);
                requestData[element] = this.newsData[element];
            }
        });
        if (requestData.forAllCustomerUsers === 2) {
            requestData.forAllCustomerUsers = 0;
        }

        // check valid from and valid to dates
        if (moment(this.newsData.validFrom) > moment(this.newsData.validTo)) {
            this.toastService.addError('VALID_FROM_MUST_BE_LESS_THAN_VALID_TO');
            return;
        }

        this.newsDetailService.putNewsDetail(this.newsData.id, requestData).subscribe(
            res => {
                // this.router.navigate(['/' + this.sharedService.appSettings.language + '/news/']);
                this.getUploadUrl = this.getUploadUrl.bind(this);
                this.getDownloadUrl = this.getDownloadUrl.bind(this);
                this.attachmentsGetter = this.attachmentsGetter.bind(this);
                this.deleteAttachment = this.deleteAttachment.bind(this);
                this.ngOnInit();
                setTimeout(() => {
                    this.readOnly = true;
                    this.subjectReloadAttachments.next({initialize: true});
                    this.isEdit = false;
                }, 0);
                this.toastService.addSuccess('NEWS_SUCCESSFULLY_UPDATED');
            },
            err => {
                console.error(err);
            }
        );
    }
    saveNewsDetail() {
        this.preSave(true);
        const requestData = Object.assign({}, this.newsData);
        Object.keys(requestData).forEach(element => {
            if (['forAllASUsers', 'forAllCustomerUsers', 'notification', 'discussion', 'important', 'skipTitleAndLogoInEmail'].indexOf(element) >= 0) {
                if (requestData[element] === true || requestData[element] === false || requestData[element] === null) {
                    requestData[element] = requestData[element] ? 1 : 0;
                }
            }
        })
        if (requestData.forAllCustomerUsers === 2) {
            requestData.forAllCustomerUsers = 0;
        }
        requestData.languages = requestData.languages.map(lang => lang.toLowerCase());

        // check valid from and valid to dates
        if (moment(this.newsData.validFrom) > moment(this.newsData.validTo)) {
            this.toastService.addError('VALID_FROM_MUST_BE_LESS_THAN_VALID_TO');
            return;
        }

        this.newsDetailService.postNewsDetail(requestData).subscribe(
            res => {
                this.toastService.addSuccess('NEWS_SUCCESSFULLY_CREATED');
                setTimeout(() => {
                    this.newsId = res.news.id;
                    this.ngOnInit();
                    this.readOnly = true;
                    this.isEdit = false;
                    this.router.navigate(['/' + this.sharedService.appSettings.language + '/news/' + res.news.id]);
                }, 0);
            },
            err => {
                console.error(err);
            }
        );
    }
    /**
     * preSave - modify newsData before save or update. Convert boolean to number interpretation, merge date and time to ISO datetime format.
     * After end delete unnecesary fields
     *
     * @param {boolean} [all] Preprocess all elements -> use only before NEW save (creating)
     * @param {string} [element] Preprocess only current element -> use only before edit save (editing)
     * @memberof NewsDetailComponent
     */
    preSave(all?: boolean, element?: string, s3AndDelete?: boolean) {
        if (all && (s3AndDelete !== false)) {
            this.newsData.attachmentS3Keys = this.attachmentS3Keys;

            delete this.newsData.authorizedSellerName;
            delete this.newsData.authorizedSellerId;
            delete this.newsData.dateCreated;
            delete this.newsData.id;
            delete this.newsData.attachments;
        } else {
            delete this.newsData.notificationSent;
            delete this.newsData.dateChanged;
            delete this.newsData.readCount;
            delete this.newsData.attachments;
        }
    }

    deleteNewsDetail() {
        this.questionDialogService.confirm(
            {
                message: 'NEWS_REALLY_TO_DELETE',
                question: 'NEWS_REALLY_TO_DELETE_WARNING',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    this.newsDetailService.deleteNews(this.newsData.id).subscribe(
                        result => {
                            this.router.navigate(['/' + this.sharedService.appSettings.language + '/news/']);
                            this.toastService.addSuccess('NEWS_SUCCESSFULLY_DELETED');
                        },
                        err => { console.log(err); }
                    );
                }
            }
        );
    }

    discussionInFormEdited(value: boolean) {
        this.edited.discussionInForm = value;
    }

    ngOnDestroy() {
        if (this.compRef) {
          this.compRef.destroy();
        }
      }
}
