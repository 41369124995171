import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Translate } from './translate/translate.module';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './shared.service';

export interface LocaleDef {
    decimalDelimiter: string; // ','
    thousandsDelimiter: string; // ' '
    currency: string; // '€'
    dateFormat: string; // 'DD.MM.YYYY'
    dateTimeFormat: string; // 'DD.MM.YYYY HH:mm'
}

/** Global translation shortener
 *
 * Why translation shortener:
 * - easy to learn, easy to remember
 * - its short
 * - optimized from 43b to 2b in single translation
 *
 * Usage:
 * Import translation function:
 * - import { _t } from '../../../services/language.service';
 *
 * Shorten your translation
 * - from: this.sharedService.translateService.instant('DELIVERY_ORDER');
 * -   to: _t('PREKLAD');
 *
 */
export function _t(text?: string) {
    // TODO: repair importing translateService
    return this.sharedService.translateService.instant(text);
}

@Injectable()
export class LanguageService {
    public static observableLanguage: ReplaySubject<string> = new ReplaySubject(1);
    public currentLocale: LocaleDef;

    constructor (
        private translate: Translate,
        private translateService: TranslateService,
        private sharedService: SharedService
    ) {
    }

    getLanguageObservable() {
        return LanguageService.observableLanguage;
    }

    /**
     * Updates language of application
     *
     * @param newValue - string code of language to be set
     */
    updateLanguage(newValue:string) {
        if (this.sharedService.apParams.languages.indexOf(newValue.toUpperCase()) < 0) {
            newValue = this.sharedService.apParams.defaultLanguage.toLowerCase();
        }

        if (this.sharedService.appSettings.language != newValue) {
            this.sharedService.appSettings.language = newValue;
            this.translateService.reloadLang(this.sharedService.appSettings.language); // for changes to occure because can be in cache
            this.translateService.use(this.sharedService.appSettings.language);
            LanguageService.observableLanguage.next(this.sharedService.appSettings.language);
            this.sharedService.appComponent.selectedLanguage = newValue;
        }
        this.currentLocale = this.getLocale()[newValue];
    }

    /**
     * Returns locale def object
     */
    public getLocale(): {[key: string]: LocaleDef} {
        return {
            'cz': {decimalDelimiter: ',', thousandsDelimiter: ' ', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'},
            'en': {decimalDelimiter: '.', thousandsDelimiter: ',', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'},
            'pl': {decimalDelimiter: ',', thousandsDelimiter: ' ', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'},
            'ru': {decimalDelimiter: ',', thousandsDelimiter: ' ', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'},
            'ua': {decimalDelimiter: ',', thousandsDelimiter: ' ', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'},
            'sk': {decimalDelimiter: ',', thousandsDelimiter: ' ', currency: '€', dateFormat: 'DD.MM.YYYY', dateTimeFormat: 'DD.MM.YYYY HH:mm'}
        };
    }

}
