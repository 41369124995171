<div class="row-container wider footer-page">
	<h3><i class="fa fa-cogs"></i> <span translate>SUPPORT</span></h3>

	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>EMAIL</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<a [href]="'mailto:' + sharedService.apParams.support.email" target="_top">{{sharedService.apParams.support.email}}</a>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>PHONE</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span>{{sharedService.apParams.support.phone}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span translate>{{'SUPPORT_AVAILABLE_WORK_DAYS_FROM_TO_AS' + sharedService.apParams.id}}</span>
			</div>
		</div>
	</div>
</div>