import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Params,
  NavigationExtras,
  ActivatedRoute,
  Router
} from '@angular/router';
import { IHeaderParams } from 'ag-grid-community';
import { EditedIdsObject, TableColumn } from '@app/model/table.model';
import { Observable } from 'rxjs';

export interface GridHeaderRendererComponentParams {
    suppressToUrl?: boolean,
    activatedRoute?: ActivatedRoute,
    router?: Router,
    editedIdsObj?: EditedIdsObject,
    observable?: Observable<any>,
    cellStyle?: {[styleAttrName: string]: string}, // { 'white-space': 'normal', 'text-align': 'center' }
    columnsObjects?: TableColumn[],
    columnsPreferenceKey?: string, // 'current-orderProductsTableColumns',
    sharedService?: any // this.sharedService
}

interface MyParams extends IHeaderParams, GridHeaderRendererComponentParams {}


@Component({
    selector: 'app-header-renderer',
    template: `<span></span>`
})
export class GridHeaderRendererComponent implements IHeaderAngularComp {
    private params: MyParams;
        // cellStyle: example: { 'white-space': 'normal', 'text-align': 'center' }
        // router
        // activatedRoute
    private eGui: any;
    currentQueryParams: any = {};

    constructor() {

    }

    agInit(params: MyParams): void {
    }

    init(params: any): void {
        this.params = params;

        // subscribe to router event
        if (!this.params.suppressToUrl) {
            this.params.activatedRoute.queryParams.subscribe((queryParams: Params) => {
                this.currentQueryParams = {};
                Object.keys(queryParams).forEach(key => {
                    this.currentQueryParams[key] = queryParams[key];
                });
            });
        }

        this.eGui = document.createElement('div');
        this.eGui.className = 'ag-header-group-cell-label ' + (!this.params.column.getColDef().sortable ? '' : 'clickable');
        this.eGui.setAttribute('title', this.params.column.getColDef().headerName);

        this.setInnerHtml(this.params.column.getSort());

        this.params.column.addEventListener('sortChanged', () => {
            const sort = this.params.column.getSort();
            this.setInnerHtml(sort);
        });

        if (this.params.column.getColDef().sortable) {
            this.eGui.addEventListener('mouseup', event => {
                if (this.params.editedIdsObj) { // do not sort if edited
                    if (Object.keys(this.params.editedIdsObj).length > 0) {
                        return;
                    }
                }

                if (!this.params.suppressToUrl) {
                    const direction = (this.params.column.getSort() === 'asc' ? 'DESC' : 'ASC');
                    this.currentQueryParams['orderBy'] = this.params.column.getColId() + ' ' + direction;
                    delete this.currentQueryParams['page'];
                    const navigationExtras: NavigationExtras = {
                          queryParams: this.currentQueryParams
                    };

                    this.params.router.navigate([], navigationExtras);

                    this.sort(this.params.column.getColId(), direction);
                } else {
                    this.params.columnsObjects.forEach((column) => {
                        if (column.orderBy && column.id === this.params.column.getColId()) {
                            column.orderDirection = column.orderDirection === 'ASC' ? 'DESC' : 'ASC';
                        } else {
                             column.orderDirection = 'ASC'
                        }
                        if (column.id === this.params.column.getColId()) {
                            this.sort(column.id, column.orderDirection);
                        }
                        column.orderBy = column.id === this.params.column.getColId();
                    });
                    this.params.sharedService.user.preferences[this.params.columnsPreferenceKey] = this.params.columnsObjects;
                    localStorage.setItem('user', JSON.stringify(this.params.sharedService.user));
                }
            });
        }

        if (this.params.observable) {
            this.params.observable.subscribe(res => {
                if (res.colId === this.params.column.getColId()) {
                    this.setInnerHtml(res.direction);
                } else {
                    this.setInnerHtml(null);
                }
            });
        }
    }

    private sort(colId: string, sort: string): void {
        this.params.columnApi.applyColumnState({
            // Sort on this column
            state: [{ colId, sort: sort.toLowerCase() }],
            // Clear sort on all other columns
            defaultState: { sort: null },
        });
    }

    getGui() {
        return this.eGui;
    }

    setInnerHtml(sort) { // ['asc',desc',null]
        let html = `<span style="margin-left: 5px; ${this.getStyleAsHtml()}" >`;
        if (sort) {
            html += `<i style="margin-top: 5px" class="fa fa-arrow-` + (sort.toLowerCase() === 'asc' ? `up` : `down`) + `"></i>`;
        }
        html += this.params.displayName + `</span>`;
        this.eGui.innerHTML = html;
    }

    /**
     * Changes { 'text-align': 'right' } to 'text-align: right;'
     */
    getStyleAsHtml() {
        let html = '';
        if (this.params.cellStyle) {
            html = Object.keys(this.params.cellStyle)
                .map(key => `${key}: ${this.params.cellStyle[key]}`)
                .join('; ');
        }
        return html;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    destroy(): void {}
}
