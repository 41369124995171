<div class="row-container bigger wider" *ngIf="params">
	<h4><span translate>PER_NOTIF_AS_USER</span></h4>

	<div style="margin-top: 10px">
		<div class="row-container clickable dropdown-item" fxLayout="row" *ngFor="let user of params.users;" (click)="selectUser(user)">
			<span *ngIf="user.lastName">{{user.lastName}} {{user.firstName}}</span>
			<span *ngIf="!user.lastName">{{user.email}}</span>
		</div>
	</div>

	<div style="padding-top: 15px;">
		<button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
	        <span translate>CLOSE</span>
	    </button>
	</div>
</div>