import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { SharedService } from '../services/shared.service';

@Directive({
  selector: '[onlyNumber]'
})
export class OnlyNumberDirective {
  locale: any;

  constructor(private el: ElementRef,
              private languageService: LanguageService,
              private sharedService: SharedService) {
    this.locale = this.languageService.getLocale();
  }

  @Input() onlyNumber: any;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    var this_ = this;
    let e = <KeyboardEvent> event;

    // if user typed delimiter and astring already contains delimiter
    if ([110, 188, 190].indexOf(e.keyCode) !== -1 && 
      (this_.onlyNumber.indexOf(this_.locale[this_.sharedService.appSettings.language].decimalDelimiter) > -1)) {
      e.preventDefault();
      return;
    }

    if ([46, 8, 9, 27, 37, 39, 13, 110, 188, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      
      // let it happen, don't do anything

      if ([110, 188, 190].indexOf(e.keyCode) !== -1) {
        localizeDelimiter();
      }
      return;
    }

    console.log(e);
    // Ensure to be valid length before and after decimal delimiter
    var testedString = this_.onlyNumber.toString().replace(/\,/g,'.');
    var match = testedString.match(/[0-9]{1,10}(\.[0-9]{1,4})?/g);
    console.log(match);
    if ((match == null || match.length > 1) && (this_.onlyNumber.length > 0)) {
      e.preventDefault();
    }

    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }  

    function localizeDelimiter() {
      setTimeout(function() {
          // replace decimalDelimiter with local delimiter
          if (this_.locale[this_.sharedService.appSettings.language].decimalDelimiter == ',') {
            this_.onlyNumber = this_.onlyNumber.toString().replace(/\./g,',');
          } else {
            this_.onlyNumber = this_.onlyNumber.toString().replace(/\,/g,'.');
          }
          this_.el.nativeElement.value = this_.onlyNumber;
      }, 0);
    }
  }
}