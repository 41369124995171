<div fxFlex="100" class="row-container wider">
	<div>
	    <div fxLayout="column" fxLayout.gt-sm="row"  class="row-container">
	    	<div fxFlex="33" class="row-container">
		    	<div class="input-container icon-after cancelable">
		            <label translate>TYPE_NAME_OF_PRODUCT</label>
		            <input type="text" class="form-control" name="productName" [(ngModel)]="productName" (ngModelChange)="searchProductsChange($event)" maxlength="255">
		            <span class="cancel clickable" *ngIf="productName.length > 0" (click)="productName = ''; cancelProductsSearch();"><i class="fa fa-times"></i></span>
		            <i class="fa fa-search"></i>
		        </div>
		    </div>

	    	<div fxFlex="33" class="row-container">
		        <div class="input-container" style="width: 100%;">
		        	<div fxLayout="row">
		            	<label translate>SEARCH_FILE_FOR_IMPORT</label>
		            	<ng-template #popFileImportTemplate><div translate>IMPORT_CSV_HELP</div><div translate>IMPORT_HELP_1</div><div translate>IMPORT_HELP_2</div></ng-template>
		            	<i class="fa fa-info-circle clickable" [popover]="popFileImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
		            </div>
		            <input #fileInput type="file" (change)="fileChangeEvent($event)" placeholder="{{ 'INSERT_CSV' | translate }}" style="max-width: 100%;"/>

		            <div class="input-container" *ngIf="brands.length > 1">
		                <label translate>BRAND</label>
		                <dropdown-component attributeName="selectedBrandFromFile" [items]="brands" [selected]="selectedBrandFromFile" (onSelect)="onSelectBrandFromFile($event)"></dropdown-component>
		            </div>  

		            <div>
				    	<button class="btn-primary" (click)="fileCsvAddToOrder()" style="margin-top: 5px;" [disabled]="addFromFileBusy || fromFileObjects.length == 0" [ngClass]="{'ec-disabled': addFromFileBusy || fromFileObjects.length == 0}">
							<i class="fa fa-plus"></i>
							<a translate>ADD_TO_ORDER</a>
						</button>
					</div>
		        </div>  
		    </div>

	    	<div fxFlex="33" class="row-container">
		        <div class="input-container">
		        	<div fxLayout="row">
		            	<label translate>INSERT_DATA_FOR_ORDER</label>
		            	<ng-template #popClipboardImportTemplate><div translate>IMPORT_CLIPBOARD_HELP</div><div translate>IMPORT_HELP_1</div><div translate>IMPORT_HELP_2</div></ng-template>
		            	<i class="fa fa-info-circle clickable" [popover]="popClipboardImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
		            </div>
		            <textarea #pasteTextarea class="form-control" [(ngModel)]="pastedExcel" (ngModelChange)="pasteExcelChanged(pastedExcel)" placeholder="{{ 'INSERT_CTRL_V' | translate }}" (keydown)="pasteExcelKeyDown($event, pasteTextarea)"></textarea>

		            <div class="input-container" *ngIf="brands.length > 1">
		                <label translate>BRAND</label>
		                <dropdown-component attributeName="selectedBrandPasted" [items]="brands" [selected]="selectedBrandPasted" (onSelect)="onSelectBrandPasted($event)"></dropdown-component>
		            </div>  

		            <div>
				    	<button class="btn-primary" (click)="ctrlVAddToOrder()" style="margin-top: 5px;" [disabled]="addFromPasteBusy || pastedExcel.length == 0" [ngClass]="{'ec-disabled': addFromPasteBusy || pastedExcel.length == 0}">
							<i class="fa fa-plus"></i>
							<a translate>ADD_TO_ORDER</a>
						</button>
					</div>
		        </div>  
		    </div>
	    </div>
	</div>

    <div *ngIf="searchProductsByNameString.length > 0">
    	<ecm-table [dataGetter]="getProducts" 
                       [optionsGetter]="getProductsGridOptions"
                       [columnsGetter]="getProductsColumns"
                       [columnsDefsGetter]="getProductsColumnsDefs"
                       [total]="totalProductsRows"
                       [observableRefresh]="subjectProductsRefresh.asObservable()"
                       [busy]="searchBusy"
                       [suppressToUrl]="true"
                       [tableNamePrefix]="'Products'"></ecm-table>
    </div>

    <div fxLayoutAlign="start center">
		<h3 style="display: inline-block;">
			<img [src]="'../../assets/images/cart_' + sharedService.apParams.iconSet + '.png'"
				style="width: 25px; margin: 0 5px;"/> <span translate>ITEMS_IN_CART</span>
		</h3>
		<ng-template #invalidItemsTooltip><span translate>BASKET_CONTAINS_INVALID_ITEMS_TOOLTIP</span></ng-template>
		<span style="margin: 10px 0 0 10px;" class="fnt-red clickable" *ngIf="invalidItemsCount > 0"
			[popover]="invalidItemsTooltip" triggers="mouseenter:mouseleave"
			(click)="filterInvalidSubject.next()" translate>
			BASKET_CONTAINS_INVALID_ITEMS
		</span>
	</div>
    <app-current-order-products-and-detail [invalidItemsSubject]="invalidItemsSubject" [filterInvalidSubject]="filterInvalidSubject"></app-current-order-products-and-detail>
</div>