import { Injectable } from "@angular/core";
import { EcmHttpService, EcmHttpQueryParams } from '@services/http/ecm.http.service';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { CCheck, CChecksResponse } from '@model/cCheck.model';
import { SharedService } from '@services/shared.service';

@Injectable()
export class CCheckService {
    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService
    ) {}

    public getCChecks(query: EcmHttpQueryParams): Observable<CChecksResponse> {
        return this.http.get('/c-check', this.http.prepareOptions(query)).pipe(
            map((response: CChecksResponse) => {
                const rows: CCheck[] = response.rows.map(row => ({...row,
                    entityTranslated: this.translateService.instant(`MODULE_${row.entityName}`),
                    url: `c-check-${this.sharedService.camelCaseToDashed(row.entityName)}/${row.id}/${row.start}`
                }));
                return {...response, rows};
            })
        );
    }

    public exportCChecks(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get('/c-check', this.http.prepareOptions(query));
    }

    public getCCheckItems(cCheckId: number, query: EcmHttpQueryParams): Observable<{rows: any[], totalCount: number}> {
        return this.http.get(`/c-check/${cCheckId}`, this.http.prepareOptions(query));
    }

    public exportCCheckItems(cCheckId: number, query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get(`/c-check/${cCheckId}`, this.http.prepareOptions(query));
    }

}
