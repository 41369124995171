import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';

@Injectable()
export class DeliveryOrdersItemsGridService {


    constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            {id: 'number', name: 'DELIVERY_NUMBER', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 130},
            ...(this.sharedService.user.availableAuthorizedSellers.length > 1 ? [{
                id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            ...(this.sharedService.user.availableCustomers.length > 1 ? [{
                id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            {id: 'deliveryCreated', name: 'DATE_OF_ISSUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'eCommProductId', name: 'PRODUCT_ID', checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'orderNumberEComm', name: 'ORDER_ID', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'deliveryAmount', name: 'AMOUNT_SHIPMENT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'packing', name: 'PACKING', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'rowPrice', name: 'TOTAL_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'deliveryDate', name: 'DELIVERY_DATE', checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'itemNote', name: 'ORDER_INTERNAL_NUMBER', checked: true, orderBy: false, orderDirection: 'ASC', width: 100}
        ];

        original = this.tableService.filterSuppressColumns(original, 'deliveryOrdersItems');
        const restored = this.sharedService.user.preferences['delivery-orders-itemsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'delivery-orders-itemsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs: any[] = [
            {
                headerName: this.translateService.instant(this.getColumn('number').name),
                field: 'number',
                width: this.getColumn('number').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryCreated').name),
                field: 'deliveryCreated',
                width: this.getColumn('deliveryCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this.translateService.instant(this.getColumn('eCommProductId').name),
                field: 'eCommProductId',
                width: this.getColumn('eCommProductId').width,
                suppressSizeToFit: true,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: '',
                field: 'postsCount',
                width: this.getColumn('postsCount').width,
                maxWidth: 50,
                minWidth: 50,
                cellRendererFramework: GridCommentRendererComponent,
                cellRendererParams: {itemArea: 'delivery-orders', idAttrName: 'deliveryOrderId', loadForEachItem: true},
            },
            {
                headerName: this.translateService.instant(this.getColumn('orderNumberEComm').name),
                field: 'orderNumberEComm',
                width: this.getColumn('orderNumberEComm').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryAmount').name),
                field: 'deliveryAmount',
                width: this.getColumn('deliveryAmount').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('packing').name),
                field: 'packing',
                width: this.getColumn('packing').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => this.translateService.instant('PACKING_' + params.data.packing)
            },
            {
                headerName: this.translateService.instant(this.getColumn('rowWeight').name),
                field: 'rowWeight',
                width: this.getColumn('rowWeight').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3}
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPrice').name),
                field: 'unitPrice',
                minWidth: 80,
                width: this.getColumn('unitPrice').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridPriceRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('rowPrice').name),
                field: 'rowPrice',
                width: this.getColumn('rowPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryDate').name),
                field: 'deliveryDate',
                width: this.getColumn('deliveryDate').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_ITEM_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        green: 'P'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemNote').name),
                field: 'itemNote',
                width: this.getColumn('itemNote').width,
                cellStyle: {'text-align': 'left'}
            }
        ];

        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this.translateService.instant(this.getColumn('authorizedSellerName').name),
                field: 'authorizedSellerName',
                width: this.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this.translateService.instant(this.getColumn('customerName').name),
                field: 'customerName',
                width: this.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'deliveryOrdersItems');
        return colDefs;
    }

    public getFilterItems(authorizedSellers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            {id: 'number', name: 'DELIVERY_NUMBER', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth},
            {id: 'orderNumberEComm', name: 'ORDER_ID', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth},
            {id: 'productName', name: 'PRODUCT_ID', type: TableFilterItemTypes.text, maxLength: 255, value: '', operator: TableFilterItemOperators.likeBoth},
            {id: 'itemNote', name: 'ORDER_INTERNAL_NUMBER', type: TableFilterItemTypes.text, maxLength: 255, value: '', operator: TableFilterItemOperators.likeBoth},
            {
                id: 'deliveryCreated', 
                name: 'DATE_OF_ISSUE', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: '',
                showYearPicker: true
            },
            {id: 'deliveryDate', name: 'DELIVERY_DATE', type: TableFilterItemTypes.dateRange, valueFrom: '', valueTo: ''},
            {id: 'itemState', name: 'STATUS', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'X', name: 'DELIVERY_ORDER_ITEM_STATE_X', dotId: 'X'},
                    {id: 'N', name: 'DELIVERY_ORDER_ITEM_STATE_N', dotId: 'N'},
                    {id: 'P', name: 'DELIVERY_ORDER_ITEM_STATE_P', dotId: 'P'}
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['X', 'N']
                }
            },
        ]
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: TableFilterItemTypes.multiselect, value: '', values: this.sharedService.user.availableAuthorizedSellers});
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({
                id: 'customer.id', 
                name: 'CUSTOMER', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: this.sharedService.user.availableCustomers.map(customer => 
                    ({...customer, id: customer.id.toString()}))
            });
        }
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'deliveryOrdersItems');
        return filterItems;
    }

}

