import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { LanguageService } from './language.service';
import { IMyOptions } from 'mydatepicker';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { LocalNumberPipe } from 'app/locale.pipes.module';
import { PackingOption } from '@app/model/basket.model';

@Injectable()
export class FormService {
    numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    
    constructor (
        private sharedService: SharedService,
        private languageService: LanguageService,
        private translateService: TranslateService
    ) {
    }

    /**
     * Returns default date picker options
     *
     * @param suppressDisabledDates [optional] - if true, default disabled dates will not be disabled
     * @param minDate [optional] - if defined, set minDate as minimal date
     * @param addDays [optional] - if defined, add these number of days from now and set it as minimal date
     */
    public getDatePickerOptions(suppressDisabledDates?: boolean, minDate?: moment, addDays?: number): IMyOptions {
        const options: IMyOptions = {
            dateFormat: this.languageService.getLocale()[this.sharedService.appSettings.language].dateFormat.toLowerCase(),
            inline: false,
            width: '150px',
            showClearDateBtn: true,
            selectionTxtFontSize: '110%'
        }
        if (!suppressDisabledDates) {
            const disableUntil = minDate ? minDate.add(-1, 'days') : this.getClosestDeliveryDate(true, addDays);
            const afterYear = moment().add(551, 'days'); // 550
            options.disableWeekends = true;
            options.disableUntil = {year: disableUntil.year(), month: disableUntil.month() + 1, day: disableUntil.date()};
            options.disableSince = {year: afterYear.year(), month: afterYear.month() + 1, day: afterYear.date()};
            options.disableDays = this.sharedService.params.holidays;
        }
        return options
    }

    public getDateStringFromIsoDate(isoStringDate): string {
    	let momentDate = moment(isoStringDate);
    	if (!momentDate.isValid()) {
    		return isoStringDate;
    	}
    	return momentDate.format("YYYY-MM-DD");
    }

    /**
     * Returns ISO string from given datepicker model
     *
     * @param modelFromDatepicker {year: <year>, month: <month>, , day: <day>}
     * @param time [optional]     js date object
     */
    getIsoStringFromDatepickerModel(modelFromDatepicker: any, time?: any): string {
        var dateMoment = moment();
        if (modelFromDatepicker.jsdate) {
            dateMoment = moment(modelFromDatepicker.jsdate);
        } else {
            dateMoment = dateMoment.set({
               'year' : modelFromDatepicker.date.year,
               'month'  : modelFromDatepicker.date.month - 1, 
               'date' : modelFromDatepicker.date.day
            });
        }
        if (time) {
            dateMoment.set({
               'hour' : time.getHours(),
               'minute'  : time.getMinutes(), 
               'second' : 0
            });
        }

        return dateMoment.toISOString();
    }

    /**
     * Returns moment date: today + 2 work days - for the purpose of the calendar - it DOES NOT include the result day
     * @param useCutOfTime [optional] - if to use cut of time
     * @param addDays      [optional] - if defined, add these number of days from now and set it as minimal date
     * @param fromDate     [optional] - if used, the method will start from that day, not from today
     */
    getClosestDeliveryDate(useCutOfTime?: boolean, addDays?: number, fromDate: Date = new Date()) {
        return this._getClosestDeliveryDate(false, useCutOfTime, addDays, fromDate);
    }

    /**
     * Returns moment date: today + 2 work days - for the purpose of the calendar - it DOES include the result day
     * @param useCutOfTime [optional] - if to use cut of time
     * @param addDays      [optional] - if defined, add these number of days from now and set it as minimal date
     * @param fromDate     [optional] - if used, the method will start from that day, not from today
     */
    getClosestDeliveryDateIncluding(useCutOfTime?: boolean, addDays?: number, fromDate: Date = new Date()) {
        let moment = this._getClosestDeliveryDate(true, useCutOfTime, addDays, fromDate);
        moment.hours(0);
        moment.minutes(0);
        moment.seconds(0);
        moment.milliseconds(0);
        return moment;
    }

    /**
     * Returns moment date: today + 2 work days
     * @param useCutOfTime [optional] - if to use cut of time
     * @param addDays      [optional] - if defined, add these number of days from now and set it as minimal date
     * @param fromDate     [optional] - if used, the method will start from that day, not from today
     */
    _getClosestDeliveryDate(includeDay: boolean, useCutOfTime?: boolean, addDays?: number, fromDate: Date = new Date()) {
        let workDaysFoundCount = 0;
        let addDaysUntil = 0;

        if (useCutOfTime) {
            let today      = moment.tz(fromDate, "Europe/Bratislava");
            let cutOffDate = moment.tz(fromDate, "Europe/Bratislava");
            cutOffDate.set({
               'hour'  : this.sharedService.params.minDeliveryDate.cutOffTime.h,
               'minute': this.sharedService.params.minDeliveryDate.cutOffTime.m, 
               'second': this.sharedService.params.minDeliveryDate.cutOffTime.s
            });
            addDaysUntil += today > cutOffDate ? this.sharedService.params.minDeliveryDate.dPlusAfterCutOffTime : this.sharedService.params.minDeliveryDate.dPlusBeforeCutOffTime;
        }
        if (addDays > 0) {
            addDaysUntil = addDays;
        }
        let plusDaysMoment = moment.tz(fromDate, "Europe/Bratislava");

        while (workDaysFoundCount != addDaysUntil) {
            // if (this.sharedService.params.holidays.filter(function(date){
            //       return date.year === plusDaysMoment.year() && date.month === plusDaysMoment.month() + 1 && date.day === plusDaysMoment.date();
            //     }).length === 0 && plusDaysMoment.day() != 6 && plusDaysMoment.day() != 0) { // if not in holidays and not saturday neither sunday
            //     workDaysFoundCount++;
            // }
            if (this.isWorkDay(plusDaysMoment))
                workDaysFoundCount++;

            if(includeDay)
                plusDaysMoment.add(1, 'days');
            else
                if (workDaysFoundCount < addDaysUntil) {plusDaysMoment.add(1, 'days');}
        }

        while (!this.isWorkDay(plusDaysMoment))
            plusDaysMoment.add(1, 'days');

        return plusDaysMoment;
    }

    /**
     * Tests if the specified moment is a work day (if not in holidays and not saturday neither sunday)
     * @param momentDate 
     */
    isWorkDay(momentDate): boolean {
        return this.sharedService.params.holidays
            .filter(date => date.year === momentDate.year() && date.month === momentDate.month() + 1 && date.day === momentDate.date())
            .length === 0 && momentDate.day() != 6 && momentDate.day() != 0;
    }

    /**
     * Get packing options returned from server, map with PACKING_ prefix
    */
    public getPackingOptionsForRow(row: {packingOptions: PackingOption[], [otherProps: string]: any}): {id: string, name: string}[] {
        return row.packingOptions.map(item => {
            return {
                id: item.packing,
                name: this.translateService.instant('PACKING_' + item.packing) +
                    (item.priceCoefficient > 1 ? (' (+' +
                        new LocalNumberPipe(
                            this.languageService).transform((Math.round((item.priceCoefficient - 1) * 1000000) / 10000).toString(),
                                this.sharedService.appSettings.language
                            ) + '%)') : '') // add coefficient percentage to name of packing item
            };
        });
    }

}
