import { Component, EventEmitter, Input, Output, ViewChildren, ViewContainerRef } from '@angular/core';
import { Animations } from '../../animations';
import { BrandCategory } from '../../model/brand.category.model';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user/user.service';
import { CatalogueService, CatalogueModes } from '@app/services/catalogue/catalogue.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-accordion-menu',
    templateUrl: 'accordion.menu.component.html',
    styleUrls: ['./accordion.menu.component.scss'],
    animations: [Animations.slideInOut]
})
export class AccordionMenuComponent {
    @Input() categories: BrandCategory[];
    @Input() productionGroup1: string;
    @Input() productionGroup2: string;
    @Input() catalogueMode: CatalogueModes;
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    openCategoryTimeout: any;

    constructor(
        public sharedService: SharedService,
        private router: Router,
        private userService: UserService,
        public catalogueService: CatalogueService
    ) {
    }

    public toggleCategory(category: BrandCategory): void {
        category.collapsed = !category.collapsed;
    }

    public openCategory(category: BrandCategory): void {
        category.collapsed = false;
    }

    public openCategoryAndLoad(category: BrandCategory): void {
        this.openCategory(category);
        this.onSelect.emit();
    }

    public openCategoryDelayed(category: BrandCategory): void {
        if (!category.collapsed) { return; }
        clearTimeout(this.openCategoryTimeout);
        var this_ = this;
        this.openCategoryTimeout = setTimeout(() => {
            this_.openCategory(category);
        }, 400);
    }

    cancelTimeoutToOpenCategory() {
        clearTimeout(this.openCategoryTimeout);
    }

    public onCategoryAllClick(): void {
        this.onLinkClick({group1: 'all'});
        // [routerLink]="['/' + sharedService.appSettings.language + '/' + catalogueService.getRoute(catalogueMode) + '/']" [queryParams]="{group1: 'all'}"
        this.router.navigate(['/' + this.sharedService.appSettings.language + '/' + this.catalogueService.getRoute(this.catalogueMode) + '/'],
            {queryParams: {group1: 'all'}});
    }

    public onLinkClick(categoryQuery): void {
        // set stored filters
        // this.userService.setUserPreference(this.sharedService.area + 'Filter', categoryQuery, true);
        this.userService.setUserPreference(this.sharedService.getUserPreferenceKey('Filter'), categoryQuery, true);

        this.onSelect.emit();
    }
}
