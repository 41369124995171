import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    selector: 'app-mail-to-renderer',
    template: `
                <a [href]="'mailto:' + params.value" target="_top">{{params.value}}</a>
    		  `
})
export class GridMailtoRendererComponent implements AgRendererComponent {
    params: any;

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.params.value = this.params.value ? this.params.value : '';
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}