import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Animations } from '../../../animations';
import * as moment from 'moment';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user/user.service';
import { BasketService } from '../../../services/basket/basket.service';
import { CurrentOrderGridService } from '../../../services/basket/current.order.grid.service';
import { CatalogueService, CatalogueModes } from '../../../services/catalogue/catalogue.service';
import { ImportBasketErrorDialogService } from '../importBasketErrorDialogComponent/import.basket.error.dialog.service';
import { Subject } from 'rxjs';
import { MonitoringService } from '../../../services/monitoring/monitoring.service';

@Component({
  selector: 'app-current-order',
  templateUrl: 'current.order.component.html',
})
export class CurrentOrderComponent implements OnDestroy {
    @ViewChild('fileInput', { static: true }) fileInputElement: any;
    productName = '';
    pastedExcel = '';
    fromFileObjects: {amountOrdered: string, eCommProductId: string, brandCode: string, deliveryDate: string}[] = [];
    pastedObjects: {amountOrdered: string, eCommProductId: string, brandCode: string, deliveryDate: string}[] = [];
    addFromFileBusy: boolean;
    addFromPasteBusy: boolean;
    brands:  any[] = [];
    selectedBrandFromFile: {id: string, name: string};
    selectedBrandPasted: {id: string, name: string};

    searchTimeout: any;
    waitUntilNoBusyTimeout: any;
    searchProductsByNameString = '';
    searchBusy: boolean;
    totalProductsRows: number;
    subjectProductsRefresh: Subject<any>;

    // Monitoring
    timerStart = 0; // reseter for timer - needed to input delay monitoring
    interval;       // interval as setInterval - needed to input delay monitoring
    monitoringLog: any[] = [];        // monitoring list of all logs

    subjectReloadCurrent: Subject<any>;

    invalidItemsSubject: Subject<number> = new Subject();
    invalidItemsCount = 0;
    filterInvalidSubject = new Subject();

    constructor(
        private cdRef: ChangeDetectorRef,
        public sharedService: SharedService,
        private userService: UserService,
        private basketService: BasketService,
        private currentOrderGridService: CurrentOrderGridService,
        private catalogueService: CatalogueService,
        private importBasketErrorDialogService: ImportBasketErrorDialogService,
        private monitoringService: MonitoringService
    ) {
        this.subjectProductsRefresh = new Subject<any>();
        this.subjectReloadCurrent = basketService.getReloadCurrentSubject();

        this.getProducts = this.getProducts.bind(this);
        this.getProductsGridOptions = this.getProductsGridOptions.bind(this);
        this.getProductsColumns = this.getProductsColumns.bind(this);
        this.getProductsColumnsDefs = this.getProductsColumnsDefs.bind(this);

        this.productName = '';
        this.searchProductsByNameString = '';

        this.sharedService.brands.forEach(brand => {
            this.brands.push({id: brand.code, name: brand.code});
        });
        this.selectedBrandFromFile = this.brands[0];
        this.selectedBrandPasted = this.brands[0];

        this.invalidItemsSubject.subscribe((invalidCount: number) => {
            this.invalidItemsCount = invalidCount;
        });
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }

    /** MONITORING - user search input */
    // searchBlur() {
    //     this.startTimer();
    // }
    // searchFocus() {
    //     this.stopTimer();
    // }

    startTimer() {
        const this_ = this;
        const addLog = {
            activity: 'PRODUCT_SEARCH',
            eCommProductId: null,
            productionGroupCode1: 'CART',
            productionGroupCode2: null
        };
        this.interval = setInterval(() => {
            if ( this.timerStart === 2) {
                this_.stopTimer();
                this.timerStart = 0;
                if (this.productName.length > 0) {
                    addLog.eCommProductId = this.productName;
                };

                if (addLog.eCommProductId !== null) {
                    // this_.monitoringLog.push(addLog);
                    this.monitoringService.setCatalogActivityToMonitoring(addLog).subscribe(
                        () => {
                            // do nothing because timer is stopped, wait for another change event
                        },
                        error => {
                            console.log(error);
                        }
                   );
                }
                // if you want to trace behavior of this timer, uncomment row below
                // console.table(this.monitoringLog);
            } else {
                this.timerStart++;
            }
        }, 1000);
    }
    stopTimer() {
      clearInterval(this.interval);
    }
    restartTimer() {
      clearInterval(this.interval);
      this.timerStart = 0;
      this.startTimer();
    }


    /**
     * Clears timeout and start new timeuot, after wich search string is set to query string to call request
     */
    searchProductsChange(newValue: string) {
        clearTimeout(this.searchTimeout);
        if (this.searchBusy) {
            this.waitUntilNotBusy();
            return;
        }
        this.searchTimeout = setTimeout(() => {
            this.searchProductsByNameString = newValue;
                this.cdRef.detectChanges();
                if (this.searchProductsByNameString.length > 0) {
                    this.searchBusy = true
                    this.userService.setUserPreference('current-orderProductsTablePage', 0);
                    this.subjectProductsRefresh.next(0);

                if ( this.sharedService.user.representative !== 'AS' ) {
                    this.restartTimer(); // monitoring
                }
              }
        }, 800);
    }

    waitUntilNotBusy() {
        clearTimeout(this.waitUntilNoBusyTimeout);
        this.waitUntilNoBusyTimeout = setTimeout(() => {
            if (!this.searchBusy) {
                this.searchProductsChange(this.productName);
            } else {
                this.waitUntilNotBusy();
            }
        }, 200);
    }

    /**
     * Cancels search of products
     */
    cancelProductsSearch() {
        this.searchProductsChange('');
    }

    /**
     * File was chosen in file input for order from file, call get objects
     *
     * @param fileInput - chosen file
     */
    fileChangeEvent(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.fromFileObjects = this.getObjectsFromSeparatedString(reader.result, this.selectedBrandFromFile.id);
            }

            reader.readAsText(fileInput.target.files[0]);
        }
    }

    /**
     * Text was pasted or changed in textarea for clippboard import of order
     *
     * @param newValue - string of changed value of textarea
     */
    pasteExcelChanged(newValue: string) {
        this.pastedObjects = this.getObjectsFromSeparatedString(newValue, this.selectedBrandPasted.id);
    }

    /**
     * Change tab key to \t and not go to next focusable element when tab is pressed
     * @param event - keydown event
     * @param element - textarea html element
     */
    pasteExcelKeyDown(event, element) {
        if (event.keyCode === 9) {
            const v = element.value,
                s = element.selectionStart,
                e = element.selectionEnd; element.value = v.substring(0, s) + '\t' + v.substring(e); element.selectionStart = element.selectionEnd = s + 1;
            this.pasteExcelChanged(element.value);
            return false;
        }
    }

    /**
     * Returns object parsed from inpud separated string string
     *
     * @param separatedString  - string separated by new lines and by separator in lines
     * @param separator  - separator used in lines
     */
    getObjectsFromSeparatedString(separatedString: any, brandCode: string) {
        const objects = [];
        let separator: string;
        const rows = separatedString.split(/\r\n|\r|\n/g);
        if (rows.length > 0) {
            separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
        }
        rows.forEach(function(row: string) {
            if (row.length > 0) {
                let amount = '';
                let code = '';
                let date = '';
                const splittedRowBySeparator = row.split(separator);
                if (splittedRowBySeparator.length > 0) {
                    code = splittedRowBySeparator[0];
                }
                if (splittedRowBySeparator.length > 1) {
                    amount = splittedRowBySeparator[1].replace(/\ /g, '');
                }
                if (splittedRowBySeparator.length > 2) {
                    date = '';
                    if (splittedRowBySeparator[2].length !== 0) {
                        const momentDate = moment(splittedRowBySeparator[2], 'D.M.YYYY')
                        if (momentDate.isValid()) {
                            date = momentDate.toISOString()
                        } else {
                            date = splittedRowBySeparator[2];
                        }
                    }
                }

                if (amount.length > 0 || code.length > 0 || date.length > 0) {
                    objects.push({amountOrdered: amount, eCommProductId: code, brandCode: brandCode, deliveryDate: date});
                }
            }
        });
        return objects
    }

    /**
     * Adds products to order from object parsed from file
     */
    fileCsvAddToOrder() {
        this.addFromFileBusy = true;
        this.basketService.addToOrder(this.fromFileObjects)
        .subscribe(data => {
            // reset file input
            this.fileInputElement.nativeElement.value = '';
            this.fromFileObjects = [];
            this.addFromFileBusy = false;
            this.subjectReloadCurrent.next();
            if (data.errors && data.errors.length > 0) {
                this.showImportBasketErrorDialog(data);
            }
        }, err => {
            this.addFromFileBusy = false;
            console.log(err);
        });
    }

    /**
     * Adds products to order from object parsed from clipboard
     */
    ctrlVAddToOrder() {
        this.addFromPasteBusy = true;
        this.basketService.addToOrder(this.pastedObjects)
        .subscribe(data => {
            // reset textarea input
            this.pastedExcel = '';
            this.pastedObjects = [];
            this.addFromPasteBusy = false;
            this.subjectReloadCurrent.next();
            if (data.errors && data.errors.length > 0) {
                this.showImportBasketErrorDialog(data);
            }
        }, err => {
            this.addFromPasteBusy = false;
            console.log(err);
        });
    }

    /**
     * Onselect brand
     */
    onSelectBrandFromFile(item) {
        this.selectedBrandFromFile = {id: item.selected.id, name: item.selected.name};
        this.fromFileObjects = this.fromFileObjects.map(obj => Object.assign({}, obj, { brandCode: this.selectedBrandFromFile.id }));
    }
    onSelectBrandPasted(item) {
        this.selectedBrandPasted = {id: item.selected.id, name: item.selected.name};
        this.pastedObjects = this.pastedObjects.map(obj => Object.assign({}, obj, { brandCode: this.selectedBrandPasted.id}));
    }

    /**
     * Shows error dialog of import to basket
     */
    showImportBasketErrorDialog(data: any) {
        this.importBasketErrorDialogService.confirm(data).subscribe(
            res => {
                if (res.rows) {
                    this.basketService.addToOrder(res.rows)
                    .subscribe(data2 => {
                        if (data2.errors && data2.errors.length > 0) {
                            this.showImportBasketErrorDialog(data2);
                        }
                        this.subjectReloadCurrent.next();
                    }, err => {
                        console.log(err);
                    });
                }
            }
        );
    }


    /**
     * Data getter for table
     */
    getProducts(params) {
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            'eCommProductId.operator': 'likeBoth',
            'eCommProductId.value': this.searchProductsByNameString,
        };

        const columns = this.getProductsColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].orderBy) {
                query.orderBy = columns[i].id + ' ' + columns[i].orderDirection;
            }
        }

        this.catalogueService.getProducts(query, CatalogueModes.Catalogue)
        .subscribe(data => {
            this.userService.setUserPreference('current-orderProductsTablePage', (params.startRow / (params.endRow - params.startRow)) + 1);
            this.totalProductsRows = data.total;
            params.successCallback(data.rows, data.total);
            this.searchBusy = false;
        }, err => {
            this.searchBusy = false;
            console.log(err);
        });
    }
    getProductsGridOptions() {
        return this.currentOrderGridService.getProductGridOptions(this.sharedService.user.preferences['current-orderProductsTablePage']);
    }
    getProductsColumns() {
        return this.currentOrderGridService.getProductColumns();
    }
    getProductsColumnsDefs() {
        return this.currentOrderGridService.getProductsColumnDefs();
    }
}
