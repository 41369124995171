import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { DeliveryOrder } from '../../model/delivery.order.model';
import { switchMap, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { PersonCreated } from '@model/personCreated.model';
import { Observable } from 'rxjs';

interface GetDeliveryOrdersResponse {
    rows: DeliveryOrder[],
    total: number,
    personCreatedSYS: PersonCreated[]
}
@Injectable()
export class DeliveryOrdersService {
    
    constructor (
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService,
    ) {
        this.getProductOnDeliveryOrders = this.getProductOnDeliveryOrders.bind(this);
    }

    /**
     * Loads list of customer of authorized seller
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getDeliveryOrders (query): Observable<GetDeliveryOrdersResponse> {
        let url = `/delivery-orders`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => { 
            let deliveryOrders: DeliveryOrder[] = [];
            data.rows.forEach(function(item){
                deliveryOrders.push(
                    new DeliveryOrder(
                        item.id,
                        item.customerId,
                        item.customerName,
                        item.authorizedSellerName,
                        item.deliveryNumberERP,
                        item.number,
                        item.deliveryNote,
                        item.state,
                        item.deliveryCreated,
                        item.deliveryDate,
                        item.sortName,
                        item.totalPrice,
                        item.totalWeight,
                        item.currency,
                        'delivery-order/' + item.id
                    )
                );
            })

            const personCreatedSYS: PersonCreated[] = data.personCreatedSYS.map(person => ({
                id: person.userId? person.userId.toString() : '',
                name: person.fullName,
                customerId: person.customerId
            }));
            return { rows: deliveryOrders, total: data.totalCount, personCreatedSYS: personCreatedSYS };
        }));
    }

    /**
     * Loads delivery order header
     *
     * @param id - id of delivery irder
     */
    getDeliveryOrder (id: number) {
        return this.http.get(`/delivery-orders/` + id + `/header`)
        .pipe(map((data) => {
            return new DeliveryOrder(
                data.id,
                data.customerId,
                data.customerName,
                data.authorizedSellerName,
                data.deliveryNumberERP,
                data.number,
                data.deliveryNote,
                data.state,
                data.deliveryCreated,
                data.deliveryDate,
                data.sortName,
                data.totalPrice,
                data.totalWeight,
                data.currency,
                'delivery-order/' + data.id
            );
        }));
    }

    /**
     * Loads items list of delivery order
     *
     * @param id    - id of delivery order
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getDeliveryOrderItems(id: number, query: any) {
        var this_ = this;
        let url = `/delivery-orders/`+ id + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/delivery-orders`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports detail items
     */
    exportDetail(id, query) {
        let url = `/delivery-orders/`+ id + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports delivery order with given id to given format file with name containing given deliveryNumber
     * @param ids - array of ids to be exported, if null export all
     * @param invoiceNumber - number of delivery order to be exported
     * @param format - format to be exported
     * @param suppressFirstPartOfFileName - true if not to include first part of file name - to use only deliveryNumber value for file name
     */
    export(ids: number[], deliveryNumber: string, format: string, suppressFirstPartOfFileName?: boolean) {
        let columns = ['authorizedSeller', 'deliveryNumber', 'deliveryCreated', 'personCreated', 'deliveryDate',
            'productName', 'brandCode', 'packing', 'itemNote', 'currency', 'itemState', 'weight',
            'totalWeight', 'deliveryAmount', 'unitPrice', 'totalPrice'];
        let query:any = {
            exportToFormat: format,
            exportColumns: columns,
            orderBy: 'number ASC',
            'state.operator': 'in',
            'state.value': 'S,C,P'

        }
        if (ids) {
            query['id.operator'] = 'in';
            query['id.value'] = ids.join(',');
        } else {
            query = Object.assign(this.sharedService.lastDeliveryOrdersQueryParams, query);
            delete query.page;
        }
        let url = `/delivery-orders-items`;
        this.http.get(url, this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, {responseType: 'text'}))
        ).subscribe(data => {
            let mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            let blob = new Blob(['\ufeff' + data], {type: mediaType});
            let filename = (suppressFirstPartOfFileName ? '' : (this.translateService.instant("DELIVERY_ORDER") + '_')) + deliveryNumber + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }

    /**
     * Returns array of delivery order information and amount on delivery order for product of given purchase order 
     *
     * @param id    - id of purchase order item
     */
    getProductOnDeliveryOrders(id: string) {
        return this.http.get(`/delivery-orders-with-product?id=` + id);
    }
}

