<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container clickable" style="margin-right: 5px;" routerLink="/{{sharedService.appSettings.language}}/invoices">
            <span translate>INVOICES_SUMMARY</span>
        </span>
        <span class="row-container tab-active clickable">
            <span translate>INVOICES_ITEMS</span>
        </span>
    </div>

    <div fxLayout="column">
    	<ecm-table [dataGetter]="getInvoicesItems" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>