import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { LocalNumberPipe } from '../../../../locale.pipes.module';

@Component({
    selector: 'app-number-renderer',
    template: `<span>{{params.value == 0 && params.hideZeroValue ? '' : params.value | localnumber:sharedService.appSettings.language:params.addDecimalZero:params.decimals}}</span>`
})
export class GridNumberRendererComponent implements AgRendererComponent {
    params: any;

    constructor(public sharedService: SharedService) {

    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
