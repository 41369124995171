import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridLinkRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { Subject } from 'rxjs';
import { GridSelectedRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { PrfqStates, Prfq, PrfqActions } from '@app/model/prfq.model';
import { GridGenericRowActionsRendererComponent, GridGenericRowActionsRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';
import { PrfqService } from '../prfq.service';
import { DiscussionDialogService } from '@components/discussion/discussionDialogComponent/discussion.dialog.service';
import { AttachmentDialogService } from '@app/components/attachmentComponent/attachmentDialogComponent/attachment.dialog.service';
import { map } from 'rxjs/operators';
import { Representative } from '@app/model/user.model';
import { PrfqPermissionService } from '../prfq.permission.service';
import { AppAreas } from '@app/model/appArea.model';

@Injectable()
export class PrfqsGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    private selected$: Subject<any> = new Subject<any>();
    public allSelected$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private prfqService: PrfqService,
        private discussionDialogService: DiscussionDialogService,
        private attachmentDialogService: AttachmentDialogService,
        private prfqPermissionService: PrfqPermissionService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas.prfqs, // 'prfqs',
            gridNameCamel: 'prfqs', // 'prfqs',
            columnList: [
                { id: 'selected', name: 'SELECTED', hidden: false, checked: true, orderBy: false, width: 30, alwaysVisible: true },
                { id: 'prfqNumber', name: 'RFQ_NUMBER', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 130 },
                { id: 'supplierName', name: 'SUPPLIER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'prfqState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
                { id: 'userCreatedSortName', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
                { id: 'dateClosed', name: 'DATE_OF_CLOSING', checked: true, orderBy: false, orderDirection: 'DESC', width: 120 },
                { id: 'prfqNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
            ].filter(col => this.prfqPermissionService.isPrfqColumnAvailable(col.id))
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.getSelection(),
                    observable: this.selected$,
                    editedIdsObj: this.editedIdsObj
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.getSelection(),
                    editedIdsObj: this.editedIdsObj,
                    observable: this.allSelected$
                },
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('prfqNumber').name),
                field: 'prfqNumber',
                width: this.getColumn('prfqNumber').width,
                minWidth: this.getColumn('prfqNumber').width,
                cellRendererFramework: GridLinkRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('supplierName').name),
                field: 'supplierName',
                width: this.getColumn('supplierName').width,
                minWidth: this.getColumn('supplierName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            },
            {
                headerName: this.translateService.instant(this.getColumn('prfqState').name),
                field: 'prfqState',
                width: this.getColumn('prfqState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'PRFQ_STATE_',
                    renderColors: this.prfqService.getPrfqStateColors()
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('userCreatedSortName').name),
                field: 'userCreatedSortName',
                width: this.getColumn('userCreatedSortName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateClosed').name),
                field: 'dateClosed',
                width: this.getColumn('dateClosed').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('prfqNote').name),
                field: 'prfqNote',
                width: this.getColumn('prfqNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('prfqNote').checked,
            },
            {
                headerName: this.translateService.instant(this.getColumn('actions').name),
                field: 'actions',
                width: this.getColumn('actions').width,
                minWidth: this.getColumn('actions').width,
                cellRendererFramework: GridGenericRowActionsRendererComponent,
                cellRendererParams: this.getActionsCellRendererParams(),
                sortable: false,
            }
        ];
        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel)
            .filter(colDef => this.prfqPermissionService.isPrfqColumnAvailable(colDef.field));
    }

    private getActionsCellRendererParams(): GridGenericRowActionsRendererComponentParams {
        return {
            rowActionsDef: [
                {
                    id: 'exportToCsv',
                    type: 'ICON_ONLY',
                    iconClass: 'fa fa-download',
                    method: (params) => {
                        const row: Prfq = params.data;
                        this.prfqService.export([row.id], row.prfqNumber, 'CSV');
                    },
                    popover: 'EXPORT_TO_CSV'
                },
                {
                    id: 'postsCount',
                    type: 'COUNT',
                    iconClass: 'fa fa-comments',
                    method: (params) => this.showCommentDialog(params.data),
                    attrName: 'postsCount'
                },
                {
                    id: 'attachmentsCount',
                    type: 'COUNT',
                    iconClass: 'fa fa-paperclip',
                    method: (params) => this.showAttachmentsDialog(params.data),
                    attrName: 'attachmentsCount'
                }
            ]
        };
    }

    private showCommentDialog(prfq: Prfq): void {
        const showPrivateControls = this.sharedService.user.representative === Representative.AS;

        this.discussionDialogService.confirm(prfq, 'prfq', 'id', true, true, null, null,
            showPrivateControls, this.prfqService.getDiscussionPrivateConfig()
        ).subscribe(
            (newCommentsCount: number) => {
                if (newCommentsCount > 0) {
                    prfq.postsCount = prfq.postsCount + newCommentsCount;
                }
            }  
        );
    }

    private showAttachmentsDialog(prfq: Prfq): void {
        const showPrivateControls = this.sharedService.user.representative === Representative.AS;

        this.attachmentDialogService.confirm(
            prfq,
            // getAttachments
            (id) => this.prfqService.getAttachments(id).pipe(map(attachments => attachments.filter(item => item.childId === null))),
            // getUploadUrl
            (fileName: string, privateItem: boolean) => this.prfqService.getAttachmentUploadUrl(fileName, prfq.id, null, privateItem),
            // getDownloadUrl
            (attachmentId: number, lineNumber?: number) => this.prfqService.getAttachmentDownloadUrl(prfq.id, attachmentId, lineNumber),
            // deleteAttachment
            (entityId: number, attachmentId: number, lineNumber?: number) => this.prfqService.deleteAttachment(prfq.id, attachmentId),
            // getAllowEdit
            () => prfq.actions ? prfq.actions.indexOf(PrfqActions.INSERT_ITEM) > -1 : false,
            // setAttachmentS3Keys
            (s3Keys: string[]) => {
              prfq.attachmentsCount = prfq.attachmentsCount + 1; // attachments were added
            },
            // hideRowLabel
            null,
            // showPrivateControls
            showPrivateControls,
            // privateConfig
            this.prfqService.getAttachmentPrivateConfig()
            ).subscribe(
              (newAttachmentsCount: number) => {
                if (newAttachmentsCount > 0) {
                  prfq.attachmentsCount = prfq.attachmentsCount + newAttachmentsCount;
                }
              }  
            );
    }

    public getFilterItems(personsCreated: { id: string, name: string }[] = []): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            ...(this.prfqPermissionService.isPrfqColumnAvailable('supplierName')
                ? [{ id: 'supplier.id', name: 'SUPPLIER', type: TableFilterItemTypes.multiselect, search: true, value: '', 
                    values: this.sharedService.user.availableSuppliers.map(item => ({id: item.id+'', name: item.name})) }]
                : []
            ),
            { id: 'prfqNumber', name: 'PRFQ_NUMBER', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'prfqNote', name: 'NOTE', type: TableFilterItemTypes.text,
                maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'dateCreated', name: 'DATE_OF_CREATION', type: TableFilterItemTypes.dateRange,
                valueFrom: '', valueTo: '', showYearPicker: true },
            { id: 'personCreated', name: 'PERSON_CREATED', type: TableFilterItemTypes.multiselect,
                search: true, value: '', values: personsCreated },
        ];

        filterItems.push({
            id: 'prfqState',name: 'STATUS', type: TableFilterItemTypes.multiselect, 
            allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
            customDotColors: this.prfqService.getPrfqStateColors(),
            values: [
                ...(this.prfqPermissionService.isPrfqColumnAvailable('supplierName')
                    ? [{ id: PrfqStates.IN_PROGRESS, name: 'PRFQ_STATE_' + PrfqStates.IN_PROGRESS, dotId: PrfqStates.IN_PROGRESS, default: true }] 
                    : []
                ),
                { id: PrfqStates.NEW, name: 'PRFQ_STATE_' + PrfqStates.NEW, dotId: PrfqStates.NEW, default: true },
                { id: PrfqStates.OPENED, name: 'PRFQ_STATE_' + PrfqStates.OPENED, dotId: PrfqStates.OPENED, default: true },
                { id: PrfqStates.CLOSED_ACCEPTED, name: 'PRFQ_STATE_' + PrfqStates.CLOSED_ACCEPTED, dotId: PrfqStates.CLOSED_ACCEPTED, default: false },
                { id: PrfqStates.CLOSED_NOT_ACCEPTED, name: 'PRFQ_STATE_' + PrfqStates.CLOSED_NOT_ACCEPTED, dotId: PrfqStates.CLOSED_NOT_ACCEPTED, default: false }
            ],
            bulkCheckbox: {
                title: 'SHOW_OPENED_STATES_ONLY',
                ids: [
                    ...(this.prfqPermissionService.isPrfqColumnAvailable('supplierName')
                        ? [PrfqStates.IN_PROGRESS] 
                        : []
                    ), 
                    PrfqStates.NEW, 
                    PrfqStates.OPENED
                ]
            }
        });

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
