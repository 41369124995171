<div class="row-container wider" *ngIf="supplier" style="margin-bottom: 200px;">
    <div class="row-container">
        <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/suppliers"
            [queryParams]="sharedService.lastSuppliersQueryParams">
            <i class="fa fa-arrow-circle-left"></i>
            <a translate>BACK</a>
        </button>
    </div>
    <div class="row-container">
        <h3><i class="fa fa-briefcase"></i> <span translate>SUPPLIER</span> {{supplier.supplierName}}</h3>
    </div>
    <form (ngSubmit)="save(supplierForm.form)" #supplierForm="ngForm">
        <div fxLayout="column" fxLayout.gt-sm="row">
            <div fxFlex.gt-sm="50" fxFlex="100">

                <div class="base-panel">

                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>SUPPLIER_NAME</label>
                            <input placeholder="{{ 'SUPPLIER_NAME' | translate }}" [(ngModel)]="supplier.supplierName"
                                class="form-control" id="supplierName" name="supplierName" #supplierNameFormItem="ngModel"
                                maxlength="50" required>
                        </div>
                        <div [hidden]="supplierNameFormItem.valid || supplierNameFormItem.pristine"
                            class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>

                    <div class="row-container" fxLayout="column" *ngIf="supplier.id === 0">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>SUPPLIER_ADMIN_EMAIL</label>
                            <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="supplier.supplierAdminEmail"
                                class="form-control" id="supplierAdminEmail" name="supplierAdminEmail"
                                #supplierAdminEmailFormItem="ngModel"
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="50" required>
                        </div>
                        <div *ngIf="supplierAdminEmailFormItem.invalid && (supplierAdminEmailFormItem.errors.required && supplierAdminEmailFormItem.dirty)"
                            class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                        <div *ngIf="supplierAdminEmailFormItem.invalid && (!supplierAdminEmailFormItem.errors.required && supplierAdminEmailFormItem.dirty)"
                            class="alert alert-danger">
                            <span translate>WRONG_EMAIL_FORMAT</span>
                        </div>
                        <div class="input-container">
                            <label translate>LANGUAGE</label>
                            <div>
                                <dropdown-component
                                    [items]="sharedService.languages"
                                    [selected]="supplier.supplierAdminLanguage"
                                    (onSelect)="onSelectLanguageAdmin($event)"
                                    [autoClose]="true"
                                    [disableSelectedValueInButton]="false"
                                ></dropdown-component>
                            </div>
                        </div>
                    </div>

                    <div class="row-container" fxLayout="column" *ngIf="supplier.notActivatedAdminUser">
                        <form (ngSubmit)="resendInvite(adminForm.form)" #adminForm="ngForm">
                            <div class="input-container" style="max-width: 300px;">
                                <label translate>SUPPLIER_ADMIN_EMAIL</label>
                                <input placeholder="{{ 'EMAIL' | translate }}"
                                    [(ngModel)]="supplier.notActivatedAdminUser.email" class="form-control"
                                    id="supplierAdminEmail" name="supplierAdminEmail" #adminFormEmail="ngModel"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="50" required>
                            </div>
                            <div *ngIf="adminFormEmail.invalid && (adminFormEmail.errors.required && adminFormEmail.dirty)"
                                class="alert alert-danger">
                                <span translate>REQUIRED_FIELD</span>
                            </div>
                            <div *ngIf="adminFormEmail.invalid && (!adminFormEmail.errors.required && adminFormEmail.dirty)"
                                class="alert alert-danger">
                                <span translate>WRONG_EMAIL_FORMAT</span>
                            </div>
                            <div>
                                <button class="btn-secondary" style="margin-right: 10px;"
                                    [disabled]="adminFormEmail.invalid" [ngClass]="{'ec-disabled': adminFormEmail.invalid}">
                                    <i class="fa fa-envelope"></i><span translate>INVITE_SEND_AGAIN</span>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>


                <div class="base-panel">
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>SUPPLIER_CODE</label>
                            <input placeholder="{{ 'SUPPLIER_CODE' | translate }}"
                                [(ngModel)]="supplier.supplierCode" class="form-control" id="supplierCode"
                                name="supplierCode" #supplierCodeFormItem="ngModel" maxlength="10" required>
                        </div>
                        <div [hidden]="supplierCodeFormItem.valid || supplierCodeFormItem.pristine"
                            class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_LOCALITY</label>
                            <input placeholder="{{ 'CUSTOMER_LOCALITY' | translate }}"
                                [(ngModel)]="supplier.locality"
                                class="form-control" id="locality" name="locality"
                                #localityFormItem="ngModel" maxlength="2" required>
                        </div>
                        <div [hidden]="localityFormItem.valid || localityFormItem.pristine" class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>COUNTRY</label>
                            <input placeholder="{{ 'COUNTRY' | translate }}" [(ngModel)]="supplier.country"
                                class="form-control" id="country" name="country" #countryFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CITY</label>
                            <input placeholder="{{ 'CITY' | translate }}" [(ngModel)]="supplier.city" class="form-control"
                                id="city" name="city" #cityFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>STREET</label>
                            <input placeholder="{{ 'STREET' | translate }}" [(ngModel)]="supplier.street"
                                class="form-control" id="street" name="street" #streetFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>ZIP</label>
                            <input placeholder="{{ 'ZIP' | translate }}" [(ngModel)]="supplier.zip" class="form-control"
                                id="zip" name="zip" #zipFormItem="ngModel" maxlength="50">
                        </div>
                    </div>

                </div>

                <div class="base-panel">

                    <div class="row-container" fxLayout="column" *ngIf="supplier.id > 0">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>STATUS</label>
                            <span class="clickable" (click)="supplier.valid = 1; supplierForm.form.markAsDirty();"><input
                                    type="radio" class="clickable" [value]="1" name="valid" [(ngModel)]="supplier.valid">
                                <span translate>CUSTOMER_STATUS_ENABLED</span></span><br>
                            <span class="clickable" (click)="supplier.valid = 0; supplierForm.form.markAsDirty();"><input
                                    type="radio" class="clickable" [value]="0" name="valid" [(ngModel)]="supplier.valid">
                                <span translate>CUSTOMER_STATUS_BLOCKED</span></span>
                        </div>
                    </div>

                </div>

                <div class="base-panel">

                    <div class="row-container bigger" *ngIf="supplier.permissionGroups">
                        <div class="input-container">
                            <label translate>SECURITY_GROUP_ADMINISTRATION</label>
                            <div class="tag fnt-smaller" *ngFor="let group of supplier.permissionGroups" translate>
                                {{'SECURITY_GROUP_' + group}}
                                <!-- ngIf here because we want always at least one admin so if user is curent user do not allow to remove from admin group -->
                                <i class="fa fa-times-circle clickable"
                                    (click)="removeFromGroup(group, supplierForm.form)"></i>
                            </div>
                            <div>
                                <dropdown-component
                                    attributeName="currentAddGroupSelected"
                                    [items]="allAvailableGroups"
                                    [selected]="addGroupSelected"
                                    (onSelect)="onSelectAddGroup($event, supplierForm.form)"
                                    [autoClose]="false"
                                    [disableSelectedValueInButton]="true"
                                ></dropdown-component>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div fxFlex.gt-sm="50" fxFlex="100" class="pl-15">
                <div class="base-panel">

                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_CONTACT_NAME</label>
                            <input placeholder="{{ 'CUSTOMER_CONTACT_NAME' | translate }}"
                                [(ngModel)]="supplier.contactName" class="form-control"
                                id="contactName" name="contactName"
                                #contactNameFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_CONTACT_PHONE</label>
                            <input placeholder="{{ 'CUSTOMER_CONTACT_PHONE' | translate }}"
                                [(ngModel)]="supplier.contactPhone" class="form-control" id="contactPhone"
                                name="contactPhone" #contactPhoneFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>EMAIL</label>
                            <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="supplier.contactEmail"
                                class="form-control" id="email" name="email" #emailFormItem="ngModel"
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="50">
                        </div>
                        <div *ngIf="emailFormItem.invalid && (!emailFormItem.errors.required && emailFormItem.dirty)"
                            class="alert alert-danger">
                            <span translate>WRONG_EMAIL_FORMAT</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="row-container" fxLayout="row" fxLayoutAlign="end center">
            <busy-indicator *ngIf="busy"></busy-indicator>
            <button class="btn-secondary" style="margin-right: 10px;" 
                [disabled]="!supplierForm.form.dirty || busy"
                [ngClass]="{'ec-disabled': !supplierForm.form.dirty || busy}" 
                (click)="cancel(supplierForm.form)" *ngIf="supplier.id > 0">
                <i class="fa fa-times-circle"></i>
                <span translate>CANCEL_CHANGES</span>
            </button>
            <button class="btn-primary" type="submit"
                [disabled]="!supplierForm.form.valid || !supplierForm.form.dirty || busy"
                [ngClass]="{'ec-disabled': !supplierForm.form.valid || !supplierForm.form.dirty || busy}">
                <i class="fa fa-chevron-circle-right"></i>
                <span translate>{{supplier.id > 0 ? 'SAVE_CHANGES':'ADD'}}</span>
            </button>
        </div>
    </form>
</div>