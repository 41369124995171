import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from 'app/services/shared.service';
import { BusinessConditionsService } from 'app/services/businessConditions/business.conditions.service';

@Component({
    selector: 'lodin-dialog',
    template: `
        <div [ngClass]="sharedService.apParams.styleTheme + '-theme'"
            fxLayout="row" 
            fxLayoutAlign="center center">

            <div class="row-container wider">
                <div class="row-container">
                    <h1 class="fnt-primary" translate>BUSINESS_CONDITIONS_ACCEPTANCE</h1>
                    <div class="row-container bigger">
                        <input type="checkbox" [(ngModel)]="acceptBusinessConditions"/> <span translate>I_ACCEPT_WITH</span> <a (click)="downloadBusinessConditions()" translate>BUSINESS_CONDITIONS_7P</a> <span translate>ECOMMERCE</span>
                    </div>
                </div>
                <div class="row-container bigger"
                        fxLayout="row" 
                        fxLayoutAlign="end center">
                    <button class="btn-secondary" (click)="cancel()" style="margin-right: 10px;">
                        <i class="fa fa-times-circle"></i>
                        <span translate>CANCEL</span>
                    </button>
                    <button class="btn-primary" (click)="continue()" [disabled]="!acceptBusinessConditions" [ngClass]="{'ec-disabled': !acceptBusinessConditions}">
                        <i class="fa fa-chevron-circle-right"></i>
                        <span translate>CONTINUE</span>
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class RequireBusinessConditionsComponent {
    doneSubject: Subject<any>;

    acceptBusinessConditions: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        public sharedService: SharedService,
        public businessConditionsService: BusinessConditionsService    
    ) {
    }

    continue():void {
        this.doneSubject.next(true);
        this.bsModalRef.hide();
    }

    cancel(): void {
        this.doneSubject.next(false);
        this.bsModalRef.hide();
    }

    downloadBusinessConditions() {
        this.businessConditionsService.downloadBusinessConditions();
    }
}