export class Product {
    public available: number;
    public amountOrder: number;
    constructor(
        public amount: 				number,
        public amountUponVerification: number,
        public amountPlAs2: 		number,
        public amountSrAs2: 		number,
        public amountSkAs4: 		number,
        public amountCzAs4: 		number,
        public customerStockAmount: number,
        public dimensionB: 			number,
        public dimensionID: 		number,
        public dimensionOD: 		number,
        public rdMin: number,
        public rdMax: number,
        public eCommProductId: 		string,
        public eCommProductIdOld: string | null,
        public eCommProductIdNew: string | null,
        public productName: 		string,
        public shortName: string,
        public brandCode: 			string,
        public productionGroupCode1: 	string,
        public productionGroupCode1Name: string,
        public productionGroupCode2: 	string,
        public productionGroupCode2Name: string,
        public unitPrice:			number,
        public amountInPacking:			number,
        public weight: 				number,
        public futureDeliveryPeriod1: number,
        public futureDeliveryPeriod1_4: number,
        public futureDeliveryPeriod2: number,
        public futureDeliveryPeriod3: number,
        public futureDeliveryPeriod4: number,
        public futureDeliveryPeriod5: number,
        public futureDeliveryPeriod5_7: number,
        public futureDeliveryPeriod6: number,
        public futureDeliveryPeriod7: number,
        public futureDeliveryAs2: 	number,
        public futureDelivery0_30As5: number,
        public futureDelivery30_60As5: number,
        public futureDelivery60_90As5: number,
        public futureDelivery90_120As5: number,
        public futureDelivery120_As5: number,
        public futureDelivery30_As5: number,
        public url: 				string,
        public isUrlAbsolute: boolean,
        public query: 				any,
        public asWebProductName: string,
        public asWebUrlPart: string,
        public asWebLinkType: AsWebLinkTypes,
        public tooltip: string, // used in a case of a product name change
        public iconClass: string // used in a case of a product name change
    ) {
        this.available = null;
        this.amountOrder = null;
    }
}

export enum AsWebLinkTypes {
    'DirectConnection' = 'D',
    'AlternativeConnection' = 'A',
    'ContactAs' = 'C'
}

export interface ProductAvailabilityItem {
    amount: number,
    deliveryDate: string,
}

export interface ProductAvailabilityObj {
    futureDelivery1_4?: ProductAvailabilityItem[],
    futureDelivery5_7?: ProductAvailabilityItem[],
    futureDelivery30_As5?: ProductAvailabilityItem[],
}
