import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DiscussionPrivateConfig } from '@app/model/discussion.model';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface NewPostResult {
    post: string,
    isPrivate: boolean
}

@Component({
  selector: 'app-new-post',
  templateUrl: 'new-post.component.html',
  styleUrls:  ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit, OnDestroy {
    @Input() public showPrivateControls?: boolean; // if to show buttons and icons related to private posts
    @Input() public privateConfig?: DiscussionPrivateConfig; // more info in a case showPrivateControls === true
    @Input() public disabled$?: BehaviorSubject<boolean>;
    @Output() onPostChange: EventEmitter<NewPostResult> = new EventEmitter<NewPostResult>();

    public post = '';
    public isPrivate = null;
    public isDisabled = false;
    private subscriptions: Subscription[] = [];

    constructor(
    ) {
    }

    public ngOnInit(): void {
        if (this.disabled$) {
            this.subscriptions.push(this.disabled$.subscribe(disabled => {
                this.isDisabled = disabled;
                if (this.isDisabled) {
                    this.post = null;
                    this.isPrivate = null;
                }
            }));
        }
    }

    public _onPostChange(): void {
        this.onPostChange.emit({ post: this.post, isPrivate: this.isPrivate });
    }

    public onIsPrivateChange(): void {
        this.onPostChange.emit({ post: this.post, isPrivate: this.isPrivate });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
