<div class="row-container" *ngIf="params">
	<div *ngIf="params.successCount > 0">
		<h4 class="fnt-green"><span translate>INSERT_TO_BASKET_SUCCESS</span>: {{params.successCount}}</h4>
	</div>

	<div style="margin-top: 10px" *ngIf="params.errors.length > 0">
		<div fxLayout="row">
			<h4 class="fnt-orange"><span translate>INSERT_TO_BASKET_ERROR</span>: {{params.errors.length}}</h4>

			<button class="btn-secondary" style="min-width: 200px; margin-left: 10px;" (click)="copyErrorsToClipboard()">
				<i class="fnt-primary fa fa-clipboard"></i>
				<span translate>COPY_ERRORS_TO_CLIPBOARD</span>
			</button>
		</div>

		<div style="display: block; overflow-y: auto; min-height: 240px;"  [ngStyle]="{'max-height.px': maxHeight - (this.params.successCount > 0 ? 30 : 0)}">
			<div fxLayout="row">
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 7px;" translate>PRODUCT_ID</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 1px;" translate>PIECES</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 0px;" translate>DATE_OF_DELIVERY</div>
			</div>
			<div fxLayout="row" *ngFor="let item of params.errors; let odd=odd; let even=even;" [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">

				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 5px;" 
					[ngClass]="{'fnt-orange': item.error.eCommProductId || item.error.eCommProductIdBrandCode}">
					<input type="text" style="margin: 4px 0;" class="form-control" [ngClass]="{'fnt-orange': item.error.eCommProductId || item.error.eCommProductIdBrandCode}" 
						[(ngModel)]="item.row.eCommProductId" (ngModelChange)="onChange(item, 'eCommProductId')">
					<div style="margin-top: -5px;">
						<span style="font-size: 75%;" translate>{{item.error.eCommProductId}}</span>
						<span style="font-size: 75%;" translate>{{item.error.eCommProductIdBrandCode13}}</span>
					</div>
				</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;">
					<input type="text" style="margin: 4px 0;" class="form-control" [ngClass]="{'fnt-orange': item.error.amountOrdered}" [(ngModel)]="item.row.amountOrdered" (ngModelChange)="onChange(item, 'amountOrdered')">
					<div style="margin-bottom: 2px">
						<span *ngIf="item.row.amountOrdered.length == 0" class="fnt-orange" style="font-size: 75%;" translate>INSERT_TO_BASKET_AMOUNT_MISSING</span>
					</div>
				</div>
				<div fxFlex="50" style="display: inline-block;">
					<div style="margin-top: 4px;">
						<my-date-picker *ngIf="item.row.datePickerOptions"
		                    [(ngModel)]="item.row.deliveryDateModel"
		                    [options]="item.row.datePickerOptions"
		                    [locale]="this.sharedService.appSettings.language"
		                    [selector]="selector"
		                    (dateChanged)="onDateChanged($event, item)"></my-date-picker>
		            </div>
					<div style="margin-top: -5px;">
						<span style="font-size: 75%;" [ngClass]="{'fnt-orange': item.error.deliveryDate}" translate>{{item.error.deliveryDate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div style="padding-top: 15px;">
		<button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
	        <span translate>CLOSE</span>
	    </button>
	    <button class="btn-primary" style="float: right" (click)="addToBasketAgain()" [disabled]="!somethingChanged" [ngClass]="{'ec-disabled': !somethingChanged}">
	    	<i class="fa fa-plus"></i>
	        <span translate>IMPORT_TO_CART_AGAIN</span>
	    </button>
	</div>