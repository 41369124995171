/**
 * Request for quotations - Grid Service
 *
 */

import { Injectable } from '@angular/core';
import { Subject ,  Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { RfqPermissionService } from './rfq.permission.service';
import { RfqsService } from './rfqs.service';
import { GridDateRendererComponent, GridDateRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridRfqActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRfqActionsRendereComponent/grid.rfq.actions.renderer.component';
import { GridDateEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridDateEditorComponent/grid.date.editor.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridAttachmentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridAttachmentRendererComponent/grid.attachment.renderer.component';
import { GridHeaderActionsRendererComponent, GridHeaderActionsRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';
import { QuestionDialogService } from '../../components/questionDialogComponent/question.dialog.service';
import { DiscountDialogService } from '../../components/discountDialogComponent/discount.dialog.service';
import {GridDropdownRendererComponent} from '../../components/tableComponent/gridRendererComponents/gridDropdownRendererComponent/grid.dropdown.renderer.component';
import { OrderDialogService } from '@components/order/orderDialogComponent/order.dialog.service';
import { OrderDialogMode, OrderDialogResultState } from '@components/order/orderDialogComponent/order.dialog.model';
import { GridRfqDateOfferedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRfqDateOfferedRendererComponent/grid.rfq.date.offered.renderer.component';
import { GridAvailabilityRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridAvailabilityRendererComponent/grid.availability.renderer.component';
import {GridNumberRendererComponent} from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridTypeaheadEditorComponent, GridTypeaheadEditorComponentParams } from '../../components/tableComponent/gridEditorComponents/gridTypeaheadEditorComponent/grid.typeahead.editor.component';
import { GridTypeaheadRendererComponent, GridTypeaheadRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridTypeaheadRendererComponent/grid.typeahead.renderer.component';
import { GridSelectRendererComponent, GridSelectRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridSelectRendererComponent/grid.select.renderer.component';
import { GridSelectEditorComponent, GridSelectEditorComponentParams } from '../../components/tableComponent/gridEditorComponents/gridSelectEditorComponent/grid.select.editor.component';
import { ToastService } from '../../services/toastService/toast.service';
import * as moment from 'moment-timezone';
import { DiscussionService } from '../discussion.service';
import { map, switchMap } from 'rxjs/operators';
import { FormService } from '@services/form.service';
import { RfqItem, Rfq } from '@app/model/rfq.model';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CellChange, TableBulk } from '@app/model/table.model';
import { RfqService } from './rfq.service';
import { ICellEditorParams } from 'ag-grid-community';

interface RfqAgParams extends ICellRendererParams {
    data: RfqItem
}

@Injectable()
export class RfqGridService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;
    subjectItemsUpdated: Subject<any>;
    subjectAttachmentChanged: Subject<any>;
    cancelRowsObservable: Subject<any>;
    subjectReloadCellEditable: Subject<any>;
    cellChanged$: Subject<CellChange>;

    allowedStatesToExport = ['N', 'O', 'S', 'F'];
    rfqItemsEditedIds: any = {};
    decimalDelimiter: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private rfqPermissionService: RfqPermissionService,
        private rfqsService: RfqsService,
        private questionDialogService: QuestionDialogService,
        private discountDialogService: DiscountDialogService,
        private discussionService: DiscussionService,
        private orderDialogService: OrderDialogService,
        private languageService: LanguageService,
        private toastService: ToastService,
        private formService: FormService,
        private rfqService: RfqService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
        this.subjectItemsUpdated = new Subject<any>();
        this.subjectAttachmentChanged = new Subject<any>();
        this.cancelRowsObservable = new Subject<any>();
        this.subjectReloadCellEditable = new Subject<any>();
        this.cellChanged$ = new Subject<CellChange>();

        this.isRowValid = this.isRowValid.bind(this);
        this.getRowStyle = this.getRowStyle.bind(this);

        const locale = this.languageService.getLocale();
        this.decimalDelimiter = locale[this.sharedService.appSettings.language].decimalDelimiter; // ',';

        this.subscriptions.push(this.subjectReloadCellEditable.subscribe((params) =>
            this.onRowChange(params)));

        this.subscriptions.push(this.cellChanged$.subscribe(change => this.onCellChange(change)));
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }
    getItemsUpdatedSubject() {
        return this.subjectItemsUpdated;
    }

    getColumns(forceOriginal?: boolean, tableNamePrefix?: string): any[] {
        let original = [
            { id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30 },
            { id: 'lineNumber', name: 'LINE_NUMBER', checked: true, orderBy: false, orderDirection: 'ASC', width: 30 },
            { id: 'rfqProduct', name: 'PRODUCT_ID', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'rfqProductAS', name: 'PRODUCT_ID_AS', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'amountRequired', name: 'REQUIRED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'availabilityState', name: 'AVAILABILITY', checked: true, orderBy: false, orderDirection: 'ASC', width: 75 },
            { id: 'amountInPacking', name: 'AMOUNT_MOQ', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
            { id: 'amountPackages', name: 'AMOUNT_PACKAGES', checked: true, orderBy: false, orderDirection: 'ASC', width: 90 },
            { id: 'amountOffered', name: 'OFFERED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'packing', name: 'PACKING_FORM', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'unitPriceCatalogue', name: 'CATALOGUE_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'unitPriceRequired', name: 'REQUIRED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'currencyPriceRequired', name: 'CURRENCY', checked: true, orderBy: true, orderDirection: 'ASC', width: 50 },
            { id: 'unitPriceOffered', name: 'OFFERED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'rowPrice', name: 'TOTAL_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'dateRequired', name: 'REQUIRED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedDays', name: 'OFFERED_DATE_DAYS', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOffered', name: 'OFFERED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedValidity', name: 'OFFERED_DATE_VALIDITY', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            // { id: 'dateOfferedCalculated', name: 'OFFERED_DATE_CALCULATED', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedCalculated', name: 'OFFERED_DATE_CALCULATED', checked: true, orderBy: false, width: 120 },
            { id: 'itemNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'itemType', name: 'RFQ_ITEM_TYPE', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', checked: true, orderBy: false, orderDirection: 'DESC', width: 120 },
            { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateConfirmed', name: 'DATE_OF_CONFIRMATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'attachmentsCount', name: 'ATTACHMENTS', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 90 }
        ];

        original = this.tableService.filterSuppressColumns(original, 'rfqItems')
            .filter(colDef => this.rfqPermissionService.isRfqItemColumnAvailable(colDef.id));
        // original = this.rfqPermissionService.filterColumns(original);
        const restored = this.sharedService.user.preferences['rfq' + (tableNamePrefix ? tableNamePrefix : '') + 'TableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }



    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }

    getGridOptions(startPage: number, getRfq) {
        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'rfqTablePageSize', this.headerClassFunc);
        // delete gridOptions.defaultColDef;
        gridOptions.columnDefs = this.getColumnDefs(getRfq);
        gridOptions.singleClickEdit = false;
        gridOptions.suppressClickEdit = true;
        gridOptions.headerHeight = 50;  // two linws
        // gridOptions.getRowStyle = params => {
        //     if ((params.data.rfqState === 'O') &&
        //         (params.data.itemState === 'C' || params.data.itemState === 'A') &&
        //         (params.data.itemValidity && !this.isRowValid(params.data))) {
        //         return { 'background-color': 'rgba(255, 84, 98, 0.5)' }
        //     }
        // };
        gridOptions.getRowStyle = this.getRowStyle;
        return gridOptions;
    }

    public getRowStyle(params) {
        if ((params.data.rfqState === 'O') &&
            (params.data.itemState === 'C' || params.data.itemState === 'A') &&
            (params.data.itemValidity && !this.isRowValid(params.data))) {
            return { 'background-color': 'rgba(255, 84, 98, 0.5)' }
        }
    };

    getColumnDefs(getRfq): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.sharedService.user.preferences['rfqItemsSelection'],
                    observable: this.getSelectedSubject(),
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.sharedService.user.preferences['rfqItemsSelection'],
                    observable: this.getItemsSelectedSubject()
                },
                headerClass: this.headerClassFunc,
                sortable: false,
            },
            {
              headerName: this.translateService.instant(this.getColumn('lineNumber').name),
              field: 'lineNumber',
              minWidth: 30,
              width: this.getColumn('lineNumber').width,
            //   headerComponent: GridHeaderRendererComponent,
            //   headerComponentParams: {
            //     router: this.router,
            //     activatedRoute: this.activatedRoute,
            //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
            //   },
            //   headerClass: this.headerClassFunc,
              cellRendererFramework: GridNumberRendererComponent,
              editable: false
            }
        ];
        
        // rfqProduct
        const rfqProduct: ColDef = {
            headerName: this.translateService.instant(this.getColumn('rfqProduct').name),
            field: 'rfqProduct',
            width: this.getColumn('rfqProduct').width,
            cellStyle: { 'text-align': 'left' },
            hide: !this.getColumn('rfqProduct').checked,
            // headerComponent: GridHeaderRendererComponent,
            // headerComponentParams: {
            //     router: this.router,
            //     activatedRoute: this.activatedRoute,
            //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
            // },
            // headerClass: this.headerClassFunc,
            suppressKeyboardEvent: (params) => params.editing === true  && ([13, 38, 40].indexOf(params.event.keyCode) > -1),
            editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'rfqProduct'),
            suppressSizeToFit: true
        };
        const rfqProductCellRendererParams: GridTypeaheadRendererComponentParams = {
            editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
            isColValid: this.isColValid,
            field: 'rfqProduct',
            getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'rfqProduct')
        };
        rfqProduct.cellRendererFramework = GridTypeaheadRendererComponent;
        rfqProduct.cellRendererParams = rfqProductCellRendererParams;
        const rfqProductCellEditorParams: GridTypeaheadEditorComponentParams = {
            dataGetter: (searchValue) => this.rfqService.getProductNamesByString(searchValue, 'rfqProduct'),
            editedIdsObj: this.rfqItemsEditedIds,
            isColValid: this.isColValid,
            cellChanged$: this.cellChanged$
        };
        rfqProduct.cellEditorFramework = GridTypeaheadEditorComponent;
        rfqProduct.cellEditorParams = rfqProductCellEditorParams;

        colDefs.push(rfqProduct);

        // rfqProductAS
        const rfqProductAS: ColDef = {
            headerName: this.translateService.instant(this.getColumn('rfqProductAS').name),
            field: 'rfqProductAS',
            width: this.getColumn('rfqProductAS').width,
            cellStyle: { 'text-align': 'left' },
            hide: !this.getColumn('rfqProductAS').checked,
            // headerComponent: GridHeaderRendererComponent,
            // headerComponentParams: {
            //     router: this.router,
            //     activatedRoute: this.activatedRoute,
            //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
            // },
            // headerClass: this.headerClassFunc,
            suppressKeyboardEvent: (params) => params.editing === true  && ([13, 38, 40].indexOf(params.event.keyCode) > -1),
            editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'rfqProductAS'),
            cellClass: (params) => {
                return !this.rfqPermissionService.isCellEditable(params.data, 'rfqProductAS') && (params.data.nonCancelable === 1) ? 'fnt-blue' : '';
            },
            valueGetter: (params) => {
                return params.data.rfqProductAS ?
                    (params.data.rfqProductAS + (!this.rfqPermissionService.isCellEditable(params.data, 'rfqProductAS') && (params.data.nonCancelable === 1) ? ' *' : '')) :
                    params.data.rfqProductAS;
            },
            tooltipValueGetter: (params) => !this.rfqPermissionService.isCellEditable(params.data, 'rfqProductAS') && (params.data.nonCancelable === 1) ? this.translateService.instant('NON_CANCELABLE_INFO') : '',
            suppressSizeToFit: true
        };
        const rfqProductASCellRendererParams: GridTypeaheadRendererComponentParams = {
            editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
            isColValid: this.isColValid,
            getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'rfqProductAS')
        };
        rfqProductAS.cellRendererFramework = GridTypeaheadRendererComponent;
        rfqProductAS.cellRendererParams = rfqProductASCellRendererParams;
        const rfqProductASCellEditorParams: GridTypeaheadEditorComponentParams = {
            dataGetter: (searchValue) => this.rfqService.getProductNamesByString(searchValue, 'rfqProductAS'),
            editedIdsObj: this.rfqItemsEditedIds,
            isColValid: this.isColValid,
            allowOnlyFromList: true,
            cellChanged$: this.cellChanged$
        };
        rfqProductAS.cellEditorFramework = GridTypeaheadEditorComponent;
        rfqProductAS.cellEditorParams = rfqProductASCellEditorParams;
            
        colDefs.push(rfqProductAS);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('amountRequired').name),
                field: 'amountRequired',
                width: this.getColumn('amountRequired').width,
                // minWidth: this.getColumn('amountRequired').width,
                cellStyle: { 'text-align': 'right' },
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'amountRequired'),
                    isColValid: this.isColValid,
                    number: true,
                    decimal: false,
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    isColValid: this.isColValid,
                    inputType: 'number',
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'amountRequired')
            },
            {
                headerName: this.translateService.instant(this.getColumn('availabilityState').name),
                field: 'availabilityState',
                width: this.getColumn('availabilityState').width,
                suppressSizeToFit: true,
                sortable: false,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridAvailabilityRendererComponent,
                cellRendererParams: {
                    watchedItems: ['rfqProduct', 'rfqProductAS', 'amountRequired', 'dateRequired'],
                    cellChanged$: this.cellChanged$
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountOffered').name),
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                field: 'amountOffered',
                width: this.getColumn('amountOffered').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false },
                cellStyle: { 'text-align': 'right' },
            },
            {
                headerName: this.translateService.instant('AMOUNT_MOQ'),
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'amountInPacking',
                width: this.getColumn('amountInPacking').width,
                minWidth: this.getColumn('amountInPacking').width,
                maxWidth: 110,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false },
                cellStyle: { 'text-align': 'right' },
            }
        ]);

        // amountPackages
        const amountPackages: ColDef = {
            headerName: this.translateService.instant(this.getColumn('amountPackages').name),
            field: 'amountPackages',
            width: this.getColumn('amountPackages').width,
            // minWidth: this.getColumn('amountPackages').width,
            cellStyle: { 'text-align': 'right' },
            suppressSizeToFit: true,
            // headerComponent: GridHeaderRendererComponent,
            // headerComponentParams: {
            //     router: this.router,
            //     activatedRoute: this.activatedRoute,
            //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
            // },
            // headerClass: this.headerClassFunc,
            editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'amountPackages')
        };
        amountPackages.cellRendererFramework = GridInputRendererComponent;
        const amountPackagesCellRendererParams: GridInputRendererComponentParams = {
            editedIdsObj: this.rfqItemsEditedIds,
            number: true,
            decimal: false,
            isColValid: (colId, value) => {
                return value && value > 0 && value < 1000000 && (value % 1 === 0) && value.indexOf(this.decimalDelimiter) < 0;
            },
            getInvalidColPopInfo: () => 'INVALID_PACKING_AMOUNT_INFO',
            getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'amountPackages'),
            // decimal: false
            allowMultipleEdit: true,
            cellChanged$: this.cellChanged$
        };
        amountPackages.cellRendererParams = amountPackagesCellRendererParams;
        amountPackages.cellEditorFramework = GridInputEditorComponent;
        const amountPackagesCellEditorParams = {
                editedIdsObj: this.rfqItemsEditedIds,
                inputType: 'number',
                isColValid: (colId, value) => {
                    return value && value > 0 && value < 1000000 && (value % 1 === 0) && value.replace(',', '.').indexOf('.') < 0;
                },
                invalidPopoverText: 'INVALID_PACKING_AMOUNT_INFO',
                onChange: (params: ICellEditorParams) => {
                    const data: RfqItem = params.node.data;
                    if (data.originalValues.filter(origVal => origVal.colId === 'amountPackagesNotRounded').length === 0) {
                        data.originalValues.push({ colId: 'amountPackagesNotRounded', value: data.amountPackagesNotRounded });
                    }
                    data.amountPackagesNotRounded = parseFloat(data.amountPackages.toString().replace(' ', '').replace(',', '.'));
                    data.amountOffered = Math.round(data.amountInPacking * data.amountPackagesNotRounded);
                    const previousAmountOfferedItem = data.originalValues.find(item => item.colId === 'amountOffered');
                    if (!previousAmountOfferedItem) {
                        // push amountOffered to original values if it is not there because save would not work
                        data.originalValues.push({colId: 'amountOffered', value: null}); 
                    }
                    params.api.refreshCells({ rowNodes: [params.node], columns: ['amountOffered'] });
                },
                allowMultipleEdit: true,
                cellChanged$: this.cellChanged$
        }
        amountPackages.cellEditorParams = amountPackagesCellEditorParams;
        colDefs.push(amountPackages);

        // packing
        const packing: ColDef = {
            headerName: this.translateService.instant('PACKING_FORM'),
            // headerComponent: GridHeaderRendererComponent,
            // headerComponentParams: {
            //     router: this.router,
            //     activatedRoute: this.activatedRoute
            // },
            // headerClass: this.headerClassFunc,
            field: 'packing',
            width: this.getColumn('packing').width,
            minWidth: 110,
            maxWidth: 200,
            editable: (params: RfqAgParams) => !(params.data.packingOptions.length === 1 &&
                params.data.packing === params.data.packingOptions[0].packing) &&
                params.data.packingOptions.length !== 0 &&
                this.rfqPermissionService.isCellEditable(params.data, 'packing'),
            cellClass: 'overflow-visible',
        };
        const packingCellRendererParams: GridSelectRendererComponentParams = {
            itemsGetter: (params: {data: RfqItem}) => this.formService.getPackingOptionsForRow(params.data),
            editedIdsObj: this.rfqItemsEditedIds,
            allowMultipleEdit: true,
            hideIfNoItems: false,
            onChange: (params: RfqAgParams) => {
                if (params.data.packingOptions.length > 0 && params.data.packing) {
                    params.data.amountInPacking = params.data.packingOptions.filter(item => item.packing === params.data.packing)[0].amount;
                    if (params.data.amountOffered) {
                        params.data.amountPackages = params.data.amountInPacking
                            ? (Math.round((params.data.amountOffered / params.data.amountInPacking) * 100) / 100)
                            : null;
                    } else {
                        params.data.amountOffered = params.data.amountInPacking * params.data.amountPackages;
                    }
                    params.api.refreshCells({ rowNodes: [params.node],
                        columns: ['amountInPacking', 'amountPackages', 'amountOffered'] });
                }
            },
            cellChanged$: this.cellChanged$
        };
        packing.cellRendererFramework = GridSelectRendererComponent;
        packing.cellRendererParams = packingCellRendererParams;
        const packingCellEditorParams: GridSelectEditorComponentParams = {
            itemsGetter: (params) => this.formService.getPackingOptionsForRow(params.data),
            cellChanged$: this.cellChanged$
        };
        packing.cellEditorFramework = GridSelectEditorComponent;
        packing.cellEditorParams = packingCellEditorParams;

        colDefs.push(packing);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceCatalogue').name),
                field: 'unitPriceCatalogue',
                colId: 'unitPriceCatalogue',
                // minWidth: 80,
                width: this.getColumn('unitPriceCatalogue').width,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceCatalogue' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceRequired').name),
                field: 'unitPriceRequired',
                // minWidth: 80,
                width: this.getColumn('unitPriceRequired').width,
                suppressSizeToFit: true,
                // cellStyle: { 'text-align': 'right' },
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'unitPriceRequired'),
                    decimal: true,
                    isColValid: this.isColValid,
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    inputType: 'decimal',
                    maxLength: 10,
                    isColValid: this.isColValid,
                    cellChanged$: this.cellChanged$
                },
                editable: this.rfqPermissionService.isColumnEditable('unitPriceRequired')
            },
            {
                headerName: this.translateService.instant(this.getColumn('currencyPriceRequired').name),
                field: 'currencyPriceRequired',
                width: this.getColumn('currencyPriceRequired').width,
                cellStyle: { 'text-align': 'left' },
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                suppressSizeToFit: true,
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceOffered').name),
                field: 'unitPriceOffered',
                // minWidth: 80,
                width: this.getColumn('unitPriceOffered').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'right' },
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'unitPriceOffered'),
                    decimal: true,
                    isColValid: this.isColValid,
                    getPopInfo: (params) => {
                        return this.rfqsService.getPriceOfferedPopupValue(params.data);
                    },
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    inputType: 'decimal',
                    maxLength: 10,
                    isColValid: this.isColValid,
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'unitPriceOffered')
            },
            {
                headerName: this.translateService.instant(this.getColumn('rowPrice').name),
                field: 'rowPrice',
                width: this.getColumn('rowPrice').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceOffered' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateRequired').name),
                field: 'dateRequired',
                width: this.getColumn('dateRequired').width,
                minWidth: 90,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateRequired'),
                    editedIdsObj: this.rfqItemsEditedIds,
                    allowMultipleEdit: true,
                    // minDateAttrName: 'minDeliveryDate'
                    cellChanged$: this.cellChanged$
                } as GridDateRendererComponentParams,
                cellEditorFramework: GridDateEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    // minDateAttrName: 'minDeliveryDate',
                    allowNullDate: true,
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateRequired'),
                cellClass: 'overflow-visible'
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedDays').name),
                field: 'dateOfferedDays',
                // minWidth: 80,
                width: this.getColumn('dateOfferedDays').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'center' },
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOfferedDays'),
                    prefixString: 'D + ',
                    maxWidth: '50%',
                    isColValid: this.isColValid,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    decimal: false,
                    textAlign: 'center',
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    inputType: 'number',
                    prefixString: 'D + ',
                    maxWidth: '50%',
                    isColValid: this.isColValid,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    textAlign: 'center',
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOfferedDays')
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOffered').name),
                field: 'dateOffered',
                width: this.getColumn('dateOffered').width,
                minWidth: 90,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOffered'),
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    // minDateAttrName: 'minDeliveryDate',
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    onChange: params => {
                        if (params.value) {
                            const date = new Date(); // set now not params.value value
                            date.setDate(date.getDate() + 7);
                            params.data.dateOfferedValidity = date.toISOString();
                        }
                    },
                    cellChanged$: this.cellChanged$
                } as GridDateRendererComponentParams,
                cellEditorFramework: GridDateEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    // minDateAttrName: 'minDeliveryDate',
                    allowNullDate: true,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOffered'),
                cellClass: 'overflow-visible'
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedValidity').name),
                field: 'dateOfferedValidity',
                width: this.getColumn('dateOfferedValidity').width,
                minWidth: 90,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOfferedValidity'),
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    // minDateAttrName: 'minDeliveryDate',
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    isColValid: this.isColValid,
                    cellChanged$: this.cellChanged$
                } as GridDateRendererComponentParams,
                cellEditorFramework: GridDateEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    // minDateAttrName: 'minDeliveryDate',
                    allowNullDate: true,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'dateOfferedValidity'),
                cellClass: 'overflow-visible'
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedCalculated').name),
                field: 'dateOfferedCalculated',
                minWidth: 80,
                width: this.getColumn('dateOfferedCalculated').width,
                cellStyle: { 'text-align': 'right' },
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridRfqDateOfferedRendererComponent,
                editable: false,
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemNote').name),
                field: 'itemNote',
                width: this.getColumn('itemNote').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('itemNote').checked,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    editable: this.rfqPermissionService.isColumnEditable('itemNote'),
                    maxWidth: 'auto',
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    inputType: 'string',
                    maxLength: '200',
                    cellChanged$: this.cellChanged$
                },
                editable: this.rfqPermissionService.isColumnEditable('itemNote')
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemType').name),
                field: 'itemType',
                width: this.getColumn('itemType').width,
                minWidth: 110,
                maxWidth: 200,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDropdownRendererComponent,
                cellRendererParams: {
                    translatePrefix: 'RFQ_ITEM_TYPE_',
                    items: ['T', 'P', 'S', 'O'],
                    pleaseSelect: 'PLEASE_SELECT_OPTION',
                    textAlign: 'center',
                  // editable: this.rfqPermissionService.isColumnEditable('itemType'),
                  suppressSizeToFit: true,
                  editedIdsObj: this.rfqItemsEditedIds,
                  getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'itemType')
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'itemType')
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemValidity').name),
                field: 'itemValidity',
                width: this.getColumn('itemValidity').width,
                minWidth: 90,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    getEditable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'itemValidity'),
                    editedIdsObj: this.rfqItemsEditedIds, allowMultipleEdit: true,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    isColValid: this.isColValid,
                    isRowValid: row => this.isRowValid(row),
                    cellChanged$: this.cellChanged$
                } as GridDateRendererComponentParams,
                cellEditorFramework: GridDateEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.rfqItemsEditedIds,
                    allowNullDate: true,
                    subjectReloadCellEditable: this.subjectReloadCellEditable,
                    isColValid: this.isColValid,
                    // customDateOptions: true,
                    customDate: {
                        hours: 12,            // hours
                        daysIfBeforeTime: 2,  // add days if before hours
                        daysIfAfterTime: 3,   // add days if after hours
                        disableAfter: 1      // add years after now
                    },
                    disableWeekends: true,
                    isRowValid: row => this.isRowValid(row),
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => this.rfqPermissionService.isCellEditable(params.data, 'itemValidity'),
                cellClass: 'overflow-visible'
            },
            {
                headerName: this.translateService.instant(this.getColumn('postsCount').name),
                field: 'postsCount',
                width: this.getColumn('postsCount').width,
                // maxWidth: 90,
                suppressSizeToFit: true,
                // minWidth: 50,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridCommentRendererComponent,
                cellRendererParams: { itemArea: 'rfq', idAttrName: 'rfqId', getItemLabel: (item) => this.translateService.instant('ROW').toLowerCase() + ' ' + item.lineNumber },
            },
            {
                headerName: this.translateService.instant(this.getColumn('attachmentsCount').name),
                field: 'attachmentsCount',
                // maxWidth: 90,
                suppressSizeToFit: true,
                // minWidth: 50,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridAttachmentRendererComponent,
                cellRendererParams: {
                    itemArea: 'rfq',
                    idAttrName: 'id',
                    getAttachments: (params, id) => this.rfqsService.getAttachments(params.data.rfqId).pipe(map(data => { data = data.filter(item => item.childId === params.data.lineNumber); return data; })),
                    getUploadUrl: (params, fileName: string) => this.rfqsService.getAttachmentUploadUrl(fileName, params.data.rfqId, params.data.lineNumber),
                    deleteAttachment: (params, entityId: number, attachmentId: number, lineNumber?: number) => this.rfqsService.deleteAttachment(params.data.rfqId, attachmentId),
                    getDownloadUrl: (params, attachmentId: number, lineNumber?: number) => this.rfqsService.getAttachmentDownloadUrl(params.data.rfqId, attachmentId, lineNumber),
                    getAllowEdit: (params) => this.rfqPermissionService.isCellEditable(params.data, 'unitPriceOffered') || this.rfqPermissionService.isCellEditable(params.data, 'rfqProduct'),
                    attachmentChanged: () => {
                        this.subjectAttachmentChanged.next();
                    }
                },
                width: this.getColumn('postsCount').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'RFQ_ITEM_STATE_',
                    renderColors: {
                        gray: 'P',
                        red: 'N',
                        yellow: 'O',
                        blueDarker: 'F',
                        blue: 'C',
                        lime: 'A',
                        green: 'R',
                        black: 'D'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateConfirmed').name),
                field: 'dateConfirmed',
                width: this.getColumn('dateConfirmed').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute,
                //     cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                // },
                // headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            }
        ]);
        // actions
        const actions: ColDef = {
            headerName: this.translateService.instant('ACTIONS'),
            field: 'actions',
            width: this.getColumn('actions').width,
            minWidth: this.getColumn('actions').width,
            headerComponent: null,
            headerComponentFramework: GridHeaderActionsRendererComponent,
            headerClass: (params) => this.headerClassFunc(params) + ' active-on-edited-rows',
            cellRendererFramework: GridRfqActionsRendererComponent,
            cellRendererParams: {
                selection: this.sharedService.user.preferences['rfqItemsSelection'],
                editedIdsObj: this.rfqItemsEditedIds,
                observable: this.getItemsUpdatedSubject(),
                reloadAttachmentsObservable: this.subjectAttachmentChanged,
                isRowValid: row => this.isRowValid(row),
                cancelObservable: this.cancelRowsObservable,
                getRfq: getRfq

            },
            sortable: false,
        };
        const actionsHeaderComponentParams: GridHeaderActionsRendererComponentParams = {
            editedIdsObj: this.rfqItemsEditedIds,
            // cancelObservable: this.cancelRowsObservable,
            cancel$: this.cancelRowsObservable,
            save: (data) => {
                const rfqId = data[0].rfqId;
                return this.rfqsService.updateRfqItems(rfqId, data, getRfq);
            },
            isRowValid: row => this.isRowValid(row),
            reload$: this.rfqsService.getReloadCurrentSubject()
        };
        actions.headerComponentParams = actionsHeaderComponentParams;
        colDefs.push(actions);

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'rfqItems')
            .filter(colDef => this.rfqPermissionService.isRfqItemColumnAvailable(colDef.field));
        // colDefs = this.rfqPermissionService.filterColumns(colDefs);
        return colDefs;
    }

    // getFilterItems(personsCreated: { id: string, name: string }[] = []) {
    getFilterItems(ommit?: any ) {
        let filterItems: any[] = [
          { id: 'productFilter', name: 'PRODUCT_ID_AND_AS', type: 'text', maxLength: 20, value: '', operator: 'likeBoth' },
          { id: 'productASFilter', name: 'PRODUCT_ID_AS',  type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
            values: [
                { id: 'FOUND', name: 'FOUND_AP', default: true},
                { id: 'NOT_FOUND', name: 'NOT_FOUND_AP', default: true},
            ]},
        ];
        if (ommit !== 'all') {
            filterItems.push({
                id: 'itemState',
                name: 'STATUS', type: 'multiselect',
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: { gray: 'P', red: 'N', yellow: 'O', blueDarker: 'F', blue: 'C', lime: 'A', green: 'R', black: 'D' },
                values:
                [
                    ...(this.rfqPermissionService.isElementAvailable('rfqItemStateFilterStateInProgress') 
                        ? [{ id: 'P', name: 'RFQ_ITEM_STATE_P', dotId: 'P', default: true }] 
                        : []
                    ),
                    { id: 'N', name: 'RFQ_ITEM_STATE_N', dotId: 'N', default: true },
                    { id: 'O', name: 'RFQ_ITEM_STATE_O', dotId: 'O', default: true },
                    { id: 'F', name: 'RFQ_ITEM_STATE_F', dotId: 'F', default: true },
                    { id: 'C', name: 'RFQ_ITEM_STATE_C', dotId: 'C', default: true },
                    { id: 'A', name: 'RFQ_ITEM_STATE_A', dotId: 'A', default: true },
                    { id: 'R', name: 'RFQ_ITEM_STATE_R', dotId: 'R', default: true },
                    { id: 'D', name: 'RFQ_ITEM_STATE_D', dotId: 'D', default: true }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['P', 'N', 'O']
                }
              });
        }
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'rfqItems');
        return filterItems;
        // return [];
    }

    /**
     * Get data for a new item
     */
    public getNewRow(customerId?: number): RfqItem {
        const skipColumns = ['selected', 'actions'];

        const columns = this.getColumns()
                        .map(item => item.id)
                        .filter(col => skipColumns.indexOf(col) === -1);

        const newRow: RfqItem = columns.reduce((acc, col) => Object.assign({}, acc, {[col]: null}), {});
        newRow.id = -(new Date()).getTime();  // negative unique number to differentiate it from real IDs
        // Set single packing
        const SINGLE_PACKING = 'A';
        newRow.packingOptions = [{ packing: SINGLE_PACKING, amount: 1, priceCoefficient: 1}];
        newRow.packing = SINGLE_PACKING;
        newRow.amountInPacking = 1;

        /** TODO: add currency */
        // newRow.currencyPriceRequired = this.rfqsService.getCurrency(customerId);
        // newRow.currencyPriceOffered = this.rfqsService.getCurrency(customerId);
        return newRow;
    }

    /**
     * Validates the whole record (item row)
     */
    isRowValid(row: RfqItem): boolean {
        if (row.id < 0 || row.itemState === 'P') {
            return this.isColValid('amountRequired', row.amountRequired, row)
        }
        const isValid = Object.keys(row)
            .reduce((valid, field) => valid && this.isColValid(field, row[field], row), true);
        return isValid;
    }

    /**
     * Validates one column (item field)
     */
    isColValid(field: string, value: any, row: any): boolean {
        const validationMap = {
            amountRequired: val => val ? parseInt(val.toString().replace(' ', ''), 10) > 0 : false,
            unitPriceRequired: val => val ? (val >= 0 && val <= 999999) : true,
            unitPriceOffered: val => val ? (val >= 0 && val <= 999999) : true,
            dateOfferedDays: val => val === null || val === '' || (val >= 3 && val <= 365),
            dateOfferedValidity: val => {
                // const now = moment().utc().format('DD.MM.YYYY');
                // const dateOfferedValidity = moment(val).utc().format('DD.MM.YYYY');
                // if (val !== null && dateOfferedValidity < now) {
                //     return false;
                // }
                return row.dateOfferedValidity ? !!val : true;
            },
            itemValidity: val => {
                if (val === null || val === '') {
                    return true;
                }
                val = moment(val).utc().format('x');
                const now = moment().utc().format('x');
                const noon = moment().set({'hour': 12, 'minute': 0, 'second': 0}).utc().format('x');
                const now1 = moment().utc().add(1, 'days').format('x');
                const now2 = moment().utc().add(2, 'days').format('x');
                const now366 = moment().utc().add(366, 'days').format('x');

                // if its before noon and valid
                if (now <= noon && (now1 < val && now366 > val)) {
                    return true;
                }

                // if its after noon and valid
                if (now > noon && (now2 < val && now366 > val)) {
                    return true;
                }
                return false;
            },
            rfqProductAS: val => { return val && (val !== '') ? row.rfqProductASInDB || typeof row.rfqProductASInDB === 'undefined' : true; },
            amountPackages: val => val && val > 0 && val < 1000000 && (val % 1 === 0) && val.toString().replace(',', '.').indexOf('.') < 0,
            packing: val => val !== undefined && val !== null
        };

        const isValid = (field in validationMap) ? validationMap[field](value) : true;
        return isValid;
    }

    /**
     * Returns bulk object with items for bulk actions according to given selected items
     */
    public getBulk(selectedItems: any, rfqId: number, subjectRefresh: Subject<any>, rfqObject): TableBulk {
        if (!this.rfqPermissionService.hasPermissionItemsAction()) { // does not have the permission for item actions so return object with empty items array
            return {items: []};
        }

        const actions = {
            DATE_REQUIRED: { itemsCanDo: 0, id: 'dateRequired', name: 'REQUIRED_DATE', value: null, type: 'date' },
            RFQ_ITEM_TYPE: { itemsCanDo: 0, id: 'itemType', name: 'RFQ_ITEM_TYPE', type: 'select', value: '', values: [{ id: null, name: 'RFQ_ITEM_TYPE' }, { id: 'T', name: 'RFQ_ITEM_TYPE_T' }, { id: 'P', name: 'RFQ_ITEM_TYPE_P' }, { id: 'S', name: 'RFQ_ITEM_TYPE_S' }, { id: 'O', name: 'RFQ_ITEM_TYPE_O' }]},
            OFFERED_DATE_DAYS: { itemsCanDo: 0, id: 'dateOfferedDays', name: 'OFFERED_DATE_DAYS', value: null, type: 'input', prefixString: 'D + ' },
            OFFERED_DATE: { itemsCanDo: 0, id: 'dateOffered', name: 'OFFERED_DATE', value: null, type: 'date' },
            OFFERED_DATE_VALIDITY: { itemsCanDo: 0, id: 'dateOfferedValidity', name: 'OFFERED_DATE_VALIDITY', value: null, type: 'date' },
            ITEM_VALIDITY: { itemsCanDo: 0, id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', value: null, type: 'date' },
            DELETE_ITEM: { itemsCanDo: 0, id: 'DELETE_ITEM', name: 'DELETE_SELECTED', value: null, type: 'button', iconClass: 'fa fa-trash' },
            CONFIRM_ITEM: { itemsCanDo: 0, id: 'CONFIRM_ITEM', name: 'RFQ_CONFIRM_ITEM', value: null, type: 'button', iconClass: 'fa fa-check-circle' },
            ACCEPT_ITEM: { itemsCanDo: 0, id: 'ACCEPT_ITEM', name: 'RFQ_ACCEPT_ITEM', value: null, type: 'button', iconClass: 'fa fa-check-circle' },
            CANCEL_ACCEPTED_ITEM: { itemsCanDo: 0, id: 'CANCEL_ACCEPTED_ITEM', name: 'RFQ_CANCEL_ACCEPTED_ITEM', value: null, type: 'button', iconClass: 'fa fa-times-circle' },
            ORDER_ITEM: { itemsCanDo: 0, notInCatalogueCount: 0, id: 'ORDER_ITEM', name: 'MAKE_ORDER', value: null, type: 'button', iconClass: 'fa fa-list-alt', primary: 'CONTINUE', secondary: 'CLOSE' },
            CANCEL_ITEM: { itemsCanDo: 0, id: 'CANCEL_ITEM', name: 'DONT_MAKE_ORDER', value: null, type: 'button', iconClass: 'fa fa-ban' },
            REOPEN_ITEM: { itemsCanDo: 0, id: 'REOPEN_ITEM', name: 'RFQ_REOPEN_ITEM', value: null, type: 'button', iconClass: 'fa fa-undo' },
            DISCOUNT_APPLY_ITEM: { itemsCanDo: 0, id: 'DISCOUNT_APPLY_ITEM', name: 'DISCOUNT_APPLY', value: null, type: 'button', iconClass: 'fa fa-tags' },
        };
        const bulkItems: any[] = [ // those which are shown according to actions array in items
            actions.RFQ_ITEM_TYPE,
            actions.OFFERED_DATE_DAYS,
            actions.OFFERED_DATE,
            actions.OFFERED_DATE_VALIDITY,
            actions.ITEM_VALIDITY,
            actions.DELETE_ITEM,
            actions.DISCOUNT_APPLY_ITEM,
            actions.CONFIRM_ITEM,
            actions.ACCEPT_ITEM,
            actions.CANCEL_ACCEPTED_ITEM,
            actions.ORDER_ITEM,
            actions.CANCEL_ITEM,
            actions.REOPEN_ITEM,
        ];

        const makeAction = (action) => {
            let question = this.translateService.instant('QUESTION_ACTION_BULK') + '? '
            if (action.id === 'DISCOUNT_APPLY_ITEM') {
                // show modal with discount input, after confirm do calculations over unitPriceOffered otherwise cancel
                // if confirmed recalculate price and show success notification,  if canceled only close modal without change
                question = this.translateService.instant('DISCOUNT_ON') + ' ' + action.itemsCanDo + '/' + action.items + ' ' +

                this.translateService.instant('DISCOUNT_ITEMS_OF_RFQ_NUMBER') + ' ' + rfqObject.rfqNumber;
                this.discountDialogService.confirm({
                    message: 'BULK_ACTION_' + action.id, question: question,
                    suppressQuestionMark: false,
                    primary: action.primary ? action.primary : 'DISCOUNT_APPLY',
                    secondary: action.secondary ? action.secondary : 'CLOSE',
                    inputLabel: this.translateService.instant('DISCOUNT_AMOUNT')
                }).subscribe(res => {
                    if (typeof res === 'object') {
                        const modifiedItems = [];
                        // if (!res.textValue || res.textValue < 0 ) { res.textValue = 0; } // if value is not present, use 0
                        if (!res.textValue ) { res.textValue = '0'; } // if value is not present, use 0
                        const coefficient: number = parseFloat(res.textValue.toString().replace(',', '.'));
                        Object.keys(selectedItems).forEach((key) => {
                            const item = selectedItems[key];
                            if (item.unitPriceCatalogue && coefficient > 0 &&
                                this.rfqPermissionService.isCellEditable(selectedItems[key], 'unitPriceOffered')) {
                                // Formula: „Katalógová cena“ * (100 - „Výška zľavy v %“) / 100, rounded by 2 decimals
                                // console.log( '(' + item.unitPriceCatalogue + '* (100 - ' + res.textValue + ')) / 100' );
                                const itemResult = (item.unitPriceCatalogue * (100 - coefficient)) / 100;

                                // item.unitPriceOffered = this.rfqsService.roundTo(itemResult, 2); // round to 2 decimals but if third decimal exists round properly
                                item.unitPriceOffered = this.sharedService.roundTo(itemResult, 2); // round to 2 decimals but if third decimal exists round properly
                                modifiedItems.push(item);
                            } else if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'unitPriceOffered')) {
                                item.unitPriceOffered = item.unitPriceCatalogue;
                                modifiedItems.push(item);
                            }
                        });

                        // this.resetSelection();
                        // subjectRefresh.next();
                        this.rfqsService.updateRfqItems(rfqId, modifiedItems, rfqObject).subscribe(result => {
                            // this.resetSelection();
                            subjectRefresh.next();
                            this.toastService.addSuccess(this.sharedService.translateService.instant('DISCOUNT_SUCCESS'));
                        },
                        error => {
                            console.log(error);
                            this.toastService.addError(this.sharedService.translateService.instant('DISCOUNT_ERROR'));
                        });
                    }
                });


            } else {
                if (action.id === 'ORDER_ITEM' && action.notInCatalogueCount > 0) {
                    question += ' ' + this.translateService.instant('SOME_ITEMS_ARE_NOT_FROM_CATALOGUE');
                }
                const attachmentsAffected = Object.keys(selectedItems).filter(key => selectedItems[key].attachmentsCount > 0).length > 0;
                if (action.id === 'DELETE_ITEM' && attachmentsAffected) {
                    question = this.translateService.instant('DELETE_RFQ_ITEMS_WITH_ATTACHMENTS_QUESTION') + ' ' + question;
                }
                question += (action.itemsCanDo < Object.keys(selectedItems).length ?
                    this.translateService.instant('ACTION_CAN_BE_DONE_ONLY_ON') + action.itemsCanDo + ' ' + this.translateService.instant('OF') + ' ' + Object.keys(selectedItems).length + this.translateService.instant('FROM_SELECTED_ITEMS') + '.'
                    : '');
                this.questionDialogService.confirm({
                    message: 'BULK_ACTION_' + action.id, question: question,
                    suppressQuestionMark: true,
                    primary: action.primary ? action.primary : 'YES',
                    secondary: action.secondary ? action.secondary : 'NO'
                }).subscribe (
                    res => {
                        if (res === 'confirm') {
                            if (action.id === 'ORDER_ITEM') {
                                this.orderDialogService.confirm({
                                    mode: OrderDialogMode.Details, data: {
                                        showOrderComment: true,
                                        orderComment: '',
                                        orderNumberCustomer: '',
                                        orderNote: ''
                                    }
                                }).subscribe(
                                    dsResult => {
                                        if (dsResult.state === OrderDialogResultState.Order) {
                                            delete dsResult.data.showOrderComment;
                                            dsResult.data.discussionText = dsResult.data.orderComment;
                                            delete dsResult.data.orderComment;
                                            this.rfqsService.rfqItemsAction(rfqId,
                                                Object.keys(selectedItems).filter(key => selectedItems[key].actions.indexOf(action.id) > -1).map(key => key),
                                                action.id,
                                                dsResult.data
                                            ).subscribe((result: {purchaseOrder: {id: number}}) => {
                                                console.log('result from order', result);

                                                if (!result.purchaseOrder.id) {
                                                    this.toastService.addError('RFQ_ORDER_NOT_CREATED', 10000);
                                                } else {
                                                    this.toastService.addSuccess('ORDER_CREATE_SUCCESS');
                                                }
                                                // this.resetSelection();
                                                subjectRefresh.next();
                                                this.discussionService.getCommentObservable().next({});
                                            });
                                        }
                                    }
                                );
                            } else if (action.id === 'DELETE_ITEM') {
                                this.rfqsService.deleteRfqItems(rfqId,
                                    Object.keys(selectedItems).filter(key => selectedItems[key].actions.indexOf(action.id) > -1).map(key => { return { id: parseInt(key)} }),
                                ).subscribe(result => {
                                    this.resetSelection();
                                    subjectRefresh.next();
                                    if (attachmentsAffected) {
                                        this.subjectAttachmentChanged.next();
                                    }
                                });
                            } else {
                                this.rfqsService.rfqItemsAction(rfqId,
                                    Object.keys(selectedItems).filter(key => selectedItems[key].actions.indexOf(action.id) > -1).map(key => key),
                                    action.id
                                ).subscribe(result => {
                                    // this.resetSelection();
                                    subjectRefresh.next();
                                });
                            }
                        } else {
                            return new Observable(observer => {
                                observer.next();
                                observer.complete();
                            })
                        }
                    }
                );
            }
        }

        Object.keys(selectedItems).forEach(key => {
            selectedItems[key].actions.forEach(action => {
                if (actions[action]) {
                    actions[action].itemsCanDo += 1;
                    if (action === 'ORDER_ITEM') {   // special for order_item action
                        if (!selectedItems[key].rfqProductAS) {
                            actions[action].notInCatalogueCount += 1;
                        }
                    }
                }
            });
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'itemType')) { // special for rfq type
                actions['RFQ_ITEM_TYPE'].itemsCanDo += 1;
            }
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'dateOfferedDays')) { // special for rfq type
                actions['OFFERED_DATE_DAYS'].itemsCanDo += 1;
            }
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'dateOffered')) { // special for rfq type
                actions['OFFERED_DATE'].itemsCanDo += 1;
            }
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'dateOfferedValidity')) { // special for rfq type
                actions['OFFERED_DATE_VALIDITY'].itemsCanDo += 1;
            }
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'itemValidity')) { // special for rfq type
                actions['ITEM_VALIDITY'].itemsCanDo += 1;
            }
            if (this.rfqPermissionService.isCellEditable(selectedItems[key], 'unitPriceOffered')) { // special for rfq type
                actions['DISCOUNT_APPLY_ITEM'].itemsCanDo += 1;
            }
        });
        for (let i = bulkItems.length - 1; i >= 0; i--) {
            if (bulkItems[i].itemsCanDo === 0) {
                bulkItems.splice(i, 1);
            } else {
                if (bulkItems[i].itemsCanDo < Object.keys(selectedItems).length) {
                    bulkItems[i].name = this.translateService.instant(bulkItems[i].name) + ' ' + bulkItems[i].itemsCanDo + '/' + Object.keys(selectedItems).length;
                }
                bulkItems[i].items = Object.keys(selectedItems).length;
                const itemCopy = Object.assign({}, bulkItems[i]);
                bulkItems[i].click = () => makeAction(itemCopy);
            }
        }

        // add bulk actions that are shown according to editable columns
        if (this.rfqPermissionService.isColumnEditable('dateRequired')) {
            bulkItems.unshift(actions.DATE_REQUIRED);
        }
        return {
            update: (items, data: {[key: string]: any}) => {  // data: {<attr to set>: <new value>}
                return this.questionDialogService.confirm(
                    { message: 'BULK_UPDATE', question: 'QUESTION_UPDATE_BULK', primary: 'YES', secondary: 'NO' }
                ).pipe(switchMap(
                    res => {
                        if (res === 'confirm') {
                            let dataKey: string; // e.g. 'itemValidity'
                            Object.keys(data).forEach(_dataKey => { // set new values to items from bulk update action
                                dataKey = _dataKey;
                            });
                            const updateData = Object.keys(selectedItems)
                                .filter(key => {
                                    if (dataKey === 'itemType' || dataKey === 'dateOfferedDays' || dataKey === 'dateOffered' || dataKey === 'dateOfferedValidity' || dataKey === 'unitPriceOffered' || dataKey === 'itemValidity') {
                                        return this.rfqPermissionService.isCellEditable(selectedItems[key], dataKey);
                                    } else {
                                        return true;
                                    }
                                })
                                .map(key => {
                                    const item = Object.assign({}, selectedItems[key]);
                                    // set new values to items from bulk update action
                                    item[dataKey] = data[dataKey]
                                    return item;
                                });

                            return this.rfqsService.updateRfqItems(rfqId, updateData, rfqObject)
                                .pipe(map(result => {
                                    this.adjustOriginalValuesOfSelectedItems(dataKey, data[dataKey]);
                                    return result;
                                }));
                        } else {
                            return new Observable(observer => {
                                observer.next();
                                observer.complete();
                            })
                        }
                    }
                ));
            },
            items: bulkItems
        };
    }

    /**
     * After a bulk update it is necessary to update value in originalValues attribute of selected itens
     * because it is not update by rows reload
     * @param key column ID e.g. 'itemValidity'
     * @param value value to set
     */
    adjustOriginalValuesOfSelectedItems(key: string, value: any) {
        if (this.sharedService.user.preferences['rfqItemsSelection'].ids) {  // some rows are selected
            Object.keys(this.sharedService.user.preferences['rfqItemsSelection'].ids).forEach(id => {
                if (this.sharedService.user.preferences['rfqItemsSelection'].ids[id].originalValues) {  // row has originalValues
                    const foundArr = this.sharedService.user.preferences['rfqItemsSelection'].ids[id].originalValues
                        .filter(colObj => colObj.colId === key);

                    if (foundArr.length === 1) {
                        foundArr[0].value = value;
                    }
                }
            });
        }
    }

    resetSelection() {
        // this.sharedService.user.preferences['rfqItemsSelection'].ids = {};
        // this.sharedService.user.preferences['rfqItemsSelection'].all = false;
        // this.sharedService.user.preferences['rfqItemsSelection'].visible = false;
        // this.subjectItemsSelected.next();
        // localStorage.setItem('user', JSON.stringify(this.sharedService.user));
        this.sharedService.resetSelection('rfqItemsSelection', this.subjectItemsSelected);
    }

    private onRowChange(changeParams: {data: RfqItem, rowIndex: number, params: ICellRendererParams}): void {
        // console.log('onRowChange', changeParams);
        if (changeParams.params.column.getColId() === 'rfqProductAS') {
            this.clearPackingAttrs(changeParams.data, changeParams.params);
        }
    }

    private clearPackingAttrs(data: RfqItem, params: ICellRendererParams): void {
        data.packing = null;
        data.packingOptions = [];
        data.amountInPacking = null;
        data.amountPackages = null;
        data.amountOffered = null;

        params.api.refreshCells({ rowNodes: [params.node],
            columns: ['packing', 'amountInPacking', 'amountPackages', 'amountOffered']
        });
        this.rfqsService.getProductPackingOptions(data.rfqProductAS, data.brandCode)
        .subscribe(packingOptions => {
            // console.log('getProductPackingOptions packingOptions', packingOptions);
            data.packingOptions = packingOptions;
            params.api.refreshCells({ rowNodes: [params.node], columns: ['packingOptions'] });
        });
    }

    /**
     * Called on each cell value change
     */
    private onCellChange(change: CellChange): void {
        if (change.changedColumnField === 'rfqProductAS') {
            // Set 'packing' and 'packingOptions' from the previously queried rfqProductAS attribute
            const row: RfqItem = change.data;
            const productLookupItem = this.rfqService.getLastProductLookupItem(row.rfqProductAS, 'rfqProductAS');

            if (!productLookupItem) {
                const newRow = this.getNewRow();
                // row.packing = null;
                // row.packingOptions = [];
                row.packing = newRow.packing;
                row.packingOptions = newRow.packingOptions;
            } else {
                row.packing = productLookupItem.defaultPacking;
                row.packingOptions = productLookupItem.packingOptions;
            }
            change.api.refreshCells({ rowNodes: [change.node],
                columns: ['packing'] });

            // console.log('rfqProductAS changed:', row.rfqProductAS, 'setting packing:', row.packing, 'setting packingOptions', row.packingOptions);
        }
    }

}
