import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { Observable } from 'rxjs';
import { ProductAvailabilityObj } from '@app/model/product.model';
import { ICellRendererParams } from 'ag-grid-community';

export interface GridCatalogueAvailabilityRendererComponentParams {
    availabilityGetter: (brandCode: string, eCommProductId: string, clickedColumn?: string) => Observable<ProductAvailabilityObj>,
}

interface MyParams extends ICellRendererParams, GridCatalogueAvailabilityRendererComponentParams {}

@Component({
    selector: 'app-availability-renderer',
    template: `	<div *ngIf="canOpenPopover" style="padding: 5px 0; margin-top: -5px;" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <ng-template #popTemplate>
                        <div (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                            <div style="text-align: right;">
                                <i class="clickable fa fa-times-circle" (click)="close()"></i>
                            </div>
                            <div style="margin-bottom: 8px;">
                                <span translate>NEXT_POSSIBLE_DATES_OF_DELIVERY</span>:
                            </div>
                            <div *ngIf="!busy">
                                <span *ngIf="availability.futureDelivery1_4 && availability.futureDelivery1_4.length > 0" translate>IN_PRODUCTION</span>
                                <div *ngFor="let availability of availability.futureDelivery1_4">
                                    {{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language:false:false}} <span translate>UNIT</span>
                                </div>
                                <span *ngIf="availability.futureDelivery5_7 && availability.futureDelivery5_7.length > 0" translate>IN_PRODUCTION_PLAN</span>
                                <div *ngFor="let availability of availability.futureDelivery5_7">
                                    {{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language:false:false}} <span translate>UNIT</span>
                                </div>
                                <span *ngIf="availability.futureDelivery0_30As5 && availability.futureDelivery0_30As5.length > 0" translate>IN_PRODUCTION</span>
                                <div *ngFor="let availability of availability.futureDelivery0_30As5">
                                    {{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language:false:false}} <span translate>UNIT</span>
                                </div>
                                <span *ngIf="availability.futureDelivery30_As5 && availability.futureDelivery30_As5.length > 0" translate>IN_PRODUCTION_PLAN</span>
                                <div *ngFor="let availability of availability.futureDelivery30_As5">
                                    {{availability.deliveryDate | localdate:sharedService.appSettings.language}}: {{availability.amount | localnumber:sharedService.appSettings.language:false:false}} <span translate>UNIT</span>
                                </div>
                            </div>
                            <busy-indicator *ngIf="busy"></busy-indicator>
                        </div>
                    </ng-template>
    				<div *ngIf="!withPopover" class="fnt-primary">
                        {{params.value ? (params.value | localnumber:sharedService.appSettings.language:false:false) : ''}}
                    </div>
                    <div *ngIf="withPopover" class="fnt-primary clickable"
                         #pop="bs-popover" [popover]="popTemplate" container="body" triggers=""
                         (click)="show(pop)">
                        {{params.value ? (params.value | localnumber:sharedService.appSettings.language:false:false) : ''}}
                    </div>
    			</div>

                <div *ngIf="!canOpenPopover">
                    {{params.value ? (params.value | localnumber:sharedService.appSettings.language:false:false) : ''}}
                </div>
                `
})
export class GridCatalogueAvailabilityRendererComponent implements AgRendererComponent {
    params: MyParams;

    canOpenPopover: boolean;
    withPopover: boolean; // popover with container body cannot be in DOM a lot of times, so change alement without popover
                                  // to element with popover when this is true - when this grid cell is mouseover

    busy: boolean;
    availability: ProductAvailabilityObj;
    private openedPopupTimeout: any;

    constructor(
        public sharedService: SharedService,
    ) {
        this.canOpenPopover = this.sharedService.hasPermission('products-availability/*', 'GET');
    }

    agInit(params: MyParams): void {
        this.params = params;
    }

    mouseover() {
        this.withPopover = true;
        clearTimeout(this.openedPopupTimeout);
    }
    mouseleave() {
        clearTimeout(this.openedPopupTimeout);
        this.openedPopupTimeout = setTimeout(() => {
            this.withPopover = false;
        }, 3000);
    }

    show(pop: any) {
        this.busy = true;
        pop.show();
        this.params.availabilityGetter(this.params.data.brandCode, this.params.data.eCommProductId, this.params.colDef.field).subscribe(data => {
            this.availability = data;
            this.busy = false;
        }, err => {
            this.busy = false;
            console.log(err)
        });
    }

    close() {
        this.withPopover = false;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
