/**
 * Grid renderer component for 'dateOfferedCalculated' column of rfq items
 * Calculates the value based on 'dateOfferedDays', 'dateOffered', 'dateOfferedValidity'
 */
import { Component } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { LocalDatePipe } from '../../../../locale.pipes.module';
import { SharedService } from '../../../../services/shared.service';
import { LanguageService } from '../../../../services/language.service';
import * as moment from 'moment-timezone';

@Component({
    selector: 'grid-rfq-date-offered',
    template: `
        <div class="center-text">
            <ng-template #textToolTip><span>{{'OFFERED_DATE_DAYS_TOOLTIP' | translate}}: {{computedDate | localdate:sharedService.appSettings.language}}</span></ng-template>
            <span *ngIf="textValue" [popover]="textToolTip" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="right">{{textValue}}</span>
            <ng-template #dateToolTip><span translate>OFFERED_DATE_TOOLTIP</span></ng-template>
            <span *ngIf="dateValue" [popover]="dateToolTip" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="right">{{dateValue | localdate:sharedService.appSettings.language}}</span>
        </div>
        `
})
export class GridRfqDateOfferedRendererComponent implements AgRendererComponent {
    params: any;
    textValue = '';
    dateValue = '';
    computedDate: any;

    constructor(
        public sharedService: SharedService,
        public languageService: LanguageService
    ) { }

    agInit(params: any): void {
        this.params = params;

        this.setDisplayValue();
    }

    setDisplayValue() {
        if (this.params.data.dateOfferedDays) {
            this.textValue = `D + ${this.params.data.dateOfferedDays}`;
            this.computedDate = new Date();
            this.computedDate.setDate(this.computedDate.getDate() + this.params.data.dateOfferedDays);
        } else if (this.params.data.dateOffered) {
            this.dateValue = this.params.data.dateOffered;
        } else {
            this.textValue = '';
            this.dateValue = '';
        }

        if (this.params.data.dateOfferedValidity &&
            (moment(this.params.data.dateOfferedValidity) < moment(moment().format('YYYY-MM-DD')))) {
            this.computedDate =  '';
            this.textValue = '';
            this.dateValue = '';
        }
    }

    /**
     * Has to be implemented in ag-grid cell, 
     * return false if we do not handle refresh and just destroy and recreate cell
     */
    refresh(): boolean {
        return false;
    }

}