<div class="row-container wider" *ngIf="params">
    <div style="display: block; overflow-y: auto; min-height: 340px;" [ngStyle]="{'max-height.px': maxHeight}">
    	<div class="input-container" style="display: block;">
            <label translate>REQUESTED_EXPEDITION_DATE</label>
            <div fxLayoutAlign="start center" style="width: 300px;">
                <div style="margin-right: 10px; padding-top: 5px;">
                    <my-date-picker 
                        [(ngModel)]="params.shipmentDate"
                        [options]="params.myDatePickerOptions"
                        [locale]="this.sharedService.appSettings.language"
                        [selector]="params.selector"
                        (dateChanged)="onDateChanged($event)"></my-date-picker>
                </div>
                <timepicker [(ngModel)]="params.pickUpTime" [showMeridian]="false" style="display: inline-block;"></timepicker>
            </div>
            <div class="fnt-red" style="display: inline-block; margin-bottom: 5px" *ngIf="shipmentDateInvalid || !params.shipmentDate">
                <span translate>INVALID_DATA_TYPE:_DELIVERYDATE_ERROR</span>
            </div>
        </div>
        <div class="input-container" style="display: block;">
            <label translate>EXPEDITION_NOTE</label>
    		<input type="text" name="orderNote" [(ngModel)]="params.shipmentNote" class="form-control">
        </div>
    </div>

    <div style="padding-top: 15px;">
    	<button class="btn-secondary" (click)="close()" style="width: 100px;">
            <span translate>CLOSE</span>
        </button>
        <button class="btn-primary" style="float: right" (click)="close(params)" [disabled]="disableSubmit || (shipmentDateInvalid || !params.shipmentDate)" [ngClass]="{'ec-disabled': disableSubmit || (shipmentDateInvalid || !params.shipmentDate)}">
            <img src="../../assets/images/shipment_white.png" style="width: 22px; margin-right: 5px;"/>
            <a translate>PREPARE_FOR_EXPEDITION</a>
        </button>
    </div>
</div>