<div class="row-container wider">
    <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/c-checks"
        [queryParams]="sharedService.lastCChecksQueryParams"
    >
        <i class="fa fa-arrow-circle-left"></i>
        <a translate>BACK</a>
    </button>
</div>	
<div class="row-container wider">
    <div class="area-title">
        <h3><i class="fa fa-flask"></i><span translate>ERP_DATA_CONSISTENCY_CHECK</span>: {{'MODULE_deliveryOrder' | translate}}, {{checkDate | localdate:sharedService.appSettings.language:true}}</h3>
    </div>
</div>
<div class="row-container wider">
    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getCCheckDeliveryOrders"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>