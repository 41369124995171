import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { NewsService} from '../../../../services/news/news.service';
import { ToastService} from '../../../../services/toastService/toast.service';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

export interface GridLinkRendererComponentParams {
    showIcon?: string, // icon class 'clickable fnt-red fa fa-exclamation-circle'
    showIconFn?: (params) => string, // as showIcon, but fn
    iconClassAttr?: string, // as showIcon but it is an attr name which contains the icon class
    aColor?: string, // 'rgb(255, 53, 53)'
    backUrl?: string, // 'rfqs-items',
    backQueryParamsAttrName?: string // 'lastRfqsItemsQueryParams'
    markAsRead?: boolean, // only for news - TODO: refactor this

    // Implicit parameters which come in 'data' attribute:
    // url: string, // url to redirect to
    // isUrlAbsolute?: boolean // absolute URL will not be modified and will be opened in a new tab
}

interface MyParams extends ICellRendererParams, GridLinkRendererComponentParams {}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'link-cell',
    template: `
            <a *ngIf="!params.data.isUrlAbsolute" 
                class="clickable" 
                style="display: inline-block; width: 100%; margin: -10px 0 -4px 0; padding: 8px 2px; text-align: left;" 
                [routerLink]="[routerLink]" (click)="linkClick()">

                    <span style="font-weight: 600" [style.color]="aColor">
                        <span *ngIf="showIcon" style="position: relative"><i class="{{ iconClass }}" aria-hidden="true"></i></span>
                        {{params.value}}
                    </span>
            </a>
            <a *ngIf="params.data.isUrlAbsolute" 
                class="clickable" 
                style="display: inline-block; width: 100%; margin: -10px 0 -4px 0; padding: 8px 2px; text-align: left;" 
                [href]="params.data.url"
                target="_blank">

                    <span style="font-weight: 600" [style.color]="aColor">
                        <span *ngIf="showIcon" style="position: relative"><i class="{{ iconClass }}" aria-hidden="true"></i></span>
                        {{params.value}}
                    </span>
            </a>
    `
})
export class GridLinkRendererComponent implements AgRendererComponent {
    public params: MyParams;
    public showIcon = false;
    public iconClass = '';
    public aColor: string;
    public routerLink: string;

    constructor(
        public sharedService: SharedService,
        public newsService: NewsService,
        public toastService: ToastService
    ) {
    }

    agInit(params: MyParams): void {
        this.params = params;

        this.routerLink = this.params.data.isUrlAbsolute
            ? this.params.data.url
            : '/' + this.sharedService.appSettings.language + '/' + params.data.url;

        if (params.showIcon && params.showIcon.length > 0) {
            this.iconClass = params.showIcon;
            this.showIcon = true;
        }

        if (params.showIconFn) {
            const iconClass = params.showIconFn(params);
            if (iconClass.length > 0) {
                this.iconClass = iconClass;
                this.showIcon = true;
            }
        }

        if (params.iconClassAttr) {
            this.iconClass = params.data[params.iconClassAttr];
            this.showIcon = true;
        }

        if (params.aColor) {
            this.aColor = params.aColor;
        } else {
            this.aColor = 'rgb(0,0,0)';
        }
    }

    public linkClick(): void {
        if (this.params.backUrl) {
            this.sharedService.backUrl = this.params.backUrl;
            this.sharedService.backQueryParams = this.sharedService[this.params.backQueryParamsAttrName];
        }
        if (this.params.markAsRead) {
            this.newsService.markAsReadNews(this.params.data.id).subscribe(
                res => {
                    this.toastService.addSuccess('NEWS_SUCCESSFULLY_READ');
                    this.sharedService.setNewsCountStorage( parseInt(this.sharedService.getNewsCount(), 10) - 1);
                    this.sharedService.refreshNewsCount();
                },
                err => console.log(err)
            );
        }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
