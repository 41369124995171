import { Observable ,  Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { NewsDiscussionDialogComponent } from '../../components/newsComponent/newsDiscussionDialogComponent/news.discussion.dialog.component';
@Injectable()
export class NewsDiscussionDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    // if load discussion is true, load of comments is needed in dialog e.g. in orders items
    public confirm(params: {data: any, isModal?: boolean, commentsChangedSubject: Subject<number>}
        ): Observable<any> {
        this.bsModalRef = this.modalService.show(NewsDiscussionDialogComponent, Object.assign({}, {
            class: 'news-discussions-modal',
            ignoreBackdropClick: true,
            keyboard: false,
        }));

        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
