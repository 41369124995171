import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Animations } from '../../../../animations';
import { Rfq } from '../../../../model/rfq.model';
import { RfqService } from '../../../../services/rfq/rfq.service';
import { Params } from '@angular/router';

@Component({
    selector: 'app-rfq-detail-note',
    templateUrl: 'rfq.detail.note.component.html',
    styleUrls: [
        './rfq.detail.note.component.scss'
    ],
    animations: [Animations.slideInOut]
})
export class RfqDetailNoteComponent implements OnInit, OnDestroy {
    @Input() rfq: Rfq;
    @Input() isNoteEditable: boolean;
    // Emits boolean when edited or changed
    @Output() edited: EventEmitter<boolean> = new EventEmitter<boolean>();

    note: string;
    oldNote: string;
    isEditing = false;
    constructor(
        private rfqService: RfqService
    ) {

    }

    ngOnInit() {
        this.note = this.rfq.rfqNote;
        this.oldNote = this.rfq.rfqNote;
    }

    ngOnDestroy() { }

    updateNote() {
        this.rfqService.updateRfqNote(this.rfq.id, this.note).subscribe(
            (params: Params) => {
                this.rfq.rfqNote = this.note;
                this.oldNote = this.note;
                this.isEditing = false;
                this.edited.emit(this.isEditing);
            },
            error => {
                console.log(error);
            });
    }

    cancelNote() {
        this.note = this.rfq.rfqNote;
        this.isEditing = false;
        this.edited.emit(this.isEditing);
    }

    onNoteChange() {
        if (!this.isEditing) {
            this.isEditing = true;
            this.edited.emit(this.isEditing);
        }
    }
}
