export enum AppAreas {
    'catalogue' = 'catalogue',
    'catalogue-sale' = 'catalogue-sale',
    'purchase-catalogue' = 'purchase-catalogue',
    'rfqs' = 'rfqs',
    'rfqs-items' = 'rfqs-items',
    'rfq' = 'rfq',
    'prfqs' = 'prfqs',
    'prfqs-items' = 'prfqs-items',
    'prfq' = 'prfq',
    'orders' = 'orders',
    'orders-items' = 'orders-items',
    'order' = 'order',
    'delivery-orders' = 'delivery-orders',
    'delivery-orders-items' = 'delivery-orders-items',
    'delivery-order' = 'delivery-order',
    'invoices' = 'invoices',
    'invoices-items' = 'invoices-items',
    'invoice' = 'invoice',
    'controlling' = 'controlling',
    'monitoring' = 'monitoring',
    'journal' = 'journal',
    'customers' = 'customers',
    'customer' = 'customer',
    'suppliers' = 'suppliers',
    'supplier' = 'supplier',
    'users-admin' = 'users-admin',
    'price-lists' = 'price-lists',
    'price-lists-items' = 'price-lists-items',
    'price-list' = 'price-list',
    'c-checks' = 'c-checks',
    'c-check-purchase-order' = 'c-check-purchase-order',
    'c-check-delivery-order' = 'c-check-delivery-order',
    'sent-emails' = 'sent-emails',
    'as-stock' = 'as-stock',
    'customer-stock' = 'customer-stock',
    'current-order' = 'current-order',
    'shipments' = 'shipments',
    'shipments-items' = 'shipments-items',
    'shipment' = 'shipment'
}
