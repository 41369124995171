<div *ngIf="sharedService.apParams.id === 1" id="footer" class="footer-component as1 row-container bigger bkg-primary">
	<div class="all-left">
		<app-footer-links></app-footer-links>
		<app-copyright style="display: block; margin-top: 10px;" [smaller]="true"></app-copyright>
	</div>
</div>

<div *ngIf="sharedService.apParams.id !== 1" id="footer" class="footer-component asx row-container bigger bkg-primary" fxLayout="row">
	<div fxFlex="50" class="left">
		<app-copyright></app-copyright>
	</div>
	<div class="links" fxFlex="50" fxLayoutAlign="end start">
		<app-footer-links></app-footer-links>
	</div>
</div>
