export interface ProjectPriceList {
    id: number,
    priceListName: string,
    priceListCode: string,
    priceListCurrency: string,
    valid?: number
}

export class Customer {
    public customerAdminEmail: string; // used in creation of customer, its email of first user of customer to be created
    public permissionGroups: string[]; // groups that are allowed for customer to add to users
    public customerAdminLanguage: string; // language for admin email
    constructor(
        public id: number,
        public authorizedSellerId: string,
        public customerName: string,
        public customerCode: string,
        public contactName: string,
        public contactPhone: string,
        public contactEmail: string,
        public assignedSellerName: string,
        public assignedSellerPhone: string,
        public priceListCode: string,
        public priceListCurrency: string,
        public productsNotTakenNotification: number,
        public locality: string,
        public country: string,
        public city: string,
        public street: string,
        public zip: string,
        public valid: number,
        public notActivatedAdminUser: {id: number, email: string},
        public url: string,
        public projectPriceLists: ProjectPriceList[], // array of project pricelists
        public isERPCustomer: boolean | number,
        public customerStockActive: boolean | number,
        public isRfqManagedByAs: boolean | number,
        public customerStockForOtherCustomers: boolean | number,
        public customerStockForSubcustomers: boolean | number
    ) {}
}

// Subset of customer attributes for 'role as customer' in RFQ
export interface RoleCustomer {
    id: number,
    customerName: string,
    // priceListCurrency: string,
    // locality: string
}
