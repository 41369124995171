import { Injectable } from '@angular/core';
import { EcmHttpQueryParams, EcmHttpService } from '../http/ecm.http.service';
import { Observable ,  Subject } from 'rxjs';
import { Shipment, ShipmentItemsUpdateSet, SaveShipmentResponse, ShipmentItemsResponse, BulkImportRow, BulkImportResponse } from '../../model/shipment.model';
import { SharedService } from '../shared.service';
import { ShipmentGridService } from './shipment.grid.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ShipmentService {
    subjectReloadCurrent: Subject<any>;

    constructor (private http: EcmHttpService, 
                 private sharedService: SharedService, 
                 private shipmentGridService: ShipmentGridService) {
        this.subjectReloadCurrent = new Subject<any>();
    }

    /**
     * Returns observable of reload - used to reload table data
     */
    getReloadCurrentSubject():Subject<any> {
        return this.subjectReloadCurrent;
    }

    /**
     * Loads details of shipment
     *
     * @param shipmentId - identificator of order in ecommerce
     */
    public getShipmentDetails(shipmentId: string): Observable<Shipment> {
        return this.http.get(`/shipments/` + shipmentId).pipe(map((data) => {
            let shipment: Shipment;
            shipment = new Shipment(
                data.id,
                data.shipmentNumberEComm,
                data.shipmentDateRequested,
                data.shipmentNote,
                data.shipmentState,
                data.dateCreated,
                data.createdSortName,
                data.createdFullName,
                data.dateChanged,
                data.changedSortName,
                data.changedFullName,
                '/shipment/' + data.id,
                data.customerId,
                data.customerName,
                data.authorizedSellerId,
                data.authorizedSellerName
            );

            return shipment;
        } ));
    }

    /** 
     * Loads list of shipment items
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    public getShipmentItems(shipmentId: string, query: any): Observable<ShipmentItemsResponse> {
        var this_ = this;
        let url = shipmentId == 'new' ? `/shipments-items` : `/shipments/` + shipmentId + `/items`;
        if (!query.hasOwnProperty('amountShipment.value')) {
            query['amountShipment.operator'] = 'ne';
            query['amountShipment.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountShipment.operator'];
            delete query['amountShipment.value'];
        }
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => {
            data.totalAmountCurrent = 0;
            data.totalWeightCurrent = 0;
            data.totalValueCurrent  = Object.keys(data.totalPrice).reduce((acc, key) => Object.assign(acc, {[key]: 0}), {});
            this_.sharedService.user.preferences.shipmentSelection.visible = false;
            // this_.sharedService.user.preferences.shipmentSelection.ids = {};
            data.shipIds.forEach(function(id){
                // if (!this_.sharedService.user.preferences.shipmentSelection.deselectedIds[id]) {this_.sharedService.user.preferences.shipmentSelection.ids[id] = {};}
                if (!this_.sharedService.user.preferences.shipmentSelection.deselectedIds[id]) {this_.sharedService.user.preferences.shipmentSelection.ids[id] = null;}
            });
            data.rows.forEach(function(product){
                product.url = '/catalogue/' + product.brandCode + '/' + encodeURIComponent(product.eCommProductId);
                data.totalAmountCurrent += product.amountShipment;
                data.totalWeightCurrent += product.rowWeight;
                data.totalValueCurrent[product.currency] += product.rowPrice;
                if (product.toShip == 1 && !this_.sharedService.user.preferences.shipmentSelection.deselectedIds[product.id]) {
                    this_.sharedService.user.preferences.shipmentSelection.ids[product.id] = product;
                }
            })
            // this.shipmentGridService.getItemsSelectedSubject().next();

            return data;
        }));
    }

    public exportShipmentItems(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        if (!query.hasOwnProperty('amountShipment.value')) {
            query['amountShipment.operator'] = 'ne';
            query['amountShipment.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountShipment.operator'];
            delete query['amountShipment.value'];
        }
        return this.http.get('/shipments-items', this.http.prepareOptions(query))
    }

    /**
     * Exports detail items
     */
    public exportDetail(shipmentId: string, query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        let url = `/shipments/` + shipmentId + `/items`;
        if (!query.hasOwnProperty('amountShipment.value')) {
            query['amountShipment.operator'] = 'ne';
            query['amountShipment.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountShipment.operator'];
            delete query['amountShipment.value'];
        }
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Creates new/Updates existing shipment
     */
    public saveShipment(shipmentId: string, data: ShipmentItemsUpdateSet): Observable<SaveShipmentResponse> {
        if (shipmentId == 'new') {
            return this.http.post(`/shipments` , JSON.stringify(data));
        } else {
            return this.http.put(`/shipments/` + shipmentId, JSON.stringify(data));
        }
    }

    /**
     * Cancels existing shipment
     */
    cancelShipment(shipmentId: string) {
        return this.http.delete(`/shipments/` + shipmentId);
    }

    /**
     * Retruns visibility object for buttons
     */
    getVisibilityOfButtons(id: string, shipment: Shipment) {
        return {
            cancelCreate: id == 'new',
            cancelChanges: id != 'new' && this.sharedService.hasPermission('shipments/*', 'PUT'),
            saveChanges: id == 'new' || this.sharedService.hasPermission('shipments/*', 'PUT'),
            cancelShipment: id != 'new' && this.sharedService.hasPermission('shipments/*', 'DELETE'),
            prepareForShipment: this.sharedService.hasPermission('shipments', 'POST'),
        };
    }

    /**
     * Creates shipment from given bulk data
     */
    public createShipmentFromImportedData(data: BulkImportRow[]): Observable<BulkImportResponse> {
        return this.http.post(`/shipments-bulk` , JSON.stringify(data));
    }
}