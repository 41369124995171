/**
 * Simple cache class with optional TTL
 */
export class TimedCache<T> {
    private cache: {[key: string]: {timestamp: Date, value: T}};
    private ttlMilliseconds: number;
  
    constructor(ttlMilliseconds?: number) {
      this.ttlMilliseconds = ttlMilliseconds;
      this.cache = {};
    }
  
    public set(key: string, value: T): void {
      // console.log('TimedCache set, key', key, 'value', value);
      this.cache[key] = {
        timestamp: new Date(),
        value: value
      };
    }
  
    public get(key: string): T {
      if (key in this.cache) {
        if (this.ttlMilliseconds) {
          const now = new Date();
          if (now.valueOf() - this.cache[key].timestamp.valueOf() <= this.ttlMilliseconds) {
            // console.log('TimedCache hit, key', key, 'value', this.cache[key].value);
            return this.cache[key].value;
          } else {
            delete this.cache[key];
            // console.log('TimedCache timeout miss, key', key);
            return undefined;
          }
        } else {
          // console.log('TimedCache hit, key', key, 'value', this.cache[key].value);
          return this.cache[key].value;
        }
      } else {
        // console.log('TimedCache miss, key', key);
        return undefined;
      }
    }
  
    public clear(key: string): void {
        // console.log('TimedCache clear, key', key);
        delete this.cache[key];
    }
  }
  