import { Component, ChangeDetectorRef} from '@angular/core';
import { SharedService } from '../../../../services/shared.service';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    selector: 'app-grid-selected-renderer',
    template: `<div class="clickable fnt-green" style="font-size: 140%;" (click)="toggle()">
    			 <i *ngIf="getEditedCount() == 0" class="fa" [ngClass]="{'fa-square-o': (!params.selection.ids || !params.selection.ids[params.data.id]) && !params.selection.all, 'fa-check-square-o': (params.selection.ids && params.selection.ids[params.data.id]) || params.selection.all}"></i>
    		   </div>`
})
export class GridSelectedRendererComponent implements AgRendererComponent {
    params: any;

    constructor(private sharedService: SharedService,
                private cdRef: ChangeDetectorRef) {

    }

    agInit(params: any): void {
        this.params = params;
    }

    toggle() {
        const wasAllOrVisible = this.params.selection.all || this.params.selection.visible;
        if (!this.params.selection.newSelectedIds) {
            this.params.selection.ids[this.params.data.id] = this.params.selection.all ? false : !this.params.selection.ids[this.params.data.id];
            if (this.params.selection.ids[this.params.data.id]) {
                this.params.selection.ids[this.params.data.id] = this.params.data;
            } else {
                delete this.params.selection.ids[this.params.data.id];
            }
            // if all is selected, remove all selected ids and add all of current page but not curently deselected one
            if (this.params.selection.all) {
                this.params.selection.ids = {};
                const model = this.params.api.getModel();
                for (let i = 0; i < model.getRowCount(); ++i) {
                    const row = model.getRow(i);
                    if (this.params.data.id !== row.data.id) {
                        this.params.selection.ids[row.data.id] = row.data;
                    }
                }
            }
        } else {
            const newValue = this.params.selection.all ? false : !this.params.selection.ids[this.params.data.id]
            if (newValue) {
                this.params.selection.ids[this.params.data.id] = this.params.data;
                if (!this.params.selection.deselectedIds[this.params.data.id]) {
                    this.params.selection.newSelectedIds[this.params.data.id] = this.params.data;
                }
                delete this.params.selection.deselectedIds[this.params.data.id];
            } else {
                if (this.params.selection.all) {
                    this.params.selection.all = false;
                    this.params.selection.visible = false;
                    this.params.observableAll.next();
                    const model = this.params.api.getModel();
                    const modelRowsIds = {};
                    for (let i = 0; i < model.getRowCount(); ++i) {
                        const row = model.getRow(i);
                        if (this.params.data.id !== row.data.id) {
                            modelRowsIds[row.data.id] = row;
                        }
                        if (!this.params.selection.ids[row.data.id] && !this.params.selection.deselectedIds[row.data.id]) {
                            this.params.selection.newSelectedIds[row.data.id] = row.data;
                        }
                        delete this.params.selection.deselectedIds[row.data.id];
                        this.params.selection.ids[row.data.id] = row.data;
                        this.params.observable.next({data: row.data, selected: true});
                    }
                    Object.keys(this.params.selection.ids).map(key => {
                        if (!modelRowsIds[key]) {
                            if (!this.params.selection.newSelectedIds[key]) {
                                this.params.selection.deselectedIds[key] = this.params.data;
                            }
                            delete this.params.selection.newSelectedIds[key];
                            delete this.params.selection.ids[key];
                        }
                    });
                } else {
                    if (!this.params.selection.newSelectedIds[this.params.data.id]) {
                        this.params.selection.deselectedIds[this.params.data.id] = this.params.data;
                    }
                    delete this.params.selection.newSelectedIds[this.params.data.id];
                    delete this.params.selection.ids[this.params.data.id];
                }
            }
        }
        this.params.selection.all = false;
        this.params.selection.visible = false;
        if (wasAllOrVisible) {
            this.params.api.redrawRows();
        } else {
            this.params.api.redrawRows({rowNodes: [this.params.node]});
        }
        localStorage.setItem('user', JSON.stringify(this.sharedService.user));


        if (this.params.observable) {
            this.params.observable.next({data: this.params.data, selected: !!this.params.selection.ids[this.params.data.id]});
        }
    }
    getEditedCount() {
        return this.params.editedIdsObj ? Object.keys(this.params.editedIdsObj).length : 0;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
