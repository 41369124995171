import { PackingOption } from '@model/basket.model';

export enum PrfqActions {
    CREATE_PRFQ = 'CREATE_PRFQ',
    DELETE_PRFQ = 'DELETE_PRFQ',
    SUBMIT_PRFQ = 'SUBMIT_PRFQ',
    OPEN_PRFQ = 'OPEN_PRFQ',
    CLOSE_PRFQ = 'CLOSE_PRFQ',
    REOPEN_PRFQ = 'REOPEN_PRFQ',

    INSERT_ITEM = 'INSERT_ITEM',
    DELETE_ITEM = 'DELETE_ITEM'
}

export enum PrfqStates {
    IN_PROGRESS = 'P',  // Rozpracovaný – P – in progress
    NEW = 'N',   // Nový – N – new,
    OPENED = 'O',  // - Otvorený – O – open,
    CLOSED_ACCEPTED = 'A',  // - Ukončený akceptovaním
    CLOSED_NOT_ACCEPTED = 'X'  // Ukončený bez akceptovania
}

export interface Prfq {
    id: number;
    prfqNumber: string;
    prfqState: PrfqStates;
    prfqNote: string;
    postsCount: number;
    attachmentsCount: number;
    supplierId: number;
    supplierName: string;
    dateCreated: string; // Date;
    // userCreatedFullName: string;
    // userCreatedSortName: string;
    personCreatedFullName: string;
    personCreatedSortName: string;
    personCreated: number; // 511
    dateClosed: string; // Date;
    attachments: any[];
    actions: PrfqActions[];
    // Added locally
    url: String;
}

export interface PrfqsResponse {
    rows: Prfq[];
    personsCreated: { id: string, name: string }[]; // [{id: 2, name: "Húšťava Matej"}, {id: 3, name: "Mravec Karol"}]
    totalCount: number; // 7
}

// export class RfqToInsert {
//     constructor (
//         public amountOffered = 0,
//         public amountRequired = 0,
//         public brandCode = 'ZVL',
//         // public currencyPriceOffered: string = 'EUR',
//         public dateOfferedDays: number = null,
//         public dateRequired: Date = null,
//         public dateOffered: Date = null,
//         public dateOfferedValidity: Date = null,
//         public itemValidity: Date = null,
//         public itemType = 'rfq',
//         public rfqProduct = '',
//         public rfqProductAS = '',
//         public unitPriceRequired: number = null,
//         public unitPriceOffered: number = null,
//     ) { }
// }
// export class RfqToUpdate {
//     constructor () {}
//     }

export enum PrfqItemActions {
    // INSERT_ITEM = 'INSERT_ITEM',
    DELETE_ITEM = 'DELETE_ITEM',
    // UPDATE_ITEM = 'UPDATE_ITEM',
    CONFIRM_ITEM = 'CONFIRM_ITEM',
    START_ITEM_WAITING = 'START_ITEM_WAITING',
    FINISH_ITEM_WAITING = 'FINISH_ITEM_WAITING',
    ACCEPT_ITEM = 'ACCEPT_ITEM',
    NOT_ACCEPT_ITEM = 'NOT_ACCEPT_ITEM',
    CANCEL_ACCEPTED_ITEM = 'CANCEL_ACCEPTED_ITEM',
    REOPEN_ITEM = 'REOPEN_ITEM'
}

export enum PrfqItemStates {
    IN_PROGRESS = 'P',  // Rozpracovaný – P – in progress
    NEW = 'N',   // Nový – N – new,
    OPENED = 'O',  // - Otvorený – O – open,
    NOT_CONFIRMED = 'F',  // -	Nepotvrdený – F – Not confirmed,
    CONFIRMED = 'C',  // -	Potvrdený – C – confirmed,
    WAITING = 'W',  // Čaká na výkres – W – waiting
    ACCEPTED = 'A',  // Akceptovaný – A – accepted,
    NOT_ACCEPTED = 'X'  // Neakceptovaný - X – not accepted,
}

export interface PrfqItem {
    id: number; // 3102
    prfqId: number; // 220
    prfqNumber: string; // "PRFQ1900012"
    supplierId: number; // 7
    supplierName: string; // "Dodo s.r.o."
    manufacturer: string; // 'Song-Hong'
    lineNumber: number; // 1
    productSupplier: string; // "1201"
    productShortName: string; // 'A1221012323'
    eCommProductId: string; // "1201"
    brandCode: string; // "ZVL"
    amountRequired: number; // 1
    amountOffered: number; // 1
    amountInPacking: number;
    packing: string; // "A"
    defaultPacking: string; // 'A'
    packingOptions: PackingOption[]; // [{ packing: "A", amount: 1, priceCoefficient: 1 }]
    amountPackages: number; // 1
    unitPriceRequired: number; // null
    unitPriceOffered: number; // null
    currencyPriceRequired: string; // "EUR"
    currencyPriceOffered: string; // null
    dateRequired: Date; // null
    dateOfferedDays: number; // null
    itemValidity: string; // "2020-12-11T00:00:00.000Z"
    itemValidityDays: number; // 10
    itemNote: string; // null
    postsCount: number; // 0
    attachmentsCount: number; // 0
    itemState: PrfqItemStates; // "F"
    dateCreated: Date; // "2019-11-04T13:10:58.000Z"
    dateChanged: Date; // null
    dateChangedAS: Date; // "2019-11-04T13:12:17.000Z"
    actions: PrfqItemActions[];
    // Added locally
    amountPackagesNotRounded?: number;
    originalValues?: { colId: string, value: any }[]; // [{ colId: 'amountOffered', value: row.amountOffered }]
}

export class PrfqItemForApi {
    public id?: number = null;
    public manufacturer?: string = null;
    // lineNumber: number; // 1
    public productSupplier?: string = null;
    public productShortName?: string = null;
    public brandCode?: string = null;
    public amountRequired?: number = null;
    public amountOffered?: number = null;
    // packing?: string; // "A"
    public unitPriceRequired?: number = null;
    public unitPriceOffered?: number = null;
    public currencyPriceRequired?: string = null;
    public currencyPriceOffered?: string = null;
    public dateRequired?: Date = null;
    public dateOfferedDays?: number = null;
    public itemValidityDays?: number = null;
    public itemNote?: string = null;
}

export interface PrfqItemsResponse {
    rows: PrfqItem[];
    personsCreated: { id: string, name: string }[]; // [{id: 2, name: "Húšťava Matej"}, {id: 3, name: "Mravec Karol"}]
    totalCount: number; // 7
    // total?: number;
}

export interface UpdatePrfqItemsResponse {
    successCount: number,
    errors?: {
        row: Partial<PrfqItem>, // original object
        error: {[attrName: string]: string} // error message for the attr
    }[]
}

export interface CreatePrfqItemResponse {
    toInsert: UpdatePrfqItemsResponse
}

export interface PrfqJournalItem {
    childId: number,
    dateCreated: Date, // "2020-08-25T12:02:29.000Z"
    discussionText: string,
    entityId: number,
    entityName: number,
    eventType: string, // "J_UPDATE_ITEM"
    id: number, // 4
    lineNumber: number, //1
    newValue: {[attrName: string]: any}, // {productSupplier: "ozndod22"}
    oldValue: {[attrName: string]: any}, // {productSupplier: "ozndod"}
    prfqId: number, // 3
    userCreatedFullName: string, // "1 Dodo"
    userCreatedId: number, // 513
    userCreatedSortName: string // "Dodo 1"
}

export interface PrfqJournalResponse {
    rows: PrfqJournalItem[],
    personsCreated: {id: number, name: string}[],
    totalCount: number
}
