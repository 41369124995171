import { Component, ViewChild, HostListener, ViewContainerRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { NewsService } from '../../services/news/news.service';
import { NewsGridService } from '../../services/news/news.grid.service';
import { SharedService } from '../../services/shared.service';
import { Subject } from 'rxjs';
import { ExportObj } from '@app/model/table.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'news',
  templateUrl: 'news.component.html'
})
export class NewsComponent implements OnInit  {

    totalRows:          number;
    filterItems: 		any[];
    personCreated:      {id: number, name: string}[] = [];
    authorizedSellers:  {id: string, name: string}[] = [];
    customers: 			{id: string, name: string}[] = [];
    // subjectRefresh = new Subject<any>();
    subjectRefresh: Subject<any>;
    public exportObj: ExportObj;

    busy:				boolean;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private newsService: NewsService,
        private newsGridService: NewsGridService,
        public sharedService: SharedService,
    ) {
        // this.subjectRefresh = new Subject<any>();
        this.subjectRefresh = this.newsService.getReloadCurrentSubject();
        this.getNews = this.getNews.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('NEWS'),
                export: this.newsService.exportAll.bind(this.newsService)
            };
        });
        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });
    }
    ngOnInit() {
        const this_ = this;
        setTimeout(function() {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastNewsQueryParams);
        }, 0);
    }
    getNews(params) {
        const this_ = this;
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastNewsQueryParams = {};
        for (const key in this.route.snapshot.queryParams) {
                if (this.route.snapshot.queryParams.hasOwnProperty(key)) {this.sharedService.lastNewsQueryParams[key] = this.route.snapshot.queryParams[key];
               if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]}
            }
           }
           if (query.skip >= 0 && query.top > 0) {
            this.newsService.getNews(query)
            .subscribe(data => {
                // data = data.mock;
                // console.log(data);
                this.totalRows = data.totalCount;
                this.personCreated = data.personsCreated;
                // this.authorizedSellers = data.authorizedSellers;
                // this.customers = data.customers;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                params.successCallback(data.rows, this.totalRows);
                this.busy = false;
            }, err => {
                console.log(err);
                this.busy = false;
            });
        }
    }
    getGridOptions() {
        return this.newsGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    getFilterItems() {
        return this.newsGridService.getFilterItems(this.personCreated);
    }
    getColumns() {
        return this.newsGridService.getColumns();
    }
    getColumnsDefs() {
        return this.newsGridService.getColumnDefs();
    }
    printExportOrders() {
        console.log('printExportOrders');
    }
}
