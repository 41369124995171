import { Component, ViewChild, HostListener, ViewContainerRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { Subject } from 'rxjs';
import { OrdersItemsService } from '../../../../services/order/orders.items.service';
import { OrdersItemsGridService } from '../../../../services/order/orders.items.grid.service';
import { SharedService } from '../../../../services/shared.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { LanguageService } from '../../../../services/language.service';
import { FormService } from '../../../../services/form.service';
import * as moment from 'moment';

@Component({
    selector: 'orders-items',
    templateUrl: 'orders.items.component.html'
})
export class OrdersItemsComponent implements OnInit {
    totalRows: number;
    filterItems: any;
    totalWeightAll = 0;
    // totalValueAll = 0;
    totalWeightVisible = 0;
    totalValueVisible = 0;
    totalWeightSelected = 0;
    totalValueSelected = 0;
    subjectRefresh = new Subject<any>();
    subjectAllSelected: Subject<any>;
    exportObj: { fileName: string, export: Function };

    busy: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private ordersItemsService: OrdersItemsService,
        private ordersItemsGridService: OrdersItemsGridService,
        public sharedService: SharedService,
        private languageService: LanguageService,
        private formService: FormService
    ) {
        const this_ = this;
        this.subjectRefresh = new Subject<any>();
        this.subjectAllSelected = this.ordersItemsGridService.getItemsSelectedSubject();

        this.filterItems = this.getFilterItems();

        this.getOrdersItems = this.getOrdersItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('ORDERS_ITEMS'),
                export: this.ordersItemsService.exportAll.bind(this.ordersItemsService)
            };
        });
    }

    ngOnInit() {
        const this_ = this;
        setTimeout(function () {
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastOrdersItemsQueryParams);
        }, 0);
    }

    getOrdersItems(params) {
        const this_ = this;
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        for (const key in this.route.snapshot.queryParams) {
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.ordersItemsService.getOrdersItems(query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                this.totalWeightAll = Math.round(data.totalWeight * 10000) / 10000;
                // this.totalValueAll = Math.round(data.totalValue * 10000) / 10000;
                this.totalWeightVisible = Math.round(data.totalWeightCurrent * 10000) / 10000;
                this.totalValueVisible = Math.round(data.totalValueCurrent * 10000) / 10000;
                if (this_.sharedService.user.preferences.productsOfCurrentOrderSelection && this_.sharedService.user.preferences.productsOfCurrentOrderSelection.visible) {
                    this_.totalWeightSelected = this_.totalWeightVisible;
                    this_.totalValueSelected = this_.totalValueVisible;
                    this_.sharedService.user.preferences.productsOfCurrentOrderSelection.totalWeight = this_.totalWeightSelected;
                    this_.sharedService.user.preferences.productsOfCurrentOrderSelection.totalValue = this_.totalValueSelected;
                }
                params.successCallback(data.rows, data.totalCount);
                this.busy = false;
            }, err => {
                console.log(err);
                this.busy = false;
            });
    }
    getGridOptions() {
        return this.ordersItemsGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }

    getFilterItems() {
        return this.ordersItemsGridService.getFilterItems();
    }

    getColumns() {
        return this.ordersItemsGridService.getColumns();
    }

    getColumnsDefs() {
        return this.ordersItemsGridService.getColumnDefs();
    }

    printExportItems() {
        console.log('printExportItems');
    }
}
