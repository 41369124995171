<div class="row-container" *ngIf="params">
    <!-- NOTE: this date picker is added for date picker styles inicialize. without this, datepicker styles/directives are not created -->
    <div style="visibility:hidden;width:0;height:0;overflow:hidden">
        <my-date-picker></my-date-picker>
    </div>
    <!-- NOTE: end of datepicker inicialization -->


    <div *ngIf="params.successCount > 0">
        <h4 class="fnt-green"><span translate>IMPORT_SUCCESS</span>: {{params.successCount}}</h4>
    </div>

    <div style="margin-top: 10px" *ngIf="params.toInsert.errors.length > 0">
        <div fxLayout="row">
            <h4 class="fnt-orange"><span translate>IMPORT_ERROR</span>: {{params.toInsert.errors.length}}</h4>

            <button class="btn-secondary" style="min-width: 200px; margin-left: 10px;"
                (click)="copyErrorsToClipboard()">
                <i class="fnt-primary fa fa-clipboard"></i>
                <span translate>COPY_ERRORS_TO_CLIPBOARD</span>
            </button>
        </div>

        <div style="display: block; overflow-y: auto; min-height: 240px;"
            [ngStyle]="{'max-height.px': maxHeight - (this.params.successCount > 0 ? 30 : 0)}">

            <div fxLayout="row">
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 10px;" translate>PRODUCT_ID</div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 10px;" translate>REQUIRED_AMOUNT</div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 10px;" translate>REQUIRED_PRICE</div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 10px;" translate>REQUIRED_DATE</div>
            </div>

            <div fxLayout="row" *ngFor="let item of params.toInsert.errors; let odd=odd; let even=even; let index=index"
                [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
                    [ngClass]="{'fnt-orange': item.error.rfqProduct}">
                    <input type="text" style="margin: 4px 0;" class="form-control"
                        [ngClass]="{'fnt-orange': item.error.rfqProduct}" [(ngModel)]="item.row.rfqProduct"
                        (ngModelChange)="onChange(item, 'rfqProduct', item.row.rfqProduct)">
                    <div style="margin-top: -5px;">
                        <span style="font-size: 75%;" translate>{{item.error.rfqProduct}}</span>
                    </div>
                </div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;">
                    <number-input name="number-input" #boxmountRequired [ngModel]="item.row.amountRequired"
                        (ngModelChange)="item.row.amountRequired=$event;onChange(item, 'amountRequired', item.row.amountRequired)"
                        [inputType]="'number'" [ngClass]="{'fnt-orange': item.error.amountRequired}"
                        class="number-input-as-basket-error" [placeholder]="item.placeholderAmount"></number-input>

                    <!-- <input type="text" style="margin: 4px 0;" class="form-control" [ngClass]="{'fnt-orange': item.error.amountRequired}" [(ngModel)]="item.row.amountRequired" (ngModelChange)="onChange(item, 'amountRequired')"> -->
                    <div style="margin-bottom: 2px">
                        <span
                            *ngIf="(item.row.amountRequired && item.row.amountRequired.length == 0) || item.error.amountRequired"
                            class="fnt-orange" style="font-size: 75%;" translate>INSERT_TO_BASKET_AMOUNT_MISSING</span>
                        <!-- <span *ngIf="(item.row.amountRequired && item.row.amountRequired.length == 0) || item.error.amountRequired" class="fnt-orange" style="font-size: 75%;" translate>{{item.error.amountRequired}}</span> -->
                    </div>
                </div>
                <div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
                    [ngClass]="{'fnt-orange': item.error.unitPriceRequired}">
                    <number-input name="number-input" #boxUnitPriceRequired [ngModel]="item.row.unitPriceRequired"
                        (ngModelChange)="item.row.unitPriceRequired=$event;onChange(item, 'unitPriceRequired', item.row.unitPriceRequired)"
                        [inputType]="'decimal'" [ngClass]="{'fnt-orange': item.error.unitPriceRequired}"
                        class="number-input-as-basket-error" [placeholder]="item.placeholderUnit"></number-input>
                    <!-- <input type="text" style="margin: 4px 0;" class="form-control" [ngClass]="{'fnt-orange': item.error.unitPriceRequired}" [(ngModel)]="item.row.unitPriceRequired" (ngModelChange)="onChange(item, 'unitPriceRequired')"> -->
                    <div
                        style="margin-top: -5px; height: 34px; line-height: 34px; font-size: 98%; padding-right: 30px;">
                        <span style="font-size: 75%;" translate>{{item.error.unitPriceRequired}}</span>
                    </div>
                </div>
                <div fxFlex="50" style="display: inline-block;">
                    <div *ngIf="!item.showDatePickerComponent" class="mydp" style="width: 150px;"
                        (click)="disableOtherDatepickers(item, index)">
                        <div class="selectiongroup">
                            <input [(ngModel)]="item.dateModel" class="selection" type="text"
                                style="height: 34px; line-height: 34px; font-size: 98%; padding-right: 30px;"
                                aria-label="Date input field" maxlength="10" [placeholder]="item.placeholderDate">

                            <div class="selbtngroup" style="height: 34px;">
                                <button class="btnpicker btnpickerenabled btnleftborder" type="button"
                                    aria-label="Open Calendar">
                                    <span class="mydpicon icon-mydpcalendar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="item.showDatePickerComponent">
                        <my-date-picker [attr.id]="'my-dp-id-'+index" [(ngModel)]="item.row.dateRequiredModel"
                            [options]="item.row.datePickerOptions" [locale]="this.sharedService.appSettings.language"
                            [selector]="selector" (dateChanged)="onDateChanged($event, item)"
                            [placeholder]="item.placeholderDate"></my-date-picker>
                    </div>
                    <div style="margin-top: -5px;">
                        <span style="font-size: 75%;" [ngClass]="{'fnt-orange': item.error.dateRequired}"
                            translate>{{item.error.dateRequired}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-top: 15px;">
        <button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
            <span translate>CLOSE</span>
        </button>
        <button class="btn-primary" style="float: right" (click)="addToBasketAgain()">
            <i class="fa fa-plus"></i>
            <span translate>IMPORT_AGAIN</span>
        </button>
    </div>