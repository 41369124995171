import { StateColorsObject } from './table.model';

export enum TableFilterItemTypes {
    'text' = 'text',
    'date' = 'date',
    'dateRange' = 'dateRange',
    'select' = 'select',
    'multiselect' = 'multiselect',
    'checkbox' = 'checkbox'
}

export interface TableFilterItemValue {
    id: string; // 'X'
    name: string; // 'DELIVERY_ORDER_STATE_X'
    dotId?: string; // 'X'
    default?: boolean; // true; default value to be in used filter - used when default filter is applied in multiselect
    checked?: boolean;
    [key: string]: any; // e.g. for custom filtering when a filter depends on other filter
}

// export interface TableFilterItemValueCustomDotColors {
//     [dotId: string]: string; // { gray: 'X', red: 'N', blue: 'S', yellow:'C', green: 'P', black: 'D'}
// }

export enum TableFilterItemOperators {
    'likeBoth' = 'likeBoth',
    'eq' = 'eq',
    'ne' = 'ne'
}

// This is only for internal usage in TableFilerComponent
export interface TableFilterItemDateOperator { 
    id: string, 
    name: string, 
    short: string 
}

export interface TableFilterItem {
        id: string; // 'dateCreated'
        name: string; // 'DATE' - for translation
        type: TableFilterItemTypes; // 'dateRange'

        // For type 'dateRange'
        valueFrom?: any; // ''
        valueTo?: any; // ''
        showYearPicker?: boolean;

        // For types 'select', 'multiselect'
        value?: any; // ''
        values?: TableFilterItemValue[];
        search?: boolean; // true
        allLabel?: string; // 'CATALOGUE_ALL'
        selectedLabel?: string; // 'SELECTED_STATES'
        customDotColors?: StateColorsObject;

        // For type 'text'
        maxLength?: number; // 100
        // Only TableFilterItemOperators should be used in grid services
        // TableFilterItemDateOperator is only for internal usage in TableFilerComponent
        operator?: TableFilterItemOperators | TableFilterItemDateOperator; // 'likeBoth'

        // For type 'checkbox'
        title?: string;
        checked?: boolean;

        bulkCheckbox?: {
            title: string
            ids: string[],
            checked?: boolean,
            active?: boolean
        };

         // Callback to optionally update value of the filter depending on external events.
         // Callback is invoked each time some filter has been changed (before the user submits it).
         // Can be used to implement dependent filters.
        updateValuesOnFilterMicroChange?: 
            /**
             * @param changedItem filter item which has been changed 
             *                    or null if the use clicked reset filter - in this case original value should be returned
             * @returns new filter value (if it should be changed) or null (if the current value should be kept)
             */
            (changedItem: TableFilterItem | null) => TableFilterItemValue[] | null;
}
