<div class="row-container wider">
	<div class="row-container">
    	<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/shipments" [queryParams]="sharedService.lastShipmentsQueryParams">
			<i class="fa fa-arrow-circle-left"></i>
			<a translate>BACK</a>
		</button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end">
    	<div fxFlex="50" class="area-title" *ngIf="shipment && route.snapshot.params['id'] !== 'new'">
	        <span>
                <h3 ><img src="../../assets/images/shipment_black.png" style="margin-right: 5px;"/>
					<span translate>SHIPMENT</span> {{shipment.shipmentNumberEComm}}
				</h3>
				<ng-template #zeroItemsTooltip><span translate>SOME_SHIPMENT_AMOUNT_IS_ZERO</span></ng-template>
				<span style="margin: 10px 0 0 10px;" class="fnt-red clickable" 
					*ngIf="totalShipAmountZeroCount > 0"
					[popover]="zeroItemsTooltip" triggers="mouseenter:mouseleave"
					(click)="filterShipAmountZero()">
						<span translate>SHIPMENT_CONTAINS_INVALID_ITEMS</span>
						<sup>{{totalShipAmountZeroCount}}</sup>
				</span>
	        </span>
		</div>
		
    	<div fxFlex="33" class="area-title" *ngIf="shipment && route.snapshot.params['id'] === 'new'">
	        <h3><img src="../../assets/images/shipment_black.png" style="margin-right: 5px;"/><span translate>SHIPMENT_NEW</span></h3>
	    </div>
	    
	    <div fxFlex="33" class="row-container">
	        <div class="input-container" style="width: 100%;" *ngIf="route.snapshot.params['id'] === 'new'">
	        	<div fxLayout="row">
	            	<label translate>SEARCH_FILE_FOR_IMPORT</label>
	            	<ng-template #popFileImportTemplate><div translate>IMPORT_CSV_HELP</div><div translate>SHIPMENT_IMPORT_HELP_1</div><div translate>IMPORT_HELP_2</div></ng-template>
	            	<i class="fa fa-info-circle clickable" [popover]="popFileImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
	            </div>
	            <input #fileInput type="file" (change)="fileChangeEvent($event)" placeholder="{{ 'INSERT_CSV' | translate }" style="max-width: 100%;"/>

	            <div class="input-container" *ngIf="brands.length > 1">
	                <label translate>BRAND</label>
	                <dropdown-component attributeName="selectedBrandFromFile" [items]="brands" [selected]="selectedBrandFromFile" (onSelect)="onSelectBrandFromFile($event)"></dropdown-component>
	            </div>  

	            <div>
			    	<button class="btn-primary" (click)="fileCsvCreateShipment()" style="margin-top: 5px;" [disabled]="addFromFileBusy || fromFileObjects.length == 0" [ngClass]="{'ec-disabled': addFromFileBusy || fromFileObjects.length == 0}">
						<i class="fa fa-plus"></i>
						<a translate>EXPEDITION_CREATE</a>
					</button>
				</div>
	        </div>  
	    </div>

		<div fxFlex="33" class="row-container">
	        <div class="input-container" *ngIf="route.snapshot.params['id'] === 'new'">
	        	<div fxLayout="row">
	            	<label translate>INSERT_DATA_FOR_SHIPMENT</label>
	            	<ng-template #popClipboardImportTemplate><div translate>IMPORT_CLIPBOARD_HELP</div><div translate>SHIPMENT_IMPORT_HELP_1</div><div translate>IMPORT_HELP_2</div></ng-template>
	            	<i class="fa fa-info-circle clickable" [popover]="popClipboardImportTemplate" container="body" triggers="mouseenter:mouseleave"></i>
	            </div>
	            <textarea #pasteTextarea class="form-control" [(ngModel)]="pastedExcel" (ngModelChange)="pasteExcelChanged(pastedExcel)" placeholder="{{ 'INSERT_CTRL_V' | translate }}" (keydown)="pasteExcelKeyDown($event, pasteTextarea)"></textarea>

	            <div class="input-container" *ngIf="brands.length > 1">
	                <label translate>BRAND</label>
	                <dropdown-component attributeName="selectedBrandPasted" [items]="brands" [selected]="selectedBrandPasted" (onSelect)="onSelectBrandPasted($event)"></dropdown-component>
	            </div>  

	            <div>
			    	<button class="btn-primary" (click)="ctrlVCreateShipment()" style="margin-top: 5px;" [disabled]="addFromPasteBusy || pastedExcel.length == 0" [ngClass]="{'ec-disabled': addFromPasteBusy || pastedExcel.length == 0}">
						<i class="fa fa-plus"></i>
						<a translate>EXPEDITION_CREATE</a>
					</button>
				</div>
	        </div>  
	    </div>
	</div>

<!-- 	<div fxLayout="row" fxLayoutAlign="end">
	    <button class="btn-secondary" (click)="printExportItems()">
	        <i class="fa fa-print"></i>
	        <span translate>PRINT_EXPORT</span>
	    </button>
	</div> -->

	<div fxLayout="column" style="margin-bottom: 20px;" *ngIf="shipment">
    	<ecm-table [dataGetter]="getShipmentItems" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [filterSelectedAttrId]="'toShip'"
                     [suppressStoreFilters]="true"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [selection]="sharedService.user.preferences.shipmentSelection"
                     [total]="totalRows"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [observableReloadCurrent]="subjectReloadCurrent.asObservable()"
                     [subjectAllSelectedChange]="subjectAllSelected"
                     [busy]="busy"
                     [secondaryHeaderColor]="true"
                     [exportObj]="exportObj"></ecm-table>
    </div>

	<div fxLayout="column" fxLayout.gt-sm="row" class="row-container bigger" *ngIf="shipment"> 
    	<div fxFlex="50">
			<div class="params-table" fxFlex="100" fxLayout="column" style="margin-right: 40px;">
				<div class="item" fxLayout="row">
					<div fxFlex="40" class="key">
						<span translate>REQUESTED_EXPEDITION_DATE</span>
					</div>
					<div fxFlex="60" class="value" fxLayoutAlign="center center">
						<div style="margin-right: 10px; padding-top: 5px;">
							<my-date-picker 
			                    [(ngModel)]="shipmentDate"
			                    [options]="myDatePickerOptions"
			                    [locale]="this.sharedService.appSettings.language"
			                    [selector]="selector"
			                    (dateChanged)="onDateChanged($event)"></my-date-picker>
			            </div>
		                <timepicker [(ngModel)]="pickUpTime" (ngModelChange)="timeChanged()" [showMeridian]="false" style="display: inline-block;"></timepicker>
					</div>
				</div>

<!-- 				<div class="item" fxLayout="row">
					<div fxFlex="40" class="key">
						<span translate>REQUESTED_PICK_UP_TIME</span>
					</div>
					<div fxFlex="60" class="value">
						
					</div>
				</div> -->

				<div class="item last" fxLayout="row">
					<div fxFlex="40" class="key">
						<span translate>EXPEDITION_NOTE</span>
					</div>
					<div fxFlex="60" class="value">
						<input type="text" name="shipmentNote" [(ngModel)]="shipmentNote" (ngModelChange)="noteChanged()" class="form-control">
					</div>
				</div>
			</div>
		</div>

		<div fxFlex="50">
			<div>
				<div class="params-table" fxFlex="100" fxLayout="column">
					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>SELECTED_ITEMS_COUNT</span>
						</div>
						<div fxFlex="60" class="value">
							{{sharedService.user.preferences.shipmentSelection.all ? totalRows : getSelectedCount()}}
						</div>
					</div>
					
					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>SELECTED_ITEMS_AMOUNT</span>
						</div>
						<div fxFlex="60" class="value">
							{{(sharedService.user.preferences.shipmentSelection.all && getSelectedCount() != 0 ? totalAmountAll : totalAmountSelected) | localnumber:sharedService.appSettings.language:false}} <span translate>UNIT</span>
						</div>
					</div>

					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>SELECTED_ITEMS_WEIGHT</span>
						</div>
						<div fxFlex="60" class="value">
							{{(sharedService.user.preferences.shipmentSelection.all && getSelectedCount() != 0 ? totalWeightAll : totalWeightSelected) | localnumber:sharedService.appSettings.language:true:3}} kg
						</div>
					</div>

					<div class="item" fxLayout="row">
						<div fxFlex="40" class="key">
							<span translate>SELECTED_ITEMS_VALUE</span>
						</div>
						<div fxFlex="60" class="value">
							<ul *ngIf="sharedService.user.preferences.shipmentSelection.all && getSelectedCount() != 0" class="list-unstyled">
								<li *ngFor="let currency of objectKeys(totalValueAll)">{{totalValueAll[currency] | localcurrency:sharedService.appSettings.language:currency}}</li>
							</ul>

							<ul *ngIf="!sharedService.user.preferences.shipmentSelection.all || getSelectedCount() == 0" class="list-unstyled">
								<li *ngFor="let currency of objectKeys(totalValueSelected)" 
									[hidden]="totalValueSelected[currency] == 0">{{totalValueSelected[currency] | localcurrency:sharedService.appSettings.language:currency}}
								</li>
								<li *ngIf="objectKeys(totalValueSelected) == 0">0</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div fxLayout="column" fxLayout.gt-sm="row" class="row-container bigger" *ngIf="shipment"> 
		<div fxFlex="50" fxLayout="row">
			<button
				class="btn-primary" (click)="cancelShipment()" style="margin-right: 10px;" *ngIf="buttonsVisibility.cancelShipment">
				<i class="fa fa-times-circle"></i>
				<a translate>EXPEDITION_CANCEL</a>
			</button>
		</div>
		<div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
			<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/shipments" [queryParams]="sharedService.lastShipmentsQueryParams" style="margin-right: 10px;" *ngIf="buttonsVisibility.cancelChanges" [disabled]="!tableService.isSomethingChanged(sharedService.user.preferences.shipmentSelection) && !shipmentDetailsEdited" [ngClass]="{'ec-disabled': !tableService.isSomethingChanged(sharedService.user.preferences.shipmentSelection) && !shipmentDetailsEdited}">
				<i class="fa fa-times-circle"></i>
				<a translate>CANCEL_CHANGES</a>
			</button>
			<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/shipments" style="margin-right: 10px;" *ngIf="buttonsVisibility.cancelCreate">
				<i class="fa fa-times-circle"></i>
				<a translate>CANCEL</a>
			</button>
			<button class="btn-primary" (click)="save()" style="margin-right: 10px;" *ngIf="buttonsVisibility.saveChanges" [disabled]="!tableService.isSomethingChanged(sharedService.user.preferences.shipmentSelection) && !shipmentDetailsEdited" [ngClass]="{'ec-disabled': !tableService.isSomethingChanged(sharedService.user.preferences.shipmentSelection) && !shipmentDetailsEdited}">
				<i class="fa fa-floppy-o"></i>
				<a *ngIf="route.snapshot.params['id'] == 'new'" translate>EXPEDITION_SAVE</a>
				<a *ngIf="route.snapshot.params['id'] != 'new'" translate>SAVE_CHANGES</a>
			</button>
			<button class="btn-primary" (click)="prepareForShiping()" *ngIf="buttonsVisibility.prepareForShipment">
                <img src="../../assets/images/shipment_white.png" style="width: 22px; margin-right: 5px;"/>
				<a translate>PREPARE_FOR_EXPEDITION</a>
			</button>
		</div>
	</div>
</div>