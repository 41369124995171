import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'lodin-dialog',
    template: `
        <div>
            <login [params]="params" (notify)="loginSuccessful($event)" *ngIf="showContent"></login>
        </div>
    `,
})
export class LoginDialog {
    doneSubject: Subject<any>;
    showContent = false;
    params: any;
    message: boolean

    constructor(public bsModalRef: BsModalRef) {

    }

    ngOnInit() {
        var this_ = this;
        setTimeout(function() {
            this_.showContent = true;
        }, 0);
    }

    loginSuccessful(message:boolean):void {
        this.doneSubject.next(message);
        this.bsModalRef.hide();
    }
}