import { Injectable } from '@angular/core';
import { RepreOverrideAreas, Representative, User } from '@app/model/user.model';
import { SharedService } from '../shared.service';
import { RfqStates } from '@model/rfq.model';
import { UserService } from '../user/user.service';

const RFQ_STATE_P = 'P'; // Rozpracovaný – P – in progress,
const RFQ_STATE_N = 'N'; // Nový – N – new,
const RFQ_STATE_O = 'O'; // Otvorený – O – open,
const RFQ_STATE_S = 'S'; // Ukončený s objednaním – S – successful,
const RFQ_STATE_F = 'F'; // Ukončený bez objednania– F – failed

const RFQ_ITEM_STATE_P = 'P'; // rozpracovana
const RFQ_ITEM_STATE_N = 'N'; // nova
const RFQ_ITEM_STATE_O = 'O'; // otvorena
const RFQ_ITEM_STATE_F = 'F'; // nepotvrdena
const RFQ_ITEM_STATE_C = 'C'; // potvrdena
const RFQ_ITEM_STATE_R = 'R'; // objednana
const RFQ_ITEM_STATE_D = 'D'; // neobjednana

@Injectable()
export class RfqPermissionService {

    rfqState: string; // = RFQ_STATE_P;


    constructor(
        private sharedService: SharedService,
        private userService: UserService
    ) {
    }

    public setRfqState(state: string): void {
        this.rfqState = state;
    }

    /**
     * Filters Rfq items columns according to the Rfq state and user representative
     */
    // filterColumns(colDefs: any[]): any[] {
    //     if (colDefs.length === 0) {
    //         return colDefs;
    //     }

    //     const attr = ('id' in colDefs[0]) ? 'id' : 'field';
    //     const representative = this.sharedService.user.representative;
    //     const suppress = {
    //         amountOffered: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         amountInPacking: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         amountPackages: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         packing: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         unitPriceOffered: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         itemType: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         itemState: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         dateCreated: () => this.rfqState === RFQ_STATE_P && representative === 'customer',
    //         postsCount: () => this.rfqState === RFQ_STATE_P,
    //         dateOfferedDays: () => representative === 'customer',
    //         dateOffered: () => representative === 'customer',
    //         dateOfferedValidity: () => representative === 'customer',
    //         dateOfferedCalculated: () => this.rfqState === RFQ_STATE_P
    //     }

    //     colDefs = colDefs.filter(colDef => !((colDef[attr] in suppress) && suppress[colDef[attr]]()))
    //     return colDefs;
    // }

    public isColumnEditable(column: string): boolean {
        const user: User = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);
        
        const editable = {
            rfqNote: () => this.rfqState === RfqStates.IN_PROGRESS,
            priceList: () => (this.rfqState === RfqStates.IN_PROGRESS) || 
                (this.rfqState === RfqStates.OPENED && user.representative === Representative.AS),
            unitPriceRequired: () => (this.rfqState === RfqStates.IN_PROGRESS && 
                    user.representative === Representative.customer) || 
                (this.rfqState === RfqStates.OPENED && user.representative === Representative.AS),
            itemNote: () => this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer,
            dateRequired: () => this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer
            // itemType: () => this.rfqState !== RFQ_STATE_P && representative === 'AS',
            // itemValidity: () => this.rfqState === RFQ_STATE_P && representative === 'AS',
        };

        return (column in editable) ?
            (editable[column]() && (this.hasPermissionItemsUpdate()))
            : false;
    }

    public isCellEditable(rfqItem: any, column: string): boolean {
        const user: User = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);

        const aSCanEdit = () => ((user.representative === Representative.AS &&
                (rfqItem.itemState === RFQ_ITEM_STATE_O || rfqItem.itemState === RFQ_ITEM_STATE_F)
        ) || (!rfqItem.itemState && user.representative === Representative.AS)) && this.rfqState !== RFQ_STATE_S && this.rfqState !== RFQ_STATE_F;

        const customerCanEdit = () => (user.representative === Representative.customer && this.rfqState === RFQ_STATE_P
            ) || (!rfqItem.itemState && user.representative === Representative.AS);

        const editable = {
            rfqProduct: () => customerCanEdit(),
            rfqProductAS: () => aSCanEdit(),
            amountRequired: () => customerCanEdit(),
            // amountOffered: () => aSCanEdit(),
            amountPackages: () => aSCanEdit(),
            packing: () => aSCanEdit(),
            dateRequired: () => customerCanEdit(),
            unitPriceRequired: () => customerCanEdit(),
            unitPriceOffered: () => aSCanEdit(),
            dateOfferedDays: () => aSCanEdit() && !rfqItem.dateOffered,
            dateOffered: () => aSCanEdit() && !rfqItem.dateOfferedDays,
            dateOfferedValidity: () => aSCanEdit() && !rfqItem.dateOfferedDays,
            itemValidity: () => aSCanEdit(),
            itemType: () => aSCanEdit()
        };

        return (column in editable) ?
            (editable[column]() && (this.hasPermissionItemsUpdate()))
            : false;
    }

    private hasPermissionItemsUpdate(): boolean {
        return this.sharedService.hasPermission('rfq/*/items', 'PUT');
    }

    public hasPermissionHeaderAction(): boolean {
        return this.sharedService.hasPermission('rfq/*/action', 'PUT');
    }

    public hasPermissionItemsAction(): boolean {
        return this.sharedService.hasPermission('rfq/*/items-action', 'PUT');
    }

    private hasPermissionEditQuestionnaire(): boolean {
        return this.sharedService.hasPermission('rfq/*/questionnaire', 'PUT');
    }

    public hasPermissionJournal(): boolean {
        return this.sharedService.hasPermission('rfq/*/journal', 'GET');
    }

    private hasPermissionCreateRfq(): boolean {
        return this.sharedService.hasPermission('rfq', 'POST');
    }

    public isQuestionnaireEditable(): boolean {
        const user = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);

        return this.hasPermissionEditQuestionnaire() && 
            user.representative === Representative.customer;
    }

    /** Control availability for element - can see
     *
     * This function collects all decisions for elements/components for permissions.
     * DO NOT USE permissions inline - do not use 'representative' comparision in another files (like templates or components etc.)
     *
     * For declaretion of variable/property in other elements/components use 'is' on start and 'Available' at the name (like isAddToRfqAvailable).
     * Usage example: this.isAddToRfqAvailable = this.rfqPermissionService.isElementAvailable('addToRfq');
     *
     * @param element  Name of permission or element which should be compared
     * @return Return bool if user/element can see/do current operation (like ngIf etc.)
     */
    public isElementAvailable(element: 'addToRfq' | 'createRfq' | 'userRoleSelect' | 'journal' | 'rfqItemStateFilterStateInProgress' | 'totalPrice'): boolean {
        const user: User = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);

        const available = {
            addToRfq: () => user.representative === Representative.customer,
            createRfq: () => this.hasPermissionCreateRfq() &&
                (user.representative === Representative.customer),
            userRoleSelect: () => user.representative === Representative.AS &&
                this.hasPermissionHeaderAction() ||
                user.repreOverride && user.repreOverride.area === RepreOverrideAreas.rfq,
            journal: () => this.rfqState !== RfqStates.IN_PROGRESS && 
                user.representative === Representative.AS && this.hasPermissionJournal(),
            rfqItemStateFilterStateInProgress: () => user.representative === Representative.customer,
            totalPrice: () => this.rfqState !== RfqStates.IN_PROGRESS,
        };

        return (element in available) ? available[element]() : false;
    }

    public isRfqColumnAvailable(column: string): boolean {
        const user: User = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);

        const availabilityMap = {
            customerName: () => user.representative === Representative.AS &&
                user.availableCustomers.length > 1,
            assignedSellerName: () => user.representative === Representative.AS &&
                user.availableCustomers.length > 1,
            totalPrice: () => user.representative === Representative.AS,
        };

        return (column in availabilityMap) ? availabilityMap[column]() : true;
    }

    public isRfqItemColumnAvailable(column: string): boolean {
        const user: User = this.userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);

        const availabilityMap = {
            customerName: () => this.isRfqColumnAvailable('customerName'),
            assignedSellerName: () => this.isRfqColumnAvailable('assignedSellerName'),
            dateOfferedDays: () => user.representative === Representative.AS,
            dateOffered: () => user.representative === Representative.AS,
            dateOfferedValidity: () => user.representative === Representative.AS,
            dateConfirmed: () => user.representative === Representative.AS,
            amountOffered: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            amountInPacking: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            amountPackages: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            packing: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            unitPriceOffered: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            itemType: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            itemState: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            dateCreated: () => !(this.rfqState === RfqStates.IN_PROGRESS && 
                user.representative === Representative.customer),
            postsCount: () => !(this.rfqState === RfqStates.IN_PROGRESS),
            dateOfferedCalculated: () => !(this.rfqState === RfqStates.IN_PROGRESS)
        };

        return (column in availabilityMap) ? availabilityMap[column]() : true;
    }

}
