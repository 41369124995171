import { Component, Output, EventEmitter, Input } from '@angular/core';
import { PrfqService } from '../../prfq.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { ImportErrorDialogService } from '@app/components/import-error/import-error-dialog.service';
import * as moment from 'moment';
import { ImportErrorColumnDef, ImportModes } from '@app/components/import-error/import-error-dialog.model';

interface DataRow {
    lineNumber: number,
    amountOffered: number,
    unitPriceOffered: number,
    dateOfferedDays: number,
    itemValidity: string
}

@Component({
    selector: 'app-prfq-detail-update-items',
    templateUrl: 'prfq-detail-update-items.component.html'
})
export class PrfqDetailUpdateItemsComponent {
    @Input() prfqId: number;
    @Output() onImportFinish: EventEmitter<{successCount: number}> = new EventEmitter<{successCount: number}>();

    private readonly columnsDef: ImportErrorColumnDef[];
    public readonly rowAttrs: string[];
    private successCount = 0;

    constructor(
        private prfqService: PrfqService,
        private toastService: ToastService,
        private importErrorDialogService: ImportErrorDialogService
    ) {
        this.columnsDef = [];
        const lineNumber: ImportErrorColumnDef = {
            attrName: 'lineNumber',
            header: 'LINE_NUMBER',
            type: 'number',
            isValid: (val) => this.prfqService.isColValid('lineNumber', val)
        };
        this.columnsDef.push(lineNumber);

        const amountOffered: ImportErrorColumnDef = {
            attrName: 'amountOffered',
            header: 'OFFERED_AMOUNT',
            type: 'number',
            isValid: (val) => this.prfqService.isColValid('amountOffered', val)
        };
        this.columnsDef.push(amountOffered);

        const unitPriceOffered: ImportErrorColumnDef = {
            attrName: 'unitPriceOffered',
            header: 'OFFERED_PRICE',
            type: 'decimal',
            isValid: (val) => this.prfqService.isColValid('unitPriceOffered', val)
        };
        this.columnsDef.push(unitPriceOffered);

        const dateOfferedDays: ImportErrorColumnDef = {
            attrName: 'dateOfferedDays',
            header: 'OFFERED_DATE_DAYS',
            type: 'number',
            isValid: (val) => this.prfqService.isColValid('dateOfferedDays', val)
        };
        this.columnsDef.push(dateOfferedDays);

        const itemValidityDays: ImportErrorColumnDef = {
            attrName: 'itemValidityDays',
            header: 'RFQ_ITEM_VALIDITY',
            type: 'number',
            isValid: (val) => this.prfqService.isColValid('itemValidityDays', val)
            // getMinDate: (row: DataRow) => this.getTomorrow()
        };
        this.columnsDef.push(itemValidityDays);

        this.rowAttrs = this.columnsDef.map(columnDef => columnDef.attrName);
    }

    private getTomorrow(): Date {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    public onDataReady(dataRows: DataRow[]): void {
        // dataRows = this.convertDates(dataRows);
        this.submitItems(dataRows);
    }

    public submitItems(dataRows: DataRow[]): void {
        this.prfqService.updatePrfqItemsByLineNumber(this.prfqId, dataRows).subscribe(response => {
            this.successCount += response.successCount;

            if (response.errors.length === 0) {
                this.onImportFinish.emit({successCount: this.successCount});
                this.toastService.addSuccess('PRFQ_ITEMS_UPDATED');
                this.successCount = 0;
            } else {
                this.importErrorDialogService.openDialog(this.columnsDef, response.successCount,
                    response.errors, ImportModes.UPDATE).subscribe(importErrorResult => {
                        if (importErrorResult.rows.length > 0) {
                            this.submitItems(importErrorResult.rows);
                        } else {
                            this.onImportFinish.emit({successCount: this.successCount});
                            this.successCount = 0;
                        }
                    });
            }
        },
        err => console.error(err));
    }

    // private convertDates(dataRows: DataRow[]): DataRow[] {
    //     return dataRows.map(dataRow => {
    //         let itemValidity: any = dataRow.itemValidity;
    //         if (itemValidity) {
    //             const momentDate = moment(itemValidity, 'D.M.YYYY');
    //             if (momentDate.isValid()) {
    //                 itemValidity = momentDate.toISOString();
    //             }
    //         }
    //         return {...dataRow, itemValidity: itemValidity};
    //     });
    // }
}
