import { Component, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { LanguageService } from '../../../../services/language.service';
import { CellChange } from '@app/model/table.model';
import { Subject } from 'rxjs';
import { ICellEditorParams } from 'ag-grid-community';

interface DropdownItem {
    id: string;
    name: string
}

export interface GridSelectEditorComponentParams {
    itemsGetter?: (params: any) => DropdownItem[], // dropdown items getter
    items?: DropdownItem[], // dropdown items, e.g. [{id: 'A', name: 'jednokusove'}, ...]
    // data: { // data row object
    //     id: number;
    //     originalValues: OriginalValue[];
    //     edited: boolean;
    //     // other custom data attrs of the grid row
    // },
    // allowMultipleEdit?: boolean,
    // onChange?: (params: any) => void,
    // editedIdsObj: any, // {27369: true, 27370: true}
    // hideIfNoItems: boolean;
    cellChanged$?: Subject<CellChange>;
}

interface MyParams extends ICellEditorParams, GridSelectEditorComponentParams {}

@Component({
    selector: 'app-select-editor-cell',
    template: `
        <div tabindex="0" class="row-container">
            <ul role="menu" aria-labelledby="single-button">
                <li role="menuitem" *ngFor="let item of params.items" (click)="select(item)">
                   <a class="dropdown-item" translate>{{item.name}}</a>
                </li>
            </ul>
        </div>
    `,
    styles: [`
        .row-container {
            background-color: #fff;
            box-shadow: 0 6px 12px rgba(0,0,0,.175);
        }
        ul {
            list-style:none;
            margin: 0;
            padding: 0;
            margin-top: 15px;
        }
        li {height: 36px;}
        .dropdown-item {
            padding: 10px;
        }
    `]
})
export class GridSelectEditorComponent implements AgEditorComponent, AfterViewInit {
    params: MyParams;
    mySelectedValue: string;

    constructor(private sharedService: SharedService, private languageService: LanguageService) {
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
    }

    agInit(params: MyParams): void {
        this.params = params;
        const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
        if (this.params.itemsGetter) {
            // this.params.items = this.params.itemsGetter({data: this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data});
            this.params.items = this.params.itemsGetter({data: data});
        }
        this.mySelectedValue = params.value;

        if (this.params.cellChanged$) {
            const change: CellChange = {
                data: data,
                rowIndex: this.params.rowIndex,
                changedColumnField: this.params.column.getColId(),
                api: this.params.api,
                node: this.params.node
            };
            this.params.cellChanged$.next(change);
        }
    }

    getValue(): any {
        return this.mySelectedValue;
    }

    isPopup(): boolean {
        return true;
    }

    select(item) {
        this.mySelectedValue = item.id;
        this.params.stopEditing();
    }
}
