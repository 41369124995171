import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { Rfq, RfqCloseQuestionnaire, RfqItem } from '../../../model/rfq.model';
import { SharedService } from '../../../services/shared.service';
import { RfqsService } from '../../../services/rfq/rfqs.service';
import { RfqService } from '../../../services/rfq/rfq.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { QuestionDialogService, QuestionDialogResult } from '../../questionDialogComponent/question.dialog.service';
import { RfqPermissionService } from '../../../services/rfq/rfq.permission.service';
import { RfqGridService } from '../../../services/rfq/rfq.grid.service';
import { RfqJournalGridService } from '../../../services/rfq/rfq.journal.grid.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { DiscussionService } from '../../../services/discussion.service';
import { map } from 'rxjs/operators';
import { UserService } from '@app/services/user/user.service';
import { RepreOverrideAreas, Representative, User } from '@app/model/user.model';
import { RfqCloseQuestionnaireDialogComponentParams } from './rfq-close-questionnaire/rfq-close-questionnaire.dialog.component';
import { RfqCloseQuestionnaireDialogService } from './rfq-close-questionnaire/rfq-close-questionnaire.dialog.service';

@Component({
    selector: 'app-rfq',
    templateUrl: 'rfq.detail.component.html'
})
export class RfqDetailComponent implements OnInit, OnDestroy {
    @ViewChild('discussion') discussion;

    rfq: Rfq;

    totalRows: number;
    filterItems: any[];
    subjectRefresh = new Subject<any>();
    exportObj: { fileName: string, export: Function };

    subjectReloadItems = new Subject<any>();
    subjectReloadItemsGridOptions: Subject<{ isProjectPriceList: boolean }> = new Subject<any>();
    subjectReloadAttachments = new Subject<any>();
    subjectReloadJournal = new Subject<any>();

    busy: boolean;
    objectKeys = Object.keys; // for ngFor iterating over object keys

    attachmentS3Keys: string[] = [];
    attachmentsCount = 0;
    itemsVisible = false;
    addItemsVisible = false;
    // buttonsVisibility = {};
    public buttonsVisibility: {[action: string]: boolean}; // actions = RfqActions
    rowsData: RfqItem[];
    isNoteEditable: boolean;
    isPriceListEditable: boolean;

    projectPriceLists: any[] = [];  // Project priceList dropdown data
    currentPriceList: any = {};  // Currently selected priceList (priceListCode == undefined means the General priceList)
    isAddToRfqAvailable: boolean;	 // controls if addToRfq can see customer or AS by permissions

    backUrl = 'rfqs';
    backQueryParams = {};
    canDoOrderAndClose: boolean;
    isQuestionnaireFulfilled: boolean;
    allowSendRfq: boolean;

    public isCollapsedChild = { // set default states for collapsible elements, if new allement will be added, add here default value
        questionnaire: true,
        discussion: true,
        journal: true,
        attachments: true,
        rfqCloseQuestionnaire: true
    };

    public edited = {  // what part of rfq is edited (changed)
        note: false,
        questionnaire: false,
        discussionInForm: false
    };

    public allItemsInStates_R_or_O: boolean;
    public repreOverrideCustomerName: string;
    public isElementAvailable = {
        assignedSellerName: false,
        journal: false,
        totalPrice: false,
    };
    public user: User;
    public representative = Representative;

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    isEditing(): boolean {
        return Object.keys(this.edited).reduce((acc, key) => acc || this.edited[key], false);
    }

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        public sharedService: SharedService,
        private rfqsService: RfqsService,
        private rfqService: RfqService,
        private rfqGridService: RfqGridService,
        private rfqJournalGridService: RfqJournalGridService,
        private questionDialogService: QuestionDialogService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
        public rfqPermissionService: RfqPermissionService,
        private toastService: ToastService,
        private discussionService: DiscussionService,
        private userService: UserService,
        private rfqCloseQuestionnaireDialogService: RfqCloseQuestionnaireDialogService
    ) {
        this.subjectRefresh = new Subject<any>();

        this.getAttachments = this.getAttachments.bind(this);
        this.getUploadUrl = this.getUploadUrl.bind(this);
        this.getDownloadUrl = this.getDownloadUrl.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.getJournal = this.getJournal.bind(this);
        this.getJournalGridOptions = this.getJournalGridOptions.bind(this);
        this.getJournalFilterItems = this.getJournalFilterItems.bind(this);
        this.getJournalColumnsDefs = this.getJournalColumnsDefs.bind(this);
        this.getJournalColumns = this.getJournalColumns.bind(this);
        this.getCommentItemLabel = this.getCommentItemLabel.bind(this);

        if (this.sharedService.backUrl.length > 0) { // set back url from previous component if exists
            this.backUrl = this.sharedService.backUrl;
            this.backQueryParams = this.sharedService.backQueryParams;
            this.sharedService.backUrl = '';
            this.sharedService.backQueryParams = null;
        }

        this.repreOverrideCustomerName = this.userService.isRepreOverrideForArea(RepreOverrideAreas.rfq)
            ? this.sharedService.user.repreOverride.customerName
            : null;

        this.isElementAvailable.assignedSellerName = 
            this.rfqPermissionService.isRfqColumnAvailable('assignedSellerName');
        
        this.user = userService.getUserForRepreOverrideArea(RepreOverrideAreas.rfq);
    }

    _t(text?: string) {
        return this.sharedService.translateService.instant(text);
    }

    ngOnInit() {
        this.isQuestionnaireFulfilled = true;
        this.allowSendRfq = false; // dont allow send rfq if there isnt rows or here on init - while we dont know rows count
        this.loadRfq();

        this.rfqGridService.subjectAttachmentChanged.subscribe(() => {
            this.subjectReloadAttachments.next();
        });

        this.rfqsService.getReloadCurrentSubject().subscribe(() => {
            this.subjectReloadJournal.next();
        });

        // var this_ = this;
        // setTimeout(function() {
        // 	// navigate with orderby parameter
        // 	this_.sharedService.navigateWithOrderby(this_.getColumns(),
        // 		this_.route.snapshot.queryParams, this_.sharedService.lastDeliveryOrderDetailQueryParams);
        // }, 0);
    }

    loadRfq() {
        this.rfqsService.getRfq(this.route.snapshot.params['id']).subscribe(data => {
            this.rfq = data;
            this.rfqPermissionService.setRfqState(this.rfq.rfqState);
            this.isElementAvailable.journal = 
                this.rfqPermissionService.isElementAvailable('journal');
            this.isElementAvailable.totalPrice = 
                this.rfqPermissionService.isElementAvailable('totalPrice');

            this.isNoteEditable = this.rfqPermissionService.isColumnEditable('rfqNote');
            this.isPriceListEditable = this.rfqPermissionService.isColumnEditable('priceList');
            this.isAddToRfqAvailable = this.rfqPermissionService.isElementAvailable('addToRfq');
            this.itemsVisible = true;
            this.buttonsVisibility = this.getButtonsVisibility(this.rfq.actions);

            // Customer project pricelists dropdown
            this.rfqService.getPriceLists(this.rfq.customerId).subscribe((result: any[]) => {
                this.projectPriceLists = result;
                this.currentPriceList = this.projectPriceLists.filter(list => list.priceListCode === this.rfq.priceListCode && list.priceListCurrency === this.rfq.priceListCurrency)[0];
                setTimeout(() => {
                    this.subjectReloadItemsGridOptions.next({ isProjectPriceList: this.currentPriceList.id > 0 });
                }, 0);
            });

            const exportFunction = function (query) {
                return this.rfqService.exportDetail(this.rfq.id, query);
            };

            this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
                this.exportObj = {
                    fileName: this._t('RFQ') + '_' + this.rfq.rfqNumber,
                    export: exportFunction.bind(this)
                };
            });
        }, err => {
            console.log(err)
        });
    }

    deleteRfq() {
        this.rfqsService.deleteRfq(this.rfq.id)
            .subscribe(() => {
                this.toastService.addSuccess('RFQ_DELETE_RFQ_DONE');
                this.router.navigate([`/${this.sharedService.appSettings.language}/rfqs`]);
            },
                err => { console.log(err) });
    }

    public rfqAction(action: string, extraData?: any): void {
        if (action === 'SUBMIT_RFQ' && !this.isQuestionnaireFulfilled) {
            this.questionDialogService.confirm({
                message: 'WARNING',
                question: 'RFQ_CONTINUE_WITH_EMPTY_QUESTIONNAIRE',
                primary: 'NO',
                secondary: 'YES',
                suppressQuestionMark: true,
            }).subscribe(
                result => {
                    if (result === QuestionDialogResult.Confirm) {
                        this.scrollToQuestionnaire();
                    } else {
                        this._rfqAction(action, extraData);
                    }
                }
            );
        } else {
            this._rfqAction(action, extraData);
        }
    }

    private _rfqAction(action: string, extraData: any): void {
        this.rfqsService.rfqAction(this.rfq.id, action, extraData)
            .subscribe(() => {
                this.toastService.addSuccess(`RFQ_${action}_DONE`);
                if (['OPEN_RFQ', 'REOPEN_RFQ'].indexOf(action) > -1) { // stay in rfq detail and reload data
                    if (['REOPEN_RFQ'].indexOf(action) > -1) {
                        this.discussionService.getCommentObservable().next({});
                    }
                    this.loadRfq();
                    this.subjectReloadItems.next();
                    this.scrollToTop();
                } else {
                    this.router.navigate([`/${this.sharedService.appSettings.language}/rfqs`]);
                }
            }, err => { console.log(err) });
    }

    getButtonsVisibility(actions: string[]) {
        return actions.reduce((acc, action) => Object.assign({}, acc, { [action]: this.rfqPermissionService.hasPermissionHeaderAction() }), {});
    }

    getAttachments(rfqId: number) {
        return this.rfqsService.getAttachments(rfqId).pipe(map(result => {
            return result;
        }));
    }

    // getUploadUrl(fileName: string, rfqId?: number) {
    getUploadUrl(fileName: string) {
        return this.rfqsService.getAttachmentUploadUrl(fileName, this.rfq.id);
    }

    getDownloadUrl(attachmentId: number, lineNumber?: number) {
        return this.rfqsService.getAttachmentDownloadUrl(this.rfq.id, attachmentId, lineNumber);
    }

    setAttachmentS3Keys(s3Keys: string[]) {
        this.attachmentS3Keys = s3Keys;
    }

    deleteAttachment(rfqId: number, attachmentId: number, lineNumber?: number) {
        return this.rfqsService.deleteAttachment(rfqId, attachmentId);
    }

    attachmentsChanged(attachmentsCount) {
        this.attachmentsCount = attachmentsCount;
        this.subjectReloadJournal.next();
    }

    scrollToComments() {
        this.isCollapsedChild.discussion = false;
        setTimeout(() => {
            this.pageScrollService.scroll({ document: this.document, scrollTarget: '#discussion' });
            this.discussion.focusNewComment();
        }, 0);
    }
    scrollToAttachments() {
        this.isCollapsedChild.attachments = false;
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#attachments' });
    }
    scrollToQuestionnaire() {
        this.isCollapsedChild.questionnaire = false;
        setTimeout(() =>
            this.pageScrollService.scroll({ document: this.document, scrollTarget: '#questionnaire' })
        , 100);
    }
    scrollToTop() {
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#rfqDetail' });
    }
    scrollToJournal() {
        this.isCollapsedChild.journal = false;
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#rfqJournal' });
    }
    getJournal(rfqId: number, query: any) {
        return this.rfqsService.getJournal(rfqId, query);
    }
    getJournalGridOptions() {
        return this.rfqJournalGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    getJournalFilterItems(personsCreated) {
        return this.rfqJournalGridService.getFilterItems(personsCreated);
    }
    getJournalColumns() {
        return this.rfqJournalGridService.getColumns();
    }
    getJournalColumnsDefs() {
        return this.rfqJournalGridService.getColumnDefs();
    }

    toggleAddItems() {
        this.addItemsVisible = !this.addItemsVisible;
    }

    getCommentItemLabel(item) {
        return item.displayItem ? this._t('DISCUSSION_TO_ROW').toLowerCase() + ' ' + (item.displayItem.lineNumber ? item.displayItem.lineNumber : item.childTableKey) : '';
    }

    discussionChanged(event) {
        this.subjectReloadJournal.next();
    }

    /**
     * Called by rfq.detail.items component after loading the items
     * @param rows a copy of rfq items
     */
    getRowsDataChange(rows: RfqItem[]): void {
        this.rowsData = rows;
        this.allowSendRfq = this.rowsData.length > 0 ? true : false;
        this.canDoOrderAndClose = this.rowsData.filter(row => {
            // find out also if some items are in other state than 'R' or 'D' for change in rfq submit button label
            this.allItemsInStates_R_or_O = true;
            if ((row.itemState !== 'R') && (row.itemState !== 'D')) {
                this.allItemsInStates_R_or_O = false;
            }

            return row.itemState === 'A';
        }).length === 0 ? false : true;
        this.rfq.totalPrice = this.getTotalPrice(rows);
    }

    private getTotalPrice(items: RfqItem[]): number {
        return items.reduce((total, row) => total + row.rowPrice, 0);
    }

    // public startRfqClose(): void {
    //     const labelForDialog = this.sharedService.user.representative === 'AS' ? 'RFQ_SUBMIT' : (this.allItemsInStates_R_or_O ? 'RFQ_SUBMIT' : 'RFQ_SUBMIT_WITHOUT_ORDER');
    //     if (this.rowsData.filter(row => row.itemState === 'A').length > 0) {
    //         this.questionDialogService.confirm({
    //             message: this._t('RFQ_SUBMIT_WITHOUT_ORDER'),
    //             question: this._t('RFQ_NOTICE_NO_ITEMS_WILL_BE_ORDERED'),
    //             primary: this._t('CONTINUE'),
    //             secondary: this._t('CANCEL'),
    //             suppressQuestionMark: true,
    //         }).subscribe(
    //             res => {
    //                 if (res === QuestionDialogResult.Confirm) {
    //                     this.showCommentModal({
    //                         question: '',
    //                         comment: '',
    //                         rows: 10,
    //                         cols: 75,
    //                         message: this._t('DISCUSSION'),
    //                         primary: this._t('RFQ_SUBMIT_WITHOUT_ORDER'),
    //                         secondary: this._t('CANCEL'),
    //                         placeHolder: this._t('DISCUSSION_NEW_PLACEHOLDER'),
    //                         suppressQuestionMark: true,
    //                     });
    //                 }
    //             }
    //         );
    //     } else {
    //         // show comment modal because all is accepted'
    //         this.showCommentModal({
    //             question: '',
    //             comment: '',
    //             rows: 10,
    //             cols: 75,
    //             message: this._t('DISCUSSION'),
    //             primary: this._t(labelForDialog),
    //             secondary: this._t('CANCEL'),
    //             placeHolder: this._t('DISCUSSION_NEW_PLACEHOLDER'),
    //             suppressQuestionMark: true,
    //         });
    //     }
    // }

    // private showCommentModal(param: QuestionDialogParamsWithMessage): void {
    //     this.questionDialogService.confirmWithMessage(param).subscribe(
    //         result => {
    //             if (result.res === QuestionDialogResult.Confirm) {
    //                 this.rfqService.doRfqAction(this.rfq.id, 'CLOSE_RFQ', { discussionText: result.textValue.toString() });
    //             }
    //         }
    //     );
    // }

    public startRfqClose(): void {
        const labelForDialog = this.sharedService.user.representative === 'AS' ? 'RFQ_SUBMIT' : (this.allItemsInStates_R_or_O ? 'RFQ_SUBMIT' : 'RFQ_SUBMIT_WITHOUT_ORDER');

        if (this.rowsData.filter(row => row.itemState === 'A').length > 0) {
            this.questionDialogService.confirm({
                message: this._t('RFQ_SUBMIT_WITHOUT_ORDER'),
                question: this._t('RFQ_NOTICE_NO_ITEMS_WILL_BE_ORDERED'),
                primary: this._t('CONTINUE'),
                secondary: this._t('CANCEL'),
                suppressQuestionMark: true,
            }).subscribe(res => {
                if (res === QuestionDialogResult.Confirm) {
                    this.showClosQuestionnaire('RFQ_SUBMIT_WITHOUT_ORDER');
                }
            });
        } else {
            this.showClosQuestionnaire(labelForDialog);
        }
    }

    private showClosQuestionnaire(submitButtonText: string): void {
        const params: RfqCloseQuestionnaireDialogComponentParams = {
            rfqCloseQuestionnaire: new RfqCloseQuestionnaire(),
            submitButtonText: submitButtonText
        };

        this.rfqCloseQuestionnaireDialogService.show(params).subscribe(rfqCloseQuestionnaire => {
            if (rfqCloseQuestionnaire) {
                this.rfqService.doRfqAction(this.rfq.id, 'CLOSE_RFQ', 
                    { rfqCloseQuestionnaire: rfqCloseQuestionnaire });
            }
        });
    }

    showModalStatusReopen() {
        this.questionDialogService.confirmWithMessage(
            {
                question: '',
                comment: '',
                rows: 10,
                cols: 75,
                message: this._t('DISCUSSION'),
                primary: this._t('RFQ_REOPEN'),
                secondary: this._t('CANCEL'),
                placeHolder: this._t('DISCUSSION_NEW_PLACEHOLDER'),
                suppressQuestionMark: true,
            }
        ).subscribe(
            result => {
                if (result.res === 'confirm') {
                    this.rfqAction('REOPEN_RFQ', { discussionText: result.textValue.toString() });
                }
            }
        );
    }

    noteEdited(value: boolean) {
        this.edited.note = value;
    }

    questionnaireEdited(value: boolean) {
        this.edited.questionnaire = value;
    }

    discussionInFormEdited(value: boolean) {
        this.edited.discussionInForm = value;
    }

    /**
     * Price list methods
     */
    onSelectProjectPriceList(event) {
        this.setProjectPriceList(event.selected);
    }

    setProjectPriceList(projectPriceList) {
        this.currentPriceList = projectPriceList;
        // this.userService.setUserPreference('currentOrder_currentPriceList', this.currentPriceList, false);
        this.rfqService.updateRfq(
            this.rfq.id,
            { priceListCode: projectPriceList.priceListCode, priceListCurrency: projectPriceList.priceListCurrency }
        ).subscribe(() => {
            this.subjectReloadItems.next();
            this.subjectReloadItemsGridOptions.next({ isProjectPriceList: this.currentPriceList.id > 0 });
        });
    }

    public rfqOrderAndClose(): void {
        this.rfqService.orderItemAndCloseRfq(this.rfq.id, this.rowsData);
    }

    setQuestionnaireFulfill(filledMandatory: any) {
        this.isQuestionnaireFulfilled = filledMandatory;
    }

    ngOnDestroy() {
    }
}
