import { Observable ,  Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { NewsDialogComponent } from '../../components/newsComponent/newsDialogComponent/news.dialog.component';
import { NewsReadDetailDialogComponent } from './../../components/newsComponent/newsReadDetailDialogComponent/news.read.detail.dialog.component';
@Injectable()
export class NewsDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    // if load discussion is true, load of comments is needed in dialog e.g. in orders items
    public confirm(params: {data: any, question: string, primary: string, secondary: string, isModal: boolean}
        ): Observable<any> {
        this.bsModalRef = this.modalService.show(NewsDialogComponent, Object.assign({}, {
            class: 'modal-80',
            ignoreBackdropClick: true,
            keyboard: false,
        }));

        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }

    public showReadCount(params: any): Observable<any> {
        this.bsModalRef = this.modalService.show(NewsReadDetailDialogComponent, {class: 'customer-stores-dialog-override'});
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
