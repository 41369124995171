import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../../services/language.service';
import { GridLinkRendererComponent } from 'app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNewsActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNewsActionsRendereComponent/grid.news.actions.renderer.component';
import { GridNewsShowInDialogRendererComponent } from './../../components/tableComponent/gridRendererComponents/gridNewsShowInDialogActionRendereComponent/grid.news.show.in.dialog.renderer.component';
import { ColDef } from 'ag-grid-community';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';

@Injectable()
export class NewsGridService {
    subjectReloadCurrent: Subject<any>;

    constructor(
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        public languageService: LanguageService) {

        this.subjectReloadCurrent = new Subject<any>();
    }

    private isAdmin(): boolean {
        return this.sharedService.hasPermission('news', 'POST');
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [];
        if (this.isAdmin()) { // admin
            original = [
                {id: 'title', name: 'NEWS_TITLE', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'titleAdmin', name: 'NEWS_TITLE_ADMIN', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'validFrom', name: 'NEWS_VALIDITY_FROM', checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
                {id: 'validTo', name: 'NEWS_VALIDITY_TO', checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
                {id: 'userCreatedSortName', name: 'NEWS_CREATED_BY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: false, orderDirection: 'DESC', width: 120},
                {id: 'dateChanged', name: 'NEWS_UPDATED_AT', checked: true, orderBy: false, orderDirection: 'DESC', width: 120},
                {id: 'important', name: 'NEWS_IS_IMPORTANT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'readCount', name: 'NEWS_READ_COUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 130}
            ]
        } else { // no admin
            original = [
                {id: 'title', name: 'NEWS_TITLE', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'validFrom', name: 'NEWS_CREATED_AT', checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
                {id: 'userCreatedSortName', name: 'NEWS_CREATED_BY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110}
            ]
        };
        original = this.tableService.filterSuppressColumns(original, 'news');
        const restored = this.sharedService.user.preferences['newsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        // tslint:disable-next-line:prefer-const
        let columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'newsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this.getColumnDefs();
        gridOptions.getRowStyle = params => {
            if (this.sharedService.permissions.news.indexOf('POST') < 0 && !params.data.read) {
                return { color: 'rgb(255, 53, 53)' }
            }
        }
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs: ColDef[] = [];

        // title
        const title: ColDef = {
            headerName: this.translateService.instant(this.getColumn('title').name),
            field: 'title',
            width: this.getColumn('title').width,
            // minWidth: this.getColumn('title').width,
            cellStyle: { 'text-align': 'left'},
            cellRendererFramework: GridLinkRendererComponent
        };
        const titleRendererParams = (params) => {
            const result = {
                ...(this.sharedService.permissions.news.indexOf('POST') < 0 && !params.data.read ? { aColor: 'rgb(255, 53, 53)' } : {}),
                ...(params.data.important ? { showIcon: 'clickable fnt-red fa fa-exclamation-circle'} : {}), // set icon
                data: {
                    url: 'news/' + params.data.id
                },
                markAsRead: params.data.read === 0 ? true : false
            };
            return result;
        };
            
        title.cellRendererParams = titleRendererParams;
        colDefs.push(title);

        colDefs = colDefs.concat([
                ...(this.isAdmin() ? 
                    [{
                        headerName: this.translateService.instant(this.getColumn('titleAdmin').name),
                        field: 'titleAdmin',
                        valueGetter: (params) => params.data.titleAdmin,
                        width: this.getColumn('titleAdmin').width,
                        cellStyle: { 'text-align': 'left' }
                    }] : [])
                ,
                {
                    headerName: this.translateService.instant(this.getColumn('validFrom').name),
                    field: 'validFrom',
                    width: 150,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridDateRendererComponent,
                    cellRendererParams: { time: this.isAdmin() },
                },
                ...(this.isAdmin() ?
                    [{ headerName: this.translateService.instant(this.getColumn('validTo').name),
                        field: 'validTo',
                        width: this.getColumn('validTo').width,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridDateRendererComponent,
                        cellRendererParams: { time: this.isAdmin() },
                    }] : [])
                ,
                {
                    headerName: this.translateService.instant(this.getColumn('userCreatedSortName').name),
                    field: 'userCreatedSortName',
                    valueGetter: (params) => params.data.userCreatedFullName,
                    width: this.getColumn('userCreatedSortName').width,
                    cellStyle: { 'text-align': 'left' }
                },
                ...(this.isAdmin() ?
                    [{headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                        field: 'dateCreated',
                        width: this.getColumn('dateCreated').width,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridDateRendererComponent,
                        cellRendererParams: { time: true },
                    }] : [])
                ,
                ...(this.isAdmin() ?
                    [{headerName: this.translateService.instant(this.getColumn('dateChanged').name),
                        field: 'dateChanged',
                        width: this.getColumn('dateChanged').width,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridDateRendererComponent,
                        cellRendererParams: { time: true },
                    }] : [])
                ,
                ...(this.isAdmin() ?
                [{
                    headerName: this.translateService.instant(this.getColumn('important').name),
                    field: 'important',
                    width: this.getColumn('important').width,
                    valueGetter: (params) => {
                        return params.data.important === 1 ? this.sharedService.translateService.instant('YES') : this.sharedService.translateService.instant('NO');
                    }
                     }] : [])
                ,
                ...(this.isAdmin() ?
                    [{
                        headerName: this.translateService.instant(this.getColumn('readCount').name),
                        field: 'readCount',
                        width: this.getColumn('readCount').width,
                        cellStyle: { 'text-align': 'center' },
                        cellRendererFramework: GridNewsShowInDialogRendererComponent,
                        cellRendererParams: {
                            subjectReloadCurrent: this.subjectReloadCurrent
                        }
                    }] : []
                    )
                ,
                {
                    headerName: this.translateService.instant(this.getColumn('actions').name),
                    field: 'actions',
                    width: this.getColumn('actions').width,
                    minWidth: this.getColumn('actions').width,
                    maxWidth: this.getColumn('actions').width,
                    cellStyle: { 'text-align': 'center' },
                    cellRendererFramework: GridNewsActionsRendererComponent,
                    ...(this.isAdmin() ?
                        {
                            cellRendererParams: {
                                showAttachments: true,
                                showDiscussions: true,
                                showUnpublish: true,
                                showMarkAsRead: true,
                                showMarkAsUnread: true,
                                subjectReloadCurrent: this.subjectReloadCurrent
                            }
                        } : {}
                    ),
                    ...(this.sharedService.permissions.news.indexOf('POST') < 0 ?
                        {
                            cellRendererParams: {
                                showAttachments: true,
                                showDiscussions: true,
                                showMarkAsRead: true,
                                showMarkAsUnread: true,
                                subjectReloadCurrent: this.subjectReloadCurrent
                            }
                        } : {}
                    ),
                    sortable: false,

                }
            ]);

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'news');
        return colDefs;
    }

    getFilterItems(personsIssued: {id: number, name: string}[]) {
        if ( this.isAdmin()) {
            // customers.unshift({id: 'NULL', name: 'AUTHORIZED_SELLER_USER'});
            let filterItems = [];
                filterItems = [
                    {id: 'title', name: 'NEWS_TITLE', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
                    // {id: 'validFrom', name: 'NEWS_VALIDITY_FROM', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}, showTimepicker: true, time: true},
                    {id: 'validFrom', name: 'NEWS_VALIDITY_FROM', type: 'dateRange', valueFrom: '', valueTo: '', showTimepicker: true, time: true},
                    // platne
                    {id: 'valid', name: 'NEWS_IS_VALID', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                        values: [
                            {id: '1', name: 'YES'},
                            {id: '0', name: 'NO'},
                        ]},
                    // vytvoril
                    {id: 'personCreated', name: 'NEWS_CREATED_BY', type: 'multiselect', search: true, value: '', values: personsIssued},
                    // important
                    {id: 'important', name: 'NEWS_IS_IMPORTANT', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                        values: [
                            {id: '1', name: 'NEWS_IS_IMPORTANT_YES'},
                            {id: '0', name: 'NEWS_IS_IMPORTANT_NO'},
                        ]},

                    // {id: 'dateChanged', name: 'NEWS_UPDATED_AT', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},

                    // {id: 'dateTo', name: 'NEWS_VALIDITY_TO', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
                    // {id: 'authorizedSeller.id', name: 'NEWS_CREATED_BY', type: 'multiselect', value: '', values: authorizedSellers},
                    // {id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: customers},
                    // {id: 'userId', name: 'USER', type: 'multiselect', search: true, value: '', values: personsIssued},

                ];
            filterItems = this.tableService.filterSuppressColumns(filterItems, 'news');
            return filterItems;
        } else { // customer shouldnt have filters yet
            const filterItems = [];
            return filterItems;
        }
    }

}

