import { Observable, Subject } from 'rxjs';
import { LoginDialog } from './login.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params?: any): Observable<boolean> {
        this.bsModalRef = this.modalService.show(LoginDialog,
            {
                class: 'login-modal' + (params && params.tempPass ? ' registration' : ''),
                keyboard: false,
                ignoreBackdropClick: true
            }
        );
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
