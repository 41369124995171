import { Observable ,  Subject } from 'rxjs';
import { QuestionDialog } from './question.dialog.component';
import { QuestionDialogWithComment } from './question.dialog.with.comment.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

export enum QuestionDialogResult {
    Confirm = 'confirm',
    Dismiss = 'dismiss'
}

export interface QuestionDialogParams {
    textClass?: string;
    message?: string; // 'EXPEDITION_CANCEL'
    question?: string; // 'SHIPMENT_CANCEL_QUESTION'
    suppressQuestionMark?: boolean;
    primary: string; // 'YES'
    secondary?: string; // 'NO'
}

export interface QuestionDialogParamsWithMessage extends QuestionDialogParams {
    placeHolder?: string;
    rows?: number;
    cols?: number;
    comment?: string;
}

export interface QuestionDialogModalParams {
    keyboard?: boolean;
    ignoreBackdropClick?: boolean;
}

@Injectable()
export class QuestionDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    /**
     * Shows the confirm modal doalog
     * @param params
     * @param modalParams { keyboard: default true, ignoreBackdropClick: default false }
     */
    public confirm(params: QuestionDialogParams, modalParams?: QuestionDialogModalParams): Observable<QuestionDialogResult> {
        this.bsModalRef = this.modalService.show(QuestionDialog, modalParams);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }

    public confirmWithMessage(params: QuestionDialogParamsWithMessage): Observable<{ res: QuestionDialogResult, textValue: string }> {
        this.bsModalRef = this.modalService.show(QuestionDialogWithComment);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
