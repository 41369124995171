import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { TableFilterItem } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridLinkRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
// import { SupplierService } from '../supplier.service';
import { Subject } from 'rxjs';
import { AppAreas } from '@app/model/appArea.model';

@Injectable()
export class SuppliersGridService extends BaseGridService {
    public reloadCurrentGridPage$ = new Subject<void>();

    constructor (
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        // private supplierService: SupplierService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas.suppliers, //'suppliers',
            gridNameCamel: 'suppliers',
            columnList: [
                { id: 'supplierName', name: 'SUPPLIER_NAME', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 120},
                { id: 'supplierCode', name: 'SUPPLIER_CODE', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
                { id: 'contactName', name: 'CUSTOMER_CONTACT_NAME', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'contactPhone', name: 'CUSTOMER_CONTACT_PHONE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'contactEmail', name: 'CUSTOMER_CONTACT_EMAIL', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'locality', name: 'CUSTOMER_LOCALITY', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
                { id: 'valid', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('supplierName').name),
                field: 'supplierName',
                width: this.getColumn('supplierName').width,
                minWidth: this.getColumn('supplierName').width,
                cellRendererFramework: GridLinkRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('supplierCode').name),
                field: 'supplierCode',
                width: this.getColumn('supplierCode').width,
                minWidth: this.getColumn('supplierCode').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactName').name),
                field: 'contactName',
                width: this.getColumn('contactName').width,
                minWidth: this.getColumn('contactName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactPhone').name),
                field: 'contactPhone',
                width: this.getColumn('contactPhone').width,
                minWidth: this.getColumn('contactPhone').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactEmail').name),
                field: 'contactEmail',
                width: this.getColumn('contactEmail').width,
                minWidth: this.getColumn('contactEmail').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('locality').name),
                field: 'locality',
                width: this.getColumn('locality').width,
                minWidth: this.getColumn('locality').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('valid').name),
                field: 'valid',
                width: this.getColumn('valid').width,
                minWidth: this.getColumn('locality').width,
                cellStyle: { 'text-align': 'left' },
                valueGetter: (params) => this.translateService.instant('CUSTOMER_STATUS_' +
                    (params.data.valid === 0 ? 'BLOCKED' : 'ENABLED'))
            }
        ];
        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [];
        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
