import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ImportErrorDialogResult, ImportErrorRow, ImportErrorColumnDef, ImportModes } from './import-error-dialog.model';
import { SharedService } from '@app/services/shared.service';
import * as moment from 'moment';
import { IMyDateModel, IMyOptions } from 'mydatepicker';
import { FormService } from '@app/services/form.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalDatePipe } from '@app/locale.pipes.module';
import { LanguageService } from '@app/services/language.service';
import { WindowService } from '@app/services/window.service';

interface ImportErrorRowExtended extends ImportErrorRow {
    datePickerOptions: {
        [attrName: string]: IMyOptions
    },
    placeholders?: { // placeholders for invalid values which cannot be displayed in e.g. decimal component
        [attrName: string]: string
    }
    // minDates?: { // minimum dates
    //     [attrName: string]: Date
    // }
}

@Component({
    selector: 'app-import-error',
    templateUrl: 'import-error.component.html',
    styleUrls: ['./import-error.component.scss'],
})
export class ImportErrorComponent implements OnInit {
    // These values come from ImportErrorDialogService
    public done$ = new Subject<ImportErrorDialogResult>();
    public columnsDef: ImportErrorColumnDef[];
    public successCount: number;
    public errorRows: ImportErrorRowExtended[];
    public importMode: ImportModes;
    public maxHeight: number;
    // End of values coming from ImportErrorDialogService

    // public datePickerOptions: IMyOptions;
    public importModes = ImportModes;

    constructor(
        private bsModalRef: BsModalRef,
        public sharedService: SharedService,
        private formService: FormService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private windowService: WindowService
    ) {
        // this.datePickerOptions = this.formService.getDatePickerOptions(false);
        // this.datePickerOptions.selectionTxtFontSize = '98%';
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
    }

    public ngOnInit(): void {
        const placeholderGetters = {
            'decimal': this.setNumberPlaceholder,
            'date': this.setDatePlaceholder
        };

        setTimeout(() => {
            this.errorRows.forEach(errorRow => {
                errorRow.placeholders = {};
                errorRow.datePickerOptions = {};
                this.columnsDef.forEach(columnDef => {
                    // Set placeholders
                    if (columnDef.type in placeholderGetters) {
                        placeholderGetters[columnDef.type](errorRow, columnDef.attrName);
                    }
                    // Set datepicker options
                    if (columnDef.type === 'date') {
                        let options: IMyOptions;
                        if (columnDef.getMinDate) {
                            const minDate = columnDef.getMinDate(errorRow.row);
                            options = this.formService.getDatePickerOptions(false, moment(minDate));
                        } else {
                            options = this.formService.getDatePickerOptions(false);
                        }
                        options.selectionTxtFontSize = '98%';
                        errorRow.datePickerOptions[columnDef.attrName] = options;
                    }
                });
            });
        }, 0);
    }

    private setNumberPlaceholder(errorRow: ImportErrorRowExtended, attrName: string): void {
        let val = errorRow.row[attrName];
        if (typeof val === 'string') {
            val = val.replace(',', '.');
        }
        if (isNaN(val)) {
            errorRow.placeholders[attrName] = errorRow.row[attrName];
            errorRow.row[attrName] = null;
        } else {
            errorRow.placeholders[attrName] = '';
            errorRow.row[attrName] = val;
        }
    }

    private setDatePlaceholder(errorRow: ImportErrorRowExtended, attrName: string): void {
        if (errorRow.error[attrName]) {
            errorRow.placeholders[attrName] = errorRow.row[attrName];
            errorRow.row[attrName] = null;
        } else {
            errorRow.placeholders[attrName] = null;
            if (errorRow.row[attrName]) {
                const momentDate = moment(errorRow.row[attrName]);
                errorRow.row[attrName] = { date: { 
                    year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } };
            }
        }
    }

    public onValueChange(errorRow: ImportErrorRow, attrName: string, value: any) {
        errorRow.row[attrName] = value;

        const isValidFn = this.columnsDef.find(columnDef => columnDef.attrName === attrName).isValid;
        if (isValidFn(value)) {
            delete errorRow.error[attrName];
        }
    }

    public onDateChanged(event: IMyDateModel, errorRow: ImportErrorRowExtended, attrName: string): void {
        if (event.jsdate) {
            delete errorRow.error[attrName];
        }
        if (event.jsdate === null) {
            delete errorRow.error[attrName];
        }
    }

    public importAgain(): void {
        const rows = this.generateOutput();

        this.done$.next({ rows: rows });
        this.bsModalRef.hide();
    }

    private generateOutput(): any[] {
        return this.errorRows.map(errorRow =>
            this.columnsDef.reduce((acc, columnDef) => {
                const value = errorRow.row[columnDef.attrName];
                if (typeof value === 'undefined') {
                    return acc;
                } else {
                    return Object.assign({}, acc, 
                        {[columnDef.attrName]: this.convertDates(value, columnDef, errorRow)});
                }
            }, {})
        );
    }

    private convertDates(value: any, columnDef: ImportErrorColumnDef, errorRow: ImportErrorRowExtended): any {
        if (columnDef.type !== 'date') {
            return value;
        }
        if (value === null) {
            if (errorRow.error[columnDef.attrName]) {
                return errorRow.placeholders[columnDef.attrName];
            } else {
                return value;
            }
        } else if (value && ('date' in value)) {
            return (new Date(value.date.year, value.date.month-1, value.date.day)).toISOString();
        } else {
            return value;
        }
    }

    public copyErrorsToClipboard(): void {
        // Caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    private copy(): void {
        const tmpEl: HTMLTextAreaElement = document.createElement('textarea');

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = '0';
        tmpEl.style.position = 'fixed';
        tmpEl.style.pointerEvents = 'none';
        tmpEl.style.zIndex = '-1';

        const outputRows: any[] = this.generateOutput();
        let valueToCopy = '';

        // fill it with your HTML
        outputRows.forEach((row, i) => {
            this.columnsDef.forEach(columnDef => {
                const attrName = columnDef.attrName;

                if (attrName in row) {
                    if (columnDef.type === 'date') {
                        // Do not transform error date
                        if (this.errorRows[i].error && this.errorRows[i].error[attrName]) {
                            valueToCopy += row[attrName];
                        } else {
                            valueToCopy += new LocalDatePipe(this.languageService)
                                .transform(row[attrName], this.sharedService.appSettings.language);
                        }
                    } else {
                        valueToCopy += row[attrName];
                    }
                    valueToCopy += '\t';
                }
            });
            // Add error messages
            if (this.errorRows[i].error) {
                for (const key in this.errorRows[i].error) {
                    valueToCopy += this.translateService.instant(this.errorRows[i].error[key]) + ' ';
               }
            }
            valueToCopy += '\n';
        });

        // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(valueToCopy))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand('copy');

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    public closeModal(): void {
        this.done$.next({ rows: [] });
        this.bsModalRef.hide();
    }

}
