import { Injectable } from '@angular/core';
import { SharedService } from '@services/shared.service';
import { PrfqStates, PrfqItem, PrfqItemStates } from '@app/model/prfq.model';
import { Representative } from '@app/model/user.model';

@Injectable()
export class PrfqPermissionService {

    private prfqState: PrfqStates;

    constructor(
        private sharedService: SharedService
    ) {}

    public setPrfqState(state: PrfqStates) {
        this.prfqState = state;
    }

    public isColumnEditable(column: string): boolean {
        const editable = {
            // unitPriceRequired: () =>
            //     (this.prfqState === PrfqStates.IN_PROGRESS && representative === Representative.supplier) ||
            //     (this.prfqState === PrfqStates.OPENED && representative === Representative.AS),
            // itemNote: () => this.prfqState === PrfqStates.IN_PROGRESS && representative === Representative.supplier,
            prfqNote: () => this.prfqState === PrfqStates.IN_PROGRESS
            // dateRequired: () => this.prfqState === PrfqStates.IN_PROGRESS && representative === Representative.supplier,
            // itemValidity: () => this.prfqState === PrfqStates.IN_PROGRESS && representative === Representative.AS,
        };

        return ((column in editable)
            ? (editable[column]() && (this.hasPermissionItemsUpdate()))
            : false
        );
    }

    public isCellEditable(prfqItem: PrfqItem, column: string): boolean {
        const representative = this.sharedService.user.representative;

        const supplierCanEdit = () => ((representative === Representative.supplier &&
                (prfqItem.itemState === PrfqItemStates.OPENED || prfqItem.itemState === PrfqItemStates.NOT_CONFIRMED)
            ) || (!prfqItem.itemState && representative === Representative.supplier)) && 
            this.prfqState !== PrfqStates.CLOSED_ACCEPTED && this.prfqState !== PrfqStates.CLOSED_NOT_ACCEPTED;

        const asCanEdit = () =>
            representative === Representative.AS && this.prfqState === PrfqStates.IN_PROGRESS

        const editableMap = {
            productShortName: () => asCanEdit() || supplierCanEdit(),
            manufacturer: () => asCanEdit() || supplierCanEdit(),
            productSupplier: () => supplierCanEdit(),
            amountRequired: () => asCanEdit() || supplierCanEdit(),
            amountOffered: () => supplierCanEdit(),
            unitPriceRequired: () => asCanEdit() || supplierCanEdit(),
            unitPriceOffered: () => supplierCanEdit(),
            dateRequired: () => asCanEdit() || supplierCanEdit(),
            attachmentsCount: () => this.prfqState !== PrfqStates.NEW &&
                this.prfqState !== PrfqStates.CLOSED_ACCEPTED && 
                this.prfqState !== PrfqStates.CLOSED_NOT_ACCEPTED,
            dateOfferedDays: () => supplierCanEdit(),
            itemValidityDays: () => supplierCanEdit(),
            itemNote: () => asCanEdit()
        };

        return (column in editableMap) ?
            (editableMap[column]() && (this.hasPermissionItemsUpdate()))
            : false;
    }

    private hasPermissionItemsUpdate(): boolean {
        return this.sharedService.hasPermission('prfq/*/items', 'PUT');
    }

    public hasPermissionHeaderAction(): boolean {
        return this.sharedService.hasPermission('prfq/*/action', 'PUT');
    }

    public hasPermissionItemsAction(): boolean {
        return this.sharedService.hasPermission('prfq/*/items-action', 'PUT');
    }

    // hasPermissionEditQuestionaire() {
    //     return this.sharedService.hasPermission('prfq/*/questionnaire', 'PUT');
    // }

    /**
     * Availability of other than grid elements, e.g. buttons atc.
     */
    public isElementAvailable(element: 'createPrfq' | 'bulkAddToPrfq' | 'bulkPrfqUpdate' | 'journal' | 'discussion' | 'attachments'): boolean {
        const representative = this.sharedService.user.representative;

        const available = {
            createPrfq: () => representative === Representative.AS &&
                this.hasPermissionHeaderAction(),
            bulkAddToPrfq: () => representative === Representative.AS && 
                this.prfqState === PrfqStates.IN_PROGRESS,
            bulkPrfqUpdate: () => representative === Representative.supplier &&
                this.prfqState === PrfqStates.OPENED,
            journal: () => this.sharedService.hasPermission('prfq/*/journal', 'GET') && this.prfqState !== PrfqStates.IN_PROGRESS,
            discussion: () => this.sharedService.hasPermission('prfq', 'GET') && this.prfqState !== PrfqStates.IN_PROGRESS,
            attachments: () => this.sharedService.hasPermission('prfq', 'GET') && this.prfqState !== PrfqStates.IN_PROGRESS
        };

        return (element in available) ? available[element]() : false;
    }

    public isPrfqColumnAvailable(column: string): boolean {
        const availabilityMap = {
            // supplierName: () => this.sharedService.user.availableSuppliers &&
            //     this.sharedService.user.availableSuppliers.length > 1
            supplierName: () => this.sharedService.user.representative === Representative.AS
        };

        return (column in availabilityMap) ? availabilityMap[column]() : true;
    }

    public isPrfqItemColumnAvailable(column: string): boolean {
        const representative = this.sharedService.user.representative;
        const availabilityMap = {
            productSupplier: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            amountOffered: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            unitPriceOffered: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            itemState: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            postsCount: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            dateOfferedDays: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            itemValidityDays: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
            dateCreated: () => this.prfqState !== PrfqStates.IN_PROGRESS || representative !== Representative.AS,
        };

        return (column in availabilityMap) ? availabilityMap[column]() : true;
    }

}
