import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PriceListResponse, PriceListItemsResponse, SavePriceListResponse } from '@app/model/priceList.model';
import { EcmHttpService, EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { switchMap, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';



@Injectable()
export class PriceListService {
    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService
    ) {}

    public getPriceLists(query: EcmHttpQueryParams): Observable<PriceListResponse> {
        return this.http.get('/price-lists', this.http.prepareOptions(query))
            .pipe(map((response: PriceListResponse) => {
                const rows = response.rows.map(row => ({
                    id: row.id,
                    priceListCode: row.priceListCode,
                    currency: row.currency,
                    note: row.note,
                    itemsCount: row.itemsCount,
                    url: '/price-list/' + row.priceListCode + '/' + row.currency
                }));

                return {
                    rows: rows,
                    totalCount: response.totalCount
                }
        }));
    }

    public exportPriceLists(query: EcmHttpQueryParams): Observable<PriceListResponse> {
        return this.http.get('/price-lists', this.http.prepareOptions(query));
    }

    public exportCSV(priceListCode: string, currency: string): void {
        const columns = [
            'priceListCode', 'productCode', 'productName', 'unitPrice', 'currency'];

        const query: EcmHttpQueryParams = {
            exportToFormat: 'CSV',
            exportColumns: columns,
            orderBy: 'productCode ASC',
            'priceListCode.operator': 'eq',
            'priceListCode.value': priceListCode,
            'currency.operator': 'eq',
            'currency.value': currency
        };

        this.http.get('/price-lists-items', this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, { responseType: 'text' }))
        ).subscribe(data => {
            const mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            const blob = new Blob(['\ufeff' + data], { type: mediaType });
            const filename = this.translateService.instant('PRICE_LIST') + '_' + priceListCode + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }

    public deletePriceList(priceListCode: string, currency: string): Observable<void> {
        return this.http.delete(`/price-lists/${priceListCode}/${currency}`);
    }

    public updatePriceLists(params: { priceListCode: string, currency: string, note?: string}[]): Observable<void> {
        return this.http.put('/price-lists', JSON.stringify({ rows: params }));
    }

    public getPriceListItems(query: EcmHttpQueryParams): Observable<PriceListItemsResponse> {
        return this.http.get('/price-lists-items', this.http.prepareOptions(query));
    }

    public exportPriceListsItems(query: EcmHttpQueryParams): Observable<PriceListItemsResponse> {
        return this.http.get('/price-lists-items', this.http.prepareOptions(query));
    }

    public getPriceListDetail(priceListCode: string, currency: string, query: EcmHttpQueryParams): Observable<PriceListItemsResponse> {
        return this.http.get('/price-lists-items',
            this.http.prepareOptions(Object.assign({}, query, {
                'priceListCode.operator': 'eq',
                'priceListCode.value': priceListCode,
                'currency.operator': 'eq',
                'currency.value': currency
            }))
        );
    }

    public exportPriceList(priceListCode: string, currency: string, query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        return this.http.get('/price-lists-items',
            this.http.prepareOptions(Object.assign({}, query, {
                'priceListCode.operator': 'eq',
                'priceListCode.value': priceListCode,
                'currency.operator': 'eq',
                'currency.value': currency
            }))
        );
    }

    public deleteItems(ids: number[]): Observable<void> {
        return this.http.put('/price-lists-items', JSON.stringify({ action: 'DELETE', ids: ids }));
    }

    public deletePriceListItems(priceListCode: string, currency: string, query?: EcmHttpQueryParams): Observable<void> {
        const _query = query === undefined ? {} : query;
        return this.http.put('/price-lists-items',
            JSON.stringify({ action: 'DELETE', priceListCode: priceListCode, currency: currency }),
            this.http.prepareOptions(_query));
    }

    public updateItems(rows: { id: number, unitPrice: number}[]): Observable<void> {
        return this.http.put('/price-lists-items',
            JSON.stringify({ action: 'UPDATE', rows: rows }));
    }

    public priceListExists(priceListCode: string, currency: string): Observable<boolean> {
        return this.http.get('/price-lists', this.http.prepareOptions({
                skip: 0,
                top: 1,
                'priceListCode.operator': 'eq',
                'priceListCode.value': priceListCode,
                'currency.operator': 'eq',
                'currency.value': currency
            }))
            .pipe(map((response: PriceListResponse) => response.totalCount > 0));
    }

    public savePriceList(priceListCode: string, currency: string, note: string,
        rows: {[key: string]: string}[], deleteExisting: boolean): Observable<SavePriceListResponse> {

        return this.http.post('/price-lists', {
                priceListCode,
                currency,
                note,
                rows,
            }, this.http.prepareOptions({ deleteExisting: deleteExisting}));
    }

    public isColumnHidden(colName: string): boolean {
        const _map = {
            'productCode': () => !this.sharedService.params.features.priceLists.productsIdentifiedByProductCode
        };
        return (colName in _map) ? _map[colName]() : false;
    }

}
