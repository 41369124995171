<div class="table-sort">
	<div class="btn-group" dropdown [isOpen]="isOpen" (onShown)="show()" (onHidden)="hide()" [autoClose]="autoClose" placement="bottom right">
		<ng-template #sortTmpl><span translate>COLUMN_SORT</span></ng-template>
		<button #dropdownToggle id="single-button" type="button" class="btn btn-secondary" dropdownToggle [ngClass]="{'ec-disabled': busy}"
			[popover]="sortTmpl" triggers="mouseenter:mouseleave">
            <i class="fa fa-sort-amount-desc"></i><i class="fa fa-caret-down caret"></i>
        </button>
        <div #popover *dropdownMenu role="menu" aria-labelledby="single-button" class="dropdown-menu dropdown-menu-right">
        	<div class="row-container">
	        	<div class="row-container">
	        		<strong translate>COLUMN_SORT</strong>
	        	</div>
				<div class="row-container dropdown-item clickable" 
					[ngClass]="{'hidden': item.hidden || !item.orderDirection || item.doNotShowInTableSettings}" 
					*ngFor="let item of items" (click)="onSelect(item)">

					<input type="radio" [(ngModel)]="orderBy" [value]="item.id"/>&ngsp;<span>{{item.name | translate}}</span>
					<i class="fa" [ngClass]="{'fa-arrow-down': item.orderDirection == 'DESC', 'fa-arrow-up': item.orderDirection == 'ASC'}"
						*ngIf="orderBy == item.id" style="padding-left: 5px;"></i>
	    	    </div>
	    	</div>
        </div>
    </div>
</div>