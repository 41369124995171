import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../services/shared.service';
import { ToastService } from '../../../../services/toastService/toast.service';
import { LanguageService } from '../../../../services/language.service';
import { WindowService } from '../../../../services/window.service';
import { LocalDatePipe } from '../../../../locale.pipes.module';
import { FormService } from '../../../../services/form.service';
import { MyDatePicker, IMyOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';

@Component({
    selector: 'rfq-import-detail-error-dialog',
    templateUrl: 'rfq.import.detail.error.dialog.component.html'
})

export class RfqImportDetailErrorDialogComponent {

    // @ViewChild('mydp') mydp: MyDatePicker;
    @ViewChild('mydp', { read: MyDatePicker }) mydp: ElementRef;

    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;
    element: HTMLElement;
    valid = false;

    // Initialize selector is closed
    private selector: number;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(private cdRef: ChangeDetectorRef,
                public bsModalRef: BsModalRef,
                public translate: TranslateService,
                private sharedService: SharedService,
                private toastService: ToastService,
                private languageService: LanguageService,
                private formService: FormService,
                private windowService: WindowService) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.params.toInsert.errors.forEach(error => {
                error.showDatePickerComponent = false;
                if (isNaN(error.row.unitPriceRequired)) { error.placeholderUnit = error.row.unitPriceRequired; error.row.unitPriceRequired = 0;  }
                if (isNaN(error.row.amountRequired)) { error.placeholderAmount = error.row.amountRequired; error.row.amountRequired = 0; }
                error.row.datePickerOptions = this.getDatePickerOptions(error);
            });
            this.valid = this.isValid(true);
        }, 0);
    }

    getDatePickerOptions(item, index?: number) {
        // min Delivery Date is now + 2 days up to one year. its here because dateRequired is not correct date
      const datePickerOptions: IMyOptions = this.formService.getDatePickerOptions(false, moment(item.row.minDeliveryDate));
      datePickerOptions.selectionTxtFontSize = '98%';
        return datePickerOptions;
    }

    disableOtherDatepickers(currentItem: any, index?: number) {
        this.selector = 0;
        currentItem.showDatePickerComponent = !currentItem.showDatePickerComponent;
        currentItem.placeholderDate = undefined;
        this.params.toInsert.errors.forEach(element => {
            if (currentItem !== element) {
                if (element.row.dateRequiredModel) {
                    element.dateModel = element.row.dateRequiredModel.formatted;
                } else {
                    const momentDate = moment(element.row.dateRequired);
                    element.dateRequiredModel = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }
                }
                element.showDatePickerComponent = false;
            }
        });
        setTimeout(() => {
            const element: HTMLElement = <HTMLElement>document.getElementById('my-dp-id-' + index).children[0].children[0].children[0];
            element.focus();
            this.selector = 1;
            this.cdRef.detectChanges();
        }, 0);
    }

    copyErrorsToClipboard() {
        // caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    copy() {
        const this_ = this;
        let tmpEl;
        tmpEl = document.createElement('textarea');

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = 0;
        tmpEl.style.position = 'fixed';
        tmpEl.style.pointerEvents = 'none';
        tmpEl.style.zIndex = -1;

        let value = '';
        // fill it with your HTML
        this.params.toInsert.errors.forEach(errorItem => {
            value += errorItem.row.rfqProduct + '\t';
            value += errorItem.row.amountRequired + '\t';
            value += errorItem.row.unitPriceRequired + '\t';
            // value += errorItem.dateRequired + '\t';
            value += new LocalDatePipe(this_.languageService).transform(errorItem.dateRequired, this_.sharedService.appSettings.language) + '\t';
            // value += new LocalDatePipe(this_.languageService).transform(errorItem.dateRequired, this_.sharedService.appSettings.language) + '\t';
            for (const key in errorItem.error) {
                 value += this_.translate.instant(errorItem.error[key]) + ' ';
            }
            value += '\n';
        });

        // // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(value))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand('copy');

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    onChange(item, attrChangedName, value) {
        item.row[attrChangedName] = value;
        this.valid = this.isValid(true);
    }

    isValid(addErrors?: boolean): boolean {
        let result = true;
        const findsDot = new RegExp(/[\,\.]/g);                       // regexp to find dot or comma
        // console.log('params.toInsert.errors', this.params.toInsert.errors);
        this.params.toInsert.errors.forEach(error => {
            // set shortname and reset value to undefined if missing
            const amount    = error.row.amountRequired      ? error.row.amountRequired    : undefined;
            const amountErr = error.error.amountRequired    ? error.error.amountRequired  : undefined;
            const product   = error.row.rfqProduct          ? error.row.rfqProduct        : undefined;
            const unit      = error.row.unitPriceRequired   ? error.row.unitPriceRequired : undefined;
            let dateVal     = error.row.dateRequired !== '' ? error.row.dateRequired      : undefined;
            // dateVal     = typeof dateVal === 'undefined' && error.dateRequired  ? error.dateRequired      : undefined;

            // rfqProduct should be shorter as 255 chars
            if (typeof product !== 'undefined' && product.length > 255) {
                result = false;
                if (addErrors) {
                    error.error.rfqProduct = 'INVALID_DATA_TYPE:_RFQPRODUCT_ERROR';
                    error.placeholderProduct = error.row.rfqProduct;
                }
            } else {
                if (addErrors) {
                    delete error.error.rfqProduct;
                }
            }

            // Amount is mandatory, check if it is provided, cant be empty or lower as zero or higher as milion
            if ( (typeof amount === 'undefined') ||
                 (typeof amount !== 'undefined' && amount.length === 0) ||
                 (typeof amount !== 'undefined' && (amount.length > 0))) {
                result = false;
                if ((parseFloat(amount) <= 0 || parseFloat(amount) > 999999)) {
                    if (addErrors) {
                        error.error.amountRequired = 'Invalid data type: amountRequired';
                        // error.error.amountRequired = 'INVALID_DATA_TYPE:_AMOUNTORDERED_ERROR';
                        error.placeholderAmount = error.row.amountRequired;
                    }
                }
            } else {
                if (addErrors) {
                    delete error.error.amountRequired;
                }
            }

            // UnitPrice must be defined and cannt be negative number and higher as milion. Its number from 0,01 to 99999
            if (typeof unit !== 'undefined' && unit.length > 0) {
                if (parseFloat(unit) <= 0 || parseFloat(unit) > 999999 || isNaN(unit) ) {
                    result = false;
                    if (addErrors) {
                        error.error.unitPriceRequired = 'INVALID_DATA_TYPE:_UNITPRICEREQUIRED_ERROR';
                        error.placeholderUnit = error.row.unitPriceRequired;
                    }
                } else {
                    if (addErrors) {
                        delete error.error.unitPriceRequired;
                    }
                }
            } else if (typeof unit === 'undefined') {
                if (addErrors) {
                    delete error.error.unitPriceRequired;
                    delete error.row.unitPriceRequired;
                }
            }

            // && (
            //     (dateVal.match(/\./g) !== null && dateVal.match(/\./g).length === 2) ||
            //     (dateVal.match(/\//g) !== null && dateVal.match(/\//g).length === 2)
            // )

            // required date - validations
            if (typeof dateVal === 'undefined') {
                error.placeholderDate = '';
            } else if (typeof dateVal !== 'undefined' && dateVal.length > 0 && dateVal !== null ) {
                const opt = this.getDatePickerOptions(error);
                // console.log('opt', opt);
                let isValidDate = false;

                // if ((dateVal.match(/\./g) !== null && dateVal.match(/\./g) === 2) ||
                // (dateVal.match(/\//g) !== null && dateVal.match(/\//g) === 2)) {
                if (moment(moment(dateVal).format('DD.MM.YYYY'), 'DD.MM.YYYY', true).isValid()) {
                    dateVal = moment(moment(dateVal).format('DD.MM.YYYY'), 'DD.MM.YYYY');
                    isValidDate = true;
                } else if (moment(moment(dateVal).format('MM/DD/YYYY'), 'MM/DD/YYYY', true).isValid()) {
                    dateVal = moment(moment(dateVal).format('MM/DD/YYYY'), 'MM/DD/YYYY');
                    isValidDate = true;
                }

                // procceed if date is in valid format
                if (isValidDate) {
                    if (addErrors) {
                        error.placeholderDate = error.row.dateRequired ? error.row.dateRequired : error.dateRequired;
                        error.placeholderDate = new LocalDatePipe(this.languageService).transform(error.placeholderDate, this.sharedService.appSettings.language);
                    }

                        // check if its lower or higher as since and until
                    const disableSince = moment(opt.disableSince.day + '.' + opt.disableSince.month + '.' + opt.disableSince.year, 'DD.MM.YYYY');
                    const disableUntil = moment(opt.disableUntil.day + '.' + opt.disableUntil.month + '.' + opt.disableUntil.year, 'DD.MM.YYYY');

                    // Date must be in range, cant be lower as until and higher as since
                    if (dateVal < disableUntil || dateVal > disableSince ) {
                        result = false;
                        if (addErrors) {
                            error.error.dateRequired = 'LESS_THAN_MINIMUM_DELIVERY_DATE_ERROR';
                            error.placeholderDate = new LocalDatePipe(this.languageService).transform(error.row.dateRequired, this.sharedService.appSettings.language);
                        }
                    }

                    // check if its saturday or sunday
                    if (dateVal.weekday() === 6 || dateVal.weekday() === 7) {
                        result = false;
                        if (addErrors) {
                            error.error.dateRequired = 'WEEKEND_OR_HOLIDAY_ERROR';
                            error.placeholderDate = new LocalDatePipe(this.languageService).transform(error.row.dateRequired, this.sharedService.appSettings.language);
                        }
                    }
                    // check if its holiday
                    if (opt.disableDays.filter(holiday => {
                        return dateVal.isSame(moment(holiday.day + '.' + holiday.month + '.' + holiday.year, 'DD.MM.YYYY'))
                    }).length > 0) {
                        result = false;
                        if (addErrors) {
                            error.error.dateRequired = 'WEEKEND_OR_HOLIDAY_ERROR';
                            error.placeholderDate = new LocalDatePipe(this.languageService).transform(error.row.dateRequired, this.sharedService.appSettings.language);
                        }
                    }
                } else {
                    result = false;
                    if (addErrors) {
                        if (typeof error.row.dateRequired !== 'undefined' ) {
                            error.placeholderDate = error.row.dateRequired;
                        } else if (typeof error.dateRequired !== 'undefined' ) {
                            error.placeholderDate = error.dateRequired;
                        } else {
                            error.placeholderDate = '';
                        }
                        error.error.dateRequired = 'INVALID_DATA_TYPE:_DELIVERYDATE_ERROR';
                        // error.placeholderDate = error.row.dateRequired;
                    }
                }
            }
        });
        // console.log(result);
        return result;
    }

    public onDateChanged(event: IMyDateModel, item): void {
        if (event.jsdate) {
            item.row.dateRequired = event.jsdate.toISOString();
            item.row.dateRequiredModel = event.jsdate.toISOString();
            delete item.error.dateRequired;
        }
        if (event.jsdate === null) {
            item.row.dateRequired = undefined;
            item.row.dateRequiredModel = undefined;
            delete item.error.dateRequired;
        }
        this.valid = this.isValid();
    }

    addToBasketAgain() {
        let rows = [];
        this.params.toInsert.errors.forEach(function (error) {
            delete error.row.dateRequiredModel;
            delete error.row.minDeliveryDate;
            delete error.row.datePickerOptions;
            rows.push(error.row);
        });
        this.doneSubject.next({ rows: rows });
        this.bsModalRef.hide();
    }
}
