<div class="input-container" style="margin-top: 10px;">
    <label translate>PUBLISH_STOCK_TITLE</label>
    <div>
        <ng-template #popOtherCustomersTemplate>
            <div translate>PUBLISH_STOCK_FOR_OTHER_CUSTOMERS_HELP</div>
        </ng-template>
        <span class="row-container clickable" (click)="onCustomerStockForOtherCustomersClick()"><i class="fa row-container"
            [ngClass]="{'fa-square-o': !options.customerStockForOtherCustomers, 'fa-check-square-o': options.customerStockForOtherCustomers}"></i><span
            translate>PUBLISH_STOCK_FOR_OTHER_CUSTOMERS</span><i class="fa fa-info-circle clickable"
            [popover]="popOtherCustomersTemplate" container="body" triggers="mouseenter:mouseleave"></i></span>
    </div>
    <div>
        <ng-template #popSubcustomersTemplate>
            <div translate>PUBLISH_STOCK_FOR_OWN_CUSTOMERS_HELP</div>
        </ng-template>
        <span class="row-container clickable" (click)="onCustomerStockForSubcustomersClick()"><i class="fa row-container"
            [ngClass]="{'fa-square-o': !options.customerStockForSubcustomers, 'fa-check-square-o': options.customerStockForSubcustomers}"></i><span
            translate>PUBLISH_STOCK_FOR_OWN_CUSTOMERS</span><i class="fa fa-info-circle clickable"
            [popover]="popSubcustomersTemplate" container="body" triggers="mouseenter:mouseleave"></i></span>
    </div>
</div>
