import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';
import { LanguageService } from '../../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalNumberPipe, LocalDatePipe } from '../../../../locale.pipes.module';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

interface GridJournalAdditionalInfoRendererComponentParams {
}

interface NewAndOldValue {
    rfqProduct?: string;
    rfqProductAS?: string;
    manufacturer?: string;
    productSupplier?: string;
    productShortName?: string;
    amountRequired?: string;
    unitPriceRequired?: number;
    amountOffered?: string;
    unitPriceOffered?: number;
    dateRequired?: string;
    dateOfferedDays?: number;
    dateOffered?: string;
    dateOfferedValidity?: string;
    itemValidity?: string;
    itemType?: string;
    fileName?: string;
}

interface MyParams extends ICellRendererParams, GridJournalAdditionalInfoRendererComponentParams {
    data: {
        oldValue?: NewAndOldValue;
        newValue?: NewAndOldValue;
        rfqId?: number;
        entityName?: string;
        entityId?: any;
        discussionText?: string;
    }
}

@Component({
    selector: 'additional-info-cell',
    template: `
        <span *ngIf="updates.length > 0"><span translate>UPDATES</span>:</span>&nbsp;
        <span *ngFor="let update of updates; let last = last;">
            <strong translate>{{update.attrName}}</strong>&ngsp;<span translate>UPDATED_FROM</span>&ngsp;<span>"{{update.old}}"</span>&ngsp;<span translate>UPDATED_TO</span>&ngsp;<span>"{{update.new}}"</span><span *ngIf="!last">, </span>
        </span>
        <span *ngFor="let update of inserts; let last = last;">
            <strong><span translate>{{update.attrName}}</span>:</strong>&ngsp;<span>"{{update.new}}"</span><span *ngIf="!last">, </span>
        </span>

        <span *ngIf="params.data.newValue && params.data.newValue.fileName"><span translate>FILE_NAME</span>: "{{params.data.newValue.fileName}}"</span>

        <a *ngIf="params.data.entityName === 'purchaseOrder' && params.data.entityId" [routerLink]="['/' + sharedService.appSettings.language + '/order/' + params.data.entityId]" (click)="setBackUrl()" translate>GO_TO_PURCHASE_ORDER</a>

        <span *ngIf="params.data.discussionText"><span translate>COMMENT_TEXT</span>: "{{params.data.discussionText}}"</span>
    `
    // template: `<span>{{params.value == 0 && params.hideZeroValue ? '' : params.value | localnumber:sharedService.appSettings.language:params.addDecimalZero:params.decimals}}</span>`
})
export class GridJournalAdditionalInfoRendererComponent implements AgRendererComponent {
    public params: MyParams;

    public updates: { attrName: string, old: string, new: string }[] = []; // array of updates to display for attributes that were updated old value / new value
    public inserts: {attrName: string, old: string, new: string}[] = []; // array of updates to display for attributes that were inserted new value

    constructor(
        public sharedService: SharedService,
        private languageService: LanguageService,
        private translate: TranslateService) {

    }

    agInit(params: MyParams):void {
        this.params = params;
        this.getUpdates();
        this.getInserts();
    }

    /**
     * Sets items of updates array from params.data.oldValue and params.data.newValue
     */
    getUpdates() {
        if (this.params.data.newValue && this.params.data.oldValue) {
            this.setNewValuesArray(this.updates);
        }
    }
    setBackUrl() {
        this.sharedService.backUrl = 'rfq/' + this.params.data.rfqId;
    }
    /**
     * Sets items of updates array from params.data.oldValue and params.data.newValue
     */
    getInserts() {
        if (this.params.data.newValue && !this.params.data.oldValue) {
            this.setNewValuesArray(this.inserts);
        }
    }

    private setNewValuesArray(array: { attrName: string, old: string, new: string }[]): void {
        const oldValue = this.params.data.oldValue ? this.params.data.oldValue : {};
        const newValue = this.params.data.newValue;
        
        if (newValue.rfqProduct || oldValue.rfqProduct) {
            array.push({
                attrName: 'PRODUCT_ID', old: oldValue.rfqProduct, new: newValue.rfqProduct
            });
        }
        if (newValue.rfqProductAS || oldValue.rfqProductAS) {
            array.push({
                attrName: 'PRODUCT_ID_AS', old: oldValue.rfqProductAS, new: newValue.rfqProductAS
            });
        }
        if (newValue.manufacturer || oldValue.manufacturer) {
            array.push({
                attrName: 'MANUFACTURER', old: oldValue.manufacturer, new: newValue.manufacturer
            });
        }
        if (newValue.productSupplier || oldValue.productSupplier) {
            array.push({
                attrName: 'PRODUCT_ID_SUPPLIER', old: oldValue.productSupplier, new: newValue.productSupplier
            });
        }
        if (newValue.productShortName || oldValue.productShortName) {
            array.push({
                attrName: 'SHORT_NAME', old: oldValue.productShortName, new: newValue.productShortName
            });
        }
        if (newValue.amountRequired || oldValue.amountRequired) {
            array.push({
                attrName: 'REQUIRED_AMOUNT', old: oldValue.amountRequired, new: newValue.amountRequired
            });
        }
        if (newValue.unitPriceRequired || oldValue.unitPriceRequired) {
            array.push({
                attrName: 'REQUIRED_PRICE',
                old: new LocalNumberPipe(this.languageService).transform(oldValue.unitPriceRequired, this.sharedService.appSettings.language),
                new: new LocalNumberPipe(this.languageService).transform(newValue.unitPriceRequired, this.sharedService.appSettings.language)
            });
        }
        if (newValue.amountOffered || oldValue.amountOffered) {
            array.push({
                attrName: 'OFFERED_AMOUNT', old: oldValue.amountOffered, new: newValue.amountOffered
            });
        }
        if (newValue.unitPriceOffered || oldValue.unitPriceOffered) {
            array.push({
                attrName: 'OFFERED_PRICE',
                old: new LocalNumberPipe(this.languageService).transform(oldValue.unitPriceOffered, this.sharedService.appSettings.language),
                new: new LocalNumberPipe(this.languageService).transform(newValue.unitPriceOffered, this.sharedService.appSettings.language)
            });
        }
        if (newValue.dateRequired || oldValue.dateRequired) {
            array.push({
                attrName: 'REQUIRED_DATE',
                old: new LocalDatePipe(this.languageService).transform(oldValue.dateRequired, this.sharedService.appSettings.language),
                new: new LocalDatePipe(this.languageService).transform(newValue.dateRequired, this.sharedService.appSettings.language)
            });
        }
        if (newValue.dateOfferedDays || oldValue.dateOfferedDays) {
            array.push({
                attrName: 'OFFERED_DATE_DAYS',
                old: oldValue.dateOfferedDays ? 'D + ' + oldValue.dateOfferedDays : '',
                new: newValue.dateOfferedDays ? 'D + ' + newValue.dateOfferedDays : ''
            });
        }
        if (newValue.dateOffered || oldValue.dateOffered) {
            array.push({
                attrName: 'OFFERED_DATE',
                old: new LocalDatePipe(this.languageService).transform(oldValue.dateOffered, this.sharedService.appSettings.language),
                new: new LocalDatePipe(this.languageService).transform(newValue.dateOffered, this.sharedService.appSettings.language)
            });
        }
        if (newValue.dateOfferedValidity || oldValue.dateOfferedValidity) {
            array.push({
                attrName: 'OFFERED_DATE_VALIDITY',
                old: new LocalDatePipe(this.languageService).transform(oldValue.dateOfferedValidity, this.sharedService.appSettings.language),
                new: new LocalDatePipe(this.languageService).transform(newValue.dateOfferedValidity, this.sharedService.appSettings.language)
            });
        }
        if (newValue.itemValidity || oldValue.itemValidity) {
            array.push({
                attrName: 'RFQ_ITEM_VALIDITY',
                old: new LocalDatePipe(this.languageService).transform(oldValue.itemValidity, this.sharedService.appSettings.language),
                new: new LocalDatePipe(this.languageService).transform(newValue.itemValidity, this.sharedService.appSettings.language)
            });
        }
        if (newValue.itemType || oldValue.itemType) {
            array.push({
                attrName: 'RFQ_ITEM_TYPE',
                old: oldValue.itemType && oldValue.itemType !== null ? this.translate.instant('RFQ_ITEM_TYPE_' + oldValue.itemType) : '',
                new: newValue.itemType && newValue.itemType !== null ? this.translate.instant('RFQ_ITEM_TYPE_' + newValue.itemType) : ''
            });
        }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}