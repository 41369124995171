<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container tab-active" style="margin-right: 5px;">
                <span translate>RFQS_SUMMARY</span>
            </span>
            <span class="row-container clickable"
                routerLink="/{{sharedService.appSettings.language}}/prfqs-items">
                <span translate>RFQS_ITEMS</span>
            </span>
        </div>
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" *ngIf="canCreatePrfq">
            <button class="btn-primary" (click)="createPrfq()" translate>RFQ_CREATE</button>
        </div>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getPurchaseRfqs"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [selection]="gridService.getSelection()"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>