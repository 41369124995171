import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { SendByEmailDialogComponent } from './send-by-email-component/send-by-email.dialog.component';
import { SendByEmailDialogResult } from './send-by-email-component/send-by-email.dialog.model';

@Injectable()
export class SendByEmailDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) {}

    public openDialog(): Observable<SendByEmailDialogResult> {
        this.bsModalRef = this.modalService.show(
            SendByEmailDialogComponent,
            { keyboard: false, ignoreBackdropClick: true }
        );
        this.bsModalRef.content.doneSubject = new Subject<SendByEmailDialogResult>();

        return this.bsModalRef.content.doneSubject;
    }
}
