import { Observable ,  Subject } from 'rxjs';
import { AddAsUserDialogComponent } from './add.as.user.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class AddAsUserDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: any): Observable<any> {
        this.bsModalRef = this.modalService.show(AddAsUserDialogComponent, { keyboard: false, ignoreBackdropClick: true, class: 'modal-md' });
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
