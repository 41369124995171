import { Component, OnChanges, DoCheck } from '@angular/core';
import { IHeaderAngularComp } from "ag-grid-angular";
import { Subject, Observable } from 'rxjs';
// import { RfqsService } from '../../../../services/rfq/rfqs.service';
import { ToastService } from './../../../../services/toastService/toast.service';
import { SharedService } from './../../../../services/shared.service';
import { IHeaderParams } from 'ag-grid-community';
import { EditedIdsObject } from '@app/model/table.model';

export interface GridHeaderActionsRendererComponentParams {
    editedIdsObj: EditedIdsObject;
    isRowValid: (paramsData: any) => boolean;
    save: (rowsData: any[]) => Observable<any>;
    cancel$?: Subject<any>;
    reload$?: Subject<any>;
    // deprecated
    cancelObservable?: Subject<any>;
}

interface MyParams extends IHeaderParams, GridHeaderActionsRendererComponentParams {}

@Component({
    selector: 'actions-renderer',
    template: ` <div class="ag-header-group-cell-label">
                    <span *ngIf="!showSave && !showCancel">{{params.displayName}}</span>
                    <div style="font-size: 20px; margin-top: -2px;" *ngIf="showSave || showCancel"> 
                        <i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" *ngIf="showSave"></i>
                        <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel()" *ngIf="showCancel"></i>
                    </div>
                </div>`
})
export class GridHeaderActionsRendererComponent implements IHeaderAngularComp, DoCheck {
    // isRowValid
    public params: MyParams;
    // private editedIdsObj: any;
    // private subjectReloadCurrent: Subject<any>;

    public showSave: boolean;
    public showCancel: boolean;

    constructor(
        // private rfqsService: RfqsService,
        private toastService: ToastService,
        private sharedService: SharedService
    ) {
        // this.subjectReloadCurrent = rfqsService.getReloadCurrentSubject();
    }

    agInit(params: MyParams): void {
        this.params = params;
    }

    // ngDoCheck() {
    //     // console.log('GridHeaderActionsRendererComponent ngDoCheck');
    //     let allRowsValid = true;
    //     if (this.params.isRowValid) {
    //         allRowsValid = Object.keys(this.params.editedIdsObj).length > 1;
    //         this.params.api.forEachNode(node => {
    //             console.log('GridHeaderActionsRendererComponent ngDoCheck forEachNode');
    //             if (node.data.edited && !this.params.isRowValid(node.data)) {
    //                 allRowsValid = false;
    //             }
    //         });
    //         this.showSave = allRowsValid;
    //     } else {
    //         this.showSave = Object.keys(this.params.editedIdsObj).length > 1;
    //     }
    //     this.showCancel = allRowsValid && Object.keys(this.params.editedIdsObj).length > 1;
    // }

    ngDoCheck() {
        // console.log('GridHeaderActionsRendererComponent ngDoCheck', this.params.editedIdsObj);
        const editedIds = Object.keys(this.params.editedIdsObj);
        let allRowsValid = true;
        if (this.params.isRowValid && editedIds.length > 1) {
            allRowsValid = editedIds.length > 1;
            this.params.api.forEachNode(node => {
                if (node.data.edited && !this.params.isRowValid(node.data)) {
                    allRowsValid = false;
                }
            });
            this.showSave = allRowsValid;
        } else {
            this.showSave = editedIds.length > 1;
        }
        this.showCancel = allRowsValid && editedIds.length > 1;
    }

    public save(): void {
        const data: any = [];
        this.params.api.forEachNode(node => {
            if (this.params.editedIdsObj[node.data.id] || node.data.id <= 0) { data.push(node.data); }
        })
            // this.params.cancelObservable.next();
            // this.subjectReloadCurrent.next();
            // console.log('params.data', data);

        this.params.save(data).subscribe((res) => {
            if (res.errors && res.errors.length > 0) {
                res.errors.forEach(element => {
                    this.toastService.addError(res.errors.length + ' ' + this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS') + ' ' + (res.successCount + res.errors.length) + ' ' + this.sharedService.translateService.instant('ITEMS_OF_X_ITEMS_CANNOT_BE_UPDATED'));
                });
            }
            this.showSave = false;
            this.showCancel = false;

            const editedCount = this.getEditedCount();
            data.forEach(row => {
                row.originalValues.forEach(item => {
                    item.value = row[item.colId];
                });
                row.edited = false;
                delete this.params.editedIdsObj[row.id];
            });

            if (editedCount > 0) {
                this.params.api.redrawRows();
            } else {
                if (this.params.cancelObservable) {
                    this.params.cancelObservable.next();
                }
                if (this.params.cancel$) {
                    this.params.cancel$.next();
                }
            }
            // if (this.subjectReloadCurrent) {
            //     this.subjectReloadCurrent.next();
            // }
            if (this.params.reload$) {
                this.params.reload$.next();
            }

        });
    }

    /**
     * Returns only attributes, which are different from originalValues
     * originalValues: [
            {colId: "rfqProduct", value: <original value>},
            {colId: "amountRequired", value: <original value>}
            ...
        ]
     */
    // getChangedData(data) {
    //     return data.originalValues.reduce((acc, row) => data[row.colId] === row.value 
    //         ? acc : Object.assign({}, acc, { [row.colId]: data[row.colId] }), 
    //         {});
    // }

    public cancel(): void {
        if (this.params.cancelObservable) {
            this.params.cancelObservable.next();
        }
        if (this.params.cancel$) {
            this.params.cancel$.next();
        }

        this.params.api.redrawRows();
    }

    // isNewRow() {
    //     return this.params.data.id < 0;  // new items have negative id
    // }

    getEditedCount() {
        return Object.keys(this.params.editedIdsObj).length;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }




    // private eGui: any;
    // private saveButton: any;
    // private cancelButton: any;

    // constructor() {

    // }

    // agInit(params: any): void {
    // }

    // init(params: any): void {

    //     var this_ = this;
    //     this.params = params;
    //     this.editedIdsObj = this.params.editedIdsObj;
    //     console.log(this.params);


    //     this.eGui = document.createElement('div');
    //     this.eGui.className = 'ag-header-group-cell-label';

    //     this.setInnerHtml();


    //     this.eGui.addEventListener('click', function (event) {
    //         if (this_.params.editedIdsObj && Object.keys(this_.params.editedIdsObj).length > 0) {
    //             return;
    //         }

    //         if (!this_.params.selection.all && !this_.params.selection.visible) {
    //             this_.params.selection.visible = true;
    //             this_.setInnerHtml();
    //             this_.params.observable.next(this_.params.selection.visible);
    //         } else {
    //             this_.params.selection.all = false;
    //             this_.params.selection.visible = false;
    //             this_.params.selection.visible = false;
    //             this_.setInnerHtml();
    //             this_.params.observable.next(true);
    //         }
    //     });

    //     this.params.observable.subscribe(function (res) {
    //         this_.setInnerHtml();
    //     });
    // }

    // getGui() {
    //     return this.eGui;
    // }

    // setInnerHtml() {
    //     let html;
    //     if (Object.keys(this.params.editedIdsObj).length === 0) {
    //         html = this.params.displayName;
    //         this.eGui.innerHTML = html;
    //     } else {
    //         html = `<i class="clickable fa fa-check fnt-green" style="margin-right: 5px; opacity: 0.9" (click)="save()" 
    //                         *ngIf="params.data.edited && this.params.isRowValid && this.params.isRowValid(params.data)"></i>
    //                     <i class="clickable fa fa-times fnt-orange" style="margin-right: 5px; opacity: 0.9" (click)="cancel()" 
    //                         *ngIf="params.data.edited"></i>`;
    //         this.eGui.innerHTML = html;
            
    //         this.saveButton = this.eGui.querySelector(".fa-check");
    //         this.cancelButton = this.eGui.querySelector(".fa-times");

    //         this.saveButton.addEventListener('click', () => {
    //             console.log('save');
    //         });
    //         this.cancelButton.addEventListener('click', () => {
    //             console.log('cancel');
    //         });
    //     }
    // }

    // destroy(): void {

    // }
}