import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { validateBasis } from '@angular/flex-layout';
import { SharedService } from '../../../../services/shared.service';

@Component({
    selector: 'rfq-detail-split-dialog',
    template: `
        <div class="rfq-detail-split-dialog row-container wider" *ngIf="params">
            <h4><span translate>{{params.message}}</span></h4>
            <div style="margin-bottom:10px"><span translate>{{params.question}}</span>{{params.suppressQuestionMark ? '' : '?'}}</div>

            <div class="split-content">
                <div fxLayout="row" class="row-container bigger">
                    <div fxFlex="30"></div>
                    <div fxFlex="30" style="font-weight: bold; text-align: center;"><span translate>PRODUCT_ID_AS</span></div>
                    <div fxFlex="15" style="font-weight: bold; text-align: center;"><span translate>REQUIRED_AMOUNT</span></div>
                    <div fxFlex="25" style="font-weight: bold; text-align: center;"><span translate>REQUIRED_DATE</span></div>
                </div>
                <div fxLayout="row" class="row-container bigger">
                    <div fxFlex="30"><span translate>SPLIT_CURRENT_RFQ_ITEM</span></div>
                    <div fxFlex="30" style="text-align: center;">{{ this.params.currentData.rfqProductAS }}</div>
                    <div fxFlex="15" style="text-align: center; padding: 0 2px;">
                        <number-input name="number-input"
                            [ngModel]="params.currentData.amountRequired"
                            (ngModelChange)="params.currentData.amountRequired=$event;"
                            [disabled]="true"
                            ></number-input>
                    </div>
                    <div fxFlex="25" style="text-align: center;">
                        {{ this.params.currentData.dateRequired | localdate:sharedService.appSettings.language }}
                    </div>
                </div>
                <div fxLayout="row" class="row-container bigger">
                    <div fxFlex="30"><span translate>SPLIT_ORIGINAL_RFQ_ITEM</span></div>
                    <div fxFlex="30" style="text-align: center;">{{ this.params.currentData.rfqProductAS }}</div>
                    <div fxFlex="15" style="text-align: center; padding: 0 2px;">

                        <number-input name="number-input"
                            [ngModel]="textValueNew"
                            (ngModelChange)="textValueNew=$event; onNewChanged(textValueNew);"
                            [inputType]="'number'"
                            [focusOnInit]="true"
                            [isValid]="isNewValid"
                            ></number-input>
                    </div>
                    <div fxFlex="25" style="text-align: center;">
                        {{ this.params.currentData.dateRequired | localdate:sharedService.appSettings.language }}
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="row-container bigger">
                <div fxFlex="50">
                    <button class="btn-secondary" (click)="dismiss()" style="width: 100px;" *ngIf="params.secondary">
                          <span class translate>{{params.secondary}}</span>
                    </button>
                </div>
                <div fxFlex="50" fxLayoutAlign="end">
                    <button class="btn-primary" (click)="confirm()" style="min-width: 100px;" [disabled]="confirmDisabled" [ngClass]="{'ec-disabled': confirmDisabled}">
                          <span class translate>{{params.primary}}</span>
                    </button>
                </div>
            </div>
        </div>
    `,


    //                     < input style = "margin-top:-8px; text-align: right;" type = "text" #newbox(keyup) = "onNewKey(newbox.value)"[value] = "textValueNew"
    // [ngStyle] = "{'background-color': isValidNew? 'unset':'rgba(255, 84, 98, 0.5)'}" class= "form-control" />
})
export class RfqDetailSplitDialogComponent {
    doneSubject: Subject<any>;
    params: any;
    textValueNew: any;
    textValueOld: any;
    confirmDisabled: boolean;
    isValidNew: boolean;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next();
            this.bsModalRef.hide();
        }
    }

    constructor(public bsModalRef: BsModalRef, public sharedService: SharedService) {
        this.isNewValid = this.isNewValid.bind(this);
    }

    ngOnInit(params) {
        this.confirmDisabled = true;
        this.isValidNew = true;
        this.textValueNew = null;

        setTimeout(() => {
            this.textValueOld = this.params.currentData.amountRequired;
        }, 0);
    }

    confirm() {
        this.confirmDisabled = true;
        this.doneSubject.next({res: 'confirm', textValueNew: this.textValueNew});
        this.bsModalRef.hide();
    }

    dismiss() {
        this.doneSubject.next('dismiss');
        this.bsModalRef.hide();
    }
    onNewChanged(val: string) {
        this.textValueNew = val;
        setTimeout(() => {
            this.validate();
        }, 0);
    }
    validate() {
        switch (true) {
            case ( !this.isNormalInteger(this.textValueNew) ): // value is not a integer, isnt valid
                this.confirmDisabled = true;
                this.isValidNew = false;
                this.params.currentData.amountRequired = this.textValueOld;
            break;
            case (parseInt(this.textValueNew, 10) > 0 && parseInt(this.textValueNew, 10) < this.textValueOld): // valid
                this.confirmDisabled = false;
                this.isValidNew = true;
                this.calculate();
            break;
            default:
                this.confirmDisabled = true;
                this.isValidNew = false;
                this.params.currentData.amountRequired = this.textValueOld;
            break;
        }
    }
    isNormalInteger(str) {
        return /^\+?(0|[1-9]\d*)$/.test(str);
    }
    calculate() {
        // this.textValueOld = this.params.currentData.amountRequired - this.textValueNew;
        const old = this.textValueOld;
        this.params.currentData.amountRequired = old - this.textValueNew;
    }

    isNewValid() {
        return this.isValidNew;
    }
}
