<div class="filter table-filter" fxLayout="column" fxLayout.gt-sm="row">

    <div fxFlex.gt-sm="150px" *ngIf="brands.length > 1">    
        <div class="input-container">
            <label translate>BRAND</label>
            <div class= "multiselect-filter" [ngClass]="{'active': brandsActive}">
                <dropdown-component attributeName="brands" [items]="brands"
                    [multiselect]="true" [autoClose]="false"
                    multiselectAllLabel="CATALOGUE_ALL" 
                    (onSelect)="onSelectBrand($event)"
                ></dropdown-component>
            </div>
        </div>
    </div>

    <div fxFlex.gt-sm="250px">    
        <div class="input-container cancelable text-filter" [ngClass]="{'active': eCommProductId.length > 0}">
            <label translate>PRODUCT_ID</label>
            <div class="product-filter" *ngIf="textOperators">
                <dropdown-component [attributeName]="'selectedECommProductIdOperator'" 
                    [items]="textOperators" 
                    [selected]="selectedECommProductIdOperator" 
                    (onSelect)="onSelectFilterOperator($event)"
                ></dropdown-component>
                <input type="text" 
                    tabindex="1" 
                    class="form-control"
                    maxlength="255" 
                    id="input-eCommProductId"
                    [(ngModel)]="eCommProductId" 
                    (ngModelChange)="searchChange($event)"
                    >
                <span class="cancel clickable" *ngIf="eCommProductId.length > 0" (click)="eCommProductId = ''; searchChange('')"><i class="fa fa-times"></i></span>
            </div>
        </div>
    </div>

    <div fxFlex.gt-sm="250px" *ngIf="!catalogueService.isColumnHidden('shortName', catalogueMode)">    
        <div class="input-container cancelable text-filter" [ngClass]="{'active': shortName.length > 0}">
            <label translate>SHORT_NAME</label>
            <div class="product-filter" *ngIf="textOperators">
                <dropdown-component [attributeName]="'selectedShortNameOperator'" 
                    [items]="textOperators" 
                    [selected]="selectedShortNameOperator" 
                    (onSelect)="onSelectFilterOperator($event)"
                ></dropdown-component>
                <input type="text" 
                    tabindex="2" 
                    class="form-control"
                    maxlength="30" 
                    [(ngModel)]="shortName" 
                    (ngModelChange)="searchChangeShortName($event)"
                >
                <span class="cancel clickable" *ngIf="shortName.length > 0" (click)="shortName = ''; searchChange('')"><i class="fa fa-times"></i></span>
            </div>
        </div>
    </div>

    <div fxFlex.gt-sm="100">
        <form (ngSubmit)="searchClick()" #filterForm="ngForm">
            <div class="input-container smaller cancelable text-filter" [ngClass]="{'active': dim1Active}">
                <label>d (mm)</label>
                <dropdown-component attributeName="selectedDim1" [items]="operators" [selected]="selectedDim1" (onSelect)="onSelectFilterOperator($event)"></dropdown-component>
                <input type="text" tabindex="3"  class="form-control" name="dim1" pattern="^[0-9]{1,10}((.|,)[0-9]{1,4})?$" [(ngModel)]="dim1" (ngModelChange)="searchCriteriaOnChange()" (keypress)="inputKeyPress($event, 'dim1')" (keydown)="onKeyDownNumber($event)" maxlength="7">
                <span class="cancel clickable" *ngIf="dim1.length > 0" (click)="dim1 = ''; searchCriteriaOnChange(); searchClick();"><i class="fa fa-times"></i></span>
            </div>    
            <div class="input-container smaller cancelable text-filter" [ngClass]="{'active': dim3Active}">
                <label>D (mm)</label>
                <dropdown-component attributeName="selectedDim3" [items]="operators" [selected]="selectedDim3" (onSelect)="onSelectFilterOperator($event)"></dropdown-component>
                <input type="text" tabindex="4"  class="form-control" name="dim3" pattern="^[0-9]{1,10}((.|,)[0-9]{1,4})?$" [(ngModel)]="dim3" (ngModelChange)="searchCriteriaOnChange()" (keypress)="inputKeyPress($event, 'dim3')" (keydown)="onKeyDownNumber($event)" maxlength="7">
                <span class="cancel clickable" *ngIf="dim3.length > 0" (click)="dim3 = ''; searchCriteriaOnChange(); searchClick();"><i class="fa fa-times"></i></span>
            </div>
            <div class="input-container smaller cancelable text-filter" [ngClass]="{'active': dim2Active}">
                <label>B (mm)</label>
                <dropdown-component attributeName="selectedDim2" [items]="operators" [selected]="selectedDim2" (onSelect)="onSelectFilterOperator($event)"></dropdown-component>
                <input type="text" tabindex="5"  class="form-control" name="dim2" pattern="^[0-9]{1,10}((.|,)[0-9]{1,4})?$" [(ngModel)]="dim2" (ngModelChange)="searchCriteriaOnChange()" (keypress)="inputKeyPress($event, 'dim2')" (keydown)="onKeyDownNumber($event)" maxlength="7">
                <span class="cancel clickable" *ngIf="dim2.length > 0" (click)="dim2 = ''; searchCriteriaOnChange(); searchClick();"><i class="fa fa-times"></i></span>
            </div>

            <div class="input-container smaller text-filter clickable"
                *ngIf="sharedService.params?.features?.catalogue?.showNewProducts"
                (click)="newProductsClicked()"
            >
                <span><i class="fa row-container" [ngClass]="{'fa-square-o': !showNewProducts, 'fa-check-square-o': showNewProducts}"></i><span translate>NEW_PRODUCTS</span></span>
            </div>

		    <div class="form-buttons">
                <button class="btn-primary" tabindex="6" type="submit" [disabled]="!filterForm.form.valid || !searchCriteriaChanged || busy" 
                    [ngClass]="{'ec-disabled': !filterForm.form.valid || !searchCriteriaChanged || busy}"
                    style="margin-right: 12px"
                    id="button-filter">
                    <i class="fa fa-chevron-circle-right"></i>
                    <a translate>FILTER</a>
                </button>
                <button class="btn-secondary" (click)="onReset()" 
                    [disabled]="(eCommProductId.length < 1 && shortName.length < 1 && !dim1Active && !dim2Active && !dim3Active &&!showNewProducts && !brandsActive) || busy"
                    [ngClass]="{'ec-disabled': (eCommProductId.length < 1 && shortName.length < 1 && !dim1Active && !dim2Active && !dim3Active &&!showNewProducts && !brandsActive) || busy}"
                    id="button-filter-reset">
                    <i class="fa fa-times-circle-o"></i>
                    <span translate>FILTER_RESET</span>
                </button>
            </div>
        </form>
    </div>
</div> 
<div class="search-help row-container" *ngIf="breadCrumbs && (currentQueryParams['eCommProductId.value'] || currentQueryParams['shortName.value'] || currentQueryParams['dimensionID.value'] || currentQueryParams['dimensionB.value'] || currentQueryParams['dimensionOD.value']) && breadCrumbs[1] && breadCrumbs[1].id != 'all'">
    <div>
        <span translate>SEARCHING_IN</span>&ngsp;
        <span *ngFor="let crumb of breadCrumbs; let isLast = last; let isFirst = first">
            <span *ngIf="!isFirst">{{crumb.name}}<span *ngIf="isLast">.</span> <span *ngIf="!isLast">&ngsp;/&ngsp;</span></span>
        </span>
    </div>
    <div>
        <span translate>SEARCH_IN</span>&ngsp;<strong><a (click)="searchInAll()" translate>WHOLE_CATALOGUE</a></strong>.
    </div>
</div>