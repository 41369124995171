<div class="users-admin row-container wider">
    <app-user-role-select
        permissionHide areaPerm="repreOverrideUserAdmin"
        [repreOverrideArea]="repreOverrideAreaUserAdmin"
        [createCustomerIfNotFound]="false"
        (onRoleChange)="onRoleChange()"
    ></app-user-role-select>

    <div class="area-title" fxLayout="column" fxLayout.gt-sm="row">
        <div fxFlex.gt-sm="50">
            <h3><i class="fa fa-users"></i><span translate>USERS_ADMINISTRATION</span></h3>
            <button class="btn-primary" (click)="newUserVisible = true" [disabled]="newUserVisible" [ngClass]="{'ec-disabled': newUserVisible}">
                <i class="fa fa-user-plus"></i>
                <span translate>ADD</span>
            </button>
        </div>
        <div fxFlex.gt-sm="50" fxLayoutAlign="end">
            <div class="input-container">
                <span class="row-container clickable checkbox" (click)="onDisabledToggle()"><i class="fa row-container" [ngClass]="{'fa-square-o': !viewDisabled, 'fa-check-square-o': viewDisabled}"></i><span translate>USERS_SHOW_DISABLED</span></span>
            </div>
        </div>
    </div>

    <div class="users">
        <div class="new row-container bigger" fxLayout="row" *ngIf="newUserVisible">
            <form fxFlex="30" #userForm="ngForm">
                <div class="input-container">
                    <label translate>EMAIL</label>
                    <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="newUser.email" class="form-control" id="email" name="email" #emailFormItem="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
                </div>
                <div [hidden]="emailFormItem.valid || emailFormItem.pristine" class="alert alert-danger">
                    <span translate>WRONG_EMAIL_FORMAT</span>
                </div>
                <div class="input-container">
                    <label translate>LANGUAGE</label>
                    <div>
                        <dropdown-component [items]="sharedService.languages" [selected]="newUser.language" (onSelect)="onSelectLanguageNewUser($event)" [autoClose]="true" [disableSelectedValueInButton]="false"></dropdown-component>
                    </div>
                </div>
                <app-subcustomer *ngIf="subcustomersAvailable"
                    [subcustomer]="newUser.subcustomer"
                    [isSubcustomer]="newUser.isSubcustomer"
                    [showButtons]="false"
                    (onStateAboutToChange)="onNewUserSubcustomerStateAboutToChange($event)"
                    (onSubcustomerChanged)="onNewUserSubcustomerChanged($event)"
                ></app-subcustomer>
            </form>
            <div fxFlex="70">
                <div>
                    <h4><i class="fa fa-lock"></i> <span translate>SECURITY_GROUP_ADMINISTRATION</span></h4>
                </div>

                <div>
                    <div class="tag fnt-smaller" *ngFor="let group of newUser.groups" translate>
                        {{'SECURITY_GROUP_' + group}}
                        <i class="fa fa-times-circle clickable" (click)="removeFromGroup(newUser, group)"></i>
                    </div>
                </div>
                <multiselect-component
                    [items]="newUser.availableGroupsForDropdown" 
                    [justButtonLabel]="'ADD_TO_SECURITY_GROUP'"
                    [disableSelectionOnSearch]="true"
                    [multiselectAllLabel]="'CATALOGUE_ALL'"
                    [multiselectFoundLabel]="'USER_ADMIN_PERMISSIONS_FOUND'"
                    [multiselect]="true" 
                    [search]="true"
                    [autoClose]="false"
                    (onMultipleSelect)="onGroupsChanged($event, newUser)" 
                    [autoClose]="false"
                    [disableSelectedValueInButton]="true"
                ></multiselect-component>
                <button class="btn-secondary" style="margin-left: 5px;"
                    (click)="removeAllGroups(newUser)"
                    [disabled]="busy" [ngClass]="{'ec-disabled': busy}"
                    *ngIf="newUser.groups && newUser.groups.length > 0">
                    <!-- <i class="fa fa-check-square-o"></i> -->
                    <span translate>PER_NOTIF_REMOVE_ALL</span>
                </button>
                <button class="btn-secondary" style="margin-left: 5px;"
                    (click)="addAsUserGroups(newUser)"
                    [disabled]="busy" [ngClass]="{'ec-disabled': busy}">
                    <!-- <i class="fa fa-check-square-o"></i> -->
                    <span translate>PER_NOTIF_AS_USER</span>
                </button>

                <div style="margin-top: 20px;">
                    <div>
                        <h4><i class="fa fa-envelope"></i> <span translate>NOTIFICATION_ROLE_ADMINISTRATION</span></h4>
                    </div>
                    <div>
                        <div class="tag fnt-smaller" *ngFor="let notifRole of newUser.notificationRoles">
                            <ng-template #popTemplate>
                                <span translate>NOTIFICATION_RULES_INFO</span>:
                                <div *ngFor="let rule of roleRuleMap[notifRole.id].rules"><span translate>{{'NOTIFICATION_RULE_' + rule}}</span></div>
                                <span *ngIf="roleRuleMap[notifRole.id].rules.length === 0" translate>EMPTY_TABLE</span>
                            </ng-template>
                            <span [popover]="popTemplate" triggers="mouseenter:mouseleave" translate>{{'NOTIFICATION_RULE_' + notifRole.name}}</span>
                            <i class="fa fa-times-circle clickable" (click)="removeFromNotifRole(newUser, notifRole)"></i>
                        </div>
                    </div>
                    <multiselect-component
                        [items]="newUser.availableNotificationRolesForDropdown" 
                        [justButtonLabel]="'ADD_TO_NOTIFICATION_ROLE'"
                        [disableSelectionOnSearch]="true"
                        [multiselectAllLabel]="'CATALOGUE_ALL'"
                        [multiselectFoundLabel]="'USER_ADMIN_NOTIF_FOUND'"
                        prefix="NOTIFICATION_RULE_" 
                        [multiselect]="true"
                        [search]="true"
                        [autoClose]="false"
                        (onMultipleSelect)="onNotificationRolesChanged($event, newUser)" 
                        [autoClose]="false"
                        [disableSelectedValueInButton]="true"
                    ></multiselect-component>
                    <button class="btn-secondary" style="margin-left: 5px;" (click)="removeAllNotificationRoles(newUser)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}"
                        *ngIf="newUser.notificationRoles && newUser.notificationRoles.length > 0">
                        <!-- <i class="fa fa-check-square-o"></i> -->
                        <span translate>PER_NOTIF_REMOVE_ALL</span>
                    </button>
                    <button class="btn-secondary" style="margin-left: 5px;" (click)="addAsUserNotificationRoles(newUser)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}">
                        <!-- <i class="fa fa-check-square-o"></i> -->
                        <span translate>PER_NOTIF_AS_USER</span>
                    </button>
                </div>
            </div>
            <div class="actions" fxFlex="110px">
                <button class="btn-primary" (click)="addNewUser(userForm.form)"
                    [disabled]="!userForm.form.valid || !userForm.form.dirty || newUser.groups?.length === 0 || (newUser.isSubcustomer && !newUser.subcustomer)" 
                    [ngClass]="{'ec-disabled': !userForm.form.valid || !userForm.form.dirty || newUser.groups?.length === 0 || (newUser.isSubcustomer && !newUser.subcustomer)}">
                    <i class="fa fa-user-plus"></i>
                    <span translate>ADD</span>
                </button>
                <button class="btn-secondary" (click)="cancelNewUser(userForm.form)">
                    <span translate>CANCEL</span>
                </button>
            </div>
        </div>

    	<div class="row-container base-panel" fxLayout="row" *ngFor="let user of users; let isLast = last;" [ngClass]="{'last': isLast}">
    		<div fxFlex="25" [ngClass]="{'fnt-line-through': !user.enabled}">
                <div>{{user.lastName}} {{user.firstName}}</div>
    			<div *ngIf="!user.firstName && !user.lastName">({{user.email}})</div>
    			<div class="fnt-smaller fnt-transparent">{{user.email}}</div>
    			<div *ngIf="user.phoneNumber" class="fnt-smaller fnt-transparent"><span translate>PHONE_NUMBER</span>: {{user.phoneNumber}}</div>
                <div *ngIf="!user.firstName && !user.lastName && user.enabled" style="margin-top: 5px;">
                    <button class="btn-secondary" (click)="resendInvite(user)">
                        <i class="fa fa-envelope"></i><span translate>INVITE_SEND_AGAIN</span>
                    </button>
                </div>
                <app-subcustomer *ngIf="subcustomersAvailable && user.id !== sharedService.user.id"
                    [subcustomer]="user.subcustomer" 
                    [isSubcustomer]="user.isSubcustomer"
                    [showButtons]="true"
                    (onStateAboutToChange)="onUserSubcustomerStateAboutToChange(user, $event)"
                    (onSaveButtonClicked)="onUserSubcustomerSaveButtonClicked(user, $event)"
                    (onCancelButtonClicked)="onUserSubcustomerCancelButtonClicked(user)"
                    (onIsEditingChanged)="onUserSubcustomerIsEditingChanged(user, $event)"
                ></app-subcustomer>

    		</div>
    		<div fxFlex="70">
                <div>
                    <h4><i class="fa fa-lock"></i> <span translate>SECURITY_GROUP_ADMINISTRATION</span></h4>
                </div>
                <div>
        			<div class="tag fnt-smaller" *ngFor="let group of user.groups" translate>
                        {{'SECURITY_GROUP_' + group}}
                        <!-- ngIf here because we want always at least one admin so if user is curent user do not allow to remove from admin group -->
                        <i class="fa fa-times-circle clickable" 
                            (click)="removeFromGroup(user, group)" 
                            *ngIf="group != 'AuthorizedSellerErp' && (user.id != sharedService.user.id || (user.id == sharedService.user.id && group != 'AuthorizedSellerUserAdmin' && group != 'CustomerUserAdmin' && group != 'SupplierUserAdmin'))"></i>
                    </div>
                    <div>
                        <multiselect-component 
                            [items]="user.availableGroupsForDropdown" 
                            [justButtonLabel]="'ADD_TO_SECURITY_GROUP'"
                            [disableSelectionOnSearch]="true"
                            [multiselectAllLabel]="'CATALOGUE_ALL'"
                            [multiselectFoundLabel]="'USER_ADMIN_PERMISSIONS_FOUND'"
                            [multiselect]="true"
                            [search]="true"
                            [autoClose]="false"
                            (onMultipleSelect)="onGroupsChanged($event, user)"
                            [autoClose]="false"
                            [disableSelectedValueInButton]="true"
                            [disabled]="user.isEditing"
                        ></multiselect-component>

                        <button class="btn-secondary" style="margin-left: 5px;"
                            (click)="removeAllGroups(user)"
                            [disabled]="busy" [ngClass]="{'ec-disabled': busy}"
                            *ngIf="user.groups && user.groups.length > 0">
                            <!-- <i class="fa fa-check-square-o"></i> -->
                            <span translate>PER_NOTIF_REMOVE_ALL</span>
                        </button>
                        <button class="btn-secondary" style="margin-left: 5px;"
                            (click)="addAsUserGroups(user)"
                            [disabled]="busy" [ngClass]="{'ec-disabled': busy}">
                            <!-- <i class="fa fa-check-square-o"></i> -->
                            <span translate>PER_NOTIF_AS_USER</span>
                        </button>
                    </div>
                </div>

                <div style="margin-top: 20px;">
                    <div>
                        <h4><i class="fa fa-envelope"></i> <span translate>NOTIFICATION_ROLE_ADMINISTRATION</span></h4>
                    </div>
                    <div class="tag fnt-smaller" *ngFor="let notifRole of user.notificationRoles">
                        <span translate>{{'NOTIFICATION_RULE_' + notifRole.name}}</span>
                        <i class="fa fa-times-circle clickable" 
                            (click)="removeFromNotifRole(user, notifRole)"
                            *ngIf="user.id !== sharedService.user.id || (user.id === sharedService.user.id && notifRole.name !== 'productsNotTakenCustomer')"></i>
                    </div>
                    <div>
                        <multiselect-component
                            [items]="user.availableNotificationRolesForDropdown" 
                            [justButtonLabel]="'ADD_TO_NOTIFICATION_ROLE'"
                            [disableSelectionOnSearch]="true"
                            [multiselectAllLabel]="'CATALOGUE_ALL'"
                            [multiselectFoundLabel]="'USER_ADMIN_NOTIF_FOUND'"
                            prefix="NOTIFICATION_RULE_" 
                            [multiselect]="true"
                            [search]="true"
                            [autoClose]="false"
                            (onMultipleSelect)="onNotificationRolesChanged($event, user)" 
                            [autoClose]="false"
                            [disableSelectedValueInButton]="true"
                            [disabled]="user.isEditing"
                        ></multiselect-component>
                        <button class="btn-secondary" style="margin-left: 5px;" (click)="removeAllNotificationRoles(user)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}"
                            *ngIf="user.notificationRoles && user.notificationRoles.length > 0">
                            <!-- <i class="fa fa-check-square-o"></i> -->
                            <span translate>PER_NOTIF_REMOVE_ALL</span>
                        </button>
                        <button class="btn-secondary" style="margin-left: 5px;" (click)="addAsUserNotificationRoles(user)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}">
                            <!-- <i class="fa fa-check-square-o"></i> -->
                            <span translate>PER_NOTIF_AS_USER</span>
                        </button>
                    </div>
                </div>
    		</div>
            <div fxFlex="110px">
                <!-- ngIf here because we want always at least one admin so if user is curent user do not allow to block -->
                <div *ngIf="user.id != sharedService.user.id">
                    <button class="btn-primary" (click)="enableUser(user)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}" *ngIf="!user.enabled">
                        <!-- <i class="fa fa-check-square-o"></i> -->
                        <span translate>ENABLE</span>
                    </button>
                    <button class="btn-secondary" (click)="disableUser(user)" [disabled]="busy" [ngClass]="{'ec-disabled': busy}" *ngIf="user.enabled">
                        <!-- <i class="fa fa-check-square-o"></i> -->
                        <span translate>DISABLE</span>
                    </button>
                </div>
            </div>
    	</div>
    </div>
</div>