import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { EcmHttpService } from './http/ecm.http.service';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class ParametersService {
    constructor (private sharedService: SharedService,
                 private http: EcmHttpService) {

    }

    getParameters() {
        return this.http.get(`/params`).pipe(map(data => {
        	for (var i = 0; i < data.holidays.length; ++i) {
        		let momentDate = moment(data.holidays[i]);
        		data.holidays[i] = {year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date()};
        	}
			this.sharedService.setParams(data);
        }, err => {
        	console.log(err);
        }));
    }
}