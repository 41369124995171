import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';

@Injectable()
export class ShipmentsItemsGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'shipmentNumberEComm', name: "SHIPMENT_ID", alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'DESC', width: 130},
            {id: 'dateCreated', name: "DATE_OF_ISSUE_SHIPMENT", checked: true, orderBy: true, orderDirection: 'DESC', width: 110},
            {id: 'orderNumberEComm', name: "ORDER_ID", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            // {id: 'orderNumberCustomer', name: "ORDER_INTERNAL_NUMBER", checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'itemNote', name: 'SPECIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'orderCreated', name: "DATE_OF_ISSUE_ORDER", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'eCommProductId', name: 'PRODUCT_ID', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'amountOrdered', name: 'ORDERED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountReserved', name: 'RESERVED', checked: false, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountDelivered', name: 'DELIVERED', checked: false, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'amountInvoiced', name: 'INVOICED', checked: false, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'amountRemaining', name: 'AMOUNT_REMAINING', checked: false, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountPacking', name: 'AMOUNT_PACKING', checked: false, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'amountShipmentInShipping', name: 'AMOUNT_IN_SHIPPING', checked: true, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountShipmentInProcess', name: 'AMOUNT_IN_PROCESS', checked: true, orderBy: false, orderDirection: 'ASC', width: 140},
            {id: 'amountShipment', name: 'AMOUNT_SHIPMENT', checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'amountInPacking', name: "AMOUNT_MOQ", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'packingsToShip', name: 'PACKINGS_TO_SHIP', checked: true, orderBy: false, orderDirection: 'ASC', width: 130},
            {id: 'packing', name: "PACKING_FORM", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: false, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'rowPrice', name: 'TOTAL_VALUE', checked: false, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'itemState', name: "STATUS", checked: false, orderBy: false, orderDirection: 'ASC', width: 50}
        ];
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            original.unshift({id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            original.unshift({id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }

        original = this.tableService.filterSuppressColumns(original, 'shipmentsItems');
        let restored = this.sharedService.user.preferences['shipments-itemsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        var this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        var gridOptions = this.tableService.getDefaultGridOptions(startPage, 'shipments-itemsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        var this_ = this;
        let colDefs: any[] = [
            {
                headerName: this_.translateService.instant(this_.getColumn('shipmentNumberEComm').name), 
                field: "shipmentNumberEComm",
                width: this_.getColumn('shipmentNumberEComm').width,
                minWidth: 60,
                cellStyle: {'text-align': 'left'}
            },       
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name), 
                field: "dateCreated",
                width: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            }, 
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNumberEComm').name), 
                field: "orderNumberEComm",
                width: this_.getColumn('orderNumberEComm').width,
                minWidth: 60,
                cellStyle: {'text-align': 'left'}
            },
            // {
            //     headerName: this_.translateService.instant(this_.getColumn('orderNumberCustomer').name), 
            //     field: "orderNumberCustomer",
            //     width: this_.getColumn('orderNumberCustomer').width,
            //     minWidth: this_.getColumn('orderNumberCustomer').width,
            //     cellStyle: {'text-align': 'left'}
            // },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemNote').name),
                field: 'itemNote',
                width: this_.getColumn('itemNote').width,
                minWidth: 60,
                cellStyle: { 'text-align': 'left' },
                hide: !this_.getColumn('itemNote').checked
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderCreated').name), 
                field: "orderCreated",
                width: this_.getColumn('orderCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('eCommProductId').name), 
                field: "eCommProductId",
                width: this_.getColumn('eCommProductId').width,
                minWidth: 110,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountOrdered').name), 
                field: "amountOrdered",
                width: this_.getColumn('amountOrdered').width,
                minWidth: this_.getColumn('amountOrdered').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountReserved').name), 
                field: "amountReserved",
                width: this_.getColumn('amountReserved').width,
                minWidth: this_.getColumn('amountReserved').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountReserved').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountDelivered').name), 
                field: "amountDelivered",
                width: this_.getColumn('amountDelivered').width,
                minWidth: this_.getColumn('amountDelivered').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountDelivered').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountInvoiced').name), 
                field: "amountInvoiced",
                width: this_.getColumn('amountInvoiced').width,
                minWidth: this_.getColumn('amountInvoiced').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountInvoiced').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountRemaining').name), 
                field: "amountRemaining",
                width: this_.getColumn('amountRemaining').width,
                minWidth: this_.getColumn('amountRemaining').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountRemaining').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountPacking').name), 
                field: "amountPacking",
                width: this_.getColumn('amountPacking').width,
                minWidth: this_.getColumn('amountPacking').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                hide: !this_.getColumn('amountPacking').checked,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipmentInShipping').name), 
                field: "amountShipmentInShipping",
                width: this_.getColumn('amountShipmentInShipping').width,
                minWidth: this_.getColumn('amountShipmentInShipping').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipmentInProcess').name), 
                field: "amountShipmentInProcess",
                width: this_.getColumn('amountShipmentInProcess').width,
                minWidth: this_.getColumn('amountShipmentInProcess').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountShipment').name), 
                field: "amountShipment",
                width: this_.getColumn('amountShipment').width,
                minWidth: this_.getColumn('amountShipment').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('amountInPacking').name), 
                field: "amountInPacking",
                width: this_.getColumn('amountInPacking').width,
                minWidth: this_.getColumn('amountInPacking').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packingsToShip').name), 
                field: "packingsToShip",
                width: this_.getColumn('packingsToShip').width,
                minWidth: this_.getColumn('packingsToShip').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packing').name), 
                field: "packing",
                width: this_.getColumn('packing').width,
                minWidth: 80,
                cellStyle: {'text-align': 'center'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('PACKING_' + params.data.packing);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowWeight').name), 
                field: "rowWeight",
                width: this_.getColumn('rowWeight').width,
                minWidth: 80, 
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3},
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('unitPrice').name), 
                field: "unitPrice",
                minWidth: 80, 
                width: this_.getColumn('unitPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
                cellStyle: {'text-align': 'right'},
                hide: !this_.getColumn('unitPrice').checked,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('rowPrice').name), 
                field: "rowPrice",
                minWidth: 80, 
                width: this_.getColumn('rowPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
                hide: !this_.getColumn('rowPrice').checked,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('deliveryDate').name), 
                field: "deliveryDate",
                width: this_.getColumn('deliveryDate').width,
                minWidth: 110,
                maxWidth: 110,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemState').name), 
                field: "itemState",
                width: this_.getColumn('itemState').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {tooltipPrefix: 'ORDER_ITEM_STATE_'},
                hide: !this_.getColumn('itemState').checked,
            }
        ];

        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name), 
                field: "authorizedSellerName",
                width: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name), 
                field: "customerName",
                width: this_.getColumn('customerName').width,
                minWidth: 60,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'shipmentsItems');
        return colDefs;
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        let filterItems: any[] = [
            {id: 'shipmentNumberEComm', name: 'SHIPMENT_ID', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            {id: 'orderNumberEComm', name: 'ORDER_ID', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            {id: 'productName', name: 'PRODUCT_ID', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
            // {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'dateRange', valueFrom: '', valueTo: ''},
            {id: 'itemState', name: 'STATUS', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '', 
                values: [
                    {id: 'X', name: 'ORDER_ITEM_STATE_X', dotId: 'X'}, 
                    {id: 'N', name: 'ORDER_ITEM_STATE_N', dotId: 'N'}, 
                    {id: 'C', name: 'ORDER_ITEM_STATE_C', dotId: 'C'}, 
                    {id: 'P', name: 'ORDER_ITEM_STATE_P', dotId: 'P'}
                ]
            },
        ]
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: this.sharedService.user.availableAuthorizedSellers});
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: this.sharedService.user.availableCustomers});
        }
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'shipmentsItems');
        return filterItems;
    }

}

