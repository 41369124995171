import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LocalDatePipe, CapitalizePipe } from '../../locale.pipes.module';
import { LanguageService } from '../../services/language.service';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridNumberRendererComponent } from '@components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
@Injectable()
export class MonitoringGridService {
    constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService,
                 private languageService: LanguageService) {
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            {id: 'dateCreated', name: 'DATE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'userSortName', name: 'USER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'activity', name: 'MONITORING_ACTIVITY', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'brandCode', name: 'MONITORING_BRAND_CODE', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'productionGroupCode1Name', name: 'MONITORING_CATEGORY', checked: false, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'productionGroupCode2Name', name: 'MONITORING_SUBCATEGORY', checked: false, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'productName', name: 'MONITORING_PRODUCT_NAME', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'value1', name: 'MONITORING_VALUE1', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'value2', name: 'MONITORING_VALUE2', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'stockAmount', name: 'MONITORING_STOCK_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'initialBasketAmount', name: 'MONITORING_INITIAL_BASKET_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'finalBasketAmount', name: 'MONITORING_FINAL_BASKET_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 110}
        ];
        original = this.tableService.filterSuppressColumns(original, 'monitoring');
        const restored = this.sharedService.user.preferences['monitoringTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        let columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage: number) {
        const this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'monitoringTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        const this_ = this;
        let colDefs: any = [
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'dateCreated',
                width: this_.getColumn('dateCreated').width,
                minWidth: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'customerName',
                width: this_.getColumn('customerName').width,
                minWidth: this_.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('userSortName').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'userSortName',
                width: this_.getColumn('userSortName').width,
                minWidth: this_.getColumn('userSortName').width,
                cellStyle: {'text-align': 'left'}
            },

            /**
             *
             *  TODO: USER NAME
             *
             **/
            {
                headerName: this_.translateService.instant(this_.getColumn('activity').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'activity',
                width: this_.getColumn('activity').width,
                minWidth: this_.getColumn('activity').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => {
                    return this.sharedService.translateService.instant(params.data.activity);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('brandCode').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'brandCode',
                width: this_.getColumn('brandCode').width,
                minWidth: this_.getColumn('brandCode').width,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('productionGroupCode1Name').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'productionGroupCode1Name',
                width: this_.getColumn('productionGroupCode1Name').width,
                minWidth: this_.getColumn('productionGroupCode1Name').width,
                cellStyle: {'text-align': 'left'},
                hide: !this_.getColumn('productionGroupCode1Name').checked
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('productionGroupCode2Name').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'productionGroupCode2Name',
                width: this_.getColumn('productionGroupCode2Name').width,
                minWidth: this_.getColumn('productionGroupCode2Name').width,
                cellStyle: {'text-align': 'left'},
                hide: !this_.getColumn('productionGroupCode2Name').checked
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('productName').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'productName',
                width: this_.getColumn('productName').width,
                minWidth: this_.getColumn('productName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('value1').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'value1',
                width: this_.getColumn('value1').width,
                minWidth: this_.getColumn('value1').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => {
                    if (params.data.activity === 'PRODUCT_AVAILABILITY') {
                        if (params.data.value1 === 'futureDeliveryPeriod1_4') {
                            return new CapitalizePipe().transform(this.sharedService.translateService.instant('IN_PRODUCTION'));
                        }
                        if (params.data.value1 === 'futureDeliveryPeriod5') {
                            return new CapitalizePipe().transform(this.sharedService.translateService.instant('IN_PRODUCTION_PLAN'));
                        }
                    }
                    if ( params.data.activity === 'PRODUCT_SEARCH' && params.data.value1 === 'CART') {
                        return this.sharedService.translateService.instant(params.data.value1);
                    }
                    if ( (params.data.activity === 'ADD_TO_BASKET' && (params.data.value1 === 'ORDERED' || params.data.value1 === 'DELETED'))) {
                        return this.sharedService.translateService.instant(params.data.value1);
                    }

                    return params.data.value1;
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('value2').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'value2',
                width: this_.getColumn('value2').width,
                minWidth: this_.getColumn('value2').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: (params) => {
                    if (params.data.activity === 'ADD_TO_BASKET') {
                        return new LocalDatePipe(this_.languageService).transform(params.data.value2, this_.sharedService.appSettings.language, true);
                    } else {
                        return params.data.value2;
                    }
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('stockAmount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'stockAmount',
                width: this_.getColumn('stockAmount').width,
                minWidth: this_.getColumn('stockAmount').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('initialBasketAmount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'initialBasketAmount',
                width: this_.getColumn('initialBasketAmount').width,
                minWidth: this_.getColumn('initialBasketAmount').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('finalBasketAmount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'finalBasketAmount',
                width: this_.getColumn('finalBasketAmount').width,
                minWidth: this_.getColumn('finalBasketAmount').width,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                cellStyle: { 'text-align': 'right' }
            }
        ];
        colDefs = this.tableService.filterSuppressColumns(colDefs, 'monitoring');
        return colDefs;
    }
    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }
    getFilterItems( customers: {id: number, name: string}[], users: {id: string, name: string}[]) {
        // customers.unshift({id: 'NULL', name: 'AUTHORIZED_SELLER_USER'});
        let filterItems = [
            // {id: 'dateCreated', name: 'DATE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'dateCreated', name: 'DATE', type: 'dateRange', valueFrom: '', valueTo: ''},
            // {id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: authorizedSellers},
            {id: 'customerId', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: customers},
            {id: 'userId', name: 'USER', type: 'multiselect', search: true, value: '', values: users},
            {id: 'activity', name: 'MONITORING_ACTIVITY', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'ADD_TO_BASKET', name: 'ADD_TO_BASKET'},
                    {id: 'PRODUCT_DETAIL', name: 'PRODUCT_DETAIL'},
                    {id: 'PRODUCT_AVAILABILITY', name: 'PRODUCT_AVAILABILITY'},
                    {id: 'PRODUCT_SEARCH', name: 'PRODUCT_SEARCH'},
                    {id: 'CUSTOMERSTOCK_SEARCH', name: 'CUSTOMERSTOCK_SEARCH'}
                ]},
            {id: 'productName', name: 'MONITORING_PRODUCT_NAME', type: 'text', maxLength: 100, value: '', operator: 'likeBoth'},
        ]
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'monitoring');
        return filterItems;
    }

}

