import { ViewChild, ElementRef, OnInit, Component, ViewContainerRef, HostListener, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationStart, Navigation } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from './services/window.service';
import { LanguageService } from './services/language.service';
import { SharedService } from './services/shared.service';
import { CleanUpService } from './services/clean.up.service';
import { UserService } from './services/user/user.service';
import { NewsService } from './services/news/news.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { LoginDialogService } from './components/login/loginDialogComponent/login.dialog.service';
import { NewsDialogService } from './services/news/news.dialog.service';
import { PermissionsService } from './services/permissions.service';
import { ChatService } from './services/chat/chat.service';
import { filter } from 'rxjs/operators';
import { HomepageService } from '@services/homepage.service';
import { Title } from '@angular/platform-browser';
import { AppAreas } from './model/appArea.model';
import { AutoLogoutService } from './services/auto-logout/auto-logout.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('chatIframe') chatIframe: ElementRef;
    publicAreas = ['home', 'support', 'security', 'contact']
    routedContentMinHeight: number;
    scrollToTopVisible = false;
    selectedLanguage: string;
    // newsMenu: NewsMenu;
    newsMenu;
    newsCount = 0;

    observableLogin: Observable<any>;

    navigator = navigator;

    chatHeight = 107;

    busySettingDetails = false;

    chatUrl: SafeResourceUrl;
    public appAreas = AppAreas;

    @HostListener('window:scroll', ['$event'])
    doSomething(event) {
      this.scrollToTopVisible = window.pageYOffset > 300;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public cdRef: ChangeDetectorRef,
        private windowService: WindowService,
        public sharedService: SharedService,
        private cleanUpService: CleanUpService,
        private userService: UserService,
        private translate: TranslateService,
        private languageService: LanguageService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
        private loginDialogService: LoginDialogService,
        private sanitizer: DomSanitizer,
        public newsService: NewsService,
        private chatService: ChatService,
        private homepageService: HomepageService,
        private titleService: Title,
        private autoLogoutService: AutoLogoutService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            setTimeout(() => {
                this.setMinimalHeight(value);
            }, 0);
        });

        // Add timestamp to the chat URL to prevent caching
        this.chatUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://magenda.sk/zvl/iframe_livechatoo.html?ts=${(new Date()).getTime()}`);

        this.sharedService.appComponent = this;

        this.selectedLanguage = this.sharedService.appSettings.language;
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(this.sharedService.appSettings.language);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.reloadLang(this.sharedService.appSettings.language); // for changes to occure because can be in cache
        translate.use(this.sharedService.appSettings.language);

        // get values from local storage if there is
        this.sharedService.getValuesFromLocalStorage();

        // start timer for logged in user - check for news and show in modal, every 15 minutes do same thing but only for not postponed news
        if (this.sharedService.isUserLoggedIn()) {
            this.newsService.startTimer();
        }
        // set authorized partner from url
        // !!!!! comment this out when deploying on test server because authorized partner name will not be in url
        this.sharedService.setAuthorizedPartner(window.location.hostname +
            (window.location.port ? (':' + window.location.port) : ''));

        this.autoLogoutService.start();

        // bind to route change
        router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                // console.log('routing...', event);
                // console.log('this.router.getCurrentNavigation()', this.router.getCurrentNavigation());
                const currentNavigation: Navigation = this.router.getCurrentNavigation();
                const previousUrl: string = currentNavigation.previousNavigation ? currentNavigation.previousNavigation.extractedUrl.toString() : null;
                // console.log('currentUrl', event.url, 'previousUrl', previousUrl, 'equal?', event.url === previousUrl);

                const urlParts = event.url.split('/'); // '/sk/current-order?orderBy=eCommProductId%20ASC&page=2'
                if (urlParts.length === 2 && urlParts[0] === '' && urlParts[1] === '') { // only '/' in route
                    // Route to default language (or to the current user language - if is the user available)
                    setTimeout(() => {
                        this.changeLanguage(this.sharedService?.user?.language || this.sharedService.apParams.defaultLanguage.toLowerCase());
                    }, 0);
                    return;
                }
                if (urlParts.length > 1) {
                    // set language from url
                    this.selectedLanguage = urlParts[1]; // 'sk'
                    // route to default language if not supported language is in url
                    if (this.sharedService.apParams.languages.indexOf(this.selectedLanguage.toUpperCase()) < 0) {
                        setTimeout(() => {
                            this.changeLanguage(this.sharedService.apParams.defaultLanguage.toLowerCase());
                        }, 0);
                        return;
                    }
                    this.languageService.updateLanguage(this.selectedLanguage);

                    if (this.sharedService.user && !this.busySettingDetails && (this.selectedLanguage !== this.sharedService.user.language)) {
                        this.busySettingDetails = true;
                        this.userService.setUserLanguage({language: this.selectedLanguage}, true).subscribe(() => {
                            this.busySettingDetails = false;
                        }, err => { this.busySettingDetails = false; });
                    }

                    // Set page title
                    this.translate.get('PAGE_TITLE_AS' + this.sharedService.apParams.id).subscribe(str => {
                        this.titleService.setTitle(str);
                    });
                }
                if (urlParts.length > 2) {
                    const areaParts = urlParts[2].split('?'); // ['current-order', 'orderBy=eCommProductId%20ASC&page=2']
                    // refresh whole application if newer version was previously detected by http service when refreshing token
                    if (this.sharedService.refreshOnNextSafeRouteChange && areaParts[0] !== this.sharedService.area) {
                        window.location.reload();
                    }

                    // redirect to home if its other area and we do not have user or params - so user is not logged in - and open login
                    // save original url and query for redirect after login

                    if (this.publicAreas.indexOf(areaParts[0]) < 0 && (!this.sharedService.user || !this.sharedService.params)) {
                        this.sharedService.clear();
                        const urlForAfterLogin = event.url.split('?');
                        this.sharedService.afterLoginUrl = urlForAfterLogin[0];
                        this.sharedService.afterLoginQuery = urlForAfterLogin[1] ? this.sharedService.parseQuery(urlForAfterLogin[1]) : {};
                        this.router.navigate(['/' + this.sharedService.appSettings.language + '/home']);
                        this.openLogin();
                    }

                    // // set area from url
                    // this.sharedService.setAreaOnlyWhenEmpty(areaParts[0]);
                    // if (areaParts[0] === this.sharedService.area) {
                    // if (event.url === previousUrl) {
                    //     console.log('AppComponent emitting sameAreaClicked');
                    //     this.sharedService.subjectSameAreaClicked.next();
                    // }

                    this.sharedService.updateArea(areaParts[0] as AppAreas);
                }
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // reset pasword link if confimationCode is in query params, so open reset password dialog
            if (params.hasOwnProperty('confirmationCode')) {
                this.openLogin({confirmationCode: params.confirmationCode, email: params.email});
                return;
            }
            if (params.hasOwnProperty('tempPass')) {
                this.openLogin({ tempPass: params.tempPass, requireBusinessConditions: params.requireBusinessConditions, email: params.email});
                return;
            }
            if (params.hasOwnProperty('email')) {
                this.openLogin({email: params.email});
                return;
            }
        });

        function receiveMessage(event) {
            if (event.data.name === 'resizeIframe') {
                this.chatHeight = event.data.params.height;
            }
        }
        window.addEventListener('message', receiveMessage.bind(this), false);
        setTimeout(() => this.newsCount = JSON.parse(localStorage.getItem('newsCount')), 0);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.sharedService.subjectReloadNewsCount.subscribe(
                () => this.newsCount = parseInt(this.sharedService.getNewsCount(), 10)
            );
        }, 0);
        // Initial chat language set
        setTimeout(() => {
                this.chatService.setChatIframeElement(this.chatIframe);
                this.chatService.sendToChat({ setLanguage: this.selectedLanguage })
            },
            1000  // 1000 works but it is not clear why :(
        );
    }

    /**
     * Changes language of application
     *
     * @param {string} language - new code for language to be set
     */
    changeLanguage(language: string) {
        if (language === this.sharedService.appSettings.language) { return; }
        this.selectedLanguage = language;
        const urlWithoutQuery = this.router.url.split('?');
        const urlParts = urlWithoutQuery[0].split('/');
        urlParts[1] = language;

        this.chatService.sendToChat({ setLanguage: language });

        this.cleanUpService.cleanUp();
        this.router.navigate(['/' + urlParts.join('/')], {queryParams: this.route.snapshot.queryParams});
    }

    /**
     * Opens login
     */
    openLogin(params?: { tempPass?: string, confirmationCode?: string, requireBusinessConditions?: boolean, email: string}) {
        this.observableLogin = this.loginDialogService.confirm(params);
        this.observableLogin.subscribe(
            res => {
                if (res) { // succesfull login
                    let url: string;

                    url = '/' + this.sharedService.appSettings.language + '/' +
                        this.homepageService.getHomepageRoute(this.sharedService.user.homepage);

                    if (this.sharedService.afterLoginUrl) {
                        url = this.sharedService.afterLoginUrl;
                    }

                    this.chatService.sendToChat({ setName: `${this.sharedService.user.lastName} ${this.sharedService.user.firstName}` });

                    this.router.navigate([url], {queryParams: this.sharedService.afterLoginQuery});
                    this.sharedService.afterLoginUrl = null;
                    this.sharedService.afterLoginQuery = null;
                } else { // dismiss
                    if (this.publicAreas.indexOf(this.sharedService.area) < 0) {
                        this.router.navigate(['/' + this.sharedService.appSettings.language + '/home']);
                    }
                }
            }
        );
    }

    /**
     * Logs user out
     */
    // logOut() {
    //     this.userService.logout().subscribe(data => {
    //         this.sendToChat({ setName: '.' });
    //         // TODO : go to catalogue when public catalogue is ready
    //         // this.router.navigate(['/' + this.sharedService.appSettings.language + '/catalogue']);
    //         this.newsService.stopTimer();
    //         this.router.navigate(['/' + this.sharedService.appSettings.language + '/home']);
    //     }, err => console.log(err));
    // }

    /**
     * Calls next on subjectSameAreaClicked if area is not changing but user clicks on menu item
     */
    public menuButtonClick(newArea: string): void {
        // if (newArea === this.sharedService.area) {
        //     console.log('AppComponent menuButtonClick equals');
        //     this.sharedService.subjectSameAreaClicked.next();
        // } else {
        //     console.log('AppComponent menuButtonClick does not equal');
        //     this.sharedService.updateArea(newArea);
        // }
    }

    /**
     * Scrolls view to top of page
     */
    scrollToTop() {
        setTimeout(() => {
            this.pageScrollService.scroll({ document: this.document, scrollTarget: '#content' });
        }, 0);
    }

    /**
     *  Sets minimal height of routed content to avoid white empty space on bottom of page
     */
    setMinimalHeight(windowHeight) {
        this.routedContentMinHeight =
            windowHeight - document.getElementById('top-navigation').offsetHeight - document.getElementById('footer').offsetHeight - 52;
    }

}
