<div fxLayout="row">
    <div class="input-container" style="width: 100%;">
        <div fxLayout="row">
            <label translate>SEARCH_FILE_FOR_IMPORT</label>
            <ng-template #popFileImportTemplate>
                <div translate>IMPORT_CSV_HELP</div>
                <!-- <div translate>IMPORT_HELP_1</div> -->
                <div>{{ importHelp1Text | translate }}</div>
                <div translate>IMPORT_HELP_2</div>
            </ng-template>
            <i class="fa fa-info-circle clickable" [popover]="popFileImportTemplate" container="body"
                triggers="mouseenter:mouseleave"></i>
        </div>
        <input #fileInput type="file" (change)="fileChangeEvent($event)" placeholder="{{ 'INSERT_CSV' | translate }" 
                style="max-width: 100%;"/>
    
        <div>
            <button class="btn-primary" (click)="emitResult()" 
                style="margin-top: 5px;" 
                [disabled]="parsedRows.length == 0 || disabled" 
                [ngClass]="{'ec-disabled': parsedRows.length == 0 || disabled}">

                <i class="fa fa-plus"></i>
                <a>{{addButtonText | translate}}</a>
            </button>
        </div>
    </div>  
</div>