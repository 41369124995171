import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import { ShipmentsService } from '../../../services/shipment/shipments.service';
import { Subject } from 'rxjs';
import { ToastService } from '@app/services/toastService/toast.service';
import { PersonCreated } from '@app/model/personCreated.model';

@Component({
  selector: 'shipments',
  templateUrl: 'shipments.component.html'
})
export class ShipmentsComponent  {

	totalRows:          number;
	filterItems: 		any[];
	personsIssued: PersonCreated[] = [];
	subjectRefresh = new Subject<any>();
	exportObj: 			{fileName: string, export: Function};

	busy:				boolean;

	constructor(private router: Router, 
				private route: ActivatedRoute,
				private shipmentsService: ShipmentsService,
				public sharedService: SharedService,
				private toastService: ToastService
				) {
		this.subjectRefresh = new Subject<any>();

		this.getShipments = this.getShipments.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);

		this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
			this.exportObj = {
				fileName: this.sharedService.translateService.instant('SHIPMENTS_SUMMARY'), 
				export: this.shipmentsService.exportAll.bind(this.shipmentsService)
			};
		});

        // to reload when menu item is clicked but it is already routed to this area
		this.sharedService.subjectSameAreaClicked.subscribe(res => {
			this.subjectRefresh.next();
		});
	}

	ngOnInit() {
		var this_ = this;
		setTimeout(function() {
			// navigate with orderby parameter
			this_.sharedService.navigateWithOrderby(this_.getColumns(), 
				this_.route.snapshot.queryParams, this_.sharedService.lastShipmentsQueryParams);
		}, 0);
	}

	getShipments(params) {
		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};

		this.sharedService.lastShipmentsQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastShipmentsQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

		this.shipmentsService.getShipments(query)
		.subscribe(data => {
			this.totalRows = data.total;
			if (this.getFilterItems().filter(item => {return this.route.snapshot.queryParams[item.id +'.value']}).length === 0) { // set shipmentsCount only if not filtered
				this.sharedService.shipmentsCount = data.total;
				localStorage.setItem('shipmentsCount', data.total.toString());
			}
			this.personsIssued = data.personCreated;
			if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
			params.successCallback(data.rows, data.total);
			this.busy = false;
        }, err => {
        	console.log(err);
        	// also in error to be able to cancel filter
        	if (!this.filterItems) { // only if not already to not loose fererence of selected
				this.filterItems = this.getFilterItems();
			}
        	this.busy = false;
        });
	}
	getGridOptions() {
		return this.shipmentsService.getShipmentsGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.shipmentsService.getFilterItems(this.personsIssued);
	}
	getColumns() {
		return this.shipmentsService.getColumns();
	}
	getColumnsDefs() {
		return this.shipmentsService.getColumnDefs();
	}



	createNew() {
		delete this.sharedService.user.preferences.shipmentSelection;
		this.router.navigate(['/' + this.sharedService.appSettings.language + '/shipment/new']);
	}
}