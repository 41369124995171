import { Component, ChangeDetectorRef } from '@angular/core';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'preloader',
  styleUrls: [
    './preloader.component.scss'
  ],
  templateUrl: './preloader.component.html'
})
export class PreloaderComponent {
  count = 0;
  
  constructor(
				private cdRef: ChangeDetectorRef, public preloaderService: PreloaderService) {
  	this.preloaderService.getPreloaderObservable().subscribe((value:number) => {
      this.count = value;
      // this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cdRef.detach(); // try this
  }
}