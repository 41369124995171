export class Invoice {
    constructor(
        public id: number,
        public customerId: number,
    	public customerName: string,
        public authorizedSellerName: string,
        public sellerName: string,
        public invoiceNumber: string,
        public issueDate: string,
        public taxDate: string,
        public dueDate: string,
        public paymentCondition: string,
        public totalPrice: number,
        public currency: string,
        public url: string
    ){}
}