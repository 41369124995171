import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../language.service';
import { InvoicesService } from './invoices.service';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridActionsRendererComponent/grid.actions.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { LocalCurrencyPipe } from '../../locale.pipes.module';

@Injectable()
export class InvoicesGridService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService,
                 private tableService: TableService,
                 private invoicesService: InvoicesService) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30},
            {id: 'invoiceNumber', name: "INVOICE_NUMBER", alwaysVisible: true, checked: true, orderDirection: 'ASC', width: 130},
            {id: 'issueDate', name: "DATE_OF_ISSUE", checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'taxDate', name: "INVOICE_TAX_DATE", checked: true, orderBy: false, orderDirection: 'DESC', width: 120},
            {id: 'dueDate', name: "INVOICE_DUE_DATE", checked: true, orderBy: false, orderDirection: 'DESC', width: 120},
            {id: 'paymentCondition', name: "INVOICE_PAYMENT_CONDITION", checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'totalPrice', name: "INVOICE_PRICE_WITHOUT_TAX", checked: true, orderBy: false, orderDirection: 'ASC', width: 120},
            {id: 'actions', hidden: true, name: "ACTIONS", checked: true, orderBy: false, orderDirection: 'ASC', width: 100}
        ];
        if (this.sharedService.user.representative == 'AS') {
            original.splice(2, 0, {id: 'sellerName', name: 'INVOICE_CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            original.splice(2, 0, {id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            original.splice(2, 0, {id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }

        original = this.tableService.filterSuppressColumns(original, 'invoices');
        let restored = this.sharedService.user.preferences['invoicesTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'invoicesTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let this_ = this;
        let colDefs: any[] = [
            {
                headerName: '', 
                field: "selected",
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences.invoicesSelection, observable: this_.getSelectedSubject(),
                },
                headerComponent : GridHeaderSelectAllRendererComponent,
                headerComponentParams : {
                    selection: this_.sharedService.user.preferences.invoicesSelection,
                    observable: this_.getItemsSelectedSubject()
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('invoiceNumber').name), 
                field: "invoiceNumber",
                width: this_.getColumn('invoiceNumber').width,
                cellRendererFramework: GridLinkRendererComponent,
            },         
            {
                headerName: this_.translateService.instant(this_.getColumn('issueDate').name), 
                field: "issueDate",
                width: this_.getColumn('issueDate').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
            },     
            {
                headerName: this_.translateService.instant(this_.getColumn('taxDate').name), 
                field: "taxDate",
                width: this_.getColumn('taxDate').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dueDate').name), 
                field: "dueDate",
                width: this_.getColumn('dueDate').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('paymentCondition').name), 
                field: "paymentCondition",
                width: this_.getColumn('paymentCondition').width,
                cellStyle: {'text-align': 'left'}
            },  
            {
                headerName: this_.translateService.instant(this_.getColumn('totalPrice').name), 
                field: "totalPrice",
                width: this_.getColumn('totalPrice').width,
                minWidth: 80, 
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('actions').name), 
                field: "actions",
                width: this_.getColumn('actions').width,
                minWidth: this_.getColumn('actions').width,
                cellRendererFramework: GridActionsRendererComponent,
                cellRendererParams: {service: this_.invoicesService, 
                    namePartGetter: function(data) {
                        return data.invoiceNumber
                    }
                },
                sortable: false,
            }
        ];
        if (this.sharedService.user.representative == 'AS') {
            colDefs.splice(2, 0, {
                headerName: this_.translateService.instant(this_.getColumn('sellerName').name), 
                field: "sellerName",
                width: this_.getColumn('sellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            colDefs.splice(2, 0, {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name), 
                field: "authorizedSellerName",
                width: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            colDefs.splice(2, 0, {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name), 
                field: "customerName",
                width: this_.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'invoices');
        return colDefs;
    }

    getFilterItems() {
        let filterItems: any[] = [
            {id: 'invoiceNumber', name: 'INVOICE_NUMBER', type: 'text', maxLength: 20, value: '', operator: 'likeBoth'},
            // {id: 'issueDate', name: 'DATE_OF_ISSUE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'issueDate', name: 'DATE_OF_ISSUE', type: 'dateRange', valueFrom: '', valueTo: ''},
            // {id: 'dueDate', name: 'INVOICE_DUE_DATE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}}
            {id: 'dueDate', name: 'INVOICE_DUE_DATE', type: 'dateRange', valueFrom: '', valueTo: ''}
        ];
        if (this.sharedService.user.representative == 'AS') {
            filterItems.unshift({id: 'sellerName', name: 'INVOICE_CUSTOMER', type: 'text', maxLength: 50, value: '', operator: 'likeBoth'});
        }
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers && this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: this.sharedService.user.availableAuthorizedSellers});
        }
        if (this.sharedService.user.availableCustomers && this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: this.sharedService.user.availableCustomers});
        }
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'invoices');
        return filterItems;
    }

}

