import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Directive validates the specified retype-password
 * 
 * Usage:
 * Template:
 * <input type="password" [(ngModel)]="password"       id="password"     name="password"      maxlength="255" [validPassword]="isPasswordValid" required>
 * <input type="password" [(ngModel)]="passwordAgain" id="passwordAgain" name="passwordAgain" maxlength="255" [validConfirmPassword]="password" required>
 * }
 */
@Directive({
  selector: '[validConfirmPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidConfirmPasswordDirective, multi: true}]
})
export class ValidConfirmPasswordDirective implements Validator {
  @Input('validConfirmPassword') originalPassword: string;

  validate(control: AbstractControl): {[key: string]: any} {
    return passwordValidator(this.originalPassword)(control);
  }
}

/**
 * Validator function
 */
export function passwordValidator(originalPassword: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return control.value === originalPassword ? null : {passwordsDoNotMatch: true};
  };
}
