<div *ngIf="deliveryOrder">
	<div class="row-container wider">
		<div class="row-container">
	    	<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/delivery-orders" [queryParams]="sharedService.lastDeliveryOrdersQueryParams">
				<i class="fa fa-arrow-circle-left"></i>
				<a translate>BACK</a>
			</button>
	    </div>
	    <div class="row-container">
	    	<h3 style="display: inline-block;"><i class="fa fa-file-text-o"></i> <span translate>DELIVERY_ORDER</span>&ngsp;
			<span *ngIf="!deliveryOrder.deliveryNumberERP">({{deliveryOrder.number}})</span>
			<span *ngIf="deliveryOrder.deliveryNumberERP">{{deliveryOrder.number}}</span></h3>
	    	<span class="row-container">
        		<span translate>{{'DELIVERY_ORDER_STATE_' + deliveryOrder.state}}</span>
	        	<span class="fa fa-circle" style="border: 2px solid #fff; border-radius: 8px;" 
                     [ngClass]="{'fnt-gray': deliveryOrder.state == 'X',
                                 'fnt-red': deliveryOrder.state == 'N',
                                 'fnt-blue-lighter': deliveryOrder.state == 'S',
                                 'fnt-yellow': deliveryOrder.state == 'C',
                     			 'fnt-green': deliveryOrder.state == 'P'}">
	            </span>
	        </span>
	        <span class="row-container clickable" (click)="scrollToComments()">
	        	<i class="fa fa-comments" style="margin-right: 5px;"></i><span>{{sharedService.currentDiscussionComments.length}}</span>
	        </span>
	    </div>
	</div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>DELIVERY_NUMBER</h4>
			<span *ngIf="!deliveryOrder.deliveryNumberERP">({{deliveryOrder.number}})</span>
			<span *ngIf="deliveryOrder.deliveryNumberERP">{{deliveryOrder.number}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>DATE_OF_ISSUE</h4>
			<span>{{deliveryOrder.deliveryCreated | localdate:sharedService.appSettings.language:true}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>DELIVERY_DATE</h4>
			<span>{{deliveryOrder.deliveryDate | localdate:sharedService.appSettings.language:true}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>NOTE</h4>
			<span>{{deliveryOrder.deliveryNote}}</span>
		</div>
	</div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>PERSON_ISSUED</h4>
			<span>{{deliveryOrder.sortName}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>WEIGHT_KG</h4>
			<span>{{deliveryOrder.totalWeight | localnumber:sharedService.appSettings.language:true:3}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>PRICE</h4>
			<!-- <span>{{deliveryOrder.totalPrice | localcurrency:sharedService.appSettings.language:deliveryOrder.currency}}</span> -->
			<ul class="list-unstyled">
				<li *ngFor="let currency of objectKeys(deliveryOrder.totalPrice)">
					{{deliveryOrder.totalPrice[currency] | localcurrency:sharedService.appSettings.language:currency}}
				</li>
			</ul>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>STATUS</h4>
			<span translate>{{'DELIVERY_ORDER_STATE_' + deliveryOrder.state}}</span>
		</div>
	</div>

	<div class="row-container wider">
	    <div fxLayout="column">
	    	<ecm-table [dataGetter]="getDeliveryOrderItems" 
	                     [optionsGetter]="getGridOptions"
	                     [columnsGetter]="getColumns"
	                     [columnsDefsGetter]="getColumnsDefs"
	                     [total]="totalRows"
	                     [observableRefresh]="subjectRefresh.asObservable()"
                     	 [showRefreshButton]="true"
	                     [busy]="busy"
	                     [secondaryHeaderColor]="true"
                         [exportObj]="exportObj"></ecm-table>
	    </div>
	</div>

	<div *ngIf="sharedService.hasPermission('delivery-orders', 'GET')" 
		class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="50">
			<discussion #discussion
	            itemArea="delivery-orders"
	            [itemId]="route.snapshot.params['id']"
				(edited)="discussionInFormEdited($event)"
	        ></discussion>
	    </div>
	</div>
</div>