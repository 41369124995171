<div class="row-container" *ngIf="columnsDef">
    <!-- NOTE: this date picker is added for date picker styles inicialize. without this, datepicker styles/directives are not created -->
    <div style="visibility:hidden;width:0;height:0;overflow:hidden">
        <my-date-picker></my-date-picker>
    </div>
    <!-- NOTE: end of datepicker inicialization -->


    <div *ngIf="successCount > 0">
        <h4 class="fnt-green"><span translate>{{importMode === importModes.UPDATE ? 'UPDATE_SUCCESS' : 'IMPORT_SUCCESS'}}</span>: {{successCount}}</h4>
    </div>

    <div style="margin-top: 10px" *ngIf="errorRows.length > 0">
        <div fxLayout="row">
            <h4 class="fnt-orange"><span translate>{{importMode === importModes.UPDATE ? 'UPDATE_ERROR' : 'IMPORT_ERROR'}}</span>: {{errorRows.length}}</h4>

            <button class="btn-secondary" style="min-width: 200px; margin-left: 10px;"
                (click)="copyErrorsToClipboard()">
                <i class="fnt-primary fa fa-clipboard"></i>
                <span translate>COPY_ERRORS_TO_CLIPBOARD</span>
            </button>
        </div>

        <div style="display: block; overflow-y: auto; min-height: 350px; padding-right: 70px;"
            [ngStyle]="{'max-height.px': maxHeight - (successCount > 0 ? 30 : 0)}">

            <table>
                <tr>
                    <td *ngFor="let columnDef of columnsDef"><span translate>{{columnDef.header}}</span></td>
                </tr>
                <tr *ngFor="let errorRow of errorRows; let even=even; let index=index"
                    [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">

                    <td *ngFor="let columnDef of columnsDef"
                        [ngClass]="{'fnt-orange': errorRow.error[columnDef.attrName]}">

                        <input *ngIf="columnDef.type === 'string'"
                            type="text" class="form-control"
                            [ngClass]="{'fnt-orange': errorRow.error[columnDef.attrName]}"
                            [(ngModel)]="errorRow.row[columnDef.attrName]"
                            (ngModelChange)="onValueChange(errorRow, columnDef.attrName, errorRow.row[columnDef.attrName])">

                        <number-input *ngIf="columnDef.type === 'number'"
                            name="number-input"
                            [inputType]="'number'" 
                            [ngModel]="errorRow.row[columnDef.attrName]"
                            (ngModelChange)="onValueChange(errorRow, columnDef.attrName, $event)"
                            [ngClass]="{'fnt-orange': errorRow.error[columnDef.attrName]}"
                            class="number-input-as-basket-error"></number-input>

                        <number-input *ngIf="columnDef.type === 'decimal'"
                            name="number-input" 
                            [inputType]="'decimal'"
                            [ngModel]="errorRow.row[columnDef.attrName]"
                            (ngModelChange)="onValueChange(errorRow, columnDef.attrName, $event)"
                            [placeholder]="errorRow.placeholders ? errorRow.placeholders[columnDef.attrName] : ''"
                            [ngClass]="{'fnt-orange': errorRow.error[columnDef.attrName]}"
                            class="number-input-as-basket-error"></number-input>

                        <my-date-picker *ngIf="columnDef.type === 'date' && errorRow.datePickerOptions"
                            [attr.id]="'my-dp-id-' + index"
                            [(ngModel)]="errorRow.row[columnDef.attrName]"
                            [options]="errorRow.datePickerOptions[columnDef.attrName]"
                            [placeholder]="errorRow.placeholders ? errorRow.placeholders[columnDef.attrName] : ''"
                            (dateChanged)="onDateChanged($event, errorRow, columnDef.attrName)"></my-date-picker>
                            <!-- [locale]="sharedService.appSettings.language" -->
    
                        <div style="margin-top: -5px;">
                            <span class="fnt-orange" style="font-size: 75%;" translate>{{errorRow.error[columnDef.attrName]}}&nbsp;</span>
                        </div>
                    </td>
                </tr>
            </table>

        </div>

    </div>

    <div style="padding-top: 15px;">
        <button class="btn-secondary" (click)="closeModal()" style="width: 100px;">
            <span translate>CLOSE</span>
        </button>
        <button class="btn-primary" style="float: right" (click)="importAgain()">
            <i class="fa fa-plus"></i>
            <span translate>IMPORT_AGAIN</span>
        </button>
    </div>

</div>
