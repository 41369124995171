import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { LanguageService } from '../../../services/language.service';
import { WindowService } from '../../../services/window.service';
import { BasketService } from '../../../services/basket/basket.service';
import { LocalDatePipe } from '../../../locale.pipes.module';
import { FormService } from '../../../services/form.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';

@Component({
    selector: 'import-shipment-error-dialog',
    templateUrl: 'import.shipment.error.dialog.component.html'
})
export class ImportShipmentErrorDialog {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;


    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(public bsModalRef: BsModalRef,
                public translate: TranslateService, 
                private sharedService: SharedService, 
                private toastService: ToastService,
                private languageService: LanguageService, 
                private formService: FormService, 
                private windowService: WindowService,
                private basketService: BasketService) {
        this.windowService.height$.subscribe((value:any) => {
            //Do whatever you want with the value.
            //You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
    }

    public copyErrorsToClipboard(): void {
        // caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    private copy(): void {
        let tmpEl;
        tmpEl = document.createElement("textarea");

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = 0;
        tmpEl.style.position = "fixed";
        tmpEl.style.pointerEvents = "none";
        tmpEl.style.zIndex = -1;

        let value = '';
        // fill it with your HTML
        this.params.errors.forEach(errorItem => {
            value += errorItem.row.eCommProductId + '\t';
            value += errorItem.row.shipmentAmount + '\t';
            value += errorItem.row.orderNumberEComm + '\t';
            value += (errorItem.row._missingAmount ? errorItem.row._missingAmount : errorItem.row.shipmentAmount) + '\t'; // if missingAmount not set, use shipmentAmount - e.g. when item not ordered
            for (let key in errorItem.error) {
                value += this.translate.instant(errorItem.error[key].toUpperCase().replace(/ /g, '_')) + ' ';
                if (errorItem.error[key] === 'Not enough amount to ship'){
                    value += errorItem.row._missingAmount + ' ' + this.translate.instant('UNIT');
                }
            }
            value += '\n';
        });

        // // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(value))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand("copy");

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    /**
     * Adds missing amount of item to basket
     **/
    public addToBasket(item: {error: any, 
                       row: {shipmentAmount: number, eCommProductId: string, brandCode: string, orderNumberEComm: string, _missingAmount: number, _orderedNotReserved: number},
                       alreadyAddedToBasket?: boolean,
                       busy?: boolean
    }): void {
        item.busy = true;
        const amountOrdered = item.error._other === 'Not enough amount to ship' 
            ? item.row._missingAmount - item.row._orderedNotReserved
            : item.row.shipmentAmount; // 'eCommProductId not ordered' - let's order the whole amount

        this.basketService.addToOrder([
            { amountOrdered: amountOrdered.toString(), 
              eCommProductId: item.row.eCommProductId, 
              brandCode: item.row.brandCode, 
              deliveryDate: ''
            }
        ])
        .subscribe(data => {
            item.alreadyAddedToBasket = true;
            item.busy = false;
        }, err => {
            item.busy = false;
            console.log(err)
        });
    }

    close(res?: any) {
        this.doneSubject.next(res);
        this.bsModalRef.hide();
    }
}