<div class="base-panel">
    
    <div fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="1 1 20%">
            <input id="role-as" type="radio" class="clickable" [value]="userRoles.as" 
                name="role" [(ngModel)]="role" (ngModelChange)="_onRoleChange()"> 
            <label for="role-as" class="clickable" translate>WORK_AS_AUTHORIZED_SELLER</label>
        </div>

        <div fxFlex="1 1 60%">
            <input id="role-customer" type="radio" class="clickable" [value]="userRoles.customer" 
                name="role" [(ngModel)]="role" (ngModelChange)="_onRoleChange()"> 
            <label for="role-customer" class="clickable" translate>WORK_AS_CUSTOMER</label>:

            <input id="searchInput" [disabled]="role === userRoles.as"
                [(ngModel)]="search"
                typeaheadOptionField="customerName"
                [typeahead]="suggestions$"
                [typeaheadAsync]="true"
                [typeaheadWaitMs]="500"
                (typeaheadOnSelect)="onSelect($event)"
                (typeaheadNoResults)="typeaheadNoResults($event)"
                autocomplete="off"
            >

            <div *ngIf="customerNotFound && role === userRoles.customer">
                <div style="margin: 15px 0;" translate>CUSTOMER_WITH_GIVEN_NAME_DOES_NOT_EXIST</div>
                <div *ngIf="createCustomerIfNotFound">
                    <div style="margin: 15px 0;" translate>YOU_CAN_CREATE_CUSTOMER</div>
                    <div class="input-container mr-15" style="max-width: 100px">
                        <label translate>CUSTOMER_LOCALITY</label>
                        <input placeholder="{{ 'CUSTOMER_LOCALITY' | translate }}" [(ngModel)]="locality" 
                            class="form-control" id="locality" name="locality" 
                            maxlength="2" required>
                    </div>

                    <div class="input-container">
                        <label translate>CUSTOMER_PRICE_LIST_CURRENCY</label>
                        <select class="form-control" style="width: auto;" [(ngModel)]="priceListCurrency">
                            <option *ngFor="let currency of sharedService.priceListCurrencies" 
                                [ngValue]="currency">{{currency}}</option>
                        </select>
                    </div>

                    <button class="btn-primary" (click)="create()"
                        [disabled]="!locality || !priceListCurrency" 
                        [ngClass]="{'ec-disabled': !locality || !priceListCurrency}">
                        <i class="fa fa-chevron-circle-right"></i>
                        <span translate>CREATE_CUSTOMER</span>
                    </button>
                </div>
            </div>
        </div>

        <div fxFlex="1 1 20%" *ngIf="role === userRoles.customer && customer?.customerName">
            <i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i>
            <span translate class="fnt-red" style="font-weight: bold" >YOU_ARE_WORKING_AS_CUSTOMER</span>:
            {{customer.customerName}}    
        </div>
    </div>

</div>
