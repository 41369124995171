import { Injectable } from '@angular/core';

interface ApParamsContact {
    name: string; // 'ZVL SLOVAKIA, a.s.'
    address: string; // 'Na stanicu 22, 010 09 Žilina'
    web: string; // 'www.zvlslovakia.sk'
    phone: string; // '+421 41 707 60 32'
    email: string; // 'sales.zvl@zvlslovakia.sk'
}
interface ApParamsSupport {
    email: string; // 'dominik.rybar@zvlslovakia.sk'
    phone: string; // '+421 907 750 752'
}
interface ApParamsVideo {
    home: string; // 'home.mp4'
}

export interface ApParams {
    id: number; // 1
    name: string; // 'zvl'
    hostUrl: string; // 'zvl.eComm.sk'
    logoFileName: string; // 'zvl.png'
    iconSet: 'set1' | 'set2' | 'set3';
    styleTheme: string;
    languages: string[]; // ['CZ', 'EN', 'PL', 'RU', 'SK']
    defaultLanguage: string; // 'SK'
    contact: ApParamsContact,
    support: ApParamsSupport,
    video?: ApParamsVideo,
    headerSlogan: boolean; // true // show the slogan on the top of the header (PROFESIONÁLNY PRÍSTUP PRE SVETOVÝ PRIEMYSEL)
    copyrightFooterTextCode: string; // 'COPYRIGHT'
    showLivechat: boolean; // true
    showIndustrPackingOrderingWarning: boolean; // true,  // show warning when ordering industrial packing
    eCommLogoInHeader?: boolean; // true
    homeComponentName?: string; // 'as1'
}

const AS1_zvl: ApParams = {
    id: 1,
    name: 'zvl',
    hostUrl: 'zvl.eComm.sk',
    logoFileName: 'zvl.png',
    iconSet: 'set1',
    styleTheme: 'zvl',
    languages: ['CZ', 'EN', 'PL', 'RU', 'SK', 'UA'],
    defaultLanguage: 'SK',
    contact: {
        name: 'ZVL SLOVAKIA, a.s.',
        address: 'Na stanicu 22, 010 09 Žilina',
        web: 'www.zvlslovakia.sk',
        phone: '+421 41 707 60 32',
        email: 'sales.zvl@zvlslovakia.sk',
    },
    support: {
        email: 'dominik.rybar@zvlslovakia.sk',
        phone: '+421 907 750 752'
    },
    video: {
        home: 'home.mp4'
    },
    headerSlogan: true, // show the slogan on the top of the header (PROFESIONÁLNY PRÍSTUP PRE SVETOVÝ PRIEMYSEL)
    copyrightFooterTextCode: 'COPYRIGHT',
    showLivechat: true,
    showIndustrPackingOrderingWarning: true,  // show warning when ordering industrial packing
    eCommLogoInHeader: true,
    homeComponentName: 'as1'
};

const AS2_spolka: ApParams = {
    id: 2,
    name: 'spolka',
    hostUrl: 'wikazvl.pl',
    logoFileName: 'zvl.sp.png',
    iconSet: 'set2',
    styleTheme: 'spolka',
    languages: ['EN', 'PL'],
    defaultLanguage: 'PL',
    contact: {
        name: 'ZVL SLOVAKIA Sp. z o.o.',
        address: 'ul. Starogliwicka 91 F, 44-109 Gliwice, POLSKA',
        web: 'www.zvl.pl',
        phone: '+48 32/270 56 41',
        email: 'sprzedaz@zvl.com.pl',
    },
    support: {
        email: 'rzodkiewicz@zvl.com.pl',
        phone: '+48668124563'
    },
    video: {
        home: 'home.zvl.sp.mp4'
    },
    headerSlogan: true,
    copyrightFooterTextCode: 'COPYRIGHT',
    showLivechat: false,
    showIndustrPackingOrderingWarning: false
};

const AS4_imetcz: ApParams = {
    id: 4,
    name: 'imetcz',
    hostUrl: 'ecimet.cz',
    logoFileName: 'imetcz.png',
    iconSet: 'set3',
    styleTheme: 'imetcz',
    languages: ['CZ', 'EN', 'PL', 'RU', 'SK'],
    defaultLanguage: 'CZ',
    contact: {
        name: 'IMET-CZ, spol. s r.o.',
        address: 'Palkovická 529, 738 01 Frýdek-Místek, Česká republika',
        web: 'www.imet-cz.cz',
        phone: '+420 558 628 133',
        email: 'vlach@imet-cz.cz',
    },
    support: {
        email: 'vlach@imet-cz.cz',
        phone: '+420 558 628 133'
    },
    headerSlogan: false,
    copyrightFooterTextCode: 'COPYRIGHT',
    showLivechat: false,
    showIndustrPackingOrderingWarning: false,
};

const AS5_rolimpex: ApParams = {
    id: 5,
    name: 'as5',
    hostUrl: 'ecrolimpex.dp.ua',
    logoFileName: 'rolimpex.png',
    iconSet: 'set1',
    styleTheme: 'as5',
    languages: ['UA', 'EN', 'SK'],
    defaultLanguage: 'UA',
    contact: {
        name: 'ROLIMPEX LTD',
        address: 'Slobozhansky prospekt 35-A, Dnipro, Ukraine',
        web: 'www.rolimpex.dp.ua',
        phone: '+380503423357',
        email: 'vq@rolimpex.dp.ua',
    },

    support: {
        email: 'vq@rolimpex.dp.ua',
        phone: '+380503423357',
    },
    headerSlogan: true,
    copyrightFooterTextCode: 'COPYRIGHT',
    showLivechat: false,
    showIndustrPackingOrderingWarning: false,
};

@Injectable()
export class APparamsService {

    apParamsConfig = {
        'localhost:4201': AS1_zvl,
        'localhost:4202': AS2_spolka,
        'localhost:4204': AS4_imetcz,
        'localhost:4205': AS5_rolimpex,

        'dev.eczvlslovakia.sk': AS1_zvl,
        'test.eczvlslovakia.sk': AS1_zvl,
        'www.eczvlslovakia.sk': AS1_zvl,

        'dev.eczvlslovakia.pl': AS2_spolka,
        'test.eczvlslovakia.pl': AS2_spolka,
        'www.eczvlslovakia.pl': AS2_spolka,

        'dev.ecimet.cz': AS4_imetcz,
        'test.ecimet.cz': AS4_imetcz,
        'www.ecimet.cz': AS4_imetcz,

        'dev.ecrolimpex.dp.ua': AS5_rolimpex,
        'test.ecrolimpex.dp.ua': AS5_rolimpex,
        'www.ecrolimpex.dp.ua': AS5_rolimpex,
    };

    constructor () {}

    /**
     * Returns params according to AP hostUrl
     * @param hostUrl - string - host url
     */
    public getApParams(hostUrl: string): ApParams {
        // if not defined in apParams set AS1_zvl as default
        return this.apParamsConfig[hostUrl] ? this.apParamsConfig[hostUrl] : AS1_zvl;
    }
}
