import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridLinkRendererComponent } from '@components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { Subject } from 'rxjs';
import { GridDateRendererComponent, GridDateRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '@components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { PrfqItemStates, PrfqStates } from '@app/model/prfq.model';
import { GridNumberRendererComponent } from '@components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '@components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridCommentRendererComponent, GridCommentRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridAttachmentRendererComponent, GridAttachmentRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridAttachmentRendererComponent/grid.attachment.renderer.component';
import { PrfqService } from '../prfq.service';
import { map } from 'rxjs/operators';
import { PrfqPermissionService } from '../prfq.permission.service';
import { AppAreas } from '@app/model/appArea.model';
import { Representative } from '@app/model/user.model';

@Injectable()
export class PrfqsItemsGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    private selected$: Subject<any> = new Subject<any>();
    private allSelected$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        private prfqService: PrfqService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private prfqPermissionService: PrfqPermissionService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['prfqs-items'], // 'prfqs-items',
            gridNameCamel: 'prfqsItems',
            columnList: [
                { id: 'prfqNumber', name: 'RFQ_NUMBER', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 130 },
                { id: 'supplierName', name: 'SUPPLIER', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'manufacturer', name: 'MANUFACTURER', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'productSupplier', name: 'PRODUCT_ID_SUPPLIER', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'productShortName', name: 'SHORT_NAME', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'amountRequired', name: 'REQUIRED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'amountOffered', name: 'OFFERED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'unitPriceRequired', name: 'REQUIRED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'unitPriceOffered', name: 'OFFERED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'dateRequired', name: 'REQUIRED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'dateOfferedDays', name: 'OFFERED_DATE_DAYS', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'itemValidityDays', name: 'RFQ_ITEM_VALIDITY', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'itemNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'attachmentsCount', name: 'ATTACHMENTS', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('prfqNumber').name),
                field: 'prfqNumber',
                width: this.getColumn('prfqNumber').width,
                minWidth: this.getColumn('prfqNumber').width,
                cellRendererFramework: GridLinkRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('supplierName').name),
                field: 'supplierName',
                width: this.getColumn('supplierName').width,
                minWidth: this.getColumn('supplierName').width,
                cellStyle: {'text-align': 'left'}
            }
        ];

        // dateCreated
        const dateCreated: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateCreated').name),
            field: 'dateCreated',
            width: this.getColumn('dateCreated').width,
            suppressSizeToFit: true,
            cellRendererFramework: GridDateRendererComponent
        };
        const dateCreatedCellRendererParams: GridDateRendererComponentParams = { 
            time: true 
        };
        dateCreated.cellRendererParams = dateCreatedCellRendererParams;
        colDefs.push(dateCreated);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('manufacturer').name),
                field: 'manufacturer',
                width: this.getColumn('manufacturer').width,
                minWidth: this.getColumn('manufacturer').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productSupplier').name),
                field: 'productSupplier',
                width: this.getColumn('productSupplier').width,
                minWidth: this.getColumn('productSupplier').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productShortName').name),
                field: 'productShortName',
                width: this.getColumn('productShortName').width,
                minWidth: this.getColumn('productShortName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountRequired').name),
                field: 'amountRequired',
                width: this.getColumn('amountRequired').width,
                minWidth: this.getColumn('amountRequired').width,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountOffered').name),
                field: 'amountOffered',
                width: this.getColumn('amountOffered').width,
                minWidth: this.getColumn('amountOffered').width,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceRequired').name),
                field: 'unitPriceRequired',
                width: this.getColumn('unitPriceRequired').width,
                minWidth: this.getColumn('unitPriceRequired').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceRequired' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceOffered').name),
                field: 'unitPriceOffered',
                width: this.getColumn('unitPriceOffered').width,
                minWidth: this.getColumn('unitPriceOffered').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceOffered' }
            }
        ]);

        // dateRequired
        const dateRequired: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateRequired').name),
            field: 'dateRequired',
            width: this.getColumn('dateRequired').width,
            // suppressSizeToFit: true,
            cellRendererFramework: GridDateRendererComponent
        };
        colDefs.push(dateRequired);

        // dateOfferedDays
        const dateOfferedDays: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateOfferedDays').name),
            field: 'dateOfferedDays',
            width: this.getColumn('dateOfferedDays').width,
            cellStyle: { 'text-align': 'center' },
            valueGetter: (params) => {
                return params.data.dateOfferedDays ? 'D + ' + params.data.dateOfferedDays : '';
            }
        };
        colDefs.push(dateOfferedDays);

        // itemValidityDays
        const itemValidityDays: ColDef = {
            headerName: this.translateService.instant(this.getColumn('itemValidityDays').name),
            field: 'itemValidityDays',
            width: this.getColumn('itemValidityDays').width,
            cellStyle: { 'text-align': 'center' },
            valueGetter: (params) => {
                return params.data.itemValidityDays ? 'D + ' + params.data.itemValidityDays : '';
            }
        };
        colDefs.push(itemValidityDays);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('itemNote').name),
                field: 'itemNote',
                width: this.getColumn('itemNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('itemNote').checked,
            }
        ]);

        // postsCount
        const postsCount: ColDef = {
            headerName: this.translateService.instant(this.getColumn('postsCount').name),
            field: 'postsCount',
            width: this.getColumn('postsCount').width,
            cellRendererFramework: GridCommentRendererComponent
        };
        const postsCountRendererParams: GridCommentRendererComponentParams = {
            itemArea: 'prfq',
            idAttrName: 'prfqId',
            loadForEachItem: true,
            getItemLabel: (item) => this.translateService.instant('ROW').toLowerCase() + ' ' +
                item.lineNumber,
            isHidden: params => params.data.itemState === PrfqItemStates.IN_PROGRESS,
            showPrivateControls: this.sharedService.user.representative === Representative.AS,
            privateConfig: this.prfqService.getDiscussionPrivateConfig()
        };
        postsCount.cellRendererParams = postsCountRendererParams;
        colDefs.push(postsCount);

        // attachmentsCount
        const attachmentsCount: ColDef = {
            headerName: this.translateService.instant(this.getColumn('attachmentsCount').name),
            field: 'attachmentsCount',
            width: this.getColumn('postsCount').width,
            cellRendererFramework: GridAttachmentRendererComponent,
        };
        const attachmentsCountRendererParams: GridAttachmentRendererComponentParams = {
            getAttachments: (params, id) => this.prfqService.getAttachments(params.data.prfqId)
                .pipe(map(attachments => {
                    attachments = attachments.filter(item => item.childId === params.data.lineNumber);
                    return attachments;
            })),
            getUploadUrl: (params, fileName: string, privateItem: boolean) =>
                this.prfqService.getAttachmentUploadUrl(fileName, params.data.prfqId, params.data.lineNumber, privateItem),
            deleteAttachment: (params, entityId: number, attachmentId: number) =>
                this.prfqService.deleteAttachment(entityId, attachmentId),
            getDownloadUrl: (params, attachmentId: number, lineNumber?: number) =>
                this.prfqService.getAttachmentDownloadUrl(params.data.prfqId, attachmentId, lineNumber),
            getAllowEdit: (params) => this.prfqPermissionService.isCellEditable(params.data, 'attachmentsCount'),
            showPrivateControls: this.sharedService.user.representative === Representative.AS,
            privateConfig: this.prfqService.getAttachmentPrivateConfig(),
            idAttrName: 'prfqId'
        }
        attachmentsCount.cellRendererParams = attachmentsCountRendererParams;
        colDefs.push(attachmentsCount);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'PRFQ_ITEM_STATE_',
                    renderColors: this.prfqService.getPrfqItemStateColors()
                }
            }
        ]);
        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(personsCreated: { id: string, name: string }[]): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            ...(this.prfqPermissionService.isPrfqColumnAvailable('supplierName')
                ? [{ id: 'supplier.id', name: 'SUPPLIER', type: TableFilterItemTypes.multiselect, search: true, value: '', 
                    values: this.sharedService.user.availableSuppliers.map(item => ({id: item.id+'', name: item.name})) }]
                : []
            ),
            {
                id: 'prfqNumber', name: 'PRFQ_NUMBER', type: TableFilterItemTypes.text, maxLength: 10, 
                value: '', operator: TableFilterItemOperators.likeBoth },
            {
                id: 'manufacturer', name: 'MANUFACTURER', type: TableFilterItemTypes.text, maxLength: 10, 
                value: '', operator: TableFilterItemOperators.likeBoth },
            {
                id: 'productSupplier', name: 'PRODUCT_ID_SUPPLIER', type: TableFilterItemTypes.text, 
                maxLength: 255, value: '', operator: TableFilterItemOperators.likeBoth },
            {
                id: 'productShortName', name: 'SHORT_NAME', type: TableFilterItemTypes.text, 
                maxLength: 30, value: '', operator: TableFilterItemOperators.likeBoth },
            {
                id: 'dateCreated', name: 'DATE_OF_CREATION', type: TableFilterItemTypes.dateRange, 
                valueFrom: '', valueTo: '', showYearPicker: true },
            {
                id: 'personCreated', name: 'PERSON_CREATED', type: TableFilterItemTypes.multiselect, 
                search: true, value: '', values: personsCreated },
            {
                id: 'itemNote', name: 'NOTE', type: TableFilterItemTypes.text, maxLength: 20, value: '', 
                operator: TableFilterItemOperators.likeBoth },
            {
                id: 'prfqState', name: 'RFQ_STATE', type: TableFilterItemTypes.multiselect, 
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: this.prfqService.getPrfqStateColors(),
                values: [
                    { id: PrfqStates.IN_PROGRESS, name: 'PRFQ_STATE_' + PrfqStates.IN_PROGRESS, dotId: PrfqStates.IN_PROGRESS, default: true },
                    { id: PrfqStates.NEW, name: 'PRFQ_STATE_' + PrfqStates.NEW, dotId: PrfqStates.NEW, default: true },
                    { id: PrfqStates.OPENED, name: 'PRFQ_STATE_' + PrfqStates.OPENED, dotId: PrfqStates.OPENED, default: true },
                    { id: PrfqStates.CLOSED_ACCEPTED, name: 'PRFQ_STATE_' + PrfqStates.CLOSED_ACCEPTED, dotId: PrfqStates.CLOSED_ACCEPTED, default: false },
                    { id: PrfqStates.CLOSED_NOT_ACCEPTED, name: 'PRFQ_STATE_' + PrfqStates.CLOSED_NOT_ACCEPTED, dotId: PrfqStates.CLOSED_NOT_ACCEPTED, default: false }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: [
                        PrfqStates.IN_PROGRESS, PrfqStates.NEW, PrfqStates.OPENED]
                }
            },
            {
                id: 'itemState', name: 'RFQ_ITEM_STATE', type: TableFilterItemTypes.multiselect, 
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: this.prfqService.getPrfqItemStateColors(),
                values: [
                    { id: PrfqItemStates.IN_PROGRESS, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.IN_PROGRESS, dotId: PrfqItemStates.IN_PROGRESS, default: true },
                    { id: PrfqItemStates.NEW, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NEW, dotId: PrfqItemStates.NEW, default: true },
                    { id: PrfqItemStates.OPENED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.OPENED, dotId: PrfqItemStates.OPENED, default: true },
                    { id: PrfqItemStates.NOT_CONFIRMED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NOT_CONFIRMED, dotId: PrfqItemStates.NOT_CONFIRMED, default: true },
                    { id: PrfqItemStates.CONFIRMED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.CONFIRMED, dotId: PrfqItemStates.CONFIRMED, default: true },
                    { id: PrfqItemStates.WAITING, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.WAITING, dotId: PrfqItemStates.WAITING, default: true },
                    { id: PrfqItemStates.ACCEPTED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.ACCEPTED, dotId: PrfqItemStates.ACCEPTED, default: true },
                    { id: PrfqItemStates.NOT_ACCEPTED, name: 'PRFQ_ITEM_STATE_' + PrfqItemStates.NOT_ACCEPTED, dotId: PrfqItemStates.NOT_ACCEPTED, default: true }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY',
                    ids: [
                        PrfqItemStates.IN_PROGRESS, PrfqItemStates.NEW, PrfqItemStates.OPENED,
                        PrfqItemStates.NOT_CONFIRMED, PrfqItemStates.CONFIRMED,
                        PrfqItemStates.WAITING
                    ]
                }
            }
            // {
            //     id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', type: TableFilterItemTypes.dateRange, 
            //     valueFrom: '', valueTo: '' }
        ];
        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
