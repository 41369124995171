import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

enum States {
    Yes = 'yes',
    No = 'no'
}

@Component({
    selector: 'app-subcustomer',
    templateUrl: 'subcustomer.component.html',
    styleUrls: ['subcustomer.component.scss']
})
export class SubcustomerComponent implements OnInit {
    @Input()
    set subcustomer(subcustomer: string) {
        this._subcustomer = subcustomer;
        this.originalSubcustomer = subcustomer;
        // console.log('component subcustomer:', subcustomer);
    };
    @Input()
    set isSubcustomer(isSubcustomer: boolean) {
        setTimeout(() => {
            this.state = isSubcustomer ? States.Yes : States.No;
            // console.log('component isSubcustomer:', isSubcustomer, 'this.state:', this.state);
        }, 0);
    }
    @Input() showButtons: boolean;
    @Output() onStateAboutToChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSubcustomerChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSaveButtonClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() onCancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() onIsEditingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    state: States;
    states = States;
    originalSubcustomer: string;
    _subcustomer: string;
    private isEditing = false;

    constructor() {}

    ngOnInit() {
        // this.state = this.isSubcustomer ? States.Yes : States.No;
        // this.originalSubcustomer = this.subcustomer;
    }

    _onStateChanged(state: States) {
        // this.state = state;
        this.onStateAboutToChange.emit(state === States.Yes);
    }

    _onSubcustomerChanged() {
        this.onSubcustomerChanged.emit(this._subcustomer);
        if (!this.isEditing) {
            this.isEditing = true;
            this.onIsEditingChanged.emit(this.isEditing);
        }
    }

    _onSaveButtonClicked() {
        this.onSaveButtonClicked.emit(this._subcustomer);
        this.isEditing = false;
        this.onIsEditingChanged.emit(this.isEditing);
    }

    _onCancelButtonClicked() {
        this.onCancelButtonClicked.emit();
        this._subcustomer = this.originalSubcustomer;
        if (this.state !== States.Yes || this._subcustomer) {
            this.isEditing = false;
            this.onIsEditingChanged.emit(this.isEditing);
        }
    }

}
