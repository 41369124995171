import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { Observable ,  Subject } from 'rxjs';
import { SharedService } from '../shared.service';
import { NewsListResponse } from '../../model/news.model';
import { NewsDialogService } from './news.dialog.service';
import { TimedCache } from '@common/cache/timed.cache';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class NewsService {
// Monitoring
    timerStart = 0; // reseter for timer - start point of time
    timerIsRuning = false; // indicates if timer is now runing or is stopped
    interval;       // interval for setInterval - end of time
    unreadNews: any[] = [];
    isModalOpen = false;
    showNewsOnStartTimer = false;
    // subjectReloadCurrent: Subject<any>();
    subjectReloadCurrent: Subject<any>;
    private newsListCache = new TimedCache<Observable<NewsListResponse>>(3000); // 3s cache TTL

    constructor (
        private http: EcmHttpService,
        private sharedService: SharedService,
        private newsDialogService: NewsDialogService
    ) {
        this.subjectReloadCurrent = new Subject<any>();
    }

    /**
     * Returns observable of reload - used to reload rfq items grid
     */
    getReloadCurrentSubject(): Subject<any> {
        return this.subjectReloadCurrent;
    }

    /**
     * Loads list of news
     * We do some caching here to prevent too many requests in a short time.
     * This caching is a quick-win solution,
     * better would be to refactor whole news so no frequent calls are made.
     * Maybe some other time.
     */
    public getNews (query?, hidePreloader?: boolean): Observable<NewsListResponse> {
        // console.log('getNews', query);
        const queryAsString = JSON.stringify(query);
      
        let cache$ = this.newsListCache.get(queryAsString);
        if (!cache$) {
            cache$ = this.http.get('/news', this.http.prepareOptions(query), hidePreloader).pipe(
                shareReplay(1) // call get only once and return new observable
            );
            this.newsListCache.set(queryAsString, cache$);
        }
        return cache$;
    }

    /**
     * Put news
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    putNews (id: number, data: any) {
        const url = `/news/${id}/header`;
        // console.log('stringify', JSON.stringify(data));
        return this.http.put(url, JSON.stringify(data));
    }
    /**
     * MarkAsRead
     */
    markAsReadNews (id) {
        const url = `/news/${id}/mark-as-read`;
        return this.http.put(url, '');
    }
    /**
     * MarkAsUnread
     */
    markAsUnreadNews (id) {
        const url = `/news/${id}/mark-as-unread`;
        return this.http.put(url, '');
    }

    /**
     * Sets initial count of News without read news
     */
    setNewsCount() {
        this.getNews({'valid.operator': 'eq', 'valid.value': '1'}).subscribe(data => {
            if (data.totalCount) {
                const total = data.rows.filter(item => item.read === 0 ).length;
                this.sharedService.setNewsCountStorage(total);
                this.sharedService.refreshNewsCount();
                if (!this.timerIsRuning) {
                    this.startTimer();
                }
            }
        }, err => {
            console.log(err);
        });
    }

    /**
     * Exports all
     */
    exportAll(query) {
        const url = `/news`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    private newsToShow() {
        const this_ = this;
        if (this.sharedService.isUserLoggedIn()) {
            this_.unreadNews = [];
            const newsPostponed: any[] = (JSON.parse(localStorage.getItem('newsPostponed')) === null ||
            JSON.parse(localStorage.getItem('newsPostponed')).length === 0 ) ? [] : JSON.parse(localStorage.getItem('newsPostponed'));
            // this.startTimer();

            // Only valid news - for non-news admins it is not necessary,
            // but news admins would get all news, even the old ones
            this.getNews({'valid.operator': 'eq', 'valid.value': '1'}, true).subscribe(news => {
                news.rows.forEach(row => {
                    if (row.read === 0 && newsPostponed.indexOf(row.id) < 0) {
                        this_.unreadNews[row.id] = row;
                    }
                });
                this_.unreadNews = this_.unreadNews.filter(i => i);
                // console.log('unreadNEws', this_.unreadNews);
                if (this_.unreadNews) {
                    localStorage.setItem('newsCount', JSON.stringify(this_.unreadNews.length));
                    if (!this.isModalOpen && this_.unreadNews.length > 0) {
                        this.showNewsInModal(this_.unreadNews);
                        this.isModalOpen = true;
                    }
                }
            })

        }
    };

    // MODAL: show modal
    showNewsInModal(unreadNews) {
        this.newsDialogService.confirm({
            data: unreadNews.filter( i => i ), // do filter because there can be empty elements
            question: this.sharedService.translateService.instant('NEWS'),
            primary: this.sharedService.translateService.instant('NEWS_MARK_AS_READ'),
            secondary: this.sharedService.translateService.instant('NEWS_READ_LATER'),
            isModal: true
            // cancel: this.sharedService.translateService.instant('NEWS_READ_LATER'),
            // suppressQuestionMark: true,
        }).subscribe(
            res => {
                if (res === 'restart') {
                    this.isModalOpen = false;
                    // all news was read or postponed
                    this.restartTimer();
                }
                if (res === 'confirm') {
                }
            }
        );
}

    /*
     * NEWS TIMER: Check news every 15 minutes if modal is closed
     *
     * if is logged in user, modal is not open and is first timer start (after login), check news imediately
     * else if is logged in user, modal is not open - restart timer and open modal window with news
     * otherwise stop timer - modal is open and user is reading news
     *
     */

    startTimer() {
        if (!this.isModalOpen && this.sharedService.user && this.showNewsOnStartTimer) {
            this.showNewsOnStartTimer = false;
            this.restartTimer();
            this.newsToShow();
        } else if (!this.isModalOpen && this.sharedService.user && !this.showNewsOnStartTimer) {
            this.interval = setInterval(() => {
                if (this.timerStart === 900) { // 15min x 60sec = 900s
                    this.restartTimer();
                    this.newsToShow();
                } else {
                    this.timerStart++;
                    this.timerIsRuning = true;
                    if (this.isModalOpen) { this.stopTimer(); }
                }
            }, 1000); // steps (after each 1s)
        } else {
            this.stopTimer();
        }

    }
    stopTimer() {
      clearInterval(this.interval);
      this.timerIsRuning = false;
    }
    restartTimer() {
      clearInterval(this.interval);
      this.timerStart = 0;
      this.startTimer();
    }

    clean() {
        this.showNewsOnStartTimer = true;
    }
}

