
import {throwError as observableThrowError,  Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map, switchMap, finalize } from 'rxjs/operators';

import { SharedService } from '../shared.service';
import { PreloaderService } from '../preloader.service';
import { ToastService } from '../toastService/toast.service';
import { TranslateService } from '@ngx-translate/core';

export interface EcmHttpQueryParams {
    skip?: number;
    top?: number;
    orderBy?: string;
    [key: string]: any
}

@Injectable()
export class EcmHttpService {

    private doNotShowPreloader: string[] = [ // do not show preloader when requests url is in this array
        '/my-profile/settings',
        '/products-availability',
        '/order-item-delivery-date-history',
        '/delivery-orders-with-product',
        '/login',
        '/app-version',
        '/params',
        '/my-profile/details',
        '/discussion',
        '/attachments',
        // '/catalogue',
        // '/monitoring'
    ];

    private doNotShowError = [ // do not show default error toast when error is in this array
        error => error.message === 'Wrong shipment amount',
        error => error.name    === 'InvalidDeliveryDate',
        error => error.message === 'Multiple currencies in items',
        error => error.message ? error.message.toString().indexOf('Unknown projectPriceListCode') !== -1 : false,  // basket items error
        error => error.message === 'SUPPLIER_WITH_GIVEN_CODE_EXISTS',
        error => error.message === 'NO_VALID_CUSTOMER_NOR_AS_NOR_SUPPLIER',
        error => error.message === 'CUSTOMER_BLOCKED'
    ];

    private apiMap = [ // first part of url references api
        {prefix: '/user',                    urlCode: 'apiUsersUrl'},
        {prefix: '/my-profile',              urlCode: 'apiUsersUrl'},
        {prefix: '/price-list',              urlCode: 'apiUsersUrl'},
        {prefix: '/supplier',                urlCode: 'apiUsersUrl'},
        {prefix: '/delivery-orders',         urlCode: 'apiDeliveryOrdersUrl'},
        {prefix: '/invoices',                urlCode: 'apiDeliveryOrdersUrl'},
        {prefix: '/news',                    urlCode: 'apiDeliveryOrdersUrl'},
        {prefix: '/customer-stock',          urlCode: 'apiDeliveryOrdersUrl'},
        {prefix: '/manual-stock',            urlCode: 'apiDeliveryOrdersUrl'},
        {prefix: '/products',                urlCode: 'apiProductsUrl'},
        // {prefix: '/products',          urlCode: 'apiOrdersUrl'},
        {prefix: '/product-groups',          urlCode: 'apiProductsUrl'},
        {prefix: '/order',                   urlCode: 'apiOrdersUrl'},
        {prefix: '/preorder',                urlCode: 'apiOrdersUrl'},
        {prefix: '/basket',                  urlCode: 'apiOrdersUrl'},
        {prefix: '/rfq',                     urlCode: 'apiRfqUrl'},
        {prefix: '/prfq',                    urlCode: 'apiPrfqUrl'},
        {prefix: '/c-check',                 urlCode: 'apiS1Url'},
        {prefix: '/sent-emails',             urlCode: 'apiS1Url'},
        {prefix: '/params',                  urlCode: 'apiCoreUrl'},
    ];

    constructor(
        private http: HttpClient,
        private sharedService: SharedService,
        private preloaderService: PreloaderService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    /**
     * Common method for different kinds of http requests
     * If unathorized, tries to use refresh_token and repeat http request and resolve original request with data after refreshing tokens
     * @param originalRequest  function to call to make the request
     * @param url
     * @param excludeUrls  optional url to exclude
     * @param suppressDoNotShowError
     * @returns {Observable<>}
     */
    private commonRequest(originalRequest: () => Observable<any>, url: string, excludeUrls?: string[],
            suppressDoNotShowError?: boolean, hidePreloader?: boolean, suppressShowError?: boolean): Observable<any> {
        if (!excludeUrls) {
            excludeUrls = [url + 'just something to make excludeUrl different from url'];
        }
        // No connection error
        if (!navigator.onLine) {
            this.toastService.addError('ERROR_NO_INTERNET_CONNECTION');
            return observableThrowError({message: 'No internet connection'});
        }

        this.doBeforeRequest(this, url, hidePreloader);
        return originalRequest().pipe(
            catchError(err => {
                if (err.status === 401 && excludeUrls.indexOf(url) === -1)  {
                    return this.refreshToken(this).pipe(
                        switchMap(() => originalRequest()),
                        catchError(
                            err2 => {
                                return this.sharedService.getObservableFor401Requests()
                                .pipe(switchMap(() => originalRequest().pipe(
                                    tap((res: any) => {
                                      this.onSubscribeSuccess(res);
                                    }, (error: any) => {
                                      // this.onSubscribeError(error, this);
                                    }),
                                    finalize(() => {
                                      this.onFinally(this, url);
                                    })
                                  )
                                ));
                            }
                        )
                      );
                } else {
                    return observableThrowError(typeof err._body === 'string' ? JSON.parse(err._body) : err);
                }
            }),
            tap((res: Response) => {
                this.onSubscribeSuccess(res);
            }, (error: any) => {
                this.onSubscribeError(error, this, suppressDoNotShowError, suppressShowError);
            }),
            finalize(() => {
                this.onFinally(this, url);
            })
          );
    }

    /**
     * Performs a request with `get` http method.
     * If unathorized, tries to use refresh_token and repeat http request and resolve original request with data after refreshing tokens
     * @param url
     * @param options
     * @returns {Observable<>}
     */
    get(url: string, options?: EcmHttpQueryParams, hidePreloader?: boolean): Observable<any> {
        return this.commonRequest(
                    () => this.http.get(this.getFullUrl(url, this), this.requestOptions(options)),
                    url,
                    null,
                    null,
                    hidePreloader);
    }

    /**
     * Performs a request with `get` http method without adding default headers and query
     * @param url
     * @param options
     * @param showPreloader
     */
    public getLocal(url: string, options?: any, showPreloader?: boolean): Observable<any> {
        if (showPreloader) {
            this.preloaderService.showPreloader();
        }
        return this.http.get(url, options).pipe(
            tap(() => {
                if (showPreloader) {
                    this.preloaderService.hidePreloader();
                }        
            }),
            catchError(error => {
                if (showPreloader) {
                    this.preloaderService.hidePreloader();
                }        
                return throwError(error);
            })
        );
    }

    /**
     * Performs a request with `post` http method.
     * If unathorized, tries to use refresh_token and repeat http request and resolve original request with data after refreshing tokens
     * @param url
     * @param body
     * @param options
     * @returns {Observable<>}
     */
    post(url: string, body: any, options?: any, hidePreloader?: boolean, suppressShowError?: boolean): Observable<any> {
        return this.commonRequest(
                    () => this.http.post(this.getFullUrl(url, this), body, this.requestOptions(options)),
                    url,
                    ['/login', '/reset-password'],
                    null,
                    hidePreloader,
                    suppressShowError);
    }

    /**
     * Performs a request with `put` http method.
     * If unathorized, tries to use refresh_token and repeat http request and resolve original request with data after refreshing tokens
     * @param url
     * @param body
     * @param options
     * @param suppressDoNotShowError
     * @returns {Observable<>}
     */
    put(url: string, body: string, options?: any, suppressDoNotShowError?: boolean, suppressShowError?: boolean): Observable<any> {
        return this.commonRequest(
            () => this.http.put(this.getFullUrl(url, this), body, this.requestOptions(options)),
            url, null, suppressDoNotShowError, null , suppressShowError);
    }

    /**
     * Performs a request with `delete` http method.
     * If unathorized, tries to use refresh_token and repeat http request and resolve original request with data after refreshing tokens
     * @param url
     * @param options
     * @returns {Observable<>}
     */
    delete(url: string, options?: any): Observable<any> {
        return this.commonRequest(
            () => this.http.delete(this.getFullUrl(url, this), this.requestOptions(options)),
            url);
    }

    /**
     * Uses refresh_token to get new access_token.
     * Saves new tokens to local storage
     * @param self - instance of http service
     */
    refreshToken(self: EcmHttpService): Observable<any> {
        this.checkVersion();
        return this.http
                    .post(self.getFullUrl(`/refresh`, self),
                          JSON.stringify({refreshToken: localStorage.getItem('refresh_token')}),
                          self.requestOptions())
                    .pipe(map(data => {
                        localStorage.setItem('access_token', data['accessToken']);
                        localStorage.setItem('id_token',     data['idToken']);
                        return data;
                    }));
    }

    /**
     * Checks for newer version of application
     */
    public checkVersion(): void {
        this.http.get<{versionNumber: string}>(this.getFullUrl(`/app-version`, this)).subscribe(data => {
            if (data.versionNumber !== this.sharedService.versionNumber) {
                this.sharedService.refreshOnNextSafeRouteChange = true;
            }
        }, err => {
            console.log(err);
        });
    }

    /**
     * Request options.
     * @param options
     * @returns object
     */
    private requestOptions(options?: any): any {
        const accessToken    = localStorage.getItem('access_token');
        const language       = this.sharedService.appSettings.language;  // set language to query params of all requests
        const now            = new Date();
        const timezoneOffset = now.getTimezoneOffset().toString();

        let newOptions = options || {};
        newOptions = Object.assign({}, newOptions);

        newOptions.params = newOptions.params || new HttpParams();
        newOptions.params = newOptions.params.set('lang', language);
        newOptions.params = newOptions.params.set('TZO', timezoneOffset);

        newOptions.headers = newOptions.headers || new HttpHeaders();
        newOptions.headers = newOptions.headers.set('Authorization', `${accessToken}`);  // set Authorization header for all requests

        return newOptions;
    }

    /**
     * Prepares request options to be given to httpService calls
     *
     * @param query - object {key: value, ...} to be send as query string
     */
    public prepareOptions(query: EcmHttpQueryParams): EcmHttpQueryParams {
        const options: any = {};
        options.params = new HttpParams();
        for (const prop in query) {
            if (query.hasOwnProperty(prop)) {
                options.params = options.params.set(prop, encodeURIComponent(query[prop]));
            }
        }
        return options;
    }

    /**
     * Build API url.
     * @param url
     * @param self - instance of http service
     * @returns {string}
     */
    private getFullUrl(url: string, self: EcmHttpService): string {
        const filteredApi = self.apiMap.filter(item => url.indexOf(item.prefix) === 0);
        let apiUrl      = filteredApi.length === 1 ? self.sharedService.appSettings[filteredApi[0].urlCode] : null;

        if (!apiUrl) {
            apiUrl = self.sharedService.appSettings.apiUrl;
        }
        return apiUrl + url;
    }

    /**
     * Request interceptor.
     *
     * @param self - instance of http service
     */
    private doBeforeRequest(self: EcmHttpService, url: string, hidePreloader?: boolean): void {
        if (this.doNotShowPreloader.filter(item => url.indexOf(item) !== -1).length === 0) {
            if (!hidePreloader) {
                self.preloaderService.showPreloader();
            }
        }
    }

    /**
     * Response interceptor.
     *
     * @param self - instance of http service
     */
    private doAfterRequest(self: EcmHttpService, url: string, hidePreloader?: boolean): void {
        if (this.doNotShowPreloader.filter(item => url.indexOf(item) !== -1).length === 0) {
            if (!hidePreloader) {
                self.preloaderService.hidePreloader();
            }
        }
    }

    /**
     * Error handler.
     * @param error
     * @param caught
     * @returns {ErrorObservable}
     */
    private onCatch(error: any, caught: Observable<any>): Observable<any> {
        return observableThrowError(error);
    }

    /**
     * onSubscribeSuccess
     * @param res
     */
    private onSubscribeSuccess(res: Response): void {
    }

    /**
     * onSubscribeError
     * @param error
     * @param self - instance of http service
     * @param suppressDoNotShowError - does not look into doNotShowError array if this is true
     */
    private onSubscribeError(errorResponse: HttpErrorResponse, self: EcmHttpService, suppressDoNotShowError?: boolean,
        suppressShowError?: boolean): void {
        setTimeout(() => {
            // no connection error
            if (!navigator.onLine) {
                self.toastService.addError('ERROR_NO_INTERNET_CONNECTION');
                return;
            }

            let message = 'ERROR_'
            if (errorResponse.error) {
                const suppressError = this.doNotShowError.reduce((acc, valFn) => acc ? acc : valFn(errorResponse.error), false);
                if ((suppressError && !suppressDoNotShowError) || suppressShowError) {
                    return;
                }

                const parsedMessage = errorResponse.error.message;
                if (parsedMessage) {
                    message += parsedMessage.toString().toUpperCase().replace(/\ /g, '_');
                }
            }
            const stringToDisplay = this.translateService.instant(message);
            self.toastService.addError(message === stringToDisplay ? 'ERROR_OCCURED' : stringToDisplay);
        }, 200);
    }

    /**
     * onFinally
     * @param self - instance of http service
     */
    private onFinally(self: EcmHttpService, url: string): void {
        this.doAfterRequest(self, url);
    }

}
