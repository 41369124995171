import { Component, OnInit, OnDestroy } from '@angular/core';
import { Animations } from '../../animations';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StockGridService } from '../stockComponent/stock.grid.service';
import { ASStockService } from './as.stock.service';
import { StockService } from '../stockComponent/stock.service.interface';
import { PermissionsService } from '@app/services/permissions.service';

@Component({
    selector: 'app-as-stock-component',
    templateUrl: 'as.stock.component.html',
    animations: [Animations.slideInOut]
})
export class ASStockComponent implements OnInit {
    stockService: StockService;
    public suppressAdding = true;

    constructor (
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private stockGridService: StockGridService,
        private aSStockService: ASStockService,
        private permissionsService: PermissionsService,
    ) {
        this.stockService = this.aSStockService;
    }

    ngOnInit() {
        this.suppressAdding = !this.permissionsService.hasPermission('asStockManage');
        
        setTimeout(() => {
            // navigate with orderby parameter
            this.sharedService.navigateWithOrderby(this.stockGridService.getColumns(false, 'as-stock'),
                this.route.snapshot.queryParams, this.sharedService.lastASStockQueryParams);
        }, 0);
    }
}
