<div class="row-container wider footer-page">
	<h3><i class="fa fa-address-card-o"></i> <span translate>CONTACT</span></h3>

	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>CONTACT_NAME</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span>{{sharedService.apParams.contact.name}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>ADDRESS</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span>{{sharedService.apParams.contact.address}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>WEB</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<a [href]="'http://' + sharedService.apParams.contact.web" target="_blank">{{sharedService.apParams.contact.web}}</a>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>PHONE</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span>{{sharedService.apParams.contact.phone}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="150px">
			<div class="row-container">
				<h4><span translate>EMAIL</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<a [href]="'mailto:' + sharedService.apParams.contact.email" target="_top">{{sharedService.apParams.contact.email}}</a>
			</div>
		</div>
	</div>
</div>