<div *ngIf="params" [ngClass]="sharedService.apParams.styleTheme + '-theme'" fxLayout="row" fxLayoutAlign="center center">

    <div class="row-container wider" style="width: 100%; margin: 0">
        <div class="row-container">
                    <h1 class="fnt-primary">
                        <span *ngIf="params.data.length > 1" translate>NEWS</span>
                        <span *ngIf="params.data.length <= 1" translate>NEWS_ONLY_ONE</span>
                    <span style="font-size: 12px" *ngIf="count > 1"> ({{count - this.params.data.length + 1}} / {{count}})</span>
                    <!-- <span style="font-size: 12px"> DEBUG: id: {{this.params.data[0].id}} | title: {{this.params.data[0].title}}</span> -->
                    </h1>
            <div class="row-container wider" style="width:100%; max-height: 70vh; overflow-y: auto; padding: 0; margin: 0 !important">

                <div permissionHide perm="news" action="GET" *ngIf="!isEditing">
                    <div fxLayout="row" class="row-container  input-container">
                        <news-detail [newsId]="this.params.data[0].id" [reload]="subjectReloadNews" [isModal]="true" style="width: 100%; min-width: 100%"></news-detail>   
                        <!-- <div fxFlex="65" class="news-body-container">
                                    <h2 style="border-bottom: 1px solid #000; text-transform: uppercase;">{{ params.data[0]?.title }}
                                        <span *ngIf="params.data[0].important"><i aria-hidden="true" class="fnt-red fa fa-exclamation-circle" style="margin-left:10px"></i></span>
                                        <strong style="color: #aaa; text-align: right; display: block; position: relative; float: right; font-size: 12px; line-height: 45px;">
                                            {{ params.data[0]?.dateCreated  }}</strong></h2>
                                    <div [innerHTML]="params.data[0].body"></div>
                                </div>
                        <div fxFlex="5"></div>
                        <div fxFlex="30">
                            
                            <div class="rfq-detail-widgets" style="margin-bottom: 10px">
                                <attachments [attachmentsGetter]="attachmentsGetter" [makeCollapsible]="true"></attachments>
                            </div>

                            
                            <div permissionHide perm="news/comment" action="POST" class="rfq-detail-widgets new-comment"
                                style="margin-bottom: 10px">
                                <div class="component-outer-container">
                                    <div class="component-header-container">
                                        <h3><i class="fa fa-comment"></i> <span translate>NEWS_COMMENT</span></h3>
                                    </div>

                                    <div class="component-body-container discussion" #scrollContainer [ngStyle]="{'max-height.px': maxHeight - 180}">
                                        <div class="row-container" style="margin-top: 0">
                                            <textarea [(ngModel)]="comment" class="form-control" placeholder="{{ 'NEWS_ADD_NEW_PLACEHOLDER' | translate }}"
                                                rows="7"></textarea>
                                        </div>
                                        <div fxLayout="row" class="row-container " fxLayoutAlign="end center">
                                            <button class="btn btn-primary" style="margin-right: 0" (click)="sendComment()"
                                                [disabled]="comment && comment.length <= 0" [ngClass]="{'ec-disabled': comment && comment.length <= 0}">
                                                <i class="fa fa-comment-o"></i><span translate>NEWS_ADD_NEW</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div permissionHide perm="news/comment" action="GET" class="rfq-detail-widgets new-comment"
                                style="margin-bottom: 10px">
                                <div class="component-outer-container">
                                    <div class="component-header-container">
                                        <h3><i class="fa fa-comments"></i> <span translate>NEWS_ALL_COMMENTS</span></h3>
                                    </div>

                                    <div class="component-body-container discussion" #scrollContainer [ngStyle]="{'max-height.px': maxHeight - 180}">
                                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                            <div fxFlex="100" fxLayoutAlign="center center">
                                                <div fxFlex="100" style="padding-top: 15px; padding-bottom: 15px"
                                                    fxLayoutAlign="center center"><span translate>NEWS_COMMENTS_NOT_FOUND</span></div>
                                                <busy-indicator *ngIf="busy"></busy-indicator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                    </div>
                </div>


            </div>
        </div>
        <div class="row-container bigger" fxLayout="row" fxLayoutAlign="end center">
            <button class="btn-secondary" (click)="postpone()" style="margin-right: 10px;">
                <i class="fa fa-times-circle"></i>
                <span>{{ params.secondary }}</span>
            </button>
            <button class="btn-primary" (click)="continue(true, this.params.data[0].id)">
                <i class="fa fa-chevron-circle-right"></i>
                <span>{{ params.primary }}</span>
            </button>
        </div>
    </div>
</div>