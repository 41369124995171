<div *ngIf="prfq" id="purchaseRfqDetail">
    <div class="row-container wider">
        <div class="row-container">
            <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/{{backUrl}}" [queryParams]="backUrl === 'prfqs' ? sharedService.lastPrfqsQueryParams : backQueryParams">
                <i class="fa fa-arrow-circle-left"></i>
                <a translate>BACK</a>
            </button>
        </div>

        <div class="row-container">
            <h3 style="display: inline-block;"><i class="fa fa-file-text-o"></i> <span translate>PURCHASE_RFQ</span>&ngsp;
                <span>{{prfq.prfqNumber}}</span>
            </h3>
            <span class="row-container">
                <span translate>{{'PRFQ_STATE_' + prfq.prfqState}}</span>&ngsp;
                <span class="fa fa-circle" style="border: 2px solid #fff; border-radius: 8px;"
                    [ngClass]="{
                    'fnt-gray': prfq.prfqState === prfqStates.IN_PROGRESS,
                    'fnt-red': prfq.prfqState === prfqStates.NEW,
                    'fnt-yellow': prfq.prfqState === prfqStates.OPENED,
                    'fnt-green': prfq.prfqState === prfqStates.CLOSED_ACCEPTED,
                    'fnt-black': prfq.prfqState === prfqStates.CLOSED_NOT_ACCEPTED
                    }">
                </span>
            </span>

            <ng-template #rfqDetailDiscussionPopover>{{'DISCUSSION' | translate | lowercase}}</ng-template>
            <span class="row-container clickable" (click)="scrollToComments()" *ngIf="elementsVisibility?.discussion" [popover]="rfqDetailDiscussionPopover" container="body" triggers="mouseenter:mouseleave">
            <i class="fa fa-comments" style="margin-right: 5px;"></i><span>{{postsCount}}</span>
            </span>

            <ng-template #rfqDetailAttachmentsPopover>{{'ATTACHMENTS' | translate | lowercase}}</ng-template>
            <span class="row-container clickable" (click)="scrollToAttachments()" *ngIf="elementsVisibility?.attachments" [popover]="rfqDetailAttachmentsPopover" container="body" triggers="mouseenter:mouseleave">
            <i class="fa fa-paperclip" style="margin-right: 5px;"></i><span>{{attachmentsCount}}</span>
            </span>

            <ng-template #rfqDetailJournalPopover>{{'JOURNAL' | translate | lowercase}}</ng-template>
            <span class="journal-notif-text clickable" *ngIf="elementsVisibility?.journal" (click)="scrollToJournal()" style="margin-top: 26px; margin-left: 8px;" [popover]="rfqDetailJournalPopover" container="body" triggers="mouseenter:mouseleave">
            <i class="fa fa-history" style="margin-right: 5px;"></i>
            </span>   

        </div>
  
    </div>

    <div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
        <div fxFlex.gt-sm="25" class="row-container">
            <h4 translate>RFQ_NUMBER</h4>
            <span>{{prfq.prfqNumber}}</span>
        </div>
        <div fxFlex.gt-sm="25" class="row-container">
            <h4 translate>DATE_OF_CREATION</h4>
            <span>{{prfq.dateCreated | localdate:sharedService.appSettings.language:true}}</span>
        </div>
        <div fxFlex.gt-sm="25" class="row-container">
            <h4 translate>PERSON_CREATED</h4>
            <span>{{prfq.personCreatedFullName}}</span>
        </div>
        <div fxFlex.gt-sm="25" class="row-container">
            <app-note [note]="prfq.prfqNote"
                [isEditable]="isNoteEditable"
                [onSaveHandler]="saveNote"
                (onEdited)="noteEdited($event)">
            </app-note>
        </div>
    </div>

    <div class="row-container wider">
        <div fxLayout="column">
            <prfq-detail-items *ngIf="prfq"
                [prfqId]="prfq.id"
                [prfq]="prfq"
                [showNewRowButton]="buttonsVisibility[prfqActions.INSERT_ITEM]"
                [reloadObservable]="reloadItems$"
                [reloadGridOptions]="reloadItemsGridOptions$"
                (rowsDataLoaded)="getRowsDataChange($event)"
            ></prfq-detail-items>
        </div>
    </div>

    <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
        <div fxFlex="50">
            <app-prfq-detail-add-items
                *ngIf="buttonsVisibility.INSERT_ITEM && isBulkAddToPrfqAvailable"
                [prfqId]="prfq.id"
                (onImportFinish)="onImportFinish($event)"
            ></app-prfq-detail-add-items>

            <app-prfq-detail-update-items
                *ngIf="isBulkPrfqUpdateAvailable"
                [prfqId]="prfq.id"
                (onImportFinish)="onImportFinish($event)"
            ></app-prfq-detail-update-items>
        </div>

        <div fxFlex="50">
            <div fxLayout="row" fxLayoutAlign="end">
                <button class="btn-primary" style="margin-right: 10px;"
                    *ngIf="buttonsVisibility[prfqActions.SUBMIT_PRFQ]" 
                    (click)="prfqAction(prfqActions.SUBMIT_PRFQ)"
                    [disabled]="!allowSendRfq || edited.note || edited.discussionInForm"
                    [ngClass]="{'ec-disabled': !allowSendRfq || edited.note || edited.discussionInForm}"
                >
                    <i class="fa fa-check-square-o"></i>
                    <a translate>RFQ_SUBMIT_RFQ</a>
                </button>
                <button class="btn-secondary" style="margin-right: 10px;"
                    *ngIf="buttonsVisibility[prfqActions.DELETE_PRFQ]" (click)="deletePrfq()"
                    [disabled]="edited.note || edited.discussionInForm"
                    [ngClass]="{'ec-disabled': edited.note || edited.discussionInForm}"
                >
                    <i class="fa fa-times-circle"></i>
                    <a translate>RFQ_DELETE_RFQ</a>
                </button>
                <button class="btn-primary" style="margin-right: 10px;"
                    *ngIf="buttonsVisibility[prfqActions.OPEN_PRFQ]"
                    (click)="prfqAction(prfqActions.OPEN_PRFQ)"
                >
                    <i class="fa fa-envelope-open"></i>
                    <a translate>RFQ_OPEN_RFQ</a>
                </button>
            </div>

            <div fxLayout="row" fxLayoutAlign="end">
                <button class="btn-primary" style="margin-right: 10px;"
                    *ngIf="buttonsVisibility[prfqActions.CLOSE_PRFQ]"
                    (click)="showModalActionClose()"
                >
                    <i class="fa fa-close"></i>
                    <a translate>RFQ_SUBMIT</a>
                </button>

                <button class="btn-primary" style="margin-right: 10px;"
                    *ngIf="buttonsVisibility[prfqActions.REOPEN_PRFQ]"
                    (click)="showModalStatusReopen()"
                >
                    <i class="fa fa-undo"></i>
                    <a translate>RFQ_REOPEN</a>
                </button>
            </div>

        </div>
    </div>

    <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
        <div fxFlex="33">
            <attachments #attachments id="attachments"
                [entityId]="prfq?.id"
                [attachmentsGetter]="getAttachments"
                [getUploadUrl]="getUploadUrl"
                [getDownloadUrl]="getDownloadUrl"
                [deleteAttachment]="deleteAttachment"
                [allowEdit]="isAttachmentEditable"
                (attachmentS3Keys)="setAttachmentS3Keys($event)"
                (attachmentsChanged)="attachmentsChanged($event)"
                [reload]="reloadAttachments$"
                [makeCollapsible]="true"
                [showPrivateControls]="showPrivateControls"
                [privateConfig]="attachmentPrivateConfig"
                [(isCollapsed)]="isCollapsedChild.attachments"
            ></attachments>
        </div>

        <div fxFlex="33">
            <div *ngIf="elementsVisibility?.discussion">
                <div>
                    <discussion #discussion id="discussion"
                        itemArea="prfq"
                        [getItemLabel]="getCommentItemLabel"
                        [itemId]="route.snapshot.params['id']"
                        (added)="discussionChanged($event)"
                        [makeCollapsible]="true"
                        [showPrivateControls]="showPrivateControls"
                        [privateConfig]="discussionPrivateConfig"
                        [(isCollapsed)]="isCollapsedChild.discussion"
                        (edited)="discussionInFormEdited($event)"
                        [publishPostsCount]="true"
                    ></discussion>
                </div>
            </div>
        </div>

        <div fxFlex="33">
            <!-- tu bol dotaznik-->
        </div>
    </div>

    <div class="row-container wider rfq-detail-widgets" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" *ngIf="elementsVisibility?.journal">
        <div fxFlex="100">
            <journal-detail #prfqJournal id="prfqJournal" 
                [entityId]="prfq?.id"
                [dataGetter]="getJournal"
                [optionsGetter]="getJournalGridOptions"
                [filterItemsGetter]="getJournalFilterItems"
                [columnsGetter]="getJournalColumns"
                [columnsDefsGetter]="getJournalColumnsDefs"
                [reload]="reloadJournal$"
                [(isCollapsed)]="isCollapsedChild.journal"
                [makeCollapsible]="true"
            ></journal-detail>
        </div>
    </div>
</div>
