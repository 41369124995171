<div class="backdrop" *ngIf="getEditedCount() > 0"></div>

<div class="input-container" *ngIf="projectPriceLists?.length > 1 && componentMode === componentModes.Customer">
	<label translate>PRICE_LISTS</label>
    <dropdown-component [items]="projectPriceLists" [selected]="currentPriceList" (onSelect)="onSelectProjectPriceList($event)" [autoClose]="true"></dropdown-component>
</div>

<div *ngIf="initialized">
	<ecm-table [dataGetter]="getBasketItems" 
                   [optionsGetter]="getProductsOfCurrentOrderGridOptions"
                   [filterItems]="filterItems"
                   [suppressStoreFilters]="true"
                   [columnsGetter]="getProductsOfCurrentOrderColumns"
                   [columnsDefsGetter]="getProductsOfCurrentOrderColumnsDefs"
                   [total]="totalRows"
                   [selection]="sharedService.user.preferences.productsOfCurrentOrderSelection"
                   [editedIdsObj]="currentOrderGridService.productsOfCurrentOrderEditedIds"
                   [observableRefresh]="subjectRefresh.asObservable()"
                   [observableReloadCurrent]="subjectReloadCurrent.asObservable()"
                   [subjectAllSelectedChange]="subjectAllProductsOfCurrentOrderSelected"
                   [subjectDeleteSelected]="subjectDeleteSelected"
                   [bulk]="bulk"
                   [busy]="busy"
                   emptyMessage="SHOPPING_CART_EMPTY_MESSAGE"
                   [tableNamePrefix]="tableNamePrefix"
                   [secondaryHeaderColor]="true"
                   [exportObj]="exportObj"></ecm-table>
</div>

<div fxLayout="row" class="row-container bigger" style="margin-top: 20px;">
	<div fxFlex="50" fxLayoutAlign="start">

	</div>
	<div fxFlex="50" fxLayoutAlign="end">
		<button class="btn-primary" (click)="reload()" [disabled]="totalRows == 0" [ngClass]="{'ec-disabled': totalRows == 0}">
			<i class="fa fa-refresh"></i>
			<a translate>REFRESH_SHOPPING_CART</a>
		</button>
	</div>
</div>

<div class="content-separator"></div>

<div fxLayout="column" fxLayout.gt-sm="row" class="row-container bigger">
	<div fxFlex="50">
	
	</div>
	
	<div fxFlex="50">
		<div class="params-table" fxLayout="column">
<!-- 				<div class="item" fxLayout="row">
				<div fxFlex="40" class="key">
					<span translate>SHIPPING_TYPE</span>
				</div>
				<div fxFlex="60" class="value">
					<select>
						<option translate>SHIPPING_PERSONAL_IN_AP</option>
						<option translate>SHIPPING_BY_AP</option>
						<option translate>SHIPPING_CURIER_DHL</option>
					</select>
				</div>
			</div>
			<div class="item" fxLayout="row">
				<div fxFlex="40" class="key">
					<span translate>TERM_OF_EXPIRATION</span>
				</div>
				<div fxFlex="60" class="value">
					<select>
						<option translate>TERM_30_DAYS</option>
						<option translate>TERM_14_DAYS</option>
					</select>
				</div>
			</div> -->
			<div class="item" fxLayout="row">
				<div fxFlex="40" class="key">
					<span translate>TOTAL_COUNT</span>
					<span *ngIf="selectedCount > 0"> / <span translate>SELECTED_COUNT</span></span>
				</div>
				<div fxFlex="60" class="value">
					{{totalAmountOrderedAll | localnumber:sharedService.appSettings.language}} <span translate>UNIT</span>
					<span *ngIf="selectedCount > 0"> / {{(sharedService.user.preferences.productsOfCurrentOrderSelection.all ? totalAmountOrderedAll : totalAmountOrderedSelected) | localnumber:sharedService.appSettings.language}} <span translate>UNIT</span></span>
				</div>
			</div>
			<div class="item" fxLayout="row">
				<div fxFlex="40" class="key">
					<span translate>TOTAL_WEIGHT</span>
					<span *ngIf="selectedCount > 0"> / <span translate>SELECTED_WEIGHT</span></span>
				</div>
				<div fxFlex="60" class="value">
					{{totalWeightAll | localnumber:sharedService.appSettings.language:true:3}} kg
					<span *ngIf="selectedCount > 0"> / {{(sharedService.user.preferences.productsOfCurrentOrderSelection.all ? totalWeightAll : totalWeightSelected) | localnumber:sharedService.appSettings.language:true:3}} kg</span>
				</div>
			</div>
			<div class="item last" fxLayout="row" *ngIf="componentMode === componentModes.Customer">
				<div fxFlex="40" class="key">
					<span translate>TOTAL_PRICE</span>
					<span *ngIf="selectedCount > 0"> / <span translate>SELECTED_PRICE</span></span>
				</div>
				<div fxFlex="60" class="value">
					{{totalPriceAll | localcurrency:sharedService.appSettings.language:sharedService.getUserCustomerCurrency()}}
					<span *ngIf="selectedCount > 0"> / {{(sharedService.user.preferences.productsOfCurrentOrderSelection.all ? totalPriceAll : totalPriceSelected) | localcurrency:sharedService.appSettings.language:sharedService.getUserCustomerCurrency()}}</span>
				</div>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="end" class="row-container bigger">
			<button class="btn-primary" (click)="order()" [disabled]="totalRows == 0" [ngClass]="{'ec-disabled': totalRows == 0}">
				<i class="fa fa-check-square-o"></i>
				<span translate>{{ componentMode === componentModes.Customer ? 'MAKE_ORDER' : 'MAKE_PREORDER' }}</span>&nbsp;<span *ngIf="selectedCount > 0" translate>SELECTED</span>
			</button>
		</div>
	</div>
</div>
