import { Component, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
import { TableService } from '../../../../services/table.service';
import { FormService } from '../../../../services/form.service';
import { SharedService } from '../../../../services/shared.service';
import { LocalNumberPipe} from '../../../../locale.pipes.module';
import { LanguageService} from '../../../../services/language.service';
// import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { EditedIdsObject, BaseSelection, CellChange } from '@app/model/table.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';

export interface GridInputRendererComponentParams {
    editable?: boolean;
    maxLength?: string; // = '10';     // optional
    maxWidth?: string; // '100px'; 'auto' will expand to the whole column width
    textAlign?: string; // = 'right';  // optional
    number: boolean; // = true; must be 'false' for a string input
    decimal: boolean; // = true; must be 'false' for a string input
    decimalLength?: number; // optional formatting
    prefixString?: string; // = '';    // optional prefix string
    tooltip?: string;              // tooltip to show - got from params.getTooltip()

    setEditableSubscription?: Subscription;
    getEditable?: (params: any) => any;
    style?: object;

    getPopInfo?: (params) => string[];

    thousandsDelimiter?: string;
    subjectReloadCellEditable?: Subject<any>;
    isColValid?: (columnName: string, columnValue: any, dataRow: any) => boolean;
    getInvalidColPopInfo?: () => string,
    editedIdsObj?: EditedIdsObject;
    allowMultipleEdit?: boolean;
    selection?: BaseSelection;
    cellChanged$?: Subject<CellChange>;
}

interface MyParams extends ICellRendererParams, GridInputRendererComponentParams {}

@Component({
    selector: 'app-grid-input',
    template: ` <div class="center-text" style="padding: 0 2px;">
                   <ng-template #popError><span translate>{{invalidColPopInfo}}</span></ng-template>
                   <ng-template #popInfo>
                        <div *ngFor="let item of popInfoValue">{{item}}</div>
                   </ng-template>
                   <span *ngIf="editable">{{prefixString}}</span>

                    <number-input *ngIf="editable && ((number === true) || (decimal === true))" name="number-input"
                        [ngModel]="params.data[params.column.colId]"
                        [inputType]="params.decimal === true ? 'decimal' : 'number'"
                        [decimalLength]="decimalLength"
                        [disabled]="_isDisabled"
                        (focus)="startEditing()"
                        (mousedown)="startEditing()"
                        (keypress)="keypress($event)"
                        [maxWidth]="maxWidth"
                        [focusOnInit]="false"
                        [isValid]="isValid"
                        [invalidPopoverText]="params.invalidPopoverText"
                        [textAlign]="params.textAlign"
                        [popover]="params.data[params.column.colId] && popInfoValue ? popInfo : null" container="body" triggers="mouseenter:mouseleave"
                        [adaptivePosition]="true" placement="right"
                    ></number-input>

                    <input #input="bs-popover" type="text" class="form-control" [ngStyle]="style"
                        *ngIf="editable && (number !== true && decimal !== true)"
                        [ngModel]="params.isCurrency ? (params.data[params.column.colId] | localnumber:sharedService.appSettings.language:true) :
                            (params.data[params.column.colId])"
                        (ngModelChange)="params.data[params.column.colId]"
                        (keypress)="keypress($event)"
                        style="margin-top: -6px; padding: 4px; height: 27px; font-size: 98%; display: inline-block;"
                        [attr.maxlength]="maxLength" [disabled]="_isDisabled"
                        (focus)="startEditing()"
                        (mousedown)="startEditing()"
                        [popover]="popError" container="body" triggers="" (mouseover)="mouseover(input)" (mouseleave)="input.hide()"
                        [adaptivePosition]="true" placement="right">
                </div>
                <span [ngStyle]="style" *ngIf="!editable" [popover]="popInfoValue ? popInfo : null" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="right">
                    <span *ngIf="params.data[params.column.colId]">{{prefixString}}</span>
                    <span *ngIf="number && decimal">{{params.data[params.column.colId] | localnumber:sharedService.appSettings.language:true:2  }}</span>
                    <span *ngIf="number && !decimal">{{params.data[params.column.colId] | localnumber:sharedService.appSettings.language  }}</span>
                    <span *ngIf="!number">{{params.data[params.column.colId]}}</span>
                </span>
               `
})
export class GridInputRendererComponent implements AgRendererComponent, OnDestroy {
    // isColValid
    // prefixString
    params: MyParams;            // can be with isCurrency - this shows input value with pipe | localnumber:sharedService.appSettings.language:true
    editable: boolean;
    maxLength = '10';     // optional
    maxWidth = '100px';   // optional
    textAlign = 'right';  // optional
    number = true;       // optional formatting
    decimal = true;        // optional formatting
    decimalLength = 2; // only applies when decimal === true
    prefixString = '';    // optional prefix string
    tooltip: string;              // tooltip to show - got from params.getTooltip()

    // setEditableSubscription: Subscription;
    style: object;

    popInfoValue: string[];

    thousandsDelimiter: string;
    // _isValid: boolean;
    _isDisabled: boolean;
    subscriptions: Subscription[] = [];
    public invalidColPopInfo: string = '';

    constructor(
        private tableService: TableService,
        private formService: FormService,
        public sharedService: SharedService,
        private languageService: LanguageService
    ) {
        this.isValid = this.isValid.bind(this);

        const locale = this.languageService.getLocale();
        this.thousandsDelimiter = locale[this.sharedService.appSettings.language].thousandsDelimiter; // ' ';
    }

    agInit(params: MyParams): void {
        this.params = params;
        this.editable = params.hasOwnProperty('editable') ? params.editable : true; // editable always, but when editable in params, take that value
        this.editable = params.hasOwnProperty('getEditable') ? params.getEditable(params) : this.editable; // if function getEditable is defined use that to get editable value - used for editable on each row different
        this.maxLength = this.params.maxLength ? this.params.maxLength : this.maxLength;
        this.maxWidth = this.params.maxWidth ? this.params.maxWidth : this.maxWidth;
        // this.style = {'text-align': this.params.textAlign ? this.params.textAlign : this.textAlign};
        this.style = this.getStyle();
        this.number = ('number' in this.params) ? this.params.number : this.number;
        this.decimal = ('decimal' in this.params) ? this.params.decimal : this.decimal;
        this.decimalLength = ('decimalLength' in this.params) ? this.params.decimalLength : this.decimalLength;
        this.prefixString = ('prefixString' in this.params) ? this.params.prefixString : this.prefixString;

        if (this.number) {
            if (this.decimal === false ) {
                this.params.value = new LocalNumberPipe(this.languageService)
                .transform(this.params.value, this.sharedService.appSettings.language, false, 0);
            } else {
                this.params.value = new LocalNumberPipe(this.languageService)
                .transform(this.params.value, this.sharedService.appSettings.language);
            }
        }

        this.updateState();

        // save original values for cancel action
        if (!this.params.data.hasOwnProperty('originalValues')) { this.params.data.originalValues = []; }
        if (this.params.data.originalValues.filter((e) => {
                    return e.colId === this.params.column['colId'];
                }).length === 0) {
            this.params.data.originalValues.push({colId: this.params.column['colId'], value: this.params.value});
        }

        this.subscriptions.push(this.tableService.getEditableObservable().subscribe((res: any) => {
            if (res.editable) {
                if (res.editableColIds.indexOf(this.params.column['colId']) > -1) {
                    this.editable = true;
                }
            }
        }));

        if (this.params.subjectReloadCellEditable) {
            this.subscriptions.push(this.params.subjectReloadCellEditable.subscribe(result => {
                if (this.params.rowIndex === result.rowIndex) {
                    this.editable = this.params.hasOwnProperty('getEditable') ? this.params.getEditable(this.params) : this.params.editable;  // reload editable value
                }
            }));
        }

        if (this.params.cellChanged$) {
            this.subscriptions.push(this.params.cellChanged$.subscribe(cellChange => {
                this.updateState(cellChange);
            }));
        }

        if (this.params.getPopInfo) {
            this.popInfoValue = this.params.getPopInfo(this.params);
        }
    }

    private updateState(cellChange?: CellChange): void {
        this.editable = this.params.hasOwnProperty('editable') ? this.params.editable : true; // editable always, but when editable in params, take that value
        this.editable = this.params.hasOwnProperty('getEditable')
            ? this.params.getEditable(this.params)
            : this.editable; // if function getEditable is defined use that to get editable value - used for editable on each row different

        this.style = this.getStyle();
        // this._isValid = this.isValid();
        this._isDisabled = this.isDisabled();
        this.invalidColPopInfo = this.params.getInvalidColPopInfo ? this.params.getInvalidColPopInfo() : null;
    }

    startEditing() {
        if (this.isDisabled()) { return; }
        this.params.api.startEditingCell({
            rowIndex: this.params.rowIndex,
            colKey: this.params.column['colId'],
            keyPress: null,
            charPress: ''
        });
    }

    public isValid(): boolean {
        const data = this.params.api.getModel()['rowsToDisplay'][this.params.rowIndex].data;
        return (this.params.isColValid
            ? this.params.isColValid(
                this.params.column['colId'],
                (this.number || this.decimal) && data[this.params.column['colId']] ?
                    data[this.params.column['colId']].toString().replace(this.thousandsDelimiter, '') : data[this.params.column['colId']],
                data
            )
            : true);
    }

    isDisabled() {
        if (!this.params.editedIdsObj || this.params.allowMultipleEdit) { return false; }
        if (Object.keys(this.params.editedIdsObj).length === 0) { return false; }
        return !(!!this.params.editedIdsObj[this.params.data.id]);
    }

    getStyle() {
        return {
            'background-color': !this.isValid()
                                ? 'rgba(255, 84, 98, 0.5)' : '', // mark red when more than given value or 0
            'max-width': this.maxWidth,
            'text-align': this.number || this.decimal ? (this.params.textAlign ? this.params.textAlign : 'right') : 'left'
        }
    }

    // mouseover(element) {
    //     if (this.params.noMoreThan &&
    //         this.params.data[this.params.noMoreThan] < this.params.data[this.params.column['colId']] &&
    //         this.params.data[this.params.column['colId']] > 0) {
    //         element.show();
    //     }
    // }

    public mouseover(element: PopoverDirective): void {
        if (this.invalidColPopInfo) {
            element.show();
        }
    }

    keypress(event) { // used to start editing when user selects text in input and then starts to write
        const this_ = this;
        if (!(this.formService.numbers.indexOf(event.key) > -1) &&       // allow number
                !(event.keyCode >= 37 && event.keyCode <= 40) &&  // allow arrows
                event.keyCode !== 46 &&                            // allow delete
                event.keyCode !== 8) {                             // allow backspace
                event.preventDefault();
                return;
            }
            if (!(event.keyCode >= 37 && event.keyCode <= 40)) { // edited only if not arrows
                setTimeout(function() {
                    this_.startEditing();
                }, 0);
            }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
