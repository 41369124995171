import {Component, ChangeDetectorRef, ViewContainerRef, ViewChild, AfterViewInit} from '@angular/core';
import {AgEditorComponent} from 'ag-grid-angular';
import {SharedService} from '../../../../services/shared.service';
import {Observable, Subject} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import { CellChange, EditedIdsObject } from '@app/model/table.model';
import { ICellEditorParams, SuppressKeyboardEventParams } from 'ag-grid-community';

export interface GridTypeaheadEditorComponentParams {
  dataGetter: (searchValue: string) => Observable<string[]>;
  editedIdsObj?: EditedIdsObject;
  cellChanged$?: Subject<CellChange>;
  allowOnlyFromList?: boolean; // if true, make validation for input - if value doesnt exist in list, mark as inValidate
  isColValid?: (coldId: string, colValue: any, row: object) => boolean;
}

interface MyParams extends ICellEditorParams, GridTypeaheadEditorComponentParams {}

@Component({
  selector: 'typeahead-editor-cell',
  template: `
    <div style="padding: 0 3px;">
      <input #input [(ngModel)]="params.value"
            [typeahead]="dataSource"
            [typeaheadAsync]="true"
            [placeholder]="'NOT_FOUND' | translate"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="5"
            [typeaheadOptionsLimit]="20"
            [ngStyle]="{'background-color': noResult && allowOnlyFromList ? 'rgba(255, 84, 98, 0.5)': ''}"
            (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadOnSelect)="typeaheadOnSelect($event)"
            (typeaheadNoResults)="typeaheadNoResults($event)"
            (ngModelChange)="onChange()"
            typeaheadOptionField="name"
            class="form-control"
            container="body"
            typeaheadWaitMs="1000"
            style="margin-top: -6px; padding: 4px; height: 27px; font-size: 98%; display: inline-block; max-width: 100px"           
        >
    </div>`,
  styles: [`
    .row-container {
      background-color: #fff;
      box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 15px;
    }

    li {
      height: 36px;
    }

    .dropdown-item {
      padding: 10px;
    }
  `]
})
export class GridTypeaheadEditorComponent implements AgEditorComponent, AfterViewInit {
  public params: MyParams;
  // private asyncSelected: string;        // selected value
  private typeaheadLoading: boolean;    // is loading dataSource?
  public noResult: boolean; // true = typeahead did not match the typed value with any of the available values
  public dataSource: Observable<any>;  // returned data from server
  // currentToken = '1';           // if value is empty, suggest list with number 1 character
  public allowOnlyFromList = false;    // if true, make validation for input - if value doesnt exist in list, mark as inValidate
  private maxWidth: string = '100px';   // optional
  // maxLength: string = '10';     // optional
  // prefixString: string = '';    // optional prefix string
  private lastEmmitedValue = null; // last value emmited on cellChanged$
  
  @ViewChild('container', { read: ViewContainerRef }) container;
  @ViewChild('input', { read: ViewContainerRef, static: true }) input;

  constructor(
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
  ) {
    // this.dataSource = Observable.create((observer: any) => { // Runs on every search
    //   this.rfqService.getProductNamesByString(this.params.value).subscribe(data => { // get list of product names from server
    //       observer.next(data.map(item => item.productName));
          
    //       // get model of current row 
    //       // const model = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data;
    //       const model = this.params.api.getModel().getRow(this.params.rowIndex).data;

    //       if (this.sharedService.user.representative === 'AS') {
    //         // search in obtained data for item with same productName as user type in. if result was found, is paired
    //         // one to one between user value and DB value which really exists. if doesnt, set model.rfqProductASInDB as false 
    //         // that means if rfqProductASInDB is false, user type in name which does not exist
            
    //         if (this.params.value === null || !this.params.value ) {
    //           model.rfqProductASInDB = true;
    //         } else {
    //           model.rfqProductASInDB = !this.noResult && data.filter( item => item.productName  === this.params.value ).length === 1;
    //         }
    //       } else {
    //         model.rfqProductASInDB = true;
    //       }
    //     });
    // });
    this.dataSource = Observable.create((observer: any) => { // Runs on every search
      this.params.dataGetter(this.params.value).subscribe(data => { // get list of product names from server
          observer.next(data);
          
          // get model of current row 
          // const model = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data;
          const model = this.params.api.getModel().getRow(this.params.rowIndex).data;

          if (this.sharedService.user.representative === 'AS') {
            // search in obtained data for item with same productName as user type in. if result was found, is paired
            // one to one between user value and DB value which really exists. if doesnt, set model.rfqProductASInDB as false 
            // that means if rfqProductASInDB is false, user type in name which does not exist
            
            if (this.params.value === null || !this.params.value ) {
              model.rfqProductASInDB = true;
            } else {
              model.rfqProductASInDB = !this.noResult && data.filter(item => item === this.params.value).length === 1;
            }
          } else {
            model.rfqProductASInDB = true;
          }
        });
    });
  }

  ngAfterViewInit() { // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    setTimeout(() => {
        this.input.element.nativeElement.focus();

        const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
        for (var i = 0; i < data.originalValues.length; ++i) {
            if (this.params.column['colId'] == data.originalValues[i].colId && this.params.value != data.originalValues[i].value) {
                if (this.params.value !== null  &&  !data.originalValues[i].value) {  
                  this.onChange(); // already changed (e.g. when user selects icharacters in input and then starts to write)
                }
                break;
            }
        }
        this.cdRef.detectChanges();
    }, 0);
  }

  agInit(params: MyParams): void {
    // console.log('params', params);
    this.params = params;
    // if (this.params.value && this.params.value !== null && this.params.value !== '') {
    //   this.asyncSelected = this.params.value;
    // }
    if (params.allowOnlyFromList) { this.allowOnlyFromList = params.allowOnlyFromList; }

    // To allow using arrows with the typeahead component
    // https://www.ag-grid.com/documentation/javascript/component-cell-editor/#option-2---suppress-keyboard-event
    params.column.getColDef().suppressKeyboardEvent = this.suppressKeyboardEvent;
  }

  private suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
    // These keys are handled by this component and will not be passed to ag-grid 
    const KEYS_HANDLED_LOCALLY = [
      37, // KEY_LEFT
      39, // KEY_RIGHT
      38, // KEY_UP
      40, // KEY_DOWN
      13, // ENTER
      9 // TAB
    ];
    const keyCode = params.event.keyCode;
    const gridShouldDoNothing = params.editing && (KEYS_HANDLED_LOCALLY.indexOf(keyCode) >= 0);
    // console.log('suppressKeyboardEvent keyCode', keyCode, 'gridShouldDoNothing', gridShouldDoNothing);
    return gridShouldDoNothing;
  }

  isPopup(): boolean {
    return false;
  }
  
  isValid() {
    // let data = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data;
    const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
    return (this.params.isColValid
        ? this.params.isColValid(this.params.column['colId'], data[this.params.column['colId']], data)
        : true);
  }

  // Required by ag-grid
  public getValue(): any {
    return this.params.value;
  }

  // getStyle() {
  //   return {
  //       // Mark red when not valid
  //       'background-color': !this.isValid() ? 'rgba(255, 84, 98, 0.5)' : '',
  //       'width.px': this.params.column['actualWidth'] - 6,
  //       'max-width': this.maxWidth
  //   }
  // }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.params.value = e.value;

    // const model = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data;
    const model = this.params.api.getModel().getRow(this.params.rowIndex).data;
    model.rfqProductASInDB = true;

    this.onChange();
  }

  public onChange(): void {
    this.params.value = this.params.value.trim();
    
    setTimeout(() => {
      // const data = this.params.api.getModel().rowsToDisplay[this.params.rowIndex].data;
      const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
      
      data.edited = true;
      data[this.params.column['colId']] = this.params.value;
      
      if (this.params.editedIdsObj) {
        this.params.editedIdsObj[data.id] = true;
      }
      // TODO: we need input editor to highlight input changed row because of loosing focus
      // select if data updated and item was not in selected before or is not new selected
      // if (this.params.selection) {
      //   if (this.params.selection.ids[data.id] && !this.params.selection.newSelectedIds[data.id]) {
      //     this.params.selection.updatedIds[data.id] = data;
      //   }
      // }

      // if (this.params.subjectReloadCellEditable) {
      //   this.params.subjectReloadCellEditable.next({
      //     data: data,
      //     rowIndex: this.params.rowIndex,
      //     params: this.params
      //   });
      //   // tell other cells listening to this to reload editable value
      // }

      if (this.params.cellChanged$ && this.params.value !== this.lastEmmitedValue) {
        this.lastEmmitedValue = this.params.value;

        const change: CellChange = {
          data: data,
          rowIndex: this.params.rowIndex,
          changedColumnField: this.params.column.getColId(),
          api: this.params.api,
          node: this.params.node
        };
        // console.log('emitting change', change);
        this.params.cellChanged$.next(change);
      }

      this.sharedService.appComponent.cdRef.detectChanges();
    }, 0);
  }

  ngOnDestroy() {
    this.cdRef.detach(); // try this
  }
  
  public typeaheadNoResults(event: boolean): void {
    this.noResult = event;
    // console.log('this.noResult', this.noResult);
  }

}
