<div class="row-container wider" *ngIf="params">
    <h4><span translate>ADD_TO_RFQ_DIALOG_LABEL</span></h4>
    <div>
        <ecm-table
            [dataGetter]="getRfqs"
            [optionsGetter]="getGridOptions"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [suppressToUrl]="true"
            [tableNamePrefix]="'AddToRfqDialog'"></ecm-table>
    </div>
    <div fxLayout="row" class="row-container bigger">
        <div fxFlex="50">
            <button class="btn-secondary" (click)="dismiss()" style="width: 100px;">
                  <span class translate>CLOSE</span>
            </button>
        </div>
        <div fxFlex="50" fxLayoutAlign="end">
            <busy-indicator *ngIf="busy"></busy-indicator>
            <button class="btn-primary" (click)="confirm()" style="min-width: 100px;" [disabled]="disableSubmit" [ngClass]="{'ec-disabled': disableSubmit}">
                  <span class translate>ADD_TO_NEW_RFQ</span>
            </button>
        </div>
    </div>
</div>