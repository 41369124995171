import { Observable ,  Subject } from 'rxjs';
import { ShipmentDialog } from './shipment.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class ShipmentDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: any): Observable<any> {
        this.bsModalRef = this.modalService.show(ShipmentDialog);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}