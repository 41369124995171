import { Observable ,  Subject } from 'rxjs';
import { DiscountDialog } from './discount.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class DiscountDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: any): Observable<{res: 'confirm', textValue: string } | string> {
        this.bsModalRef = this.modalService.show(DiscountDialog);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
