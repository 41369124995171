import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { AreaPerm, PermissionsService } from '../services/permissions.service';

@Directive({ selector: '[permissionHide]' })
export class PermissionHideDirective implements OnInit {
    @Input() areaPerm: AreaPerm;    // single permission group defined in permissions.service
    @Input() areaPerms: AreaPerm[]; // array of permission groups defined in permissions.service (arePerm || areaPerm || ...)
    @Input() perm: string;    // single permission
    @Input() perms: string[]; // more permissions
    @Input() action: string;

    constructor(
        private el: ElementRef,
        private sharedService: SharedService,
        private permissionsService: PermissionsService) {

    }

    ngOnInit() {
        if (this.areaPerm) {
            if (!this.permissionsService.hasPermission(this.areaPerm)) {
                this.el.nativeElement.style.display = 'none';
            }
        } else if (this.areaPerms) {
            let hasPermission = false;
            this.areaPerms.forEach(perm => hasPermission = this.permissionsService.hasPermission(perm) || hasPermission);

            if (!hasPermission) {
                this.el.nativeElement.style.display = 'none';
            }
        } else {
            if (!this.sharedService.hasPermission(this.perm ? this.perm : this.perms, this.action)) {
                this.el.nativeElement.style.display = 'none';
            }
        }
    }
}
