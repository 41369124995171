<div class="row-container wider" style="width: 100%" *ngIf="params">
	<div>
		<discussion
            [itemArea]="params.itemArea"
            [itemId]="params[params.idAttrName]"
            [item]="params"
            [getItemLabel]="params.getItemLabel"
            [forceLoadOnInit]="params.loadDiscussion"
            [showOnlyEmptyChildTableKeyItems]="params.showOnlyEmptyChildTableKeyItems"
            [comentToFilterItemId]="params.showAllComments ? null : (params.lineNumber ? params.lineNumber : params.id)"
            [showPrivateControls]="params.showPrivateControls"
            [privateConfig]="params.privateConfig"
            [maxHeight]="maxHeight"
            (added)="added($event)"
            [isModal]=true>
        </discussion>
	</div>
</div>

<!-- <div style="padding-top: 15px;">
	<button class="btn-secondary" (click)="dialogRef.close(addedCount)" style="width: 100px;">
        <span translate>CLOSE</span>
    </button>
</div> -->