import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';

@Component({
    selector: 'on-delivery-order-renderer',
    template: ` <div style="padding: 5px 0; margin-top: -5px;" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <ng-template #popTemplate>
                        <div (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                            <div style="text-align: right;">
                                <i class="clickable fa fa-times-circle" (click)="close()"></i>
                            </div>
                            <div style="margin-bottom: 8px;">
                                <span translate>DELIVERED</span>:
                            </div>
                            <div *ngIf="!busy">
                                <div *ngFor="let deliveryOrder of onDeliveryOrder" style="margin-bottom: 8px;">
                                    {{deliveryOrder.deliveryNumberERP ? deliveryOrder.deliveryNumberERP : '(' + deliveryOrder.deliveryNumber + ')'}}: {{deliveryOrder.deliveryAmount | localnumber:sharedService.appSettings.language:false:false}} <span translate>UNIT</span>
                                    <div>
                                        <span translate>DELIVERY_DATE</span>: <span *ngIf="deliveryOrder.deliveryDate">{{deliveryOrder.deliveryDate | localdate:sharedService.appSettings.language}}</span>
                                        <span *ngIf="!deliveryOrder.deliveryDate" translate>WILL_BE_ASSIGNED</span>
                                    </div>
                                </div>
                                <div *ngIf="dataNotAvailable">
                                    <span translate>{{onDeliveryOrder.length === 0 ? 'DATA_NOT_AVAILABLE' : 'REMAINING_DATA_NOT_AVAILABLE'}}</span>
                                </div>
                            </div>
                            <busy-indicator *ngIf="busy"></busy-indicator>
                        </div>
                    </ng-template>
                    <div permissionHide perm="delivery-orders" action="GET">
                        <div *ngIf="!withPopover" class="fnt-primary">
                            {{params.value | localnumber:sharedService.appSettings.language:false:false}}
                        </div>
                        <div *ngIf="withPopover" class="fnt-primary clickable"
                             #pop="bs-popover" [popover]="popTemplate" container="body" triggers="" [adaptivePosition]="true" placement="right"
                             (click)="show(pop)">
                            {{params.value | localnumber:sharedService.appSettings.language:false:false}}
                        </div>
                    </div>
                    <div *ngIf="!sharedService.hasPermission('delivery-orders', 'GET')">
                        {{params.value | localnumber:sharedService.appSettings.language:false:false}}
                    </div>
                </div>
                `
})
export class GridOnDeliveryOrderRendererComponent implements AgRendererComponent {
    params:any;

    withPopover: boolean; // popover with container body cannot be in DOM a lot of times, so change alement without popover
                                  // to element with popover when this is true - when this grid cell is mouseover

    busy: boolean;                                  
    onDeliveryOrder: {deliveryNumberERP: string, deliveryNumber: string, deliveryDate: string, deliveryAmount: number}[] = [];
    dataNotAvailable = false; // set to true after load if value does not equal sum of deliveryAmount of all loaded deliveryOrders
    openedPopupTimeout:any;

    constructor(public sharedService: SharedService, 
                private renderer: Renderer2) {
    }

    agInit(params:any):void {
        this.params = params;
    }

    mouseover() {
        this.withPopover = true;
        clearTimeout(this.openedPopupTimeout);
    }
    mouseleave() {
        clearTimeout(this.openedPopupTimeout);
        var this_ = this;
        this.openedPopupTimeout = setTimeout(() => {  
            if (!this.busy) { // hide popover only if data is loaded already
                this_.withPopover = false;
            }
        }, 3000);
    }

    show(pop: any) {
        this.busy = true;
        pop.show();
        this.params.onDeliveryOrderGetter(this.params.data.id).subscribe(data => {
            // find out sum of delivery amounts on delivery orders
            let sumDeliveryAmount = 0;
            this.onDeliveryOrder = data.map(deliveryOrder => {
                sumDeliveryAmount += deliveryOrder.deliveryAmount;
                let date = new Date(deliveryOrder.deliveryDate);
                if (isNaN( date.getTime() )) {
                    deliveryOrder.deliveryDate = null;
                }
                return deliveryOrder;
            });
            // set flag that some data are not available when sum of amounts is not equal to value shown in column
            this.dataNotAvailable = (sumDeliveryAmount < this.params.value) || (sumDeliveryAmount === 0);

            this.busy = false;
            this.mouseleave();
        }, err => {
            this.busy = false;
            console.log(err)
        });
    }

    close() {
        this.withPopover = false;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}