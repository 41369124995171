import { Observable ,  Subject } from 'rxjs';
import { DiscussionDialog } from './discussion.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { DiscussionPrivateConfig } from '@app/model/discussion.model';

@Injectable()
export class DiscussionDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    // if load discussion is true, load of comments is needed in dialog e.g. in orders items
    public confirm(params: any, itemArea: string, idAttrName: string, loadDiscussion?: boolean, 
        showOnlyEmptyChildTableKeyItems? :boolean, getItemLabel? : Function, 
        showAllComments?: boolean, showPrivateControls?: boolean,
        privateConfig?: DiscussionPrivateConfig
    ): Observable<any> {

        this.bsModalRef = this.modalService.show(DiscussionDialog, { keyboard: false, ignoreBackdropClick: true });
        params.itemArea = itemArea;                 // orders
        params.idAttrName = idAttrName;             // like orderId
        params.loadDiscussion = loadDiscussion;
        params.showOnlyEmptyChildTableKeyItems = showOnlyEmptyChildTableKeyItems;
        params.getItemLabel = getItemLabel;
        params.showAllComments = showAllComments; // to not filter comments on item id
        params.showPrivateControls = showPrivateControls;
        params.privateConfig = privateConfig;
        params.isModal = true;

        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}