import { Component, ChangeDetectorRef, ViewContainerRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { SharedService } from '../../../../services/shared.service';
import { LanguageService } from '../../../../services/language.service';
import { FormService } from '../../../../services/form.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';
import { CellChange } from '@app/model/table.model';
import { Subject } from 'rxjs';
import { ICellEditorParams } from 'ag-grid-community';

export interface GridDateEditorComponentParams {
    customDate?: {
        hours?: number,
        daysIfBeforeTime?: number,
        daysIfAfterTime?: number,
        disableAfter?: number
    },
    disableWeekends?: boolean,
    allowNullDate?: boolean,
    minDateAttrName?: string,
    cellChanged$?: Subject<CellChange>
    isColValid?: (colName: string, colValue: any, row: any) => boolean;
    // deprecated
    subjectReloadCellEditable?: Subject<any>;
}

interface MyParams extends ICellEditorParams, GridDateEditorComponentParams {}

@Component({
    selector: 'app-editor-cell',
    template: `
        <div >
            <div #container>
                <my-date-picker
                    [(ngModel)]="model"
                    [options]="myDatePickerOptions"
                    [locale]="this.sharedService.appSettings.language"
                    [selector]="selector"
                    (dateChanged)="onDateChanged($event)"></my-date-picker>
            </div>
        </div>
    `,
    styles: [`
    `]
})
export class GridDateEditorComponent implements AgEditorComponent, AfterViewInit, OnDestroy {
    // allowNullDate
    params: MyParams;
    myDate: string;
    model: Object;
    myDatePickerOptions: IMyOptions;
    // Initialize selector is closed
    selector: number;

    @ViewChild('container', { read: ViewContainerRef, static: true }) container;

    constructor(private cdRef: ChangeDetectorRef,
                public sharedService: SharedService,
                private languageService: LanguageService,
                private formService: FormService) {
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        setTimeout(() => {
            this.selector = 1;
            this.cdRef.detectChanges();

            setTimeout(() => {
                this.selector = 1;
                this.cdRef.detectChanges();
                this.container.element.nativeElement.children[0].children[0].children[0].children[0].focus();
            }, 500);
        }, 500);
    }

    agInit(params: MyParams): void {
        this.params = params;
        if (params.customDate ) {
            this.myDatePickerOptions = this.formService.getDatePickerOptions(true);
            this.myDatePickerOptions.disableUntil = this.addDaysBasedOnCurrentHour(
                params.customDate.hours,
                params.customDate.daysIfBeforeTime,
                params.customDate.daysIfAfterTime
            );
            const nextYear = params.customDate.disableAfter ? params.customDate.disableAfter : 1;
            this.myDatePickerOptions.disableSince = {
                year:  moment().year() + nextYear,
                month: moment().month(),
                day:   moment().date()
            };
            this.myDatePickerOptions.disableWeekends = params.disableWeekends ? params.disableWeekends : null;
            this.myDatePickerOptions.disableDays = this.sharedService.params.holidays;
        } else {
            this.myDatePickerOptions = this.formService.getDatePickerOptions(false, params.node.data[params.minDateAttrName] ? moment(params.node.data[params.minDateAttrName]) : null);
        }

        this.myDatePickerOptions.showClearDateBtn = params.allowNullDate ? true : false;
        this.myDatePickerOptions.selectionTxtFontSize = '98%';

        this.myDate = params.value;
        if (params.value) {
            const momentDate = moment(params.value);
            this.model = { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } }
        } else {
            this.model = { date: null };
        }
    }

    /** Add days to date from now based on actual hour in day (like add 2 days if its before noon and 4 days if its after noon)
     *
     * @param {number} hour             Set hour of the day when return date should change, default: 12 (noon)
     * @param {number} daysIfBeforeTime Set number of days added to return date if current hours is lower as setted hours, default: 0
     * @param {number} daysIfAfterTime  Set number of days added to return date if current hours is higher as setted hours, default: 0
     *
     * @return {moment}                 Return current day + daysIfBeforeTime or day + daysIfAfterTime based on actual time
     */
    addDaysBasedOnCurrentHour (hour?: number, daysIfBeforeTime?: number, daysIfAfterTime?: number) {
        let correctDay;
        const now = moment().utc();
        const noon = now.clone().hour( hour ? hour : 12).minute(0).second(0).utc();

        if (now.isAfter(noon)) {
            correctDay = daysIfAfterTime ? daysIfAfterTime : 0;
        } else {
            correctDay = daysIfBeforeTime ? daysIfBeforeTime : 0;
        }
        return { year: now.year(), month: now.month() + 1, day: now.date() + correctDay };
        // return now.year(now.year()).month(now.month()).day(now.date() + daysIfAfterTime ? daysIfAfterTime : 0);
    }

    getValue(): any {
        return this.myDate;
    }

    isPopup(): boolean {
        return true;
    }

    onDateChanged(event: IMyDateModel) {
        if (this.params.allowNullDate || event.jsdate) {
            this.myDate = event.jsdate ? event.jsdate.toISOString() : null;
            this.params.stopEditing();

            if (this.params.subjectReloadCellEditable) {
                this.params.subjectReloadCellEditable.next({
                    data: this.params.node.data, rowIndex: this.params.rowIndex
                }); // tell other cells listening to this to reload editable value
            }

            if (this.params.cellChanged$) {
                this.params.cellChanged$.next({
                    data: this.params.node.data, rowIndex: this.params.rowIndex
                } as CellChange);
            }
        }
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }

    isValid() {
        const data = this.params.api.getModel().getRow(this.params.rowIndex).data;
        return (this.params.isColValid
            ? this.params.isColValid(this.params.column.getColId(), data[this.params.column.getColId()], data)
            : true);
    }
    getStyle() {
        return {
            // Mark red when not valid
            'background-color': !this.isValid() ? 'rgba(255, 84, 98, 0.5)' : '',
            // 'width.px': this.params.column.actualWidth - 6,
            // 'max-width': this.maxWidth,
            // 'text-align': (this.inputType === 'number') || (this.inputType === 'decimal') ? 'right' : 'left'
        }
    }
}
