export class Order {
    constructor(
        public id: 		        	number,
        public orderNumberCustomer: string,
        public orderNumberEComm: 	string,
        public orderNumberERP:    	string,
        public orderState:          string,
        public totalWeight:         string,
        public totalPrice:          string,
        public currency:            string,
        public orderCreated:        string,   
        public fullName:            string,  
        public sortName:            string,  
        public url:                 string,
        public orderNote:           string,
        public shippingType:        string,
        public paymentCondition:    string,
        public customerId:          string,
        public customerName:        string,
        public authorizedSellerId:  string,
        public authorizedSellerName:string,
        public postsCount:          number
    ){

    }
}

export interface OrderItem {
    active: number, // 1
    amountDelivered: number, // 0
    amountInvoiced: number, // 0
    amountOpened: number, // 10
    amountOrdered: number, // 10
    amountReserved: number, // 0
    authorizedSellerId: number, // 1
    authorizedSellerName: string, // "ZVL SLOVAKIA, a.s."
    brandCode: string, // "ZVL"
    currency: string, // "EUR"
    customerId: number, // 40
    customerName: string, // "Zákazník 40"
    dateChangedSYS: string, // "2020-06-24T15:55:44.000Z"
    deliveryDate: string, // "2020-08-25T00:00:00.000Z"
    eCommProductId: string, // "231/500KW33M"
    id: string, // "11104/231/500KW33M/ZVL/2020-08-25/A"
    itemNote: string, // "FORECAST Tender MMI 2020"
    itemState: string, // "N"
    nonCancelable: number, // 0
    openedPrice: number, // 49625
    orderCreated: string, // "2020-01-16T06:26:36.000Z"
    orderId: number, // 11104
    orderNumberCustomer: string, // "Forecast Tenders 202"
    orderNumberEComm: string, // "EO2000188"
    orderNumberERP: string, // "EO2000188"
    packing: string, // "A"
    personCreated: string, // "235 Používateľ"
    postsCount: number, // 0
    productName: string, // "231/500KW33M"
    requiredDate: null
    rowPrice: number, // 49625
    rowWeight: number, // 5554
    unitPrice: number, // 4962.5
    weight: number // 555.4
}

export interface OrderItemsResponse {
    rows: OrderItem[],
    totalCount: number, // 146063
    totalPrice: number, // 34782451.9017
    totalWeight: number // 5071349.3227
    // added locally
    totalWeightCurrent: number,
    totalValueCurrent: number
}
