import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Directive validates the specified password using specified validation function
 * 
 * Usage:
 * Template:
 * <input type="password" [(ngModel)]="password" id="password" name="password" maxlength="255" [validPassword]="isPasswordValid" required>
 * In the class:
 * isPasswordValid(password: string): {[key: string]: any} {
 *     return password.length > 8 ? null : {length: true}  // null if password is valid
 *                                                         // or object with keys indicating problems; value can be any
 * }
 */
@Directive({
  selector: '[validPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidPasswordDirective, multi: true}]
})
export class ValidPasswordDirective implements Validator {
  @Input('validPassword') isPasswordValid: Function;

  validate(control: AbstractControl): {[key: string]: any} {
    return passwordValidator(this.isPasswordValid)(control);
  }
}

/**
 * Validator function
 */
export function passwordValidator(isPasswordValid: Function): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    return isPasswordValid(control.value);
  };
}
