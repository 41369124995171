import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { SharedService } from './app/services/shared.service';
// temporarry not enterprise
// import {LicenseManager} from "ag-grid-enterprise/main";
// LicenseManager.setLicenseKey("ag-Grid_Evaluation_Key_Not_For_Production_100Devs12_April_2018__MTUyMzQ4NzYwMDAwMA==89a19fbd59241d8dce898bd4a20f2da0");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
