import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridUrlLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridUrlLinkRendererComponent/grid.url.link.renderer.component';

@Injectable()
export class JournalGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'dateCreated', name: "DATE", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'authorizedSellerName', name: "AUTHORIZED_SELLER", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'customerName', name: "CUSTOMER", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'sortName', name: "USER", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'typeName', name: "JOURNAL_TYPE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'priority', name: "JOURNAL_PRIORITY", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'process', name: "JOURNAL_PROCESS", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'journalValue', name: "JOURNAL_VALUE", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
        ];
        original = this.tableService.filterSuppressColumns(original, 'journal');
        let restored = this.sharedService.user.preferences['journalTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'journalTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let this_ = this;
        let colDefs: any = [
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name), 
                field: "dateCreated",
                width: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name), 
                field: "authorizedSellerName",
                width: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name), 
                field: "customerName",
                width: this_.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('sortName').name), 
                field: "sortName",
                width: this_.getColumn('sortName').width,
                valueGetter: 'data.fullName',
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('process').name), 
                field: "process",
                width: this_.getColumn('process').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('JOURNAL_PROCESS_' + params.data.process);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('typeName').name), 
                field: "typeName",
                width: this_.getColumn('typeName').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('JOURNAL_TYPE_' + params.data.typeCode);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('priority').name), 
                field: "priority",
                width: this_.getColumn('priority').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: function ageNowValueGetter(params) {
                    return this_.translateService.instant('JOURNAL_PRIORITY_' + params.data.priority);
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('journalValue').name),
                field: "journalValue",
                width: this_.getColumn('journalValue').width,
                cellStyle: {'text-align': 'left'},
                cellRendererFramework: GridUrlLinkRendererComponent
            },
        ];
        colDefs = this.tableService.filterSuppressColumns(colDefs, 'journal');
        return colDefs;
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], customers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        customers.unshift({id: 'NULL', name: 'AUTHORIZED_SELLER_USER'});
        let filterItems = [
            // {id: 'dateCreated', name: 'DATE', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'dateCreated', name: 'DATE', type: 'dateRange', valueFrom: '', valueTo: ''},
            {id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: 'multiselect', value: '', values: authorizedSellers},
            {id: 'customer.id', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: customers},
            {id: 'userId', name: 'USER', type: 'multiselect', search: true, value: '', values: personsIssued},
            {id: 'process', name: 'JOURNAL_PROCESS', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'erpImport', name: 'JOURNAL_PROCESS_erpImport'},
                    {id: 'user', name: 'JOURNAL_PROCESS_user'},
                    {id: 'customer', name: 'JOURNAL_PROCESS_customer'},
                    {id: 'userState', name: 'JOURNAL_PROCESS_userState'},
                    {id: 'schedNotif', name: 'JOURNAL_PROCESS_schedNotif'},
                    {id: 'priceLists', name: 'JOURNAL_PROCESS_priceLists'}
                ]
            },
            {id: 'eventType', name: 'JOURNAL_TYPE', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'disableUsr', name: 'JOURNAL_TYPE_disableUsr'},
                    {id: 'blockCust', name: 'JOURNAL_TYPE_blockCust'},
                    {id: 'asErpPoUpE', name: 'JOURNAL_TYPE_asErpPoUpE'},
                    {id: 'asErpDoUpE', name: 'JOURNAL_TYPE_asErpDoUpE'},
                    {id: 'asErpInUpE', name: 'JOURNAL_TYPE_asErpInUpE'},
                    {id: 'impProdErp', name: 'JOURNAL_TYPE_impProdErp'},
                    {id: 'enableUsr', name: 'JOURNAL_TYPE_enableUsr'},
                    {id: 'enableCust', name: 'JOURNAL_TYPE_enableCust'},
                    {id: 'logout', name: 'JOURNAL_TYPE_logout'},
                    {id: 'login', name: 'JOURNAL_TYPE_login'},
                    {id: 'createUsr', name: 'JOURNAL_TYPE_createUsr'},
                    {id: 'createCust', name: 'JOURNAL_TYPE_createCust'},
                    {id: 'stockUpCh', name: 'JOURNAL_TYPE_stockUpCh'},
                    {id: 'deDaChNoti', name: 'JOURNAL_TYPE_deDaChNoti'},
                    {id: 'prodNoTake', name: 'JOURNAL_TYPE_prodNoTake'},
                    {id: 'insertPL', name: 'JOURNAL_TYPE_insertPL'},
                    {id: 'deletePL', name: 'JOURNAL_TYPE_deletePL'}
                ]},
            {id: 'priority', name: 'JOURNAL_PRIORITY', type: 'select', value: '',
                values: [
                    {id: 'NULL', name: 'ALL_KIND_OF'},
                    {id: 'error', name: 'JOURNAL_PRIORITY_error'},
                    {id: 'info', name: 'JOURNAL_PRIORITY_info'},
                    {id: 'warn', name: 'JOURNAL_PRIORITY_warn'}
                ]
            },
            {id: 'journalValue', name: 'JOURNAL_VALUE', type: 'text', maxLength: 100, value: '', operator: 'likeBoth'},
        ]
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'journal');
        return filterItems;
    }

}

