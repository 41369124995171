import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '@services/shared.service';

@Component({
    template: 'NO UI HERE'
})
export class BaseComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService
    ) {}

    // public resetQueryParamsAfterDelete(deletedIds: number[], totalRows: number, gridNameCamel: string): boolean {
    //     let filterFound = false;
    //     if (deletedIds.length === 0 || deletedIds.length === totalRows) {
    //         for (const key in this.route.snapshot.queryParams) {
    //             if (key !== 'orderBy' && key !== 'page') {
    //                 delete this.sharedService.lastProductsOfCurrentOrderQueryParams[key];
    //                 filterFound = true;
    //             }
    //         }
    //         if (filterFound) {
    //             const lastGridName = 'last' + this.capitalize(gridNameCamel) + 'QueryParams';
    //             // delete this_.sharedService.lastProductsOfCurrentOrderQueryParams['page'];
    //             delete this.sharedService[lastGridName + 'page'];
    //             this.router.navigate([], {
    //                 queryParams: this.sharedService[lastGridName],
    //                 replaceUrl: true
    //             });
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
