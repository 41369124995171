import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { MonitoringService } from '../../services/monitoring/monitoring.service';
import { MonitoringGridService } from '../../services/monitoring/monitoring.grid.service';
import { SharedService } from '../../services/shared.service';
import { Subject } from 'rxjs';
import { Monitoring } from 'app/model/monitoring.model';
import { ExportObj } from '@app/model/table.model';

@Component({
    selector: 'monitoring',
    templateUrl: 'monitoring.component.html'
})
export class MonitoringComponent {

    totalRows: number;
    filterItems: any[];
    users: { id: string, name: string }[] = [];
    // authorizedSellers: { id: string, name: string }[] = [];
    customers: { id: number, name: string }[] = [];
    subjectRefresh = new Subject<any>();
    public exportObj: ExportObj;

    busy: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private monitoringService: MonitoringService,
        private monitoringGridService: MonitoringGridService,
        private sharedService: SharedService) {
        this.subjectRefresh = new Subject<any>();

        this.getMonitoring = this.getMonitoring.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('MONITORING'),
                export: this.monitoringService.exportAll.bind(this.monitoringService)
            };
        });

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });
    }

    ngOnInit() {
        const this_ = this;
        setTimeout(function () {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastMonitoringQueryParams);
        }, 0);
    }

    getMonitoring(params) {
        const this_ = this;
        this.busy = true;
        let query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastMonitoringQueryParams = {};
        for (var key in this.route.snapshot.queryParams) {
            this.sharedService.lastMonitoringQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key != 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        if (query.skip >= 0 && query.top > 0) {
            this.monitoringService.getMonitoring(query)
                .subscribe(data => {
                    // console.log('data', data);
                    this.totalRows = data.totalCount;
                    this.users = data.personsCreated;
                    // this.authorizedSellers = data.authorizedSellers;
                    this.customers = this.sharedService.user.availableCustomers.map(customer => { return {id: customer.id, name: customer.name}} );
                    if (!this.filterItems) { // only if not already to not loose fererence of selected
                        this.filterItems = this.getFilterItems();
                    }
                    params.successCallback(data.rows, data.totalCount);
                    this.busy = false;
                }, err => {
                    console.log(err);
                    this.busy = false;
                });
        }
    }
    getGridOptions() {
        return this.monitoringGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    getFilterItems() {
        return this.monitoringGridService.getFilterItems(this.customers, this.users); // this.authorizedSellers,
    }
    getColumns() {
        return this.monitoringGridService.getColumns();
    }
    getColumnsDefs() {
        return this.monitoringGridService.getColumnDefs();
    }



    printExportOrders() {
        console.log('printExportOrders');
    }
}