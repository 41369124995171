import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

export interface GridTranslateRendererComponentParams {
    translatePrefix: string;
}
interface MyParams extends ICellRendererParams, GridTranslateRendererComponentParams {}

@Component({
    selector: 'link-cell',
    // template: `<span translate>{{params.data[params.column.colId] ? params.translatePrefix + params.data[params.column.colId] : ''}}</span>`
    template: `<span translate>{{params.data[params.column.getColId()] ? params.translatePrefix + params.data[params.column.getColId()] : ''}}</span>`
})
export class GridTranslateRendererComponent implements AgRendererComponent {
    public params:MyParams;

    constructor() {
    }

    agInit(params: MyParams):void {
        this.params = params;
    }
    
    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}