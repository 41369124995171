import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
import { Invoice } from '../../model/invoice.model';
import { LanguageService } from '../../services/language.service';
import { LocalNumberPipe } from '../../locale.pipes.module';
import * as FileSaver from 'file-saver';

@Injectable()
export class InvoicesService {
    
    constructor (private http: EcmHttpService, 
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService,
                 private toastService: ToastService) {
    }

    /**
     * Loads list of customer of authorized seller
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getInvoices (query) {
        var this_ = this;
        let url = `/invoices`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => { 
            var deliveryOrders: Invoice[] = [];
            data.rows.forEach(function(item){
                deliveryOrders.push(
                    new Invoice(
                        item.id,
                        item.customerId,
                        item.customerName,
                        item.authorizedSellerName,
                        item.sellerName,
                        item.invoiceNumber,
                        item.issueDate,
                        item.taxDate,
                        item.dueDate,
                        item.paymentCondition,
                        item.totalPrice,
                        item.currency,
                        'invoice/' + item.id
                    )
                );
            })
            return {rows: deliveryOrders, total: data.totalCount};
        }));
    }

    /**
     * Loads delivery order header
     *
     * @param id - id of delivery irder
     */
    getInvoice (id: number) {
        return this.http.get(`/invoices/` + id + `/header`)
        .pipe(map((data) => {
            return new Invoice(
                data.id,
                data.customerId,
                data.customerName,
                data.authorizedSellerName,
                data.sellerName,
                data.invoiceNumber,
                data.issueDate,
                data.taxDate,
                data.dueDate,
                data.paymentCondition,
                data.totalPrice,
                data.currency,
                'invoice/' + data.id
            );
        }));
    }

    /** 
     * Loads items list of delivery order
     *
     * @param id    - id of delivery order
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getInvoiceItems(id: number, query: any) {
        var this_ = this;
        let url = `/invoices/`+ id + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/invoices`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports detail items
     */
    exportDetail(id, query) {
        let url = `/invoices/`+ id + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports invoice with given id to given format file with name containing given invoiceNumber
     * @param ids - array of ids to be exported, if null export all
     * @param invoiceNumber - number of invoice to be exported
     * @param format - format to be exported
     * @param suppressFirstPartOfFileName - true if not to include first part of file name - to use only invoiceNumber value for file name
     */
    export(ids: string[], invoiceNumber: string, format: string, suppressFirstPartOfFileName?: boolean) {
        let columns = ['authorizedSeller', 'invoiceNumber', 'issueDate', 'taxDate', 'dueDate', 'paymentCondition', 'orderNumber', 
            'productCode', 'productName', 'brandCode', 'amount', 'unit', 'unitPrice', 'totalPrice', 'totalPriceWithVAT', 'VATRate', 'currency', 
            'totalWeight', 'itemNote', 'packing'];
        let query:any = {
            exportToFormat: format,
            exportColumns: columns,   
            orderBy: 'invoiceNumber ASC'
        }
        if (ids) {
            query['id.operator'] = 'in';
            query['id.value'] = ids.join(',');
        }else {
            query = Object.assign(this.sharedService.lastInvoicesQueryParams, query);
            delete query.page;
        }
        let url = `/invoices-items`;
        this.http.get(url, this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, {responseType: 'text'}))
        ).subscribe(data => {
            let mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            let blob = new Blob(['\ufeff' + data], {type: mediaType});
            let filename = (suppressFirstPartOfFileName ? '' : (this.translateService.instant("INVOICE") + '_')) + invoiceNumber + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }
}

