import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import * as FileSaver from 'file-saver';


@Injectable()
export class BusinessConditionsService {
    constructor(private http: EcmHttpService,
        private sharedService: SharedService) {

    }

    downloadBusinessConditions() {
        this.http.getLocal(this.getBusinessConditionsUrl(), { responseType: 'blob' }).subscribe(data => {
            let blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, 'Business conditions.pdf');
        }, err => console.log(err));
    }

    getBusinessConditionsUrl() {
        return this.sharedService.assetsS3BucketUrl + '/' + this.sharedService.appSettings.language + '/Business conditions.pdf';
    }
}