<div class="row-container" *ngIf="params">
 	<div *ngIf="params.successCount > 0">
		<h4 class="fnt-green"><span translate>INSERT_TO_SHIPMENT_SUCCESS</span>: {{params.successCount}}</h4>
	</div>

	<div style="margin-top: 10px" *ngIf="params.errors.length > 0">
		<div fxLayout="row">
			<h4 class="fnt-orange"><span translate>INSERT_TO_SHIPMENT_ERROR</span></h4>

			<button class="btn-secondary" style="min-width: 200px; margin-left: 10px;" (click)="copyErrorsToClipboard()">
				<i class="fnt-primary fa fa-clipboard"></i>
				<span translate>COPY_ERRORS_TO_CLIPBOARD</span>
			</button>
		</div>

		<div style="display: block; overflow-y: auto; min-height: 240px;"  [ngStyle]="{'max-height.px': maxHeight - (this.params.successCount > 0 ? 30 : 0)}">
			<div fxLayout="row">
				<div fxFlex="25" style="display: inline-block; margin-right: 5px;  margin-left: 5px;" translate>PRODUCT_ID</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 4px;  margin-left: 0px;" translate>AMOUNT_SHIPMENT</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 0px;" translate>ORDER_ID</div>
			</div>
			<div fxLayout="row" *ngFor="let item of params.errors; let odd=odd; let even=even;" [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;  margin-left: 5px;" [ngClass]="{'fnt-orange': item.error._other}">
					<div style="margin: 4px 0;" [ngClass]="{'fnt-orange': item.error.eCommProductId}">
						{{item.row.eCommProductId}}
					</div>
					<div class="fnt-orange" style="margin-top: -5px;" *ngIf="item.error.eCommProductId == 'Product not found'">
						<span style="font-size: 75%;" translate>PRODUCT_NOT_FOUND</span>
					</div>
					<div class="fnt-orange" style="margin-top: -5px;" *ngIf="item.error._other == 'eCommProductId not ordered'">
						<span style="font-size: 75%;" translate>ECOMMPRODUCTID_NOT_ORDERED</span>
					</div>
				</div>
				<div fxFlex="25" style="display: inline-block; margin-right: 10px;">
					<div style="margin: 4px 0;" [ngClass]="{'fnt-orange': item.error.shipmentAmount}" *ngIf="item.row.shipmentAmount > 0">
						<span translate>REQUESTED</span>: {{item.row.shipmentAmount}} <span translate>UNIT</span>
					</div>
					<div style="margin-bottom: 2px;">
						<div style="margin: 4px 0;" *ngIf="item.row._missingAmount > 0">
							<i class="fa fa-check-circle fnt-green" style="margin-right: 5px"></i><span translate>TO_SHIPMENT</span>: {{item.row.shipmentAmount - item.row._missingAmount}} <span translate>UNIT</span>
						</div>
						<div *ngIf="item.row.shipmentAmount.length == 0" style="margin: 4px 0;" class="fnt-orange" translate>INSERT_TO_BASKET_AMOUNT_MISSING</div>
						<div *ngIf="item.error._other == 'Not enough amount to ship'">
							<i class="fa fa-times-circle fnt-orange" style="margin-right: 5px"></i><span translate>NOT_ENOUGH_AMOUNT_TO_SHIP</span>: {{item.row._missingAmount}} <span translate>UNIT</span>
							<div style="font-size: 75%;" *ngIf="item.row._orderedNotReserved > 0">
								<span translate>ORDERED_NOT_RESERVED</span>: {{item.row._orderedNotReserved}} <span translate>UNIT</span>
							</div>
						</div>
					</div>
				</div>
				<div fxFlex="25" style="display: inline-block;">
					<div style="margin-top: 4px;" [ngClass]="{'fnt-orange': item.error.orderNumberEComm}">
						{{item.row.orderNumberEComm}}
		            </div>
					<div style="margin-top: -5px;" *ngIf="item.error.orderNumberEComm">
						<span style="font-size: 75%;" [ngClass]="{'fnt-orange': item.error.orderNumberEComm}" translate>{{item.error.orderNumberEComm}}</span>
					</div>
				</div>
				<div fxFlex="25" style="display: inline-block;">
					<div style="margin-bottom: 2px" *ngIf="!item.alreadyAddedToBasket">
						<div *ngIf="item.error._other === 'Not enough amount to ship' && item.row._missingAmount - item.row._orderedNotReserved <= 0">
							<span translate>NO_NEED_TO_ORDER</span>
						</div>
						<div *ngIf="(item.error._other === 'Not enough amount to ship' && item.row._missingAmount - item.row._orderedNotReserved > 0) || item.error._other === 'eCommProductId not ordered'">
							<span translate>ADD_TO_ORDER</span>: 
							<div>
								<span *ngIf="item.error._other === 'Not enough amount to ship'">{{item.row._missingAmount - item.row._orderedNotReserved}} </span>
								<span *ngIf="item.error._other === 'eCommProductId not ordered'">{{item.row.shipmentAmount}} </span>
								<span translate>UNIT</span> 
								<span class="clickable" (click)="addToBasket(item)" style="font-size: 20px; margin-left: 4px;" *ngIf="!item.busy">
				    				<i class="fa fa-plus-square fnt-primary"></i>
				    			</span>
				    			<busy-indicator *ngIf="item.busy" style="display: inline-block; width: 21px; height: 21px;"></busy-indicator>
     						</div>
		    			</div>
					</div>
					<div *ngIf="item.alreadyAddedToBasket">
						<i class="fa fa-check-circle fnt-green" style="margin-right: 5px"></i><span translate>ADDED_TO_ORDER</span>: 
						<span *ngIf="item.error._other === 'Not enough amount to ship'">{{item.row._missingAmount - item.row._orderedNotReserved}} </span>
						<span *ngIf="item.error._other === 'eCommProductId not ordered'">{{item.row.shipmentAmount}} </span>
						<span translate>UNIT</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div style="padding-top: 15px;">
		<button class="btn-secondary" (click)="close()" style="width: 100px;">
	        <span translate>CLOSE</span>
	    </button>
	    <button class="btn-primary" style="float: right" routerLink="/{{sharedService.appSettings.language}}/shipment/{{params.shipmentId}}" (click)="close({reload: true})">
	        <span translate>SHIPMENT_GO_TO_NEW</span>
	    </button>
	</div>
</div>