import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'question-dialog',
    template: `
        <div class="row-container wider bigger" *ngIf="params">
            <h3 [ngClass]="params.textClass"><span translate>{{params.message}}</span>.</h3>
            <span [ngClass]="params.textClass"><span translate>{{params.question}}</span>{{params.suppressQuestionMark ? '' : '?'}}</span>
            <div fxLayout="row" class="row-container top-padded">
                <div fxFlex="50">
                    <button class="btn-secondary" (click)="dismiss()" style="width: 100px;" *ngIf="params.secondary">
                          <span class translate>{{params.secondary}}</span>
                    </button>
                </div>
                <div fxFlex="50" fxLayoutAlign="end">
                    <button class="btn-primary" (click)="confirm()" style="min-width: 100px;" [disabled]="disableSubmit" [ngClass]="{'ec-disabled': disableSubmit}">
                          <span class translate>{{params.primary}}</span>
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class QuestionDialog {
    doneSubject: Subject<any>;
    params: any;

    disableSubmit = false;

    constructor(public bsModalRef: BsModalRef) {

    }

    confirm() {
        this.disableSubmit = true;
        this.doneSubject.next('confirm');
        this.bsModalRef.hide();
    }

    dismiss() {
        this.doneSubject.next('dismiss');
        this.bsModalRef.hide();
    }
}
