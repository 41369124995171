import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { Observable } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridApi, RowNode } from 'ag-grid-community';

export interface OriginalValue {
    colId: string; // 'amountOrdered'
    value: any; // 1
}

export interface CellChange {
    data: any; // grid data row
    rowIndex: number;
    changedColumnField?: string;
    api?: GridApi;
    node?: RowNode;
}

export interface EditedIdsObject {
    [key: string]: boolean
}

export interface BaseSelection {
    all: boolean;
    ids: {
        [key: string]: any;
    };
    visible: boolean;
    newSelectedIds?: {
        // [key: string]: boolean
        [key: string]: any
    };
    updatedIds?: {
        [key: string]: any
    };
    deselectedIds?: {
        [key: string]: any
    }
}

export interface BaseTSelection<T> {
    all: boolean;
    ids: {
        [key: string]: T;
    };
    visible: boolean;
    newSelectedIds?: {
        // [key: string]: boolean
        [key: string]: T
    };
    updatedIds?: {
        [key: string]: T
    };
    deselectedIds?: {
        [key: string]: T
    }
}

export interface TableColumn {
    id: string; // 'dateCreated'
    name: string; // 'DATE'
    alwaysVisible?: boolean; // true
    checked: boolean; // true
    orderBy: boolean; // true
    orderDirection?: string; // 'DESC'
    width: number; // 120
    hidden?: boolean; // true
    doNotShowInTableSettings?: boolean;
}

export interface TableDataGetterParams {
    startRow: number;
    endRow: number;
    successCallback: (rowData: any[], total: number) => void,
    filter?: any;
    orderBy?: { colId: string, sort: string };
}

export type ExportFunction = (query: EcmHttpQueryParams) => Observable<{exportUrl: string}>; // function thar returns observable of data with url to download file

export interface ExportObj {
    name?: string; // name displayed in the dropdown export menu (if there are multiple exports)
    fileName: string; // name for exported file
    export: ExportFunction;
    excludeCols?: string[]; // array of excluded column ids
    customColNames?: any; // object {<col id>: <name for col>, ..}
}

export interface RowActionsDef {
    id: string; // 'delete'
    type: 'COUNT' | 'ICON_ONLY';
    iconClass: string; // 'fa-trash'
    method: (params: ICellRendererParams) => void;
    popover?: string; // 'DELETE_SELECTED'
    attrName?: string; // 'postsCount'
    isVisible?: (params: ICellRendererParams, rowActionsDefId: string) => boolean;
}

export enum TableBulkTypes {
    'button' = 'button',
    'date' = 'date',
    'select' = 'select',
    'input' = 'input'
}

export type TableBulkClickFn = (itemIds: string[]) => void;
export type TableBulkItemUpdateFn = (itemIds: string[], newValues: any) => Observable<any>;
export type TableBulkUpdateFn = (itemIds: string[], newValues: any) => Observable<any>;

export interface TableBulkActionItem {
    id: string; // must be unique; if it is a bulk update, must be attr. name; 'dateOfferedDays'
    name: string; // language code; 'DUPLICATE'
    type: TableBulkTypes;
    iconClass?: string; // 'fa fa-clone'
    value?: any; // null
    values?: { id: string, name: string }[],
    click?: TableBulkClickFn, // required for button
    itemUpdateFn?: TableBulkItemUpdateFn, // optional for dropdown, date picker and input;
                                          // if set it overrides the general 'update; function from TableBulk
    buttonLabel?: string; // forces select label to be this regardless of the selected option
    itemsCanDo?: number // counter on how many items (rows) can the action be applied
    items?: number;
    primary?: string; // primary button text, e.g. 'CONTINUE'
    secondary?: string; // primary button text, e.g. 'CLOSE'
    prefixString?: string; // 'D + '
    width?: string; // input width style, e.g. '40px'
}

export interface TableBulk {
    // 'update' method is called for bulk item actions of these types:
    // - dropdown 
    // - date picker
    // - input
    // However, TableBulkActionItem must be defined even for these items, but without the click fn
    update ?: TableBulkUpdateFn;
    // For other types of action use 'click' function on TableBulkActionItem-s
    items: TableBulkActionItem []
};

export interface StateColorsObject {
    gray?: string,
    red?: string,
    yellow?: string,
    blueDarker?: string,
    blue?: string,
    orange?: string,
    green?: string,
    black?: string,
    lime?: string
}

export interface BatchItemsUpdateResponse {
    successCount: number, // 2
    errors: any []
}

export interface BulkItemsUpdateResponse<T> {
    successCount: number,
    errors?: {
        row: Partial<T>, // original object
        error: {[attrName: string]: string} // error message for the attr
    }[]
}
