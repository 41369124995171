<div [ngClass]="{'component-outer-container': makeCollapsible}">
  <div class="discussion">
    <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()"
        [attr.role]="makeCollapsible ? 'button': ''" data-target="collapseDiscusion">
    <h3><i class="fa fa-comments"></i> 
      <span translate>DISCUSSION</span>&ngsp;
      <span *ngIf="item && !showOnlyEmptyChildTableKeyItems">
        <span *ngIf="item && !showOnlyEmptyChildTableKeyItems && !showMergedLabel">
          <span translate>DISCUSSION_TO_ROW</span>{{ item.lineNumber }}</span>
        <span *ngIf="showMergedLabel && !itemLabel">

            <span translate>DISCUSSION_TO</span>:
            <span *ngIf="itemLabel"> {{itemLabel}}</span>
        </span>
        
        <div>
          <span *ngIf="showMergedLabel && !itemLabel">
            <i class="fa fa-comments" style="visibility: hidden"></i> 
            <span *ngIf="item.productName">{{item.productName}} / </span>
            <span *ngIf="sharedService.brands.length > 1 && item.brandCode">{{item.brandCode}} / </span>
            <span *ngIf="item.productName && showDeliveryDate">{{item.deliveryDate | localdate:sharedService.appSettings.language}} / </span>
            <span *ngIf="item.packing" translate>{{'PACKING_' + item.packing}}</span>
          </span>
        </div>
      </span>
      <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }" class="fa" style="float: right"></i>
    </h3>
    

    </div> <!-- end of component header container --->
    <div [@slideInOut]="isCollapsed"  [ngClass]="{'component-body-container': makeCollapsible}" id="collapseDiscusion" >


      <div *ngIf="loadBusy" fxLayoutAlign="center">
        <busy-indicator></busy-indicator>
      </div>
      <div #scrollContainer class="comments"[ngStyle]="{'max-height.px': maxHeight - 180}">
        <div class="comment row-container" *ngFor="let comment of comments; let isLast = last;" [ngClass]="{'last': isLast}">
          <div fxLayout="row">
            <div fxFlex="100">
              <div class="display-item" fxFlex="50">
                <strong class="name">{{comment.fullName}} <span class="time">{{comment.dateCreated | localDateTimeFromNow:sharedService.appSettings.language}}</span></strong>
              </div>
              <div class="display-item" fxFlex="50" fxLayoutAlign="end" *ngIf="comment.childTableKey.length > 0 && !comentToFilterItemId">
                <span>
                  <span *ngIf="comment.commentItemLabel"><span class="comment-to"><span translate>DISCUSSION_COMMENT</span></span> {{comment.commentItemLabel}}</span>
                  <span *ngIf="showMergedLabel && !comment.commentItemLabel">
                    <span class="comment-to"><span translate>DISCUSSION_COMMENT_TO_ITEM</span>:</span>
                    <span>{{comment.displayItem.productName}} / </span>
                    <span *ngIf="sharedService.brands.length > 1 && comment.displayItem.brandCode">{{comment.displayItem.brandCode}} / </span>
                    <span *ngIf="comment.displayItem.deliveryDate !== undefined">{{comment.displayItem.deliveryDate | localdate:sharedService.appSettings.language}} / </span>
                    <span translate>{{comment.displayItem.packing ? 'PACKING_' + comment.displayItem.packing : ''}}</span>
                  </span>
                  <ng-template #popReply><span translate>DISCUSSION_REPLY</span></ng-template>
                  <i permissionHide [perm]="itemArea + '/*/discussion'" action="POST" 
                    class="fa fa-reply clickable" style="margin: 3px 5px" (click)="reply(comment)" [popover]="popReply" 
                    container="body" triggers="mouseenter:mouseleave"></i>
                </span>
              </div>
    
              <div class="display-item" fxFlex="50" fxLayoutAlign="end" *ngIf="!(comment.childTableKey.length > 0 && !comentToFilterItemId)">
                <!-- just a space holder-->
              </div>
    
              <div class="display-item" fxFlex="5" fxLayoutAlign="end" *ngIf="showPrivateControls && !comment.privateItem">
                <ng-template #privateInfo><span>{{privateConfig.iconTitle | translate}}</span></ng-template>
                <i class="fa fa-info-circle alert-danger" [popover]="privateInfo" container="body" triggers="mouseenter:mouseleave"
                  [adaptivePosition]="true" placement="left"></i>
              </div>
            </div>
          </div>
          <div fxLayout="row">
          <div class="text">
            {{comment.discussionText}}
          </div>
        </div>

      </div>
      <div id="newComment" class="new-comment">
        <div class="display-item" fxLayoutAlign="end" *ngIf="showMergedLabel && item && !comentToFilterItemId">
          <i class="fa fa-times-circle clickable" style="margin: 3px 5px;" (click)="item = null"></i>
          <span translate>DISCUSSION_COMMENT_TO_ITEM</span>:&nbsp;<span *ngIf="itemLabel"> {{itemLabel}}</span>
          <span *ngIf="!itemLabel"> {{item.productName}} / <span *ngIf="sharedService.brands.length > 1 && item.brandCode">{{item.brandCode}} / </span>
            <span *ngIf="item.deliveryDate !== undefined">{{item.deliveryDate | localdate:sharedService.appSettings.language}} /</span>
            <span translate>{{'PACKING_' + item.packing}}</span>
          </span>
        </div>
        <textarea permissionHide [perm]="itemArea + '/*/discussion'" action="POST"
          #newCommentInput class="form-control" [(ngModel)]="newComment" (ngModelChange)="commentOnchange()" 
          placeholder="{{ 'DISCUSSION_NEW_PLACEHOLDER' | translate }}" [disabled]="showNotSavedQuestion"></textarea>

        <div fxLayout="row" style="margin-top: 15px;" *ngIf="showNotSavedQuestion" class="fnt-orange">
          <span translate>WARNING_DATA_NOT_SAVED_CONTINUE_ANYWAYS</span> ?
        </div>

        <div fxLayout="row" style="margin-top: 5px;" *ngIf="showPrivateControls">
          <div fxFlex="50 0" fxLayout="row" fxLayoutAlign="end center" class="row-container">
            <span>{{privateConfig?.dropdownText.title | translate}}:</span>
          </div>
          <div fxFlex="50 0" fxLayout="row" fxLayoutAlign="end" class="row-container">
            <select class="form-control" style="width: auto;"
              [(ngModel)]="isNewCommentPrivate"
              (ngModelChange)="onIsNewCommentPrivateChange($event)">

              <option [ngValue]="true">{{privateConfig?.dropdownText.privateText|translate}}</option>
              <option [ngValue]="false">{{privateConfig?.dropdownText.publicText|translate}}</option>
            </select>
          </div>
        </div>

        <div fxLayout="row" style="margin-top: 5px;">
          <div fxFlex="50" fxLayout="row" class="row-container">
            <button class="btn-secondary" *ngIf="isModal && !showNotSavedQuestion" (click)="close()">
              <span translate>CLOSE</span>
            </button>
            <button class="btn-secondary" (click)="doNotClose()" *ngIf="showNotSavedQuestion">
              <span translate>NO</span>
            </button>
          </div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" class="row-container">
            <busy-indicator *ngIf="sendBusy"></busy-indicator>
            <button permissionHide [perm]="itemArea + '/*/discussion'" action="POST" *ngIf="!showNotSavedQuestion"
              class="btn-primary" (click)="sendNewComment()"
              [disabled]="newComment.length === 0 || sendBusy || showPrivateControls && isNewCommentPrivate === null" 
              [ngClass]="{'ec-disabled': newComment.length === 0 || sendBusy}">
              <i class="fa fa-comment-o"></i>
              <span translate>DISCUSSION_NEW_SEND</span>
            </button>
            <button class="btn-primary" (click)="forceClose()" *ngIf="showNotSavedQuestion">
              <span translate>YES</span>
            </button>
          </div>
        </div>
        <div fxLayout="row" style="margin-top: 5px;" *ngIf="showPrivateControls && isNewCommentPrivate === false">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="end" class="row-container">
            <span class="alert-danger" id="privateConfigWarning">{{privateConfig.buttonWarning | translate}}</span>
          </div>
        </div>

      </div>
    </div><!-- end of component body container --->
  </div>

</div>
