<div class="row-container wider" *ngIf="customer" style="margin-bottom: 200px;">
    <div class="row-container">
        <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/customers" [queryParams]="sharedService.lastCustomersQueryParams">
            <i class="fa fa-arrow-circle-left"></i>
            <a translate>BACK</a>
        </button>
    </div>
    <div class="row-container">
        <h3 ><i class="fa fa-briefcase"></i> <span translate>CUSTOMER</span> {{customer.customerName}}</h3>
    </div>
    <form (ngSubmit)="save(customerForm.form)" #customerForm="ngForm">
        <div fxLayout="column" fxLayout.gt-sm="row">
            <div fxFlex.gt-sm="50" fxFlex="100">
                <div class="row-container base-panel" fxLayout="column">

                    <div *ngIf="sharedService.params.features.customer.eRPnonErpOption" class="input-container" style="max-width: 300px;">
                        <label id="erp_customer" title="erp_customer" translate>ERP_CUSTOMER</label>
                        <!-- [disabled] and [attr.disabled] doesnt work with binding. roll back to ngIf -->
                        <!-- https://github.com/angular/angular/issues/18206 -->
                        <!-- [attr.disabled]="!isNewCustomer && customer.isERPCustomer -->
                        <div *ngIf="!isNewCustomer && customer.isERPCustomer">
                            <span style="display: inline-block; min-width: 25%">
                                <input type="radio" (click)="ReplaceAllAvailableGroups(true)" [(ngModel)]="isERPCustomer"
                                    name="erp_customer" id="erp_custormer_A"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="true" style="float:left;margin-right:10px"
                                    disabled="disabled">
                                <label for="erp_custormer_A" translate> YES</label>
                            </span>
                            <span style="display: inline-block; min-width: 25%">
                                <input (click)="ReplaceAllAvailableGroups(false)" type="radio" [(ngModel)]="isERPCustomer"
                                    #neErpCustomerFalse name="erp_customer" id="erp_custormer_B"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="false" style="float:left;margin-right:10px"
                                    disabled="disabled">
                                <label for="erp_custormer_B" translate> NO</label>
                            </span>
                        </div>

                        <div *ngIf="isNewCustomer || !customer.isERPCustomer">
                            <span style="display: inline-block; min-width: 25%">
                                <input (click)="ReplaceAllAvailableGroups(true)" type="radio" [(ngModel)]="isERPCustomer" name="erp_customer" id="erp_custormer_C"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="true" style="float:left;margin-right:10px">
                                <label for="erp_custormer_C" translate> YES</label>
                            </span>
                            <span style="display: inline-block; min-width: 25%">
                                <input (click)="ReplaceAllAvailableGroups(false)" type="radio" [(ngModel)]="isERPCustomer" #neErpCustomerFalse name="erp_customer" id="erp_custormer_D"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="false" style="float:left;margin-right:10px">
                                <label for="erp_custormer_D" translate> NO</label>
                            </span>
                        </div>

                    </div>

                    <div class="input-container" style="max-width: 300px;">
                        <div>
                            <label translate>CUSTOMER_NAME</label>
                            <input placeholder="{{ 'CUSTOMER_NAME' | translate }}" [(ngModel)]="customer.customerName" class="form-control" id="customerName" name="customerName" #customerNameFormItem="ngModel" maxlength="50" required>
                        </div>
                        <div [hidden]="customerNameFormItem.valid || customerNameFormItem.pristine" class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column" *ngIf="customer.id === 0">
                        <div class="input-container" style="max-width: 300px;" permissionHide areaPerm="repreOverrideUserAdmin">
                            <label id="admin_user" title="admin_user" translate>CREATE_CUSTOMER_ADMIN_USER</label>
                            <span style="display: inline-block; min-width: 25%">
                                <input type="radio" (click)="createAdminUserRadioClicked(true)" [(ngModel)]="createAdminUser"
                                    name="admin_user" id="customer_admin_user_A"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="true" style="float:left;margin-right:10px">
                                <label for="customer_admin_user_A" translate>YES</label>
                            </span>
                            <span style="display: inline-block; min-width: 25%">
                                <input type="radio" (click)="createAdminUserRadioClicked(false)" [(ngModel)]="createAdminUser"
                                    name="admin_user" id="customer_admin_user_B"
                                    title="{{'ERP_CUSTOMER'|translate}}" [value]="false" style="float:left;margin-right:10px">
                                <label for="customer_admin_user_B" translate>NO</label>
                            </span>
                        </div>

                        <div fxLayout="column" *ngIf="createAdminUser">
                            <div class="input-container" style="max-width: 300px;">
                                <label translate>CUSTOMER_ADMIN_EMAIL</label>
                                <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="customer.customerAdminEmail" 
                                    class="form-control" id="customerAdminEmail" name="customerAdminEmail" 
                                    #customerAdminEmailFormItem="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" 
                                    maxlength="50" required>
                            </div>
                            <div *ngIf="customerAdminEmailFormItem.invalid && (customerAdminEmailFormItem.errors.required && customerAdminEmailFormItem.dirty)" class="alert alert-danger">
                                <span translate>REQUIRED_FIELD</span>
                            </div>
                            <div *ngIf="customerAdminEmailFormItem.invalid && (!customerAdminEmailFormItem.errors.required && customerAdminEmailFormItem.dirty)" class="alert alert-danger">
                                <span translate>WRONG_EMAIL_FORMAT</span>
                            </div>
                            <div class="input-container">
                                <label translate>LANGUAGE</label>
                                <div>
                                    <dropdown-component [items]="sharedService.languages" [selected]="customer.customerAdminLanguage" 
                                        (onSelect)="onSelectLanguageAdmin($event)" [autoClose]="true" [disableSelectedValueInButton]="false"
                                    ></dropdown-component>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row-container" fxLayout="column" *ngIf="customer.notActivatedAdminUser">
                        <form (ngSubmit)="resendInvite(adminForm.form)" #adminForm="ngForm">
                            <div class="input-container" style="max-width: 300px;">
                                <label translate>CUSTOMER_ADMIN_EMAIL</label>
                                <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="customer.notActivatedAdminUser.email" class="form-control" id="customerAdminEmail" name="customerAdminEmail" #adminFormEmail="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="50" required>
                            </div>
                            <div *ngIf="adminFormEmail.invalid && (adminFormEmail.errors.required && adminFormEmail.dirty)" class="alert alert-danger">
                                <span translate>REQUIRED_FIELD</span>
                            </div>
                            <div *ngIf="adminFormEmail.invalid && (!adminFormEmail.errors.required && adminFormEmail.dirty)" class="alert alert-danger">
                                <span translate>WRONG_EMAIL_FORMAT</span>
                            </div>
                            <div>
                                <button class="btn-secondary" style="margin-right: 10px;" [disabled]="adminFormEmail.invalid" [ngClass]="{'ec-disabled': adminFormEmail.invalid}">
                                    <i class="fa fa-envelope"></i><span translate>INVITE_SEND_AGAIN</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="base-panel">
                    <div class="row-container" fxLayout="column" *ngIf="isERPCustomer">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_CODE</label>
                            <input placeholder="{{ 'CUSTOMER_CODE' | translate }}" [disabled]="!isERPCustomer" [required]="isERPCustomer" [ngClass]="{'ec-disabled':  !isERPCustomer}" [(ngModel)]="customer.customerCode" class="form-control" id="customerCode" name="customerCode" #customerCodeFormItem="ngModel" maxlength="10">
                        </div>
                        <div [hidden]="customerCodeFormItem.valid || customerCodeFormItem.pristine" class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>        
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_LOCALITY</label>
                            <input placeholder="{{ 'CUSTOMER_LOCALITY' | translate }}" [(ngModel)]="customer.locality" class="form-control" id="locality" name="locality" #localityFormItem="ngModel"  maxlength="2" required>
                        </div>
                        <div [hidden]="localityFormItem.valid || localityFormItem.pristine" class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>COUNTRY</label>
                            <input placeholder="{{ 'COUNTRY' | translate }}" [(ngModel)]="customer.country" class="form-control" id="country"
                                name="country" #countryFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CITY</label>
                            <input placeholder="{{ 'CITY' | translate }}" [(ngModel)]="customer.city" class="form-control" id="city" name="city"
                                #cityFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>STREET</label>
                            <input placeholder="{{ 'STREET' | translate }}" [(ngModel)]="customer.street" class="form-control" id="street" name="street" 
                                #streetFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>ZIP</label>
                            <input placeholder="{{ 'ZIP' | translate }}" [(ngModel)]="customer.zip" class="form-control" id="zip" name="zip"
                                #zipFormItem="ngModel" maxlength="20">
                        </div>
                    </div>
                </div>

                <div class="base-panel">
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_PRICE_LIST_CODE</label>
                            <input placeholder="{{ 'CUSTOMER_PRICE_LIST_CODE' | translate }}" [(ngModel)]="customer.priceListCode" class="form-control" id="priceListCode" name="priceListCode" #priceListCodeFormItem="ngModel" (ngModelChange)="setAllPriceListCodes()" maxlength="10" [required]="isERPCustomer">
                        </div>
                        <div [hidden]="isERPCustomer || priceListCodeFormItem.valid || priceListCodeFormItem.pristine" class="alert alert-danger">
                            <span translate>REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row-container">
                        <div class="input-container">
                            <label translate>CUSTOMER_PRICE_LIST_CURRENCY</label>
                            <div>
                                <dropdown-component [items]="sharedService.priceListCurrencies" [selected]="customer.priceListCurrency" (onSelect)="onSelectCurrency($event, customerForm.form)" [autoClose]="true" [disableSelectedValueInButton]="false"></dropdown-component>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-container base-panel" *ngIf="isERPCustomer">
                    <label class="fnt-primary" translate>PROJECT_PRICE_LISTS</label>
                    <ul class="price-lists" style="padding: 0;">
                        <li class="price-list" fxLayout="row" *ngFor="let priceList of customer.projectPriceLists; let i = index">
                            <div fxFlex="300px" class="row-container" style="word-break: break-all;">{{priceList.priceListName}}</div>
                            <div fxFlex="20" class="row-container">{{priceList.priceListCode}}</div>
                            <div fxFlex="7" class="row-container">{{priceList.priceListCurrency}}</div>
                            <div fxFlex="7" class="row-container">
                                <ng-template #toolTemplateDelete>{{'DELETE_SELECTED' | translate | lowercase}}</ng-template>
                                <i class="clickable fa fa-trash" style="margin-right: 5px; opacity: 0.9" (click)="deletePriceList(i, customerForm.form)" [popover]="toolTemplateDelete" container="body" triggers="mouseenter:mouseleave"></i>
                            </div>
                        </li>
                    </ul>
                    <div *ngIf="newPriceListVisible">
                        <div  fxLayout="row">
                            <div fxFlex="300px" class="row-container">
                                <div class="input-container" style="width: 100%">
                                    <input type="text" placeholder="{{'PROJECT_PRICE_LIST_NAME' | translate}}" [(ngModel)]="newPriceList.priceListName" class="form-control" id="newPricelistName" name="newPricelistName" #newPriceListNameFormItem="ngModel" maxlength="50" 
                                    [validateUnique]="customer.projectPriceLists" validateUniqueAttr="priceListName" [myFocus]="newPriceListVisible" required>
                                </div>
                                <div *ngIf="newPriceListNameFormItem.invalid && !newPriceListNameFormItem.pristine">
                                    <div *ngIf="newPriceListNameFormItem.errors.required" class="alert alert-danger">
                                        <span translate>REQUIRED_FIELD</span>
                                    </div>
                                    <div *ngIf="newPriceListNameFormItem.errors.notUnique" class="alert alert-danger">
                                        <span translate>VALUE_MUST_BE_UNIQUE</span>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" class="row-container">
                                <div class="input-container">
                                    <input type="text" placeholder="{{'CUSTOMER_PRICE_LIST_CODE' | translate}}" [(ngModel)]="newPriceList.priceListCode" class="form-control" id="newPricelistCode" name="newPricelistCode" #newPriceListCodeFormItem="ngModel" maxlength="10" 
                                    [validateUnique]="allPriceListCodes" required>
                                </div>
                                <div *ngIf="newPriceListCodeFormItem.invalid && !newPriceListCodeFormItem.pristine">
                                    <div *ngIf="newPriceListCodeFormItem.errors.required" class="alert alert-danger">
                                        <span translate>REQUIRED_FIELD</span>
                                    </div>
                                    <div *ngIf="newPriceListCodeFormItem.errors.notUnique" class="alert alert-danger">
                                        <span translate>VALUE_MUST_BE_UNIQUE</span>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="7" class="row-container">
                                <div class="input-container">
                                    <dropdown-component [items]="sharedService.priceListCurrencies" [selected]="newPriceList.priceListCurrency" (onSelect)="onSelectCurrencyNewPriceList($event)" [autoClose]="true"></dropdown-component>
                                </div>
                            </div>
                        </div>
                        <div class="row-container">
                            <button type="button" class="btn-primary" (click)="addNewPriceList()" style="margin-right: 5px;" 
                                [disabled]="!newPriceListNameFormItem.valid || !newPriceListCodeFormItem.valid || busy"
                                [ngClass]="{'ec-disabled':!newPriceListNameFormItem.valid || !newPriceListCodeFormItem.valid || busy}"
                            >
                                <i class="fa fa-plus"></i>
                                <span translate>ADD</span>
                            </button>
                            <button type="button" class="btn-secondary" (click)="cancelNewPriceList()">
                                <i class="fa fa-times-circle"></i>
                                <span translate>CANCEL</span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="btn-primary" *ngIf="!newPriceListVisible" (click)="newPriceListVisible = true;">
                            <i class="fa fa-plus"></i>
                            <span translate>ADD</span>
                        </button>
                    </div>
                </div>

                <div class="base-panel">
                    <div class="row-container" fxLayout="row">
                        <div *ngIf="!isAttrHidden.customerStockActive" class="input-container" fxFlex="40">
                            <label translate>DISTRIBUTOR_STORES</label>
                            <span class="clickable" (click)="setCustomerStockActive(1)" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"><input type="radio" class="clickable" [value]="1" name="customerStockActive" [(ngModel)]="customer.customerStockActive" [disabled]="!isERPCustomer || busy" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"> <span translate>CUSTOMER_DISTRIBUTOR_STORES_ENABLED</span></span><br>
                            <span class="clickable" (click)="setCustomerStockActive(0);" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"><input type="radio" class="clickable" [value]="0" name="customerStockActive" [(ngModel)]="customer.customerStockActive"  [disabled]="!isERPCustomer || busy" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"> <span translate>CUSTOMER_DISTRIBUTOR_STORES_DISABLED</span></span>
                        </div>
                        <div *ngIf="!isAttrHidden.productsNotTakenNotification" class="input-container" fxFlex="60">
                            <label translate>SEND_PRODUCTS_NOT_TAKEN_NOTIFICATION</label>
                            <span class="clickable" (click)="setProductsNotTakenNotification(1)" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"><input type="radio" class="clickable" [value]="1" name="productsNotTakenNotification" [(ngModel)]="customer.productsNotTakenNotification" [disabled]="!isERPCustomer || busy" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"> <span translate>YES</span></span><br>
                            <span class="clickable" (click)="setProductsNotTakenNotification(0);" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"><input type="radio" class="clickable" [value]="0" name="productsNotTakenNotification" [(ngModel)]="customer.productsNotTakenNotification"  [disabled]="!isERPCustomer || busy" [ngClass]="{'ec-disabled': !isERPCustomer || busy}"> <span translate>NO</span></span>
                        </div>
                    </div>

                    <div class="row-container" fxLayout="row">
                        <div *ngIf="customer.id > 0" class="input-container" fxFlex="40">
                            <label translate>STATUS</label>
                            <span class="clickable" (click)="customer.valid = 1; customerForm.form.markAsDirty();"><input type="radio" class="clickable" [value]="1" name="valid" [(ngModel)]="customer.valid" > <span translate>CUSTOMER_STATUS_ENABLED</span></span><br>
                            <span class="clickable" (click)="customer.valid = 0; customerForm.form.markAsDirty();"><input type="radio" class="clickable" [value]="0" name="valid" [(ngModel)]="customer.valid" > <span translate>CUSTOMER_STATUS_BLOCKED</span></span>
                        </div>
                        <div *ngIf="!isAttrHidden.isRfqManagedByAs" class="input-container" fxFlex="60">
                            <label translate>AS_CAN_MANAGE_RFQ</label>
                            <span class="clickable" (click)="customer.isRfqManagedByAs = 1; customerForm.form.markAsDirty();" [ngClass]="{'ec-disabled': busy}"><input type="radio" class="clickable" [value]="1" name="isRfqManagedByAs" [(ngModel)]="customer.isRfqManagedByAs" [disabled]="busy" [ngClass]="{'ec-disabled': busy}"> <span translate>YES</span></span><br>
                            <span class="clickable" (click)="customer.isRfqManagedByAs = 0; customerForm.form.markAsDirty();" [ngClass]="{'ec-disabled': busy}"><input type="radio" class="clickable" [value]="0" name="isRfqManagedByAs" [(ngModel)]="customer.isRfqManagedByAs" [disabled]="busy" [ngClass]="{'ec-disabled': busy}"> <span translate>NO</span></span>
                        </div>
                    </div>
                </div>

                <div class="base-panel">
                    <div class="row-container bigger" *ngIf="customer.permissionGroups">
                        <div class="input-container">
                            <label translate>SECURITY_GROUP_ADMINISTRATION</label>
                            <div class="tag fnt-smaller" *ngFor="let group of customer.permissionGroups" translate>
                                {{'SECURITY_GROUP_' + group}}
                                <!-- ngIf here because we want always at least one admin so if user is curent user do not allow to remove from admin group -->
                                <i class="fa fa-times-circle clickable" (click)="removeFromGroup(group)"></i>
                            </div>
                            <div>
                                <dropdown-component attributeName="currentAddGroupSelected" [items]="allAvailableGroups" [selected]="addGroupSelected" (onSelect)="onSelectAddGroup($event)" [autoClose]="false" [disableSelectedValueInButton]="true"></dropdown-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex.gt-sm="50" fxFlex="100" class="pl-15">
                <div class="base-panel">
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_CONTACT_NAME</label>
                            <input placeholder="{{ 'CUSTOMER_CONTACT_NAME' | translate }}" [(ngModel)]="customer.contactName" class="form-control" id="contactName" name="contactName" #contactNameFormItem="ngModel" maxlength="50" >
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>CUSTOMER_CONTACT_PHONE</label>
                            <input placeholder="{{ 'CUSTOMER_CONTACT_PHONE' | translate }}" [(ngModel)]="customer.contactPhone" class="form-control" id="contactPhone" name="contactPhone" #contactPhoneFormItem="ngModel" maxlength="50" >
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>EMAIL</label>
                            <input placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="customer.contactEmail" class="form-control" id="email" name="email" #emailFormItem="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="50" >
                        </div>
                        <div *ngIf="emailFormItem.invalid && (!emailFormItem.errors.required && emailFormItem.dirty)" class="alert alert-danger">
                            <span translate>WRONG_EMAIL_FORMAT</span>
                        </div>
                    </div>
                </div>

                <div class="base-panel">
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>ASSIGNED_SELLER_NAME</label>
                            <input placeholder="{{ 'ASSIGNED_SELLER_NAME' | translate }}" [(ngModel)]="customer.assignedSellerName" class="form-control"
                                id="assignedSellerName" name="assignedSellerName" #assignedSellerNameFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                    <div class="row-container" fxLayout="column">
                        <div class="input-container" style="max-width: 300px;">
                            <label translate>ASSIGNED_SELLER_PHONE</label>
                            <input placeholder="{{ 'ASSIGNED_SELLER_NAME' | translate }}" [(ngModel)]="customer.assignedSellerPhone" class="form-control"
                                id="assignedSellerPhone" name="assignedSellerPhone" #assignedSellerPhoneFormItem="ngModel" maxlength="50">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row-container"
            fxLayout="row" 
            fxLayoutAlign="end center">
            <busy-indicator *ngIf="busy"></busy-indicator>
            <button class="btn-secondary" style="margin-right: 10px;" [disabled]="!customerForm.form.dirty|| busy" [ngClass]="{'ec-disabled': !customerForm.form.dirty|| busy}" (click)="cancel(customerForm.form)" *ngIf="customer.id > 0">
                <i class="fa fa-times-circle"></i>
                <span translate>CANCEL_CHANGES</span>
            </button>
            <button class="btn-primary" type="submit" 
                [disabled]="!customerForm.form.valid || !customerForm.form.dirty || newPriceListVisible || busy" 
                [ngClass]="{'ec-disabled': !customerForm.form.valid || !customerForm.form.dirty || newPriceListVisible || busy}">
                <i class="fa fa-chevron-circle-right"></i>
                <span translate>{{customer.id > 0 ? 'SAVE_CHANGES':'ADD'}}</span>
            </button>
        </div>
    </form>
</div>
