import { Component }       from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'manuals',
  templateUrl: 'manuals.component.html',
})
export class ManualsComponent { 
    showVideos: Boolean = true;
    path: string;
    
    constructor(
        public sharedService: SharedService,
        private languageService: LanguageService
    ) {
                
        languageService.getLanguageObservable().subscribe(() => this.languageChanged());
        this.path = this.sharedService.assetsS3BucketUrl +'/'+ this.sharedService.appSettings.language +'/AS'+ this.sharedService.apParams.id +'_';
    }

    languageChanged(): void {
        // Force template to redraw because video elements ignore change of the src attribute
        this.showVideos = false;
        setTimeout(() => this.showVideos = true, 0);
    }
}