import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { Subject } from 'rxjs';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { AppAreas } from '@app/model/appArea.model';
import { GridNumberRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridStateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { OrdersService } from '@app/services/order/orders.service';
import { GridTranslateRendererComponent, GridTranslateRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import { GridPriceRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { CCheckRecordSource } from '@app/model/cCheck.model';

@Injectable()
export class CCheckPurchaseOrderGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    public allSelected$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private ordersService: OrdersService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['c-check-purchase-order'],
            gridNameCamel: 'cCheckPurchaseOrder',
            columnList: [
                { id: 'orderNumberEComm', name: 'ORDER_ID', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'customerName', name: 'CUSTOMER', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 130 },
                { id: 'customerCode', name: 'CUSTOMER_CODE_FULL', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 130 },
                { id: 'priceListCode', name: 'CUSTOMER_PRICE_LIST_CODE', checked: true, orderBy: true, orderDirection: 'ASC', width: 100 },
                { id: 'orderState', name: 'ORDER_STATUS', checked: true, orderBy: false, width: 50 },
                { id: 'lineNumber', name: 'ROW', checked: true, orderBy: true, orderDirection: 'ASC', width: 100 },
                { id: 'itemState', name: 'RFQ_ITEM_STATE', checked: true, orderBy: false, width: 50 },
                { id: 'productCode', name: 'PRODUCT_CODE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'stockCode', name: 'STOCK_GENERAL', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'packing', name: 'PACKING', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'amountOrdered', name: 'ORDERED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 150 },
                { id: 'amountReserved', name: 'RESERVED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
                { id: 'amountDelivered', name: 'DELIVERED', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
                { id: 'amountInvoiced', name: 'INVOICED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },    
                { id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'recordSource', name: 'SOURCE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('orderNumberEComm').name),
                field: 'orderNumberEComm',
                width: this.getColumn('orderNumberEComm').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerName').name),
                field: 'customerName',
                width: this.getColumn('customerName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerCode').name),
                field: 'customerCode',
                width: this.getColumn('customerCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('priceListCode').name),
                field: 'priceListCode',
                width: this.getColumn('priceListCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('orderState').name),
                field: 'orderState',
                width: this.getColumn('orderState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'STATETOOLTYP_',
                    renderColors: this.ordersService.getOrderStateRenderColors()
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('lineNumber').name),
                field: 'lineNumber',
                width: this.getColumn('lineNumber').width,
                suppressSizeToFit: true,
                // minWidth: this.getColumn('lineNumber').width,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'ORDER_ITEM_STATE_'
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('productCode').name),
                field: 'productCode',
                width: this.getColumn('productCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('stockCode').name),
                field: 'stockCode',
                width: this.getColumn('stockCode').width,
                cellStyle: { 'text-align': 'left' }
            }
        ];
        
        // packing
        const packing: ColDef = {
            headerName: this.translateService.instant(this.getColumn('packing').name),
            field: 'packing',
            width: this.getColumn('packing').width,
            minWidth: 110,
            cellRendererFramework: GridTranslateRendererComponent
        };
        const packingRendererParams: GridTranslateRendererComponentParams = { 
            translatePrefix: 'PACKING_' 
        };
        packing.cellRendererParams = packingRendererParams;
        colDefs.push(packing);

        colDefs = colDefs.concat([
            {
                headerName: this.translateService.instant(this.getColumn('deliveryDate').name),
                field: 'deliveryDate',
                width: this.getColumn('deliveryDate').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountOrdered').name),
                field: 'amountOrdered',
                width: this.getColumn('amountOrdered').width,
                minWidth: this.getColumn('amountOrdered').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountReserved').name),
                field: 'amountReserved',
                width: this.getColumn('amountReserved').width,
                minWidth: this.getColumn('amountReserved').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountDelivered').name),
                field: 'amountDelivered',
                width: this.getColumn('amountDelivered').width,
                minWidth: this.getColumn('amountDelivered').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountInvoiced').name),
                field: 'amountInvoiced',
                width: this.getColumn('amountInvoiced').width,
                minWidth: this.getColumn('amountInvoiced').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPrice').name),
                field: 'unitPrice',
                minWidth: 80,
                width: this.getColumn('unitPrice').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent
            }
        ]);
        // recordSource
        const recordSource: ColDef = {
            headerName: this.translateService.instant(this.getColumn('recordSource').name),
            field: 'recordSource',
            width: this.getColumn('recordSource').width,
            cellRendererFramework: GridTranslateRendererComponent
        };
        const recordSourceRendererParams: GridTranslateRendererComponentParams = {
            translatePrefix: 'CHECK_RECORD_SOURCE_'
        };
        recordSource.cellRendererParams = recordSourceRendererParams;
        colDefs.push(recordSource);

        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'orderNumberEComm', name: 'ORDER_ID', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'customer.customerName', name: 'CUSTOMER', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'customer.customerCode', name: 'CUSTOMER_CODE_FULL', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'orderState', name: 'ORDER_STATUS', type: TableFilterItemTypes.multiselect, 
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: this.ordersService.getOrderStateRenderColors(),
                values: this.tableService.filterSuppressStates([
                    { id: 'X', name: 'STATETOOLTYP_X', dotId: 'X', default: true },
                    { id: 'W', name: 'STATETOOLTYP_W', dotId: 'W', default: true },
                    { id: 'N', name: 'STATETOOLTYP_N', dotId: 'N', default: true },
                    { id: 'C', name: 'STATETOOLTYP_C', dotId: 'C', default: true },
                    { id: 'P', name: 'STATETOOLTYP_P', dotId: 'P', default: true },
                    { id: 'D', name: 'STATETOOLTYP_D', dotId: 'D', default: true }
                ], 'orderState')
            },
            {
                id: 'itemState', name: 'RFQ_ITEM_STATE', type: TableFilterItemTypes.multiselect, 
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: 'X', name: 'ORDER_ITEM_STATE_X', dotId: 'X' },
                    { id: 'N', name: 'ORDER_ITEM_STATE_N', dotId: 'N' },
                    { id: 'C', name: 'ORDER_ITEM_STATE_C', dotId: 'C' },
                    { id: 'P', name: 'ORDER_ITEM_STATE_P', dotId: 'P' }
                ]
            },
            {
                id: 'productCode', name: 'PRODUCT_CODE', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'recordSource', name: 'SOURCE', type: TableFilterItemTypes.multiselect,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: CCheckRecordSource.Erp, name: `CHECK_RECORD_SOURCE_${CCheckRecordSource.Erp}` },
                    { id: CCheckRecordSource.ECommerce, name: `CHECK_RECORD_SOURCE_${CCheckRecordSource.ECommerce}` }
                ]
            }
        ];

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
