import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { NewsReadDetailService } from '../../../services/news/news.read.detail.service';
import { NewsReadDetailGridService } from '../../../services/news/news.read.detail.grid.service';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
@Component({
    selector: 'app-news-read-detail-dialog',
    templateUrl: 'news.read.detail.dialog.component.html'
})
export class NewsReadDetailDialogComponent implements OnInit {
    params: any;
    productAvailabilities: any[];
    totalCount;
    users;
    customers;
    busy: boolean;
    exportObj: {fileName: string, export: Function};
    filterItems: 		any[];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private bsModalRef: BsModalRef,
                private translate: TranslateService,
                public sharedService: SharedService,
                private newsReadDetailService: NewsReadDetailService,
                private newsReadDetailGridService: NewsReadDetailGridService) {
        this.getList = this.getList.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('NEWS_READ_DETAIL_TITLE'),
                export: (query) => {
                     const exportFunc = this.newsReadDetailService.exportAll.bind(this.newsReadDetailService);
                     return exportFunc(this.params.id, query);
                }
            };
        });
    }

    ngOnInit() {

    }

    getList(params) {
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: params.orderBy.colId + ' ' + params.orderBy.sort
        };

        this.sharedService.lastNewsReadDetailQueryParams = {};
        for (const key in params.filter) {
            if (params.filter[key]) {
                this.sharedService.lastNewsReadDetailQueryParams[key] = params.filter[key];
                query[key] = params.filter[key];
            }
        }
        if (query.skip >= 0 && query.top > 0) {
            this.newsReadDetailService.getReadDetails(this.params.id, query)
            .subscribe((data: any) => {
                this.busy = false;
                this.totalCount = data.totalCount;
                this.customers = data.customers.map(item => { return {id: item.customerId, name: item.customerName }});
                this.users = data.users.map(item => { return {id: item.userId, name: item.userFullName }});
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                params.successCallback(data.rows, this.totalCount);

            }, err => {
                this.busy = false;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = [this.getFilterItems()];
                }
                console.log(err);
            });
        }
    }
    getGridOptions() {
        return this.newsReadDetailGridService.getGridOptions();
    }
    getFilterItems() {
        return this.newsReadDetailGridService.getFilterItems(this.customers, this.users);
    }

    getColumns() {
        return this.newsReadDetailGridService.getColumns();
    }
    getColumnsDefs() {
        return this.newsReadDetailGridService.getColumnDefs();
    }
}
