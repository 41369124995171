import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, Params } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
// import { ParametersService } from '../services/parameters.service';
import { User } from '../model/user.model';
import { Brand } from '../model/brand.model';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LoginDialogService } from '../components/login/loginDialogComponent/login.dialog.service';
import { APparamsService, ApParams } from '../services/ap.params.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { NgxFaviconService } from 'ngx-favicon';
import { CustomFavicon } from '../favicon.config';
import { AplParams } from '@app/model/aplParams.model';
import { AppAreas } from '@app/model/appArea.model';

@Injectable()
export class SharedService {

    public versionNumber = '2.3.0'; // used to force refresh if newer version is available
    public time = '14.8.2024 11:56';

    private jwtHelper: JwtHelperService = new JwtHelperService();

    public appSettings: {
        versionNumber: string,
        deploymentTarget: string,
        deploymentDateTime: string,
        apiUrl: string,
        apiUsersUrl: string,
        apiDeliveryOrdersUrl: string,
        apiOrdersUrl: string,
        apiRfqUrl: string,
        apiPrfqUrl: string,
        apiS1Url: string,
        apiProductsUrl: string,
        apiCoreUrl: string,
        language: string,
        authorizedPartner: string,
    };
    public user: User;
    private savedUserPreferences: {id: number, preferences: any};
    public permissions: { [key: string]: [ string]; }; // permissions of user
    public params: AplParams; // params of application e.g. disabled vacation dates...
    public brands: Brand[]; // brands that will be displayed in catalogue
    public appComponent; // main component off app
    public area: AppAreas;  // main area in application - item in top menu (e.g. catalogue, orders, ...) set in component
    private previousArea: AppAreas; // previous value of 'area'; used when routing is canceled because of an unsaved form

    public refreshOnNextSafeRouteChange: boolean; // set to true if app version get by http service on refresh token is different from current version of client app, used on route change to reload whole application
    public subjectSameAreaClicked = new Subject<any>(); // called .next() on this subject to tell subscribers to refresh current view e.g. when user is on orders and clicks oreders in menu

    private observableRelogin: Observable<any>;
    private observersOfReloginCount: number;

    // public currentQueries: any = {};

    public lastCatalogueQueryParams: any = {};
    public lastPurchaseCatalogueQueryParams: any = {};
    public lastOrdersSummaryQueryParams: any = {};
    public lastProductsOfCurrentOrderQueryParams: any = {};
    public lastOrderDetailQueryParams: any = {};
    public lastOrdersItemsQueryParams: any = {};
    public lastShipmentQueryParams: any = {};
    public lastShipmentsQueryParams: any = {};
    public lastShipmentsItemsQueryParams: any = {};
    public lastJournalQueryParams: any = {};
    public lastNewsQueryParams: any = {};
    public lastNewsDetailQueryParams: any = {};
    public lastMonitoringQueryParams: any = {};
    public lastCustomersQueryParams: any = {};
    public lastDeliveryOrdersQueryParams: any = {};
    public lastDeliveryOrderItemsQueryParams: any = {};
    public lastDeliveryOrderDetailQueryParams: any = {};
    public lastInvoicesQueryParams: any = {};
    public lastInvoiceItemsQueryParams: any = {};
    public lastInvoiceDetailQueryParams: any = {};
    public lastControllingQueryParams: any = {};
    public lastRfqsQueryParams: any = {};
    public lastRfqsItemsQueryParams: any = {};
    public lastRfqDetailQueryParams: any = {};
    public lastPrfqsQueryParams: any = {};
    public lastPrfqsItemsQueryParams: any = {};
    public lastPrfqDetailQueryParams: any = {};
    public lastCustomerStockQueryParams: any = {};
    public lastASStockQueryParams: any = {};
    public lastNewsReadDetailQueryParams: any = {};
    public lastPriceListsQueryParams: any = {};
    public lastPriceListsItemsQueryParams: any = {};
    public lastPriceListDetailQueryParams: any = {};
    public lastSuppliersQueryParams: any = {};
    public lastCChecksQueryParams: any = {};
    public lastCCheckPurchaseOrderQueryParams: any = {};
    public lastCCheckDeliveryOrderQueryParams: any = {};
    public lastSentEmailsListQueryParams: any = {};

    newsCount: 0;
    newsPostponed: any[] = [];
    subjectReloadNewsCount = new Subject<any>();

    basketItemsCount = 0;
    shipmentsCount = 0;

    public currentDiscussionComments: any[] = []; // used to not load items every time in detail when comments of item are opened

    public backUrl = ''; // used when needed to tel next component custom url to go to when user clicks back (e.g. from basket to order and back to basket, not list of orders)
    public backQueryParams: any;

    afterLoginUrl: string;
    afterLoginQuery: any;

    priceListCurrencies: string[] = ['EUR', 'USD', 'PLN', 'CZK', 'UAH'];
    languages: string[] = ['CZ', 'EN', 'RU', 'PL', 'SK', 'UA'];
    public apParams: ApParams; // = {};

    private assetsS3BucketUrlPrefix = `https://s3-eu-west-1.amazonaws.com/ecm-assets`;

    private target: 'development' | 'testing' | 'production' = environment.target;
    private apiUrl = environment.apiUrl;
    private apiUsersUrl = environment.apiUsersUrl;
    private apiDeliveryOrdersUrl = environment.apiDeliveryOrdersUrl;
    private apiOrdersUrl = environment.apiOrdersUrl;
    private apiRfqUrl = environment.apiRfqUrl;
    private apiPrfqUrl = environment.apiPrfqUrl;
    private apiS1Url = environment.apiS1Url;
    private apiProductsUrl = environment.apiProductsUrl;
    private apiCoreUrl = environment.apiCoreUrl;

    public assetsS3BucketUrl = `${this.assetsS3BucketUrlPrefix}-${this.target}`;

    public CustomFavicon: typeof CustomFavicon = CustomFavicon;

    constructor(
        private loginDialogService: LoginDialogService,
        private router: Router,
        public translateService: TranslateService,
        private aPparamsService: APparamsService,
        private faviconService: NgxFaviconService<CustomFavicon>,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.appSettings = {
            versionNumber: this.versionNumber,
            deploymentTarget: this.target,
            deploymentDateTime: this.time,
            apiUrl: this.apiUrl,
            apiUsersUrl: this.apiUsersUrl,
            apiDeliveryOrdersUrl: this.apiDeliveryOrdersUrl,
            apiOrdersUrl: this.apiOrdersUrl,
            apiRfqUrl: this.apiRfqUrl,
            apiPrfqUrl: this.apiPrfqUrl,
            apiS1Url: this.apiS1Url,
            apiProductsUrl: this.apiProductsUrl,
            apiCoreUrl: this.apiCoreUrl,
            language: 'sk',
            authorizedPartner: 'zvl',
        };
        this.observersOfReloginCount = 0;

        this.init();
    }

    init() {
        this.lastCatalogueQueryParams = {};
        this.lastPurchaseCatalogueQueryParams = {};
        this.lastOrdersSummaryQueryParams = {};
        this.lastProductsOfCurrentOrderQueryParams = {};
        this.lastOrderDetailQueryParams = {};
        this.lastOrdersItemsQueryParams = {};
        this.lastShipmentQueryParams = {};
        this.lastShipmentsQueryParams = {};
        this.lastShipmentsItemsQueryParams = {};
        this.lastJournalQueryParams = {};
        this.lastNewsQueryParams = {};
        this.lastNewsDetailQueryParams = {};
        this.lastMonitoringQueryParams = {};
        this.lastCustomersQueryParams = {};
        this.lastDeliveryOrdersQueryParams = {};
        this.lastDeliveryOrderItemsQueryParams = {};
        this.lastDeliveryOrderDetailQueryParams = {};
        this.lastInvoicesQueryParams = {};
        this.lastInvoiceItemsQueryParams = {};
        this.lastInvoiceDetailQueryParams = {};
        this.lastControllingQueryParams = {};
        this.lastRfqsQueryParams = {};
        this.lastRfqsItemsQueryParams = {};
        this.lastRfqDetailQueryParams = {};
        this.lastPrfqsQueryParams = {};
        this.lastPrfqsItemsQueryParams = {};
        this.lastPrfqDetailQueryParams = {};
        this.lastNewsReadDetailQueryParams = {};
        this.lastPriceListsQueryParams = {};
        this.lastPriceListsItemsQueryParams = {};
        this.lastPriceListDetailQueryParams = {};
        this.lastSuppliersQueryParams = {};
        this.lastCChecksQueryParams = {};
        this.lastCCheckPurchaseOrderQueryParams = {};
        this.lastCCheckDeliveryOrderQueryParams = {};
        this.lastSentEmailsListQueryParams = {};
}

    /**
     * Updates area (main navigation item) of aplication
     *
     * @param newValue - new string value of area
     */
    public updateArea(newValue: AppAreas): void {
        this.previousArea = this.area;
        this.area = newValue;
    }

    public setAreaOnlyWhenEmpty(area: AppAreas): void {
        if (!this.area) {
            this.area = area;
        }
    }

    public restorePreviousAreaValue(): void {
        this.area = this.previousArea;
    }

    /**
     * Sets Authorized partner name
     *
     * @param locationHostname
     */
    setAuthorizedPartner(locationHostname: string) {
        const hostnameParts = locationHostname.split('.');
        if (hostnameParts.length > 1) {
            if (this.appSettings.authorizedPartner !== hostnameParts[1]) {
                this.appSettings.authorizedPartner = hostnameParts[1];
            }
        }
        // get params for ap
        const apParams = this.aPparamsService.getApParams(locationHostname);
        this.languages = apParams.languages;
        this.appSettings.authorizedPartner = apParams.name;
        this.apParams = apParams;

        this.document.body.classList.add(this.apParams.styleTheme + '-theme');
        this.faviconService.setFaviconByUrl(`assets/images/favicon_${this.apParams.iconSet}.ico`);
    }

    /**
     * Sets user by decoding JWT ID token
     *
     * @param {string} idToken - JWT id token
     */
    setUserFromToken(idToken: string) {
        const decodedIdToken = this.jwtHelper.decodeToken(idToken);
        this.user = new User(decodedIdToken['cognito:username'],
                             decodedIdToken.given_name,
                             decodedIdToken.family_name,
                             '',
                             decodedIdToken.email,
                             decodedIdToken['cognito:groups'],
                             [],
                             '',
                             decodedIdToken.businessCondConsent);
        // set remembered preferences if it is the same user as before relogin
        if (this.savedUserPreferences && this.savedUserPreferences.id === this.user.id) {
            this.user.preferences = this.savedUserPreferences.preferences;
        } else {
            // navigate to home if user changed
            if (this.savedUserPreferences !== null) {
                this.router.navigate(['/' + this.appSettings.language + '/home']);
            }
        }
        this.removeSavedUserPreferences();

        localStorage.setItem('user', JSON.stringify(this.user));
    }

    /**
     * Sets permissions
     */
    setPermissions(permissions: { [key: string]: [ string]; }) {
        this.permissions = permissions;
        localStorage.setItem('permissions', JSON.stringify(this.permissions));
    }

    /**
     * Sets application params
     */
    public setParams(params: AplParams): void {
        this.params = params;
        localStorage.setItem('params', JSON.stringify(this.params));
    }
    /**
     * Sets news count in localStorage
     */
    getNewsCount() {
        return localStorage.getItem('newsCount');
    }
    /**
     * Sets news count in localStorage
     */
    setNewsCountStorage(count: any) {
        localStorage.setItem('newsCount', JSON.stringify(count));
    }

    /**
     *  Refresh news count in header
     */
    refreshNewsCount() {
        this.subjectReloadNewsCount.next();
    }
    /**
     * Sets postponed news
     */
    setNewsPostponed(postponed: any[]) {
        localStorage.setItem('newsPostponed', JSON.stringify(postponed));
    }

    /**
     * Returns true if user has given permission
     *
     * @param {string or string[]} permission - permission name e.g. 'orders', 'my_profile' orr array of permission names
     * @param {string} action - action name e.g. 'GET', 'PUT', 'POST', 'DELETE'
     */
    hasPermission(permission: string | string[], action: string) {
        if (!this.permissions) {
            return false;
        }
        if (typeof permission === 'string') {
            return this.permissions.hasOwnProperty(permission) && (this.permissions[permission].indexOf(action) > -1);
        } else {
            return permission.filter(perm => {
                return this.permissions.hasOwnProperty(perm) && (this.permissions[perm].indexOf(action) > -1);
            }).length > 0;
        }
    }

    /**
     * Sets brands that will be displayed for user in catalogue
     */
    setBrands(brands: Brand[]) {
        this.brands = brands;
        localStorage.setItem('brands', JSON.stringify(this.brands));
    }

    /**
     * Gets values from local storage e.g. user, permissions
     */
    getValuesFromLocalStorage() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.params = JSON.parse(localStorage.getItem('params'));
        this.brands = JSON.parse(localStorage.getItem('brands'));
        this.basketItemsCount = JSON.parse(localStorage.getItem('basketItemsCount'));
        this.shipmentsCount = JSON.parse(localStorage.getItem('shipmentsCount'));
        // create newsCount and newsPostponed if doesnt exist
        this.newsCount = JSON.parse(localStorage.getItem('newsCount')); // .length === 0 ? 0 : parseInt(localStorage.getItem('newsCount'), 10);
        this.newsPostponed = JSON.parse(localStorage.getItem('newsPostponed')); // .length === 0  ? '[]' : JSON.parse(localStorage.getItem('newsPostponed'));
    }

    /**
     * Returns user customer price list currency (if the user is a customer representative), or null otherwise
     */
    getUserCustomerCurrency() {
        return this.user.representative === 'customer' ? this.user.representsCustomer.priceListCurrency : null;
    }

    /**
     * Removes User of application
     */
    removeUser() {
        this.user = null;
    }

    /**
     * Removes saved user preferences
     */
    removeSavedUserPreferences() {
        this.savedUserPreferences = null;
    }

    /**
     * Returns observable for requests with error status 401, to be recalled after relogin
     */
    getObservableFor401Requests(): Observable<any> {
        return new Observable(observer => {
            // if not already created observable
            if (!this.observableRelogin) {
                // clear app because user will need to be relogged in
                this.savedUserPreferences = {id: this.user.id, preferences: this.user.preferences};
                this.permissions = null;
                localStorage.clear();
                // open relogin dialog
                this.observableRelogin = this.loginDialogService.confirm();
            }
            this.observableRelogin.subscribe(
                res => {
                    this.observersOfReloginCount--;
                    if (this.observersOfReloginCount === 0) {
                        this.observableRelogin = null;
                    }
                    observer.next(res);

                    if (!res) { // dismiss
                        this.router.navigate(['/' + this.appSettings.language + '/home']);
                    }
                }
            );
            this.observersOfReloginCount++;
        });
    }


    /**
     * Returns true if implementation changed in original columns
     */
    hasImplementationOfCOlumnsChanged(original: any[], restored: any[]) {
        if (original.length !== restored.length) { return true; }
        for (let i = 0; i < original.length; ++i) {
            if (original[i] && restored[i]) {
                if (original[i].id !== restored[i].id || original[i].name !== restored[i].name) {
                    return true;
                }
            } else {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns string with capitalized first letter
     */
    capitalizeFirstLetter(s: string) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    /**
     * Parses query string to object
     */
    parseQuery(queryString) {
        const query = {};
        const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    /**
     * Navigates with orderby from columns if not already in given current query params
     */
    public navigateWithOrderby(columns, currentQueryParams, lastQuery): void {
        const this_ = this;
        if (!currentQueryParams.hasOwnProperty('orderBy')) {
            columns.forEach(function(column) {
                if (column.orderBy) {
                    lastQuery = {};
                    for (const key of Object.keys(currentQueryParams)) {
                        lastQuery[key] = currentQueryParams[key];
                    }
                    lastQuery['orderBy'] = column.id + ' ' + column.orderDirection;
                    this_.router.navigate([], {queryParams: lastQuery, replaceUrl: true});
                    return true;
                }
            });
        }
    }

    /**
     * Clears shared service and locaal storage
     */
    clear() {
        this.init();
        this.removeUser();
        this.removeSavedUserPreferences();
        this.permissions = null;
        this.brands = null;
        this.newsCount = 0;
        this.newsPostponed = [];
        this.basketItemsCount = 0;
        this.shipmentsCount   = 0;
        localStorage.clear();
    }

    /**
     * Sets last...QueryParams for specific screens
     */
    setLastQueryParams() {
        const representative = this.user.representative;
        const map = [
            { filterName: 'ordersFilter', propertyName: 'lastOrdersSummaryQueryParams' },
            { filterName: 'orders-itemsFilter', propertyName: 'lastOrdersItemsQueryParams' },
            { filterName: 'delivery-ordersFilter', propertyName: 'lastDeliveryOrdersQueryParams' },
            { filterName: 'delivery-orders-itemsFilter', propertyName: 'lastDeliveryOrderItemsQueryParams' },
            { filterName: 'invoicesFilter', propertyName: 'lastInvoicesQueryParams' },
            { filterName: 'invoices-itemsFilter', propertyName: 'lastInvoiceItemsQueryParams' },
            { filterName: 'shipmentsFilter', propertyName: 'lastShipmentsQueryParams' },
            { filterName: 'shipments-itemsFilter', propertyName: 'lastShipmentsItemsQueryParams' },
            { filterName: 'rfqsFilter', propertyName: 'lastRfqsQueryParams' },
            { filterName: 'rfqs-itemsFilter', propertyName: 'lastRfqsItemsQueryParams' },
            { filterName: 'prfqsFilter', propertyName: 'lastPrfqsQueryParams' },
            { filterName: 'prfqs-itemsFilter', propertyName: 'lastPrfqsItemsQueryParams' },
            { filterName: 'news-read-detail-filter', propertyName: 'lastNewsReadDetailQueryParams' },
        ];

        map.forEach(item => {
            const key = `${representative}_${item.filterName}`;

            this[item.propertyName] = this.user.preferences[key] ? this.user.preferences[key] : {};
        });

        // if (this.user.preferences[`${representative}_ordersFilter`])
        //     this.lastOrdersSummaryQueryParams = this.user.preferences[`${representative}_ordersFilter`];
        // else
        //     this.lastOrdersSummaryQueryParams = {};
    }

    /**
     * Returns user preferences key
     */
    public getUserPreferenceKey(type: 'TableColumns' | 'Filter', tableNamePrefix?: string): string {
        return this.user.representative + '_' + this.area + (tableNamePrefix ? tableNamePrefix : '') + type;
    }

    /**
     * Create a new object by omitting specified keys
     * @param  {object} object
     * @param  {array}  keys  keys from object to omit
     * @return {object} a new object without attributes specified in the keys array
     */
    omitObjectKeys(object: any, keys: string[]) {
        // let columnsToDrop = [...new Set(keys)];  // make keys unique
        const columnsToDrop = Object.keys(keys.reduce((acc, key) =>
            Object.assign({}, acc, {[key]: null}), {}));

        return columnsToDrop.reduce((colsObj, colName) => {
            const obj = Object.assign({}, colsObj);
            delete obj[colName];
            return obj;
        }, object);
    }

    public getObjectsFromSeparatedString(separatedString: string, rowAttrs: string[]): { [key: string]: any }[] {
        const resultRows = [];
        const rows = separatedString.split(/\r\n|\r|\n/g);

        let separator: string;
        if (rows.length > 0) {
            separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
        }

        rows.forEach((row: string) => {
            if (row !== '') {
                const resultRow = {};

                const splittedRowBySeparator = row.split(separator);

                splittedRowBySeparator.forEach((colValue, i) => {
                    const attrName = rowAttrs[i];
                    if (attrName) {
                        resultRow[attrName] = colValue;
                    }
                });
                resultRows.push(resultRow);
            }
        });
        return resultRows;
    }

    /**
     * Round to N decimals but if the N+1 decimal exists round properly
     */
    public roundTo(number: number, digits: number): number {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
            if( number < 0) {
            negative = true;
          number = number * -1;
        }
        var multiplicator = Math.pow(10, digits);
        number = parseFloat((number * multiplicator).toFixed(11));
        number = +(Math.round(number) / multiplicator).toFixed(2);
        if( negative ) {    
            number = +(number * -1).toFixed(2);
        }
        return number;
    }

    public resetSelection(selectionAttrName: string, selected$?: Subject<any>): void {
        this.user.preferences[selectionAttrName].ids = {};
        this.user.preferences[selectionAttrName].all = false;
        this.user.preferences[selectionAttrName].visible = false;

        if (selected$) {
            selected$.next();
        }

        localStorage.setItem('user', JSON.stringify(this.user));
    }

    public getNumberFromString(value: string): number {
        if (typeof value !== 'string') {
            return value;
        }
        const num: number = +value.replace(',', '.');
        return num;
    }

    public isUserLoggedIn(): boolean {
        return this.user ? true : false;
    }

    /**
     * purchaseOrder -> purchase-order
     */
    public camelCaseToDashed(camel: string): string {
        return camel.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    }
}
