import { Injectable } from '@angular/core';
import { EcmHttpService, EcmHttpQueryParams } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import { Rfq, RfqItemsResponse, RfqItem } from '@model/rfq.model';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Subject , Observable, throwError} from 'rxjs';
import { FormService } from '../form.service';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { LocalNumberPipe } from '@app/locale.pipes.module';
import { LanguageService } from '../language.service';
import { PackingOption } from '@app/model/basket.model';
import { UserService } from '../user/user.service';
import { RepreOverrideAreas } from '@app/model/user.model';
import { ToastService } from '../toastService/toast.service';

@Injectable()
export class RfqsService {
    subjectReloadCurrent: Subject<any>;

    constructor(
        private http: EcmHttpService,
        private sharedService: SharedService,
        private formService: FormService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private userService: UserService,
        private toastService: ToastService
    ) {
        this.subjectReloadCurrent = new Subject<any>();
    }

    /**
     * Loads RFQs
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getRfqs(query: any) {
        let url = `/rfq`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(
            map(data => {
                let rfqs: Rfq[] = [];
                data.rows.forEach(item => {
                    const _item = Object.assign({}, item, {
                        url: `rfq/${item.id}`,
                        attachments: []
                    });
                    rfqs.push(new Rfq(_item));
                });
                // If customer suddenly becomes unavailable for RFQ management by AS
                if (this.userService.isRepreOverrideForArea(RepreOverrideAreas.rfq)) {
                    if (rfqs.length > 0 && !rfqs[0].customerIsRfqManagedByAs) {
                        this.toastService.addError('CUSTOMER_RFQ_MANAGEMENT_NOT_ALLOWED');
                        return { rows: [], total: 0, personsCreated:[], assignedSellerNames: [] };
                    }
                }
                return { rows: rfqs, total: data.totalCount, personsCreated: data.personsCreated, assignedSellerNames: data.assignedSellerNames };
            }),
            catchError(err => {
                if (err.error && err.error.message === 'CUSTOMER_BLOCKED') {
                    this.toastService.addError('CUSTOMER_IS_BLOCKED');
                }
                return throwError(err);
            })
        );
    }

    /**
     * Loads RFQ header
     * @param id - id of delivery irder
     */
    public getRfq(id: number): Observable<Rfq> {
        return this.http.get(`/rfq/${id}/header`)
            .pipe(map(item => {
                const _item = Object.assign({}, item, {
                    url: `rfq/${item.id}`
                });
                return new Rfq(_item);
            }));
    }

    /**
     * Creates a new rfq and returns {id, rfqNumber}
     */
    createRfq() {
        return this.http.post('/rfq', {});
    }

    getAllRfqItems(query: any) {
        return this.http.get(`/rfq-items`, this.http.prepareOptions(query)).pipe(
            map(data => {
                data.rows.forEach(item => {
                    item.url = `/rfq/${item.rfqId}`;
                });

                return data;
            }),
            catchError(err => {
                if (err.error && err.error.message === 'CUSTOMER_BLOCKED') {
                    this.toastService.addError('CUSTOMER_IS_BLOCKED');
                }
                return throwError(err);
            })
        );
    }

    /**
     * Returns observable of reload - used to reload rfq items grid
     */
    getReloadCurrentSubject(): Subject<any> {
        return this.subjectReloadCurrent;
    }

    /**
     * Items of the specified rfq
     */
    getRfqItems(rfqId: number, query: any): Observable<RfqItemsResponse> {
        return this.http.get(`/rfq/${rfqId}/items`, this.http.prepareOptions(query))
            .pipe(map((data: RfqItemsResponse) => Object.assign({}, data, {
                rows: data.rows.map(row => Object.assign({}, row, {
                    rfqId,
                    amountPackagesNotRounded: row.amountPackages,
                    // amountPackages: new LocalNumberPipe(this.languageService).transform(
                    //     row.amountPackages,
                    //     this.sharedService.appSettings.language, false, 2),
                    originalValues: [
                        { colId: 'amountOffered', value: row.amountOffered },
                        { colId: 'amountInPacking', value: row.amountInPacking },
                        { colId: 'packingOptions', value: row.packingOptions },
                        { colId: 'itemValidity', value: row.itemValidity }
                    ],
                    packingOptions: this.setDefaultPackingOptionFirst(row)
                    // rfqProductAS: row.rfqProductAS ? row.rfqProductAS : '('+ this.sharedService.translateService.instant('RFQ_ITEM_NOT_FOUND') +')'
                }))
            })));
    }

    private setDefaultPackingOptionFirst(rfqItem: RfqItem): PackingOption[] {
        if (!rfqItem.packingOptions || rfqItem.packingOptions.length < 2) {
            return rfqItem.packingOptions;
        }
        const defaultOption = rfqItem.packingOptions.filter(item =>
            item.packing === rfqItem.defaultPacking)[0];
        const otherOptions = rfqItem.packingOptions.filter(item =>
            item.packing !== rfqItem.defaultPacking);
        return [defaultOption, ...otherOptions];
    }

    updateRfqItem(rfqId: number, item: any, rfqGetter: Object) {
        // Include only these columns to the request
        const columns = ['id'];

        return this.http.put(`/rfq/${rfqId}/items`,
            JSON.stringify({ toInsert: [], toUpdate: [this.normalizeItem(item, columns, false, rfqGetter)], toDelete: []}));
    }

    public updateRfqItems(rfqId: number, items: any[], rfqObject): Observable<any> {
        // Include only these columns to the request
        const columns = ['id'];

        return this.http.put(`/rfq/${rfqId}/items`,
            JSON.stringify({
                toInsert: items.filter(item => item.id <= 0).map(item => {
                    Object.keys(item).forEach(key => {
                        if (!item[key]) {
                            delete item[key];
                        }
                    })
                    return this.normalizeItem(item, columns.filter(col => col !== 'id' && col !== 'currencyPriceRequired'));
                }),
                toUpdate: items.filter(item => item.id > 0).map(item => this.normalizeItem(item, columns, rfqObject)),
                toDelete: []
            })
        );
    }

    rfqItemsAction(rfqId: number, rfqItemIds: string[], action: string, extraData?: any) {
        return this.http.put(`/rfq/${rfqId}/items-action`, JSON.stringify({ rfqItemIds, action, extraData }), null, true);
    }

    // roundTo(n, digits) {
    //     var negative = false;
    //     if (digits === undefined) {
    //         digits = 0;
    //     }
    //         if( n < 0) {
    //         negative = true;
    //       n = n * -1;
    //     }
    //     var multiplicator = Math.pow(10, digits);
    //     n = parseFloat((n * multiplicator).toFixed(11));
    //     n = (Math.round(n) / multiplicator).toFixed(2);
    //     if( negative ) {    
    //         n = (n * -1).toFixed(2);
    //     }
    //     return n;
    // }

    createRfqItem(rfqId: number, item: RfqItem, rfqGetter, columns?: string[]) {
        const isNewRow = true;
        const skipColumns = ['amountPackages'];
        return this.http.put(`/rfq/${rfqId}/items`,
            JSON.stringify({
                toInsert: [this.normalizeItem(item, columns
                    ? columns
                    : Object.keys(item).filter(key => skipColumns.indexOf(key) < 0), isNewRow, rfqGetter)],
                toUpdate: [],
                toDelete: []
            }));
    }

    private normalizeItem(item, columns: string[], isNewRow?: boolean, rfq?: Object) {
        let apiItem = columns.reduce((acc, col) => Object.assign({}, acc, { [col]: item[col] }), {});
        if (item.originalValues) { // its existing item
            item.originalValues.forEach(origVal => {
                if (origVal.value !== item[origVal.colId]) {
                    // values are different but try to convert as string and remove dots and commas
                    // compare this values. if there are numbers (int, float): from "0,35"<=>"0.35" will be same values 035
                    let valOrig = String(origVal.value).replace(/[.,]/g, '');
                    let valItemOrig = String(item[origVal.colId]).replace(/[.,]/g, '');
                    switch (true){
                        case (valOrig === valItemOrig):
                        case (valOrig === '' && valItemOrig === 'null'):
                        case (valOrig === 'null' && valItemOrig === ''):
                            // its same values in our use case, this is only for reference
                        break;
                        case (valOrig !== valItemOrig):
                            apiItem[origVal.colId] = item[origVal.colId] === '' ? null : item[origVal.colId];
                        break;
                    }
                }
            });
        }
        apiItem = this.convertDates(apiItem);
        apiItem = this.convertTypes(apiItem);

        if (('rfqProduct' in apiItem) || ('rfqProductAS' in apiItem) || isNewRow) {
            apiItem = Object.assign(apiItem, {
                brandCode: 'ZVL',  // TODO: replace this when brandCode will be in UI
            });
        }

        if ( isNewRow ) {
            apiItem = Object.assign(apiItem, { currencyPriceRequired: this.getCurrency(rfq) });
            apiItem = Object.assign(apiItem, { currencyPriceOffered: this.getCurrency(rfq)  });
        }

        if (('unitPriceRequired' in apiItem) && ( item['unitPriceRequired'] !== null )) {
            apiItem = Object.assign(apiItem, { currencyPriceRequired: this.getCurrency(rfq) });
        } else if ( !('unitPriceRequired' in apiItem) ||
                    ('unitPriceRequired' in apiItem) && ( item['unitPriceRequired'] === null )) {
            delete apiItem['unitPriceRequired'];
            delete apiItem['currencyPriceRequired'];
        }

        if (('unitPriceOffered' in apiItem) && ( item['unitPriceOffered'] !== null )) {
            apiItem = Object.assign(apiItem, { currencyPriceOffered: this.getCurrency(rfq) });
        } else if ( !('unitPriceOffered' in apiItem) ||
                    ('unitPriceOffered' in apiItem) && ( item['unitPriceOffered'] === null )) {
            delete apiItem['unitPriceOffered'];
            delete apiItem['currencyPriceOffered'];
        }
        delete apiItem['amountPackages'];
        delete apiItem['amountPackagesNotRounded'];
        delete apiItem['amountInPacking'];
        delete apiItem['packingOptions'];

        return apiItem
    }

     /** Get currency statically by role and if user is AS then by customerId */
     public getCurrency(rfq) {
        if (this.sharedService.user.representsCustomer) { // user is customer
            return this.sharedService.user.representsCustomer.priceListCurrency;
        } else if (this.sharedService.user.availableCustomers && rfq.customerId) { 
            // user is AS so take currency from available customers where this rfq belongs
            const customerOfRfq = this.sharedService.user.availableCustomers.filter(customer => customer.id === rfq.customerId);
            if (customerOfRfq[0]) {
                return customerOfRfq[0].priceListCurrency;
            }
        }
    }

    convertDates(item) {
        const dateAttrs = ['dateRequired', 'dateOffered', 'dateOfferedValidity', 'itemValidity'];
        return dateAttrs.reduce((acc, attr) => acc[attr] 
            ? Object.assign({}, acc, { [attr]: this.formService.getDateStringFromIsoDate(acc[attr]) })
            : acc, 
            Object.assign({}, item));
    }

    convertTypes(item) {
        return Object.assign({}, item, {
            dateOfferedDays: item.dateOfferedDays === '' ? null : item.dateOfferedDays
        });
    }

    deleteRfqItems(rfqId: number, items: {id: number}[]) {
        return this.http.put(`/rfq/${rfqId}/items`,
            JSON.stringify({ toInsert: [], toUpdate: [], toDelete: items }));
    }

    deleteRfq(rfqId: number) {
        return this.http.delete(`/rfq/${rfqId}/header`);
    }

    rfqAction(rfqId: number, action: string, extraData?: any) {
        if (action === 'ORDER_ITEM') {
            return this.http.put(`/rfq/${rfqId}/items-action`, JSON.stringify({action, extraData } ), null, true);
        } else  {
            return this.http.put(`/rfq/${rfqId}/action`, JSON.stringify({action, extraData } ), null, true);
        }
    }

    /**
     * Exports rfq with given id to given format file with name containing given rfqNumber
     * @param ids - array of ids to be exported, if null export all
     * @param invoiceNumber - number of rfq to be exported
     * @param format - format to be exported
     * @param suppressFirstPartOfFileName - true if not to include first part of file name - to use only deliveryNumber value for file name
     */
    export(ids: number[], rfqNumber: string, format: string, suppressFirstPartOfFileName?: boolean) {
        const columns = ['authorizedSeller', 'rfqNumber', 'rfqCreated', 'personCreated', 'lineNumber',
            'rfqProduct', 'amountRequired', 'amountOffered', 'packing', 'unitPriceRequired', 'totalPriceRequired',
            'currencyPriceRequired', 'unitPriceOffered', 'totalPriceOffered', 'currencyPriceOffered',
            'dateRequired', 'deliveryTerm', 'itemNote', 'dateChanged', 'itemValidity'];
        let query: any = {
            exportToFormat: format,
            exportColumns: columns,
            orderBy: 'rfqNumber ASC'
        }
        if (ids) {
            query['rfqId.operator'] = 'in';
            query['rfqId.value'] = ids.join(',');
        } else {
            query = Object.assign(this.sharedService.lastDeliveryOrdersQueryParams, query);
            delete query.page;
        }
        const url = `/rfq-items`;
        this.http.get(url, this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, { responseType: 'text' }))
        ).subscribe(data => {
            const mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            const blob = new Blob(['\ufeff' + data], { type: mediaType });
            const filename = (suppressFirstPartOfFileName ? '' : (this.translateService.instant('RFQ') + '_')) + rfqNumber + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }

    public exportAll(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
        let url = `/rfq`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    exportAllItems(query) {
        let url = `/rfq-items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    getAttachments(rfqId: number) {
        return this.http.get(`/rfq/${rfqId}/attachments`);
    }

    getAttachmentUploadUrl(fileName: string, rfqId?: number, childId?: number) {
        return this.http.put(`/rfq/attachment-upload-url`, JSON.stringify({ fileName: fileName, entityId: rfqId, childId: childId }));
    }

    getAttachmentDownloadUrl(rfqId: number, attachmentId: number, lineNumber?: number) {
        let data = { entityId: rfqId, attachmentId: attachmentId };
        if (lineNumber)
            data['lineNumber'] = lineNumber;

        return this.http.put(`/rfq/attachment-download-url`, JSON.stringify(data));
    }

    deleteAttachment(entityId: number, attachmentId: number, lineNumber?: number) {
        return this.http.delete(`/rfq/${entityId}/attachment/${attachmentId}${lineNumber ? '/line-number/' + lineNumber : ''}`);
    }

    /**
     * Returns journal for given rfqId
     * @param rfqId
     */
    getJournal(rfqId: number, query) {
        return this.http.get(`/rfq/${rfqId}/journal`, this.http.prepareOptions(query));
    }

    getPriceOfferedPopupValue(data) {
        const catalogueDiscount = data.unitPriceCatalogue ? Math.abs(Math.round(((data.unitPriceCatalogue - data.unitPriceOffered) / (data.unitPriceCatalogue)) * 10000) / 100) : 0;
        const requiredDiscount = data.unitPriceRequired ? Math.abs(Math.round(((data.unitPriceRequired - data.unitPriceOffered) / (data.unitPriceRequired)) * 10000) / 100) : 0;
        return [
            this.translateService.instant(catalogueDiscount < 0 ? 'INCREASE' : 'DISCOUNT_FROM') + ' ' +
            this.translateService.instant('FROM_CATALOGUE_PRICE') + ': ' +
                (catalogueDiscount === 0  ? '-' :
                    new LocalNumberPipe(this.languageService).transform(catalogueDiscount, this.sharedService.appSettings.language, true, 2) + '%'),

            this.translateService.instant(requiredDiscount < 0 ? 'INCREASE' : 'DISCOUNT_FROM') + ' ' +
            this.translateService.instant('FROM_REQUIRED_PRICE') + ': ' +
                (requiredDiscount === 0  ? '-' :
                    new LocalNumberPipe(this.languageService).transform(requiredDiscount, this.sharedService.appSettings.language, true, 2) + '%')
        ];
    }

    public getProductPackingOptions(eCommProductId: string, brand: string): Observable<PackingOption[]> {
        return this.http.get(`/products/${brand}/${eCommProductId}/packing-options`,
            this.http.prepareOptions({
                suppressProductNotFoundError: true,
                forcePriceCoefficient: 1
            }));
    }

}
