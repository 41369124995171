import { Observable ,  Subject } from 'rxjs';
import { SplitBasketItemDialogComponent } from './split.basket.item.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class SplitBasketItemDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public open(params: any): Observable<any> {
        this.bsModalRef = this.modalService.show(SplitBasketItemDialogComponent);
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
