import { trigger, state, transition, animate, style } from '@angular/animations';

export class Animations {
    public static slideInOut = trigger('slideInOut', [
        state('true', style({ display: 'block', height: '0px',  overflow: 'hidden' })),
        state('false', style({ overflow: 'hidden', display: 'block', height: '*' })),
        transition('1 => 0', animate('300ms ease-in')),
        transition('0 => 1', animate('300ms ease-out'))
    ]);
}
