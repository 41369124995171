import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable ,  Subject } from 'rxjs';
import { SharedService } from '../shared.service';
import { map } from 'rxjs/operators';
import { OrderItemsResponse } from '@app/model/order.model';

@Injectable()
export class OrdersItemsService {
    subjectReloadCurrent: Subject<any>;

    constructor (private http: EcmHttpService, 
                 private sharedService: SharedService) {
        this.subjectReloadCurrent = new Subject<any>();
        this.getOrderItemDeliveryDateHistory = this.getOrderItemDeliveryDateHistory.bind(this);
    }

    /**
     * Returns observable of reload - used to reload table data
     */
    getReloadCurrentSubject():Subject<any> {
        return this.subjectReloadCurrent;
    }    

    /** 
     * Loads list of products added to current order
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    public getOrdersItems(query):Observable<OrderItemsResponse> {
        let url = this.sharedService.hasPermission('orders', 'GET') ? `/orders-items` : `/orders-items-reduced`;
        // this default filter is now disabled because we do not have state that we do not want to show for now
        // query['orderState.operator'] = 'in';
        // query['orderState.value'] = 'N,C,X';
        if (!query.hasOwnProperty('amountOrdered.value')) {
            query['amountOrdered.operator'] = 'ne';
            query['amountOrdered.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountOrdered.operator'];
            delete query['amountOrdered.value'];
        }
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => {
                data.totalWeightCurrent = 0;
                data.totalValueCurrent = 0;
                data.rows.forEach(function(product){
                    product.url = '/catalogue/' + product.brandCode + '/' + encodeURIComponent(product.eCommProductId);
                    data.totalWeightCurrent += product.rowWeight;
                    data.totalValueCurrent += product.rowPrice;
                })
                return data;
        }));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = this.sharedService.hasPermission('orders', 'GET') ? `/orders-items` : `/orders-items-reduced`;
        // this default filter is now disabled because we do not have state that we do not want to show for now
        // query['orderState.operator'] = 'in';
        // query['orderState.value'] = 'N,C,X';
        if (!query.hasOwnProperty('amountOrdered.value')) {
            query['amountOrdered.operator'] = 'ne';
            query['amountOrdered.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountOrdered.operator'];
            delete query['amountOrdered.value'];
        }
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Returns order item delivery date history : {originalDeliveryDate: <date>, currentDeliveryDate: <date>}
     * @param id    - id of purchase order item
     */
    getOrderItemDeliveryDateHistory(id: string) {
        return this.http.get(`/order-item-delivery-date-history?id=` + id);
    }

}