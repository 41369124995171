import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { EditedIdsObject } from '@app/model/table.model';
import { ColDef } from 'ag-grid-community';
import { Customer } from '@app/model/customer.model';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';
import { CustomersService } from './customers.service';
import { BaseGridService, GridServiceOptions } from '../base/base-grid.service';
import { Subject } from 'rxjs';
import { AppAreas } from '@app/model/appArea.model';
import { GridSelectedRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';

@Injectable()
export class CustomersGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    private selected$: Subject<any> = new Subject<any>();
    public allSelected$: Subject<any> = new Subject<any>();

	constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private customersService: CustomersService,
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas.customers,
            gridNameCamel: 'customers',
            columnList: [
                {id: 'customerName', name: "CUSTOMER_NAME", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
                {id: 'customerCode', name: "CUSTOMER_CODE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'contactName', name: "CUSTOMER_CONTACT_NAME", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'contactPhone', name: "CUSTOMER_CONTACT_PHONE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
                {id: 'contactEmail', name: "CUSTOMER_CONTACT_EMAIL", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'priceListCode', name: "CUSTOMER_PRICE_LIST_CODE", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'priceListCurrency', name: "CUSTOMER_PRICE_LIST_CURRENCY", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'locality', name: "CUSTOMER_LOCALITY", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'country', name: 'COUNTRY', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'city', name: 'CITY', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'street', name: 'STREET', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'zip', name: 'ZIP', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'assignedSellerName', name: 'ASSIGNED_SELLER_NAME', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'assignedSellerPhone', name: 'ASSIGNED_SELLER_PHONE', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'productsNotTakenNotification', name: 'SEND_PRODUCTS_NOT_TAKEN_NOTIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'isERPCustomer', name: 'ERP_CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'customerStockActive', name: 'DISTRIBUTOR_STORES', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'isRfqManagedByAs', name: 'AS_CAN_MANAGE_RFQ', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
                {id: 'valid', name: "STATUS", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},    
            ].filter((item) => !this.customersService.isColumnHidden(item.id)),
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.getSelection(),
                    observable: this.selected$,
                    editedIdsObj: this.editedIdsObj
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.getSelection(),
                    editedIdsObj: this.editedIdsObj,
                    observable: this.allSelected$
                },
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerName').name), 
                field: "customerName",
                width: this.getColumn('customerName').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridLinkRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerCode').name), 
                field: "customerCode",
                width: this.getColumn('customerCode').width,
                cellStyle: {'text-align': 'left'}
            },            
            {
                headerName: this.translateService.instant(this.getColumn('contactName').name), 
                field: "contactName",
                width: this.getColumn('contactName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactPhone').name), 
                field: "contactPhone",
                width: this.getColumn('contactPhone').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('contactEmail').name), 
                field: "contactEmail",
                width: this.getColumn('contactEmail').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('priceListCode').name), 
                field: "priceListCode",
                width: this.getColumn('priceListCode').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('priceListCurrency').name), 
                field: "priceListCurrency",
                width: this.getColumn('priceListCurrency').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('locality').name), 
                field: "locality",
                width: this.getColumn('locality').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('country').name), 
                field: 'country',
                width: this.getColumn('country').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('city').name), 
                field: 'city',
                width: this.getColumn('city').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('street').name), 
                field: 'street',
                width: this.getColumn('street').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('zip').name), 
                field: 'zip',
                width: this.getColumn('zip').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('assignedSellerName').name), 
                field: 'assignedSellerName',
                width: this.getColumn('assignedSellerName').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('assignedSellerPhone').name), 
                field: 'assignedSellerPhone',
                width: this.getColumn('assignedSellerPhone').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productsNotTakenNotification').name), 
                field: 'productsNotTakenNotification',
                width: this.getColumn('productsNotTakenNotification').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => {
                    const customer: Customer = params.data;
                    return customer.productsNotTakenNotification ? this.translateService.instant('YES') : this.translateService.instant('NO');
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('isERPCustomer').name), 
                field: 'isERPCustomer',
                width: this.getColumn('isERPCustomer').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => {
                    const customer: Customer = params.data;
                    return customer.isERPCustomer ? this.translateService.instant('YES') : this.translateService.instant('NO');
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerStockActive').name), 
                field: 'customerStockActive',
                width: this.getColumn('customerStockActive').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => {
                    const customer: Customer = params.data;
                    return customer.customerStockActive ? this.translateService.instant('YES') : this.translateService.instant('NO');
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('isRfqManagedByAs').name), 
                field: 'isRfqManagedByAs',
                width: this.getColumn('isRfqManagedByAs').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => {
                    const customer: Customer = params.data;
                    return customer.isRfqManagedByAs ? this.translateService.instant('YES') : this.translateService.instant('NO');
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('valid').name), 
                field: "valid",
                width: this.getColumn('valid').width,
                cellStyle: {'text-align': 'center'},
                valueGetter: (params) => {
                    return this.translateService.instant('CUSTOMER_STATUS_' + (params.data.valid === 0 ? 'BLOCKED' : 'ENABLED'));
                }
            },
        ];

        colDefs = this.tableService.filterSuppressColumns(
            colDefs.filter(item => !this.customersService.isColumnHidden(item.field)),
            'catalogue'
        );
        return colDefs;
    }

    public getFilterItems(): TableFilterItem[] {
        const makeYesNoList = () => [{ id: '1', name: 'YES' }, { id: '0', name: 'NO' }]
            .map(item => ({ id: item.id, name: this.translateService.instant(item.name) }));

        const filterItems: TableFilterItem[] = [
            {
                id: 'customerName',
                name: 'CUSTOMER_NAME', 
                type: TableFilterItemTypes.text, 
                maxLength: 20, 
                value: '', 
                operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'priceListCurrency',
                name: 'CUSTOMER_PRICE_LIST_CURRENCY',
                type: TableFilterItemTypes.multiselect,
                value: '', 
                allLabel: 'CATALOGUE_ALL',
                selectedLabel: 'SELECTED_STATES',
                values: this.sharedService.priceListCurrencies.map(currency => ({ id: currency, name: currency })),
            },
            {
                id: 'assignedSellerName',
                name: 'ASSIGNED_SELLER_NAME', 
                type: TableFilterItemTypes.text, 
                maxLength: 20, 
                value: '', 
                operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'country',
                name: 'COUNTRY', 
                type: TableFilterItemTypes.text, 
                maxLength: 20, 
                value: '', 
                operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'productsNotTakenNotification',
                name: 'SEND_PRODUCTS_NOT_TAKEN_NOTIFICATION', 
                type: TableFilterItemTypes.multiselect, 
                value: '',
                allLabel: 'CATALOGUE_ALL',
                selectedLabel: 'SELECTED_STATES',
                values: makeYesNoList(),
            },
            {
                id: 'customerStockActive',
                name: 'DISTRIBUTOR_STORES', 
                type: TableFilterItemTypes.multiselect, 
                value: '',
                allLabel: 'CATALOGUE_ALL',
                selectedLabel: 'SELECTED_STATES',
                values: makeYesNoList(),
            },
            {
                id: 'isRfqManagedByAs',
                name: 'AS_CAN_MANAGE_RFQ', 
                type: TableFilterItemTypes.multiselect, 
                value: '',
                allLabel: 'CATALOGUE_ALL',
                selectedLabel: 'SELECTED_STATES',
                values: makeYesNoList(),
            },
            {
                id: 'valid',
                name: 'STATUS', 
                type: TableFilterItemTypes.multiselect, 
                value: '',
                allLabel: 'CATALOGUE_ALL',
                selectedLabel: 'SELECTED_STATES',
                values: [
                    { id: '1', name: this.translateService.instant('CUSTOMER_STATUS_ENABLED') },
                    { id: '0', name: this.translateService.instant('CUSTOMER_STATUS_BLOCKED') },
                ],
            },
        ];
        return this.tableService.filterSuppressColumns(
            filterItems.filter(item => !this.customersService.isColumnHidden(item.id)),
            'customers'
        );
    }
}
