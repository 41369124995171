import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from '@services/shared.service';
import { PrfqService } from '../../prfq.service';
import { ToastService } from '@app/services/toastService/toast.service';
import { ImportErrorDialogService } from '@app/components/import-error/import-error-dialog.service';
import * as moment from 'moment';
import { ImportErrorColumnDef, ImportModes } from '@app/components/import-error/import-error-dialog.model';

interface DataRow {
    productShortName: string,
    manufacturer: string,
    amountRequired: number,
    unitPriceRequired: number,
    dateRequired: Date
}

@Component({
    selector: 'app-prfq-detail-add-items',
    templateUrl: 'prfq-detail-add-items.component.html'
})
export class PrfqDetailAddItemsComponent {
    @Input() prfqId: number;
    @Output() onImportFinish: EventEmitter<{successCount: number}> = new EventEmitter<{successCount: number}>();

    private readonly columnsDef: ImportErrorColumnDef[];
    public readonly rowAttrs: string[];
    private successCount = 0;

    constructor(
        private sharedService: SharedService,
        private prfqService: PrfqService,
        private toastService: ToastService,
        private importErrorDialogService: ImportErrorDialogService
    ) {
        this.columnsDef = [];
        const productShortName: ImportErrorColumnDef = {
            attrName: 'productShortName',
            header: 'SHORT_NAME',
            type: 'string',
            isValid: (val) => this.prfqService.isColValid('productShortName', val)
        };
        this.columnsDef.push(productShortName);

        const amountRequired: ImportErrorColumnDef = {
            attrName: 'amountRequired',
            header: 'REQUIRED_AMOUNT',
            type: 'number',
            isValid: (val) => this.prfqService.isColValid('amountRequired', val)
        };
        this.columnsDef.push(amountRequired);

        const unitPriceRequired: ImportErrorColumnDef = {
            attrName: 'unitPriceRequired',
            header: 'REQUIRED_PRICE',
            type: 'decimal',
            isValid: (val) => this.prfqService.isColValid('unitPriceRequired', val)
        };
        this.columnsDef.push(unitPriceRequired);

        const dateRequired: ImportErrorColumnDef = {
            attrName: 'dateRequired',
            header: 'REQUIRED_DATE',
            type: 'date',
            isValid: (val) => this.prfqService.isColValid('dateRequired', val)
        };
        this.columnsDef.push(dateRequired);

        const manufacturer: ImportErrorColumnDef = {
            attrName: 'manufacturer',
            header: 'MANUFACTURER',
            type: 'string',
            isValid: (val) => this.prfqService.isColValid('manufacturer', val)
        };
        this.columnsDef.push(manufacturer);

        this.rowAttrs = this.columnsDef.map(columnDef => columnDef.attrName);
    }

    public onDataReady(dataRows: DataRow[]): void {
        dataRows = this.convertTypes(dataRows);
        this.submitItems(dataRows);
    }

    public submitItems(dataRows: DataRow[]): void {
        this.prfqService.createPrfqItems(this.prfqId, dataRows).subscribe(response => {
            this.successCount += response.toInsert.successCount;

            if (response.toInsert.errors.length === 0) {
                this.onImportFinish.emit({successCount: this.successCount});
                this.toastService.addSuccess('PRODUCT_ADDED_TO_RFQ_GENERAL');
                this.successCount = 0;
            } else {
                this.importErrorDialogService.openDialog(this.columnsDef, response.toInsert.successCount,
                    response.toInsert.errors, ImportModes.INSERT).subscribe(importErrorResult => {
                        if (importErrorResult.rows.length > 0) {
                            this.submitItems(importErrorResult.rows);
                        } else {
                            this.onImportFinish.emit({successCount: this.successCount});
                            this.successCount = 0;
                        }
                    });
            }
        },
        err => console.error(err));
    }

    private convertTypes(dataRows: DataRow[]): DataRow[] {
        return dataRows.map(dataRow => {

            let dateRequired: any = dataRow.dateRequired;
            if (dateRequired) {
                const momentDate = moment(dateRequired, 'D.M.YYYY');
                if (momentDate.isValid()) {
                    dateRequired = momentDate.toISOString();
                }
            }

            let unitPriceRequired: any = dataRow.unitPriceRequired;
            if (unitPriceRequired === '') {
                unitPriceRequired = null;
            }

            return {...dataRow, 
                dateRequired: dateRequired,
                unitPriceRequired: unitPriceRequired
            };
        });
    }
}
