<div class="row-container wider footer-page" style="max-width: 900px;">
	<h3><i class="fa fa-lock"></i> <span translate>SECURITY</span></h3>

	<div fxLayout="row">
		<div fxFlex="250px">
			<div class="row-container">
				<h4><span translate>HTTPS_SSL_ENCRYPTION</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span translate>{{'HTTPS_SSL_ENCRYPTION_TEXT_AS' + sharedService.apParams.id}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="250px">
			<div class="row-container">
				<h4><span translate>AMAZON_SERVERS</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span translate>{{'AMAZON_SERVERS_TEXT_AS' + sharedService.apParams.id}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="250px">
			<div class="row-container">
				<h4><span translate>DATABASE_CONTINUOUS_REPLICATION</span>:</h4>
			</div>
		</div>
		<div>
			<div class="row-container">
				<span translate>{{'DATABASE_CONTINUOUS_REPLICATION_TEXT_AS' + sharedService.apParams.id}}</span>
			</div>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="250px">
			<div class="row-container">
				<h4><span translate>YOUR_DATA_ARE_YOURS</span>:</h4>
			</div>
		</div>
		<div fxFlex="100">
			<div class="row-container">
				<span translate>YOUR_DATA_ARE_YOURS_TEXT</span>
			</div>
		</div>
	</div>
</div>