import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { SharedService } from '../../../../services/shared.service';
import { RfqsService } from '../../../../services/rfq/rfqs.service';
import { RfqsItemsGridService } from '../../../../services/rfq/rfqs.items.grid.service';
import { RfqPermissionService } from '@app/services/rfq/rfq.permission.service';
import { ExportObj } from '@app/model/table.model';

@Component({
    selector: 'rfqs-items',
    templateUrl: 'rfqs.items.component.html'
})
export class RfqsItemsComponent implements OnInit {

    totalRows: number;
    filterItems: any[];
    personsCreated: { id: string, name: string }[] = [];
    assignedSellerNames: { id: string, name: string }[] = [];
    subjectRefresh = new Subject<any>();
    public exportObj: ExportObj;

    busy: boolean;
    public initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table
    public readonly selectionAttrName = 'rfqItemsSelection';
    public resetFilter$ = new Subject<void>();
    private roleChangingIsRunning = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private rfqsService: RfqsService,
        private rfqsItemsGridService: RfqsItemsGridService,
        private rfqPermissionService: RfqPermissionService
    ) {
        this.subjectRefresh = new Subject<any>();

        this.getRfqItems = this.getRfqItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('RFQS_ITEMS'),
                export: this.rfqsService.exportAllItems.bind(this.rfqsService)
            };
        });

        const exportFunction = function () {
            const allowedStates = this.deliveryOrdersGridService.allowedStatesToExport;
            const selection = this.sharedService.user.preferences[this.selectionAttrName];
            const allowedItems = Object.keys(selection.ids).reduce((acc, item) => {
                if (allowedStates.indexOf(selection.ids[item].state) >= 0) {
                    acc.push(selection.ids[item]);
                }
                return acc;
            }, []);
            if (!selection.all && allowedItems.length === 0) { // if nothing to export
                this.toastService.addError('DELIVERY_ORDER_ALL_NOT_IN_ALLOWED_STATE_FOR_EXPORT');
                return;
            }
            if (selection.all || (allowedItems.length < Object.keys(selection.ids).length)) { // if some are in state that is not allowed for export
                this.toastService.addSuccess('DELIVERY_ORDER_EXPORTED_WILL_BE_ONLY_ALLOWED_STATES');
            }
            this.deliveryOrdersService.export(
                selection.all ? null : allowedItems.map(item => item.id),
                this.sharedService.translateService.instant('DELIVERY_ORDERS_SUMMARY'), 'CSV', true);
        };

        if (!this.sharedService.user.preferences.hasOwnProperty(this.selectionAttrName)) {
            this.sharedService.user.preferences[this.selectionAttrName] = { ids: {}, all: false, visible: false };
        }

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });

        this.initialized = true;
    }

    ngOnInit() {
        // set default filter if not filter already in query
        if (!this.route.snapshot.queryParams['rfqState.value']) {
            this.sharedService.lastRfqsItemsQueryParams['rfqState.operator'] = 'in';
            this.sharedService.lastRfqsItemsQueryParams['rfqState.value'] = 'P,N,O';
            this.router.navigate([], { queryParams: this.sharedService.lastRfqsItemsQueryParams });
        }
        setTimeout(() => {
            // navigate with orderby parameter
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastRfqsItemsQueryParams);
        }, 0);
    }

    public getRfqItems(params) {
        if (this.roleChangingIsRunning) {
            this.finishRoleChange();
            return;
        }
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };
        this.sharedService.lastRfqsItemsQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastRfqsItemsQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.rfqsService.getAllRfqItems(query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                this.personsCreated = data.personsCreated;
                this.assignedSellerNames = data.assignedSellerNames;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                params.successCallback(data.rows, data.totalCount);
                this.busy = false;
            }, err => {
                console.log(err);
                // also in error to be able to cancel filter
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                this.busy = false;
            });
    }

    public onRoleChange(): void {
        // Role change has two steps
        // 1. Reset filter - causing grid reload
        // 2. Reset the grid by hiding and re-showing it
        this.roleChangingIsRunning = true;
        this.resetFilter$.next();
    }

    private finishRoleChange(): void {
        this.roleChangingIsRunning = false;
        // Clear selection
        this.sharedService.resetSelection(this.selectionAttrName);

        // Reload grid after role change
        this.initialized = false;
        this.filterItems = null;

        setTimeout(() => {
            this.initialized = true;
        }, 0);
    }

    public isUserRoleSelectAvailable(): boolean {
        return this.rfqPermissionService.isElementAvailable('userRoleSelect');
    }

    getGridOptions() {
        return this.rfqsItemsGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    getFilterItems() {
        return this.rfqsItemsGridService.getFilterItems(this.personsCreated, this.assignedSellerNames);
    }
    getColumns() {
        return this.rfqsItemsGridService.getColumns();
    }
    getColumnsDefs() {
        return this.rfqsItemsGridService.getColumnDefs();
    }

    createNew() {
        this.rfqsService.createRfq()
            .subscribe(rfq => {
                this.router.navigate([`/${this.sharedService.appSettings.language}/rfq/${rfq.id}`]);
            }, err => {
                console.log(err);
            })
    }
}
