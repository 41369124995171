import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

interface Item {
    id: number | string,
    name: string,
    checked?: boolean,
    dotId?: string
}

@Component({
  selector: 'dropdown-component',
  templateUrl: 'dropdown.component.html',
  styleUrls:  ['./dropdown.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class DropdownComponent implements OnInit, OnChanges {
    @ViewChild('popover') private popover: ElementRef;
    @ViewChild('dropdownToggle', { static: true }) private dropdownToggle: ElementRef;
    @ViewChild('searchMultiple') private searchMultiple: ElementRef;
    @Input() attributeName: string;
    @Input() items: Item[];
    @Input() prefix: string;   // prefix to be added to name used to translate
    @Input() selected: any;  // value to be displayed as selected
    @Input() disabled: boolean;
    @Input() disableSelectedValueInButton: boolean
    @Input() autoClose: any;
    @Input() multiselect: boolean;
    @Input() disableOptionAll: boolean; // if true, dont show "All" item on top of component, default is false/undefined otherwise true
    @Input() search: boolean; // true if search is shown in list of items
    @Input() multiselectAllLabel?: string; // custom label to be shown when all are selected
    @Input() multiselectSelectedLabel?: string; // custom label to be shown when some items are selected
    @Input() multiselectFoundLabel?: string; // custom label to be shown when some items are filtered
    @Input() resetMultiselectSearch: Subject<any>;
    @Input() smaller: boolean;
    @Input() customDotColors: any;
    @Input() titleGetter: Function; // function that returns array of strings to show in title, each item is in new row
    @Input() dropup?: boolean; //  if true, it drops up, if false (default) it drops down
    @Input() justButtonLabel?: string; // to show only this string in button when multiple not all label and selected items labels
    @Input() disableSelectionOnSearch?: boolean; // to disable selection of item on search
    // @Input() changeItems: Subject<void>; // items have been updated from outside of the dropdown -> reload the dropdown to set the new items
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHidden: EventEmitter<any> = new EventEmitter<any>();
    @Output() onShown: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEnterInSearch: EventEmitter<any> = new EventEmitter<any>();

    isOpen: boolean;
    multiSelectedItems: any[] = [];
    searchMultipleItemsText = '';
    allItems: any[]; // to store all items for search in items

    public colors = { // can be overriden by setting custom colors
        black: 'D',
        gray: 'X',
        green: 'P',
        red: 'N',
        yellow: 'C',
        lime: 'L',
        blue: 'S',
        blueDarker: 'F',
        orange: 'O'
    };

    constructor(
        private translate: TranslateService
    ) {
    }

    public ngOnInit(): void {
        if (this.autoClose !== false) {
            this.autoClose = true;
        }
        if (this.disableOptionAll !== true) {
            this.disableOptionAll = false;
        }
        if (!this.selected) {
            this.selected = this.items[0];
        }
        if (this.multiselect) {
            // this.allItems = this.items.map(item => item); // to store all items for search in items
            // this.items = this.items.map(item => item);
            // this.multiSelectedItems = this.items.filter(function(item) { return item.checked} );
            this.initMultiselect();

            if (this.resetMultiselectSearch) {
                this.resetMultiselectSearch.subscribe(res => {
                    this.resetSearch();
                });
            }
        }
        if (this.customDotColors) {
            this.colors = this.customDotColors;
        }
        if (typeof this.dropup === 'undefined') {
            this.dropup = false;
        }
    }

    private initMultiselect(): void {
        this.allItems = this.items.map(item => item); // to store all items for search in items
        this.items = this.items.map(item => item);
        this.multiSelectedItems = this.items.filter(function(item) { return item.checked} );
    }

    /**
     * It should update internal dropdown structures to reflect a change of the source.
     * !!! WARNING !!! It is only implemented for the 'multiselect' type
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.items && !changes.items.firstChange) {
            console.log('ngOnChanges changes', changes);
            if (this.multiselect) {
                this.initMultiselect();
            }
        }
    }

    /**
     * Item was selected
     */
    select(item: any) {
        if (!this.disableSelectedValueInButton && !this.multiselect) {
            // set selected to button
            this.selected = item;
        }
        if (this.multiselect) {
            // check item if multiselect mode
            item.checked = !item.checked;
            this.multiSelectedItems = this.items.filter(function(item) { return item.checked });
            if (this.multiSelectedItems.length === 1) {
                this.selected = this.multiSelectedItems[0];
            }

        }

        if (!this.disableSelectionOnSearch) {
            setTimeout(() => {
                this.onSelect.emit({attributeName: this.attributeName, selected: item});
            }, 0);
        }
    }

    /**
     * Toggles all selected
     */
    public multiselectToggleAll(): void {
        if (this.multiSelectedItems.length === this.items.length) {
            this.multiselectCancelAll();
        } else {
            this.multiselectSelectAll();
        }
        this.multiSelectedItems = this.items.filter(function(item) { return item.checked });
    }

    /**
     * Selects all items in multiselect dropdown
     */
    multiselectSelectAll() {
        this.items.forEach(item => {
            item.checked = true;
            if (!this.disableSelectionOnSearch) {
                this.onSelect.emit({attributeName: this.attributeName, selected: item});
            }
        });
        this.multiSelectedItems = this.items.filter(function (item) { return item.checked });
    }
    /**
     * Deselects all items in multiselect dropdown
     */
    multiselectCancelAll() {
        this.items.forEach(item => {
            item.checked = false;
            if (!this.disableSelectionOnSearch) {
                this.onSelect.emit({attributeName: this.attributeName, selected: item});
            }
        });
        this.multiSelectedItems = this.items.filter(function (item) { return item.checked });
    }

    public multiselectApplyWhenSelectionOnSearchDisabled(): void {
        this.onSelect.emit({
            attributeName: this.attributeName,
            selected: this.searchMultipleItemsText.length > 0 ? this.allItems.filter(item => item.checked) : this.multiSelectedItems
        });
    }

    /**
     * Filter items in multiple select list
     */
    filterMultiValues(newValue) {
        // if (this.disableSelectionOnSearch && this.items.length > this.allItems.length) {
        //     this.allItems = this.items.map(item => item); // in case items were not already filled from outside when oninit
        // }
        this.items = this.allItems.map(item => {
            const hidden = !this.isSearchStringInValue(newValue, item.name);
            if (!this.disableSelectionOnSearch) {
                item.checked = !hidden;
                this.onSelect.emit({attributeName: this.attributeName, selected: item});
            }
            return item;
        }).filter(item => {
            return this.disableSelectionOnSearch ? this.isSearchStringInValue(newValue, item.name) : item.checked;
        });
        this.multiSelectedItems = this.items.filter(function (item) { return item.checked });
    }

    isSearchStringInValue(searchString: string, value: string): boolean {
        return this.translate.instant((this.prefix ? this.prefix : '') + value).toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    }

    /**
     * Resets search in multiple dropdown
     */
    public resetSearch(): void {
        setTimeout(() => {
            this.searchMultipleItemsText = '';
            if (this.allItems) {
                this.multiSelectedItems = this.allItems.filter(function (item) { return item.checked });

                this.items = this.allItems.map(item => {
                    if (!this.disableSelectionOnSearch) {
                        item.checked = this.multiSelectedItems.filter(selecteditem => selecteditem.id === item.id && selecteditem.checked).length === 1;
                        if (this.items.length === 0) { // set  to default if searched is empty
                            item.checked = item.hasOwnProperty('default') ? item.default : true;
                        }
                    }
                    return item;
                });
                this.multiSelectedItems = this.items.filter(function (item) { return item.checked });
            }
        }, 0);
    }


    /**
      * On key up of search input in multiple select
     */
    searchKeyUp(event) {
        if (event.keyCode === 13 && this.items.length > 0) { // enter and some items are to be filtered
            this.onEnterInSearch.next();
            this.isOpen = false;
        }
        if (event.keyCode === 27) { // escape
            this.isOpen = false;
            this.resetSearch();
        }
    }

    /**
     * Returns text that should be in button of select
     */
    public getSelectedBtnText(): string {
        if (this.justButtonLabel) {
            return this.translate.instant(this.justButtonLabel);
        } else if (this.selected.short) {
            return this.selected.short;
        } else {
            return this.selected.name === undefined ? this.selected : this.selected.name;
        }
    }

    /**
     * Returns text that should be in button of multiselect
     */
    public getMultiSelectedBtnText(): string {
        if (this.justButtonLabel) {
            return this.translate.instant(this.justButtonLabel);
        }
        const selectedItems = this.items.filter(function(item) {return item.checked});
        // this.multiSelectedItems = selectedItems;
        if (selectedItems.length === this.allItems.length) {
            return this.translate.instant(this.multiselectAllLabel ? this.multiselectAllLabel : 'MULTISELECT_FILTER_ALL').toLowerCase();
        }
        if (selectedItems.length < this.allItems.length && selectedItems.length > 1) {
            return this.translate.instant(this.multiselectSelectedLabel ? this.multiselectSelectedLabel : 'MULTISELECT_FILTER_SELECTED').toLowerCase() + ' (' + selectedItems.length + ')';
        }
        if (selectedItems.length < this.allItems.length && selectedItems.length === 1) {
            return selectedItems[0].name;
        }
    }

    /**
     * Hides popover, used when autoclose is false
     */
    hide() {
        if (!this.onHidden) { return; }
        this.onHidden.emit();
        this.isOpen = false;
        this.resetSearch();
    }
    /**
     * Shows popover, used when autoclose is false
     */
    public show(): void {
        if (this.onShown) {
            this.onShown.emit();
        }
        this.isOpen = true;

        if (this.search) {
            setTimeout(() => {
                this.searchMultiple.nativeElement.focus();
            }, 0);
        }
        // if (!this.allItems || this.allItems.length === 0) {
        //     setTimeout(() => { // in timeout to wait for "outside" component to provide the items after onShown.emit()
        //         this.allItems = this.items.map(item => item); // in case items were not already filled from outside when oninit
        //         this.multiSelectedItems = this.items.filter(item => item.checked);
        //     });
        // }
        // if (!this.onShown) {
        //     return;
        // }
        // this.onShown.emit();
        // this.isOpen = true;

        // if (this.search) {
        //     setTimeout(() => {
        //         this.searchMultiple.nativeElement.focus();
        //     }, 0);
        // }
    }

    /**
     * If click outside of popover, calls hide - used when autoclose is false
     */
    onClick(event) {
         if (this.popover && !this.popover.nativeElement.contains(event.target) && !this.dropdownToggle.nativeElement.contains(event.target)  && this.popover.nativeElement.clientHeight > 0) {
            this.hide();
         }
    }
}
