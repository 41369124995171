import { Component } from '@angular/core';
import { SharedService } from '@services/shared.service';

@Component({
  selector: 'security',
  templateUrl: 'security.component.html',
  // styleUrls:  ['./security.component.css']
})
export class SecurityComponent  {

  constructor(public sharedService: SharedService) {}

}
