<div class="input-container" style="width: 100%;">
    <div fxLayout="row">
        <label translate>INSERT_DATA</label>
        <ng-template #popClipboardImportTemplate>
            <div translate>IMPORT_CLIPBOARD_HELP</div>
            <div>{{ importHelp1Text | translate }}</div>
            <div translate>IMPORT_HELP_2</div>
        </ng-template>
        <i class="fa fa-info-circle clickable" [popover]="popClipboardImportTemplate" container="body"
            triggers="mouseenter:mouseleave"></i>
    </div>
    <textarea #pasteTextarea class="form-control" 
        [(ngModel)]="pastedExcel"
        (ngModelChange)="pasteExcelChanged(pastedExcel)"
        placeholder="{{ 'INSERT_CTRL_V' | translate }}"
        (keydown)="pasteExcelKeyDown($event, pasteTextarea)"
    ></textarea>

    <div>
        <button class="btn-primary" (click)="emitResult()"
            style="margin-top: 5px;"
            [disabled]="pastedExcel.length == 0 || disabled"
            [ngClass]="{'ec-disabled': pastedExcel.length == 0 || disabled}">
            
            <i class="fa fa-plus"></i>
            <a>{{addButtonText | translate}}</a>
        </button>
    </div>
</div>