import { Component } from '@angular/core';
import { SharedService } from '@services/shared.service'

@Component({
    selector: 'footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {

    constructor(public sharedService: SharedService) {

    }
}
