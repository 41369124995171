<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container clickable" routerLink="/{{sharedService.appSettings.language}}/orders" style="margin-right: 5px;">
            <span translate>ORDERS_SUMMARY</span>
        </span>
        <span class="row-container tab-active">
            <span translate>ORDERS_ITEMS</span>
        </span>
    </div>

<!-- 	<div fxLayout="row" fxLayoutAlign="end">
	    <button class="btn-secondary" (click)="printExportItems()">
	        <i class="fa fa-print"></i>
	        <span translate>PRINT_EXPORT</span>
	    </button>
	</div> -->

	<div fxLayout="column" style="margin-bottom: 20px;">
    	<ecm-table [dataGetter]="getOrdersItems" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>