import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridHeaderRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderRendererComponent/grid.header.renderer.component';
import { 
    GridJournalAdditionalInfoRendererComponent
} from '../../components/tableComponent/gridRendererComponents/gridJournalAdditionalInfoRendererComponent/grid.journal.additional.info.renderer.component';

@Injectable()
export class RfqJournalGridService {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
    ) {
    }

    getColumns(forceOriginal?: boolean, tablePrefix?: string): any[] {
        let original = [
            { id: 'dateCreated', name: "DATE_OF_CREATION", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'userCreatedSortName', name: "AUTHOR", checked: true, orderDirection: 'ASC' },
            { id: 'lineNumber', name: "ROW", checked: true, orderDirection: 'ASC', width: 80 },
            { id: 'eventType', name: "EVENT_TYPE", alwaysVisible: true, checked: true },
            { id: 'info', name: "ADDITIONAL_INFO", alwaysVisible: true, checked: true },
        ];

        let restored = this.sharedService.user.preferences[tablePrefix ? 'rfq' + tablePrefix + 'TableColumns' : 'rfqJournalTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored))
                return restored;
            else
                return original;
        } else
            return original;
    }

    getColumn(colId) {
        let columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    getGridOptions(startPage: number, suppressToUrl?: boolean, tablePrefix?: string) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'rfqJournalTablePageSize', headerClassFunc);
        gridOptions.defaultColDef = {
            headerComponent: GridHeaderRendererComponent,
            headerComponentParams: {
                columnsObjects: this_.getColumns(),
                columnsPreferenceKey: 'rfqJournalTableColumns',
                sharedService: this_.sharedService,
                suppressToUrl: true,
            },
            headerClass: headerClassFunc,

        };
        gridOptions.columnDefs = this_.getColumnDefs(suppressToUrl, tablePrefix);
        return gridOptions;
    }

    getColumnDefs(suppressToUrl?: boolean, tablePrefix?: string) {
        const this_ = this;
        const colDefs: any[] = [
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name),
                field: 'dateCreated',
                width: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                sort: 'DESC',
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                    suppressToUrl: suppressToUrl,
                    columnsObjects: this_.getColumns(false, tablePrefix),
                    sharedService: this_.sharedService,
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('userCreatedSortName').name),
                field: 'userCreatedSortName',
                width: this_.getColumn('userCreatedSortName').width,
                cellStyle: { 'text-align': 'left' },
                valueGetter: (params) => params.data.userCreatedFullName,
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                    suppressToUrl: suppressToUrl,
                    columnsObjects: this_.getColumns(false, tablePrefix),
                    sharedService: this_.sharedService,
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('lineNumber').name),
                field: 'lineNumber',
                width: this_.getColumn('lineNumber').width,
                suppressSizeToFit: true,
                valueGetter: (params) => params.data.lineNumber ? params.data.lineNumber : '',
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                    suppressToUrl: suppressToUrl,
                    columnsObjects: this_.getColumns(false, tablePrefix),
                    sharedService: this_.sharedService,
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('eventType').name),
                field: 'eventType',
                width: this_.getColumn('eventType').width,
                valueGetter: (params) => this_.translateService.instant('JOURNAL_' + params.data.eventType),
                sortable: false,
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                    suppressToUrl: suppressToUrl,
                    columnsObjects: this_.getColumns(false, tablePrefix),
                    sharedService: this_.sharedService,
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('info').name),
                field: 'info',
                width: this_.getColumn('info').width,
                cellClass: 'cell-wrap-text', autoHeight: true,
                cellStyle: { 'text-align': 'left' },
                cellRendererFramework: GridJournalAdditionalInfoRendererComponent,
                sortable: false,
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                    suppressToUrl: suppressToUrl,
                    columnsObjects: this_.getColumns(false, tablePrefix),
                    sharedService: this_.sharedService,
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc
            },
        ];
        return colDefs;
    }
    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }

    getFilterItems(personsCreated: { id: string, name: string }[] = []) {
        let filterItems: any[] = [
            { id: 'dateCreated', name: 'DATE_OF_CREATION', type: 'date', value: '', operator: { id: 'between', name: 'EQUAL', short: '=' } },
            { id: 'userId', name: 'AUTHOR', type: 'multiselect', search: true, value: '', values: personsCreated },
            {
                id: 'eventType', name: 'EVENT_TYPE', type: 'multiselect', search: true,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '', values: [
                    { id: 'SUBMIT_RFQ', name: 'JOURNAL_SUBMIT_RFQ' },
                    { id: 'OPEN_RFQ', name: 'JOURNAL_OPEN_RFQ' },
                    { id: 'CLOSE_RFQ', name: 'JOURNAL_CLOSE_RFQ' },
                    { id: 'REOPEN_RFQ', name: 'JOURNAL_REOPEN_RFQ' },
                    { id: 'ORDER_AND_CLOSE_RFQ', name: 'JOURNAL_ORDER_AND_CLOSE_RFQ' },
                    { id: 'INSERT_ITEM', name: 'JOURNAL_INSERT_ITEM' },
                    { id: 'J_CREATE_RFQ_POST', name: 'JOURNAL_J_CREATE_RFQ_POST' },
                    { id: 'CONFIRM_ITEM', name: 'JOURNAL_CONFIRM_ITEM' },
                    { id: 'ACCEPT_ITEM', name: 'JOURNAL_ACCEPT_ITEM' },
                    { id: 'ORDER_ITEM', name: 'JOURNAL_ORDER_ITEM' },
                    { id: 'J_UPDATE_ITEM', name: 'JOURNAL_J_UPDATE_ITEM' },
                    { id: 'CANCEL_ACCEPTED_ITEM', name: 'JOURNAL_CANCEL_ACCEPTED_ITEM' },
                    { id: 'CANCEL_ITEM', name: 'JOURNAL_CANCEL_ITEM' },
                    { id: 'REOPEN_ITEM', name: 'JOURNAL_REOPEN_ITEM' },
                    { id: 'DELETE_ITEM', name: 'JOURNAL_DELETE_ITEM' },
                    { id: 'J_EDIT_QUESTIONNAIRE', name: 'JOURNAL_J_EDIT_QUESTIONNAIRE' },
                    { id: 'J_DELETE_ATTACHMENT', name: 'JOURNAL_J_DELETE_ATTACHMENT' },
                    { id: 'J_CREATE_ATTACHMENT', name: 'JOURNAL_J_CREATE_ATTACHMENT' },
                ]
            },
        ];
        return filterItems;
    }

}

