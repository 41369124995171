import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";

@Component({
    selector: 'url-link',
    template: `<span *ngIf="!isUrlString">{{params.value}}</span>
    		   <a *ngIf="isUrlString" [attr.href]="params.value" target="_blank">{{params.value}}</a>
    		  `
})
export class GridUrlLinkRendererComponent implements AgRendererComponent {
    params:any;
    isUrlString: boolean;

    constructor() {
    }

    agInit(params:any):void {
        this.params = params;
        this.params.value = this.params.value ? this.params.value : '';
        this.isUrlString = this.params.value.match(/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g);
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}