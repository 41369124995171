import { Component, HostListener, ViewContainerRef, ChangeDetectorRef, Inject, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { Subject } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { ToastService } from '../../services/toastService/toast.service';
import { Animations} from '../../animations';
import { StockGridService } from './stock.grid.service';
import { ImportStockErrorDialogService } from './importStockErrorDialogComponent/import.stock.error.dialog.service';
import { QuestionDialogService } from '../../components/questionDialogComponent/question.dialog.service';
import { StockService } from './stock.service.interface';
import { UserService } from '../../services/user/user.service';
import { ExportObj, TableBulk } from '@app/model/table.model';

@Component({
    selector: 'app-stock-component',
    templateUrl: 'stock.component.html',
    styleUrls: ['./stock.component.scss'],
    animations: [Animations.slideInOut]
})
export class StockComponent implements OnInit, OnDestroy {

    @ViewChild('fileInput', { static: true }) fileInputElement: any;
    @Input() stockService: StockService;  // see StockService interface
    @Input() area: 'as-stock' | 'customer-stock';  // used in the grid service
    @Input() showCustomerStockOptions: boolean; // show CustomerStockOptionsComponent
    @Input() suppressAdding?: boolean; // suppress ability to add to stock

    translationPrefix: string;
    dataParseConfig: any;
    addProductsVisible = false;
    productName = '';
    pastedExcel = '';
    fromFileObjects: {amount: string, eCommProductId: string, brandCode: string}[] = [];
    pastedObjects: {amount: string, eCommProductId: string, brandCode: string}[] = [];
    addFromFileBusy: boolean;
    addFromPasteBusy: boolean;
    // brands:  any[] = [];
    // selectedBrandFromFile: {id: string, name: string};
    // selectedBrandPasted: {id: string, name: string};

    searchTimeout: any;
    waitUntilNoBusyTimeout: any;
    searchProductsByNameString = '';
    searchBusy: boolean;
    totalProductsRows: number;
    subjectProductsRefresh: Subject<any>;
    subjectDeleteSelected: Subject<any>;
    subjectAllSelected: Subject<any>;

    subjectReloadCurrent: Subject<any>;
    subjectRefresh: Subject<any>;

    busy;
    selectedCount = 0;
    public bulk: TableBulk;
    total;
    filterItems;
    initialized: boolean; // to be sure properties are initialized e.g. user.preferences['productsOfCurrentOrderSelection'] then show table
    public exportObj: ExportObj;

    constructor (
        private router: Router,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        public sharedService: SharedService,
        public stockGridService: StockGridService,
        private importStockErrorDialogService: ImportStockErrorDialogService,
        private questionDialogService: QuestionDialogService,
        private toastService: ToastService,
        private userService: UserService
    ) {
        this.getColumns     = this.getColumns.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.subjectRefresh = new Subject<any>();
        this.subjectDeleteSelected  = new Subject<any>();
        this.subjectProductsRefresh = new Subject<any>();

        this.subjectReloadCurrent = this.subjectReloadCurrent;

        // Bulk operations
        const bulkFnUpdate = (items, data) => {
            return this.stockService.updateStockItems(items, data, Object.assign({}, this.route.snapshot.queryParams));
        };

        this.bulk = {
            update: bulkFnUpdate.bind(this),
            items: [
                // {id: 'duplicate', name: 'DUPLICATE', value: null, type: 'button', iconClass: 'fa fa-clone', click: bulkFnDuplicate},
                // {id: 'distributorStores', name: 'CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY', value: null, type: 'button', iconClass: 'fa fa-database', click: bulkFnDistributorStores},
            ]
        }

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });

        this.initialized = true;
    }

    ngOnInit() {
        const mainConfig = {
            'as-stock': {
                translationPrefix: 'AS_STOCK',
                dataParseConfig: {
                    rowAttrs: ['brandCode', 'eCommProductId', 'amount'],
                    extraData: {}
                }
            },
            'customer-stock': {
                translationPrefix: 'DISTRIBUTOR_STORES',
                dataParseConfig: {
                    rowAttrs: ['eCommProductId', 'amount'],
                    extraData: { brandCode: this.sharedService.brands[0].code }  // add brandCode to each imported row
                }
            }
        };
        this.translationPrefix = (this.area in mainConfig) ? mainConfig[this.area].translationPrefix : '???';
        this.dataParseConfig = (this.area in mainConfig) ? mainConfig[this.area].dataParseConfig : {};

        this.stockGridService.setUpdateStockItemsFn(this.stockService.updateStockItems);
        this.stockGridService.setDeleteStockItemsFn(this.stockService.deleteStockItems);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('DISTRIBUTOR_STORES_ITEMS_IN_STORE'),
                export: this.stockService.exportAll.bind(this.stockService)
            };
        });
        this.subjectAllSelected = this.stockService.getAllStockSelectedSubject();

        this.subjectDeleteSelected.subscribe(res => {
            this.busy = true;
            this.stockService.deleteStockItems(res, this.route.snapshot.queryParams).subscribe(data => {
                let filterFound = false;
                if (res.length === 0 || res.length === this.total) {
                    for (const key in this.route.snapshot.queryParams) {
                        if (key !== 'orderBy' && key !== 'page') {
                            delete this.sharedService[`last${this.area}QueryParams`][key];
                            filterFound = true;
                        }
                    }
                    if (filterFound) {
                        delete this.sharedService[`last${this.area}QueryParams`]['page'];
                        this.router.navigate([], { queryParams: this.sharedService[`last${this.area}QueryParams`], replaceUrl: true });
                        return;
                    }
                }
                this.reload();
            }, err => {
                console.log(err);
            });
        });

        // item was selected
        this.stockService.getStockSelectedSubject().subscribe(res => {
            const preference = this.sharedService.user.preferences[`${this.area}Selection`];
            if (res.selected) {
            }
            localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            this.selectedCount = this.getSelectedCount();
        });

        if (!this.sharedService.user.preferences.hasOwnProperty(`${this.area}ItemsSelection`)) {
            this.sharedService.user.preferences[`${this.area}ItemsSelection`] = { ids: {}, all: false, visible: false };
        }

        this.subjectProductsRefresh = new Subject<any>();
        this.subjectReloadCurrent = this.stockService.getReloadCurrentSubject();

        this.getStockItems = this.getStockItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.addProductsVisible = !!this.sharedService.user.preferences[`${this.area}AddPanelVisible`];
        this.productName = '';
        this.searchProductsByNameString = '';

        // this.sharedService.brands.forEach(brand => {
        //     this.brands.push({id: brand.code, name: brand.name});
        // });
        // this.selectedBrandFromFile = this.brands[0];
        // this.selectedBrandPasted = this.brands[0];
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }

    toggleAddPanel() {
        this.addProductsVisible = !this.addProductsVisible;
        if (!this.addProductsVisible) {
            this.productName = '';
            this.cancelProductsSearch();
        }
        this.userService.setUserPreference(`${this.area}AddPanelVisible`, this.addProductsVisible, true);
    }

    getEditedCount() {
        return this.stockGridService.itemsEditedIds ? Object.keys(this.stockGridService.itemsEditedIds).length : 0;
    }

    /**
     * Cancels search of products
     */
    cancelProductsSearch() {
        this.searchProductsChange('');
    }

    reload() {
        this.subjectReloadCurrent.next();
    }

    /**
     * Clears timeout and start new timeuot, after wich search string is set to query string to call request
     */
    searchProductsChange(newValue: string) {
        clearTimeout(this.searchTimeout);
        if (this.searchBusy) {
            this.waitUntilNotBusy();
            return;
        }
        const this_ = this;
        this.searchTimeout = setTimeout(() => {
            this_.searchProductsByNameString = newValue;
              this_.cdRef.detectChanges();
              if (this_.searchProductsByNameString.length > 0) {
                this_.searchBusy = true
                  // this_.userService.setUserPreference('current-orderProductsTablePage', 0);
                  this_.subjectProductsRefresh.next(0);

                if ( this.sharedService.user.representative !== 'AS' ) {
                    // this_.restartTimer(); // monitoring
                }
              }
        }, 800);
    }

    waitUntilNotBusy() {
        clearTimeout(this.waitUntilNoBusyTimeout);
        const this_ = this;
        this.waitUntilNoBusyTimeout = setTimeout(() => {
            if (!this_.searchBusy) {
                this_.searchProductsChange(this_.productName);
            } else {
                this_.waitUntilNotBusy();
            }
        }, 200);
    }

    /**
     * Change tab key to \t and not go to next focusable element when tab is pressed
     * @param event - keydown event
     * @param element - textarea html element
     */
    pasteExcelKeyDown(event, element) {
        if (event.keyCode === 9) {
            // tslint:disable-next-line:prefer-const
            let v = element.value, s = element.selectionStart, e = element.selectionEnd;
            element.value = v.substring(0, s) + '\t' + v.substring(e);
            element.selectionStart = element.selectionEnd = s + 1;
            this.pasteExcelChanged(element.value);
            return false;
        }
    }

    /**
     * Adds products to order from object parsed from clipboard
     */
    ctrlVAddToOrder() {
        if (this.total > 0) {
        this.questionDialogService.confirm(
            {
                message: 'DISTRIBUTOR_STORES_REALLY_TO_DELETE',
                question: 'DISTRIBUTOR_STORES_REALLY_TO_DELETE_WARNING',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    this.stockService.deleteStockItems([]).subscribe(
                        done => {
                            this.toastService.addSuccess('DISTRIBUTOR_STORES_SUCCESSFULLY_DELETED');
                            this.addCtrlVAddToOrder();
                        },
                        err => console.error(err)
                    );
                }
            }
        );
        } else {
            this.addCtrlVAddToOrder();
        }
    }

    addCtrlVAddToOrder() {
        this.addFromPasteBusy = true;
        this.stockService.addStockItems(this.pastedObjects)
            .subscribe(data => {
                // reset textarea input
                this.pastedExcel = '';
                this.pastedObjects = [];
                this.addFromPasteBusy = false;
                this.subjectReloadCurrent.next();
                if (data.errors && data.errors.length > 0) {
                    this.showImportBasketErrorDialog(data);
                }
            }, err => {
                this.addFromPasteBusy = false;
                console.log(err);
            });
    }
    /**
     * File was chosen in file input for order from file, call get objects
     *
     * @param fileInput - chosen file
     */
    fileChangeEvent(fileInput: any) {
        const this_ = this;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // tslint:disable-next-line:prefer-const
            let reader = new FileReader();
            reader.onload = function (e: any) {
                // this_.fromFileObjects = this_.getObjectsFromSeparatedString(reader.result, this_.selectedBrandPasted.id);
                this_.fromFileObjects = this_.getObjectsFromSeparatedString(reader.result, this_.dataParseConfig.rowAttrs, this_.dataParseConfig.extraData);
            }

            reader.readAsText(fileInput.target.files[0]);
        }
    }

    /**
     * Text was pasted or changed in textarea for clippboard import of order
     *
     * @param newValue - string of changed value of textarea
     */
    pasteExcelChanged(newValue: string) {
        // this.pastedObjects = this.getObjectsFromSeparatedString(newValue, this.selectedBrandFromFile.id);
        this.pastedObjects = this.getObjectsFromSeparatedString(newValue, this.dataParseConfig.rowAttrs, this.dataParseConfig.extraData);
    }
    /**
     * Adds products to order from object parsed from file
     */
    fileCsvAddToOrder() {
        if (this.total > 0) {
        this.questionDialogService.confirm(
            {
                message: 'DISTRIBUTOR_STORES_REALLY_TO_DELETE',
                question: 'DISTRIBUTOR_STORES_REALLY_TO_DELETE_WARNING',
                primary: 'YES',
                secondary: 'NO',
            }
        ).subscribe(
            res => {
                if (res === 'confirm') {
                    this.stockService.deleteStockItems([]).subscribe(
                        done => {
                            this.toastService.addSuccess('DISTRIBUTOR_STORES_SUCCESSFULLY_DELETED');
                            this.addFileCsvAddToOrder();
                        },
                        err => console.error(err)
                    );
                }
            }
        );
        } else {
            this.addFileCsvAddToOrder();
        }

    }
    addFileCsvAddToOrder() {
        this.addFromFileBusy = true;
        this.stockService.addStockItems(this.fromFileObjects)
            .subscribe(data => {
                // reset file input
                this.fileInputElement.nativeElement.value = '';
                this.fromFileObjects = [];
                this.addFromFileBusy = false;
                this.subjectReloadCurrent.next();
                if (data.errors && data.errors.length > 0) {
                    this.showImportBasketErrorDialog(data);
                }
            }, err => {
                this.addFromFileBusy = false;
                console.log(err);
            });
    }
    /**
     * Shows error dialog of import to basket
     */
    showImportBasketErrorDialog(data: any) {
        const additionalData = {
            showBrandCode: !('brandCode' in this.dataParseConfig.extraData)
        };
        this.importStockErrorDialogService.confirm(Object.assign({}, data, additionalData)).subscribe(
            res => {
                if (res.rows) {
                    this.stockService.addStockItems(res.rows)
                    .subscribe(rowData => {
                        if (rowData.errors && rowData.errors.length > 0) {
                            this.showImportBasketErrorDialog(rowData);
                        }
                        this.subjectReloadCurrent.next();
                    }, err => {
                        console.log(err);
                    });
                }
            }
        );
    }

    /**
     * Returns object parsed from inpud separated string string
     *
     * @param separatedString  - string separated by new lines and by separator in lines
     * @param separator  - separator used in lines
     */
    // getObjectsFromSeparatedString(separatedString: any, brandCode: string) {
    //     // tslint:disable-next-line:prefer-const
    //     let objects = [];
    //     let separator: string;
    //     // tslint:disable-next-line:prefer-const
    //     let rows = separatedString.split(/\r\n|\r|\n/g);
    //     if (rows.length > 0) {
    //         separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
    //     }
    //     rows.forEach(function(row: string) {
    //         if (row.length > 0) {
    //             let amount = '';
    //             let code = '';
    //             // tslint:disable-next-line:prefer-const
    //             let splittedRowBySeparator = row.split(separator);
    //             if (splittedRowBySeparator.length > 0) {
    //                 code = splittedRowBySeparator[0];
    //             }
    //             if (splittedRowBySeparator.length > 1) {
    //                 amount = splittedRowBySeparator[1].replace(/\ /g, '');
    //             }

    //             if (amount.length > 0 || code.length > 0 ) {
    //                 objects.push({amount: amount, eCommProductId: code, brandCode: brandCode});
    //             }
    //         }
    //     });
    //     return objects
    // }

    /**
     * Returns object parsed from inpud separated string string
     *
     * @param separatedString string separated by new lines and by separator in lines
     * @param attrs array of attributes of the line
     * @param extraData optional dat, e.g. { brandCode: 'ZVL' }
     */
    getObjectsFromSeparatedString(separatedString: any, attrs: string[], extraData = {}) {
        // tslint:disable-next-line:prefer-const
        let result = [];
        let separator: string;
        // tslint:disable-next-line:prefer-const
        let rows = separatedString.split(/\r\n|\r|\n/g);
        if (rows.length > 0) {
            separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
        }
        rows.forEach((row: string) => {
            if (row.length > 0) {
                const rowResultObj = {};
                const rowParts = row.split(separator);
                let empty = true;
                rowParts.forEach((part, i) => {
                    const attr = attrs[i];
                    rowResultObj[attr] = attr === 'amount' ? part.replace(/\ /g, '') : part;
                    empty = empty && rowResultObj[attr].length === 0;
                });

                if (!empty) {
                    result.push(Object.assign({}, rowResultObj, extraData));
                }
            }
        });
        return result
    }

    getSelectedCount() {
        return this.sharedService.user.preferences[`${this.area}Selection`] ?
            Object.keys(this.sharedService.user.preferences[`${this.area}Selection`].ids).length : 0;
    }
    getStockItems(params) {
        this.busy = true;
        // tslint:disable-next-line:prefer-const
        let query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService[`last${this.area}QueryParams`] = {};
        for (const key in this.route.snapshot.queryParams) {
            if (key) {
                this.sharedService[`last${this.area}QueryParams`][key] = this.route.snapshot.queryParams[key];
                if (key !== 'page') {
                    query[key] = this.route.snapshot.queryParams[key]
                }
            }
        }

        if (query.skip >= 0 && query.top > 0) {
            this.stockService.getStockItems(query).subscribe(data => {
                this.total = data.totalCount;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getFilterItems();
                }
                params.successCallback(data.rows, data.totalCount);
                this.busy = false;
            }, err => {
                console.log(err);
                this.busy = false;
            });
        }
    }
    getGridOptions() {
        return this.stockGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10), this.area,
            this.stockService.getAllStockSelectedSubject(), this.stockService.getReloadCurrentSubject());
    }
    getFilterItems() {
        return this.stockGridService.getFilterItems();
    }
    getColumns() {
        return this.stockGridService.getColumns(false, this.area);
    }
    getColumnsDefs() {
        return this.stockGridService.getColumnDefs(this.area, this.stockService.getAllStockSelectedSubject(), 
            this.stockService.getReloadCurrentSubject());
    }

    printExportOrders() {
        console.log('printExportOrders');
    }

}
