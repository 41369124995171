import { AvailabilityFulfillings, AvailabilityState, PackingOption } from '@model/basket.model';

export enum rfqActions {
    CREATE_FRQ = 'CREATE_RFQ',
    DELETE_RFQ = 'DELETE_RFQ',
    SUBMIT_RFQ = 'SUBMIT_RFQ',
    OPEN_RFQ = 'OPEN_RFQ',
    CLOSE_RFQ = 'CLOSE_RFQ',
    REOPEN_RFQ = 'REOPEN_RFQ',
    ORDER_AND_CLOSE_RFQ = 'ORDER_AND_CLOSE_RFQ',

    INSERT_ITEM = 'INSERT_ITEM',
    DELETE_ITEM = 'DELETE_ITEM'
}

export enum RfqStates {
    IN_PROGRESS = 'P',  // Rozpracovaný – P – in progress
    NEW = 'N',   // Nový – N – new,
    OPENED = 'O',  // - Otvorený – O – open,
    CLOSED_SUCCESSFULLY = 'S', // Ukončený s objednaním – S – successful,
    FAILED = 'F' // Ukončený bez objednania– F – failed
}

export class Rfq {
    public id: number;
    public rfqNumber: string;
    public rfqState: string;
    public rfqNote: string;
    public priceListCode: string;
    public priceListCurrency: string;
    public totalPrice: number;
    public totalPriceCurrency: string;
    public postsCount: number;
    public attachmentsCount: number;
    public customerId: number;
    public customerName: string;
    public dateCreated: string; // Date;
    public userCreatedFullName: string;
    public userCreatedSortName: string;
    public assignedSellerName: string;
    public dateClosed: string; // Date;
    public url: String;
    public attachments: any[];
    public actions?: string[];
    public customerValid?: number;
    public customerIsRfqManagedByAs?: number;
    public rfqCloseQuestionnaire?: RfqCloseQuestionnaire | null;

    constructor(item: any) {
        this.id = item.id;
        this.rfqNumber = item.rfqNumber;
        this.rfqState = item.rfqState;
        this.rfqNote = item.rfqNote;
        this.priceListCode = item.priceListCode;
        this.priceListCurrency = item.priceListCurrency;
        this.totalPrice = item.totalPrice;
        this.totalPriceCurrency = item.totalPriceCurrency;
        this.postsCount = item.postsCount;
        this.attachmentsCount = item.attachmentsCount;
        this.customerId = item.customerId;
        this.customerName = item.customerName;
        this.dateCreated = item.dateCreated;
        this.userCreatedFullName = item.userCreatedFullName;
        this.userCreatedSortName = item.userCreatedSortName;
        this.assignedSellerName = item.assignedSellerName;
        this.dateClosed = item.dateClosed;
        this.url = item.url;
        this.attachments = item.attachments;
        this.actions = item.actions;
        this.customerValid = item.customerValid;
        this.customerIsRfqManagedByAs = item.customerIsRfqManagedByAs;
        this.rfqCloseQuestionnaire = item.rfqCloseQuestionnaire 
            ? new RfqCloseQuestionnaire(item.rfqCloseQuestionnaire)
            : item.rfqCloseQuestionnaire;
    }
}

export class RfqToInsert {
    constructor (
        public amountOffered = 0,
        public amountRequired = 0,
        public brandCode = 'ZVL',
        // public currencyPriceOffered: string = 'EUR',
        public dateOfferedDays: number = null,
        public dateRequired: Date = null,
        public dateOffered: Date = null,
        public dateOfferedValidity: Date = null,
        public itemValidity: Date = null,
        public itemType = 'rfq',
        public rfqProduct = '',
        public rfqProductAS = '',
        public unitPriceRequired: number = null,
        public unitPriceOffered: number = null,
    ) { }
}
export class RfqToUpdate {
    constructor () {}
    }

export interface RfqItem {
    actions: rfqActions[];
    amountInPacking: number;
    amountMissing: number;
    amountOffered: number; // 1
    amountOrdered: number; // 1
    amountPackages: number; // 1
    amountRequired: number; // 1
    assignedSellerName: string; // "Matejko Metalovic"
    attachmentsCount: number; // 0
    authorizedSellerName: string; // "ZVL SLOVAKIA, a.s."
    availabilityFulfillings: AvailabilityFulfillings[];
    availabilityState: AvailabilityState; // "PARTIALLY_FULFILLED"
    brandCode: string; // "ZVL"
    currencyPriceCatalogue: string; // "EUR"
    currencyPriceOffered: number; // null
    currencyPriceRequired: string; // "EUR"
    rowPrice: number | null; // 12
    customerId: number; // 7
    customerName: string; // "Zákazník s.r.o."
    dateChanged: Date; // null
    dateChangedAS: Date; // "2019-11-04T13:12:17.000Z"
    dateCreated: Date; // "2019-11-04T13:10:58.000Z"
    dateOffered: Date; // null
    dateOfferedCalculated: Date; // null
    dateOfferedDays: number; // null
    dateOfferedValidity: Date; // null
    dateRequired: Date; // null
    dateConfirmed: Date | null; // null
    defaultPacking: string; // 'A'
    eCommProductId: string; // "1201"
    id: number; // 3102
    itemNote: string; // null
    itemState: string; // "F"
    itemType: string; // ""
    itemValidity: Date; // null
    lineNumber: number; // 1
    minDeliveryDate: string; // "2019-11-07"
    nonCancelable: number; // 0
    packing: string; // "A"
    packingOptions: PackingOption[]; // [{ packing: "A", amount: 1, priceCoefficient: 1 }]
    personCreatedFullName: string; // "Štric Rasťooo"
    personCreatedId: number; // 1
    personCreatedSortName: string; // "Štric Rasťooo"
    postsCount: number; // 0
    rfqId: number; // 220
    rfqNumber: string; // "RFQ1900012"
    rfqProduct: string; // "1201"
    rfqProductAS: string; // "1201"
    rfqState: string; // "O"
    unitPriceCatalogue: number; // 0.94
    unitPriceOffered: number; // null
    unitPriceRequired: number; // null
    // added locally
    amountPackagesNotRounded: number;
    originalValues: { colId: string, value: any }[]; // [{ colId: 'amountOffered', value: row.amountOffered }]
}

export interface RfqItemsResponse {
    rows: RfqItem[];
    assignedSellerNames: { id: string, name: string }[]; // [{id: "Matejko Metalovic", name: "Matejko Metalovic"}]
    personsCreated: { id: string, name: string }[]; // [{id: 2, name: "Húšťava Matej"}, {id: 3, name: "Mravec Karol"}]
    totalCount: number; // 7
    total?: number;
}

export class RfqCloseQuestionnaire {
    public priceNotSuitable: boolean;
    public deliveryDateNotSuitable: boolean;
    public highMoq: boolean;
    public constructionNotSuitable: boolean;
    public other: boolean;
    public notes: string;

    constructor(item?: any) {
        const _item = item || {};

        this.priceNotSuitable = _item.priceNotSuitable || false;
        this.deliveryDateNotSuitable = _item.deliveryDateNotSuitable || false;
        this.highMoq = _item.highMoq || false;
        this.constructionNotSuitable = _item.constructionNotSuitable || false;
        this.other = _item.other || false;
        this.notes = _item.notes || '';
    }
}
