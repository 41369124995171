import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'app-file-loader',
    templateUrl: 'file-loader.component.html'
})
export class FileLoaderComponent {
    @Input() rowAttrs: string[]; // ['eCommProductId', 'amountOrdered]
    @Input() addButtonText: string; // 'ADD_TO_ORDER'
    @Input() importHelp1Text: string; // 'PRICE_LIST_IMPORT_HELP_1_3'
    @Input() disabled: boolean;
    @Output() onResultReady: EventEmitter<any[]> = new EventEmitter<any[]>();

    @ViewChild('fileInput', { static: true }) fileInputElement: any;
    public parsedRows: any[] = [];

    constructor(
        private sharedService: SharedService
    ) {
    }

    /**
     * File was chosen in file input for order from file, call get objects
     * @param fileInput - chosen file
     */
    public fileChangeEvent(fileInput: any): void {
        this.parsedRows = [];
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.parsedRows = this.sharedService.getObjectsFromSeparatedString(
                    reader.result as string, this.rowAttrs);
            }

            reader.readAsText(fileInput.target.files[0]);
        }
    }

    public emitResult(): void {
        this.onResultReady.emit(this.parsedRows);
    }

}
