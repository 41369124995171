import { Component, EventEmitter, Input, Output, ViewContainerRef, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { EcmHttpService } from '../../../services/http/ecm.http.service';
import { SharedService } from '../../../services/shared.service';
import { ParametersService } from '../../../services/parameters.service';
import { UserService } from '../../../services/user/user.service';
import { CleanUpService } from '../../../services/clean.up.service';
import { BusinessConditionsService } from 'app/services/businessConditions/business.conditions.service';
import { RequireBusinessConditionsDialogService } from 'app/components/login/requireBusinessConditionsDialogComponent/require.business.conditions.dialog.service';
import { NewsService} from '../../../services/news/news.service';
import { HomepageService } from '@app/services/homepage.service';
import { ToastService } from '@services/toastService/toast.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    @Input()  params: any;
    @Output() notify: EventEmitter<any> = new EventEmitter<any>();

    email: string;
    password: string;
    passwordAgain: string;
    givenName: string;
    familyName: string;
    phoneNumber: string;
    acceptBusinessConditions: boolean;
    requireBusinessConditions: boolean;
    confirmationCode: string;
    tempPass: string;

    passHelpVisible = false;

    mode = 'login';  // can be: 'login', 'forgottenPassword', 'clickOnLink', 'confirmForgotPassword', 'setCredentials'

    busy = false;

    constructor(private router: Router,
                private httpService: EcmHttpService,
                public sharedService: SharedService,
                private authentication: AuthenticationService,
                private parametersService: ParametersService,
                private userService: UserService,
                private cleanUpService: CleanUpService,
                private businessConditionsService: BusinessConditionsService,
                private requireBusinessConditionsDialogService: RequireBusinessConditionsDialogService,
                private newsService: NewsService,
                private homepageService: HomepageService,
                private toastService: ToastService
    ) {
    }

    ngOnInit() {
        if (this.params) {
            this.email = this.params.email;
            this.confirmationCode = this.params.confirmationCode;
            this.tempPass = this.params.tempPass;
            if (this.email && this.confirmationCode) {
                this.mode = 'confirmForgotPassword';
            }
            if (this.email && this.tempPass) {
                this.mode = 'setCredentials';
                this.requireBusinessConditions = this.params.requireBusinessConditions === 'true';
            }
        }

        if (this.mode !== 'login') {
            const this_ = this;
            setTimeout(function() {
                this_.sharedService.clear();
                this_.cleanUpService.cleanUp();
            });
        }
    }

    primary() {
        switch (this.mode) {
            case 'login':
                this.login();
                break;
            case 'forgottenPassword':
                this.resetPassword();
                break;
            case 'confirmForgotPassword':
                this.confirmForgotPassword();
                break;
            case 'setCredentials':
                this.setCredentials();
                break;
            default:
                // code...
                break;
        }
    }

    /**
     * Logs user in and notifies observers of login component or redirects to catalogue after login success
     */
    login() {
        if (!this.sharedService.refreshOnNextSafeRouteChange) { // check version off client app if not already set to refresh
            this.httpService.checkVersion();
        }
        this.busy = true;
        this.authentication.login(this.email, this.password).subscribe(data => {
            this.parametersService.getParameters().subscribe(() => {
                this.getUserDetails();
            });
        }, err => {
            console.log(err)
            this.busy = false;
            if (err.error && err.error.message === 'NO_VALID_CUSTOMER_NOR_AS_NOR_SUPPLIER') {
                this.toastService.addError('NO_VALID_CUSTOMER_NOR_AS_NOR_SUPPLIER');
            }
        });
    }

    /**
     * Loads user details and application params
     */
    getUserDetails() {
        this.userService.getUserDetails().subscribe(data => {
            this.getUserPreferences();
            this.userService.loadDataAfterUserChange();
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    getUserPreferences() {
        this.userService.getUserPreferences().subscribe(data => {
            if (this.sharedService.params.showBusinessConditions && this.sharedService.user.representative === 'customer' && !this.sharedService.user.businessCondConsent) {
                this.requireBusinessConditionsDialogService.confirm().subscribe(result => {
                    if (result) {
                        this.userService.sendBusinessConditionsConsent().subscribe(() => {
                            this.continueAfterLoginAndInitialDataLoad();
                        });
                    } else {
                        this.userService.logout().subscribe(() => {
                            this.notify.emit(false);
                        }, err => console.log(err));
                    }
                });
            } else {
                this.continueAfterLoginAndInitialDataLoad();
            }
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    continueAfterLoginAndInitialDataLoad() {
        this.newsService.clean(); // to open news dialog on startTimer
        this.newsService.startTimer(); // to open news dialog if neccessary
        if (this.notify.observers.length > 0) { // there are some observers so notify them that login was successful
            this.notify.emit(true);
        } else { // there no observers so just redirect to catalogue if has permissions or home if not

            // homepage is one of homepageSettingsDef if defined, otherwise is 'orders'
            // const homepage = this.sharedService.homepageSettingsDef.filter(
            //     item => item.id === ( this.sharedService.user.homepage ? this.sharedService.user.homepage : 'orders'))[0];

            // route if user has permission to saved homepage or 'orders', otherwise go to home page
            this.router.navigate(['/' + this.sharedService.appSettings.language + '/' +
                this.homepageService.getHomepageRoute(this.sharedService.user.homepage) ]);

        }
        this.busy = false;
    }

    resetPassword() {
        this.busy = true;
        this.authentication.resetPassword(this.email).subscribe(data => {
            this.busy = false;
            this.mode = 'clickOnLink';
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    confirmForgotPassword() {
        this.busy = true;
        this.authentication.confirmForgotPassword(this.email, this.password, this.confirmationCode).subscribe(data => {
            this.login();
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    setCredentials() {
        this.busy = true;
        this.authentication.setCredentials(this.email, this.password, this.tempPass, this.givenName, this.familyName, this.phoneNumber,
            this.requireBusinessConditions ? (this.acceptBusinessConditions ? 1 : 0) : 0).subscribe(data => {
            this.login();
        }, err => {
            console.log(err)
            this.busy = false;
        });
    }

    /**
     * Valid password test
     * @returns	 null  if password is valid
     *           {     if password is not valid
     *           	<problem>: true,
     *           	...
     *           } where problem is lowercase/uppercase/number/passLength -> any combination
     */
    isPasswordValid(password: string): any {
        password = typeof password !== 'string' ? '' : password;

        const passValid = {
            lowercase : password.match(/[a-z]/) != null,
            uppercase : password.match(/[A-Z]/) != null,
            number    : password.match(/\d/) != null,
            passLength: password.length >= 8
        };

        return Object.keys(passValid).reduce((acc, key) => passValid[key] ? acc : Object.assign(acc ? acc : {}, {[key]: true}), null);
    }

    /**
     * hides help with password
     */
    hidePassHelp(errors) {
        if (!errors) {
            this.passHelpVisible = false;
        }
    }

    /**
     * shows help with password
     */
    showPassHelp() {
        this.passHelpVisible = true;
    }

    toForgottenPassword() {
        this.mode = 'forgottenPassword';
    }
    toLogin() {
        this.mode = 'login';
    }

    dismiss() {
        this.sharedService.clear();
        this.notify.emit(false);
    }

    downloadBusinessConditions() {
        this.businessConditionsService.downloadBusinessConditions();
    }

}
