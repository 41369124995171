import { Component,EventEmitter, Output, ViewChildren, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'search',
  templateUrl: 'search.component.html',
  styleUrls:  ['./search.component.css']
})
export class SearchComponent  { 
	@ViewChildren('searchInput', {read: ViewContainerRef}) searchInput;
	@Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

	searchString: string;
	dropdownVisible: boolean;
	
	constructor(private router: Router, 
				private sharedService: SharedService, 
				private translate: TranslateService) {
		this.searchString = '';
		this.dropdownVisible = false;
	}

	toggled(toggled) {
		this.dropdownVisible = toggled;
		if (toggled) {
			var this_ = this;
        	setTimeout(function() {
				this_.searchInput.first.element.nativeElement.focus();
			}, 0);
		}
	}
}