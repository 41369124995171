import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import {
    Router, ActivatedRoute, Params,
    NavigationExtras
} from '@angular/router';

interface ItemsPerPageOption {
    id: number,
    name: string
}

@Component({
    selector: 'table-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
    @Input() public gridOptions: GridOptions;
    @Input() public total: number;
    @Input() public pagination: { startRow: number, pageSize: number };
    @Input() public busy: boolean;
    @Input() public suppressToUrl: boolean;
    @Input() public dropup?: boolean; //  if true, it drops up, if false (default) it drops down
    @Output() onPageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() onPageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

    public maxSize: number = 5;
    public currentPage: number;
    public numPages: number = 0;
    public itemsPerPage: number = 10;
    public itemsPerPageOptions: ItemsPerPageOption[] = [
        { id: 10, name: '10' },
        { id: 20, name: '20' },
        { id: 30, name: '30' },
        { id: 50, name: '50' },
        { id: 100, name: '100' },
    ];
    public itemsPerPageSelected = this.itemsPerPageOptions[0];
    public isVisible = true;
    private currentQueryParams: any = {};

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.currentQueryParams = {};
            for (var key in params) {
                this.currentQueryParams[key] = params[key];
            }

            if (this.currentQueryParams.page != this.currentPage) {
                this.init();
            }
        });
        if (typeof this.dropup === 'undefined') {
            this.dropup = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.hasOwnProperty('total')) {
            const total = changes.total.currentValue;
            if (total !== undefined) {
                // If total changes we need to recalculate currentPage and itemsPerPage
                this.init();
            }
        }
    }

	/**
	 * Switches to page given in event
	 */
    public pageChanged(event: {page: number, itemsPerPage: number}): void {
        if (event.page === this.currentPage) {
            // Page not changed, skipping the event
            // The event is generated even when filter changes but not the page so we need to skip emitting a change here.
            return;
        }

        this.onPageChanged.emit(event.page - 1);

        if (!this.suppressToUrl) {
            this.currentQueryParams['page'] = event.page;
            let navigationExtras: NavigationExtras = {
                queryParams: this.currentQueryParams
            };
            this.router.navigate([], navigationExtras);
        }
    }

    private init(): void {
        // A hack to refresh ngx-bootstrap pagination component
        // because it does not updates pages controls (1 | 2 | 3 | ...)
        // after updare of the model variables here.
        this.isVisible = false;

        this.currentPage = (this.pagination.startRow / this.pagination.pageSize) + 1;
        this.itemsPerPage = this.pagination.pageSize;
        for (var i = 0; i < this.itemsPerPageOptions.length; ++i) {
            if (this.itemsPerPageOptions[i].id == this.itemsPerPage) {
                this.itemsPerPageSelected = this.itemsPerPageOptions[i];
                break;
            }
        }

        // A hack to refresh ngx-bootstrap pagination component - the second part
        setTimeout(() => this.isVisible = true);
    }

    public onSelectItemsPerPage(event: {attributeName: "itemsPerPage", selected: ItemsPerPageOption}): void {
        var newValue = event.selected.id;
        if (newValue != this.itemsPerPage) {
            this.itemsPerPage = newValue;
            this.currentPage = 1;
            this.onPageSizeChanged.emit(newValue);
        }

        this.currentQueryParams['page'] = 1;
        let navigationExtras: NavigationExtras = {
            queryParams: this.currentQueryParams
        };

        this.router.navigate([], navigationExtras);
    }

}