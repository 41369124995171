import { Component, Input, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService }      from '../../../services/shared.service';
import { ControllingService }      from '../../../services/controlling/controlling.service';
import { ControllingGridService }      from '../../../services/controlling/controlling.grid.service';
// temporarry not enterprise
// import "ag-grid-enterprise"; 

@Component({
  selector: 'controlling',
  templateUrl: 'controlling.component.html',
  styleUrls:  ['./controlling.component.scss']
})
export class ControllingComponent  {
    showGrid = false;
    gridApi; 
    gridColumnApi;
    gridHeight = 500;

    lastWindowHeight = 0;

    constructor(private router: Router,
                private sharedService: SharedService,
                private controllingService: ControllingService,
                public controllingGridService: ControllingGridService) {

        this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.showGrid = true;
        });

    }

    ngDoCheck() {
        // adjust height of grid according to window size
        if (window.innerHeight != this.lastWindowHeight) {
            this.lastWindowHeight = window.innerHeight;
            this.gridHeight = window.innerHeight - 270;
        }
    }
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.loadData();
    }

    loadData() {
        this.controllingService.getControllingData().subscribe(data => {
            this.gridApi.setRowData(data);
        });
    }

    quickFilterOnChange(event) {
        this.gridApi.setQuickFilter(event.target.value)
    }

    export(grid, format) {
        grid.api.exportDataAsCsv({
          fileName: 'Controlling',
        });
    }
}