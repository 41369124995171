import { Component, Inject } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { Params,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationExtras
} from '@angular/router';

@Component({
    selector: 'app-header-renderer',
    template: `<span></span>`
})
export class GridHeaderSelectAllRendererComponent implements IHeaderAngularComp {
    private params: any;
    private eGui: any;

    constructor() {

    }

    agInit(params: any): void {
    }

    init(params: any): void {

        this.params = params;

        this.eGui = document.createElement('div');
        this.eGui.className = 'ag-header-group-cell-label clickable';

        this.setInnerHtml();


        this.eGui.addEventListener('click', (event) => {
            if (this.params.editedIdsObj && Object.keys(this.params.editedIdsObj).length > 0) {
                return;
            }

            if (!this.params.selection.all && !this.params.selection.visible) {
                this.params.selection.visible = true;
                this.setInnerHtml();
                this.params.observable.next(this.params.selection.visible);
            } else {
                this.params.selection.all = false;
                this.params.selection.visible = false;
                this.params.selection.visible = false;
                this.setInnerHtml();
                this.params.observable.next(true);
            }
        });

        this.params.observable.subscribe((res) => {
            this.setInnerHtml();
        });
    }

    getGui() {
        return this.eGui;
    }

    setInnerHtml() {
        const html = `<div><i class="fa fa-` + ((this.params.selection.all || this.params.selection.visible) ? `check-` : ``) + `square-o" style="font-size: 140%; margin: 4px 0px 0px -4px;"></i>`;
        this.eGui.innerHTML = html;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    destroy(): void {

    }
}
