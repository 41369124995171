<div class="row-container wider footer-page">
    <h2><i class="fa fa-file-pdf-o"></i> <span translate>PRESENTATIONS</span></h2>
    
    <div *ngFor="let group of presentationGroups" class="presentations-group">
        <h3 translate>{{group.title}}</h3>
        <div class="presentations-container">

            <div *ngFor="let presentation of group.presentations">
                
                <a [href]="presentation.fileUrl" target="_blank">
                    <h4>{{presentation.title}}</h4>
                    <img [src]="presentation.thumbnailUrl" class="thumbnail">
                </a>
                <div class="icons">
                    <i class="fa fa-download clickable" aria-hidden="true" (click)="download(presentation.baseName)"></i>
                    <div class="languages">
                        <span class="clickable" *ngFor="let language of presentation.languages" (click)="download(presentation.baseName, language)">{{language}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>