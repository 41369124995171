import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AttachmentDialogService } from '../../../attachmentComponent/attachmentDialogComponent/attachment.dialog.service';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { Observable } from 'rxjs';
import { Attachment, AttachmentUploadObj, AttachmentDownloadObj } from '@app/model/attachment.model';
import { map } from 'rxjs/operators';
import { AttachmentPrivateConfig } from '@app/components/attachmentComponent/attachment.component';

export interface GridAttachmentRendererComponentParams {
    getAllowEdit: (params: ICellRendererParams) => boolean;
    getAttachments: (params: ICellRendererParams, entityId: number) => Observable<Attachment[]>;
    getUploadUrl?: (params: ICellRendererParams, fileName: string, privateItem?: boolean) => Observable<AttachmentUploadObj>;
    getDownloadUrl?: (params: ICellRendererParams, attachmentId: number, attachmentChildId?: number) => Observable<AttachmentDownloadObj>;
    deleteAttachment?: (params: ICellRendererParams, entityId: number, attachmentId: number) => Observable<void>;
    attachmentChanged?: () => void;
    showPrivateControls?: boolean;
    privateConfig?: AttachmentPrivateConfig;
    idAttrName?: string; // name of the key attribute, if different from 'id'
                        // e.g. in case of prfq item is used value 'prfqId', because 'id'
                        // is id of the line, not id of prfq
}

interface MyParams extends ICellRendererParams, GridAttachmentRendererComponentParams {}

@Component({
    selector: 'app-attachment-cell',
    template: `<div class="clickable comment-count center-text" [ngClass]="{'empty': params.data.attachmentsCount === 0}" style="margin: -8px 0 -4px 0; padding: 8px 0 4px 0; text-align: left;" (click)="showAttachments()">
                  <i class="fa fa-paperclip"></i> <span>{{params.data.attachmentsCount > 0 ? params.data.attachmentsCount : ''}}</span>
               </div>`
})
export class GridAttachmentRendererComponent implements AgRendererComponent {
    params: MyParams;

    constructor(
        private attachmentDialogService: AttachmentDialogService
    ) {
    }

    agInit(params: MyParams): void {
        this.params = params;
    }

    public showAttachments(): void {
        this.attachmentDialogService.confirm(
            this.params.data,
            (id) => this.params.getAttachments(this.params, id),
            (fileName: string, privateItem: boolean) => this.params.getUploadUrl(this.params, fileName, privateItem),
            (attachmentId: number, lineNumber?: number) => this.params.getDownloadUrl(this.params, attachmentId, lineNumber),
            (entityId: number, attachmentId: number, lineNumber?: number) => this.params.deleteAttachment(this.params, entityId, attachmentId)
            .pipe(
                map(() => {
                    this.params.data.attachmentsCount = this.params.data.attachmentsCount - 1; // attachment was deleted
                    if (this.params.attachmentChanged) {
                        this.params.attachmentChanged();
                    }
                })
            ),
            () => this.params.getAllowEdit(this.params),
            (s3Keys: string[]) => {
                this.params.data.attachmentsCount = this.params.data.attachmentsCount + 1; // attachments were added
                if (this.params.attachmentChanged) {
                    this.params.attachmentChanged();
                }
            },
            null,
            this.params.showPrivateControls,
            this.params.privateConfig,
            this.params.idAttrName
        ).subscribe(
            res => {
                if (res > 0) {
                    this.params.data.attachmentsCount = this.params.data.attachmentsCount + res;
                    if (this.params.attachmentChanged) {
                        this.params.attachmentChanged();
                    }
                }
            }
        );
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
