import { Component, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'sticky-header',
  styleUrls: [
    './sticky.header.scss'
  ],
  templateUrl: './sticky.header.html',
  host: {'(window:scroll)': 'onScroll($event)'}
})
export class StickyHeaderComponent {
    @Input() public gridHeight: number;
    @Input() public gridWidth: number;
    @Input() public getAllColumns: Function;
    @Input() public scrolledByLeft: number;
    @Input() public secondaryHeaderColor: boolean;


    headerWidth: number;
    marginLeft: number;
    showStickyHeader: boolean; // flag when should be shown
    alreadyRefreshed: boolean; // dimensions, columns, placement has been already counted
    columns: {
              actualWidth: number,
              left: number,
              headerName: string
            }[];
    parents: {
              actualWidth: number,
              left: number,
              headerName: string
            }[];
    additionalLeftOffset: number = 0;
    lastContentWidth: number;

    @ViewChild('bottomElement', { static: true }) bottomElement; 
    @HostListener('window:scroll', ['$event']) 
    onScroll(event) {
        this.refreshStickyHeader();
    }

    /**
     * Adjusts  widths if needed
     */
    onresize(value:any) {
        this.alreadyRefreshed = false;
        this.refreshStickyHeader();
    }

    constructor() {

    }

    ngDoCheck() {
        // if content width is changed call onresize - this happens in chrome, window is not resized when scrollbar appears/disappears, 
        // but content is, which causes empty space in grid
        if (this.lastContentWidth != document.getElementById('content').offsetWidth) {
            this.lastContentWidth = document.getElementById('content').offsetWidth;
            this.onresize(this.lastContentWidth);
        }
    }

    /**
     * Refreshes sticky header if needed - columns, their widths, positions
     */
    refreshStickyHeader() {
        // set flag to display sticky header - if user scrolled below table header and did noc scroll under the end of table
        this.showStickyHeader = this.bottomElement.nativeElement.getBoundingClientRect().top + (this.parents && this.parents.length > 0 ? 52 : 26) <= 0 &&
                              this.bottomElement.nativeElement.getBoundingClientRect().top + this.gridHeight - 50 > 0;

        if (this.showStickyHeader && !this.alreadyRefreshed) {
            // find out grid width
            this.headerWidth = this.gridWidth < this.bottomElement.nativeElement.clientWidth ? this.gridWidth : this.bottomElement.nativeElement.clientWidth;
            // find out grid position from left
            this.marginLeft = this.bottomElement.nativeElement.getBoundingClientRect().left;

            // filter visible columns, map to use only needed attributes
            this.parents = [];
            let foundParentIds = {};
            this.columns = this.getAllColumns().filter(column => {
                if (column.visible && column.colId == 'selected') { // when selected column is visible, left offset is wrong so fix that
                    this.additionalLeftOffset = column.actualWidth + 2;
                    return false;
                }
                return column.visible;
            }).map(column => {
                if (column.parent && !foundParentIds[column.parent.groupId]) {
                    let width = 0;
                    column.parent.children.map(child => {
                        width += child.actualWidth
                    });
                    foundParentIds[column.parent.groupId] = true;
                    this.parents.push({
                      actualWidth: width,
                      left: column.parent.left,
                      headerName: column.parent.originalColumnGroup.colGroupDef.headerName
                    });
                }
                return {
                  actualWidth: column.actualWidth,
                  left: column.left,
                  headerName: column.colDef.headerName
                }
            });

            this.alreadyRefreshed = true;
        } 
        if (!this.showStickyHeader) {
            this.alreadyRefreshed = false;
        }
    }
}