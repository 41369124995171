import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { BasketService } from '../../../services/basket/basket.service';
import { CustomerStoresAvailabilityGridService } from '../../../services/basket/customer.stores.availability.grid.service';

@Component({
    selector: 'app-customer-stores-dialog',
    templateUrl: 'customer.stores.availability.dialog.component.html'
})
export class CustomerStoresAvailabilityDialogComponent implements OnInit {
    params: any;
    productAvailabilities: any[];
    totalCount;
    busy: boolean;
    exportObj: {fileName: string, export: Function};

    constructor(public bsModalRef: BsModalRef,
                private translate: TranslateService,
                public sharedService: SharedService,
                private basketService: BasketService,
                private customerStoresAvailabilityGridService: CustomerStoresAvailabilityGridService) {
        this.getList = this.getList.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY'),
                export: (query) => {
                    const exportFunc = this.basketService.customerStoresAvailabilityExportAll.bind(this.basketService);
                    return exportFunc(this.params.ids, query);
                }
            };
        });
    }

    ngOnInit() {
    }

    getList(params) {
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: params.orderBy.colId + ' ' + params.orderBy.sort
        };

        if (query.skip >= 0 && query.top > 0) {
            this.basketService.getCustomerStoresAvailability(this.params.ids, query)
            .subscribe((data: any) => {
                this.busy = false;
                this.totalCount = data.totalCount;
                params.successCallback(data.rows, this.totalCount);
            }, err => {
                this.busy = false;
                console.log(err);
            });
        }
    }
    getGridOptions() {
        return this.customerStoresAvailabilityGridService.getGridOptions();
    }
    getFilterItems() {
        return this.customerStoresAvailabilityGridService.getFilterItems([], []);
    }
    getColumns() {
        return this.customerStoresAvailabilityGridService.getColumns();
    }
    getColumnsDefs() {
        return this.customerStoresAvailabilityGridService.getColumnDefs();
    }
}
