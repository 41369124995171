import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../language.service'
import { LocalCurrencyPipe } from '../../locale.pipes.module';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridOptions } from 'ag-grid-community';

@Injectable()
export class ControllingGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions() {
        var this_ = this;

        var gridOptions: GridOptions = {
            rowHeight: 30,
            suppressLoadingOverlay: true,
            suppressNoRowsOverlay: true,
            enableRangeSelection: true,
            floatingFilter: true,
            cacheQuickFilter: true,
            rowGroupPanelShow: "always",
            animateRows: true,
            pivotColumnGroupTotals: 'after',
            pivotMode: true,
            sideBar: true,
            columnTypes: {
              numberValue: {
                enableValue: true,
                aggFunc: "sum",
                // editable: true,
                valueParser: function numberParser(params) {
                    console.log(params);
                  return parseInt(params.newValue);
                }
              },
              dimension: {
                enableRowGroup: true,
                enableValue: true,
                enablePivot: true
              }
            },
            defaultColDef: {
                cellStyle: {textAlign: "left"},
                filterParams: {
                    clearButton: true
                }
            },
            localeTextFunc: function(key, defaultValue) {
                var gridKey = 'ag_grid_' + key;
                var value = this_.translateService.instant(gridKey);
                return value === gridKey ? defaultValue : value;
            }
        };
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        var this_ = this;
        return [
            {
                headerName: this_.translateService.instant('Typ dokladu'), 
                field: "type",
                type: "dimension",
                filter: "agSetColumnFilter",
                filterParams: { selectAllOnMiniFilter: true, clearButton: true }
            },
            {
                headerName: this_.translateService.instant('Zákazník'), 
                field: "customer",
                type: "dimension",
                filter: "agSetColumnFilter",
                filterParams: { selectAllOnMiniFilter: true, clearButton: true }
            },
            {
                headerName: this_.translateService.instant('Vytvoril'), 
                field: "person_created",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Rok'), 
                field: "date_created_year",
                type: "dimension",
                filter: "agSetColumnFilter",
                filterParams: { selectAllOnMiniFilter: true, clearButton: true }
            },
            {
                headerName: this_.translateService.instant('Mesiac'), 
                field: "date_created_month",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Deň'), 
                field: "date_created_day",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Číslo dokladu'), 
                field: "number",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Stav'), 
                field: "state",
                type: "dimension",
                filter: "agSetColumnFilter",
                filterParams: { selectAllOnMiniFilter: true, clearButton: true }
            },
            {
                headerName: this_.translateService.instant('Kategória'), 
                field: "category",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Podkatekória'), 
                field: "subcategory",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Produkt'), 
                field: "product",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Mena'), 
                field: "currency",
                type: "dimension",
                filter: "agTextColumnFilter",
            },
            {
                headerName: this_.translateService.instant('Suma'), 
                field: "price",
                type: "dimension",
                filter: "agNumberColumnFilter",
                aggFunc: sumAggFunction,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 2}
            },
            {
                headerName: this_.translateService.instant('Množstvo'), 
                field: "amount",
                type: "dimension",
                filter: "agNumberColumnFilter",
                aggFunc: sumAggFunction,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
            },
            {
                headerName: this_.translateService.instant('Počet'), 
                field: "count",
                type: "dimension",
                filter: "agNumberColumnFilter",
                aggFunc: sumAggFunction,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
            },
        ];
    }
}

function sumAggFunction(values) {
    var sum = 0;
    values.forEach(function(value) {
        sum += value;
    });
    sum = parseFloat(sum.toFixed(2));
    return sum;
}

