<div id="dialogcontainer" #dialogcontainer style="position: relative;">
	<busy-indicator *ngIf="busy" style="z-index: 1 !important;position: absolute;top:50%;left:50%"></busy-indicator>
	<div class="row-container" *ngIf="params">
		<h4 class="fnt-primary"><span translate>CUSTOMER_DISTRIBUTOR_STORES_AVAILABILITY</span></h4>

		<ecm-table [dataGetter]="getList" [optionsGetter]="getGridOptions" [columnsGetter]="getColumns" [columnsDefsGetter]="getColumnsDefs"
		 [total]="totalCount" [busy]="busy" [suppressToUrl]="true" [tableNamePrefix]="'CustomerStoresAvailability'"
		 [showRefreshButton]="true" [exportObj]="exportObj"></ecm-table>

		<div style="padding-top: 15px;">
			<button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
				<span translate>CLOSE</span>
			</button>
		</div>
	</div>
</div>