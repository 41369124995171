import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, 
    OnInit, OnDestroy } from '@angular/core';
import {
    Router, ActivatedRoute, Params,
    NavigationExtras
} from '@angular/router';
import { QuestionDialogService } from '../../../components/questionDialogComponent/question.dialog.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user/user.service';
import { FormService } from '../../../services/form.service';
import { TableService } from '../../../services/table.service';
import { IMyOptions } from 'mydatepicker';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { TableFilterItem, TableFilterItemDateOperator, TableFilterItemOperators, TableFilterItemTypes, TableFilterItemValue } from '@app/model/table.filter.model';

interface FilterItem extends TableFilterItem {
    title ?: string,
    checked ?: boolean, 
    checkedValue ?: any, 
    active ?: boolean,

    // DateItem
    // operator?: DateOperator,
    // value?: { date: any, jsdate: Date }, // date
    // valueFrom?: { date: any, jsdate: Date }, // dateRange
    // valueTo?: { date: any, jsdate: Date }, // dateRange
    valueFromActive?: boolean,
    valueToActive?: boolean,
    time?: boolean
}

// interface DateOperator { 
//     id: string, 
//     name: string, 
//     short: string 
// }

// interface DateItem {
//     id: string,
//     name: string,
//     type: string,
//     operator?: DateOperator,
//     value?: { date: any, jsdate: Date }, // date
//     valueFrom?: { date: any, jsdate: Date }, // dateRange
//     valueTo?: { date: any, jsdate: Date }, // dateRange
//     valueFromActive?: boolean,
//     valueToActive?: boolean,
//     time?: boolean
// };

const BETWEEN = 0;
const LE = 1;
const GE = 2;

@Component({
    selector: 'app-table-filter',
    templateUrl: 'table.filter.component.html',
    styleUrls: ['./table.filter.component.scss']
})
export class TableFilterComponent implements OnInit, OnDestroy {
    @ViewChild('popSelectedOnly') popSelectedOnlyElement: ElementRef;
    @ViewChild('popNotSelectedOnly') popNotSelectedOnlyElement: ElementRef;
    // @Output() filter: EventEmitter<any> = new EventEmitter<any>();
    @Input() items: FilterItem[];
    @Input() filterSelectedAttrId: string;
    @Input() customDotColors: any;
    @Input() selection: any;
    @Input() tableNamePrefix: string;
    @Input() suppressStoreFilters: boolean;
    @Input() suppressToUrl: boolean;
    @Input() public resetFilter$?: Subject<void>; // force filter reset from outside of the component
    @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();

    firstInitDone: boolean;

    currentQueryParams: any;

    operators: TableFilterItemDateOperator[] = [
        { id: 'between', name: 'EQUAL', short: '=' },
        { id: 'le', name: 'LESS_EQUAL', short: '<=' },
        { id: 'ge', name: 'GREATER_EQUAL', short: '>=' },
    ];
    public textOperators: TableFilterItemDateOperator[] = [
        { id: TableFilterItemOperators.likeBoth, name: 'ag_grid_contains', short: '' }, // short will be translated later on
        { id: TableFilterItemOperators.eq, name: 'EQUAL', short: '=' }
    ];
    myDatePickerOptions: IMyOptions;

    filterChanged = false;
    filterActive = false;

    public filterSelectedOnly = false;
    public filterNotSelectedOnly = false;

    showFilterButtons = false; // show buttons only if at least one filter is different than checkbox

    resetMultiselectSearch = new Subject<any>(); // call next on this when search in multiselect dropdown should be reseted
    suppressNextReset: boolean; // not reset search in next apply filter - used when user hits enter in search input of multiple dropdown to not reset searched string
    placeholderFrom = '';
    placeholderTo = '';
    private subscriptions: Subscription[] = [];
    // 'Micro change' is each change in filter even without emitting 'onFilterChanged', e.g. change
    // in multiselect dropdown without clicking 'Filter' button
    // It is used for filter criteria dependencies.
    private onMicroChange = new Subject<FilterItem>();

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        public sharedService: SharedService,
        private userService: UserService,
        private formService: FormService,
        private tableService: TableService,
        private questionDialogService: QuestionDialogService) {

        this.myDatePickerOptions = this.formService.getDatePickerOptions(true);
        this.myDatePickerOptions.showClearDateBtn = true;
        this.myDatePickerOptions.selectionTxtFontSize = '100%';

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.placeholderFrom = this.sharedService.translateService.instant('FROM');
            this.placeholderTo = this.sharedService.translateService.instant('TO');

            // Add translation of short
            const likeBothOperator = this.textOperators.find(operator => operator.id === TableFilterItemOperators.likeBoth);
            if (likeBothOperator) {
                likeBothOperator.short = this.sharedService.translateService.instant('ag_grid_contains');
            } else {
                console.error('Could not find likeBoth row in', this.textOperators);
            }
        });
    }

    public ngOnInit(): void {
        // subscribe to router event
        // sets current filter values from query
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.filterActive = false;
            this.currentQueryParams = {};
            if (!this.suppressToUrl) {
                for (const key of Object.keys(params)) {
                    this.currentQueryParams[key] = params[key];
                    // if(key.endsWith('.value')) {
                    // 	this.filterActive = true;
                    // }
                    if (key === this.filterSelectedAttrId) {
                        this.filterSelectedOnly = params[key] === '1';
                        this.filterNotSelectedOnly = params[key] === '0';
                    }
                }
            }
            this.init();
        });

        // Subscribe to internal changes
        this.subscriptions.push(
            this.onMicroChange.subscribe(changedFilterItem => {
                this.items.forEach(item => {
                    this.updateItemValuesOnFilterMicroChange(item, changedFilterItem);
                });
            })
        );

        if (this.suppressToUrl) {
            this.getCurrentQueryParamsFromPreferences();
            this.init();
        }

        if (this.resetFilter$) {
            this.subscriptions.push(this.resetFilter$.subscribe(() => {
                this.resetFilter(true);
            }));
        }
    }

    // Check if filter item has a microchange callback and call it to update items
    private updateItemValuesOnFilterMicroChange(filterItem: FilterItem, changedFilterItem: FilterItem | null): void {
        if (filterItem.updateValuesOnFilterMicroChange) {
            const updatedValues = filterItem.updateValuesOnFilterMicroChange(changedFilterItem);

            if (updatedValues) { // only set new values if non-null result
                // !!! Only implemented for multiselect !!!
                if (filterItem.type === TableFilterItemTypes.multiselect) {
                    this.setNewValuesForMultiselect(filterItem, updatedValues);
                }
            }
        }
    }

    private setNewValuesForMultiselect(item: FilterItem, newValues: TableFilterItemValue[]): void {
        item.values = newValues;
    }

    getCurrentQueryParamsFromPreferences() {
        const prefKey = this.sharedService.getUserPreferenceKey('Filter', this.tableNamePrefix);
        const filter = this.sharedService.user.preferences[prefKey] || {};
        for (const key of Object.keys(filter)) {
            this.currentQueryParams[key] = filter[key];
            if (key === this.filterSelectedAttrId) {
                this.filterSelectedOnly = filter[key] === '1';
                this.filterNotSelectedOnly = filter[key] === '0';
            }
        }
    }

    /**
     * Initializes filter values
     */
    init() {
        // get values from url
        this.items.forEach(item => {
            const initFnName = item.type.charAt(0).toUpperCase() + item.type.slice(1);  // 'text' -> 'Text'
            this[`init${initFnName}`](item);
        });

        setTimeout(() => {
            this.firstInitDone = true;
        }, 0);
    }

    // if (item.type === 'text') {
    private initText(item: FilterItem): void {
        if (this.currentQueryParams[item.id + '.value']) {
            item.value = this.currentQueryParams[item.id + '.value'];
            item.active = true;
            this.filterActive = true;
        } else {
            item.value = '';
            item.active = false;
            item.operator = this.textOperators[0];
        }
        this.findAndSetTextOperator(item);
        this.showFilterButtons = true;
    }

    private findAndSetTextOperator(item: FilterItem): void {
        const textOperator = this.textOperators.find(_operator => _operator.id === this.currentQueryParams[item.id + '.operator']);
        if (textOperator) {
            item.operator = textOperator;
        }
    }

    // if (item.type === 'date') {
    initDate(item: FilterItem) {
        if (this.currentQueryParams[item.id + '.value']) {
            let momentDate = moment(this.currentQueryParams[item.id + '.value'].split(',')[0]);
            if (this.currentQueryParams[item.id + '.operator'] === 'lt') { // because le is converted to date + 1 day and lt operator when filter is applied
                this.currentQueryParams[item.id + '.operator'] = 'le';
                momentDate = momentDate.add(-1, 'days');
            }
            item.value = {
                date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() },
                jsdate: momentDate.toDate()
            };
            item.active = true;
            this.filterActive = true;
        } else {
            item.value = { date: '', jsdate: null };
            item.operator = this.operators[0];
            item.active = false;
        }
        this.findAndSetDateOperator(item);
        this.showFilterButtons = true;
    }

    findAndSetDateOperator(item: FilterItem) {
        for (let i = 0; i < this.operators.length; ++i) {
            if (this.operators[i].id === this.currentQueryParams[item.id + '.operator']) {
                item.operator = this.operators[i];
                break;
            }
        }
    }

    // if (item.type === 'dateRange') {
    initDateRange(item: FilterItem) {
        item.value = { date: '', jsdate: null };
        item.valueFromActive = false;
        item.valueToActive = false;
        item.valueFrom = { date: '', jsdate: null };
        item.valueTo = { date: '', jsdate: null };

        if (this.currentQueryParams[item.id + '.value']) {
            // 'from date' only
            if (this.currentQueryParams[item.id + '.operator'] === 'ge') {
                const momentDate = moment(this.currentQueryParams[item.id + '.value']);
                item.valueFrom = {
                    date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() },
                    jsdate: momentDate.toDate()
                };
                item.valueFromActive = true;
                this.filterActive = true;
            } else
            // 'to date' only
            if (this.currentQueryParams[item.id + '.operator'] === 'lt') {
                let momentDate = moment(this.currentQueryParams[item.id + '.value']);
                this.currentQueryParams[item.id + '.operator'] = 'le';  // because le is converted to date + 1 day and lt operator when filter is applied
                momentDate = momentDate.add(-1, 'days');
                item.valueTo = {
                    date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() },
                    jsdate: momentDate.toDate()
                };
                item.valueToActive = true;
                this.filterActive = true;
            } else
            // both dates
            if (this.currentQueryParams[item.id + '.operator'] === 'between') {
                const datesArr = this.currentQueryParams[item.id + '.value'].split(',');
                let momentDate = moment(datesArr[0]);
                item.valueFrom = {
                    date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() },
                    jsdate: momentDate.toDate()
                };
                momentDate = moment(datesArr[1]);
                momentDate = momentDate.add(-1, 'days');
                item.valueTo = {
                    date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() },
                    jsdate: momentDate.toDate()
                };
                item.valueFromActive = true;
                item.valueToActive = true;
                this.filterActive = true;
            } else {
                console.error('Wrong operator', this.currentQueryParams[item.id + '.operator']);
            }
        }
        this.findAndSetDateOperator(item);
        this.showFilterButtons = true;
    }

    // if (item.type === 'select') {
    initSelect(item: FilterItem) {
        if (this.currentQueryParams[item.id + '.value']) {
            for (let i = 0; i < item.values.length; ++i) {
                if (item.values[i].id === this.currentQueryParams[item.id + '.value']) {
                    item.value = item.values[i];
                }
            }
            this.filterActive = true;
            item.active = true;
        } else {
            item.value = item.values[0];
            item.active = false;
        }
        this.showFilterButtons = true;
    }

    // if (item.type === 'multiselect') {
    initMultiselect(item: FilterItem) {
        item.active = false;
        if (this.currentQueryParams[item.id + '.value'] !== undefined) {
            const splitedValues = this.currentQueryParams[item.id + '.value'].split(',');
            for (let i = 0; i < item.values.length; ++i) {
                item.values[i].checked = splitedValues.indexOf(item.values[i].id.toString()) > -1;

                if (item.values[i].checked && splitedValues.length === 1) {
                    item.value = item.values[i];
                }

                if ((!item.values[i].hasOwnProperty('default') && !item.values[i].checked) ||
                    (item.values[i].hasOwnProperty('default') && item.values[i].default !== item.values[i].checked)) {
                    this.filterActive = true;
                    item.active = true;
                }
            }
        } else {
            for (let i = 0; i < item.values.length; ++i) {
                item.values[i].checked = true;
                if (item.values[i].hasOwnProperty('default') && (item.values[i].default !== item.values[i].checked)) {
                    this.filterActive = true;
                    item.active = true;
                }
            }
        }
        this.showFilterButtons = true;
        this.setMultiselectBulkCheckedValue(item);
    }

    // if (item.type === 'checkbox') {
    initCheckbox(item: FilterItem) {
        if (this.currentQueryParams[item.id + '.value']) {
            item.checked = true;
            this.filterActive = true;
            item.active = true;
        } else {
            item.checked = false;
            item.active = false;
        }
    }

    public onChange(item: FilterItem, value: any): void {
        item.value = value;
        this.detectChanged();
    }

    public cancelText(item: FilterItem): void {
        item.value = '';
        this.onChange(item, '');
        this.filterChanged = true;
        if (this.filterChanged) {
            this.filter();
        }
    }

    onDateChange(item: FilterItem, value: any) {
        if (value.date.year && value.date.month && value.date.day) {
            item.value = value;
        } else {
            item.value = { date: '', jsdate: null };
        }
        this.detectChanged();
        if (this.filterChanged) {
            this.filter();
        }
    }

    public onDateRangeFromChange(item: FilterItem, value: any): void {
        if (value.date.year && value.date.month && value.date.day) {
            item.valueFrom = value;
        } else {
            item.valueFrom = { date: '', jsdate: null };
        }
        // item.value = item.valueFrom;
        this.onDateRangeChange(item);
    }

    public onDateRangeToChange(item: FilterItem, value: any): void {
        if (value.date.year && value.date.month && value.date.day) {
            item.valueTo = value;
        } else {
            item.valueTo = { date: '', jsdate: null };
        }
        // item.value = item.valueTo;
        this.onDateRangeChange(item);
    }

    public onDateRangeChange(item: FilterItem): void {
        this.setDateRangeOperator(item);
        this.detectChanged();
        if (this.filterChanged) {
            this.filter();
        }
    }

    setDateRangeOperator(item: FilterItem) {
        if (typeof item.valueFrom === 'object' && (typeof item.valueTo !== 'object' || item.valueTo.date === '')) {
            item.operator = this.operators[GE];
        } else if ((typeof item.valueFrom !== 'object' || item.valueFrom.date === '') && typeof item.valueTo === 'object') {
            item.operator = this.operators[LE];
        } else {
            item.operator = this.operators[BETWEEN];
        }
    }

    public onDateRangeSelectedFromPicker(item: FilterItem, event: {fromDate: Date, toDate: Date}): void {
        item.valueFrom = { 
            date: {
                year: event.fromDate.getFullYear(),
                month: event.fromDate.getMonth() + 1,
                day: event.fromDate.getDate()
            },
            jsdate: event.fromDate
        };
        item.valueTo = { 
            date: {
                year: event.toDate.getFullYear(),
                month: event.toDate.getMonth() + 1,
                day: event.toDate.getDate()
            },
            jsdate: event.toDate
        };
        this.onDateRangeChange(item);
    }

    inputKeyPress(event) {
        if (event.keyCode === 13 && this.filterChanged) {
            this.filter();
        }
    }

    onSelect(item: FilterItem, value: any) {
        item.value = value.selected;
        this.detectChanged();
        if (this.filterChanged) {
            this.filter();
        }
    }

    public multipleOnSelect(item: FilterItem): void {
        this.filterChanged = true;
        this.onMicroChange.next(item);
        this.setMultiselectBulkCheckedValue(item);
    }

    onMultiselectBulkCheckToggle(item: FilterItem) {
        item.bulkCheckbox.checked = !item.bulkCheckbox.checked;
        item.values.map(value => {
            value.checked = item.bulkCheckbox.checked ? this.multiselectItemHasBulkId(item, value.id) : true;
        });
        this.detectChanged();
        if (this.filterChanged) {
            this.filter();
        }
    }

    multiselectItemHasBulkId(item: FilterItem, valueId): boolean  {
        return item.bulkCheckbox.ids.some(id => valueId === id)
    }

    multiselectHasAllAndOnlyBulkIdsChecked(item: FilterItem) {
        const checkedIds = item.values.filter(i => i.checked).map(i => i.id);
        let checkedNewValue = false;
        if (checkedIds.length === item.bulkCheckbox.ids.length) {
            checkedNewValue = true;
            checkedIds.map(id => {
                if (!this.multiselectItemHasBulkId(item, id)) {
                    checkedNewValue = false;
                }
            });
        }
        return checkedNewValue;
    }

    multiselectHasOnlyOtherThanBulkIdsChecked(item: FilterItem): boolean {
        const checkedIds = item.values.filter(i => i.checked).map(i => i.id);
        let checkedNewValue = true;
        item.bulkCheckbox.ids.map(id => {
            if (checkedIds.some(checkedId => id === checkedId)) {
                checkedNewValue = false;
            }
        });
        return checkedNewValue;
    }

    private setMultiselectBulkCheckedValue(item: FilterItem): void {
        if (item.bulkCheckbox) {
            item.bulkCheckbox.checked = this.multiselectHasAllAndOnlyBulkIdsChecked(item);
            item.bulkCheckbox.active = this.multiselectHasOnlyOtherThanBulkIdsChecked(item);
        }
    }

    onSelectFilterOperator(item: FilterItem, value: any) {
        item.operator = value.selected;
        this.filterChanged = typeof item.value === 'object' && typeof item.value.date === 'object';
        if (this.filterChanged) {
            this.filter();
        }
    }

    // User changed a text field operator
    public onSelectTextFilterOperator(item: FilterItem, value: any): void {
        item.operator = value.selected;
        this.filterChanged = !!item.value;
        if (this.filterChanged) {
            this.filter();
        }
    }

    onCheckboxToggle(item) {
        item.checked = !item.checked;
        this.detectChanged();
        if (this.filterChanged) {
            this.filter();
        }
    }

    detectChanged() {
        this.cdRef.detectChanges();

        for (let i = 0; i < this.items.length; ++i) {
            if (this.items[i].value === null || (this.items[i].value && this.items[i].value.length > 0) || (this.items[i].value && this.items[i].value.jsdate) ||
                typeof this.items[i].value === 'object' ||
                this.items[i].valueFrom === null || (this.items[i].valueFrom && this.items[i].valueFrom.jsdate) || typeof this.items[i].valueFrom === 'object' ||
                this.items[i].valueTo === null || (this.items[i].valueTo && this.items[i].valueTo.jsdate) || typeof this.items[i].valueTo === 'object'
            ) {
                this.filterChanged = true;
                this.cdRef.detectChanges();
                return;
            }
        }
        this.filterChanged = false;
    }

    onEnterInSearch() {
        this.suppressNextReset = true;
        this.filter();
    }

    /**
     * Checks if table that holds this filter has something unsaved and opens dialog to save before filtering if needed
     */
    public filter(): void {
        // to tell component that holds table that it has to be saved before loading
        if (this.tableService.isSomethingChanged(this.selection)) {
            this.questionDialogService.confirm(
                {
                    message: 'SAVE_BEFORE_APPLYING_FILTER_TITLE',
                    question: 'SAVE_BEFORE_APPLYING_FILTER_QUESTION',
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                res => {
                    if (res === 'confirm') {
                        this.setCurrentQueryParams();
                        this.saveToFilter();
                    }
                }
            );
        } else {
            this.applyFilter();
        }
    }

    /**
     * Applies filter values and navigates with new query
     */
    private applyFilter(): void {
        setTimeout(() => {
            this.setCurrentQueryParams();

            this.filterChanged = false;
            const navigationExtras: NavigationExtras = {
                queryParams: this.currentQueryParams
            };
            if (!this.suppressToUrl) {
                this.router.navigate([], navigationExtras);
            }
            this.onFilterChanged.emit();
            this.storeFilterToPreferences();

            if (this.suppressToUrl) {
                this.getCurrentQueryParamsFromPreferences();
                this.init();
            }
            if (!this.suppressNextReset) {
                this.resetMultiselectSearch.next();
            } else {
                this.suppressNextReset = false;
            }
        }, 0);

    }

    /**
     * Adds save saveBeforeLoad attr to query and navigates with new query
     */
    saveToFilter() {
        this.filterChanged = false;
        this.currentQueryParams.saveBeforeLoad = true;
        const navigationExtras: NavigationExtras = {
            queryParams: this.currentQueryParams
        };
        this.router.navigate([], navigationExtras);
    }

    /**
     * Sets query params according to values in filter items
     */
    setCurrentQueryParams() {
        // const this_ = this;
        this.currentQueryParams['page'] = '1';
        this.items.forEach(item => {
            const typeName = item.type.charAt(0).toUpperCase() + item.type.slice(1);  // 'text' -> 'Text'
            this[`setCurrentQueryParamsFor${typeName}`](item);
        });
    }

    // if (item.type === 'text') {
    private setCurrentQueryParamsForText(item): void {
        if (item.value.length > 0) {
            this.currentQueryParams[item.id + '.operator'] = item.operator ? item.operator.id : 'like';
            this.currentQueryParams[item.id + '.value'] = item.value;
        } else {
            this.removeFromQueryParams(item.id);
        }
    }

    // if (item.type === 'date') {
    setCurrentQueryParamsForDate(item) {
        if (typeof item.value === 'object' && typeof item.value.date === 'object') {
            this.currentQueryParams[item.id + '.operator'] = item.operator.id;

            // if item has time set to true, use datetime format
            if (item.time) {
                this.currentQueryParams[item.id + '.value'] = moment(item.value.jsdate).hour(0).minute(0).second(0).toISOString();
            } else {
                this.currentQueryParams[item.id + '.value'] = this.formService.getDateStringFromIsoDate(moment(item.value.jsdate).toISOString());
            }
            if (item.operator.id === 'between') { // special case when date should equal is implemented with operator between selected day and next day
                this.currentQueryParams[item.id + '.value'] += ',';
                if (item.time) {
                    this.currentQueryParams[item.id + '.value'] += moment(item.value.jsdate).add(1, 'days').hour(0).minute(0).second(0).toISOString();
                } else {
                    this.currentQueryParams[item.id + '.value'] += (this.formService.getDateStringFromIsoDate((moment(item.value.jsdate).add(1, 'days')).toISOString()));
                }
            }
            if (item.operator.id === 'le') { // transform when le oparator to date + 1 day and operator to lt
                if (item.time) {
                    this.currentQueryParams[item.id + '.value'] = moment(item.value.jsdate).add(1, 'days').hour(0).minute(0).second(0).toISOString();
                } else {
                    this.currentQueryParams[item.id + '.value'] = this.formService.getDateStringFromIsoDate((moment(item.value.jsdate).add(1, 'days')).toISOString());
                }

                this.currentQueryParams[item.id + '.operator'] = 'lt';
            }
        } else {
            this.removeFromQueryParams(item.id);
        }
    }

    // if (item.type === 'dateRange') {
    setCurrentQueryParamsForDateRange(item: FilterItem) {
        // 'date from' only
        if (typeof item.valueFrom === 'object' && typeof item.valueFrom.date === 'object' && (typeof item.valueTo !== 'object' || typeof item.valueTo.date !== 'object')) {
            this.setCurrentQueryParamsForDateValue(item.valueFrom, item.id, 
                item.operator as TableFilterItemDateOperator, item.time);
        } else
        // 'date to' only
        if (typeof item.valueTo === 'object' && typeof item.valueTo.date === 'object' && (typeof item.valueFrom !== 'object' || typeof item.valueFrom.date !== 'object')) {
            this.setCurrentQueryParamsForDateValue(item.valueTo, item.id, 
                item.operator as TableFilterItemDateOperator, item.time);
        } else
        // both dates
        if (typeof item.valueFrom === 'object' && typeof item.valueFrom.date === 'object' && typeof item.valueTo === 'object' && typeof item.valueTo.date === 'object') {
            this.setCurrentQueryParamsForDateValue(item.valueFrom, item.id, 
                item.operator as TableFilterItemDateOperator, item.time);

            this.currentQueryParams[item.id + '.value'] += ',';
            if (item.time) {
                this.currentQueryParams[item.id + '.value'] += moment(item.valueTo.jsdate).add(1, 'days').hour(0).minute(0).second(0).toISOString();
            } else {
                this.currentQueryParams[item.id + '.value'] += (this.formService.getDateStringFromIsoDate((moment(item.valueTo.jsdate).add(1, 'days')).toISOString()));
            }
        } else {
            this.removeFromQueryParams(item.id);
        }
    }

    setCurrentQueryParamsForDateValue(itemValue: { date: any, jsdate: Date }, itemId: string, 
        itemOperator: TableFilterItemDateOperator, itemTime: boolean) {
        this.currentQueryParams[itemId + '.operator'] = itemOperator.id;

        // if item has time set to true, use datetime format
        if (itemTime) {
            this.currentQueryParams[itemId + '.value'] = moment(itemValue.jsdate).hour(0).minute(0).second(0).toISOString();
        } else {
            this.currentQueryParams[itemId + '.value'] = this.formService.getDateStringFromIsoDate(moment(itemValue.jsdate).toISOString());
        }

        if (itemOperator.id === 'le') { // transform when le oparator to date + 1 day and operator to lt
            if (itemTime) {
                this.currentQueryParams[itemId + '.value'] = moment(itemValue.jsdate).add(1, 'days').hour(0).minute(0).second(0).toISOString();
            } else {
                this.currentQueryParams[itemId + '.value'] = this.formService.getDateStringFromIsoDate((moment(itemValue.jsdate).add(1, 'days')).toISOString());
            }

            this.currentQueryParams[itemId + '.operator'] = 'lt';
        }
    }

    // if (item.type === 'select') {
    setCurrentQueryParamsForSelect(item) {
        if (typeof item.value === 'object' && item.value.id !== 'NULL') {
            this.currentQueryParams[item.id + '.operator'] = 'eq';
            this.currentQueryParams[item.id + '.value'] = item.value.id;
        } else {
            this.removeFromQueryParams(item.id);
        }
    }

    // if (item.type === 'multiselect') {
    setCurrentQueryParamsForMultiselect(item) {
        let checked = item.values.filter(_item => _item.checked);
        // apply filter only if not all are selected or filter has default values
        if ((((checked.length < item.values.length || (item.values[0] && item.values[0].hasOwnProperty('default'))) && checked.length > 0)) ||
            (item.values[0] && item.values[0].hasOwnProperty('default') && checked.length === 0)) {
            if (item.values[0].hasOwnProperty('default') && checked.length === 0) { // set back to default if none is selected
                checked = item.values.filter(_item => {
                    _item.checked = _item.default;
                    return _item.default
                });
            }
            if (checked.length !== item.values.length) {
                this.currentQueryParams[item.id + '.operator'] = 'in';
                this.currentQueryParams[item.id + '.value'] = '';
                checked.forEach(multiSelectedItem => {
                    this.currentQueryParams[item.id + '.value'] += (this.currentQueryParams[item.id + '.value'].length > 0 ? ',' : '') + multiSelectedItem.id;
                });
            } else {
                this.removeFromQueryParams(item.id);
            }
        } else {
            item.values.forEach(_item => {
                _item.checked = true;
            });
            this.removeFromQueryParams(item.id);
        }
        this.resetMultiselectSearch.next(item);
    }

    // if (item.type === 'checkbox') {
    setCurrentQueryParamsForCheckbox(item) {
        if (item.checked) {
            this.currentQueryParams[item.id + '.operator'] = item.operator;
            this.currentQueryParams[item.id + '.value'] = item.checkedValue ? item.checkedValue : '-1';
        } else {
            this.removeFromQueryParams(item.id);
        }
    }

    /**
     * Removes values from query for given item.id
     * @param itemId - id of filter item to be removed from query
     */
    private removeFromQueryParams(itemId: string): void {
        delete this.currentQueryParams[itemId + '.operator'];
        delete this.currentQueryParams[itemId + '.value'];
    }

    /**
     * Checks if table that holds this filter has something unsaved and opens dialog to save before resetting if needed
     */
    public reset(): void {
        if (this.tableService.isSomethingChanged(this.selection)) {
            this.questionDialogService.confirm(
                {
                    message: 'SAVE_BEFORE_APPLYING_FILTER_TITLE',
                    question: 'SAVE_BEFORE_APPLYING_FILTER_QUESTION',
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                res => {
                    if (res === 'confirm') {
                        this.resetFilter(true);
                        this.saveToFilter();
                    }
                }
            );
        } else {
            this.resetFilter(true);
        }
    }

    /**
     * Resets filter
     */
    private resetFilter(navigate: boolean): void {
        this.items.forEach((item) => {
            this.removeFromQueryParams(item.id);
            this.setDefaultToCurrentQueryParams(item);

            // If some callbacks exist - ask them to restore the original values
            this.updateItemValuesOnFilterMicroChange(item, null);

            if (this.suppressToUrl) {
                this.init();
            }
        });
        this.filterChanged = false;

        if (navigate) {
            const navigationExtras: NavigationExtras = {
                queryParams: this.currentQueryParams
            };
            if (!this.suppressToUrl) {
                this.router.navigate([], navigationExtras);
            }
            this.onFilterChanged.emit();
            this.storeFilterToPreferences();
            if (this.suppressToUrl) {
                this.filterActive = false;
            }
            if (!this.suppressNextReset) {
                this.resetMultiselectSearch.next();
            } else {
                this.suppressNextReset = false;
            }
        }
    }

    /**
     * Set default to current query params
     * used when multiselect has default values
     */
    private setDefaultToCurrentQueryParams(item): void {
        if ((item.type === 'multiselect' && item.values[0] && item.values[0].hasOwnProperty('default')
            && (item.values && item.values.filter(value => value.default === true).length !== item.values.length)
        )) {
            this.currentQueryParams[item.id + '.operator'] = 'in';
            this.currentQueryParams[item.id + '.value'] = item.values.filter(value => value.default === true).map(value => value.id).join(',');
        }
    }

    /**
     * Toggles checkbox that displays only selected items
     * @param attrName - name of attribute in checkbox
     */
    public toggleSelectedCheckbox(attrName: 'filterSelectedOnly' | 'filterNotSelectedOnly'): void {
        this.setCurrentQueryParams();

        this[attrName] = !this[attrName];
        if (this[attrName]) {
            if (attrName === 'filterSelectedOnly') {
                this.currentQueryParams[this.filterSelectedAttrId] = '1';
                this.filterNotSelectedOnly = false;
            } else {
                this.currentQueryParams[this.filterSelectedAttrId] = '0';
                this.filterSelectedOnly = false;
            }
        } else {
            delete this.currentQueryParams[this.filterSelectedAttrId];
        }

        // to tell component that holds table that it has to be saved before loading
        if (this.tableService.isSomethingChanged(this.selection)) {
            this.questionDialogService.confirm(
                {
                    message: 'SAVE_BEFORE_APPLYING_FILTER_TITLE',
                    question: 'SAVE_BEFORE_APPLYING_FILTER_QUESTION',
                    primary: 'YES',
                    secondary: 'NO',
                }
            ).subscribe(
                res => {
                    if (res === 'confirm') {
                        this.saveToFilter();
                    } else {
                        delete this.currentQueryParams[this.filterSelectedAttrId];
                        this[attrName] = !this[attrName];
                    }
                }
            );
        } else {
            this.applyFilter();
        }
    }

    /**
     * Stores filter for current table to user preferences
     */
    storeFilterToPreferences() {
        if (this.suppressStoreFilters) { return; }

        const filterToSave = Object.assign({}, this.currentQueryParams);
        delete filterToSave.orderBy;
        delete filterToSave.page;
        delete filterToSave.saveBeforeLoad;
        // this.userService.setUserPreference(this.sharedService.area + (this.tableNamePrefix ? this.tableNamePrefix : '') + 'Filter', filterToSave, true);
        this.userService.setUserPreference(this.sharedService.getUserPreferenceKey('Filter', this.tableNamePrefix), filterToSave, true);
    }

    public ngOnDestroy(): void {
        this.cdRef.detach(); // try this
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
