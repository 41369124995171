import { Component } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: 'busy-indicator',
    styleUrls: [
        './busy.indicator.component.scss'
    ],
    template: `
        <div class="busy-indicator" [ngClass]="'spinner-' + iconSet">
            <div class="busy-spinner"></div>
        </div>
    `
})
export class BusyIndicatorComponent {
    iconSet: string;

    constructor(
        private sharedService: SharedService
    ) {
        this.iconSet = this.sharedService.apParams.iconSet;
    }
}
