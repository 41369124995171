import { Component, Input, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { CellChange } from '@app/model/table.model';
import { Subject, Subscription } from 'rxjs';

export interface GridAvailabilityRendererComponentParams {
    watchedItems?: string[]; // columns, which influence the availability column and when they change, availability is not shown
    cellChanged$?: Subject<CellChange>;
}

interface MyParams extends ICellRendererParams, GridAvailabilityRendererComponentParams {}

@Component({
    selector: 'basket-availability-renderer',
    template: `	<div *ngIf="isStateShowable && (hasId && params?.data.availabilityState != null)" style="font-size: 15px;" (mouseover)="mouseover()" (mouseleave)="mouseleave()">
                    <ng-template #popTemplate>
                        <div style="margin-bottom: 8px;">
                            <span translate>{{params?.data.availabilityState}}</span>.
                        </div>
                        <div *ngIf="params?.data.availabilityState == 'LATELY_FULFILLED' || params?.data.availabilityState == 'PARTIALLY_FULFILLED'">
                            <span *ngIf="params?.data.amountMissing == params?.data.amountOrdered"><span translate>NOT_STOCK_NEITHER_IN_PRODUCTION</span>.</span>
                            <span *ngIf="params?.data.amountMissing != params?.data.amountOrdered"><span translate>NEXT_POSSIBLE_DATES_OF_DELIVERY</span>:</span>
                            <div *ngFor="let item of params?.data.availabilityFulfillings">
                                <span *ngIf="item.amount != 0">{{item.deliveryDate | localdate:sharedService.appSettings.language}}: {{item.amount}} <span translate>UNIT</span></span>
                            </div>
                        </div>
                    </ng-template>
    				<div *ngIf="!withPopover && params?.data.availabilityState !== null" style="width: 20px; height: 20px; border: 3px solid #fff; border-radius: 10px; margin: 0 auto;"
                         [ngClass]="{'bkg-green': params?.data.availabilityState == 'COMPLETELY_FULFILLED' || availabilityState == 'COMPLETELY_FULFILLED',
                                     'bkg-yellow': params?.data.availabilityState == 'LATELY_FULFILLED'    || availabilityState == 'LATELY_FULFILLED',
                                     'bkg-red': params?.data.availabilityState == 'PARTIALLY_FULFILLED'    || availabilityState == 'PARTIALLY_FULFILLED'}">
                    </div>
                    <div *ngIf="withPopover" style="width: 20px; height: 20px; border: 3px solid #fff; border-radius: 10px; margin: 0 auto;"
                         [ngClass]="{'bkg-green': params?.data.availabilityState == 'COMPLETELY_FULFILLED',
                                     'bkg-yellow': params?.data.availabilityState == 'LATELY_FULFILLED',
                                     'bkg-red': params?.data.availabilityState == 'PARTIALLY_FULFILLED'}"
                         [popover]="popTemplate" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="right">
                    </div>
    			</div>`
})
export class GridAvailabilityRendererComponent implements AgRendererComponent, OnInit, OnDestroy {
    @Input() availabilityState: string;  // optional - with this it can be used without ag-grid
    @Input() usePopover: boolean;        // optional - with this it can be used without ag-grid

    params: MyParams;
    hasId: any;
    isStateShowable = true;
    withPopover: boolean; // popover with container body cannot be in DOM a lot of times, so change alement without popover
                                  // to element with popover when this is true - when this grid cell is mouseover
    subscriptions: Subscription[] = [];

    constructor(
        public sharedService: SharedService,
        private renderer:Renderer2
    ) {
    }

    agInit(params: MyParams): void {
        this.params = params;
        this.hasId = params && params.data && params.data.id > 0;

        this.updateState();

        if (this.params.cellChanged$) {
            this.subscriptions.push(this.params.cellChanged$.subscribe(cellChange => {
                this.updateState(cellChange);
            }));
        }
    }

    ngOnInit() {
        if (typeof this.params === 'undefined') {
            this.params = {
                data: {
                    availabilityState: this.availabilityState
                }
            } as MyParams;
            this.withPopover = this.usePopover;
            this.hasId = true;
        }
    }

    private updateState(cellChange?: CellChange): void {
        this.doCheck(cellChange);
    }

    // ngDoCheck () {
    //     console.log('grid.availability.renderer.component ngDoCheck');
    //     // console.log('GridAvailabilityRendererComponent ngDoCheck');
    //     /** After every change do check if watchedItem is in originalValues, if so check length and compare old value (before edit) with
    //      * new value (after edit) and sum all founded issues. If sum is higher as 0 , disable itemStatus because something was changed from
    //      * watchedItems
    //      */
    //     let oldItemArray = [];
    //     if (this.params && this.params.watchedItems && this.params.watchedItems > 0) {
    //         this.isStateShowable = this.params.watchedItems.filter(watchedItem => {
    //             oldItemArray = this.params.data.originalValues.filter(item => item.colId === watchedItem);
    //             if (oldItemArray.length === 0) {
    //                 return false;
    //             }
    //             return ( oldItemArray[0].value !== ( this.params.data[watchedItem] === null ? null : this.params.data[watchedItem].toString() )   );
    //         }).length === 0;
    //     }
    // }

    /**
     * Hide the availability column in a case of a change in one of the watched columns.
     */
    private doCheck(cellChange?: CellChange) {
        this.isStateShowable = true;

        if (this.params && this.params.watchedItems && this.params.watchedItems.length > 0 &&
            cellChange && cellChange.changedColumnField && cellChange.data &&
            cellChange.rowIndex === this.params.node.rowIndex
        ) {
            this.isStateShowable = this.params.watchedItems.indexOf(cellChange.changedColumnField) === -1;
        }
    }

    mouseover() {
        this.withPopover = typeof this.usePopover === 'undefined' ? true : this.usePopover;
    }
    mouseleave() {
        this.withPopover = false;
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
