import { Observable, Subject } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { ImportErrorComponent } from './import-error.component';
import { ImportErrorColumnDef, ImportModes, ImportErrorDialogResult, ImportErrorRow } from './import-error-dialog.model';

@Injectable()
export class ImportErrorDialogService {
    private bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public openDialog(columnsDef: ImportErrorColumnDef[], successCount: number, 
        errorRows: ImportErrorRow[], importMode: ImportModes): Observable<ImportErrorDialogResult> {

        const modalOptions: ModalOptions = {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'import-error-dialog-override modal-lg',
        };
        this.bsModalRef = this.modalService.show(ImportErrorComponent, modalOptions);
        this.bsModalRef.content.done$ = new Subject<ImportErrorDialogResult>();
        this.bsModalRef.content.columnsDef = columnsDef;
        this.bsModalRef.content.successCount = successCount;
        this.bsModalRef.content.errorRows = errorRows;
        this.bsModalRef.content.importMode = importMode;

        return this.bsModalRef.content.done$;
    }

}
