<div *ngIf="!isModal" fxLayout="row" class="row-container wider">
    <div  fxFlex="50" fxLayoutAlign="start center">
            
            <button *ngIf="!isEdit || newsId === 0" class="btn-secondary" [routerLink]="'/' + sharedService.appSettings.language + '/news'" [queryParams]="sharedService.lastNewsQueryParams">
                <i class="fa fa-arrow-circle-left"></i>
                <a translate>BACK</a>
            </button>
            <button *ngIf="isEdit && newsId !== 0" class="btn-secondary" (click)="backToNonEdit();">
                <i class="fa fa-arrow-circle-left"></i>
                <a translate>BACK</a>
            </button>
    </div>
    <div  fxFlex="50" fxLayoutAlign="end center">
            <!-- <button class="btn-primary" routerLink="/{{sharedService.appSettings.language}}/news/new" [queryParams]="sharedService.lastNewsQueryParams" >
                <i class="fa fa-exclamation-circle"></i>
                <a translate>NEWS_CREATE_NEW</a>
            </button> -->
    </div>
</div>

<!-- DETAIL VIEW -->
<div permissionHide perm="news" action="GET" [hidden]="isEdit">
    <div fxLayout="row" fxLayout.lt-lg="column" ngClass.gt-md="row-container wider input-container" style="width: 100%; margin: 0;" [ngStyle]="{'padding': isModal ? 0 : '6px 20px 6px 20px'}">
        <div [fxFlex]="isModal ? 57 : 55" fxLayout="column" fxLayout.lt-lg="row" fxFlex.lt-lg="100">
            <div class="news-body-container" style="width: 100%">
                <h2>
                    <span *ngIf="newsData?.important"><i aria-hidden="true" class="fnt-red fa fa-exclamation-circle" style="margin-left:10px"></i></span>
                    {{ newsData?.title }}
                    <strong *ngIf="!isEdit">
                        {{ newsData?.validFrom | localdate:sharedService.appSettings.language }}</strong></h2>
                <div class="read-only-wysiwyg" *ngIf="newsData?.body">
                    <iframe #iframe id="iframe" (load)="onLoad()" width="100%" height="350" style="border: 0; outline: 0;" marginheight="0" marginwidth="0"></iframe>
                </div>
            </div>
            <div class="row-container bigger" *ngIf="canEdit && !isModal">
                <button permissionHide perm="news" action="POST" class="btn-primary" (click)="deleteNewsDetail()"><i class="fa fa-times-circle"
                    aria-hidden="true"></i><span translate>NEWS_DELETE</span></button>
            </div>
        </div>

        <div fxFlex="5" fxLayout="column" fxLayout.lt-lg="row" fxFlex.lt-lg="100" style="margin-bottom: 10px">&nbsp;</div>
        <div fxFlex="38" fxLayout="column" fxLayout.lt-lg="column" fxFlex.lt-lg="100">
            <!-- titleAdmin -->
            <div fxLayout="row" class="rfq-detail-widgets" style="margin-bottom: 10px">
                <h3>{{ newsData?.titleAdmin }}</h3>
            </div>

            <!-- Attachments -->
            <div fxLayout="row" class="rfq-detail-widgets" style="margin-bottom: 10px">
                <attachments style="width: 100%" #attachments id="attachments" 
                    [entityId]="newsData?.id"
                    [attachmentsGetter]="attachmentsGetter"
                    [getUploadUrl]="getUploadUrl" 
                    [getDownloadUrl]="getDownloadUrl"
                    [deleteAttachment]="deleteAttachment" 
                    (attachmentS3Keys)="setAttachmentS3Keys($event)"
                    (attachmentsChanged)="attachmentsChanged($event)" 
                    [allowEdit]="canEdit && !isModal" 
                    [reload]="subjectReloadAttachments"
                    [makeCollapsible]="true"
                    [isCollapsed]="false"
                    [hideRowLabel]="true"
                ></attachments>
            </div>
            
            <!-- Add discussion -->
            <div *ngIf="newsData?.discussion === 1" fxLayout="row" class="rfq-detail-widgets" style="margin-bottom: 10px">
                <news-discussion [comments]="comments" [total]="comments?.length" [makeCollapsible]="true"
                    [newsId]="newsId" (commentsHasChange)="commentsHasChanged(parent)" [reload]="reloadComments"
				    (edited)="discussionInFormEdited($event)"
                    [isCollapsed]="false" style="width: 100%"></news-discussion>
            </div>
            
            <!-- Action buttons -->
            <div *ngIf="canEdit && !isModal" fxLayout="row" class="row-container input-container" fxLayoutAlign="end center">
                <button permissionHide perm="news" action="POST" class="btn-primary" (click)="isEdit = true; resetForm(addNews.form)"
                    [disabled]="(isEdit && !allow.reset) || edited.discussionInForm" 
                    [ngClass]="{'ec-disabled': (isEdit && !allow.reset) || edited.discussionInForm}"><i class="fa fa-pencil-square-o"
                        aria-hidden="true"></i><span translate>NEWS_EDIT</span></button>
            </div>
        </div>
        <div *ngIf="!isModal" fxFlex="2" fxLayout="column" fxLayout.lt-lg="row" fxFlex.lt-lg="100" style="margin-bottom: 10px">&nbsp;</div>
    </div>
</div>


<!-- DETAIL EDIT -->
<div permissionHide perm="news" action="POST">
<form id="addNews" #addNews="ngForm" ngForm (keydown.enter)="$event.preventDefault()">
    <div *ngIf="isEdit" fxLayout="column" class="row-container wider">
        <div fxLayout="column" fxLayout.gt-sm="row">
            <div fxLayout="column" fxFlex="55" class="input-container">
                <div>
                    <label><span translate>NEWS_TITLE</span> *:</label>
                </div>
                <div>
                    <input name="newsTitle" #newsTitle required id="newsTitle" [(ngModel)]="newsData.title"
                        type="text" class="form-control" style="width: 100%" maxlength="200" (keyup)="validate()"
                        [myFocus]="newsId === 0"/>
                </div>

                <div style="margin-top: 15px">
                    <label><span translate>NEWS_BODY</span>:</label>
                </div>
                <div>
                    <wysiwyg id="news-editor" [valueGetter]="newsDataOld?.body" (wysiwygText)="wysiwygTextChanged($event)" [reload]="subjectReloadWysiwyg"></wysiwyg>
                </div>
            </div>
            <div fxLayout="column" fxFlex="5">
                &nbsp;
            </div>
            <div fxLayout="column" fxFlex="40">
                <!-- title admin -->
                <div  class="input-container">
                    <label><span translate>NEWS_TITLE_ADMIN</span>:</label>
                </div>
                <div>
                    <input name="newsTitleAdmin" #newsTitleAdmin required id="newsTitleAdmin" [(ngModel)]="newsData.titleAdmin"
                        type="text" class="form-control" style="width: 100%" maxlength="200" (keyup)="validate()"/>
                </div>

                <!-- LANGUAGES -->
                <div class="input-container" style="margin-top: 10px;">
                    <span fxFlex="50"><label><span translate>LANGUAGES</span>:</label></span>
                    <span fxFlex="50">
                        <dropdown-component attributeName="languages" 
                            [items]="languages" 
                            [selected]="selectedLanguages"
                            [multiselect]="true" 
                            [autoClose]="false" 
                            [multiselectAllLabel]="multiselectAllLabel" 
                            [multiselectSelectedLabel]="multiselectSelectedLabel"
                            (onSelect)="newsData.languages = $event.selected; onDropdownChanged('languages')">
                        </dropdown-component>
                    </span>
                </div>
                <!-- DATE FROM -->
                <div class="input-container" style="margin-top: 10px;" fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutAlign="left start" >
                    <div fxFlex.gt-md="50" fxFlex.lt-lg="100" fxLayout.gt-md="column" fxLayout.lt-lg="row" fxLayoutAlign="left start" style="text-align: left !important; padding-top:5px;">
                        <label><span translate>NEWS_VALIDITY_FROM</span> *:</label>
                    </div>
                    <div fxFlex.gt-md="50" fxFlex.lt-lg="100" fxLayout.gt-md="column" fxLayout.lt-lg="row" class="value" fxLayoutAlign="left start" style="text-align: left !important">
                        <div style="padding-top: 5px; text-align: left">
                            <my-date-picker name="dateFrom" [(ngModel)]="validFromDate" [options]="myDatePickerOptionsFrom"
                                [locale]="sharedService.appSettings.language" [selector]="selector" (dateChanged)="onDateFromChanged($event)"
                                *ngIf="showDateFromPicker"></my-date-picker>
                        
                            <timepicker name="timeFrom" [(ngModel)]="validFromTime" [showMeridian]="false" style="display: inline-block;"
                                [showSpinners]="false" (ngModelChange)="onTimeFromChanged(validFromTime)"></timepicker>
                        </div>
                    </div>
                </div>
                <!-- DATE TO -->
                <div class="input-container" style="margin-top: 10px;" fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutAlign="left start" >
                        <div fxFlex.gt-md="50" fxFlex.lt-lg="100" fxLayout.gt-md="column" fxLayout.lt-lg="row" fxLayoutAlign="left start" style="text-align: left !important; padding-top:5px;">
                            <label><span translate>NEWS_VALIDITY_TO</span>:</label>
                        </div>
                        <div fxFlex.gt-md="50" fxFlex.lt-lg="100" fxLayout.gt-md="column" fxLayout.lt-lg="row" class="value" fxLayoutAlign="left start" style="text-align: left !important">
                                <div style="padding-top: 5px; text-align: left">
                            
                                <my-date-picker name="dateTo" [(ngModel)]="validToDate" [options]="myDatePickerOptionsTo"
                                    [locale]="sharedService.appSettings.language" [selector]="selector" (dateChanged)="onDateToChanged($event)"
                                    *ngIf="showDateToPicker"></my-date-picker>

                            <timepicker name="timeTo" [(ngModel)]="validToTime"
                                [showMeridian]="false" style="display: inline-block;" [showSpinners]="false"
                                (ngModelChange)="onTimeToChanged(validToTime)"></timepicker>
                        </div>
                    </div>
                </div>
                <!-- For customers -->
                <div class="input-container" style="margin-top: 10px;">
                    <span fxFlex="50"><label><span translate>NEWS_FOR_USERS_OF_SELLER</span>:</label></span>
                    <span fxFlex="50">
                        <div><input attrName="forAllASUsers" class="row-container clickable" type="checkbox" [(ngModel)]="newsData.forAllASUsers" [checked]="false" [value]="true" id="forAllASUsers" name="forAllASUsers" (ngModelChange)="onCheckboxChanged('forAllASUsers')"/>
                        </div>
                    </span>
                </div>
                <div class="input-container" style="margin-top: 10px;">
                    <span fxFlex="50"><label><span translate>NEWS_FOR_USERS_OF_CUSTOMER</span>:</label></span>
                    <span fxFlex="50">
                        <div><input type="radio" class="clickable" name="forAllCustomerUsers" [(ngModel)]="newsData.forAllCustomerUsers" [value]="1" (ngModelChange)="onCheckboxChanged('forAllCustomerUsers')" /> <span translate>NEWS_FOR_ALL</span></div>
                        <div><input type="radio" class="clickable" name="forAllCustomerUsers" [(ngModel)]="newsData.forAllCustomerUsers" [value]="0" (ngModelChange)="onCheckboxChanged('forAllCustomerUsers')"/> <span translate>NEWS_FOR_NOBODY</span></div>
                        <div><input type="radio" class="clickable" name="forAllCustomerUsers" [(ngModel)]="newsData.forAllCustomerUsers" [value]="2" (ngModelChange)="onCheckboxChanged('forAllCustomerUsers')"/> <span translate>NEWS_FOR_SELECTED_ONLY</span></div>
                        <!-- For customers -->
                        <div class="input-container" *ngIf="newsData.forAllCustomerUsers === 2" style="margin-top: 10px;">
                                <dropdown-component 
                                    attributeName="customers" 
                                    [items]="customersItems" 
                                    [selected]="newsData.customers" 
                                    (onSelect)="selectedCustomer($event.selected)" 
                                    [multiselect]="true" 
                                    [autoClose]="false" 
                                    [search]="true">
                                </dropdown-component>
                        </div>
                    </span>
                </div>
                <!-- Send email notification -->
                <div class="input-container">
                    <span fxFlex="50"><label><span translate>NEWS_SEND_EMAIL_NOTIFICATIONS</span>:</label></span>
                    <span fxFlex="50">
                        <input class="clickable" type="checkbox" [(ngModel)]="newsData.notification" [checked]="true" [checked]="true" id="notification" name="notification" (ngModelChange)="onCheckboxChanged('notification')" />
                    </span>
                </div>

                <div class="input-container">
                    <span fxFlex="50"><label><span translate>NEWS_SKIP_TITLE_AND_LOGO_IN_EMAIL</span>:</label></span>
                    <span fxFlex="50">
                        <input class="clickable" type="checkbox" [(ngModel)]="newsData.skipTitleAndLogoInEmail" [checked]="true" [checked]="true" id="skipTitleAndLogoInEmail" name="skipTitleAndLogoInEmail" (ngModelChange)="onCheckboxChanged('skipTitleAndLogoInEmail')" />
                    </span>
                </div>

                <!-- Allow discussion -->
                <div class="input-container">
                    <span fxFlex="50"><label><span translate>NEWS_ALLOW_DISCUSSIONS</span>:</label></span>
                    <span fxFlex="50">
                        <input class="clickable" type="checkbox" [(ngModel)]="newsData.discussion" [checked]="true" [checked]="true" id="discussion" name="discussion" (ngModelChange)="onCheckboxChanged('discussion')" />
                    </span>
                </div>
                <!-- This news are important-->
                <div class="input-container">
                    <span fxFlex="50"><label><span translate>NEWS_IS_IMPORTANT</span>:</label></span>
                    <span fxFlex="50">
                        <input class="clickable" type="checkbox" [(ngModel)]="newsData.important" [checked]="true" [value]="false" name="important"  (ngModelChange)="onCheckboxChanged('important')"/>
                    </span>
                </div>
                <!-- This news are important-->
                <div class="input-container">
                    <span fxFlex="100"><label translate style="font-weight: normal">MANDATORY_FIELD</label></span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isEdit && canEdit && !isModal" fxLayout="column" class="row-container wider">
        <div class="input-container" fxFlex="99">
            <!-- Delete this news -->
            <button permissionHide perm="news" action="POST" *ngIf="newsId !== 0" class="btn-primary" style="float: left" (click)="deleteNewsDetail()"><i class="fa fa-times-circle" aria-hidden="true"></i><span translate>NEWS_DELETE</span></button>
    
            <!-- Cancel changes and return original values (if news -> empty form, if edit -> untouched values saved in DB) -->
            <button permissionHide perm="news" action="POST" class="btn-secondary" style="float: right" (click)="resetForm(addNews.form)" [disabled]="!allow.reset" [ngClass]="{'ec-disabled': !allow.reset}">
                <i class="fa fa-times-circle" aria-hidden="true"></i><span translate>CANCEL_CHANGES</span>
            </button>
    
            <!-- Update existing entity -->
            <button *ngIf="newsId !== 0" permissionHide perm="news" action="POST" class="btn-primary update-news-button" style="float: right;margin-right: 10px" [disabled]="!allow.publish" [ngClass]="{'ec-disabled': !allow.publish}" (click)="updateNewsDetail()">
                <i class="fa fa-check-square-o" aria-hidden="true"></i><span translate>NEWS_SAVE</span>
            </button>

            <!-- Save new entity -->
            <button *ngIf="newsId === 0" permissionHide perm="news" action="POST" class="btn-primary save-news-button" style="float: right;margin-right: 10px" [disabled]="!allow.publish" [ngClass]="{'ec-disabled': !allow.publish}" (click)="saveNewsDetail()">
                <i class="fa fa-check-square-o" aria-hidden="true"></i><span translate>NEWS_SAVE</span>
            </button>
        </div>
    </div>

    <div *ngIf="isEdit" permissionHide perm="news" action="POST" fxLayout="row" class="row-container wider">
        <div fxLayout="column" fxFlex="55" class="input-container">
            <!-- Attachments  -->
            <div class="rfq-detail-widgets" style="margin-bottom: 10px">
                
                <!-- attachmentsWithoutEntity is not a function, its array !!!! -->
                <attachments *ngIf="newsId === 0" #attachments id="attachments"
                [entityId]="newsData?.id"
                [attachmentsGetterWithoutEntity]="attachmentsWithoutEntity"
                [getUploadUrl]="getUploadUrl"
                (attachmentS3Keys)="setAttachmentS3Keys($event)"
                (attachmentsChanged)="attachmentsChangedWithoutEntity($event)"
                [allowEdit]="canEdit && !isModal"
                [reload]="subjectReloadAttachments"
            ></attachments>

            <attachments *ngIf="newsId !== 0" #attachments id="attachments"
                [entityId]="newsData?.id"
                [attachmentsGetter]="attachmentsGetter"
                [getUploadUrl]="getUploadUrl"
                [getDownloadUrl]="getDownloadUrl"
                [deleteAttachment]="deleteAttachment"
                (attachmentS3Keys)="setAttachmentS3Keys($event)"
                (attachmentsChanged)="attachmentsChanged($event)"
                [allowEdit]="canEdit && !isModal"
                [reload]="subjectReloadAttachments"
              ></attachments>
            </div>
        </div>
        <div fxLayout="column" fxFlex="40" class="input-container"></div>
    </div>
</form>
</div>