import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { Subject } from 'rxjs';
import { GridDateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { AppAreas } from '@app/model/appArea.model';
import { GridNumberRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridStateRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { OrdersService } from '@app/services/order/orders.service';
import { GridTranslateRendererComponent, GridTranslateRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import { GridPriceRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { CCheckRecordSource } from '@app/model/cCheck.model';

@Injectable()
export class CCheckDeliveryOrderGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    public allSelected$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private ordersService: OrdersService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['c-check-delivery-order'],
            gridNameCamel: 'cCheckDeliveryOrder',
            columnList: [
                { id: 'customerName', name: 'CUSTOMER', alwaysVisible: false, checked: true, orderBy: true, orderDirection: 'ASC', width: 130 },
                { id: 'customerCode', name: 'CUSTOMER_CODE_FULL', alwaysVisible: false, checked: true, orderBy: true, orderDirection: 'ASC', width: 80 },
                { id: 'deliveryNumber', name: 'DELIVERY_NUMBER', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'deliveryNumberERP', name: 'DELIVERY_NUMBER_ERP', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'deliveryState', name: 'ORDER_STATUS', checked: true, orderBy: false, width: 50 },
                { id: 'wfState', name: 'WF_STATE', checked: true, orderBy: false, width: 50 },
                { id: 'orderNumberEComm', name: 'ORDER_ID', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'lineNumber', name: 'ROW', checked: true, orderBy: true, orderDirection: 'ASC', width: 100 },
                { id: 'deliveryAmount', name: 'AMOUNT_SHIPMENT', checked: true, orderBy: false, orderDirection: 'ASC', width: 150 },
                { id: 'totalPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, width: 50 },
                { id: 'recordSource', name: 'SOURCE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('customerName').name),
                field: 'customerName',
                width: this.getColumn('customerName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('customerCode').name),
                field: 'customerCode',
                width: this.getColumn('customerCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryNumber').name),
                field: 'deliveryNumber',
                width: this.getColumn('deliveryNumber').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryNumberERP').name),
                field: 'deliveryNumberERP',
                width: this.getColumn('deliveryNumberERP').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryDate').name),
                field: 'deliveryDate',
                width: this.getColumn('deliveryDate').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryState').name), 
                field: "deliveryState",
                width: this.getColumn('deliveryState').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        blue:  'S',
                        yellow:'C',
                        green: 'P',
                        black: 'D'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('wfState').name), 
                field: "wfState",
                width: this.getColumn('wfState').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        blue:  'S',
                        yellow:'C',
                        green: 'P',
                        black: 'D'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('orderNumberEComm').name),
                field: 'orderNumberEComm',
                width: this.getColumn('orderNumberEComm').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('lineNumber').name),
                field: 'lineNumber',
                width: this.getColumn('lineNumber').width,
                suppressSizeToFit: true,
                // minWidth: this.getColumn('lineNumber').width,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('deliveryAmount').name),
                field: 'deliveryAmount',
                width: this.getColumn('deliveryAmount').width,
                minWidth: this.getColumn('deliveryAmount').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('totalPrice').name),
                field: 'totalPrice',
                minWidth: 80,
                width: this.getColumn('totalPrice').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'DELIVERY_ORDER_ITEM_STATE_',
                    renderColors: {
                        gray:  'X',
                        red:   'N',
                        green: 'P'
                    }
                }
            }
        ];
        
        // recordSource
        const recordSource: ColDef = {
            headerName: this.translateService.instant(this.getColumn('recordSource').name),
            field: 'recordSource',
            width: this.getColumn('recordSource').width,
            cellRendererFramework: GridTranslateRendererComponent
        };
        const recordSourceRendererParams: GridTranslateRendererComponentParams = {
            translatePrefix: 'CHECK_RECORD_SOURCE_'
        };
        recordSource.cellRendererParams = recordSourceRendererParams;
        colDefs.push(recordSource);

        return this.tableService.filterSuppressColumns(colDefs, this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'customer.customerName', name: 'CUSTOMER', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'customer.customerCode', name: 'CUSTOMER_CODE_FULL', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'deliveryNumber', name: 'DELIVERY_NUMBER', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'orderNumberEComm', name: 'ORDER_ID', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'recordSource', name: 'SOURCE', type: TableFilterItemTypes.multiselect,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: CCheckRecordSource.Erp, name: `CHECK_RECORD_SOURCE_${CCheckRecordSource.Erp}` },
                    { id: CCheckRecordSource.ECommerce, name: `CHECK_RECORD_SOURCE_${CCheckRecordSource.ECommerce}` }
                ]
            }
        ];

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
