// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { AppEnvironment } from "@app/model/appEnvironment.model";

export const environment: AppEnvironment = {
    production: false,

    target: 'development',

    apiUrl: 'https://woh2ndzx5d.execute-api.eu-west-1.amazonaws.com/development',
    apiUsersUrl: 'https://p56yprkis4.execute-api.eu-west-1.amazonaws.com/development',
    apiDeliveryOrdersUrl: 'https://vg12zbyt90.execute-api.eu-west-1.amazonaws.com/development',
    apiOrdersUrl: 'https://rqvvkiejrc.execute-api.eu-west-1.amazonaws.com/development',
    apiRfqUrl: 'https://ut3h0n2jbi.execute-api.eu-west-1.amazonaws.com/development',
    apiPrfqUrl: 'https://q0is1rq7he.execute-api.eu-west-1.amazonaws.com/development',
    apiS1Url: 'https://muohj5w757.execute-api.eu-west-1.amazonaws.com/development',

    apiProductsUrl: 'https://2uk567bfk7.execute-api.eu-west-1.amazonaws.com/dev',
    apiCoreUrl: 'https://rvscn8gy6f.execute-api.eu-west-1.amazonaws.com/dev/'
};
