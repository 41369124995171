import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class PreloaderService {
    public static loadingCount: number = 0;
    public static observablePreloader = new ReplaySubject(1);

    getPreloaderObservable() {
        return PreloaderService.observablePreloader;
    }

    showPreloader(): void {
        PreloaderService.loadingCount++;
        PreloaderService.observablePreloader.next(PreloaderService.loadingCount);
    }

    hidePreloader(): void {
        if (PreloaderService.loadingCount > 0) {
            PreloaderService.loadingCount--;
        }
        PreloaderService.observablePreloader.next(PreloaderService.loadingCount);
    }
}