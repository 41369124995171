import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { Observable } from 'rxjs';
import { Shipment } from '../../model/shipment.model';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from '../table.service';
import { SharedService } from '../shared.service';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { map } from 'rxjs/operators';
import { PersonCreated } from '@app/model/personCreated.model';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';

interface GetShipmentsResponse {
    rows: Shipment[],
    total: number,
    personCreated: PersonCreated[]
}

@Injectable()
export class ShipmentsService {
    
    constructor (
        private http: EcmHttpService, 
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
    ) {
    }

    /**
     * Loads list of orders
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getShipments (query, hidePreloader?: boolean):Observable<GetShipmentsResponse> {
        let url = `/shipments`;
        return this.http.get(url, this.http.prepareOptions(query), hidePreloader).pipe(map(data => { 
            var shipments: Shipment[] = [];
            data.rows.forEach(function(shipment){
                shipments.push(
                    new Shipment(
                        shipment.id,
                        shipment.shipmentNumberEComm,
                        shipment.shipmentDateRequested,
                        shipment.shipmentNote,
                        shipment.shipmentState,
                        shipment.dateCreated,
                        shipment.createdSortName,
                        shipment.createdFullName,
                        shipment.dateChanged,
                        shipment.changedSortName,
                        shipment.changedFullName,
                        '/shipment/' + shipment.id,
                        shipment.customerId,
                        shipment.customerName,
                        shipment.authorizedSellerId,
                        shipment.authorizedSellerName
                    )
                );
            })

            const personCreated: PersonCreated[] = data.personCreated.map(person => ({
                id: person.createdUserId.toString(),
                name: person.createdFullName,
                customerId: person.customerId
            }));

            return { rows: shipments, total: data.totalCount, personCreated: personCreated };
        }));
    }

    /**
     * Loads and saves shipments count
     */
    getShipmentsCount(hidePreloader?: boolean) {
        this.getShipments({skip: 0, top: 1}, hidePreloader)
        .subscribe(data => {
            this.sharedService.shipmentsCount = data.total;
            localStorage.setItem('shipmentsCount', data.total.toString());
        }, err => {
            console.log(err);
        });
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/shipments`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'shipmentNumberEComm', name: "SHIPMENT_ID", alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'shipmentNote', name: "EXPEDITION_NOTE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'dateCreated', name: "DATE_OF_ISSUE", checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'createdSortName', name: "PERSON_ISSUED", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'shipmentDateRequested', name: "REQUESTED_EXPEDITION_DATE", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'dateChanged', name: "DATE_OF_CHANGE", checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            {id: 'changedSortName', name: "PERSON_CHANGED", checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            // {id: 'actions', hidden: true, name: "ACTIONS", checked: true, orderBy: false, orderDirection: 'ASC', width: 100}
        ];
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            original.unshift({id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            original.unshift({id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110});
        }
        original = this.tableService.filterSuppressColumns(original, 'shipments');
        let restored = this.sharedService.user.preferences['shipmentsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for shipments grid
     */
    getShipmentsGridOptions(startPage:number) {
        var this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        var gridOptions = this.tableService.getDefaultGridOptions(startPage, 'shipmentsTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        var this_ = this;
        var colDefs = [
            {
                headerName: this_.translateService.instant(this_.getColumn('shipmentNumberEComm').name), 
                field: "shipmentNumberEComm",
                width: this_.getColumn('shipmentNumberEComm').width,
                cellRendererFramework: GridLinkRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('shipmentNote').name), 
                field: "shipmentNote",
                width: this_.getColumn('shipmentNote').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dateCreated').name), 
                field: "dateCreated",
                width: this_.getColumn('dateCreated').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('createdSortName').name), 
                field: "createdSortName",
                width: this_.getColumn('createdSortName').width,
                valueGetter: 'data.createdFullName',
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('shipmentDateRequested').name), 
                field: "shipmentDateRequested",
                width: this_.getColumn('shipmentDateRequested').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('dateChanged').name), 
                field: "dateChanged",
                width: this_.getColumn('dateChanged').width,
                suppressSizeToFit: true, 
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {time: true},
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('changedSortName').name), 
                field: "changedSortName",
                width: this_.getColumn('changedSortName').width,
                valueGetter: 'data.changedFullName',
                cellStyle: {'text-align': 'left'}
            },
            // {
            //     headerName: this_.translateService.instant(this_.getColumn('actions').name), 
            //     field: "actions",
            //     width: this_.getColumn('actions').width,
            //     minWidth: this_.getColumn('actions').width,
            //     cellRendererFramework: GridActionsRendererComponent,
            //     cellRendererParams: {service: this_},
            //     sortable: false,
            // }
        ];

        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name), 
                field: "authorizedSellerName",
                width: this_.getColumn('authorizedSellerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            colDefs.splice(1, 0, {
                headerName: this_.translateService.instant(this_.getColumn('customerName').name), 
                field: "customerName",
                width: this_.getColumn('customerName').width,
                cellStyle: {'text-align': 'left'}
            });
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'shipments');
        return colDefs;
    }

    public getFilterItems(personsCreated: PersonCreated[]): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            {
                id: 'shipmentNumberEComm', 
                name: 'SHIPMENT_ID', 
                type: TableFilterItemTypes.text, 
                maxLength: 20, 
                value: '', 
                operator: TableFilterItemOperators.likeBoth,
            },
            {
                id: 'dateCreated', 
                name: 'DATE_OF_ISSUE', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: ''
            },
            {
                id: 'personCreated', 
                name: 'PERSON_ISSUED', 
                type: TableFilterItemTypes.multiselect,
                search: true, 
                value: '', 
                values: personsCreated,
                updateValuesOnFilterMicroChange: (changedItem) => this.tableService.updatePersonCreatedFilter(changedItem, personsCreated),
            },
        ]
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({
                id: 'authorizedSeller.id', 
                name: 'AUTHORIZED_SELLER', 
                type: TableFilterItemTypes.multiselect,
                value: '', 
                values: 
                this.sharedService.user.availableAuthorizedSellers
            });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({
                id: 'customer.id', 
                name: 'CUSTOMER', 
                type: TableFilterItemTypes.multiselect,
                search: true, 
                value: '', 
                values: this.sharedService.user.availableCustomers.map(customer => ({...customer, id: customer.id.toString()})),
            });
        }
        
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'shipments');
        return filterItems;
    }
}