import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './services/language.service';
import * as moment from 'moment';

var locale: any = {};

@Pipe({
  name: 'localnumber'
})
export class LocalNumberPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
    locale = this.languageService.getLocale();
  }
  transform(value: string | number, lang: string, addDecimalZero?: boolean, decimals?: number, thousandsSeparator = true): string {
    return getLocalNumber(value, lang, addDecimalZero, decimals, thousandsSeparator);
  }
}

@Pipe({
  name: 'localcurrency'
})
export class LocalCurrencyPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
    locale = this.languageService.getLocale();
  }
  transform(value: any, lang: string, currency?: string): string { // optional currency overrides default language currency
    if (currency && value) {
      value = parseFloat(value).toFixed(2);
      if (lang === 'cz') { // change cz to sk because js currency formatter makes €100 instead of 100 € for cz locales
        lang = 'sk'
      }
      return parseFloat(value).toLocaleString(lang, {style: 'currency', currency: currency})
    }
    return getLocalNumber(value, lang, true, 2);
  }
}

function getLocalNumber(value: any, lang: string, addDecimalZero?: boolean, decimals?: number, thousandsSeparator = true) {
  if (!value && value != 0) {return ''}
  value = value.toString().replace(' ', '').replace(',', '.');
  let valueBefore = value;
  if (decimals && value && value.length > 0) { // round value to decimals if defined
    value = parseFloat(value).toFixed(decimals);
    if (valueBefore.length < value.length) {
      value = value.substring(0, value.length - (value.length - valueBefore.length));
    }
  }

  const newValue = value.toString();
  const splittedByDot = newValue.split('.');
  if (thousandsSeparator) {
    splittedByDot[0] = addThousandsSeparator(splittedByDot[0], lang);
  }

  if (addDecimalZero && splittedByDot[0].length > 0) {
    // add 0 to end if not two decimal
    if ((splittedByDot[1] && splittedByDot[1].length === 1) &&  addDecimalZero) {
      splittedByDot[1] += '0';
    }
    // add .00 if number without decimals
    if (splittedByDot.length === 1) {
      // splittedByDot[0] += locale[lang].decimalDelimiter;
      splittedByDot.push('00');
    }
  }

  if (addDecimalZero && decimals && splittedByDot[0].length > 0) {
    for (let i = splittedByDot[1].length; i < decimals; ++i) {
      splittedByDot[1] += '0';
    }
  }

  return splittedByDot.join(locale[lang].decimalDelimiter);
}

function addThousandsSeparator(x, lang) {
  x = x.toString().replace(' ', '');
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, locale[lang].thousandsDelimiter);
}


@Pipe({
  name: 'localdate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
    locale = this.languageService.getLocale();
  }
  transform(value: string, lang: string, time?: boolean): string {
    if (value === undefined || value === null) {
      return '';
    }
    const momentDate = moment(value);
    if (!momentDate.isValid()) {
      return ''; // return empty string if not valid date
    }
    return momentDate.format(time ? locale[lang].dateTimeFormat : locale[lang].dateFormat);
  }
}

@Pipe({
  name: 'localDateTimeFromNow'
})
export class LocalDateTimeFromNowPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
    locale = this.languageService.getLocale();
  }
  transform(value: string, lang: string): string {
    moment.locale(lang);
    var momentDate = moment(value);
    if (moment().diff(momentDate, 'days') != 0) {
      return new LocalDatePipe(this.languageService).transform(value, lang, true);
    }
    return momentDate.fromNow();
  }
}




@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(value:any) {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }

}