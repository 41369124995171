import { Component, OnInit, OnDestroy } from '@angular/core';
import { PriceListService } from '../price-list.service';
import { PriceListsGridService } from './price-lists.grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataGetterParams, TableColumn, ExportObj } from '@app/model/table.model';
import { SharedService } from '@services/shared.service';
import { Subject } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { BaseComponent } from '@app/components/base/base.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PriceListImportComponent, PriceListImportResult } from '../price-list-import/price-list-import.component';
import { QuestionDialogService, QuestionDialogResult } from '@components/questionDialogComponent/question.dialog.service';
import { SavePriceListResponse } from '@app/model/priceList.model';
import { PriceListImportErrorDialogComponent, PriceListImportErrorDialogResult } from '../price-list-import/price-list-import-error-dialog.component';
import { TableFilterItem } from '@app/model/table.filter.model';

@Component({
    selector: 'app-price-lists',
    templateUrl: './price-lists.component.html',
    styleUrls: ['./price-lists.component.css']
})
export class PriceListsComponent extends BaseComponent implements OnInit, OnDestroy {

    public totalRows: number;
    public filterItems: TableFilterItem[];
    public exportObj: ExportObj;
    public initialized = false;
    public refreshGrid$: Subject<void>;
    private importModalRef: BsModalRef;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService,
        private priceListService: PriceListService,
        public gridService: PriceListsGridService,
        private modalService: BsModalService,
        private questionDialogService: QuestionDialogService
    ) {
        super(router, route, sharedService);

        this.getPriceLists = this.getPriceLists.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        // To reload when menu item is clicked but it is already routed to this area
        this.refreshGrid$ = new Subject<void>();
        this.subscriptions.push(this.sharedService.subjectSameAreaClicked.subscribe(() => {
            this.refreshGrid$.next();
        }));

        this.sharedService.translateService.get('PRICE_LIST_SUMMARY').subscribe(translation => {
            this.exportObj = {
                fileName: translation,
                export: this.priceListService.exportPriceLists.bind(this.priceListService)
            };
        })

        this.initialized = true;
    }

    public ngOnInit() {
        setTimeout(() => {
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastPriceListsQueryParams);
        });
    }

    public addPriceList(): void {
        this.importModalRef = this.modalService.show(PriceListImportComponent, {
            // class: 'modal-lg',
            ignoreBackdropClick: true
        });
        this.importModalRef.content.modalClosed$ = new Subject<PriceListImportResult>();

        this.subscriptions.push(this.importModalRef.content.modalClosed$.subscribe((pricelistData: PriceListImportResult) => {
            if (pricelistData.rows) {
                this.savePriceListData(pricelistData);
            }
        }));
    }

    public getPriceLists(params: TableDataGetterParams): void {
        const query: EcmHttpQueryParams = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastPriceListsQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastPriceListsQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.priceListService.getPriceLists(query).subscribe(response => {
            this.totalRows = response.totalCount;
            if (!this.filterItems) { // only if not already have it; not to loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
            params.successCallback(response.rows, response.totalCount);
        }, err => {
            console.log(err);
            // Also in error to be able to cancel filter
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
        });
  }

    public getGridOptions(): GridOptions {
        return this.gridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.gridService.getColumns();
    }

    public getColumnsDefs(): ColDef[] {
        return this.gridService.getColumnDefs();
    }

    private savePriceListData(priceListData: PriceListImportResult): void {
        this.priceListService.priceListExists(priceListData.priceListCode, priceListData.currency)
            .subscribe(exists => {
                if (exists) {
                    this.questionDialogService.confirm({
                            message: 'PRICE_LIST_EXISTS_AND_WILL_BE_DELETED',
                            question: 'DO_YOU_WANT_TO_CONTINUE',
                            primary: 'YES',
                            secondary: 'NO'
                    }).subscribe(answer => {
                        if (answer === QuestionDialogResult.Confirm) {
                            this._savePriceListData(priceListData);
                        }
                    });
                } else {
                    this._savePriceListData(priceListData);
                }
            },
            err => console.error(err)
        );
    }

    private _savePriceListData(priceListData: PriceListImportResult, deleteExisting = true): void {
        this.priceListService.savePriceList(priceListData.priceListCode, priceListData.currency, priceListData.note,
            priceListData.rows, deleteExisting).subscribe(result => {
                console.log(result);
                if (result.successCount > 0) {
                    this.questionDialogService.confirm({
                        message: 'PRICE_LIST_INSERT_SUCCESS',
                        question: 'PRICE_LIST_ROUTE_TO_NEW_PRICE_LIST',
                        primary: 'YES',
                        secondary: 'NO'
                    }).subscribe(answer => {
                        if (answer === QuestionDialogResult.Confirm) {
                            this.router.navigate([
                                `/${this.sharedService.appSettings.language}/price-list/${priceListData.priceListCode}/${priceListData.currency}`]);
                        } else {
                            this.gridService.reloadCurrentGridPage$.next();
                        }
                    });
                }
                if (result.errors.length > 0) {
                    this.showImportErrorDialog(priceListData.priceListCode,
                        priceListData.currency, result);
                }
            },
            err => console.error(err));
    }

    private showImportErrorDialog(priceListCode: string, currency: string,
        saveErrorData: SavePriceListResponse): void {

        const errorModalRef = this.modalService.show(PriceListImportErrorDialogComponent, {
            class: 'modal-lg',
            ignoreBackdropClick: true
        });
        errorModalRef.content.saveErrorData = saveErrorData;
        errorModalRef.content.modalClosed$ = new Subject<PriceListImportResult>();

        this.subscriptions.push(errorModalRef.content.modalClosed$.subscribe((fixedData: PriceListImportErrorDialogResult) => {
            if (fixedData.rows && fixedData.rows.length > 0) {
                this._savePriceListData({
                    priceListCode: priceListCode,
                    currency: currency,
                    rows: fixedData.rows
                }, false);
            }
        }));
    }
}
