<div [ngClass]="sharedService.apParams.styleTheme + '-theme'"
     fxLayout="row" 
     fxLayoutAlign="center center">

	<div [fxFlex]="mode === 'setCredentials' ? '530px' : '350px'" fxFlex.xs="100%">
        <h1 class="fnt-primary" *ngIf="mode == 'login'" translate>LOGIN</h1>
        <h1 class="fnt-primary" *ngIf="mode == 'forgottenPassword' || mode == 'clickOnLink'" translate>FORGOTTEN_PASSWORD</h1>
        <h1 class="fnt-primary" *ngIf="mode == 'confirmForgotPassword'" translate>RESET_PASSWORD</h1>
        <h1 class="fnt-primary" *ngIf="mode == 'setCredentials'" translate>{{'WELCOME_TO_ECOMMERCE_AS' + sharedService.apParams.id}}</h1>
        <form (ngSubmit)="primary()" #loginForm="ngForm" *ngIf="mode != 'clickOnLink'">
            <div class="row-container" fxLayout="column">
                <div *ngIf="mode == 'forgottenPassword'">
                  <span translate>RESET_PASSWORD_INFO</span>
                </div>
                <div *ngIf="mode == 'setCredentials'">
                  <span translate>SET_CREDENTIALS_INFO</span>
                </div>
                <div class="input-container">
                    <label translate>EMAIL</label>
                    <input style="max-width: 370px;" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="email" class="form-control" id="email" name="email" #emailFormItem="ngModel"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [disabled]="mode == 'confirmForgotPassword' || mode == 'setCredentials'"
                      [ngClass]="{'ec-disabled': mode == 'confirmForgotPassword' || mode == 'setCredentials'}" required>
                </div>

                <div class="input-container" *ngIf="mode == 'login'">
                    <label translate>PASSWORD</label>
                    <input style="max-width: 370px;" type="password" placeholder="{{ 'PASSWORD' | translate }}" [(ngModel)]="password" class="form-control" id="password" name="password"
                      #passwordFormItem="ngModel" maxlength="255" required>
                </div>

                <div *ngIf="mode == 'setCredentials'">
                  <div>
                    <div class="input-container">
                        <label translate>GIVEN_NAME</label>
                        <input style="width: 180px; margin-right: 10px;" type="text" placeholder="{{ 'GIVEN_NAME' | translate }}" [(ngModel)]="givenName" class="form-control"
                          id="givenName" name="givenName" #givenNameFormItem="ngModel" maxlength="50" required>
                    </div>
                    <div class="input-container">
                        <label translate>FAMILY_NAME</label>
                        <input style="width: 180px;" type="text" placeholder="{{ 'FAMILY_NAME' | translate }}" [(ngModel)]="familyName" class="form-control"
                          id="familyName" name="familyName" #familyNameFormItem="ngModel" maxlength="50" required>
                    </div>
                  </div>
                  <div>
                    <div class="input-container">
                        <label translate>PHONE_NUMBER</label>
                        <input style="width: 180px;" type="text" placeholder="{{ 'PHONE_NUMBER' | translate }}" [(ngModel)]="phoneNumber" class="form-control" id="phoneNumber"
                            name="phoneNumber" #phoneNumberFormItem="ngModel" maxlength="40" required>
                    </div>
                  </div>
                </div>

                <div class="input-container with-help" *ngIf="mode == 'confirmForgotPassword' || mode == 'setCredentials'">
                    <label translate>PASSWORD_NEW</label>
                    <input style="width: 180px;" type="password" placeholder="{{ 'PASSWORD' | translate }}" [(ngModel)]="password" (blur)="hidePassHelp(passwordFormItem.errors)" (focus)="showPassHelp()" class="form-control" id="password" name="password" #passwordFormItem="ngModel" maxlength="255" [validPassword]="isPasswordValid" required>
                    <div class="help row-container" *ngIf="passHelpVisible">
                        <div><i class="fa fa-times-circle fnt-orange" *ngIf="passwordFormItem.errors?.lowercase"></i><i class="fa fa-check-circle fnt-green" *ngIf="!passwordFormItem.errors?.lowercase"></i><span translate>PASSWORD_LOWERCASE</span></div>
                        <div><i class="fa fa-times-circle fnt-orange" *ngIf="passwordFormItem.errors?.uppercase"></i><i class="fa fa-check-circle fnt-green" *ngIf="!passwordFormItem.errors?.uppercase"></i><span translate>PASSWORD_UPPERCASE</span></div>
                        <div><i class="fa fa-times-circle fnt-orange" *ngIf="passwordFormItem.errors?.number"></i><i class="fa fa-check-circle fnt-green" *ngIf="!passwordFormItem.errors?.number"></i><span translate>PASSWORD_NUMBER</span></div>
                        <div><i class="fa fa-times-circle fnt-orange" *ngIf="passwordFormItem.errors?.passLength"></i><i class="fa fa-check-circle fnt-green" *ngIf="!passwordFormItem.errors?.passLength"></i><span translate>PASSWORD_LENGTH</span></div>
                    </div>
                </div>

                <div class="input-container" *ngIf="mode == 'confirmForgotPassword' || mode == 'setCredentials'">
                    <label translate>PASSWORD_NEW_AGAIN</label>
                    <input style="width: 180px;" type="password" placeholder="{{ 'PASSWORD' | translate }}" [(ngModel)]="passwordAgain" class="form-control" id="passwordAgain" name="passwordAgain" #passwordFormItem="ngModel" maxlength="255" [validConfirmPassword]="password" required>
                </div>

                <div class="input-container" *ngIf="mode == 'setCredentials' && requireBusinessConditions">
                    <input type="checkbox" [(ngModel)]="acceptBusinessConditions" id="acceptBusinessConditions" name="acceptBusinessConditions" />&nbsp;<span translate>I_ACCEPT_WITH</span>&nbsp;<a (click)="downloadBusinessConditions()" translate>BUSINESS_CONDITIONS_7P</a>&nbsp;<span translate>ECOMMERCE</span>
                </div>
            </div>

            <div  class="row-container"
            	fxLayout="row" 
            	fxLayoutAlign="end center">
              <busy-indicator *ngIf="busy"></busy-indicator>
            	<button class="btn-primary" type="submit" [disabled]="!loginForm.form.valid || (mode == 'setCredentials' && requireBusinessConditions && !acceptBusinessConditions) || busy" [ngClass]="{'ec-disabled': !loginForm.form.valid || (mode == 'setCredentials' && requireBusinessConditions && !acceptBusinessConditions) || busy}">
                  <i class="fa fa-chevron-circle-right"></i>
                  <span *ngIf="mode == 'login'" translate>LOGIN_BTN</span>
                  <span *ngIf="mode == 'forgottenPassword'" translate>RESET_PASSWORD</span>
                  <span *ngIf="mode == 'confirmForgotPassword' || mode == 'setCredentials'" translate>SUBMIT</span>
              </button>
            </div>
        </form>

        <div *ngIf="mode == 'clickOnLink'">
          <p translate>EMAIL_SEND_SUCCESS</p>
          <p translate>CLICK_LINK_IN_EMAIL</p>
        </div>

        <div fxLayout="row" style="margin: 20px 0;">
          <span fxFlex="33" class="row-container fnt-primary clickable" (click)="toLogin()" *ngIf="mode == 'forgottenPassword' || mode == 'clickOnLink'" translate>LOGIN</span>
          <!-- <span fxFlex="33" class="row-container fnt-primary clickable" routerLink="/{{sharedService.appSettings.language}}/registration" translate>REGISTRATION</span> -->
          <span fxFlex="50" class="row-container fnt-primary clickable" (click)="toForgottenPassword()" *ngIf="mode == 'login'" translate>FORGOTTEN_PASSWORD</span>
          <span fxFlex="50" class="row-container fnt-primary clickable" (click)="dismiss()" translate>CONTINUE_UNLOGGED</span>
        </div>

<!--             <input
              [(ngModel)]="myDate"
              date-format="DD.MM.YYYY"
              ng2-datetime-picker
              date-only="true" /> -->
	</div>
</div>