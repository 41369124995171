import {Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../../../services/shared.service';
import { TableService } from '../../../services/table.service';
import { TableDataGetterParams, TableColumn, TableBulk, ExportObj } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { Prfq, PrfqStates, PrfqItem } from '@app/model/prfq.model';
import { PrfqsDetailItemsGridService } from './prfqs-detail-items.grid.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { PrfqService } from '../prfq.service';

@Component({
    selector: 'prfq-detail-items',
    templateUrl: 'prfq-detail-items.component.html'
})
export class PrfqDetailItemsComponent implements OnInit, OnDestroy {
    @Input() prfqId: number; /** TODO: refactor: rfqId exists in rfq:Rfq. Do you need this? */
    @Input() prfq: Prfq;
    @Input() showNewRowButton: boolean;
    @Input() reloadObservable: Subject<any>;
    @Input() reloadGridOptions: Subject<{isProjectPriceList: boolean}>;
    @Output() rowsDataLoaded: EventEmitter<any> = new EventEmitter<any>();

    public tableNamePrefix: string; // because of storing table column state differently for state P and for other states
    public totalRows: number;
    public refreshGrid$ = new Subject<void>();
    public exportObj: ExportObj;
    public bulk: TableBulk;
    public filterItems: TableFilterItem[];
    busy: boolean;

    attachmentS3Keys: string[] = [];
    addItemsVisible = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public sharedService: SharedService,
        private tableService: TableService,
        public gridService: PrfqsDetailItemsGridService,
        private prfqService: PrfqService
    ) {
        this.getPrfqItems = this.getPrfqItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        this.getNewRow = this.getNewRow.bind(this);

        this.gridService.initSelection();

        this.refreshGrid$ = new Subject<any>();
        this.subscriptions.push(this.gridService.selected$.subscribe(() => { // item was selected
            this.setBulk();
        }));
        this.subscriptions.push(this.gridService.allSelected$.subscribe(() => { // all items was selected
            this.setBulk();
        }));
    }

    ngOnInit() {
        this.tableNamePrefix = this.prfq.prfqState === PrfqStates.IN_PROGRESS ? 'InStateP' : null;

        const exportFunction = (query: EcmHttpQueryParams) =>
            this.prfqService.exportPrfqItems(this.prfq.id, query);
        
        this.sharedService.translateService.get('RFQ_ITEM_NO').subscribe((translation: string) => {
            this.exportObj = {
                fileName: translation + ' ' + this.prfq.prfqNumber,
                export: exportFunction.bind(this)
            };
        });

        // reset editedIds on start which can disable backdrop; issue FE #400
        // this.rfqGridService.rfqItemsEditedIds = {};

        this.subscriptions.push(this.reloadObservable.subscribe(() => {
            this.refreshGrid$.next();
        }));

        setTimeout(() => {
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastPrfqDetailQueryParams);
        });
    }

    public getPrfqItems(params: TableDataGetterParams): void {
        this.busy = true;
        const query: EcmHttpQueryParams = {
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastPrfqDetailQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastPrfqDetailQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.prfqService.getPrfqItems(this.prfqId, query).subscribe(data => {
            this.totalRows = data.totalCount;
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems(this.prfq.prfqState);
            }
            this.rowsDataLoaded.emit([...data.rows]);
            params.successCallback(data.rows, data.totalCount);
            this.busy = false;
            // this.tableService.updateSelectionWithData(this.sharedService.user.preferences['prfqDetailSelection'], data.rows);
            this.tableService.updateSelectionWithData(this.gridService.getSelection(), data.rows);
            this.setBulk();
        }, err => {
            console.log(err);
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems(this.prfq.prfqState);
            }
            this.busy = false;
        });
    }

    private setBulk(): void {
        // this.bulk = this.rfqGridService.getBulk(this.sharedService.user.preferences['prfqDetailSelection'].ids, this.rfqId, this.subjectRefresh,
        //     this.getRfq());
        this.bulk = this.gridService.getBulk(this.gridService.getSelection().ids, this.prfqId);
    }

    /**
     * Get data for a new item
     */
    public getNewRow(): PrfqItem {
        return Object.assign(this.gridService.getNewRow(), {prfqId: this.prfqId});
    }

    public getEditedCount(): number {
        return this.gridService.editedIdsObj ? Object.keys(this.gridService.editedIdsObj).length : 0;
    }

    public getGridOptions(): GridOptions {
        return this.gridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.gridService.getColumns(null, this.tableNamePrefix);
    }

    public getColumnsDefs(): ColDef[] {
        return this.gridService.getColumnDefs();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
