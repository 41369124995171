<div class="fixed-header" *ngIf="showStickyHeader" [ngStyle]="{'width.px': headerWidth, 'margin-left.px': marginLeft + 1}">
	<div class="header-cols" [ngClass]="{'bkg-primary': !secondaryHeaderColor, 'bkg-gray-darker': secondaryHeaderColor}" [ngStyle]="{'height.px': parents.length > 0 ? 52 : 26}">
		<div class="col parent" [ngClass]="{'fnt-white': !secondaryHeaderColor}" *ngFor="let parent of parents" [ngStyle]="{'width.px': parent.actualWidth, 'left.px': parent.left + scrolledByLeft + additionalLeftOffset}">
			{{parent.headerName}}
		</div>
		<div class="col" [ngClass]="{'fnt-white': !secondaryHeaderColor}" *ngFor="let col of columns" [ngStyle]="{'width.px': col.actualWidth, 'top.px': parents.length > 0 ? 26 : 0, 'left.px': col.left + scrolledByLeft + additionalLeftOffset}">
			{{col.headerName}}
		</div>
	</div>
</div>
<div #bottomElement class="bottom-end"></div>