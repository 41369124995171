import { Component} from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { DiscussionDialogService } from '../../../../components/discussion/discussionDialogComponent/discussion.dialog.service';
import { AttachmentDialogService } from '../../../attachmentComponent/attachmentDialogComponent/attachment.dialog.service';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { Attachment, AttachmentDownloadObj, AttachmentUploadObj } from '@app/model/attachment.model';
import { map, switchMap } from 'rxjs/operators';
import { ToastService } from '@app/services/toastService/toast.service';
import { SendByEmailDialogService } from '@app/components/send-by-email/send-by-email.dialog.service';
import { SendByEmailDialogResultState } from '@app/components/send-by-email/send-by-email-component/send-by-email.dialog.model';

/** 
* export button is shown if not suppressActions in params
* confirm button is shown if showConfirm function is defined in params and returns true value
* decline button is shown if showDecline function is defined in params and returns true value
*/

export interface GridActionsRendererComponentParams<TData> {
  allowOnStates?: string[];
  stateAttrName?: string;
  showConfirm: (data: TData) => boolean;
  showDecline: (data: TData) => boolean;
  namePartGetter: (data: TData) => string;
  itemArea: string;
  loadForEachItem: boolean;
  showOnlyEmptyChildTableKeyItems?: boolean;
  showComments?: boolean;
  showAttachments?: boolean;
  showSendCsvByEmail?: boolean;
  confirm?: (params: ICellRendererParams) => void;
  confirmTitle?: string;
  decline?: (params: ICellRendererParams) => void;
  declineTitle?: string;
  export?: (ids: number[], orderNumberEcomm: string, format: string, suppressFirstPartOfFileName?: boolean) => void;
  exportAndSendByEmail?: (ids: number[], orderNumberEcomm: string, format: string, email: string) => Observable<void>;
  getAllowEdit?: (params: MyParams<TData>) => boolean;
  attachmentsAddedObservable?: Subject<unknown>;
  getAttachments?: (params: ICellRendererParams, entityId: number) => Observable<Attachment[]>;
  getUploadUrl?: (params: ICellRendererParams, fileName: string, privateItem?: boolean) => Observable<AttachmentUploadObj>;
  getDownloadUrl?: (params: ICellRendererParams, attachmentId: number, attachmentChildId?: number) => Observable<AttachmentDownloadObj>;
  deleteAttachment?: (
    params: ICellRendererParams, 
    entityId: number, 
    attachmentId: number,
    lineNumber?: number,
  ) => Observable<void>;
  attachmentChanged?: () => void;
  idAttrName?: string; // name of the key attribute, if different from 'id'
                      // e.g. in case of prfq item is used value 'prfqId', because 'id'
                      // is id of the line, not id of prfq
  // Deprecated! Use 'export' directly instead of the whole service
  service?: {
    export: (ids: number[], orderNumberEcomm: string, format: string, suppressFirstPartOfFileName?: boolean) => void,
  };
}

interface MyParams<TData> extends ICellRendererParams, GridActionsRendererComponentParams<TData> {}

@Component({
    selector: 'actions-renderer',
    template: `	<div class="actions" style="font-size: 20px; margin-top: -5px;">
                     <ng-template #toolTemplateCsv><span translate>EXPORT_TO_CSV</span></ng-template>
                     <ng-template #toolTemplatePdf><span translate>EXPORT_TO_PDF</span></ng-template>
                     <ng-template #toolTemplatePrint><span translate>EXPORT_TO_PRINT</span></ng-template>
                     <ng-template #toolTemplateConfirm><span translate>{{params.confirmTitle}}</span></ng-template>
                     <ng-template #toolTemplateDecline><span translate>{{params.declineTitle}}</span></ng-template>
                     <ng-template #toolTemplateSendCsvByEmail><span translate>SEND_CSV_BY_EMAIL</span></ng-template>
                     
                     <div *ngIf="params.showComments"  >
                        <div *ngIf="isShowable" (click)="comment()" class="action clickable comment-count center-text" 
                        [ngClass]="{'empty': params.data.postsCount == 0}">
                          <i class="fa fa-comments"></i> {{params.data.postsCount > 0 ? params.data.postsCount : ''}}
                        </div>
                     </div>
                     <div *ngIf="params.showAttachments" class="clickable comment-count center-text" 
                        [ngClass]="{'empty': params.data.attachmentsCount === 0}" (click)="attachments()">
                        <i class="fa fa-paperclip"></i> <span>{{params.data.attachmentsCount > 0 ? params.data.attachmentsCount : ''}}</span>
                     </div>
                     <div>
                        <i *ngIf="!suppressActions"
                          class="action clickable fa fa-download"
                          (click)="exportCSV()"
                          [popover]="toolTemplateCsv"
                          container="body"
                          triggers="mouseenter:mouseleave"
                          [adaptivePosition]="true"
                          placement="left"
                        ></i>
                     </div>
                     <div>
                        <i *ngIf="!suppressActions && params.showSendCsvByEmail"
                          class="action clickable fa fa-envelope-o"
                          (click)="sendCsvByEmail()"
                          [popover]="toolTemplateSendCsvByEmail"
                          container="body"
                          triggers="mouseenter:mouseleave"
                          [adaptivePosition]="true"
                          placement="left"
                        ></i>
                     </div>
                     <div>
                        <i *ngIf="params.confirm && showConfirm" class="action clickable fa fa-check-circle fnt-green" (click)="params.confirm(params)" [popover]="toolTemplateConfirm" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
                     </div>
                     <div>
                        <i *ngIf="params.decline && showDecline" class="action clickable fa fa-times-circle fnt-orange" (click)="params.decline(params)" [popover]="toolTemplateDecline" container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="left"></i>
    			     </div>
                </div>`,
                /**
                 *  These are hidden for now
                 *  <i class="clickable fa fa-file-pdf-o" style="margin-right: 5px; opacity: 0.9" [popover]="toolTemplatePdf" container="body" triggers="mouseenter:mouseleave"></i>
                    <i class="clickable fa fa-print" style="margin-right: 5px; opacity: 0.9" [popover]="toolTemplatePrint" container="body" triggers="mouseenter:mouseleave"></i>
                 */
  styleUrls:  ['./actions.renderer.component.scss']
})
export class GridActionsRendererComponent<TData> implements AgRendererComponent {
    params: MyParams<TData>;
    suppressActions: boolean;
    showConfirm: boolean;
    showDecline: boolean;
    isShowable = true;

    constructor(
      private discussionDialogService: DiscussionDialogService,
      private attachmentDialogService: AttachmentDialogService,
      private sendByEmailDialogService: SendByEmailDialogService,
      private toastService: ToastService,
    ) {}

    agInit(params: MyParams<TData>): void {
        this.params = params;
        // allowOnStates is optional array of states ['P', ..] in which actions are allowed
        if (params.allowOnStates) {
            this.suppressActions = params.allowOnStates.indexOf(params.stateAttrName ? params.data[params.stateAttrName] : params.data.state) < 0;
        }
        this.isShowable = this.params.data.rfqState !== 'P';
        this.showConfirm = this.params.showConfirm ? this.params.showConfirm(params.data) : false;
        this.showDecline = this.params.showDecline ? this.params.showDecline(params.data) : false;
    }

    exportCSV() {
      const fileNamePart = this.params.namePartGetter(this.params.data);
      if (this.params.export) {
        this.params.export([this.params.data.id], fileNamePart, 'CSV');
      } else if (this.params.service.export) {
        this.params.service.export([this.params.data.id], fileNamePart, 'CSV');
      } else {
        console.error('Export function not defined');
      }
    }

    sendCsvByEmail(): void {
      this.sendByEmailDialogService.openDialog().subscribe(
        (dialogResult) => {
          if (dialogResult.state === SendByEmailDialogResultState.Send) {
            const fileNamePart = this.params.namePartGetter(this.params.data);

            this.params.exportAndSendByEmail([this.params.data.id], fileNamePart, 'CSV', dialogResult.data.email).subscribe(
              () => {
                this.toastService.addSuccess('SENT_EMAIL_SUCCESS');
              },
              (err) => {
                console.log(err);
              }
            );
          }
        }
      );
    }

    comment() {
      this.discussionDialogService.confirm(this.params.data, this.params.itemArea, this.params.idAttrName, this.params.loadForEachItem, this.params.showOnlyEmptyChildTableKeyItems).subscribe(
        res => {
          if (res > 0) {
            this.params.data.postsCount = this.params.data.postsCount + res;
          }
        }  
      );
    }

    attachments() {
        this.attachmentDialogService.confirm(
          this.params.data,
          (id) => this.params.getAttachments(this.params, id),
          (fileName: string) => this.params.getUploadUrl(this.params, fileName),
          (attachmentId: number, lineNumber?: number) => this.params.getDownloadUrl(this.params, attachmentId, lineNumber),
          (entityId: number, attachmentId: number, lineNumber?: number) => this.params.deleteAttachment(this.params, entityId, attachmentId, lineNumber).pipe(
            map(result => {
              this.params.data.attachmentsCount = this.params.data.attachmentsCount - 1; // attachment was deleted
              if (this.params.attachmentChanged) {
                this.params.attachmentChanged();
              }
            })
          ),
          () => this.params.getAllowEdit(this.params),
          (s3Keys: string[]) => {
            this.params.data.attachmentsCount = this.params.data.attachmentsCount + 1; // attachments were added
            if (this.params.attachmentChanged) {
              this.params.attachmentChanged();
            }
          },
          // this.params.getItemLabel
          ).subscribe(
            res => {
              if (res > 0) {
                this.params.data.attachmentsCount = this.params.data.attachmentsCount + res;
                this.params.attachmentsAddedObservable.next('');
              }
            }  
          );
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }

}