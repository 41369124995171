import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { LanguageService } from '../language.service';
import { LocalDatePipe } from '../../locale.pipes.module';
// import { GridLinkRendererComponent } from 'app/components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridHeaderActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';

// import { GridNewsActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNewsActionsRendereComponent/grid.news.actions.renderer.component';
// import { GridNewsShowInDialogRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNewsShowInDialogActionRendereComponent/grid.news.show.in.dialog.renderer.component';

@Injectable()
export class NewsReadDetailGridService {
    subjectReloadCurrent: Subject<any>;

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        public languageService: LanguageService) {

        this.subjectReloadCurrent = new Subject<any>();
    }

    getColumns(forceOriginal?: boolean): any[] {
        const original = [
            {id: 'customerName', name: 'CUSTOMER', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'userFullName', name: 'USER', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'email', name: 'EMAIL', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'dateRead', name: 'DATE_READ', checked: true, orderBy: true, orderDirection: 'DESC', width: 120},
            // {id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 130}
        ];
        // original = this.tableService.filterSuppressColumns(original, 'newsReadDetailTableColumns');
        const restored = this.sharedService.user.preferences['newsReadDetailTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        // tslint:disable-next-line:prefer-const
        let columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions() {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }
        const gridOptions = this.tableService.getDefaultGridOptions(null, 'newsNewsReadDetailTablePageSize', headerClassFunc);
        gridOptions.defaultColDef.headerComponentParams.suppressToUrl = true;
        gridOptions.defaultColDef.headerComponentParams.columnsObjects = this.getColumns();
        gridOptions.defaultColDef.headerComponentParams.sharedService = this.sharedService;
        gridOptions.columnDefs = this.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs;
            colDefs = [
                {
                    headerName: this.translateService.instant(this.getColumn('customerName').name),
                    field: 'customerName',
                    width: this.getColumn('customerName').width,
                    minWidth: this.getColumn('customerName').width,
                },
                {
                    headerName: this.translateService.instant(this.getColumn('userFullName').name),
                    field: 'userFullName',
                    width: this.getColumn('userFullName').width,
                    minWidth: this.getColumn('userFullName').width,
                },
                {
                    headerName: this.translateService.instant(this.getColumn('email').name),
                    field: 'email',
                    width: this.getColumn('email').width,
                    minWidth: this.getColumn('email').width,
                },
                {
                    headerName: this.translateService.instant(this.getColumn('dateRead').name),
                    headerClass: this.headerClassFunc,
                    field: 'dateRead',
                    width: this.getColumn('dateRead').width,
                    minWidth: this.getColumn('dateRead').width,
                    suppressSizeToFit: true,
                    valueGetter: (row) => {
                        return new LocalDatePipe(this.languageService).transform(row.data.dateRead, this.sharedService.appSettings.language, true);
                    }
                }
            ];

        // colDefs = this.tableService.filterSuppressColumns(colDefs, 'newsReadDetail');
        return colDefs;
    }
    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }
    getFilterItems(customers: {id: string, name: string}[], users: {id: string, name: string}[]) {
            const filterItems = [
                {id: 'customerId', name: 'CUSTOMER', type: 'multiselect', search: true, value: '', values: customers},
                {id: 'userId', name: 'USER', type: 'multiselect', search: true, value: '', values: users},
                // {id: 'email', name: 'EMAIL', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
                {id: 'dateRead', name: 'DATE_READ', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
                ];
                // , showTimepicker: true, time: true
            // filterItems = this.tableService.filterSuppressColumns(filterItems, 'newsReadDetail');
            return filterItems;
    }

}

