import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component, HostListener, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../services/shared.service';
import { ToastService } from '../../../services/toastService/toast.service';
import { LanguageService } from '../../../services/language.service';
import { WindowService } from '../../../services/window.service';
import { LocalDatePipe } from '../../../locale.pipes.module';
import { FormService } from '../../../services/form.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'import-stock-error-dialog',
    templateUrl: 'import.stock.error.dialog.component.html'
})
export class ImportStockErrorDialogComponent implements OnInit {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;
    somethingChanged = false;

    // Initialize selector is closed
    private selector: number;

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.doneSubject.next({});
            this.bsModalRef.hide();
        }
    }

    constructor(public bsModalRef: BsModalRef,
                public translate: TranslateService,
                private sharedService: SharedService,
                private toastService: ToastService,
                private languageService: LanguageService,
                private formService: FormService,
                private windowService: WindowService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 160;
        });
    }

    ngOnInit() {
    }

    copyErrorsToClipboard() {
        // caled twice to remove previous clipboard value
        this.copy();
        this.copy();
        this.toastService.addSuccess('COPY_ERRORS_TO_CLIPBOARD_SUCCESS');
    }

    /**
     * Creates hidden element and fills it with error data, than copies to clipboard
     */
    copy() {
        const this_ = this;
        let tmpEl;
        tmpEl = document.createElement('textarea');

        // since we remove the element immediately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
        tmpEl.style.opacity = 0;
        tmpEl.style.position = 'fixed';
        tmpEl.style.pointerEvents = 'none';
        tmpEl.style.zIndex = -1;

        let value = '';
        // fill it with your HTML
        this.params.errors.forEach(errorItem => {
            if (this.params.showBrandCode) {
                value += (errorItem.row.brandCode ? errorItem.row.brandCode : '') + '\t';
            }
            value += (errorItem.row.eCommProductId ? errorItem.row.eCommProductId : '') + '\t';
            value += (errorItem.row.amount ? errorItem.row.amount : '') + '\t';
            // value += new LocalDatePipe(this_.languageService).transform(errorItem.row.deliveryDate, this_.sharedService.appSettings.language) + '\t';
            for (const key in errorItem.error) {
                if (key) {
                    value += this_.translate.instant(errorItem.error[key]) + ' ';
                }
            }
            value += '\n';
        });

        // // append the temporary node to the DOM
        document.body.appendChild(tmpEl);

        tmpEl.appendChild(document.createTextNode(value))
        tmpEl.focus();
        tmpEl.select();

        // copy
        document.execCommand('copy');

        // and remove the element immediately
        document.body.removeChild(tmpEl);
    }

    onChange(item, attrChangedName) {
        this.somethingChanged = true;
        delete item.error[attrChangedName];

        if (attrChangedName === 'brandCode') {
            if (item.row.brandCode.length <= 0) {
                item.error[attrChangedName] = this.translate.instant('REQUIRED_ATTRIBUTE_MISSING_ERROR');
            }
        }
        if (attrChangedName === 'eCommProductId') {
            if (item.row.eCommProductId.length <= 0) {
                item.error[attrChangedName] = this.translate.instant('INVALID_DATA_RANGE_FOR_RFQPRODUCT_ERROR');
            }
        }
        if (attrChangedName === 'amount') {
            if (parseFloat(item.row.amount) === 0 || isNaN(item.row.amount)) {
                item.error[attrChangedName] =  this.translate.instant('ERROR_INVALID_DATA_RANGE_FOR_AMOUNT');
            }
        }
    }

    addToBasketAgain() {
        const rows = this.params.errors.map(error => ({ 
            brandCode: error.row.brandCode,
            eCommProductId: error.row.eCommProductId,
            amount: error.row.amount
        }));
        this.doneSubject.next({rows: rows});
        this.bsModalRef.hide();
    }
}
