<div class="controlling row-container wider" *ngIf="showGrid">
	<div fxLayout="row" style="padding: 0 0 5px 0">
		<div fxFlex="100">
			<input type="text" style="width: 250px;" placeholder="{{'FILTER' | translate }}" 
	          	(keyup)="quickFilterOnChange($event)">
		</div>
		<div fxFlex="100px" fxLayout="row" fxLayoutAlign="end end">
			<ng-template #refreshTmpl><span translate>REFRESH</span></ng-template>
			<button class="btn-secondary" (click)="loadData()" [popover]="refreshTmpl" triggers="mouseenter:mouseleave">
		    	<i class="fa fa-refresh"></i>
		    </button>
			<ng-template #exportTmpl><span translate>EXPORT_TO_CSV</span></ng-template>
			<button class="btn-secondary" (click)="export(agGrid, 'CSV')" [popover]="exportTmpl" triggers="mouseenter:mouseleave">
		    	<i class="fa fa-download"></i>
		    </button>
		</div>
	</div>
	<ag-grid-angular #agGrid style="width: 100%;" [ngStyle]="{'height.px': gridHeight}" class="ag-fresh"
        [gridOptions]="controllingGridService.getGridOptions()"
        (gridReady)="onGridReady($event)" >
	</ag-grid-angular>
</div>