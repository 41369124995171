<div fxLayout="row" class="row-container wider">
    <div  fxFlex="50" fxLayoutAlign="start center">
            <!-- <button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/{{backUrl}}" [queryParams]="backUrl == 'news' ? sharedService.lastNewsQueryParams : {}">
                <i class="fa fa-arrow-circle-left"></i>
                <a translate>BACK</a>
            </button> -->
            <div class="row-container">
                <h3 style="display: inline-block;"><i class="fa fa-newspaper-o"></i> <span translate>NEWS</span> 
                    </h3>
            </div>
            
    </div>
    <div  fxFlex="50" fxLayoutAlign="end center">
            <button permissionHide perm="news" action="POST" class="btn-primary" routerLink="/{{sharedService.appSettings.language}}/news/0" [queryParams]="sharedService.lastNewsQueryParams" >
                <i class="fa fa-exclamation-circle"></i>
                <a translate>NEWS_CREATE_NEW</a>
            </button>
    </div>
</div>

<div class="row-container wider">    
    <div fxLayout="column">
    	<ecm-table [dataGetter]="getNews" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>



<!-- <div class="row-container wider">
        <div fxLayout="column">
            <ecm-table [dataGetter]="getNewsDetail" 
                [optionsGetter]="getGridOptions" 
                [filterItems]="filterItems"
                [columnsGetter]="getColumns" 
                [columnsDefsGetter]="getColumnsDefs" 
                [total]="totalRows" 
                [observableRefresh]="subjectRefresh.asObservable()"
                [showRefreshButton]="true" 
                [busy]="busy" 
                [exportObj]="exportObj"></ecm-table>
        </div>
    </div> -->