<h4 translate>NOTE</h4>
<!-- Show only note text, after click on text show textarea element with note text or placeholder  [placeholder]="'Pridajte poznámku' . -->
<!--<div *ngIf="!isEditing">-->
  <!--<span *ngIf="note" (click)="isEditing = true">{{ note }}</span>-->
  <!--<span *ngIf="!note" (click)="isEditing = true" class="text-muted">K tomuto dopytu zatiaľ nie je pridaná žiadna poznámka.</span>-->
<!--</div>-->

<div *ngIf="!isNoteEditable">
  <span>{{ note }}</span>
</div>
<div *ngIf="isNoteEditable">
    <div>
      <textarea class="form-control" [(ngModel)]="note" rows="4" cols="40" [value]="note" title="{{'NOTE'|translate}}" style="width:100%"
        (ngModelChange)="onNoteChange()"
      ></textarea>
    </div>
    <div  *ngIf="isEditing"  style="text-align:right;">
      <button class="btn-primary text-right" style="margin-right: 10px;" (click)="updateNote()">
        <i class="fa fa-check-square-o"></i><a translate>SAVE</a>
      </button>
      <button class="btn-secondary text-right" style="margin-right: 10px;" (click)="cancelNote()">
        <i class="fa fa-close"></i><a translate>CANCEL_CHANGES</a>
      </button>
    </div>
</div>
