import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { map, pluck, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class WindowService {
    width$: Observable<number>;
    height$: Observable<number>;

    constructor() {
        const windowSize$ = new BehaviorSubject(getWindowSize());
        this.width$ = (windowSize$.pipe(pluck('width')) as Observable<number>).pipe(distinctUntilChanged());
        this.height$ = (windowSize$.pipe(pluck('height')) as Observable<number>).pipe(distinctUntilChanged());

        fromEvent(window, 'resize')
            .pipe(map(getWindowSize))
            .subscribe(windowSize$);
    }
}

function getWindowSize() {
    return {
        height: window.innerHeight,
        width: window.innerWidth
    };
}