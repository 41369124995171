import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';
import { SharedService } from '../../../../services/shared.service';
import { RfqsService } from '../../../../services/rfq/rfqs.service';
import { RfqJournalGridService } from '../../../../services/rfq/rfq.journal.grid.service';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

@Component({
    selector: 'rfq-detail-journal-dialog',
    template: `
        <div class="rfq-detail-journal-dialog row-container wider" *ngIf="params">
            <h3><i class="fa fa-history"></i> <span translate>JOURNAL</span> <span translate>DISCUSSION_TO_ROW</span> {{params.lineNumber}}</h3>   
            
            <ecm-table
                [dataGetter]="getJournal"
                [optionsGetter]="getJournalGridOptions"
                [columnsGetter]="getJournalColumns"
                [columnsDefsGetter]="getJournalColumnsDefs"
                [filterItems]="filterItems"
                [showRefreshButton]="true" 
                [total]="totalRows"
                [observableRefresh]="subjectRefresh.asObservable()"
                [secondaryHeaderColor]="true"
                [suppressToUrl]="true"
                [tableNamePrefix]="tablePrefix"
            ></ecm-table>
        </div>
        <div class="row-container">
            <button class="btn-secondary" (click)="dismiss()">
                <span translate>CLOSE</span>
            </button>
        </div>
    `,
})
export class RfqDetailJournalDialogComponent {
    doneSubject: Subject<any>;
    params: { rfqId: number, lineNumber: number };
    tablePrefix = 'JournalItem';

    busy: boolean;
    totalRows: number;
    personsCreated: any;
    subjectRefresh = new Subject();
    filterItems;

    constructor(
        private router: Router, 
        public bsModalRef: BsModalRef,
        public sharedService: SharedService,
        public rfqsService: RfqsService,
        public rfqJournalGridService: RfqJournalGridService) {
        this.getJournal = this.getJournal.bind(this);
        this.getJournalGridOptions = this.getJournalGridOptions.bind(this);
        this.getJournalFilterItems = this.getJournalFilterItems.bind(this);
        this.getJournalColumnsDefs = this.getJournalColumnsDefs.bind(this);
        this.getJournalColumns = this.getJournalColumns.bind(this);

        // bind to route change to close dialog
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.dismiss();
            });
    }

    getJournal(getterParams: any) {
        this.busy = true;
        let query: any = {
            skip: getterParams.startRow,
            top: getterParams.endRow - getterParams.startRow,
            lineNumber: this.params.lineNumber,
            orderBy: getterParams.orderBy.colId + ' ' + getterParams.orderBy.sort
        };
        if (getterParams.filter) { // because of suppressToUrl filters are here
            query = Object.assign(query, getterParams.filter);
        }

        this.rfqsService.getJournal(this.params.rfqId, query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                this.personsCreated = data.personsCreated;
                // this.assignedSellerNames = data.assignedSellerNames;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getJournalFilterItems(this.personsCreated);
                }
                getterParams.successCallback(data.rows, data.total);
                this.busy = false;
            }, err => {
                console.log(err);
                // also in error to be able to cancel filter
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getJournalFilterItems();
                }
                this.busy = false;
            });
    }
    getJournalGridOptions() {
        const options = this.rfqJournalGridService.getGridOptions(1, true, this.tablePrefix);
        options.columnDefs = options.columnDefs.filter((colDef: ColDef) => colDef.field !== 'lineNumber');
        return options;
    }
    getJournalFilterItems(personsCreated?) {
        return this.rfqJournalGridService.getFilterItems(personsCreated);
    }
    getJournalColumns() {
        const columns = this.rfqJournalGridService.getColumns(false, this.tablePrefix).filter(col => col.id !== 'lineNumber');
        return columns;
    }
    getJournalColumnsDefs() {
        const columnDefs = this.rfqJournalGridService.getColumnDefs().filter(colDef => colDef.field !== 'lineNumber');
        return columnDefs;
    }

    dismiss() {
        this.bsModalRef.hide();
    }
}
