import { Observable,  Subject } from 'rxjs';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
import { FormService } from '../form.service';
import { OrdersService } from '../order/orders.service';
import { CustomersService } from '../customer/customers.service';
import { RfqImportDetailErrorDialogComponent } from './../../components/rfqComponent/rfqDetailComponent/RfqImportDetailErrorDialogComponent/rfq.import.detail.error.dialog.component';
import { QuestionDialogService } from '../../components/questionDialogComponent/question.dialog.service';
import { OrderDialogService } from '@components/order/orderDialogComponent/order.dialog.service';
import { OrderDialogMode, OrderDialogResultState } from '@components/order/orderDialogComponent/order.dialog.model';
import { RfqsService } from '../../services/rfq/rfqs.service';
import { Rfq, rfqActions, RfqItem } from '../../model/rfq.model';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { PackingOption } from '@app/model/basket.model';

type ProductLookupItem = {
  brandCode: string, //"ZVL"
  defaultPacking: string, // "A"
  eCommProductId: string, // "1201 C3"
  packingOptions: PackingOption[], // [{packing: "A", amount: 1, priceCoefficient: 1}, {packing: "C", amount: 1, priceCoefficient: 1}]
  productName: string // "1201 C3"
};

@Injectable()
export class RfqService {
  bsModalRef: BsModalRef;
  subjectReloadCurrent: Subject<any>;
  rfq: Rfq;

  cachedPriceLists: any = {}; // map of cached price lists where key is customerId and value is array of price lists
  private lastProductLookupItems: {
    rfqProduct: ProductLookupItem[],
    rfqProductAS: ProductLookupItem[]
  } = {rfqProduct: [], rfqProductAS: []}; // save product lookup items from typeahead for packing options dropdown

  constructor( private modalService: BsModalService,
    private http: EcmHttpService,
    private sharedService: SharedService,
    private toastService: ToastService,
    private formService: FormService,
    private customersService: CustomersService,
    private questionDialogService: QuestionDialogService,
    private orderDialogService: OrderDialogService,
    private rfqsService: RfqsService,
    private router: Router) {
      this.subjectReloadCurrent = new Subject<any>();
    }

  /** Show modal window to confirm */
  public confirm(data: any): Observable<any> {
    this.bsModalRef = this.modalService.show(RfqImportDetailErrorDialogComponent, {
      class: 'rfq-import-detail-error-dialog-override modal-lg',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.doneSubject = new Subject<any>();
    this.bsModalRef.content.params = data;
    // console.log(data);
    return this.bsModalRef.content.doneSubject;
  }

  _t( text?: string ) {
    return this.sharedService.translateService.instant(text);
  }
  /**
     * Adds items to current rfq
     *
     * @param rfqId - RFQ id
     * @param items - array of items to be added
     */
    addToRfq(rfqId: number,
            items: {
              rfqProduct: string,
              amountRequired: string,
              unitPriceRequired?: string,
              brandCode: string,
              dateRequired?: string}[]
    ) {
      const this_ = this;
      items.forEach(function(item) {
        // const slovakDate = new RegExp(/(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})/g); // 22.04.2018
        // if (item.dateRequired && (item.dateRequired.match(slovakDate) !== null )) {
        // if ((item.dateRequired.match(/\./g) !== null && item.dateRequired.match(/\./g).length === 2) ||
        // (item.dateRequired.match(/\//g) !== null && item.dateRequired.match(/\//g).length === 2)) {
          if (item.dateRequired) {
            item.dateRequired = this_.formService.getDateStringFromIsoDate(item.dateRequired);
          }
        // }
      });
      return this.http.put(`/rfq/${rfqId}/items`, JSON.stringify({ toInsert: items, toUpdate: [], toDelete: [] }) ).pipe(map((data) => {
        if (data.toInsert.errors.length && data.toInsert.errors.length === 0) {
          this_.toastService.addSuccess('ADD_TO_ORDER_SUCCESS');
        } else {
          data.toInsert.errors.forEach(function (item) {
            for (const key in item.error) {
              if (item.error.hasOwnProperty(key)) {
                item.error[key] = item.error[key].toUpperCase().replace(/\ /g, '_') + '_ERROR';
                // if (item.error[key] === 'INVALID_DATA_TYPE:_DATEREQUIRED_ERROR') {
                //   item.row.dateRequired = '';
                // }
                // if (item.error[key] === 'INVALID_DATA_RANGE_FOR_RFQPRODUCT_ERROR') {
                //   item.row.rfqProduct = '';
                // }
                // if (item.error[key] === 'INVALID_DATA_RANGE_FOR_UNITPRICEREQUIRED_ERROR') {
                //   item.row.unitPriceRequired = '';
                // }
                // if (item.error[key] === 'INVALID_DATA_RANGE_FOR_AMOUNTREQUIRED_ERROR') {
                //   item.row.amountRequired = '';
                // }
                // if (item.error[key] === 'LESS_THAN_MINIMUM_OR_MORE_THAN_MAXIMUM_DELIVERY_DATE_ERROR') {
                //   item.row.dateRequired = '';
                // }
              }
            }
          });
        }
          return data;
      } ));
  }

    updateRfqNote(rfqId: number, note: string) {
        return this.http.put(`/rfq/${rfqId}/header`, JSON.stringify({ rfqNote: note }));
    }

    updateRfq(rfqId: number, data: any) {
      return this.http.put(`/rfq/${rfqId}/header`, JSON.stringify(data));
    }

    getQuestionnaire(rfqId: number) {
      return this.http.get(`/rfq/${rfqId}/questionnaire`);
    }

    updateQuestionnaire(rfqId: number, data: any) {
      return this.http.put(`/rfq/${rfqId}/questionnaire`,
        JSON.stringify({
          customerName: (data.customerName) ? data.customerName : null,
          customerLocality: (data.customerLocality) ? data.customerLocality : null,
          customerSize: (data.customerSize) ? data.customerSize : null,
          segment: (data.segment) ? data.segment : null,
          application: (data.application) ? data.application : null,
          competition: (data.competition) ? data.competition : null,
          repeatProbability: (data.repeatProbability) ? data.repeatProbability : null,
          notes: (data.notes) ? data.notes : null
        }
      ));
    }

  // used for typeahead
  public getProductNamesByString(value: string, attr: 'rfqProduct' | 'rfqProductAS'): Observable<string[]> {
    const query = {
      'skip': 0,
      'top': 20,
      'productName.operator': 'likeBoth',
      'productName.value': typeof value === 'undefined' ? '100' : value,
      'columns': 'eCommProductId,productName,brandCode,defaultPacking',
      'addPackingOptions': true
    };

    return this.http.get(`/products-lookup`, this.http.prepareOptions(query)).pipe(
      map((lookupItems: ProductLookupItem[]) => {
        this.lastProductLookupItems[attr] = lookupItems;
        return lookupItems.map(item => item.productName);
      })
    );
  }

  public getLastProductLookupItem(productName: string, attr: 'rfqProduct' | 'rfqProductAS'): ProductLookupItem {
    return this.lastProductLookupItems[attr].find(item => item.productName === productName);
  }

  /**
     * Returns observable of reload - used to reload rfq items grid
     */
    getReloadCurrentSubject(): Subject<any> {
      return this.subjectReloadCurrent;
  }

  /**
     * Exports detail items
     */
    exportDetail(id, query) {
      const url = `/rfq/` + id + `/items`;
      return this.http.get(url, this.http.prepareOptions(query));
  }

  /**
   * Returns array of pricelists for dropdown in rfq detail
   */
  getPriceLists(customerId: number) {
    return new Observable(observer => {
      if (this.sharedService.user.representative === 'customer') { // if customer we already have price lists
        observer.next([
          {
            id: null, name: this.sharedService.translateService.instant('GENERAL_PROJECT_PRICE_LIST'),
            priceListCode: this.sharedService.user.representsCustomer.priceListCode,
            priceListCurrency: this.sharedService.user.representsCustomer.priceListCurrency
          },
          ...this.sharedService.user.representsCustomer.projectPriceLists.map(item => Object.assign({}, item, { name: item.priceListName }))
        ]);
        observer.complete();
      }
      if (this.sharedService.user.representative === 'AS') { // we need to get price lists from server or cache on this service
        if (this.cachedPriceLists[customerId]) {
          observer.next(this.cachedPriceLists[customerId]);
          observer.complete();
        } else {
          this.customersService.getCustomer(customerId).subscribe(result => {
            this.cachedPriceLists[customerId] = [
              {
                id: null, name: this.sharedService.translateService.instant('GENERAL_PROJECT_PRICE_LIST'),
                priceListCode: result.priceListCode,
                priceListCurrency: result.priceListCurrency
              },
              ...result.projectPriceLists.map(item => Object.assign({}, item, { name: item.priceListName }))
            ];
            observer.next(this.cachedPriceLists[customerId]);
            observer.complete();
          });
        }
      }
    });
  }

  public orderItem(rfqId: number, rfqItem: RfqItem, reload?: Subject<any>): void {
    if (!rfqItem.rfqProductAS) {
      this.rfqsService.rfqItemsAction(rfqId, [rfqItem.id.toString()], 'ORDER_ITEM', 
        {orderNumberCustomer: '', orderNote: ''})
      .subscribe(() => {
        this.toastService.addError('RFQ_ITEM_CANT_BE_ORDERED', 10000);
      });
    } else {
      this.orderItemAndCloseRfq(rfqId, [rfqItem], false, reload);
    }
  }

/**
 * Order item, after that can be RFQ closed or not. Show modal notifications based on following conditions / states
 *
 * STATE 1: compare itemState-s, if nothing to order - show toast with message
 * STATE 2: if any itemValidity has invalid date (its date after) show warning about it in toast and after confirm go to 3
 * STATE 3: compare if items to order has rfqProductAS, if yes show modal and allow order otherwise show toast with error and send notif to AS
 * STATE 4: show other modal (comment ... etc.)
 *
 * @param {any} rowsData
 * @param {boolean} closeRfqAfter
 * @return {void}
 */
  public orderItemAndCloseRfq(rfqId: number, rowsData?: RfqItem[], closeRfqAfter?: boolean, reload?: Subject<any>): void {
    const now = moment().utc().format('x');

    if (rowsData.filter(row => row.itemState === 'A').length === 0) { // STATE 1
      this.toastService.addError(`RFQ_NOTHING_TO_ORDER_NOTIFY`);
    } else { // STATE 2
      if (rowsData.filter( row => {
          const val = moment(row.itemValidity).utc().format('x');
          return (now > val) && (row.itemState === 'A'); 
        }).length
      ){
        // show info about items to order after itemValidity date
        this.questionDialogService.confirm({
          message: this._t('RFQ_ITEM_VALIDITY'), question: this._t('RFQ_ITEM_VALIDITY_CANT_BE_ORDERED'),
          primary: this._t('CONTINUE'), secondary: this._t('CANCEL'), suppressQuestionMark: true,
        }).subscribe(result => { // STATE 3 and on confirm STATE 4
          if (result === 'confirm') {
            this.orderAndCloseModalOrNotifyAP(rfqId, rowsData, closeRfqAfter, reload);
          }
        });
      } else { // STATE 3 and on confirm STATE 4
        this.orderAndCloseModalOrNotifyAP(rfqId, rowsData, closeRfqAfter, reload);
      }
    }
  }

  private orderAndCloseModalOrNotifyAP(rfqId: number, rowsData: RfqItem[], closeRfqAfter?: boolean, reload?: Subject<any>): void {
    if (rowsData.filter(row => row.itemState === 'A').length ===
        rowsData.filter(row => row.itemState === 'A' && (row.rfqProductAS !== null && row.rfqProductAS !== '')).length
    ){
      // every product marked with A (na objednanie) state was identified in rfqProductAS, allow this order
      if (closeRfqAfter === false) {
        // on click action ORDER, show comments to order in modal
        this.orderDialogService.confirm({
          mode: OrderDialogMode.Details,
          data: { showOrderComment: true, orderComment: '', orderNumberCustomer: '', orderNote: ''}}).subscribe( res => {
            if (res.state === OrderDialogResultState.Order) {
              this.rfqsService.rfqItemsAction(rfqId, [ rowsData[0].id.toString() ] , 'ORDER_ITEM',
                {discussionText: res.data.orderComment.toString(), orderNumberCustomer: res.data.orderNumberCustomer, orderNote: res.data.orderNote})
              .subscribe(() => {
                  this.toastService.addSuccess(`RFQ_ORDER_ITEM_DONE`);
                  // this.router.navigate([`/${this.sharedService.appSettings.language}/rfq/` + rfqId]);
                  reload.next();
                  },
                  err => { console.log(err) });
          }
        });

      } else {
        this.questionDialogService.confirm({
          message: this._t('RFQ_ORDER_AND_CLOSE'),
          question: this._t('RFQ_WILL_BE_ORDERED'),
          primary: this._t('CONTINUE'),
          secondary: this._t('CANCEL'),
          suppressQuestionMark: true}).subscribe( result => {
          if (result === 'confirm') {
            this.orderDialogService.confirm({
              mode: OrderDialogMode.Details, data: { showOrderComment: true, orderComment: '', orderNumberCustomer: '', orderNote: ''}}
              ).subscribe( res => {
                if (res.state === OrderDialogResultState.Order) {
                    this.doRfqAction(rfqId, 'ORDER_AND_CLOSE_RFQ',
                    {discussionText: res.data.orderComment.toString(), orderNumberCustomer: res.data.orderNumberCustomer, orderNote: res.data.orderNote});
                  }
              });
          }
        });
      }
    } else {
        // STATE 4 - stop order and inform client about notifying AP "there are some unidentified products"
        this.doRfqAction(rfqId, 'ORDER_AND_CLOSE_RFQ', {discussionText: '', orderNumberCustomer: '', orderNote: ''});
        this.toastService.addError(`RFQ_CANT_BE_ORDERED_BUT_NOTIFY`, 20000);
    }
  }

  public doRfqAction(rfqId: number, action: string, extraData?: any): void {
    this.rfqsService.rfqAction(rfqId, action, extraData)
        .subscribe(() => {
            this.toastService.addSuccess(`RFQ_${action}_DONE`);
            this.router.navigate([`/${this.sharedService.appSettings.language}/rfqs`]);
          },
          err => { console.log(err)
        });
  }

}
