import { Injectable, APP_INITIALIZER } from '@angular/core';
import { EcmHttpService, EcmHttpQueryParams } from '@services/http/ecm.http.service';
import { SharedService } from '@services/shared.service';
import { Rfq, RfqToInsert, RfqItemsResponse, RfqItem } from '@model/rfq.model';
import { switchMap, map, filter } from 'rxjs/operators';
import { Subject , Observable, of} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { LocalNumberPipe } from '@app/locale.pipes.module';
import { PackingOption } from '@app/model/basket.model';
import { PrfqsResponse, PrfqStates, PrfqItemsResponse, PrfqItemStates } from '@app/model/prfq.model';

@Injectable()
export class PrfqsItemsService {
    subjectReloadCurrent: Subject<any>;

    constructor(
        private http: EcmHttpService,
        private sharedService: SharedService,
        private translateService: TranslateService,
    ) {
        // this.subjectReloadCurrent = new Subject<any>();
    }

    // public getPurchaseRfqsItems(query: EcmHttpQueryParams): Observable<PrfqItemsResponse> {
    //     return this.http.get('/prfq-items', this.http.prepareOptions(query));
    // }

    // public exportPurchaseRfqsItems(query: EcmHttpQueryParams): Observable<{exportUrl: string}> {
    //     return of({ exportUrl: ''
    //     });
    // }

    // public getPrfqItems(prfqId: number, query: EcmHttpQueryParams): Observable<PrfqItemsResponse> {
    //     return this.http.get(`/prfq/${prfqId}/items`, this.http.prepareOptions(query));
    // }

    // public exportDetail(prfqId: number, query: EcmHttpQueryParams): Observable<PrfqItemsResponse> {
    //     return this.http.get(`/prfq/${prfqId}/items`, this.http.prepareOptions(query));
    // }

}
