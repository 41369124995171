import { Injectable } from '@angular/core';
import { EcmHttpQueryParams, EcmHttpService } from '../http/ecm.http.service';
import { switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { ToastService } from '../toastService/toast.service';
import { QuestionDialogService } from '../../components/questionDialogComponent/question.dialog.service';
import { TableService } from '../table.service';
import { DeliveryOrdersService } from '../deliveryOrder/delivery.orders.service';
import { OrdersItemsService } from './orders.items.service';
import { Order } from '../../model/order.model';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridTranslateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridActionsRendererComponent, GridActionsRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridActionsRendererComponent/grid.actions.renderer.component';
import { GridRowPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowPriceRendererComponent/grid.row.price.renderer.component';
import { GridRowWeightRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowWeightRendererComponent/grid.row.weight.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridOnDeliveryOrderRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridOnDeliveryOrderRendererComponent/grid.on.delivery.order.renderer.component';
import { GridOrderItemDeliveryDateHistoryRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridOrderItemDeliveryDateHistoryRendererComponent/grid.order.item.delivery.date.history.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import * as FileSaver from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes, TableFilterItemValue } from '@app/model/table.filter.model';
import { Representative } from '@app/model/user.model';
import { PersonCreated } from '@model/personCreated.model';
import { ColDef } from 'ag-grid-community';

interface GetOrdersResponse {
    rows: Order[],
    total: number,
    personCreatedSYS: PersonCreated[]
}
@Injectable()
export class OrdersService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

    constructor(
        private http: EcmHttpService,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private toastService: ToastService,
        private questionDialogService: QuestionDialogService,
        private tableService: TableService,
        private deliveryOrdersService: DeliveryOrdersService,
        private ordersItemsService: OrdersItemsService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
        this.export = this.export.bind(this);
        this.exportAndSendByEmail = this.exportAndSendByEmail.bind(this);
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    /**
     * Loads list of orders
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    public getOrders(query): Observable<GetOrdersResponse> {
        const url = this.sharedService.hasPermission('orders', 'GET') ? `/orders` : `/orders-reduced`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => {
            const orders: Order[] = [];
            data.rows.forEach(function (order) {
                orders.push(
                    new Order(
                        order.id,
                        order.orderNumberCustomer,
                        order.orderNumberEComm,
                        order.orderNumberERP,
                        order.orderState,
                        order.totalWeight ? order.totalWeight : 0,
                        order.totalPrice,
                        order.currency,
                        order.orderCreated,
                        order.fullName,
                        order.sortName,
                        '/order/' + order.id,
                        order.orderNote,
                        order.shippingType,
                        order.paymentCondition,
                        order.customerId,
                        order.customerName,
                        order.authorizedSellerId,
                        order.authorizedSellerName,
                        order.postsCount
                    )
                );
            });

            const personCreatedSYS: PersonCreated[] = data.personCreatedSYS.map(person => ({
                id: person.userId.toString(),
                name: person.fullName,
                customerId: person.customerId
            }));
            return { rows: orders, total: data.totalCount, personCreatedSYS: personCreatedSYS };
        }, error => {
            console.error(error);
        }));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        const url = this.sharedService.hasPermission('orders', 'GET') ? `/orders` : `/orders-reduced`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports detail items
     */
    exportDetail(orderId, query) {
        if (!query.hasOwnProperty('amountOrdered.value')) {
            query['amountOrdered.operator'] = 'ne';
            query['amountOrdered.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountOrdered.operator'];
            delete query['amountOrdered.value'];
        }
        const url = (this.sharedService.hasPermission('orders', 'GET') ? `/orders/` : `/orders-reduced/`) + orderId + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports order with given id to given format file with name containing given orderNumberEcomm
     * @param ids - array of ids to be exported, if null export all
     * @param invoiceNumber - number of order to be exported
     * @param format - format to be exported
     * @param suppressFirstPartOfFileName - true if not to include first part of file name - to use only orderNumberEcomm value for file name
     */
    export(ids: number[], orderNumberEcomm: string, format: string, suppressFirstPartOfFileName?: boolean) {
        const { url, query } = this.getParamsForExport(ids, format);

        this.http.get(url, this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl, { responseType: 'text' }))
        ).subscribe(data => {
            const mediaType = 'text/csv';
            // we are adding UTF8 Byte order mark for diacritics - '\ufeff'
            const blob = new Blob(['\ufeff' + data], { type: mediaType });
            const filename = (suppressFirstPartOfFileName ? '' : (this.translateService.instant('ORDER') + '_')) + orderNumberEcomm + '.csv';
            FileSaver.saveAs(blob, filename);
        }, err => console.log(err));
    }

    exportAndSendByEmail(ids: number[], orderNumberEcomm: string, format: string, email: string): Observable<void> {
        const { url, query } = this.getParamsForExport(ids, format);
        query.emailAddress = email;
        query.emailSubject = `${orderNumberEcomm}.csv`;
        query.emailFilename = query.emailSubject;
       
        return this.http.get(url, this.http.prepareOptions(query));
    }

    getParamsForExport(ids: number[], format: string): { url: string, query: EcmHttpQueryParams } {
        const columns = this.tableService.filterSuppressExportColumns(
            ['authorizedSeller', 'customerName', 'orderNumberEComm', 'orderCreated', 'orderNumberCustomer', 'personCreated', 'eCommProductId',
                'productName', 'brandCode', 'deliveryDate', 'packing', 'itemNote', 'dateChangedSYS', 'currency', 'itemState', 'weight',
                'totalWeight', 'amountOrdered', 'unitPrice', 'totalPrice'],
            'orders', format);
        let query: EcmHttpQueryParams = {
            exportToFormat: format,
            exportColumns: columns,
            orderBy: 'orderNumberEComm ASC'
        }
        if (ids) {
            query['id.operator'] = 'in';
            query['id.value'] = ids.join(',');
        } else {
            query = Object.assign(this.sharedService.lastOrdersSummaryQueryParams, query);
            delete query.page;
        }
        // Custom endpoint for AP5 users
        let url: string;
        if (this.isAs5AsUser()) {
            url = `/orders-items-custom`;
            query.exportColumns = [...query.exportColumns, 'productCode', 'assignedSellerName'];
        } else {
            url = `/orders-items`;
        }
        return { url, query };
    }

    /**
     * Updates order state
     * @param orderId - id of order
     * @param orderState - state to set
     */
    updateOrderState(orderId, orderState) {
        const subject = new Subject<any>();
        this.questionDialogService.confirm({
            message: 'UPDATE_ORDER_STATUS',
            question: 'QUESTION_UPDATE_ORDER_STATUS_' + orderState,
            primary: 'YES',
            secondary: 'NO',
        }).subscribe(
            res => {
                if (res === 'confirm') {
                    this.http.put(`/orders/` + orderId + `/update-state`, JSON.stringify({ orderState: orderState }))
                        .subscribe((data) => {
                            this.toastService.addSuccess('CHANGES_APPLIED_SUCCESSFULLY');
                            subject.next();
                        });
                }
            }
        );
        return subject;
    }

    public getOrderStateRenderColors(): {[color: string]: string} {
        return {
            gray: 'X',
            red: 'W',
            blue: 'N',
            yellow: 'C',
            green: 'P',
            black: 'D'
        };
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            { id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30 },
            { id: 'orderNumberEComm', name: 'ORDER_ID', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            ...(this.sharedService.user.availableAuthorizedSellers.length > 1 ? [{
                id: 'authorizedSellerName', name: 'AUTHORIZED_SELLER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            ...(this.sharedService.user.availableCustomers.length > 1 ? [{
                id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110
            }] : []),
            { id: 'orderNumberCustomer', name: 'ORDER_INTERNAL_NUMBER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'orderCreated', name: 'DATE_OF_ISSUE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'sortName', name: 'PERSON_ISSUED', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'totalWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
            ...(!this.sharedService.hasPermission('orders-reduced', 'GET') ? [
                { id: 'totalPrice', name: 'TOTAL_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 }
            ] : [] ),
            { id: 'orderState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'orderNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
            ...(!this.sharedService.hasPermission('orders-reduced', 'GET') ? [
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
            ] : [])
        ];

        original = this.tableService.filterSuppressColumns(original, 'orders');
        const restored = this.sharedService.user.preferences['ordersTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getOrdersGridOptions(startPage: number) {
        const this_ = this;

        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'ordersTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        const this_ = this;
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences['ordersSelection'], observable: this_.getSelectedSubject(),
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this_.sharedService.user.preferences['ordersSelection'],
                    observable: this_.getItemsSelectedSubject()
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNumberEComm').name),
                field: 'orderNumberEComm',
                width: this_.getColumn('orderNumberEComm').width,
                cellRendererFramework: GridLinkRendererComponent,
            },
            ...(this.sharedService.user.availableAuthorizedSellers.length > 1 ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('authorizedSellerName').name),
                    field: 'authorizedSellerName',
                    width: this_.getColumn('authorizedSellerName').width,
                    cellStyle: { 'text-align': 'left' }
                }
            ] : []),
            ...(this.sharedService.user.availableCustomers.length > 1 ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('customerName').name),
                    field: 'customerName',
                    width: this_.getColumn('customerName').width,
                    cellStyle: { 'text-align': 'left' }
                }
            ] : []),
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNumberCustomer').name),
                field: 'orderNumberCustomer',
                width: this_.getColumn('orderNumberCustomer').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderCreated').name),
                field: 'orderCreated',
                width: this_.getColumn('orderCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('sortName').name),
                field: 'sortName',
                width: this_.getColumn('sortName').width,
                valueGetter: 'data.fullName',
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('totalWeight').name),
                field: 'totalWeight',
                width: this_.getColumn('totalWeight').width,
                minWidth: 80,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: { addDecimalZero: true, decimals: 3 }
            },
            ...(!this.sharedService.hasPermission('orders-reduced', 'GET') ? [
                {
                    headerName: this_.translateService.instant(this_.getColumn('totalPrice').name),
                    field: 'totalPrice',
                    width: this_.getColumn('totalPrice').width,
                    minWidth: 80,
                    // cellStyle: {'text-align': 'right'},
                    // valueGetter: '(data.totalPrice? data.totalPrice : 0) + ' €''
                    cellRendererFramework: GridPriceRendererComponent
                }
            ] : []),
            {
                headerName: this_.translateService.instant(this_.getColumn('orderState').name),
                field: 'orderState',
                width: this_.getColumn('orderState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'STATETOOLTYP_',
                    renderColors: this.getOrderStateRenderColors()
                }
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('orderNote').name),
                field: 'orderNote',
                width: this_.getColumn('orderNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this_.getColumn('orderNote').checked,
            },
        ];
        // Actions
        if(!this.sharedService.hasPermission('orders-reduced', 'GET')) {
            const actionsCellRendererParams: GridActionsRendererComponentParams<Order> = {
                export: this.export,
                exportAndSendByEmail: this.exportAndSendByEmail,
                namePartGetter: function (data) { return data.orderNumberEComm; },
                showConfirm: data => data.orderState !== 'P',
                showDecline: data => data.orderState !== 'D',
                confirm: (this_.sharedService.user.representative === 'AS') && this_.sharedService.hasPermission('orders/*/update-state', 'PUT') ? function (params) {
                    this_.updateOrderState(params.data.id, 'P').subscribe(response => {
                        params.data.orderState = 'P';
                        params.api.redrawRows();
                    });
                } : undefined,
                decline: (this_.sharedService.user.representative === 'AS') && this_.sharedService.hasPermission('orders/*/update-state', 'PUT') ? function (params) {
                    this_.updateOrderState(params.data.id, 'D').subscribe(response => {
                        params.data.orderState = 'D';
                        params.api.redrawRows();
                    });
                } : undefined,
                confirmTitle: this_.translateService.instant('CONFIRM_ORDER'),
                declineTitle: this_.translateService.instant('DECLINE_ORDER'),
                showComments: true,
                itemArea: 'orders', idAttrName: 'id', loadForEachItem: true,
                showOnlyEmptyChildTableKeyItems: true,
                showSendCsvByEmail: this_.sharedService.user.representative === Representative.AS &&
                    this_.sharedService.params.features.order.sendCsvByEmail,
            };
            colDefs.push(
                {
                    headerName: this_.translateService.instant(this_.getColumn('actions').name),
                    field: 'actions',
                    width: this_.getColumn('actions').width,
                    minWidth: this_.getColumn('actions').width,
                    cellRendererFramework: GridActionsRendererComponent,
                    cellRendererParams: actionsCellRendererParams,
                    sortable: false,
                }
            );
        }

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'orders');
        return colDefs;
    }

    public getFilterItems(personsCreated: PersonCreated[]): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            { id: 'orderNumberEComm', name: 'ORDER_ID', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'orderNumberCustomer', name: 'ORDER_INTERNAL_NUMBER', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'orderNote', name: 'NOTE', type: TableFilterItemTypes.text, maxLength: 100, value: '', operator: TableFilterItemOperators.likeBoth },
            { 
                id: 'orderCreated', 
                name: 'DATE_OF_ISSUE', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', valueTo: '',
                showYearPicker: true
            },
            {
                id: 'personCreatedSYS', 
                name: 'PERSON_ISSUED', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: personsCreated,
                updateValuesOnFilterMicroChange: (changedItem) => this.tableService.updatePersonCreatedFilter(changedItem, personsCreated),
            },
            {
                id: 'orderState', name: 'STATUS', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: this.getOrderStateRenderColors(),
                values: this.tableService.filterSuppressStates([
                    { id: 'X', name: 'STATETOOLTYP_X', dotId: 'X', default: true },
                    { id: 'W', name: 'STATETOOLTYP_W', dotId: 'W', default: true },
                    { id: 'N', name: 'STATETOOLTYP_N', dotId: 'N', default: true },
                    { id: 'C', name: 'STATETOOLTYP_C', dotId: 'C', default: true },
                    { id: 'P', name: 'STATETOOLTYP_P', dotId: 'P', default: false },
                    { id: 'D', name: 'STATETOOLTYP_D', dotId: 'D', default: false }
                ], 'orderState'),
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['X', 'W', 'N', 'C']
                }
            },
        ];
        // additional filters for users that have more customers or authorized sellers - superuser, seller etc.
        if (this.sharedService.user.availableAuthorizedSellers.length > 1) {
            filterItems.unshift({ id: 'authorizedSeller.id', name: 'AUTHORIZED_SELLER', type: TableFilterItemTypes.multiselect, value: '', values: this.sharedService.user.availableAuthorizedSellers });
        }
        if (this.sharedService.user.availableCustomers.length > 1) {
            filterItems.unshift({ 
                id: 'customer.id', 
                name: 'CUSTOMER', 
                type: TableFilterItemTypes.multiselect, 
                search: true, 
                value: '', 
                values: this.sharedService.user.availableCustomers.map(customer => ({...customer, id: customer.id.toString()}))
            });
        }

        filterItems = this.tableService.filterSuppressColumns(filterItems, 'orders');
        return filterItems;
    }

    /**
     * Loads details of order
     *
     * @param orderNumberEComm - identificator of order in ecommerce
     */
    getOrderDetails(orderId: string) {
        return this.http.get((this.sharedService.hasPermission('orders', 'GET') ? `/orders/` : `/orders-reduced/`) + orderId + '/header').pipe(map((data) => {
            let order: Order;
            order = new Order(
                data.id,
                data.orderNumberCustomer,
                data.orderNumberEComm,
                data.orderNumberERP,
                data.orderState,
                data.totalWeight,
                data.totalPrice,
                data.currency,
                data.orderCreated,
                data.personCreated,
                data.sortName,
                data.url,
                data.orderNote,
                data.shippingType,
                data.paymentCondition,
                data.customerId,
                data.customerName,
                data.authorizedSellerId,
                data.authorizedSellerName,
                data.postsCount
            );

            return order;
        }));
    }

    /**
     * Loads list of products added to order
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getProductsOfOrder(orderId, query): Observable<any> {
        if (!query.hasOwnProperty('amountOrdered.value')) {
            query['amountOrdered.operator'] = 'ne';
            query['amountOrdered.value'] = 0;
        } else { // delete if in query because we want all items
            delete query['amountOrdered.operator'];
            delete query['amountOrdered.value'];
        }
        const url = (this.sharedService.hasPermission('orders', 'GET') ? `/orders/` : `/orders-reduced/`) + orderId + `/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    getProductsOfOrderColumns(forceOriginal?: boolean) {
        let original = [
            { id: 'brandCode', name: 'BRAND', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'productName', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
            ...(!this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [
                { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 }
            ] : []),
            { id: 'amountOrdered', name: 'ORDERED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'amountOpened', name: 'OPENED_AMOUNT', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'amountReserved', name: 'RESERVED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'amountDelivered', name: 'DELIVERED', checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
            { id: 'amountInvoiced', name: 'INVOICED', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 },
            { id: 'packing', name: 'PACKING', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 75 },
            ...(!this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [
                { id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'rowPrice', name: 'TOTAL_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'openedPrice', name: 'OPENED_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ] : []),
            { id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'itemNote', name: 'SPECIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 100 }
        ];

        original = this.tableService.filterSuppressColumns(original, 'orderItems');
        const restored = this.sharedService.user.preferences['orderProductsOfOrderTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getProductsOfOrderColumn(colId) {
        const columns = this.getProductsOfOrderColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    getProductsOfOrderGridOptions(startPage: number) {
        const this_ = this;

        function headerClassFunc(params) {
            return 'bkg-gray-darker';
        }
        function yellowHeaderClassFunc(params) {
            return 'bkg-secondary fnt-primary';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'orderProductsOfOrderTablePageSize', headerClassFunc);

        gridOptions.columnDefs = this_.getProductsOfOrderColumnDefs();
        return gridOptions;

    }

    getProductsOfOrderColumnDefs() {
        const this_ = this;
        let colDefs: any[] = [];
        colDefs = colDefs.concat(
            [
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('brandCode').name),
                    field: 'brandCode',
                    minWidth: 70,
                    width: this_.getProductsOfOrderColumn('brandCode').width,
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('productName').name),
                    field: 'productName',
                    width: this_.getProductsOfOrderColumn('productName').width,
                    minWidth: 110,
                    cellStyle: { 'text-align': 'left' }
                }
            ],
            !this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [
                {
                    headerName: '',
                    field: 'postsCount',
                    maxWidth: 50,
                    minWidth: 50,
                    cellRendererFramework: GridCommentRendererComponent,
                    cellRendererParams: { itemArea: 'orders', idAttrName: 'orderId' },
                    width: this_.getProductsOfOrderColumn('postsCount').width,
                }
            ] : [],
            [
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('amountOrdered').name),
                    field: 'amountOrdered',
                    width: this_.getProductsOfOrderColumn('amountOrdered').width,
                    minWidth: this_.getProductsOfOrderColumn('amountOrdered').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('amountOpened').name),
                    field: 'amountOpened',
                    width: this_.getProductsOfOrderColumn('amountOpened').width,
                    minWidth: this_.getProductsOfOrderColumn('amountOpened').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('amountReserved').name),
                    field: 'amountReserved',
                    width: this_.getProductsOfOrderColumn('amountReserved').width,
                    minWidth: this_.getProductsOfOrderColumn('amountReserved').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('amountDelivered').name),
                    field: 'amountDelivered',
                    width: this_.getProductsOfOrderColumn('amountDelivered').width,
                    minWidth: this_.getProductsOfOrderColumn('amountDelivered').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridOnDeliveryOrderRendererComponent,
                    cellRendererParams: { onDeliveryOrderGetter: this_.deliveryOrdersService.getProductOnDeliveryOrders }
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('amountInvoiced').name),
                    field: 'amountInvoiced',
                    width: this_.getProductsOfOrderColumn('amountInvoiced').width,
                    minWidth: this_.getProductsOfOrderColumn('amountInvoiced').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridNumberRendererComponent,
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('packing').name),
                    field: 'packing',
                    width: this_.getProductsOfOrderColumn('packing').width,
                    minWidth: 110,
                    maxWidth: 200,
                    cellRendererFramework: GridTranslateRendererComponent,
                    cellRendererParams: { translatePrefix: 'PACKING_' },
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('rowWeight').name),
                    field: 'rowWeight',
                    width: this_.getProductsOfOrderColumn('rowWeight').width,
                    minWidth: 80,
                    cellRendererFramework: GridRowWeightRendererComponent,
                    cellRendererParams: { amountColId: 'amountOrdered' },
                }
            ],
            !this.sharedService.hasPermission('orders-items-reduced', 'GET') ? [
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('unitPrice').name),
                    field: 'unitPrice',
                    minWidth: 80,
                    width: this_.getProductsOfOrderColumn('unitPrice').width,
                    cellStyle: { 'text-align': 'right' },
                    cellRendererFramework: GridPriceRendererComponent
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('rowPrice').name),
                    field: 'rowPrice',
                    width: this_.getProductsOfOrderColumn('rowPrice').width,
                    minWidth: 80,
                    cellRendererFramework: GridRowPriceRendererComponent,
                    cellRendererParams: { amountColId: 'amountOrdered' },
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('openedPrice').name),
                    field: 'openedPrice',
                    minWidth: 80,
                    width: this_.getColumn('openedPrice').width,
                    cellRendererFramework: GridPriceRendererComponent
                }
            ] : [],
            [
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('deliveryDate').name),
                    field: 'deliveryDate',
                    width: this_.getProductsOfOrderColumn('deliveryDate').width,
                    minWidth: 110,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridOrderItemDeliveryDateHistoryRendererComponent,
                    cellRendererParams: { getOrderItemDeliveryDateHistory: this_.ordersItemsService.getOrderItemDeliveryDateHistory }
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('itemState').name),
                    field: 'itemState',
                    width: this_.getProductsOfOrderColumn('itemState').width,
                    suppressSizeToFit: true,
                    cellRendererFramework: GridStateRendererComponent,
                    cellRendererParams: { tooltipPrefix: 'ORDER_ITEM_STATE_' },
                },
                {
                    headerName: this_.translateService.instant(this_.getProductsOfOrderColumn('itemNote').name),
                    field: 'itemNote',
                    width: this_.getProductsOfOrderColumn('itemNote').width,
                    minWidth: 80,
                    cellStyle: { 'text-align': 'left' }
                }
            ]
        );

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'orderItems');
        return colDefs
    }

    getProductsOfOrderFilterItems() {
        let filterItems = [
            { id: 'amountOrdered', title: 'SHOW_ZERO_AMOUNTS', type: 'checkbox', value: '0', operator: 'ne', checked: false }
        ];
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'orderItems');
        return filterItems;
    }

    // AP5
    private isAs5AsUser(): boolean {
        const user = this.sharedService.user;
        return user.representative === Representative.AS && user.representsAuthorizedSeller.id === '5';
    }
}
