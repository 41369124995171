export interface ImportErrorColumnDef {
    attrName: string,
    header: string,
    type: 'string' | 'number' | 'decimal' | 'date',
    isValid: (val: any) => boolean,
    getMinDate?: (row: any) => Date
}

export interface ImportErrorRow {
    row: any, // data row object, {"rfqProduct":"_1200","amountRequired":"100","brandCode":"ZVL","unitPriceRequired":"100","dateRequired":"32.12.2020","minDeliveryDate":"2020-09-10T00:00:00.000Z"}
    error: {[attrName: string]: string}, // {"dateRequired":"Invalid data type: dateRequired"}
    [attrName: string]: any // "dateRequired":"32.12.2020"
}

export interface ImportErrorDialogResult {
    rows: any[]
}

export enum ImportModes {
    INSERT = 'INSERT',
    UPDATE = 'UPDATE'
}
