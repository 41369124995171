<div class="row-container wider" *ngIf="isUserRoleSelectAvailable()">
    <app-rfq-user-role-select
        (onRoleChange)="onRoleChange()"
    ></app-rfq-user-role-select>
</div>

<div class="row-container wider">

    <div fxLayout="row" class="tab-container">
        <div fxFlex="50" fxLayout="row">
            <span class="row-container clickable" routerLink="/{{sharedService.appSettings.language}}/rfqs">
                <span translate>RFQS_SUMMARY</span>
            </span>
            <span class="row-container tab-active" style="margin-right: 5px;">
                <span translate>RFQS_ITEMS</span>
            </span>
        </div>

        <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
            <button permissionHide perm="rfq" action="POST" class="btn-primary" (click)="createNew()">
                <a translate>RFQ_CREATE</a>
            </button>
        </div>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table 
            [dataGetter]="getRfqItems" 
            [optionsGetter]="getGridOptions" 
            [filterItems]="filterItems" 
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs" 
            [total]="totalRows" 
            [observableRefresh]="subjectRefresh.asObservable()"
            [showRefreshButton]="true" 
            [busy]="busy" 
            [resetFilter$]="resetFilter$"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>