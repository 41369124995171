import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Animations } from '@app/animations';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-note',
    templateUrl: 'note.component.html',
    styleUrls: [
        './note.component.scss'
    ],
    animations: [Animations.slideInOut]
})
export class NoteComponent implements OnInit, OnDestroy {
    @Input() note: string;
    @Input() isEditable: boolean;
    @Input() onSaveHandler: (string) => Observable<void>;
    @Output() onEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    public _note: string;
    private oldNote: string;
    public isEditing = false;
    private subscriptions: Subscription[] = [];

    public ngOnInit(): void {
        this._note = this.note;
        this.oldNote = this.note;
    }

    public save(): void {
        this.subscriptions.push(this.onSaveHandler(this._note).subscribe(() => {
            this.oldNote = this._note;
            this.isEditing = false;
            this.onEdited.emit(false);
        },
        error => {
            console.log(error);
        }));
    }

    public cancelEdit(): void {
        this._note = this.oldNote;
        this.isEditing = false;
        this.onEdited.emit(false);
    }

    public onNoteChange(): void {
        if (!this.isEditing) {
            this.isEditing = true;
            this.onEdited.emit(true);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
