import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { Journal } from '../../model/journal.model';
import { LanguageService } from '../../services/language.service';
import { LocalNumberPipe } from '../../locale.pipes.module';
import { map } from 'rxjs/operators';

@Injectable()
export class JournalService {
    
    constructor (private http: EcmHttpService, 
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /**
     * Loads list of journal
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getJournal (query) {
        var this_ = this;
        let url = `/journal`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => { 
            var journal: Journal[] = [];
            data.rows.forEach(function(journalItem){
                journal.push(
                    new Journal(
                        journalItem.authorizedSellerId,
                        journalItem.authorizedSellerName,
                        journalItem.customerId,
                        journalItem.customerName,
                        journalItem.userId,        
                        journalItem.dateCreated,
                        journalItem.journalValue,
                        journalItem.typeCode,   
                        journalItem.typeName,   
                        journalItem.priority,   
                        journalItem.process,    
                        journalItem.fullName,     
                        journalItem.sortName
                    )
                );
            })

            var userId = data.userId.filter(person => person.userId > 0).map(person => {return {id: person.userId, name: person.fullName};});
            var authorizedSellers = data.authorizedSellers.map(seller => {return {id: seller.id, name: seller.authorizedSeller};});
            var customers = data.customers.map(customer => {return {id: customer.id, name: customer.customerName};});

            return {rows: journal, total: data.totalCount, userId: userId, authorizedSellers: authorizedSellers, customers: customers};
        }));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        let url = `/journal`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

