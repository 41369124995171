<div class="row-container wider">    
    <div class="area-title">
        <h3><i class="fa fa-briefcase"></i><span translate>SUPPLIERS_ADMINISTRATION</span></h3>
        <button class="btn-primary" routerLink="/{{sharedService.appSettings.language}}/supplier/0">
            <i class="fa fa-plus"></i>
            <span translate>ADD</span>
        </button>
    </div>

    <div fxLayout="column">
        <ecm-table
            [dataGetter]="getSuppliers" 
            [optionsGetter]="getGridOptions"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [observableRefresh]="refreshGrid$.asObservable()"
            [showRefreshButton]="true"
            [exportObj]="exportObj"></ecm-table>
    </div>
</div>
