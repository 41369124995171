import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from '../table.service';
import { SharedService } from '../shared.service';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';

@Injectable()
export class InvoiceDetailGridService {


	constructor (private router: Router,
                 private activatedRoute: ActivatedRoute,
                 private translateService: TranslateService,
                 private sharedService: SharedService,
                 private tableService: TableService) {
    }

    getColumns(forceOriginal?: boolean):any[] {
        let original = [
            {id: 'productCode', name: "PRODUCT_CODE", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'productName', name: "PRODUCT_NAME", checked: true, orderBy: true, orderDirection: 'ASC', width: 100},
            {id: 'amount', name: "INVOICE_AMOUNT", checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'unit', name: "INVOICE_UNIT", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'unitPrice', name: "INVOICE_UNIT_PRICE", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'totalPrice', name: "INVOICE_PRICE_WITHOUT_TAX", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'totalWeight', name: "INVOICE_WEIGHT_BRUTTO", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'itemNote', name: "SPECIFICATION", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'packing', name: "PACKING", checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
        ];

        original = this.tableService.filterSuppressColumns(original, 'invoiceItems');
        let restored = this.sharedService.user.preferences['invoiceTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId){
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].id == colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getGridOptions(startPage:number) {
        let this_ = this;

        function headerClassFunc(params) {
            return 'bkg-gray-darker';
        }

        let gridOptions = this.tableService.getDefaultGridOptions(startPage, 'invoiceTablePageSize', headerClassFunc);
        gridOptions.columnDefs = this_.getColumnDefs();
        return gridOptions;
    }

    getColumnDefs() {
        let this_ = this;
        let colDefs: any =  [
            {
                headerName: this_.translateService.instant(this_.getColumn('productCode').name), 
                field: "productCode",
                width: this_.getColumn('productCode').width,
                suppressSizeToFit: true, 
                cellStyle: {'text-align': 'left'}
            },  
            {
                headerName: this_.translateService.instant(this_.getColumn('productName').name), 
                field: "productName",
                width: this_.getColumn('productName').width,
                suppressSizeToFit: true, 
                cellStyle: {'text-align': 'left'}
            },       
            {
                headerName: this_.translateService.instant(this_.getColumn('amount').name), 
                field: "amount",
                width: this_.getColumn('amount').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent
            },    
            {
                headerName: this_.translateService.instant(this_.getColumn('unit').name), 
                field: "unit",
                width: this_.getColumn('unit').width,
                suppressSizeToFit: true, 
                cellStyle: {'text-align': 'right'}
            },  
            {
                headerName: this_.translateService.instant(this_.getColumn('unitPrice').name), 
                field: "unitPrice",
                width: this_.getColumn('unitPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('totalPrice').name), 
                field: "totalPrice",
                width: this_.getColumn('totalPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('totalWeight').name), 
                field: "totalWeight",
                width: this_.getColumn('totalWeight').width,
                cellStyle: {'text-align': 'right'},
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: true, decimals: 3}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('itemNote').name), 
                field: "itemNote",
                width: this_.getColumn('itemNote').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this_.translateService.instant(this_.getColumn('packing').name), 
                field: "packing",
                width: this_.getColumn('packing').width,
                cellStyle: {'text-align': 'left'},
                valueGetter: function ageNowValueGetter(params) {
                    return params.data.packing ? this_.translateService.instant('PACKING_' + params.data.packing) : '';
                }
            },
        ];
        colDefs = this.tableService.filterSuppressColumns(colDefs, 'invoiceItems');
        return colDefs
    }

    getFilterItems(authorizedSellers: {id: string, name: string}[], personsIssued: {id: string, name: string}[]) {
        return [];
    }

}

