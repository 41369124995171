import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RfqCloseQuestionnaireDialogComponentParams } from './rfq-close-questionnaire.dialog.component';
import { RfqCloseQuestionnaire } from '@app/model/rfq.model';
import { RfqCloseQuestionnaireDialogComponent } from './rfq-close-questionnaire.dialog.component';

@Injectable()
export class RfqCloseQuestionnaireDialogService {
    bsModalRef: BsModalRef

    constructor(
        private modalService: BsModalService
    ) { }

    public show(params: RfqCloseQuestionnaireDialogComponentParams): Observable<RfqCloseQuestionnaire | null> {
        this.bsModalRef = this.modalService.show(RfqCloseQuestionnaireDialogComponent,
            { keyboard: false, ignoreBackdropClick: true});

        this.bsModalRef.content.doneSubject = new Subject<RfqCloseQuestionnaire | null>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
