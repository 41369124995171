import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { WindowService } from '@services/window.service';
import { PriceListService } from '../price-list.service';

export interface PriceListImportResult {
    priceListCode: string;
    currency: string;
    note?: string;
    rows: {[key: string]: string}[]
}

enum ComponentModes {
    WithProductCode, WithBrandCodeAndProductName
}
@Component({
    selector: 'app-price-list-import',
    templateUrl: 'price-list-import.component.html',
    styleUrls: ['./price-list-import.component.scss'],
})
export class PriceListImportComponent implements OnInit, OnDestroy {
    public modalClosed$: Subject<any>;
    public maxHeight: number;
    public priceListCode: string;
    public currency: string;
    public note: string;
    public currencies: { id: string, name: string }[];
    public defaultCurrencySelection = { id: null, name: 'PLEASE_SELECT_OPTION' };
    public rowAttrs: string[];
    public importHelp1Text: string;
    private componentMode: ComponentModes;
    private subscriptions: Subscription[] = [];
    // private importedPriceListData: {[key: string]: string}[] = [];

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 27) { // escape
            this.modalClosed$.next({});
            this.thisModal.hide();
        }
    }

    constructor(
        public thisModal: BsModalRef,
        public translate: TranslateService,
        private sharedService: SharedService,
        private windowService: WindowService,
        private priceListService: PriceListService
    ) {
        this.subscriptions.push(this.windowService.height$.subscribe((value: any) => {
            this.maxHeight = value - 160;
        }));

        this.currencies = this.sharedService.priceListCurrencies.map(currency =>
            ({ id: currency, name: currency }));
        this.currencies = [
            this.defaultCurrencySelection,
            ... this.currencies
        ];

        this.componentMode = this.priceListService.isColumnHidden('productCode')
            ? ComponentModes.WithBrandCodeAndProductName : ComponentModes.WithProductCode;

        if (this.componentMode === ComponentModes.WithBrandCodeAndProductName) {
            this.rowAttrs = ['brandCode', 'productName'];
            this.importHelp1Text = 'PRICE_LIST_IMPORT_HELP_1_3';
        } else {
            this.rowAttrs = ['productCode'];
            this.importHelp1Text = 'PRICE_LIST_IMPORT_HELP_1_2';
        }
        this.rowAttrs.push('unitPrice');
    }

    public ngOnInit(): void {
    }

    public onCurrencySelected(currencyItem): void {
        this.currency = currencyItem.selected.id;
    }

    public onDataReady(data: {[key: string]: string}[]): void {
        data = data.map(row => Object.assign({}, row, {
            unitPrice: row.unitPrice 
                ? row.unitPrice.replace(',', '.').replace(' ', '')
                : '0'
        }));
        this.close(data);
    }

    public close(data?: {[key: string]: string}[]): void {
        const result: PriceListImportResult = data
            ? {
                priceListCode: this.priceListCode,
                currency: this.currency,
                note: this.note,
                rows: data
            }
            : null;
        this.modalClosed$.next(result);
        this.thisModal.hide();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
