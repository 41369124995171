import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Animations } from '../../../../animations';
import { RfqQuestionnaire, rfqQCustomerSize, rfqQRepeatProbability } from '../../../../model/rfq.questionnaire.model';
import { Rfq } from '../../../../model/rfq.model';
import { RfqService } from '../../../../services/rfq/rfq.service';
import { ToastService } from '../../../../services/toastService/toast.service';

@Component({
    selector: 'app-rfq-detail-questionnaire',
    templateUrl: 'rfq.detail.questionnaire.component.html',
    styleUrls: [
        './rfq.detail.questionnaire.component.scss'
    ],
    animations: [Animations.slideInOut]
})
export class RfqDetailQuestionnaireComponent implements OnInit {
    @Input() rfq: Rfq;
    @Input() makeCollapsible: boolean;    // Set Header and Body for collapsible component (after click on header then body will collapse
    @Input() readOnly: boolean;           // buttons to save and cancel are not shown if true
    @Input() isCollapsed;                 // set collapsed state for this element
    @Output() isCollapsedChange = new EventEmitter();       // emit collapsed state for this element
    @Output() filledMandatory = new EventEmitter();
    // Emits boolean when edited or changed
    @Output() edited: EventEmitter<boolean> = new EventEmitter<boolean>();

    customerSizeList: string[] = rfqQCustomerSize;
    repeatProbabilityList: string[] = rfqQRepeatProbability;
    questionnaire: RfqQuestionnaire = new RfqQuestionnaire();
    questionnaireOld: RfqQuestionnaire = new RfqQuestionnaire();
    isChanged = false;

    constructor(
        private rfqService: RfqService,
        private toastService: ToastService
    ) {
    }

    ngOnInit() {
        this.makeCollapsible = this.makeCollapsible ? this.makeCollapsible : false;
        this.isCollapsed = this.isCollapsed ? this.isCollapsed : false;
        this.getQuestionnaireData();
    }

    onElementChange(event: any) {
        this.isChanged = true;
        this.edited.emit(this.isChanged);
        this.isFilledMandatory();
    }

    resetForm() {
        this.questionnaire = Object.assign({}, this.questionnaireOld);
        this.isChanged = false;
        this.edited.emit(this.isChanged);

        this.isFilledMandatory();
    }
    submitForm(form) {
        this.rfqService.updateQuestionnaire(this.rfq.id, this.questionnaire).subscribe(
            next => {
                this.toastService.addSuccess(`RFQ_DETAIL_QUESTIONNAIRE_UPDATED`);
                this.isChanged = false;
                this.edited.emit(this.isChanged);
                this.getQuestionnaireData();
            },
            error => {
                console.log(error);
                this.toastService.addError(`RFQ_DETAIL_QUESTIONNAIRE_UPDATE_ERROR`);
            }
        );
    }

    toggleCollapse() {
        this.isCollapsed = (this.makeCollapsible) ? !this.isCollapsed : null;
        this.isCollapsedChange.emit(this.isCollapsed);
    }
    isFilledMandatory() {
        let result = false;
        const application = this.questionnaire ? this.questionnaire.application : '';
        const customerSize = this.questionnaire ? this.questionnaire.segment : '';
        const repeatProbability = this.questionnaire ? this.questionnaire.repeatProbability : '';
        if (this.isDefined(application) && this.isDefined(customerSize) && this.isDefined(repeatProbability)) {
            result = true;
        }
        this.filledMandatory.emit(result);
        return result;
    }

    isDefined(text: string) {
        return typeof text !== 'undefined' && text !== null && text !== 'null' && text.length > 0;
    }

    getQuestionnaireData() {
        this.rfqService.getQuestionnaire(this.rfq.id).subscribe(
            data => {
                data.repeatProbability = data.repeatProbability === 'null' ? null : data.repeatProbability;
                data.customerSize = data.customerSize === 'null' ? null : data.customerSize;
                this.questionnaire = Object.assign({}, data);
                this.questionnaireOld = Object.assign({}, data);
                this.isFilledMandatory();
            },
            error => console.log(error)
        );
    }
}
