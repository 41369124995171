<div class="row-container" *ngIf="params">
	<div *ngIf="params.successCount > 0">
		<h4 class="fnt-green"><span translate>DISTRIBUTOR_STORES_INSERT_TO_STORE_SUCCESS</span>: {{params.successCount}}</h4>
	</div>

	<div style="margin-top: 10px" *ngIf="params.errors.length > 0">
		<div fxLayout="row">
			<h4 class="fnt-orange"><span translate>DISTRIBUTOR_STORES_INSERT_TO_STORE_ERROR</span>: {{params.errors.length}}</h4>

			<button class="btn-secondary" style="min-width: 200px; margin-left: 10px;" (click)="copyErrorsToClipboard()">
				<i class="fnt-primary fa fa-clipboard"></i>
				<span translate>COPY_ERRORS_TO_CLIPBOARD</span>
			</button>
		</div>

		<div style="display: block; overflow-y: auto; min-height: 240px;"  [ngStyle]="{'max-height.px': maxHeight - (this.params.successCount > 0 ? 30 : 0)}">
			<div fxLayout="row">
				<div *ngIf="params.showBrandCode" fxFlex="33" style="display: inline-block; margin-right: 10px;  margin-left: 7px;" translate>BRAND</div>
				<div [fxFlex]="params.showBrandCode ? 33 : 50" style="display: inline-block; margin-right: 10px;  margin-left: 7px;" translate>PRODUCT_ID</div>
				<div [fxFlex]="params.showBrandCode ? 33 : 50" style="display: inline-block; margin-right: 10px;  margin-left: 1px;" translate>NUMBER_OF_PCS</div>
			</div>

			<div fxLayout="row" *ngFor="let item of params.errors; let odd=odd; let even=even;" [ngStyle]="{'background-color': even ? '#eee' : '#fff'}">
				
				<div *ngIf="params.showBrandCode" [fxFlex]="33" style="display: inline-block; margin-right: 10px;  margin-left: 5px;"
					[ngClass]="{'fnt-orange': item.error.brandCode}">
					<input type="text" style="margin: 4px 0;" class="form-control" 
						[ngClass]="{'fnt-orange': item.error.brandCode || item.error.branECommProductId}"
						[(ngModel)]="item.row.brandCode" (ngModelChange)="onChange(item, 'brandCode')">
					<div style="margin-top: -5px;">
						<span style="font-size: 75%;" translate>{{item.error.brandCode}}</span>
						<span style="font-size: 75%;" translate>{{item.error.branECommProductId}}</span>
					</div>
				</div>

				<div [fxFlex]="params.showBrandCode ? 33 : 50" style="display: inline-block; margin-right: 10px;  margin-left: 5px;" [ngClass]="{'fnt-orange': item.error.eCommProductId}">
					<input type="text" style="margin: 4px 0;" class="form-control" 
						[ngClass]="{'fnt-orange': item.error.eCommProductId || item.error.branECommProductId}" 
						[(ngModel)]="item.row.eCommProductId" (ngModelChange)="onChange(item, 'eCommProductId')">
					<div *ngIf="item.error.eCommProductId || item.error.branECommProductId" style="margin-top: -5px;">
						<span style="font-size: 75%;" translate>{{item.error.eCommProductId}}</span>
						<span style="font-size: 75%;" translate>{{item.error.branECommProductId}}</span>
					</div>
				</div>

				<div [fxFlex]="params.showBrandCode ? 33 : 50" style="display: inline-block; margin-right: 10px;">
					<!-- <input type="text" style="margin: 4px 0;" class="form-control" [ngClass]="{'fnt-orange': item.error.amount}" [(ngModel)]="item.row.amount" (ngModelChange)="onChange(item, 'amount')"> -->

					<number-input name="amount" #amount
						[(ngModel)]="item.row.amount"
						(ngModelChange)="onChange(item, 'amount')"
						[inputType]="'number'"
						[ngClass]="{'fnt-orange': item.error.amount}"
						class="number-input-as-basket-error"
					></number-input>
					<div style="margin-top: -2px;" [ngClass]="{'fnt-orange': item.error.amount}">
						<span style="font-size: 75%;"><span translate>{{item.error.amount}}</span></span>
					</div>
					<div style="margin-bottom: 2px">
						<span *ngIf="item.row.amount && item.row.amount.length == 0" class="fnt-orange" style="font-size: 75%;" translate>INSERT_TO_BASKET_AMOUNT_MISSING</span>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div style="padding-top: 15px;">
		<button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
	        <span translate>CLOSE</span>
	    </button>
	    <button class="btn-primary" style="float: right" (click)="addToBasketAgain()" [disabled]="!somethingChanged" [ngClass]="{'ec-disabled': !somethingChanged}">
	    	<i class="fa fa-plus"></i>
	        <span translate>DISTRIBUTOR_STORES_IMPORT_AGAIN</span>
	    </button>
	</div>