import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Attachment, AttachmentUploadObj, AttachmentDownloadObj } from '../../model/attachment.model';
import { SharedService } from '../../services/shared.service';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { EcmHttpService } from '../../services/http/ecm.http.service'
import * as FileSaver from 'file-saver';
import * as mime from 'mime';  // !!! node_modules/mime/index.js has to be edited and added .json to both 'require' !!!
import { Subject, Observable } from 'rxjs';
import { Animations} from '../../animations';

export interface AttachmentPrivateConfig {
    dropdownText: {
        title: string,
        privateText: string,
        publicText: string
    },
    iconTitle: string,
    buttonWarning: string
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'attachments',
  templateUrl: 'attachment.component.html',
  styleUrls:  ['./attachment.component.scss'],
  animations: [Animations.slideInOut]
})
export class AttachmentComponent implements OnInit, AfterViewInit {
    @Input() entityId?: number;          // optional entity ID (if entity already exists)
    @Input() attachmentsGetter: (id: number) => Observable<Attachment[]>; // function to provide Attachment[]
    @Input() attachmentsGetterWithoutEntity: any[]; // function to provide Attachment[]
    @Input() getUploadUrl: (fileName: string, privateItem?: boolean) => Observable<AttachmentUploadObj>; // function to provide {url, s3Key}; returns observable
    @Input() getDownloadUrl: (attachmentId: number, attachmentChildId?: number) => Observable<AttachmentDownloadObj>;   // function to provide {url}; returns observable
    @Input() deleteAttachment: (entityId: number, attachmentId: number, attachmentChildId?: number) => Observable<void>; // function delete the specified attachment; returns observable
    @Input() allowEdit: boolean;         // edit = add a new attachment or delete existing attachment
    @Input() reload: Subject<any>;       // optional reload subject - reloads attachments when next() is called on this from outside, when initialize passed as attr, load as if first time
    @Input() makeCollapsible: boolean;   // Set Header and Body for collapsible component (after click on header then body will collapse
    @Input() isModal: boolean;           // If true, show attachments wil be use in modal
    @Input() bsModalRef: any;        // call this function to close modal
    @Input() isCollapsed;                 // set collapsed state for this element
    @Input() hideRowLabel: boolean;                 // set collapsed state for this element
    @Input() public showPrivateControls?: boolean; // if to show buttons and icons related to private attachments
    @Input() public privateConfig?: AttachmentPrivateConfig; // more info in a case showPrivateControls === true
    @Output() isCollapsedChange = new EventEmitter();       // emit collapsed state for this element
    @Output() attachmentS3Keys = new EventEmitter<string[]>();  // outputs an array of new attachment's S3 keys
    @Output() attachmentsChanged = new EventEmitter<any>();  // calls this function when attachments list was changed with a number of attachments as a param

    @ViewChild('fileInput') fileInputElement: ElementRef;
    uploader: FileUploader = new FileUploader({
        url: '',
        disableMultipart: true
    });

    attachments: Attachment[] = [];
    reloadSubscribtion;
    extraFileParams: any = {};
    isAddDialogOpen = false;
    busy = false;
    oldAttachments;
    public isNewAttachmentPrivate = null;

    constructor(
        public sharedService: SharedService,
        private http: EcmHttpService
    ) {
        this.uploader.onAfterAddingFile = this.onFileAdded.bind(this);
        this.uploader.onSuccessItem = this.onUploadSuccess.bind(this);
        this.uploader.onErrorItem = this.onUploadError.bind(this);
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    ngOnInit() {
        this.makeCollapsible = this.makeCollapsible ? this.makeCollapsible : false;
        this.isCollapsed = this.isCollapsed ? this.isCollapsed : false;
    }
    ngAfterViewInit() {
        this.loadAttachments();
        if (this.reload) {
            this.reloadSubscribtion = this.reload.subscribe(result => {
                if (result && result.initialize) {
                    this.loadAttachments();
                } else {
                    this.setAttachments(true);
                }
            })
        }
    }

    public toggleCollapse(): void {
        this.isCollapsed  = (this.makeCollapsible) ? !this.isCollapsed : null;
        this.isCollapsedChange.emit(this.isCollapsed);
        this.isNewAttachmentPrivate = null;
    }

    setAttachments(withPreloader?: boolean) {
        const this_ = this;

        if (!this.attachmentsGetterWithoutEntity && !this.entityId) { // this.attachments = [];
            return;
        }
        if (this.attachmentsGetterWithoutEntity && !this.entityId) {
            this.attachmentsChanged.emit(this.attachments.length);
            return this.attachments;
        }
        if (withPreloader) {
            this_.busy = true;
          this.tryGetAttachments(1000);
        }
    }

    /** set attachments  */
    loadAttachments() {
        if (!this.attachmentsGetter && this.attachmentsGetterWithoutEntity) {
            this.attachments = this.attachmentsGetterWithoutEntity;
            this.attachmentsChanged.emit(this.attachments.length);
        }
        if (this.attachmentsGetter) {
            this.attachmentsGetter(this.entityId).subscribe(attachments => {
                this.attachments = attachments;
                this.attachmentsChanged.emit(this.attachments.length);
            });
        }
    }

    /** Get attachments and compare array length with items count in oldAttachments.
     * same value is without update, different value means update which leads to setAttachments
     * if same value, try wait 3000ms and try again, repeat next 2 times  [0, 3000 , 6000]ms
     * */
    tryGetAttachments(timeOut?: number) {
        this.busy = true;

        setTimeout( () => {
            this.attachmentsGetter(this.entityId).subscribe(attachments => {
                // console.log(attachments.length !== this.attachments.length);
                // console.log(attachments);
                if (attachments.length !== this.attachments.length) {
                    this.attachments = attachments;
                    this.busy = false;
                    this.attachmentsChanged.emit(attachments.length);
                } else {
                    if (timeOut < 8000) {
                        this.tryGetAttachments(timeOut + 3000);
                    } else {
                        this.busy = false;
                    }
                }
            });
        }, timeOut);
    }

    openAddDialog() {
        this.isAddDialogOpen = true;
    }

    public closeAddDialog(): void {
        this.isAddDialogOpen = false;
        this.isNewAttachmentPrivate = null;
    }

    /**
     * File was added to the uploader queue - upload it immediatelly
     */
    onFileAdded(fileItem: FileItem) {
        fileItem.withCredentials = false;
        fileItem.method = 'PUT';
        // file content type without mime
        // fileItem.headers = [{ name: 'Content-Type', value: fileItem._file.type }];
        fileItem.headers = [{ name: 'Content-Type', value: mime.getType(fileItem._file.name) }];
        this.getUploadUrl(fileItem._file.name, this.isNewAttachmentPrivate)
        .subscribe(result => {  // {url, s3Key}
            this.extraFileParams[fileItem._file.name] = result;
            this.attachmentS3Keys.emit(Object.keys(this.extraFileParams).map(fileName => this.extraFileParams[fileName].s3Key));

            this.uploader.setOptions({  url: result.url })

            this.uploader.uploadItem(fileItem);
            
        });
    }

    onUploadSuccess(fileItem: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        // console.log(`upload success, fileItem=${fileItem}, response=${response}, status=${status}`);
        this.setAttachments(true);
        this.uploader.removeFromQueue(fileItem);
        this.fileInputElement.nativeElement.value = '';

        if (this.attachmentsGetterWithoutEntity) {
            this.attachments.push(new Attachment(
                this.sharedService.user.firstName + ' ' + this.sharedService.user.lastName,
                (new Date()).toISOString(),
                0,
                fileItem._file.name,
                null,
                null,
                this.extraFileParams[fileItem._file.name].s3Key
                ));
        }
    }

    onUploadError(fileItem: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
        console.log(`upload error, fileItem=${fileItem}, response=${response}, status=${status}`);
    }

    /**
     * Downloads the attachment
     */
    download(attachment: Attachment) {
        this.getDownloadUrl(attachment.id, attachment.childId)
        .subscribe(downloadUrlObj => {
            this.http.getLocal(downloadUrlObj.url, { responseType: 'blob' })
            .subscribe(data => {
                const blob = new Blob([data], { type: mime.getType(attachment.fileName) });
                FileSaver.saveAs(blob, attachment.fileName);
            }, error => {
                console.log(error);
            });
        })
    }

    /**
     * Deletes an existing attachment (with already set entityId)
     */
    deleteExisting(attachment: Attachment) {
        // check if exists attachmentsGetter and attachmentGetterWithoutEntity. If first is empty, delete only attachments item
        // otherwise call output this.deleteAttachment
        if (!this.attachmentsGetter && this.attachmentsGetterWithoutEntity) {
            // remove attachment from this.attachments
            const newAttachments: any[] = [];
            this.attachments.forEach((element, index) => {
                if ( element.dateCreated !== attachment.dateCreated) {
                    newAttachments.push(element);
                }
            });
            this.attachments = newAttachments;

            // remove S3Keys from extraFileParams
            if (this.extraFileParams[attachment.fileName].s3Key === attachment.s3) {
                delete this.extraFileParams[attachment.fileName]
            }
            this.attachmentS3Keys.emit(Object.keys(this.extraFileParams).map(fileName => this.extraFileParams[fileName].s3Key));
            this.attachmentsChanged.emit(this.attachments.length);
        } else if (this.attachmentsGetter) {
            this.deleteAttachment(this.entityId, attachment.id, attachment.childId)
            .subscribe(
                () => {
                    this.loadAttachments();
                    // this.attachmentsChanged.emit(0);  // we do not know the number yet
                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    /**
     * Deletes an attachment in the uploader queue (without entityId set)
     * It only removes it from the queue and let the attachment garbage collector to delete the DB record and the S3 file
     */
    deleteNew(fileItem: FileItem) {
        this.uploader.removeFromQueue(fileItem);
    }

    ngOnDestroy() {
        
        if (this.reloadSubscribtion) {
            this.reloadSubscribtion.unsubscribe();
        }
    }

}
