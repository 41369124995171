import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridDateRendererComponent } from '@components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridPriceRendererComponent } from '@components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { PriceListService } from '../price-list.service';
import { AppAreas } from '@app/model/appArea.model';

@Injectable()
export class PriceListsItemsGridService extends BaseGridService {
    constructor (
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private priceListService: PriceListService
    ) {
        super(tableService, sharedService);
    }

    protected getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['price-lists-items'], //'price-lists-items',
            gridNameCamel: 'priceListsItems',
            columnList: [
                { id: 'priceListCode', name: 'CUSTOMER_PRICE_LIST_CODE', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
                { id: 'brandCode', name: 'BRAND', checked: true, orderBy: false, orderDirection: 'ASC', width: 60 },
                { id: 'productName', name: 'PRODUCT_ID', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'productCode', name: 'PRODUCT_CODE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'personChangedERP', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'dateChangedSYS', name: 'DATE_OF_CREATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 }
            ].filter(item => !this.priceListService.isColumnHidden(item.id))
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: this.translateService.instant(this.getColumn('priceListCode').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'priceListCode',
                width: this.getColumn('priceListCode').width,
                minWidth: this.getColumn('priceListCode').width,
                cellStyle: {'text-align': 'left', 'font-weight': 'bold'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('brandCode').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'brandCode',
                width: this.getColumn('brandCode').width,
                minWidth: this.getColumn('brandCode').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productName').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'productName',
                width: this.getColumn('productName').width,
                minWidth: this.getColumn('productName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('productCode').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'productCode',
                width: this.getColumn('productCode').width,
                minWidth: this.getColumn('productCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPrice').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'unitPrice',
                width: this.getColumn('unitPrice').width,
                minWidth: this.getColumn('unitPrice').width,
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: {
                    currencyColumnName: 'currency'
                },
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('personChangedERP').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'personChangedERP',
                width: this.getColumn('personChangedERP').width,
                minWidth: this.getColumn('personChangedERP').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateChangedSYS').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute
                },
                headerClass: this.headerClassFunc,
                field: 'dateChangedSYS',
                width: this.getColumn('dateChangedSYS').width,
                minWidth: this.getColumn('dateChangedSYS').width,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            }
        ];

        return this.tableService.filterSuppressColumns(
            colDefs.filter(item => !this.priceListService.isColumnHidden(item.field)),
            this.getGridServiceOptions().gridNameCamel);
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            {
                id: 'priceListCode', name: 'CUSTOMER_PRICE_LIST_CODE', type: TableFilterItemTypes.text,
                maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'currency', name: 'CURRENCY', type: TableFilterItemTypes.multiselect,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    {id: 'CZK', name: 'CZK'},
                    {id: 'EUR', name: 'EUR'},
                    {id: 'PLN', name: 'PLN'},
                    {id: 'USD', name: 'USD'},
                    {id: 'UAH', name: 'UAH'},
                ]
            },
            ...(
                this.sharedService.brands.length > 1 ? [{
                    id: 'brandCode', name: 'BRAND', type: TableFilterItemTypes.multiselect,
                    allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                    values: this.sharedService.brands.map(brand => ({ id: brand.code, name: brand.code }))
                }] : []
            ),
            {
                id: 'productName', name: 'PRODUCT_ID', type: TableFilterItemTypes.text,
                maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'priceList.dateChangedSYS', name: 'DATE_OF_CREATION', type: TableFilterItemTypes.dateRange,
                valueFrom: '', valueTo: ''
            }
        ]

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

}
