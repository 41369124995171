import { Component, HostListener, ViewContainerRef, ChangeDetectorRef, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Invoice } from '../../../model/invoice.model';
import { SharedService } from '../../../services/shared.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { LanguageService } from '../../../services/language.service';
import { InvoicesService } from '../../../services/invoice/invoices.service';
import { InvoiceDetailGridService } from '../../../services/invoice/invoice.detail.grid.service';
import * as moment from 'moment';
import { QuestionDialogService } from '../../../components/questionDialogComponent/question.dialog.service';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
import { ExportObj } from '@app/model/table.model';

@Component({
  selector: 'invoice-detail',
  templateUrl: 'invoice.detail.component.html'
})
export class InvoiceDetailComponent  { 
	invoice: 		Invoice;

	totalRows:          number;
	filterItems: 		any[];
	subjectRefresh = new Subject<any>();
	public exportObj: ExportObj;

	busy:				boolean;

	constructor(private cdRef: ChangeDetectorRef,
				private router: Router, 
				private route: ActivatedRoute,
				public sharedService: SharedService,
				private languageService: LanguageService,
				private invoicesService: InvoicesService,
				private invoiceDetailGridService: InvoiceDetailGridService,
				private questionDialogService: QuestionDialogService,
				private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {

		this.subjectRefresh = new Subject<any>();

		this.getInvoiceItems = this.getInvoiceItems.bind(this);
		this.getGridOptions = this.getGridOptions.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.getColumnsDefs = this.getColumnsDefs.bind(this);
	}

	ngOnInit() {
		this.invoicesService.getInvoice(this.route.snapshot.params['id']).subscribe(data => {
			this.invoice = data;

			let exportFunction = function(query) {
				return this.invoicesService.exportDetail(this.invoice.id, query);
			};
			this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
				this.exportObj = {
					fileName: this.sharedService.translateService.instant('INVOICE') + '_' + this.invoice.invoiceNumber, 
					export: exportFunction.bind(this)
				};
			});
        }, err => {
        	console.log(err)
        });

		var this_ = this;
		setTimeout(function() {
			this_.sharedService.navigateWithOrderby(this_.getColumns(),
				this_.route.snapshot.queryParams, this_.sharedService.lastInvoiceDetailQueryParams);
		}, 0);
	}

	getInvoiceItems(params) {
		var this_ = this;
		this.busy = true;
		var query: any = {
			skip: params.startRow, 
			top: params.endRow - params.startRow,
			orderBy: this.route.snapshot.queryParams['orderBy']
		};
		
		this.sharedService.lastInvoiceDetailQueryParams = {};
		for (var key in this.route.snapshot.queryParams) {
        	this.sharedService.lastInvoiceDetailQueryParams[key] = this.route.snapshot.queryParams[key];
        	if (key != 'page') {
				query[key] = this.route.snapshot.queryParams[key]
			}
        }

        if (query.skip >= 0 && query.top > 0) {
			this.invoicesService.getInvoiceItems(this.route.snapshot.params['id'], query)
			.subscribe(data => {
				this.totalRows = data.totalCount;
				if (!this.filterItems) { // only if not already to not loose fererence of selected
					this.filterItems = this.getFilterItems();
				}
				params.successCallback(data.rows, data.total);
				this.busy = false;
	        }, err => {
	        	console.log(err);
	        	this.busy = false;
	        });
		}
	}
	getGridOptions() {
		return this.invoiceDetailGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
	}
	getFilterItems() {
		return this.invoiceDetailGridService.getFilterItems([], []);
	}
	getColumns() {
		return this.invoiceDetailGridService.getColumns();
	}
	getColumnsDefs() {
		return this.invoiceDetailGridService.getColumnDefs();
	}

	scrollToComments() {
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#newComment' });
	}
}