<div class="btn-group" dropdown [isOpen]="isOpen" 
  (onShown)="show()" (onHidden)="hide()" [autoClose]="true" placement="bottom left"
  *ngIf="items.length > 0">
  <button #dropdownToggle id="single-button" type="button" class="btn btn-secondary" dropdownToggle>
    <i class="fa fa-cogs"></i><span translate>ADMINISTRATION</span><i class="fa fa-caret-down caret"></i>
  </button>
  <div #popover *dropdownMenu role="menu" aria-labelledby="single-button" class="dropdown-menu dropdown-menu-left">
    <div class="row-container dropdown-item clickable" 
      *ngFor="let item of items" (click)="onSelect(item)">
      <span><i [ngClass]="item.class"></i> <span> {{item.name | translate}}</span></span>
    </div>
  </div>
</div>
