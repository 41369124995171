<div class="row-container" *ngIf="params">
	<h4 class="fnt-primary"><span translate>NEWS_READ_DETAIL_TITLE</span></h4>

	<ecm-table [dataGetter]="getList" 
				[filterItems]="filterItems"			
				[optionsGetter]="getGridOptions"
				[columnsGetter]="getColumns"
				[columnsDefsGetter]="getColumnsDefs"
				[total]="totalCount"
				[busy]="busy"
				[suppressToUrl]="true"
				[tableNamePrefix]="'NewsReadDetail'"
				[showRefreshButton]="true"
				[exportObj]="exportObj"></ecm-table>

	<div style="padding-top: 15px;">
		<button class="btn-secondary" (click)="bsModalRef.hide()" style="width: 100px;">
			<span translate>CLOSE</span>
		</button>
	</div>
</div>