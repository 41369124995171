
<span dropdown placement="bottom right" (onToggle)="toggled($event)">
	<div class="btn-animated" id="search-dropdown" dropdownToggle>
		<i class="fa" [ngClass]="{'fa-search': !dropdownVisible, 'fa-close': dropdownVisible}"></i>
		<span translate>SEARCH</span><span>...</span>
		<div class="underline"></div>
	</div>
	<div  class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu" aria-labelledby="search-dropdown">
	    <div class="input-container">
            <input #searchInput type="text" placeholder="{{ 'SEARCH_HINT' | translate }}" [(ngModel)]="searchString">
        </div>   
	 </div>
</span>