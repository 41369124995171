import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { TableColumn } from '@app/model/table.model';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'table-settings',
  templateUrl: 'table.settings.component.html',
  styleUrls:  ['./table.settings.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class TableSettingsComponent  {
	@ViewChild('popover') private popover: ElementRef;
	@ViewChild('dropdownToggle', { static: true }) private dropdownToggle: ElementRef;
	// @Input() items: {id: string, name: string, alwaysVisible: boolean, checked: boolean, orderBy: boolean, orderDirection: string, width: number}[];
	@Input() items: TableColumn[];
	@Input() busy: boolean;
	@Output() setColumnVisibility: EventEmitter<any> = new EventEmitter<any>();
	@Output() resetColumns: EventEmitter<any> = new EventEmitter<any>();

	isOpen: boolean;
	autoClose = false; // to not hide

	constructor(private sharedService: SharedService,
				private userService: UserService) {
	}

	ngOnInit() {
	}

	onSelect(item) {
		if (item.alwaysVisible) {return;}
		this.setColumnVisibility.emit(item);
	}

	reset() {
		this.resetColumns.emit();
	}

	hide() {
		this.isOpen = false;
	}
	show() {
		this.isOpen = true;
	}

	onClick(event) {
		// call hide only if click outside of popover
	 	if (this.popover && !this.popover.nativeElement.contains(event.target) && !this.dropdownToggle.nativeElement.contains(event.target)  && this.popover.nativeElement.clientHeight > 0) {
			this.hide();
	 	}
	}
}