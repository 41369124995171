import { Component} from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { BasketService  } from '../../../../services/basket/basket.service'
import { Subject } from 'rxjs';

@Component({
    selector: 'app-plus-cell',
    template: `	<div class="clickable" (click)="onClick()" style="font-size: 20px; margin-top: -2px;" *ngIf="params.data[affectingColumnName] > 0">
    				<i class="fa fa-plus-square fnt-primary"></i>
    			</div>`
})
export class GridPlusRendererComponent implements AgRendererComponent {
    params: any;
    affectingColumnName: string;
    subjectReloadCurrent: Subject<any>;

    constructor(private basketService: BasketService) {
        this.subjectReloadCurrent = basketService.getReloadCurrentSubject();
    }

    agInit(params: any): void {
        this.params = params;
        if (params.column.colId === 'add') {
            this.affectingColumnName = 'amountOrder';
        }
        if (params.column.colId === 'addRfq') {
            this.affectingColumnName = 'amountRfq';
        }
    }

    onClick() {
        if (this.params.column.colId === 'add') {
            if (this.params.data.amountOrder > 0) {
                this.basketService.addToOrder([
                    { amountOrdered: this.params.data.amountOrder,
                      eCommProductId: this.params.data.eCommProductId,
                      brandCode: this.params.data.brandCode,
                      deliveryDate: ''
                    }
                ])
                .subscribe(data => {
                    this.params.data.amountOrder = null;
                    this.params.data.priceTotal = 0.00;
                    this.params.api.redrawRows();
                    this.subjectReloadCurrent.next();
                }, err => console.log(err));
            }
        }
        if (this.params.column.colId === 'addRfq') {
            this.params.onClick(this.params, {...this.params.data})
        }
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}
