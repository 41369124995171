<div class="table-filter" *ngIf="firstInitDone">
	<div class="row-container filter-items">
		<div>
		    <div class="input-container" [ngClass]="{'cancelable': item.type === 'text' }" *ngFor="let item of items">
				<label [ngClass]="{shifted: item.type === 'dateRange'}">{{item.name | translate}}
					<app-year-range-picker *ngIf="item.type === 'dateRange' && item.showYearPicker" 
						class="pick-year-link"
						[fromDate]="item.valueFrom?.jsdate"
						[toDate]="item.valueTo?.jsdate"
						(onRangeSelected)="onDateRangeSelectedFromPicker(item, $event)"
					></app-year-range-picker>
				</label>
				   
				   
				<div *ngIf="item.type === 'text'" class="text-filter" [ngClass]="{'active': item.active}">
					<dropdown-component [attributeName]="item.id" [items]="textOperators" [selected]="item.operator" (onSelect)="onSelectTextFilterOperator(item, $event)"></dropdown-component>
		   			<input type="text" class="form-control" [(ngModel)]="item.value"
	          			(ngModelChange)="onChange(item, $event)" (keypress)=inputKeyPress($event) [maxLength]="item.maxLength">
	    			<span class="cancel clickable" *ngIf="item.value.length > 0" (click)="cancelText(item)"><i class="fa fa-times" style="margin-top: -10px;"></i></span>
		    	</div>
				
				<div *ngIf="item.type === 'select'" style="padding-top: 3px;" class="select-filter" [ngClass]="{'active': item.active}">
	        		<dropdown-component [attributeName]="item.id" [items]="item.values" [selected]="item.value" [customDotColors]="item.customDotColors" (onSelect)="onSelect(item, $event)"></dropdown-component>
				</div>
				
		    	<div *ngIf="item.type === 'date'" class="date-filter" [ngClass]="{'active': item.active}">
		    		<div class="date-dropdown">
		    			<dropdown-component [attributeName]="item.id" [items]="operators" [selected]="item.operator" (onSelect)="onSelectFilterOperator(item, $event)"></dropdown-component>
		    		</div>
		    		<div class="date-picker">
		    			<my-date-picker 
			                    [selDate]="item.value.date"
			                    [options]="myDatePickerOptions"
			                    [locale]="this.sharedService.appSettings.language"
			                    (dateChanged)="onDateChange(item, $event)"></my-date-picker>
			        </div>
		    	</div>
				
				<div *ngIf="item.type === 'dateRange'" class="date-range">
					<div class="date-filter" [ngClass]="{'active': item.valueFromActive}">
						<my-date-picker [selDate]="item.valueFrom.date" [options]="myDatePickerOptions"
							[locale]="this.sharedService.appSettings.language"
							(dateChanged)="onDateRangeFromChange(item, $event)" [placeholder]="placeholderFrom"
						></my-date-picker>
					</div>
					<div class="date-filter" [ngClass]="{'active': item.valueToActive}">
						<my-date-picker [selDate]="item.valueTo.date" [options]="myDatePickerOptions"
							[locale]="this.sharedService.appSettings.language"
							(dateChanged)="onDateRangeToChange(item, $event)" [placeholder]="placeholderTo"
						></my-date-picker>
					</div>
				</div>

				<div *ngIf="item.type === 'multiselect'" style="padding-top: 3px;" class="multiselect-filter" [ngClass]="{'active': item.active}">
					<dropdown-component
						[attributeName]="item.id"
						[items]="item.values"
						[multiselect]="true"
						[search]="item.search"
						[resetMultiselectSearch]="resetMultiselectSearch"
						[autoClose]="false" 
						[customDotColors]="item.customDotColors"
						[multiselectAllLabel]="item.allLabel"
						[multiselectSelectedLabel]="item.selectedLabel"
						[selected]="item.value"
						(onSelect)="multipleOnSelect(item)"
						(onEnterInSearch)="onEnterInSearch()"
					></dropdown-component>
					<span *ngIf="item.bulkCheckbox" class="clickable checkbox" [ngClass]="{'active': item.bulkCheckbox.active}" (click)="onMultiselectBulkCheckToggle(item)">
						<i class="fa" [ngClass]="{'fa-square-o': !item.bulkCheckbox.checked, 'fa-check-square-o': item.bulkCheckbox.checked}"></i>
						<span class="title" translate>{{item.bulkCheckbox.title}}</span>
					</span>
		    	</div>
				
				<div *ngIf="item.type === 'checkbox'" style="padding-top: 25px;" class="checkbox-filter" [ngClass]="{'active': item.active}">
		    		<span class="row-container clickable" (click)="onCheckboxToggle(item)"><i class="fa row-container" [ngClass]="{'fa-square-o': !item.checked, 'fa-check-square-o': item.checked}"></i><span translate>{{item.title}}</span></span>
		    	</div>
		    </div>
		    <div class="form-buttons" *ngIf="showFilterButtons">
		    	<div class="row-container">
		    		<button class="btn-primary" (click)="filter()" [disabled]="!filterChanged" [ngClass]="{'ec-disabled': !filterChanged}">
						<i class="fa fa-filter"></i>
						<span translate>FILTER</span>
					</button>
		    	</div>
			    <div class="row-container">
		    		<button class="btn-secondary" (click)="reset()" [disabled]="!filterActive" [ngClass]="{'ec-disabled': !filterActive}">
						<i class="fa fa-times-circle-o"></i>
						<span translate>FILTER_RESET</span>
					</button>
		    	</div>
		    </div>
	    </div>
	</div>
	
	<div class="row-container" style="margin-top: -15px;" fxLayout="row" *ngIf="filterSelectedAttrId">
		<span class="row-container clickable" (click)="toggleSelectedCheckbox('filterSelectedOnly')"><i class="fa row-container" [ngClass]="{'fa-square-o': !filterSelectedOnly, 'fa-check-square-o': filterSelectedOnly}"></i><span translate>SELECTED_ONLY</span></span>
		<span class="row-container clickable" (click)="toggleSelectedCheckbox('filterNotSelectedOnly')"><i class="fa row-container" [ngClass]="{'fa-square-o': !filterNotSelectedOnly, 'fa-check-square-o': filterNotSelectedOnly}"></i><span translate>NOT_SELECTED_ONLY</span></span>
	</div>
</div>