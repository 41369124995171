export enum SendByEmailDialogResultState {
    Close = 'close',
    Send = 'send',
}

export interface SendByEmailData {
    email: string,
}

export interface SendByEmailDialogResult {
    state: SendByEmailDialogResultState;
    data: SendByEmailData;
}
