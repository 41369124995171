import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RfqCloseQuestionnaire } from '@app/model/rfq.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export interface RfqCloseQuestionnaireDialogComponentParams {
    rfqCloseQuestionnaire: RfqCloseQuestionnaire,
    submitButtonText: string
}

@Component({
    selector: 'app-rfq-close-questionnaire-dialog',
    template: `
        <div class="row-container wider bigger" *ngIf="params">
            <app-rfq-close-questionnaire
                [rfqCloseQuestionnaire]="params.rfqCloseQuestionnaire"
                [makeCollapsible]="false"
                [readOnly]="false"
                [isCollapsed]="isCollapsed"
                [showTitle]="true"
                [submitButtonText]="params.submitButtonText"
                (onSubmit)="onSubmit($event)"
                (onCancel)="onCancel()"
            ></app-rfq-close-questionnaire>
        </div>
    `
})
export class RfqCloseQuestionnaireDialogComponent {
    private doneSubject: Subject<RfqCloseQuestionnaire | null>;
    public params: RfqCloseQuestionnaireDialogComponentParams;
    public isCollapsed = false;

    constructor(
        private bsModalRef: BsModalRef
    ) {}

    public onCancel(): void {
        this.doneSubject.next(null);
        this.bsModalRef.hide();
    }

    public onSubmit(rfqCloseQuestionnaire: RfqCloseQuestionnaire): void {
        this.doneSubject.next(rfqCloseQuestionnaire);
        this.bsModalRef.hide();
    }

}
