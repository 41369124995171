export class DeliveryOrder {
    constructor(
        public id: number,
        public customerId: number,
    	public customerName: string,
        public authorizedSellerName: string,
        public deliveryNumberERP: string,
        public number: string,
        public deliveryNote: string,
        public state: string,
        public deliveryCreated: string,
        public deliveryDate: string,
        public sortName: string,
        public totalPrice: number,
        public totalWeight: number,
        public currency: string,
        public url: string
    ){}
}