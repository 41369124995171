import { Injectable } from '@angular/core';
import { EcmHttpQueryParams, EcmHttpService } from '../http/ecm.http.service';
import { Observable } from 'rxjs';
import { ToastService } from '../toastService/toast.service';
import { Customer, RoleCustomer } from '../../model/customer.model';
import { map } from 'rxjs/operators';
import { TableFilterItemOperators } from '@app/model/table.filter.model';
import { RepreOverrideAreas } from '@app/model/user.model';
import { SharedService } from '../shared.service';

@Injectable()
export class CustomersService {
    private optionallyHiddenAttrsMap = new Map<string, () => boolean>();

    constructor (
        private http: EcmHttpService,
        private toastService: ToastService,
        private sharedService: SharedService,
    ) {
        this.optionallyHiddenAttrsMap.set('productsNotTakenNotification',
            () => !this.sharedService.params.features.customer.productsNotTakenAfterDeliveryDateNotif);
        this.optionallyHiddenAttrsMap.set('isERPCustomer',
            () => !this.sharedService.params.features.customer.eRPnonErpOption);
        this.optionallyHiddenAttrsMap.set('customerStockActive', 
            () => !this.sharedService.params.features.customer.customerStock);
        this.optionallyHiddenAttrsMap.set('isRfqManagedByAs', 
            () => !this.sharedService.params.features.customer.isRfqManagedByAs);
    }

    /**
     * Loads list of customer of authorized seller
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    public getCustomers(query): Observable<{rows: Customer[], total: number}> {
        const url = `/authorized-seller-customers`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => {
            const customers: Customer[] = [];
            data.rows.forEach(function(customerItem) {
                customers.push(
                    new Customer(
                        customerItem.id,
                        customerItem.authorizedSellerId,
                        customerItem.customerName,
                        customerItem.customerCode,
                        customerItem.contactName,
                        customerItem.contactPhone,
                        customerItem.contactEmail,
                        customerItem.assignedSellerName,
                        customerItem.assignedSellerPhone,
                        customerItem.priceListCode,
                        customerItem.priceListCurrency,
                        customerItem.productsNotTakenNotification,
                        customerItem.locality,
                        customerItem.country,
                        customerItem.city,
                        customerItem.street,
                        customerItem.zip,
                        customerItem.valid,
                        null,
                        'customer/' + customerItem.id,
                        [],
                        customerItem.isERPCustomer,
                        customerItem.customerStockActive,
                        customerItem.isRfqManagedByAs,
                        customerItem.customerStockForOtherCustomers,
                        customerItem.customerStockForSubcustomers
                    )
                );
            })
            return {rows: customers, total: data.totalCount};
        }));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        const url = `/authorized-seller-customers`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Loads customer
     *
     * @param id - id of customer
     */
    public getCustomer (id: number): Observable<Customer> {
        return this.http.get(`/authorized-seller-customers/` + id)
        .pipe(map(data => {
            return new Customer(
                data.id,
                data.authorizedSellerId,
                data.customerName,
                data.customerCode,
                data.contactName,
                data.contactPhone,
                data.contactEmail,
                data.assignedSellerName,
                data.assignedSellerPhone,
                data.priceListCode,
                data.priceListCurrency,
                data.productsNotTakenNotification,
                data.locality,
                data.country,
                data.city,
                data.street,
                data.zip,
                data.valid,
                data.notActivatedAdminUser,
                'customer/' + data.id,
                data.projectPriceLists,
                data.isERPCustomer,
                data.customerStockActive,
                data.isRfqManagedByAs,
                data.customerStockForOtherCustomers,
                data.customerStockForSubcustomers
                );
        }));
    }

    /**
     * Loads available customers options - permisson groups
     */
    getAvailableCustomerOptions() {
        return this.http.get(`/customers/available-options`)
        .pipe(map((data) => {
            data.groups = data.groups.map(group => {return {
              id: group.id,
              name: 'SECURITY_GROUP_' + group.id,
              nonERPCustomer: group.nonERPCustomer}});
            return data;
        }));
    }

    /**
     * Removes customer from security group
     *
     * @param customerId  <id of customer>
     * @param groupId     <id of group>
     */
    removeCustomerFromGroup(customerId: number, groupId: string) {
        return this.http.delete('/customers/' + customerId + '/permission-groups/' + groupId, '');
    }

    /**
     * Adds customer to security group
     *
     * @param customerId  <id of customer>
     * @param groupId     <id of group>
     */
    addCustomerToGroup(customerId: number, groupId: string) {
        return this.http.put('/customers/' + customerId + '/permission-groups/' + groupId, '');
    }

    /**
     * Loads customers permission groups
     *
     * @param id - id of customer
     */
    getCustomerPermissionGroups (id: number) {
        return this.http.get(`/customers/` + id + `/permission-groups`);
    }

    /**
     * Updates customer
     *
     * @param id -     <id of customer>
     * @param params - {<key>: <value>, ....}
     */
    updateCustomer(id: number, params) {
        return this.http.put(`/authorized-seller-customers/` + id, JSON.stringify(params))
        .pipe(map((data) => {
            this.toastService.addSuccess('CHANGES_APPLIED_SUCCESSFULLY');
            return data;
        }));
    }

    /**
     * Creates customer
     *
     * @param params - {<key>: <value>, ....}
     */
    createCustomer(params) {
        return this.http.post(`/authorized-seller-customers`, JSON.stringify(params))
        .pipe(map((data) => {
            this.toastService.addSuccess('CUSTOMER_CREATED_SUCCESS');
            return data;
        }));
    }

    public bulkUpdateCustomers(itemIds: string[], data: Record<'priceListCode', string>): Observable<void> {
        type RequestBody = {
            itemIds: string[],
            data: Record<'priceListCode', string>,
            action: 'UPDATE',
        };
        const requestBody: RequestBody = {
            itemIds,
            data,
            action: 'UPDATE',
        };

        return this.http.put('/authorized-seller-customers', JSON.stringify(requestBody));
    }

    /**
     * Resends invite to admin user of customer and change users email if new email is defined
     *
     * @param user - {id: <id of user to resend to>, email: [optional]<updated email address if defined>}
     */
    resendInvite(user: {id: number, email?: string}) {
        return this.http.put('/users/' + user.id + '/resend-invite', user.email ? JSON.stringify({email: user.email}) : '')
        .pipe(map((data) => {
            this.toastService.addSuccess('INVITE_SEND_AGAIN_SUCCESS');
            return data;
        }));
    }

    public getCustomersForRepreOverrideByName(customerName: string, area: RepreOverrideAreas): Observable<RoleCustomer[]> {
        const query: EcmHttpQueryParams = {
            top: 30,
            'customerName.operator': TableFilterItemOperators.likeBoth,
            'customerName.value': customerName
        };
        return this.http.get(`/customers/for-repre-override-area/${area}`, this.http.prepareOptions(query));
    }

    public createCustomerForRepreOverride(customerName: string, priceListCurrency: string,
        locality: string, area: RepreOverrideAreas): Observable<RoleCustomer> {

        return this.http.post(`/customers/for-repre-override-area/${area}`, JSON.stringify({
            customerName, priceListCurrency, locality
        }));
    }

    public isColumnHidden(colName: string): boolean {
        return this.optionallyHiddenAttrsMap.has(colName) ? this.optionallyHiddenAttrsMap.get(colName)() : false;
    }

    public getOptionallyHiddenAttrs(): string[] {
        return [...this.optionallyHiddenAttrsMap.keys()];
    }
}
