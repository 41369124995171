<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container tab-active" style="margin-right: 5px;">
            <span translate>SENT_EMAILS</span>
        </span>
    </div>

    <div fxLayout="column" *ngIf="initialized">
        <ecm-table
            [dataGetter]="getList"
            [optionsGetter]="getGridOptions"
            [filterItems]="filterItems"
            [columnsGetter]="getColumns"
            [columnsDefsGetter]="getColumnsDefs"
            [total]="totalRows"
            [editedIdsObj]="gridService.editedIdsObj"
            [observableRefresh]="refreshGrid$.asObservable()"
            [observableReloadCurrent]="gridService.reloadCurrentGridPage$.asObservable()"
            [subjectAllSelectedChange]="gridService.allSelected$"
            [showRefreshButton]="true"
            [exportObj]="exportObj"
        ></ecm-table>
    </div>
</div>