export class RfqQuestionnaire {
  public customerName: string|null = null;
  public customerLocality: string|null = null;
  public customerSize: string|null = null;
  public segment: string|null = null;
  public application: string|null = null;
  public competition: string|null = null;
  public repeatProbability: string|null = null;
  public notes: string|null = null;
};
export const rfqQCustomerSize = [
  'FROM_0_TO_300T',
  'FROM_300T_TO_500T',
  'FROM_500T_TO_1M',
  'FROM_1M_TO_3M',
  'FROM_3M_TO_5M',
  'FROM_5M_TO_10M',
  'FROM_10M_TO_20M',
  'FROM_20M_TO_30M',
  'FROM_30M_TO_50M',
  'FROM_50M'
];

export const rfqQRepeatProbability = [
  'ONCE',
  'MONTHLY',
  'QUARTERLY',
  'ANNUALLY'
];
