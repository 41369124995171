import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/homeComponent/home.component';
import { UnderConstructionComponent } from './components/underConstructionComponent/under.construction.component';
import { UserProfileComponent } from './components/userProfileComponent/user.profile.component';
import { CatalogueComponent } from './components/catalogueComponent/catalogue.component';
import { OrdersSummaryComponent } from './components/order/ordersComponent/ordersSummaryComponent/orders.summary.component';
import { OrdersItemsComponent } from './components/order/ordersComponent/ordersItemsComponent/orders.items.component';
import { OrderDetailComponent } from './components/order/orderDetailComponent/order.detail.component';
import { ShipmentsComponent } from './components/shipment/shipmentsComponent/shipments.component';
import { ShipmentsItemsComponent } from './components/shipment/shipmentsComponent/shipments.items.component';
import { ShipmentDetailComponent } from './components/shipment/shipmentDetailComponent/shipment.detail.component';
import { CurrentOrderComponent } from './components/basket/currentOrderComponent/current.order.component';
import { SupportComponent } from './components/footer/supportComponent/support.component';
import { ContactComponent } from './components/footer/contactComponent/contact.component';
import { ManualsComponent } from './components/footer/manualsComponent/manuals.component';
import { SecurityComponent } from './components/footer/securityComponent/security.component';
import { FaqComponent } from './components/footer/faqComponent/faq.component';
import { BusinessConditionsComponent } from './components/footer/businessConditionsComponent/business.conditions.component';
import { JournalComponent } from './components/journalComponent/journal.component';
import { MonitoringComponent } from './components/monitoringComponent/monitoring.component';
import { UsersAdminComponent } from './components/usersAdminComponent/users.admin.component';
import { CustomersComponent } from './components/customer/customersComponent/customers.component';
import { CustomerComponent } from './components/customer/customerComponent/customer.component';
import { DeliveryOrdersComponent } from './components/deliveryOrder/deliveryOrdersComponent/delivery.orders.component';
import { DeliveryOrdersItemsComponent } from './components/deliveryOrder/deliveryOrdersComponent/delivery.orders.items.component';
import { DeliveryOrderComponent } from './components/deliveryOrder/deliveryOrderComponent/delivery.order.component';
import { InvoicesComponent } from './components/invoice/invoicesComponent/invoices.component';
import { InvoicesItemsComponent } from './components/invoice/invoicesComponent/invoices.items.component';
import { InvoiceDetailComponent } from './components/invoice/invoiceDetailComponent/invoice.detail.component';
import { ControllingComponent } from './components/controlling/controllingComponent/controlling.component';
import { RfqsSummaryComponent } from './components/rfqComponent/rfqsComponent/rfqsSummaryComponent/rfqs.summary.component';
import { RfqsItemsComponent } from './components/rfqComponent/rfqsComponent/rfqsItemsComponent/rfqs.items.component';
import { RfqDetailComponent } from './components/rfqComponent/rfqDetailComponent/rfq.detail.component';
import { NewsComponent } from './components/newsComponent/news.component';
import { NewsDetailComponent } from './components/newsComponent/newsDetailComponent/news.detail.component';
import { CustomerStockComponent } from './components/customerStockComponent/customer.stock.component';
import { ASStockComponent } from './components/aSStockComponent/as.stock.component';
import { LogoutComponent } from './components/logoutComponent/logout.component';
import { PriceListsComponent } from './components/price-list/price-lists/price-lists.component';

import { CanDeactivateDetail } from './detailGuard/detail.guard';
import { PriceListsItemsComponent } from './components/price-list/price-lists/price-lists-items.component';
import { PriceListDetailComponent } from './components/price-list/price-list-detail/price-list-detail.component';
import { SuppliersComponent } from './components/supplier/suppliers/suppliers.component';
import { SupplierDetailComponent } from './components/supplier/supplier-detal/supplier-detail.component';
import { PrfqsComponent } from './components/prfq/prfqs/prfqs.component';
import { PrfqsItemsComponent } from '@app/components/prfq/prfqs-items/prfqs-items.component';
import { PrfqDetailComponent } from './components/prfq/prfq-detail/prfq-detail.component';
import { CChecksComponent } from './components/c-check/c-checks/c-checks.component';
import { CCheckPurchaseOrderComponent } from './components/c-check/c-check-purchase-order/c-check-purchase-order.component';
import { CCheckDeliveryOrderComponent } from './components/c-check/c-check-delivery-order/c-check-delivery-order.component';
import { SentEmailsListComponent } from './components/sent-emails/sent-emails-list/sent-emails-list.component';
import { PresentationsComponent } from './components/footer/presentations-component/presentations.component';

const routes: Routes = [
    { path: '',                 redirectTo: '/sk/home', pathMatch: 'full' },
    { path: ':language',        redirectTo: '/sk/home', pathMatch: 'full' },
    { path: ':language/login',  redirectTo: '/sk/home', pathMatch: 'full' },
    { path: ':language/logout',                component: LogoutComponent },
    { path: ':language/home',                  component: HomeComponent },
    { path: ':language/registration',          component: UnderConstructionComponent },
    { path: ':language/user-profile',          component: UserProfileComponent },
    { path: ':language/catalogue',             component: CatalogueComponent },
    { path: ':language/catalogue-sale',        component: CatalogueComponent },
    { path: ':language/purchase-catalogue',    component: CatalogueComponent },
    { path: ':language/catalogue/:brand/:id',  component: CatalogueComponent },
    { path: ':language/catalogue-sale/:brand/:id', component: CatalogueComponent },
    { path: ':language/purchase-catalogue/:brand/:id',  component: CatalogueComponent },
    { path: ':language/orders', component: OrdersSummaryComponent },
    { path: ':language/orders-items',          component: OrdersItemsComponent },
    { path: ':language/order/:id',             component: OrderDetailComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/shipments',             component: ShipmentsComponent },
    { path: ':language/shipments-items',       component: ShipmentsItemsComponent },
    { path: ':language/shipment/:id',          component: ShipmentDetailComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/reclamations',          component: UnderConstructionComponent },
    { path: ':language/consulting',            component: UnderConstructionComponent },
    { path: ':language/current-request',       component: UnderConstructionComponent },
    { path: ':language/current-order',         component: CurrentOrderComponent },
    { path: ':language/trade-terms',           component: UnderConstructionComponent },
    { path: ':language/support',               component: SupportComponent },
    { path: ':language/contact',               component: ContactComponent },
    { path: ':language/security',              component: SecurityComponent },
    { path: ':language/faq',                   component: FaqComponent },
    { path: ':language/business-conditions',   component: BusinessConditionsComponent },
    { path: ':language/manuals',               component: ManualsComponent },
    { path: ':language/presentations',         component: PresentationsComponent },
    { path: ':language/journal',               component: JournalComponent },
    { path: ':language/monitoring',            component: MonitoringComponent },
    { path: ':language/users-admin',           component: UsersAdminComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/customers',             component: CustomersComponent },
    { path: ':language/customer/:id',          component: CustomerComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/delivery-orders',       component: DeliveryOrdersComponent },
    { path: ':language/delivery-orders-items', component: DeliveryOrdersItemsComponent },
    { path: ':language/delivery-order/:id',    component: DeliveryOrderComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/invoices',              component: InvoicesComponent },
    { path: ':language/invoices-items',        component: InvoicesItemsComponent },
    { path: ':language/invoice/:id',           component: InvoiceDetailComponent },
    { path: ':language/controlling',           component: ControllingComponent },
    { path: ':language/rfqs',                  component: RfqsSummaryComponent },
    { path: ':language/rfqs-items',            component: RfqsItemsComponent },
    { path: ':language/rfq/:id',               component: RfqDetailComponent, canDeactivate: [CanDeactivateDetail]  },
    { path: ':language/news',                  component: NewsComponent },
    { path: ':language/news/:id',              component: NewsDetailComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/customer-stock',        component: CustomerStockComponent },
    { path: ':language/as-stock', component: ASStockComponent },
    { path: ':language/price-lists', component: PriceListsComponent },
    { path: ':language/price-lists-items', component: PriceListsItemsComponent },
    { path: ':language/price-list/:priceListCode/:currency', component: PriceListDetailComponent },
    { path: ':language/suppliers', component: SuppliersComponent },
    { path: ':language/supplier/:id', component: SupplierDetailComponent, canDeactivate: [CanDeactivateDetail] },
    { path: ':language/prfqs', component: PrfqsComponent },
    { path: ':language/prfqs-items', component: PrfqsItemsComponent },
    { path: ':language/prfq/:id', component: PrfqDetailComponent, canDeactivate: [CanDeactivateDetail]  },
    { path: ':language/c-checks', component: CChecksComponent },
    { path: ':language/c-check-purchase-order/:cCheckId/:checkDate', component: CCheckPurchaseOrderComponent },
    { path: ':language/c-check-delivery-order/:cCheckId/:checkDate', component: CCheckDeliveryOrderComponent },
    { path: ':language/sent-emails', component: SentEmailsListComponent },
];

@NgModule({
    // imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}) ],
    imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
    exports: [ RouterModule ],
})
export class AppRoutingModule {}

