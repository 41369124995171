import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Rfq, RfqItem } from '../../../model/rfq.model';
import { SharedService } from '../../../services/shared.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { TableService } from '../../../services/table.service';
import { RfqsService } from '../../../services/rfq/rfqs.service';
import { RfqService } from '../../../services/rfq/rfq.service';
import { RfqGridService } from '../../../services/rfq/rfq.grid.service';
import { map, filter, scan } from 'rxjs/operators';
import * as moment from 'moment';
import { ExportObj, TableBulk } from '@app/model/table.model';

@Component({
    selector: 'rfq-detail-items',
    templateUrl: 'rfq.detail.items.component.html'
})
export class RfqDetailItemsComponent implements OnInit {
    @Input() rfqId: number; /** TODO: refactor: rfqId exists in rfq:Rfq. Do you need this? */
    @Input() rfq: Rfq;
    @Input() customerId: number; /** TODO: refactor: customerId exists in rfq:Rfq. Do you need this? */
    // @Input() filterItems: any[];
    @Input() showNewRowButton: boolean;
    @Input() reloadObservable: Subject<any>;
    @Input() reloadGridOptions: Subject<{isProjectPriceList: boolean}>;

    /** @Output() rowsData: any[] Emit rows data */
    @Output() rowsDataLoaded: EventEmitter<RfqItem[]> = new EventEmitter<RfqItem[]>();

    tableNamePrefix: string; // because of storing table column state differently for state P and for other states
    totalRows: number;
    personsCreated: { id: string, name: string }[] = [];
    subjectRefresh = new Subject<any>();
    subjectReloadCurrent: Subject<any>;
    subjectAllSelected: Subject<any>;
    gridOptions: any;
    public exportObj: ExportObj;
    public bulk: TableBulk;
    filterItems: any[];
    busy: boolean;
    objectKeys = Object.keys; // for ngFor iterating over object keys

    attachmentS3Keys: string[] = [];
    addItemsVisible = false;

    constructor(
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private tableService: TableService,
        private rfqsService: RfqsService,
        private rfqService: RfqService,
        public rfqGridService: RfqGridService
    ) {

        this.subjectRefresh = new Subject<any>();
        this.subjectReloadCurrent = rfqsService.getReloadCurrentSubject();
        this.subjectAllSelected = this.rfqGridService.getItemsSelectedSubject();
        this.rfqGridService.getSelectedSubject().subscribe(result => { // item was selected
            this.setBulk();
        });
        this.rfqGridService.getItemsSelectedSubject().subscribe(result => { // all items was selected
            this.setBulk();
        });

        this.getRfqItems = this.getRfqItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        this.getNewRow = this.getNewRow.bind(this);
        this.getFilterItems = this.getFilterItems.bind(this);

        this.sharedService.user.preferences['rfqItemsSelection'] = { ids: {}, all: false, visible: false };
    }

    ngOnInit() {
        const this_ = this;
        this.tableNamePrefix = this.rfq.rfqState === 'P' ? 'InStateP' : null;

        const exportFunction = function(query) {
            return this_.rfqService.exportDetail(this.rfq.id, query);
        };
        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            this.exportObj = {
                fileName: this.sharedService.translateService.instant('RFQ_ITEM_NO') + ' ' + this.rfq.rfqNumber,
                export: exportFunction.bind(this)
            };
        });

        // reset editedIds on start which can disable backdrop; issue FE #400
        this.rfqGridService.rfqItemsEditedIds = {};

        this.reloadObservable.subscribe(() => {
            this.subjectRefresh.next();
        });

        // because name of column is updated according to pricelist chosen
        this.gridOptions = this.rfqGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10), this.getRfq());
        this.reloadGridOptions.subscribe(result => {
            this.tableService.changeHeaderName(
                this.gridOptions, 'unitPriceCatalogue',
                result.isProjectPriceList ? this.sharedService.translateService.instant('PROJECT_PRICE') : this.sharedService.translateService.instant('CATALOGUE_PRICE'));
        });

        setTimeout(function () {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastDeliveryOrderDetailQueryParams);
        }, 0);
    }

    getRfqItems(params) {
        this.busy = true;
        const query: any = {
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastRfqDetailQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastRfqDetailQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        // this.rfqsService.getRfqItems(this.route.snapshot.params['id'], query)
        this.rfqsService.getRfqItems(this.rfqId, query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    // sharedService.user.representative
                    this.filterItems = this.rfq.rfqState === 'P' ? this.getFilterItems('all') : this.getFilterItems() ;
                }
                this.rowsDataLoaded.emit([...data.rows]);
                params.successCallback(data.rows, data.total);
                this.busy = false;
                this.tableService.updateSelectionWithData(this.sharedService.user.preferences['rfqItemsSelection'], data.rows);
                this.setBulk();
            }, err => {
                console.log(err);
              if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.rfq.rfqState === 'P' ? this.getFilterItems('all') : this.getFilterItems() ;
            }
            this.busy = false;
        });
    }

    setBulk() {
        this.bulk = this.rfqGridService.getBulk(this.sharedService.user.preferences['rfqItemsSelection'].ids, this.rfqId, this.subjectRefresh,
            this.getRfq());
    }

    /**
     * Get data for a new item
     */
    getNewRow() {
        return Object.assign(this.rfqGridService.getNewRow(this.customerId), {rfqId: this.rfqId});
    }

    getEditedCount() {
        return this.rfqGridService.rfqItemsEditedIds ? Object.keys(this.rfqGridService.rfqItemsEditedIds).length : 0;
    }

    getGridOptions() {
        return this.gridOptions;
    }

    /** Get filters but ommit all values except for values from parameter */
    getFilterItems(ommit?: any) {
            return this.rfqGridService.getFilterItems(ommit);
    }

    getColumns() {
        return this.rfqGridService.getColumns(null, this.tableNamePrefix);
    }

    getColumnsDefs() {
        return this.rfqGridService.getColumnDefs( this.getRfq() );
    }
    getRfq() {
        return this.rfq;
    }
}
