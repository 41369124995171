import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ControllingGridService } from './controlling.grid.service';
import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class ControllingService {
    
    constructor (private http: EcmHttpService,
                 private controllingGridService: ControllingGridService) {}

    /**
     * Loads list of customer of authorized seller
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getControllingData() {
        let query = {
            exportToFormat: 'JSON',
            exportColumns: this.controllingGridService.getColumnDefs().map(col => col.field),
            exportColumnsTranslated: this.controllingGridService.getColumnDefs().map(col => col.headerName)
        }
        let url = `/controlling`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(
            switchMap(response => this.http.getLocal(response.exportUrl).pipe(
                map(response => {
                    // transform data from server response[0] is row with translated column names
                    // each row contains array of data according to query.exportColumns
                    let transformedData = [];
                    response.forEach((row, index) => {
                        if (index != 0) {
                            let transformedRow = {};
                            row.forEach((col, index) => {
                                transformedRow[query.exportColumns[index]] = col;
                            });
                            transformedData.push(transformedRow);
                        }
                    });
                    return transformedData;
                })
            ))
        );
    }
}