import { Component, ViewChild, HostListener, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { CustomersService } from '../../../services/customer/customers.service';
import { CustomersGridService } from '../../../services/customer/customers.grid.service';
import { SharedService } from '../../../services/shared.service';
import { Observable, Subject, of } from 'rxjs';
import { ExportObj, TableBulk, TableBulkTypes, TableColumn, TableDataGetterParams } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { BaseComponent } from '@app/components/base/base.component';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'customers',
  templateUrl: 'customers.component.html'
})
export class CustomersComponent extends BaseComponent implements OnInit, OnDestroy { 
  public filterItems: TableFilterItem[];
  public totalRows: number;
  public refreshGrid$ = new Subject<void>();
  public exportObj: ExportObj;
  public busy: boolean;
  public bulk: TableBulk;

  constructor(
    protected router: Router, 
    protected route: ActivatedRoute,
    private customersService: CustomersService,
    public gridService: CustomersGridService,
    public sharedService: SharedService
  ) {
    super(router, route, sharedService);

    this.getCustomers = this.getCustomers.bind(this);
    this.getGridOptions = this.getGridOptions.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.getColumnsDefs = this.getColumnsDefs.bind(this);
    this.bulkSetPriceListCode = this.bulkSetPriceListCode.bind(this);
    
    this.filterItems = this.getFilterItems();

    this.gridService.initSelection();

    this.sharedService.translateService.get('SUNDAY', {value: ''}).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
      this.exportObj = {
        fileName: this.sharedService.translateService.instant('CUSTOMERS'), 
        export: this.customersService.exportAll.bind(this.customersService)
      };
    });

    // to reload when menu item is clicked but it is already routed to this area
    this.sharedService.subjectSameAreaClicked.subscribe(() => {
      this.refreshGrid$.next();
    });

    this.bulk = {
      items: [{
        id: 'setPriceListCode',
        name: 'SET_PRICE_LIST_CODE',
        type: TableBulkTypes.input,
        itemUpdateFn: this.bulkSetPriceListCode,
        width: '90px',
      }],
    };
  }

  ngOnInit() {
    setTimeout(() => {
      // navigate with orderby parameter
      this.sharedService.navigateWithOrderby(this.getColumns(), 
        this.route.snapshot.queryParams, this.sharedService.lastCustomersQueryParams);
    }, 0);
  }

  public getCustomers(params: TableDataGetterParams): void {
    this.busy = true;
    const query: EcmHttpQueryParams = {
      skip: params.startRow, 
      top: params.endRow - params.startRow,
      orderBy: this.route.snapshot.queryParams['orderBy']
    };
    
    this.sharedService.lastCustomersQueryParams = {};
    for (const key in this.route.snapshot.queryParams) {
      this.sharedService.lastCustomersQueryParams[key] = this.route.snapshot.queryParams[key];
      if (key != 'page') {
        query[key] = this.route.snapshot.queryParams[key]
      }
    }

    if (query.skip >= 0 && query.top > 0) {
      this.customersService.getCustomers(query).subscribe(data => {
        this.totalRows = data.total;
        if (!this.filterItems) { // only if not already to not loose fererence of selected
        	this.filterItems = this.getFilterItems();
        }
        params.successCallback(data.rows, data.total);
        this.busy = false;
      }, err => {
        console.log(err);
        // Also in error to be able to cancel filter
        if (!this.filterItems) { // only if not already to not loose fererence of selected
          this.filterItems = this.getFilterItems();
        }
        this.busy = false;
      });
    }
  }

  private bulkSetPriceListCode(itemIds: string[], newValue: { setPriceListCode: string }): Observable<void> {
    return this.customersService.bulkUpdateCustomers(itemIds, { priceListCode: newValue.setPriceListCode });
  }

  public getGridOptions(): GridOptions {
    return this.gridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
  }
  
  public getFilterItems(): TableFilterItem[] {
    return this.gridService.getFilterItems();
  }
  
  public getColumns(): TableColumn[] {
    return this.gridService.getColumns();
  }
  
  public getColumnsDefs(): ColDef[] {
    return this.gridService.getColumnDefs();
  }
}
