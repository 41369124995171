import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject, Observable, Subscription } from 'rxjs';
import { SharedService } from '@services/shared.service';
import { PrfqService } from '../prfq.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { PrfqJournalGridService } from './prfq.journal.grid.service';
import { ToastService } from '@services/toastService/toast.service';
import { DiscussionService } from '@services/discussion.service';
import { tap } from 'rxjs/operators';
import { Prfq, PrfqActions, PrfqStates, PrfqJournalResponse } from '@app/model/prfq.model';
import { ExportObj, TableColumn } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { QuestionDialogService } from '@components/questionDialogComponent/question.dialog.service';
import { Attachment, AttachmentUploadObj, AttachmentDownloadObj } from '@app/model/attachment.model';
import { PrfqPermissionService } from '../prfq.permission.service';
import { PrfqsDetailItemsGridService } from './prfqs-detail-items.grid.service';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { Representative } from '@app/model/user.model';
import { AttachmentPrivateConfig } from '@app/components/attachmentComponent/attachment.component';
import { CommentItem, DiscussionPrivateConfig } from '@app/model/discussion.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClosePrfqComponent, ClosePrfqParams } from './close-prfq/close-prfq.component';
import { NewPostResult } from '@app/components/discussion/newPostComponent/new-post.component';

@Component({
    selector: 'app-prfq-detail',
    templateUrl: 'prfq-detail.component.html'
})
export class PrfqDetailComponent implements OnInit, OnDestroy {
    @ViewChild('discussion') discussion;

    public prfq: Prfq;
    public prfqStates = PrfqStates;
    public prfqActions = PrfqActions;
    public reloadItems$ = new Subject<void>();
    public reloadItemsGridOptions$: Subject<{ isProjectPriceList: boolean }> = new Subject();
    public reloadAttachments$ = new Subject<any>();
    public reloadJournal$ = new Subject<any>();
    public addItemsVisible = false;
    public exportObj: ExportObj;

    attachmentS3Keys: string[] = [];
    public attachmentsCount = 0;
    public postsCount = 0;
    itemsVisible = false;
    public journalVisible = false;
    public buttonsVisibility: {[action: string]: boolean}; // actions = PurchaseRfqActions
    public elementsVisibility: {[element: string]: boolean}; // elements = journal, discussion, ...
    public isAttachmentEditable = false;
    rowsData: any;
    isNoteEditable: boolean;

    public isBulkAddToPrfqAvailable = false;
    public isBulkPrfqUpdateAvailable = false;

    public backUrl = 'prfqs';
    public backQueryParams = {};
    // canDoOrderAndClose: boolean;
    public allowSendRfq: boolean;

    public isCollapsedChild = { // set default states for collapsible elements, if new allement will be added, add here default value
        discussion: true,
        journal: true,
        attachments: true
    }

    public edited = {  // what part of rfq is edited (changed)
        note: false,
        discussionInForm: false
    }

    // allItemsInStates_R_or_O: boolean;

    public showPrivateControls = false;
    public discussionPrivateConfig: DiscussionPrivateConfig;
    public attachmentPrivateConfig: AttachmentPrivateConfig;

    private subscriptions: Subscription[] = [];

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    public isEditing(): boolean {
        return Object.keys(this.edited).reduce((acc, key) => acc || this.edited[key], false);
    }

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        public sharedService: SharedService,
        private prfqService: PrfqService,
        private gridService: PrfqsDetailItemsGridService,
        private prfqJournalGridService: PrfqJournalGridService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
        public prfqPermissionService: PrfqPermissionService,
        private toastService: ToastService,
        private discussionService: DiscussionService,
        private questionDialogService: QuestionDialogService,
        private modalService: BsModalService
    ) {
        this.getAttachments = this.getAttachments.bind(this);
        this.getUploadUrl = this.getUploadUrl.bind(this);
        this.getDownloadUrl = this.getDownloadUrl.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.getJournal = this.getJournal.bind(this);
        this.getJournalGridOptions = this.getJournalGridOptions.bind(this);
        this.getJournalFilterItems = this.getJournalFilterItems.bind(this);
        this.getJournalColumnsDefs = this.getJournalColumnsDefs.bind(this);
        this.getJournalColumns = this.getJournalColumns.bind(this);
        this.getCommentItemLabel = this.getCommentItemLabel.bind(this);
        this.saveNote = this.saveNote.bind(this);

        if (this.sharedService.backUrl.length > 0) { // set back url from previous component if exists
            this.backUrl = this.sharedService.backUrl;
            this.backQueryParams = this.sharedService.backQueryParams;
            this.sharedService.backUrl = '';
            this.sharedService.backQueryParams = null;
        }

        this.showPrivateControls = this.sharedService.user.representative === Representative.AS;
        this.discussionPrivateConfig = this.prfqService.getDiscussionPrivateConfig();
        this.attachmentPrivateConfig = this.prfqService.getAttachmentPrivateConfig();
    }

    private _t(text?: string): string {
        return this.sharedService.translateService.instant(text);
    }

    public ngOnInit(): void {
        this.allowSendRfq = false; // dont allow send rfq if there isnt rows or here on init - while we dont know rows count
        this.loadPrfq();

        this.subscriptions.push(this.gridService.attachmentChanged$.subscribe(() => {
            this.reloadAttachments$.next();
        }));

        this.subscriptions.push(this.prfqService.getReloadCurrentSubject().subscribe(() => {
            this.reloadJournal$.next();
        }));

        this.subscriptions.push(this.discussionService.postsCount$.subscribe(count => {
            this.postsCount = count;
        }));
    }

    public loadPrfq(): void {
        this.prfqService.getPrfq(this.route.snapshot.params['id']).subscribe(prfq => {
            this.prfq = prfq;
            this.prfqPermissionService.setPrfqState(this.prfq.prfqState);
            this.isNoteEditable = this.prfqPermissionService.isColumnEditable('prfqNote');
            this.isBulkAddToPrfqAvailable = this.prfqPermissionService.isElementAvailable('bulkAddToPrfq');
            this.isBulkPrfqUpdateAvailable = this.prfqPermissionService.isElementAvailable('bulkPrfqUpdate');
            this.itemsVisible = true;
            this.buttonsVisibility = this.getButtonsVisibility(this.prfq.actions);
            this.elementsVisibility = this.getElementsVisibility();
            this.isAttachmentEditable = this.prfqPermissionService.isCellEditable(null, 'attachmentsCount');

            const exportFunction = function (query) {
                return this.rfqService.exportDetail(this.rfq.id, query);
            };

            this.sharedService.translateService.get('PRFQ').subscribe((translation: string) => {
                this.exportObj = {
                    fileName: translation + '_' + this.prfq.prfqNumber,
                    export: exportFunction.bind(this)
                };
            });
            console.log('this.prfq', this.prfq);
        }, err => {
            console.log(err)
        });
    }

    public deletePrfq(): void {
        this.prfqService.deletePrfq(this.prfq.id)
            .subscribe(() => {
                this.toastService.addSuccess('RFQ_DELETE_RFQ_DONE');
                this.router.navigate([`/${this.sharedService.appSettings.language}/prfqs`]);
            },
            err => { console.log(err) });
    }

    public prfqAction(action: PrfqActions, extraData?: any): void {
        this.prfqService.prfqAction(this.prfq.id, action, extraData)
            .subscribe(() => {
                this.toastService.addSuccess(`PRFQ_${action}_DONE`);
                // stay in prfq detail and reload data
                if ([PrfqActions.OPEN_PRFQ, PrfqActions.REOPEN_PRFQ].indexOf(action) > -1) {
                    if ([PrfqActions.REOPEN_PRFQ].indexOf(action) > -1) {
                        this.discussionService.getCommentObservable().next({});
                    }
                    this.loadPrfq();
                    this.reloadItems$.next();
                    this.scrollToTop();
                } else {
                    this.router.navigate([`/${this.sharedService.appSettings.language}/prfqs`]);
                }
            }, err => { console.log(err) });
    }

    private getButtonsVisibility(actions: PrfqActions[]): {[action: string]: boolean} {
        return actions.reduce((acc, action) => Object.assign({}, acc, { [action]: this.prfqPermissionService.hasPermissionHeaderAction() }), {});
    }

    private getElementsVisibility(): {[element: string]: boolean} {
        const elementNames = ['journal' as const, 'discussion' as const, 'attachments' as const];
        return elementNames.reduce((acc, elementName) => Object.assign({}, acc, {
            [elementName]: this.prfqPermissionService.isElementAvailable(elementName)
        }), {});
    }

    public getAttachments(prfqId: number): Observable<Attachment[]> {
        return this.prfqService.getAttachments(prfqId);
    }

    public getUploadUrl(fileName: string, privateItem?: boolean): Observable<AttachmentUploadObj> {
        return this.prfqService.getAttachmentUploadUrl(fileName, this.prfq.id, null, privateItem);
    }

    public getDownloadUrl(attachmentId: number, lineNumber?: number): Observable<AttachmentDownloadObj> {
        return this.prfqService.getAttachmentDownloadUrl(this.prfq.id, attachmentId, lineNumber);
    }

    public setAttachmentS3Keys(s3Keys: string[]): void {
        this.attachmentS3Keys = s3Keys;
    }

    public deleteAttachment(rfqId: number, attachmentId: number, lineNumber?: number): Observable<void> {
        return this.prfqService.deleteAttachment(rfqId, attachmentId);
    }

    public attachmentsChanged(attachmentsCount: number): void {
        this.attachmentsCount = attachmentsCount;
        this.reloadJournal$.next();
    }

    public scrollToComments(): void {
        this.isCollapsedChild.discussion = false;
        setTimeout(() => {
            this.pageScrollService.scroll({ document: this.document, scrollTarget: '#discussion' });
            this.discussion.focusNewComment();
        }, 0);
    }

    public scrollToAttachments(): void {
        this.isCollapsedChild.attachments = false;
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#attachments' });
    }

    scrollToTop() {
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#rfqDetail' });
    }

    public scrollToJournal(): void {
        this.isCollapsedChild.journal = false;
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#prfqJournal' });
    }

    public getJournal(prfqId: number, query: EcmHttpQueryParams): Observable<PrfqJournalResponse> {
        return this.prfqService.getJournal(prfqId, query);
    }
    public getJournalGridOptions(): GridOptions {
        return this.prfqJournalGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page'], 10));
    }
    public getJournalFilterItems(personsCreated): TableFilterItem[] {
        return this.prfqJournalGridService.getFilterItems(personsCreated);
    }
    public getJournalColumns(): TableColumn[] {
        return this.prfqJournalGridService.getColumns();
    }
    public getJournalColumnsDefs(): ColDef[] {
        return this.prfqJournalGridService.getColumnDefs();
    }

    public toggleAddItems(): void {
        this.addItemsVisible = !this.addItemsVisible;
    }

    public getCommentItemLabel(item: CommentItem): string {
        return item.displayItem ? this._t('DISCUSSION_TO_ROW').toLowerCase() + ' ' + (item.displayItem.lineNumber ? item.displayItem.lineNumber : item.childTableKey) : '';
    }

    public discussionChanged(changed: number): void {
        this.reloadJournal$.next();
    }

    /**
     * Called by rfq.detail.items component after loading the items
     * @param {array} rows a copy of rfq items
     */
    public getRowsDataChange(rows: any) {
        this.rowsData = rows;
        this.allowSendRfq = this.rowsData.length > 0 ? true : false;
        // this.canDoOrderAndClose = this.rowsData.filter(row => {
        //     // // find out also if some items are in other state than 'R' or 'D' for change in rfq submit button label
        //     // this.allItemsInStates_R_or_O = true;
        //     // if ((row.itemState !== 'R') && (row.itemState !== 'D')) {
        //     //     this.allItemsInStates_R_or_O = false;
        //     // }

        //     return row.itemState === 'A';
        // }).length === 0 ? false : true;
    }

    public showModalActionClose(): void {
        this.closeOrReopen('PRFQ_ENTER_CLOSING_COMMENT', 'RFQ_SUBMIT', PrfqActions.CLOSE_PRFQ);
    }

    public showModalStatusReopen(): void {
        this.closeOrReopen('PRFQ_ENTER_REOPEN_COMMENT', 'RFQ_REOPEN', PrfqActions.REOPEN_PRFQ);
    }

    private closeOrReopen(checkboxText: string, submitButtonText: string, action: PrfqActions): void {
        const bsModalRef: BsModalRef = this.modalService.show(ClosePrfqComponent, 
            {keyboard: false, ignoreBackdropClick: true});

        bsModalRef.content.done$ = new Subject<void>();
        const params: ClosePrfqParams = {
            checkboxText: checkboxText,
            submitButtonText: submitButtonText,
            showPrivateControls: this.showPrivateControls,
            discussionPrivateConfig: this.discussionPrivateConfig
        };
        bsModalRef.content.params = params;

        this.subscriptions.push(
            bsModalRef.content.done$.subscribe((newPostResult: NewPostResult) => {
                bsModalRef.hide();

                if (newPostResult) {
                    const extraData = newPostResult.post
                        ? { 
                            discussionText: newPostResult.post || '',
                            privateItem: newPostResult.isPrivate
                        }
                        : {};

                    this.prfqAction(action, extraData);
                }
            })
        );
    }

    public noteEdited(value: boolean): void {
        this.edited.note = value;
    }

    public saveNote(note: string): Observable<void> {
        return this.prfqService.updatePrfqNote(this.prfq.id, note).pipe(
            tap(() => {
                this.prfq.prfqNote = note;
            })
        );
    }

    public discussionInFormEdited(value: boolean): void {
        this.edited.discussionInForm = value;
    }

    public onImportFinish(result: {successCount: number}): void {
        if (result.successCount > 0) {
            this.reloadItems$.next();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
