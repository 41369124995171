<div class="price-list-import row-container">

    <div fxLayout="row">
        <div class="input-container" style="max-width: 300px; margin-right: 15px;">
            <label translate>CUSTOMER_PRICE_LIST_CODE</label>
            <input [(ngModel)]="priceListCode" class="form-control"
                id="priceListCode" name="priceListCode" maxlength="10" required>
        </div>
        <div class="input-container" style="max-width: 300px; margin-right: 15px;">
            <label translate>CURRENCY</label>
            <div>
                <dropdown-component
                    [items]="currencies"
                    [selected]="defaultCurrencySelection"
                    [autoClose]="true"
                    [disableSelectedValueInButton]="false"
                    (onSelect)="onCurrencySelected($event)"
                ></dropdown-component>
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <div class="input-container" style="width: 100%;">
            <label translate>NOTE</label>
            <input [(ngModel)]="note" class="form-control"
                id="note" name="note" maxlength="200">
        </div>
    </div>

    <hr>

    <div fxLayout="row">
        <app-file-loader
            [rowAttrs]="rowAttrs"
            [addButtonText]="'ADD_PRICE_LIST'"
            [importHelp1Text]="importHelp1Text"
            [disabled]="!priceListCode || !currency"
            (onResultReady)="onDataReady($event)"
        ></app-file-loader>
    </div>

    <hr>

    <div fxLayout="row">
        <app-paste style="width: 100%;"
            [rowAttrs]="rowAttrs"
            [addButtonText]="'ADD_PRICE_LIST'"
            [importHelp1Text]="importHelp1Text"
            [disabled]="!priceListCode || !currency"
            (onResultReady)="onDataReady($event)"
        ></app-paste>
    </div>

    <hr>

    <div fxLayout="row" fxLayoutAlign="end">
        <button class="btn-secondary" (click)="close()">
            <span translate>CLOSE</span>
        </button>
    </div>

</div>
