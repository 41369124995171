import { Injectable } from "@angular/core";
import { BaseGridService, GridServiceOptions } from "@app/services/base/base-grid.service";
import { SharedService } from "@app/services/shared.service";
import { TableService } from "@app/services/table.service";
import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from "@app/model/table.filter.model";
import { TranslateService } from "@ngx-translate/core";
import { GridDateRendererComponent, GridDateRendererComponentParams } from "@app/components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component";
import { GridJournalAdditionalInfoRendererComponent } from "@app/components/tableComponent/gridRendererComponents/gridJournalAdditionalInfoRendererComponent/grid.journal.additional.info.renderer.component";
import { PrfqActions, PrfqItemActions, PrfqJournalItem } from "@app/model/prfq.model";
import { AppAreas } from "@app/model/appArea.model";

@Injectable()
export class PrfqJournalGridService extends BaseGridService {

    constructor (
        protected sharedService: SharedService,
        protected tableService: TableService,
        private translateService: TranslateService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed:  AppAreas.prfq, //'prfq-journal',
            gridNameCamel: 'prfqJournal',
            tableNamePrefix: 'Journal', // this value is in 'journal.detail.component.html' -> [tableNamePrefix]="'Journal'"
            columnList: [
                { id: 'dateCreated', name: "DATE_OF_CREATION", alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
                { id: 'userCreatedSortName', name: "AUTHOR", checked: true, orderBy: false, orderDirection: 'ASC', width: 120 },
                { id: 'lineNumber', name: "ROW", checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
                { id: 'eventType', name: "EVENT_TYPE", orderBy: false, alwaysVisible: true, checked: true, width: 120 },
                { id: 'info', name: "ADDITIONAL_INFO", alwaysVisible: true, checked: true, orderBy: false, width: 50 }
            ]
        };
    }

    public getColumnDefs(): ColDef[] {
        let colDefs: ColDef[] = [];

        // dateCreated
        const dateCreated: ColDef = {
            headerName: this.translateService.instant(this.getColumn('dateCreated').name),
            field: 'dateCreated',
            width: this.getColumn('dateCreated').width,
            suppressSizeToFit: true,
            sort: 'DESC'
        };
        dateCreated.cellRendererFramework = GridDateRendererComponent;
        const dateCreatedCellRendererParams: GridDateRendererComponentParams = {
            time: true
        };
        dateCreated.cellRendererParams = dateCreatedCellRendererParams;
        colDefs.push(dateCreated);

        // userCreatedSortName
        const userCreatedSortName: ColDef = {
            headerName: this.translateService.instant(this.getColumn('userCreatedSortName').name),
            field: 'userCreatedSortName',
            width: this.getColumn('userCreatedSortName').width,
            cellStyle: { 'text-align': 'left' },
            suppressSizeToFit: true,
            valueGetter: (params: {data: PrfqJournalItem}) => params.data.userCreatedFullName
        };
        colDefs.push(userCreatedSortName);

        // lineNumber
        const lineNumber: ColDef = {
            headerName: this.translateService.instant(this.getColumn('lineNumber').name),
            field: 'lineNumber',
            width: this.getColumn('lineNumber').width,
            suppressSizeToFit: true,
            valueGetter: (params: {data: PrfqJournalItem}) => params.data.lineNumber
        };
        colDefs.push(lineNumber);

        // eventType
        const eventType: ColDef = {
            headerName: this.translateService.instant(this.getColumn('eventType').name),
            field: 'eventType',
            width: this.getColumn('eventType').width,
            suppressSizeToFit: true,
            sortable: false,
            valueGetter: (params: {data: PrfqJournalItem}) => this.translateService.instant('JOURNAL_' + params.data.eventType)
        };
        colDefs.push(eventType);

        // info
        const info: ColDef = {
            headerName: this.translateService.instant(this.getColumn('info').name),
            field: 'info',
            width: this.getColumn('info').width,
            cellClass: 'cell-wrap-text', 
            autoHeight: true,
            cellStyle: { 'text-align': 'left' },
            sortable: false,
            cellRendererFramework: GridJournalAdditionalInfoRendererComponent
        };
        colDefs.push(info);

        return colDefs;
    }

    public getFilterItems(personsCreated: { id: string, name: string }[] = []): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            { 
                id: 'dateCreated', name: 'DATE_OF_CREATION',  type: TableFilterItemTypes.dateRange, 
                valueFrom: '', valueTo: '', operator: TableFilterItemOperators.likeBoth
            },
            { id: 'userCreatedId', name: 'AUTHOR', type: TableFilterItemTypes.multiselect, search: true,
                value: '', values: personsCreated
            },
            {
                id: 'eventType', name: 'EVENT_TYPE', type: TableFilterItemTypes.multiselect,
                search: true,
                allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: PrfqActions.SUBMIT_PRFQ, name: 'JOURNAL_SUBMIT_RFQ' },
                    { id: PrfqActions.OPEN_PRFQ, name: 'JOURNAL_OPEN_RFQ' },
                    { id: PrfqActions.CLOSE_PRFQ, name: 'JOURNAL_CLOSE_RFQ' },
                    { id: PrfqActions.REOPEN_PRFQ, name: 'JOURNAL_REOPEN_RFQ' },
                    { id: PrfqActions.INSERT_ITEM, name: 'JOURNAL_INSERT_ITEM' },
                    { id: 'J_CREATE_RFQ_POST', name: 'JOURNAL_J_CREATE_RFQ_POST' },
                    { id: PrfqItemActions.CONFIRM_ITEM, name: 'JOURNAL_CONFIRM_ITEM' },
                    { id: PrfqItemActions.ACCEPT_ITEM, name: 'JOURNAL_ACCEPT_ITEM' },
                    { id: PrfqItemActions.NOT_ACCEPT_ITEM, name: 'JOURNAL_NOT_ACCEPT_ITEM' },
                    { id: PrfqItemActions.START_ITEM_WAITING, name: 'JOURNAL_START_ITEM_WAITING' },
                    { id: PrfqItemActions.FINISH_ITEM_WAITING, name: 'JOURNAL_FINISH_ITEM_WAITING' },
                    { id: 'J_UPDATE_ITEM', name: 'JOURNAL_J_UPDATE_ITEM' },
                    { id: PrfqItemActions.CANCEL_ACCEPTED_ITEM, name: 'JOURNAL_CANCEL_ACCEPTED_ITEM' },
                    { id: PrfqItemActions.REOPEN_ITEM, name: 'JOURNAL_REOPEN_ITEM' },
                    { id: PrfqItemActions.DELETE_ITEM, name: 'JOURNAL_DELETE_ITEM' },
                    { id: 'J_DELETE_ATTACHMENT', name: 'JOURNAL_J_DELETE_ATTACHMENT' },
                    { id: 'J_CREATE_ATTACHMENT', name: 'JOURNAL_J_CREATE_ATTACHMENT' }
                ]
            }
        ];
        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }
}
