import { Component, Input, ChangeDetectorRef, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { SharedService } from '../../../services/shared.service';
import { OrdersService } from '../../../services/order/orders.service';
import { DiscussionService } from '../../../services/discussion.service';
import { Order } from '../../../model/order.model';
import { LocalNumberPipe, LocalCurrencyPipe } from '../../../locale.pipes.module';
import { Location } from '@angular/common';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';

@Component({
    selector: 'order-detail',
    templateUrl: 'order.detail.component.html'
    // styleUrls:  ['./product.detail.component.css']
})
export class OrderDetailComponent implements OnInit, OnDestroy {
    @ViewChild('discussion') discussion;

    order: Order;
    tableNamePrefix = 'ProductsOfOrder';
    totalRows: number;
    filterItems: any[];
    subjectRefresh: Subject<any>;
    exportObj: { fileName: string, export: Function };
    totalWeight: number;
    totalPrice: number;
    orderCurrency: string;

    busy: boolean;

    backUrl = 'orders';
    itemArea: string;

    edited = {  // what part of form is edited (changed)
        discussionInForm: false
    }

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    isEditing(): boolean {
        return Object.keys(this.edited).reduce((acc, key) => acc || this.edited[key], false);
    }

    constructor(private cdRef: ChangeDetectorRef,
        private router: Router,
        private location: Location,
        public route: ActivatedRoute,
        public sharedService: SharedService,
        private ordersService: OrdersService,
        private discussionService: DiscussionService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
        this.subjectRefresh = new Subject<any>();

        this.getProductsOfOrder = this.getProductsOfOrder.bind(this);
        this.getProductsOfOrderGridOptions = this.getProductsOfOrderGridOptions.bind(this);
        this.getProductsOfOrderColumns = this.getProductsOfOrderColumns.bind(this);
        this.getProductsOfOrderColumnsDefs = this.getProductsOfOrderColumnsDefs.bind(this);

        if (this.sharedService.backUrl.length > 0) { // set back url from previous component if exists
            this.backUrl = this.sharedService.backUrl;
            this.sharedService.backUrl = '';
        }
    }

    ngOnInit() {
        this.ordersService.getOrderDetails(this.route.snapshot.params['id'])
            .subscribe(data => {
                this.order = data;
                this.cdRef.detectChanges();

                const exportFunction = function (query) {
                    return this.ordersService.exportDetail(this.order.id, query);
                };
                this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
                    this.exportObj = {
                        fileName: this.sharedService.translateService.instant('ORDER') + '_' + this.order.orderNumberEComm,
                        export: exportFunction.bind(this)
                    };
                });
            }, err => {
                console.log(err)
            });

        const this_ = this;
        setTimeout(function () {
            this_.sharedService.navigateWithOrderby(this_.getProductsOfOrderColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastOrderDetailQueryParams);
        }, 0);
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }

    repeatOrder() {
        console.log('opakovat objednavku');
    }

    getProductsOfOrder(params) {
        const this_ = this;
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastOrderDetailQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastOrderDetailQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.ordersService.getProductsOfOrder(this.order.id, query)
            .subscribe(data => {
                this.totalRows = data.totalCount;
                this.totalWeight = data.totalWeight;
                this.totalPrice = data.totalPrice;
                if (!this.filterItems) { // only if not already to not loose fererence of selected
                    this.filterItems = this.getProductsOfOrderFilterItems();
                }
                this.busy = false;
                this.orderCurrency = data.rows.length > 0 ? data.rows[0].currency : null;
                params.successCallback(data.rows, data.totalCount);
            }, err => {
                console.log(err);
                this.busy = false;
            });
    }
    getProductsOfOrderGridOptions() {
        return this.ordersService.getProductsOfOrderGridOptions(parseInt(this.route.snapshot.queryParams['page']));
    }
    getProductsOfOrderFilterItems() {
        return this.ordersService.getProductsOfOrderFilterItems();
    }
    getProductsOfOrderColumns(forceOriginal?: boolean) {
        return this.ordersService.getProductsOfOrderColumns(forceOriginal);
    }
    getProductsOfOrderColumnsDefs() {
        return this.ordersService.getProductsOfOrderColumnDefs();
    }

    scrollToComments() {
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#newComment' });
        this.discussion.focusNewComment();
    }

    discussionInFormEdited(value: boolean) {
        this.edited.discussionInForm = value;
    }
}
