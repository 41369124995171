import { Injectable } from '@angular/core';
import { EcmHttpService } from '../http/ecm.http.service';
import { ReplaySubject ,  Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { LanguageService } from '../language.service';
import { LocalDatePipe, LocalNumberPipe } from '../../locale.pipes.module';

@Injectable()
export class NewsReadDetailService {

    constructor (private http: EcmHttpService,
                 private translateService: TranslateService,
                 private languageService: LanguageService,
                 private sharedService: SharedService) {}

    /**
     * Loads list of news
     *
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getReadDetails (newsId: number, query?: any) {
        const url = `/news/${newsId}/users-read`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Exports all
     */
    exportAll(newsId: number, query?: any) {
        const url = `/news/${newsId}/users-read`;
        return this.http.get(url, this.http.prepareOptions(query));
    }
}

