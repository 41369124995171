import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'question-dialog-with-comment',
  template: `
        <div class="row-container wider" *ngIf="params">
            <h4 [ngClass]="params.textClass"><span translate>{{params.message}}</span></h4>
            <span [ngClass]="params.textClass"><span translate>{{params.question}}</span>{{params.suppressQuestionMark ? '' : '?'}}</span>
          <span>
            <textarea class="form-control" placeholder="{{params.placeHolder ? params.placeHolder : ''}}"
                      [myFocus]="true"
                      #box (keyup)="onKey(box.value)"
                      rows="{{params.rows ? params.rows : '8'}}"
                      cols="{{params.cols ? params.cols : '20'}}">{{params.comment}}</textarea>
          </span>
          <div fxLayout="row" class="row-container bigger">
                <div fxFlex="50">
                    <button class="btn-secondary" (click)="dismiss()" style="width: 100px;" *ngIf="params.secondary">
                          <span class translate>{{params.secondary}}</span>
                    </button>
                </div>
                <div fxFlex="50" fxLayoutAlign="end">
                    <button class="btn-primary" (click)="confirm()" style="min-width: 100px;" [disabled]="disableSubmit"
                            [ngClass]="{'ec-disabled': disableSubmit}">
                          <span class translate>{{params.primary}}</span>
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class QuestionDialogWithComment {
  doneSubject: Subject<any>;
  params: any;
  textValue = '';
  disableSubmit = false;

  constructor(public bsModalRef: BsModalRef) {

  }
  onKey(val: string) {
    this.textValue = val;
  }
  confirm() {
    this.disableSubmit = true;
    this.doneSubject.next({res: 'confirm', textValue: this.textValue});
    this.bsModalRef.hide();
  }

  dismiss() {
    this.doneSubject.next({res: 'dismiss'});
    this.bsModalRef.hide();
  }
}
