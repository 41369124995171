import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

const MINUTES_UNITL_AUTO_LOGOUT = 120 // in Minutes
const CHECK_INTERVAL_IN_MS = 5 * 60 * 1000 // 5 min
const STORE_KEY = 'lastUserActionTime';

@Injectable()
export class AutoLogoutService {

    private _isTimeout: boolean = false;

    constructor(
        private ngZone: NgZone,
        private sharedService: SharedService,
        private router: Router
    ) {
    }

    public start(): void {
        this.check();
        this.initListener();
        this.initInterval();
    }

    public isTimeout(): boolean {
        return this._isTimeout;
    }

    private getLastUserActionTime(): number {
        const lastUserActionTime = parseInt(localStorage.getItem(STORE_KEY));
        if (lastUserActionTime) {
            return lastUserActionTime;
        } else {
            this.reset();
            return this.getLastUserActionTime();
        }
    }

    private setLastUserActionTime(value: number): void {
        localStorage.setItem(STORE_KEY, value.toString());
    }

    private initListener(): void {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
        });
    }

    private initInterval(): void {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, CHECK_INTERVAL_IN_MS);
        });
    }

    private reset(): void {
        this.setLastUserActionTime(Date.now());
    }

    private check(): void {
        const now = Date.now();
        const timeleft = this.getLastUserActionTime() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        this._isTimeout = diff < 0;

        this.ngZone.run(() => {
            // console.log('check diff', diff);
            if (this._isTimeout && this.sharedService.isUserLoggedIn()) {
                console.log(`Automatic logout after ${MINUTES_UNITL_AUTO_LOGOUT} Minutes...`);
                this.router.navigate(['/' + this.sharedService.appSettings.language + '/logout']);
            }
        });
    }
}
