import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, HostListener, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../../../services/window.service';

@Component({
    selector: 'discussion-dialog',
    templateUrl: 'discussion.dialog.component.html'
})
export class DiscussionDialog implements OnInit {
    doneSubject: Subject<any>;
    params: any;
    maxHeight: number;

    // @HostListener('document:keyup', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     if (event.keyCode === 27) { // escape
    //         this.doneSubject.next();
    //         this.bsModalRef.hide();
    //     }
    // }

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private windowService: WindowService
    ) {
        this.windowService.height$.subscribe((value: any) => {
            // Do whatever you want with the value.
            // You can also subscribe to other observables of the service
            this.maxHeight = value - 100;
        });
    }

    ngOnInit() {
    }

    added($event) {
        this.doneSubject.next($event);
        this.bsModalRef.hide();
    }
}
