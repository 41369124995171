import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SharedService } from '../../../services/shared.service'
import { LanguageService } from '../../../services/language.service'
import { UserService } from '../../../services/user/user.service'
import { FormService } from '../../../services/form.service'

import { BrandCategory } from '../../../model/brand.category.model'
import { LocalNumberPipe } from '../../../locale.pipes.module';
import { ToastService } from '../../../services/toastService/toast.service';
import { MonitoringService } from '../../../services/monitoring/monitoring.service';
import { Representative } from '@app/model/user.model';
import { CatalogueService, CatalogueModes } from '@app/services/catalogue/catalogue.service';
import { TableFilterItemDateOperator, TableFilterItemOperators } from '@app/model/table.filter.model';
import { Observable, Subscription } from 'rxjs';

interface BrandItem {
    id: string, // "ZVL",
    name: string, // "ZVL",
    checked: boolean
}

export interface ForceFilter {
    showNewProducts?: boolean;
}
@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls:  ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnDestroy {
    @Input() public busy: boolean;
    @Input() public breadCrumbs: BrandCategory[];
    @Input() public catalogueMode: CatalogueModes;
    @Input() public forceFilter$: Observable<ForceFilter>; // set filter from outside of the component
    @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('filterForm', { static: true }) public filterForm: NgForm;

    private searchTimeout: any;
    private searchShortNameTimeout: any;
    waitUntilNoBusyTimeout: any;
    currentQueryParams: any = {};

    public eCommProductId = '';
    public shortName = '';
    dim1 = '';
    dim2 = '';
    dim3 = '';
    dim1Orig = '';
    dim2Orig = '';
    dim3Orig = '';
    dim1Active: boolean;
    dim2Active: boolean;
    dim3Active: boolean;
    public showNewProducts = false;
    searchCriteriaChanged = false;

    // Monitoring
    timerStart = 0; // reseter for timer - needed to input delay monitoring
    interval = null;       // interval as setInterval - needed to input delay monitoring
    monitoringLog: any[] = [];        // monitoring list of all logs

    operators: TableFilterItemDateOperator[] = [
        {id: 'eq', name: 'EQUAL', short: '='},
        {id: 'le', name: 'LESS_EQUAL', short: '<='},
        {id: 'ge', name: 'GREATER_EQUAL', short: '>='},
    ];
    public textOperators: TableFilterItemDateOperator[] = [
        { id: TableFilterItemOperators.likeBoth, name: 'ag_grid_contains', short: '' }, // short will be set later as a translation
        { id: TableFilterItemOperators.eq, name: 'EQUAL', short: '=' }
    ];

    public selectedECommProductIdOperator: TableFilterItemDateOperator = this.textOperators[0];
    private selectedECommProductIdOperatorOiginalId: string = this.selectedECommProductIdOperator.id;

    public selectedShortNameOperator: TableFilterItemDateOperator = this.textOperators[0];
    private selectedShortNameOperatorOiginalId: string = this.selectedShortNameOperator.id;

    public selectedDim1: TableFilterItemDateOperator = this.operators[0];
    public selectedDim2: TableFilterItemDateOperator = this.operators[0];
    public selectedDim3: TableFilterItemDateOperator = this.operators[0];
    private selectedDim1OriginalId = 'eq';
    private selectedDim2OriginalId = 'eq';
    private selectedDim3OriginalId = 'eq';

    public brands: BrandItem[] = []; // do not change reference to the array items! dropdown will not work correctly
    private selectedBrands: BrandItem[] = [];
    brandsActive = false;
    private subscriptions: Subscription[] = [];

    constructor(
        public sharedService: SharedService,
        private languageService: LanguageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private formService: FormService,
        private toastService: ToastService,
        private monitoringService: MonitoringService,
        public catalogueService: CatalogueService
    ) {
        this.sharedService.brands.forEach(brand => {
            this.brands.push({ id: brand.code, name: brand.code, checked: true });
        });
        this.selectedBrands = this.brands.filter(brand => brand.checked);

        this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
            // Set a translation to textOperators
            // We do not set the whole arre here because we want to keep reference in other code
            const likeBothOperator = this.textOperators.find(operator => operator.id === TableFilterItemOperators.likeBoth);
            if (likeBothOperator) {
                likeBothOperator.short = this.sharedService.translateService.instant('ag_grid_contains');
            } else {
                console.error('Could not find likeBoth operator item in:', this.textOperators);
            }
        });
    }

    ngOnInit() {
        // subscribe to router event
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.reset();

            if (this.currentQueryParams['group1'] !== params['group1'] || this.currentQueryParams['group2'] !== params['group2']) {
                this.selectedDim1 = this.operators[0];
                this.selectedDim2 = this.operators[0];
                this.selectedDim3 = this.operators[0];
                this.selectedDim1OriginalId = 'eq';
                this.selectedDim2OriginalId = 'eq';
                this.selectedDim3OriginalId = 'eq';
                this.showNewProducts = false;
            }
            this.currentQueryParams = {};
            for (const key of Object.keys(params)) {
                this.currentQueryParams[key] = params[key];
                if (key === 'eCommProductId.value') { 
                    this.eCommProductId = params[key] 
                }
                if (key === 'shortName.value') {
                    this.shortName = params[key]
                }
                if (key === 'dimensionID.value') {
                    this.dim1 = this.dim1Orig = new LocalNumberPipe(this.languageService).transform(params[key], this.sharedService.appSettings.language);
                    this.dim1Active = true;
                }
                if (key === 'dimensionB.value') {
                    this.dim2 = this.dim2Orig = new LocalNumberPipe(this.languageService).transform(params[key], this.sharedService.appSettings.language);
                    this.dim2Active = true;
                }
                if (key === 'dimensionOD.value') {
                    this.dim3 = this.dim3Orig = new LocalNumberPipe(this.languageService).transform(params[key], this.sharedService.appSettings.language);
                    this.dim3Active = true;
                }
                if (key === 'dateCreated.value') {
                    this.showNewProducts = true;
                }

                if (key === 'eCommProductId.operator') {
                    const textOperator = this.textOperators.find(_operator => _operator.id === params[key]) || this.textOperators[0];
                    this.selectedECommProductIdOperator = textOperator;
                    this.selectedECommProductIdOperatorOiginalId = this.selectedECommProductIdOperator.id;
                }
                if (key === 'shortName.operator') {
                    const textOperator = this.textOperators.find(_operator => _operator.id === params[key]) || this.textOperators[0];
                    this.selectedShortNameOperator = textOperator;
                    this.selectedShortNameOperatorOiginalId = this.selectedShortNameOperator.id;
                }
                if (key === 'dimensionID.operator') {this.selectedDim1 = this.getOperator(params[key]); this.selectedDim1OriginalId = this.selectedDim1.id}
                if (key === 'dimensionB.operator') {this.selectedDim2 = this.getOperator(params[key]); this.selectedDim2OriginalId = this.selectedDim2.id}
                if (key === 'dimensionOD.operator') {this.selectedDim3 = this.getOperator(params[key]); this.selectedDim3OriginalId = this.selectedDim3.id}

                if (key === 'brandCode.value') {
                    // this.brands = this.brands.map(brand => Object.assign({}, brand, { checked: false }));
                    this.setBrandsCheckedTo(false);
                    params[key].split(',').forEach(brandCode => {
                        const brandArr = this.brands.filter(brand => brand.id === brandCode);
                        if (brandArr.length > 0) {
                            brandArr[0].checked = true;
                        }
                    });
                    this.selectedBrands = this.brands.filter(brand => brand.checked);
                    this.brandsActive = this.selectedBrands.length !== this.brands.length && this.selectedBrands.length !== 0;
                }
            }
        });

        if (this.forceFilter$) {
            this.subscriptions.push(
                this.forceFilter$.subscribe(forceFilter => this.setForcedFilter(forceFilter))
            );
        }
    }

    private setForcedFilter(forceFilter: ForceFilter): void {
        if ('showNewProducts' in forceFilter) {
            this.newProductsClicked(forceFilter.showNewProducts);
        }
    }

    reset() {
        this.setBrandsCheckedTo(true);
        this.selectedBrands = [...this.brands];
        this.eCommProductId = '';
        this.shortName = '';
        this.dim1 = '';
        this.dim2 = '';
        this.dim3 = '';
        this.dim1Orig = '';
        this.dim2Orig = '';
        this.dim3Orig = '';
        this.dim1Active = false;
        this.dim2Active = false;
        this.dim3Active = false;
        this.showNewProducts = false;
        this.brandsActive = false;
    }

    // Array elements in this.brands MUST NOT!!! be replaced by other elements
    // because the dropdown component will not reset them properly :(
    private setBrandsCheckedTo(checked: boolean): void {
        this.brands.forEach(brandItem => brandItem.checked = checked);
    }

    /**
     * Reset filter clicked
     */
    public onReset(): void {
        this.reset();
        this.stopTimer();

        const attrs = ['brandCode', 'eCommProductId', 'shortName', 'dimensionID', 'dimensionB', 'dimensionOD', 'dateCreated'];
        attrs.forEach(attr => {
            delete this.currentQueryParams[`${attr}.operator`];
            delete this.currentQueryParams[`${attr}.value`];
        });

        this.applyFilter();
    }

    public onSelectBrand(item: { attributeName: string, selected: { id: string, name: string, checked: boolean }}): void {
        this.selectedBrands = this.brands.filter(brand => brand.checked);
        delete this.currentQueryParams['brandCode.operator'];
        delete this.currentQueryParams['brandCode.value'];
        if (this.selectedBrands.length !== this.brands.length && this.selectedBrands.length !== 0) {
            this.currentQueryParams['brandCode.operator'] = 'in';
            this.currentQueryParams['brandCode.value'] = this.selectedBrands.map(brand => brand.id).join(',');
        }
        // console.log('onSelectBrand', JSON.stringify(this.selectedBrands, null, 4));
        this.applyFilter();
    }

    /**
     * Returns operator object with given id
     *
     * @param id of operator
     */
    getOperator(id): any {
        let operatorObj = this.operators[0];
        this.operators.forEach(function(operator) {
            if  (operator.id === id) {
                operatorObj = operator;
            }
        });
        return operatorObj;
    }

    /** MONITORING - user search input */
    // searchBlur() {
    //     this.startTimer();
    // }
    // searchFocus() {
    //     this.stopTimer();
    // }

    startTimer() {
        const this_ = this;
        const addLog = {
            activity: 'PRODUCT_SEARCH',
            eCommProductId: null,
            productionGroupCode1: null,
            productionGroupCode2: null
        };
        this.interval = setInterval(() => {
            if ( this.timerStart === 2) {
                this_.stopTimer();
                this.timerStart = 0;
                if (this.eCommProductId.length > 0) {
                    addLog.eCommProductId = this.eCommProductId;
                };

                switch (this.breadCrumbs.length) {
                    case (0 || 1):
                        // Do nothing?
                        // addLog.productionGroupCode1 = this.breadCrumbs[1];
                    break;
                    case (2): // Catalog / GAL
                        addLog.productionGroupCode1 = this.breadCrumbs[1].id;
                    break;
                    case (3): // Catalog / GAL / GAL_VLK
                        addLog.productionGroupCode1 = this.breadCrumbs[1].id;
                        addLog.productionGroupCode2 = this.breadCrumbs[2].id;
                    break;
                }

                if (addLog.eCommProductId !== null) {
                    // this_.monitoringLog.push(addLog);
                    this.monitoringService.setCatalogActivityToMonitoring(addLog).subscribe(
                        () => {
                            // do nothing because timer is stopped, wait for another change event
                        },
                        error => {
                            console.log(error);
                        }
                   );
                }
                // if you want to trace behavior of this timer, uncomment row below
                // console.table(this.monitoringLog);
            } else {
                this.timerStart++;
            }
        }, 1000);
    }
    stopTimer() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    restartTimer() {
      this.stopTimer();
      this.timerStart = 0;
      this.startTimer();
    }

    /** end of MONITORING - user search input */

    /**
     * Clears timeout and start new timeuot, after wich search string is set to query string to call request
     */
    public searchChange(newValue: string): void {
        clearTimeout(this.searchTimeout);
        if (this.busy) {
            this.waitUntilNotBusy('eCommProductId');
            return;
        }
        const this_ = this;
        this.searchTimeout = setTimeout(() => {
            this_.currentQueryParams['page'] = '1';
            this_.currentQueryParams['eCommProductId.operator'] = this.selectedECommProductIdOperator.id;
            this_.currentQueryParams['eCommProductId.value'] = newValue;
            if (newValue.length === 0) {delete this.currentQueryParams['eCommProductId.operator']; }
            if (newValue.length === 0) {delete this.currentQueryParams['eCommProductId.value']; }
            this_.applyFilter();
            if ( this.sharedService.user.representative !== 'AS' ) {
                this_.restartTimer(); // monitoring
            }
        }, 800);
    }

    public searchChangeShortName(newValue: string): void {
        clearTimeout(this.searchShortNameTimeout);
        if (this.busy) {
            this.waitUntilNotBusy('shortName');
            return;
        }
        const this_ = this;
        this.searchShortNameTimeout = setTimeout(() => {
            this_.currentQueryParams['page'] = '1';
            this_.currentQueryParams['shortName.operator'] = this.selectedShortNameOperator.id;
            this_.currentQueryParams['shortName.value'] = newValue;
            if (newValue.length === 0) {delete this.currentQueryParams['shortName.operator']; }
            if (newValue.length === 0) {delete this.currentQueryParams['shortName.value']; }
            this_.applyFilter();
            if ( this.sharedService.user.representative !== Representative.AS ) {
                this_.restartTimer(); // monitoring
            }
        }, 800);
    }

    private waitUntilNotBusy(attrName: 'eCommProductId' | 'shortName'): void {
        clearTimeout(this.waitUntilNoBusyTimeout);
        const this_ = this;
        this.waitUntilNoBusyTimeout = setTimeout(() => {
            if (!this_.busy) {
                this_.searchChange(this_[attrName]);
            } else {
                this_.waitUntilNotBusy(attrName);
            }
        }, 200);
    }

    // User changed eCommProductId operator
    // public onSelectTextFilterOperator(ev: {attributeName: string, selected: TableFilterItemDateOperator}): void {
    //     this[ev.attributeName] = ev.selected;
    //     this.searchCriteriaOnChange();
    //     if (this.searchCriteriaChanged) {
    //         this.searchClick();
    //     }
    // }

    /**
     * Callback from dropdown - sets selected operator to operator object
     */
    public onSelectFilterOperator(ev: {attributeName: string, selected: TableFilterItemDateOperator}): void {
        this[ev.attributeName] = ev.selected;
        this.searchCriteriaOnChange();
        if (this.searchCriteriaChanged) {
            this.searchClick();
        }
    }

    public newProductsClicked(forcedState?: boolean): void {
        if (forcedState) {
            this.showNewProducts = forcedState;
        } else {
            this.showNewProducts = !this.showNewProducts;
        }
        this.searchCriteriaChanged = true;
        this.searchClick();
    }

    public searchCriteriaOnChange(): void {
        this.searchCriteriaChanged =
            this.dim1 !== this.dim1Orig ||
            this.dim2 !== this.dim2Orig ||
            this.dim3 !== this.dim3Orig ||
            ((this.selectedECommProductIdOperator.id !== this.selectedECommProductIdOperatorOiginalId) && this.eCommProductId.length !== 0) ||
            ((this.selectedShortNameOperator.id !== this.selectedShortNameOperatorOiginalId) && this.shortName.length !== 0) ||
            ((this.selectedDim1.id !== this.selectedDim1OriginalId) && (this.dim1.length !== 0 && this.dim1Orig.length !== 0)) ||
            ((this.selectedDim2.id !== this.selectedDim2OriginalId) && (this.dim2.length !== 0 && this.dim2Orig.length !== 0)) ||
            ((this.selectedDim3.id !== this.selectedDim3OriginalId) && (this.dim3.length !== 0 && this.dim3Orig.length !== 0));
    }

    public inputKeyPress(event, attrName): void {
        const this_ = this;
        setTimeout(function() {
            this_[attrName] = this_[attrName + 'Orig'] = new LocalNumberPipe(this_.languageService).transform(this_[attrName].replace(/\ /g, ''), this_.sharedService.appSettings.language);

            if (event.keyCode === 13 && this_.searchCriteriaChanged && this_.filterForm.valid) {
                this_.searchClick();
            }
        }, 0);
    }

    onKeyDownNumber(event) {
        // console.log(event.keyCode);
        if (!(this.formService.numbers.indexOf(event.key) > -1) &&       // allow number
            !(event.keyCode >= 37 && event.keyCode <= 40) &&  // allow arrows
            event.keyCode !== 46 &&                            // allow delete
            event.keyCode !== 8 &&                             // allow delete
            event.keyCode !== 9 &&                             // allow tab
            event.keyCode !== 13 &&                            // allow enter
            event.keyCode !== 16 &&                            // allow shift
            event.keyCode !== 17 &&                            // allow ctrl
            event.keyCode !== 18 &&                            // allow alt
            event.keyCode !== 20 &&                            // allow caps lock
            event.keyCode !== 91 &&                            // allow cmd
            event.keyCode !== 93 &&                            // allow right cmd
            event.keyCode !== 110 &&                           // allow comma , on numeric keypad
            event.keyCode !== 188 &&                           // allow comma ,
            event.keyCode !== 190                              // allow dot .
        ) {
            this.toastService.addError('TEXT_ENTRY_NOT_ALLOWED');
            event.preventDefault();
            return;
        }
    }


    /**
     * Set url query to search by attributes fired by click on button
     */
    public searchClick(): void {
        this.currentQueryParams['page'] = '1';

        this.currentQueryParams['eCommProductId.operator'] = this.selectedECommProductIdOperator.id;
        this.currentQueryParams['eCommProductId.value'] = this.eCommProductId;
        if (this.eCommProductId.length === 0) {
            delete this.currentQueryParams['eCommProductId.operator'];
            delete this.currentQueryParams['eCommProductId.value'];
        }

        this.currentQueryParams['shortName.operator'] = this.selectedShortNameOperator.id;
        this.currentQueryParams['shortName.value'] = this.shortName;
        if (this.shortName.length === 0) {
            delete this.currentQueryParams['shortName.operator'];
            delete this.currentQueryParams['shortName.value'];
        }

        this.currentQueryParams['dimensionID.operator'] = this.selectedDim1.id;
        this.currentQueryParams['dimensionID.value'] = this.dim1.toString().replace(/\,/g, '.').replace(/\ /g, '');
        if (this.dim1.length === 0) {
            delete this.currentQueryParams['dimensionID.operator'];
            delete this.currentQueryParams['dimensionID.value'];
        }

        this.currentQueryParams['dimensionB.operator'] = this.selectedDim2.id;
        this.currentQueryParams['dimensionB.value'] = this.dim2.toString().replace(/\,/g, '.').replace(/\ /g, '');
        if (this.dim2.length === 0) {
            delete this.currentQueryParams['dimensionB.operator'];
            delete this.currentQueryParams['dimensionB.value'];
        }

        this.currentQueryParams['dimensionOD.operator'] = this.selectedDim3.id;
        this.currentQueryParams['dimensionOD.value'] = this.dim3.toString().replace(/\,/g, '.').replace(/\ /g, '');
        if (this.dim3.length === 0) {
            delete this.currentQueryParams['dimensionOD.operator'];
            delete this.currentQueryParams['dimensionOD.value'];
        }

        this.currentQueryParams['dateCreated.operator'] = this.operators.find(operator => operator.id === 'ge').id;
        this.currentQueryParams['dateCreated.value'] = this.catalogueService.getDateForNewProduct();
        if (!this.showNewProducts) {
            delete this.currentQueryParams['dateCreated.operator'];
            delete this.currentQueryParams['dateCreated.value'];
        }

        this.searchCriteriaChanged = false;

        this.applyFilter();
    }

    searchInAll() {
        this.currentQueryParams['page'] = '1';
        this.currentQueryParams['group1'] = 'all';
        delete this.currentQueryParams.group2;

        this.applyFilter();
    }

    private applyFilter(): void {
        const this_ = this;
        setTimeout(function() {
            if (this_.filterForm.valid) {
                const navigationExtras: NavigationExtras = {
                    queryParams: this_.currentQueryParams
                };
                this_.router.navigate([], navigationExtras);

                const filterToSave = Object.assign({}, this_.currentQueryParams);
                delete filterToSave.orderBy;
                delete filterToSave.page;
                // this_.userService.setUserPreference(this_.sharedService.area + 'Filter', filterToSave, true);
                this_.userService.setUserPreference(this_.sharedService.getUserPreferenceKey('Filter'), filterToSave, true);
                this_.onFilterChanged.emit();
            }
        }, 0);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
