<div class="row-container wider" fxLayout="row">
    <!-- <div fxLayout="column" fxLayout.gt-sm="row"> -->
        <form #userForm="ngForm" fxLayout="column" fxFlex.gt-sm="33" fxFlex="100">
            <h3 translate>PERSONAL_SETTINGS</h3>
            <div class="row-container base-panel" fxLayout="column">
                <div class="input-container" style="max-width: 300px;">
                    <label translate>EMAIL</label>
                    <input type="email" placeholder="{{ 'EMAIL' | translate }}" maxlength="100" [(ngModel)]="email" (ngModelChange)="detailsOnChange()" class="form-control" name="email"
                     id="email" #emailFormItem="ngModel" required>
                </div>
                <div *ngIf="emailFormItem.invalid && (emailFormItem.errors.required && emailFormItem.dirty)" class="alert alert-danger">
                    <span translate>REQUIRED_FIELD</span>
                </div>

                <div class="input-container" style="max-width: 300px;">
                    <label translate>GIVEN_NAME</label>
                    <input type="text" placeholder="{{ 'GIVEN_NAME' | translate }}" maxlength="50" [(ngModel)]="givenName" (ngModelChange)="detailsOnChange()" class="form-control" name="givenName"
                    id="givenName" #givenNameFormItem="ngModel" required>
                </div>
                <div *ngIf="givenNameFormItem.invalid && (givenNameFormItem.errors.required && givenNameFormItem.dirty)" class="alert alert-danger">
                    <span translate>REQUIRED_FIELD</span>
                </div>

                <div class="input-container" style="max-width: 300px;">
                    <label translate>FAMILY_NAME</label>
                    <input type="text" placeholder="{{ 'FAMILY_NAME' | translate }}" maxlength="50" [(ngModel)]="familyName" (ngModelChange)="detailsOnChange()" class="form-control" name="familyName" 
                    id="familyName" #familyNameFormItem="ngModel" required>
                </div>
                <div *ngIf="familyNameFormItem.invalid && (familyNameFormItem.errors.required && familyNameFormItem.dirty)" class="alert alert-danger">
                    <span translate>REQUIRED_FIELD</span>
                </div>

                <div class="input-container" style="max-width: 300px;">
                    <label translate>PHONE_NUMBER</label>
                    <input type="text" placeholder="{{ 'PHONE_NUMBER' | translate }}" maxlength="40" [(ngModel)]="phoneNumber" (ngModelChange)="detailsOnChange()"
                        class="form-control" name="phoneNumber" id="phoneNumber" #phoneNumberFormItem="ngModel" required>
                </div>
                <div *ngIf="phoneNumberFormItem.invalid && (phoneNumberFormItem.errors.required && phoneNumberFormItem.dirty)" class="alert alert-danger">
                    <span translate>REQUIRED_FIELD</span>
                </div>

                <div>
                    <button class="btn-primary mr-15" (click)="saveDetails()" [disabled]="!detailsChanged || !userForm.valid" [ngClass]="{'ec-disabled': !detailsChanged || !userForm.valid}">
                        <i class="fa fa-check-square-o"></i>
                        <span translate>SAVE</span>
                    </button>
                    <button class="btn-secondary" (click)="initDetails()" *ngIf="detailsChanged">
                        <i class="fa fa-times-circle"></i>
                        <span translate>CANCEL</span>
                    </button>
                </div>
            </div>

            <div class="row-container base-panel" fxLayout="column" *ngIf="isChangeRepresentativeVisible()">
                <div class="input-container">
                    <label translate>REPRESENTATIVE</label>
                    <div *ngIf="sharedService.user.representsCustomer && sharedService.user.representsCustomer.id">
                        <span class="row-container clickable" (click)="setRepresentative('customer')"><i class="fa row-container" [ngClass]="{'fa-square-o': sharedService.user.representative != 'customer', 'fa-check-square-o': sharedService.user.representative == 'customer'}"></i><span translate>CUSTOMER</span>: {{sharedService.user.representsCustomer.name}}</span>
                    </div>
                    <div *ngIf="sharedService.user.representsSupplier">
                        <span class="row-container clickable" (click)="setRepresentative('supplier')"><i class="fa row-container" [ngClass]="{'fa-square-o': sharedService.user.representative !== 'supplier', 'fa-check-square-o': sharedService.user.representative === 'supplier'}"></i><span translate>SUPPLIER</span>: {{sharedService.user.representsSupplier.name}}</span>
                    </div>
                    <div *ngIf="sharedService.user.representsAuthorizedSeller">
                        <span class="row-container clickable" (click)="setRepresentative('AS')"><i class="fa row-container" [ngClass]="{'fa-square-o': sharedService.user.representative != 'AS', 'fa-check-square-o': sharedService.user.representative == 'AS'}"></i><span translate>AUTHORIZED_SELLER</span>: {{sharedService.user.representsAuthorizedSeller.name}}</span>
                    </div>
                    <div *ngIf="sharedService.user.readAllRows">
                        <span class="row-container clickable" (click)="setRepresentative('readAllRows')"><i class="fa row-container" [ngClass]="{'fa-square-o': sharedService.user.representative != 'readAllRows', 'fa-check-square-o': sharedService.user.representative == 'readAllRows'}"></i><span translate>READ_ALL_ROWS</span></span>
                    </div>
                </div>
            </div>
        </form>

        <div fxLayout="column" fxFlex.gt-sm="33" fxFlex="100" class="pl-15">
            <h3 translate>USER_SETTINGS</h3>
            <form (ngSubmit)="savePersonalSettings()" class="row-container base-panel" fxLayout="column">
                <div class="input-container">
                    <label translate>PERSONAL_SETTINGS_HOMEPAGE</label>
                    <dropdown-component 
                        [items]="homepageSettings" 
                        attributeName="personal_settings_homepage"
                        [selected]="selectedHomepageSettings"
                        (onSelect)="onSelectHomepageSettings($event)">
                    </dropdown-component>
                </div>
                
                <div>
                    <button class="btn-primary"
                    [disabled]="!homepageSettingsChanged" [ngClass]="{'ec-disabled': !homepageSettingsChanged}">
                        <i class="fa fa-check-square-o"></i>
                        <span translate>SAVE</span>
                    </button>
                </div>
            </form>

            <form class="row-container base-panel" fxLayout="column"
                *ngIf="notificationsRecords.length > 0">
                <h4 translate>NOTIFICATIONS_SETTING</h4>
                <div *ngFor="let record of notificationsRecords; let i = index">
                    <div class="input-container">
                        <label translate>{{'NOTIFICATION_RULE_' + record.userNotificationRecurrency.roleName}}</label>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <div>
                            <input type="checkbox" [id]="'recurrency-0' + i" [name]="'recurrency-0' + i"
                                [(ngModel)]="record.userNotificationRecurrency.day0"
                                (ngModelChange)="onNotificationsRecurrencyChange(record)">
                            <label [for]="'recurrency-0' + i" translate>WEEKDAY_0</label>
                        </div>
                        <div>
                            <input type="checkbox" [id]="'recurrency-1' + i" [name]="'recurrency-1' + i"
                                [(ngModel)]="record.userNotificationRecurrency.day1"
                                (ngModelChange)="onNotificationsRecurrencyChange(record)">
                            <label [for]="'recurrency-1' + i" translate>WEEKDAY_1</label>
                        </div>
                        <div>
                            <input type="checkbox" [id]="'recurrency-2' + i" [name]="'recurrency-2' + i"
                                [(ngModel)]="record.userNotificationRecurrency.day2"
                                (ngModelChange)="onNotificationsRecurrencyChange(record)">
                            <label [for]="'recurrency-2' + i" translate>WEEKDAY_2</label>
                        </div>
                        <div>
                            <input type="checkbox" [id]="'recurrency-3' + i" [name]="'recurrency-3' + i"
                                [(ngModel)]="record.userNotificationRecurrency.day3"
                                (ngModelChange)="onNotificationsRecurrencyChange(record)">
                            <label [for]="'recurrency-3' + i" translate>WEEKDAY_3</label>
                        </div>
                        <div>
                            <input type="checkbox" [id]="'recurrency-4' + i" [name]="'recurrency-4' + i"
                                [(ngModel)]="record.userNotificationRecurrency.day4"
                                (ngModelChange)="onNotificationsRecurrencyChange(record)">
                            <label [for]="'recurrency-4' + i" translate>WEEKDAY_4</label>
                        </div>
                    </div>
                    <div *ngIf="record.hasChanged">
                        <button class="btn-primary mr-15" (click)="saveUserNotificationRecurrency(record)">
                            <i class="fa fa-check-square-o"></i>
                            <span translate>SAVE</span>
                        </button>
                        <button class="btn-secondary" (click)="cancelUserNotificationRecurrency(record)">
                            <i class="fa fa-times-circle"></i>
                            <span translate>CANCEL</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div fxLayout="column" fxFlex.gt-sm="33" fxFlex="100" class="pl-15">
            <form (ngSubmit)="savePassword()" #passwordForm="ngForm">
                <!-- <div style="max-width: 300px;"> -->
                <h3 translate>CHANGE_PASSWORD</h3>
                <div class="row-container base-panel" fxLayout="column">
                    <div class="input-container">
                        <label translate>PASSWORD_OLD</label>
                        <input type="password" placeholder="{{ 'PASSWORD_OLD' | translate }}" [(ngModel)]="oldPassword" class="form-control" id="password" name="password" #passwordFormItem="ngModel" maxlength="255" required>
                    </div>
                    <div [hidden]="passwordFormItem.valid || passwordFormItem.pristine" class="alert alert-danger">
                        <span translate>REQUIRED_FIELD</span>
                    </div>

                    <div class="input-container with-help">
                        <label translate>PASSWORD_NEW</label>
                        <input type="password" placeholder="{{ 'PASSWORD_NEW' | translate }}" [(ngModel)]="newPassword" (ngModelChange)="validatePassword(newPassword)" (blur)="hidePassHelp()" (focus)="showPassHelp()" class="form-control" id="passwordNew" name="passwordNew" #passwordNewFormItem="ngModel" maxlength="255" required>
                        <div class="help row-container" *ngIf="passHelpVisible">
                            <div><i class="fa fa-times-circle fnt-orange" *ngIf="!newPassValid.lowercase"></i><i class="fa fa-check-circle fnt-green" *ngIf="newPassValid.lowercase"></i><span translate>PASSWORD_LOWERCASE</span></div>
                            <div><i class="fa fa-times-circle fnt-orange" *ngIf="!newPassValid.uppercase"></i><i class="fa fa-check-circle fnt-green" *ngIf="newPassValid.uppercase"></i><span translate>PASSWORD_UPPERCASE</span></div>
                            <div><i class="fa fa-times-circle fnt-orange" *ngIf="!newPassValid.number"></i><i class="fa fa-check-circle fnt-green" *ngIf="newPassValid.number"></i><span translate>PASSWORD_NUMBER</span></div>
                            <div><i class="fa fa-times-circle fnt-orange" *ngIf="!newPassValid.length"></i><i class="fa fa-check-circle fnt-green" *ngIf="newPassValid.length"></i><span translate>PASSWORD_LENGTH</span></div>
                        </div>
                    </div>                
                    <div [hidden]="passwordNewFormItem.valid || passwordNewFormItem.pristine" class="alert alert-danger">
                        <span translate>REQUIRED_FIELD</span>
                    </div>

                    <div class="input-container">
                        <label translate>PASSWORD_NEW_AGAIN</label>
                        <input type="password" placeholder="{{ 'PASSWORD_NEW_AGAIN' | translate }}" [(ngModel)]="newPasswordAgain" class="form-control" id="passwordNewAgain" #passwordNewAgainFormItem="ngModel" [validConfirmPassword]="newPassword" name="passwordNewAgain" maxlength="255" required>
                    </div>                             
<!--                     <div [hidden]="passwordNewAgainFormItem.valid || passwordNewAgainFormItem.pristine" class="alert alert-danger">
                        <span translate>REQUIRED_FIELD</span>
                    </div> -->

                    <div>
                        <button class="btn-primary" type="submit" [disabled]="!passwordForm.form.valid || !this.newPassValid.lowercase || !this.newPassValid.uppercase || !this.newPassValid.number || !this.newPassValid.length || busy" [ngClass]="{'ec-disabled': !passwordForm.form.valid || !this.newPassValid.lowercase || !this.newPassValid.uppercase || !this.newPassValid.number || !this.newPassValid.length || busy}">
                            <i class="fa fa-check-square-o"></i>
                            <span translate>SAVE</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    <!-- </div> -->
</div>