import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AreaPerm, PermissionsService } from '@app/services/permissions.service';
import { SharedService } from '@app/services/shared.service';

type Item = {
	name: string;
	class: string;
	areaPerms: AreaPerm[];
    routerLink: string;
    queryParams: string | undefined;
};

@Component({
  selector: 'app-admin-menu',
  templateUrl: 'admin-menu.component.html',
})
export class AdminMenuComponent  {
	public items: Item[] = [];
	private allItems: Item[];
	public isOpen: boolean;

	constructor(
        public sharedService: SharedService,
		private permissionsService: PermissionsService,
        private router: Router,
	) {
		this.allItems = [
			{
                name: 'CONTROLLING',
                class: 'fa fa-chevron-circle-right',
                areaPerms: ['controllingRead'],
                routerLink: 'controlling',
                queryParams: 'lastControllingQueryParams',
            },
			{
                name: 'MONITORING',
                class: 'fa fa-bar-chart',
                areaPerms: ['journalRead'],
                routerLink: 'monitoring',
                queryParams: 'lastMonitoringQueryParams',
            },
			{
                name: 'JOURNAL',
                class: 'fa fa-info-circle',
                areaPerms: ['journalRead'],
                routerLink: 'journal',
                queryParams: 'lastJournalQueryParams',
            },
			{
                name: 'CUSTOMERS_ADMINISTRATION',
                class: 'fa fa-chevron-circle-right',
                areaPerms: ['authorizedSellerCustomersRead'],
                routerLink: 'customers',
                queryParams: 'lastCustomersQueryParams',
            },
			{
                name: 'SUPPLIERS_ADMINISTRATION',
                class: 'fa fa-chevron-circle-right',
                areaPerms: ['suppliersRead'],
                routerLink: 'suppliers',
                queryParams: 'lastSuppliersQueryParams',
            },
			{
                name: 'USERS_ADMINISTRATION',
                class: 'fa fa-chevron-circle-right',
                areaPerms: ['usersAdmin'],
                routerLink: 'users-admin',
                queryParams: undefined,
            },
			{
                name: 'PRICE_LIST_ADMINISTRATION',
                class: 'fa fa-chevron-circle-right',
                areaPerms: ['PriceListUpdate'],
                routerLink: 'price-lists',
                queryParams: 'lastPriceListsQueryParams',
            },
			{
                name: 'AS_STOCK',
                class: 'fa fa-database',
                areaPerms: ['asStockManage'],
                routerLink: 'as-stock',
                queryParams: undefined,
            },
			{
                name: 'DISTRIBUTOR_STORES',
                class: 'fa fa-database',
                areaPerms: ['customerStockItemsRead', 'customerStockItemsReadAs'],
                routerLink: 'customer-stock',
                queryParams: undefined,
            },
			{
                name: 'ERP_DATA_CONSISTENCY_CHECK',
                class: 'fa fa-flask',
                areaPerms: ['erpDataConsistencyCheck'],
                routerLink: 'c-checks',
                queryParams: 'lastCChecksQueryParams',
            },
			{
                name: 'SENT_EMAILS',
                class: 'fa fa-paper-plane-o',
                areaPerms: ['sentEmails'],
                routerLink: 'sent-emails',
                queryParams: 'lastSentEmailsListQueryParams',
            },
		];
	}

	public ngOnInit(): void {
		this.items = this.allItems.filter((item) =>item.areaPerms.reduce(
			(acc, areaPerm) => this.permissionsService.hasPermission(areaPerm) || acc,
			false)
		);
	}

	public onSelect(item: Item): void {
        this.router.navigate(
            [`${this.sharedService.appSettings.language}/${item.routerLink}`],
            item.queryParams !== undefined ? { queryParams: this.sharedService[item.queryParams] } : {},
        );
	}

	public hide(): void {
		this.isOpen = false;
	}
	
	public show(): void {
		this.isOpen = true;
	}
}
