import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnDestroy } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { RfqService } from '../../../services/rfq/rfq.service';
import { RfqsService } from '../../../services/rfq/rfqs.service';
import { RfqsGridService } from '../../../services/rfq/rfqs.grid.service';
import { ToastService } from '../../../services/toastService/toast.service';

@Component({
    selector: 'app-add-to-rfq-dialog',
    templateUrl: 'add.to.rfq.dialog.component.html',
})
export class AddToRfqDialogComponent implements OnDestroy {
    doneSubject: Subject<any>;
    params: any;

    disableSubmit = false;

    busy: boolean;
    totalRows;

    addSubscribtion: Subscription;

    constructor(public bsModalRef: BsModalRef,
        private translateService: TranslateService,
        private rfqService: RfqService,
        private rfqsService: RfqsService,
        private rfqsGridService: RfqsGridService,
        private toastService: ToastService) {

        this.getRfqs = this.getRfqs.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        this.getColumns = this.getColumns.bind(this);

        this.addSubscribtion = this.rfqsGridService.subjectAddToRfq.subscribe((rfq: any) => {
            // add product to existing rfq
            this.busy = true;
            this.addToRfq(rfq.id).subscribe(() => {
                this.busy = false;
                this.toastService.addSuccess(this.translateService.instant('PRODUCT_ADDED_TO_RFQ') + ' ' + rfq.rfqNumber);
                this.doneSubject.next('confirm');
                this.bsModalRef.hide();
            }, err => {
                console.log(err);
                this.busy = false;
            });
        });
    }

    /**
     * Create new rfq and add product to that rfq
     */
    confirm() {
        this.disableSubmit = true;
        this.busy = true;
        let newRfqId, newRfqNumber;
        this.rfqsService.createRfq().pipe(switchMap(rfq => {
            newRfqId = rfq.id;
            newRfqNumber = rfq.rfqNumber;
            return this.addToRfq(newRfqId);
        }))
        .subscribe(() => {
            this.busy = false;
            this.toastService.addSuccess(this.translateService.instant('PRODUCT_ADDED_TO_NEW_RFQ') + ' ' + newRfqNumber);
            this.doneSubject.next('confirm');
            this.bsModalRef.hide();
        }, err => {
            console.log(err);
            this.busy = false;
        });
    }

    dismiss() {
        this.doneSubject.next('dismiss');
        this.bsModalRef.hide();
    }

    /**
     * Adds product to rfq
     * @param newRfqId
     */
    addToRfq(newRfqId) {
        return this.rfqService.addToRfq(newRfqId, [
            {
                rfqProduct: this.params.productName,
                amountRequired: this.params.amountRfq,
                brandCode: this.params.brandCode,
            }
        ]);
    }

    getRfqs(params) {
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: params.orderBy.colId + ' ' + params.orderBy.sort,
            'rfqState.operator': 'in',
            'rfqState.value': 'P'
        };

        this.rfqsService.getRfqs(query)
            .subscribe(data => {
                this.busy = false;
                this.totalRows = data.total;
                params.successCallback(data.rows, data.total);
            }, err => {
                this.busy = false;
                console.log(err);
            });
    }

    getGridOptions() {
        return this.rfqsGridService.getInAddDialogGridOptions();
    }
    getColumns() {
        return this.rfqsGridService.getInAddDialogColumns();
    }
    getColumnsDefs() {
        return this.rfqsGridService.getInAddDialogColumnDefs();
    }

    ngOnDestroy() {
        this.addSubscribtion.unsubscribe();
    }
}
