import { Component, OnInit, OnDestroy } from '@angular/core';
import { CCheckService } from '../c-check.service';
import { CCheckPurchaseOrderGridService } from './c-check-purchase-order.grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataGetterParams, TableColumn, ExportObj } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { SharedService } from '@services/shared.service';
import { Subject } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { BaseComponent } from '@app/components/base/base.component';

@Component({
    selector: 'app-c-check-purchase-order',
    templateUrl: './c-check-purchase-order.component.html',
    styleUrls: ['./c-check-purchase-order.component.scss']
})
export class CCheckPurchaseOrderComponent extends BaseComponent implements OnInit, OnDestroy {

    public totalRows: number;
    public filterItems: TableFilterItem[];
    public exportObj: ExportObj;
    public initialized = false;
    public refreshGrid$: Subject<void>;
    public checkDate: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService,
        private cCheckService: CCheckService,
        public gridService: CCheckPurchaseOrderGridService
    ) {
        super(router, route, sharedService);

        this.getCCheckPurchaseOrders = this.getCCheckPurchaseOrders.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
        
        this.gridService.initSelection();

        // To reload when menu item is clicked but it is already routed to this area
        this.refreshGrid$ = new Subject<void>();
        this.subscriptions.push(this.sharedService.subjectSameAreaClicked.subscribe(() => {
            this.refreshGrid$.next();
        }));

        const exportFunction = (query) => {
            return this.cCheckService.exportCCheckItems(this.route.snapshot.params['cCheckId'], query);
        };

        this.sharedService.translateService.get('ERP_DATA_CONSISTENCY_CHECK').subscribe(translation => {
            this.exportObj = {
                fileName: translation,
                export: exportFunction.bind(this)
            };
        })

        this.initialized = true;
    }

    public ngOnInit() {
        setTimeout(() => {    
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastCCheckPurchaseOrderQueryParams);
        });
    }

    public getCCheckPurchaseOrders(params: TableDataGetterParams): void {
        this.checkDate = this.route.snapshot.params['checkDate'];

        const query: EcmHttpQueryParams = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastCCheckPurchaseOrderQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastCCheckPurchaseOrderQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.cCheckService.getCCheckItems(this.route.snapshot.params['cCheckId'], query).subscribe(response => {
            this.totalRows = response.totalCount;
            if (!this.filterItems) { // only if not already have it; not to loose reference of selected
                this.filterItems = this.gridService.getFilterItems();
            }
            params.successCallback(response.rows, response.totalCount);
        }, err => {
            console.log(err);
            // Also in error to be able to cancel filter
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
        });
    }

    public getGridOptions(): GridOptions {
        return this.gridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.gridService.getColumns();
    }

    public getColumnsDefs(): ColDef[] {
        return this.gridService.getColumnDefs();
    }

}
