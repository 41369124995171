<div class="component-outer-container">
    <div class="journal-detail">
        <div [ngClass]="{'component-header-container': makeCollapsible}" (click)="toggleCollapse()"
        [attr.role]="makeCollapsible ? 'button': ''" data-target="collapseJournal">
            <h3><i class="fa fa-history"></i> <span translate>JOURNAL</span>
                <i *ngIf="makeCollapsible" [ngClass]="{'fa-caret-up': !isCollapsed, 'fa-caret-down': isCollapsed }" class="fa" style="float: right"></i>
            </h3>
        </div> <!-- end of component header container --->

        <div [@slideInOut]="isCollapsed"  [ngClass]="{'component-body-container': makeCollapsible}" id="collapseJournal">
            <ecm-table *ngIf="isCollapsedLazy"
                [dataGetter]="loadJournalItems"
                [optionsGetter]="optionsGetter"
                [columnsGetter]="columnsGetter"
                [columnsDefsGetter]="columnsDefsGetter"
                [filterItems]="filterItems"
                [showRefreshButton]="true" 
                [total]="totalRows"
                [observableRefresh]="subjectRefresh.asObservable()"
                [busy]="busy"
                [secondaryHeaderColor]="true"
                [suppressToUrl]="true"
                [tableNamePrefix]="'Journal'"
                [dropup]="true"
            ></ecm-table>
        </div><!-- end of component body container --->
    </div>
</div>
