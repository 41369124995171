export class BrandCategory {
	children: BrandCategory[];
	collapsed: boolean;
    constructor(
        public id:   	string,
        public name: 	string,
        public url:		string,
        public query:   any
    ){
    	this.children = [];
    	this.collapsed = true;
    }
}