import { Component, OnInit, OnDestroy } from '@angular/core';
import { PriceListService } from '../price-list.service';
import { PriceListDetailGridService } from './price-list-detail.grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableDataGetterParams, TableColumn, ExportObj, TableBulk } from '@app/model/table.model';
import { TableFilterItem } from '@app/model/table.filter.model';
import { SharedService } from '@services/shared.service';
import { Subject } from 'rxjs';
import { EcmHttpQueryParams } from '@app/services/http/ecm.http.service';
import { GridOptions, ColDef } from 'ag-grid-community';
import { BaseComponent } from '@app/components/base/base.component';

@Component({
    selector: 'app-price-list-detail',
    templateUrl: './price-list-detail.component.html',
    styleUrls: ['./price-list-detail.component.css']
})
export class PriceListDetailComponent extends BaseComponent implements OnInit, OnDestroy {

    priceListCode: string;
    currency: string;
    totalRows: number;
    filterItems: TableFilterItem[];
    exportObj: ExportObj;
    public bulk: TableBulk = {items: []}; // only the default 'delete' bulk action is available
    refreshGrid$ = new Subject<void>();
    deleteSelected$ = new Subject<any>();

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public sharedService: SharedService,
        private priceListService: PriceListService,
        public gridService: PriceListDetailGridService
    ) {
        super(router, route, sharedService);

        this.getPriceListDetail = this.getPriceListDetail.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        this.gridService.initSelection();

        // To reload when menu item is clicked but it is already routed to this area
        this.subscriptions.push(this.sharedService.subjectSameAreaClicked.subscribe(() => {
            this.refreshGrid$.next();
        }));

        this.sharedService.translateService.get('PRICE_LIST_SUMMARY').subscribe(translation => {
            this.exportObj = {
                fileName: translation,
                export: query => this.priceListService.exportPriceList(this.priceListCode, this.currency, query)
            };
        });

        // Bulk delete is not made via bulk actions but via this observable
        this.subscriptions.push(this.deleteSelected$.subscribe(ids => this.deleteSelected(ids)));
    }

    ngOnInit() {
        this.priceListCode = this.route.snapshot.params['priceListCode'];
        this.currency = this.route.snapshot.params['currency'];

        setTimeout(() => {
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastPriceListDetailQueryParams);
        });
    }

    private deleteSelected(ids: number[]): void {
        (ids.length === 0
            ? this.priceListService.deletePriceListItems(this.priceListCode, this.currency, this.route.snapshot.queryParams)
            : this.priceListService.deleteItems(ids)
        ).subscribe(
            () => {
                this.gridService.reloadCurrentGridPage$.next();
            },
            err => console.error(err)
        );
    }

    public getPriceListDetail(params: TableDataGetterParams): void {
        const query: EcmHttpQueryParams = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastPriceListDetailQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastPriceListDetailQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        this.priceListService.getPriceListDetail(this.priceListCode, this.currency, query).subscribe(response => {
            this.totalRows = response.totalCount;

            if (!this.filterItems) { // only if not already have it; not to loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
            params.successCallback(response.rows, response.totalCount);
        }, err => {
            console.log(err);
            // Also in error to be able to cancel filter
            if (!this.filterItems) { // only if not already to not loose fererence of selected
                this.filterItems = this.gridService.getFilterItems();
            }
        });
  }

    public getGridOptions(): GridOptions {
        return this.gridService.getGridOptions(this.route.snapshot.queryParams['page']);
    }

    public getColumns(): TableColumn[] {
        return this.gridService.getColumns();
    }

    public getColumnsDefs(): ColDef[] {
        return this.gridService.getColumnDefs();
    }

}
