<div class="row-container bkg-secondary" style="margin-top: -15px">
	<h3 class="fnt-primary" style="text-align: center; margin-top: 10px" translate>WELCOME_TO_SYSTEM_PLATFORM_ZVL</h3>
</div>
<div class="home row-container wider">
	<div class="zvl">
		<div fxLayout="column" fxLayout.gt-sm="row" class="icons" style="max-width: 1100px; margin: 10px auto;">
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<img [src]="'assets/images/login_' + sharedService.apParams.iconSet + '.png'" style="width: 70px" />
				<div style="margin-bottom: 10px" translate>LOGGED_USER_ZONE_INFO</div>
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<img [src]="'assets/images/contact_form_' + sharedService.apParams.iconSet + '.png'" style="width: 70px"/>
				<div style="margin-bottom: 10px" translate>ADDITIONAL_INFO_INFO</div>
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<img [src]="'assets/images/logistics_' + sharedService.apParams.iconSet + '.png'" style="width: 70px"/>
				<div style="margin-bottom: 10px" translate>LOGISTICS_INFO</div>
			</div>
		</div>
		<div fxLayout="column" fxLayout.gt-sm="row" class="icons" style="max-width: 1100px; margin: 0px auto 10px;">
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<button id="login" class="btn-primary" (click)="openLogin()"><span translate>LOGIN</span></button>
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<a href="{{contactForms[sharedService.appSettings.language]}}" target="_blank"><button class="btn-primary"><span translate>CONTACT_FORM</span></button></a>
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<a href="{{distributionNetwork[sharedService.appSettings.language]}}" target="_blank"><button class="btn-primary"><span translate>LOGISTICS</span></button></a>
			</div>
		</div>

		<div fxLayout="column" fxLayout.gt-sm="row" class="main-title" style="max-width: 1100px; margin: 10px auto;">
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<hr class="fnt-primary">
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<h3 class="fnt-primary" style="text-align: center" translate>HOME_PROFESSIONAL_MARKET</h3>
			</div>
			<div fxFlex.gt-sm="33" class="row-container center-text">
				<hr class="fnt-primary">
			</div>
		</div>

		<div fxLayout="column" fxLayout.gt-sm="row" style="max-width: 1100px; margin: 0 auto">
			<div fxFlex.gt-sm="250px" class="row-container">
			</div>
			<div fxFlex.gt-sm="50" class="row-container">
				<div *ngIf="showVideos && sharedService.apParams.video.home">
					<video width="100%" height="300" controls>
						<source [src]="sharedService.assetsS3BucketUrl + '/' + sharedService.appSettings.language + '/' + sharedService.apParams.video.home" type="video/mp4">
					</video>
				</div>
			</div>
			<div fxFlex.gt-sm="250px" class="row-container">
			</div>
		</div>
	</div>
	<!-- / ZVL -->

	<h4 class="fnt-primary" style="text-align: center" translate>OUR_BENEFITS</h4>

	<div fxLayout="column" fxLayout.gt-sm="row" class="icons" style="max-width: 1100px; margin: 10px auto;">
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/saving_' + sharedService.apParams.iconSet + '.png'" />
			<div translate>SAVING</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/availability_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>AVAILABILITY</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/speed_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>SPEED</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/simplicity_' + sharedService.apParams.iconSet + '.png'"/>
			<div translate>SIMPLICITY</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/support_' + sharedService.apParams.iconSet + '.png'" />
			<div translate>SUPPORT</div>
		</div>
		<div fxFlex.gt-sm="20" class="row-container center-text fnt-primary">
			<img [src]="'assets/images/mobile_app_' + sharedService.apParams.iconSet + '.png'" />
			<div translate>MOBILE_APP</div>
		</div>
	</div>
</div>