import { RfqService } from './../../../services/rfq/rfq.service';
import { RfqsService } from './../../../services/rfq/rfqs.service';
import { Component, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Animations } from '../../../animations';
import * as moment from 'moment';
import { SharedService } from '../../../services/shared.service';
import { Subject } from 'rxjs';
import { ToastService } from '@app/services/toastService/toast.service';

@Component({
    selector: 'rfq-detail-add-items',
    templateUrl: 'rfq.detail.add.items.component.html',
    animations: [Animations.slideInOut]
})
export class RfqDetailAddItemsComponent {
    @ViewChild('fileInput', { static: true }) fileInputElement: any;
    @Input() rfqId: number;
    addProductsVisible = false;
    productName = '';
    pastedExcel = '';
    fromFileObjects: { rfqProduct: string, amountRequired: string,
                       unitPriceRequired?: string, brandCode: string, dateRequired?: string }[] = [];
    pastedObjects: { rfqProduct: string, amountRequired: string,
                     unitPriceRequired?: string, brandCode: string, dateRequired?: string }[] = [];
    addFromFileBusy: boolean;
    addFromPasteBusy: boolean;
    brands: any[] = [];
    selectedBrandFromFile: { id: string, name: string };
    selectedBrandPasted: { id: string, name: string };

    searchTimeout: any;
    waitUntilNoBusyTimeout: any;
    searchProductsByNameString = '';
    searchBusy: boolean;
    totalProductsRows: number;
    subjectProductsRefresh: Subject<any>;
    subjectReloadCurrent: Subject<any>;

    constructor(
        // private router: Router,
        // private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        // private translate: TranslateService,
        private sharedService: SharedService,
        // private userService: UserService,
        // private currentOrderService: CurrentOrderService,
        // private currentOrderGridService: CurrentOrderGridService,
        // private catalogueService: CatalogueService,
        // private importBasketErrorDialogService: ImportBasketErrorDialogService
        private rfqService: RfqService,
        private rfqsService: RfqsService,
        private toastService: ToastService
    ) {
        const this_ = this;
        this.subjectReloadCurrent = rfqsService.getReloadCurrentSubject();
        // this.subjectReloadCurrent = currentOrderService.getReloadCurrentSubject();

        // this.getProducts = this.getProducts.bind(this);
        // this.getProductsGridOptions = this.getProductsGridOptions.bind(this);
        // this.getProductsColumns = this.getProductsColumns.bind(this);
        // this.getProductsColumnsDefs = this.getProductsColumnsDefs.bind(this);

        this.addProductsVisible = !!this.sharedService.user.preferences['current-orderAddPanelVisible'];
        this.productName = '';
        this.searchProductsByNameString = '';

        this.sharedService.brands.forEach(function (brand) {
            this_.brands.push({ id: brand.code, name: brand.name });
        });
        this.selectedBrandFromFile = this.brands[0];
        this.selectedBrandPasted = this.brands[0];
    }

    ngOnDestroy() {
        this.cdRef.detach(); // try this
    }

    toggleAddPanel() {
        this.addProductsVisible = !this.addProductsVisible;
        if (!this.addProductsVisible) {
            this.productName = '';
            // this.cancelProductsSearch();
        }
        // this.userService.setUserPreference('current-orderAddPanelVisible', this.addProductsVisible, true);
    }

  /**
	 * File was chosen in file input for order from file, call get objects
	 *
	 * @param fileInput - chosen file
	 */
    fileChangeEvent(fileInput: any) {
        const this_ = this;
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e: any) {
                this_.fromFileObjects = this_.getObjectsFromSeparatedString(reader.result, this_.selectedBrandPasted.id);
            }

            reader.readAsText(fileInput.target.files[0]);
        }
    }

  /**
	 * Text was pasted or changed in textarea for clippboard import of order
	 *
	 * @param newValue - string of changed value of textarea
	 */
    pasteExcelChanged(newValue: string) {
        this.pastedObjects = this.getObjectsFromSeparatedString(newValue, this.selectedBrandFromFile.id);
    }

  /**
	 * Change tab key to \t and not go to next focusable element when tab is pressed
	 * @param event - keydown event
	 * @param element - textarea html element
	 */
    pasteExcelKeyDown(event, element) {
        if (event.keyCode === 9) {
            const v = element.value,
                  s = element.selectionStart,
                  e = element.selectionEnd;
                element.value = v.substring(0, s) + '\t' + v.substring(e); element.selectionStart = element.selectionEnd = s + 1;
            this.pasteExcelChanged(element.value);
            return false;
        }
    }

  /**
	 * Returns object parsed from input separated string string
	 *
	 * @param separatedString  - string separated by new lines and by separator in lines
	 * @param separator  - separator used in lines
	 */
    getObjectsFromSeparatedString(separatedString: any, brandCode: string) {
        const objects = [];
        let separator: string;
        const rows = separatedString.split(/\r\n|\r|\n/g);
        if (rows.length > 0) {
            separator = rows[0].indexOf('\t') > -1 ? '\t' : ';';
        }
        const findsDot = new RegExp(/[\,\.]/g);             // regexp to find dot or comma
        rows.forEach(function (row: string) {
            if (row.length > 0) {
                let amount = '';
                let code = '';
                let price = '';
                let date = '';
                const splittedRowBySeparator = row.split(separator);
                if (splittedRowBySeparator.length > 0) {
                    code = splittedRowBySeparator[0];
                }
                if (splittedRowBySeparator.length > 1) {
                    amount = splittedRowBySeparator[1].replace(/\ /g, '');
                    amount = amount.replace(findsDot, '.');
                }
                if (splittedRowBySeparator.length > 2) {
                  price = splittedRowBySeparator[2].replace(/\ /g, '');
                  price = price.replace(findsDot, '.');
              }
                if (splittedRowBySeparator.length > 3) {
                    if (splittedRowBySeparator[3].length !== 0) {
                        const slovakDate = new RegExp(/(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})/g); // 22.04.2018
                        const englishDate = new RegExp(/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})/g); // 04/22/2018
                        if ( splittedRowBySeparator[3].match(slovakDate) !== null || splittedRowBySeparator[3].match(englishDate) !== null) {
                            const momentDate = moment(splittedRowBySeparator[3], 'D.M.YYYY');
                            if (momentDate.isValid()) {
                                date = momentDate.toISOString();
                            } else {
                                date = splittedRowBySeparator[3];
                            }
                        } else {
                            date = splittedRowBySeparator[3];
                        }
                    }
                }

                if (code.length > 0 || amount.length > 0 || price.length > 0  || date.length > 0) {
                    // objects.push({ amountOrdered: amount, eCommProductId: code, brandCode: brandCode, deliveryDate: date });
                    /** označenie, počet kusov, požadovaná jednotková cena, požadovaný dátum doručenia. */
                    const data: any = {rfqProduct: code, amountRequired: amount, brandCode: brandCode};
                    if ( price !== '') { data.unitPriceRequired = price; }
                    if ( date !== '') { data.dateRequired = date; }
                    objects.push(data);
                }
            }
        });
        return objects
    }

    /**
	 * Adds products to order from object parsed from file
	 */
    fileCsvAddToOrder() {
        this.addFromFileBusy = true;
        this.rfqService.addToRfq(this.rfqId, this.fromFileObjects)
            .subscribe(data => {
                // reset file input
                this.fileInputElement.nativeElement.value = '';
                this.fromFileObjects = [];
                this.addFromFileBusy = false;
                this.subjectReloadCurrent.next();
                if (data.toInsert.errors && data.toInsert.errors.length > 0) {
                    this.showDetailImportErrorDialog(data);
                } else {
                    this.toastService.addSuccess('PRODUCT_ADDED_TO_RFQ_GENERAL');
                }
            }, err => {
                this.addFromFileBusy = false;
                console.log(err);
            });
    }

  /**
	 * Adds products to order from object parsed from clipboard
	 */
    ctrlVAddToOrder() {
        this.addFromPasteBusy = true;
        this.rfqService.addToRfq(this.rfqId, this.pastedObjects)
            .subscribe(data => {
              // reset textarea input
                this.pastedExcel = '';
                this.pastedObjects = [];
                this.addFromPasteBusy = false;
                this.subjectReloadCurrent.next(); // refresh grid after insert data
                if (data.toInsert.errors && data.toInsert.errors.length > 0) {
                    this.showDetailImportErrorDialog(data);
                } else {
                    this.toastService.addSuccess('PRODUCT_ADDED_TO_RFQ_GENERAL');
                }
            }, err => {
                this.addFromPasteBusy = false;
                console.log(err);
            });
    }

  /**
	 * Onselect brand
	 */
    onSelectBrandFromFile(item) {
        // console.log(item);
        this.selectedBrandFromFile = { id: item.selected.id, name: item.selected.name };
    }
    onSelectBrandPasted(item) {
        // console.log(item);
        this.selectedBrandPasted = { id: item.selected.id, name: item.selected.name };
    }

    /**
	 * Shows error dialog of import to rfq
	 */
    showDetailImportErrorDialog(data: any) {
        this.rfqService.confirm(data).subscribe( res => {
            if (res.rows) {
                this.rfqService.addToRfq(this.rfqId, res.rows).subscribe(addedData => {
                    if (addedData.toInsert.errors && addedData.toInsert.errors.length > 0) {
                        this.showDetailImportErrorDialog(addedData);
                    }
                    this.subjectReloadCurrent.next();
                }, err => {
                    console.log(err);
                });
            }
        });
    }

}
