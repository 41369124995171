<div class="row-container wider footer-page">
	<h3><i class="fa fa-video-camera"></i> <span translate>MANUALS</span></h3>
	<div *ngIf="sharedService.permissions && showVideos">
		<div class="row-container bigger">
			<h4 translate>MANUAL_LOGIN</h4>
		    <video width="560" height="350" controls>
		        <source [src]="path + 'login.mp4'" type="video/mp4">
		    </video>
		</div>
		<div class="row-container bigger">
			<h4 translate>MANUAL_CATALOGUE</h4>
		    <video width="560" height="350" controls>
		        <source [src]="path + 'catalogue.mp4'" type="video/mp4">
		    </video>
		</div>
		<div class="row-container bigger">
			<h4 translate>MANUAL_ORDER_CREATE</h4>
		    <video width="560" height="350" controls>
		        <source [src]="path + 'create_order.mp4'" type="video/mp4">
		    </video>
		</div>
		<div class="row-container bigger">
			<h4 translate>MANUAL_COMMENT</h4>
		    <video width="560" height="350" controls>
		        <source [src]="path + 'comment.mp4'" type="video/mp4">
		    </video>
		</div>
	</div>
</div>