import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@services/shared.service';
import { TableService } from '@services/table.service';
import { CellChange, EditedIdsObject } from '@model/table.model';
import { TableFilterItem, TableFilterItemTypes, TableFilterItemOperators } from '@model/table.filter.model';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { BaseGridService, GridServiceOptions } from '@services/base/base-grid.service';
import { GridDateRendererComponent } from '@components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { Subject, Observable } from 'rxjs';
import { GridInputEditorComponent } from '@app/components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridGenericRowActionsRendererComponent, GridGenericRowActionsRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridGenericRowActionsRendererComponent/grid.generic.row.actions.renderer.component';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { PriceListItem } from '@app/model/priceList.model';
import { GridSelectedRendererComponent } from '@app/components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '@components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { PriceListService } from '../price-list.service';
import { GridHeaderActionsRendererComponent } from '@components/tableComponent/gridRendererComponents/gridHeaderActionsRendererComponent/grid.header.actions.renderer.component';
import { map } from 'rxjs/operators';
import { AppAreas } from '@app/model/appArea.model';

@Injectable()
export class PriceListDetailGridService extends BaseGridService {
    public editedIdsObj: EditedIdsObject = {};
    public reloadCurrentGridPage$ = new Subject<void>();
    private selected$: Subject<any> = new Subject<any>();
    public allSelected$: Subject<any> = new Subject<any>();
    private cellChanged$: Subject<CellChange> = new Subject<CellChange>();
    private cancelRows$: Subject<any> = new Subject<any>();

    constructor (
        private translateService: TranslateService,
        protected sharedService: SharedService,
        protected tableService: TableService,
        private priceListService: PriceListService
    ) {
        super(tableService, sharedService);
    }

    public getGridServiceOptions(): GridServiceOptions {
        return {
            gridNameDashed: AppAreas['price-list'],// 'price-list',
            gridNameCamel: 'priceList',
            columnList: [
                { id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30 },
                { id: 'brandCode', name: 'BRAND', checked: true, orderBy: false, orderDirection: 'ASC', width: 60 },
                { id: 'productName', name: 'PRODUCT_ID', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'productCode', name: 'PRODUCT_CODE', checked: true, orderBy: true, orderDirection: 'ASC', width: 110 },
                { id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'personChangedERP', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'dateChangedSYS', name: 'DATE_OF_CREATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
                { id: 'actions', hidden: true, name: 'ACTIONS', checked: true, orderBy: false, orderDirection: 'ASC', width: 80 }
            ].filter(item => !this.priceListService.isColumnHidden(item.id))
        };
    }

    public getColumnDefs(): ColDef[] {
        const colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this.getSelection(),
                    observable: this.selected$,
                    editedIdsObj: this.editedIdsObj
                },
                headerComponent: GridHeaderSelectAllRendererComponent,
                headerComponentParams: {
                    selection: this.getSelection(),
                    editedIdsObj: this.editedIdsObj,
                    observable: this.allSelected$
                },
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('brandCode').name),
                field: 'brandCode',
                width: this.getColumn('brandCode').width,
                minWidth: this.getColumn('brandCode').width,
                cellStyle: {'text-align': 'left'}
            },
            {
                headerName: this.translateService.instant(this.getColumn('productName').name),
                field: 'productName',
                width: this.getColumn('productName').width,
                minWidth: this.getColumn('productName').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('productCode').name),
                field: 'productCode',
                width: this.getColumn('productCode').width,
                minWidth: this.getColumn('productCode').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPrice').name),
                field: 'unitPrice',
                width: this.getColumn('unitPrice').width,
                minWidth: this.getColumn('unitPrice').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this.editedIdsObj,
                    decimal: true,
                    decimalLength: 4,
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams: {
                    editedIdsObj: this.editedIdsObj,
                    inputType: 'decimal',
                    maxLength: 11,
                    decimalLength: 4,
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                },
                editable: true
            },
            {
                headerName: this.translateService.instant(this.getColumn('personChangedERP').name),
                field: 'personChangedERP',
                width: this.getColumn('personChangedERP').width,
                minWidth: this.getColumn('personChangedERP').width,
                cellStyle: { 'text-align': 'left' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateChangedSYS').name),
                field: 'dateChangedSYS',
                width: this.getColumn('dateChangedSYS').width,
                minWidth: this.getColumn('dateChangedSYS').width,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true }
            },
            {
                headerName: this.translateService.instant('ACTIONS'),
                field: 'actions',
                width: this.getColumn('actions').width,
                minWidth: this.getColumn('actions').width,
                maxWidth: this.getColumn('actions').width,
                headerClass: (params) => this.headerClassFunc(params) + ' active-on-edited-rows',
                headerComponent: null,
                headerComponentFramework: GridHeaderActionsRendererComponent,
                headerComponentParams: {
                    isRowValid: row => true,
                    editedIdsObj: this.editedIdsObj,
                    cancelObservable: this.cancelRows$,
                    save: (items) => this.updateRows(items)
                },
                cellRendererFramework: GridGenericRowActionsRendererComponent,
                cellRendererParams: this.getActionsCellRendererParams(),
                sortable: false,
            }
        ];

        return this.tableService.filterSuppressColumns(
            colDefs.filter(item => !this.priceListService.isColumnHidden(item.field)),
            this.getGridServiceOptions().gridNameCamel);
    }

    private getActionsCellRendererParams(): GridGenericRowActionsRendererComponentParams {
        return {
            editedIdsObj: this.editedIdsObj,
            rowActionsDef: [
                {
                    id: 'delete',
                    type: 'ICON_ONLY',
                    iconClass: 'fa fa-trash',
                    popover: 'DELETE_SELECTED',
                    method: (params) => this.deleteRow(params)
                }
            ],
            cellChanged$: this.cellChanged$,
            save: (item) => this.updateRow(item),
            cancel$: this.cancelRows$
        };
    }

    public getFilterItems(): TableFilterItem[] {
        const filterItems: TableFilterItem[] = [
            ...(
                this.sharedService.brands.length > 1 ? [{
                    id: 'brandCode', name: 'BRAND', type: TableFilterItemTypes.multiselect,
                    allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                    values: this.sharedService.brands.map(brand => ({ id: brand.code, name: brand.code }))
                }] : []
            ),
            {
                id: 'productName', name: 'PRODUCT_ID', type: TableFilterItemTypes.text,
                maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth
            },
            {
                id: 'priceList.dateChangedSYS', name: 'DATE_OF_CREATION', type: TableFilterItemTypes.dateRange,
                valueFrom: '', valueTo: ''
            }
        ]

        return this.tableService.filterSuppressColumns(filterItems, this.getGridServiceOptions().gridNameCamel);
    }

    private deleteRow(iCellRendererParams: ICellRendererParams) {
        this.priceListService.deleteItems([iCellRendererParams.data.id]).subscribe(
            () => {
                this.reloadCurrentGridPage$.next();
            },
            err => console.error(err)
        );
    }

    private updateRow(priceListItem: PriceListItem): Observable<any> {
        return this.priceListService.updateItems([
            { id: priceListItem.id, unitPrice: priceListItem.unitPrice }])
            .pipe(map(response => {
                this.reloadCurrentGridPage$.next();
                return response;
            }));
    }

    private updateRows(priceListItems: PriceListItem[]): Observable<any> {
        return this.priceListService.updateItems(priceListItems.map(priceListItem =>
                ({ id: priceListItem.id, unitPrice: priceListItem.unitPrice })
            ))
            .pipe(map(response => {
                this.reloadCurrentGridPage$.next();
                return response;
            }));
    }
}
