export class Journal {
    constructor(
        public authorizedSellerId:   string,
        public authorizedSellerName: string,
        public customerId:   string,
        public customerName: string,
        public userId: 		 string,
        public dateCreated:  string,
        public journalValue: string,
        public typeCode:     string,
        public typeName:     string,
        public priority:     string,
        public process:      string,
        public fullName:     string,  
        public sortName:     string
    ){

    }
}