import { Injectable } from '@angular/core';
import { AreaPerm, PermissionsService } from '@services/permissions.service';

// Definition for homepage - used after login and in user profile page
interface HomePageDef {
    id: string; // area definition from server
    name: string; // translation identifier
    permission: AreaPerm; // areaPerm from persmissions.service
    route: string; // frontend url of area
}

@Injectable()
export class HomepageService {
    private homepageSettingsDef: HomePageDef[] = [
        { id: 'cataloque', name: 'CATALOGUE', permission: 'cataloqueRead', route: 'catalogue' },
        { id: 'purchase-catalogue', name: 'PURCHASE_CATALOGUE', permission: 'purchaseCatalogueRead', route: 'purchase-catalogue' },
        { id: 'rfq', name: 'RFQS', permission: 'rfqRead', route: 'rfqs' },
        { id: 'prfq', name: 'PURCHASE_RFQS', permission: 'purchaseRfqRead', route: 'prfqs' },
        { id: 'basket', name: 'CART', permission: 'ordersCreate', route: 'current-order' },
        { id: 'orders', name: 'ORDERS', permission: 'ordersRead', route: 'orders' },
        { id: 'shipments', name: 'EXPEDITIONS', permission: 'shipmentsRead', route: 'shipments' },
        { id: 'deliveryOrders', name: 'DELIVERY_ORDERS', permission: 'deliveryOrdersRead', route: 'delivery-orders' },
        { id: 'invoices', name: 'INVOICES', permission: 'invoicesRead', route: 'invoices' },
        { id: 'journal', name: 'JOURNAL', permission: 'journalRead', route: 'journal' },
        { id: 'monitoring', name: 'MONITORING', permission: 'monitoringRead', route: 'monitoring' },
        { id: 'customers', name: 'CUSTOMERS_ADMINISTRATION', permission: 'authorizedSellerCustomersRead', route: 'customers' },
        { id: 'usersAdmin', name: 'USERS_ADMINISTRATION', permission: 'usersAdmin', route: 'users-admin' },
        { id: 'aSStock', name: 'AS_STOCK', permission: 'asStockManage', route: 'as-stock' },
        { id: 'priceLists', name: 'PRICE_LIST_ADMINISTRATION', permission: 'PriceListUpdate', route: 'price-lists' },
        { id: 'suppliers', name: 'SUPPLIERS_ADMINISTRATION', permission: 'suppliersRead', route: 'suppliers' }
    ];

    public constructor(
        private permissionsService: PermissionsService
    ) {}

    public getHomepageSettingsDef(): HomePageDef[] {
        return this.homepageSettingsDef.map(item => Object.assign({}, item));
    }

    public getHomepageRoute(userHomepage: string): string {
        let route;
        let homepage = this.homepageSettingsDef.filter(item => item.id === (userHomepage ? userHomepage : 'orders'))[0];
        // check perm on one saved homepage otherwise this is orders
        if (this.permissionsService.hasPermission(homepage.permission)) {
            route = homepage.route;
        } else {
            if (this.permissionsService.hasPermission('ordersRead')) {
                homepage = this.homepageSettingsDef.filter(item => item.id === 'orders')[0];
                route = homepage.route;
            } else {
                // if isnt saved or orders, do forEach on array and find first match.
                let foundId = false;
                this.homepageSettingsDef.some((homepageDefault) => {
                    if (this.permissionsService.hasPermission(homepageDefault.permission)) {
                        route = homepageDefault.route;
                        foundId = true;
                        return true;
                    }
                });
                // if nothing found - use fallback to route home
                if (!foundId) { route = 'home'; }
            }
        }
        return route;
    }

}
