import { Observable ,  Subject } from 'rxjs';
import { CustomerStoresAvailabilityDialogComponent } from './customer.stores.availability.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class CustomerStoresAvailabilityDialogService {
    bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(params: any): Observable<any> {
        this.bsModalRef = this.modalService.show(CustomerStoresAvailabilityDialogComponent, {class: 'customer-stores-dialog-override'});
        this.bsModalRef.content.doneSubject = new Subject<any>();
        this.bsModalRef.content.params = params;

        return this.bsModalRef.content.doneSubject;
    }
}
