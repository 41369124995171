import { Component } from '@angular/core';
import { AgRendererComponent } from "ag-grid-angular";
import { SharedService } from '../../../../services/shared.service';
import { DiscussionService } from '../../../../services/discussion.service';
import { DiscussionDialogService } from '../../../../components/discussion/discussionDialogComponent/discussion.dialog.service';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { DiscussionPrivateConfig } from '@app/model/discussion.model';

export interface GridCommentRendererComponentParams {
  itemArea: string;
  idAttrName: string;
  showOnlyEmptyChildTableKeyItems?: boolean,
  loadForEachItem?: boolean;
  showAllComments?: boolean;
  showPrivateControls?: boolean;
  privateConfig?: DiscussionPrivateConfig;
  isHidden?: (params: ICellRendererParams) => boolean;
  getItemLabel?: (item: any) => string;
}

interface MyParams extends ICellRendererParams, GridCommentRendererComponentParams {}

@Component({
    selector: 'link-cell',
    template: `<div class="clickable comment-count center-text" [ngClass]="{'empty': params.data.postsCount == 0}"
                  style="margin: -8px 0 -4px 0; padding: 8px 0 4px 0; text-align: left;" (click)="comment()"
                  *ngIf="!hidden">
                  <i class="fa fa-comments"></i>&ngsp;<span>{{params.data.postsCount > 0 ? params.data.postsCount : ''}}</span>
               </div>`
})
export class GridCommentRendererComponent implements AgRendererComponent {
    params: MyParams;
    hidden: boolean;

    constructor(
      private sharedService: SharedService,
      private discussionService: DiscussionService,
      private discussionDialogService: DiscussionDialogService
    ) {
    }

    agInit(params: MyParams):void {
      this.params = params;

      if (this.params.isHidden) {
        this.hidden = this.params.isHidden(this.params)
      }

      this.discussionService.getCommentAddedToItemObservable().subscribe(
        () => {
          if (!this.params.loadForEachItem) {
            this.params.data.postsCount = this.sharedService.currentDiscussionComments
              .filter(item => {
                if (item.childTableKey && !item.displayItem.lineNumber) { // used in orders, delivery orders
                  return item.childTableKey === this.params.data.id;
                } else if (item.displayItem.lineNumber) { // used in rfq
                  return item.displayItem.lineNumber === this.params.data.lineNumber;
                }
                return false;
              }).length;
            this.sharedService.appComponent.cdRef.detectChanges();
          }
        },
      );
    }

    public comment(): void {
      this.discussionDialogService.confirm(
        this.params.data,
        this.params.itemArea,
        this.params.idAttrName,
        this.params.loadForEachItem,
        this.params.showOnlyEmptyChildTableKeyItems,
        this.params.getItemLabel,
        this.params.showAllComments,
        this.params.showPrivateControls,
        this.params.privateConfig
      ).subscribe(
        res => {
          if (res > 0) {
            this.params.data.postsCount = this.params.data.postsCount + res;
            this.discussionService.getCommentObservable().next('');
          }
        }  
      );
    }

    refresh(): boolean { // has to be implemented in ag-grid cell, return false if we do not handle refresh and just destroy and recreate cell
        return false;
    }
}