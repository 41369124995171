import { OriginalValue, BaseSelection, BaseTSelection } from "./table.model";

export class Shipment {
    constructor(
        public id: 		        	number,
        public shipmentNumberEComm: string,
        public shipmentDateRequested: Date,
        public shipmentNote:        string,
        public shipmentState:       string,
        public dateCreated:         string,
        public createdSortName:     string,
        public createdFullName:     string,
        public dateChanged:         string,
        public changedSortName:     string,
        public changedFullName:     string,
        public url:                 string,
        public customerId:          string,
        public customerName:        string,
        public authorizedSellerId:  string,
        public authorizedSellerName:string,
    ) {

    }
}

export interface ShipmentItem {
    amountBeingPacked: number, // 0
    amountDelivered: number, //  0
    amountInPacking: number, //  10
    amountInvoiced: number, //  0
    amountOrdered: number, //  70
    amountRemaining: number, //  70
    amountReserved: number, //  50
    amountShipment: number, //  40
    amountShipmentInProcess: number, //  0
    amountShipmentInShipping: number, //  0
    availableToShip: number, //  50
    brandCode: string, // "ZVL"
    currency: string, // "EUR"
    deliveryDate: Date, // "2020-07-07T00:00:00.000Z"
    eCommProductId: string, // "1202"
    edited: boolean, // true
    id: string, // "1428/1202/ZVL/2020-07-07/D"
    itemNote: string, // "sre"
    itemState: string, // "C"
    orderCreated: Date, // "2020-07-02T13:59:39.000Z"
    orderNumberCustomer: string, // "sre"
    orderNumberEComm: string, // "EO2000020"
    packing: string, // "D"
    packingsToShip: number, // "3"
    productName: string, // "1202"
    rowPrice: number, //  44.1
    rowWeight: number, //  1.92
    toShip: number, //  1
    unitPrice: number, //  1.1025
    weight: number, //  0.048
    // added locally
    url: string, "/catalogue/ZVL/1202"
    originalValues?: OriginalValue[]; // [{ colId: 'amountOffered', value: row.amountOffered }]
}

export interface ShipmentItemsResponse {
    rows: ShipmentItem[],
    shipIds: string[], // ["758/UC206/ZVL/2018-02-02/A", "1428/1202/ZVL/2020-07-07/D"]
    totalAmount: number, // 377
    totalCount: number, // 15
    totalShipAmountZeroCount: number, // count of rows with zero amount
    totalPrice: {[currency: string]: number}, // {EUR: 1243.2, USD: 26.67}
    totalShipAmount: number, // 41
    totalShipCount: number, // 2
    totalShipPrice: {[currency: string]: number}, // {EUR: 46.35}
    totalShipWeight: number, // 2.2
    totalWeight: number, // 152.233
    totalAmountCurrent: number,
    totalWeightCurrent: number,
    totalValueCurrent: number
}

export interface ShipmentItemsSelection extends BaseTSelection<ShipmentItem> {
    // BaseSelection
    // all: false
    // ids: {758/UC206/ZVL/2018-02-02/A: {…}, 1428/1202/ZVL/2020-07-07/D: {…}, 1429/1202/ZVL/2020-07-07/D: {…}}
    // visible: false
    // newSelectedIds: {1429/1202/ZVL/2020-07-07/D: {…}}
    updatedIds?: {[id: string]: ShipmentItem} // {1428/1202/ZVL/2020-07-07/D: {…}}
    deselectedIds?: {[id: string]: ShipmentItem},
    totalAmount: number, // 41
    totalWeight: number, // 2.2
    totalValue: {[currency: string]: number}, // {EUR: 46.35}
    totalAmountSelected: number, // 41
    totalWeightSelected: number, // 2.2
    totalValueSelected: {[currency: string]: number}, // {EUR: 46.35}
}

export interface ShipmentItemUpdateRow {
    id: string,
    shipmentAmount: number
}
export interface ShipmentItemsUpdateSet {
    items: {
        toInsert: ShipmentItemUpdateRow[],
        toUpdate: ShipmentItemUpdateRow[],
        toDelete: ShipmentItemUpdateRow[]
    },
    shipmentDateRequested: string,
    shipmentNote: string,
    action?: 'CREATE_DELIVERY_ORDER'  // optional attribute
}

export interface SaveShipmentResponse {
    shipment: {
        id: number
    },
    newDeliveryOrder?: {
        id: number
    }
}

export interface BulkImportRow {
    shipmentAmount: string,
    eCommProductId: string,
    brandCode: string,
    orderNumberEComm: string
}

export interface BulkImportResponse {
    errors: {
        row: BulkImportRow,  // original data
        error: {  // only error atributes will be included
            eCommProductId?: string, // "Error string in a case of a formal error of the attribute",
            shipmentAmount?: string, // "Error string in a case of a formal error of the attribute",
            orderNumberEComm?: string, //  "Error string in a case of a formal error of the attribute",
            _other: 'eCommProductId not ordered' |  'Product not found' | 'Not enough amount to ship' 
            // 'eCommProductId not ordered' - when such product could not be found in the customer orders
            // 'Product not found' - when requested product does not exists
            // 'Not enough amount to ship' - when requested more than ordered 
        }
    }[],
    shipmentId: number, // 292
    someShipmentAmountWasRounded: boolean, // false
    someShipmentAmountWasRoundedToZero: boolean, // false
    successCount: number // 2
}
