<div *ngIf="invoice">
	<div class="row-container wider">
		<div class="row-container">
	    	<button class="btn-secondary" routerLink="/{{sharedService.appSettings.language}}/invoices" [queryParams]="sharedService.lastInvoicesQueryParams">
				<i class="fa fa-arrow-circle-left"></i>
				<a translate>BACK</a>
			</button>
	    </div>
	    <div class="row-container">
	    	<h3 style="display: inline-block;"><i class="fa fa-file-text"></i> <span translate>INVOICE</span> {{invoice.invoiceNumber}}</h3>
	    </div>
	</div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>INVOICE_NUMBER</h4>
			<span>{{invoice.invoiceNumber}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>DATE_OF_ISSUE</h4>
			<span>{{invoice.issueDate | localdate:sharedService.appSettings.language}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>INVOICE_TAX_DATE</h4>
			<span>{{invoice.taxDate | localdate:sharedService.appSettings.language}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>INVOICE_DUE_DATE</h4>
			<span>{{invoice.dueDate | localdate:sharedService.appSettings.language}}</span>
		</div>
	</div>

	<div class="row-container wider" fxLayout="column" fxLayout.gt-sm="row">
		<div fxFlex.gt-sm="25" class="row-container" *ngIf="sharedService.user.representative == 'AS'">
			<h4 translate>INVOICE_CUSTOMER</h4>
			<span>{{invoice.sellerName}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>INVOICE_PAYMENT_CONDITION</h4>
			<span>{{invoice.paymentCondition}}</span>
		</div>
		<div fxFlex.gt-sm="25" class="row-container">
			<h4 translate>INVOICE_PRICE_WITHOUT_TAX</h4>
			<span>{{invoice.totalPrice | localcurrency:sharedService.appSettings.language:invoice.currency}}</span>
		</div>
	</div>

	<div class="row-container wider">
	    <div fxLayout="column">
	    	<ecm-table [dataGetter]="getInvoiceItems" 
	                     [optionsGetter]="getGridOptions"
	                     [columnsGetter]="getColumns"
	                     [columnsDefsGetter]="getColumnsDefs"
	                     [total]="totalRows"
	                     [observableRefresh]="subjectRefresh.asObservable()"
                     	 [showRefreshButton]="true"
	                     [busy]="busy"
	                     [secondaryHeaderColor]="true"
                     	 [exportObj]="exportObj"></ecm-table>
	    </div>
	</div>
</div>