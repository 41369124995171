import { Component, HostListener, ViewContainerRef, ChangeDetectorRef, Inject, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DeliveryOrder } from '../../../model/delivery.order.model';
import { SharedService } from '../../../services/shared.service';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { LanguageService } from '../../../services/language.service';
import { DeliveryOrdersService } from '../../../services/deliveryOrder/delivery.orders.service';
import { DeliveryOrderGridService } from '../../../services/deliveryOrder/delivery.order.grid.service';
import * as moment from 'moment';
import { QuestionDialogService } from '../../../components/questionDialogComponent/question.dialog.service';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
import { ExportObj } from '@app/model/table.model';

@Component({
    selector: 'delivery-order',
    templateUrl: 'delivery.order.component.html'
})
export class DeliveryOrderComponent implements OnInit {
    @ViewChild('discussion') discussion;

    deliveryOrder: DeliveryOrder;

    totalRows: number;
    filterItems: any[];
    subjectRefresh = new Subject<any>();
    public exportObj: ExportObj;

    busy: boolean;
    objectKeys = Object.keys; // for ngFor iterating over object keys

    edited = {  // what part of form is edited (changed)
        discussionInForm: false
    }

    /**
     * Return true if something in detail is edited in detail = user has unsaved changes
     * Method used in routing module by CanDeactivateDetail class
     */
    isEditing(): boolean {
        return Object.keys(this.edited).reduce((acc, key) => acc || this.edited[key], false);
    }

    constructor(private cdRef: ChangeDetectorRef,
        private router: Router,
        public route: ActivatedRoute,
        public sharedService: SharedService,
        private languageService: LanguageService,
        private deliveryOrdersService: DeliveryOrdersService,
        private deliveryOrderGridService: DeliveryOrderGridService,
        private questionDialogService: QuestionDialogService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {

        this.subjectRefresh = new Subject<any>();

        this.getDeliveryOrderItems = this.getDeliveryOrderItems.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);
    }

    ngOnInit() {
        this.deliveryOrdersService.getDeliveryOrder(this.route.snapshot.params['id']).subscribe(data => {
            this.deliveryOrder = data;

            const exportFunction = function (query) {
                return this.deliveryOrdersService.exportDetail(this.deliveryOrder.id, query);
            };
            this.sharedService.translateService.get('SUNDAY', { value: '' }).subscribe((res: string) => { // TO BE SHURE LANGUAGE IS LOADED
                this.exportObj = {
                    fileName: this.sharedService.translateService.instant('DELIVERY_ORDER') + '_' +
                        (this.deliveryOrder.deliveryNumberERP ? this.deliveryOrder.deliveryNumberERP : '(' + this.deliveryOrder.number + ')'),
                    export: exportFunction.bind(this)
                };
            });
        }, err => {
            console.log(err)
        });

        const this_ = this;
        setTimeout(function () {
            // navigate with orderby parameter
            this_.sharedService.navigateWithOrderby(this_.getColumns(),
                this_.route.snapshot.queryParams, this_.sharedService.lastDeliveryOrderDetailQueryParams);
        }, 0);
    }

    getDeliveryOrderItems(params) {
        const this_ = this;
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        this.sharedService.lastDeliveryOrderDetailQueryParams = {};
        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            this.sharedService.lastDeliveryOrderDetailQueryParams[key] = this.route.snapshot.queryParams[key];
            if (key !== 'page') {
                query[key] = this.route.snapshot.queryParams[key]
            }
        }

        if (query.skip >= 0 && query.top > 0) {
            this.deliveryOrdersService.getDeliveryOrderItems(this.route.snapshot.params['id'], query)
                .subscribe(data => {
                    this.totalRows = data.totalCount;
                    if (!this.filterItems) { // only if not already to not loose fererence of selected
                        this.filterItems = this.getFilterItems();
                    }
                    params.successCallback(data.rows, data.total);
                    this.busy = false;
                }, err => {
                    console.log(err);
                    this.busy = false;
                });
        }
    }
    getGridOptions() {
        return this.deliveryOrderGridService.getGridOptions(parseInt(this.route.snapshot.queryParams['page']));
    }
    getFilterItems() {
        return this.deliveryOrderGridService.getFilterItems([], []);
    }
    getColumns() {
        return this.deliveryOrderGridService.getColumns();
    }
    getColumnsDefs() {
        return this.deliveryOrderGridService.getColumnDefs();
    }

    scrollToComments() {
        this.pageScrollService.scroll({ document: this.document, scrollTarget: '#newComment' });
        this.discussion.focusNewComment();
    }

    discussionInFormEdited(value: boolean) {
        this.edited.discussionInForm = value;
    }
}
