import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { AddToRfqService } from '@components/catalogueComponent/addToRfqDialogComponent/add.to.rfq.dialog.service';
import { GridLinkRendererComponent, GridLinkRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from '@components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '@components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridRowPriceRendererComponent } from '@components/tableComponent/gridRendererComponents/gridRowPriceRendererComponent/grid.row.price.renderer.component';
import { GridInputRendererComponent } from '@components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent } from '@components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridPlusRendererComponent } from '@components/tableComponent/gridRendererComponents/gridPlusRendererComponent/grid.plus.renderer.component';
import { GridCatalogueAvailabilityRendererComponent } from '@components/tableComponent/gridRendererComponents/gridCatalogueAvailabilityRendererComponent/grid.catalogue.availability.renderer.component';
import { TableColumn } from '@app/model/table.model';
import { CatalogueService, CatalogueModes } from './catalogue.service';
import { BasketService } from '../basket/basket.service';
import { Product } from '@app/model/product.model';
import { ColDef } from 'ag-grid-community';

@Injectable()
export class CatalogueGridService {

    constructor (
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private addToRfqService: AddToRfqService,
        private catalogueService: CatalogueService,
        private basketService: BasketService
    ) {
    }

    public getColumns(catalogueMode: CatalogueModes, forceOriginal?: boolean): TableColumn[] {
        let original = [
            {id: 'brandCode', name: 'BRAND', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 60 },
            {id: 'productName', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'shortName', name: 'SHORT_NAME', checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'productionGroupCode1Name', name: 'PRODUCT_CATEGORY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'productionGroupCode2Name', name: 'PRODUCT_SUBCATEGORY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'amountInPacking', name: 'AMOUNT_MOQ', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'customerStockAmount', name: 'CUSTOMER_STOCK', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'amount', name: 'STOCK', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountUponVerification', name: 'AMOUNT_UPON_VERIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountPlAs2', name: 'STOCK_PL', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountSrAs2', name: 'STOCK_SR', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountSkAs4', name: 'STOCK_SR', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'amountCzAs4', name: 'STOCK_CZ', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'futureDeliveryPeriod1_4', name: 'IN_PRODUCTION', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod5_7', name: 'IN_PRODUCTION_PLAN', checked: true, orderBy: false, orderDirection: 'ASC', width: 100},
            {id: 'futureDeliveryAs2', name: 'IN_PRODUCTION', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery0_30As5', name: `IN_PRODUCTION`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery30_As5', name: `IN_PRODUCTION_PLAN`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'dimensionID', name: 'd', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'dimensionOD', name: 'D', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'dimensionB', name: 'B', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'rdMin', name: 'RD_MIN', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'rdMax', name: 'RD_MAX', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'weight', name: 'WEIGHT', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'amountOrder', name: 'UNIT', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 60 },
            {id: 'priceTotal', name: 'PRICE', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
            {id: 'add', name: '', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 30 },
            {id: 'amountRfq', name: 'UNIT', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 56 },
            {id: 'addRfq', name: '', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 30},
        ];
        original = original.filter(item => !this.catalogueService.isColumnHidden(item.id, catalogueMode));

        original = this.tableService.filterSuppressColumns(original, 'catalogue');
        const restored = this.sharedService.user.preferences['catalogueTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    public getColumn(colId, catalogueMode: CatalogueModes) {
        const columns = this.getColumns(catalogueMode);
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    /**
     * returns gridOptions for products grid e.g. in catalogue
     */
    getProductGridOptions(startPage: number, catalogueMode: CatalogueModes) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'catalogueTablePageSize', headerClassFunc);
        gridOptions.headerHeight = 25;  // one line
        gridOptions.columnDefs = this.getColumnDefs(catalogueMode);
        return gridOptions;
    }

    public getColumnDefs(catalogueMode: CatalogueModes): any[] {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        function yellowHeaderClassFunc(params) {
            return 'bkg-secondary fnt-primary';
        }

        let colDefs: any[] = [
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('BRAND'),
                        field: 'brandCode',
                        width: this.getColumn('brandCode', catalogueMode).width,
                        minWidth: 70,
                    }
                ]
            }
        ];

        // productName
        const productNameChild: ColDef = {
            headerName: this.translateService.instant('PRODUCT_ID'),
            field: 'productName',
            tooltipField: 'tooltip', // in a case of the product name change
            width: this.getColumn('productName', catalogueMode).width,
            minWidth: 70,
            cellRendererFramework: GridLinkRendererComponent
        };
        const productNameChildRendererParams: GridLinkRendererComponentParams = {
            iconClassAttr: 'iconClass'
        };
        productNameChild.cellRendererParams = productNameChildRendererParams;
        const productName = {
            headerName: '',
            headerClass: headerClassFunc,
            children: [ productNameChild ]
        };
        colDefs.push(productName);

        colDefs = colDefs.concat([
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('SHORT_NAME'),
                        field: 'shortName',
                        width: this.getColumn('shortName', catalogueMode).width,
                        minWidth: 70
                    }
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('PRODUCT_CATEGORY'),
                        field: 'productionGroupCode1Name',
                        width: this.getColumn('productionGroupCode1Name', catalogueMode).width,
                        minWidth: 70,
                    }
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('PRODUCT_SUBCATEGORY'),
                        field: 'productionGroupCode2Name',
                        width: this.getColumn('productionGroupCode2Name', catalogueMode).width,
                        minWidth: 70,
                    }
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant(this.sharedService.apParams.id === 5 ? 'UNIT_PRICE_WITHOUT_VAT' : 'UNIT_PRICE'),
                        field: 'unitPrice',
                        width: 80, minWidth: 50,
                        cellRendererFramework: GridPriceRendererComponent,
                        cellRendererParams: { currency: this.sharedService.getUserCustomerCurrency() },
                        suppressSizeToFit: true
                    },
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('AMOUNT_MOQ'),
                        field: 'amountInPacking',
                        width: 80, minWidth: 50,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                        suppressSizeToFit: true
                    },
                ]
            },
            {
                headerName: this.translateService.instant('AVAILABILITY'),
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('CUSTOMER_STOCK'),
                        field: 'customerStockAmount',
                        width: this.getColumn('customerStockAmount', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('STOCK'),
                        field: 'amount',
                        width: this.getColumn('amount', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('AMOUNT_UPON_VERIFICATION'),
                        field: 'amountUponVerification',
                        width: this.getColumn('amountUponVerification', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRenderer: (params) => {
                            const product: Product = params.data;
                            if (product.amountUponVerification > 0) {
                                return `
                                    <i class="fa fa-phone" aria-hidden="true" title="${this.translateService.instant('UPON_VERIFICATION_INFO')}"></i>
                                `;
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        headerName: this.translateService.instant('STOCK_PL'),
                        field: 'amountPlAs2',
                        width: this.getColumn('amountPlAs2', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('STOCK_SR'),
                        field: 'amountSrAs2',
                        width: this.getColumn('amountSrAs2', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('STOCK_SR'),
                        field: 'amountSkAs4',
                        width: this.getColumn('amountSkAs4', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('STOCK_CZ'),
                        field: 'amountCzAs4',
                        width: this.getColumn('amountCzAs4', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION'),
                        field: 'futureDeliveryPeriod1_4',
                        width: this.getColumn('futureDeliveryPeriod1_4', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridCatalogueAvailabilityRendererComponent,
                        cellRendererParams: { availabilityGetter: this.catalogueService.getProductAvailability }
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PLAN'),
                        field: 'futureDeliveryPeriod5_7',
                        width: this.getColumn('futureDeliveryPeriod1_4', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridCatalogueAvailabilityRendererComponent,
                        cellRendererParams: { availabilityGetter: this.catalogueService.getProductAvailability }
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION'),
                        field: 'futureDeliveryAs2',
                        width: this.getColumn('futureDeliveryAs2', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION'),
                        field: 'futureDelivery0_30As5',
                        width: this.getColumn('futureDelivery0_30As5', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridCatalogueAvailabilityRendererComponent,
                        cellRendererParams: { availabilityGetter: this.catalogueService.getProductAvailability },
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PLAN'),
                        field: 'futureDelivery30_As5',
                        width: this.getColumn('futureDelivery30_As5', catalogueMode).width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridCatalogueAvailabilityRendererComponent,
                        cellRendererParams: { availabilityGetter: this.catalogueService.getProductAvailability },
                    },
                ]
            },
            {
                headerName: this.translateService.instant('DIMENSIONS'),
                headerClass: headerClassFunc,
                children: [
                    {headerName: 'd', field: 'dimensionID', width: this.getColumn('dimensionID', catalogueMode).width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {headerName: 'D', field: 'dimensionOD', width: this.getColumn('dimensionOD', catalogueMode).width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {headerName: 'B', field: 'dimensionB', width: this.getColumn('dimensionB', catalogueMode).width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {
                        headerName: this.translateService.instant('RD_MIN'), 
                        field: 'rdMin', 
                        width: this.getColumn('rdMin', catalogueMode).width, 
                        minWidth: 30, 
                        cellRendererFramework: GridNumberRendererComponent, 
                        suppressSizeToFit: true
                    },
                    {
                        headerName: this.translateService.instant('RD_MAX'), 
                        field: 'rdMax', 
                        width: this.getColumn('rdMax', catalogueMode).width, 
                        minWidth: 30, 
                        cellRendererFramework: GridNumberRendererComponent, 
                        suppressSizeToFit: true
                    }
                ]
            },
            {
                headerName: this.translateService.instant('WEIGHT'),
                headerClass: headerClassFunc,
                children: [
                    {headerName: 'kg', field: 'weight', width: this.getColumn('weight', catalogueMode).width, minWidth: 80,
                    cellRendererFramework: GridNumberRendererComponent, cellRendererParams: {addDecimalZero: true, decimals: 3},
                    cellStyle: {'text-align': 'right'}, suppressSizeToFit: false}
                ]
            },
            {
                headerName: this.translateService.instant('TO_ORDER'),
                headerClass: yellowHeaderClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('UNIT'),
                        field: 'amountOrder', width: 60, minWidth: 60, suppressSizeToFit: true,
                        sortable: false,
                        headerClass: yellowHeaderClassFunc,
                        cellRendererFramework: GridInputRendererComponent,
                        cellRendererParams: {
                            number: true,
                            maxWidth: '56px'
                        },
                        cellEditorFramework: GridInputEditorComponent,
                        cellEditorParams: {
                            onEnter: (data) => {
                                this.basketService.addToOrder([
                                    {
                                        amountOrdered: data.amountOrder,
                                        eCommProductId: data.eCommProductId,
                                        brandCode: data.brandCode,
                                        deliveryDate: ''
                                    }]).subscribe(result => {
                                        data.amountOrder = null;
                                        data.priceTotal = 0.00;
                                    }, err => console.log(err));
                            },
                            inputType: 'number',
                            maxWidth: '56px'
                        },
                        editable: true,
                    },
                    {
                        headerName: '',
                        field: 'add', width: 30, minWidth: 30, headerClass: yellowHeaderClassFunc, suppressSizeToFit: true,
                        sortable: false, cellRendererFramework: GridPlusRendererComponent
                    },
                    {
                        headerName: this.translateService.instant('PRICE').toLowerCase(),
                        field: 'priceTotal',
                        width: 80,
                        headerClass: yellowHeaderClassFunc,
                        suppressSizeToFit: true,
                        sortable: false,
                        cellRendererFramework: GridRowPriceRendererComponent,
                        cellRendererParams: { amountColId: 'amountOrder', currency: this.sharedService.getUserCustomerCurrency() }
                    }
                ]
            },
            {
                headerName: this.translateService.instant('TO_RFQ'),
                headerClass: yellowHeaderClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('UNIT'),
                        field: 'amountRfq', width: 60, minWidth: 60, suppressSizeToFit: true,
                        sortable: false,
                        headerClass: yellowHeaderClassFunc,
                        cellRendererFramework: GridInputRendererComponent,
                        cellRendererParams: {
                            number: true,
                            maxWidth: '56px'
                        },
                        cellEditorFramework: GridInputEditorComponent,
                        cellEditorParams: {
                            onEnter: (data) => {
                                this.openAddToRfqDialog({ ...data }).subscribe(result => {
                                    if (result === 'confirm') {
                                        data.amountRfq = null;
                                    }
                                });
                            },
                            inputType: 'number',
                            maxWidth: '56px'
                        },
                        editable: true,
                    },
                    {
                        headerName: '',
                        field: 'addRfq', width: 30, minWidth: 30, headerClass: yellowHeaderClassFunc, suppressSizeToFit: true,
                        sortable: false,
                        cellRendererFramework: GridPlusRendererComponent,
                        cellRendererParams: {
                            onClick: (params, data) => {
                                this.openAddToRfqDialog(data).subscribe(result => {
                                    if (result === 'confirm') {
                                        params.data.amountRfq = null;
                                    }
                                });
                            }
                        }
                    }
                ]
            }
        ]);

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'catalogue');
        colDefs = this.filterHiddenColumns(colDefs, this.catalogueService.isColumnHidden, catalogueMode);
        return colDefs
    }

    private filterHiddenColumns(colDefs: any[], isColumnHidden: (field: string, catalogueMode: CatalogueModes) => boolean,
        catalogueMode: CatalogueModes
    ): any[] {
        return colDefs
            .filter(colDef => colDef.field ? !isColumnHidden(colDef.field, catalogueMode) : true)
            .map(colDef => colDef.children
                ? Object.assign({}, colDef, {
                    children: colDef.children.filter(child => !isColumnHidden(child.field, catalogueMode))
                })
                : colDef)
            .filter(colDef => colDef.children ? colDef.children.length > 0 : true);
    }

    openAddToRfqDialog(data) {
        return this.addToRfqService.confirm(data);
    }

}
