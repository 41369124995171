<div class="dropdown-component" [ngClass]="{'smaller': smaller, 'dropdown-multiselect': multiselect}">
    <div class="btn-group" dropdown [isOpen]="isOpen" [isDisabled]="disabled" (onShown)="show()" (onHidden)="hide()" [autoClose]="autoClose" [dropup]="dropup">
        <button #dropdownToggle type="button" class="btn btn-secondary" dropdownToggle>
            <span *ngIf="multiselect && !justButtonLabel"><i class="fa fa-list"></i></span>
            <span class="fa fa-circle" style="margin-right: 4px;"
                 *ngIf="selected && ((!multiselect && selected.dotId) || (multiselect && selected.dotId && multiSelectedItems.length === 1))"
                 [ngClass]="{'fnt-gray':         selected.dotId == colors.gray,
                             'fnt-green':        selected.dotId == colors.green,
                             'fnt-lime':         selected.dotId == colors.lime,
                             'fnt-red':          selected.dotId == colors.red,
                             'fnt-yellow':       selected.dotId == colors.yellow,
                             'fnt-black':        selected.dotId == colors.black,
                             'fnt-blue-lighter': selected.dotId == colors.blue,
                             'fnt-blue':         selected.dotId == colors.blueDarker }">
            </span>
            <span *ngIf="!multiselect">{{(selected.short ? selected.short : (selected.name === undefined ? selected : selected.name)) | translate}}</span> 
            <span *ngIf="multiselect">{{getMultiSelectedBtnText() | translate}}</span> 
            <i class="fa fa-caret-down caret"></i>
        </button>
        <ul #popover *dropdownMenu class="dropdown-menu" role="menu">
            <div *ngIf="multiselect && !disableOptionAll" class="dropdown-menu-search">
                <div *ngIf="search" class="bkg-gray fnt-primary row-container wider" style="margin-top: -5px;" fxLayout="row">
                    <input #searchMultiple class="fnt-black" type="text" [(ngModel)]="searchMultipleItemsText" (ngModelChange)="filterMultiValues(searchMultipleItemsText)" (keyup)="searchKeyUp($event)" placeholder="{{'SEARCH' | translate}}">
                    <i class="fa fa-times-circle clickable" style="margin: 5px" (click)="resetSearch()" *ngIf="searchMultipleItemsText.length > 0"></i>
                </div>
                <div class="bkg-gray fnt-primary row-container wider" style="margin-top: -5px;" fxLayout="row">
                    <div fxFlex="50" fxLayoutAlign="start center">
                        <span class="clickable" (click)="multiselectToggleAll()" *ngIf="innerItems.length > 0"><i class="fa" [ngClass]="{'fa-square-o': multiSelectedItems.length != innerItems.length, 'fa-check-square-o': multiSelectedItems.length === innerItems.length}"></i>&ngsp;<span>{{(multiselectAllLabel ? multiselectAllLabel : 'MULTISELECT_FILTER_ALL') | translate | lowercase}}</span> {{(searchMultipleItemsText.length > 0 ? (multiselectFoundLabel ? multiselectFoundLabel : 'SEARCH_RESULTS_PERSON') : '') | translate}}</span>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="end center" *ngIf="disableSelectionOnSearch">
                        <button class="btn-primary" style="margin-left: 5px;"
                            (click)="multiselectApplyWhenSelectionOnSearchDisabled()">
                            <span translate>APPLY</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="dropdown-menu-content">
                <li role="menuitem" *ngFor="let item of innerItems" (click)="select(item)" class="fnt-black">
                    <ng-template #popTemplate>
                        <div *ngFor="let title of titleGetter(item)">{{title}}</div>
                    </ng-template>
                    <a class="dropdown-item fnt-black">
                        <div  *ngIf="multiselect" style="display: inline-block; width: 13px; margin-right: 4px;"><i class="fa" [ngClass]="{'fa-square-o': !item.checked, 'fa-check-square-o': item.checked}"></i></div>
                        <span class="fa fa-circle" 
                            *ngIf="item.dotId"
                            [ngClass]="{'fnt-gray':         item.dotId == colors.gray,
                                        'fnt-green':        item.dotId == colors.green,
                                        'fnt-lime':         item.dotId == colors.lime,
                                        'fnt-red':          item.dotId == colors.red,
                                        'fnt-yellow':       item.dotId == colors.yellow,
                                        'fnt-black':        item.dotId == colors.black,
                                        'fnt-blue-lighter': item.dotId == colors.blue,
                                        'fnt-blue':         item.dotId == colors.blueDarker }">
                        </span>
                        <span translate> {{(prefix ? prefix : '') + (item.name === undefined ? item : item.name)}}</span>
                        <i *ngIf="titleGetter" class="fa fa-info-circle fnt-primary" [popover]="popTemplate" container="body" triggers="mouseenter:mouseleave"></i>
                    </a>
                </li>
                <div class="row-container" *ngIf="innerItems.length === 0" translate>EMPTY_TABLE</div>
            </div>
        </ul>
    </div>
</div>