<preloader></preloader>

<div id="content" class="content">
	<div class="inner">
		<div id="top-navigation" class="top-navigation">
			<div class="row-container wider bkg-primary-darker quote-and-lang" fxLayout="row">
				<div fxFlex="50">
					<strong *ngIf="sharedService.apParams.headerSlogan" class="fnt-secondary" translate>TOP_QUOTE</strong>
				</div>
				<div class="fnt-secondary" fxFlex="50" fxLayoutAlign="end center" *ngIf="sharedService.apParams.languages.length > 1">
					<span *ngFor="let lang of sharedService.apParams.languages; let last = last">
						<strong class="clickable" [ngClass]="{'fnt-white': selectedLanguage == lang.toLowerCase()}" (click)="changeLanguage(lang.toLowerCase())">{{lang}}</strong><span class="padded-side" *ngIf="!last">|</span>
					</span>
				</div>
			</div>

			<div class="row-container bigger wider bkg-primary" fxLayout="row">
				<div fxFlex="250px">
					<a [href]="'http://' + sharedService.apParams.contact.web" target="_blank"><img src="{{'../../assets/images/' + sharedService.apParams.logoFileName}}" class="logo"/></a>
				</div>

				<div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" style="margin-top: -10px;">
<!-- 					<div class="btn-animated" routerLink="/{{sharedService.appSettings.language}}/login" *ngIf="!sharedService.permissions">
			      		<a routerLink="/{{sharedService.appSettings.language}}/login" translate>LOGIN</a>
			      		<div class="underline"></div>
			    	</div> -->
			    	<div class="btn-animated" (click)="openLogin()" *ngIf="!sharedService.permissions && !sharedService.apParams.eCommLogoInHeader">
			      		<span translate>LOGIN</span>
			      		<div class="underline"></div>
					</div>
					<img src="{{'../../assets/images/ecommerce_white.png'}}" class="logo" *ngIf="!sharedService.permissions && sharedService.apParams.eCommLogoInHeader"/>
					
					<!-- NEWS BUTTON NEW DESIGN -->
					<div class="btn-animated news-outer-container" routerLink="/{{sharedService.appSettings.language}}/news"
						[queryParams]="sharedService.lastNewsQueryParams" 
						*ngIf="sharedService.permissions && sharedService.user && sharedService.params?.features?.news && sharedService.user.representative !== 'supplier'" 
						permissionHide perm="news" action="GET" style="outline: none">
						<div style="display:none">{{newsService.timerStart}}</div>
						<div class="news-inner-ontainer" fxLayoutAlign="end center" style="outline: none; margin: 0 5px 0 0;">
							<span  style="outline: none" translate>NEWS</span>&nbsp;
							<span class="news-icon fnt-white"  [ngClass]="{'has-new-news': newsCount > 0}" style="outline: none"><i class="fa fa-envelope-o" aria-hidden="true" style="outline: none; font-size: 20px"></i></span>
							<span class="news-count-right-top" *ngIf="newsCount > 0" style="outline: none">{{newsCount}}</span> 
						</div>
						<div class="underline"></div>
					</div>
					<span class="fnt-white" style="padding-top: 8px;" 
						*ngIf="sharedService.permissions && sharedService.user && sharedService.params?.features?.news && sharedService.user.representative !== 'supplier'"
						permissionHide perm="news" action="GET">|</span>
					

					<div class="btn-animated" routerLink="/{{sharedService.appSettings.language}}/user-profile" *ngIf="sharedService.permissions && sharedService.user">
						<div fxLayoutAlign="end">
							<span>{{sharedService.user.lastName}} {{sharedService.user.firstName}}</span>
						</div>
						<div class="customer-name" fxLayoutAlign="end center">
							<span *ngIf="sharedService.user.representsCustomer && sharedService.user.representative == 'customer' && !sharedService.user.subcustomer"><span translate>CUSTOMER</span>: {{sharedService.user.representsCustomer.name}}</span>  
							<span *ngIf="sharedService.user.representsCustomer && sharedService.user.representative == 'customer' && sharedService.user.subcustomer"><span translate>SELLER</span>: {{sharedService.user.representsCustomer.name}}, <span translate>CUSTOMER</span>: {{sharedService.user.subcustomer}}</span>  
							<span *ngIf="sharedService.user.representsSupplier && sharedService.user.representative == 'supplier'"><span translate>SUPPLIER</span>: {{sharedService.user.representsSupplier.name}}</span>  
							<span *ngIf="sharedService.user.representsAuthorizedSeller && sharedService.user.representative == 'AS'"><span translate>AUTHORIZED_SELLER</span>: {{sharedService.user.representsAuthorizedSeller.name}}</span>
							<span *ngIf="sharedService.user.readAllRows && sharedService.user.representative == 'readAllRows'"><span translate>READ_ALL_ROWS</span></span>  
						</div>
						<div class="underline"></div>
					</div>

			    	<span class="fnt-white" style="padding-top: 8px;" *ngIf="sharedService.permissions">|</span>

			    	<!-- <div class="btn-animated" routerLink="/{{sharedService.appSettings.language}}/registration" *ngIf="!sharedService.permissions">
			      		<a routerLink="/{{sharedService.appSettings.language}}/registration" translate>REGISTRATION</a>
			      		<div class="underline"></div>
			    	</div> -->
					<div class="btn-animated" *ngIf="sharedService.permissions" routerLink="/{{sharedService.appSettings.language}}/logout">
			      		<span translate>LOGOUT</span>
			      		<div class="underline"></div>
			    	</div>

			    	<!-- <search></search> -->
				</div>
			</div>

	        <div class="main-menu row-container wider bkg-secondary" [ngClass]="{'bigger': sharedService.user}" fxLayout="row" *ngIf="sharedService.permissions">
<!-- 				<div fxFlex="100" class="area-title center-text"  *ngIf="!sharedService.permissions">
			        <h3><strong translate>PRODUCT_CATALOGUE</strong></h3>
			    </div> -->

				<div fxFlex="70" *ngIf="sharedService.permissions">
					<!-- Catalogue  -->  
					<!-- last permissions:  [perm]="['products','products-reduced1','products-reduced2']" action="GET"-->
				    <button id="mnav-btn-catalogue" permissionHide areaPerm="cataloqueRead"
				    	class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas.catalogue || sharedService.area === appAreas['catalogue-sale']}" 
				    	routerLink="/{{sharedService.appSettings.language}}/catalogue" 
				    	[queryParams]="sharedService.lastCatalogueQueryParams" *ngIf="sharedService.permissions"
				    	(click)="menuButtonClick('catalogue')" style="margin-right: 5px;">
			      		<i class="fa fa-chevron-circle-right"></i>
			      		<a translate>CATALOGUE</a>
					</button>
					
					<!-- Catalogue (purchase) -->  
					<button id="mnav-btn-purchase-catalogue" permissionHide areaPerm="purchaseCatalogueRead"
						*ngIf="sharedService.permissions && sharedService.params?.features?.prfq"
				    	class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas['purchase-catalogue']}" 
				    	routerLink="/{{sharedService.appSettings.language}}/purchase-catalogue" 
				    	[queryParams]="sharedService.lastPurchaseCatalogueQueryParams"
				    	(click)="menuButtonClick('purchase-catalogue')" style="margin-right: 5px;">
			      		<i class="fa fa-chevron-circle-right"></i>
			      		<a translate>PURCHASE_CATALOGUE</a>
			    	</button>

					<!-- RFQ -->
					<!-- last permissions:  perm="rfq" action="GET" -->
					<button id="mnav-btn-rfqs" permissionHide areaPerm="rfqRead"
							class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas.rfqs || sharedService.area === appAreas['rfqs-items'] || sharedService.area === appAreas.rfq}"
							routerLink="/{{sharedService.appSettings.language}}/rfqs"
							[queryParams]="sharedService.lastRfqsQueryParams"
							(click)="menuButtonClick('rfqs')" style="margin-right: 5px;"
					>
						<i class="fa fa-chevron-circle-right"></i>
						<a translate>RFQS</a>
					</button>

					<!-- Purchase RFQ -->
					<button id="mnav-btn-prfqs" permissionHide areaPerm="purchaseRfqRead"
						*ngIf="sharedService.params?.features?.prfq"
						class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas.prfqs || sharedService.area === appAreas['prfqs-items'] || sharedService.area === appAreas.prfq}"
						routerLink="/{{sharedService.appSettings.language}}/prfqs"
						[queryParams]="sharedService.lastPrfqsQueryParams"
						(click)="menuButtonClick('prfqs')" style="margin-right: 5px;"
					>
						<i class="fa fa-chevron-circle-right"></i>
						<a translate>PURCHASE_RFQS</a>
					</button>

			    	<!-- Orders -->
			    	<button id="mnav-btn-orders" permissionHide [perms]="['orders','orders-reduced']" action="GET" 
			    		class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas.orders || sharedService.area === appAreas['orders-items'] || sharedService.area === appAreas.order}" 
			    		routerLink="/{{sharedService.appSettings.language}}/orders" 
			    		[queryParams]="sharedService.lastOrdersSummaryQueryParams" *ngIf="sharedService.permissions"
			    		(click)="menuButtonClick('orders')" style="margin-right: 5px;">
			    		<i class="fa fa-chevron-circle-right"></i>
			      		<a translate>ORDERS</a>
			    	</button>

			    	<!-- Delivery orders -->
			    	<button permissionHide perm="delivery-orders" action="GET"
			    		class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas['delivery-orders'] || sharedService.area === appAreas['delivery-orders-items'] || sharedService.area === appAreas['delivery-order']}" 
			    		routerLink="/{{sharedService.appSettings.language}}/delivery-orders"
			    		[queryParams]="sharedService.lastDeliveryOrdersQueryParams"
			    		(click)="menuButtonClick('delivery-orders')" *ngIf="sharedService.permissions" style="margin-right: 5px;">
			    		<i class="fa fa-chevron-circle-right"></i>
			      		<a translate>DELIVERY_ORDERS</a>
			    	</button>

			    	<!-- Invoices -->
			    	<button permissionHide perm="invoices" action="GET" 
			    		class="btn-primary" [ngClass]="{'active': sharedService.area === appAreas.invoices || sharedService.area === appAreas['invoices-items'] || sharedService.area === appAreas.invoice}" 
			    		routerLink="/{{sharedService.appSettings.language}}/invoices" 
			    		[queryParams]="sharedService.lastInvoicesQueryParams" 
			    		(click)="menuButtonClick('invoices')" *ngIf="sharedService.permissions" style="margin-right: 5px;">	
			    		<i class="fa fa-chevron-circle-right"></i>
			      		<a translate>INVOICES</a>
			    	</button>

				</div>

				<div fxFlex="30" style="text-align: right;" *ngIf="sharedService.permissions">
					<app-admin-menu></app-admin-menu>
				
					<span style="padding-left: 30px;"></span>

			    	<!-- Basket - current order -->
			    	<button permissionHide areaPerm="ordersCreate"
			    		class="btn-secondary cart" [ngClass]="{'active': sharedService.area === appAreas['current-order']}" 
			    		routerLink="/{{sharedService.appSettings.language}}/current-order"
			    		[queryParams]="sharedService.lastProductsOfCurrentOrderQueryParams" 
						(click)="menuButtonClick('current-order')" *ngIf="sharedService.permissions" style="margin-left: 5px;">
			    		<img [src]="'../../assets/images/cart_' + sharedService.apParams.iconSet + '.png'" style="width: 20px; margin-right: 5px;"/>
			    		<span class="items-count" *ngIf="sharedService.basketItemsCount > 0">{{sharedService.basketItemsCount}}</span>
			    	</button>

			    	<!-- Shipments -->
 			    	<button permissionHide perm="shipments" action="GET"
			    		class="btn-secondary" [ngClass]="{'active': sharedService.area === appAreas.shipments || sharedService.area === appAreas['shipments-items'] || sharedService.area === appAreas.shipment}" 
			    		routerLink="/{{sharedService.appSettings.language}}/shipments"
			    		[queryParams]="sharedService.lastShipmentsQueryParams" 
						(click)="menuButtonClick('shipments')" *ngIf="sharedService.permissions" style="margin-left: 5px;">
			    		<img src="../../assets/images/shipment.png" style="width: 22px; margin-right: 5px;"/>
			      		<span class="items-count" *ngIf="sharedService.shipmentsCount > 0">{{sharedService.shipmentsCount}}</span>
					</button>
						
				</div>
			</div>
		</div>

		<div id="routed-content" [ngStyle]="{'min-height.px': routedContentMinHeight}">
			<router-outlet></router-outlet>
		</div>

		<div class="scroll-up" *ngIf="scrollToTopVisible" (click)="scrollToTop()">
			<i class="fa fa-arrow-up"></i>
		</div>

		<footer></footer>

		<iframe #chatIframe [src]="chatUrl" frameBorder="0" style="position: fixed; right: 80px; bottom: 0px; width: 260px; z-index: 10;" 
			[ngStyle]="{'height.px': chatHeight}"
			*ngIf="sharedService.apParams.showLivechat"
		></iframe>
	</div>
</div>
