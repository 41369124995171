import { Component, Input, Output, forwardRef, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormService } from '../../../services/form.service';
import { LocalNumberPipe } from '../../../locale.pipes.module';
import { SharedService } from '../../../services/shared.service'
import { LanguageService } from '../../../services/language.service'

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'number-input',
    template: `
                <ng-template #invalidError><span translate>{{invalidPopoverText}}</span></ng-template>
                <input #input="bs-popover" name="number" #number
                    class="form-control"
					[(ngModel)]="outerNumberValue"
					(ngModelChange)="onChange()"
					(keydown)="$event.stopPropagation(); onKeyDownOrPress($event);"
					(keypress)="$event.stopPropagation(); onKeyDownOrPress($event);"
					(focus)="focus.emit($event);"
					(blur)="blur.emit($event);"
					(mousedown)="mousedown.emit($event);"
					[disabled]="disabled"
					[ngClass]="{'ec-disabled': disabled, 'ec-invalid': !valid}"
					[attr.maxlength]="maxLength"
                    [ngStyle]="{'max-width': maxWidth, 'text-align': textAlign ? textAlign : 'right'}"
                    [placeholder]="placeholder ? placeholder : ''"
                    style="margin-top: -6px; padding: 4px; height: 27px; font-size: 98%; display: inline-block;"
                    [popover]="invalidError" container="body" triggers="" (mouseover)="mouseover(input)" (mouseleave)="input.hide()"
                    [adaptivePosition]="true" placement="right"/>
    `,
    providers: [
        {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NumberInputComponent), // If we don’t do this, we get a new instance as this is how DI in Angular
        multi: true
        }
    ]
})
export class NumberInputComponent implements ControlValueAccessor, OnInit {
    @ViewChild('number', { static: true }) numberInput: ElementRef;

    @Input() inputType: 'number' | 'decimal'; // input type to distinguish which keys are allowed
    @Input() maxWidth: string;                // max width style attribute to apply on input element
    @Input() maxLength: number;               // max length of input text
    @Input() decimalLength = 2;               // max length of decimal digits
    @Input() focusOnInit: boolean;            // true if element should be focused on ngInit
    @Input() disabled: boolean;               // true if input is disabled
    @Input() isValid?: Function;              // [optional] function that returns true if input is valid, if not defined function - valid is true by default
    @Input() invalidPopoverText?: string;         // [optional] string to place in invalid popover
    @Input() placeholder?: string;            // [optional] placeholder string
    @Input() textAlign: string;
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();          // blur event emitter
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();          // blur event emitter
    @Output() mousedown: EventEmitter<any> = new EventEmitter<any>();  // mousedown event emitter
    @Output() enterPressed: EventEmitter<any> = new EventEmitter<any>();  // enter button pressed event emitter
    @Output() escPressed: EventEmitter<any> = new EventEmitter<any>();    // esc button pressed event emitter

    valid = true;

    outerNumberValue;

    /** REGEXP dot, thousands, spaces */
    findsDot = new RegExp(/[\,\.]/g);                       // regexp to find dot or comma
    findsThousands = new RegExp(/\B(?=(\d{3})+(?!\d))/g);   // regexo to find thousands (3 numbers)
    findsSpaces = new RegExp(/\s+/g);                       // regexp to find spaces, can be suplemented with .replace(' ', '')

    locale: any;
    decimalDelimiter: string;
    thousandsDelimiter: string;
    thousandsDelimiterRegExp: any;

    propagateChange = (_: any) => {}; // on change use this.propagateChange(this.counterValue);

    constructor(
        private formService: FormService,
        private sharedService: SharedService,
        private languageService: LanguageService,
        private cdRef: ChangeDetectorRef
    ) {
        this.isNumberKey = this.isNumberKey.bind(this);
        this.isDecimalNumberKey = this.isDecimalNumberKey.bind(this);

        this.locale = this.languageService.getLocale();
        this.decimalDelimiter = this.locale[this.sharedService.appSettings.language].decimalDelimiter; // ',';
        this.thousandsDelimiter = this.locale[this.sharedService.appSettings.language].thousandsDelimiter; // ' ';
        if (this.thousandsDelimiter === ' ') {
            this.thousandsDelimiterRegExp = new RegExp(/\s+/g);
        } else if (this.thousandsDelimiter === ',') {
            this.thousandsDelimiterRegExp = new RegExp(/[\,]/g);
        } else {
            console.error('Unknown thousands delimiter');
        }
    }

    ngOnInit() {
        if (this.isValid) {
            this.valid = this.isValid(this.outerNumberValue);
        }

        setTimeout(() => {
            if (this.focusOnInit) {
                this.numberInput.nativeElement.focus();
            }
        }, 0);

    }

    writeValue(value: any) {
        if (value === null || typeof value === 'undefined') { value = ''; }
        // this.outerNumberValue = value.toString().replace(this.findsDot, '.');
        this.outerNumberValue = value.toString().replace(this.decimalDelimiter, '.');
        this.formatter();
    }

    // FORMATTER
    formatter(suppressAddDecimalZeros?: boolean) {
        // let newValue = this.outerNumberValue;
        let newValue = this.parser();
        if (this.inputType === 'decimal') {
            // newValue = this.parser();
            const splitted = newValue.split('.');
            if (splitted[1] && splitted[1].length > this.decimalLength) {
                newValue = newValue.substring(0, newValue.length - 1);
            }
            newValue = new LocalNumberPipe(this.languageService).transform(newValue,
                this.sharedService.appSettings.language, suppressAddDecimalZeros ? false : true, this.decimalLength);
        } else {
            newValue = new LocalNumberPipe(this.languageService).transform(
                newValue,
                this.sharedService.appSettings.language
            );
            // newValue = newValue.replace(this.findsSpaces, '').replace(' ', '').replace(this.findsThousands, ' ');
        }
        this.outerNumberValue = newValue;
    }

    // PARSER
    private parser(): string {
        // return this.outerNumberValue.toString().replace(this.thousandsDelimiterRegExp, '').replace(this.decimalDelimiter, '.');
        // Removes non-numeric chars which come here by ctrl+c
        const cleanedValue = this.removeNonNumericChars(this.outerNumberValue);
        return cleanedValue.replace(this.thousandsDelimiterRegExp, '').replace(this.decimalDelimiter, '.');
    }

    // Removes from a string anything but digits and '.' and ','
    private removeNonNumericChars(val: number | string): string {
        const strVal = val.toString();
        let result = '';

        for (let i = 0; i < strVal.length; i++) {
            const char = strVal[i];
            // Digit
            if (this.formService.numbers.indexOf(char) > -1) {
                result += char;
            // Decimal delimiter
            } else if (char === '.' || char === ',') {
                result += char;
            }
        }
        return result;
    }

    onChange() {
        setTimeout(() => {
            this.formatter(true);
            this.propagateChange(this.parser());
            setTimeout(() => {
                if (this.isValid) {
                    this.valid = this.isValid(this.parser());
                }
            }, 0);
        }, 0);
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    onKeyDownOrPress = (event: KeyboardEvent) => {
        // enter
        if (event.keyCode === 13) {
            this.enterPressed.emit();
            return;
        }
        // escape
        if (event.keyCode === 27) {
            this.escPressed.emit();
            return;
        }
        if (
            !this.isCtrlOrCmdC(event) &&                            // allow Ctrl+C / Cmd+C
            !this.isCtrlOrCmdV(event) &&                            // allow Ctrl+V / Cmd+V
            !(this.isKeyAllowed(event)) &&                          // allow number/string
            !((event.keyCode >= 37) && (event.keyCode <= 40)) &&    // allow arrows
            event.keyCode !== 46 &&                                 // allow delete
            event.keyCode !== 8 &&                                  // allow backspace
            event.keyCode !== 9                                     // allow tab
        ) {
            event.preventDefault(); // ignore the event
            return;
        }
        // if (!(event.charCode >= 37 && event.charCode <= 40)) { // edited only if not arrows
        //   this.onchange();
        // }
    }

    private isCtrlOrCmdC(event: KeyboardEvent): boolean {
        const ctrlDown = event.ctrlKey||event.metaKey // Mac support
        return ctrlDown && event.keyCode === 67; // c
    }

    private isCtrlOrCmdV(event: KeyboardEvent): boolean {
        const ctrlDown = event.ctrlKey||event.metaKey // Mac support
        return ctrlDown && event.keyCode === 86; // v
    }

    private isCtrlOrCmdX(event: KeyboardEvent): boolean {
        const ctrlDown = event.ctrlKey||event.metaKey // Mac support
        return ctrlDown && event.keyCode === 88; // x
    }

    isKeyAllowed(event): boolean {
        const handlers = {
            number: this.isNumberKey,
            decimal: this.isDecimalNumberKey
        };
        return handlers[this.inputType](event);
    }

    isNumberKey(event): boolean {
        return this.formService.numbers.indexOf(event.key) > -1;
    }

    isDecimalNumberKey(event): boolean {
        const containsDot = this.outerNumberValue ? this.outerNumberValue.toString().match(this.findsDot) : null;
        return (this.formService.numbers.indexOf(event.key) > -1) ||
        (this.outerNumberValue && [0, 44, 46].indexOf(event.charCode) > -1 && !containsDot); // 110 - numpad dot, 188 - comma, 190 - dot
    }

    mouseover(element) {
        if (!this.valid && this.invalidPopoverText) {
            element.show();
        }
    }
}
