import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RfqCloseQuestionnaire } from '@app/model/rfq.model';
import { Animations } from '../../../../animations';

@Component({
    selector: 'app-rfq-close-questionnaire',
    templateUrl: 'rfq-close-questionnaire.component.html',
    animations: [Animations.slideInOut]
})
export class RfqCloseQuestionnaireComponent implements OnInit {
    @Input() rfqCloseQuestionnaire: RfqCloseQuestionnaire | null;
    @Input() makeCollapsible: boolean; // set Header and Body for collapsible component (after click on header then body will collapse
    @Input() readOnly: boolean;
    @Input() isCollapsed; // set collapsed state for this element
    @Input() showTitle: boolean;
    @Input() submitButtonText?: string; // required when !readonly
    @Output() onSubmit: EventEmitter<RfqCloseQuestionnaire> = new EventEmitter();
    @Output() onCancel: EventEmitter<void> = new EventEmitter();
    
    public isFormValid: boolean = false;

    constructor() {}

    public ngOnInit(): void {
        this.validateForm();
    }

    public onElementChange(): void {
        this.validateForm();
    }

    public cancel(): void {
        this.onCancel.emit();
    }

    public submit(): void {
        this.onSubmit.emit(this.rfqCloseQuestionnaire);
    }

    private validateForm(): void {
        if (this.rfqCloseQuestionnaire) {
            this.isFormValid = this.rfqCloseQuestionnaire.priceNotSuitable ||
             this.rfqCloseQuestionnaire.deliveryDateNotSuitable ||
             this.rfqCloseQuestionnaire.highMoq ||
             this.rfqCloseQuestionnaire.constructionNotSuitable ||
             this.rfqCloseQuestionnaire.other ||
             !!this.rfqCloseQuestionnaire.notes;
        }
    }

    public toggleCollapse(): void {
        this.isCollapsed = (this.makeCollapsible) ? !this.isCollapsed : null;
    }
}
