import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '@services/shared.service';
import { CustomerStockService } from '../customer.stock.service';

interface SettingsOptions {
    customerStockForOtherCustomers: boolean;
    customerStockForSubcustomers: boolean;
}

@Component({
    selector: 'app-customer-stock-options-component',
    templateUrl: 'customer.stock.options.component.html'
})
export class CustomerStockOptionsComponent implements OnInit {
    options: SettingsOptions;

    constructor (
        private sharedService: SharedService,
        private customerStockService: CustomerStockService
    ) {
    }

    ngOnInit() {
        this.options = {
            customerStockForOtherCustomers:
                this.sharedService.user.representsCustomer.customerStockForOtherCustomers,
            customerStockForSubcustomers:
                this.sharedService.user.representsCustomer.customerStockForSubcustomers
        }
    }

    onCustomerStockForOtherCustomersClick() {
        const newValues = {
            customerStockForOtherCustomers: !this.options.customerStockForOtherCustomers,
            customerStockForSubcustomers: this.options.customerStockForSubcustomers
        };
        if (!newValues.customerStockForOtherCustomers && !newValues.customerStockForSubcustomers) {
            newValues.customerStockForSubcustomers = true;
        }
        this.setAttrs(newValues);
    }

    onCustomerStockForSubcustomersClick() {
        const newValues = {
            customerStockForOtherCustomers: this.options.customerStockForOtherCustomers,
            customerStockForSubcustomers: !this.options.customerStockForSubcustomers
        };
        if (!newValues.customerStockForOtherCustomers && !newValues.customerStockForSubcustomers) {
            newValues.customerStockForOtherCustomers = true;
        }
        this.setAttrs(newValues);
    }

    private setAttrs(newValues: SettingsOptions) {
        this.customerStockService.setCustomerStockSettings(newValues.customerStockForOtherCustomers,
            newValues.customerStockForSubcustomers).subscribe(() => {
                this.options.customerStockForOtherCustomers =
                this.sharedService.user.representsCustomer.customerStockForOtherCustomers =
                newValues.customerStockForOtherCustomers;

                this.options.customerStockForSubcustomers =
                this.sharedService.user.representsCustomer.customerStockForSubcustomers =
                newValues.customerStockForSubcustomers;

                localStorage.setItem('user', JSON.stringify(this.sharedService.user));
            },
            err => console.log(err)
        );
    }
}
