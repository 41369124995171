<div class="row-container pagination" fxFlex="100" fxLayout="row" fxLayoutAlign="end" *ngIf="total">
    <span class="counts items-per-page">
        <span translate>ITEMS_PER_PAGE</span>: 
				<dropdown-component attributeName="itemsPerPage" [items]="itemsPerPageOptions" [selected]="itemsPerPageSelected" (onSelect)="onSelectItemsPerPage($event)" 
					[disabled]="busy" [dropup]="dropup"></dropdown-component>
    </span>
      <span class="counts" *ngIf="total > 0">
          {{((currentPage -1) * itemsPerPage) + 1}} - {{currentPage * itemsPerPage > total ? total : currentPage * itemsPerPage}} 
          <span translate>OF</span>&nbsp;<span class="total-rows-number">{{total}}</span></span>
	  <pagination *ngIf="isVisible"
				class="pagination-sm"
				[totalItems]="total" 
				[itemsPerPage]="itemsPerPage" 
				[(ngModel)]="currentPage" 
				[maxSize]="maxSize" 
              	[boundaryLinks]="true" 
              	[rotate]="false"
                [disabled]="busy"
              	firstText="&laquo;"
              	previousText="&lsaquo;"
              	nextText="&rsaquo;"
              	lastText="&raquo;"
              	(numPages)="numPages = $event" 
              	(pageChanged)="pageChanged($event)"></pagination>
</div>