import { Injectable } from '@angular/core';
import { EcmHttpService } from '../../services/http/ecm.http.service';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toastService/toast.service'
import { StockService } from '../stockComponent/stock.service.interface'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerStockService implements StockService {

    total: number;
    distrubutorStores = [];
    subjectReloadCurrent = new Subject<any>();
    subjectStockSelected: Subject<any>;
    subjectStockUpdated: Subject<any>;
    subjectAllStockSelected: Subject<any>;

    constructor(
        private http: EcmHttpService,
        private toastService: ToastService,
        ) {
        this.subjectStockSelected = new Subject<any>();
        this.subjectStockUpdated = new Subject<any>();
        this.subjectAllStockSelected = new Subject<any>();
        this.updateStockItems = this.updateStockItems.bind(this);
        this.deleteStockItems = this.deleteStockItems.bind(this);
    }

    /**
     * @param query - object of query params ({skip: number, top: number, ...})
     */
    getStockItems (query) {
        const url = `/customer-stock/items`;
        return this.http.get(url, this.http.prepareOptions(query)).pipe(map(data => {
            return {rows: data.rows, totalCount: data.totalCount};
        }));
    }

    /**
     * Exports all
     */
    exportAll(query) {
        const url = `/customer-stock/items`;
        return this.http.get(url, this.http.prepareOptions(query));
    }

    /**
     * Bulk update of items in current order
     *
     * @param itemId - id of item in order
     * @param attrs  - object of editable attributes of item in order with its values
     */
    updateStockItems(items: string[], data: {}, query?: any) {
        // delete query.page;
        // delete query.top;
        return this.http.put(`/customer-stock/items`, JSON.stringify({itemIds: items, data: data, action: 'UPDATE'}), this.http.prepareOptions(query)).pipe(map(() => {
            this.toastService.addSuccess('DISTRIBUTOR_STORES_ITEM_CHANGED_SUCCESSFULLY');
        } ));
    }

    /**
     * Deletes given items of current order
     */
    deleteStockItems(items: string[], query?: any) {
        return this.http.put(`/customer-stock/items`, JSON.stringify({itemIds: items, action: 'DELETE'}), this.http.prepareOptions(query));
    }

        /**
     * Adds products to current order
     *
     * @param products - array of products to be added
     */
    addStockItems(products: {amount: string, eCommProductId: string, brandCode: string}[]) {
        return this.http.post(`/customer-stock/items`, JSON.stringify(products)).pipe(map((data) => {
            if (data.errors.length === 0) {
                this.toastService.addSuccess('DISTRIBUTOR_STORES_ADD_TO_STORE_SUCCESS');
            } else {
                data.errors.forEach(function(item) {
                    for (const key in item.error) {
                        if (item.error[key]) {
                            item.error[key] = item.error[key].toUpperCase().replace(/\ /g, '_') + '_ERROR';
                            // if (item.error[key] === 'INVALID_DATA_TYPE:_DELIVERYDATE_ERROR') {
                            //     item.row.deliveryDate = '';
                            // }
                        }
                    }
                });
            }
            if (data.invalidPackingAmount) {
                this.toastService.addSuccess('SOME_BASKET_ITEMS_NEED_ADJUST_AMOUNT_PACKAGES', 10000);
            }
            return data;
        } ));
    }

    setCustomerStockSettings(customerStockForOtherCustomers: boolean,
        customerStockForSubcustomers: boolean): Observable<void> {

        return this.http.put('/customer-stock/settings', JSON.stringify({
            customerStockForOtherCustomers: customerStockForOtherCustomers ? 1 : 0,
            customerStockForSubcustomers: customerStockForSubcustomers ? 1 : 0}));
    }

    /**
     * Returns observable of reload - used to reload table data
     */
    getReloadCurrentSubject(): Subject<any> {
        return this.subjectReloadCurrent;
    }

    /**
     * Returns observable of all selection - used to detext change in header renderer of grid
     */
    getAllStockSelectedSubject(): Subject<any> {
        return this.subjectAllStockSelected;
    }

    /**
     * Returns observable of single selection - used to detect change in row selection to count weight andprice of selected items
     */
    getStockSelectedSubject(): Subject<any> {
        return this.subjectStockSelected;
    }

    getStockUpdatedSubject(): Subject<any> {
        return this.subjectStockUpdated;
    }
}
