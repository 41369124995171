<div class="row-container wider">
    <div fxLayout="row" class="tab-container">
        <span class="row-container tab-active" style="margin-right: 5px;">
            <span translate>ORDERS_SUMMARY</span>
        </span>
        <span class="row-container clickable" routerLink="/{{sharedService.appSettings.language}}/orders-items">
            <span translate>ORDERS_ITEMS</span>
        </span>
    </div>

<!--     <div fxLayout="row" fxLayoutAlign="end">
        <button class="btn-secondary" (click)="printExportOrders()">
            <i class="fa fa-print"></i>
            <span translate>PRINT_EXPORT</span>
        </button>
    </div> -->
    
    <div fxLayout="column" *ngIf="initialized">
    	<ecm-table [dataGetter]="getOrders" 
                     [optionsGetter]="getGridOptions"
                     [filterItems]="filterItems"
                     [columnsGetter]="getColumns"
                     [columnsDefsGetter]="getColumnsDefs"
                     [total]="totalRows"
                     [selection]="sharedService.user.preferences.ordersSelection"
                     [subjectAllSelectedChange]="subjectAllOrdersSelected"
                     [bulk]="bulk"
                     [observableRefresh]="subjectRefresh.asObservable()"
                     [showRefreshButton]="true"
                     [busy]="busy"
                     [exportObj]="exportObj"></ecm-table>
    </div>
</div>