<div class="row-container wider" style="width: 100%" *ngIf="params">
	<div class="attachments-in-modal">
        <attachments 
            [entityId]="params.idAttrName ? params[params.idAttrName] : params.id"
            [attachmentsGetter]="params.getAttachments"
            [getUploadUrl]="params.getUploadUrl"
            [getDownloadUrl]="params.getDownloadUrl"
            [deleteAttachment]="params.deleteAttachment"
            [allowEdit]="params.allowEdit"
            (attachmentS3Keys)="params.setAttachmentS3Keys($event)"
            [isModal]='true'
            [bsModalRef]="this.bsModalRef"
            [hideRowLabel]="params.hideRowLabel ? true : false"
            [showPrivateControls]="params.showPrivateControls"
            [privateConfig]="params.privateConfig"
          >
        </attachments>
  </div>
</div>
