import { Component, OnInit, OnDestroy } from '@angular/core';
import { Animations } from '../../animations';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StockGridService } from '../stockComponent/stock.grid.service';
import { CustomerStockService } from './customer.stock.service';
import { StockService } from '../stockComponent/stock.service.interface';
import { PermissionsService } from '@app/services/permissions.service';

@Component({
    selector: 'app-customer-stock-component',
    templateUrl: 'customer.stock.component.html',
    // styleUrls: ['./customer.stock.component.scss'],
    animations: [Animations.slideInOut]
})
export class CustomerStockComponent implements OnInit {
    public stockService: StockService;
    public showCustomerStockOptions = false;
    public suppressAdding = true;


    constructor (
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private stockGridService: StockGridService,
        private customerStockService: CustomerStockService,
        private permissionsService: PermissionsService,
    ) {
        this.stockService = this.customerStockService;
        this.showCustomerStockOptions = !this.permissionsService.hasPermission('customerStockItemsReadAs');
    }

    ngOnInit() {
        this.suppressAdding = this.permissionsService.hasPermission('customerStockItemsReadAs');

        setTimeout(() => {
            // navigate with orderby parameter
            this.sharedService.navigateWithOrderby(this.stockGridService.getColumns(false, 'customer-stock'),
                this.route.snapshot.queryParams, this.sharedService.lastCustomerStockQueryParams);
        }, 0);
    }
}
