import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { RfqsService } from './rfqs.service';
import { RfqGridService } from './rfq.grid.service';
import { RfqPermissionService } from './rfq.permission.service';
import { GridHeaderRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderRendererComponent/grid.header.renderer.component';
import { GridLinkRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridDateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridStateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridStateRendererComponent/grid.state.renderer.component';
import { GridCommentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridCommentRendererComponent/grid.comment.renderer.component';
import { GridAttachmentRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridAttachmentRendererComponent/grid.attachment.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridTranslateRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridTranslateRendererComponent/grid.translate.renderer.component';
import {GridRfqDateOfferedRendererComponent} from '../../components/tableComponent/gridRendererComponents/gridRfqDateOfferedRendererComponent/grid.rfq.date.offered.renderer.component';
import { map } from 'rxjs/operators';
import { TableFilterItem, TableFilterItemOperators, TableFilterItemTypes } from '@app/model/table.filter.model';
@Injectable()
export class RfqsItemsGridService {
    subjectItemsSelected: Subject<any>;
    subjectSelected: Subject<any>;

    allowedStatesToExport = ['N', 'O', 'S', 'F'];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private rfqsService: RfqsService,
        private rfqPermissionService: RfqPermissionService,
        private rfqGridService: RfqGridService
    ) {
        this.subjectItemsSelected = new Subject<any>();
        this.subjectSelected = new Subject<any>();
    }

    getItemsSelectedSubject() {
        return this.subjectItemsSelected;
    }
    getSelectedSubject() {
        return this.subjectSelected;
    }

    getColumns(forceOriginal?: boolean): any[] {
        let original = [
            { id: 'rfqNumber', name: 'RFQ_NUMBER', alwaysVisible: true, checked: true, orderDirection: 'ASC', width: 130 },
            { id: 'customerName', name: 'CUSTOMER', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'dateCreated', name: 'DATE_OF_CREATION', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'rfqProduct', name: 'PRODUCT_ID', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'rfqProductAS', name: 'PRODUCT_ID_AS', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'amountRequired', name: 'REQUIRED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'amountOffered', name: 'OFFERED_AMOUNT', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'unitPriceCatalogue', name: 'CATALOGUE_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'unitPriceRequired', name: 'REQUIRED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'unitPriceOffered', name: 'OFFERED_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'rowPrice', name: 'TOTAL_PRICE', checked: true, orderBy: true, orderDirection: 'ASC', width: 120 },
            { id: 'dateRequired', name: 'REQUIRED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedDays', name: 'OFFERED_DATE_DAYS', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOffered', name: 'OFFERED_DATE', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedValidity', name: 'OFFERED_DATE_VALIDITY', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            // { id: 'dateOfferedCalculated', name: 'OFFERED_DATE_CALCULATED', checked: true, orderBy: true, orderDirection: 'DESC', width: 120 },
            { id: 'dateOfferedCalculated', name: 'OFFERED_DATE_CALCULATED', checked: true, orderBy: false, width: 120 },
            { id: 'itemType', name: 'RFQ_ITEM_TYPE', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', checked: true, orderBy: false, orderDirection: 'DESC', width: 120 },
            { id: 'itemNote', name: 'NOTE', checked: false, orderBy: false, orderDirection: 'ASC', width: 110 },
            { id: 'postsCount', name: 'DISCUSSION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'attachmentsCount', name: 'ATTACHMENTS', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            { id: 'itemState', name: 'STATUS', checked: true, orderBy: false, orderDirection: 'ASC', width: 50 },
            { id: 'personCreatedSortName', name: 'PERSON_CREATED', checked: true, orderBy: false, orderDirection: 'ASC', width: 110 }
        ];

        original = this.tableService.filterSuppressColumns(original, 'rfqsItems')
            .filter(colDef => this.rfqPermissionService.isRfqItemColumnAvailable(colDef.id));
        const restored = this.sharedService.user.preferences['rfqs-itemsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return { name: '_', width: 0 };
    }

    getGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'rfqs-itemsTablePageSize', headerClassFunc);
        gridOptions.headerHeight = 50;  // two linws
        gridOptions.columnDefs = this.getColumnDefs();
        gridOptions.getRowStyle = this.rfqGridService.getRowStyle;
        return gridOptions;
    }

    getColumnDefs() {
        let colDefs: any[] = [
            {
                headerName: this.translateService.instant(this.getColumn('rfqNumber').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'rfqNumber',
                width: this.getColumn('rfqNumber').width,
                cellRendererFramework: GridLinkRendererComponent,
                cellRendererParams: {
                    backUrl: 'rfqs-items',
                    backQueryParamsAttrName: 'lastRfqsItemsQueryParams'
                }
            },
            ...(this.sharedService.user.availableCustomers.length > 1
                ? [{
                    headerName: this.translateService.instant(this.getColumn('customerName').name), headerComponent: GridHeaderRendererComponent,
                    headerComponentParams: {
                      router: this.router,
                      activatedRoute: this.activatedRoute,
                      cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                    },
                    headerClass: this.headerClassFunc,
                    field: 'customerName',
                    width: this.getColumn('customerName').width,
                    cellStyle: { 'text-align': 'left' }
                }]
                : []
            ),
            {
                headerName: this.translateService.instant(this.getColumn('dateCreated').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateCreated',
                width: this.getColumn('dateCreated').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: { time: true },
            },
            {
                headerName: this.translateService.instant(this.getColumn('rfqProduct').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'rfqProduct',
                width: this.getColumn('rfqProduct').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('rfqProduct').checked,
            },
            {
                headerName: this.translateService.instant(this.getColumn('rfqProductAS').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'rfqProductAS',
                width: this.getColumn('rfqProductAS').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('rfqProductAS').checked,
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountRequired').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'amountRequired',
                width: this.getColumn('amountRequired').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('amountOffered').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'amountOffered',
                width: this.getColumn('amountOffered').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceCatalogue').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'unitPriceCatalogue',
                width: this.getColumn('unitPriceCatalogue').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceCatalogue' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceRequired').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'unitPriceRequired',
                width: this.getColumn('unitPriceRequired').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceRequired' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('unitPriceOffered').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'unitPriceOffered',
                width: this.getColumn('unitPriceOffered').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: {
                    currencyColumnName: 'currencyPriceOffered',
                    getPopInfo: (params) => {
                        return this.rfqsService.getPriceOfferedPopupValue(params.data);
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('rowPrice').name),
                field: 'rowPrice',
                width: this.getColumn('rowPrice').width,
                suppressSizeToFit: true,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridPriceRendererComponent,
                cellRendererParams: { currencyColumnName: 'currencyPriceOffered' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateRequired').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateRequired',
                width: this.getColumn('dateRequired').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                // cellRendererParams: { time: true },
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedDays').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateOfferedDays',
                width: this.getColumn('dateOfferedDays').width,
                cellStyle: { 'text-align': 'center' },
                // cellRendererFramework: GridInputRendererComponent,
                valueGetter: (params) => {
                    return params.data.dateOfferedDays ? 'D + ' + params.data.dateOfferedDays : '';
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOffered').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateOffered',
                width: this.getColumn('dateOffered').width,
                cellRendererFramework: GridDateRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedValidity').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateOfferedValidity',
                width: this.getColumn('dateOfferedValidity').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent
            },
            {
                headerName: this.translateService.instant(this.getColumn('dateOfferedCalculated').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'dateOfferedCalculated',
                width: this.getColumn('dateOfferedCalculated').width,
                cellStyle: { 'text-align': 'right' },
                cellRendererFramework: GridRfqDateOfferedRendererComponent,
                editable: false,
                sortable: false,
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemType').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                  },
                  headerClass: this.headerClassFunc,
                field: 'itemType',
                width: this.getColumn('itemType').width,
                maxWidth: 200,
                cellRendererFramework: GridTranslateRendererComponent,
                cellRendererParams: { translatePrefix: 'RFQ_ITEM_TYPE_' }
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemValidity').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                  },
                  headerClass: this.headerClassFunc,
                field: 'itemValidity',
                width: this.getColumn('itemValidity').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    isColValid: this.rfqGridService.isColValid,
                    isRowValid: row => this.rfqGridService.isRowValid(row),
                    useValidationBackground: false // if isnt valid, dont use red background on element
                },
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemNote').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'itemNote',
                width: this.getColumn('itemNote').width,
                cellStyle: { 'text-align': 'left' },
                hide: !this.getColumn('itemNote').checked,
            },
            {
                headerName: this.translateService.instant(this.getColumn('postsCount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'postsCount',
                cellRendererFramework: GridCommentRendererComponent,
                cellRendererParams: {
                    itemArea: 'rfq', idAttrName: 'rfqId', loadForEachItem: true,
                    getItemLabel: (item) => this.translateService.instant('ROW').toLowerCase() + ' ' + item.lineNumber,
                    isHidden: params => params.data.itemState === 'P'
                },
                width: this.getColumn('postsCount').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('attachmentsCount').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                  },
                  headerClass: this.headerClassFunc,
                field: 'attachmentsCount',
                cellRendererFramework: GridAttachmentRendererComponent,
                cellRendererParams: {
                    itemArea: 'rfq', idAttrName: 'id',
                    getAttachments: (params, id) => this.rfqsService.getAttachments(params.data.rfqId).pipe(map(data => {
                        data = data.filter(item => item.childId === params.data.lineNumber);
                        return data;
                    })),
                    getUploadUrl: (params, fileName: string) => this.rfqsService.getAttachmentUploadUrl(fileName, params.data.rfqId, params.data.id),
                    deleteAttachment: (params, entityId: number, attachmentId: number, lineNumber?: number) => this.rfqsService.deleteAttachment(params.data.id, attachmentId, lineNumber),
                    getDownloadUrl: (params, attachmentId: number, lineNumber?: number) => this.rfqsService.getAttachmentDownloadUrl(params.data.rfqId, attachmentId, lineNumber),
                    // getAllowEdit: (params) => this.rfqPermissionService.isCellEditable(params.data, 'unitPriceOffered')
                    getAllowEdit: params => false
                },
                width: this.getColumn('postsCount').width,
            },
            {
                headerName: this.translateService.instant(this.getColumn('itemState').name),
                headerComponentParams: {
                    router: this.router,
                    activatedRoute: this.activatedRoute,
                    cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                  },
                  headerClass: this.headerClassFunc,
                field: 'itemState',
                width: this.getColumn('itemState').width,
                suppressSizeToFit: true,
                cellRendererFramework: GridStateRendererComponent,
                cellRendererParams: {
                    tooltipPrefix: 'RFQ_ITEM_STATE_',
                    renderColors: {
                        gray: 'P',
                        red: 'N',
                        yellow: 'O',
                        blueDarker: 'F',
                        blue: 'C',
                        lime: 'A',
                        green: 'R',
                        black: 'D'
                    }
                }
            },
            {
                headerName: this.translateService.instant(this.getColumn('personCreatedSortName').name), 
                headerComponent: GridHeaderRendererComponent,
                headerComponentParams: {
                  router: this.router,
                  activatedRoute: this.activatedRoute,
                  cellStyle: { 'white-space': 'normal', 'text-align': 'center' }
                },
                headerClass: this.headerClassFunc,
                field: 'personCreatedSortName',
                width: this.getColumn('personCreatedSortName').width,
                cellStyle: { 'text-align': 'left' }
            }
        ];

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'rfqsItems')
            .filter(colDef => this.rfqPermissionService.isRfqItemColumnAvailable(colDef.field));
        return colDefs;
    }

    headerClassFunc(params) {
        return 'bkg-primary fnt-white';
    }

    public getFilterItems(personsCreated: { id: string, name: string }[] = [], assignedSellerNames: { id: string, name: string }[] = []): TableFilterItem[] {
        let filterItems: TableFilterItem[] = [
            ...(this.rfqPermissionService.isRfqItemColumnAvailable('customerName')
                ? [{ 
                    id: 'customer.id', 
                    name: 'CUSTOMER', 
                    type: TableFilterItemTypes.multiselect, 
                    search: true, 
                    value: '', 
                    values: this.sharedService.user.availableCustomers.map(customer => ({
                        ...customer, id: customer.id.toString()
                    }))
                }]
                : []
            ),
            ...(this.rfqPermissionService.isRfqItemColumnAvailable('assignedSellerName')
                ? [{ id: 'assignedSellerName', name: 'ASSIGNED_SELLER_NAME', type: TableFilterItemTypes.multiselect, search: true, value: '', values: assignedSellerNames }]
                : []
            ),
            { id: 'rfqNumber', name: 'RFQ_NUMBER', type: TableFilterItemTypes.text, maxLength: 10, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'itemNote', name: 'NOTE', type: TableFilterItemTypes.text, maxLength: 20, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'productFilter', name: 'PRODUCT_ID_AND_AS', type: TableFilterItemTypes.text, maxLength: 255, value: '', operator: TableFilterItemOperators.likeBoth },
            { id: 'productASFilter', name: 'PRODUCT_ID_AS',  type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL',
              selectedLabel: 'SELECTED_STATES', value: '',
              values: [
                  { id: 'FOUND', name: 'FOUND_AP', default: true},
                  { id: 'NOT_FOUND', name: 'NOT_FOUND_AP', default: true},
            ]},
            { 
                id: 'dateCreated', 
                name: 'DATE_OF_CREATION', 
                type: TableFilterItemTypes.dateRange, 
                valueFrom: '', 
                valueTo: '',
                showYearPicker: true
            },
            { id: 'personCreated', name: 'PERSON_CREATED', type: TableFilterItemTypes.multiselect, search: true, value: '', values: personsCreated },
            {
                id: 'rfqState', name: 'RFQ_STATE', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: { gray: 'P', red: 'N', yellow: 'O', green: 'S', black: 'F' },
                values: [
                    { id: 'P', name: 'RFQ_STATE_P', dotId: 'P', default: true },
                    { id: 'N', name: 'RFQ_STATE_N', dotId: 'N', default: true },
                    { id: 'O', name: 'RFQ_STATE_O', dotId: 'O', default: true },
                    { id: 'S', name: 'RFQ_STATE_S', dotId: 'S', default: false },
                    { id: 'F', name: 'RFQ_STATE_F', dotId: 'F', default: false }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['P', 'N', 'O']
                }
            },
            {
                id: 'itemState', name: 'RFQ_ITEM_STATE', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                customDotColors: { gray: 'P', red: 'N', yellow: 'O', blueDarker: 'F', blue: 'C', lime: 'A', green: 'R', black: 'D' },
                values: [
                    { id: 'P', name: 'RFQ_ITEM_STATE_P', dotId: 'P', default: true },
                    { id: 'N', name: 'RFQ_ITEM_STATE_N', dotId: 'N', default: true },
                    { id: 'O', name: 'RFQ_ITEM_STATE_O', dotId: 'O', default: true },
                    { id: 'F', name: 'RFQ_ITEM_STATE_F', dotId: 'F', default: true },
                    { id: 'C', name: 'RFQ_ITEM_STATE_C', dotId: 'C', default: true },
                    { id: 'A', name: 'RFQ_ITEM_STATE_A', dotId: 'A', default: true },
                    { id: 'R', name: 'RFQ_ITEM_STATE_R', dotId: 'R', default: true },
                    { id: 'D', name: 'RFQ_ITEM_STATE_D', dotId: 'D', default: true }
                ],
                bulkCheckbox: {
                    title: 'SHOW_OPENED_STATES_ONLY', ids: ['P', 'N', 'O', 'F', 'C', 'A']
                }
            },
            {
                id: 'itemType', name: 'RFQ_ITEM_TYPE', type: TableFilterItemTypes.multiselect, allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: [
                    { id: 'T', name: 'RFQ_ITEM_TYPE_T', default: true },
                    { id: 'P', name: 'RFQ_ITEM_TYPE_P', default: true },
                    { id: 'S', name: 'RFQ_ITEM_TYPE_S', default: true },
                    { id: 'O', name: 'RFQ_ITEM_TYPE_O', default: true }
                ]
            },
            // { id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', type: 'date', value: '', operator: { id: 'between', name: 'EQUAL', short: '=' } },
            { id: 'itemValidity', name: 'RFQ_ITEM_VALIDITY', type: TableFilterItemTypes.dateRange, valueFrom: '', valueTo: '' }
        ]

        filterItems = this.tableService.filterSuppressColumns(filterItems, 'rfqsItems');
        return filterItems;
    }

}

