import { Observable ,  Subject } from 'rxjs';
import { RequireBusinessConditionsComponent } from './require.business.conditions.dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';

@Injectable()
export class RequireBusinessConditionsDialogService {
	bsModalRef: BsModalRef

    constructor(private modalService: BsModalService) { }

    public confirm(): Observable<boolean> {
		this.bsModalRef = this.modalService.show(RequireBusinessConditionsComponent, 
	    	{
				class: 'require-business-conditions-modal',
				keyboard: false,
    			ignoreBackdropClick: true
			}
    	);
        this.bsModalRef.content.doneSubject = new Subject<any>();

        return this.bsModalRef.content.doneSubject;
    }
}